export default {
  Home: '/home',
  Privacy: '/privacy',
  SystematicTrading: '/SystematicTrading',
  Ctb: '/Chart-To-Backtest',
  Terms: '/terms',
  Disclosure: '/disclosure',
  Demo: '/demo',
  Login: '/login',
}
