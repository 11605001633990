import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import { ICONS, SPACING, theme } from '../../Theme'
import CustomTooltip from '../../UI/CustomTooltip'

const OrderbookSectionCondensed = ({
  orderBookOptions, routeToOrderbook, orderBookCount, isDark,
}) => {
  const [activeTab, setActiveTab] = useState('')

  const renderOrderBookItems = (item) => {
    const { title, value, image } = item
    return (
      <div onMouseEnter={() => setActiveTab(value)}>
        <CustomTooltip
          show={activeTab === value}
          placement="left"
          tooltipProps={{ text: title }}
          contentRenderer={() => orderbookTooltip(item)}
          interactive
          tooltipContainerStyles={styles.orderbookTooltip}
          showArrow={false}
        >
          <button className={styles.orderBookOption} type="button" onClick={() => routeToOrderbook(value)}>
            <img src={image} alt={title} className={styles[`orderBook${value}${isDark ? 'Dark' : 'Light'}`]} />
          </button>
        </CustomTooltip>
      </div>
    )
  }

  const orderbookTooltip = (item) => {
    const {
      title, image, color, value,
    } = item
    return(
      <button className={styles.orderBookRow} type="button" onClick={() => routeToOrderbook(value)}>
        <img src={image} alt={title} className={styles.orderBookTooltipImg} />
        <div className={styles.orderBookTooltipContent}>
          <CustomText color={color} size="large" weight="medium">{orderBookCount[value]}</CustomText>
          <div className={styles.subTitle}>
            {title === 'Executed Orders' && <Icon name={ICONS.TICK} color={color} size={12} />}
            <CustomText color="textSecondary" size="small">{title}</CustomText>
          </div>
        </div>
      </button>
    )
  }
  const styles = stylesheet()
  return (
    <div className={styles.orderBookContainer}>
      {orderBookOptions.map(item => <div key={item.title}>{renderOrderBookItems(item)}</div>)}
    </div>
  )
}

const stylesheet = makeStyles({
  orderBookRow: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    display: 'flex',
    alignItems: 'center',
    width: 250,
    cursor: 'pointer',
  },
  orderBookContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: SPACING.SPACE_30,
    margin: `${SPACING.SPACE_20} 0`,
  },
  orderBookpendingLight: {
    width: 35,
    '&:hover': {
      boxShadow: '#e0dfff 0px 5px 15px;',
      borderRadius: '5px',
    },
  },
  orderBookpendingDark: {
    width: 35,
    '&:hover': {
      boxShadow: '#221bff 0px 5px 15px;',
      borderRadius: '5px',
    },
  },
  orderBookexecutedLight: {
    width: 35,
    '&:hover': {
      boxShadow: '#f0e5ff 0px 5px 15px;',
      borderRadius: '5px',
    },
  },
  orderBookexecutedDark: {
    width: 35,
    '&:hover': {
      boxShadow: '#8730ff 0px 5px 15px;',
      borderRadius: '5px',
    },
  },
  orderbookTooltip: {
    background: theme.bgSidebar,
    boxShadow: `0px 10px 20px ${theme.dropdownShadow}`,
  },
  orderBookTooltipImg: {
    width: 35,
  },
  orderBookTooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: SPACING.SPACE_20,
    alignItems: 'flex-start',
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING.SPACE_8,
  },
})

export default OrderbookSectionCondensed
