import React from 'react'
import throttle from 'lodash/throttle'
import FreeTrialBanner from '../../components/FreeTrialBanner'
import Brokers from '../../components/Brokers'
import GetStarted from '../../components/GetStarted'
// import FooterHomepage from '../../components/FooterHomepage'
import PlanBanner from '../../components/PlanBanner'
// import StatisticsResponsive from '../../components/StatisticsResponsive'

import HOME_ASSETS from '../../assets'
import './systematicTrading.css'
import { pathname_mapping } from '../../../utils/navigationConstant'
import { SPACING } from '../../../Theme'
import { parseQuery } from '../../../utils/common'
import Statistic from '../../components/Statistic' // { statistics }
import Testimonials from '../../components/Testimonials'
import FeaturesInfo from '../../components/FeaturesInfo'
import { DISPLAY_DEVICE } from '../../../utils/consts'
import { withTheme } from '../../../Theme/ThemeProvider'
// import Footer from '../../components/Footer'
import FooterNewHomePage from '../../components/FooterNewHomepage'

const {
  NotficationBell, TechnicalBar, PaperTrade, Multitime,
  CreateAdvance, OptionsTrading, DycContracts,
} = HOME_ASSETS

const featureData = [
  { label: 'Technicals', imgSrc: TechnicalBar, style: { width: 280, marginTop: 32 } },
  { label: 'Get notified', imgSrc: NotficationBell, style: { width: 135, marginTop: 22 } },
  { label: 'Paper trade', imgSrc: PaperTrade, style: { width: 120, marginTop: 22 } },
  { label: 'Advanced strategy creation', imgSrc: CreateAdvance, style: { width: 160, marginTop: 22 } },
  { label: 'Options trading', imgSrc: OptionsTrading, style: { width: 170, marginTop: 32 } },
  { label: 'Multi-Time frame', imgSrc: Multitime, style: { width: 120, marginTop: 22 } },
  { label: 'Dynamic contracts', imgSrc: DycContracts, style: { width: 150, marginTop: 22 } },
]

let attached = false

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollTop: 0,
      currentFeatIndex: 0,
      boxAnimation: false,
      isMobile: false,
      displayDevice: 'desktop',
    }

    const { search } = props.location
    this.queryParams = {}
    if (search) {
      this.queryParams = parseQuery(search)
    }
    // http://localhost:3000/browse/upstox/pricing/offers?plan=regular&period=3&offer_token=
  }

  isMobile = navigator.userAgentData && navigator.userAgentData.platform && (navigator.userAgentData.platform.includes('Android')
    || navigator.userAgentData.platform.includes('ios'))

  checkWindowSize = () => {
    const currentWidth = window.innerWidth
    // const currentHeight = window.innerHeight
    let isMobile = false
    let displayDevice = DISPLAY_DEVICE.DESKTOP.valueKey
    if(currentWidth <= 558) {
      displayDevice = DISPLAY_DEVICE.SMALL_MOBILE.valueKey
      isMobile = true
    } else if (currentWidth <= 786) {
      displayDevice = DISPLAY_DEVICE.MOBILE.valueKey
      isMobile = true
    } else if (currentWidth <= 1024) {
      displayDevice = DISPLAY_DEVICE.TAB.valueKey
    } else if (currentWidth <= 1440) {
      displayDevice = DISPLAY_DEVICE.DESKTOP.valueKey
    } else if (currentWidth <= 1920) {
      displayDevice = DISPLAY_DEVICE.FHD.valueKey
    } else {
      displayDevice = DISPLAY_DEVICE.UHD.valueKey
    }
    this.setState({ isMobile, displayDevice })
  }

  componentDidMount() {
    const { toggleAuthModal, location } = this.props
    this.checkWindowSize()
    if (this.queryParams.claimNow) {
      toggleAuthModal(true)
    }
    this.main = document.getElementById('main')
    if (this.main) {
      this.main.addEventListener('scroll', this.scrollListener)
      this.scrollListenerAttached = true
      if (location && location.hash) {
        const ele = document.getElementById(location.hash.split('#')[1])
        if (ele) {
          this.main.scrollTo({
            top: ele.getBoundingClientRect().top
              - SPACING.HEADER_HEIGHT_MOBILE,
          })
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.scrollListenerAttached) this.main.removeEventListener('scroll', this.scrollListener)
  }

  scrollListener = throttle((ev) => {
    const {
      offsetHeight: viewHeight,
      // scrollTop
    } = ev.target
    const mainheader = document.getElementById('mainheader')

    const resEle = document.getElementById('homeRes')
    if (resEle) {
      const { top, height } = resEle.getBoundingClientRect()
      if (height <= viewHeight && (top) >= 0 && (top) <= viewHeight) resEle.classList.add('resContainerhoverd')
      else resEle.classList.remove('resContainerhoverd')
    }
    const benefitsEle = document.getElementById('homeBenefits')
    if (benefitsEle) {
      const { top, height } = benefitsEle.getBoundingClientRect()
      if (height <= viewHeight && (top) >= 0 && (top) <= viewHeight) benefitsEle.classList.add('benefitsRightContenthover')
      else benefitsEle.classList.remove('benefitsRightContenthover')
    }
    const trialEle = document.getElementById('trialWrapper')
    if (trialEle) {
      const { top, height } = trialEle.getBoundingClientRect()
      if (top <= 56 && top + height - 56 >= 0) {
        attached = true
        mainheader.setAttribute('data-theme', 'dark')
        this.setState({ boxAnimation: true })
      } else if (mainheader) {
        attached = false
        mainheader.removeAttribute('data-theme')
        this.setState({ boxAnimation: false })
      }
    }
    // const featuresEle = document.getElementById('featWrapper')
    // if (featuresEle) {
    //   const { top, height } = featuresEle.getBoundingClientRect()
    //   if (top <= 56 && top + height - 56 >= 0) {
    //     attached = true
    //     const featureContainer = document.getElementById('featureContainer')
    // if (featureContainer) {
    //   const { offsetHeight: featContainerHeight } = featureContainer
    //   const relTop = scrollTop - featuresEle.offsetTop
    //   let currentFeatIndex = Math.floor(relTop / featContainerHeight)
    //   if (currentFeatIndex < 0) {
    //     currentFeatIndex = 0
    //   } else if (currentFeatIndex >= featureData.length) {
    //     currentFeatIndex = featureData.length - 1
    //   }
    //   if (this.state.currentFeatIndex !== currentFeatIndex) {
    //     this.setState({ currentFeatIndex })
    //   }
    // }
    // mainheader.setAttribute('data-theme', 'dark')
    // } else if (!attached && mainheader) {
    // mainheader.removeAttribute('data-theme')
    // }
    // }
    const footerEle = document.getElementById('footerWrapper')
    if (footerEle) {
      const { top, height } = footerEle.getBoundingClientRect()
      if (top <= 56 && top + height - 56 >= 0 && !mainheader.getAttribute('data-theme')) {
        mainheader.setAttribute('data-theme', 'dark')
      } else if (!attached && mainheader) {
        mainheader.removeAttribute('data-theme')
      }
    }
  }, 150)

  routeToDashboard = () => {
    const { history } = this.props
    history.push(pathname_mapping.Auth)
  }

  routeToDemo = () => {
    const { history } = this.props
    history.push(pathname_mapping.demo)
  }

  routeToPricing = (query = '') => {
    const { history, toggleLoginModal } = this.props
    if (toggleLoginModal) {
      toggleLoginModal(true, { pathname: `${pathname_mapping.Account}`, pathParams: `?tab=pricing${query}` })
    } else {
      history.push(`${pathname_mapping.pricing}${query}`)
    }
  }

  onPressTrial = () => {
    const { history } = this.props
    // updateGA('Log in', 'SystematicTrading', 1, 'Home', 'Search')
    history.push(pathname_mapping.Auth)
  }

  update = (index) => {
    // const featureContainer = document.getElementById('featureContainer')
    // const featuresEle = document.getElementById('featWrapper')
    // // const main = document.getElementById('main')
    // const { offsetHeight: featContainerHeight } = featureContainer
    // const top = featuresEle.offsetTop + (featContainerHeight * index) + 30
    // main.scrollTo({ top })
    this.setState({ currentFeatIndex: index })
  }

  skipCarousal = (skipTo) => {
    const featureContainer = document.getElementById('featureContainer')
    const featuresEle = document.getElementById('featWrapper')
    const main = document.getElementById('main')
    if (skipTo === 'down') {
      const { offsetHeight: featContainerHeight } = featureContainer
      const top = featuresEle.offsetTop + (featContainerHeight * (featureData.length + 1)) + 30
      main.scrollTo({ top })
    }
  }

  render() {
    const {
      isMobile,
      displayDevice,
      // currentFeatIndex,
    } = this.state
    const { updateGA } = this.props
    return (
      <div className="systematicTradingContainer" id="systematicTradingContainer">
        { !isMobile && (
        <>
          <div className="homeBackgroundGradientLeft" />
          <div className="homeBackgroundGradientRight" />
        </>
        )}
        <FreeTrialBanner
          onPressTrial={this.onPressTrial}
          isMobile={isMobile}
          displayDevice={displayDevice}
          updateGA={updateGA}
        />
        <Brokers isMobile={isMobile} />
        <PlanBanner
          isMobile={isMobile}
          displayDevice={displayDevice}
          onPressTrial={this.onPressTrial}
          updateGA={updateGA}
        />
        <FeaturesInfo isMobile={isMobile} onPressTrial={this.onPressTrial} updateGA={updateGA} />
        {/* {isMobile ? (
          <StatisticsResponsive
            onPressDemo={this.onPressTrial}
            currentFeatIndex={currentFeatIndex}
            featureData={statistics}
            update={this.update}
            skipCarousal={this.skipCarousal}
          />
        ) : ( */}
        <Statistic
          onPressDemo={this.onPressTrial}
          isMobile={isMobile}
          updateGA={updateGA}
        />
        {/* )} */}
        <Testimonials
          isMobile={isMobile}
          displayDevice={displayDevice}
        />
        <GetStarted onPressDemo={this.onPressTrial} isMobile={isMobile} updateGA={updateGA} />
        {/* <FooterHomepage isMobile={isMobile} /> */}
        <FooterNewHomePage />
      </div>
    )
  }
}

const stylesheet = {}

export default withTheme(stylesheet)(Home)
