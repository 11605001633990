import { takeLatest, put, call } from 'redux-saga/effects'
import {
  prodEndpoints, baseUrl, wpBaseUrl, wpEndpoints,
} from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  getNotificationsSuccess,
  getNotificationsFailure,
  placeOrderSuccess,
  placeOrderFailure,
  markUsedSuccess,
  markUsedFailure,
  markReadSuccess,
  markReadFailure,
  fetchOrderlogSuccess,
  fetchOrderlogFailure,
  markNotificationUsedSuccess,
  markNotificationUsedFailure,
  getNotificationCountSuccess,
  getNotificationCountFailure,
  getDisclaimerNotificationsSuccess,
  getDisclaimerNotificationsFailure,
} from './actions'
import {
  GET_NOTIFICATIONS_INIT,
  PLACE_ORDER_INIT,
  MARK_READ_INIT,
  MARK_USED_INIT,
  MARK_NOTIFICATION_USED_INIT,
  ORDER_LOG_INIT,
  GET_NOTIFICATION_COUNT_INIT,
  GET_DISCLAIMER_NOTIFICATIONS_INIT,
} from './actionTypes'
// eslint-disable-next-line import/no-cycle
import store from '../../store'
import { error_msg } from '../../utils/common'

function* getNotifications(action) {
  try {
    const { params = {} } = action
    const { platform = 'streak' } = params
    let url = prodEndpoints.notifications
    if(platform === 'openstreak') {
      url = `https://api-op.streak.tech/${prodEndpoints.notifications}`
    }
    const data = yield call(getRequest, url, {})
    if (!data.error && data.status === 'success') {
      yield put(getNotificationsSuccess(data))
    } else {
      yield put(getNotificationsFailure(data.error))
    }
  } catch(err) {
    yield put(getNotificationsFailure(err.message || error_msg))
  }
}

export function* getNotificationsSaga() {
  yield takeLatest(GET_NOTIFICATIONS_INIT, getNotifications)
}

function* placeOrder(action) {
  try {
    const { params, orderType: type, headers } = action
    const { transaction_type, order_type, platform = 'streak' } = params
    let url = ''
    const platformUrl = platform === 'openstreak' ? 'https://api-op.streak.tech/' : baseUrl
    if (type === 'tpsl') {
      url = `${platformUrl}${prodEndpoints.place_order_tpsl}`
    } else if (type === 'normal') {
      url = `${platformUrl}${prodEndpoints.place_order}`
    } else {
      url = `${platformUrl}${prodEndpoints.place_order_discp}`
    }
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(placeOrderSuccess({ ...data, transaction_type, order_type }))
    } else {
      yield put(placeOrderFailure(data.error_msg || data.error))
    }
  } catch(err) {
    yield put(placeOrderFailure(err.message || error_msg))
  }
}

export function* placeOrderSaga() {
  yield takeLatest(PLACE_ORDER_INIT, placeOrder)
}

function* markUsed(action) {
  try {
    const { params, headers } = action
    let coreurl = baseUrl
    if(params?.platform === 'openstreak') {
      coreurl = 'https://api-op.streak.tech/'
    }
    const url = `${coreurl}${prodEndpoints.mark_used}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(markUsedSuccess(data))
    } else {
      yield put(markUsedFailure(data.error))
    }
  } catch(err) {
    yield put(markUsedFailure(err.message || error_msg))
  }
}

export function* markUsedSaga() {
  yield takeLatest(MARK_USED_INIT, markUsed)
}

function* markRead(action) {
  try {
    const { params, headers, notifActiveTab } = action
    const url = `${baseUrl}${prodEndpoints.notifications}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(markReadSuccess(data, notifActiveTab))
    } else {
      yield put(markReadFailure(data.error))
    }
  } catch(err) {
    yield put(markReadFailure(err.message || error_msg))
  }
}

export function* markReadSaga() {
  yield takeLatest(MARK_READ_INIT, markRead)
}

function* markNotificationUsed(action) {
  try {
    const { params, headers } = action
    let coreurl = baseUrl
    if(params?.platform === 'openstreak') {
      coreurl = 'https://api-op.streak.tech/'
    }
    const url = `${coreurl}${prodEndpoints.mark_notifications_used}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(markNotificationUsedSuccess(data))
    } else {
      yield put(markNotificationUsedFailure(data.error))
    }
  } catch(err) {
    yield put(markNotificationUsedFailure(err.message || error_msg))
  }
}

export function* markNotificationUsedSaga() {
  yield takeLatest(MARK_NOTIFICATION_USED_INIT, markNotificationUsed)
}

function* fetchOrderlog(action) {
  try {
    const { deployment_uuid, headers } = action
    const url = `${baseUrl}${prodEndpoints.fetch_order_log}?deployment_uuid=${deployment_uuid}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchOrderlogSuccess(data.order_logs))
    } else {
      yield put(fetchOrderlogFailure(data.error))
    }
  } catch(err) {
    yield put(fetchOrderlogFailure(err.message || error_msg))
  }
}

export function* fetchOrderlogSaga() {
  yield takeLatest(ORDER_LOG_INIT, fetchOrderlog)
}

function* getNotificationCount() {
  try {
    const url = prodEndpoints.notifications_count
    const data = yield call(getRequest, url, {})
    if (!data.error && data.status === 'success') {
      yield put(getNotificationCountSuccess(data))
    } else {
      yield put(getNotificationCountFailure(data.error))
    }
  } catch(err) {
    yield put(getNotificationCountFailure(err.message || error_msg))
  }
}

export function* getNotificationCountSaga() {
  yield takeLatest(GET_NOTIFICATION_COUNT_INIT, getNotificationCount)
}

function* getDisclaimerNotifications({ params }) {
  try {
    const url = `${wpBaseUrl}${wpEndpoints.pages}`
    const data = yield call(getRequest, url, params)
    if (!data.error && data.length > 0) {
      const state = store.getState()
      const { auth: { user_details } = {} } = state || {}
      const { broker } = user_details || {}
      yield put(getDisclaimerNotificationsSuccess(data, broker))
    } else {
      yield put(getDisclaimerNotificationsFailure(data.error))
    }
  } catch (err) {
    yield put(getDisclaimerNotificationsFailure(err.message || error_msg))
  }
}

export function* getDisclaimerNotificationsSaga() {
  yield takeLatest(GET_DISCLAIMER_NOTIFICATIONS_INIT, getDisclaimerNotifications)
}
