import React, { lazy } from 'react'
import {
  Route,
} from 'react-router-dom'

// import PageLoader from './UI/PageLoader'

// import Auth from './containers/Auth/Auth-redux'

import { pathname_mapping } from './utils/navigationConstant'

const Discover = lazy(() => import('./containers/MarketPlace/Discover-redux'))
const MarketplaceBacktest = lazy(() => import('./containers/Backtests/Backtests-redux'))
const Scans = lazy(() => import('./containers/Scanner/Scans-redux'))
const Explore = lazy(() => import('./containers/Explore/Explore'))
const DiscoverScans = lazy(() => import('./containers/Scans/DiscoverScans'))
const OfferPage = lazy(() => import('./homepage/components/OfferPage'))

export const commonRoutes = [
  // { Component: Auth, path: pathname_mapping.Auth, exact: true },
  { Component: Explore, path: pathname_mapping.Explore, exact: true },
  { Component: Discover, path: pathname_mapping.Discover, exact: true },
  { Component: DiscoverScans, path: pathname_mapping.popular },
  { Component: MarketplaceBacktest, path: pathname_mapping.MarketplaceBacktest, exact: true },
  { Component: MarketplaceBacktest, path: pathname_mapping.Backtests, exact: true },
  { Component: Scans, path: pathname_mapping.Scanners, exact: true },
  { Component: OfferPage, path: pathname_mapping.OfferPage, exact: false },
]

const CommonRoutes = ({ domainChange, toggleLoginModal }) => {
  return (
    commonRoutes.map((item) => {
      const { path, exact, Component } = item
      return (
        <Route
          key={path}
          exact={exact}
          path={path}
          render={props => (
            // pass the sub-routes down to keep nesting
            <Component {...props} domainChange={domainChange} toggleLoginModal={toggleLoginModal} />
          )}
        />
      )
    })
  )
}

export default CommonRoutes
