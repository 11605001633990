import { takeLatest, put, call } from 'redux-saga/effects'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  fetchActiveAlgoStockSuccess,
  fetchActiveAlgoStockFailure,
  fetchStoppedAlgoSuccess,
  fetchStoppedAlgoFailure,
  stopAlgoSuccess,
  stopAlgoFailure,
  reDeployAlgoSuccess,
  reDeployAlgoFailure,
  getOrderDetailsSuccess,
  getOrderDetailsFailure,
  stopWaitingAlgosSuccess,
  stopWaitingAlgosFailure,
  getDeployedCountSuccess,
  getDeployedCountFailure,
  openPositionsSuccess,
  openPositionsFailure,
} from './actions'
import {
  ACTIVE_ALGOS_STOCK_INIT,
  STOPPED_ALGOS_INIT,
  STOP_ALGO_INIT,
  FETCH_OPEN_POSITIONS_INIT,
  REDEPLOY_INIT,
  ORDER_DETAILS_INIT,
  STOP_WAITING_ALGOS_INIT,
  DEPLOYED_COUNT_INIT,
} from './actionTypes'
import { getInstruments, fetchDeployedInstruments } from '../Instruments/actions'
import { NAVIGATIONS } from '../../utils/navigationConstant'

import { error_msg } from '../../utils/common'

function* fetchActiveStocks(action) {
  try {
    const { params = {}, headers } = action
    let fetchUrl = baseUrl
    if(params?.platform === 'openstreak') {
      fetchUrl = 'https://api-op.streak.tech/'
    }
    const url = `${fetchUrl}${prodEndpoints.fetch_order_log}?status=0&limit=100&page=0`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchActiveAlgoStockSuccess(data))
      yield put(getInstruments(
        fetchDeployedInstruments(null, data.grouped_orders, NAVIGATIONS.DEPLOYED.name),
        {},
        NAVIGATIONS.DEPLOYED.name,
      ))
    } else {
      yield put(fetchActiveAlgoStockFailure(data.error))
    }
  } catch(err) {
    yield put(fetchActiveAlgoStockFailure(err.message || error_msg))
  }
}

export function* fetchActiveStocksSaga() {
  yield takeLatest(ACTIVE_ALGOS_STOCK_INIT, fetchActiveStocks)
}

function* fetchStoppedStocks(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.fetch_order_log}?status=-1&limit=500&page=0`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchStoppedAlgoSuccess(data))
      yield put(getInstruments(
        fetchDeployedInstruments(null, data.grouped_orders, NAVIGATIONS.DEPLOYED.name),
        {},
        NAVIGATIONS.DEPLOYED.name,
      ))
    } else {
      yield put(fetchStoppedAlgoFailure(data.error))
    }
  } catch(err) {
    yield put(fetchStoppedAlgoFailure(err.message || error_msg))
  }
}

export function* fetchStoppedStocksSaga() {
  yield takeLatest(STOPPED_ALGOS_INIT, fetchStoppedStocks)
}

function* stopAlgo(action) {
  try {
    const { headers, actionType, params } = action
    let url = ''
    let fetchUrl = baseUrl
    if(params?.platform === 'openstreak') {
      fetchUrl = 'https://api-op.streak.tech/'
    }
    if (actionType === 'exitNow') {
      url = prodEndpoints.exit_position_now_force_stop
    } else if (actionType === 'keepPositionsOpen') {
      url = prodEndpoints.exit_with_positions_open
    }
    const callUrl = `${fetchUrl}${url}`
    const data = yield call(postRequest, callUrl, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(stopAlgoSuccess(data))
    } else {
      yield put(stopAlgoFailure(data.error_msg))
    }
  } catch(err) {
    yield put(stopAlgoFailure(err.message || error_msg))
  }
}

export function* stopAlgoSaga() {
  yield takeLatest(STOP_ALGO_INIT, stopAlgo)
}

function* getOpenPositions(action) {
  try {
    const { headers, params } = action
    let fetchUrl = baseUrl
    if(params?.platform === 'openstreak') {
      fetchUrl = 'https://api-op.streak.tech/'
    }
    const url = `${fetchUrl}${prodEndpoints.fetch_open_positions}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(openPositionsSuccess(data))
    } else {
      yield put(openPositionsFailure(data.error))
    }
  } catch(err) {
    yield put(openPositionsFailure(err.message || error_msg))
  }
}

export function* getOpenPositionsSaga() {
  yield takeLatest(FETCH_OPEN_POSITIONS_INIT, getOpenPositions)
}

function* reDeployAlgo(action) {
  try {
    const { params, headers } = action
    const subsLimitUrl = `${prodEndpoints.get_subscription_limit}`
    const limitResp = yield call(getRequest, subsLimitUrl, { resp: 'json' }, headers)
    if (limitResp.status === 'success' && limitResp.deployments_remaining > 0) {
      const url = `${prodEndpoints.redeploy_algorithm}`
      const data = yield call(postRequest, url, params, headers)
      if (!data.error && data.status === 'success') {
        yield put(reDeployAlgoSuccess(data))
      } else {
        yield put(reDeployAlgoFailure(data.error_msg))
      }
    } else {
      yield put(reDeployAlgoFailure('Error while re-deploying stock'))
    }
  } catch (err) {
    yield put(reDeployAlgoFailure(error_msg))
  }
}

export function* reDeployAlgoSaga() {
  yield takeLatest(REDEPLOY_INIT, reDeployAlgo)
}

function* orderDetails(action) {
  try {
    const { params, headers } = action
    const url = `${prodEndpoints.show_order_details}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(getOrderDetailsSuccess(data.order))
    } else {
      yield put(getOrderDetailsFailure(data.error_msg))
    }
  } catch (err) {
    yield put(getOrderDetailsFailure(error_msg))
  }
}

export function* orderDetailsSaga() {
  yield takeLatest(ORDER_DETAILS_INIT, orderDetails)
}

function* stopWaitingAlgos(action) {
  try {
    const { params, headers } = action
    const url = `${prodEndpoints.stop_waiting_algos}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(stopWaitingAlgosSuccess(data))
    } else {
      yield put(stopWaitingAlgosFailure(data.error_msg || data.msg))
    }
  } catch (err) {
    yield put(stopWaitingAlgosFailure(error_msg))
  }
}

export function* stopWaitingAlgosSaga() {
  yield takeLatest(STOP_WAITING_ALGOS_INIT, stopWaitingAlgos)
}

function* getDeployedCount(action) {
  try {
    const { params, headers } = action
    const url = `${prodEndpoints.depoyed_count}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(getDeployedCountSuccess(data))
    } else {
      yield put(getDeployedCountFailure(data.error_msg))
    }
  } catch (err) {
    yield put(getDeployedCountFailure(error_msg))
  }
}

export function* getDeployedCountSaga() {
  yield takeLatest(DEPLOYED_COUNT_INIT, getDeployedCount)
}
