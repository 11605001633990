import React from 'react'
import classnames from 'classnames'
import { animated } from 'react-spring'

import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
// import CircleLoader from '../../UI/CircleLoader'

import {
  SPACING, theme,
} from '../../Theme'
import { CURRENCY_SYM } from '../../UI/ShowLTP'
import { withTheme } from '../../Theme/ThemeProvider'

const FundsCard = React.memo(({
  balance = 0,
  value = 0,
  margins = 0,
  iconName,
  wrapperStyles = {},
  isFetching,
  fundsError,
  // onRefresh,
  iconColor,
  styles,
  backgroundColor,
  animationStyles,
}) => {
  const renderCell = (cellLabel, cellValue, flex) => {
    return (
      <div className={styles.cell} style={{ flex }}>
        <CustomText weight="medium">
          {CURRENCY_SYM}
          {` ${cellValue.toFixed(2)}`}
        </CustomText>
        <CustomText color="textSecondary" size="small">{cellLabel}</CustomText>
      </div>
    )
  }
  return (
    <animated.div
      className={classnames(styles.card, wrapperStyles)}
      style={{ backgroundColor, ...animationStyles }}
    >
      <div className={styles.header}>
        <Icon name={iconName} color={iconColor} size={25} />
        <CustomText style={{ marginLeft: SPACING.SPACE_10 }} size="large" weight="medium">
          {'  '}
          {CURRENCY_SYM}
          {` ${balance.toFixed(2)}`}
        </CustomText>
      </div>
      <CustomText size="small" color="textSecondary">Available Balance</CustomText>

      <div className={styles.footer}>
        {isFetching ? <p>fetching</p> : (
          <>
            {renderCell('Margin', margins, 0.35)}
            <div className={styles.footerCell}>
              {renderCell('Account', value, 1)}
            </div>
          </>
        )}
      </div>
      {fundsError && (
      <CustomText className={styles.error} size="tiny_1" color="red" weight="regular">
        {/* <Icon name={ICONS.REFRESH} color={COLORS.RED} size={8} /> */}
        {'  Some error occured'}
      </CustomText>
      )}
    </animated.div>
  )
})

const stylesheet = {
  card: {
    padding: `${SPACING.SPACE_24} ${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    borderRadius: 8,
    // position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_4,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: SPACING.SPACE_12,
  },
  footerCell: {
    flex: 0.6,
    borderLeft: `1px solid ${theme.borderColor}`,
    paddingLeft: SPACING.SPACE_20,
    marginLeft: SPACING.SPACE_16,
  },
  cell: {
    textAlign: 'left',
  },
  error: {
    position: 'absolute',
    bottom: SPACING.SPACE_4,
    right: SPACING.SPACE_10,
  },
}

export default withTheme(stylesheet)(FundsCard)
