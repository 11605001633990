import {
  CLEAR_TECHNICALS_RESP,
  FETCH_TECHNICALS_LIST,
  FETCH_TECHNICALS_LIST_SUCCESS,
  FETCH_TECHNICALS_LIST_FAILURE,
  GET_MULTI_ANALYSIS_INIT,
  GET_MULTI_ANALYSIS_SUCCESS,
  GET_MULTI_ANALYSIS_FAILURE,
  CLEAR_MULTI_ANALYSIS,
  UPDATE_TECHNICALS_LIST,
  UPDATE_TECHNICALS_LIST_SUCCESS,
  UPDATE_TECHNICALS_LIST_FAILURE,
  FETCH_CAROUSAL_CATEGORY,
  FETCH_CAROUSAL_CATEGORY_SUCCESS,
  FETCH_CAROUSAL_CATEGORY_FAILURE,
  FETCH_RESISTANCE,
  FETCH_RESISTANCE_SUCCESS,
  FETCH_RESISTANCE_FAILURE,
  CLEAR_RESISTANCE,
} from './actionTypes'
import { formatAnalysis } from '../../utils/common'

const initialState = {
  // TechnicalsList
  isFetchingTechnicalsList: false,
  fetchTechnicalsListError: false,
  fetchTechnicalsListErrMsg: '',
  // discoverAlgo: [],
  // technial Instrument
  technicalInstruments: [],
  isFetchingMultiAnalysis: false,
  multiAnalysis: {},
  multiAnalysisError: false,
  multiAnalysisErrorMsg: '',
  updateInstrumentListError: false,
  updateInstrumentListErrorMsg: '',
  carousalCategory: {},
  isFetchingCategories: false,
  carousalCategoryError: false,
  carousalCategoryErrorMsg: '',
  resistanceSingle: {},
  resistance: {},
  isFetchingResistance: false,
  resistanceError: false,
  resistanceErrorMsg: '',
  // viewer_dict: {}
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_TECHNICALS_RESP:
      return {
        ...state,
        cloneSampleResp: {},
        isCloning: false,
        cloneError: false,
        cloneSampleErrorMsg: '',
      }

    case FETCH_TECHNICALS_LIST:
      return {
        ...state,
        isFetchingTechnicalsList: false,
        fetchTechnicalsListError: false,
        fetchTechnicalsListErrMsg: '',
        // discoverAlgo: [],
        technicalInstruments: [],
      }

    case FETCH_TECHNICALS_LIST_SUCCESS: {
      const { instruments_list } = action.data
      return {
        ...state,
        isFetchingTechnicalsList: false,
        // discoverAlgo: algo || [],
        // viewer_dict,
        technicalInstruments: instruments_list || [],
      }
    }

    case FETCH_TECHNICALS_LIST_FAILURE:
      return {
        ...state,
        isFetchingTechnicalsList: false,
        fetchTechnicalsListError: true,
        fetchTechnicalsListErrMsg: action.error,
      }

    case GET_MULTI_ANALYSIS_INIT:
      return {
        ...state,
        isFetchingAnalysis: true,
      }

    case GET_MULTI_ANALYSIS_SUCCESS: {
      const { data, stockList } = action
      let modAnalysis = { ...state.multiAnalysis }
      stockList.forEach((item) => {
        modAnalysis = {
          ...modAnalysis,
          [item]: data[item] ? formatAnalysis(data[item]) : { noData: true },
        }
      })
      return {
        ...state,
        isFetchingAnalysis: false,
        multiAnalysis: modAnalysis,
      }
    }

    case GET_MULTI_ANALYSIS_FAILURE:
      return {
        ...state,
        isFetchingAnalysis: false,
        multiAnalysisError: true,
        multiAnalysisErrorMsg: action.error,
      }

    case CLEAR_MULTI_ANALYSIS:
      return {
        ...state,
        multiAnalysis: {},
        multiAnalysisError: false,
        multiAnalysisErrorMsg: '',
      }

    case UPDATE_TECHNICALS_LIST:
      return {
        ...state,
        updateInstrumentListError: false,
        updateInstrumentListErrorMsg: '',
      }

    case UPDATE_TECHNICALS_LIST_SUCCESS: {
      const { technicalInstruments = [], seg_sym, remove } = action.data
      const modAnalysis = { ...state.analysis }
      if (remove) {
        delete modAnalysis[seg_sym]
      }
      return {
        ...state,
        multiAnalysis: modAnalysis,
        technicalInstruments: [...technicalInstruments],
      }
    }

    case UPDATE_TECHNICALS_LIST_FAILURE:
      return {
        ...state,
        updateInstrumentListError: true,
        updateInstrumentListErrorMsg: action.error,
      }

    case FETCH_CAROUSAL_CATEGORY:
      return {
        ...state,
        isFetchingCategories: true,
        carousalCategoryError: false,
        carousalCategoryErrorMsg: '',
      }

    case FETCH_CAROUSAL_CATEGORY_SUCCESS: {
      const { data, category } = action
      return {
        ...state,
        carousalCategory: {
          ...state.carousalCategory,
          [category]: data,
        },
        isFetchingCategories: false,
      }
    }

    case FETCH_CAROUSAL_CATEGORY_FAILURE:
      return {
        ...state,
        technicalCategoryError: true,
        technicalCategoryErrorMsg: action.error,
      }

    case FETCH_RESISTANCE: {
      if (action.single) {
        return {
          ...state,
          isFetchingResistance: true,
          resistanceSingle: {},
        }
      }
      return {
        ...state,
        isFetchingResistance: true,
      }
    }

    case FETCH_RESISTANCE_SUCCESS: {
      const { data, stockList, single } = action
      const stock = stockList[0]
      if (single) {
        return {
          ...state,
          isFetchingResistance: false,
          resistanceSingle: {
            [stock]: data[stock] || { noData: true },
          },
        }
      }
      let modAnalysis = { ...state.resistance }
      stockList.forEach((item) => {
        modAnalysis = {
          ...modAnalysis,
          [item]: data[item] || { noData: true },
        }
      })
      return {
        ...state,
        resistance: modAnalysis,
        isFetchingResistance: false,
      }
    }

    case FETCH_RESISTANCE_FAILURE:
      return {
        ...state,
        isFetchingResistance: false,
        resistanceError: true,
        resistanceErrorMsg: action.error,
      }

    case CLEAR_RESISTANCE:
      if (action.single) {
        return {
          ...state,
          isFetchingResistance: true,
          resistanceSingle: {},
        }
      }
      return {
        ...state,
        isFetchingResistance: false,
        resistance: {},
        resistanceError: false,
        resistanceErrorMsg: '',
      }

    default:
      return state
  }
}

export default dashboard
