import {
  CREATE_ORDER_INIT,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_SUCCESS,
  FETCH_PAYMENT_PARAMS_INIT,
  FETCH_PAYMENT_PARAMS_SUCCESS,
  FETCH_PAYMENT_PARAMS_FAILURE,
  FETCH_PAYMENT_METHOD_INIT,
  FETCH_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHOD_FAILURE,
  FETCH_SUBSCRIPTION_PRICE_INIT,
  FETCH_SUBSCRIPTION_PRICE_SUCCESS,
  FETCH_SUBSCRIPTION_PRICE_FAILURE,
  CLEAR_ORDER_RESP,
  PAY_WITH_CREDITS,
  PAY_WITH_CREDITS_SUCCESS,
  PAY_WITH_CREDITS_FAILURE,
} from './actionTypes'

const initialState = {
  customer_id: null,
  subscription_payment_type: null,
  availableMethods: {},
  order_id: null,
  price: 0,
  total_price: 0,
  tax: 0,
  redeem_credit: 0,
  subscription_id: null,
  payment_id: null,
  currency: 'INR',
  createOrderError: false,
  createOrderErrorMsg: '',
  createOrderSuccess: false,
  isCreatingOrder: false,
  cardDetails: {},
  selectedPlan: null,
  fetchPaymentParamsFailure: false,
  fetchPaymentParamsFailureMsg: '',
  fetchCardDetailsFailure: false,
  fetchCardDetailsFailureMsg: '',
  isFetchingPaymentParams: false,

  paymentMethodsError: false,
  isFetchingPaymentMethods: false,
  paymentMethodsErrorMsg: '',
  paymentMethodsSuccess: false,

  payWithCreditsResp: {},
  isPayingWithCredits: true,
  payWithCreditsError: false,
  payWithCreditsErrorMsg: '',
}

const payment = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER_INIT:
      return {
        ...state,
        customer_id: null,
        subscription_payment_type: null,
        order_id: null,
        price: 0,
        total_price: 0,
        tax: 0,
        redeem_credit: 0,
        subscription_id: null,
        payment_id: null,
        currency: 'INR',
        cardDetails: {},
        availableMethods: {},
        isCreatingOrder: true,
        selectedPlan: null,
        createOrderError: false,
        createOrderSuccess: false,
      }

    case CREATE_ORDER_FAILURE: {
      return {
        ...state,
        createOrderError: true,
        isCreatingOrder: false,
        createOrderErrorMsg: action.error,
      }
    }

    case CREATE_ORDER_SUCCESS: {
      const {
        subscription_payment_type,
        order_id,
        price,
        currency,
        subscription_id,
        payment_id,
        customer_id,
        tokens,
        methods,
        total_price,
        tax,
        paymentPlan,
        redeem_credit,
      } = action.data
      return {
        ...state,
        subscription_payment_type,
        order_id,
        price,
        total_price,
        redeem_credit,
        tax,
        currency,
        subscription_id,
        payment_id,
        customer_id,
        cardDetails: tokens,
        selectedPlan: paymentPlan,
        createOrderSuccess: true,
        availableMethods: methods,
        isCreatingOrder: false,
      }
    }

    case FETCH_PAYMENT_PARAMS_INIT:
      return {
        ...state,
        isFetchingPaymentParams: true,
        fetchPaymentParamsFailure: false,
      }

    case FETCH_PAYMENT_PARAMS_SUCCESS: {
      const { data = {}, errorDesc } = action
      const {
        payment_data,
        customer,
        tokens,
        methods,
        price,
        tax,
        total_price,
        redeem_credit,
      } = data
      return {
        ...state,
        cardDetails: tokens,
        customer_id: customer.id,
        subscription_payment_type: payment_data.entity,
        order_id: payment_data.entity === 'order' ? payment_data.id : null,
        price,
        total_price,
        redeem_credit,
        tax,
        subscription_id: payment_data.entity === 'subscription' ? payment_data.id : null,
        payment_id: null,
        availableMethods: methods,
        currency: payment_data.currency,
        paymentFailureError: true,
        paymentFailureErrorMsg: errorDesc,
        isFetchingPaymentParams: false,
      }
    }

    case FETCH_PAYMENT_PARAMS_FAILURE:
      return {
        ...state,
        isFetchingPaymentParams: false,
        fetchPaymentParamsFailure: true,
        fetchPaymentParamsFailureMsg: action.error,
      }

    case FETCH_PAYMENT_METHOD_INIT:
      return {
        ...state,
        cardDetails: {},
        availableMethods: {},
        paymentMethodsError: false,
        isFetchingPaymentMethods: true,
        paymentMethodsErrorMsg: '',
        paymentMethodsSuccess: false,
      }

    case FETCH_PAYMENT_METHOD_SUCCESS: {
      const {
        methods = {},
      } = action.data
      return {
        ...state,
        cardDetails: methods.cards || methods.tokens || {},
        availableMethods: { ...methods, tokens: methods.cards || methods.tokens || {} },
        paymentMethodsSuccess: true,
        isFetchingPaymentMethods: false,
      }
    }

    case FETCH_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        paymentMethodsError: true,
        isFetchingPaymentMethods: false,
        paymentMethodsErrorMsg: action.error,
      }

    case FETCH_SUBSCRIPTION_PRICE_INIT: {
      return {
        ...state,
        isFetchingSubsPrice: true,
        price: 0,
        total_price: 0,
        tax: 0,
        redeem_credit: 0,
        currency: 'INR',
        fetchSubsPriceError: false,
        fetchSubsPriceErrorMsg: '',
      }
    }

    case FETCH_SUBSCRIPTION_PRICE_SUCCESS: {
      return {
        ...state,
        ...action.data,
        currency: 'INR',
        isFetchingSubsPrice: false,
      }
    }

    case FETCH_SUBSCRIPTION_PRICE_FAILURE:
      return {
        ...state,
        isFetchingSubsPrice: false,
        fetchSubsPriceError: true,
        fetchSubsPriceErrorMsg: action.error,
      }

    case CLEAR_ORDER_RESP: {
      const { params = {} } = action
      return {
        ...state,
        order_id: '',
        payment_id: '',
        createOrderError: false,
        createOrderErrorMsg: '',
        createOrderSuccess: false,
        selectedPlan: null,
        redeem_credit: 0,
        ...params,
      }
    }

    case PAY_WITH_CREDITS: {
      return {
        ...state,
        payWithCreditsResp: {},
        isPayingWithCredits: true,
        payWithCreditsError: false,
        payWithCreditsErrorMsg: '',
      }
    }

    case PAY_WITH_CREDITS_SUCCESS: {
      return {
        ...state,
        payWithCreditsResp: action.data,
        isPayingWithCredits: false,
      }
    }

    case PAY_WITH_CREDITS_FAILURE: {
      return {
        ...state,
        payWithCreditsError: true,
        isPayingWithCredits: false,
        payWithCreditsErrorMsg: action.error,
      }
    }

    default:
      return state
  }
}

export default payment
