import React from 'react'
import HOME_ASSETS from '../assets'
// import { DISPLAY_DEVICE } from '../../utils/consts'

const PlanBanner = React.memo((
  {
    isMobile,
    onPressTrial,
    updateGA,
  },
) => {
  const onClickLearnMore = () => {
    updateGA('Start Trial B Features', 'Features', 1, 'Landing Page')
    onPressTrial()
  }
  return (
    <div className="planContainerWrapper">
      <div className="planContainer" style={{ margin: 0 }}>
        {!isMobile && (
        <>
          <img src={HOME_ASSETS.PlanBannerBackground} alt="planBanner" className="planBannerDesignRight" />
          <img src={HOME_ASSETS.PlanBannerBackground} alt="planBanner" className="planBannerDesignLeft" />
        </>
        )}
        <h1 className="startedTitle" style={{ fontSize: `${isMobile && '30px'}` }}>
          Why Streak ?
        </h1>
        {isMobile ? (
          <div className="whyStreakTable">
            <img src={HOME_ASSETS.WhyStreakTableMobile} alt="whyStreakTable" className="WSTableMobileImg" />
            <button type="button" onClick={onClickLearnMore}>
              <p className="ReadMore">Learn more</p>
            </button>
          </div>
        ) : (
          <div className="whyStreakTable">
            {HOME_ASSETS.whyStreakTable.map(row => <img src={row.src} alt={row.name} key={row.name} className={row.name === 'whyStreakTableHeader' ? 'WSTableHeader' : 'WSTableRow'} />)}
            <button type="button" onClick={onClickLearnMore}>
              <p className="ReadMore">Learn more</p>
            </button>
          </div>
        )}
      </div>
    </div>
  )
})

export default PlanBanner
