import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'
import html2canvas from 'html2canvas'
import CustomText from './CustomText'
import Button from './Button'
import {
  DIMENSIONS, SPACING, theme, FONTWEIGHT, ASSETS, ICONS,
} from '../Theme'
import { shareUrl, embedUrl } from '../utils/apiEndpoints'
import { SOCIAL_HANDLER } from '../utils/consts'
import { showSnackbar } from './Snackbar'
import AnimatedCarousal from './AnimatedCarousal'
import { withTheme } from '../Theme/ThemeProvider'
import { fetchBacktestDetails, fetchShareLink, generateShareLink } from '../containers/Backtests/actions'
import Checkbox from './Checkbox'
import CustomInput from './CustomInput'
import InfoIcon from '../assets/icons/InfoIcon'
import CustomTooltip from './CustomTooltip'
import CustomModal from './CustomModal'
import ShareEmbedCode from './ShareEmedCode'
import { parseQuery } from '../utils/common'
import Icon from './Icon'
import { generateScannerShareLink } from '../containers/Scans/actions'

const referalText = 'Hey there!👋🏽 I am using Streak to create, backtest and deploy trading strategies - all in one place. Give it a try! Use my referral link and get 150 credits.'

class SharePopUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shareWindow: false,
      shareModal: false,
      isPublic: false,
      preview: false,
      showEmbedModal: false,
      loadingSharelink: false,
    }
    this.viewShotRef = {}
    this.shareData = {}
    this.embedData = {}
    this.shareLinkRef = React.createRef()
    this.shareModalParams = {}
    this.reshareUrl = null
    this.queryParams = {}
    this.feauture = 'strategy'
    this.shareMenuOptions = [
      {
        label: 'Get Shareable Link', value: 'getLink', iconColor: 'TEXT', iconSize: 18,
      },
      {
        label: 'Download as Image', value: 'downloadImage', iconColor: 'TEXT', iconSize: 18,
      },
      {
        label: 'Embed backtest results', value: 'getCode', iconColor: 'TEXT', iconSize: 21,
      },
    ]
  }

  componentDidMount() {
    const { scannerShare } = this.props
    if(scannerShare) {
      this.feature = 'scanner'
    }else{
      this.feature = 'backtest'
    }
  }

  componentDidUpdate = async (prevProps) => {
    const {
      generateShareLinkError,
      generateShareLinkErrorMsg,
      backtest_share_uuid,
      generateShareLinkSuccess,
      getShareLink,
      shareLinkId,
      fetchShareLinkError,
      multiShare,
      publishing_uuid,
      user_details,
      generateScannerShareLinkError, generateScannerShareLinkErrorMsg,
      generateScannerShareLinkSuccess, screener_share_uuid, id,
    } = this.props
    const {
      preview,
      isPublic,
    } = this.state
    if (!isEqual(generateShareLinkError, prevProps.generateShareLinkError)
      && generateShareLinkError) {
      showSnackbar(generateShareLinkErrorMsg, {}, 0)
    } else if (!isEqual(generateShareLinkSuccess, prevProps.generateShareLinkSuccess)
      && generateShareLinkSuccess) {
      showSnackbar('Generating shareable link')
      const {
        seg_sym = '',
        imgData = '',
        shareAlgoId = '',
      } = this.shareData
      // let srcUrl = `https://www.streak.tech`
      let srcUrl = 'https://pro.streak.tech'
      let extraParam = {}
      const { user_name, ref_id } = user_details
      if (backtest_share_uuid) {
        srcUrl = `https://pro.streak.tech/backtests?algo_share_uuid=${backtest_share_uuid}&user=${btoa(user_name)}&ref_id=${ref_id}`
      } else if (shareAlgoId) {
        srcUrl = `https://pro.streak.tech/backtests?algo_share_uuid=${shareAlgoId}&user=${btoa(user_name)}&ref_id=${ref_id}`
        extraParam = {
          origin_url: 'https://pro.streak.tech',
        }
      } else if(publishing_uuid) {
        srcUrl = `https://pro.streak.tech/marketplace_backtest?publishing_uuid=${shareAlgoId}&ref_id=${ref_id}`
      }
      const idBacktest = backtest_share_uuid || shareAlgoId
      const encodedEmbedUrl = `${embedUrl}?id=${id}&s=${seg_sym}&source=in`
      const embed_url = idBacktest ? `http://api.streak.tech/oembed/?url=${encodeURIComponent(encodedEmbedUrl)}&format=json` : ''
      if (imgData) {
        const shareHtmlParams = {
          title: "Streak | World's first systematic trading platform without coding",
          desc: 'Systematic trading platform',
          img_alt: `${seg_sym} backtest image`,
          src_url: srcUrl,
          src_text: backtest_share_uuid ? 'View full result' : 'Visit Streak',
          platform: 'mobile',
          embed_url,
          source: 'in',
          ...extraParam,
        }
        const headers = new Headers()
        headers['content-type'] = 'multipart/form-data'
        const formData = new FormData()
        formData.append('img_src', imgData)
        formData.append('params', JSON.stringify(shareHtmlParams))
        getShareLink(formData, headers)
        this.shareModalParams = {
          url: encodedEmbedUrl,
          embedCode: true,
        }
        this.embedData = {
          url: encodedEmbedUrl,
          embedCode: true,
        }
      } else {
        this.shareModalParams = {
          url: encodedEmbedUrl,
          embedCode: true,
        }
        if(preview) {
          this.setState({ loadingSharelink: false })
        } else {
          this.setState({ loadingSharelink: false })
          if(multiShare) {
            this.setState({ shareModal: true })
          }
        }
      }
    }
    if (!isEqual(generateScannerShareLinkError, prevProps.generateScannerShareLinkError)
      && generateScannerShareLinkError) {
      showSnackbar(generateScannerShareLinkErrorMsg, {}, 0)
    } else if (!isEqual(generateScannerShareLinkSuccess, prevProps.generateScannerShareLinkSuccess)
      && generateScannerShareLinkSuccess) {
      showSnackbar('Generating shareable link')
      const {
        imgData = '',
      } = this.shareData
      const { user_name, ref_id } = user_details
      const srcUrl = `https://pro.streak.tech/scans?screener_share_uuid=${screener_share_uuid}&user=${btoa(user_name)}&ref_id=${ref_id}&tab=share_scan`
      const extraParam = {}
      const encodedEmbedUrl = `${embedUrl}?id=${id}&source=in`
      const embed_url = id ? `http://api.streak.tech/oembed/?url=${encodeURIComponent(encodedEmbedUrl)}&format=json` : ''
      if (imgData) {
        const shareHtmlParams = {
          title: "Streak | World's first systematic trading platform without coding",
          desc: 'Systematic trading platform',
          img_alt: `${id} scanner image`,
          src_url: srcUrl,
          src_text: screener_share_uuid ? 'View full result' : 'Visit Streak',
          platform: 'mobile',
          embed_url,
          source: 'in',
          ...extraParam,
        }
        const headers = new Headers()
        headers['content-type'] = 'multipart/form-data'
        const formData = new FormData()
        formData.append('img_src', imgData)
        formData.append('params', JSON.stringify(shareHtmlParams))
        getShareLink(formData, headers)
        this.shareModalParams = {
          url: encodedEmbedUrl,
          embedCode: true,
        }
        this.embedData = {
          url: encodedEmbedUrl,
          embedCode: true,
        }
      } else {
        this.shareModalParams = {
          url: encodedEmbedUrl,
          embedCode: true,
        }
        if(preview) {
          this.setState({ loadingSharelink: false })
        } else {
          this.setState({ loadingSharelink: false })
          if(multiShare) {
            this.setState({ shareModal: true })
          }
        }
      }
    }
    // got the link id to public endpoint
    if (shareLinkId !== prevProps.shareLinkId && shareLinkId) {
      this.shareModalParams = {
        url: `${shareUrl}${shareLinkId}`,
        public: isPublic,
      }
      if(preview) {
        setTimeout(() => {
          this.setState({ loadingSharelink: false })
          window.open(`${this.shareModalParams.url}`, '_blank', {}, () => {
          })
        }, 5000)
      } else {
        this.setState({ loadingSharelink: false })
        if(multiShare) {
          this.setState({ shareModal: true })
        }
        this.setState({ shareModal: true })
      }
      this.shareData = {}
    } else if (fetchShareLinkError !== prevProps.fetchShareLinkError && fetchShareLinkError) {
      showSnackbar('Not able to fetch link', {}, 0)
    }
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  onPress = () => {
    const { preview, isPublic } = this.state
    const { scannerShare, selectedSymbols } = this.props
    if(selectedSymbols?.size < 1) {
      showSnackbar('To generate shareable link, please select atleast one instrument')
      return
    }
    if(preview && this.shareModalParams.public === isPublic) {
      this.setState({ loadingSharelink: true })
      this.setState({ shareModal: true })
    } else {
      this.setState({ loadingSharelink: true })
      if(scannerShare) {
        this.getScannerShareImage()
      } else {
        this.getShareImage(this.shareMenuOptions[0].value)
      }
    }
  }

 copyToClipboard = () => {
   if (this.shareLinkRef && this.shareLinkRef.current) {
     const ele = this.shareLinkRef.current
     ele.select()
     ele.setSelectionRange(0, 99999)
     document.execCommand('copy')
   }
 }

  onShareOptionSelect = (type) => {
    if (type === this.shareMenuOptions[2].value) {
      this.getShareCode()
    } else {
      this.getShareImage(type)
    }
  }

   getShareCode = () => {
     this.generateShareLink() // generateShareBacktestId
   }

   generateShareLink = (imgData) => {
     const {
       csrf,
       createShareLink,
       item: { seg_sym }, algo_uuid, publishing_uuid, algo_subscription_uuid,
       selectedSymbols,
     } = this.props
     const { isPublic } = this.state
     let id = {}
     if (algo_subscription_uuid || publishing_uuid) {
       id = algo_subscription_uuid ? { algo_subscription_uuid } : { publishing_uuid }
     } else if (algo_uuid) {
       id = { algo_uuid }
     }
     let shareAlgoId = ''
     this.shareData = {}
     const seg_syms = []
     const array = [...selectedSymbols]
     array.forEach((script) => {
       seg_syms.push(script)
     })
     if (publishing_uuid) {
       shareAlgoId = `${publishing_uuid}`
     } else if (algo_subscription_uuid) {
       shareAlgoId = `${algo_subscription_uuid}`
     } else if (algo_uuid) {
       shareAlgoId = `${algo_uuid}`
     }
     this.shareData = {
       seg_sym, imgData, shareAlgoId,
     }
     if(publishing_uuid) {
       this.genearateSharelinkMarketplce()
     } else {
       createShareLink({
         ...id,
         seg_syms,
         csrfmiddlewaretoken: csrf,
         public: isPublic ? true : '',
       }, this.headers)
     }
   }

   genearateSharelinkMarketplce = () => {
     const {
       backtest_share_uuid,
       getShareLink,
       multiShare,
       publishing_uuid,
       user_details,
     } = this.props
     const { preview } = this.state
     const {
       seg_sym = '',
       imgData = '',
       shareAlgoId = '',
     } = this.shareData
     let srcUrl = 'https://pro.streak.tech'
     let extraParam = {}
     const { ref_id } = user_details
     if (publishing_uuid) {
       srcUrl = `https://pro.streak.tech/marketplace_backtest?publishing_uuid=${shareAlgoId}&ref_id=${ref_id}`
     } else if (shareAlgoId) {
       srcUrl = `https://pro.streak.tech/backtests?algo_share_uuid=${shareAlgoId}&ref_id=${ref_id}`
       extraParam = {
         origin_url: 'https://pro.streak.tech',
       }
     } else if(publishing_uuid) {
       srcUrl = `https://pro.streak.tech/marketplace_backtest?publishing_uuid=${shareAlgoId}&ref_id=${ref_id}`
     }
     const id = backtest_share_uuid || shareAlgoId
     const encodedEmbedUrl = `${embedUrl}?id=${id}&s=${seg_sym}&source=in`
     const embed_url = id ? `http://api.streak.tech/oembed/?url=${encodeURIComponent(encodedEmbedUrl)}&format=json` : ''
     if (imgData) {
       const shareHtmlParams = {
         title: "Streak | World's first systematic trading platform without coding",
         desc: 'Systematic trading platform',
         img_alt: `${seg_sym} backtest image`,
         src_url: srcUrl,
         src_text: backtest_share_uuid ? 'View full result' : 'Visit Streak',
         platform: 'mobile',
         embed_url,
         source: 'in',
         ...extraParam,
       }
       const headers = new Headers()
       headers['content-type'] = 'multipart/form-data'
       const formData = new FormData()
       formData.append('img_src', imgData)
       formData.append('params', JSON.stringify(shareHtmlParams))
       getShareLink(formData, headers)
       this.shareModalParams = {
         url: encodedEmbedUrl,
         embedCode: true,
       }
       this.embedData = {
         url: encodedEmbedUrl,
         embedCode: true,
       }
     } else {
       this.shareModalParams = {
         url: encodedEmbedUrl,
         embedCode: true,
       }
       if(preview) {
         this.setState({ loadingSharelink: false })
         window.open(`${this.shareModalParams.url}`, '_blank')
       } else {
         this.setState({ loadingSharelink: false })
         if(multiShare) {
           this.setState({ shareModal: true })
           // this.toggleModal('shareModal')
         }
         // this.setState({ shareModal: true })
       }
     }
   }

  getShareImage = (type) => {
    const {
      isListView,
    } = this.props
    showSnackbar('Fetching Info')
    const ele = isListView ? document.getElementById('backtestResultList') : document.getElementById('backtestContainer')
    let count = 0
    const config = {
      useCORS: true,
      ignoreElements(element) {
        if(element.id === 'tour_bt_5') {
          return true
        }
        if(isListView && element.id === 'tableRow') {
          count += 1
          if(count > 10) {
            return true
          }
        }
        return false
      },
      scale: 2,
      scrollY: -window.scrollY,
    }
    html2canvas(ele, config)
      .then((canvas) => {
        const data = canvas.toDataURL('image/png')
        const imgTag = document.createElement('img')
        imgTag.src = data
        if (type === this.shareMenuOptions[1].value) {
          this.downloaderImg(data)
        } else if (type === this.shareMenuOptions[0].value) {
          this.generateShareLink(data)
        }
      }).catch(() => {
        showSnackbar('Some error occured', {}, 0)
      })
  }

  getScannerShareImage = () => {
    showSnackbar('Fetching Info')
    const ele = document.getElementById('scanner')
    let count = 0
    const config = {
      useCORS: true,
      ignoreElements(element) {
        if(element.id === 'tableRow') {
          count += 1
          if(count > 10) {
            return true
          }
        }
        return false
      },
      scale: 1,
      scrollY: -window.scrollY,
    }
    html2canvas(ele, config)
      .then((canvas) => {
        const data = canvas.toDataURL('image/png')
        const imgTag = document.createElement('img')
        imgTag.src = data
        this.generateShareLinkScanner(data)
      }).catch(() => {
        showSnackbar('Some error occured', {}, 0)
      })
  }

  generateShareLinkScanner = (imgData) => {
    const {
      csrf,
      createScannerShareLink,
      screenerState,
    } = this.props
    const { isPublic } = this.state
    const { screener_uuid, sample } = screenerState
    this.shareData = {}
    this.shareData = {
      imgData, shareScannerId: screener_uuid,
    }
    if(sample) {
      this.genearateScannerSharelinkMarketplace()
    } else {
      createScannerShareLink({
        screener_uuid,
        csrfmiddlewaretoken: csrf,
        public: isPublic ? true : '',
      }, this.headers)
    }
  }

  genearateScannerSharelinkMarketplace = () => {
    const {
      imgData = '',
    } = this.shareData
    const { preview } = this.state
    const {
      user_details, screenerState, getShareLink, multiShare,
    } = this.props
    const { screener_uuid } = screenerState
    const id = screener_uuid
    const { ref_id, user_name } = user_details
    // const srcUrl = `${window.location.href}&ref_id=${ref_id}`
    const srcUrl = `https://pro.streak.tech/scans?screener_share_uuid=${screener_uuid}&user=${btoa(user_name)}&ref_id=${ref_id}&tab=scan_details_discover&sample=${btoa(true)}`
    const extraParam = {}
    const encodedEmbedUrl = `${embedUrl}?id=${id}&source=in`
    const embed_url = id ? `http://api.streak.tech/oembed/?url=${encodeURIComponent(encodedEmbedUrl)}&format=json` : ''
    if (imgData) {
      const shareHtmlParams = {
        title: "Streak | World's first systematic trading platform without coding",
        desc: 'Systematic trading platform',
        img_alt: `${id} scanner image`,
        src_url: srcUrl,
        src_text: id ? 'View full result' : 'Visit Streak',
        platform: 'mobile',
        embed_url,
        source: 'in',
        ...extraParam,
      }
      const headers = new Headers()
      headers['content-type'] = 'multipart/form-data'
      const formData = new FormData()
      formData.append('img_src', imgData)
      formData.append('params', JSON.stringify(shareHtmlParams))
      getShareLink(formData, headers)
      this.shareModalParams = {
        url: encodedEmbedUrl,
        embedCode: true,
      }
      this.embedData = {
        url: encodedEmbedUrl,
        embedCode: true,
      }
    } else {
      this.shareModalParams = {
        url: encodedEmbedUrl,
        embedCode: true,
      }
      if(preview) {
        this.setState({ loadingSharelink: false })
      } else {
        this.setState({ loadingSharelink: false })
        if(multiShare) {
          this.setState({ shareModal: true })
        }
      }
    }
  }

  onCheckBoxPress = () => {
    const { isPublic } = this.state
    if (isPublic) {
      this.setState({ isPublic: false })
    } else {
      this.setState({ isPublic: true })
    }
  }

  downloaderImg = (data) => {
    const { item: { seg_sym } } = this.props
    const imgEle = document.createElement('a')
    imgEle.href = data
    imgEle.download = `${seg_sym.split('_').reverse().join('-')}.png`
    imgEle.click()
  }

  modScripList = () => {
    const { scripList } = this.props
    if (scripList.length < 1) {
      return scripList
    }
    const modList = [...scripList]
    return modList
  }

  onPressTwitter = () => {
    const { reShare, referral } = this.props
    this.updateGA('Twitter')
    if(reShare) {
      if(referral) {
        window.open(`https://twitter.com/intent/tweet?&text=${referalText} ${SOCIAL_HANDLER.TWITTER.handler}
        ${this.reshareUrl}`, '_blank')
      } else {
        window.open(`https://twitter.com/intent/tweet?&text=Hey, checkout these backtest results on ${SOCIAL_HANDLER.TWITTER.handler}
    ${this.reshareUrl}`, '_blank')
      }
    } else {
      window.open(`https://twitter.com/intent/tweet?&text=Hey, checkout these backtest results on ${SOCIAL_HANDLER.TWITTER.handler}
    ${this.shareModalParams.url}`, '_blank')
    }
  }

  onPressFacebook = () => {
    const { reShare } = this.props
    this.updateGA('Facebook')

    if(reShare) {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.reshareUrl)}`, '_blank')
    } else {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareModalParams.url)}`, '_blank')
    }
  }

  onPressWhatsapp = () => {
    const { reShare, referral } = this.props
    this.updateGA('Whatsapp')

    if(reShare) {
      if(referral) {
        window.open(`https://api.whatsapp.com/send/?phone&text=${referalText} ${SOCIAL_HANDLER.TWITTER.handler} ${this.reshareUrl}`, '_blank')
      } else {
        window.open(`https://api.whatsapp.com/send/?phone&text=Hey, checkout these ${this.feauture} results on ${SOCIAL_HANDLER.TWITTER.handler} ${this.reshareUrl}`, '_blank')
      }
    } else {
      window.open(`https://api.whatsapp.com/send/?phone&text=Hey, checkout these ${this.feature} results on ${SOCIAL_HANDLER.TWITTER.handler} ${this.shareModalParams.url}`, '_blank')
    }
  }

  onPressLinkedIn = () => {
    const { reShare } = this.props
    this.updateGA('LinkedIn')
    if(reShare) {
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${this.reshareUrl}`, '_blank')
    } else {
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${this.shareModalParams.url}`, '_blank')
    }
  }

  onPressEmbed = () => {
    const { reShare, item: { seg_sym, algo_uuid } } = this.props
    if(reShare) {
      const id = algo_uuid
      const encodedEmbedUrl = `${embedUrl}?id=${id}&s=${seg_sym}&source=in`
      this.embedData = {
        url: encodedEmbedUrl,
      }
    }
    this.updateGA('Embed')
    this.setState({ showEmbedModal: true })
  }

  onPreview = () => {
    const { isPublic } = this.state
    const { scannerShare, selectedSymbols } = this.props
    if(selectedSymbols?.size < 1) {
      showSnackbar('Please select atleast one instrument before preview')
      return
    }
    if(this.shareModalParams.url && this.shareModalParams.public === isPublic) {
      window.open(`${this.shareModalParams.url}`, '_blank')
    } else {
      this.setState({ preview: true })
      this.setState({ loadingSharelink: true })
      if(scannerShare) {
        this.getScannerShareImage()
      } else {
        this.getShareImage(this.shareMenuOptions[0].value)
      }
    }
    this.updateGA('Preview')
  }

  copyToClipboard = () => {
    showSnackbar('Link copied to clipboard')
    if (this.shareLinkRef && this.shareLinkRef.current) {
      const ele = this.shareLinkRef.current
      ele.select()
      ele.setSelectionRange(0, 99999)
      document.execCommand('copy')
    }
    this.updateGA('Copy link')
  }

  backPressHandler = () => {
    this.setState({ showEmbedModal: false })
  }

  updateGA = (gaLabel) => {
    const { updateGA, gaCategory = 'Share' } = this.props
    updateGA(gaCategory, gaLabel)
  }

  render() {
    const {
      shareModal, isPublic, showEmbedModal, loadingSharelink,
    } = this.state
    const {
      styles, viewRenderer, isDark, scripList, selectedSymbols,
      reShare, onDismiss, scannerShare,
      referral, referralUrl, dismissKey,
    } = this.props
    const { search } = window.location
    if(search) {
      this.queryParams = parseQuery(search, true)
    }
    let osrc = ''
    if(referral) {
      this.reshareUrl = referralUrl
    }
    if(this.queryParams.sbt || this.queryParams.algo_share_uuid
      || this.queryParams.screener_share_uuid) {
      if(this.queryParams.osrc === undefined) {
        this.reshareUrl = window.location.href
      } else {
        osrc = this.queryParams.osrc.split('_').join('/')
        this.reshareUrl = `https://public.streak.tech/${osrc}`
      }
    }
    let shareText
    const label = !scannerShare ? 'Allow others to copy this strategy into their accounts and use it for deploying in live market, paper trade or modify it themselves. Please note your account details will not be shared publicly.'
      : 'Allow others to copy this scanner into their accounts and use it for deploying in live market or modify it themselves. Please note your account details will not be shared publicly.'
    if(selectedSymbols) {
      shareText = selectedSymbols.size > 0 ? `Share ${selectedSymbols.size} Stocks` : 'Share Stocks'
    }
    if(scannerShare) {
      shareText = 'Share Scanner'
    }
    if(reShare) {
      return (
        <div className={styles.container}>
          <CustomModal
            visible={showEmbedModal}
            animationType="fade"
            onDismiss={onDismiss}
            dismissKey="show embed"
            containerStyles={styles.embedCodeContainer}
          >
            <ShareEmbedCode
              scripList={scripList}
              selectedSymbols={selectedSymbols}
              onDismiss={onDismiss}
              dismissKey={dismissKey}
              shareCodeParams={this.embedData}
              backtest_share_uuid={this.shareModalParams.url}
              backPressHandler={this.backPressHandler}
            />
          </CustomModal>
          <CustomText weight="semi_bold" size="medium">Share</CustomText>
          <div className={styles.actionBar}>
            {!referral && (
            <div
              className={styles.SocialContainer}
            >
              <button type="button" onClick={this.onPressEmbed} className={styles.embedBtn}>
                <Icon name={ICONS.EMBED} color="#696969" size={29} />
              </button>
              <CustomText size="regular_1" style={{ marginTop: '8px' }}>Embed</CustomText>
            </div>
            )}
            <div
              className={styles.SocialContainer}
            >
              <button type="button" onClick={this.onPressWhatsapp} className={styles.socialButton}>
                <img src={ASSETS.Whatsapp} alt="no img" />
              </button>
              <CustomText size="regular_1">Whatsapp</CustomText>
            </div>
            <div className={styles.SocialContainer}>
              <button
                type="button"
                className={`${styles.socialButton} ${styles.fbNew}`}
                onClick={this.onPressFacebook}
              >
                <img src={ASSETS.FacebookIcon} alt="no img" style={{ marginTop: '10px' }} />
              </button>
              <CustomText size="regular_1" style={{ marginTop: '8px' }}>Facebook</CustomText>
            </div>
            <div
              className={styles.SocialContainer}
            >
              <button type="button" onClick={this.onPressLinkedIn} className={styles.socialButton}>
                <img src={ASSETS.LinkedIn} alt="no img" />
              </button>
              <CustomText size="regular_1">LinkedIn</CustomText>
            </div>
            <div
              className={styles.SocialContainer}
            >
              <button type="button" onClick={this.onPressTwitter} className={styles.socialButton}>
                <img src={ASSETS.Twitter} alt="no img" />
              </button>
              <CustomText size="regular_1">Twitter</CustomText>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <CustomInput
              className={styles.input}
              color={isDark ? 'white' : '#9BABC6'}
              inputStyles={styles.input}
              inputRef={this.shareLinkRef}
              value={this.reshareUrl}
              iconName="COPY"
              iconPosition="end"
              size={14}
              isIconBtn
              onIconClick={this.copyToClipboard}
              onChangeText={() => {}}
              inputProps={{
                readOnly: true,
              }}
            />
          </div>
        </div>
      )
    }
    return (
      <div className={styles.container}>
        <CustomModal
          visible={showEmbedModal}
          animationType="fade"
          onDismiss={onDismiss}
          dismissKey="show embed"
          containerStyles={styles.embedCodeContainer}
        >
          <ShareEmbedCode
            scripList={scripList}
            selectedSymbols={selectedSymbols}
            dismissKey={dismissKey}
            onDismiss={onDismiss}
            shareCodeParams={this.embedData}
            backtest_share_uuid={this.shareModalParams.url}
            backPressHandler={this.backPressHandler}
          />
        </CustomModal>
        {!shareModal ? (
          <div>
            <div className={styles.header}>
              <CustomText weight="semi_bold" size="medium">{shareText}</CustomText>
              <button
                type="button"
                onClick={() => onDismiss(dismissKey)}
                className={styles.closeBtn}
              >
                <Icon name={ICONS.CLOSE} color="#9BABC6" size={20} />
              </button>
            </div>
            {!scannerShare && (
              <div className={styles.scriplist}>
                <AnimatedCarousal
                  singleView={false}
                  showNav
                    // containerStyles={{ minHeight: 'unset' }}
                  navBtnSize={25}
                >
                  {scripList.map(item => viewRenderer({ ...item, type: 'shareStockCard' }))}
                </AnimatedCarousal>
              </div>
            )}
            <div>
              <div className={styles.copyCheckboxContainer} style={{ display: 'flex' }}>
                <Checkbox
                  id="agreeSharePublic"
                  value={isPublic}
                  onValueChange={this.onCheckBoxPress}
                  className={styles.checkbox}
                />
                <CustomText size="small" weight="semi-bold" className={styles.copyText}>{` Allow Copy ${scannerShare ? 'Scanner' : 'Backtest'}`}</CustomText>
                <CustomTooltip show tooltipProps={{ text: label }}>
                  <div style={{ paddingTop: '6px' }}>
                    <InfoIcon width={16} height={16} color={theme.textSecondary} />
                  </div>
                </CustomTooltip>
              </div>
              <div className={styles.action}>
                <Button
                  text="Preview"
                  buttonColor="blue_300"
                  mode={loadingSharelink ? 'contained' : 'outlined'}
                  btnStyles={styles.shareStockBtn}
                  labelStyle={styles.shareStockBtnText}
                  disabled={loadingSharelink}
                  loading={loadingSharelink}
                  onPress={this.onPreview}
                />
                <Button
                  text="Next"
                  className={styles.tweetBtn}
                  isDark={isDark}
                  buttonColor="blue"
                  onPress={this.onPress}
                  disabled={loadingSharelink}
                  loading={loadingSharelink}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.title}>
              <CustomText weight="semi_bold" size="medium">Share</CustomText>
            </div>
            <div className={styles.actionBar}>
              <div
                className={styles.SocialContainer}
              >
                <button type="button" onClick={this.onPressEmbed} className={styles.embedBtn}>
                  <Icon name={ICONS.EMBED} color="#696969" size={29} />
                </button>
                <CustomText size="regular_1" style={{ marginTop: '8px' }}>Embed</CustomText>
              </div>
              <div
                className={styles.SocialContainer}
              >
                <button type="button" onClick={this.onPressWhatsapp} className={styles.socialButton}>
                  <img src={ASSETS.Whatsapp} alt="no img" />
                </button>
                <CustomText size="regular_1">Whatsapp</CustomText>
              </div>
              <div className={styles.SocialContainer}>
                <button
                  type="button"
                  className={`${styles.socialButton} ${styles.fbNew}`}
                  onClick={this.onPressFacebook}
                >
                  <img src={ASSETS.FacebookIcon} alt="no img" style={{ marginTop: '10px' }} />
                </button>
                <CustomText size="regular_1" style={{ marginTop: '8px' }}>Facebook</CustomText>
              </div>
              <div
                className={styles.SocialContainer}
              >
                <button type="button" onClick={this.onPressLinkedIn} className={styles.socialButton}>
                  <img src={ASSETS.LinkedIn} alt="no img" />
                </button>
                <CustomText size="regular_1">LinkedIn</CustomText>
              </div>
              <div
                className={styles.SocialContainer}
              >
                <button type="button" onClick={this.onPressTwitter} className={styles.socialButton}>
                  <img src={ASSETS.Twitter} alt="no img" />
                </button>
                <CustomText size="regular_1">Twitter</CustomText>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <CustomInput
                className={styles.input}
                color={isDark ? 'white' : '#9BABC6'}
                inputStyles={styles.input}
                inputRef={this.shareLinkRef}
                value={this.shareModalParams.embedCode
                  ? this.shareModalParams.shareableText : this.shareModalParams.url}
                iconName="COPY"
                iconPosition="end"
                size={14}
                isIconBtn
                onIconClick={this.copyToClipboard}
                onChangeText={() => {}}
                inputProps={{
                  readOnly: true,
                }}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const stylesheet = ({
  modal: {
    alignSelf: 'center',
  },
  checkbox: {
    marginRight: '10px',
  },
  title: {
    paddingLeft: SPACING.SPACE_18,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 11px 10px',
  },
  shareStockBtn: {
    marginLeft: `${SPACING.SPACE_10}`,
    border: '1px solid blue !important',
    borderRadius: '12px',
    '&:disabled': {
      backgroundColor: 'blue',
      opacity: 0.9,
    },
  },
  container: {
    paddingTop: DIMENSIONS.SPACE_VERTICAL * 2,
    // paddingBottom: DIMENSIONS.SPACE_VERTICAL,
    alignItems: 'center',
    justifyContent: 'center',
  },
  copyCheckboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: SPACING.SPACE_20,
  },
  copyText: {
    marginRight: SPACING.SPACE_8,
  },
  embedBtn: {
    backgroundColor: 'rgb(234, 236, 242)',
    borderRadius: '50%',
    textAlign: 'center',
    height: '60px',
    padding: '0px',
    width: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialButton: {
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0px',
    '& > img': {
      width: '60px',
      height: '60px',
    },
  },
  fbNew: {
    backgroundColor: 'blue',
    borderRadius: '50%',
    textAlign: 'center',
    '& > img': {
      height: 44,
    },
  },
  fbBtn: {
    height: '60px',
    width: '60px',
    borderRadius: '50%',
    backgroundColor: 'blue',
    // padding: '9px',
    paddingTop: '2px',
    marginLeft: '13px',
    marginBottom: SPACING.SPACE_6,
  },
  inputBtn: {
    marginTop: SPACING.SPACE_24,
    marginBottom: SPACING.SPACE_16,
    // display: 'flex',
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    paddingTop: SPACING.SPACE_24,
    // paddingBottom: SPACING.SPACE_36,
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_8}`,
  },
  input: {
    color: '#9BABC6',
    fontWeight: FONTWEIGHT.MEDIUM,
  },
  copyIcon: {
    marginLeft: SPACING.SPACE_16,
    borderLeftColor: theme.borderColor,
    borderLeftWidth: 1,
  },
  actionBar: {
    display: 'flex',
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    alignItems: 'center',
    // justifyContent: 'space-between',
    justifyContent: 'space-evenly',
    marginTop: SPACING.SPACE_20,
    '& button': {
      // width: '',
      flex: 1,
      // padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
      border: '1px solid transparent',
      margin: 'auto',
    },
  },
  embedCodeContainer: {
    maxWidth: '500px',
    padding: '29px 33px',
  },
  action: {
    display: 'flex',
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: SPACING.SPACE_20,
    '& button': {
      // width: '',
      flex: 1,
      padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
      border: '1px solid transparent',
      marginLeft: SPACING.SPACE_20,
      '&:first-child': {
        margin: 0,
      },
    },
  },
  tweetBtn: {
    borderRadius: '12px',
  },
  SocialContainer: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  '@media only screen and (max-width: 786px)': {
    fbBtn: {
      height: '100px',
      width: '100px',
      marginLeft: '24px',
      padding: '11px',
    },
    embedBtn: {
      width: 50,
      height: 50,
    },
    socialButton: {
      '& > img': {
        width: '50px',
        height: '50px',
      },
    },
    fbNew: {
      '& > img': {
        height: 32,
      },
    },
  },
})

const mapStateToProps = (state) => {
  const {
    screener_share_uuid, generateScannerShareLinkSuccess, isGeneratingScannerShareLink,
    generateScannerShareLinkError, generateScannerShareLinkErrorMsg,
  } = state.scans

  const {
    screenerState,
  } = state.screener

  return {
    ...state.auth.loginResp,
    user_details: state.auth.user_details,
    generateShareLinkError: state.backtests.generateShareLinkError,
    generateShareLinkErrorMsg: state.backtests.generateShareLinkErrorMsg,
    backtest_share_uuid: state.backtests.backtest_share_uuid,
    multiShare: state.backtests.multiShare,
    generateShareLinkSuccess: state.backtests.generateShareLinkSuccess,
    shareLinkId: state.backtests.shareLinkId,
    fetchShareLinkError: state.backtests.fetchShareLinkError,
    clonedSharedSuccess: state.backtests.clonedSharedSuccess,
    clonedSharedError: state.backtests.clonedSharedError,
    clonedSharedErrorMsg: state.backtests.clonedSharedErrorMsg,
    isGeneratingShareLink: state.backtests.isGeneratingShareLink,
    isFetchingShareableLink: state.backtests.isFetchingShareableLink,
    backtestDetails: state.backtests.backtestDetails,

    screener_share_uuid,
    generateScannerShareLinkSuccess,
    isGeneratingScannerShareLink,
    generateScannerShareLinkError,
    generateScannerShareLinkErrorMsg,

    screenerState,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getShareLink: (params, headers) => dispatch(fetchShareLink(params, headers)),
    createShareLink: (params, headers) => dispatch(generateShareLink(params, headers)),
    getBacktestDetails: params => dispatch(fetchBacktestDetails(params)),
    createScannerShareLink: (params, headers) => dispatch(
      generateScannerShareLink(params, headers),
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(SharePopUp))
