/* eslint-disable import/no-cycle */
import React from 'react'
import classnames from 'classnames'

import ToggleButton from '../../UI/ToggleButton'
import CreateProParams from '../EditAlgoParams/CreateProParams'
import CustomModal from '../../UI/CustomModal'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import CustomInput from '../../UI/CustomInput'
import Button from '../../UI/Button'

import { withTheme } from '../../Theme/ThemeProvider'
import {
  CONDITION_TYPE,
} from '../../containers/Create/Create-utils'
import {
  // algoNameRegex,
  candleIntervals,
  MAX_ALLOCATION_OBJ,
  postionBtnMap,
  qtyRegex,
} from '../../utils/consts'
import {
  COLORS,
  ICONS, SPACING, theme,
} from '../../Theme'
import { getColor } from '../../UI/Steps'
import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import { getCandleIntervalsMap } from '../../utils/common'

// 1DJZDxXP

const labelProps = {
  weight: 'medium',
  size: 'small',
}

const renderStep = ({
  isActive, isCompleted, hasError, styles, tickStyles = '',
}) => {
  if (isCompleted && !isActive) {
    return (
      <Icon
        name={ICONS.TICK_ROUND_FILLED}
        color={theme.linkColor}
        className={`${styles.stepIcon} ${tickStyles}`}
      />
    )
  }
  return (
    <div
      className={`${styles.stepBtn}  ${isActive ? styles.stepActive : ''}`}
      style={{
        backgroundColor: getColor({
          field: 'btnBg', activeColor: theme.linkColor, isActive, hasError,
        }),
        borderColor: getColor({
          field: 'btnBorder', activeColor: theme.stepsCircleBorder, isActive, hasError,
        }),
      }}
    >
      <CustomText
        size="small"
        color={getColor({
          field: 'label', isActive, activeColor: 'textSecondary', hasError,
        })}
      >
        {' '}
      </CustomText>
    </div>

  )
}

const CreateRow = React.forwardRef((props, ref) => {
  const {
    children, styles, isActive, hasError, isCompleted, isLast = false,
    isFirst, stepContainerStyles, rowContentStyles,
  } = props
  return (
    <div className={styles.row} ref={ref}>
      <div className={styles.rowStep}>
        <div
          className={`${styles.stepContainer} ${stepContainerStyles}`}
        >
          {renderStep({
            isActive, isCompleted, hasError, styles,
          })}
        </div>
        <div className={`${styles.stepLine}
          ${isLast ? styles.stepLastLine : ''}
          ${isFirst ? styles.stepFirstLine : ''}`}
        />
      </div>
      <div className={`${styles.rowContent} ${rowContentStyles}`}>
        {children}
      </div>
    </div>
  )
})

function CreateSingle(props) {
  const {
    user_details, toggleAuthGreeting,
    styles, isDark, renderContent,
    scripList, positionType, showError, algo_name,
    scripValid, conditionEditType, algoNameErr,
    algoNameValid, entryValid, exitValid, quantity, max_allocation, max_quantity,
    togglePositions, onSaveNRun, handleAlgoNameChange,
    entryRef,
    exitRef,
    paramsRef,
    positionRef,
    algoNameRef,
    quantityRef,
    onEditParams, dt_start, dt_stop, holding_type,
    daily_strategy_cycle, position_sizing_type, candleInterval, chart_type,
    trading_start_time, trading_stop_time, initial_capital, // tpsl_type,
    toggleParamsModal, initialFetch, handleCandleChange, createAdvance,
    onInputChange, isMultiPage,
    isDeployingAlgo, isFetchingState,
  } = props
  const postionBtn = postionBtnMap(isDark)
  const isBtnDisabled = isDeployingAlgo || isFetchingState
  // (!algoNameValid || !entryValid || !exitValid || scripList.length === 0)
  const candleIntervalsTabs = getCandleIntervalsMap(candleIntervals, candleIntervals)
  const time_frame = candleIntervals[candleInterval] || candleInterval
  const isQtyDisabled = max_allocation.length > 0
  const qtyFieldStyle = isQtyDisabled ? styles.qtyDisabled : styles.qtyInput
  const isParamValid = quantity || (!quantity && Boolean(max_allocation))

  const tabsProps = {
    tabColor: isDark ? 'VOILET_600' : 'VOILET',
    activeTabColor: 'WHITE',
  }

  const renderParamsCell = (label, value, showParams = true) => {
    if (!showParams) return null
    let itemValue
    if(value) {
      itemValue = value
      if(value.length > 15) {
        itemValue = `${itemValue.slice(0, 15)}...`
      }
    }else{
      itemValue = '-'
    }
    return (
      <div className={styles.paramsCell}>
        <CustomText weight="semi_bold">{label}</CustomText>
        <CustomText size="small" color="textSecondary">{itemValue}</CustomText>
      </div>
    )
  }

  const onEditCondition = () => { }

  const renderBasicParams = () => {
    return (
      <div className={styles.paramsRow}>
        <div className={styles.field}>
          <CustomText weight="medium" className={styles.inputLabel}>
            Position I would take
          </CustomText>
          <ToggleButton
            selected={positionType.toLowerCase()}
            btnMap={postionBtn}
            toggleHandler={togglePositions}
            containerStyles={styles.positions}
            isDark={isDark}
            btnWidth={60}
          />
        </div>
        <div className={styles.field}>
          <CustomText weight="medium" className={styles.inputLabel}>Candle Interval</CustomText>
          <CustomHorizontalTab
            tabs={candleIntervalsTabs}
            defaultActiveTab={time_frame}
            tabChangeHandler={handleCandleChange}
            style={classnames(styles.tabContainer, styles.candleTabContainer)}
            tabStyles={classnames(styles.tab, styles.candleTab)}
            dragBtnStyles={styles.tabActive}
            {...tabsProps}
          />
        </div>
        <div className={styles.field}>
          <CustomText
            {...labelProps}
            className={styles.inputLabel}
          >
            Quantity ( in lots )
          </CustomText>

          <CustomInput
            value={quantity}
            inputError={!quantity}
            placeholder="Qty in lots (1 = 1 lot)"
            onChangeText={onInputChange}
            inputStyles={`${styles.fieldInput} ${qtyFieldStyle}`}
            containerStyles={styles.fieldInputContainer}
            inputKey="quantity"
            inputRegx={new RegExp(qtyRegex)}
            disabled={isQtyDisabled}
          />
        </div>
      </div>
    )
  }

  const renderParams = (disableActions) => {
    const {
      label: maxAllocationLabel,
    } = MAX_ALLOCATION_OBJ(position_sizing_type)
    const isQty = isParamValid
    if (!initialFetch) {
      return (
        <div className={styles.paramsContainer}>
          <CustomModal
            visible={CONDITION_TYPE.REVIEW === conditionEditType}
            onDismiss={toggleParamsModal}
            dismissKey={CONDITION_TYPE.PARAMS}
            containerStyles={styles.paramsModal}
            hideScroll
          >
            <CreateProParams
              {...props}
              onEditParams={onEditParams}
              create_plus={createAdvance}
              user_details={user_details}
              containerStyles={styles.paramsContainer}
              disabled={disableActions}
              isDark={isDark}
              onEditCondition={onEditCondition}
              toggleAuthGreeting={toggleAuthGreeting}
              isMultiPage={isMultiPage}
            />
          </CustomModal>
          <div className={styles.paramsView}>
            <div className={styles.paramsHeader}>
              <div className={styles.paramsHeaderLeft}>
                <Icon name={ICONS.PARAMETERS} color={theme.voilet} size={12} />
                <CustomText weight="semi_bold" className={styles.paramsTitle}>Backtest Parameters</CustomText>
                <button type="button" onClick={() => toggleParamsModal()}>
                  <Icon name={ICONS.EDIT} color={theme.linkColor} size={12} />
                </button>
              </div>
              <div className={styles.paramsHeaderRight}>
                <CustomText size="small">{isQty ? 'Completed' : 'Error'}</CustomText>
                {isQty ? (
                  <Icon name={ICONS.TICK_ROUND_FILLED} color={theme.green} size={12} />
                ) : (
                  <Icon name={ICONS.INFO} color={theme.red} size={12} />
                )}
              </div>
            </div>
            <div className={styles.paramsContent}>
              {/* {renderParamsCell('Candle interval', candleInterval)} */}
              {renderParamsCell('Chart type', chart_type)}
              {/* {renderParamsCell('Quantity', quantity)} */}
              {renderParamsCell('Holding type', holding_type)}

              {renderParamsCell(maxAllocationLabel, max_allocation, createAdvance)}
              {renderParamsCell('Max Quantity', max_quantity, createAdvance)}
              {renderParamsCell('Position sizing type', position_sizing_type, createAdvance)}

              {renderParamsCell('Entry start time', trading_start_time)}
              {renderParamsCell('Entry stop time', trading_stop_time)}

              {renderParamsCell('Initial Capital', initial_capital)}
              {renderParamsCell('Daily strategy cycle', daily_strategy_cycle, createAdvance)}
              {renderParamsCell('Start date', dt_start)}
              {renderParamsCell('Stop date', dt_stop)}

              {/* {renderParamsCell('TPSL type', tpsl_type)} */}
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div className={styles.createProContainer}>
      <div className={styles.proContent}>
        <CreateRow
          styles={styles}
          isActive={conditionEditType === CONDITION_TYPE.POSITION}
          isFirst
          ref={positionRef}
          isCompleted={scripValid && scripList.length > 0}
          hasError={!scripValid && showError}
          rowContentStyles={styles.rowContentStyle}
        >
          {renderContent(false)}
        </CreateRow>
        <CreateRow
          styles={styles}
          isActive={false}
          isCompleted={isParamValid}
          hasError={!isParamValid && showError}
          ref={quantityRef}
        >
          {renderBasicParams()}
        </CreateRow>

        <CreateRow
          styles={styles}
          isActive={conditionEditType === CONDITION_TYPE.ENTRY}
          isCompleted={entryValid}
          hasError={!entryValid && showError}
          ref={entryRef}
        >
          {renderContent(false, CONDITION_TYPE.ENTRY)}
        </CreateRow>
        <CreateRow
          styles={styles}
          isActive={conditionEditType === CONDITION_TYPE.EXIT}
          isCompleted={exitValid}
          hasError={!exitValid && showError}
          ref={exitRef}
        >
          {renderContent(false, CONDITION_TYPE.EXIT)}
        </CreateRow>
        <CreateRow
          styles={styles}
          isActive={conditionEditType === CONDITION_TYPE.REVIEW}
          isCompleted={isParamValid}
          hasError={!isParamValid && showError}
          stepContainerStyles={styles.paramsStepContainer}
          ref={paramsRef}
        >
          {renderParams()}
        </CreateRow>
        <CreateRow
          styles={styles}
          isActive={conditionEditType === CONDITION_TYPE.ALGO_NAME}
          isCompleted={algoNameValid}
          hasError={algoNameErr}
          stepContainerStyles={styles.nameStepContainer}
          ref={algoNameRef}
        >
          <CustomInput
            placeholder="Save strategy as"
            inputKey="algo_name"
            inputError={algoNameErr}
            value={algo_name}
            inputRegx={/^[A-Za-z0-9- ]+$/gi}
            onChangeText={handleAlgoNameChange}
            inputStyles={styles.fieldInput}
            containerStyles={styles.fieldInputContainer}
            wrapperStyles={styles.inputWrapper}
          />
        </CreateRow>
        <CreateRow
          styles={styles}
          isActive={false}
          isCompleted={algoNameValid && entryValid && exitValid && scripValid}
          hasError={false}
          isLast
        >
          <Button
            text="Save and Backtest"
            onPress={onSaveNRun}
            buttonColor="blue"
            disabled={isBtnDisabled}
            loading={isBtnDisabled}
            btnStyles={`${styles.saveBtn} ${isBtnDisabled ? styles.saveBtnDisable : ''}`}
          />
        </CreateRow>
      </div>
    </div>
  )
}

const stylesheet = {
  createProContainer: {},
  headerStyle: {
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_32} 0`,
  },
  headerActionBar: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionTabContainer: {
    minWidth: 200,
    marginRight: SPACING.SPACE_20,
  },
  proContent: {},
  row: {
    display: 'flex',
  },
  rowStep: {
    flex: 0.05,
    minWidth: 80,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: SPACING.SPACE_22,
  },
  stepLine: {
    width: SPACING.SPACE_8,
    backgroundColor: theme.orange,
    height: '100%',
    position: 'absolute',
    top: 0,
  },
  stepLastLine: {
    height: 30,
  },
  stepFirstLine: {
    top: 'unset',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  stepDot: {},
  rowContent: {
    flex: 1,
    padding: `${SPACING.SPACE_20} 0`,
  },
  rowContentStyle: {
    width: '90%',
  },
  paramsCell: {
    display: 'inline-block',
    width: '100%',
    maxWidth: 200,
    minWidth: 100,
    marginBottom: SPACING.SPACE_10,
  },
  paramsContainer: {
    padding: SPACING.SPACE_20,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    background: theme.bgPrimary,
    borderRadius: SPACING.SPACE_10,
  },
  paramsTitle: {
    padding: `0 ${SPACING.SPACE_10} 0 ${SPACING.SPACE_6}`,
  },
  paramsView: {},
  paramsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} 0`,
  },
  paramsHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  paramsHeaderRight: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginRight: SPACING.SPACE_6,
    },
  },
  paramsContent: {
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  conditionContainer: {
    margin: 0,
  },
  stepContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    maxHeight: 20,
    borderRadius: '50%',
    zIndex: 2,
    maxWidth: 20,
  },
  activeStepValue: {
    backgroundColor: '#fff',
    height: '50%',
    width: '50%',
    borderRadius: '50%',
    maxHeight: 10,
    maxWidth: 10,
  },
  stepBtn: {
    width: '100%',
    borderRadius: '50%',
    border: `5px solid ${theme.stepsCircleBorder}`,
  },
  paramsStepContainer: {
    marginTop: 26,
  },
  nameStepContainer: {
    marginTop: SPACING.SPACE_8,
  },
  symbolContainer: {
    marginTop: 0,
  },
  stepIcon: {
    height: '100%',
    width: '100%',
    maxHeight: 20,
    zIndex: 2,
    maxWidth: 20,
    backgroundColor: theme.bgPrimary,
  },
  actionBar: {
    margin: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_30}`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  positions: {
    // marginTop: SPACING.SPACE_20,
  },
  saveBtn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_24}`,
  },
  saveBtnDisable: {
    backgroundColor: theme.disabled,
    borderColor: theme.disabled,
  },
  paramsModal: {
    maxWidth: 815,
    paddingTop: 0,
  },
  alertContainer: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  alertContent: {
    margin: ' auto',
    width: '100%',
    maxWidth: 200,
  },
  alertTitle: {
    margin: `${SPACING.SPACE_10} auto ${SPACING.SPACE_4}`,
    width: '90%',
  },
  alertActionBar: {
    width: '90%',
    margin: `${SPACING.SPACE_20} auto 0`,
  },
  alertImg: {
    maxHeight: 180,
  },
  alertModal: {
    maxWidth: 300,
  },
  alertActiveBtn: {
    margin: `${SPACING.SPACE_32} auto ${SPACING.SPACE_10}`,
  },
  paramsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabContainer: {
    backgroundColor: theme.createTabsBg,
    padding: '0 ',
    // display: 'inline-flex',
    // minWidth: '115px',
  },
  candleTabContainer: {
    display: 'inline-flex',
  },
  tab: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_12}`,
  },
  candleTab: {
  },
  tabActive: {
    backgroundColor: COLORS.VOILET,
  },
  inputLabel: {
    marginBottom: SPACING.SPACE_12,
  },
  fieldInput: {
    borderColor: 'transparent !important',
  },
  fieldInputContainer: {
    // maxWidth: 120,
  },
  qtyDisabled: {
    borderColor: `${theme.createTabsBg} !important`,
    backgroundColor: `${theme.btnDisabled} !important`,
    color: theme.textSecondary,
  },
  qtyInput: {
    borderColor: `${theme.createTabsBg} !important`,
    backgroundColor: `${theme.createTabsBg} !important`,
  },
  '@media only screen and (max-width: 786px)': {
    sectionTabContainer: {
      marginRight: 0,
      minWidth: 'unset',
    },
    paramsRow: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: SPACING.SPACE_20,
    },
    tabContainer: {
      flexWrap: 'wrap',
    },
    rowContentStyle: {
      width: '75%',
    },
  },
}

export default withTheme(stylesheet)(CreateSingle)
