import React, { useEffect } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import CustomText from '../../UI/CustomText'
import ViewAllCard from '../../UI/ViewAllCard'

import DISOVER_CATEGORY_DATA from './DetailView-utils'
import { DISOVER_CATEGORY_ICON_MAP } from '../AlgoCondensedView/AlgoUtils'
import { fetchDiscoverCategories } from '../../containers/MarketPlace/actions'
import { pathname_mapping } from '../../utils/navigationConstant'
import { theme, SPACING, ASSETS } from '../../Theme'
import { parseQuery } from '../../utils/common'

const { QL_DEPLOYED, QL_ORDERBOOK, QL_PORTFOLIO } = ASSETS
const QUICK_LINKS_MAP = [
  {
    id: 'DEPLOYED', title: 'Deployed', imgSrc: QL_DEPLOYED, to: pathname_mapping.Deployed, filterKey: 'live',
  },
  {
    id: 'ORDERBOOK', title: 'Orderbook', imgSrc: QL_ORDERBOOK, to: pathname_mapping.pending_orders, filterKey: 'pending',
  },
  {
    id: 'PORTFOLIO', title: 'Portfolio', imgSrc: QL_PORTFOLIO, to: pathname_mapping.Portfolio, filterKey: 'positions',
  },
]

const renderRow = (filter_tag, styles, onPress, discoverColorMap) => {
  const filterTag = filter_tag ? filter_tag.toUpperCase() : ''
  const {
    tag_name, tag_short_desc,
  } = filter_tag ? DISOVER_CATEGORY_DATA[filterTag] : {}
  return (
    <button type="button" onClick={() => onPress(filter_tag)} className={styles.row} key={filter_tag}>
      <div className={styles.imgContainer}>
        <img src={discoverColorMap[filterTag] ? discoverColorMap[filterTag].IMG : ''} alt={filter_tag} />
      </div>
      <div className={styles.info}>
        <CustomText weight="medium">{tag_name}</CustomText>
        <CustomText size="small" color="textSecondary">{tag_short_desc}</CustomText>
      </div>
    </button>
  )
}

const AlgoCategorySideBar = ({
  categoryList,
  getDiscoverCategories,
  isDark,
  isLoggedIn,
  updateGA,
}) => {
  const history = useHistory()
  const location = useLocation()
  const styles = stylesheet()
  const discoverColorMap = DISOVER_CATEGORY_ICON_MAP(isDark)
  const { tab } = parseQuery(location.search)
  const title = isLoggedIn ? 'Discover' : 'Discover More Strategies'
  const FILTERED_QL = QUICK_LINKS_MAP.filter(ql => ql.filterKey !== tab)

  useEffect(() => {
    if (categoryList.length === 0) {
      getDiscoverCategories()
    }
  }, [])

  const onPress = (category) => {
    updateGA('Strategy categories', 'Strategy categories sidepanel')
    history.push({
      pathname: `${pathname_mapping.Discover}`,
      search: `?filter=${category}&tab=explore`,
    })
  }
  const onViewAll = () => {
    updateGA('View all', 'Strategy categories sidepanel')
    history.push({
      pathname: pathname_mapping.Explore,
    })
  }

  const onPressQuickLink = (link) => {
    history.push(link)
  }

  return (
    <div className={classnames(styles.container, 'hideScrollbar')}>
      <ViewAllCard
        title={title}
        cardStyles={classnames(styles.cardContainer)}
        onPress={onViewAll}
        headerStyles={classnames(styles.header)}
        showViewAll={categoryList.length > 5 && isLoggedIn}
      >
        {categoryList.map(item => renderRow(item.tag_name, styles, onPress, discoverColorMap))}
      </ViewAllCard>
      {isLoggedIn && (
      <div className={styles.quickLinksContainer}>
        <CustomText size="medium" weight="semi_bold">Quick links</CustomText>
        <div className={styles.quickLinksRow}>
          {FILTERED_QL.map((item) => {
            return (
              <button key={`quickLink-${item.id}`} type="button" onClick={() => onPressQuickLink(item.to)}>
                <div className={styles.quickLink}>
                  <img src={item.imgSrc} alt={item.title} className={styles.quickLinkIcon} />
                  <CustomText>{item.title}</CustomText>
                </div>
              </button>
            )
          })}
        </div>
      </div>
      )}
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    height: 'calc(100vh - 80px)',
    overflow: 'auto',
    position: 'relative',
  },
  cardContainer: {
    boxShadow: 'none !important',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: SPACING.SPACE_10,
    '& > img': {
      width: 30,
    },
  },
  info: {
    flex: 1,
    textAlign: 'left',
  },
  header: {},
  quickLinksContainer: {
    margin: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
  quickLinksRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: `${SPACING.SPACE_10} 0`,
  },
  quickLinkIcon: {
    width: 40,
    margin: `${SPACING.SPACE_10} 0`,
  },
})

const mapStateToProps = (state) => {
  return {
    categoryList: state.marketplace.tagsDescription,
    isLoggedIn: state.auth.isLoggedIn,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDiscoverCategories: () => dispatch(fetchDiscoverCategories()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlgoCategorySideBar)
