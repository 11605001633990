export default {
  // key is what we are using the icon in code for and value descibes what the icon is
  // TABS
  TAB_SCANNER: 'tab_create',
  TAB_STRATEGY: 'tab_add',
  TAB_PROFILE: 'tab_profile',
  TAB_DASHBOARD: 'tab_home',
  TAB_MARKET: 'tab_market',

  ADD: 'add',
  ADD_ROUND_OUTLINED: 'add_rounded_outlined',
  ADD_ROUND_FILLED: 'add_rounded_filled',
  ADD_RECT_OUTLINED: 'add_rect_outlined',
  DELETE: 'bin',
  EDIT: 'pencil',
  BACK: 'backward_head',
  FORWARD: 'forward_head',
  UP: 'up_head',
  DOWN: 'down_head',
  UP_HEAD_FILLED: 'up_head_filled', //
  DOWN_HEAD_FILLED: 'down_head_filled',
  FACEBOOKICON: 'facebookIcon',
  // ARROW_HEAD_FILLED: 'arrow_head_filled',
  UP_ARROW: 'up_arrow', //
  // UP_ARROW_ROUND_FILLED: 'up_arrow_round_filled',
  DOWN_ARROW: 'down_arrow',
  // DOWN_ARROW_ROUND_FILLED: 'down_arrow_round_filled',
  TICK_ROUND_FILLED: 'tick_round_filled',
  REFRESH: 'circular_arrow',
  // RADIO_SELECTED: 'radio_selected',
  ARROW_DOWN_FILLED: 'arrow_down_filled',
  ARROW_BACK: 'arrow_back',
  CLOSE: 'cross',
  // TICK_SQUARE_FILLED: 'tick_square_filled',
  // HORIZONTAL_LINE: 'horizontal_line',
  // SQUARE_OUTLINED: 'square_outlined',
  // RECTANGLE_FILLED: 'rectangle_filled',
  CIRCLE_FILLED: 'circle_filled',
  CIRCLE_OUTLINED: 'circle_outlined',
  // ARROW_UP_W_LINE: 'arrow_up_w_line',
  ARROW_DOWN_W_LINE: 'arrow_down_w_line',
  // ARROW_BACK_W_LINE: 'arrow_back_w_line',
  ARROW_FORWARD_W_LINE: 'arrow_forward_w_line',

  TICK: 'tick',
  // MESSAGE_FILLED: 'message_filled',
  // LOCK_FILLED: 'lock_filled',
  // KEY_OUTLINED_FILLED: 'key_outlined_filled',
  // VIDEO_ROUND_FILLED: 'video_round_filled',
  HELP: 'help',
  HELP_CIRCLE: 'help_circle',
  FEEDBACK: 'feedback',
  HELP_OUTLINED: 'help_outlined',
  TUTORIALS: 'cap',
  // TICKET: 'ticket',
  MOON_CRESCENT_OUTLINED_FILLED: 'moon_crescent_outlined_filled',
  SUN: 'sun',
  MOON_CRESCENT_FILLED: 'moon_crescent_filled',
  MOON_CRESCENT: 'moon_crescent',
  CLOSE_FILLED: 'cross_filled',
  DYNAMIC_CONTRACT: 'paper_pen',
  // OFFER: 'percent_round_filled',
  // SORT: 'up_down_head_filled',

  CREATE_ADVANCE: '3d_box',
  CREATE_STRATEGY: 'bulb',
  CREATE_BASKET: 'basket',
  CONVERT_INTO_STRATEGY: 'ring_square_loop',

  // // -------- ORDERBOOK TAGS ICONS ----------
  OPEN: 'circle_filled_outlined',
  CANCEL: 'cancel_filled',
  REJECTED: 'rejected_filled',
  TRIGGER_PENDING: 'clock_filled_outlined',
  // // -------- ORDERBOOK TAGS ICONS ----------

  BULLISH: 'bullish_head',
  BEARISH: 'bearish',
  NEUTRAL: 'neutral',

  CHART_CANDLESTICK: 'candlestick',
  CHART_HEIKIN_ASHI: 'heikin_ashi',
  CHART_RENKO: 'renko',
  CHART_KITE: 'zigzag_line',

  POSITION: 'flag_round_filled',
  ENTRY: 'entry_rounded_filled',
  EXIT: 'exit_rounded_filled',
  PARAMETERS: 'parameters',

  LIST_VIEW: 'two_horizontal_line',
  GRID_VIEW: 'four_square_filled',
  THREE_DOTS_HORIZONTAL: 'meatball',
  THREE_DOTS_VERTICAL: 'kebab',

  LIKE: 'thumbs_up',
  DISLIKE: 'thumbs_down',
  SEARCH: 'search',
  CALENDAR: 'calendar',
  // MINUS: 'minus',
  VIEW: 'eye',
  VIEW_CUT: 'eye_line_through',
  COPY: 'copy',
  SHARE: 'share',
  SHARE2: 'share2',
  LINK: 'link',
  WARN: 'exclamation_round_filled',
  EMBED_CODE: 'embed_code',
  EMBED: 'embed',
  DOWNLOAD_OUTLINED: 'download_outlined',
  DOWNLOAD_CLOUD: 'download_cloud',
  SCAN_ROUND_FILLED: 'scan_round_filled',
  SCAN: 'scan',
  IMAGE: 'image',
  // EARTH_W_CLOCK: 'earth_w_clock',
  COPY_FILLED: 'copy_filled',
  PASTE_FILLED: 'paste_filled',
  // FILTER: 'funnel',
  FILTER: 'filter',
  SETTING_OUTLINED: 'setting_outlined',
  // TWO_GEAR: 'two_gear',
  SETTING: 'setting',
  // CALENDER: 'calender',
  // SPARKLINE: 'sparkline',
  REWARDS: 'rewards',
  UN_FAVOURITE: 'star_outlined',
  FAVOURITE: 'star_filled',
  // CAMERA_ROUND_FILLED: 'camera_round_filled',
  // SPEEDOMETER: 'speedometer',
  // LOCK: 'lock',
  CLOCK: 'clock',
  COMMODITY: 'commodity_round_filled',
  EQUITY: 'equity_round_filled',
  EMAIL: 'mail',
  PHONE: 'phone',
  // ID_CARD: 'id_card',
  UNSUBSCRIBE: 'cross_w_pointer',
  // CAMERA: 'camera',
  PRICING: 'pricing',
  PROFILE: 'profile',
  BILLING: 'billing',
  CUMULATIVE: 'bars_with_arrow',
  CUMULATIVE_ICON: 'cumulative_icon',
  DRAG_DROP: 'dots_rectangle',
  DISABLE_CONDITION: 'rounded_rectangle_cut',
  ENABLE_CONDITION: 'rounded_rectangle',
  SIDE_MENU: 'hamburger',

  // // NOTIFICATION
  // NOTIF_DEFAULT: 'robot',
  NOTIF_AUTO: 'wifi',
  NOTIF_PAPER: 'paper',
  NOTIF_SCANNER: 'scan', // 'scanner_duo',
  NOTIF_DASHBOARD: 'bell_outlined',
  DETAILS_LIVE: 'live_trade_details',
  DETAILS_PAPER: 'paper_trade_details',
  // PAPER: 'paper',
  // NOTIFICATION: 'notification',
  // AUTO: 'robot',
  // AUTO_FILLED: 'robot_round_filled',

  PARTIAL_1: 'arrow_zigzag',
  PARTIAL_2: 'star_outlined',
  PARTIAL_3: 'rocket_tilted_outlined',

  CART: 'cart_round_filled',
  BASKET: 'basket_round_filled',
  // SELL: 'sell',
  ALERT: 'bell',
  ALERT_TILTED: 'bell_tilted',
  ALERT_ROUND_FILLED: 'bell_round_filled',
  WAITING: 'clock_filled',
  CANCELLED: 'no_action_round_filled',
  ORDER_PLACED: 'order_placed',
  USER_ACTION: 'pointer_round_filled',
  STOP: 'stop_round_filled',
  TIME: 'time_rounded_filled', // 'WAITING'
  REDEPLOY: 'rocket_tilted',
  STRATEGY_DETAILS: 'three_cards',
  ORDER_LOG: 'arrow_left_w_square',
  DOT: 'circle_filled',
  INFO: 'i_circle_outlined',
  // DOWNLOAD: 'download',

  // // Orderlog icons
  EXPIRED: 'clock_expired_filled_outlined',
  AT_EXCHANGE: 'double_arrow_horizontal_filled',
  SLM_ORDER: 'parachute_round_filled',
  CLOSE_FILLED_OUTLINED: 'cross_filled_outlined',
  ENTERED: 'right_arrow_w_line_round_filled',
  PAPER_FILLED: 'paper_round_filled',
  SCANNER_ALERT: 'radar_round_filled',

  // LETTER_B: 'letter_b',
  // LETTER_N: 'letter_n',
  // LETTER_S: 'letter_s',

  // Discover Scanner Category icons
  SCANNER_BULLISH: 'bullish_head_filled',
  SCANNER_BEARISH: 'bearish_head_filled',
  SCANNER_CROSSOVER: 'crossover',
  SCANNER_INTRADAY_BULLISH: 'bullish_head_filled_outlined',
  SCANNER_INTRADAY_BEARISH: 'bearish_head_filled_outlined',
  SCANNER_RANGE_BREAKOUT: 'range_breakout',
  SCANNER_TOP_GAINERS: 'top_gainers',
  SCANNER_TOP_LOSERS: 'top_losers',
  YOUTUBE_FILLED: 'youtube_filled',
  SCANNER_CANDLE_PATTERNS: 'candlestick_filled',

  // // Discover Strategy Category icons
  ALL: 'all',
  MOMENTUM: 'momentum',
  MISCELLANEOUS: 'miscellaneous',
  OVERLAYS: 'overlays',
  TREND_FOLLOWING: 'trend_following',
  PRICE_ACTION: 'price_action',
  MEAN_REVERSION: 'mean_reversion',
  PIVOT_POINTS: 'pivot_points',
  OPTIONS_STRATEGIES: 'options_startegies',
  INTRADAY: 'intraday',
  SWING_TRADING: 'swing_trading',
  POSITION_TRADING: 'position_trading',
  THE_MILLENNIALS: 'the_millennials',
  TECH: 'tech',
  LEADING: 'leading',
  ATR: 'atr',
  MTF: 'mtf',

  // // TO Decide name
  DEPLOYED: 'rocket_outlined',
  PIN: 'pin',
  fb: 'facebookIcon',
  // CONVERT: 'rocket_outlined',
  // BASKET_OUTLINED: 'rocket_outlined',
  // CREATE: 'rocket_outlined',

  // DOWNLOAD_CLOUD: 'download_cloud',
  UPLOAD: 'upload',
  EXTERNAL_LINK: 'external_link',
  LOGOUT: 'logout',
  CREDIT_CARD: 'credit_card',
  SWITCH: 'switch',
  ANIMATION: 'animation',
  SORT: 'sort',

  GIFT: 'gift',
  TROPHY: 'trophy',
  COIN: 'coin',

  SINGLE_PAGE_VIEW: 'single_page_view',
  MULTI_PAGE_VIEW: 'multi_page_view',
  APP_VERSION: 'app_version',

  FEATURE_SCANNER: 'feature_scanner',
  FEATURE_BACKTEST: 'feature_backtest',
  FEATURE_DEPLOY: 'feature_deploy',
  FEATURE_TECHNICALS: 'feature_technicals',

  ACADEMY: 'academy',
  FAQ: 'faq',
  HELP_SUPPORT: 'help_support',

  PT_DEPLOY: 'pt_deploy',
  PT_BACKTEST: 'pt_backtest',
  PT_SCANNERS: 'pt_scanners',
  PT_TECHNICALS: 'pt_technicals',
  PT_NOIDEA: 'pt_noidea',
  PT_CHECK: 'pt_check',
  PT_VIDEO: 'pt_video',
  PT_SKIP_NEXT: 'pt_skip_next',
  // // Crypto
  // XTZ: 'XTZ',
  // REP: 'REP',
  // XBT: 'BTC',
  // BTC: 'BTC',
  // DASH: 'DASH',
  // EOS: 'EOS',
  // ETH: 'ETH',
  // LTC: 'LTC',
  // TRX: 'TRX',
  // USD: 'USD',
  // USDC: 'USDC',
  // USDT: 'USDT',
  // XLM: 'XLM',
  // XMR: 'XMR',
  // XRP: 'XRP',
  // ZB: 'ZB',

  // social
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  YOUTUBE: 'youtube',
  TELEGRAM: 'telegram',
  PLAYSTORE: 'playstore',
  APPSTORE: 'appstore',
  WHATSAAP: 'whatsapp',

  TOUR: 'tour',
  CROSS_FT: 'cross_FT',

  SIDEBAR_HOLDINGS_ICON: 'SidebarHoldingIcon',
  SIDEBAR_POSITIONS_ICON: 'SidebarPositionIcon',
  SIDEBAR_STRATEGY: 'sidebarStrategy',
  SIDEBAR_SCANNER: 'sidebarScanner',

  BT_NUDGE: 'bt_nudge',
  BT_NUDGE_BLACK: 'bt_nudge_black',
  BT_DETS_NUDGE: 'bt_dets_nudge',
  MTF_PREFERENCE: 'new_mtf',

  SHAREABLE_LINKS: 'shareable_link',
  DISCOVER: 'discover',
  FILTER_SETTINGS: 'filter_settings',
  WIFI: 'wifi',

  FEATURESCANNERS: 'featureScanners',
  FEATURESTRATEGIES: 'featureStrategies',
  FEATUREPAPER: 'featurePaper',
  FEATURELIVE: 'featureLive',
  FEATURETECHNICAL: 'featureTechnicals',
}
