import React from 'react'
import moment from 'moment'
import { baseChartUrl } from '../../utils/apiEndpoints'
import { date_range_epochs, algoDateFormat } from '../../utils/consts'
import { ICONS, SPACING, theme } from '../../Theme'
import Icon from '../../UI/Icon'
import { withTheme } from '../../Theme/ThemeProvider'
// backtestResult.backtest_results[0].backtest_result.NYSE_LMT.trade_log
const STREAK_PLATFORM = {
  INDIA: 'INDIA', // tech
  WORLD: 'WORLD',
}
// chart mode with key as "mode" is required* parameter for any chart to get rendered
// it is sent in get params
export const CHART_MODES = {
  LINE: 'LINE', // render line chart without sending chart data
  LINE_WITH_DATA: 'LINE_WITH_DATA', // render line chart with sending chart data
  CANDLE: 'CANDLE', // render candle chart without sending chart data
  CANDLE_WITH_LOG: 'CANDLE_WITH_LOG', // render candle chart with chart data & tradeLog
}

const TvWidget = React.memo(({
  tradeLogChart, item, csrf, sessionid, height = '100%', time_frame = '3min', algo_uuid, publishing_uuid, dt_stop, dt_start, tradeLogData = [], isDark, styles,
  shared, fullView, algo_name,
}) => {
  let uri = ''
  let segment_symbol
  if(fullView) {
    segment_symbol = encodeURIComponent(item)
  } else {
    const { seg_sym } = item
    segment_symbol = encodeURIComponent(seg_sym)
  }
  const chartTheme = isDark ? 'dark' : ''
  const { seg_sym } = item
  const candleInterval = date_range_epochs[time_frame] || 7689600
  const algo_id = publishing_uuid || algo_uuid
  let isDiscover = false
  if(publishing_uuid) {
    isDiscover = true
  }
  const endTime = dt_stop ? moment(dt_stop, algoDateFormat).valueOf() / 1000
    : Number(((new Date().getTime() / 1000)).toFixed(0))
  const startTime = dt_start ? moment(dt_start, algoDateFormat).valueOf() / 1000
    : Number(((new Date().getTime() / 1000) - candleInterval).toFixed(0))
  const commonParams = `&platform=${STREAK_PLATFORM.INDIA}&csrf=${csrf}&sessionid=${sessionid}&seg_sym=${segment_symbol}&startTime=${startTime}&endTime=${endTime}&timeFrame=${time_frame}&algo_uuid=${algo_id}&isDiscover=${isDiscover}&theme=${chartTheme}&shared=${shared}`
  if (tradeLogChart) {
    uri = `${baseChartUrl}lite?mode=${CHART_MODES.CANDLE_WITH_LOG}${commonParams}`
  } else if (tradeLogData.length) {
    uri = `${baseChartUrl}lite?mode=${CHART_MODES.LINE_WITH_DATA}${commonParams}`
  } else {
    uri = `${baseChartUrl}lite?mode=${CHART_MODES.LINE}${commonParams}`
  }

  const maximizeChart = () => {
    window.open(`/graph?seg_sym=${encodeURIComponent(seg_sym)}&algo_name=${algo_name}&startTime=${dt_start}&endTime=${dt_stop}&timeFrame=${time_frame}&algo_uuid=${algo_uuid}&isDiscover=${isDiscover}&publishing_uuid=${publishing_uuid}&theme=${theme}&shared=${shared}`, '_blank')
  }

  // line Chart without any Data being sent ( For MarketWatch )
  return (
    <div className={styles.tvWidgetContainer} id="tvWidgetContainer">
      <iframe className={styles.tvChartIframe} frameBorder="0" width="100%" height={height} src={uri} title="Trade Chart" />
      {!fullView && (
      <button
        type="button"
        className={styles.maximizeIcon}
        onClick={maximizeChart}
      >
        <Icon
          name={ICONS.EXTERNAL_LINK}
          color={theme.text}
          size={24}
        />
      </button>
      )}
    </div>
  )
})

const stylesheet = {
  tvWidgetContainer: {
    position: 'relative',
    marginTop: SPACING.SPACE_20,
  },
  tvChartIframe: {
    borderRadius: 12,
  },
  maximizeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
  },
}

export default withTheme(stylesheet)(TvWidget)
