import React from 'react'
import PropTypes from 'prop-types'

import Marquee from './Marquee'
import CustomText from './CustomText'
import { compressedDYCName, symbolNameByOptions } from '../utils/common'
import {
  COLORS, SPACING, normalize, FONTWEIGHT, ASSETS,
} from '../Theme'

const styles = {
  segSym: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    overflow: 'hidden',
  },
  segSymCol: {
    display: 'inline-grid',
  },
  segSymCollapsed: {
    display: 'flex',
    flexDirection: 'column',
  },
  sym: {},
  seg: {
    textTransform: 'uppercase',
    marginTop: 6,
  },
  symbolStyles: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  optionsText: {
    fontSize: normalize(7),
    backgroundColor: COLORS.BLUE,
    color: COLORS.WHITE,
    borderRadius: SPACING.SPACE_20,
    marginLeft: SPACING.SPACE_5,
    fontWeight: FONTWEIGHT.MEDIUM,
  },
}

const SegSym = React.memo(({
  seg_sym,
  style,
  segStyles,
  symStyles,
  column,
  segChild,
  splitter,
  ellipsis,
  showTooltip,
  marqueStyle,
  alignToCenter,
  optionStyles,
  isMobile,
  collapseSymbol,
}) => {
  const segSymArr = seg_sym.split(splitter)
  // for dynamic symbol
  const [segment] = segSymArr
  segSymArr.shift()
  const symbol = segSymArr.join('_')
  let symbolName = compressedDYCName(symbol)
  const rawSymbolName = symbolNameByOptions(symbolName)
  let showOptions
  if (symbolName !== rawSymbolName) {
    showOptions = rawSymbolName.charAt(rawSymbolName.length - 2)
    symbolName = rawSymbolName.substring(0, rawSymbolName.length - 4)
  }

  const finalOptionText = {
    // backgroundColor: showOptions === 'W' ? COLORS.BLUE : COLORS.ORANGE,
    ...optionStyles,
  }
  const isLongSymbol = showOptions && showOptions !== ' ' && collapseSymbol && isMobile

  const view = (
    <div style={{ ...styles[column ? 'segSymCol' : isLongSymbol ? 'segSymCollapsed' : 'segSym'], ...style }}>
      <CustomText weight="medium" style={symStyles} ellipsis={ellipsis}>
        {symbolName}
        {showOptions && showOptions !== ' ' && (
        <img src={showOptions === 'W' ? ASSETS.Weekly : ASSETS.Monthly} style={finalOptionText} className="optionsText" alt="w" />
        )}
        {segChild}
      </CustomText>
      <CustomText
        size="small"
        color="textSecondary"
        ellipsis={ellipsis}
        style={{ marginLeft: column || isLongSymbol ? 0 : 4, ...segStyles }}
      >
        {segment}
      </CustomText>
    </div>
  )

  if (showTooltip) {
    return (
      <Marquee showMarquee={false} alignToCenter={alignToCenter} tooltipText={`${symbol} ${segment}`} changes={seg_sym} style={marqueStyle}>
        {view}
      </Marquee>
    )
  }
  return view
})

export default SegSym

SegSym.defaultProps = {
  style: {},
  segStyles: {},
  symStyles: {},
  column: false,
  segChild: '',
  splitter: '_',
  ellipsis: true,
  showTooltip: false,
  isMobile: false,
  collapseSymbol: false,
}

SegSym.propTypes = {
  seg_sym: PropTypes.string.isRequired,
  column: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  segStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  symStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  segChild: PropTypes.node,
  splitter: PropTypes.string,
  ellipsis: PropTypes.bool,
  showTooltip: PropTypes.bool,
  isMobile: PropTypes.bool,
  collapseSymbol: PropTypes.bool,
}
