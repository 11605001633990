import React, { PureComponent } from 'react'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'

import Deploy from './Deploy'
import CustomModal from '../../UI/CustomModal'
import { getUserDetails } from '../../containers/Auth/actions'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import { deployAlgoStocks } from '../../containers/Backtests/actions'
import { reDeployAlgo } from '../../containers/Deployed/actions'
import { ALGO_KEY_MAP } from '../AlgoCondensedView/AlgoUtils'
import { deployAlgo, subscribeAndDeployAlgo } from '../../containers/MarketPlace/actions'
import { showSnackbar } from '../../UI/Snackbar'
import { DEPLOYED_SECTION_MAP } from '../../containers/Deployed/deployedUtils'
import { segSymConverter } from '../../utils/common'
import {
  toggleRootModal, toggleAuthGreeting, updateTourIndex, changePtComponentStatus,
  extraTourTip, toggleTour, togglePtSideBar, toggleTourBackdrop,
} from '../../containers/Common/actions'
import { PRO_FEATURE_MAP } from '../../utils/userAccessChecks'

class DeploySymbols extends PureComponent {
  marketAlgo = false

  deployedSection = ''

  gaParamsMap = {}

  updateAnalytics = () => {
    if (!isEmpty(this.gaParamsMap)) {
      Object.keys(this.gaParamsMap).map(
        itemKey => this.updateGA(...this.gaParamsMap[itemKey]),
      )
    }
    this.gaParamsMap = {}
    this.updateGA(`${this.deployedSection.gaLabel} trade`, `${this.deployedSection.gaLabel} trade`, this.deployedSection.count)
  }

  componentDidUpdate(prevProps) {
    const {
      isDeployingStocks,
      deployResponse,
      deploymentError,
      limitExceeded,
      isCheckingLimit,
      deploymentErrorMsg,

      isRedeploying,
      reDeployResp,
      reDeployError,
      reDeployErrorMsg,

      marketIsDeployingStocks,
      marketDeploymentError,
      marketDeployResponse,
      marketDeploymentErrorMsg,
      dismissDeploy,
      fetchUserDetails,
    } = this.props
    if (prevProps.isDeployingStocks && !isDeployingStocks) {
      if (deployResponse.status === 'success') {
        if (this.deployedSection) {
          this.updateAnalytics()
        }
        fetchUserDetails()
        // navigation.navigate(NAVIGATIONS.DEPLOYED.name,
        // { activeSection: this.deployedSection, activeTabIndex: 0 })
        // goBack({ currentNavigation: navigation, params:
        // { activeSection: this.deployedSection } })
        dismissDeploy('success', { activeSection: this.deployedSection, activeTabIndex: 0 })
      } else if (deploymentError || deployResponse.status === 'error') {
        showSnackbar(deploymentErrorMsg, {}, 0)
        this.onBackPress()
      }
    }
    if (isCheckingLimit !== prevProps.isCheckingLimit && !isCheckingLimit && limitExceeded) {
      dismissDeploy('exceeded', { errorMsg: 'Deploy limit exceeded' })
      showSnackbar('Deploy limit exceeded', {}, 0)
    }
    if (marketIsDeployingStocks !== prevProps.marketIsDeployingStocks && !marketIsDeployingStocks) {
      if (marketDeployResponse && marketDeployResponse.status === 'success') {
        if (this.deployedSection) {
          this.updateAnalytics()
        }

        // navigation.navigate(NAVIGATIONS.DEPLOYED.name,
        // { activeSection: this.deployedSection, activeTabIndex: 0 })
        // goBack({ currentNavigation: navigation, params:
        // { activeSection: this.deployedSection } })
        dismissDeploy('success', { activeSection: this.deployedSection, activeTabIndex: 0 })
      } else if (marketDeploymentError) {
        showSnackbar(marketDeploymentErrorMsg || 'Error occured', {}, 0)
        this.onBackPress()
      }
    }
    if (prevProps.isRedeploying && !isRedeploying) {
      if (reDeployResp.status === 'success') {
        if (this.deployedSection) {
          this.updateAnalytics()
        }

        // navigation.navigate(NAVIGATIONS.DEPLOYED.name,
        // { activeSection: this.deployedSection, activeTabIndex: 0 })
        // goBack({ currentNavigation: navigation, params:
        // { activeSection: this.deployedSection } })
        dismissDeploy('success', { activeSection: this.deployedSection, activeTabIndex: 0 })
      } else if (limitExceeded) {
        // dismissDeploy('exceeded')
        showSnackbar('Deploy limit exceeded', {}, 0)
      } else if (reDeployError || reDeployResp.status === 'error') {
        // showSnackbar('redeploy error')
        // dismissDeploy('error', deploymentErrorMsg)
        showSnackbar(reDeployErrorMsg || 'Error redeploying', {}, 0)
        this.onBackPress()
      }
    }
  }

  updateGA = (a, l, v) => {
    const { updateGA } = this.props
    updateGA(a, l, v, 'Deploy pop-up')
  }

  toggleAuthGreeting = (params) => {
    this.props.toggleAuthGreeting(params)
  }

  onBackPress = () => {
    const {
      onDismiss, dismissKey, toggleSkipTour, startTour,
      activeTourIndex,
    } = this.props
    if (startTour && (activeTourIndex === 3 || activeTourIndex === 4)) {
      toggleSkipTour(true)
      return
    }
    onDismiss(dismissKey)
  }

  deployStocks = (deployParams = {}, extraParams = {}, gaParams) => {
    const {
      csrf,
      deployStocks,
      user_details,
      subscribeAndDeploy,
      reDeployAlgo: reDeploy,
      deploySubscribedAlgo,
      algoInfo,
      fromRouteName,
    } = this.props
    const {
      status, subscription_valid, subscription_type, broker,
    } = user_details
    if (algoInfo) {
      const {
        algo_subscription_uuid = '',
        algo_uuid = '',
        take_profit = '',
        stop_loss = '',
        quantity = '',
        time_frame = '',
        publishing_uuid = '', //
        chart_type,
        segment_symbol,
        deployment_uuid,
        subscription_status,
      } = algoInfo

      if (deployParams.deployment_type === 'Notifications') {
        this.deployedSection = DEPLOYED_SECTION_MAP.NOTIFICATION_TRADING
      } else if (deployParams.deployment_type === 'Paper trading') {
        this.deployedSection = DEPLOYED_SECTION_MAP.PAPER_TRADING
      } else {
        this.deployedSection = {}
      }
      this.deployedSection.count = 0
      this.gaParamsMap = gaParams
      const {
        mcx, stocksQuantity = {}, selectedStocks = [], stocksMaxAllocation,
      } = extraParams
      if (status === 'success' && subscription_valid === false) {
        // showSnackbar('Your subscription is over, please renew to continue')
        showSnackbar('Deployment limit reached.')
        this.onBackPress()
        return
      }
      if (subscription_type < 3 && /Renko/i.test(chart_type)) {
        this.toggleAuthGreeting(PRO_FEATURE_MAP.RENKO.ID)
        // showSnackbar('Subscribe to Ultimate plan to use Renko', {}, 0)
        this.onBackPress()
        return
      }
      if (subscription_type !== 3 && mcx) {
        this.toggleAuthGreeting(PRO_FEATURE_MAP.MCX.ID)
        // showSnackbar('MCX is only available in Ultimate plan', {}, 0)
        this.onBackPress()
        return
      }
      if (subscription_type !== 3 && (deployParams.algo_live_for === '30'
        || deployParams.algo_live_for === '60')) {
        const featureMap = deployParams.algo_live_for === '30'
          ? PRO_FEATURE_MAP.THIRTY_DAY_LIVE.ID : PRO_FEATURE_MAP.SIXTY_DAY_LIVE.ID
        this.toggleAuthGreeting(featureMap)
        // showSnackbar('30 days live is only available in Ultimate plan', {}, 0)
        this.onBackPress()
        return
      }
      if (status === 'success' && subscription_valid === false) {
        // showSnackbar('Your subscription is over, please renew to continue')
        showSnackbar('Deployment limit reached.')
        this.onBackPress()
        return
      }
      let params = {
        take_profit,
        stop_loss,
        quantity,
        interval: time_frame,
        broker,
        csrfmiddlewaretoken: csrf,
        subscription_status,
        ...deployParams,
      }
      if (fromRouteName === NAVIGATIONS.DEPLOYED.name) {
        params = {
          ...params,
          quantity: stocksQuantity[selectedStocks] || quantity,
          max_allocation: stocksMaxAllocation[selectedStocks],
          deployment_uuid,
          seg_sym: segment_symbol,
        }
        if (!deployment_uuid) return
        reDeploy(params)
        this.deployedSection.count = Number(stocksMaxAllocation[selectedStocks]
          || stocksQuantity[selectedStocks] || quantity)
      } else {
        const seg_sym_quantity_list = []
        const seg_sym_list = selectedStocks.map((selectedStock) => {
          const { segment, symbol } = segSymConverter(selectedStock)
          seg_sym_quantity_list.push([
            segment, symbol, stocksQuantity[selectedStock], stocksMaxAllocation[selectedStock],
          ])
          this.deployedSection.count += Number(stocksMaxAllocation[selectedStocks]
            || stocksQuantity[selectedStock])
          return [segment, symbol]
        })

        if (seg_sym_quantity_list.length < 1) {
          return
        }
        params = {
          ...params,
          algo_uuid,
          seg_sym_list,
          seg_sym_quantity_list,
        }
        if (this.marketAlgo && subscription_status >= 2) {
          if (algo_subscription_uuid) {
            deploySubscribedAlgo({ ...params, algo_subscription_uuid }, this.headers)
          } else {
            subscribeAndDeploy({ ...params, publishing_uuid })
          }
          return
        }
        deployStocks(params)
      }
    }
  }

  render() {
    const {
      user_details,
      isFetchingAccounts,
      accounts,
      accountsError,
      accountsErrorMsg,
      isDeployingStocks,
      isRedeploying,
      visible,
      algoInfo = {},
      stocks,
      connectedBrokers,
      fancyBroker,
      startTour,
      activeTourIndex,
      updateTour,
      changePtComponent,
      getExtraTourtip,
      ptExtraTourtip,
      showPtTour,
      toggleProductTour,
      ptSideBar,
      fetchUserDetails,
      isMobile,
      updateGA,
    } = this.props
    this.marketAlgo = !!algoInfo[ALGO_KEY_MAP.DISCOVER] || !!algoInfo[ALGO_KEY_MAP.SUBSCRIBED]
    return (
      <CustomModal
        visible={visible}
        onDismiss={this.onBackPress}
        containerStyles="deploy-modal"
        disableBackDrop={isDeployingStocks || isRedeploying}
      >
        <Deploy
          user_details={user_details}
          connectedBrokers={connectedBrokers}
          isFetchingAccounts={isFetchingAccounts}
          accounts={accounts}
          accountsError={accountsError}
          accountsErrorMsg={accountsErrorMsg}
          fetchUserDetails={fetchUserDetails}
          {...algoInfo}
          stocks={stocks}
          deployStocks={this.deployStocks}
          isDeploying={isDeployingStocks || isRedeploying}
          marketAlgo={this.marketAlgo}
          onBackPress={this.onBackPress}
          toggleRootModal={this.props.toggleRootModal}
          toggleAuthGreeting={this.toggleAuthGreeting}
          fancyBroker={fancyBroker}
          startTour={startTour}
          activeTourIndex={activeTourIndex}
          updateTourIndex={updateTour}
          changePtComponentStatus={changePtComponent}
          getExtraTourtip={getExtraTourtip}
          ptExtraTourtip={ptExtraTourtip}
          showTour={showPtTour}
          toggleProductTourSidebar={toggleProductTour}
          ptSideBar={ptSideBar}
          onDismiss={this.onBackPress}
          isMobile={isMobile}
          updateGA={updateGA}
        />
      </CustomModal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    ...state.auth.loginResp,

    isDeployingStocks: state.backtests.isDeployingStocks,
    deployResponse: state.backtests.deployResponse,
    deploymentErrorMsg: state.backtests.deploymentErrorMsg,
    deploymentError: state.backtests.deploymentError,
    limitExceeded: state.backtests.limitExceeded,
    isCheckingLimit: state.backtests.isCheckingLimit,

    startTour: state.common.startTour,
    activeTourIndex: state.common.activeTourIndex,
    ptExtraTourtip: state.common.ptExtraTourtip,
    ptSideBar: state.common.ptSideBar,
    showSkipTourPopup: state.common.showSkipTourPopup,

    isRedeploying: state.deployed.isRedeploying,
    reDeployResp: state.deployed.reDeployResp,
    reDeployError: state.deployed.reDeployError,
    reDeployErrorMsg: state.deployed.reDeployErrorMsg,

    marketIsDeployingStocks: state.marketplace.isDeployingStocks,
    marketDeploymentError: state.marketplace.deploymentError,
    marketDeployResponse: state.marketplace.deployResponse,
    marketDeploymentErrorMsg: state.marketplace.deploymentErrorMsg,

    isDeploying: state.backtests.isDeployingStocks
      || state.deployed.isRedeploying || state.marketplace.isDeployingStocks,

    connectedBrokers: state.auth.connectedBrokers,

    fancyBroker: state.auth.fancyBroker,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deployStocks: params => dispatch(deployAlgoStocks(params)),
    fetchUserDetails: () => dispatch(getUserDetails()),
    subscribeAndDeploy: (params, headers) => dispatch(subscribeAndDeployAlgo(params, headers)),
    reDeployAlgo: (params, headers) => dispatch(reDeployAlgo(params, headers)),
    deploySubscribedAlgo: (params, headers, noDeploy) => dispatch(
      deployAlgo(params, headers, noDeploy),
    ),
    toggleRootModal: (modalName, showModal) => dispatch(toggleRootModal(modalName, showModal)),
    toggleAuthGreeting: (modalType, routeAfterActivation) => dispatch(
      toggleAuthGreeting(modalType, routeAfterActivation),
    ),
    updateTour: index => dispatch(updateTourIndex(index)),
    changePtComponent: status => dispatch(changePtComponentStatus(status)),
    getExtraTourtip: params => dispatch(extraTourTip(params)),
    showPtTour: params => dispatch(toggleTour(params)),
    toggleProductTour: status => dispatch(togglePtSideBar(status)),
    toggleSkipTour: params => dispatch(toggleTourBackdrop(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeploySymbols)
