import {
  GET_PAYMENT_INFO_SUCCESS,
  GET_PAYMENT_INFO_INIT,
  GET_PAYMENT_INFO_FAILURE,
} from './actionTypes'

const initialState = {
  paymentMethodsError: false,
  isFetchingPaymentMethods: false,
  paymentMethodsErrorMsg: '',
  paymentMethodsSuccess: false,

  paymentInfo: null,
  isFetchingPaymentInfo: true,
  paymentInfoError: false,
}

const paymentPages = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_INFO_INIT: {
      return {
        ...state,
        isFetchingPaymentInfo: true,
        paymentInfoError: false,
      }
    }

    case GET_PAYMENT_INFO_SUCCESS: {
      const { data } = action
      let paymentInfo = {}
      const data2 = data[0].content.rendered
      const doc = new DOMParser().parseFromString(data2, 'text/html')
      doc.querySelectorAll('tr').forEach((tr, index) => {
        if(index === 0) return
        const tds = tr.querySelectorAll('td')
        const offerId = tds[0].textContent
        const title = tds[1].textContent
        const description = tds[2].innerHTML.split('<br>').filter(item => item.length > 0)
        const subHeading = tds[3].textContent
        const image = tds[4].textContent
        const planPeriod = 12
        const planType = tds[6].textContent || 'ultimate'
        const price = tds[7].textContent || 13876
        const originalPrice = tds[8].textContent || 19824
        const payBtnText = tds[9].textContent || 'Pay Now'
        const desktopImage = tds[10].textContent
        paymentInfo = ({
          offerId,
          title,
          description,
          subHeading,
          image,
          planPeriod,
          planType,
          price,
          originalPrice,
          payBtnText,
          desktopImage,
        })
      })
      return {
        ...state,
        isFetchingPaymentInfo: false,
        paymentInfo,
      }
    }

    case GET_PAYMENT_INFO_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingPaymentInfo: false,
        paymentInfoError: true,
        paymentInfoErrorMsg: error,
      }
    }
    default:
      return state
  }
}

export default paymentPages
