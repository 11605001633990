/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { createRef, PureComponent } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty, isEqual } from 'lodash'

import ProfileImage from '../ProfileImage'
import ModalDropdown from '../../UI/Dropdown/ModalDropdown'
import NavBar from './NavBar'
import LinkBrokerLogin from '../Auth/LinkBrokerLogin'
import CustomModal from '../../UI/CustomModal'
import CustomText from '../../UI/CustomText'
import AlertPopup from '../../UI/AlertPopup'
import Icon from '../../UI/Icon'
import Dot from '../../UI/Dot'
import ABLoginContainer from '../Auth/ABLoginContainer'
import PlaceHolderLoader from '../../UI/PlaceHolderLoader'
import Button from '../../UI/Button'
import HeaderNotificationPopup from '../Notifications/HeaderNotificationPopup'

import {
  ASSETS, COLORS, ICONS, theme, SPACING, FONTS, FONTWEIGHT, // DIMENSIONS
} from '../../Theme'
import {
  getHomepageUrlBroker, getSessionStorage, setCookie, setLocalStorage, setSessionStorage,
} from '../../utils/common'
import { withTheme } from '../../Theme/ThemeProvider'
import { pathname_mapping } from '../../utils/navigationConstant'
import { showSnackbar } from '../../UI/Snackbar'
import { BROKERS, origins } from '../../utils/consts'
import { academyBaseUrl } from '../../utils/apiEndpoints'
import { PREFERENCES, THEME_TYPE_MAP } from '../../containers/Profile/profileUtils'
import { togglePtSideBar } from '../../containers/Common/actions'
import CustomTooltip from '../../UI/CustomTooltip'

const {
  Logo, LogoDark,
} = ASSETS

const dotStyles = {
  position: 'absolute',
  top: '2px',
  right: '2px',
}

const tourPages = [
  pathname_mapping.Dashboard, pathname_mapping.Strategies,
  pathname_mapping.Scanners, pathname_mapping.Backtests,
  pathname_mapping.marketwatch,
]

export const getDotColor = (unReadCount = {}, totalUnreadCount) => {
  let dotColor = COLORS.RED
  let notifColor = COLORS.GREY
  let routeToTab = pathname_mapping.Notifications

  if (totalUnreadCount > 0) {
    notifColor = theme.linkColor
    routeToTab = pathname_mapping.notif_scanner
  }
  if (unReadCount.Notifications) {
    routeToTab = pathname_mapping.notif_live
    dotColor = theme.linkColor
  } else if (unReadCount['Paper trading']) {
    routeToTab = pathname_mapping.notif_paper
    dotColor = COLORS.GREEN
  } else if (unReadCount.changelogs) {
    dotColor = COLORS.RED
    routeToTab = pathname_mapping.Notifications
  }
  return { dotColor, notifColor, routeToTab }
}

class ScreenHeader extends PureComponent {
  state = {
    linkBrokerLogin: false,
    brokerChangeAlert: false,
    showABLogin: false,
    showBrokerDropdown: false,
    showSideMenu: false,
  }

  alertProps = {}

  headerRef = createRef()

  componentDidUpdate(prevProps) {
    const {
      user_details, startTour, isDark, showAddBrokerPopup,
    } = this.props
    const { showABLogin, pathname } = this.state
    if (prevProps.user_details.user_uuid !== user_details.user_uuid && showABLogin) {
      this.addBroker = false
      this.setState({ showABLogin: false })
    }
    if (pathname !== prevProps.pathname && this.headerRef.current) {
      this.headerRef.current.removeAttribute('data-theme')
    }
    if (prevProps.startTour !== startTour) {
      if (startTour && isDark) {
        this.toggleTheme()
      } else if (!startTour) {
        const previousUserTheme = getSessionStorage('previousUserTheme') || THEME_TYPE_MAP.LIGHT.value
        if (previousUserTheme === THEME_TYPE_MAP.DARK.value) {
          this.toggleTheme()
        }
      }
      setSessionStorage('previousUserTheme', isDark ? THEME_TYPE_MAP.DARK.value : THEME_TYPE_MAP.LIGHT.value)
    }
    if (!isEqual(prevProps.showAddBrokerPopup, showAddBrokerPopup) && showAddBrokerPopup) {
      this.changeBroker('addBroker')
    }
  }

  onProfileClick = () => { }

  inputRenderer = () => {
    const { styles, isMobile, loggedIn } = this.props
    if (isMobile || !loggedIn) {
      return null
    }
    return (
      <ProfileImage showUpload={false} imgContainerStyles={styles.imgContainer} />
    )
  }

  onChangeOption = (actionType) => {
    const { logout } = this.props
    switch (actionType) {
      case 'logout': {
        logout()
        break
      }

      default:
        break
    }
  }

  routeToNotifications = () => {

  }

  linkBrokerHandler = () => {
    this.toggleModal('brokerChangeAlert')
    if (!this.alertProps.brokerName) {
      showSnackbar('Something went wrong', {}, 0)
      return
    }
    const brokerName = this.alertProps.brokerName
    const isConnected = true
    const {
      user_details, csrf,
    } = this.props
    const adding_broker = !isConnected
    if (user_details.email) {
      const { email, user_uuid } = user_details
      this.addBroker = {
        email, csrf, user_uuid, adding_broker,
      }
    }
    switch (brokerName) {
      case BROKERS.ZERODHA.name: {
        setCookie({ addBroker: JSON.stringify(this.addBroker) })
        window.open(origins.kite, '_self'); break
      }
      case BROKERS.FIVE_PAISA.name: {
        setCookie({ addBroker: JSON.stringify(this.addBroker) })
        window.open(origins.paisa, '_self'); break
      }
      case BROKERS.ANGEL.name: {
        if (!adding_broker) {
          this.addBroker = null
        }
        this.toggleModal('showABLogin')
        break
      }
      case BROKERS.UPSTOX.name: {
        setCookie({ addBroker: JSON.stringify(this.addBroker) })
        window.open(origins.upstox, '_self'); break
      }
      case BROKERS.ICICI.name: {
        setCookie({ addBroker: JSON.stringify(this.addBroker) })
        window.open(origins.icici, '_self'); break
      }
      default: break
    }
  }

  changeBroker = (brokerName) => {
    if (brokerName === 'addBroker') {
      this.toggleModal('linkBrokerLogin')
    } else {
      this.alertProps = {
        brokerName,
        title: 'Are you sure, you want to switch?',
        actions: [
          {
            text: 'Cancel',
            action: () => this.toggleModal('brokerChangeAlert'),
            btnProps: {
              buttonColor: 'tabsBg', mode: 'text', labelColor: 'textSecondary',
            },
          },
          {
            text: 'Continue', action: this.linkBrokerHandler,

          },
        ],
      }
      this.toggleModal('brokerChangeAlert')
    }
  }

  brokersInputRenderer = () => {
    const { connectedBrokers, styles, fancyBroker } = this.props
    if (!isEmpty(fancyBroker)) {
      const { label, logo } = fancyBroker
      return (
        <div className={styles.brokerInputContainer}>
          <div className={styles.currentBroker}>
            <img src={logo} className={styles.logoImg} alt={`${label} logo`} />
            <CustomText weight="semi_bold">{label}</CustomText>
          </div>
          {this.inputRenderer()}
        </div>
      )
    }
    const currentBroker = connectedBrokers.filter(item => item.currentBroker)
    if (!currentBroker[0]) return null
    const { logo, label } = currentBroker[0]
    return (
      <div className={styles.brokerInputContainer}>
        <div className={styles.currentBroker}>
          <img src={logo} className={styles.logoImg} alt={`${label} logo`} />
          <CustomText weight="semi_bold">{label}</CustomText>
        </div>
        {this.inputRenderer()}
      </div>
    )
  }

  brokerLabelRenderer = (optionObj, label, selected) => {
    const { styles } = this.props
    const { logo, name } = optionObj
    const addBroker = name === 'addBroker'
    return (
      <div className={styles.brokerRow}>
        {addBroker ? <Icon name={ICONS.ADD_ROUND_OUTLINED} className={styles.logoIcon} color="orange" size={15} />
          : <img src={logo} className={styles.logoImg} alt={`${label} logo`} />}
        <CustomText
          color={addBroker ? 'orange' : 'text'}
          weight="semi_bold"
          style={{ flex: 1, textAlign: 'left' }}
        >
          {label}
        </CustomText>
        {selected && (
          <Icon name={ICONS.TICK} color={COLORS.GREEN} size={12} />
        )}
      </div>
    )
  }

  toggleModal = (modal) => {
    const { showAddBrokerPopup, toggleAddBrokerPopup } = this.props
    if (showAddBrokerPopup) {
      toggleAddBrokerPopup(false)
    }
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  toggleAbLogin = (addBroker) => {
    this.addBroker = addBroker
    this.setState({ showABLogin: true })
  }

  routeToHelp = () => {
    window.open(academyBaseUrl, '_blank')
  }

  routeToLogin = ({
    loggedIn, routeToLogin, routeToDashboard, routeFrom = '', brokername, preventGA,
  }) => {
    const { domainChange } = this.props
    this.setState({ showSideMenu: false })
    const { location } = window
    const { hostname } = location
    if (loggedIn) routeToDashboard()
    // BROKERS.ZERODHA.hostnames[0]
    else if (hostname === 'streak.zerodha.com') {
      setCookie({ broker: BROKERS.ZERODHA.name })
      domainChange({ loginWithBroker: BROKERS.ZERODHA.name })
      // showBrokerAuth: false
    } else routeToLogin(routeFrom, brokername, preventGA)
  }

  renderSlmNotifications = () => {
    return <HeaderNotificationPopup containerStyles={this.props.styles.notifPopup} />
  }

  toggleCallback = (showBrokerDropdown) => {
    this.setState({ showBrokerDropdown })
  }

  shouldDisplayTour = (pathname, search) => {
    return (tourPages.includes(pathname) && isEmpty(search))
      || (pathname === pathname_mapping.Scanners && search.includes('tab=scan_details'))
      || (pathname === pathname_mapping.Backtests
      )
  }

  updateGA = (a, l, v) => {
    this.props.updateGA(a, l, v)
  }

  updateGASys = (action, label, value, category) => {
    this.props.updateGA(action, label, value, category)
  }

  toggleTheme = () => {
    const { toggleTheme, isDark, startTour } = this.props
    this.toggleCallback(false)

    const value = startTour ? THEME_TYPE_MAP.LIGHT.value : isDark
      ? THEME_TYPE_MAP.LIGHT.value : THEME_TYPE_MAP.DARK.value
    setLocalStorage({ [PREFERENCES.THEME]: value })
    toggleTheme(value)
    this.updateGA('Toggle theme', isDark ? THEME_TYPE_MAP.LIGHT.label : THEME_TYPE_MAP.DARK.label)
  }

  toggleTourSidebar = () => {
    const { ptSideBar, toggleProductTourSideBar } = this.props
    toggleProductTourSideBar(!ptSideBar)
  }

  toggleSideMenu = () => {
    this.setState(prevState => ({ showSideMenu: !prevState.showSideMenu }))
  }

  routeToPricing = () => {
    const { routeToPricing } = this.props
    this.setState({ showSideMenu: false })
    routeToPricing()
  }

  renderHeaderRight = () => {
    const {
      isMobile, styles, unAuthScreens, routeToDashboard, routeToLogin,
      loggedIn, pathname, // innerWidth, routeToHome
      marketingHomePageScrolled, // isNewHomePage, routeToDiscover routeToScanners,
    } = this.props
    const { showSideMenu } = this.state
    // const isSmallMonitorScreen = innerWidth > 768 && innerWidth < 1030
    const { logo = '', name: brokername = '' } = getHomepageUrlBroker()

    const isSytematicTradingPage = pathname === pathname_mapping.SystematicTrading.toLowerCase()
      || pathname.toLowerCase() === pathname_mapping.Ctb.toLowerCase()
    const startFreeTrialParams = {
      loggedIn, routeToLogin, routeToDashboard, routeFrom: 'StartFreeTrial Button',
    }

    if (isMobile && pathname !== pathname_mapping.OpenScanOrder) {
      if (isSytematicTradingPage) {
        return (
          <div className={styles.row}>
            <CustomModal
              onDismiss={this.toggleSideMenu}
              visible={showSideMenu}
              containerStyles={styles.newSideMenuContainer}
              position="right"
              fullScreen
            >
              <div className={styles.newSideMenuHeader}>
                <img
                  src={Logo}
                  className={styles.logoHeaderImg}
                  id="logoLink"
                  alt="streak"
                />
                <button
                  type="button"
                  onClick={() => this.toggleSideMenu('showSideMenu')}
                  className={styles.sideMenuBtnClose}
                  style={{ backgroundColor: COLORS.BTN_SECONDARY_COLOR, borderRadius: '50%' }}
                >
                  <Icon size={14} name={ICONS.CLOSE} color={theme.linkColor} />
                </button>
              </div>
              <div className={styles.sideMenuContent} style={{ height: 'calc(92% - 40px)', padding: SPACING.SPACE_10 }}>
                {/* <button
                  type="button"
                  className={styles.newHomeBtn}
                  onClick={() => {
                    this.updateGASys('Academy Header', 'Header', 1, 'Landing Page')
                    this.routeToHelp()
                  }}
                  style={{ borderBottom: 'none' }}
                >
                  <CustomText size="medium" weight="medium">
                    Streak Academy
                  </CustomText>
                  <Icon name={ICONS.FORWARD} size={12} color="#b2b3c8" />
                </button> */}
                {/* <button
                  type="button"
                  className={styles.newHomeBtn}
                  onClick={this.routeToPricing}
                  style={{ borderBottom: 'none' }}
                >
                  <CustomText size="medium" weight="medium">
                    Pricing
                  </CustomText>
                  <Icon name={ICONS.FORWARD} size={12} color="#b2b3c8" />
                </button> */}
                <div style={{ flex: 1 }} />
                <Button
                  mode="text"
                  labelColor="white"
                  buttonColor="BTN_PRIMARY_COLOR"
                  onPress={() => {
                    this.updateGASys('Login Button Header', 'Header', 1, 'Landing Page')
                    this.updateGASys('Log in', 'Login Button', 1, 'Home')
                    this.routeToLogin({
                      loggedIn, routeToLogin, routeToDashboard, routeFrom: 'SignUp Button', preventGA: true,
                    })
                  }}
                  btnStyles={styles.smGetStarted}
                  text="Sign up"
                  labelSize="medium"
                />
                <Button
                  mode="text"
                  labelColor="linkColor"
                  buttonColor="BTN_SECONDARY_COLOR"
                  onPress={() => {
                    this.updateGASys('Login Button Header', 'Header', 1, 'Landing Page')
                    this.updateGASys('Log in', 'Login Button', 1, 'Home')
                    this.routeToLogin({
                      loggedIn, routeToLogin, routeToDashboard, routeFrom: 'Login Button', brokername, preventGA: true,
                    })
                  }}
                  text="Log in"
                  labelSize="medium"
                  btnStyles={styles.smLoginBtn}
                >
                  {logo && <img src={logo} className={styles.logoImg} alt={`${brokername} logo`} />}
                </Button>
              </div>
            </CustomModal>
            <button
              type="button"
              onClick={() => this.toggleSideMenu('showSideMenu')}
              className={styles.sideMenuBtn}
              id="sideMenuBtn"
            >
              <Icon
                size={14}
                name={ICONS.SIDE_MENU}
                color={COLORS.BLUE}
              />
            </button>
          </div>
        )
      }

      return (
        <div className={styles.row}>
          <CustomModal
            onDismiss={this.toggleSideMenu}
            visible={showSideMenu}
            containerStyles={styles.sideMenuContainer}
            position="right"
            fullScreen
          >
            <div className={styles.sideMenuHeader}>
              {/* <Button
              mode="outlined"
              buttonColor="blue"
              text="Start Free Trial"
              onPress={this.routeToLogin}
              params={{ loggedIn, routeToLogin, routeToDashboard }}
              btnStyles={styles.headerGetStartedMobile}
              labelWeight="medium"
              labelSize={isMobile ? 'SMALL_1' : 'small'}
              roundness={0}
            /> */}
              <button
                type="button"
                onClick={() => this.toggleSideMenu('showSideMenu')}
                className={styles.sideMenuBtnClose}
              >
                <Icon size={10} name={ICONS.CLOSE} color={theme.linkColor} />
              </button>
            </div>
            <div className={styles.sideMenuContent}>
              {/* <button
              type="button"
              className={styles.homeBtn}
              onClick={() => { routeToDiscover(); this.setState({ showSideMenu: false }) }}
            >
              <CustomText size="medium" weight="medium">
                Discover Strategies
              </CustomText>
            </button> */}
              {/* <button
              type="button"
              className={styles.homeBtn}
              onClick={() => { routeToScanners(); this.setState({ showSideMenu: false }) }}
            >
              <CustomText size="medium" weight="medium">
                Discover Scanners
              </CustomText>
            </button> */}
              {/* <button
                type="button"
                className={styles.homeBtn}
                onClick={this.routeToHelp}
              >
                <CustomText size="medium" weight="medium">
                  Streak Academy
                </CustomText>
              </button> */}
              {/* <button
                type="button"
                className={styles.homeBtn}
                onClick={this.routeToPricing}
              >
                <CustomText size="medium" weight="medium">
                  Pricing
                </CustomText>
              </button> */}
              <div style={{ flex: 1 }} />
              <Button
                mode="text"
                labelColor="linkColor"
                buttonColor="blueLightBg"
                onPress={this.routeToLogin}
                text="Log in"
                labelSize="medium"
                params={{
                  loggedIn, routeToLogin, routeToDashboard, routeFrom: 'Login Button', brokername,
                }}
                btnStyles={styles.smLoginBtn}
              >
                {logo && <img src={logo} className={styles.logoImg} alt={`${brokername} logo`} />}
              </Button>
              <Button
                buttonColor="blue"
                onPress={this.routeToLogin}
                params={{
                  loggedIn, routeToLogin, routeToDashboard, routeFrom: 'GetStarted Button',
                }}
                btnStyles={styles.smGetStarted}
                text="Get Started"
                labelSize="medium"
              />
            </div>
          </CustomModal>
          {/* <Button
            mode="outlined"
            buttonColor={isSytematicTradingPage ? 'white' : 'blue'}
            text="Start Free Trial"
            onPress={this.routeToLogin}
            params={{ loggedIn }}
            btnStyles={styles.headerGetStartedMobile}
            labelWeight="medium"
            labelSize={isMobile ? 'SMALL_1' : 'small'}
            roundness={0}
          />
          <button
            type="button"
            onClick={() => this.toggleSideMenu('showSideMenu')}
            className={styles.sideMenuBtn}
            id="sideMenuBtn"
          >
            <Icon
              size={12}
              name={ICONS.SIDE_MENU}
              color={isSytematicTradingPage ? COLORS.WHITE : theme.linkColor}
              // color={isSytematicTradingPage ? theme.linkWhite : theme.linkColor}
            />
          </button> */}

          {!marketingHomePageScrolled && (
            <button
              type="button"
              onClick={() => this.toggleSideMenu('showSideMenu')}
              // className={isSytematicTradingPage && styles.sideMenuBtn}
              id="sideMenuBtn"
            >
              <Icon
                size={12}
                name={ICONS.SIDE_MENU}
                color={theme.linkColor}
              />
            </button>
          )}

          {/* {marketingHomePageScrolled ? (
            <button
              type="button"
              className={styles.scrolledGetStartedButton}
              onClick={() => this.routeToLogin(startFreeTrialParams)}
            >
              Start Free Trial
            </button>
          )
            : (
              <>
                <Button
                  mode="outlined"
                  buttonColor="blue"
                  text="Start Free Trial"
                  onPress={this.routeToLogin}
                  params={{
                    loggedIn, routeToLogin, routeToDashboard, routeFrom: 'StartFreeTrial Button',
                  }}
                  btnStyles={styles.headerGetStartedMobile}
                  labelWeight="medium"
                  labelSize={isMobile ? 'SMALL_1' : 'small'}
                  roundness={0}
                />
                <button
                  type="button"
                  onClick={() => this.toggleSideMenu('showSideMenu')}
                  // className={isSytematicTradingPage && styles.sideMenuBtn}
                  id="sideMenuBtn"
                >
                  <Icon
                    size={12}
                    name={ICONS.SIDE_MENU}
                    color={theme.linkColor}
                  />
                </button>
              </>
            )} */}
        </div>
      )
    }

    if (marketingHomePageScrolled) {
      return (
        <div className={styles.row}>
          {/* <Button
            mode="contained"
            text="Start Free Trial"
            onPress={() => {
              this.updateGASys('Start Trial B Header', 'Header', 1, 'Landing Page')
              this.updateGASys('Log in', 'StartFreeTrial Button', 1, 'Home')
              this.routeToLogin({
                loggedIn, routeToLogin, routeToDashboard,
                routeFrom: 'GetStarted Button', preventGA: true,
              })
            }}
            btnStyles={styles.headerGetStartedBlueScrolled}
            respText
            roundness={20}
          /> */}
        </div>
      )
    }

    // if (isSytematicTradingPage) {
    //   return (
    //     <div className={styles.row}>
    //       <Button
    //         mode="text"
    //         labelColor="white"
    //         transparent
    //         onPress={this.routeToPricing}
    //         text="Pricing"
    //         btnStyles={styles.headerHelpBlueBtn}
    //         respText
    //       />
    //       <Button
    //         mode="text"
    //         labelColor="white"
    //         transparent
    //         onPress={this.routeToHelp}
    //         btnStyles={`${styles.headerHelpBlueBtn} ${styles.headerAcademy}`}
    //       >
    //         <img src={ASSETS.AcademyLogo} className={styles.academyLogo} alt="academy" />
    //         <CustomText color="white" size={isSmallMonitorScreen ?
    // 'small_0' : 'small'} weight="medium">Streak Academy</CustomText>
    //       </Button>
    //       <Button
    //         mode="text"
    //         labelColor="white"
    //         transparent
    //         onPress={this.routeToLogin}
    //         text="Log in"
    //         params={{
    //           loggedIn, routeToLogin, routeToDashboard, routeFrom: 'Login Button',
    //         }}
    //         btnStyles={styles.headerLoginBlueBtn}
    //         respText
    //       />
    //       <Button
    //         mode="contained"
    //         buttonColor="blue"
    //         text="Start Free Trial"
    //         onPress={this.routeToLogin}
    //         params={{
    //           loggedIn, routeToLogin, routeToDashboard, routeFrom: 'StartFreeTrial Button',
    //         }}
    //         btnStyles={styles.headerGetStartedBlue}
    //         respText
    //       />
    //     </div>
    //   )
    // }

    if (isSytematicTradingPage && !isMobile) {
      return (
        <div className={styles.row} style={{ gap: SPACING.SPACE_50 }}>
          <Button
            mode="text"
            labelColor="#000651"
            transparent
            labelWeight="bold"
            onPress={() => {
              this.updateGASys('Login Button Header', 'Header', 1, 'Landing Page')
              this.updateGASys('Log in', 'Login Button', 1, 'Home')
              this.routeToLogin({
                loggedIn, routeToLogin, routeToDashboard, routeFrom: 'Login Button', preventGA: true,
              })
            }}
            btnStyles={styles.newHeaderBtn}
            respText
          >
            <span className={styles.headerBtnLabel}>Log in</span>
          </Button>
          {/* <Button
            mode="text"
            labelColor="#000651"
            transparent
            onPress={() => {
              this.updateGASys('Academy Header', 'Header', 1, 'Landing Page')
              this.routeToHelp()
            }}
            btnStyles={`${styles.newHeaderBtn} ${styles.headerAcademy}`}
          >
            <span className={styles.headerBtnLabel}>Streak academy</span>
          </Button> */}
          <Button
            mode="contained"
            text="Get Started 🡢"
            onPress={() => {
              this.updateGASys('Get Started Header', 'Header', 1, 'Landing Page')
              this.updateGASys('Log in', 'StartFreeTrial Button', 1, 'Home')
              this.routeToLogin({
                loggedIn, routeToLogin, routeToDashboard, routeFrom: 'GetStarted Button', preventGA: true,
              })
            }}
            btnStyles={styles.headerGetStartedBlue}
            respText
            roundness={20}
          />
        </div>
      )
    }

    return (

      <div className={styles.row}>
        {/* {unAuthScreens && pathname !== pathname_mapping.OpenScanOrder && (
          <Button
            mode="text"
            labelColor="blue"
            transparent
            onPress={routeToHome}
            text="Home"
            params={{
              loggedIn, routeToLogin, routeToDashboard, routeFrom: 'Home Button',
            }}
            btnStyles={styles.headerHelpBtn}
            respText
          />
        )} */}
        {pathname !== pathname_mapping.OpenScanOrder && (
          <>
            {/* <Button
              mode="text"
              labelColor="blue"
              transparent
              onPress={this.routeToPricing}
              text="Pricing"
              btnStyles={styles.headerHelpBtn}
              respText
            /> */}
            {/* <Button
          mode="text"
          labelColor="blue"
          transparent
          onPress={routeToScanners}
          text="Discover Scanners"
          iconName="TAB_SCANNER"
          iconProps={{ color: COLORS.BLUE }}
          btnStyles={styles.headerHelpBtn}
          respText
        /> */}
            {/* <Button
          mode="text"
          labelColor="blue"
          transparent
          onPress={routeToDiscover}
          text="Discover Strategies"
          iconName="TAB_STRATEGY"
          iconProps={{ color: COLORS.BLUE }}
          btnStyles={styles.headerHelpBtn}
          respText
        /> */}
            {/* <Button
              mode="text"
              labelColor="blue"
              transparent
              onPress={this.routeToHelp}
              btnStyles={`${styles.headerHelpBtn} ${styles.headerAcademy}`}
            >
              <img src={ASSETS.AcademyLogo} className={styles.academyLogo} alt="academy" />
              <CustomText color="blue" size={isSmallMonitorScreen ? 'small_0' : 'small'}
              // weight="medium">Streak Academy</CustomText>
            </Button> */}
          </>
        )}
        {!unAuthScreens && (
          <Button
            mode="text"
            labelColor="blue"
            transparent
            onPress={this.routeToLogin}
            text="Log in"
            params={{
              loggedIn, routeToLogin, routeToDashboard, routeFrom: 'Login Button', brokername,
            }}
            btnStyles={styles.headerLoginBtn}
            respText
          >
            {logo && <img src={logo} className={styles.logoImg} alt={`${brokername} logo`} />}
          </Button>
        )}
        {/* {!unAuthScreens && (
          <Button
            mode="outlined"
            buttonColor="blue"
            text="Start Free Trial"
            onPress={this.routeToLogin}
            params={{
              loggedIn, routeToLogin, routeToDashboard, routeFrom: 'StartFreeTrial Button',
            }}
            btnStyles={styles.headerGetStarted}
            respText
          />
        )} */}
      </div>
    )
  }

  toggleProductTour = () => {
    const {
      ptSideBar, toggleProductTourSideBar, updateGA, user_details,
    } = this.props
    const { lc } = user_details
    const gaLabel = lc <= 10 ? 'Navbar' : 'Profile'
    toggleProductTourSideBar(!ptSideBar)
    updateGA('Take Tour', gaLabel, 1, 'Product Tour')
    this.toggleCallback(false)
  }

  render() {
    const {
      styles, unReadCount, isMobile, loggedIn, connectedBrokers, user_details = {},
      isDark, validatingEmail, pathname, inComingBroker,
      authScreens, commonScreens, // switchDomain,
      appLogin, startTour,
    } = this.props
    const { lc } = user_details
    const {
      linkBrokerLogin, brokerChangeAlert, showABLogin, showBrokerDropdown,
    } = this.state
    const showAuthHeader = (authScreens || commonScreens) && loggedIn
    const openScanOrder = pathname === pathname_mapping.OpenScanOrder
    if (appLogin || inComingBroker) {
      return (
        <header
          className={`mainHeader ${styles.header} ${showAuthHeader ? styles.headerAuth : styles.headerUnAuth}`}
          id="mainheader"
          ref={this.headerRef}
        >
          <img
            src={isDark ? LogoDark : Logo}
            className={styles.logoHeaderImg}
            id="logoLink"
            alt="streak"
          />
        </header>
      )
    }
    const totalUnreadCount = unReadCount && !isEmpty(unReadCount)
      ? Object.values(unReadCount).reduce((item, agg) => item + agg) : 0
    const { broker } = user_details
    const { dotColor, notifColor, routeToTab } = getDotColor(unReadCount, totalUnreadCount)
    const options = connectedBrokers.length < (Object.keys(BROKERS).length - 1)
      ? [...connectedBrokers, { label: 'Add new broker', name: 'addBroker' }]
      : connectedBrokers
    const showNotifPopup = pathname !== pathname_mapping.Notifications
    const isProfile = pathname === pathname_mapping.Account
    const themeIconProps = isDark ? {
      name: ICONS.SUN,
      size: 14,
    } : {
      name: ICONS.MOON_CRESCENT_FILLED,
      size: 12,
    }
    const isSytematicTradingPage = pathname === pathname_mapping.SystematicTrading.toLowerCase()
      || pathname.toLowerCase() === pathname_mapping.Ctb.toLowerCase()
    return (
      <header className={`mainHeader ${styles.header} ${showAuthHeader ? styles.headerAuth : isSytematicTradingPage ? styles.headerMarket : styles.headerUnAuth} ${isSytematicTradingPage ? styles.newHeaderHeight : styles.headerHeight}`} id="mainheader" ref={this.headerRef}>
        <ABLoginContainer
          visible={showABLogin}
          dismissKey="showABLogin"
          onDismiss={this.toggleModal}
          loginProps={{
            addBroker: this.addBroker,
            authModal: true,
          }}
        />
        <AlertPopup
          visible={brokerChangeAlert}
          dismissKey="brokerChangeAlert"
          onDismiss={this.toggleModal}
          {...this.alertProps}
        />
        <CustomModal
          visible={linkBrokerLogin}
          onDismiss={this.toggleModal}
          dismissKey="linkBrokerLogin"
          position="right"
          containerStyles={styles.linkBrokerModal}
        >
          <LinkBrokerLogin
            onDismiss={this.toggleModal}
            dismissKey="linkBrokerLogin"
            toggleAbLogin={this.toggleAbLogin}
          />
        </CustomModal>
        <div className={styles.headerContainer} id="headerContainer">
          {showAuthHeader && isMobile ? null : (
            <div id="logoSection" className={styles.logoSection}>
              {openScanOrder
                ? <img src={isDark ? LogoDark : Logo} id="headerLogo" alt="streak-logo" className={styles.logo} />
                : (
                  <Link
                    to={!showAuthHeader ? pathname_mapping.Home : pathname_mapping.Dashboard}
                    // style={{ backgroundImage: `url(${isSytematicTradingPage ?
                    // LogoDark : isDark ? LogoDark : Logo})` }}
                    style={{ backgroundImage: `url(${isDark ? LogoDark : Logo})`, marginLeft: `${(isSytematicTradingPage && !isMobile) ? '12vw' : '0'}` }}
                    className={styles.logoLink}
                    id="logoLink"
                  >
                    {/* <img src={} id="headerLogo" alt="streak-logo" className={styles.logo} /> */}
                  </Link>
                )}
            </div>
          )}
          {showAuthHeader
            ? (
              <>
                <NavBar
                  styles={styles}
                  isMobile={isMobile}
                  isDark={isDark}
                  updateGA={this.updateGA}
                  startTour={startTour}
                />
                {!isMobile && !isEmpty(user_details) && (
                  <div className={styles.leftSection}>
                    <div className={`${showNotifPopup ? styles.showNotifPopup : ''}`}>
                      <NavLink
                        to={routeToTab}
                        className={`${styles.navLinkNotif}`}
                        activeClassName={styles.activeNavLinkNotif}
                        onClick={() => { this.updateGA('Notification icon') }}
                      >
                        {/* className={styles.notifBtn} */}
                        <Icon name={ICONS.NOTIF_DASHBOARD} color={notifColor} size={18} />
                        {Boolean(totalUnreadCount) && <Dot styles={dotStyles} color={dotColor} />}
                      </NavLink>
                      {showNotifPopup && this.renderSlmNotifications()}
                    </div>
                    {validatingEmail ? <PlaceHolderLoader className={styles.brokerLoader} /> : (
                      <ModalDropdown
                        options={options}
                        labelKey="label"
                        valueKey="name"
                        selected={broker}
                        labelRender={this.brokerLabelRenderer}
                        onChangeOption={this.changeBroker}
                        inputRenderer={this.brokersInputRenderer}
                        containerStyles={styles.brokerContainer}
                        visible={showBrokerDropdown}
                        toggleCallback={this.toggleCallback}
                      >
                        <NavLink
                          to={isProfile ? pathname_mapping.Dashboard : pathname_mapping.profile}
                          style={{ color: theme.linkWhite }}
                          className={styles.routeToProfileBtn}
                          onClick={() => this.toggleCallback(false)}
                        >
                          {`Go to ${isProfile ? 'Dashboard' : 'Profile'}`}

                        </NavLink>
                        {/* {this.shouldDisplayTour(pathname, search) ? (
                          <button
                            type="button"
                            className={styles.takeTourBtn}
                            onClick={() => {
                              this.toggleCallback(false)
                              toggleTour(true)
                              this.updateGA('Take tour', 'Right menu')
                            }}
                          >
                            <CustomText weight="medium" color="linkWhite">Take tour</CustomText>
                          </button>
                        ) : null} */}

                        <button
                          type="button"
                          className={styles.switchThemeBtn}
                          onClick={this.toggleTheme}
                        >
                          <CustomText weight="medium" color="linkWhite">{`${isDark ? 'Light' : 'Dark'} Theme`}</CustomText>
                          <Icon
                            {...themeIconProps}
                            className={styles.themeIcon}
                            color={theme.textSecondary}
                          />
                        </button>
                        {lc > 10 && (
                          <Button
                            btnStyles={styles.switchDomainBtn}
                            onPress={this.toggleProductTour}
                          >
                            <CustomText weight="medium" color="linkWhite">Take tour</CustomText>
                          </Button>
                        )}
                        {/* {!broker || broker === BROKERS.NONE.name
                          || broker === BROKERS.ICICI.name ? null : (
                            <button
                              type="button"
                              className={styles.switchDomainBtn}
                              onClick={() => {
                                switchDomain()
                                this.updateGA('Switch version', 'v3')
                              }}
                            >
                              <CustomText weight="medium"
                              color="linkWhite">Switch to old version</CustomText>
                            </button>
                          )} */}
                      </ModalDropdown>
                    )}
                    {/* <NavLink
                  to={pathname_mapping.profile}
                  activeClassName={styles.profileImgBtn}
                > */}
                    {/* {this.inputRenderer()} */}
                    {/* </NavLink> */}
                    {lc <= 10
                      && (
                        <CustomTooltip
                          show
                          tooltipProps={{ text: 'Take a guided tour' }}
                          placement="bottom"
                        >
                          <Button
                            btnStyles={styles.toggleTour}
                            onPress={this.toggleProductTour}
                          >
                            <Icon
                              name={ICONS.TOUR}
                              size={22}
                            />
                          </Button>
                        </CustomTooltip>
                      )}
                  </div>
                )}

                {/* <ModalDropdown
              inputRenderer={this.inputRenderer}
              options={['logout']}
              onChangeOption={this.onChangeOption}
            /> */}
              </>
            )
            : this.renderHeaderRight()}
        </div>
      </header>
    )
  }
}

const stylesheet = ({
  header: {
    backgroundColor: theme.bgPrimary,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    // marginBottom: DIMENSIONS.HEIGHT * 0.06, // used in app also to fix aside
    position: 'sticky',
    top: '0',
    zIndex: '9999',
    padding: '5px 13px',
  },
  headerHeight: {
    height: 56,
  },
  headerHeightScrolled: {
    height: 62,
  },
  newHeaderHeight: {
    height: 60,
  },
  headerContainer: {
    display: 'flex',
    // justifyContent: 'space-between',
    height: '100%',
  },
  headerMarket: {
    backgroundColor: '#FFFFFF',
  },
  logoSection: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  logoLink: {
    backgroundSize: 'contain',
    width: 100,
    height: 24,
    backgroundRepeat: 'no-repeat',
  },
  logo: {
    height: 24,
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  menuBtn: {},
  imgContainer: {
    width: '30px',
    height: '30px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  headerLoginBtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    marginRight: SPACING.SPACE_20,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_28}`,
    '&:hover': {
      backgroundColor: theme.hover,
      borderColor: theme.hover,
    },
  },
  headerLoginBlueBtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    // marginRight: SPACING.SPACE_20,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_28}`,
    '&:hover': {
      backgroundColor: '#0000001F',
    },
  },
  newHeaderBtn: {
    '&:hover > span': {
      color: '#202DDA',
    },
    '& span': {
      fontWeight: 500,
      fontSize: `${SPACING.SPACE_14}`,
    },
  },
  headerBtnLabel: {
    color: '#000651',
    fontSize: `${SPACING.SPACE_12}`,
  },
  headerHelpBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_28}`,
    '&:hover': {
      backgroundColor: theme.hover,
      borderColor: theme.hover,
    },
  },
  headerHelpBlueBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_28}`,
    '&:hover': {
      backgroundColor: '#0000001F',
    },
  },
  headerAcademy: {
    display: 'flex',
    alignItems: 'center',
  },
  academyLogo: {
    width: 10,
    marginRight: SPACING.SPACE_8,
  },
  headerGetStarted: {
  },
  headerGetStartedBlue: {
    backgroundColor: '#445DFF',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    '&:hover': {
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
    },
    '& p': {
      fontSize: `${SPACING.SPACE_14} !important`,
    },
  },
  headerGetStartedBlueScrolled: {
    backgroundColor: '#445DFF',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    filter: 'drop-shadow(2px 2px 5px #445DFF)',
    '&:hover': {
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
    },
    '& p': {
      fontSize: `${SPACING.SPACE_14} !important`,
    },
  },
  // navBar styles
  headerNav: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    maxWidth: 340,
  },
  navLink: {
    // margin: 'auto 20px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    display: 'inline-flex',
    borderRadius: '8px',
    '&:hover ': {
      backgroundColor: theme.hover,
      '& > svg': {
        fill: theme.primaryLink,
      },
    },
  },
  ptNavLink: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    display: 'inline-flex',
    borderRadius: '8px',
    cursor: 'default',
  },
  activeNavLink: {
    '& $activeBar': {
      backgroundColor: theme.primaryLink,
      transition: 'all 0.8s',
    },
    '& > svg': {
      fill: theme.primaryLink,
    },
    '&:hover ': {
      backgroundColor: 'unset',
      '& > svg': {
        fill: theme.primaryLink,
      },
    },
  },
  profileImgBtn: {
    cursor: 'default',
  },
  activeBar: {
    height: 4,
    backgroundColor: theme.primaryLink,
    bottom: 0,
    position: 'absolute',
    transition: 'all 0.3s var(--anim-func-bouncy)',
  },
  imgContainerNav: {
    width: '30px',
    height: '30px',
    flex: 'unset',
  },
  navLinkNotif: {
    marginRight: SPACING.SPACE_12,
    position: 'relative',
    borderRadius: '50%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 35,
    width: 35,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  showNotifPopup: {
    position: 'relative',
    '&:hover': {
      '& $notifPopup': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  activeNavLinkNotif: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  currentBroker: {
    display: 'flex',
    alignItems: 'center',
    margin: `0 ${SPACING.SPACE_10} 0 0`,
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_8}`,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  logoImg: {
    width: 16,
    marginRight: SPACING.SPACE_8,
  },
  logoIcon: {
    marginRight: SPACING.SPACE_8,
  },
  brokerRow: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    minWidth: 180,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  linkBrokerModal: {
    height: '100%',
  },
  brokerInputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  routeToProfileBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    fontSize: FONTS.REGULAR,
    fontWeight: FONTWEIGHT.MEDIUM,
    marginTop: SPACING.SPACE_6,
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
  },
  routeToProfile: {
  },
  switchDomainBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
  },
  takeTourBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
  },
  switchThemeBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    '&:hover > svg': {
      fill: theme.linkWhite,
    },
  },
  takeTour: {
  },
  brokerContainer: {
    minWidth: 75,
  },
  brokerLoader: {
    maxWidth: '72px',
    height: '20px',
    marginRight: SPACING.SPACE_10,
  },
  notifPopup: {
    position: 'absolute',
    top: 34,
    backgroundColor: theme.dropdownBg,
    overflow: 'hidden',
    borderRadius: 5,
    visibility: 'hidden',
    opacity: 0,
    right: '-106px',
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    padding: `${SPACING.SPACE_10} 0`,
  },
  sideMenuContainer: {
    height: '100%',
    // padding: `${SPACING.SPACE_28} 0 ${SPACING.SPACE_20}`,
    textAlign: 'center',
    position: 'relative',
    '& > button': {
      marginBottom: SPACING.SPACE_16,
    },
  },
  newSideMenuContainer: {
    height: '75%',
    textAlign: 'center',
    position: 'relative',
    '& > button': {
      marginBottom: SPACING.SPACE_16,
    },
  },
  quickNavToggleBtn: {
    padding: `${SPACING.SPACE_10} 0`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  logoHeaderImg: {
    height: '24px',
    width: '70px',
    margin: '12px 0 0px 30px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  scrolledGetStartedButton: {
    border: 'solid 4px transparent',
    borderRadius: 8,
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_20}`,
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #EF9CFF, #E0DE2D)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    fontWeight: FONTWEIGHT.MEDIUM,
  },
  toggleTour: {
    paddingBottom: 0,
  },
  '@media only screen and (max-width: 786px)': {
    headerNav: {
      margin: '0 auto',
      maxWidth: 500,
    },
    headerAuth: {
      top: 'unset',
      position: 'fixed',
      width: '100%',
      marginBottom: 0,
      bottom: 0,
      zIndex: 9999,
    },
    navLink: {
    },
    headerGetStartedMobile: {
      borderWidth: '0 0 1px 0',
      borderRadius: 0,
      padding: '2px 4px',
      marginRight: 20,
    },
    headerAcademy: {
      margin: 'auto',
    },
    sideMenuHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: 30,
    },
    newSideMenuHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${SPACING.SPACE_10}`,
      marginRight: `${SPACING.SPACE_20}`,
    },
    newHomeBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    sideMenuBtn: {
      padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10} ${SPACING.SPACE_4} ${SPACING.SPACE_10}`,
      backgroundColor: '#e6e7ff',
      borderRadius: `${SPACING.SPACE_8}`,
    },
    sideMenuBtnClose: {
      // position: 'absolute',
      top: 6,
      right: 0,
      position: 'relative',
      padding: '10px 12px 4px',
    },
    sideMenuContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: 'calc(100% - 40px)',
      '& button': {
        padding: 10,
        textAlign: 'left',
        borderBottom: `1px solid ${theme.borderColor}`,
        margin: '0 20px',
        width: 'calc(100% - 40px)',
        boxSizing: 'border-box',
      },
    },
    smLoginBtn: {
      marginBottom: `${SPACING.SPACE_16} !important`,
      textAlign: 'center !important',
    },
    smGetStarted: {
      textAlign: 'center !important',
      marginBottom: `${SPACING.SPACE_20} !important`,
      boxShadow: '0px 10px 24px #0022FF3B',
    },
    headerLoginBtn: {
      marginRight: 0,
    },
    imgContainerNav: {
      width: '26px',
      height: '26px',
    },
  },
  // '@media only screen and (max-width:1370px)': {
  //   leftSection: {
  //     marginRight: '75px !important',
  //   },
  // },
})

const mapStateToProps = (state) => {
  return {
    unReadCount: state.notification.unReadCount,

    connectedBrokers: state.auth.connectedBrokers,
    user_details: state.auth.user_details,
    validatingEmail: state.auth.validatingEmail,

    fancyBroker: state.auth.fancyBroker,

    ptSideBar: state.common.ptSideBar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleProductTourSideBar: status => dispatch(togglePtSideBar(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(ScreenHeader))
