/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react'
// import ReactPlayer from 'react-player'
import { COLORS } from '../../Theme'
import Button from '../../UI/Button'
import { DISPLAY_DEVICE } from '../../utils/consts'
import HOME_ASSETS from '../assets'
import { brokerImgMap } from './Brokers'

const Banner = React.memo(({
  onPressTrial, isMobile, displayDevice, updateGA,
}) => {
  const [state, setState] = React.useState({
    pip: false,
    playing: true,
    controls: false,
    light: false,

    muted: true,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    volume: 1,
    loop: false,
    seeking: false,
  })

  const {
    playing,
  } = state

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing })
  }

  const playerRef = React.useRef(null)
  const bgImg = displayDevice === DISPLAY_DEVICE.TAB.valueKey ? HOME_ASSETS.VideoBackgroundTab : displayDevice === DISPLAY_DEVICE.MOBILE.valueKey === 'mobile' ? HOME_ASSETS.VideoBackgroundTab : HOME_ASSETS.VideoBackground

  //   return (
  //     <div className="homeContainerMain">
  //       {/* <img src={displayDevice === DISPLAY_DEVICE.SMALL_MOBILE.valueKey ? HOME_ASSETS.VideoBackgroundMobile : isMobile ? HOME_ASSETS.VideoBackgroundTab : HOME_ASSETS.VideoBackground} className="featureBackgroundImg" alt="feature_background" /> */}
  //       <div className="bannerFeatureContainer" style={{ width: '100%', flexDirection: 'column', backgroundImage: `url(${bgImg})` }}>
  //         <div className="bannerCenterContent">
  //           <h1 className="startedTitle">
  //             Trading is now EASY
  //           </h1>
  //           <p className="startedSubTitle">
  //             Excel Your Trading Journey With Streak
  //           </p>
  //           <Button
  //             mode="text"
  //             buttonColor="START_FREE_TRIAL_BUTTON"
  //             labelColor="white"
  //             text="Start Free Trial"
  //             btnStyles="getStartedBtn"
  //             labelSyle="getStartedLabel"
  //             onPress={onPressTrial}
  //           />
  //         </div>
  //         {/* <div className="videoContainer"> */}
  //         <img src={HOME_ASSETS.HeroImg} alt="HERO IMG" className="videoContainer" />
  //         <div className="aboutUsContainer" style={{ width: '65%', alignItems: 'flex-start' }}>
  //           <h2 className="aboutBrokerText" style={{ fontSize: '22px !important' }}>
  //             Trusted by Top Brokers in India.
  //           </h2>
  //           <div className="brokerImgContainer">
  //             {brokerImgMap.map((data) => {
  //               const { brokerName, imgSrc, width = isMobile ? '200px' : '150px' } = data
  //               return <img src={imgSrc} alt={`${brokerName} img`} width={width} key={brokerName} style={{ paddingTop: isMobile && brokerName === '5Paisa' ? '15px' : '0px' }} className="brokerImg" />
  //             })}
  //           </div>
  //         </div>
  //         {/* <ReactPlayer
  //             ref={playerRef}
  //             width="100%"
  //             height="100%"
  //             playing={playing}
  //             className="heroSectionVideo"
  //             url="https://www.youtube.com/watch?v=oiz6m4h_4kE"
  //             config={{
  //               youtube: {
  //                 playerVars: { showinfo: 1 },
  //               },
  //             }}
  //           />
  //           <button type="button" onClick={handlePlayPause} className="playBtn"><img src={HOME_ASSETS.Play} alt="play" width="80px" /></button> */}
  //         {/* </div> */}
  //       </div>
  //     </div>
  //   )
  // })

  return (
    <div className="homeContainerWrapper">
      <div className="homeContainerMain">
        <div className="bannerFeatureContainer">
          <img src={isMobile ? HOME_ASSETS.HeroImgMobile : HOME_ASSETS.HeroImgNew} alt="HERO IMG" className="videoContainer" />
          <div className="bannerCenterContent">
            <h1 className="startedTitle">
              Trading is now EASY
            </h1>
            {isMobile && <div className="mobileDivison" />}
            <p className="startedSubTitle">
              Excel Your Trading Journey With Streak
            </p>
            <Button
              mode="text"
              buttonColor="START_FREE_TRIAL_BUTTON"
              labelColor="white"
              text="Get Started"
              btnStyles="getStartedBtn"
              labelStyle="getStartedLabel"
              onPress={() => {
                updateGA('Get Started Hero', 'Hero Section', 1, 'Landing Page')
                updateGA('Log in', 'SystematicTrading', 1, 'Home')
                onPressTrial()
              }}
            />
            {!isMobile && (
              <div className="brokerList">
                {brokerImgMap.map((data) => {
                  const { brokerName, imgSrc } = data
                  return (
                    <div style={{ backgroundColor: '#ffffff', display: 'flex', borderRadius: '8px' }}>
                      <img src={imgSrc} alt={`${brokerName} img`} key={brokerName} style={{ paddingTop: isMobile && brokerName === '5Paisa' ? '15px' : '0px' }} className="bannerBrokerImg" />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {/* <div className="videoContainer"> */}

          {/* <ReactPlayer
          ref={playerRef}
          width="100%"
          height="100%"
          playing={playing}
          className="heroSectionVideo"
          url="https://www.youtube.com/watch?v=oiz6m4h_4kE"
          config={{
            youtube: {
              playerVars: { showinfo: 1 },
            },
          }}
        />
        <button type="button" onClick={handlePlayPause} className="playBtn"><img src={HOME_ASSETS.Play} alt="play" width="80px" /></button> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  )
})

export default Banner
