import {
  GET_STOCKS_INIT,
  GET_STOCKS_SUCCESS,
  GET_STOCKS_FAILURE,
  UPDATE_PREFS_INIT,
  UPDATE_PREFS_SUCCESS,
  UPDATE_PREFS_FAILURE,
  DEPLOY_ALGO_INIT,
  DEPLOY_ALGO_SUCCESS,
  DEPLOY_ALGO_FAILURE,
  CLEAR_RESP,
  GET_ALGO_STATE_INIT,
  GET_ALGO_STATE_SUCCESS,
  GET_ALGO_STATE_FAILURE,
  CLEAR_ALGO_STATE_RESP,
  GET_INDICATORS_INIT,
  GET_INDICATORS_SUCCESS,
  GET_INDICATORS_FAILURE,
  GET_TRAINER_MODEL_INIT,
  GET_TRAINER_MODEL_SUCCESS,
  GET_TRAINER_MODEL_FAILURE,
  CLEAR_LAUNCH_PARAMS,
  SET_LAUNCH_PARAMS,
  UPDATE_SCRIPLIST,
  COPY_ALGO,
  COPY_ALGO_SUCCESS,
  COPY_ALGO_FAILURE,
  GET_DEFAULT_STOCKS,
  GET_DEFAULT_STOCKS_FAILURE,
  GET_DEFAULT_STOCKS_SUCCESS,
  GET_ALGO_FROM_TOKEN,
  GET_ALGO_FROM_TOKEN_SUCCESS,
  GET_ALGO_FROM_TOKEN_FAILURE,
} from './actionTypes'

export function getStocksSuccess(data) {
  return {
    type: GET_STOCKS_SUCCESS,
    data,
  }
}

export function getStocksFailure(error) {
  return {
    type: GET_STOCKS_FAILURE,
    error,
  }
}

export function getStocks(
  searchQuey,
  headers,
  user_uuid,
  baskets = false,
  exchanges = ['NSE', 'NFO', 'CDS'],
) {
  return {
    type: GET_STOCKS_INIT,
    searchQuey,
    headers,
    user_uuid,
    baskets,
    exchanges,
  }
}

export function updatePrefsSuccess(data) {
  return {
    type: UPDATE_PREFS_SUCCESS,
    data,
  }
}

export function updatePrefsFailure(error) {
  return {
    type: UPDATE_PREFS_FAILURE,
    error,
  }
}

export function updatePrefs(params, headers) {
  return {
    type: UPDATE_PREFS_INIT,
    params,
    headers,
  }
}

export function deployAlgoSuccess(data) {
  return {
    type: DEPLOY_ALGO_SUCCESS,
    data,
  }
}

export function deployAlgoFailure(error) {
  return {
    type: DEPLOY_ALGO_FAILURE,
    error,
  }
}

export function deployAlgo(params, headers) {
  return {
    type: DEPLOY_ALGO_INIT,
    params,
    headers,
  }
}

export function clearAlgoResp() {
  return {
    type: CLEAR_RESP,
  }
}

export function getAlgoStateSuccess(data) {
  return {
    type: GET_ALGO_STATE_SUCCESS,
    data,
  }
}

export function getAlgoStateFailure(error) {
  return {
    type: GET_ALGO_STATE_FAILURE,
    error,
  }
}

export function getAlgoState(algo_uuid, headers) {
  return {
    type: GET_ALGO_STATE_INIT,
    algo_uuid,
    headers,
  }
}

export function clearAlgoStateResp() {
  return {
    type: CLEAR_ALGO_STATE_RESP,
  }
}

export function getIndicatorsSuccess(data) {
  return {
    type: GET_INDICATORS_SUCCESS,
    data,
  }
}

export function getIndicatorsFailure(error) {
  return {
    type: GET_INDICATORS_FAILURE,
    error,
  }
}

export function getIndicators(headers) {
  return {
    type: GET_INDICATORS_INIT,
    headers,
  }
}

export function getTrainerModelSuccess(data) {
  return {
    type: GET_TRAINER_MODEL_SUCCESS,
    data,
  }
}

export function getTrainerModelFailure(error) {
  return {
    type: GET_TRAINER_MODEL_FAILURE,
    error,
  }
}

export function getTrainerModel(headers) {
  return {
    type: GET_TRAINER_MODEL_INIT,
    headers,
  }
}

export function clearLaunchParams() {
  return {
    type: CLEAR_LAUNCH_PARAMS,
  }
}

export function setLaunchParams(url) {
  return {
    type: SET_LAUNCH_PARAMS,
    data: url,
  }
}

export function updateScripList(list) {
  return {
    type: UPDATE_SCRIPLIST,
    list,
  }
}

export function copyAlgo(params) {
  return {
    type: COPY_ALGO,
    params,
  }
}

export function copyAlgoSuccess(data) {
  return {
    type: COPY_ALGO_SUCCESS,
    data,
  }
}

export function copyAlgoFailure(error) {
  return {
    type: COPY_ALGO_FAILURE,
    error,
  }
}

export function getDefaultStocks(params) {
  return {
    type: GET_DEFAULT_STOCKS,
    params,
  }
}

export function getDefaultStocksSuccess(data) {
  return {
    type: GET_DEFAULT_STOCKS_SUCCESS,
    data,
  }
}

export function getDefaultStocksFailure(error) {
  return {
    type: GET_DEFAULT_STOCKS_FAILURE,
    error,
  }
}

export function getAlgoFromToken(params, headers) {
  return {
    type: GET_ALGO_FROM_TOKEN,
    params,
    headers,
  }
}

export function getAlgoFromTokenSuccess(data) {
  return {
    type: GET_ALGO_FROM_TOKEN_SUCCESS,
    data,
  }
}

export function getAlgoFromTokenFailure(error) {
  return {
    type: GET_ALGO_FROM_TOKEN_FAILURE,
    error,
  }
}
