import React from 'react'
import HOME_ASSETS from '../assets'

const FeaturesInfoResponsive = React.memo(({
  content, index, updateIndex, onPressTrial, updateGA,
}) => {
  const onNextClick = () => {
    // prevIndex = index
    if(index === 3) {
      updateIndex(0)
    }else{
      updateIndex(index + 1)
    }
  }

  const onPrevClick = () => {
    // prevIndex = index
    if(index === 0) {
      updateIndex(3)
    }else{
      updateIndex(index - 1)
    }
  }
  return (
    <div className="infoContainerMobile">
      <div className="infoBannerRight">
        <div className="arrowBtnsContainer">
          <button
            type="button"
            onClick={onPrevClick}
            className="left-arrow"
          >
            <img src={HOME_ASSETS.BackwardArrow} alt="back" />
          </button>
          <button
            type="button"
            onClick={onNextClick}
            className="right-arrow"
          >
            <img src={HOME_ASSETS.ForwardArrow} alt="forward" />
          </button>
        </div>
        {content.map((item, i) => {
          const { label, video } = item
          //   const classes = ''
          //   const active = i === index
          //   if (active) {
          //     classes = prevIndex > i && prevIndex
          // !== content.length - 1 && i !== 0 ? 'scrollFromLeft' : 'scrollFromRight'
          //   } else if (i === prevIndex) {
          //     classes = index > i || (i ==
          // = content.length - 1 && index === 0) ? 'scrollToLeft' : 'scrollToRight'
          //   }
          return (
            <>
              <div key={label} className={`infoBannerRightContent ${index !== i ? 'scrollFromLeft' : 'scrollFromRight'}`} style={{ height: '100%' }}>
                <video
                  src={video}
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: 12, width: '100%', height: '100%',
                  }}
                />
              </div>
            </>
          )
        })}
        <div className="infoBannerRightButtons">
          {content.map((item, i) => {
            const { label } = item
            return (
              <input
                key={label}
                onClick={() => { updateIndex(i) }} // prevIndex = index
                type="button"
                className={`${index === i ? 'activeButton' : 'inactiveButton'}`}
              />
            )
          })}
        </div>
      </div>
      <div className="infoTextContainerMobile">
        <h1 style={{ fontSize: '28px', marginBottom: '30px', fontFamily: 'multi-display-bold' }}>{content[index].label}</h1>
        <p style={{ fontSize: '22px', fontFamily: 'nobel-light' }}>{content[index].subTitle}</p>
        <p style={{ fontSize: '18px', fontFamily: 'nobel-light' }}>{content[index].description}</p>
        <button onClick={() => { updateGA('Start Trial B', 'Tools', 1, 'Landing Page'); onPressTrial() }} type="button" style={{ width: 'fit-content', alignSelf: 'flex-start' }}>
          <p className="learnMore">Start Trial</p>
        </button>
      </div>
    </div>
  )
})

export default FeaturesInfoResponsive
