import store from '../../store'
import { BROKERS } from '../consts'

export const ticksProperty = {
  tick: 'tick',
  ltp: 'ltp',
  change: 'change',
}

export const getLTPChangeValue = ({ seg_sym, label }) => {
  const reduxState = store.getState()

  const { ticks, seg_sym_list } = reduxState.instruments
  const { user_details: { broker } } = reduxState.auth

  if(ticks && seg_sym_list && label) {
    let tick
    if(broker === BROKERS.ZERODHA.name) {
      tick = ticks[seg_sym_list[seg_sym]]
    }else {
      tick = ticks[seg_sym]
    }
    if(tick) {
      if(label === ticksProperty.tick) {
        return tick
      }
      if(label === ticksProperty.ltp) {
        return tick.last_price || 0
      }
      if(label === ticksProperty.change) {
        return tick.change || 0
      }
    }
  }
  return 0
}
