import React, { Component } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import CustomText from '../../UI/CustomText'
// import fTree from './tree.json'
import Icon from '../../UI/Icon'
import IndicatorSection from './IndicatorSection'
import CustomInput from '../../UI/CustomInput'
import Switch from '../../UI/Switch'
// import ModalDropdown from '../../UI/Dropdown/ModalDropdown'
import { showSnackbar } from '../../UI/Snackbar'

import {
  ICONS, COLORS, SPACING, theme,
} from '../../Theme'
import {
  candle_freq_map, decimalRegex, indicatorPattern,
} from '../../utils/consts'
import { CONDITION_TYPE, validity, logicToStringGenerator } from '../../containers/Create/Create-utils'
import { withTheme } from '../../Theme/ThemeProvider'
import { getLocalStorage, setLocalStorage } from '../../utils/common'

let treeFromFuncGrp = {}

class ConditionRenderer extends Component {
  state = {
    funcGrpLength: 0,
  }

  // componentDidMount() {
  // if (isEmpty(treeFromFuncGrp)) {
  // this.updateFunGrpTree()
  // }
  // }

  // componentDidUpdate(prevProps) {
  //   const { indicatorListUpdated } = this.props
  //   if (indicatorListUpdated !== prevProps.indicatorListUpdated && indicatorListUpdated) {
  //     this.updateFunGrpTree()
  //   }
  // }

  updateFunGrpTree = () => {
    const { indicatorsList } = this.props
    Object.keys(indicatorsList).forEach((itemKey) => {
      const item = indicatorsList[itemKey]
      const oldTree = treeFromFuncGrp[item.function_group] || {}
      treeFromFuncGrp = {
        ...treeFromFuncGrp,
        [item.function_group]: {
          ...oldTree,
          [itemKey]: item,
        },
      }
    })
    this.setState({ funcGrpLength: Object.keys(treeFromFuncGrp).length })
  }

  handleConditionAddition = () => {
    const {
      expressions, andOrs, editType, handleConditionAddition,
    } = this.props
    const lastEntry = expressions.slice(-1)[0]
    if (
      lastEntry.indicator === 'Indicator'
      || lastEntry.indicator === ''
      || lastEntry.compareIndicator === 'Indicator'
      || lastEntry.comparator === ''
    ) {
      showSnackbar('Complete the previous Condition')
    } else if (lastEntry && lastEntry.indicatorItem
      && lastEntry.indicatorItem.name === 'Trade At Price') {
      showSnackbar('Only one indicator is allowed for At Price')
    } else {
      handleConditionAddition(expressions, editType, andOrs)
    }
  }

  onAddCondition = () => {
    const {
      toggleExitConditionText, editType, showExitIndicator,
    } = this.props
    if (editType === CONDITION_TYPE.EXIT && !showExitIndicator) {
      toggleExitConditionText()
      return
    }
    this.handleConditionAddition()
  }

  renderAddBtn = () => {
    const {
      styles, showExitIndicator, editType, isDark,
    } = this.props
    return (
      <button
        type="button"
        onClick={this.onAddCondition}
        className={styles.addConditionBtn}
        style={{ backgroundColor: isDark ? COLORS.BLACK_100 : COLORS.ORANGE_000 }}
      >
        <Icon
          name={ICONS.ADD}
          size={14}
          color={COLORS.ORANGE}
        />
        &nbsp;
        &nbsp;
        <CustomText weight="semi_bold" color="orange">
          {editType === CONDITION_TYPE.EXIT && !showExitIndicator
            ? 'Add Exit Condition(Optional)' : 'Add Another Condition'}
        </CustomText>
      </button>
    )
  }

  deleteIndicator = (id, index) => {
    const {
      editType: type, exitValid, conditionsValid,
      expressions, andOrs, copiedCondition, updateCopiedCondition,
    } = this.props
    let modifiedIndicator = [...expressions]
    let valid = true
    let extraChanges = {}
    if (modifiedIndicator.length === 1) {
      extraChanges = type === CONDITION_TYPE.EXIT ? { showExitIndicator: false } : {}
      modifiedIndicator[0] = {
        ...indicatorPattern,
        id: Date.now(),
      }
      valid = false
    } else {
      modifiedIndicator = modifiedIndicator.filter(indi => indi.id !== id)
      if (modifiedIndicator.length === 1) {
        const lastEntry = modifiedIndicator[0]
        if (lastEntry.indicator === 'Indicator'
          || lastEntry.indicator === ''
          || lastEntry.compareIndicator === 'Indicator'
          || lastEntry.comparator === '') {
          valid = false
        }
      }
    }
    if (type === CONDITION_TYPE.EXIT) {
      valid = exitValid
    }
    conditionsValid(modifiedIndicator, type, andOrs, valid, extraChanges)
    if (index === copiedCondition.copiedIndex) updateCopiedCondition({ copiedCondition: {} })
  }

  updateAndOr = (value, i) => {
    const { handleAndOrChange, editType } = this.props
    handleAndOrChange(editType, i, value)
  }

  addIndicatorDetails = (details) => {
    const {
      trainerModel, conditionsValid, showExitIndicator, positionType, // assistMode
      exitIndicators, editType, expressions, andOrs,
    } = this.props
    const isEntry = editType === CONDITION_TYPE.ENTRY
    const modifiedData = [...expressions]

    const len = modifiedData.length
    const extraChanges = {}
    if (isEntry) {
      extraChanges.modExitindicators = [...exitIndicators]
    }
    let isValid = false
    if (
      (modifiedData.length >= 2 || editType === CONDITION_TYPE.EXIT)
      && details.indicatorItem
      && details.indicatorItem.name === 'Trade At Price'
    ) {
      const msg = editType === CONDITION_TYPE.EXIT ? 'At Price is not allowed for exit condition'
        : 'Only one indicator is allowed for At Price'
      showSnackbar(msg)
      modifiedData.pop()
      indicatorPattern.id = Date.now()
      modifiedData.push(indicatorPattern)
    } else {
      const { id } = details
      const index = modifiedData.findIndex(indicator => indicator.id === id)
      if (index !== -1) {
        modifiedData[index] = { ...modifiedData[index], ...details }
      } else {
        modifiedData.pop()
        modifiedData.push(details)
      }
      if (details.indicatorValid && validity(details)) {
        isValid = true
      }
      if (isEntry) {
        // as traditional create tree has class and new tree has key
        const key = details.indicatorItem
          && (details.indicatorItem.key || details.indicatorItem.class)
        if (showExitIndicator && details.assisted && len < 2) {
          const exitIndis = trainerModel[key][
            positionType.toUpperCase()
          ][0].exitIndicators
          extraChanges.modExitindicators = exitIndis
        }
      }
      // if (details.assisted) {
      //   assistMode = true
      // }
    }
    conditionsValid(modifiedData, editType, andOrs, isValid, extraChanges)
  }

  onCopy = ({ index }) => {
    const {
      expressions, editType,
      // updateCopiedCondition
    } = this.props
    // updateCopiedCondition({
    //   copiedCondition: {
    //     conditions: cloneDeep(expressions[index]), copiedEditType: editType, copiedIndex: index,
    //   },
    // })
    setLocalStorage({
      copiedConditionCookie: JSON.stringify({
        conditions: cloneDeep(expressions[index]),
        copiedIndex: index,
        copiedEditType: editType,
        mode: 'basic',
      }),
      copiedExpression: JSON.stringify(expressions),
    })
    showSnackbar('Condition copied successfully', {}, 1)
    try {
      navigator.clipboard.writeText(logicToStringGenerator([expressions[index]], []))
    } catch(err) {
      console.log(err.message, 'err getting string')
    }
  }

  onPaste = ({ index }) => {
    const {
      expressions, conditionsValid, editType, exitIndicators, showExitIndicator,
      trainerModel, positionType, andOrs,
    } = this.props
    const cookie = getLocalStorage('copiedConditionCookie')
    const parsedCondition = JSON.parse(cookie)
    const {
      copiedEditType,
      mode,
      conditions,
    } = parsedCondition
    if(mode === 'advance') {
      showSnackbar('conditions copied in Advance mode cannot be pasted in Basic mode')
      return
    }
    if (!parsedCondition || (parsedCondition && !parsedCondition.conditions)) {
      showSnackbar('Copied condition is invalid', {}, 0)
      return
    }
    if(editType === 'expressions' && copiedEditType !== 'expressions') {
      showSnackbar('conditions copied in Basic mode startegy cannot be pasted in Basic mode scanner')
      return
    }
    if(editType !== 'expressions' && copiedEditType === 'expressions') {
      showSnackbar('conditions copied in Basic mode scanner cannot be pasted in Basic mode strategy')
      return
    }
    const isEntry = editType === CONDITION_TYPE.ENTRY
    const modifiedData = [...expressions]

    const len = modifiedData.length
    const extraChanges = {}
    if (isEntry) {
      extraChanges.modExitindicators = [...exitIndicators]
    }
    let isValid = false
    if (
      (modifiedData.length >= 1 || editType === CONDITION_TYPE.EXIT)
      && conditions.indicatorItem
      && conditions.indicatorItem.name === 'Trade At Price'
    ) {
      const msg = editType === CONDITION_TYPE.EXIT ? 'At Price is not allowed for exit condition'
        : 'Only one indicator is allowed for At Price'
      showSnackbar(msg)
    } else {
      modifiedData[index] = { ...conditions, id: Date.now(), disabled: false }

      if (conditions.indicatorValid && validity(conditions)) {
        isValid = true
      }
      if (isEntry) {
        // as traditional create tree has class and new tree has key
        const key = conditions.indicatorItem
          && (conditions.indicatorItem.key || conditions.indicatorItem.class)
        if (showExitIndicator && conditions.assisted && len < 2) {
          const exitIndis = trainerModel[key][
            positionType.toUpperCase()
          ][0].exitIndicators
          extraChanges.modExitindicators = exitIndis
        }
      }
      // if (details.assisted) {
      //   assistMode = true
      // }
      conditionsValid(modifiedData, editType, andOrs, isValid, extraChanges)
    }
  }

  toggleDisableCondition = ({ index, disabled }) => {
    const {
      expressions, conditionsValid, editType, andOrs,
    } = this.props
    const modifiedData = [...expressions]
    modifiedData[index] = { ...expressions[index], disabled }
    conditionsValid(modifiedData, editType, andOrs)
  }

  renderConditions = () => {
    const {
      editType, showExitIndicator, candleInterval, positionType = '', comparatorsList,
      trainerModel, assist, indicatorsList, showError, styles,
      expressions, andOrs, createStrategy, isDark, updateGA,
    } = this.props
    if (editType === CONDITION_TYPE.EXIT && !showExitIndicator) {
      return null
    }
    let cookie = {}
    const copiedConditionCookie = getLocalStorage('copiedConditionCookie')
    if(copiedConditionCookie) {
      cookie = copiedConditionCookie
    }
    const parsedCondition = cookie.length ? JSON.parse(cookie) : {}
    const {
      copiedIndex,
      copiedEditType,
    } = parsedCondition || {}
    let showPaste = false
    if(!isEmpty(cookie)) {
      if(parsedCondition && parsedCondition.conditions) {
        showPaste = true
      }
    }
    const intervalIndex = candle_freq_map[candleInterval]
    return expressions.map((indicator, i) => {
      const andor = andOrs[i]
      const showAndOr = i !== 0
      // const editable = (editMode || assistMode) && this.validity(indicator)
      return (
        <IndicatorSection
          key={`${indicator.id}`}
          showAndOr={showAndOr}
          andor={andor}
          candleIntervalIndex={intervalIndex}
          trainerModel={trainerModel}
          addIndicatorDetails={this.addIndicatorDetails}
          indicatorState={indicator}
          disabled={indicator.disabled}
          removeIndicator={this.deleteIndicator}
          handleAndOrChange={this.updateAndOr}
          // edit={editable}
          assist={assist}
          index={i}
          position={positionType.toUpperCase()}
          styles={styles}
          comparatorsList={comparatorsList}
          indicatorsList={indicatorsList}
          showError={showError}
          createStrategy={createStrategy}
          editType={editType}
          CONDITION_TYPE={CONDITION_TYPE}
          isDark={isDark}
          theme={theme}
          funcGrpLength={this.state.funcGrpLength}
          treeFromFuncGrp={treeFromFuncGrp}
          copiedIndex={copiedIndex}
          showPaste={showPaste}
          copiedEditType={copiedEditType}
          onCopy={this.onCopy}
          onPaste={this.onPaste}
          toggleDisableCondition={this.toggleDisableCondition}
          updateGA={updateGA}
        />
      )
    })
  }

  onTpslErrChange = (e, field) => {
    const { onSlErrChange, onTpErrChange } = this.props
    const { value } = e.target
    if (field === 'sl') onSlErrChange(value)
    if (field === 'tp') onTpErrChange(value)
  }

  onTpslChange = (e, field) => {
    const { onSlChange, onTpChange } = this.props
    const { value } = e.target
    if (field === 'sl') onSlChange(value)
    if (field === 'tp') onTpChange(value)
  }

  renderSltp = () => {
    const {
      showError, stopLoss, stopLossErr, takeProfit, takeProfitErr,
      styles, slLabel, tpLabel,
    } = this.props
    return (
      <div className={styles.tpslCard}>
        <div className={styles.tpsl}>
          <CustomText weight="medium" color="red">{slLabel}</CustomText>
          &nbsp;
          &nbsp;
          <div className={styles.tpslRow}>
            <CustomInput
              inputError={(showError && !stopLoss) || stopLossErr}
              value={stopLoss}
              onChangeText={this.onTpslChange}
              onBlur={this.onTpslErrChange}
              inputKey="sl"
              inputStyles={styles.fieldInput}
              containerStyles={styles.fieldInputContainer}
              wrapperStyles={styles.fieldInputWrapper}
              placeholder="00"
              inputRegx={new RegExp(decimalRegex)}
            />
            {/* {(showError && !stopLoss) || stopLossErr ? (
              <CustomText size="tiny" flex={1} style={{ textAlign: 'right' }}>
                {'This field is mandatory  '}
                <Icon name={ICONS.WARN} size={12}
                color={COLORS.RED} style={{ verticalAlign: 'sub' }} />
              </CustomText>
            ) : null} */}
          </div>
        </div>
        <div className={styles.tpsl}>
          <CustomText weight="medium" color="green">{tpLabel}</CustomText>
          &nbsp;
          &nbsp;
          <div className={styles.tpslRow}>
            <CustomInput
              inputError={(showError && !takeProfit) || takeProfitErr}
              value={takeProfit}
              onChangeText={this.onTpslChange}
              onBlur={this.onTpslErrChange}
              inputKey="tp"
              inputStyles={styles.fieldInput}
              containerStyles={styles.fieldInputContainer}
              wrapperStyles={styles.fieldInputWrapper}
              inputRegx={new RegExp(decimalRegex)}
              placeholder="00"
            />
            {/* {(showError && !takeProfit) || takeProfitErr ? (
              <CustomText size="tiny" flex={1} style={{ textAlign: 'right' }}>
                {'This field is mandatory  '}
                <Icon name={ICONS.WARN} size={12} color={COLORS.RED}
                style={{ verticalAlign: 'sub' }} />
              </CustomText>
            ) : null} */}
          </div>
        </div>
      </div>
    )
  }

  // renderSltpType = () => {
  //   const { styles, tpsl_type, onSltpTypeChange } = this.props
  //   return (
  //     <div className={styles.row}>
  //       <CustomText weight="semi_bold" size="medium">TPSL type</CustomText>
  //       <ModalDropdown
  //         selected={tpsl_type}
  //         options={TPSL_TYPES}
  //         changeKey="tpsl_type"
  //         onChangeOption={onSltpTypeChange}
  //         containerStyles={styles.dropDownContainer}
  //       />
  //     </div>
  //   )
  // }

  render() {
    const {
      assist, toggleAssist, title, showSltp, styles,
      editType,
    } = this.props
    return (
      <div className={styles.conditionContainer}>
        <div className={styles.header}>
          <CustomText size="medium" weight="semi_bold" flex={1}>
            {title}
          </CustomText>
          {(editType === CONDITION_TYPE.ENTRY || editType === CONDITION_TYPE.SCREENER) && (
          <div className={styles.assist}>
            <CustomText weight="regular" color={assist ? 'linkColor' : 'text'} size="small">Assist</CustomText>
            <Switch
              value={assist}
              onValueChange={toggleAssist}
              id="createAssist"
            />
          </div>
          )}
        </div>
        {showSltp && this.renderSltp()}
        {this.renderConditions()}
        {this.renderAddBtn()}
      </div>
    )
  }
}

const stylesheet = ({
  conditionContainer: {
    margin: `${SPACING.SPACE_40} 0 0`,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  assist: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  addConditionBtn: {
    borderRadius: 6,
    borderColor: COLORS.ORANGE,
    borderWidth: 2,
    borderStyle: 'dashed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 55,
    width: '100%',
    marginTop: SPACING.SPACE_28,
  },
  addConditionView: {
    alignItems: 'center',
  },
  tpslCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${SPACING.SPACE_24}`,
    background: theme.bgPrimary,
    borderRadius: '10px',
    marginTop: SPACING.SPACE_12,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
  },
  tpsl: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  tpslRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginRight: '10%',
  },
  fieldInput: {
    border: `1px solid ${theme.inputBorder}`,
    borderRadius: 4,
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  fieldInputWrapper: {
    flex: 1,
    maxWidth: 200,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: SPACING.SPACE_8,
  },
  inputContainer: {
    width: 80,
    borderRadius: 8,
    borderColor: theme.borderColor,
    borderWidth: 1,
    padding: 0,
    margin: 0,
    backgroundColor: theme.screenBg, // isDark ? COLORS.BLACK_100 : ,
    marginRight: SPACING.SPACE_16,
  },
  input: {
    margin: 0,
    paddingVertical: SPACING.SPACE_8,
    paddingHorizontal: SPACING.SPACE_10,
  },
  '@media only screen and (max-width: 786px)': {
    tpslCard: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: SPACING.SPACE_20,
    },
    tpsl: {
      justifyContent: 'space-between',
    },
  },
})

export default withTheme(stylesheet)(ConditionRenderer)
