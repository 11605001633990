import makeStyles from '@material-ui/styles/makeStyles'
import React, { useCallback, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'

import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import ViewAllCard from '../../UI/ViewAllCard'
import PortfolioSidebarCondensed from '../Portfolio/PortfolioSidebarCondensed'

import { useTheme } from '../../Theme/ThemeProvider'
import {
  SPACING, ICONS, theme, ASSETS, COLORS, // COLORS,
} from '../../Theme'
import { pathname_mapping } from '../../utils/navigationConstant'
import { getNotificationCount } from '../../containers/Notifications/actions'
import { getDeployedCount } from '../../containers/Deployed/actions'
import { NOTIFICATIONS_SECTION_MAP } from '../../containers/Notifications/notificationUtils'
import { PORTFOLIO_TABS } from '../../containers/Portfolio/portfolioUtils'
import { ORDER_TYPE } from '../../containers/OrderBook/orderbookUtils'
import SidebarMenuCondensed from './SidebarMenuCondensed'
import { fetchOrders } from '../../containers/OrderBook/actions'
import OrderbookSectionCondensed from './OrderbookSectionCondensed'

const tabs = [
  {
    label: 'Positions', value: 'positions', textColor: 'textSecondary', activeTextColor: 'purple', index: 0, gaAction: 'Position', icon: ICONS.SIDEBAR_POSITIONS_ICON,
  },
  {
    label: 'Holdings', value: 'holdings', textColor: 'textSecondary', activeTextColor: 'green', index: 1, gaAction: 'Holding', icon: ICONS.SIDEBAR_HOLDINGS_ICON,
  },
]

const gaCategory = 'Sidepanel quicklinks'

const AlgoCondensedSidebar = (props) => {
  const styles = stylesheet()
  const history = useHistory()
  const { updateGA } = useTheme()

  const {
    deployedCountObj,
    fetchDeployedCount,
    getOrders,
    pending,
    executed,
    isFetchingOrders,
    isDark,
  } = props

  const {
    CubeSquared, CubeFilledDark, FlowerSquared, FlowerFilledDark,
  } = ASSETS

  const filter = 'All'

  const orderBookCount = isFetchingOrders ? { pending: 0, executed: 0 }
    : { pending: pending.length, executed: executed.length }

  const [portfolioTabIndex, updateTabIndex] = useState(0)

  useEffect(() => { fetchDeployedCount(); getOrders(filter) }, [])

  const deployedSectionCount = useCallback(
    (section, keys) => {
      const data = deployedCountObj[section.DEPLOYED_COUNT_KEY]
      if (isEmpty(data)) return 0
      let count = 0
      keys.forEach((item) => {
        count += data[item] || 0
      })
      return count
    },
    [deployedCountObj],
  )

  const onBarPress = (sectionId, gaAction) => {
    updateGA(gaAction, 'Deployed', 1, gaCategory)
    if (sectionId !== NOTIFICATIONS_SECTION_MAP.SCANNER.ID) {
      history.push({
        pathname: pathname_mapping.Strategies,
        search: `?tab=${sectionId.toLowerCase()}`,
      })
    } else {
      history.push({
        pathname: pathname_mapping.live_scans,
      })
    }
  }

  const routeToTechicals = () => {
    history.push({
      pathname: pathname_mapping.marketwatch,
    })
  }

  const routeToOrderbook = (tab) => {
    updateGA(ORDER_TYPE.PENDING ? 'Open' : 'Complete', 'Orders', 1, gaCategory)
    history.push({
      pathname: pathname_mapping.Trades,
      search: `?tab=${tab}`,
    })
  }

  const updateIndex = (tab, item) => {
    if (tab === tabs[0].value) updateTabIndex(0)
    else updateTabIndex(1)
    if (item) {
      updateGA(item.gaAction, 'Portfolio', 1, gaCategory)
    }
  }

  const routeToPortfolio = (tabKey) => {
    updateGA('View all', 'Portfolio', 1, gaCategory)
    history.push({
      pathname: pathname_mapping.Trades,
      search: `?tab=${tabKey}`,
    })
  }

  const onPressPortfolioViewAll = () => {
    routeToPortfolio(portfolioTabIndex === 0
      ? PORTFOLIO_TABS.POSITIONS.KEY
      : PORTFOLIO_TABS.HOLDINGS.KEY)
  }

  // do not change the sequence
  const menuItems = [
    {
      title: 'Live Trades',
      sectionId: NOTIFICATIONS_SECTION_MAP.LIVE.ID,
      subTitle: deployedSectionCount(NOTIFICATIONS_SECTION_MAP.LIVE, ['waiting', 'entered']),
      iconName: ICONS.NOTIF_AUTO,
      stPrefix: 'Deployed',
      gaAction: 'Live trade',
    },
    {
      title: 'Paper Trades',
      sectionId: NOTIFICATIONS_SECTION_MAP.PAPER.ID,
      subTitle: deployedSectionCount(NOTIFICATIONS_SECTION_MAP.PAPER, ['waiting', 'entered']),
      iconName: ICONS.NOTIF_PAPER,
      stPrefix: 'Deployed',
      gaAction: 'Paper trade',
    },
    {
      title: 'Scanners',
      sectionId: NOTIFICATIONS_SECTION_MAP.SCANNER.ID,
      subTitle: deployedSectionCount(NOTIFICATIONS_SECTION_MAP.SCANNER, ['live']),
      iconName: ICONS.NOTIF_SCANNER,
      stPrefix: 'Live',
      gaAction: 'Live scanner',
    },
  ]

  const orderBookOptions = [
    {
      title: 'Open Orders', value: ORDER_TYPE.PENDING, image: isDark ? CubeFilledDark : CubeSquared, color: COLORS.BLUE,
    },
    {
      title: 'Executed Orders', value: ORDER_TYPE.EXECUTED, image: isDark ? FlowerFilledDark : FlowerSquared, color: COLORS.PURPLE,
    },
  ]

  return (
    <div className={styles.container}>
      <SidebarMenuCondensed menuItems={menuItems} onBarPress={onBarPress} isDark={isDark} />
      <div className={styles.seprator} />
      <OrderbookSectionCondensed
        orderBookOptions={orderBookOptions}
        orderBookCount={orderBookCount}
        routeToOrderbook={routeToOrderbook}
        isDark={isDark}
      />
      <div className={styles.seprator} />
      <ViewAllCard
        title="Portfolio"
        showViewAll={false}
        cardStyles={classnames(styles.card)}
        headerStyles={styles.portfolioHeader}
      >
        <PortfolioSidebarCondensed
          tabs={tabs}
          updateIndex={updateIndex}
          routeToTechicals={routeToTechicals}
          index={portfolioTabIndex}
          onPressPortfolioViewAll={onPressPortfolioViewAll}
          isDark={isDark}
        />
      </ViewAllCard>
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    paddingTop: SPACING.SPACE_10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  seprator: {
    width: '50%',
    height: '2px',
    backgroundColor: theme.borderColor,
    margin: `${SPACING.SPACE_10} 0`,
  },
  card: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  portfolioHeader: {
    padding: 0,
  },
})

const mapStateToProps = (state) => {
  return {
    csrf: state.auth.loginResp.csrf,
    deployedCountObj: state.deployed.deployedCountObj,
    isFetchingNotifications: state.notification.isFetchingNotifications,
    isFetchingDeployedCount: state.deployed.isFetchingDeployedCount,
    pending: state.orderbook.pending,
    executed: state.orderbook.executed,
    isFetchingOrders: state.orderbook.isFetchingOrders,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotificationCount: headers => dispatch(getNotificationCount(headers)),
    fetchDeployedCount: headers => dispatch(getDeployedCount(headers)),
    getOrders: (type, headers) => dispatch(fetchOrders(type, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlgoCondensedSidebar)
