import { CONDITION_TYPE } from '../Create/Create-utils'

export const STEP_CONDITION_TYPE = [
  {
    label: 'Parameters', value: CONDITION_TYPE.POSITION, stepValue: 1, validTypes: ['scan_on'],
  },
  {
    label: 'Conditions', value: CONDITION_TYPE.SCREENER, stepValue: 2, validTypes: ['expressions'],
  },
  {
    label: 'Review',
    value: CONDITION_TYPE.REVIEW,
    stepValue: '',
    validTypes: [
      'scan_on', 'expressions',
    ],
  },
]

export const tabsProps = {
  tabColor: 'VOILET',
  activeTabColor: 'WHITE',
}
