import { takeLatest, put, call } from 'redux-saga/effects'

import { postRequest, getRequest } from '../../utils/apiRequests'
import { error_msg } from '../../utils/common'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import {
  getPricingSuccess,
  getPricingFailure,
  fetchBillingSuccess,
  fetchBillingFailure,
  unsubscribeFeedbackSuccess,
  unsubscribeFeedbackFailure,
  changeSubscriptionSuccess,
  changeSubscriptionFailure,
  downloadInvoiceSuccess,
  downloadInvoiceFailure,
  fetchBillingOffersSuccess,
  fetchBillingOffersFailure,
} from './actions'
import {
  GET_PRICING_INIT,
  FETCH_BILLING_INIT,
  UNSUBSCRIBE_FEEDBACK,
  CHANGE_SUBSCRIPTION_INIT,
  DOWNLOAD_INVOICE_INIT,
  FETCH_BILLING_OFFERS_INIT,
} from './actionTypes'

function* fetchBilling(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.fetch_billing}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchBillingSuccess(data))
    } else {
      yield put(fetchBillingFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(fetchBillingFailure(error_msg))
  }
}

export function* fetchBillingSaga() {
  yield takeLatest(FETCH_BILLING_INIT, fetchBilling)
}

function* changeSubscription(action) {
  try {
    const { headers, actionType: type, params } = action
    let url
    if (type === 'cancel') {
      url = `${baseUrl}${prodEndpoints.cancel_subscription}`
    } else {
      url = `${baseUrl}${prodEndpoints.start_subscription}`
    }

    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status !== 'error') {
      yield put(changeSubscriptionSuccess(data))
    } else {
      yield put(changeSubscriptionFailure(data.error_msg || data.error))
    }
  } catch (err) {
    yield put(changeSubscriptionFailure(err))
  }
}

export function* changeSubscriptionSaga() {
  yield takeLatest(CHANGE_SUBSCRIPTION_INIT, changeSubscription)
}

function* getPricing(action) {
  const { headers, params } = action
  try {
    const url = `${baseUrl}${prodEndpoints.get_pricing}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      const { user_details = { } } = params
      yield put(getPricingSuccess(data, user_details.broker))
    } else {
      yield put(getPricingFailure('Get pricing Error'))
    }
  } catch (err) {
    yield put(getPricingFailure(err))
  }
}

export function* getPricingSaga() {
  yield takeLatest(GET_PRICING_INIT, getPricing)
}

function* unsubscribeFeedback(action) {
  const { params } = action
  try {
    const url = `${baseUrl}${prodEndpoints.submit_unsubscribe_feedback}`
    const data = yield call(postRequest, url, params, {})
    if (!data.error) {
      yield put(unsubscribeFeedbackSuccess(data))
    } else {
      yield put(unsubscribeFeedbackFailure(data.error_msg || data.error))
    }
  } catch (err) {
    yield put(unsubscribeFeedbackFailure(err.message))
  }
}

export function* unsubscribeFeedbackSaga() {
  yield takeLatest(UNSUBSCRIBE_FEEDBACK, unsubscribeFeedback)
}

function* downloadInvoice(action) {
  try {
    const { params, fileName } = action
    const url = prodEndpoints.get_invoice
    const data = yield call(getRequest, url, params, {})
    if (!data.error) {
      yield put(downloadInvoiceSuccess())
      const headers = new Headers()
      const blob = new Blob([data], { type: headers['application/pdf'] })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${fileName}.pdf`
      link.click()
    } else {
      yield put(downloadInvoiceFailure(data.error_msg))
    }
  } catch (err) {
    yield put(downloadInvoiceFailure(err))
  }
}

export function* downloadInvoiceSaga() {
  yield takeLatest(DOWNLOAD_INVOICE_INIT, downloadInvoice)
}

function* fetchBillingOffers(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.billing_page_offer}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error) {
      yield put(fetchBillingOffersSuccess(data))
    } else {
      yield put(fetchBillingOffersFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(fetchBillingOffersFailure(error_msg))
  }
}

export function* fetchBillingOffersSaga() {
  yield takeLatest(FETCH_BILLING_OFFERS_INIT, fetchBillingOffers)
}
