import React, { useCallback, useEffect, useState } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { connect } from 'react-redux'
import classnames from 'classnames'

import CustomText from '../../UI/CustomText'
import {
  ASSETS,
  COLORS,
  SPACING, theme,
} from '../../Theme'
import { useTheme } from '../../Theme/ThemeProvider'
import { fetchHoldings, fetchPositions } from '../../containers/Portfolio/actions'
import { unSubscribe } from '../../containers/Instruments/actions'
import GetPortfolioPnl from './GetPortfolioPnl'
import { PORTFOLIO_TABS } from '../../containers/Portfolio/portfolioUtils'
import Icon from '../../UI/Icon'
import CustomTooltip from '../../UI/CustomTooltip'
import ViewAllCard from '../../UI/ViewAllCard'

const PortfolioSidebarCondensed = React.memo(({
  getPositions,
  getHoldings,
  positions,
  holdings,
  routeToTechicals,
  //   isFetchingPositions,
  //   isFetchingHoldings,
  tabs,
  updateIndex,
  index,
  onPressPortfolioViewAll,
}) => {
  const styles = stylesheet()
  const { isDark } = useTheme()
  const [activeTab, tabChangeHandler] = useState(tabs[0].value)
  const isPositionsTab = index === 0 && PORTFOLIO_TABS.POSITIONS.KEY
  useEffect(() => {
    const filter = 'All'
    const days = '7'
    getPositions({ filter, days }, {})
    getHoldings({ filter, days }, {})
  }, [])

  const renderEmptyContainer = () => {
    return (
      <div className={styles.emptyContianer}>
        <img src={ASSETS[isDark ? 'DASH_PORTFOLIO_EMPTY_DARK' : 'DASH_PORTFOLIO_EMPTY']} className={styles.emptyImg} alt="portfolio_empty" />
        <CustomText color="textSecondary" weight="medium">{`No ${index === 1 ? 'holdings' : 'positions'} available`}</CustomText>
        <button
          type="button"
          onClick={routeToTechicals}
          className={styles.emptyContianerAction}
        >
          <CustomText color="text" weight="medium">Go to Technicals</CustomText>
        </button>
      </div>
    )
  }

  const renderTooltipHeader = label => (
    <CustomText
      weight="semi_bold"
      color={COLORS.PURPLE}
    >
      {label}
    </CustomText>
  )

  const renderTooltipActionBtn = () => <div className={styles.viewAllBtn}>View All</div>

  const tooltip = tab => (
    <ViewAllCard
      title={tab.label}
      renderTitle={() => renderTooltipHeader(tab.label)}
      cardStyles={styles.tooltipCard}
      headerStyles={styles.tooltipHeader}
      actionBtnRederer={renderTooltipActionBtn}
      onPress={onPressPortfolioViewAll}
    >
      {renderTable()}
    </ViewAllCard>
  )

  const renderButton = (tab) => {
    return (
      <div onMouseEnter={() => onTabChangeHandler(tab.value, tab.index)}>
        <CustomTooltip
          show={activeTab === tab.value}
          contentRenderer={() => tooltip(tab)}
          placement="left"
          tooltipProps={{}}
          tooltipContainerStyles={styles.tooltipContainer}
          key={tab.index}
          interactive
        >
          <button className={classnames(styles.iconButton, isDark ? styles.iconButtonDark : styles.iconButtonLight)} type="button" onClick={onPressPortfolioViewAll}>
            <Icon name={tab.icon} size={24} />
          </button>
        </CustomTooltip>
      </div>
    )
  }

  const renderTableContent = (data) => {
    return data.map((item) => {
      const {
        tradingsymbol, product, seg_sym, quantity,
      } = item
      let rowDisabled = false
      const rowDisabledStyles = {
        opacity: 0.6,
        backgroundColor: theme.btnDisabled,
      }
      if(quantity === 0) {
        rowDisabled = true
      }
      return (
        <ul className={styles.row} key={`${tradingsymbol}_${product}`} style={rowDisabled ? rowDisabledStyles : { opacity: 1 }}>
          <div style={{ width: '60%' }}>
            <CustomText ellipsis style={{ paddingRight: SPACING.SPACE_4 }}>
              {tradingsymbol}
            </CustomText>
          </div>
          <div>
            <GetPortfolioPnl
              isPositionsTab={isPositionsTab}
              item={item}
              seg_sym={seg_sym}
              pnlProps={{
                pnlStyle: styles.pnlStyle, // { opacity: isClosed ? 0.5 : 1 }]}
                showColor: true,
                showSign: true,
                showLabel: false,
              }}
            />
          </div>
        </ul>
      )
    })
  }

  const renderTable = () => {
    const data = index === 0 ? positions : holdings
    const textProps = {
      weight: 'medium',
      size: 'small',
      color: 'textSecondary',
    }
    return (
      <div className={styles.table}>
        <ul className={styles.tableHeader}>
          <li style={{ width: '80%' }}><CustomText {...textProps}>Instruments</CustomText></li>
          <li style={{ width: '20%' }}><CustomText {...textProps}>P&L</CustomText></li>
        </ul>
        {(
             data.length ? (
               <ul className={styles.tableBody}>
                 {renderTableContent(data.slice(0, 3))}
               </ul>
             ) : renderEmptyContainer()
        )}
      </div>
    )
  }

  const onTabChangeHandler = useCallback((value, item) => {
    // const { index } = item
    updateIndex(value, item)
    tabChangeHandler(value)
  }, [])

  return (
    <div className={styles.container}>
      {tabs.map(tab => <div key={tab.index}>{renderButton(tab)}</div>)}
    </div>
  )
})

const stylesheet = makeStyles(() => ({
  container: {
    margin: `${SPACING.SPACE_20} ${SPACING.SPACE_24}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: SPACING.SPACE_30,
  },
  emptyImg: {
    width: 100,
  },
  emptyContianerAction: {
    marginTop: SPACING.SPACE_4,
  },
  table: {
    width: '250px',
    maxHeight: '100%',
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${SPACING.SPACE_20} ${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    marginBottom: SPACING.SPACE_10,
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  tableBody: {
    backgroundColor: theme.bgSidebar,
  },
  emptyContianer: {
    display: 'flex',
    height: '65%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${SPACING.SPACE_12} ${SPACING.SPACE_20}`,
    '&:first-child': {
      marginTop: SPACING.SPACE_8,
    },
  },
  iconButton: {
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_6} ${SPACING.SPACE_2} ${SPACING.SPACE_8}`,
    border: `1px solid ${theme.borderColor}`,
    borderRadius: 5,
    '& svg': {
      fill: COLORS.BLACK,
      stroke: COLORS.BLACK,
    },
    '&:hover': {
      backgroundColor: theme.borderColor,
    },
    '&:active': {
      backgroundColor: COLORS.BLUE_000,
      border: `1px solid ${theme.linkColor}`,
      '& svg': {
        fill: theme.linkColor,
        stroke: theme.linkColor,
      },
    },
  },
  iconButtonLight: {
    border: `1px solid ${theme.borderColor}`,
    '& svg': {
      fill: COLORS.BLACK,
      stroke: COLORS.BLACK,
    },
    '&:hover': {
      backgroundColor: theme.borderColor,
    },
    '&:active': {
      backgroundColor: COLORS.BLUE_000,
      border: `1px solid ${theme.linkColor}`,
      '& svg': {
        fill: theme.linkColor,
        stroke: theme.linkColor,
      },
    },
  },
  iconButtonDark: {
    border: `1px solid ${theme.borderColor}`,
    backgroundColor: theme.borderColor,
    '& svg': {
      fill: COLORS.WHITE,
      stroke: COLORS.WHITE,
    },
    '&:hover': {
      '& svg': {
        fill: theme.linkColor,
        stroke: theme.linkColor,
      },
    },
  },
  tooltipContainer: {
    backgroundColor: theme.bgSidebar,
    boxShadow: `0px 3px 8px ${theme.dropdownShadow}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tooltipCard: {
    backgroundColor: theme.bgSidebar,
    width: '100%',
    boxShadow: 'none',
    padding: '0',
    paddingTop: SPACING.SPACE_4,
  },
  tooltipHeader: {
    backgroundColor: theme.toolTipHeader,
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_6}`,
    borderRadius: 5,
  },
  viewAllBtn: {
    color: COLORS.GREY_200,
    '&:hover': {
      color: theme.linkColor,
    },
  },
}))

const mapStateToProps = state => ({
  sessionId: state.auth.loginResp.sessionid,
  positions: state.portfolio.positions,
  holdings: state.portfolio.holdings,

  isFetchingPositions: state.portfolio.isFetchingPositions,
  positionsError: state.portfolio.positionsError,

  isFetchingHoldings: state.portfolio.isFetchingHoldings,
  // meta: state.auth.meta,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getPositions: (params, headers, screenName) => dispatch(
      fetchPositions(params, headers, screenName),
    ),
    getHoldings: (type, headers, screenName) => dispatch(fetchHoldings(type, headers, screenName)),
    unSubscribeTokens: (screenName, pairList) => dispatch(unSubscribe(screenName, pairList)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioSidebarCondensed)
