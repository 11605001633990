import { makeStyles } from '@material-ui/styles'
import React from 'react'

import Button from './Button'
import CustomText from './CustomText'

import { ASSETS, SPACING } from '../Theme'
// https://ref.streak.tech/YHDfEgKC4RESBWvH9

function ReferralModal({ onDismiss, dismissKey, updateAnalytics }) {
  const styles = stylesheet()

  const signUp = () => {
    updateAnalytics('Sign up', 'Sign up')
    onDismiss(dismissKey)
  }
  return (
    <div>
      <div className={styles.container}>
        <div>
          <img src={ASSETS.ThumbsUp} width="60%" alt="REWARDS" />
        </div>
        <div>
          <CustomText size="large" weight="semi_bold" className={styles.heading}>Welcome to Streak</CustomText>
          <div>
            <CustomText size="small" color="#5C3A23" className={styles.invitationText}>Here is a sweet offer for you, since you came from an invitation link.</CustomText>
            <div className={styles.text}>
              <CustomText size="small" color="#5C3A23">You can earn</CustomText>
              <CustomText size="small" color="black" weight="semi_bold">&nbsp;150 credits!</CustomText>
            </div>
          </div>
        </div>
      </div>
      <Button
        text="Sign Up"
        mode="contained"
        btnStyles={styles.signUp}
        buttonColor="blue"
        onPress={signUp}
      />
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    padding: 40,
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    lineHeight: 1.6,
    display: 'flex',
    justifyContent: 'center',
    // columnGap: SPACING.SPACE_10,
  },
  invitationText: {
    paddingBottom: SPACING.SPACE_10,
  },
  heading: {
    paddingBottom: SPACING.SPACE_20,
  },
  signUp: {
    width: '100%',
    padding: '6px',
  },

})

export default ReferralModal
