import {
  GET_INSTRUMENTS_INIT,
  GET_INSTRUMENTS_SUCCESS,
  GET_INSTRUMENTS_FAILURE,
  GET_EXCHANGE_TOKEN_SUCCESS,
  GET_EXCHANGE_TOKEN_FAILURE,
  UPDATE_TICKS,
  SUBSCRIBE_TOKENS,
  UNSUBSCRIBE_TOKENS,
  GET_EXCHANGE_TOKEN_INIT,
  WEBSOCKET_CONNECTION,
} from './actionTypes'
import { segSymConverter } from '../../utils/common'
import { getSegSymFromDC } from '../Create/Create-utils'

const headers = { 'content-type': 'application/json' }

export function getInstruments(params, actionheaders, screenName) {
  return {
    type: GET_INSTRUMENTS_INIT,
    params,
    actionheaders,
    screenName,
  }
}

export function getExchangeTokens(params, actionheaders, screenName) {
  return {
    type: GET_EXCHANGE_TOKEN_INIT,
    params,
    actionheaders,
    screenName,
  }
}

export function getInstrumentsSuccess(data, screenName) {
  return {
    type: GET_INSTRUMENTS_SUCCESS,
    data,
    screenName,
  }
}

export function getInstrumentsFailure(error) {
  return {
    type: GET_INSTRUMENTS_FAILURE,
    error,
  }
}

export function getExchangeTokensSuccess(data, screenName) {
  return {
    type: GET_EXCHANGE_TOKEN_SUCCESS,
    data,
    screenName,
  }
}

export function getExchangeTokensFailure(error) {
  return {
    type: GET_EXCHANGE_TOKEN_FAILURE,
    error,
  }
}

export function fetchDashboardInstruments() {
  return (dispatch) => {
    const instrument_params = {
      symbols: [['INDICES', 'NIFTY 50'], ['INDICES', 'SENSEX']],
      return_fields: ['instrument_token', 'symbol', 'segment'],
    }
    dispatch(getInstruments(instrument_params, headers))
  }
}

export function fetchPortfolioInstruments(dispatch, data) {
  const instrument_params = {
    symbols: [],
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  const symbols = data.map((position = {}) => {
    // const { tradingsymbol, exchange } = position
    const { segment, symbol: tradingsymbol } = segSymConverter(`${position.exchange}_${position.tradingsymbol}`)
    const exchange = segment === 'BSE' ? 'NSE' : segment
    return [exchange, tradingsymbol]
  })
  instrument_params.symbols = symbols
  return instrument_params
}

export function fetchOrderbookInstruments(dispatch, data) {
  const instrument_params = {
    symbols: [],
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  const symbols = data.map((order = {}) => {
    const { segment, symbol: tradingsymbol } = segSymConverter(`${order.exchange}_${order.tradingsymbol}`)
    const exchange = segment === 'BSE' ? 'NSE' : segment
    return [exchange, tradingsymbol]
  })
  instrument_params.symbols = symbols
  return instrument_params
}

export function fetchBasketImportedInstruments(instrument_params) {
  return getInstruments(instrument_params, headers)
}

export function fetchDeployedInstruments(dispatch, data) {
  const instrument_params = {
    symbols: [],
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  const symbols = data.map((order) => {
    const { segment_symbol } = order
    const { segSym: token } = getSegSymFromDC(segment_symbol)
    return token.split('_')
  })
  instrument_params.symbols = symbols
  return instrument_params
}

export function fetchBackTestInstruments(dispatch, equities) {
  const instrument_params = {
    symbols: [],
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  const symbols = equities.map((stock) => {
    const { segSym } = getSegSymFromDC(stock)
    return segSym.split('_')
  })
  instrument_params.symbols = symbols
  return instrument_params
}

export function fetchCreateInstruments(dispatch, data, screenName) {
  const instrument_params = {
    symbols: [],
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  const symbols = data.map((item) => {
    const { segment, symbol } = item
    const stock = `${segment}_${symbol}`
    const { segSym } = getSegSymFromDC(stock)
    return segSym.split('_')
  })
  instrument_params.symbols = symbols
  if (dispatch) {
    dispatch(getInstruments(instrument_params, headers, screenName))
  }
  return instrument_params
}

export const screenerPairs = (dispatch, data, screenName) => {
  const instrument_params = {
    symbols: [],
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  const token_keys = {}
  data.map((item) => {
    const { seg_sym } = item
    const [exchange, symbol] = seg_sym.split(':')
    const token = `${exchange}_${symbol}`
    if (!token_keys[token]) {
      token_keys[token] = true
      instrument_params.symbols.push([exchange, symbol])
    }
    return null
  })
  if (dispatch) dispatch(getInstruments(instrument_params, {}, screenName))
  return instrument_params
}

export const openOrdersPairs = (dispatch, data = '', screenName = 'streak.scanDetails') => {
  const instrument_params = {
    symbols: [],
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  const token_keys = {}
  const [exchange, symbol] = data.split('_')
  const token = `${exchange}_${symbol}`
  if (!token_keys[token]) {
    token_keys[token] = true
    instrument_params.symbols.push([exchange, symbol])
  }
  if (dispatch) dispatch(getInstruments(instrument_params, {}, screenName))
  return instrument_params
}

export const screenerExchangePairs = (dispatch, data, screenName) => {
  const instrument_params = {
    symbols: [],
    return_fields: ['exchange_token', 'symbol', 'segment'],
  }
  const token_keys = {}
  data.map((item) => {
    const { seg_sym } = item
    const [exchange, symbol] = seg_sym.split(':')
    const token = `${exchange}_${symbol}`
    if (!token_keys[token]) {
      token_keys[token] = true
      instrument_params.symbols.push([exchange, symbol])
    }
    return null
  })
  if (dispatch) dispatch(getExchangeTokens(instrument_params, {}, screenName))
  return instrument_params
}

export const fetchAlgoInstrument = (dispatch, algos) => {
  const instrument_params = {
    symbols: [],
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  const stocks = new Set()
  const symbols = []
  algos.forEach((item) => {
    const { backtest = [] } = item
    backtest.forEach((results) => {
      const { seg_sym } = results
      if (!stocks.has(seg_sym)) {
        stocks.add(seg_sym)
        const { segSym } = getSegSymFromDC(seg_sym)
        symbols.push(segSym.split('_'))
      }
    })
  })
  instrument_params.symbols = symbols
  return instrument_params
}

export const technicalPairs = (dispatch, stocks, screenName) => {
  const instrument_params = {
    symbols: stocks.map((item) => {
      const [exchange, symbol] = item.split('_')
      return [exchange, symbol]
    }),
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  if (dispatch) {
    dispatch(getInstruments(instrument_params, headers, screenName))
  }
  return instrument_params
}

export const notifPair = (dispatch, stocks, screenName) => {
  const instrument_params = {
    symbols: stocks.map((item) => {
      const [exchange, symbol] = item.split('_')
      return [exchange, symbol]
    }),
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  if (dispatch) {
    dispatch(getInstruments(instrument_params, headers, screenName))
  }
  return instrument_params
}

export const pairsFromSegSym = (dispatch, stocks, screenName) => {
  const instrument_params = {
    symbols: stocks.map((item) => {
      const { segment, symbol } = item
      return [segment, symbol]
    }),
    return_fields: ['instrument_token', 'symbol', 'segment'],
  }
  if (dispatch) {
    dispatch(getInstruments(instrument_params, headers, screenName))
  }
  return instrument_params
}

export function updateTicks(data) {
  return {
    type: UPDATE_TICKS,
    ticks: data,
  }
}

export function subscribe(tokens) {
  return {
    type: SUBSCRIBE_TOKENS,
    tokens, // tokens that need to be added
  }
}

export function unSubscribe(screenName, unSubPairList, logout) {
  return {
    type: UNSUBSCRIBE_TOKENS,
    screenName,
    unSubPairList,
    logout,
  }
}

export function getWebsocketConnection() {
  return {
    type: WEBSOCKET_CONNECTION,
  }
}
