import { ICONS, ASSETS, theme } from '../../Theme'

const { KiteImage, ABLogo, WifiFilled } = ASSETS

export const ORDERBOOK_MENU_MAP = {
  VIEW_DETAILS: {
    LABEL: 'View Details',
    ID: 'VIEW_DETAILS',
    ICON: ICONS.STRATEGY_DETAILS,
  },
  CANCEL: {
    LABEL: 'Cancel',
    ID: 'CANCEL',
    ICON: ICONS.CANCELLED,
    iconStyle: { color: theme.red },
    labelSyle: { color: theme.red },
  },
}

export const ORDER_TYPE = {
  PENDING: 'pending',
  EXECUTED: 'executed',
}

export const ORDERBOOK_TABS = {
  PENDING: {
    KEY: ORDER_TYPE.PENDING, TITLE: 'OPEN ORDERS', INDEX: 0, gaLabel: 'Open',
  },
  EXECUTED: {
    KEY: ORDER_TYPE.EXECUTED, TITLE: 'EXECUTED ORDERS', INDEX: 1, gaLabel: 'Complete',
  },
}

export const ORDERBOOK_DROPDOWN = {
  STREAK: {
    LABEL: 'Streak',
    ID: 'Streak',
    shortLabel: 'Streak',
    gaLabel: 'Streak',
  },
  ALL: {
    LABEL: 'All',
    ID: 'All',
    shortLabel: 'All',
    gaLabel: 'All',
  },
}

// strings values of order_tag field (from backend)
export const ORDER_TAG_ICON_MAP = {
  direct: {
    ID: 'direct', ICON: ICONS.ARROW_HEAD_FILLED, SVG: false, FONT: true, LABEL: 'Direct',
  }, // TODO: Need icon for direct orders (svg)
  strategy: {
    ID: 'strategy', ICON: WifiFilled, SVG: true, FONT: false, LABEL: 'Live trading',
  },
  kite: {
    ID: 'kite', ICON: KiteImage, SVG: false, FONT: false, LABEL: 'Kite',
  },
  ab: {
    ID: 'ab', ICON: ABLogo, SVG: true, FONT: false, LABEL: 'Angel Broking',
  },

}

// ideally all tags should be covered. Tags can be found in kite docs https://kite.trade/docs/connect/v3/orders/#order-statuses
export const ORDERBOOK_STATUS_MAPPING = {
  OPEN: { ICON: ICONS.OPEN, COLOR: theme.linkColor },
  COMPLETE: { ICON: ICONS.TICK_ROUND_FILLED, COLOR: theme.green },
  CANCELLED: { ICON: ICONS.CANCEL, COLOR: theme.bntDisabled },
  REJECTED: { ICON: ICONS.REJECTED, COLOR: theme.red },
  'TRIGGER PENDING': { ICON: ICONS.TRIGGER_WAITING, COLOR: theme.linkColor },
}
