import React from 'react'
import {
  Route,
} from 'react-router-dom'

import Auth from './containers/Auth/Auth-redux'

import { pathname_mapping } from './utils/navigationConstant'

import NewHomePage from './homepage/containers/HomePageNew/HomePageNew-redux'
import PaymentPages from './containers/PaymentPages/PaymentPages-redux'
import OpenDirectOrder from './containers/OpenDirectOrder/OpenDirectOrder'

export const UnauthRoutes = [
  { Component: Auth, path: pathname_mapping.Auth, exact: false },
  // { Component: Auth, path: pathname_mapping.AppZerodhaLogin, exact: true },
  // { Component: Auth, path: pathname_mapping.AppPaisaLogin, exact: true },
  // { Component: Auth, path: pathname_mapping.AppAngelLogin, exact: true },
  // { Component: Auth, path: pathname_mapping.AppUpstoxLogin, exact: true },
  { Component: Auth, path: pathname_mapping.PasswordReset, exact: true },
  { Component: Auth, path: pathname_mapping.ForgotPassword, exact: true },
  { Component: Auth, path: pathname_mapping.SignUp, exact: true },
  { Component: NewHomePage, path: pathname_mapping.SystematicTrading, exact: true },
  { Component: PaymentPages, path: pathname_mapping.PaymentPages, exact: true },
  { Component: OpenDirectOrder, path: pathname_mapping.OpenScanOrder, exact: true },
]

const UnAuthenticatedRoutes = ({ domainChange }) => {
  return (
    UnauthRoutes.map((item) => {
      const { path, exact, Component } = item
      return (
        <Route
          key={path}
          exact={exact}
          path={path}
          render={props => (
            // pass the sub-routes down to keep nesting
            <Component {...props} domainChange={domainChange} />
          )}
        />
      )
    })
  )
}

export default UnAuthenticatedRoutes
