/* eslint-disable max-len */
import dayjs from 'dayjs'
import React from 'react'
import { ASSETS } from '../../Theme'

// import Button from '../../UI/Button'

import HOME_ASSETS from '../assets'
import home_pathname_mapping from '../homeutils/navigationConstant'
// import { Copy } from '../UI/Icons'

const {
  AppStore, PlayStore, Facebook, Instagram, Twitter, YouTube,
  LinkedIn, StreakTmLogo, Telegram,
} = HOME_ASSETS

const { LogoDark } = ASSETS

const currentYear = dayjs().year()

class FooterNewHomePage extends React.Component {
  state = {}

  shareLinkRef = React.createRef()

  copyToClipboard = () => {
    // showSnackbar('Link copied to clipboard')
    if (this.shareLinkRef && this.shareLinkRef.current) {
      const ele = this.shareLinkRef.current
      ele.select()
      ele.setSelectionRange(0, 99999)
      document.execCommand('copy')
    }
  }

  onClaimTrialOffer = () => {
    const { toggleAuthModal, history } = this.props
    history.push({
      search: '?claimNow=true',
    })
    toggleAuthModal(true)
  }

  render() {
    const { isMobile } = this.props
    const linkProps = { target: '_blank', rel: 'noreferrer' }
    return (
      <div className="homeWrapper sysFooterWrapper" id="sysFooterWrapper" style={{ margin: 0 }}>
        <div className="sysFooter">
          <div className="sysFooterBottom">
            {isMobile ? (<img className="streakWhiteLogo" alt="streak logo" src={LogoDark} />) : (
              <img className="streakLogo" alt="streak logo" src={StreakTmLogo} />

            )}
            {/* <img className="streakLogoTm" alt="streak" src="" /> */}
            <div className="sysFooterBottomContent">
              <div className="sysFooterBottomCol">
                <h5 className="sysFooterBottomColTitle">Learn and get help</h5>
                {/* <div><a href="https://academy.streak.tech/" className="sysFooterlink">Streak Academy</a></div> */}
                <div><a href="https://academy.streak.tech/technical-indicators/" className="sysFooterlink">Technical Indicators</a></div>
                <div><a href="https://academy.streak.tech/topics/" className="sysFooterlink">Articles</a></div>
                <div><a href="https://help.streak.tech/" className="sysFooterlink">Help</a></div>
                {/* <div><a href="help.streak.tech" className="sysFooterlink">Support</a></div> */}
              </div>
              <div className="sysFooterBottomCol sysFooterBottomCol_2">
                <h5 className="sysFooterBottomColTitle">Company</h5>
                {/* <div><a href="help.streak.tech" className="sysFooterlink">About</a></div>
                <div><a href="help.streak.tech" className="sysFooterlink">Careers</a></div> */}
                <div><a href={home_pathname_mapping.Terms} className="sysFooterlink">Terms of use</a></div>
                <div><a href={home_pathname_mapping.Privacy} className="sysFooterlink">Privacy Policy</a></div>
                <div><a href={home_pathname_mapping.Disclosure} className="sysFooterlink">Risk Disclosure</a></div>
              </div>
              <div className="sysFooterSocialContainer">
                <h5 className="sysFooterBottomColTitle">Social</h5>
                <div className="socialsContainer">
                  <a href="https://twitter.com/StreakTech" className="sysFooterlink sysFooterlinkFixed" {...linkProps}>
                    <img src={Twitter} alt="streak twitter" />
                  </a>
                  <a href="https://t.me/Streaktech" className="sysFooterlink sysFooterlinkFixed sysFooterTelegram" {...linkProps}>
                    <img src={Telegram} alt="streak telegram" />
                  </a>
                  <a href=" https://www.facebook.com/StreakAI/" className="sysFooterlink sysFooterlinkFixed sysFooterFb" {...linkProps}>
                    <img src={Facebook} alt="streak facebook" />
                  </a>
                  <a href="https://www.youtube.com/c/StreakTech" className="sysFooterlink sysFooterlinkFixed" {...linkProps}>
                    <img src={YouTube} alt="streak youtube" />
                  </a>
                  <a href="https://www.instagram.com/streak.tech/" className="sysFooterlink sysFooterlinkFixed" {...linkProps}>
                    <img src={Instagram} alt="streak instagram" />
                  </a>
                  <a href="https://www.linkedin.com/company/streaktech/" className="sysFooterlink sysFooterlinkFixed" {...linkProps}>
                    <img src={LinkedIn} alt="streak linkedin" />
                  </a>
                  <a href=" https://apps.apple.com/in/app/streak-algo-trade/id1423080803" className="sysFooterlink sysFooterlinkFixed" {...linkProps}>
                    <img src={AppStore} alt="streak appstore" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.streak" className="sysFooterlink sysFooterlinkFixed" {...linkProps}>
                    <img src={PlayStore} alt="streak playstore" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <h5>{`© ${currentYear} Streak AI Technologies Pvt Ltd. All rights reserved.`}</h5>
          </div>
        </div>
      </div>
    )
  }
}

export default FooterNewHomePage
