import {
  ACTIVE_ALGOS_STOCK_INIT,
  ACTIVE_ALGOS_STOCK_SUCCESS,
  ACTIVE_ALGOS_STOCK_FAILURE,
  STOPPED_ALGOS_INIT,
  STOPPED_ALGOS_SUCCESS,
  STOPPED_ALGOS_FAILURE,
  STOP_ALGO_INIT,
  STOP_ALGO_SUCCESS,
  STOP_ALGO_FAILURE,
  FETCH_OPEN_POSITIONS_INIT,
  FETCH_OPEN_POSITIONS_SUCCESS,
  FETCH_OPEN_POSITIONS_FAILURE,
  CLEAR_STOP_ALGO_RESP,
  CLEAR_OPEN_POSITIONS_RESP,
  REDEPLOY_INIT,
  REDEPLOY_SUCCESS,
  REDEPLOY_FAILURE,
  ORDER_DETAILS_INIT,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAILURE,
  STOP_WAITING_ALGOS_INIT,
  STOP_WAITING_ALGOS_SUCCESS,
  STOP_WAITING_ALGOS_FAILURE,
  DEPLOYED_COUNT_INIT,
  DEPLOYED_COUNT_SUCCESS,
  DEPLOYED_COUNT_FAILURE,
} from './actionTypes'

export function fetchActiveStocks(params, headers) {
  return {
    type: ACTIVE_ALGOS_STOCK_INIT,
    params,
    headers,
  }
}

export function fetchActiveAlgoStockSuccess(data) {
  return {
    type: ACTIVE_ALGOS_STOCK_SUCCESS,
    data,
  }
}

export function fetchActiveAlgoStockFailure(error) {
  return {
    type: ACTIVE_ALGOS_STOCK_FAILURE,
    error,
  }
}

export function fetchStoppedStocks() {
  return {
    type: STOPPED_ALGOS_INIT,
  }
}

export function fetchStoppedAlgoSuccess(data) {
  return {
    type: STOPPED_ALGOS_SUCCESS,
    data,
  }
}

export function fetchStoppedAlgoFailure(error) {
  return {
    type: STOPPED_ALGOS_FAILURE,
    error,
  }
}

export function stopAlgo(actionType, params, headers) {
  return {
    type: STOP_ALGO_INIT,
    actionType,
    params,
    headers,
  }
}

export function stopAlgoSuccess(data) {
  return {
    type: STOP_ALGO_SUCCESS,
    data,
  }
}

export function stopAlgoFailure(error) {
  return {
    type: STOP_ALGO_FAILURE,
    error,
  }
}

export function clearResp() {
  return {
    type: CLEAR_STOP_ALGO_RESP,
  }
}

export function clearOpenPositionsData() {
  return {
    type: CLEAR_OPEN_POSITIONS_RESP,
  }
}

export function getOpenPositions(params, headers) {
  return {
    type: FETCH_OPEN_POSITIONS_INIT,
    params,
    headers,
  }
}

export function openPositionsSuccess(data) {
  return {
    type: FETCH_OPEN_POSITIONS_SUCCESS,
    data,
  }
}

export function openPositionsFailure(error) {
  return {
    type: FETCH_OPEN_POSITIONS_FAILURE,
    error,
  }
}

export function reDeployAlgo(params, headers) {
  return {
    type: REDEPLOY_INIT,
    params,
    headers,
  }
}

export function reDeployAlgoSuccess(data) {
  return {
    type: REDEPLOY_SUCCESS,
    data,
  }
}

export function reDeployAlgoFailure(error) {
  return {
    type: REDEPLOY_FAILURE,
    error,
  }
}

export function getOrderDetails(params) {
  return {
    type: ORDER_DETAILS_INIT,
    params,
  }
}

export function getOrderDetailsSuccess(data) {
  return {
    type: ORDER_DETAILS_SUCCESS,
    data,
  }
}

export function getOrderDetailsFailure(error) {
  return {
    type: ORDER_DETAILS_FAILURE,
    error,
  }
}

export function stopWaitingAlgos(params, headers) {
  return {
    type: STOP_WAITING_ALGOS_INIT,
    params,
    headers,
  }
}

export function stopWaitingAlgosSuccess(data) {
  return {
    type: STOP_WAITING_ALGOS_SUCCESS,
    data,
  }
}

export function stopWaitingAlgosFailure(error) {
  return {
    type: STOP_WAITING_ALGOS_FAILURE,
    error,
  }
}

export function getDeployedCount() {
  return {
    type: DEPLOYED_COUNT_INIT,
  }
}

export function getDeployedCountSuccess(data) {
  return {
    type: DEPLOYED_COUNT_SUCCESS,
    data,
  }
}

export function getDeployedCountFailure(error) {
  return {
    type: DEPLOYED_COUNT_FAILURE,
    error,
  }
}
