import React, { useCallback, useEffect, useState } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import isEmpty from 'lodash/isEmpty'
import { Transition } from 'react-spring'

// import SingleViewCarousal from '../../UI/SingleViewCarousal'
import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import FundsCard from './FundsCard'
import FundsCardRms from './FundsCardRms'
import PlaceHolderLoader from '../../UI/PlaceHolderLoader'

import {
  SPACING, ICONS, COLORS, theme,
} from '../../Theme'
import { BROKERS } from '../../utils/consts'
import { useTheme } from '../../Theme/ThemeProvider'
import { showSnackbar } from '../../UI/Snackbar'
// import { BROKERS } from '../../utils/consts'

const eqCommTabs = [
  {
    label: 'Equity', value: 'equity', textColor: 'textSecondary', activeTextColor: 'purple', index: 0,
  },
  {
    label: 'Commodity', value: 'commodity', textColor: 'textSecondary', activeTextColor: 'green', index: 1,
  },
]

const eqFnoTabs = [
  {
    label: 'Equity', value: 'equity', textColor: 'textSecondary', activeTextColor: 'purple', index: 0,
  },
  {
    label: 'FNO', value: 'fno', textColor: 'textSecondary', activeTextColor: 'red', index: 1,
  },
]

const TRANSITION_PROPS = tabs => ({
  [tabs[0].value]: {
    from: {
      opacity: 0, transform: 'translate3d(-100%,0,0)', width: '40%',
    },
    enter: {
      opacity: 1, transform: 'translate3d(0%,0,0)', width: '100%',
    },
    leave: {
      opacity: 0, transform: 'translate3d(100%,0,0)', position: 'absolute',
    },
  },
  [tabs[1].value]: {
    from: {
      opacity: 0, transform: 'translate3d(100%,0,0)', width: '40%', right: 0,
    },
    enter: {
      opacity: 1, transform: 'translate3d(0%,0,0)', width: '100%', right: 0,
    },
    leave: {
      opacity: 0, transform: 'translate3d(-100%,0,0)', position: 'absolute',
    },
  },
  default: {},
})

const Funds = React.memo(({
  fetchFunds,
  funds = {},
  commodity_funds = {},
  fno_funds = {},
  isFetchingFunds,
  fundsErrorMsg,
  fundsError,
  user_details,
  // logout,
}) => {
  const styles = stylesheet()
  const { isDark } = useTheme()

  const { broker } = user_details

  const tabs = broker === BROKERS.ICICI.name ? eqFnoTabs : eqCommTabs

  const [activeTab, tabChangeHandler] = useState(tabs[0].value)
  const [activeTabAnim, updateAnim] = useState('default')
  // const [selectedIndex, updateIndex] = useState(0)
  useEffect(() => {
    const fetchTimer = setTimeout(() => {
      if (!isFetchingFunds && !isEmpty(user_details) && broker !== BROKERS.NONE.name) {
        fetchFunds()
      }
    }, 150)
    return () => {
      if (fetchTimer) {
        clearTimeout(fetchTimer)
      }
    }
  }, [user_details])

  useEffect(() => {
    // let logoutTimer = null
    if (fundsError && fundsErrorMsg && !isEmpty(user_details)
      && fundsErrorMsg.toLowerCase().includes('session expired')) {
      // showSnackbar(fundsErrorMsg, {}, 0)
      showSnackbar('Unable to fetch funds, re-login might be require.', {}, 0)
      // logoutTimer = setTimeout(() => {
      // logout()
      // }, 2000)
    }
    // return () => { if (logoutTimer) clearTimeout(logoutTimer) }
  }, [fundsError])

  const {
    account_value = 0,
    available_balance = 0,
    margins_used = 0,

    // AB specific fields
    // fundsForAllocation,
    fundsForTrading,
  } = funds
  const {
    account_value: c_account_value = 0,
    available_balance: c_available_balance = 0,
    margins_used: c_margins_used = 0,
  } = commodity_funds
  const {
    account_value: f_account_value = 0,
    available_balance: f_available_balance = 0,
    margins_used: f_margins_used = 0,
  } = fno_funds
  const onTabChangeHandler = useCallback((value) => {
    // const { index } = item
    // updateIndex(index)
    updateAnim(value)
    tabChangeHandler(value)
  }, [])
  const darkBgColor = COLORS.BLACK_30
  const renderItem = (key, props) => {
    if (!broker || broker === BROKERS.NONE.name) return <div />
    if (broker === BROKERS.ANGEL.name) {
      return (
        <FundsCardRms
          rmsLimit={fundsForTrading}
          isFetching={isFetchingFunds}
          onRefresh={fetchFunds}
          fundsError={fundsError}
          isDark={isDark}
        />
      )
    }
    if (key === tabs[0].value) {
      return (
        <FundsCard
          animationStyles={props}
          balance={available_balance}
          value={account_value}
          margins={margins_used}
          // wrapperStyles={styles.equityWrapper}
          isFetching={isFetchingFunds}
          onRefresh={fetchFunds}
          fundsError={fundsError}
          iconName={ICONS.EQUITY}
          iconColor={COLORS.PURPLE}
          backgroundColor={isDark ? darkBgColor : COLORS.PURPLE_100}
        />
      )
    }
    if (key === tabs[1].value && broker === BROKERS.ICICI.name) {
      return (
        <FundsCard
          animationStyles={props}
          balance={f_available_balance}
          value={f_account_value}
          margins={f_margins_used}
          // wrapperStyles={styles.equityWrapper}
          isFetching={isFetchingFunds}
          onRefresh={fetchFunds}
          fundsError={fundsError}
          iconName={ICONS.COMMODITY}
          iconColor={COLORS.RED}
          backgroundColor={isDark ? darkBgColor : COLORS.RED_100}
        />
      )
    }
    return (
      <FundsCard
        animationStyles={props}
              // wrapperStyles={styles.commodityWrapper}
        balance={c_available_balance}
        value={c_account_value}
        margins={c_margins_used}
        isFetching={isFetchingFunds}
        onRefresh={fetchFunds}
        fundsError={fundsError}
        iconName={ICONS.COMMODITY}
        iconColor={COLORS.GREEN}
        backgroundColor={isDark ? darkBgColor : COLORS.GREEN_100}
      />
    )
  }

  if (user_details.broker === BROKERS.NONE.name) return null
  return (
    <div id="dash_tour_5">
      <div className={styles.container}>
        {isFetchingFunds ? (
          <>
            <PlaceHolderLoader className={styles.loaderTab} />
            <PlaceHolderLoader className={styles.loader} />
          </>
        ) : (
          <>
            {[BROKERS.ZERODHA.name, BROKERS.UPSTOX.name, BROKERS.ICICI.name].includes(broker) && (
            <CustomHorizontalTab
              tabs={tabs}
              tabChangeHandler={onTabChangeHandler}
              defaultActiveTab={activeTab}
              style={styles.tabContainer}
              isDark={isDark}
            />
            )}
            <div className={styles.row}>
              <Transition
                items={activeTab}
                keys={item => item}
                config={{
                  mass: 5, tension: 2000, friction: 200,
                }}
                {...TRANSITION_PROPS(tabs)[activeTabAnim]}
              >
                {(props, item) => renderItem(item, props)}
              </Transition>
            </div>
          </>
        )}

      </div>
    </div>
  )
})

const stylesheet = makeStyles(() => ({
  container: {
    margin: `${SPACING.SPACE_20} ${SPACING.SPACE_24}`,
  },
  row: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  commodityWrapper: {
    marginTop: SPACING.SPACE_32,
  },
  equityWrapper: {
    marginTop: SPACING.SPACE_32,
  },
  tabContainer: {
    backgroundColor: theme.tabsBg,
    borderRadius: 8,
    marginBottom: SPACING.SPACE_16,
  },
  loader: {
    height: 150,
  },
  loaderTab: {
    marginBottom: SPACING.SPACE_16,
    height: 50,
  },
}))

export default Funds
