/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from 'react'
import classnames from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'

import CreateCard from '../../UI/CreateCard'
import Icon from '../../UI/Icon'
import SymbolCard from './SymbolCard'
import ToggleButton from '../../UI/ToggleButton'
import AnimatedCarousal from '../../UI/AnimatedCarousal'
import CustomText from '../../UI/CustomText'
import CustomTooltip from '../../UI/CustomTooltip'
import CustomModal from '../../UI/CustomModal'

import {
  SPACING, COLORS, ICONS, normalize, theme, ASSETS,
} from '../../Theme'
import { compressedDYCName, segSymConverter, symbolNameByOptions } from '../../utils/common'
import { CONDITION_TYPE, DYNAMIC_SEGMENTS } from '../../containers/Create/Create-utils'

const CARD_HEIGHT = normalize(140)
const CARD_WIDTH = normalize(120)
// const SYMBOLS_HEIGHT = normalize(270)
const CONDENDED_HEIGHT = normalize(110)

let symbolProps = {}
const keyExtractor = item => `${item.segment}_${item.symbol}`
const symbolListRef = React.createRef(null)

const CreateView = ({
  togglePositions,
  addNewSymbol,
  conditionEditType,
  // state
  scripList,
  showError,
  scripValid,
  positionType = '',
  postionBtnMap,
  // disableActions,
  onSymbolDelete,
  // toggleLongPress,
  // longPressActive,
  // selectedSymbols,
  // toggleSelectAll,
  // allSelected,
  // scrollToRef,
  // SCROLL_REF,
  // updateScrollToRef,
  createAdvance,
  convertToDynamic,
  isDark,
  isMobile,
  showCreateAdvance,
  containerStyles,
  isMultiPage,
  startTour,
  changePtComponentStatus,
  activeTourIndex,
  tourType,
  updateGA,
}) => {
  const scrollRef = useRef(null)
  const styles = stylesheet()
  const [showOptionsMenu, toggleOptionsMenu] = useState(false)
  // const [scrollPos, updatePos] = useState({})
  // const entryRef = useRef(null)
  // const qtyRef = useRef(null)
  // const exitRef = useRef(null)
  // const refMap = {
  //   [SCROLL_REF.ENTRY]: entryRef,
  //   [SCROLL_REF.QUANTITY]: qtyRef,
  //   [SCROLL_REF.EXIT]: exitRef,
  // }

  // useEffect(() => {
  //   if (scrollToRef) {
  //     if (scrollPos[scrollToRef]) {
  //       scrollRef.current.scrollTo({ y: scrollPos[scrollToRef] - 40, animated: true })
  //     } else if (scrollToRef && refMap[scrollToRef].current) {
  //       refMap[scrollToRef].current.measure((x, y, w, h, pX, pY) => {
  //         updatePos({ ...scrollPos, [scrollToRef]: pY - normalize(100) })
  //         scrollRef.current.scrollTo({ y: (pY - normalize(100)), animated: true })
  //       })
  //     }
  //     updateScrollToRef('')
  //   }
  // }, [scrollToRef])
  useEffect(() => {
    if (scripList.length === 0 && symbolListRef.current) {
      symbolListRef.current.scrollToOffset({ offset: 0, animated: false })
    }

    if(startTour) {
      changePtComponentStatus('landedReviewStep')
    }
  }, [scripList])
  // const onSymbolPress = (seg_sym, isSelected, item, index) => {
  //   const { segment = '', details } = item
  //   const clickable = Boolean(details || DYNAMIC_SEGMENTS.includes(segment.toUpperCase()))
  //   if (clickable) {
  //     convertToDynamic(item, index)
  //   } else {
  //     onSymbolDelete(seg_sym, isSelected)
  //   }
  // }
  const renderSymbols = (item, index) => {
    const { segment = '', details, type } = item
    if (type === 'create') {
      return renderListHeader(item)
    }
    const { seg_sym } = segSymConverter(item)
    const isSelected = false // selectedSymbols.has(seg_sym)
    const actionBg = isDark ? `${COLORS.BLACK_80}90` : '#ffffffa0'
    const tooltipText = seg_sym.replace('_', ' ')
    // const sym = seg_sym.includes('DYNAMIC CONTRACT')
    // ? compressedDYCName(seg_sym) : seg_sym.split('_')[1]
    // // const symbolName = compressedDYCName(sym)
    // const ttText = ` ${segment} ${sym}`
    return (
      <CustomTooltip
        show={tooltipText && Boolean(tooltipText.trim())}
        key={keyExtractor(item)}
        tooltipProps={{ text: tooltipText }}
      >
        <div className={styles.symbolWrapper}>
          {!isMobile && !startTour && (
            <div className={styles.symbolOptions} style={{ backgroundColor: actionBg }}>
              {(showCreateAdvance || (!showCreateAdvance && createAdvance)) && (
                Boolean(details || DYNAMIC_SEGMENTS.includes(segment.toUpperCase()))) && (
                  <div>
                    <button
                      type="button"
                      onClick={() => convertToDynamic(item, index - 1)}
                    >
                      <Icon
                        name={ICONS.DYNAMIC_CONTRACT}
                        color={COLORS.BLUE}
                        className={styles.symbolOptionsIcon}
                        size={20}
                      />
                    </button>
                  </div>
              )}
              <div>
                <button
                  type="button"
                  onClick={() => onSymbolDelete(seg_sym, isSelected, item, index - 1)}
                >
                  <Icon
                    name={ICONS.DELETE}
                    color={COLORS.RED}
                    className={styles.symbolOptionsIcon}
                    size={20}
                  />
                </button>
              </div>
            </div>
          )}
          <button
            type="button"
            disabled={!isMobile}
            style={{ textAlign: 'left' }}
            onClick={() => {
              symbolProps = {
                seg_sym, isSelected, item, i: index - 1,
              }; toggleOptionsMenu(true)
            }}
          >
            <SymbolCard
              isSelected={isSelected}
              seg_sym={seg_sym}
              cardHeight={CARD_HEIGHT}
              condensedHeight={CONDENDED_HEIGHT}
              cardWidth={CARD_WIDTH}
            />
          </button>
        </div>
      </CustomTooltip>

    )
  }

  const renderListHeader = (item) => {
    return (
      <CreateCard
        wrapperStyle={styles.addSymbolCardWrapper}
        containerStyle={styles.addSymbolCard}
        key={keyExtractor(item)}
        onPress={addNewSymbol}
        title="Add"
        description="Stocks eg, SBIN"
      />
    )
  }

  const hideOptionMenu = () => {
    toggleOptionsMenu(false)
  }

  const onUpdateGA = () => {
    const gaLabel = activeTourIndex === 2 ? 'Ct Strat Pg 3' : 'Ct Strat Pg 12'
    updateGA('Highlight Click', gaLabel, 1, 'Product Tour')
  }

  const renderSymbolSection = () => {
    if (scripList.length === 0 && isMultiPage) {
      const hasError = showError && !scripValid
      return (
        <>
          {hasError ? (
            <div className={styles.symboldErrRow}>
              <CustomText weight="medium" size="small" color="red">
                Please select some stocks
              </CustomText>
              <CustomText weight="medium" size="small" color="red">Error message</CustomText>
            </div>
          ) : null}
          <div
            id="tour_create_strategy_3"
            className={`${styles.emptySymbolSection} ${hasError ? styles.symbolSectionErr : ''}`}
            role="button"
            onClick={onUpdateGA}
            tabIndex={0}
            onKeyUp={onUpdateGA}
          >
            {hasError ? (
              <Icon name={ICONS.WARN} color={COLORS.RED} size={12} className={styles.errorIcon} />
            ) : null}
            <img
              src={ASSETS[isDark ? 'ADD_STOCKS_DARK' : 'ADD_STOCKS']}
              className={styles.addStockImg}
              alt="add_stocks"
            />
            <CustomText weight="semi_bold" size="medium_1" style={{ marginBottom: SPACING.SPACE_20 }}>
              Add your first stock
            </CustomText>
            <button
              type="button"
              onClick={addNewSymbol}
              className={styles.addSymboBtn}
            >
              <div className={styles.addSymbol}>
                <Icon name={ICONS.ADD} color={COLORS.ORANGE} size={14} />
              &nbsp;
              &nbsp;
                <CustomText color="orange" weight="semi_bold">Add Symbols, F&O etc.</CustomText>
              </div>
            </button>
          </div>
        </>
      )
    }
    const renderOptionsMenu = () => {
      const {
        item = {}, i, seg_sym, isSelected,
      } = symbolProps
      return (
        <>
          {(showCreateAdvance || (!showCreateAdvance && createAdvance))
             && Boolean(item.details
            || (item.segment && DYNAMIC_SEGMENTS.includes(item.segment.toUpperCase()))) && (
            <button
              type="button"
              onClick={() => {
                convertToDynamic(item, i)
                hideOptionMenu()
              }}
              className={styles.dycOptionsBtn}
            >
              <Icon
                name={ICONS.DYNAMIC_CONTRACT}
                color={COLORS.BLUE}
                style={{ marginRight: SPACING.SPACE_20 }}
              />
              <CustomText>
                Convert to dynamic contract
              </CustomText>
            </button>
          )}
          <button
            type="button"
            onClick={() => {
              onSymbolDelete(seg_sym, isSelected, item, i)
              hideOptionMenu()
            }}
            className={styles.deleteOptionsBtn}
          >
            <Icon
              name={ICONS.DELETE}
              color={COLORS.RED}
              style={{ marginRight: SPACING.SPACE_20 }}
            />
            <CustomText color="red">
              Delete
            </CustomText>
          </button>
        </>
      )
    }
    return (
      <div className={styles.symbolSection}>
        <AnimatedCarousal
          singleView={false}
          showNav
          startTour={startTour}
          activeTourIndex={activeTourIndex}
          tourType={tourType}
          updateGA={updateGA}
        >
          {[{ segment: 'create', symbol: 'card', type: 'create' }, ...scripList].map(renderSymbols)}
        </AnimatedCarousal>
        {isMobile && !startTour
        && (
        <CustomModal
          visible={showOptionsMenu}
          onDismiss={hideOptionMenu}
          dismissKey="showOptionsMenu"
        >
          {renderOptionsMenu()}
        </CustomModal>
        )}
      </div>
    )
  }

  const renderSymbolSectionHeader = () => {
    return (
      <div className={styles.positionsContainer}>
        {conditionEditType === CONDITION_TYPE.REVIEW ? (
          <ToggleButton
            selected={positionType.toLowerCase()}
            btnMap={postionBtnMap}
            toggleHandler={togglePositions}
            containerStyles={styles.positions}
            isDark={isDark}
            btnWidth={60}
          />
        ) : (
          <CustomText weight="semi_bold" size="medium">Instruments</CustomText>
        )}

        {scripList.length !== 0 && (
        <button
          type="button"
          className={classnames(styles.viewAllBtn, 'view-all-button')}
          onClick={() => addNewSymbol(true)}
        >
          View all
          &nbsp;
          <Icon name={ICONS.FORWARD} color={COLORS.GREY} size={9} />
        </button>
        )}
        {/* <Button
          text="Buy"
          onPress={togglePositions}
          params="buy"
          containerStyle={[styles.postionBtnContainer, buyStyles]}
          mode={buySelected ? 'contained' : 'text'}
          buttonColor={buySelected ? COLORS.BLUE : 'transparent'}
          contentStyle={buyStyles}
          roundness={6}
          labelColor={isDark && !buySelected ? COLORS.BLACK_60 : ''}
          labelStyle={[styles.postionBtn]}
        />
        <Button
          text="Sell"
          onPress={togglePositions}
          params="sell"
          containerStyle={[styles.postionBtnContainer, sellStyles]}
          mode={!buySelected ? 'contained' : 'text'}
          buttonColor={!buySelected ? COLORS.ORANGE : 'transparent'}
          contentStyle={sellStyles}
          labelColor={isDark && buySelected ? COLORS.BLACK_60 : ''}
          roundness={6}
          labelStyle={[styles.postionBtn]}
        /> */}
      </div>
    )
  }
  return (
    <div
      id="tour_create_strategy_12"
      ref={scrollRef}
      className={`${styles.symbolCard} ${containerStyles}`}
      role="button"
      onClick={onUpdateGA}
      tabIndex={0}
      onKeyUp={onUpdateGA}
    >
      {renderSymbolSectionHeader()}
      {renderSymbolSection()}
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    backgroundColor: theme.screenBg,
  },
  positionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  positions: {
    marginBottom: SPACING.SPACE_10,
  },
  positionImg: {
    height: 20,
    width: 20,
    marginRight: SPACING.SPACE_8,
  },
  symbolCard: {
    borderRadius: 8,
    marginTop: SPACING.SPACE_16,
    '&:hover': {
      '& $viewAllBtn': {
        color: theme.linkColor,
        '& > svg': {
          fill: theme.linkColor,
        },
      },
    },
    // height: SYMBOLS_HEIGHT,
  },
  symbolSection: {},
  addSymboBtn: {
    borderColor: COLORS.ORANGE,
    borderWidth: 2,
    borderRadius: 6,
    backgroundColor: theme.bgPrimary,
    borderStyle: 'dashed',
  },
  emptySymbolSection: {
    textAlign: 'center',
    minHeight: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: SPACING.SPACE_10,
  },
  symbolSectionErr: {
    border: `1px solid ${COLORS.RED}`,
    borderRadius: 6,
    backgroundColor: theme.redLightBg,
    marginTop: 0,
    position: 'relative',
  },
  symboldErrRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: SPACING.SPACE_10,
    marginBottom: SPACING.SPACE_6,
  },
  errorIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  addSymbol: {
    display: 'flex',
    textAlign: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_24}`,
    alignItems: 'center',
  },
  addSymbolCardWrapper: {
    height: CARD_HEIGHT,
    margin: `${SPACING.SPACE_10} ${SPACING.SPACE_10} ${SPACING.SPACE_10} 0`,
    backgroundColor: theme.bgPrimary,
  },
  addSymbolCard: {
    width: CARD_WIDTH,
  },
  symbolWrapper: {
    position: 'relative',
    margin: SPACING.SPACE_10,
    '&:hover': {
      '& $symbolOptions': {
        opacity: 1,
      },
    },
  },
  symbolOptions: {
    position: 'absolute',
    opacity: 0,
    top: '0',
    left: '0',
    transition: 'height 0.3s var(--anim-func-ease)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 6,
    '& > div': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  symbolOptionsIcon: {
    // maxWidth: '30px',
    width: '100%',
    height: '100%',
    maxHeight: 20,
  },
  viewAllBtn: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.GREY,
  },
  addStockImg: {
    width: '100%',
    maxWidth: '90px',
    marginBottom: SPACING.SPACE_20,
  },
  dycOptionsBtn: {
    display: 'inline-flex',
    width: '100%',
    textAlign: 'left',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} 0`,
  },
  deleteOptionsBtn: {
    display: 'inline-flex',
    width: '100%',
    textAlign: 'left',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} 0`,

  },
})

export default CreateView
