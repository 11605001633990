import React, { useEffect } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { connect } from 'react-redux'

import isEqual from 'lodash/isEqual'
import ShowLTP from '../../UI/ShowLTP'
import CustomText from '../../UI/CustomText'
import StockIcon from '../../UI/StockIcon'
import SegSym from '../../UI/SegSym'
import Icon from '../../UI/Icon'
import { Row } from '../Deployed/DeployedRow'

import {
  SPACING, theme, ICONS, COLORS,
} from '../../Theme'
import DotLoader from '../../UI/DotLoader'
import Button from '../../UI/Button'
import CustomModal from '../../UI/CustomModal'
import { LINK_MAP } from '../../utils/consts'
import { ALGO_KEY_MAP } from '../AlgoCondensedView/AlgoUtils'

const areEqual = (props, nextProps) => {
  const {
    item, index, last_price, change, seg_sym, showSelection,
  } = props
  if(!isEqual(item, nextProps.item)
  || !isEqual(index, nextProps.index)
  || !isEqual(last_price, nextProps.last_price)
  || !isEqual(change, nextProps.change)
  || !isEqual(showSelection, nextProps.showSelection)
  || !isEqual(seg_sym, nextProps.seg_sym)) {
    return false
  }
  return true
}

const BacktestResultItem = React.memo(({
  item,
  seg_sym,
  isDark,
  renderPnl,
  showSelection,
  reRunBacktestHandler,
  mtfExist,
  removeOverLay,
  renderNudge,
  showNudgeTooltip,
  row,
  algoIdKey,
}) => {
  const {
    win_count,
    loss_count,
    total_number_of_signals,
    totalReturn,
    waiting,
    error_msg,
    max_draw,
    isSelected,
  } = item

  const [reRunBacktestModal, setReRunBacktestModal] = React.useState(false)

  useEffect(() => {
    const mtfEle = document.getElementById('mtfContainer')
    const mtfRowEle = document.getElementById(`${seg_sym}_mtf`)
    const mtfGridPnl = document.getElementById(`${seg_sym}_mtf_pnl`)
    const mtfCumuContainer = mtfEle?.style.display
    if(mtfCumuContainer === 'none') {
      mtfRowEle.style.display = 'none'
      mtfGridPnl.style.display = 'none'
    }
  }, [])

  const styles = stylesheet()
  const stockIconStyle = {
    marginRight: SPACING.SPACE_8,
    display: 'inline-block',
  }
  const renderCheckbox = () => {
    return (
      <>
        {showSelection && (
          <Icon
            name={isSelected ? ICONS.TICK_ROUND_FILLED : ICONS.CIRCLE_OUTLINED}
            color={theme.linkColor}
            size={16}
            className={styles.selectIconBtn}
          />
        )}
      </>
    )
  }
  const renderInstrument = () => (
    <div className={styles.instrument}>
      {showSelection ? renderCheckbox() : (
        <StockIcon seg_sym={seg_sym} height={15} width={15} containerStyle={stockIconStyle} />
      )}
      <SegSym
        seg_sym={seg_sym}
        ellipsis={false}
        showTooltip
        optionStyles={{ position: 'relative' }}
      />
      {waiting === false && algoIdKey === ALGO_KEY_MAP.MY_ALGOS
        && (
        <button type="button" onClick={(e) => { showNudgeTooltip(e, seg_sym) }}>
          {renderNudge(row)}
        </button>
        )}
    </div>
  )
  const renderWvL = () => {
    const renderTypeStyles = styles[win_count >= loss_count ? 'gain' : 'loss']
    if (waiting) {
      return (
        <div className={styles.pnlPlaceHolder}>
          <DotLoader props={{ width: 30 }} />
        </div>
      )
    }
    return (
      <div className={styles.wvl}>
        <CustomText color="textSecondary" className={styles.labelStyles}>W v L:</CustomText>
        <CustomText weight="medium" className={renderTypeStyles}>{`${win_count} | ${loss_count}`}</CustomText>
      </div>
    )
  }
  const renderSignals = () => {
    if (waiting) {
      return (
        <div className={styles.pnlPlaceHolder}>
          <DotLoader props={{ width: 30 }} />
        </div>
      )
    }
    return (
      <div className={styles.signals}>
        <CustomText color="textSecondary" className={styles.labelStyles}>Signals:</CustomText>
        <CustomText>{total_number_of_signals}</CustomText>
      </div>
    )
  }
  const renderLtp = () => {
    return (
      <div className={styles.ltp}>
        <CustomText color="textSecondary" className={styles.labelStyles}>LTP:</CustomText>
        <ShowLTP
          seg_sym={seg_sym}
          ltpStyles={styles.ltpStyles} // { opacity: isClosed ? 0.5 : 1 }
          changeStyles={styles.changeStyles}
          changeContainerStyles={styles.changeContainer}
          showBrackets
          hideChange
          showLtpColor={!isDark}
          hideChangeColor={isDark}
        />
      </div>
    )
  }
  const renderPNL = () => {
    return (
      <div className={styles.pnl}>
        {mtfExist && (
        <div className={styles.mtfPnl} id={`${seg_sym}_mtf_pnl`}>
          <CustomText
            size="small"
          >
            PNL:
            <span
              className={styles.valueBlur}
            >
              N/A
            </span>
          </CustomText>
        </div>
        )}
        <CustomText color="textSecondary" className={styles.labelStyles}>PNL:</CustomText>
        { renderPnl({
          error_msg, final_return: totalReturn, waiting,
        })}
      </div>

    )
  }
  const renderMaxdd = () => {
    const max_dd = max_draw || typeof max_draw === 'number' ? Number(max_draw).toFixed(4) : ''

    if (waiting) {
      return (
        <div className={styles.pnlPlaceHolder}>
          <DotLoader props={{ width: 30 }} />
        </div>
      )
    }
    return (
      <div className={styles.maxdd}>
        <CustomText color="textSecondary" className={styles.labelStyles}>Max DD:</CustomText>
        <CustomText>{max_dd}</CustomText>
      </div>
    )
  }

  const renderWS = () => {
    if (waiting) {
      return (
        <div className={styles.pnlPlaceHolder}>
          <DotLoader props={{ width: 30 }} />
        </div>
      )
    }
    return (
      <div className={styles.signals}>
        <CustomText color="textSecondary" className={styles.labelStyles}>WS:</CustomText>
        <CustomText>{total_number_of_signals}</CustomText>
      </div>
    )
  }

  const renderLS = () => {
    if (waiting) {
      return (
        <div className={styles.pnlPlaceHolder}>
          <DotLoader props={{ width: 30 }} />
        </div>
      )
    }
    return (
      <div className={styles.signals}>
        <CustomText color="textSecondary" className={styles.labelStyles}>LS:</CustomText>
        <CustomText>{total_number_of_signals}</CustomText>
      </div>
    )
  }

  const renderMTFDesc = () => {
    return (
      <div className={styles.mtfDescMobile} id="mtfListView">
        <CustomText size="small_1" weight="medium" className={styles.mtfTitle} color="orange" id={`${seg_sym}_mtf_title`}>
          Backtest results for MTF are unverifiable.
          <a href={LINK_MAP.MTF.link} rel="noreferrer" target="_blank"> Learn More.</a>
        </CustomText>
        <Button
          mode="text"
          labelColor="blue_300"
          transparent
          onPress={() => removeOverLay(seg_sym)}
          text="Show result"
          btnStyles={styles.showResultBtnMobile}
          id={`${seg_sym}_mtf_btn`}
          respText
        />
      </div>
    )
  }

  return (
    <li className={styles.rowWrapper}>
      <div style={{ display: error_msg ? 'none' : 'block' }}>
        <Row>
          {renderInstrument()}
          {renderPNL()}
        </Row>
        <Row>
          {renderMaxdd()}
          {renderLtp()}
        </Row>
        <Row>
          <Row styles={{ margin: 0, justifyContent: 'flex-start' }}>
            {renderWvL()}
            {renderSignals()}
          </Row>
          <Row styles={{ margin: 0, gap: 10, justifyContent: 'flex-end' }}>
            {renderWS()}
            {renderLS()}
          </Row>
        </Row>
      </div>
      {mtfExist
      && (
      <div
        className={styles.mtfContent}
        id={`${seg_sym}_mtf`}
      >
        {renderMTFDesc(seg_sym)}
      </div>
      )}
      {
        error_msg && (
          <div>
            <Row>
              {renderInstrument()}
              <div className={styles.pnl}>
                <CustomText className={styles.labelStyles}>N/A</CustomText>
              </div>
            </Row>

            <Button
              onPress={() => reRunBacktestHandler(seg_sym)}
              mode="container"
              btnStyles={styles.reRunBacktestButton}
            >
              <div className={styles.error_msg}>
                <CustomText size="small_0" style={{ textAlign: 'left' }} color={theme.reRunBacktestText}>
                  {error_msg}
                </CustomText>
              </div>
              <Icon
                name={ICONS.REFRESH}
                style={{ marginRight: SPACING.SPACE_8 }}
                color={COLORS.BLUE}
                size={14}
              />
            </Button>
          </div>
        )
      }
      <div className={styles.seperator} />
      <CustomModal
        visible={reRunBacktestModal}
        dismissKey="reRunBacktest"
        onDismiss={() => setReRunBacktestModal(false)}
        containerStyles={styles.modalStyles}
      >
        <button type="button" style={{ width: '100%' }} className={styles.modalContainer} onClick={e => e.stopPropagation()}>
          <div className={styles.modalHeader}>
            <CustomText weight="medium">
              Backtest Failed
            </CustomText>
          </div>
          <div className={styles.line} />
          <CustomText size="small" style={{ margin: `${SPACING.SPACE_10} 0`, textAlign: 'left' }}>
            {error_msg}
          </CustomText>
        </button>
      </CustomModal>
    </li>
  )
}, areEqual)

const stylesheet = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20} ${SPACING.SPACE_10} ${SPACING.SPACE_32}`,
    width: '100%',
  },
  instrument: {
    display: 'flex',
    paddingRight: SPACING.SPACE_8,
    textAlign: 'left',
    alignItems: 'center',
  },
  pnl: {
    display: 'flex',
    alignItems: 'center',
  },
  ltp: {
    display: 'flex',
    alignItems: 'center',
  },
  signals: {
    display: 'flex',
    alignItems: 'center',
  },
  naText: {
    color: `${theme.textSecondary}  !important`,
    //   fontSize: `${FONTS.REGULAR}px !important`,
  },
  ltpRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wvl: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: SPACING.SPACE_8,
  },
  maxdd: {
    display: 'flex',
    alignItems: 'center',
  },
  labelStyles: {
    marginRight: SPACING.SPACE_6,
  },
  // mtf disc styles
  mtfContent: {
    position: 'absolute',
    top: 40,
    right: 15,
    background: theme.bgPrimary,
    zIndex: 999,
    width: '93%',
  },
  mtfPnl: {
    position: 'absolute',
    background: theme.bgPrimary,
    zIndex: 9997,
    display: 'flex',
    justifyContent: 'end',
    width: 120,
    gap: 10,
  },
  mtfDescMobile: {
    flexDirection: 'column',
  },
  mtfTitle: {
    background: theme.mtfTitleBg,
    padding: SPACING.SPACE_8,
    borderRadius: 8,
  },
  showResultBtnMobile: {
    marginTop: SPACING.SPACE_10,
    background: theme.mtfTitleBg,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    width: '100%',
    zIndex: 999,
  },
  valueBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #3CBC00',
    userSelect: 'none',
  },
  '@media only screen and (max-width: 786px)': {
    row: {
      flexDirection: 'column',
      padding: `${SPACING.SPACE_12} ${SPACING.SPACE_20}`,
    },
    rowWrapper: {
      width: '100%',
      boxSizing: 'border-box',
      padding: `${SPACING.SPACE_12} ${SPACING.SPACE_20}`,
      '& > button': {
        width: '100%',
      },
    },
    ltpRow: {
      flexDirection: 'row-reverse',
    },
    seperator: {
      height: 1,
      backgroundColor: theme.borderColor,
      width: '100%',
    },
    avgPrice: {
      marginLeft: SPACING.SPACE_10,
    },
    instrument: {
      width: '60%',
    },
    pnl: {
      flex: 1,
      justifyContent: 'flex-end',
    },
    selectIconBtn: {
      marginRight: SPACING.SPACE_8,
    },
    reRunBacktestButton: {
      width: '100%',
      marginTop: SPACING.SPACE_16,
      marginBottom: SPACING.SPACE_12,
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    error_msg: {
      background: theme.reRunBacktestBg,
      borderRadius: SPACING.SPACE_4,
      padding: SPACING.SPACE_8,
      width: '80%',
    },
    modalStyles: {
      width: '100%',
      height: 'max-content',
      minHeight: 'unset',
    },
    modalContainer: {
      padding: `0 ${SPACING.SPACE_4}`,
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${SPACING.SPACE_12} 0`,
    },
    line: {
      height: 1,
      backgroundColor: theme.borderColor,
      width: '100%',
      marginBottom: SPACING.SPACE_20,
    },
    mtfContent: {
      width: '96%',
    },
  },
  '@media only screen and (max-width: 500px)': {
    mtfContent: {
      width: '93%',
    },
  },
  '@media only screen and (max-width: 400px)': {
    mtfContent: {
      width: '90%',
    },
  },
})

const mapStateToProps = (state, ownProps) => {
  const { seg_sym } = ownProps
  const { seg_sym_list, ticks } = state.instruments
  const token = seg_sym_list[seg_sym]
  const tick = ticks[token] || {}
  const { last_price = 0.0, change = 0.0, day_open_price = 0.0 } = tick

  return {
    last_price,
    day_open_price,
    change,
  }
}

export default connect(mapStateToProps, null)(BacktestResultItem)
