/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import html2canvas from 'html2canvas'

import Button from '../../UI/Button'
import CustomText from '../../UI/CustomText'
import Switch from '../../UI/Switch'
// import BacktestChart from './BacktestChart'
import Icon from '../../UI/Icon'
// import ModalDropdown from '../../UI/Dropdown/ModalDropdown'
import ShareModal from '../../UI/ShareModal'
// import TvLiteWidget from '../Marketwatch/TvLiteWidget'
import PlaceHolderLoader from '../../UI/PlaceHolderLoader'
import StockIcon from '../../UI/StockIcon'
import TransactionDetails from './TransactionDetails'
import BacktestChart from './BacktestChart'
import EmptyContainer from '../../UI/EmptyContainer'
import Marquee from '../../UI/Marquee'

import {
  ICONS, COLORS, SPACING, theme, FONTWEIGHT, FONTS, ASSETS,
} from '../../Theme'
import { fetchShareLink, generateShareLink, fetchBacktestDetails } from '../../containers/Backtests/actions'
import { shareUrl, embedUrl } from '../../utils/apiEndpoints'
import { LINK_MAP, shareModalOptions } from '../../utils/consts'
import { accessConditions, userAccessCheck } from '../../utils/userAccessChecks'
import { compressedDYCName, segSymConverter, symbolNameByOptions } from '../../utils/common'
import { withTheme } from '../../Theme/ThemeProvider'
import { showSnackbar } from '../../UI/Snackbar'
import { ALGO_KEY_MAP } from '../AlgoCondensedView/AlgoUtils'
// eslint-disable-next-line import/no-cycle

class BacktestsDetails extends Component {
  constructor(props) {
    super(props)
    const { item: { seg_sym, backtest_result = {} } } = props
    const { final_pnl = 0 } = backtest_result[seg_sym] || {}
    this.prevPnl = final_pnl >= 0 ? 'gain' : 'loss'
    this.state = {
      isPublic: false,
      // brokerage: false,
      shareModal: false,
      // brokerageData: [],
      // brokerageLogs: [],
      shareSelect: false,
      prevPnl: this.prevPnl,
      mtfObj: {},
    }
    this.viewShotRef = {}
    this.shareData = {}
    this.shareModalParams = {}
    // this.brokerageData = {}
    this.shareMenuOptions = [
      {
        label: 'Get Shareable Link', value: 'getLink', icon: ICONS.LINK, iconColor: 'TEXT', iconSize: 18,
      },
      {
        label: 'Download as Image', value: 'downloadImage', icon: ICONS.IMAGE, iconColor: 'TEXT', iconSize: 18,
      },
      {
        label: 'Embed backtest results', value: 'getCode', icon: ICONS.EMBED_CODE, iconColor: 'TEXT', iconSize: 21,
      },
    ]
  }

  componentDidMount() {
    const { item, backtestDetails, isListView } = this.props
    const { seg_sym } = item
    const mtfCumuEle = document.getElementById('mtfContainer')
    const mtfEle = document.getElementById(`${seg_sym}_mtf`)
    const mtfCumuContainer = mtfCumuEle?.style.display
    if (item && !isEmpty(item)) {
      this.getBacktestDetails()
    }
    if(mtfCumuContainer === 'none') {
      mtfEle.style.display = 'none'
    }
    Object.keys(backtestDetails).forEach((itemBt) => {
      const mtfGridPnl = document.getElementById(`${itemBt}_mtf_pnl`)
      if(mtfCumuContainer === 'none' && !isListView) {
        mtfGridPnl.style.display = 'none'
      }
    })
  }

  componentDidUpdate = async (prevProps) => {
    const {
      // share
      generateShareLinkError,
      generateShareLinkErrorMsg,
      backtest_share_uuid,
      generateShareLinkSuccess,
      getShareLink,
      shareLinkId,
      fetchShareLinkError,
      item: {
        backtest_result = {},
        seg_sym: itemSegSym,
      },
      backtestDetails,
      brokerage,
      updateBrokerage,
      user_details,
      brokerageData,
      mtfExist,
      isListView,
    } = this.props
    const { shareSelect, mtfObj } = this.state
    const BTScreen = document.getElementById(itemSegSym)
    const mtfEle = document.getElementById(`${itemSegSym}_mtf`)
    const mtfCumuEle = document.getElementById('mtfContainer')
    const mtfCumuContainer = mtfCumuEle?.style.display
    if(mtfCumuContainer !== 'none' && !mtfObj[itemSegSym] && mtfExist) {
      BTScreen.style.display = 'none'
      mtfEle.style.display = 'block'
    }
    if(mtfCumuContainer === 'none') {
      mtfEle.style.display = 'none'
    }
    Object.keys(backtestDetails).forEach((item) => {
      const mtfGridPnl = document.getElementById(`${item}_mtf_pnl`)
      if(mtfCumuContainer === 'none' && !isListView) {
        mtfGridPnl.style.display = 'none'
      }
    })
    if (!isEqual(generateShareLinkError, prevProps.generateShareLinkError)
      && generateShareLinkError) {
      showSnackbar(generateShareLinkErrorMsg, {}, 0)
    } else if (!isEqual(generateShareLinkSuccess, prevProps.generateShareLinkSuccess)
      && generateShareLinkSuccess && shareSelect) {
      showSnackbar('Generating shareable link')
      const {
        seg_sym = '',
        imgData = '',
        shareAlgoId = '',
      } = this.shareData
      const hostName = 'pro.streak.tech'
      let srcUrl = `https://${hostName}`
      let extraParam = {}
      const user_name = user_details.user_name
      if (backtest_share_uuid) {
        srcUrl = `https://${hostName}/backtests/?sbt=${backtest_share_uuid}&user=${btoa(user_name)}`
      } else if (shareAlgoId) {
        srcUrl = `https://${hostName}/backtests/?sbt=${shareAlgoId}&user=${btoa(user_name)}`
        extraParam = {
          origin_url: `https://${hostName}`,
        }
      }
      const id = backtest_share_uuid || shareAlgoId
      const encodedEmbedUrl = `${embedUrl}?id=${id}&s=${seg_sym}&source=in`
      const embed_url = id ? `http://api.streak.world/oembed/?url=${encodeURIComponent(encodedEmbedUrl)}&format=json` : ''
      if (imgData) {
        const shareHtmlParams = {
          title: "Streak | World's first systematic trading platform without coding",
          desc: 'Systematic trading platform',
          img_alt: `${seg_sym} backtest image`,
          src_url: srcUrl,
          src_text: backtest_share_uuid ? 'View full result' : 'Visit Streak',
          platform: 'mobile',
          embed_url,
          source: 'in',
          ...extraParam,
        }
        const headers = new Headers()
        headers['content-type'] = 'multipart/form-data'
        const formData = new FormData()
        formData.append('img_src', imgData)
        formData.append('params', JSON.stringify(shareHtmlParams))
        getShareLink(formData, headers)
      } else {
        this.shareModalParams = {
          url: encodedEmbedUrl,
          embedCode: true,
        }
        this.toggleModal('shareModal')
        this.setState({ shareSelect: false })
      }
    }
    // got the link id to public endpoint
    if (shareLinkId !== prevProps.shareLinkId && shareLinkId && shareSelect) {
      const {
        imgData = '',
      } = this.shareData
      // eslint-disable-next-line no-unused-vars
      const shareOptions = {
        ...shareModalOptions,
        url: imgData,
        message: `Hey, check out this strategy at ${shareUrl}${shareLinkId}`,
      }
      this.shareModalParams = {
        url: `${shareUrl}${shareLinkId}`,
      }
      this.setState({ shareSelect: false })
      // if(!multiShare) {
      this.toggleModal('shareModal')
      // }
      // this.toggleModal('shareModal')
      // const ShareResponse = await Share.open(shareOptions)
      this.shareData = {}
    } else if (fetchShareLinkError !== prevProps.fetchShareLinkError && fetchShareLinkError) {
      showSnackbar('Not able to fetch link', {}, 0)
    }
    if (backtest_result && prevProps.item.backtest_result
      && !isEqual(prevProps.item.backtest_result[prevProps.item.seg_sym],
        backtest_result[itemSegSym])) {
      const { final_pnl } = backtest_result[itemSegSym] || {}
      this.setState({ prevPnl: this.prevPnl })
      this.prevPnl = final_pnl >= 0 ? 'gain' : 'loss'
    }
    if (brokerage
      && !isEmpty(backtestDetails[itemSegSym])
      && (!isEqual(backtestDetails[itemSegSym], prevProps.backtestDetails[itemSegSym])
        || !brokerageData[itemSegSym])
    ) {
      updateBrokerage({
        backtest_result,
        seg_sym: itemSegSym,
      })
    }
  }

  // generating a id for algo
  generateShareLink = (imgData) => {
    const {
      csrf,
      createShareLink,
      item: { seg_sym }, algo_uuid, publishing_uuid, algo_subscription_uuid,
    } = this.props
    const { isPublic } = this.state

    let id = {}
    if (publishing_uuid || algo_subscription_uuid) {
      id = algo_subscription_uuid ? { algo_subscription_uuid } : { publishing_uuid }
    } else if (algo_uuid) {
      id = { algo_uuid }
    }
    this.shareData = {}

    createShareLink({
      ...id,
      seg_sym,
      csrfmiddlewaretoken: csrf,
      public: isPublic ? true : '',
    }, this.headers)

    let shareAlgoId = ''
    if (algo_subscription_uuid) {
      shareAlgoId = `AS_${algo_subscription_uuid}`
    } else if (publishing_uuid) {
      shareAlgoId = `P_${publishing_uuid}`
    } else if (algo_uuid) {
      shareAlgoId = `A_${algo_uuid}`
    }
    this.shareData = {
      seg_sym, imgData, shareAlgoId,
    }
  }

  renderCell = (label, value) => {
    const { styles, isMobile } = this.props
    return (
      <div className={styles.cell} style={{ width: isMobile ? '50%' : 140 }}>
        <CustomText weight="medium">{value}</CustomText>
        <CustomText size="SMALL_0" color="textSecondary">{label}</CustomText>
      </div>
    )
  }

  renderHeaderCell = (label, value, flex = 0.4) => {
    const { styles, isDark } = this.props
    return (
      <div className={styles.cell} style={{ flex }}>
        <CustomText center size="small" weight="semi_bold">{value}</CustomText>
        <CustomText
          center
          size="SMALL_0"
          weight="regular"
          color={isDark ? 'textSecondary' : ''}
          className={styles.headerLabel}
        >
          {label}

        </CustomText>
      </div>
    )
  }

  getBacktestDetails = () => {
    const {
      getBacktestDetails,
      item: {
        seg_sym,
        algo_uuid, algo_subscription_uuid, publishing_uuid, algo_subscribed,
      }, backtestDetails,
      shared, isCtb,
    } = this.props
    const { waiting, error } = backtestDetails[seg_sym] || {}
    let finalSegSym = seg_sym
    if(seg_sym.includes('&')) {
      finalSegSym = finalSegSym.replace('&', '%26')
    }
    if (!waiting && !error && (algo_uuid || algo_subscription_uuid || publishing_uuid)) {
      if(!shared && !isCtb) {
        getBacktestDetails({
          seg_sym: finalSegSym,
          algo_uuid,
          algo_subscription_uuid,
          publishing_uuid,
          algo_subscribed,
        })
      }
    }
  }

  backPressHandler = () => {
    const {
      navigation,
    } = this.props
    navigation.pop()
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  renderPnlChart = (brokerageLogs) => {
    const {
      backtestDetails,
      item: {
        seg_sym,
      },
      styles,
      brokerage,
    } = this.props
    const { waiting } = backtestDetails[seg_sym] || {}
    if (waiting) {
      return (
        <PlaceHolderLoader className={styles.graph} />
      )
    }
    // if (brokerageLogs.length === 0 && brokerage) {
    //   return <CustomText>Not able to fetch chart </CustomText>
    // }
    const modChartData = brokerageLogs.map(
      item => ({ y: Number(item[5].toFixed(2)), x: item[0] }),
    )
    return (
      <BacktestChart
        id={seg_sym}
        data={modChartData}
        theme={theme}
        brokerage={brokerage}
      />
    )
  }

  inputRenderer = () => {
    const { styles, algoIdKey } = this.props
    if(algoIdKey === ALGO_KEY_MAP.SHARED_ALGOS) {
      return null
    }
    return (
      <div className={styles.shareBtn}>
        <CustomText weight="medium" size="small" color="linkColor" style={{ marginRight: SPACING.SPACE_8 }}>Share</CustomText>
        <Icon name={ICONS.SHARE} color={theme.linkColor} size={10} />
      </div>
    )
  }

  renderPeriodReturn = ({ period_high, period_low, period_return }) => {
    const { styles } = this.props
    return (
      <div className={styles.periodDetails}>
        {this.renderHeaderCell('Period High', period_high.toFixed(2), 0.2)}
        {this.renderHeaderCell('Period Low', period_low.toFixed(2), 0.45)}
        {this.renderHeaderCell('Period Return', `${(+period_return).toFixed(2)} %`, 0.25)}
      </div>
    )
  }

  toggleBrokerage = () => {
    const { item, toggleBrokerage } = this.props
    toggleBrokerage(item)
  }

  renderBacktestContent = ({
    brokerageLogs, isMobile,
    isFetchingShareableLink,
    isGeneratingShareLink,
    deploySymbols,
    isDeployed,
    showBrokeageToggle,
    brokerage,
    noData,
    waiting,
    pnlColor,
  }) => {
    const {
      csrf, sessionid, styles, details,
      item, algo_uuid, publishing_uuid, isDark, algoIdKey, shared,
      algo_name, isLoggedIn, isListView, updateGA, gaCategory,
      isCtb, showSideBar,
    } = this.props
    const { backtest_result, seg_sym } = item
    const precision = 2
    const {
      win_count = 0,
      total_number_of_signals = 1,
      loss_count = 0,
      winning_streak = 0,
      lossing_streak = 0,
      average_gain_per_losing_trade = 0,
      average_gain_per_winning_trade = 0,
      maximum_loss = 0,
      maximum_gain = 0,
      max_draw,
      period_return = 0,
      period_low = 0,
      period_high = 0,
    } = backtest_result[seg_sym] || {}

    const { dt_start, dt_stop, time_frame } = details
    const max_dd = max_draw || typeof max_draw === 'number' ? Number(max_draw).toFixed(4) : ''
    const cellFlex = 0.6

    return (

      <div className={styles.content} data-value="sticky">
        {isLoggedIn && (
        <div className={styles.chartContainer} id="tour_bt_3">
          {this.renderPnlChart(brokerageLogs)}
        </div>
        )}
        {isMobile && this.renderInfoHeaderRight({
          isFetchingShareableLink,
          isGeneratingShareLink,
          deploySymbols,
          isDeployed,
          showBrokeageToggle,
          brokerage,
          seg_sym,
          noData,
          waiting,
          pnlColor,
          isMobile,
          algoIdKey,
        })}
        <div className={styles.backtestDetails} id="tour_bt_4">
          {this.renderCell('Total number of signals', total_number_of_signals, cellFlex)}
          {this.renderCell('Number of wins', win_count, cellFlex)}
          {this.renderCell('Number of losses', loss_count, cellFlex)}
          {this.renderCell('Winning streak', winning_streak)}
          {this.renderCell('Losing streak', lossing_streak)}
          {this.renderCell('Max gains', maximum_gain.toFixed(precision))}
          {this.renderCell('Max loss', maximum_loss.toFixed(precision), cellFlex)}
          {this.renderCell('Avg gain/winning trade', average_gain_per_winning_trade.toFixed(precision))}
          {this.renderCell('Avg loss/losing trade', average_gain_per_losing_trade.toFixed(precision), cellFlex)}
          {this.renderCell('Max DD', max_dd)}
        </div>
        <TransactionDetails
          algo_uuid={algo_uuid}
          publishing_uuid={publishing_uuid}
          trade_log={brokerageLogs}
          csrf={csrf}
          sessionid={sessionid}
          dt_start={dt_start}
          dt_stop={dt_stop}
          time_frame={time_frame}
          period_return={period_return}
          period_low={period_low}
          period_high={period_high}
          renderPeriodReturn={this.renderPeriodReturn}
          isMobile={isMobile}
          isDark={isDark}
          seg_sym={seg_sym}
          shared={shared}
          algo_name={algo_name}
          isLoggedIn={isLoggedIn}
          isListView={isListView}
          updateGA={updateGA}
          gaCategory={gaCategory}
          isCtb={isCtb}
          showSideBar={showSideBar}
        />
      </div>
    )
  }

  renderBacktestEmpty = (errMsg) => {
    const { styles } = this.props
    return (
      <EmptyContainer
        title=""
        subTitle={errMsg}
        subTitleStyles={styles.subTitleStyles}
        imgSrc="NO_BACKTESTS"
        containerStyles={styles.btEmptyContainer}
        imgStyles={styles.btEmptyImg}
      />
    )
  }

  getShareCode = () => {
    this.generateShareLink() // generateShareBacktestId
  }

  getShareImage = (type) => {
    const { item: { seg_sym } } = this.props
    showSnackbar('Fetching Info')
    // const waterMarkDiv = document.getElementById(`watermark_${seg_sym}`)
    // if (waterMarkDiv) waterMarkDiv.style.display = 'flex'
    const ele = document.getElementById(seg_sym)
    html2canvas(ele, {
      scale: 2,
      scrollY: -window.scrollY,
    })
      .then((canvas) => {
        const data = canvas.toDataURL('image/png')
        const imgTag = document.createElement('img')
        imgTag.src = data
        // imgTag.style.width = '100px'
        // imgTag.style.height = '100px'
        if (type === this.shareMenuOptions[1].value) {
          this.downloaderImg(data)
        } else if (type === this.shareMenuOptions[0].value) {
          this.generateShareLink(data)
        }
      }).catch(() => {
        showSnackbar('Some error occured', {}, 0)
      })
    // if (waterMarkDiv) waterMarkDiv.style.display = 'none'
  }

  downloaderImg = (data) => {
    const { item: { seg_sym } } = this.props
    const imgEle = document.createElement('a')
    imgEle.href = data
    imgEle.download = `${seg_sym.split('_').reverse().join('-')}.png`
    imgEle.click()
  }

  onShareOptionSelect = (type) => {
    this.setState({ shareSelect: true })
    if (type === this.shareMenuOptions[2].value) {
      this.getShareCode()
    } else {
      this.getShareImage(type)
    }
  }

  renderInfoHeaderRight = ({
    // isFetchingShareableLink,
    // isGeneratingShareLink,
    deploySymbols,
    isDeployed,
    showBrokeageToggle,
    brokerage,
    seg_sym,
    noData,
    canDeploy,
    waiting,
    pnlColor, isMobile,
    error_msg,
  }) => {
    const {
      styles, algoIdKey, startTour,
      isListView, renderNudge, item,
      showNudgeTooltip,
    } = this.props

    if(error_msg) return null
    return (
      <div className={styles.infoHeaderRight}>
        {noData || waiting ? (
          <div />
        ) : (
          <>
            {/* <ModalDropdown
              isGeneratingShareLink={isGeneratingShareLink}
              isFetchingShareableLink={isFetchingShareableLink}
              inputRenderer={this.inputRenderer}
              options={this.shareMenuOptions}
              onChangeOption={this.onShareOptionSelect}
              containerStyles={styles.shareBtnContainer}
            /> */}
            <div className={styles.infoHeaderSeperator} />
            {showBrokeageToggle && !startTour
              ? (
                <div className={styles.brokerage}>
                  <CustomText size="small" weight="medium" color="linkColor" className={styles.brokerageText}>Brokerage</CustomText>
                  <Switch
                    value={brokerage}
                    onValueChange={this.toggleBrokerage}
                  />
                </div>
              )
              : null}
          </>
        )}

        {!isDeployed && !startTour
         && Boolean(deploySymbols) && algoIdKey !== ALGO_KEY_MAP.SHARED_ALGOS && (
         <div className={styles.dployBtnWrapper}>
           {!isMobile && <div className={styles.infoHeaderSeperator} />}
           <Button
             text="Deploy"
             onPress={deploySymbols}
             params={{ seg_sym, isBacktestExist: canDeploy && !waiting }}
             buttonColor={pnlColor ? pnlColor.toLowerCase() : 'blue'}
             labelSize="small"
             disabled={waiting}
             btnStyles={styles.deployBtn}
             uppercase
           />
         </div>
        )}

        {(noData || waiting) ? null
          : (
            <button type="button" onClick={(e) => { showNudgeTooltip(e, seg_sym) }}>
              {algoIdKey === ALGO_KEY_MAP.MY_ALGOS
          && !isMobile && !isListView && renderNudge(item)}
            </button>
          )}
      </div>
    )
  }

   removeOverLay = (seg_sym) => {
     const { mtfObj } = this.state
     const mtfBtn = document.getElementById(`${seg_sym}_mtf`)
     const BTScreen = document.getElementById(seg_sym)
     const mtfPnl = document.getElementById(`${seg_sym}_mtf_pnl`)
     const newObj = { ...mtfObj }
     newObj[seg_sym] = true
     this.setState({ mtfObj: newObj })
     if(mtfBtn) {
       BTScreen.style.display = 'block'
       mtfPnl.style.display = 'none'
       mtfBtn.style.display = 'none'
     }
     showSnackbar('To access the backtest results for all individual instruments, click on "Show results" of the cumulative backtest result.')
   }

    renderMTFDesc = () => {
      const {
        styles,
        scrollType,
        item: {
          seg_sym,
          backtest_result = {},
        },
        isMobile,
        isBacktestExist,
        isDark,
      } = this.props
      const {
        prevPnl,
      } = this.state
      const btResults = backtest_result[seg_sym] || {}
      const noData = !isBacktestExist(btResults)
      const {
        final_pnl = 0,
        waiting = false,
      } = btResults

      const pnlText = Number(final_pnl)
      const { symbol } = segSymConverter(seg_sym)

      let pnlColor = pnlText > 0 ? 'GREEN' : 'RED'
      let type = pnlText > 0 ? 'gain' : 'loss'
      if (waiting || noData) {
        pnlColor = 'text'
        type = 'white'
      }
      let symbolName = compressedDYCName(symbol)
      const rawSymbolName = symbolNameByOptions(symbolName)
      // options sytles
      let showOptions
      if(symbolName !== rawSymbolName) {
        showOptions = rawSymbolName.charAt(rawSymbolName.length - 2)
        symbolName = rawSymbolName.substring(0, rawSymbolName.length - 4)
      }
      const optionsStyles = {
        backgroundColor: showOptions === 'W' ? COLORS.BLUE : COLORS.ORANGE,
      }
      return (
        <div className={styles.mtfDescContainer} id={`${seg_sym}_mtf`}>
          <div className={classnames(styles.header, 'backtest-details-anim')} style={{ animationName: `${scrollType}_${prevPnl}_${type}` }}>
            <StockIcon
              width={isMobile ? 22 : 26}
              height={isMobile ? 22 : 26}
              key={seg_sym}
              seg_sym={seg_sym}
              containerStyle={{
                margin: `0 ${SPACING.SPACE_10} 0 ${isMobile ? 0 : SPACING.SPACE_10}`,
                filter: noData ? 'grayscale(1)' : 'unset',
              }}
            />
            <div className={styles.title}>
              <Marquee key={symbol} tooltipText={symbol}>
                <CustomText size={isMobile ? 'regular' : 'large'} weight="semi_bold" color={pnlColor}>
                  {symbolName}
                  {showOptions && showOptions !== ' ' && (
                  <img src={showOptions === 'W' ? ASSETS.Weekly : ASSETS.Monthly} style={optionsStyles} className="optionsText" alt="w" />
                  )}
                </CustomText>
              </Marquee>
            </div>
            <div className={styles.infoHeaderSeperator} />
            <div className={styles.backtestInfo}>
              <div className={styles.infoHeaderLeft}>
                <CustomText size={isMobile ? 'small_0' : 'regular'} className={styles.pnlText}>
                  Backtest P&L
                  <span className={styles.valueBlur}>labelvalue</span>
                </CustomText>
              </div>
              <Button
                mode="text"
                labelColor={isDark ? 'blue_300' : 'blue'}
                transparent
                onPress={() => this.removeOverLay(seg_sym)}
                text="Show result"
                btnStyles={styles.showResultBtn}
                respText
              />
            </div>
          </div>
          <div className={styles.mtfDesc}>
            <EmptyContainer
              imgSrc="NO_BACKTESTS"
              contentStyles={styles.mtfContent}
              imgStyles={styles.btEmptyImg}
            />
            <CustomText size="small_1" weight="medium" className={styles.mtfTitle} color="orange">
              Backtest results for MTF are unverifiable.
              <a href={LINK_MAP.MTF.link} rel="noreferrer" target="_blank"> Learn More.</a>
            </CustomText>
          </div>
        </div>
      )
    }

    render() {
      const {
        prevPnl, shareModal,
      } = this.state
      const {
        user_details, styles, isDark, deployed_seg_sym = [], scrollType,
        isFetchingShareableLink, isGeneratingShareLink, backtestDetails,
        item: {
          seg_sym,
          backtest_result = {},
        },
        deploySymbols,
        brokerage,
        brokerageData,
        isMobile,
        algoIdKey,
        isBacktestExist,
        startTour,
        reRunBacktestHandler,
        mtfExist,
        isListView,
        renderNudge,
        showNudgeTooltip,
        item: nudgeItem,
      } = this.props
      if (!seg_sym) {
        return (
          <CustomText center style={{ margin: SPACING.SPACE_20 }}>
            Something Went Wrong
          </CustomText>
        )
      }
      const btResults = backtest_result[seg_sym] || {}
      const noData = !isBacktestExist(btResults)
      const {
        final_pnl = 0,
        return: final_return = 0.0,
        waiting = false,
        error,
        error_msg,
      } = btResults

      const errMsg = error_msg || error
      const isDeployed = deployed_seg_sym.findIndex(i => i === seg_sym) !== -1

      let totalReturn = Number(final_return)
      let pnlText = Number(final_pnl)
      const { symbol } = segSymConverter(seg_sym)
      const { trade_log = [] } = backtestDetails[seg_sym] || {}
      let brokerageLogs = trade_log && trade_log !== null ? trade_log : []
      if (!isEmpty(brokerageData) && brokerageData[seg_sym] && brokerage) {
        const data = brokerageData[seg_sym]
        pnlText = Number(data.pnl)
        totalReturn = Number(data.period_return)
        brokerageLogs = data.brokerageLogs || []
      }
      const disp_ret = +totalReturn >= 0 ? `(+${(+totalReturn).toFixed(2)} %)` : `(${(+totalReturn).toFixed(2)} %)`

      const showBrokeageToggle = userAccessCheck({
        condition: accessConditions.SHOW_BROKERAGE_TOGGLE, user_details,
      }) && !noData
      let pnlColor = pnlText > 0 ? 'GREEN' : 'RED'
      let type = pnlText > 0 ? 'gain' : 'loss'
      if (waiting || noData) {
        pnlColor = 'text'
        type = 'white'
      }
      let symbolName = compressedDYCName(symbol)
      const rawSymbolName = symbolNameByOptions(symbolName)
      let showOptions
      if (symbolName !== rawSymbolName) {
        showOptions = rawSymbolName.charAt(rawSymbolName.length - 2)
        symbolName = rawSymbolName.substring(0, rawSymbolName.length - 4)
      }

      const finalOptionText = {
        backgroundColor: showOptions === 'W' ? COLORS.BLUE : COLORS.ORANGE,
        transform: 'translateY(0px)',
      }

      const ptFocusId = startTour ? 'tour_deploy_2_1' : seg_sym

      return (
        <>
          <div id="mtfGridView">
            {mtfExist && this.renderMTFDesc()}
          </div>
          <div id="mtfGridResult">
            <div className={styles.screen} id={ptFocusId}>
              <div className={classnames(styles.header, 'backtest-details-anim')} style={{ animationName: `${scrollType}_${prevPnl}_${type}` }}>
                <StockIcon
                  width={isMobile ? 22 : 26}
                  height={isMobile ? 22 : 26}
                  key={seg_sym}
                  seg_sym={seg_sym}
                  containerStyle={{
                    margin: `0 ${SPACING.SPACE_10} 0 ${isMobile ? 0 : SPACING.SPACE_10}`,
                    filter: noData ? 'grayscale(1)' : 'unset',
                  }}
                />
                <div className={styles.title}>
                  <Marquee key={symbol} tooltipText={symbol}>
                    <CustomText size={isMobile ? 'regular' : 'large'} weight="semi_bold" color={pnlColor}>
                      {symbolName}
                      {showOptions && showOptions !== ' ' && (
                      <img src={showOptions === 'W' ? ASSETS.Weekly : ASSETS.Monthly} style={finalOptionText} className="optionsText" alt="w" />
                      )}
                    </CustomText>
                  </Marquee>
                </div>
                <div className={styles.infoHeaderSeperator} />
                <div className={styles.backtestInfo}>
                  <div className={styles.infoHeaderLeft}>
                    <CustomText size={isMobile ? 'small_0' : 'regular'} className={styles.pnlText}>
                      Backtest P&L
                      {noData || waiting ? (
                        <span className={styles.pnlValueNa}>N/A</span>
                      ) : (
                        <span
                          className={classnames(styles.pnlValue)}
                          style={{ color: COLORS[pnlColor] }}
                        >
                          {`  ${Number(pnlText.toFixed(2)).toLocaleString('en-IN')} `}
                          {!isMobile && (
                          <span
                            style={{ marginLeft: SPACING.SPACE_4, color: COLORS[pnlColor] }}
                          >
                            {disp_ret}
                          </span>
                          )}
                        </span>
                      )}
                      <button type="button" onClick={(e) => { showNudgeTooltip(e, seg_sym) }}>
                        {noData || waiting ? null : algoIdKey === ALGO_KEY_MAP.MY_ALGOS
                        && isMobile && !isListView && renderNudge(nudgeItem)}
                      </button>
                    </CustomText>
                  </div>
                  {error_msg && (
                  <div style={{ marginTop: isMobile ? 0 : SPACING.SPACE_10 }}>
                    <Button
                      btnStyles={styles.reRunBacktest}
                      onPress={() => reRunBacktestHandler(seg_sym)}
                    >
                      <Icon
                        name={ICONS.REFRESH}
                        style={{ marginRight: isMobile ? 0 : SPACING.SPACE_12 }}
                        color={COLORS.BLUE}
                        size={isMobile ? 16 : 14}
                      />
                      {!isMobile && (
                      <CustomText size="small_0" color={theme.reRunBacktestText} weight="medium">
                        Re run backtest
                      </CustomText>
                      )}
                    </Button>
                  </div>
                  )}
                  {!isMobile && this.renderInfoHeaderRight({
                    isFetchingShareableLink,
                    isGeneratingShareLink,
                    deploySymbols,
                    isDeployed,
                    showBrokeageToggle,
                    brokerage,
                    seg_sym,
                    noData,
                    canDeploy: isBacktestExist(btResults),
                    waiting,
                    pnlColor,
                    isMobile,
                    algoIdKey,
                    error_msg,
                  })}
                </div>
              </div>
              {!(noData || waiting) && this.renderBacktestContent({
                brokerageLogs,
                isMobile,
                isFetchingShareableLink,
                isGeneratingShareLink,
                deploySymbols,
                isDeployed,
                showBrokeageToggle,
                brokerage,
                seg_sym,
                noData,
                waiting,
                pnlColor,
              })}
              {noData && !waiting && this.renderBacktestEmpty(errMsg)}
              {waiting && (
              <>
                <PlaceHolderLoader className={styles.graph} />
                <div className={styles.backtestDetails}>
                  {[1, 2, 4, 5, 6, 7, 78, 8, 60].map((item, index) => (
                    <div key={`cellLoader${index}`} className={styles.cell} style={{ width: isMobile ? '50%' : 140 }}>
                      <PlaceHolderLoader className={styles.cellValue} />
                      <PlaceHolderLoader className={styles.cellLabel} />
                    </div>
                  ))}
                </div>
              </>
              )}
              <ShareModal
                {...this.shareModalParams}
                visible={shareModal}
                onDismiss={this.toggleModal}
                dismissKey="shareModal"
                isDark={isDark}
              />
            </div>
          </div>

        </>
      )
    }
}

const stylesheet = ({
  screen: {
    backgroundColor: theme.bgPrimary,
    position: 'relative',
  },
  headerWrapper: {
    borderRadius: '6px',
    // background: '#00000012',
    height: '172px',
    position: 'absolute',
    width: '100%',
  },
  header: {
    position: 'relative',
    zIndex: '99',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_12} ${SPACING.SPACE_20}`,
  },
  stockIconContainer: {},
  title: {
    marginRight: SPACING.SPACE_20,
    maxWidth: '22%',
  },
  backtestInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '1',
  },
  infoHeaderSeperator: {
    height: SPACING.SPACE_20,
    width: 1,
    backgroundColor: theme.borderColor,
  },
  infoHeaderLeft: {
    paddingLeft: SPACING.SPACE_20,
  },
  pnlText: {
    display: 'flex',
    alignItems: 'center',
  },
  pnlValue: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: SPACING.SPACE_10,
    fontWeight: FONTWEIGHT.MEDIUM,
  },
  pnlValueNa: {
    fontWeight: FONTWEIGHT.MEDIUM,
    marginLeft: SPACING.SPACE_10,
    fontSize: FONTS.LARGE,
  },
  infoHeaderRight: {
    display: 'flex',
    alignItems: 'center',
  },
  shareBtnContainer: {
    marginRight: SPACING.SPACE_10,
  },
  shareBtn: {
    display: 'flex',
    alignItems: 'baseline',
  },
  brokerage: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: SPACING.SPACE_10,
  },
  brokerageText: {},
  content: {
    backgroundColor: theme.bgPrimary,
    zIndex: '98',
    position: 'relative',
    borderRadius: '6px',
    padding: `0 ${SPACING.SPACE_12}`,
  },
  chartContainer: {
    height: 200,
  },
  backtestDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: SPACING.SPACE_10,
    marginBottom: SPACING.SPACE_60,
    padding: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_4}`,
    borderTop: `1px solid ${theme.borderColor}`,
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  row: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 0.5,
  },
  cell: {
    padding: SPACING.SPACE_10,
  },
  periodDetails: {
    display: 'flex',
    background: theme.bgTertiary, // screenBg,
    marginTop: SPACING.SPACE_10,
    alignSelf: 'flex-start',
    borderRadius: '10px',
    padding: `0 ${SPACING.SPACE_10}`,
    justifyContent: 'space-around',
  },
  headerLabel: {
    whiteSpace: 'nowrap',
    marginTop: SPACING.SPACE_4,
  },
  gain: {},
  loss: {},
  btEmptyContainer: {
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btEmptyImg: {
    height: 100,
    width: 100,
  },
  btEmptyTitle: {
    marginTop: SPACING.SPACE_20,
    marginBottom: SPACING.SPACE_8,
  },
  deployBtn: {
    marginLeft: SPACING.SPACE_10,
  },
  dployBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: SPACING.SPACE_10,
  },
  graph: {
    height: 210,
  },

  cellValue: {
    height: 12,
    display: 'flex',
    width: 60,
  },
  cellLabel: {
    height: 12,
    display: 'flex',
    width: 100,
    marginTop: SPACING.SPACE_4,
  },
  reRunBacktest: {
    background: theme.reRunBacktestBg,
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
  subTitleStyles: {
    background: theme.reRunBacktestBg,
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    borderRadius: SPACING.SPACE_6,
    color: `${theme.reRunBacktestText} !important`,
    fontSize: `${FONTS.SMALL} !important`,
  },
  symbol: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  showResultBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    marginLeft: SPACING.SPACE_20,
    background: theme.mtfTitleBg,
  },
  showResultBtnContainer: {
    position: 'absolute',
    right: 0,
    width: '180px',
    marginTop: SPACING.SPACE_8,
    textAlign: 'left',
    background: COLORS.WHITE,
  },
  mtfDescContainer: {
    marginBottom: SPACING.SPACE_32,
  },
  mtfTitle: {
    background: theme.mtfTitleBg,
    padding: SPACING.SPACE_8,
    borderRadius: 8,
  },
  mtfDesc: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mtfContent: {
    display: 'none',
  },
  valueBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #3CBC00',
    userSelect: 'none',
  },
  '@media only screen and (max-width: 786px)': {
    title: {
      maxWidth: '35%',
      marginRight: '4%',
    },
    dployBtnWrapper: {
      flex: '1',
      justifyContent: 'flex-end',
    },
    infoHeaderRight: {
      padding: `${SPACING.SPACE_8} 0 0`,
      margin: `${SPACING.SPACE_20} 0 0`,
      borderTop: `1px solid ${theme.borderColor}`,
    },
    periodDetails: {
      margin: `${SPACING.SPACE_10} 0`,
    },
    header: {
      padding: `${SPACING.SPACE_12}`,
    },
    infoHeaderLeft: {
      paddingLeft: '5%',
    },
    reRunBacktest: {
      borderRadius: '50% !important',
      padding: SPACING.SPACE_10,
    },
  },
})

const mapStateToProps = (state) => {
  return {
    ...state.auth.loginResp,
    user_details: state.auth.user_details,
    generateShareLinkError: state.backtests.generateShareLinkError,
    generateShareLinkErrorMsg: state.backtests.generateShareLinkErrorMsg,
    backtest_share_uuid: state.backtests.backtest_share_uuid,
    multiShare: state.backtests.multiShare,
    generateShareLinkSuccess: state.backtests.generateShareLinkSuccess,
    shareLinkId: state.backtests.shareLinkId,
    fetchShareLinkError: state.backtests.fetchShareLinkError,
    clonedSharedSuccess: state.backtests.clonedSharedSuccess,
    clonedSharedError: state.backtests.clonedSharedError,
    clonedSharedErrorMsg: state.backtests.clonedSharedErrorMsg,
    isGeneratingShareLink: state.backtests.isGeneratingShareLink,
    isFetchingShareableLink: state.backtests.isFetchingShareableLink,
    backtestDetails: state.backtests.backtestDetails,

    showSideBar: state.common.showSideBar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getShareLink: (params, headers) => dispatch(fetchShareLink(params, headers)),
    createShareLink: (params, headers) => dispatch(generateShareLink(params, headers)),
    getBacktestDetails: params => dispatch(fetchBacktestDetails(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(BacktestsDetails))
