/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import Chart from 'chart.js/auto'
import isEqual from 'lodash/isEqual'

import { COLORS } from '../../Theme'
import chart_options from './Chart-const'

const lineColor = positive => (positive ? COLORS.GREEN : COLORS.RED)
const height = 210

class VisualChart extends Component {
  constructor(props) {
    super(props)
    this.chartRef = React.createRef()
    this.chart = null
    this.rendered = false
    this.initiated = true
  }

  componentDidMount() {
    this.initiateChart()
  }

  shouldComponentUpdate(nextProps) {
    const { data: prevData } = this.props
    if (!isEqual(prevData, nextProps.data)) {
      return true
    }
    return false
  }

  componentDidUpdate(prevProps) {
    const { data, id, brokerage } = this.props
    if (!isEqual(data, prevProps.data)) {
      if (id !== prevProps.id || brokerage) {
        this.initiateChart()
      } else {
        this.chart.data.datasets[0].data = data

        let positive = true
        if (data[data.length - 1] && data[data.length - 1].y < 0) {
          positive = false
        }
        const graphColor = lineColor(positive)
        this.chart.options.elements.line.backgroundColor = graphColor
        this.chart.options.elements.line.borderColor = graphColor
        this.chart.update()
      }
    }
  }

  initiateChart = () => {
    const { data } = this.props
    if (this.initiateTimer) clearTimeout(this.initiateTimer)
    if (this.chart) this.chart.destroy()
    this.context = this.chartRef.current.getContext('2d')
    let positive = true
    if (data[data.length - 1] && data[data.length - 1].y < 0) {
      positive = false
    }
    const graphColor = lineColor(positive)
    this.chart = new Chart(
      this.context, chart_options({
        positive, lineColor: graphColor,
      }),
    )
    this.initiateTimer = setTimeout(() => {
      this.chart.data.datasets[0].data = data
      this.chart.update()
    }, 100)
    this.initiated = true
  }

  render() {
    const {
      id = '',
    } = this.props
    return (
      <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
        <canvas id={id} ref={this.chartRef} width="100%" height={height} />
      </div>
    )
  }
}

export default VisualChart
