/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable prefer-const */
import React, { Component } from 'react' // PureComponent
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import TourPopper from './TourPopper'
import Tourjson, {
  productTourSteps, mobileProductTourSteps, PRODUCT_TOUR_SECTIONS,
  PRODUCT_TOUR_GA_LABELS, closeProductTourPopup,
} from './ProductTour-utils'
import {
  COLORS,
  FONTS, FONTWEIGHT, SPACING, theme,
} from '../../Theme'
import {
  changePtComponentStatus,
  extraTourTip, togglePtSideBar, toggleTour,
  toggleTourBackdrop, updateCompletedTours, updateTourIndex,
} from '../../containers/Common/actions'
import { withTheme } from '../../Theme/ThemeProvider'
import CustomText from '../../UI/CustomText'
import CustomModal from '../../UI/CustomModal'
import { updateOnboardingActivity } from '../../containers/Auth/actions'
import { ONBOARDING_ACTIVITY_TYPES } from '../../utils/consts'
import assets from '../../homepage/assets'
import Button from '../../UI/Button'
import CircleLoader from '../../UI/CircleLoader'

const headerOffset = 54

const initialState = {
  activeTour: {},
  // activeIndex: -1,
  tourKeys: [],
  tourSteps: 0,
  popperLayoutDim: {
    opacity: 0,
  },
  placement: 'top',
  activeTourKey: '',
  prevDup: '',
  showSkipTourModal: false,
  showDeployVideoModal: false,
  isVideoLoading: true,
}
let prevEle = null

const mobileTourArrowIndex = [0, 2, 8]

const mobileTourTypes = ['TECHNICALS', 'TECHNICALS_CREATE', 'SCANNERS', 'DEPLOY']

class ProductTour extends Component {
  state = {
    ...initialState,
  }

  tourtimer = null

  showTimerCount = 0

  popperRef = React.createRef()

  componentDidMount() {
    this.getActiveTour()
  }

  componentDidUpdate(prevProps) {
    const {
      location, startTour, ptComponentStatus, activeTourIndex,
      showSkipTourPopup, tourType,
    } = this.props
    const { activeTour, prevDup, showDeployVideoModal } = this.state
    if (!isEqual(prevProps.location, location) && !isEmpty(activeTour)) {
      this.setState({ ...initialState })
    }
    if ((startTour !== prevProps.startTour)
      || (ptComponentStatus !== prevProps.ptComponentStatus)
      || (activeTourIndex !== prevProps.activeTourIndex)) {
      this.getActiveTour()
      // check if the tour moves to next page remove old duplicated
      if(activeTourIndex !== prevProps.activeTourIndex) {
        const prevTourDub = document.getElementById(prevDup)
        if (prevTourDub) prevTourDub.style.display = 'none'
      }
    }
    if(prevProps.showSkipTourPopup === false && showSkipTourPopup) {
      this.backDropHandler()
    }
    if(startTour !== prevProps.startTour && tourType === PRODUCT_TOUR_SECTIONS.DEPLOY) {
      this.getActiveTour()
    }
    if(showDeployVideoModal) {
      setTimeout(() => {
        this.setState({ isVideoLoading: false })
      }, 2000)
    }
  }

  calcDim = () => {
    const { activeTour, tourKeys = [] } = this.state
    const { activeTourIndex, tourType, isMobile } = this.props
    const { content = {} } = activeTour
    const key = tourKeys[activeTourIndex]
    const clickerDot = document.getElementById('tour-dot')

    if(clickerDot) { clickerDot.style.opacity = 0 }
    if (content[key]) {
      let {
        placement = 'top', focusMap, showClicker, fixedPos, subPlacement,
        dotPlacement, dupLoading, skipScroll, topPlacement = 0, mobile,
      } = content[key]
      // if tour is in mobile view replace data
      if(isMobile) {
        const {
          placement: mobilePlacement,
          subPlacement: mobileSubPlacement,
        } = mobile || {}
        placement = mobilePlacement || placement
        subPlacement = mobileSubPlacement || subPlacement
      }
      const ele = document.getElementById(key)
      const popper = this.popperRef.current
      if (prevEle) {
        prevEle.forEach(node => node.removeAttribute('data-value'))
        prevEle = null
      }

      if (ele && this.main && popper) {
        prevEle = [ele]
        // exceptions
        if (focusMap) {
          focusMap.forEach((nodeId) => {
            const node = document.getElementById(nodeId)
            if (node) {
              prevEle.push(node)
              node.setAttribute('data-value', 'tour')
            }
          })
        }
        // prevEleStyle = { ...ele.style }
        ele.setAttribute('data-value', 'tour-highlighted')
        // ele.style.zIndex = 999999
        // ele.style.position = 'relative'
        // ele.style.backgroundColor = theme.screenBg
        // ele.style.borderRadius = '5px'
        const {
          top, left, width, height, right,
        } = ele.getBoundingClientRect()
        let newPos = placement
        let newTop = top
        let scrollProps = null
        // const activeTourIndexes = [3, 4]
        const popOut = (height + 20) >= this.main.offsetHeight
        const tourArrow = document.querySelector('.tour-arrow')
        // if height of element is greatter than screen height
        if (popOut) {
          if (left >= (popper.offsetWidth + 30)) {
            newPos = 'left'
          } else if ((this.main.offsetWidth - right) >= (popper.offsetWidth)) {
            newPos = 'right'
          } else newPos = 'top'
        }
        // scroling main to selected element
        if (top <= headerOffset) {
          const dif = newPos === 'top' ? Math.abs(top) + popper.offsetHeight + 10 : Math.abs(top)
          scrollProps = { behavior: 'smooth', top: this.main.scrollTop - dif - headerOffset }
          newTop = newPos === 'top' ? popper.offsetHeight + 10 + headerOffset : headerOffset
        } else if (this.main.offsetHeight <= (top + height)) {
          let dif = height + 20
          if (dif >= this.main.offsetHeight) {
            dif = (newPos === 'top' || newPos === 'bottom') ? this.main.offsetHeight - popper.offsetHeight - 10
              : (this.main.offsetHeight - 40)
          }
          scrollProps = { behavior: 'smooth', top: this.main.scrollTop + (dif + top - this.main.offsetHeight) }
          newTop = this.main.offsetHeight - dif
        }

        if (!fixedPos && newPos === 'top' && newTop <= (popper.offsetHeight + 30)) {
          if ((this.main.offsetWidth - right) >= popper.offsetWidth + 20) newPos = 'right'
          else if (left >= popper.offsetWidth + 20) newPos = 'left'
          else newPos = 'bottom'
        }
        if (!skipScroll && scrollProps) {
          this.main.scrollTo(scrollProps)
        }
        // setting popper position
        const popperLayoutDim = {}
        const popperSubPos = (subPlacement || 1 / 2)
        if (newPos === 'top') {
          popperLayoutDim.top = newTop - (popper.offsetHeight) - 10 + topPlacement
          if(isMobile) {
            popperLayoutDim.left = popper.offsetLeft + 10
            if(tourArrow) {
              if((tourType === PRODUCT_TOUR_SECTIONS.BACKTEST && activeTourIndex === 0)) {
                tourArrow.setAttribute('style', 'left: 25%')
              }else { tourArrow.setAttribute('style', 'left: 45%') }
            }
          } else { popperLayoutDim.left = left + (width * popperSubPos) - (popper.offsetWidth / 2) }
        } else if (newPos === 'left') {
          popperLayoutDim.top = popOut
            ? newTop + (this.main.offsetHeight / 2) - (popper.offsetHeight / 2)
            : newTop + (height * popperSubPos) - (popper.offsetHeight / 2)
          if(isMobile) {
            popperLayoutDim.left = 5
          }else { popperLayoutDim.left = left - popper.offsetWidth - 10 }
          popperLayoutDim.marginRight = 10
        } else if (newPos === 'right') {
          popperLayoutDim.top = popOut
            ? newTop + (this.main.offsetHeight / 2) - (popper.offsetHeight / 2)
            : newTop + (height * popperSubPos) - (popper.offsetHeight / 2)
          popperLayoutDim.left = left + width + 10
          popperLayoutDim.marginLeft = 10
        } else {
          popperLayoutDim.top = newTop + height + 10

          if(isMobile) {
            popperLayoutDim.left = popper.offsetLeft + 10
            if((tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE
              && activeTourIndex === 2)
            || (tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY
              && activeTourIndex === 3)) {
              popperLayoutDim.top = '42%'
              popperLayoutDim.left = 30
            }
            if((tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE && activeTourIndex === 3)
            || (tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY && activeTourIndex === 4)) {
              popperLayoutDim.left = popper.offsetLeft + 10
              popperLayoutDim.top = newTop + height - 40
            }
            if(tourArrow) {
              if (mobileTourArrowIndex.includes(activeTourIndex)) {
                tourArrow.setAttribute('style', 'left: 15%')
              }
              if((tourType === PRODUCT_TOUR_SECTIONS.SCANNERS_CREATE && activeTourIndex === 4)) {
                tourArrow.setAttribute('style', 'left: 15%')
              }
              if((tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE && activeTourIndex === 3)
                || (tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY && activeTourIndex === 4)) {
                tourArrow.setAttribute('style', 'left: 85%')
              }
              if(tourType === PRODUCT_TOUR_SECTIONS.SCANNERS && activeTourIndex === 5) {
                tourArrow.setAttribute('style', 'left:40%')
              }
              if((mobileTourTypes.includes(tourType) && activeTourIndex === 0)
              || (tourType === PRODUCT_TOUR_SECTIONS.DEPLOY && activeTourIndex === 2)) {
                tourArrow.setAttribute('style', 'left: 70%')
              }
            } else { tourArrow.setAttribute('style', 'left: 80%') }
          } else { popperLayoutDim.left = left + (width * popperSubPos) - (popper.offsetWidth / 2) }
        }
        this.setState({
          popperLayoutDim: { ...popperLayoutDim, opacity: 1 }, placement: newPos,
        })

        // clicker (dot)
        const clicker = document.getElementById('tour-dot')
        if (showClicker) {
          const clickerEle = document.getElementById(`${key}_clicker`) || ele
          if (clicker && clickerEle) {
            const clickerDim = {}
            const {
              top: clickerEleTop,
              left: clickerEleLeft,
              width: clickerEleWidth,
              height: clickerEleHeight,
            } = clickerEle.getBoundingClientRect()
            const { topPos = 1 / 2, leftPos = 1 / 2 } = dotPlacement || {}
            clickerDim.top = clickerEleTop + (clickerEleHeight * topPos) - 10
            clickerDim.left = clickerEleLeft + (clickerEleWidth * leftPos)
            clicker.style.top = `${clickerDim.top}px`
            clicker.style.left = `${clickerDim.left}px`
            clicker.style.opacity = 1
            if(isMobile && (tourType === PRODUCT_TOUR_SECTIONS.SCANNERS
              || tourType === PRODUCT_TOUR_SECTIONS.DEPLOY) && activeTourIndex === 0) {
              clicker.style.left = `${clickerDim.left - 80}px`
            }
            if(isMobile && tourType === PRODUCT_TOUR_SECTIONS.SCANNERS && activeTourIndex === 3) {
              clicker.style.top = `${clickerDim.top - 90}px`
            }
            if((isMobile && tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE
              && activeTourIndex === 3)
            || (isMobile && tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY
              && activeTourIndex === 4)) {
              clicker.style.top = `${clickerDim.top - 20}px`
            }
          }
        } else {
          clicker.style.opacity = 0
        }
        // for duplicate elements
        const currentTourDub = document.getElementById(`${key}_dup`)
        if (currentTourDub) {
          setTimeout(() => { currentTourDub.style.opacity = '1' }, dupLoading || 1000)
          currentTourDub.style.width = `${width}px`
          currentTourDub.style.height = `${height}px`
          currentTourDub.style.top = `${top}px`
          currentTourDub.style.left = `${left}px`
          currentTourDub.style.display = 'block'
          currentTourDub.style.pointerEvents = 'all'
          this.setState({ prevDup: `${key}_dup` })
        }
      } else if (!ele && tourType === PRODUCT_TOUR_SECTIONS.FINISHED) {
        const popperLayoutDim = {}
        // const popperSubPos = (subPlacement || 1 / 2)
        popperLayoutDim.top = '50%'
        popperLayoutDim.left = '50%'
        popperLayoutDim.transform = 'translate(-50%, -50%)'
        this.setState({
          popperLayoutDim: { ...popperLayoutDim, opacity: 1 }, placement: 'center',
        })
      }
    }
  }

  getActiveTour = () => {
    const {
      startTour, broker, tourType = '', activeTourIndex,
      isMobile,
    } = this.props
    if (!startTour) return
    this.main = document.getElementById('main')
    this.main.style.overflow = 'hidden'
    const activeTour = Tourjson(broker)[tourType]
    const modTourKey = []
    const { tourKeys = [], mobileTourKeys = [] } = activeTour || {}
    const finalTourKeys = isMobile && (tourType === PRODUCT_TOUR_SECTIONS.SCANNERS
    || tourType === PRODUCT_TOUR_SECTIONS.BACKTEST)
      ? mobileTourKeys : tourKeys
    if (activeTour) {
      if (activeTourIndex >= finalTourKeys.length) {
        this.backDropHandler(true)
        return
      }
      finalTourKeys.forEach((nodeId) => {
        // const ele = document.getElementById(nodeId)
        // if (ele && ele.style.display !== 'none' && ele.style.visibility !== 'hidden') {
        //   modTourKey.push(nodeId)
        // }
        modTourKey.push(nodeId)
      })
      if (modTourKey.length === 0) {
        if (this.showTimerCount < 3) {
          if (this.tourtimer) clearTimeout(this.tourtimer)
          this.showTimerCount += 1
          this.tourtimer = setTimeout(() => {
            this.getActiveTour()
          }, 1500)
        } else {
          this.setState({ ...initialState })
          this.showTimerCount = 0
          this.backDropHandler()
        }
      } else {
        if (this.tourtimer) clearTimeout(this.tourtimer)
        this.showTimerCount = 0
        const tourSteps = isMobile ? mobileProductTourSteps[tourType] : productTourSteps[tourType]
        this.setState({ activeTour, tourKeys: modTourKey, tourSteps }, this.calcDim)
      }
    }
  }

  toggleModal = (modal) => {
    const { toggleSkipTour } = this.props
    const { showDeployVideoModal } = this.state
    if(showDeployVideoModal) {
      toggleSkipTour(true)
    }
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  continueTour = () => {
    const { toggleSkipTour } = this.props
    const { showDeployVideoModal } = this.state
    this.setState({ showSkipTourModal: false })
    if(showDeployVideoModal) {
      toggleSkipTour(false)
      return
    }
    toggleSkipTour(false)
    this.calcDim()
  }

  clearTour = () => {
    const{ showTour, toggleSkipTour } = this.props
    this.setState({ showSkipTourModal: false })
    this.toggleModal('showDeploy')
    showTour(false)
    toggleSkipTour(true)
  }

  renderSkipTourPopup = () => {
    const { styles, isMobile } = this.props
    return (
      <div className={styles.skipTourPopup}>
        {isMobile
          ? <CustomText size="large" weight="semi_bold" color={COLORS.WHITE} style={{ margin: SPACING.SPACE_14 }}>ATTENTION!</CustomText>
          : null}
        <CustomText size={isMobile ? 'regular_2' : 'medium'} color={COLORS.WHITE}>Are you sure you want to quit the tour?</CustomText>
        <div className={styles.skipPopupFooter} style={{ margin: `${SPACING.SPACE_60} 0 ${SPACING.SPACE_20}` }}>
          <button
            type="button"
            className={isMobile ? styles.prevBtnMobile : styles.prevBtn}
            onClick={() => this.clearTour()}
          >
            Yes, clear progress
          </button>
          <button
            type="button"
            className={styles.startTourBtn}
            style={isMobile ? { marginInline: SPACING.SPACE_22, padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}` } : { padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}` }}
            onClick={() => this.continueTour()}
          >
            No, lets continue
          </button>
        </div>
      </div>
    )
  }

  renderDeployVideoPopup = () => {
    const { styles, isMobile } = this.props
    const { isVideoLoading } = this.state
    return (
      <div
        className={styles.playVideoContainer}
      >
        {isVideoLoading
          ? (
            <div className={styles.loaderContainer}>
              <CircleLoader style={{ width: 50, height: 50 }} />
            </div>
          )
          : (
            <>
              <video
                src={assets.PTDeploy}
                autoPlay
                loop
                preload="auto"
                type="video/mp4"
                style={isMobile ? {
                  width: '100%',
                } : {
                  borderRadius: 12,
                  width: '100%',
                  height: '370px',
                }}
              />
            </>
          )}
        <div className={styles.deployBtnContainer}>
          <div />
          <Button
            transparent
            btnStyles={styles.nextBtn}
            onPress={() => this.onDeployVideoPress()}
          >
            <CustomText color={theme.productTourBg} weight="semi_bold">
              Done
            </CustomText>
          </Button>
        </div>
      </div>
    )
  }

  backDropHandler = () => {
    const { tourKeys = [], prevDup } = this.state
    const {
      activeTourIndex, tourType, showTour,
      toggleProductTourSideBar, updateGA,
    } = this.props
    const key = tourKeys[activeTourIndex]
    let gaLabel = tourType !== undefined ? PRODUCT_TOUR_GA_LABELS[tourType][activeTourIndex] : 'clear progress'
    if (this.main) this.main.style.overflow = 'auto'
    if (prevEle) {
      prevEle.forEach((node) => {
        return node.removeAttribute('data-value')
      })
    }
    if(tourType !== PRODUCT_TOUR_SECTIONS.FINISHED) {
      updateGA('Click out', gaLabel, 1, 'Product Tour')
      this.setState({ showSkipTourModal: true })
    } else {
      showTour(false)
      toggleProductTourSideBar(true)
    }
    const clicker = document.getElementById('tour-dot')
    if (clicker) clicker.style.opacity = 0
    // remove any current duplicate item if any
    const currentTourDub = document.getElementById(`${key}_dup`)
    if (currentTourDub) currentTourDub.style.display = 'none'
    // remove any previous duplicate item if any
    const prevTourDub = document.getElementById(prevDup)
    if (prevTourDub) prevTourDub.style.display = 'none'
  }

  onTourFinish = () => {
    const {
      setTourIndex, showTour, changePtComponentStatus: changePtStatus,
      tourType, toggleProductTourSideBar,
      completedTours = [], setCompletedTour, updateOnboardingStatus,
      csrf,
    } = this.props
    if (tourType === PRODUCT_TOUR_SECTIONS.FINISHED) {
      this.backDropHandler()
    } else {
      showTour(true, PRODUCT_TOUR_SECTIONS.FINISHED)
      setTourIndex(0)
      changePtStatus('finished')
      toggleProductTourSideBar(false)
      if (!completedTours.includes(tourType)) {
        completedTours.push(tourType)
        setCompletedTour(completedTours)
        updateOnboardingStatus({
          csrfmiddlewaretoken: csrf,
          activity: ONBOARDING_ACTIVITY_TYPES.TOUR,
          [ONBOARDING_ACTIVITY_TYPES.TOUR]: JSON.stringify({
            completedTours,
          }),
        })
      }
    }
  }

  goBack = () => {
    const {
      setTourIndex, activeTourIndex, history, ptExtraTourtip,
      getExtraTourtip, updateGA, tourType,
    } = this.props
    const { activeTour, tourKeys = [] } = this.state
    const { content = {} } = activeTour
    const key = tourKeys[activeTourIndex]
    const gaLabel = PRODUCT_TOUR_GA_LABELS[tourType][activeTourIndex]
    updateGA('Previous', gaLabel, 1, 'Product Tour')
    if(content[key] && content[key].canNavigateBack) history.goBack()
    setTourIndex(activeTourIndex - 1 < 0 ? 0 : activeTourIndex - 1)
    if(ptExtraTourtip) {
      getExtraTourtip(false)
    }
    this.setState({
      popperLayoutDim: { opacity: 0 },
    }, () => this.calcDim('back'))
  }

  goNext = () => {
    const { tourKeys = [] } = this.state
    const {
      setTourIndex, activeTourIndex, updateGA,
      tourType, completedTours = [], isMobile,
    } = this.props
    const len = tourKeys.length || 0
    const lastEle = len - 1 === activeTourIndex
    const gaAction = lastEle ? (tourType === PRODUCT_TOUR_SECTIONS.DEPLOY ? 'Video Button' : 'Done') : 'Next'
    let gaLabel = (tourType !== undefined || tourType !== PRODUCT_TOUR_SECTIONS.FINISHED) ? PRODUCT_TOUR_GA_LABELS[tourType][activeTourIndex] : 'Clear progress'
    const recentCompleteTour = completedTours[completedTours.length - 1]
    const gaLabelClose = closeProductTourPopup[recentCompleteTour]
    updateGA(gaAction, gaLabel, 1, 'Product Tour')
    if(!isMobile && activeTourIndex + 1 >= len && tourType === PRODUCT_TOUR_SECTIONS.DEPLOY) {
      this.setState({ showDeployVideoModal: true })
      this.setState({ popperLayoutDim: { opacity: 0 } })
      if (prevEle) {
        prevEle.forEach((node) => {
          return node.removeAttribute('data-value')
        })
      }
      return
    }
    if (activeTourIndex + 1 >= len) {
      this.onTourFinish()
      updateGA('Close', gaLabelClose, 1, 'Product Tour')
      return
    }
    setTourIndex(activeTourIndex + 1)
  }

  onDeployVideoPress = () => {
    const {
      completedTours = [], updateGA,
    } = this.props
    const recentCompleteTour = completedTours[completedTours.length - 1]
    const gaLabelClose = closeProductTourPopup[recentCompleteTour]
    this.setState({ showDeployVideoModal: false })
    this.onTourFinish()
    updateGA('Close', gaLabelClose, 1, 'Product Tour')
  }

  render() {
    const {
      styles, startTour, activeTourIndex, pauseTour,
      ptExtraTourtip, tourType, isMobile,
    } = this.props
    const {
      activeTour, popperLayoutDim, placement, tourKeys = [], tourSteps,
      showSkipTourModal, showDeployVideoModal,
    } = this.state
    if (!startTour || isEmpty(activeTour) || activeTourIndex < 0) return null
    const { content, extraTooltip = {} } = activeTour
    const key = tourKeys[activeTourIndex] || []
    return (
      <>
        <div className={styles.container}>
          {ReactDOM.createPortal(
            <button type="button" onClick={this.backDropHandler} style={{ pointer: 'default' }} id="pt-backdrop">
              <div className={styles.backDrop} style={{ backgroundColor: theme.tourBackdrop }} />
            </button>,
            document.getElementById('portalWindow'),
          )}
          <TourPopper
            index={activeTourIndex}
            len={tourKeys.length || 0}
            {...content[key]}
            {...extraTooltip[key]}
            tourSteps={tourSteps}
            placement={placement}
            styles={styles}
            goNext={this.goNext}
            goBack={this.goBack}
            popperRef={this.popperRef}
            popperLayoutDim={popperLayoutDim}
            pauseTour={pauseTour}
            ptExtraTourtip={ptExtraTourtip}
            tourType={tourType}
            isMobile={isMobile}
          />
        </div>
        <CustomModal
          visible={showSkipTourModal}
          containerStyles={styles.skipTourModalContainer}
          mainContainerStyles={styles.skipTourMainContainer}
          onDismiss={this.toggleModal}
          dismissKey="skipTourModal"
          cursorType="default"
          hideScroll
          overrideMobilePosition
        >
          {this.renderSkipTourPopup()}
        </CustomModal>
        <CustomModal
          visible={showDeployVideoModal}
          containerStyles={styles.deployVideoModalContainer}
          mainContainerStyles={styles.deployVideoMainContainer}
          onDismiss={this.toggleModal}
          dismissKey="deployVideoModal"
          cursorType="default"
          hideScroll
          overrideMobilePosition
        >
          {this.renderDeployVideoPopup()}
        </CustomModal>
      </>
    )
  }
}

const stylesheet = {
  container: {
    position: 'relative',
  },
  backDrop: {
    backgroundColor: theme.backdropBg,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 9999,
    height: '100%',
  },
  deployVideoModalContainer: {
    backgroundColor: `${theme.productTourBg} !important`,
    minWidth: 800,
  },
  // popper styles
  tourPopper: {
    position: 'fixed',
    zIndex: 99999,
    minHeight: 100,
    borderRadius: 10,
    maxWidth: 340,
    width: '100%',
    minWidth: 100,
    backgroundColor: theme.productTourBg,
    padding: `${SPACING.SPACE_20} 0 0 0`,
  },
  finishedTourPopper: {
    width: '80%',
    maxWidth: 500,
    opacity: 0,
    margin: 'auto',
    borderRadius: 10,
    padding: `${SPACING.SPACE_24} ${SPACING.SPACE_20}`,
    boxShadow: '0px 14px 20px #162B7433',
    maxHeight: '80%',
    overflow: 'auto',
    position: 'fixed',
    zIndex: 99999,
    backgroundColor: theme.productTourBg,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    paddingInline: SPACING.SPACE_20,
    marginBottom: SPACING.SPACE_10,
  },
  headerCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_10,
    gap: SPACING.SPACE_20,
    paddingInline: SPACING.SPACE_20,
  },
  page: {
    fontSize: FONTS.REGULAR,
    fontWeight: FONTWEIGHT.SEMI_BOLD,
  },
  btnContainer: {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
    marginTop: SPACING.SPACE_10,
    justifyContent: 'space-between',

    '& Button': {
      marginInline: SPACING.SPACE_16,
    },
  },
  deployBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: SPACING.SPACE_14,
    marginBottom: SPACING.SPACE_14,
    justifyContent: 'space-between',

    '& Button': {
      marginInline: SPACING.SPACE_16,
    },
  },
  actionBar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: SPACING.SPACE_24,
    backgroundColor: theme.bgPrimary,
    borderRadius: '0 0 10px 10px',
    padding: `${SPACING.SPACE_10}`,
  },
  tourProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: theme.yellowLightBg,
  },
  nextBtn: {
    backgroundColor: theme.productTourSecondary,
    display: 'flex',
    alignItems: 'center',
  },
  backBtn: {
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    backgroundColor: theme.productTourBgLight,
  },
  skipBtn: {
    padding: `${SPACING.SPACE_2} ${SPACING.SPACE_8}`,
    '&:hover': {
      backgroundColor: theme.blueLightBg,
      '& > p': {
        color: `${theme.linkColor} !important`,
      },
    },
  },
  skipTourModalContainer: {
    backgroundColor: `${theme.productTourBg} !important`,
    minWidth: 100,
    minHeight: 180,
    cursor: 'default !important',
  },
  skipTourMainContainer: {
    zIndex: 999999,
  },
  skipTourPopup: {
    textAlign: 'center',
  },
  startTourBtn: {
    backgroundColor: theme.productTourSecondary,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_60}`,
    color: theme.productTourBg,
    borderRadius: '6px',
    fontWeight: 'bolder',
  },
  prevBtn: {
    marginRight: SPACING.SPACE_30,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_30}`,
    backgroundColor: theme.productTourBgLight,
    color: COLORS.WHITE,
    borderRadius: '6px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: COLORS.WHITE,
      color: theme.productTourBg,
    },
  },
  ptgif1: {
    width: '33%',
    position: 'absolute',
    top: '-23px',
  },
  ptgif2: {
    position: 'absolute',
    width: '80%',
    top: '26%',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: COLORS.WHITE,
    width: '100%',
    height: '370px',
  },
  '@media only screen and (max-width:1024px)': {
    deployVideoModalContainer: {
      minWidth: 0,
    },
    skipTourModalContainer: {
      minWidth: 100,
      borderRadius: 10,
    },
    skipPopupFooter: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    nextBtnMobile: {
      width: '100px',
    },
    prevBtnMobile: {
      color: theme.productTourSecondary,
      marginTop: SPACING.SPACE_20,
    },
    ptgif1: {
      top: '4% !important',
    },
    ptgif2: {
      top: '36%',
    },
  },
  '@media only screen and (max-width:768px)': {
    ptgif1: {
      top: '-8%',
    },
    ptgif2: {
      top: '30%',
    },
  },
  '@media only screen and (max-width:400px)': {
    tourPopper: {
      maxWidth: '250px !important',
    },
  },
  '@media only screen and (min-width:425px)': {
    tourPopper: {
      maxWidth: '330px !important',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    ...state.auth.loginResp,
    startTour: state.common.startTour,
    broker: state.auth.user_details.broker,
    tourType: state.common.tourType,
    activeTourIndex: state.common.activeTourIndex,
    ptComponentStatus: state.common.ptComponentStatus,
    pauseTour: state.common.pauseTour,
    ptExtraTourtip: state.common.ptExtraTourtip,
    completedTours: state.common.completedTours,
    showSkipTourPopup: state.common.showSkipTourPopup,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showTour: (params, tourType) => dispatch(toggleTour(params, tourType)),
    setTourIndex: params => dispatch(updateTourIndex(params)),
    toggleProductTourSideBar: status => dispatch(togglePtSideBar(status)),
    getExtraTourtip: params => dispatch(extraTourTip(params)),
    setCompletedTour: params => dispatch(updateCompletedTours(params)),
    changePtComponentStatus: params => dispatch(changePtComponentStatus(params)),
    toggleSkipTour: params => dispatch(toggleTourBackdrop(params)),
    updateOnboardingStatus: params => dispatch(updateOnboardingActivity(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(ProductTour))
