import { makeStyles } from '@material-ui/styles'
import React, {
  useRef, useCallback, // useEffect,
} from 'react'
// import { Spring, animated } from 'react-spring'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

import CustomText from './CustomText'
import Icon from './Icon'
import PlaceHolderLoader from './PlaceHolderLoader'

import {
  ICONS, SPACING, theme,
} from '../Theme'
import { useTheme } from '../Theme/ThemeProvider'
import CustomTooltip from './CustomTooltip'

const SectionTab = ({
  tabs,
  tabChangeHandler,
  // defaultActiveTab,
  style,
  tabStyles,
  activeTabStyles,
  loading,
  //   loadingText,
  activeTab,
  labelKey,
  valueKey,
  tabColor = theme.textSecondary,
  activeTabColor,
  showLabel = true,
  subComponentRenderer,
  showSubComponent,
  tabKey,
  disabledTab,
  disabledTabText = '',
  id,
}) => {
  const selectedBtnRef = useRef(null)
  const styles = stylesheet()
  const { isDark } = useTheme()
  // const [activeTab, toggleTab] = useState(defaultActiveTab)
  const debouncedHandler = useCallback(
    debounce((e, value, item, index) => tabChangeHandler(value, item, index, tabKey), 300), [],
  )
  const onTabChange = (e, value, item, index) => {
    e.stopPropagation()
    e.preventDefault()
    if (value === activeTab) return
    // toggleTab(value)
    debouncedHandler(e, value, item, index)
  }
  const activeTabStyle = classnames(styles.activeTabViewBtn, activeTabStyles)
  if (loading) {
    return (
      <div
        className={classnames(styles.tabViewContainer, style)}
        style={{ backgroundColor: isDark ? theme.bgPrimary : theme.tabsBg }}
      >
        <PlaceHolderLoader className={styles.loader} />
      </div>
    )
  }
  return (
    <CustomTooltip
      show={disabledTab && disabledTabText}
      tooltipProps={{ text: disabledTab && disabledTabText }}
    >
      <div
        className={classnames(styles.tabViewContainer, style)}
        style={{ backgroundColor: isDark ? theme.bgPrimary : theme.tabsBg }}
        id={id}
      >
        {tabs.map((item, index) => {
          const label = labelKey ? item[labelKey] : item.label
          const value = valueKey ? item[valueKey] : item.value
          const { iconName, iconProps = {}, shortLabel } = item
          let calcTabColor = isDark && disabledTab ? '#575757' : isDark ? theme.textSecondary : tabColor
          let calcActiveTabColor = disabledTab && isDark ? '#575757' : disabledTab ? 'lightgrey' : activeTabColor
          calcTabColor = item.textColor || calcTabColor
          calcActiveTabColor = item.activeTextColor || calcActiveTabColor
          const active = activeTab === value
          return (

          // <Spring
          //   from={{
          //     opacity: active ? 0 : 1,
          //     //   width: active ? '25%' : '75%',
          //     flex: active ? 0 : 1,
          //   }}
          //   to={{
          //     opacity: active ? 1 : 0,
          //     //   width: !active ? '25%' : '75%',
          //     flex: active ? 1 : 0,
          //   }}
          //   key={value.toString()}
          //   // config={config.stiff}
          //   config={{
          //     mass: 1, tension: 160, friction: 10,
          //     // mass: 5, tension: 2800, friction: 200,
          //   }}
          // >
          //   {props => (
            <button
              key={value.toString()}
              id={value.toString()}
              ref={active ? selectedBtnRef : null}
              type="button"
              onClick={(e) => { if (!active) onTabChange(e, value, item, index) }}
              className={classnames(styles.tabViewBtn, tabStyles, active ? activeTabStyle : '')}
              disabled={active || disabledTab}
              style={{ flex: active ? 1 : 0 }}
            >
              {showSubComponent ? subComponentRenderer({ item, activeTab, value }) : null}
              {Boolean(iconName) && (
              <Icon
                name={ICONS[iconName]}
                size={15}
                color={active ? calcActiveTabColor : calcTabColor}
                style={{ zIndex: 9, transform: 'all 0.2s' }}
                {...iconProps}
              />
              )}
              {Boolean(shortLabel) && !(active) && (
              <CustomText
                className={styles.btnShortText}
                center
                color="textSecondary"
                weight="medium"
                size="small"
              >
                {shortLabel}
              </CustomText>
              )}
              {showLabel && (
              <CustomText
                className={styles.btnText}
                center
                  // color="grey"
                size="small"
                weight="medium"
                animated
                style={{
                  opacity: active ? 1 : 0,
                  position: 'absolute',
                  marginLeft: iconName ? SPACING.SPACE_20 : 0,
                  color: active ? calcActiveTabColor : calcTabColor,
                }}
              >
                {label}
              </CustomText>
              )}
            </button>
          )
        })}
      </div>
    </CustomTooltip>
  )
}

const stylesheet = makeStyles({
  tabViewContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    // padding: `${SPACING.SPACE_4}`,
    // border: `1px solid ${theme.borderColor}`,
    maxWidth: 200,
    overflow: 'hidden',
  },
  loader: {
    height: 20,
    maxWidth: 240,
  },
  tabViewBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_14}`,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    // transition: 'width 0.2s',
    position: 'relative',
    minHeight: 25,
    transition: 'flex 0.3s var(--anim-func-ease)',
    '& > *': {
      transition: 'opacity 0.3s var(--anim-func-ease)',
    },
  },
  activeTabViewBtn: {
    backgroundColor: theme.activeTabsBg,
    // boxShadow: '0px 1px 7px #162B7433',
  },
  btnText: {
    whiteSpace: 'nowrap',
  },
  '@media only screen and (max-width: 786px)': {
    tabViewContainer: {
      maxWidth: 180,
    },
  },
})

export default SectionTab
