import {
  UPDATE_NOTIFICATIONS,
  RESET_NOTIFICATION_COUNT,
  TOGGLE_TERMS_PAGE,
  REFRESH_ALGO,
  REFRESH_SCANS,
  FETCH_PROFILE_CAROUSEL,
  FETCH_PROFILE_CAROUSEL_SUCCESS,
  FETCH_PROFILE_CAROUSEL_FAILURE,
  TOGGLE_ROOT_MODAL,
  POLL_USER,
  UPDATE_WEBSITE_CONFIG,
  TOGGLE_AUTH_GREETING,
  TOGGLER_TOUR,
  UPDATE_TOUR_INDEX,
  CHANGE_PT_COMPONENT_STATUS,
  PAUSE_TOUR,
  TOGGLE_PT_SIDEBAR,
  EXTRA_TOURTIP,
  UPDATE_COMPLETED_TOURS,
  TOGGLE_TOUR_BACKDROP,
  GET_SIDEBAR_VALUE,
  GET_BLOCKED_MTF_TF,
  GET_LOGIN_VALUE,
} from './actionTypes'

export function updateNotifications(data) {
  return {
    type: UPDATE_NOTIFICATIONS,
    notification: data,
  }
}

export function clearNotifications() {
  return {
    type: RESET_NOTIFICATION_COUNT,
  }
}

export function toggleTerms(terms_uri, heading) {
  return {
    type: TOGGLE_TERMS_PAGE,
    terms_uri,
    heading,
  }
}

export function pollUser(params, headers) {
  return {
    type: POLL_USER,
    params,
    headers,
  }
}

export function refreshAlgos(field, value) {
  return {
    type: REFRESH_ALGO,
    field,
    value,
  }
}

export function refreshScans(shouldRefresh) {
  return {
    type: REFRESH_SCANS,
    shouldRefresh,
  }
}

export function profileCarousel() {
  return {
    type: FETCH_PROFILE_CAROUSEL,
  }
}

export function profileCarouselSuccess(data) {
  return {
    type: FETCH_PROFILE_CAROUSEL_SUCCESS,
    data,
  }
}
export function profileCarouselFailure(error) {
  return {
    type: FETCH_PROFILE_CAROUSEL_FAILURE,
    error,
  }
}

// export function toggleAppExit(showAppExit) {
//   return (dispatch) => {
//     return (dispatch({
//       type: TOGGLE_APP_EXIT,
//       showAppExit,
//     }))
//   }
// }

export function toggleRootModal(modalName, showModal) {
  return {
    type: TOGGLE_ROOT_MODAL,
    modalName,
    showModal,
  }
}

export function updateWebSiteConfig(field, config) {
  return {
    type: UPDATE_WEBSITE_CONFIG,
    field,
    config,
  }
}

export function toggleAuthGreeting(modalType, routeAfterActivation, showFeatures) {
  return {
    type: TOGGLE_AUTH_GREETING,
    modalType,
    routeAfterActivation,
    showFeatures,
  }
}

export function toggleTour(params, tourType) {
  return {
    type: TOGGLER_TOUR,
    params,
    tourType,
  }
}

export function extraTourTip(params) {
  return {
    type: EXTRA_TOURTIP,
    params,
  }
}

export function pauseTourFun(params) {
  return {
    type: PAUSE_TOUR,
    params,
  }
}

export function updateTourIndex(index) {
  return {
    type: UPDATE_TOUR_INDEX,
    index,
  }
}

export function changePtComponentStatus(status) {
  return {
    type: CHANGE_PT_COMPONENT_STATUS,
    status,
  }
}

export function togglePtSideBar(status) {
  return {
    type: TOGGLE_PT_SIDEBAR,
    status,
  }
}

export function updateCompletedTours(tours) {
  return {
    type: UPDATE_COMPLETED_TOURS,
    tours,
  }
}

export function toggleTourBackdrop(params) {
  return {
    type: TOGGLE_TOUR_BACKDROP,
    params,
  }
}

export function getSideBarValue(params) {
  return {
    type: GET_SIDEBAR_VALUE,
    params,
  }
}

export function setBlockedTimeFrame(params) {
  return {
    type: GET_BLOCKED_MTF_TF,
    params,
  }
}

export function isLoginOnOrder() {
  return {
    type: GET_LOGIN_VALUE,
  }
}
