/**
 *   @akshay
 *   how to use
 *   <CircleLoader size={20} />
 */

import React from 'react'
import Proptypes from 'prop-types'

import CircleGif from '../assets/gif/circle_loader.gif'

const CircleLoader = React.memo(({
  size,
  style,
}) => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'center',
      height: size,
      width: size,
      ...style,
    }}
    >
      <img src={CircleGif} height="100%" width="100%" alt="...loading" />
    </div>

  )
})

export default CircleLoader

CircleLoader.defaultProps = {
  size: 20,
  style: {},
}

CircleLoader.proptypes = {
  size: Proptypes.number,
  style: Proptypes.object,
}
