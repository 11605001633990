import makeStyles from '@material-ui/styles/makeStyles'
import React from 'react'

import { useHistory } from 'react-router-dom'
import Icon from '../../UI/Icon'
import CustomText from '../../UI/CustomText'
import Funds from '../Funds'
// import RequestDemo from '../RequestDemo'
import DashboardCarousel from '../Dashboard/DashboardCarousel'
import UsageMetrics from '../Accounts/UsageMetrics'

import {
  SPACING, DIMENSIONS, ICONS, theme,
} from '../../Theme'
import { pathname_mapping } from '../../utils/navigationConstant'
// import { helpPageUrl } from '../../utils/apiEndpoints'

const menuItems = [
  {
    title: 'Preferences', page: pathname_mapping.preferences, subTitle: 'Dark mode, sound, animation', iconName: ICONS.SETTING_OUTLINED, gaLabel: 'Preferences',
  },
  // {
  //   title: 'Tutorials', page: '', subTitle: 'Video, help', iconName: ICONS.TUTORIALS,
  // },
  {
    title: 'Help', page: pathname_mapping.help, subTitle: 'Demo, support', iconName: ICONS.HELP_CIRCLE, gaLabel: 'Help',
  },
]

const DashboardSideBar = ({ updateGA }) => {
  const styles = stylesheet()
  const history = useHistory()
  const onMenuItemPress = ({ page, link, gaLabel }) => {
    if (page) {
      if (link) {
        window.open(page, '_blank')
      } else {
        history.push(page)
      }
      updateGA(gaLabel, gaLabel)
    }
  }

  const renderMenuItems = (item) => {
    const { title, subTitle, iconName } = item
    return (
      <button
        onClick={() => onMenuItemPress(item)}
        type="button"
        key={title}
        style={{ width: '100%' }}
      >
        <div className={styles.row}>
          <div className={styles.iconContainer}>
            <Icon name={iconName} color={theme.linkColor} size={20} />
          </div>
          <div className={styles.info}>
            <CustomText weight="medium">{title}</CustomText>
            <CustomText className={styles.subText} color="textSecondary" size="small_0">{subTitle}</CustomText>
          </div>
          <div className={styles.arrow}>
            <Icon name={ICONS.FORWARD} color={theme.textSecondary} size={10} />
          </div>
        </div>
      </button>
    )
  }

  return (
    <div className={styles.container}>
      {/* <RequestDemo updateGA={updateGA} /> */}
      <Funds />
      <UsageMetrics containerStyles={styles.usageMetrics} />
      <div className={styles.menuItems}>
        {menuItems.map(item => renderMenuItems(item))}
      </div>
      <div className={styles.carousalSection}>
        <DashboardCarousel />
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    paddingTop: SPACING.SPACE_10,
  },
  usageMetrics: {
    margin: `0 ${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    padding: '0',
  },
  carousalSection: {
    height: 230,
    margin: `${SPACING.SPACE_20} ${SPACING.SPACE_24} ${SPACING.SPACE_10}`,
  },
  row: {
    display: 'flex',
    padding: `${SPACING.SPACE_10} ${DIMENSIONS.SPACE_HORIZONTAL}`,
    '&:hover': {
      background: theme.hover,
      '& $arrow > svg': {
        fill: theme.linkColor,
      },
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '38px',
    width: '38px',
    borderRadius: '20px',
    justifyContent: 'center',
  },
  info: {
    marginLeft: SPACING.SPACE_16,
    textAlign: 'left',
    textTransform: 'capitalize',
    flex: 1,
  },
  subText: {
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
  },
})

export default (DashboardSideBar)
