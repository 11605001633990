import makeStyles from '@material-ui/styles/makeStyles'
import React, {
  useEffect, useRef, useState, useCallback,
} from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import throttle from 'lodash/throttle'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import CreateButton from '../../UI/CreateButton'
import Button from '../../UI/Button'
import CustomText from '../../UI/CustomText'
import StreakCarousal from '../../UI/StreakCarousal'
import BacktestResultsCard from './BacktestResultsCard'
import CreateCard from '../../UI/CreateCard'
import BacktestResultsListView from './BacktestResultsListView'
import Icon from '../../UI/Icon'
import Checkbox from '../../UI/Checkbox'

import { algoDateFormat, PRECISION } from '../../utils/consts'
import {
  FONTS, SPACING, theme, COLORS, ICONS, // ICONS,
} from '../../Theme'
import { setCookie } from '../../utils/common'
import { useTheme } from '../../Theme/ThemeProvider'
import CustomModal from '../../UI/CustomModal'
import SharePopUp from '../../UI/SharePopUp'
import { ALGO_KEY_MAP } from '../AlgoCondensedView/AlgoUtils'
import { copyAlgo } from '../../containers/Create/actions'
import ShareCopy from '../../UI/ShareCopy'
import { pathname_mapping } from '../../utils/navigationConstant'
import { toggleLoginModal } from '../../containers/Auth/actions'

export const CARD_HEIGHT = 158
export const CARD_WIDTH = 160
export const CONDENSED_HEIGHT = 88
export const CARD_WIDTH_MAP = {
  0: 145,
  1: 140,
  2: 150,
  3: 160,
  4: 200,
}

export const CARD_HEIGHT_MAP = {
  0: 158,
  1: 150,
  2: 165,
  3: 185,
  4: 200,
}

// helper to get btresults if seg_sym is provided
export const getReqBacktests = (backtest_results, index = 0, seg_sym) => {
  let reqBacktest = backtest_results[index]
  if (!reqBacktest || (reqBacktest && reqBacktest.seg_sym !== seg_sym)) {
    reqBacktest = backtest_results.filter(bt => bt.seg_sym === seg_sym)
    reqBacktest = reqBacktest[0] || { seg_sym }
  }
  return reqBacktest
}

const CARDS_ADD_MAP = {
  0: 3,
  1: 4,
  2: 6,
  3: 8,
}

const BacktestResults = (props) => {
  const {
    dt_start, dt_stop, scripList, backtest_results, onDelete, showBacktestDetails,
    selectedSymbols = new Set(),
    deployed_seg_sym = [],
    isDark, onSymbolSelect,
    deployAll,
    removeSymbols, selectedLen, allSelected, len, addNewSymbol, hideAddStock, selectedIndex = 0,
    brokerageData, brokerage, algoId, algoIdKey, isRunningBacktests, showEditBtn, showDCParams,
    publishing_uuid, selectedItem, algo_uuid, algo_subscription_uuid,
    isSharedPublic,
    showShare, isCopyingAlgo, isLoggedIn, user_details, toggleAuthModal,
    backtestDetailsProps, editSubscribedAlgo,
    toggleSelectAll, isListView = false, toggleListView, renderPnl, updateGA, gaLabel, gaCategory,
    startTour, activeTourIndex, mtfExist,
  } = props
  const containerRef = useRef(null)
  const [isCondesedView, updateView] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showReShareModal, setShowReShareModal] = useState(false)
  const [containerDim, updateDim] = useState({})
  const { displayDevice, isMobile } = useTheme()
  const [shareSelected, setShareSelected] = useState(new Set())
  const [showPrivateDialog, setShowPrivateDialog] = useState(false)
  const itemSelected = selectedItem
  const history = useHistory()
  useEffect(() => {
    if (containerRef.current) {
      const { offsetTop } = containerRef.current
      updateDim({ offsetTop })
    }
  }, [])

  useEffect(() => {
    const main = document.getElementById('main')
    main.addEventListener('scroll', onScrollRes)
    return () => {
      main.removeEventListener('scroll', onScrollRes)
    }
  }, [isCondesedView, containerDim])
  const dif = CARD_HEIGHT - CONDENSED_HEIGHT
  const onScrollRes = useCallback(throttle((ev) => {
    const { scrollTop } = ev.target
    const { offsetTop = 0 } = containerDim
    if (offsetTop && scrollTop >= offsetTop) {
      if (!isCondesedView) updateView(true)
    } else if (scrollTop + dif < offsetTop && isCondesedView) {
      updateView(false)
    }
  }, 50), [isCondesedView, containerDim])
  const start_date = dt_start ? dayjs(dt_start, algoDateFormat).format('DD MMM YYYY') : '-' // DANGER
  const stop_date = dt_stop ? dayjs(dt_stop, algoDateFormat).format('DD MMM YYYY') : '-'
  const styles = stylesheet()
  const updateSelected = debounce((modIndex, sliderProps) => {
    const { item } = sliderProps
    const { seg_sym } = item
    onCardPress({ seg_sym, index: modIndex })
  }, 400)

  const onCardPress = ({
    seg_sym, index = 0,
  }) => {
    if (selectedIndex === index) return
    const precision = PRECISION
    const scrollType = selectedIndex > index ? 'next' : 'back'
    showBacktestDetails(seg_sym, {
      precision, index, scrollType,
    })
  }

  const viewRenderer = (item, index, sliderProps) => {
    const { type } = item
    if (type === 'createCard') {
      return (
        <div className={styles.addCard}>
          <CreateCard
            wrapperStyle={styles.addCardWrapper}
            containerStyle={styles.addStocksCard}
            titleStyle={styles.addCardTitle}
            onPress={addNewSymbolHandlerCard}
            title=""
          />
        </div>
      )
    }

    const {
      seg_sym = '', waiting = false, backtest_result = {},
    } = getReqBacktests(backtest_results, index, item.seg_sym)
    const scripBtResult = backtest_result[seg_sym] || {}
    const scrip = scripList.filter(e => e.seg_sym === seg_sym)[0]
    const { return: final_return = '', error_msg = '' } = scripBtResult
    const isSelected = selectedSymbols.has(seg_sym)
    const isDeployed = deployed_seg_sym.some(segSym => segSym === seg_sym)
    let totalReturn = final_return
    if (brokerageData[seg_sym] && brokerage && selectedIndex === index) {
      totalReturn = Number(brokerageData[seg_sym].period_return)
    }
    // DANGER put inside component
    return (
      <BacktestResultsCard
        cardHeight={CARD_HEIGHT}
        cardWidth={CARD_WIDTH}
        seg_sym={seg_sym}
        final_return={totalReturn}
        onPress={onCardPress}
        waiting={waiting}
        isSelected={isSelected}
        isDeployed={isDeployed}
        onSymbolSelect={onSymbolSelect}
        onDelete={onDelete}
        error_msg={error_msg}
        index={index}
        isDark={isDark}
        showActionBar={!hideAddStock && !isCondesedView
          && !isDeployed && !isMobile
          && algoIdKey !== ALGO_KEY_MAP.SHARED_ALGOS && !hidePTBtns}
        isCondesedView={isCondesedView}
        algoId={algoId}
        algoIdKey={algoIdKey}
        showEditBtn={showEditBtn}
        showDCParams={showDCParams}
        scrip={scrip}
        cardType={type}
        renderPnl={renderPnl}
        isLoggedIn={isLoggedIn}
        shareSelected={shareSelected}
        showSelection={selectedSymbols.size > 0}
        mtfExist={mtfExist}
        {...sliderProps}
      />
    )
  }

  const shareViewRenderer = (item, index, sliderProps) => {
    const { type } = item
    if (type === 'createCard') {
      return (
        <div className={styles.addCard}>
          <CreateCard
            wrapperStyle={styles.addCardWrapper}
            containerStyle={styles.addStocksCard}
            titleStyle={styles.addCardTitle}
            onPress={addNewSymbol}
            title=""
          />
        </div>
      )
    }

    const {
      seg_sym = '', waiting = false, backtest_result = {},
    } = getReqBacktests(backtest_results, index, item.seg_sym)
    const scripBtResult = backtest_result[seg_sym] || {}
    const scrip = scripList.filter(e => e.seg_sym === seg_sym)[0]
    const { return: final_return = '', error_msg = '' } = scripBtResult
    const isSelected = shareSelected.has(seg_sym)
    let totalReturn = final_return
    if (brokerageData[seg_sym] && brokerage && selectedIndex === index) {
      totalReturn = Number(brokerageData[seg_sym].period_return)
    }
    // DANGER put inside component
    return (
      <BacktestResultsCard
        cardHeight={CARD_HEIGHT}
        cardWidth={CARD_WIDTH}
        seg_sym={seg_sym}
        final_return={totalReturn}
        onPress={onCardPress}
        waiting={waiting}
        isSelected={isSelected}
        onSymbolSelect={onSelection}
        error_msg={error_msg}
        index={index}
        isDark={isDark}
        showActionBar={!hideAddStock && !isCondesedView}
        isCondesedView={isCondesedView}
        algoId={algoId}
        algoIdKey={algoIdKey}
        showEditBtn={showEditBtn}
        showDCParams={showDCParams}
        scrip={scrip}
        cardType={type}
        renderPnl={renderPnl}
        mtfExist={mtfExist}
        showShareModal={showShareModal}
        {...sliderProps}
      />
    )
  }

  const onSelection = ({ seg_sym, isSelected }) => {
    const modSelection = new Set(shareSelected)
    if (!isSelected) {
      modSelection.add(seg_sym)
    } else {
      modSelection.delete(seg_sym)
    }
    setShareSelected(modSelection)
  }

  const containerClass = classnames(styles.container,
    isCondesedView && styles.condensedContainer,
    !isListView && (startTour ? styles.containerNonSticky : styles.containerSticky))
  const modScripList = useCallback(() => {
    if (scripList.length < 1) {
      return scripList
    }
    const modList = [...scripList]
    const cardsToAdd = CARDS_ADD_MAP[displayDevice] - scripList.length
    if (cardsToAdd > 0) {
      if(algoIdKey === ALGO_KEY_MAP.SHARED_ALGOS && isSharedPublic === 'true') {
        return modList
      }
      for (let i = 0; i < cardsToAdd; i++) {
        modList.push({
          type: 'createCard', symbol: `createCard${i}`, navDisabled: true,
        })
      }
      return modList
    }
    return modList
  }, [scripList])
  const showModal = () => {
    if(selectedSymbols.size === 0) {
      const modSet = new Set()
      scripList.forEach((item) => {
        modSet.add(item.seg_sym)
      })
      setShareSelected(modSet)
    } else {
      setShareSelected(selectedSymbols)
    }
    if(showShareModal) {
      setShowShareModal(false)
    } else {
      setShowShareModal(true)
    }
  }

  const deployStocks = () => {
    if(startTour) updateGA('Highlight Click', 'Disc Strat Pg 3', 1, 'Product Tour')
    if(algoIdKey === ALGO_KEY_MAP.SHARED_ALGOS) {
      if(!isLoggedIn) {
        setCookie({
          savedPathname: window.location.pathname,
          pathParams: window.location.search,
        })
        toggleAuthModal(true, false, true)
      } else if(isSharedPublic) {
        editSubscribedAlgo(true)
      } else {
        setShowPrivateDialog(true)
      }
      updateGA('Deploy all', `${gaLabel} options`, 1, gaCategory)
    } else {
      deployAll(true)
    }
  }

  const addNewSymbolHandlerCard = () => {
    if(hidePTBtns) return
    addNewSymbolHandler('card')
  }

  const addNewSymbolHandler = (addType) => {
    if(algoIdKey === ALGO_KEY_MAP.SHARED_ALGOS) {
      if(!isLoggedIn) {
        setCookie({
          savedPathname: window.location.pathname,
          pathParams: window.location.search,
        })
        toggleAuthModal(true)
      } else if(isSharedPublic) {
        editSubscribedAlgo()
      } else {
        setShowPrivateDialog(true)
      }
    } else {
      updateGA('Add stocks', `Add stocks${addType === 'card' ? ' card' : ' button'}`)
      addNewSymbol()
    }
  }

  const openShareDialog = () => {
    // const { search } = window.location
    // const { osrc } = parseQuery(search, true)
    // if(!osrc || osrc.length !== 13) {
    //   showSnackbar('Reshare not enabled for this link')
    //   return
    // }
    if(showReShareModal) {
      setShowReShareModal(false)
    } else {
      setShowReShareModal(true)
    }
  }

  const showPrivateModal = () => {
    if(showPrivateDialog) {
      setShowPrivateDialog(false)
    } else {
      setShowPrivateDialog(true)
    }
  }

  const openDiscover = () => {
    history.replace({
      pathname: pathname_mapping.Discover,
    })
  }

  const toggleSelections = () => {
    if (selectedSymbols.size > 0) {
      toggleSelectAll(true)
    } else if (!isEmpty(selectedItem)) {
      onSymbolSelect({ seg_sym: selectedItem.seg_sym, isSelected: false })
    }
  }

  const renderActionBar = () => {
    const viewToggleBtn = (
      <button
        type="button"
        onClick={toggleListView}
        className={`${styles.toggleViewBtn} ${isMobile ? styles.toggleViewBtnResp : ''}`}
      >
        <Icon
          name={isListView ? ICONS.GRID_VIEW : ICONS.LIST_VIEW}
          size={12}
          color={isDark ? COLORS.BLUE_300 : COLORS.BLUE}
        />
      </button>
    )
    if (isMobile) {
      return (
        <div className={styles.headerActionBar}>
          {/* <Button
            mode="outlined"
            buttonColor="blue"
            text={selectedSymbols.size > 0 ? 'Deselect' : 'Select'}
            onPress={selectCurrentStock}
            // params={allSelected}
            btnStyles={styles.selectAllBtn}
          /> */}
          <CreateButton
            text="Add Symbols"
            onPress={addNewSymbolHandler}
            showIcon={false}
            className={styles.addStock}
            btnStyles={styles.addStockBtn}
            createBtnText={styles.addStockBtnText}
          />
          {viewToggleBtn}
        </div>
      )
    }
    return (
      <div className={styles.headerActionBar}>
        {selectedSymbols.size > 0 ? (
          <Button
            mode="outlined"
            buttonColor="blue"
            text={allSelected ? 'Deselect All' : 'Select All'}
            onPress={toggleSelectAll}
            params={allSelected}
            btnStyles={styles.selectAllBtn}
          />
        ) : (
          !hidePTBtns
          && (
          <CreateButton
            text="Add Stocks eg SBIN, F&O etc."
            onPress={addNewSymbolHandler}
            className={styles.addStock}
            btnStyles={styles.addStockBtn}
            createBtnText={styles.addStockBtnText}
          />
          )
        )}

        {(selectedLen >= 1) && (
        <Button
          buttonColor="red"
          text={allSelected || selectedLen === 0 ? 'Delete All' : `Delete ${selectedLen}`}
          uppercase
          btnStyles={styles.removeAllBtn}
          onPress={removeSymbols}
        />
        )}
        {!hidePTBtns
        && (
        <div id="tour_deploy_3">
          <Button
            text={allSelected || selectedLen === 0 ? 'Deploy All' : `Deploy ${selectedLen}`}
            onPress={deployStocks}
            buttonColor="blue"
            btnStyles={styles.deployBtn}
            uppercase
            disabled={len === 0}
            loading={isRunningBacktests}
          />
        </div>
        )}
        {!hidePTBtns
        && (
        <Button
          buttonColor={isDark ? 'blue_300' : 'blue'}
          mode="outlined"
          text="Share"
          iconName="SHARE"
          disabled={isRunningBacktests}
          loading={isRunningBacktests}
          btnStyles={styles.shareStockBtn}
          iconProps={{
            color: COLORS.BLUE_300,
          }}
          labelStyle={styles.shareStockBtnText}
          onPress={showModal}
        />
        )}
        {!hidePTBtns && viewToggleBtn}
      </div>
    )
  }

  const onUpdateGA = () => {
    const gaLabelPT = activeTourIndex === 1 ? 'Disc Strat Pg 2' : activeTourIndex === 14 ? 'Ct Strat Pg 15' : 'Bt Pg 1'
    updateGA('Highlight Click', gaLabelPT, 1, 'Product Tour')
  }

  const ptID = activeTourIndex === 1 ? 'tour_deploy_2' : activeTourIndex === 14 ? 'tour_create_strategy_15' : ''
  const ptIdBacktest1 = 'tour_backtest_1'
  const hidePTBtns = startTour && (activeTourIndex === 1 || activeTourIndex === 14)
  // eslint-disable-next-line max-len
  // const privateText = 'This is a private strategy. You can discover other strategies from our repository of strategies across categories'
  const isDiscover = algoIdKey === ALGO_KEY_MAP.DISCOVER
  return (
    <div
      className={containerClass}
      ref={containerRef}
      data-value="sticky"
      id={ptID}
      role="button"
      onClick={onUpdateGA}
      tabIndex={0}
      onKeyUp={onUpdateGA}
    >
      <CustomModal
        visible={showShareModal}
        animationType="fade"
        onDismiss={showModal}
        dismissKey="showShare"
        containerStyles={styles.modalContainer}
      >
        <SharePopUp
          data={modScripList()}
          scripList={scripList}
          displayDevice={displayDevice}
          viewRenderer={shareViewRenderer}
          publishing_uuid={publishing_uuid}
          item={itemSelected}
          algo_uuid={algo_uuid}
          algo_subscription_uuid={algo_subscription_uuid}
          selectedSymbols={shareSelected}
          onDismiss={showModal}
          user_details={user_details}
          isListView={isListView}
        />
      </CustomModal>
      <CustomModal
        visible={showReShareModal}
        animationType="fade"
        onDismiss={openShareDialog}
        dismissKey="showShare"
        containerStyles={styles.modalContainer}
      >
        <SharePopUp
          reShare
          item={itemSelected}
          algo_uuid={algo_uuid}
          isListView={isListView}
          onDismiss={openShareDialog}
          dismissKey="showShare"
        />
      </CustomModal>
      <CustomModal
        visible={showPrivateDialog}
        animationType="fade"
        onDismiss={showPrivateModal}
        dismissKey="showLogin"
        containerStyles={styles.copyModalContainer}
      >
        <ShareCopy
          title="This is a Private Strategy"
          btnText="Discover Strategies"
          onClickHandler={openDiscover}
          loading={isCopyingAlgo}
          privateStrategy
          subText="You can discover other strategies from our repository of strategies across categories"
          hideCancel
        />
      </CustomModal>
      <div className={styles.header}>
        {!hideAddStock && algoIdKey !== ALGO_KEY_MAP.SHARED_ALGOS && isMobile
          ? (
            <Checkbox
              value={selectedLen > 0}
              onValueChange={toggleSelections}
              className={styles.selectCheckbox}
              id="bt_select_all"
            />
          ) : null}
        <div
          className={styles.title}
          id={ptIdBacktest1}
          role="button"
          onClick={onUpdateGA}
          tabIndex={0}
          onKeyUp={onUpdateGA}
        >
          <CustomText weight="semi_bold" size="medium" className={styles.titleRow1}>
            {'Backtest Results '}
          </CustomText>
          <CustomText size="small" color="textSecondary">
            {` ${start_date} to ${stop_date}`}
          </CustomText>
        </div>
        {isDiscover && (
        <div className={styles.headerActionBarMarketplace}>
          {showShare && isLoggedIn ? (
            <Button
              buttonColor={isDark ? 'blue_300' : 'blue'}
              mode="outlined"
              text="Share"
              iconName="SHARE"
              btnStyles={styles.shareStockBtn}
              labelStyle={styles.shareStockBtnText}
              iconProps={{
                color: COLORS.BLUE_300,
                marginTop: SPACING.SPACE_10,
              }}
              onPress={showModal}
            />
          ) : null}
          <button
            type="button"
            onClick={toggleListView}
            className={`${styles.toggleViewBtn} ${isMobile ? styles.toggleViewBtnResp : ''}`}
          >
            <Icon
              name={isListView ? ICONS.GRID_VIEW : ICONS.LIST_VIEW}
              size={12}
              color={isDark ? COLORS.BLUE_300 : COLORS.BLUE}
            />
          </button>
        </div>
        )}
        {!hideAddStock && algoIdKey !== ALGO_KEY_MAP.SHARED_ALGOS
          ? renderActionBar()
          : (
            <div className={styles.headerActionBarShared}>
              {!hideAddStock ? (
                <div className={styles.headerActionBarShared}>
                  <Button
                    buttonColor="blue"
                    mode={isSharedPublic ? 'outlined' : 'contained'}
                    text="Reshare"
                    iconName="SHARE"
                    disabled={isRunningBacktests}
                    loading={isRunningBacktests}
                    btnStyles={styles.shareStockBtn}
                    iconProps={{
                      color: isSharedPublic ? COLORS.BLUE : COLORS.WHITE,
                    }}
                    labelStyle={styles.shareStockBtnText}
                    onPress={openShareDialog}
                  />
                  {isSharedPublic && (
                  <Button
                    buttonColor="blue"
                    text="COPY STRATEGY"
                    disabled={isRunningBacktests}
                    loading={isRunningBacktests}
                    btnStyles={styles.deployBtn}
                    onPress={deployStocks}
                  />
                  )}
                  {!isMobile && !startTour && (
                    <div>
                      <Button
                        text={allSelected || selectedLen === 0 ? 'Deploy All' : `Deploy ${selectedLen}`}
                        onPress={deployStocks}
                        buttonColor="blue"
                        btnStyles={styles.deployBtn}
                        uppercase
                        disabled={len === 0}
                        loading={isRunningBacktests}
                      />
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={toggleListView}
                    className={`${styles.toggleViewBtn} ${isMobile ? styles.toggleViewBtnResp : ''}`}
                  >
                    <Icon
                      name={isListView ? ICONS.GRID_VIEW : ICONS.LIST_VIEW}
                      size={12}
                      color={isDark ? COLORS.BLUE_300 : COLORS.BLUE}
                    />
                  </button>
                </div>
              ) : null}
            </div>
          )}
      </div>
      {isMobile && selectedSymbols.size > 0 && (
      <div className={styles.headerActionBarSec}>
        {/* <button
          type="button"
          className={styles.removeAllBtn}
          onClick={() => removeSymbols()}
        >
          <Icon name={ICONS.DELETE} color={COLORS.WHITE} />
          {selectedLen > 0 && (
            <CustomText>
              {` ${selectedLen}`}
            </CustomText>
          )}
        </button> */}
        <Button
          buttonColor="red"
          text=""
          uppercase
          iconName="DELETE"
          iconProps={{ color: COLORS.WHITE, size: 16 }}
          btnStyles={styles.removeAllBtn}
          onPress={removeSymbols}
        />
        <Button
          text={allSelected || selectedLen === 0 ? 'Deploy All' : `Deploy ${selectedLen}`}
          onPress={deployStocks}
          buttonColor="blue"
          btnStyles={styles.deployBtn}
          uppercase
          disabled={len === 0}
          loading={isRunningBacktests}
        />
        <Button
          mode="outlined"
          buttonColor="blue"
          text={allSelected ? 'Deselect All' : 'Select All'}
          onPress={toggleSelectAll}
          params={allSelected}
          btnStyles={styles.selectAllBtn}
        />
        <Button
          buttonColor="blue"
          mode="outlined"
          text="Share"
          iconName="SHARE"
          disabled={isRunningBacktests}
          loading={isRunningBacktests}
          btnStyles={styles.shareStockBtn}
          iconProps={{
            color: COLORS.BLUE_300,
          }}
          labelStyle={styles.shareStockBtnText}
          onPress={showModal}
        />
      </div>
      )}
      {isListView && (
      <BacktestResultsListView
        {...props}
        backtestDetailsProps={backtestDetailsProps}
        renderPnl={renderPnl}
        shareSelected={shareSelected}
      />
      ) }
      {isListView ? <div /> : (
        <div className={styles.scriplist} id="tour_bt_2">
          <StreakCarousal
            data={modScripList()}
            viewRenderer={viewRenderer}
            keyName="symbol"
            maxItemWidth={CARD_WIDTH_MAP[displayDevice]}
          // itemHeight={CARD_HEIGHT}
            defaultIndex={selectedIndex}
            onNavButtonPress={updateSelected}
            showNav
            defaultDim={isCondesedView ? {
              height: CONDENSED_HEIGHT,
              baseWidth: CARD_WIDTH_MAP[displayDevice],
            }
              : {
                minHeight: 100,
                minWidth: 80,
                baseWidth: CARD_WIDTH_MAP[displayDevice],
                baseHeight: CARD_HEIGHT_MAP[displayDevice],
              }}
          />
        </div>
      )}
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    padding: `${SPACING.SPACE_10} 0`,
    zIndex: '999',
    backgroundColor: theme.screenBg,
    transition: 'all 250ms',
  },
  containerSticky: {
    position: 'sticky',
    top: 100,
  },
  containerNonSticky: {
    top: 100,
  },
  condensedContainer: {
  },
  // modalContainer: {

  // },
  loginModal: {
    width: 'auto',
    padding: '0px 0px',
    borderRadius: '27px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    alignItems: 'baseline',
  },
  titleRow1: {
    marginRight: SPACING.SPACE_10,
  },
  headerActionBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerActionBarMarketplace: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 'auto',
  },
  headerActionBarShared: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '10px',
  },
  addStockBtn: {
    margin: `0 ${SPACING.SPACE_10} 0 0`,
  },
  copyModalContainer: {
    // height: '427px',
    width: '326px',
    margin: 'auto',
    padding: '36px 26px',
  },
  shareStockBtn: {
    marginLeft: `${SPACING.SPACE_10}`,
    padding: '6px 16px 6px 26px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    columnGap: '7px',
    '& > svg': {
      marginRight: '0 !important',
    },
    '&:disabled': {
      backgroundColor: 'blue',
      opacity: 0.9,
    },
  },
  shareStockBtnIcon: {
    color: '#0013F4 !important',
  },
  shareStockBtnText: {
    fontSize: '14px',
  },
  addStock: {
    '& p': {
      fontSize: `${FONTS.SMALL} !important`,
    },
  },
  scriplist: {
    marginTop: SPACING.SPACE_10,
  },
  scripCard: {
    display: 'inline-block',
    padding: '20px',
    width: 200,
  },
  removeAllBtn: {
    marginRight: SPACING.SPACE_10,
    display: 'flex',
    alignItems: 'center',
  },
  deployBtn: {
  },
  addCard: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addStocksCard: {
    width: '100%',
  },
  addCardWrapper: {
    height: 'calc(100% - 20px)',
    margin: '10px',
    width: '100%',
    borderColor: COLORS.ORANGE,
    opacity: 0.5,
    '&:hover': {
      opacity: 0.9,
    },
  },
  addCardTitle: {
    fontSize: `${FONTS.SMALL_1} !important`,
  },
  selectAllBtn: {
    marginRight: SPACING.SPACE_10,
  },
  table: {
    padding: `0 0 ${SPACING.SPACE_12}`,
    marginTop: SPACING.SPACE_10,
  },
  tableRow: {
    // display: 'flex',
    // alignItems: 'center',
    // padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    height: 45,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    '&:hover $actionBar': {
      opacity: 1,
    },
  },
  actionBar: {},
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${SPACING.SPACE_10}`,
    borderBottom: `1px solid ${theme.borderColor}`,
    // width: '100%',
    height: 40,
    marginBottom: SPACING.SPACE_10,
    // margin: `0 ${SPACING.SPACE_10}`,
    // '& > div:first-child': {
    //   paddingLeft: SPACING.SPACE_10,
    // },
  },
  tableBody: {
    backgroundColor: theme.bgPrimary,
  },
  boxRow: {
    marginLeft: SPACING.SPACE_10,
  },
  checkboxHeader: {
    marginLeft: SPACING.SPACE_10,
  },
  symRow: {
    display: 'inline-grid',
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: SPACING.SPACE_8,
    alignItems: 'center',
    paddingRight: SPACING.SPACE_10,
  },
  gain: {
    color: `${COLORS.GREEN} !important`,
  },
  loss: {
    color: `${COLORS.RED} !important`,
  },
  toggleViewBtn: {
    backgroundColor: theme.blueLightBg,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: SPACING.SPACE_6,
    padding: SPACING.SPACE_10,
  },
  toggleViewBtnResp: {
    top: 'unset',
    position: 'relative',
  },
  '@media only screen and (max-width: 786px)': {
    title: {
      flexDirection: 'column',
      flex: 1,
    },
    titleRow1: {
      margin: 0,
    },
    container: {
      top: 0,
    },
    addStock: {
      padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
      '& p': {
        fontSize: `${FONTS.SMALL_0} !important`,
      },
    },
    addStockBtn: {
      margin: 0,
    },
    deployBtn: {
      padding: `${SPACING.SPACE_6} ${SPACING.SPACE_16}`,
      '& p': {
        fontSize: `${FONTS.SMALL_0} !important`,
      },
    },
    selectAllBtn: {
      marginLeft: SPACING.SPACE_10,
      marginRight: 0,
      padding: `${SPACING.SPACE_4} ${SPACING.SPACE_8}`,
    },
    removeAllBtn: {
      padding: `0 ${SPACING.SPACE_8}`,
    },
    shareStockBtn: {
      padding: `${SPACING.SPACE_6} ${SPACING.SPACE_16}`,
    },
    headerActionBarSec: {
      display: 'flex',
      marginTop: SPACING.SPACE_10,
    },
    selectIconBtn: {
      marginRight: SPACING.SPACE_8,
    },
    selectCheckbox: {
      marginBottom: SPACING.SPACE_18,
      marginRight: SPACING.SPACE_10,
    },
  },
})

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    ...state.auth.loginResp,
    isLoggedIn: state.auth.isLoggedIn,
    // showLoginModal: state.auth.showLoginModal,
    copyAlgoResp: state.create.copyAlgoResp,
    isCopyingAlgo: state.create.isCopyingAlgo,
    copyAlgoError: state.create.copyAlgoError,
    copyAlgoErrorMsg: state.create.copyAlgoErrorMsg,

    publicationStatsResp: state.marketplace.publicationStatsResp,
    isUpdatingPublicationStats: state.marketplace.isUpdatingPublicationStats,
    publicationStatsError: state.marketplace.publicationStatsError,
    publicationStatsErrorMsg: state.marketplace.publicationStatsErrorMsg,

    isSavingBacktestParams: state.backtests.isSavingBacktestParams,
    // saveBacktestParamsSuccess: state.backtests.saveBacktestParamsSuccess,
    // saveBacktestParamsError: state.backtests.saveBacktestParamsError,
    // saveBacktestParamsErrorMsg: state.backtests.saveBacktestParamsErrorMsg,

    disabled: state.backtests.onGoingBacktests[state.backtests.backtests?.algo_uuid],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    copyAlgorithm: (params, headers) => dispatch(copyAlgo(params, headers)),
    toggleAuthModal: (state, postLoginParams, loginShared) => dispatch(
      toggleLoginModal(state, postLoginParams, loginShared),
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BacktestResults)
