import { call, put, takeLatest } from 'redux-saga/effects'
import {
  SCAN_STOCKS_INIT,
  SAVE_SCREEN_INIT,
  SCREENER_STATE_INIT,
  PLACE_DIRECT_INIT,
  SCREENER_TRAINER_INIT,
  SCREENER_TREE_INIT,
  COPY_SCREENER,
  GET_SCRIP_LOT_SIZE_INIT,
} from './actionTypes'
import { error_msg } from '../../utils/common'
import {
  scanStocksSuccess,
  scanStocksFailure,
  saveScreenerSuccess,
  saveScreenerFailure,
  getScreenerStateSuccess,
  getScreenerStateFailure,
  placeDirectSuccess,
  placeDirectFailure,
  screenerTrainerSuccess,
  screenerTrainerFailure,
  screenerTreeSuccess,
  screenerTreeFailure,
  copyScreenerSuccess,
  copyScreenerFailure,
  getScripLotSizeSuccess,
  getScripLotSizeSuccessFailure,
} from './actions'

import { postRequest, getRequest } from '../../utils/apiRequests'
import { baseUrl, scannerUrl, prodEndpoints } from '../../utils/apiEndpoints'
import { GET_USER_DETAILS_INIT } from '../Auth/actionTypes'
import {
  getInstruments,
  screenerPairs,
  getExchangeTokens,
  screenerExchangePairs,
} from '../Instruments/actions'
import { BROKERS } from '../../utils/consts'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import { refreshAlgos, toggleAuthGreeting } from '../Common/actions'
import { PRO_FEATURE_MAP } from '../../utils/userAccessChecks'

function* scanStocks(action) {
  try {
    const { params, headers, refetchState } = action
    const url = `${scannerUrl}`
    const data = yield call(postRequest, url, params.params, headers, true, 90000)
    if (!data.error && data.status === 'success') {
      yield put(scanStocksSuccess(data))
      // done in component
      // yield put(getInstruments(
      //   screenerPairs(null, data.stocks),
      //   {},
      //   NAVIGATIONS.SCAN_DETAILS.name,
      // ))
      const { saveParams } = params
      if (saveParams.isScreenerLive || saveParams.isShared) {
        return
      }
      delete saveParams.isScreenerLive
      saveParams.data.screener_result = data.stocks
      saveParams.data = JSON.stringify(saveParams.data)
      // console.log(saveParams, 'lolscan')
      yield put({
        type: SAVE_SCREEN_INIT,
        params: saveParams,
        headers,
        refetchState,
      })
    } else {
      if (data.status_code === 400 || data.error_msg === 'Limit reached or subscription is over, please upgrade') {
        yield put(toggleAuthGreeting(PRO_FEATURE_MAP.SCANNER_LIMIT.ID))
      }
      yield put(scanStocksFailure(data.error_msg || 'Limit reached, please upgrade'))
    }
  } catch (err) {
    yield put(scanStocksFailure(error_msg))
  }
}

export function* scanStocksSaga() {
  yield takeLatest(SCAN_STOCKS_INIT, scanStocks)
}

function* saveScreener(action) {
  try {
    const { params, headers, refetchState } = action
    const url = `${baseUrl}${prodEndpoints.screener}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(saveScreenerSuccess({ ...data, refetchState }))
      yield put({
        type: GET_USER_DETAILS_INIT,
      })
      if (!params.screener_uuid) {
        yield put(refreshAlgos('scans', true))
      }
    } else {
      yield put(saveScreenerFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(saveScreenerFailure(error_msg))
  }
}

export function* saveScreenerSaga() {
  yield takeLatest(SAVE_SCREEN_INIT, saveScreener)
}

function* screenerState(action) {
  try {
    const {
      params, headers, screenName,
    } = action
    const { broker } = params
    let url = `${baseUrl}${prodEndpoints.screener}`
    if(params.screener_share_uuid) {
      url = `${baseUrl}${prodEndpoints.shared_screener}`
    }
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(getScreenerStateSuccess(data))
      if (screenName) {
        yield put(getInstruments(
          screenerPairs(null, data.screener_result),
          {},
          NAVIGATIONS.SCAN_DETAILS.name,
        ))
        if(broker === BROKERS.UPSTOX.name) {
          yield put(getExchangeTokens(
            screenerExchangePairs(null, data.screener_result),
            {},
            NAVIGATIONS.SCAN_DETAILS.name,
          ))
        }
      }
    } else {
      yield put(getScreenerStateFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(getScreenerStateFailure(error_msg))
  }
}

export function* screenerStateSaga() {
  yield takeLatest(SCREENER_STATE_INIT, screenerState)
}

function* placeDirect(action) {
  try {
    const { params, headers } = action
    const { platform = 'streak' } = params
    let url = `${baseUrl}${prodEndpoints.place_direct}`
    if(platform === 'openstreak') {
      url = `https://api-op.streak.tech/${prodEndpoints.place_direct}`
    }
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(placeDirectSuccess(data))
    } else {
      yield put(placeDirectFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(placeDirectFailure(error_msg))
  }
}

export function* placeDirectSaga() {
  yield takeLatest(PLACE_DIRECT_INIT, placeDirect)
}

function* getScreenerTrainer(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.screener_trainer}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error) {
      yield put(screenerTrainerSuccess(data))
    } else {
      yield put(screenerTrainerFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(screenerTrainerFailure(error_msg))
  }
}

export function* getScreenerTrainerSaga() {
  yield takeLatest(SCREENER_TRAINER_INIT, getScreenerTrainer)
}

function* screenerTree(action) {
  try {
    const { headers } = action
    const url = prodEndpoints.scanner_tree
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error) {
      yield put(screenerTreeSuccess(data))
    } else {
      yield put(screenerTreeFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(screenerTreeFailure(error_msg))
  }
}

export function* screenerTreeSaga() {
  yield takeLatest(SCREENER_TREE_INIT, screenerTree)
}

function* copyScreener(action) {
  try {
    const { params } = action
    const url = `${baseUrl}${prodEndpoints.copy_screener}`
    const data = yield call(postRequest, url, params)
    if (!data.error && data.status === 'success') {
      yield put(copyScreenerSuccess(data))
    } else {
      yield put(copyScreenerFailure(data.error))
    }
  } catch (err) {
    yield put(copyScreenerFailure('Error getting response'))
  }
}

export function* copyScreenerSaga() {
  yield takeLatest(COPY_SCREENER, copyScreener)
}

function* getScripLotSize(action) {
  try {
    const { details_params } = action
    const url = prodEndpoints.get_instrument_token_open
    const data = yield call(postRequest, url, JSON.stringify(details_params), { 'content-type': 'application/json' }, false)

    if(!data.error) {
      yield put(getScripLotSizeSuccess(data))
    } else {
      yield put(getScripLotSizeSuccessFailure(data.error))
    }
  } catch (err) {
    yield put(getScreenerStateFailure(err.message))
  }
}

export function* getScripLotSizeSaga() {
  yield takeLatest(GET_SCRIP_LOT_SIZE_INIT, getScripLotSize)
}
