import makeStyles from '@material-ui/styles/makeStyles'
import classnames from 'classnames'
import { animated } from 'react-spring'
import React from 'react'

import Icon from '../../UI/Icon'
import CustomText from '../../UI/CustomText'

import { SPACING, ICONS, COLORS } from '../../Theme'
import { CURRENCY_SYM } from '../../UI/ShowLTP'

const FundsCardRms = ({
  rmsLimit = 0.00,
  wrapperStyles = {},
  isFetching,
  fundsError,
  isDark,
}) => {
  const styles = stylesheet()
  return (
    <animated.div
      className={classnames(styles.card, wrapperStyles)}
      style={{ backgroundColor: isDark ? COLORS.BLACK_30 : COLORS.PURPLE_100 }}
    >
      {isFetching ? <p>loading</p> : (
        <>
          <div className={styles.header}>
            <Icon name={ICONS.EQUITY} color={COLORS.PURPLE} size={25} />
            <CustomText style={{ marginLeft: SPACING.SPACE_10 }} size="large" weight="medium">
              {'  '}
              RMS Limit
            </CustomText>
          </div>
          <CustomText size="large" weight="medium" style={{ marginTop: SPACING.SPACE_10 }}>
            {CURRENCY_SYM}
            {` ${rmsLimit.toFixed(2)}`}
          </CustomText>
          <CustomText size="small" color="textSecondary">Available Balance</CustomText>
          {fundsError && (
            <CustomText className={styles.error} size="tiny_1" color="red" weight="regular">
              {/* <Icon name={ICONS.REFRESH} color={COLORS.RED} size={8} /> */}
              {'  Some error occured'}
            </CustomText>
          )}
        </>
      )}

    </animated.div>
  )
}

const stylesheet = makeStyles({
  card: {
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    borderRadius: 8,
    boxSizing: 'border-box',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    position: 'absolute',
    bottom: SPACING.SPACE_4,
    right: SPACING.SPACE_10,
  },
})

export default FundsCardRms
