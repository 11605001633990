import { connect } from 'react-redux'

import Authenticator from './Authenticator'

import {
  logout,
  // setToken,
  getUserDetails,
  fetchVersion,
  updateVersion,
  validateSession,
  loginUser,
  fetchVersionSuccess,
  setToken,
  clearUserDetails,
  accountActivation,
  loginUserFailure,
  loginUserSuccess,
  toggleLoginModal,
  updateFancyBroker,
} from '../Auth/actions'
import { getNotifications } from '../Notifications/actions'
import { fetchBilling } from '../Billing/actions'

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    isLoggedIn: state.auth.isLoggedIn,
    loginError: state.auth.loginError,
    loginResp: state.auth.loginResp,
    defaultVersion: state.auth.defaultVersion,
    isFetchingUser: state.auth.isFetchingUser,
    loginErrorMsg: state.auth.loginErrorMsg,
    sessionValid: state.auth.sessionValid,
    sessionValidError: state.auth.sessionValidError,
    fetchVersionError: state.auth.fetchVersionError,
    fetchVersionErrorMsg: state.auth.fetchVersionErrorMsg,
    signUp: state.auth.signUp,

    isActivating: state.auth.isActivating,
    activationError: state.auth.activationError,
    activationErrorMsg: state.auth.activationErrorMsg,
    activationResp: state.auth.activationResp,
    websiteConfig: state.common.websiteConfig,

    updateVersionSuccess: state.auth.updateVersionSuccess,
    isUpdatingVersion: state.auth.isUpdatingVersion,
    updateVersionError: state.auth.updateVersionError,
    updateVersionErrorMsg: state.auth.updateVersionErrorMsg,

    isLogging: state.auth.isLogging,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: params => dispatch(getUserDetails(params)),
    fetchVersion: headers => dispatch(fetchVersion(headers)),
    validateSession: (params, headers) => dispatch(validateSession(params, headers)),
    updateVersion: (params, headers) => dispatch(updateVersion(params, headers)),
    loginUser: (params, headers, type) => dispatch(loginUser(params, headers, type)),
    fetchVersionSuccess: data => dispatch(fetchVersionSuccess(data)),
    clearUserDetails: () => dispatch(clearUserDetails()),
    setToken: params => dispatch(setToken(params)),
    logout: () => dispatch(logout()),
    getNotifications: headers => dispatch(getNotifications(headers)),
    accountActivation: (params, headers) => dispatch(accountActivation(params, headers)),
    fetchBilling: headers => dispatch(fetchBilling(headers)),
    loginUserFailure: error => dispatch(loginUserFailure(error)),
    loginUserSuccess: params => dispatch(loginUserSuccess(params)),
    toggleAuthModal: (state, params) => dispatch(toggleLoginModal(state, params)),
    updateFancyBroker: params => dispatch(updateFancyBroker(params)),
    // getNotificationCount: () => dispatch(getNotificationCount()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticator)
