import React, { memo } from 'react'
import classnames from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types'

import Button from './Button'
import CustomText from './CustomText'
import CustomModal from './CustomModal'

import {
  SPACING, theme,
} from '../Theme'

const AlertPopup = memo(({
  imgSrc,
  title,
  content,
  children = null,
  actions,
  containerStyles,
  contentStyles,
  titleStyles,
  imgStyles,
  actionBarStyles,
  extraStyles,
  modalStyles,
  visible,
  onDismiss,
  dismissKey,
  loading,
  isMobile,
  overrideMobilePosition = false,
}) => {
  const styles = stylesheet()

  const modalId = !isMobile && dismissKey === 'deploySuccessModal' ? 'tour_deploy_6' : 'tour_scan_4'
  const ptIdMobile = isMobile ? 'tour_deploy_6' : ''

  if (!visible) return null
  return (
    <CustomModal
      visible={visible}
      onDismiss={onDismiss}
      dismissKey={dismissKey}
      containerStyles={modalStyles}
      id={ptIdMobile}
      overrideMobilePosition={overrideMobilePosition}
    >
      <div
        className={classnames(styles.container, containerStyles)}
        style={extraStyles}
        id={modalId}
      >
        {Boolean(imgSrc) && (
        <img
          src={imgSrc}
          alt={`${title}_img`}
          className={classnames(styles.alertImg, imgStyles)}
        />
        )}
        <CustomText
          weight="semi_bold"
          size="medium"
          className={classnames(styles.title, titleStyles)}
        >
          {title}
        </CustomText>
        {content ? (
          <CustomText size="small" color="textSecondary" className={classnames(styles.text, contentStyles)}>{content}</CustomText>
        ) : null}
        {children}
        <div className={classnames(styles.actionBar, actionBarStyles)}>
          {actions.map((actionObj) => {
            const {
              text, action, containerStyle = '', btnProps = {},
              labelStyle = '',
            } = actionObj
            return (
              <Button
                key={text}
                labelStyle={classnames(styles.actionBtnCustomText, labelStyle)}
                containerStyle={classnames(styles.actionBarContainer, containerStyle)}
                contentStyle={classnames(styles.actionBtn)}
                onPress={action}
                roundness={6}
                text={text}
                labelSize="regular"
                buttonColor="blue"
                {...btnProps}
                loading={loading}
              />
            )
          })}
        </div>
      </div>
    </CustomModal>
  )
})

const stylesheet = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.bgPrimary,
  },
  alertImg: {
    marginBottom: SPACING.SPACE_10,
    width: '100%',
  },
  title: {

  },
  text: {
    marginTop: SPACING.SPACE_10,
  },
  actionBarContainer: {
    marginTop: SPACING.SPACE_20,
  },
  actionBtnCustomText: {
  },
  actionBtn: {
  },
  leftActionBtn: {
    marginRight: SPACING.SPACE_10,
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: SPACING.SPACE_20,
    '& button': {
      // width: '',
      flex: 1,
      padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
      border: '1px solid transparent',
      marginLeft: SPACING.SPACE_20,
      '&:first-child': {
        margin: 0,
      },
    },
  },
})

export default AlertPopup

AlertPopup.propTypes = {
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  actions: PropTypes.array,
  containerStyles: PropTypes.string,
  contentStyles: PropTypes.string,
  titleStyles: PropTypes.string,
  imgStyles: PropTypes.string,
  actionBarStyles: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  dismissKey: PropTypes.string,
  extraStyles: PropTypes.object,
  modalStyles: PropTypes.string,
}

AlertPopup.defaultProps = {
  imgSrc: '',
  title: '',
  content: '',
  containerStyles: '',
  contentStyles: '',
  titleStyles: '',
  imgStyles: '',
  actionBarStyles: '',
  dismissKey: '',
  extraStyles: {},
  modalStyles: '',
  actions: [],
}
