/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AnimatedCarousal from '../../UI/AnimatedCarousal'
import PlaceHolderLoader from '../../UI/PlaceHolderLoader'

import { getDashboardCarousel, getWordpressCarousel } from '../../containers/Dashboard/actions'
import { getWebSupportedUrl } from '../../utils/common'
import { toggleRequestDemo } from '../../containers/Account/actions'

const DashboardCarousel = ({
  dashboardCarousel, fetchDashboardCarousel, isFetchingDashboardCarousel,
  carouselReqDemo, startTour,
  wordpressCarousel, fetchWordpressCarousel, isFetchingWordpressCarousel,
  isMobile, user_details,
}) => {
  const history = useHistory()
  const [carousal, setCarousal] = useState([])

  useEffect(() => {
    if (isEmpty(dashboardCarousel)) {
      fetchDashboardCarousel()
    }
    if (isEmpty(wordpressCarousel)) {
      fetchWordpressCarousel({ slug: 'strk-crsl' })
    }
    setCarousal(dashboardCarousel.map((dashObj) => {
      const found = wordpressCarousel.find(
        wordpressObj => wordpressObj.description === dashObj.description,
      )
      if (found) {
        return found
      }
      return dashObj
    }).filter(dashObj => dashObj.description !== 'Demo request' && dashObj.description !== 'Festive offer'))
  }, [wordpressCarousel, dashboardCarousel])

  const onCardPress = (item) => {
    const { action_type, external_url, action_url } = item
    if (action_type === 'external' || external_url) {
      window.open(action_url, '_blank')
    } else {
      const { actionUrl, isDemoReq } = getWebSupportedUrl(action_url)
      if (isDemoReq) {
        carouselReqDemo(true, true)
      } else {
        history.push(actionUrl)
      }
    }
  }

  const viewRenderer = (item) => {
    const { image_url = '', description = '' } = item
    return (
      <button
        type="button"
        onClick={() => onCardPress(item)}
        key={description}
        style={{ width: '100%', height: '100%' }}
      >
        <div
          style={{
            backgroundImage: `url(${image_url})`,
            width: '100%',
            height: '100%',
            backgroundSize: 'contain',
            backgroundPositionX: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: 6,
          }}
        />
      </button>
    )
  }

  const filterCarouselData = () => {
    const { subscription_remaining = 0, subscription_plan = '', subscription_period = '' } = user_details || {}
    let filterData = carousal || []
    if(subscription_plan === 'ultimate' && subscription_period === '12' && subscription_remaining >= 30) {
      filterData = carousal.filter(item => item.description !== 'Festive offer')
    }
    return filterData
  }

  if (isFetchingDashboardCarousel || isFetchingWordpressCarousel) {
    return (
      <PlaceHolderLoader style={{
        height: '100%',
      }}
      />
    )
  }
  const finalCarousel = filterCarouselData() || []
  return (
    <AnimatedCarousal
      singleView
      keyName="description"
      showDots={carousal.length > 1}
      showNav
      dotProps={{
        size: 9,
      }}
      startTour={startTour}
      containerStyles={{ height: isMobile ? '100%' : '80%' }}
    >
      {finalCarousel.map(viewRenderer)}
    </AnimatedCarousal>
  )
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    csrf: state.auth.loginResp.csrf,
    isFetchingDashboardCarousel: state.dashboard.isFetchingDashboardCarousel,
    dashboardCarousel: state.dashboard.dashboardCarousel,
    dashboardCarouseError: state.dashboard.dashboardCarouseError,
    dashboardCarouseErrorMsg: state.dashboard.dashboardCarouseErrorMsg,
    startTour: state.common.startTour,
    isFetchingWordpressCarousel: state.dashboard.isFetchingWordpressCarousel,
    wordpressCarousel: state.dashboard.wordpressCarousel,
    wordpressCarouselError: state.dashboard.wordpressCarouselError,
    wordpressCarouselErrorMsg: state.dashboard.wordpressCarouselErrorMsg,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardCarousel: (params, headers) => dispatch(getDashboardCarousel(params, headers)),
    fetchWordpressCarousel: params => dispatch(getWordpressCarousel(params)),
    carouselReqDemo: (value, isCarouselReqDemo) => dispatch(
      toggleRequestDemo(value, isCarouselReqDemo),
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCarousel)
