/* eslint-disable import/no-cycle */
/* eslint-disable radix */
import React, { Component } from 'react'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'
import { isEqual, throttle } from 'lodash'

import CustomModal from '../../UI/CustomModal'
import IndicatorDetails from '../Create/IndicatorDetails'

import {
  date_range_epochs,
  //   candle_freq_map,
  candleIntervals,
  candle_map,
  renkoParams,
  //   algoDateFormat,
  defaultChartList,
  TPSL_TYPES,
  TPSL_LIMIT,
  DAILY_STRATEGY_CYCLE,
  //   POSITION_SIZING_TYPES,
  //   MAX_ALLOCATION_OBJ,
  POSITION_SIZING_TYPE,
  TPSL_TYPE,
  algoDateFormat,
  candleIntervalsShort,
  condensed_candle_map,
  decimalRegex,
  qtyRegex,
  postionBtnMap,
  tf_map,
  rev_candle,
  rev_mtf_candle,
  candle_min_map,
} from '../../utils/consts'
import { getCandleIntervalsMap } from '../../utils/common'
import { showSnackbar } from '../../UI/Snackbar'
import { PRO_FEATURE_MAP } from '../../utils/userAccessChecks'
import { setEditGAParams } from '../../containers/Backtests/Backtests'
import { tpslValidator, tslValidator, VALID_TYPE_ERROR } from '../../containers/Create/Create-utils'
import { checkBlockedMTFIndc } from '../../containers/Create/Create'

const multipleCiProps = {
  hideDelay: 4000,
  showReadMore: true,
  link: 'https://blog.streak.tech/timeframe-compatibility-and-setting-a-base-timeframe/',
}

class EditAlgoParams extends Component {
  constructor(props) {
    super(props)
    const {
      quantity = 0,
      dt_start,
      dt_stop,
      initial_capital = 10000000,
      trading_start_time,
      trading_stop_time,
      chart_type: chartType = '',
      time_frame,
      candleInterval,
      create_plus,
      holding_type,
      max_allocation = '',
      max_allocationErr = false,
      max_quantity = '',
      max_quantityErr = false,
      tpsl_type,
      daily_strategy_cycle,
      position_sizing_type,
      showHeader = true, algo_desc = '', // parentPage
      isBacktestPage,
      take_profit,
      stop_loss,
      position_type = '',
      trailing_sl,
      trailing_slErr = false,
    } = props
    // const time_frame = candleIntervals[candleInterval]
    const timeFrame = candleInterval ? condensed_candle_map[candleInterval] : time_frame
    const chart_list = create_plus ? defaultChartList.filter(cType => cType !== 'Renko') : [...defaultChartList]
    this.holding_type = ['MIS', 'CNC/NRML']
    this.candleIntervals = isBacktestPage
      ? getCandleIntervalsMap(candleIntervalsShort, candleIntervalsShort)
      : getCandleIntervalsMap(candleIntervals, candleIntervals)
    // if (subscription_type === 0) { // DANGER
    //   this.chart_list = defaultChartList.filter(cType => cType === defaultChartList[0])
    // }
    // if(!create_plus && subscription_type === 3) {
    //   this.chart_list.push('Renko')
    // }

    let fancyChartType = chartType
    let chart_type = chartType
    if (/Renko/i.test(chartType)) {
      chart_type = chartType
      fancyChartType = defaultChartList[2]
    } else if (chart_type === 'Heikin-Ashi' || chart_type === 'heikinAshi' || chart_type === 'Heikin-Ashi Chart') {
      chart_type = chart_list[1]
      fancyChartType = chart_type
    } else {
      chart_type = chart_list[0]
      fancyChartType = chart_type
    }
    this.maxStopDate = new Date()
    this.showBacktestBtn = true
    this.showHeader = showHeader
    this.btnText = 'Run Backtest'

    let start_date = moment(dt_start, 'DD/MM/YYYY')
    const stop_date = moment(dt_stop, 'DD/MM/YYYY')
    const candle_allowed_time = date_range_epochs[timeFrame]
    // console.log({
    //   timeFrame, time_frame, candleInterval, candleIntervals: this.candleIntervals,
    // })
    if ((moment(stop_date).unix() - moment(start_date).unix()) > candle_allowed_time) {
      start_date = moment((moment(stop_date).unix() - candle_allowed_time) * 1000)
    }
    let f_trading_start_time = moment(trading_start_time, 'HH:mm')
    let f_trading_stop_time = moment(trading_stop_time, 'HH:mm')

    if (trading_start_time.toLowerCase().includes('m')) {
      f_trading_start_time = moment(trading_start_time, 'hh : mm A')
      f_trading_stop_time = moment(trading_stop_time, 'hh : mm A')
    }

    this.state = {
      quantity,
      time_frame: timeFrame,
      dt_start: start_date,
      dt_stop: stop_date,
      initial_capital,
      trading_start_time: f_trading_start_time,
      trading_stop_time: f_trading_stop_time,
      chart_type,
      algo_desc,
      min_candle_freq: 10,
      quantityErr: !quantity,
      holding_type,
      max_allocation,
      max_allocationErr,
      max_quantity,
      max_quantityErr,
      tpsl_type,
      daily_strategy_cycle,
      position_sizing_type,
      take_profit,
      stop_loss,
      position_type,
      // if adding a new state variable remove it while sending in getFormatedState
      showDateTimeModal: false,
      showRenkoSelection: false,
      fancyChartType,
      chart_list,
      trailing_sl,
      trailing_slErr,
    }
    this.numRegx = /^[0-9]*\.?[0-9]*$/
    this.decimalRegx = /^\.[0-9]+$/
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isBacktestPage, tpsl_type, create_plus, isCreatePro,
      activeTourIndex, onEditParams,
    } = this.props
    const { fancyChartType, chart_type } = this.state
    if (!isEqual(prevState, this.state) && !isBacktestPage && !isCreatePro) {
      throttle(() => onEditParams(
        this.getFormatedState(),
      ), 250, { leading: false })()
    }
    if (!isBacktestPage && tpsl_type !== prevProps.tpsl_type) {
      this.setState({ tpsl_type })
    }
    if (create_plus !== prevProps.create_plus) {
      let modFancyChart = fancyChartType
      let chart_list = [...defaultChartList]
      if (create_plus) {
        chart_list = defaultChartList.filter(cType => cType !== 'Renko')

        if (/Renko/i.test(chart_type)) {
          modFancyChart = chart_list[0]
        }
      }
      this.setState({ fancyChartType: modFancyChart, chart_type: modFancyChart, chart_list })
    }
    // next button logic for product tour
    if(prevProps.activeTourIndex !== activeTourIndex
          && activeTourIndex > prevProps.activeTourIndex) {
      switch(activeTourIndex) {
        case 7:
        case 10:
        case 11:
        case 14: {
          this.onEditParams()
          break
        }
        default: break
      }
    }
  }

  getFormatedState = () => {
    const { isBacktestPage } = this.props
    const {
      dt_start, dt_stop, trading_start_time, trading_stop_time, take_profit, position_type,
      time_frame, quantity, daily_strategy_cycle, position_sizing_type, stop_loss,
      initial_capital, chart_type, holding_type, algo_desc, tpsl_type, max_allocation, max_quantity,
      trailing_sl,
    } = this.state
    let finalDailyStategyCycle = daily_strategy_cycle
    if(holding_type === 'CNC/NRML' && daily_strategy_cycle !== '-') {
      finalDailyStategyCycle = '-'
    }
    return {
      take_profit,
      stop_loss,
      quantity,
      initial_capital,
      chart_type,
      holding_type,
      algo_desc,
      tpsl_type,
      trailing_sl,
      max_allocation,
      max_quantity,
      daily_strategy_cycle: finalDailyStategyCycle,
      position_sizing_type,
      position_type: isBacktestPage ? position_type.toUpperCase() : position_type,
      dt_start: moment(dt_start).format(algoDateFormat),
      dt_stop: moment(dt_stop).format(algoDateFormat),
      trading_start_time: moment(trading_start_time).format('HH:mm'),
      trading_stop_time: moment(trading_stop_time).format('HH:mm'),
      time_frame: isBacktestPage ? time_frame : candle_map[time_frame],
    }
  }

  onInputChange = (e, key) => {
    const { target: { value } } = e
    this.showBacktestBtn = true
    this.setState({ [key]: value, [`${key}Err`]: false })
  }

  handleChange = (value, obj, field) => {
    const {
      time_frame, position_sizing_type, tpsl_type, daily_strategy_cycle,
    } = this.state

    if (field === 'tpsl_type' || field === 'position_sizing_type') {
      if ((value === TPSL_TYPE.ABSOLUTE.value
        && position_sizing_type === POSITION_SIZING_TYPE.RISK_BASED)
        || (value === POSITION_SIZING_TYPE.RISK_BASED && tpsl_type === TPSL_TYPE.ABSOLUTE.value)) {
        showSnackbar('Cannot select TPSL type as abs with Risk based position sizing')
        return
      }
    }

    if (field === 'holding_type' && value === 'MIS' && time_frame === 'day') {
      showSnackbar('Cannot select MIS with day candle')
      return
    }

    if (field === 'holding_type' && value === 'CNC/NRML' && daily_strategy_cycle !== '-') {
      this.setState({ daily_strategy_cycle: '-' })
      showSnackbar('Daily strategy has been set to default since it cannot be used when holding type is CNC/NRML', { hideDelay: 6000 })
    }

    this.showBacktestBtn = true
    this.setState({
      [field]: value,
      [`${field}Err`]: false,
    })
    if (field === 'holding_type') {
      setEditGAParams({ holding_type: ['Holding type', value, 1, this.props.gaCategory] })
    }
  }

  checkForInvalidCandleInterval = (selectedCandleInterval) => {
    const { action_str, action_str_exit } = this.props
    const finalActionStr = action_str + action_str_exit
    if (finalActionStr) {
      let finalIndcators = finalActionStr
      if (finalActionStr.includes('Period min(')) {
        finalIndcators = finalActionStr.replaceAll('Period min(', 'Periodmin(')
      }

      const strArr = finalIndcators.split(new RegExp('[-+()*/:?, ]', 'g'))
      const conditionTimeFrames = strArr
        .filter(e => tf_map.indexOf(e) !== -1)
        .map(tf => rev_candle[tf] || rev_mtf_candle[tf]).sort((a, b) => b - a)
      const smallestConditionTimeFrame = conditionTimeFrames[0]
      const selectedCandleIntervalIndex = rev_candle[selectedCandleInterval]
      if (selectedCandleIntervalIndex < smallestConditionTimeFrame) {
        return -1
      }
      const multipleReturnVal = conditionTimeFrames.map((tf) => {
        if (candle_min_map[tf]
          % candle_min_map[selectedCandleIntervalIndex] !== 0) {
          return -2
        }
        return 1
      }).sort((a, b) => a - b)[0]
      return multipleReturnVal
    }
    return 1
  }

  handleCandleChange = (value) => {
    const { holding_type } = this.state
    if (holding_type === 'MIS' && value === 'day') {
      showSnackbar('Cannot select day with MIS')
      return
    }
    const isValidCandle = this.checkForInvalidCandleInterval(value)
    if (isValidCandle < 0) {
      if (isValidCandle === -1) {
        showSnackbar('Candle interval can not be higher than the smallest time frame in condition', { hideDelay: 5000 }, 0)
      } else if (isValidCandle === -2) {
        showSnackbar('The time frame in condition is not a multiple of candle interval selected', multipleCiProps, 0)
      }
      return
    }

    const candle_allowed_time = date_range_epochs[value]
    const { dt_start, dt_stop } = this.state
    if ((moment(dt_stop).unix() - moment(dt_start).unix()) !== candle_allowed_time) {
      const new_start_time = moment((moment(dt_stop).unix() - candle_allowed_time) * 1000)
      this.showBacktestBtn = true
      this.setState({ time_frame: value, dt_start: new_start_time })
      showSnackbar('Backtesting period(start date / stop date) adjusted accordingly')
    } else {
      this.showBacktestBtn = true
      this.setState({ time_frame: value })
    }
    setEditGAParams({ time_frame: ['Position toggle', value, 1, this.props.gaCategory] })
  }

  setTradingTime = (field, date) => { // date comes as moment obj
    // if user tries to edit input field then return
    if (typeof date === 'string') {
      return
    }
    const { trading_stop_time, trading_start_time } = this.state
    if (field === 'trading_stop_time'
      && trading_start_time.isAfter(date)) {
      showSnackbar('Stop time cannot be less than start time', {}, 0)
    } else if (field === 'trading_start_time'
      && trading_stop_time.isBefore(date)) {
      showSnackbar('Start time cannot be more than stop time', {}, 0)
    } else {
      this.showBacktestBtn = true
      this.setState({ [field]: date })
    }
  }

  setSelectedDateTime = (field, date) => {
    if (typeof date === 'string') {
      return
    }
    const candle_allowed_time = date_range_epochs[this.state.time_frame]
    const { dt_start, dt_stop } = this.state
    const epochDate = date.unix()
    this.inputChanged = true
    if (field === 'dt_stop'
      && (((epochDate - moment(dt_start).unix()) > candle_allowed_time)
        || (epochDate - moment(dt_start).unix() < 0))) {
      const end_date = moment(epochDate * 1000)
      const start_date = moment((epochDate - candle_allowed_time) * 1000)
      this.showBacktestBtn = true
      this.setState({
        dt_stop: end_date,
        dt_start: start_date,
      })
      return
    }
    if (field === 'dt_start'
      && (((moment(dt_stop).unix() - epochDate) > candle_allowed_time)
        || ((moment(dt_stop).unix() - epochDate) < 0))) {
      const start_date = moment(epochDate * 1000)
      const end_epoch = Math.min((epochDate + candle_allowed_time) * 1000, Date.now())
      const end_date = moment(end_epoch)
      this.showBacktestBtn = true
      this.setState({
        dt_start: start_date,
        dt_stop: end_date,
      })
      return
    }
    this.showBacktestBtn = true
    this.setState({ [field]: date })
  }

  chartChangeHandler = (chart) => {
    const { user_details, toggleAuthGreeting } = this.props
    this.inputChanged = true
    if (chart === 'Renko') {
      if (user_details
        && user_details.subscription_type < 3) {
        // showSnackbar('Subscribe to higher plan to use Renko')
        toggleAuthGreeting(PRO_FEATURE_MAP.RENKO.ID)
        return
      }
      this.setState({ showRenkoSelection: true, show_chart: false })
    } else if (user_details.subscription_type === 0
      && chart === 'Heikin-Ashi') {
      toggleAuthGreeting(PRO_FEATURE_MAP.HEIKIN_ASHI.ID)
      // showSnackbar('Subscribe to higher plan to use Heikin-Ashi')
    } else {
      this.showBacktestBtn = true
      this.setState({ chart_type: chart, fancyChartType: chart, show_chart: false })
      setEditGAParams({ chart_type: ['Chart type', chart, 1, this.props.gaCategory] })
    }
  }

  saveDetails = (data) => {
    this.showBacktestBtn = true
    this.setState({
      showRenkoSelection: false,
      chart_type: `Renko(${data['Brick size']})`,
      fancyChartType: defaultChartList[2],
    })
  }

  slTpValidity = (sltp) => {
    const num = Number(sltp)
    // limit is there in conditionRenderer
    if (num <= 0 || num > 1000) {
      return false
    }
    return true
  }

  onEditParams = () => {
    const {
      isCreatePro,
      scripList = [],
      changePtComponentStatus,
      activeTourIndex,
      updateTourIndex,
      startTour,
      updateGA,
      entryStr,
      exitStr,
    } = this.props
    const {
      holding_type,
      daily_strategy_cycle,
      dt_start,
      position_sizing_type,
      tpsl_type, take_profit, stop_loss, trailing_sl,
      max_allocation, initial_capital, quantity,
    } = this.state
    const expiryDate = moment('1 March 2020')
    const updateStep = !isCreatePro
    changePtComponentStatus('landedEntryStep')

    const result = checkBlockedMTFIndc(entryStr)
    const { isMatched = false, matchedIndc = null, matchedPeriod = null } = result[0] || {}
    if (isMatched) {
      showSnackbar(`Cannot use ${matchedIndc}
   with ${matchedPeriod}, please edit the strategy and select the timeframe lower than equal to 'day'.`)
      return
    }

    const resultExit = checkBlockedMTFIndc(exitStr)
    const {
      isMatched: isMatchedExit = false, matchedIndc: matchedIndcExit = null,
      matchedPeriod: matchedPeriodExit = null,
    } = resultExit[0] || {}
    if (isMatchedExit) {
      showSnackbar(`Cannot use ${matchedIndcExit}
   with ${matchedPeriodExit}, please edit the strategy and select the timeframe lower than equal to 'day'.`)
      return
    }

    if(holding_type === 'CNC/NRML' && daily_strategy_cycle !== '-') {
      this.handleChange(holding_type, {}, 'holding_type')
    }
    if(startTour) updateGA('Button Click', 'Ct Strat Pg 14', 1, 'Product Tour')
    if(activeTourIndex === 13) {
      updateTourIndex(14)
    }

    if(position_sizing_type === 'Risk based' && max_allocation === '') {
      showSnackbar('Please enter Max SL per trade.')
      return
    }

    if(position_sizing_type === 'Capital based' && max_allocation === '') {
      showSnackbar('Please enter Max Allocation.')
      return
    }

    const expiredcontractExist = scripList.some((scrip) => {
      if(scrip.details || (scrip.symbol && scrip.symbol.includes('DYNAMIC CONTRACT'))) {
        if(moment(dt_start).isBefore(expiryDate)) {
          return true
        }
      }
      return false
    })
    if(expiredcontractExist) {
      showSnackbar('Backtesting on expired contracts is only enabled from 1st Mar 2020')
      return
    }

    if(parseFloat(take_profit) === 0) {
      showSnackbar(VALID_TYPE_ERROR.tp, 'error')
      return
    }
    if(parseFloat(stop_loss) === 0) {
      showSnackbar(VALID_TYPE_ERROR.sl, 'error')
      return
    }
    if(parseFloat(initial_capital) === 0) {
      showSnackbar(VALID_TYPE_ERROR.ic, 'error')
      return
    }
    if(parseFloat(max_allocation) === 0) {
      showSnackbar(VALID_TYPE_ERROR.max, 'error')
      return
    }
    if(parseFloat(quantity) === 0) {
      showSnackbar(VALID_TYPE_ERROR.qty, 'error')
      return
    }
    if (tpsl_type === 'pct') {
      let type = this.slTpValidity(stop_loss)
      if (!type) {
        showSnackbar(VALID_TYPE_ERROR.slLimit)
        return
      }
      type = this.slTpValidity(take_profit)
      if (!type) {
        showSnackbar(VALID_TYPE_ERROR.tpLimit)
        return
      }
    }
    if(trailing_sl) {
      const { error, errorMsg } = tslValidator(tpsl_type, trailing_sl)
      if(error) {
        showSnackbar(errorMsg, 'error')
        return
      }
    }
    this.props.onEditParams(this.getFormatedState(), updateStep)
    // true to change step or run backtest
  }

  toggleRenkoSelection = () => {
    this.setState(prevState => ({ showRenkoSelection: !prevState.showRenkoSelection }))
  }

  blockDtStart = (current) => {
    const { dt_stop } = this.state
    const maxStartTime = moment(dt_stop).clone().subtract(7, 'day')
    return !current.isAfter(maxStartTime)
  }

  blockDtStop = (current) => {
    const { dt_start } = this.state
    const maxStopTime = moment(dt_start).clone().add(7, 'day')
    return !current.isBefore(maxStopTime) && !current.isAfter()
  }

  onSltpTypeChange = (value, obj, field) => {
    const {
      position_sizing_type,
      trailing_sl,
      take_profit,
      stop_loss,
      // tpsl_type,
    } = this.state
    let changes = {}

    if (field === 'tpsl_type' && value === 'abs'
      && position_sizing_type === POSITION_SIZING_TYPE.RISK_BASED) {
      changes = { ...changes, position_sizing_type: '-' }
    }
    if (position_sizing_type === POSITION_SIZING_TYPE.RISK_BASED && field === 'tpsl_type' && value === TPSL_TYPE.ABSOLUTE.value) {
      showSnackbar(`Cannot select TPSL type as ${TPSL_TYPE.ABSOLUTE.value} with ${POSITION_SIZING_TYPE.RISK_BASED} position sizing`)
      return
    }
    if(trailing_sl) {
      const { error, errorMsg } = tslValidator(value, trailing_sl)
      if(error) {
        showSnackbar(errorMsg, 'error')
        return
      }
    }
    if(take_profit) {
      const { error, errorMsg } = tpslValidator(take_profit, 'target profit')
      if(error) {
        showSnackbar(errorMsg, 'error')
        return
      }
    }
    if(stop_loss) {
      const { error, errorMsg } = tpslValidator(stop_loss, 'stop loss')
      if(error) {
        showSnackbar(errorMsg, 'error')
        return
      }
    }
    this.showBacktestBtn = true
    this.setState({ [field]: value, ...changes })
  }

  onTpChange = (e, inputKey) => {
    const { value } = e.target
    // const { tpsl_type } = this.state
    let err = true
    if (Number(value.trim()) && (qtyRegex.test(value) || decimalRegex.test(value)
    || Number(value) >= TPSL_LIMIT)) {
      err = false
      const { error, errorMsg } = tpslValidator(value, 'target progit')
      if(error) {
        err = true
        showSnackbar(errorMsg, 'error')
      }
    }
    this.showBacktestBtn = true
    this.setState(
      { take_profit: value, [`${inputKey}Err`]: err },
    )
  }

  handleNumericChangeforTsl = (e, inputKey) => {
    const { value } = e.target
    const { tpsl_type } = this.state
    const { user_details, toggleAuthGreeting } = this.props
    const {
      subscription_type,
    } = user_details
    let trailing_slErr = false
    if(subscription_type !== 3) {
      toggleAuthGreeting(PRO_FEATURE_MAP.TRAILING_SL.ID)
      return
    }
    if (this.numRegx.test(value) || this.decimalRegx.test(value)) {
      const { error, errorMsg } = tslValidator(tpsl_type, value)
      if(error) {
        trailing_slErr = error
        showSnackbar(errorMsg, 'error')
      }
    }
    this.showBacktestBtn = true
    this.setState({
      trailing_sl: value,
      [`${inputKey}Err`]: trailing_slErr,
    })
  }

  onSlChange = (e, inputKey) => {
    const { value } = e.target
    let err = true
    if (Number(value.trim()) && (qtyRegex.test(value) || decimalRegex.test(value)
      || Number(value) >= TPSL_LIMIT)) {
      err = false
      const { error, errorMsg } = tpslValidator(value, 'stop loss')
      if(error) {
        err = true
        showSnackbar(errorMsg, 'error')
      }
    }
    this.showBacktestBtn = true
    this.setState({
      stop_loss: value, [`${inputKey}Err`]: err,
    })
  }

  togglePositions = (position_type) => {
    this.showBacktestBtn = true
    this.setState({ position_type })
    setEditGAParams({ position_type: ['Position toggle', position_type, 1, this.props.gaCategory] })
  }

  render() {
    const { children } = this.props
    const { showRenkoSelection, chart_type, chart_list } = this.state
    let renkoDetails = {}
    if (showRenkoSelection && chart_type) {
      const matches = chart_type.match(/[^()]+(?=\))/i)
      renkoDetails = { [renkoParams.params[0][0]]: matches ? matches[0] : '1' }
    }
    return (
      <>
        <CustomModal
          visible={showRenkoSelection}
          onDismiss={this.toggleRenkoSelection}
        >
          <IndicatorDetails
            indicator={renkoParams}
            indicatorDetails={renkoDetails}
            saveDetails={this.saveDetails}
            dismissModal={this.toggleRenkoSelection}
            candleIntervalIndex={10}
            showRemove={false}
          />
        </CustomModal>
        {children({
          ...this.state,
          strategyCycleMap: DAILY_STRATEGY_CYCLE,
          chart_list_map: chart_list,
          holding_type_map: this.holding_type,
          candleIntervals: this.candleIntervals,
          showBacktestBtn: this.showBacktestBtn,
          postionBtnMap,
          tpslTypes: TPSL_TYPES,
          toggleRenkoSelection: this.toggleRenkoSelection,
          handleChange: this.handleChange,
          handleCandleChange: this.handleCandleChange,
          setTradingTime: this.setTradingTime,
          setSelectedDateTime: this.setSelectedDateTime,
          chartChangeHandler: this.chartChangeHandler,
          blockDtStart: this.blockDtStart,
          blockDtStop: this.blockDtStop,
          onInputChange: this.onInputChange,
          onEditParams: this.onEditParams,

          togglePositions: this.togglePositions,
          onSltpTypeChange: this.onSltpTypeChange,
          handleNumericChangeforTsl: this.handleNumericChangeforTsl,
          onSlChange: this.onSlChange,
          onTpChange: this.onTpChange,
        })}
      </>
    )
  }
}

export default EditAlgoParams
