import React from 'react'

import PlaceHolderLoader from '../../PlaceHolderLoader'

const CummulativePnlLoader = ({
  styles, isMobile,
}) => {
  const renderCell = () => {
    return (
      <div className={`${styles.cell} ${styles.cellLoader}`} style={{ width: isMobile ? '50%' : 180, height: 58 }}>
        <PlaceHolderLoader className={styles.labelLoader} />
        <PlaceHolderLoader className={styles.valueLoader} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <PlaceHolderLoader style={{ maxWidth: 180, height: 16 }} />
        </div>
        <div className={styles.headerRight}>
          <PlaceHolderLoader style={{ maxWidth: 140, height: 16 }} />
          <PlaceHolderLoader style={{ maxWidth: 120, height: 16 }} />
        </div>
      </div>
      <div className={styles.content}>
        {renderCell()}
        {renderCell()}
        {renderCell()}
        {renderCell()}
        {renderCell()}
        {renderCell()}
      </div>
    </div>
  )
}

export default CummulativePnlLoader
