import { ICONS } from '../../Theme'
import { NAVIGATIONS, pathname_mapping } from '../../utils/navigationConstant'

const {
  REWARDS,
  BILLING,
  PRICING,
  PROFILE,
  EDIT,
  SETTING,
  // HELP,
  HELP_CIRCLE,
  FEEDBACK,
  LOGOUT,
  SHAREABLE_LINKS,
} = ICONS

export const PROFILE_MENU_MAP = {
  referrals: {
    title: 'Referral & Rewards',
    page: pathname_mapping.Account,
    subTitle: 'Invites, History',
    iconName: REWARDS,
    tab: 'referrals',
    extraProps: { routeTo: NAVIGATIONS.REFERRAL.name },
  },
  rewards: {
    title: 'Referral & Rewards',
    page: pathname_mapping.Account,
    subTitle: 'Invites, History',
    iconName: REWARDS,
    tab: 'referrals',
    extraProps: { routeTo: NAVIGATIONS.REWARDS.name },
  },
  billing: {
    title: 'Billing',
    page: pathname_mapping.Account,
    subTitle: 'Current Plan, Invoices',
    iconName: BILLING,
    tab: 'billing',
    extraProps: { routeTo: NAVIGATIONS.BILLING.name },
  },
  pricing: {
    title: 'Pricing',
    page: pathname_mapping.Account,
    subTitle: 'See all different plans available',
    iconName: PRICING,
    extraProps: { routeTo: NAVIGATIONS.PRICING.name },
    tab: 'pricing',
  },
  profile: {
    title: 'My Profile',
    page: pathname_mapping.Account,
    subTitle: 'Profile details',
    iconName: PROFILE,
    extraProps: { routeTo: NAVIGATIONS.MY_PROFILE.name },
    tab: 'profile',
  },
  accounts: {
    title: 'My Trading Accounts',
    page: pathname_mapping.Account,
    subTitle: 'Add a broker account',
    iconName: EDIT,
    extraProps: { routeTo: NAVIGATIONS.MY_ACCOUNT.name },
    tab: 'accounts',
  },
  preferences: {
    title: 'Preferences',
    page: pathname_mapping.Account,
    subTitle: 'Dark mode, Notifications, Animations, Global Assist',
    iconName: SETTING,
    extraProps: { routeTo: NAVIGATIONS.PREFERENCE.name },
    tab: 'preferences',
  },
  shareable_links: {
    title: 'Shareable Links',
    page: pathname_mapping.Account,
    subTitle: 'Strategy & Scanner links',
    iconName: SHAREABLE_LINKS,
    extraProps: { routeTo: NAVIGATIONS.SHAREABLE_LINKS.name },
    tab: 'shareable_links',
  },
  // TODO: change text to 'Videos, Manual, Feedback' when all are added
  help: {
    title: 'Help',
    page: pathname_mapping.Account,
    subTitle: 'Read manual',
    iconName: HELP_CIRCLE,
    extraProps: { routeTo: NAVIGATIONS.HELP.name },
    tab: 'help',
  },
  feedback: {
    title: 'Feedback',
    page: 'feedbackModal',
    subTitle: 'Have suggestions? Submit here',
    iconName: FEEDBACK,
    tab: '',
  },
  logout: {
    title: 'Logout',
    page: pathname_mapping.Auth,
    subTitle: 'Logout of current session',
    iconName: LOGOUT,
  },
}

export const PROFILE_MENU_ITEMS = [
  PROFILE_MENU_MAP.profile, PROFILE_MENU_MAP.billing, // PROFILE_MENU_MAP.pricing,
  PROFILE_MENU_MAP.accounts,
  // PROFILE_MENU_MAP.referrals,
  PROFILE_MENU_MAP.shareable_links,
  PROFILE_MENU_MAP.preferences,
  PROFILE_MENU_MAP.help, PROFILE_MENU_MAP.feedback, PROFILE_MENU_MAP.logout,
]

export const PROFILE_MENU_TABS = Object.keys(PROFILE_MENU_MAP)

// value and key name shud be same
export const THEME_TYPE_MAP = {
  DARK: {
    label: 'Dark', value: 'DARK', icon: ICONS.MOON_CRESCENT_FILLED, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  LIGHT: {
    label: 'Light', value: 'LIGHT', icon: ICONS.SUN, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  AUTO: {
    label: 'Auto', value: 'AUTO', icon: ICONS.MOON_CRESCENT_OUTLINED_FILLED, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
}

export const PREFERENCES = {
  NOTIF_SILENT: 'silent',
  NOTIF_POPUP: 'popUp',
  MTF_WARNING: 'mtfWarning',
  SHOW_ANIMATION: 'animations',
  THEME: 'theme',
  KEY: 'preferences',
  SITE_CONFIG: 'websiteConfig',
  GLOBAL_ASSIST: 'globalAssist',
  DETAIL_VIEW: 'detailView',
  SINGLE_PAGE_MODE: 'singlePageMode',
  NUDGE: 'nudge',
  OPTIONS_FORMAT: 'optionFormat',
}
