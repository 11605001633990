import {
  GET_PAYMENT_INFO_INIT,
  GET_PAYMENT_INFO_SUCCESS,
  GET_PAYMENT_INFO_FAILURE,
} from './actionTypes'

export function getPaymentInfo(params, headers) {
  return {
    type: GET_PAYMENT_INFO_INIT,
    headers,
    params,
  }
}

export function getPaymentInfoSuccess(data) {
  return {
    type: GET_PAYMENT_INFO_SUCCESS,
    data,
  }
}

export function getPaymentInfoFailure(error) {
  return {
    type: GET_PAYMENT_INFO_FAILURE,
    error,
  }
}
