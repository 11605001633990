import {
  FETCH_BACKTEST_INIT,
  FETCH_BACKTEST_SUCCESS,
  FETCH_BACKTEST_FAILURE,
  CHECK_SUBSCRIPTION_LIMIT,
  CHECK_SUBSCRIPTION_LIMIT_SUCCESS,
  LIMIT_EXCEEDED,
  DEPLOY_STOCKS_INIT,
  DEPLOY_STOCKS_SUCCESS,
  DEPLOY_STOCKS_FAILURE,
  BACKTEST_RESULT_SUCCESS,
  BACKTEST_RESULT_FAILURE,
  CREATE_EQUITIES_ENTRY,
  CLEAR_BACKTEST_RESPONSE,
  ONGOING_BACKTESTS,
  GENERATE_SHARE_LINK,
  GENERATE_SHARE_LINK_SUCCESS,
  GENERATE_SHARE_LINK_FAILURE,
  FETCH_SHARE_LINK,
  FETCH_SHARE_LINK_SUCCESS,
  FETCH_SHARE_LINK_FAILURE,
  UPDATE_BACKTEST_SCRIPS,
  FETCH_BACKTEST_DETAILS,
  FETCH_BACKTEST_DETAILS_SUCCESS,
  FETCH_BACKTEST_DETAILS_FAILURE,
  REFRESH_BACKTEST,
  RUN_BACKTEST,
  SAVE_BACKTEST_PARAMS,
  SAVE_BACKTEST_PARAMS_SUCCESS,
  SAVE_BACKTEST_PARAMS_FAILURE,
  UPDATE_BACKTEST_PARAMS,
  EDIT_SUBSCRIBED_ALGO,
  FETCH_BACKTEST_ALL,
  FETCH_BACKTEST_ALL_SUCCESS,
  FETCH_BACKTEST_ALL_FAILURE,
  CHANGE_WASM_STATUS,
  FETCH_BACKTEST_DETAILS_DYC,
  FETCH_BACKTEST_DETAILS_DYC_SUCCESS,
  FETCH_BACKTEST_DETAILS_DYC_FAILURE,
  UPDATE_BT_SCRIPSTACK,
} from './actionTypes'

export function createEmptyPairEntries(equities, backtest_results, algo_obj, scripList) {
  return {
    type: CREATE_EQUITIES_ENTRY,
    equities,
    backtest_results,
    algo_obj,
    scripList,
  }
}

export function fetchBacktestResults(params) {
  return {
    type: FETCH_BACKTEST_INIT,
    params,
  }
}

export function backtestResultSuccess(backtests) {
  return {
    type: FETCH_BACKTEST_SUCCESS,
    data: backtests,
  }
}

export function backtestResultFailure(err) {
  return {
    type: FETCH_BACKTEST_FAILURE,
    errorMsg: err,
  }
}

export function updateBacktest(scripList, algo_obj, headers, marketAlgo, extraParams) {
  return {
    type: RUN_BACKTEST,
    payload: {
      ...extraParams,
      scripList,
      algo_obj,
      headers,
      marketAlgo,
      editParams: true,
    },
  }
}

export function backtestSuccess(algo_obj, data, seg_sym) {
  return {
    type: BACKTEST_RESULT_SUCCESS,
    algo_obj,
    data,
    seg_sym,
  }
}

export function backtestFailure(error, seg_sym) {
  return {
    type: BACKTEST_RESULT_FAILURE,
    error,
    seg_sym,
  }
}

export function getLimitInit() {
  return {
    type: CHECK_SUBSCRIPTION_LIMIT,
  }
}

export function getLimitSuccess(data) {
  return {
    type: CHECK_SUBSCRIPTION_LIMIT_SUCCESS,
    data,
  }
}

export function limitExceeded() {
  return {
    type: LIMIT_EXCEEDED,
  }
}

export function deployAlgoStocks(params, headers) {
  return {
    type: DEPLOY_STOCKS_INIT,
    params,
    headers,
  }
}

export function deploySuccess(data) {
  return {
    type: DEPLOY_STOCKS_SUCCESS,
    data,
  }
}

export function deployFailure(error) {
  return {
    type: DEPLOY_STOCKS_FAILURE,
    error,
  }
}

export function clearResp() {
  return {
    type: CLEAR_BACKTEST_RESPONSE,
  }
}

export function onGoingBacktests(isRunning, algoId) {
  return {
    type: ONGOING_BACKTESTS,
    isRunning,
    algoId,
    // algoName,
  }
}

export function generateShareLink(params, headers) {
  return {
    type: GENERATE_SHARE_LINK,
    params,
    headers,
  }
}

export function generateShareLinkSuccess(data) {
  return {
    type: GENERATE_SHARE_LINK_SUCCESS,
    data,
  }
}

export function generateShareLinkFailure(error) {
  return {
    type: GENERATE_SHARE_LINK_FAILURE,
    error,
  }
}

export function fetchShareLink(params, headers) {
  return {
    type: FETCH_SHARE_LINK,
    params,
    headers,
  }
}

export function fetchShareLinkSuccess(data) {
  return {
    type: FETCH_SHARE_LINK_SUCCESS,
    data,
  }
}

export function fetchShareLinkFailure(error) {
  return {
    type: FETCH_SHARE_LINK_FAILURE,
    error,
  }
}

export function updateBacktestScrips(list, extraParams) {
  return {
    type: UPDATE_BACKTEST_SCRIPS,
    list,
    extraParams,
  }
}

export function fetchBacktestDetails(params) {
  return {
    type: FETCH_BACKTEST_DETAILS,
    params,
  }
}

export function fetchBacktestDetailsSuccess(data, params) {
  // todo, chart
  // console.log('todo 2')
  return {
    type: FETCH_BACKTEST_DETAILS_SUCCESS,
    data,
    params,
  }
}

export function fetchBacktestDetailsFailure(error, params = {}) {
  return {
    type: FETCH_BACKTEST_DETAILS_FAILURE,
    error,
    params,
  }
}

export function fetchBacktestDetailsDyc(params) {
  return {
    type: FETCH_BACKTEST_DETAILS_DYC,
    params,
  }
}

export function fetchBacktestDetailsDycSuccess(data, algo_obj, params) {
  return {
    type: FETCH_BACKTEST_DETAILS_DYC_SUCCESS,
    data,
    params,
    algo_obj,
  }
}

export function fetchBacktestDetailsDycFailure(error, seg_sym, runtime) {
  return {
    type: FETCH_BACKTEST_DETAILS_DYC_FAILURE,
    error,
    seg_sym,
    runtime,
  }
}

export function refreshBacktest(flag) {
  return {
    type: REFRESH_BACKTEST,
    flag,
  }
}

export function saveBacktestParams(params) {
  return {
    type: SAVE_BACKTEST_PARAMS,
    params,
  }
}

export function saveBacktestParamsSuccess(data) {
  return {
    type: SAVE_BACKTEST_PARAMS_SUCCESS,
    data,
  }
}

export function saveBacktestParamsFailure(error) {
  return {
    type: SAVE_BACKTEST_PARAMS_FAILURE,
    error,
  }
}

export function updateBacktestParams(params, paramsEdited) {
  return {
    type: UPDATE_BACKTEST_PARAMS,
    params,
    paramsEdited,
  }
}

export function editSubscribedAlgo() {
  return {
    type: EDIT_SUBSCRIBED_ALGO,
  }
}

export function fetchBacktestAll(params) {
  return {
    type: FETCH_BACKTEST_ALL,
    params,
  }
}

export function fetchBacktestAllSuccess(data) {
  // todo
  // console.log('todo 3')
  return {
    type: FETCH_BACKTEST_ALL_SUCCESS,
    data,
  }
}

export function fetchBacktestAllFailure(error) {
  return {
    type: FETCH_BACKTEST_ALL_FAILURE,
    error,
  }
}

export const changeWasmStatus = (status, load) => {
  return {
    type: CHANGE_WASM_STATUS,
    status,
    load,
  }
}

export const updateBtScripStack = (scripList, updateType) => {
  return {
    type: UPDATE_BT_SCRIPSTACK,
    scripList,
    updateType,
  }
}
