import {
  CLEAR_VERSION,
  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CLEAR_LOGIN_RESP,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_INIT,
  CLEAR_USER_DETAILS,
  LOGOUT_INIT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  BROKER_LOGIN,
  FETCH_VERSION,
  FETCH_VERSION_SUCCESS,
  FETCH_VERSION_FAILURE,
  UPDATE_VERSION,
  UPDATE_VERSION_SUCCESS,
  UPDATE_VERSION_FAILURE,
  VALIDATE_SESSION,
  VALIDATE_SESSION_SUCCESS,
  VALIDATE_SESSION_FAILURE,
  SET_TOKEN,
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  EMAIL_LOGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_INIT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_INIT,
  SWTICH_BROKER_FAILURE,
  ACCOUNT_ACTIVATE_INIT,
  ACCOUNT_ACTIVATE_SUCCESS,
  ACCOUNT_ACTIVATE_FAILURE,
  UPDATE_PARTNER_REF,
  UPDATE_PARTNER_REF_SUCCESS,
  UPDATE_PARTNER_REF_FAILURE,
  UPDATE_ONBRDING_ACTIVITY,
  UPDATE_ONBRDING_ACTIVITY_SUCCESS,
  UPDATE_ONBRDING_ACTIVITY_FAILURE,
  SHOW_LOGIN_MODAL,
  GET_ALL_REWARDS_INIT,
  GET_ALL_REWARDS_SUCCESS,
  GET_ALL_REWARDS_FAILURE,
  AWAIL_REWARD_INIT,
  AWAIL_REWARD_SUCCESS,
  AWAIL_REWARD_FAILURE,
  UPDATE_FANCY_BROKER,
  SAVE_LEAD,
  SAVE_LEAD_SUCCESS,
  SAVE_LEAD_FAILURE,
  SHOW_DEEPLINK_MODAL,
} from './actionTypes'

export function fetchVersion(headers) {
  return {
    type: FETCH_VERSION,
    headers,
  }
}

export function fetchVersionSuccess(data) {
  return {
    type: FETCH_VERSION_SUCCESS,
    data,
  }
}

export function fetchVersionFailure(error) {
  return {
    type: FETCH_VERSION_FAILURE,
    error,
  }
}

export function clearVersion() {
  return {
    type: CLEAR_VERSION,
  }
}

export function loginUser(params, headers, type) {
  return {
    type: LOGIN_INIT,
    params,
    headers,
    actionType: type,
  }
}

export function loginUserSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    data,
  }
}

export function updatePartnerRef(params, headers) {
  return {
    type: UPDATE_PARTNER_REF,
    payload: { headers, params },
  }
}

export function loginUserFailure(error, status_code) {
  return {
    type: LOGIN_FAILURE,
    error,
    status_code,
  }
}

export function switchBrokerFailure(params) {
  return {
    type: SWTICH_BROKER_FAILURE,
    params,
  }
}

export function getUserDetails(params) {
  return {
    type: GET_USER_DETAILS_INIT,
    params,
  }
}

export function getUserDetailsSuccess(data) {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    data,
  }
}

export function getUserDetailsFailure(err) {
  return {
    type: GET_USER_DETAILS_FAILURE,
    err,
  }
}

export function clearUserDetails() {
  return {
    type: CLEAR_USER_DETAILS,
  }
}

export function clearLoginResp() {
  return {
    type: CLEAR_LOGIN_RESP,
  }
}

export function clearUserAndLoginResp() {
  return (dispatch) => {
    dispatch(clearLoginResp())
  }
}

export function logout() {
  return {
    type: LOGOUT_INIT,
  }
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  }
}

export function logoutFailure(error) {
  return {
    type: LOGOUT_FAILURE,
    error,
  }
}

export function brokerLogin(params, brokerName, headers) {
  return {
    type: BROKER_LOGIN,
    params,
    brokerName,
    headers,
  }
}

export function updateVersion(params, headers) {
  return {
    type: UPDATE_VERSION,
    params,
    headers,
  }
}

export function updateVersionSuccess(version) {
  return {
    type: UPDATE_VERSION_SUCCESS,
    version,
  }
}

export function updateVersionFailure(error) {
  return {
    type: UPDATE_VERSION_FAILURE,
    error,
  }
}

export function validateSession(params, headers) {
  return {
    type: VALIDATE_SESSION,
    params,
    headers,
  }
}

export function validateSessionSuccess(data) {
  return {
    type: VALIDATE_SESSION_SUCCESS,
    data,
  }
}

export function validateSessionFailure(error) {
  return {
    type: VALIDATE_SESSION_FAILURE,
    error,
  }
}

export function setToken(params) {
  return {
    type: SET_TOKEN,
    data: params,
  }
}

export function validateEmailSuccess(data) {
  return {
    type: VALIDATE_EMAIL_SUCCESS,
    data,
  }
}

export function validateEmailFailure(error) {
  return {
    type: VALIDATE_EMAIL_FAILURE,
    error,
  }
}

export function validateEmail(params) {
  return {
    type: VALIDATE_EMAIL,
    params,
  }
}

export function emailLogin(params) {
  return {
    type: EMAIL_LOGIN,
    params,
  }
}

export function resetPasswordSuccess(data) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    data,
  }
}

export function resetPasswordFailure(error) {
  return {
    type: RESET_PASSWORD_FAILURE,
    error,
  }
}

export function resetPassword(params, headers) {
  return {
    type: RESET_PASSWORD_INIT,
    params,
    headers,
  }
}

export function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    data,
  }
}

export function forgotPasswordFailure(error) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    error,
  }
}

export function forgotPassword(params, headers) {
  return {
    type: FORGOT_PASSWORD_INIT,
    params,
    headers,
  }
}

export function accountActivation(params) {
  return {
    type: ACCOUNT_ACTIVATE_INIT,
    params,
  }
}

export function accountActivationSuccess(data) {
  return {
    type: ACCOUNT_ACTIVATE_SUCCESS,
    data,
  }
}

export function accountActivationFailure(error) {
  return {
    type: ACCOUNT_ACTIVATE_FAILURE,
    error,
  }
}

export function updatePartnerRefSuccess() {
  return {
    type: UPDATE_PARTNER_REF_SUCCESS,
  }
}

export function updatePartnerRefFailure() {
  return {
    type: UPDATE_PARTNER_REF_FAILURE,
  }
}

export function updateOnboardingActivity(params) {
  return {
    type: UPDATE_ONBRDING_ACTIVITY,
    params,
  }
}

export function updateOnboardingActivitySuccess(data) {
  return {
    type: UPDATE_ONBRDING_ACTIVITY_SUCCESS,
    data,
  }
}

export function updateOnboardingActivityFailure(error) {
  return {
    type: UPDATE_ONBRDING_ACTIVITY_FAILURE,
    error,
  }
}

export function toggleLoginModal(state, postLoginParams = false, loginShared) {
  return {
    type: SHOW_LOGIN_MODAL,
    state,
    postLoginParams,
    loginShared,
  }
}

export function toggleDeeplinkModal(state, params) {
  return {
    type: SHOW_DEEPLINK_MODAL,
    state,
    params,
  }
}

export function getRewardsInit() {
  return {
    type: GET_ALL_REWARDS_INIT,
  }
}

export function getRewardsSuccess(data) {
  return {
    type: GET_ALL_REWARDS_SUCCESS,
    data,
  }
}

export function getRewardsFailure(error) {
  return {
    type: GET_ALL_REWARDS_FAILURE,
    error,
  }
}

export function awailRewardInit(params) {
  return {
    type: AWAIL_REWARD_INIT,
    params,
  }
}

export function awailRewardSuccess(data) {
  return {
    type: AWAIL_REWARD_SUCCESS,
    data,
  }
}

export function awailRewardFailure(error) {
  return {
    type: AWAIL_REWARD_FAILURE,
    error,
  }
}

export function updateFancyBroker(brokerObj) {
  return {
    type: UPDATE_FANCY_BROKER,
    brokerObj,
  }
}

export const saveLead = (params) => {
  return {
    type: SAVE_LEAD,
    params,
  }
}

export const saveLeadSuccess = (data) => {
  return {
    type: SAVE_LEAD_SUCCESS,
    data,
  }
}

export const saveLeadFailure = (error) => {
  return {
    type: SAVE_LEAD_FAILURE,
    error,
  }
}
