/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import makeStyles from '@material-ui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import CustomText from '../../UI/CustomText'
import Button from '../../UI/Button'
import CustomInput from '../../UI/CustomInput'
import Icon from '../../UI/Icon'

import { emailRegex, RECAPTCHKEY } from '../../utils/consts'
import { showSnackbar } from '../../UI/Snackbar'
import { forgotPassword } from '../../containers/Auth/actions'
import { pathname_mapping } from '../../utils/navigationConstant'
import { ICONS, SPACING, theme } from '../../Theme'

const ForgotPassword = React.memo(({
  isRequesting, requestResp, requestError, requestErrorMsg, onForgotPassword,
  containerStyles, showBackBtn, onBackPress,
}) => {
  const styles = stylesheet()
  const [emailObj, updateEmail] = useState({ email: '', emailErr: '' })
  useEffect(() => {
    if (requestResp && requestResp.status === 'success') {
      showSnackbar('Reset password link successfully sent', {}, 1)
      updateEmail({ email: '', emailErr: false })
    } else if (requestError) {
      showSnackbar(requestErrorMsg || 'Some error occurred', {}, 0)
    }
  }, [isRequesting])
  const { email, emailErr } = emailObj
  const onChangeText = (e) => {
    updateEmail({ email: e.target.value, emailErr: false })
  }

  const onKeyDown = (ev, field) => {
    const { key } = ev
    if (key === 'Enter' && field === 'email') {
      forgotPwdHandler()
    }
  }

  const forgotPwdHandler = () => {
    if (isRequesting) return

    if (!emailRegex.test(String(email).toLowerCase())) {
      showSnackbar('Please enter valid email')
      updateEmail({ emailErr: true })
    } else {
      const params = {
        email,
        token: RECAPTCHKEY,

      }
      onForgotPassword(params)
    }
  }

  return (
    <div className={`${styles.container} ${containerStyles}`}>
      <div className={styles.header}>
        {showBackBtn && (
          <button
            type="button"
            onClick={onBackPress}
            className={styles.backIconBtn}
          >
            <Icon name={ICONS.BACK} size={10} color={theme.text} />
          </button>
        )}
        <CustomText size="medium_1" weight="medium" center={!showBackBtn}>Reset Password</CustomText>
      </div>
      <CustomText size="small" center={!showBackBtn} style={{ marginTop: SPACING.SPACE_12 }}>
        Enter the Email associated with your account and we’ll send an email with instructions to reset your password.
      </CustomText>
      <CustomInput
        // label="Email"
        inputError={emailErr}
        onChangeText={onChangeText}
        inputKey="email"
        value={email}
        placeholder="Enter you email address"
        inputStyles={styles.fieldInput}
        containerStyles={styles.emailInputContainer}
        wrapperStyles={styles.inputWrapper}
        disabled={isRequesting}
        inputProps={{
          onKeyDown: ev => onKeyDown(ev, 'email'),
        }}
      />
      <Button
        text="Send reset link"
        buttonColor="blue"
        btnStyles={styles.resetBtn}
        disabled={isRequesting}
        loading={isRequesting}
        onPress={forgotPwdHandler}
      />
      <div className={styles.noAccount}>
        <CustomText>
          {'Don\'t have an account? '}
          <a href={pathname_mapping.Auth}>Sign up</a>
        </CustomText>
      </div>
    </div>
  )
})

const stylesheet = makeStyles({
  noAccount: {},
  container: {},
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  emailInputContainer: {
    marginTop: SPACING.SPACE_20,
  },
  resetBtn: {
    margin: `${SPACING.SPACE_20} 0`,
    width: '100%',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
  backIconBtn: {
    marginRight: SPACING.SPACE_10,
  },
})

const mapStateToProps = (state) => {
  return {
    isRequesting: state.auth.isRequesting,
    requestResp: state.auth.requestResp,
    requestError: state.auth.requestError,
    requestErrorMsg: state.auth.requestErrorMsg,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // clearSignupResp: () => dispatch(clearSignupResp()),
    onForgotPassword: (params, headers) => dispatch(forgotPassword(params, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
