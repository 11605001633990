import axios from 'axios'
import {
  getEncodedParams, parseQuery, updateQueryParamsSessExp,
} from './common'
import { baseUrl } from './apiEndpoints'

export const ApiRequest = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-type': 'application/x-www-form-urlencoded',
  },
  timeout: 45000,
})

export const getRequest = async (
  url, params, headers = {}, extraParams = {}, sendType, timeout,
) => {
  let queryParams = ''
  if (params && Object.keys(params).length) {
    queryParams = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&')
    queryParams = `?${queryParams}`
  }
  const modHeaders = { ...headers } // 'Accept-Encoding': 'gzip'
  const final_url = `${url}${queryParams}`
  const data = await ApiRequest.get(final_url,
    { headers: modHeaders, timeout: timeout || 45000, ...extraParams })
    .then((resp) => {
      // console.log('get', resp, url, params)
      if (sendType) {
        return {
          data: resp.data,
          contentType: resp.headers['content-type'],
        }
      }
      return resp.data
    })
    .catch((err) => {
      // console.log('get', err, url, params, headers)
      // debugger
      let error_msg = 'Error fetching data'
      const err_code = err.response && err.response.status
      if (err.response && err.response.status === 401) {
        error_msg = 'Unauthorized'
      } else if (err.response && err.response.status === 500) {
        error_msg = 'Internal server error, try again later'
      } else if (err.response) {
        error_msg = err.response.data.data || error_msg
      } else if (err.request) {
        error_msg = 'Error getting response'
      }
      return {
        error: true,
        error_msg,
        err_code,
      }
    })
  const { session } = parseQuery(window.location.search)
  if((data.status === 'error' || data.status === 401)
    && (data.error === 'auth' || data.error_msg?.toLowerCase() === 'invalid auth'
    || data.error_msg?.toLowerCase() === 'session expired, re-login required'
    || data['error-type']?.toLowerCase() === 'order needs authorisation at depository, please visit broker trading terminal to place the order')
    && session !== 'expired'
    && !data.error_msg.includes('unexpected end of JSON input')) {
    updateQueryParamsSessExp()
  }
  // debugger
  return data
}

export const postRequest = async (url, params, modHeaders, encode = true, timeout) => {
  const transformedData = encode ? getEncodedParams(params) : params
  const data = await ApiRequest.post(url, transformedData, {
    headers: modHeaders,
    timeout: timeout || 45000,
  })
    .then((resp) => {
      // console.log(resp, url, params)
      // debugger
      return resp.data
    })
    .catch((err) => {
      // debugger
      // console.log('post err', err, url, params, modHeaders)
      // debugger
      let error_msg = 'Error getting response'
      const err_code = err.response && err.response.status
      if (err.response && err.response.status === 401) {
        error_msg = 'Unauthorized'
      } else if (err.response) {
        ({ error_msg = '' } = err.response.data)
        if (err.status === 500) {
          error_msg = 'Error connecting to server'
        }
      } else if (err.request) {
        error_msg = 'Error getting response'
      }
      return {
        error: true,
        error_msg,
        err_code,
      }
    })
  const { session } = parseQuery(window.location.search)
  if((data.status === 'error' || data.status === 401)
      && (data.error === 'auth' || data.error_msg?.toLowerCase() === 'invalid auth'
      || data.error_msg?.toLowerCase() === 'session expired, re-login required'
      || data['error-type']?.toLowerCase() === 'order needs authorisation at depository, please cvisit broker trading terminal to place the order')
      && session !== 'expired'
      && !data.error_msg.includes('unexpected end of JSON input')
  ) {
  // if(url === 'https://api.streak.ninja/update_additional_info/') {
    updateQueryParamsSessExp()
  }
  return data
}

export const deleteRequest = async (url, params, headers, encode = true) => {
  const data = await axios
    .delete(url, { data: encode ? getEncodedParams(params) : params, headers, timeout: 30000 })
    .then(resp => resp.data)
    .catch((err) => {
      let error_msg = 'Error getting response'
      if (err.response) {
        error_msg = err.response.data || 'Error getting response'
      } else if (err.request) {
        error_msg = 'Error getting response'
      }
      return {
        error: true,
        error_msg,
      }
    })
  return data
}
