/* eslint-disable import/no-cycle */
import isEmpty from 'lodash/isEmpty'
import {
  takeLatest, put, call, takeEvery, delay,
} from 'redux-saga/effects'
import dayjs from 'dayjs'
import {
  prodBtService,
  prodEndpoints,
  prodBtFastService,
  baseUrl, shareUrl,
} from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  backtestResultSuccess,
  backtestResultFailure,
  createEmptyPairEntries,
  backtestSuccess,
  backtestFailure,
  onGoingBacktests,
  deploySuccess,
  deployFailure,
  fetchBacktestDetailsSuccess,
  fetchBacktestDetailsFailure,
  generateShareLinkSuccess,
  generateShareLinkFailure,
  fetchShareLinkSuccess,
  fetchShareLinkFailure,
  saveBacktestParamsSuccess,
  saveBacktestParamsFailure,
  fetchBacktestAllSuccess,
  fetchBacktestAllFailure,
  fetchBacktestDetailsDycFailure,
  fetchBacktestDetailsDycSuccess,
} from './actions'
import {
  FETCH_BACKTEST_INIT,
  RUN_BACKTEST,
  DEPLOY_STOCKS_INIT,
  FETCH_SHARE_LINK,
  FETCH_BACKTEST_DETAILS,
  GENERATE_SHARE_LINK,
  SAVE_BACKTEST_PARAMS,
  FETCH_BACKTEST_ALL,
  FETCH_BACKTEST_DETAILS_DYC,
  UPDATE_BT_SCRIPSTACK,
} from './actionTypes'
import { toggleAuthGreeting } from '../Common/actions'
import { GET_USER_DETAILS_INIT } from '../Auth/actionTypes'
import { cleanAlgoObj, error_msg } from '../../utils/common'
import { getInstruments, fetchBackTestInstruments } from '../Instruments/actions'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import { PRO_FEATURE_MAP } from '../../utils/userAccessChecks'
import { showSnackbar } from '../../UI/Snackbar'
// eslint-disable-next-line import/no-cycle
import store from '../../store'

function* fetchBacktestResults(action) {
  try {
    const {
      algo_uuid,
      publishing_uuid,
      algo_subscription_uuid,
      headers,
      runBacktest: shouldRunBacktest,
      scripList,
      algo_obj,
      sample,
      getAllData,
      subscription_type, //  DANGER,
      // createAdvance, // DANGER
      algo_share_uuid,
      sbt,
      // createAdvance, // DANGER
      stocks,
      onboard,
      canRunGoBt = false,
      csrf,
      sessionid,
      max_count,
    } = action.params
    // let endPoint = 'backtest'
    // if (fetchType === 'sample') {
    //   endPoint = 'sample_backtest'
    // } else if (fetchType === 'discover') {
    //   endPoint = 'marketpace_backtest'
    // }
    let url = `${baseUrl}backtest/?resp=json`
    if (algo_uuid) {
      url = `${baseUrl}backtest/?algo_uuid=${algo_uuid}&resp=json`
    }
    if (algo_share_uuid) {
      url = `${baseUrl}strategy_backtest_shared/?algo_share_uuid=${algo_share_uuid}`
    }
    if (sbt) {
      url = `${baseUrl}backtest_shared/?sbt=${sbt}`
    }
    if (sample) {
      url = `${baseUrl}sample_backtest/?algo_uuid=${algo_uuid}&resp=json`
    }
    if (algo_subscription_uuid) {
      url = `${baseUrl}marketplace_backtest/?algo_subscription_uuid=${algo_subscription_uuid}&resp=json`
    } else if (publishing_uuid) {
      url = `${baseUrl}marketplace_backtest/?publishing_uuid=${publishing_uuid}&stocks=${stocks}&resp=json`
    }
    if (!getAllData) url = `${url}&max_count=-3`
    if (max_count) url = `${url}&max_count=${max_count}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      if (getAllData) {
        yield put(fetchBacktestAllSuccess(data))
      } else {
        yield put(backtestResultSuccess({ ...data, equities: data.equities || {} }))

        const equities = data.equities
          ? Object.keys(data.equities).map(sym => `${data.equities[sym]}_${sym}`) : []
        yield put(getInstruments(
          fetchBackTestInstruments(null, equities),
          {},
          NAVIGATIONS.BACKTESTS.name,
        ))
        if (algo_obj && shouldRunBacktest) {
          let f_algo_obj = algo_obj
          if (onboard) {
            if (data.backtest_results && data.backtest_results[0]
              && !isEmpty(data.backtest_results[0].algo_obj)) {
              f_algo_obj = cleanAlgoObj(data.backtest_results[0].algo_obj, scripList)
              yield put({
                type: RUN_BACKTEST,
                payload: {
                  scripList,
                  algo_obj: f_algo_obj,
                  headers,
                  subscription_type,
                  // createAdvance,
                },
              })
            } else {
              yield put(backtestResultFailure('Error running backtest'))
            }
          } else if (sample) {
            f_algo_obj = { ...data, quantity: data.position_qty }
            yield put({
              type: RUN_BACKTEST,
              payload: {
                scripList,
                algo_obj: f_algo_obj,
                headers,
                subscription_type,
                // createAdvance,
              },
            })
          } else {
            yield put({
              type: RUN_BACKTEST,
              payload: {
                scripList,
                algo_obj: f_algo_obj,
                headers,
                subscription_type,
                csrf,
                sessionid,
                canRunGoBt,
                // createAdvance,
              },
            })
          }
        } else {
          // DANGER
          // const instrument_tokens = backtestPairs(equities) DANGER
          // yield put({
          //   type: GET_INSTRUMENTS_INIT,
          //   payload: { params: instrument_tokens },
          // })
        }
      }
    } else if (getAllData) {
      yield put(fetchBacktestAllFailure(data.error || data.error_msg || error_msg))
    } else {
      yield put(backtestResultFailure(data.error || data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(backtestResultFailure(error_msg))
  }
}

export function* fetchBacktestResultsSaga() {
  yield takeLatest(FETCH_BACKTEST_INIT, fetchBacktestResults)
}

export function* fetchBacktestAllSaga() {
  yield takeLatest(FETCH_BACKTEST_ALL, fetchBacktestResults)
}

function* runBacktest(action) {
  const {
    scripList = [],
    algo_obj = {},
    // headers,
    // subscription_type = 0,
    canRunGoBt,
    csrf,
    sessionid,
    sessionFailure = 0,
    // editParams,
    reRunseg_sym,
    backtest_results = [],
  } = action.payload
  const {
    algo_uuid, user_uuid, algo_subscription_uuid, publishing_uuid, create_plus: createAdvance,
    algo_subscribed,
  } = algo_obj
  const state = store.getState()
  const { user_details } = state.auth
  const {
    subscription_period,
    subscription_plan,
    subscription_validity,
    subscription_type = 0,
  } = user_details
  const validity_unix = dayjs(subscription_validity).unix()
  const equities = []
  const empty_entries = []
  let marketFetch = false
  if (algo_subscription_uuid || publishing_uuid) {
    marketFetch = true
  }
  const id = algo_subscription_uuid || publishing_uuid || algo_uuid

  // this map is used to map the backtest results to the scripList (Re run backtest)
  const backtest_result_map = {}
  backtest_results.forEach((item) => {
    backtest_result_map[item.seg_sym] = item
  })
  let firstBtCycle = !reRunseg_sym
  try {
    yield put(onGoingBacktests(true, id))
    if (firstBtCycle) {
      const btStack = scripList.map(scrip => `${scrip.segment}_${scrip.symbol}`)
      yield put({
        type: UPDATE_BT_SCRIPSTACK,
        scripList: btStack,
        updateType: 'add',
      })
      firstBtCycle = false
    }

    scripList.forEach((scrip) => {
      const { segment, symbol } = scrip
      const entry = `${segment}_${symbol}`
      equities.push(entry)
      if(reRunseg_sym && scrip.seg_sym !== reRunseg_sym && backtest_result_map[entry]) {
        empty_entries.push(backtest_result_map[entry])
        return
      }
      const obj = {}
      obj.algo_obj = algo_obj
      obj.seg_sym = entry
      obj.symbol = entry
      obj.algo_uuid = algo_uuid
      obj.user_uuid = user_uuid
      obj.backtest_result = {
        [entry]: {
          waiting: true,
          seg_sym: entry,
        },
      }
      obj.waiting = true
      if(reRunseg_sym && scrip.seg_sym !== reRunseg_sym) obj.waiting = false
      empty_entries.push(obj)
    })
    yield put(createEmptyPairEntries(equities, empty_entries, algo_obj))
    //   const instrument_tokens = backtestPairs(equities) DANGER
    //   yield put({
    //     type: GET_INSTRUMENTS_INIT,
    //     payload: { params: instrument_tokens },
    //   })
    let success = 0
    let failure = sessionFailure || 0
    // HOT-FIX : when dt_start and dt_stop range comes invalid from create -> backtest (on Edit)
    const dateRangeValidatedAlgoObj = algo_obj // validateDateRange DANGER
    let limitReached = false
    for (let i = 0; i < scripList.length; i++) {
      const { segment, symbol } = scripList[i]
      const seg_sym = `${segment}_${symbol}`
      // for rerun backtest to skips rest of the scrips
      // eslint-disable-next-line no-continue
      if(reRunseg_sym && seg_sym !== reRunseg_sym) continue
      try {
        const new_algo_obj = {
          ...dateRangeValidatedAlgoObj,
          algo_desc: dateRangeValidatedAlgoObj.algo_desc || '',
          algo_pref: '',
          commission: 0,
          version: '2',
        }
        const formedParams = { ...new_algo_obj, symbols: [[segment, symbol]], trade_time_given: 'True' }
        let data = {}
        if (canRunGoBt) {
          const authToken = `csrftoken=${csrf};csrfmiddlewaretoken=${csrf};sessionid=${sessionid}`
          // eslint-disable-next-line no-await-in-loop
          const resp = yield window.BacktestGoFunc(JSON.stringify(formedParams), '[]', authToken)
          data = JSON.parse(resp)
        } else {
          const url = createAdvance ? prodBtFastService : prodBtService
          let urlEndpoint = marketFetch ? 'market_backtest_service' : 'backtest_service'
          if (algo_obj.backtest_share_uuid || algo_obj.shared) {
            urlEndpoint = 'market_backtest_service'
          }
          if (scripList[i].seg_sym?.includes('_DYNAMIC CONTRACT') || scripList[i].symbol.includes('DYNAMIC CONTRACT')) {
            urlEndpoint = algo_subscription_uuid ? 'market_backtest_service_async' : 'backtest_service_async'
          }
          data = yield call(postRequest, `${url}${prodEndpoints[urlEndpoint]}`, JSON.stringify(formedParams), { 'content-type': 'application/json' }, false)
          if (i === scripList.length - 1) showSnackbar('All backtest request submitted. Your results are being prepared. You can refresh the page if results are still loading.')
        }
        if (data.updated_time || data.status || data.error_msg || data[seg_sym]) {
          if (data.status === 'error') {
            if (data.error_msg && data.error_msg.toLowerCase() === 'limit reached') {
              limitReached = true
            }
            yield put({ type: UPDATE_BT_SCRIPSTACK, scripList: seg_sym, updateType: 'remove' })
            yield put(backtestFailure(data, seg_sym))
            failure += 1
          } else if (!data[seg_sym]) {
            yield delay(10000)
            yield put({
              type: FETCH_BACKTEST_DETAILS_DYC,
              params: {
                seg_sym,
                algo_uuid,
                algo_subscription_uuid,
                publishing_uuid,
                algo_subscribed,
                algo_obj: formedParams,
                runtime: data.runtime,
              },
            })
          } else {
            yield put({ type: UPDATE_BT_SCRIPSTACK, scripList: seg_sym, updateType: 'remove' })
            yield put(backtestSuccess(formedParams, data, seg_sym))
            if (data[seg_sym].error_msg) {
              failure += 1
            } else {
              success += 1
            }
          }
        } else {
          failure += 1
          yield put({ type: UPDATE_BT_SCRIPSTACK, scripList: seg_sym, updateType: 'remove' })
          yield put(backtestFailure(data, seg_sym))
        }
      } catch (err) {
        failure += 1
        yield put({ type: UPDATE_BT_SCRIPSTACK, scripList: seg_sym, updateType: 'remove' })
        yield put(backtestFailure(error_msg, seg_sym, failure))
      }
    }
    if (limitReached) {
      if (subscription_type >= 3 && Number(subscription_period) === 12
        && validity_unix > dayjs().unix()) { // for annaual ultimate users
        showSnackbar('Daily backtest limit reached.', {
          showReadMore: true,
          readMoreText: 'Learn how we count backtests',
          link: 'https://help.streak.tech/backtest/#backtest-per-day',
        })
      } else if (subscription_plan
       === 'ultimate' && Number(subscription_period) !== 12) { // for non-annual ultimate users
        yield put(toggleAuthGreeting(PRO_FEATURE_MAP.BT_LIMIT.ID, '', false))
      } else { // for others users
        yield put(toggleAuthGreeting(PRO_FEATURE_MAP.BT_LIMIT_REGULAR.ID))
      }
    }
    // yield put({ type: UPDATE_BT_SCRIPSTACK, updateType: 'clear' })
    yield put(onGoingBacktests(false, id, success))
  } catch (err) {
    yield put({ type: UPDATE_BT_SCRIPSTACK, updateType: 'clear' })
    yield put(onGoingBacktests(false, id))
  }

  yield put({
    type: GET_USER_DETAILS_INIT,
  })
}

export function* runBacktestSaga() {
  yield takeLatest(RUN_BACKTEST, runBacktest)
}

function* deployAlgoStocks(action) {
  try {
    const { params, headers } = action
    const { algo_subscription_uuid, subscription_status } = params
    const subsLimitUrl = prodEndpoints.get_subscription_limit
    const limitResp = yield call(getRequest, subsLimitUrl, { resp: 'json' }, headers)
    if (limitResp.status === 'success' && limitResp.deployments_remaining > 0) {
      let url = `${baseUrl}${prodEndpoints.deployAlgo}`
      if (algo_subscription_uuid && (subscription_status >= 2)) {
        url = `${baseUrl}${prodEndpoints.marketplace_deploy}`
      }
      const data = yield call(postRequest, url, params, headers)

      if (!data.error && data.status === 'success') {
        yield put(deploySuccess(data))
      } else {
        yield put(deployFailure(data.error_msg))
      }
    } else {
      yield put(deployFailure('Deploy limit reached'))
    }
  } catch (err) {
    yield put(deployFailure(error_msg))
  }
}

export function* deployAlgoStocksSaga() {
  yield takeLatest(DEPLOY_STOCKS_INIT, deployAlgoStocks)
}

function* generateShareLink(action) {
  try {
    const { params, headers } = action
    let url = prodEndpoints.generate_shareable_link
    if (params.seg_syms) {
      url = prodEndpoints.generate_strategy_shareable_link
    }
    // const url = prodEndpoints.generate_shareable_link
    const data = yield call(postRequest, url, params, headers)
    if (!data.error) {
      yield put(generateShareLinkSuccess(data))
    } else {
      yield put(generateShareLinkFailure(data.error))
    }
  } catch (err) {
    yield put(generateShareLinkFailure(error_msg))
  }
}

export function* generateShareLinkSaga() {
  yield takeLatest(GENERATE_SHARE_LINK, generateShareLink)
}

function* fetchShareLink(action) {
  try {
    const { params, headers } = action
    const url = `${shareUrl}${prodEndpoints.generate_keys}`
    const data = yield call(postRequest, url, params, headers, false)
    if (!data.error) {
      yield put(fetchShareLinkSuccess(data))
    } else {
      yield put(fetchShareLinkFailure(data.error))
    }
  } catch (err) {
    yield put(fetchShareLinkFailure(error_msg))
  }
}

export function* fetchShareLinkSaga() {
  yield takeLatest(FETCH_SHARE_LINK, fetchShareLink)
}

function* fetchBacktestDetails(action) {
  try {
    const { params, headers } = action
    const {
      seg_sym, algo_subscription_uuid, algo_uuid, publishing_uuid,
      algo_subscribed, shared = false,
    } = params
    let finalEndPoint = ''
    let final_params = {}
    if (algo_subscription_uuid && algo_subscribed) {
      finalEndPoint = 'fetch_marketplace_chart'
      final_params = { algo_subscription_uuid }
    } else if (publishing_uuid) {
      finalEndPoint = 'fetch_marketplace_chart'
      final_params = { publishing_uuid }
    } else if (algo_uuid && shared) {
      finalEndPoint = 'fetch_backtest_chart'
      final_params = { algo_uuid, shared }
    } else if (algo_uuid) {
      finalEndPoint = 'fetch_backtest_chart'
      final_params = { algo_uuid }
    }
    //  tl=true&max_count=-1 give full pnl and tradelog, max_count=-2 give blank pnl and tradelog
    final_params = {
      ...final_params, seg_sym, tl: true, max_count: -1,
    }
    const url = `${baseUrl}${prodEndpoints[finalEndPoint]}`
    const data = yield call(getRequest, url, final_params, headers)
    if (!data.error) {
      yield put(fetchBacktestDetailsSuccess(data, final_params))
    } else {
      yield put(fetchBacktestDetailsFailure(data.error, final_params))
    }
  } catch (err) {
    yield put(fetchBacktestDetailsFailure(err.message))
  }
}

export function* fetchBacktestDetailsSaga() {
  yield takeLatest(FETCH_BACKTEST_DETAILS, fetchBacktestDetails)
}

function* fetchBacktestDetailsDyc(action) {
  try {
    const { params, headers } = action
    const {
      seg_sym, algo_subscription_uuid, algo_uuid, publishing_uuid,
      algo_subscribed, shared, sessionFailure, algo_obj,
    } = params
    let finalSegSym = seg_sym
    if(seg_sym.includes('&')) {
      finalSegSym = finalSegSym.replace('&', '%26')
    }
    let failure = sessionFailure || 0
    let finalEndPoint = ''
    let final_params = {}
    if (algo_subscription_uuid && algo_subscribed) {
      finalEndPoint = 'fetch_marketplace_chart'
      final_params = { algo_subscription_uuid }
    } else if (publishing_uuid) {
      finalEndPoint = 'fetch_marketplace_chart'
      final_params = { publishing_uuid }
    } else if (algo_uuid && shared) {
      finalEndPoint = 'fetch_backtest_chart'
      final_params = { algo_uuid, shared }
    } else if (algo_uuid) {
      finalEndPoint = 'fetch_backtest_chart'
      final_params = { algo_uuid }
    }
    //  tl=true&max_count=-1 give full pnl and tradelog, max_count=-2 give blank pnl and tradelog
    final_params = {
      ...final_params, seg_sym: finalSegSym, tl: true, max_count: -1,
    }
    const url = `${baseUrl}${prodEndpoints[finalEndPoint]}`
    const data = yield call(getRequest, url, final_params, headers)
    if (!data.error) {
      if (params.runtime === data.backtests[0]?.runtime) {
        yield put({ type: UPDATE_BT_SCRIPSTACK, scripList: seg_sym, updateType: 'remove' })
        yield put(fetchBacktestDetailsDycSuccess(data, params.algo_obj, final_params))
      } else if (failure <= 5) {
        yield delay((5 * (2 ** (failure - 1))) * 1000)
        failure += 1
        yield put({
          type: FETCH_BACKTEST_DETAILS_DYC,
          params: {
            seg_sym: finalSegSym,
            algo_uuid,
            algo_subscription_uuid,
            publishing_uuid,
            algo_subscribed,
            algo_obj,
            runtime: params.runtime,
            sessionFailure: failure,
          },
        })
      } else if (failure > 5) {
        yield put({ type: UPDATE_BT_SCRIPSTACK, scripList: seg_sym, updateType: 'remove' })
        yield put(fetchBacktestDetailsDycFailure(data, seg_sym, params.runtime))
      }
    } else {
      yield put({ type: UPDATE_BT_SCRIPSTACK, scripList: seg_sym, updateType: 'remove' })
      yield put(fetchBacktestDetailsDycFailure(data, seg_sym))
    }
  } catch (err) {
    yield put({ type: UPDATE_BT_SCRIPSTACK, updateType: 'clear' })
    yield put(fetchBacktestDetailsDycFailure(err.message))
  }
}

export function* fetchBacktestDetailsDycSaga() {
  yield takeEvery(FETCH_BACKTEST_DETAILS_DYC, fetchBacktestDetailsDyc)
}

function* saveBacktestParams(action) {
  try {
    const { params } = action
    const url = prodEndpoints.saveBacktest
    const data = yield call(postRequest, url, params)
    if (!data.error && data.status === 'success') {
      yield put(saveBacktestParamsSuccess(data))
    } else {
      yield put(saveBacktestParamsFailure(data.error))
    }
  } catch (error) {
    yield put(saveBacktestParamsFailure(error.message))
  }
}

export function* saveBacktestParamsSaga() {
  yield takeLatest(SAVE_BACKTEST_PARAMS, saveBacktestParams)
}
