import {
  FETCH_FUNDS_INIT,
  FETCH_FUNDS_SUCCESS,
  FETCH_FUNDS_FAILURE,
  FETCH_SAMPLE_ALGO_INIT,
  FETCH_SAMPLE_ALGO_SUCCESS,
  FETCH_SAMPLE_ALGO_FAILURE,
  FETCH_PERFORMERS_INIT,
  FETCH_PERFORMERS_SUCCESS,
  FETCH_PERFORMERS_FAILURE,
  CLONE_SAMPLE_INIT,
  CLONE_SAMPLE_SUCCESS,
  CLONE_SAMPLE_FAILURE,
  CLEAR_DASHBOARD_RESP,
  FETCH_WHATS_NEW_INIT,
  FETCH_WHATS_NEW_SUCCESS,
  FETCH_WHATS_NEW_FAILURE,
  FETCH_DASHBOARD_EXPLORE,
  FETCH_DASHBOARD_EXPLORE_SUCCESS,
  FETCH_DASHBOARD_EXPLORE_FAILURE,
  GET_PARTIAL_COMPLETE_INIT,
  GET_PARTIAL_COMPLETE_SUCCESS,
  GET_PARTIAL_COMPLETE_FAILURE,
  GET_RECOMMENDATIONS_INIT,
  GET_RECOMMENDATIONS_SUCCESS,
  GET_RECOMMENDATIONS_FAILURE,
  GET_DASHBOARD_CAROUSEL_INIT,
  GET_DASHBOARD_CAROUSEL_SUCCESS,
  GET_DASHBOARD_CAROUSEL_FAILURE,
  GET_WORDPRESS_CAROUSEL_INIT,
  GET_WORDPRESS_CAROUSEL_SUCCESS,
  GET_WORDPRESS_CAROUSEL_FAILURE,

} from './actionTypes'

export function fetchFunds(headers) {
  return {
    type: FETCH_FUNDS_INIT,
    headers,
  }
}

export function fetchFundsSuccess(data) {
  return {
    type: FETCH_FUNDS_SUCCESS,
    data,
  }
}

export function fetchFundsFailure(error) {
  return {
    type: FETCH_FUNDS_FAILURE,
    error,
  }
}

export function fetchSampleAlgo(headers) {
  return {
    type: FETCH_SAMPLE_ALGO_INIT,
    headers,
  }
}

export function fetchSampleAlgoSuccess(data) {
  return {
    type: FETCH_SAMPLE_ALGO_SUCCESS,
    data,
  }
}

export function fetchSampleAlgoFailure(error) {
  return {
    type: FETCH_SAMPLE_ALGO_FAILURE,
    error,
  }
}

export function fetchTopperformers(headers) {
  return {
    type: FETCH_PERFORMERS_INIT,
    headers,
  }
}

export function fetchTopperformersSuccess(data) {
  return {
    type: FETCH_PERFORMERS_SUCCESS,
    data,
  }
}

export function fetchTopperformersFailure(error) {
  return {
    type: FETCH_PERFORMERS_FAILURE,
    error,
  }
}

export function cloneSample(params, headers) {
  return {
    type: CLONE_SAMPLE_INIT,
    params,
    headers,
  }
}

export function cloneSampleSuccess(data) {
  return {
    type: CLONE_SAMPLE_SUCCESS,
    data,
  }
}

export function cloneSampleFailure(error) {
  return {
    type: CLONE_SAMPLE_FAILURE,
    error,
  }
}

export function clearDashboardResp() {
  return {
    type: CLEAR_DASHBOARD_RESP,
  }
}

export function getWhatsNew(headers) {
  return {
    type: FETCH_WHATS_NEW_INIT,
    headers,
  }
}
export function getWhatsNewSuccess(data) {
  return {
    type: FETCH_WHATS_NEW_SUCCESS,
    data,
  }
}

export function getWhatsNewFailure(error) {
  return {
    type: FETCH_WHATS_NEW_FAILURE,
    error,
  }
}

export function fetchDashboardExplore(params, headers) {
  return {
    type: FETCH_DASHBOARD_EXPLORE,
    params,
    headers,
  }
}

export function fetchDashboardExploreSuccess(data) {
  return {
    type: FETCH_DASHBOARD_EXPLORE_SUCCESS,
    data,
  }
}

export function fetchDashboardExploreFailure(error) {
  return {
    type: FETCH_DASHBOARD_EXPLORE_FAILURE,
    error,
  }
}

export function getPartialComplete(params, headers) {
  return {
    type: GET_PARTIAL_COMPLETE_INIT,
    params,
    headers,
  }
}

export function getPartialCompleteSuccess(data) {
  return {
    type: GET_PARTIAL_COMPLETE_SUCCESS,
    data,
  }
}

export function getPartialCompleteFailure(error) {
  return {
    type: GET_PARTIAL_COMPLETE_FAILURE,
    error,
  }
}

export function getRecommendations(params, headers) {
  return {
    type: GET_RECOMMENDATIONS_INIT,
    params,
    headers,
  }
}

export function getRecommendationsSuccess(data) {
  return {
    type: GET_RECOMMENDATIONS_SUCCESS,
    data,
  }
}

export function getRecommendationsFailure(error) {
  return {
    type: GET_RECOMMENDATIONS_FAILURE,
    error,
  }
}

export function getDashboardCarousel(params, headers) {
  return {
    type: GET_DASHBOARD_CAROUSEL_INIT,
    params,
    headers,
  }
}

export function getDashboardCarouselSuccess(data) {
  return {
    type: GET_DASHBOARD_CAROUSEL_SUCCESS,
    data,
  }
}

export function getDashboardCarouselFailure(error) {
  return {
    type: GET_DASHBOARD_CAROUSEL_FAILURE,
    error,
  }
}

export function getWordpressCarousel(params) {
  return {
    type: GET_WORDPRESS_CAROUSEL_INIT,
    params,
  }
}

export function getWordpressCarouselSuccess(data) {
  return {
    type: GET_WORDPRESS_CAROUSEL_SUCCESS,
    data,
  }
}

export function getWordpressCarouselFailure(error) {
  return {
    type: GET_WORDPRESS_CAROUSEL_FAILURE,
    error,
  }
}
