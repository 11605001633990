import {
  takeLatest, put, call, takeEvery,
} from 'redux-saga/effects'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  fetcAlgosSuccess,
  fetchAlgosFailure,
  deleteAlgoSuccess,
  deleteAlgoFailure,
  fetchBacktestGraphSuccess,
  fetchBacktestGraphFailure,
  filterAlgosFailure,
  filterAlgosSuccess,
} from './actions'
import {
  FETCH_ALGO_INIT,
  DELETE_ALGO_INIT,
  FETCH_BACKTEST_GRAPH,
  FILTER_ALGO_INIT,
} from './actionTypes'

import { GRAPH_FETCH_COUNT } from '../../utils/consts'
import { ALGO_KEY_MAP } from '../../components/AlgoCondensedView/AlgoUtils'
import { error_msg } from '../../utils/common'
import { fetchAlgoInstrument, getInstruments } from '../Instruments/actions'
import { NAVIGATIONS } from '../../utils/navigationConstant'
/* eslint-disable-next-line import/no-cycle */
import store from '../../store'

function* fetchAlgos(action) {
  const { params, detailed } = action
  const state = store.getState()
  const { common } = state
  const { startTour } = common
  try {
    const {
      kind, algo_uuids, page, page_limit, csrfmiddlewaretoken, resp, total_pages,
      search = '', filter = '', isFilterOn,
    } = params
    const version = 4
    let final_params = {
      page, page_limit, csrfmiddlewaretoken, resp, search, filter, version,
    }
    if (algo_uuids) {
      final_params = {
        ...final_params, algo_uuids,
      }
    }
    if (isFilterOn) {
      final_params = {
        page: 1, page_limit, csrfmiddlewaretoken, resp, algo_uuids, search, filter, version,
      }
    }
    const url = `${baseUrl}${prodEndpoints.algos}`
    const data = yield call(postRequest, url, final_params, { 'x-csrftoken': 'sdf' })
    const finalAlgos = startTour ? data.algo.filter(item => item.absolute_pnl !== 0) : data.algo
    if (!data.error && data.status === 'success') {
      yield put(fetcAlgosSuccess(
        { algo: finalAlgos, pages: total_pages || data.pages }, kind, detailed, params,
      ))
      if (detailed) {
        yield put(getInstruments(
          fetchAlgoInstrument(null, data.algo.slice(0, 16)),
          {},
          NAVIGATIONS.MY_ALGOS.name,
        ))
      }
    } else {
      yield put(fetchAlgosFailure(data.error_msg || 'Error fetching response', detailed))
    }
  } catch(err) {
    yield put(fetchAlgosFailure(err.message || error_msg, detailed))
  }
}

export function* fetchAlgosSaga() {
  yield takeLatest(FETCH_ALGO_INIT, fetchAlgos)
}

function* deleteAlgo(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.deleteAlgo}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(deleteAlgoSuccess(data, params.algo_uuid))
    } else {
      yield put(deleteAlgoFailure(data.error))
    }
  } catch(err) {
    yield put(deleteAlgoFailure(err.message || error_msg))
  }
}

export function* deleteAlgoSaga() {
  yield takeLatest(DELETE_ALGO_INIT, deleteAlgo)
}

function* fetchBacktestGraph(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.fetch_backtest_chart}`
    for (let i = 0; i < params.length; i++) {
      const idObj = params[i]
      if (idObj && Object.values(idObj).length) {
        const data = yield call(
          getRequest, url, { ...idObj, max_count: GRAPH_FETCH_COUNT }, headers,
        )
        if (!data.error && data.status === 'success') {
          yield put(fetchBacktestGraphSuccess(data, idObj[ALGO_KEY_MAP.MY_ALGOS]))
        } else {
          yield put(fetchBacktestGraphFailure(data.error, idObj[ALGO_KEY_MAP.MY_ALGOS]))
        }
      }
    }
  } catch(err) {
    yield put(fetchBacktestGraphFailure(err.message || error_msg))
  }
}

export function* fetchBacktestGraphSaga() {
  yield takeEvery(FETCH_BACKTEST_GRAPH, fetchBacktestGraph)
}

function* filterAlgos(action) {
  try {
    const { params, fetchAlgoPayload } = action
    const url = prodEndpoints.multi_search
    const isFavOn = Boolean(fetchAlgoPayload.algo_uuids)
    const reqPageNo = params.page_number
    const reqPageSize = params.page_size
    if (isFavOn) {
      params.page_number = 1
      params.page_size = 5000
    }
    const data = yield call(postRequest, url, JSON.stringify(params), { 'content-type': 'application/json' }, false)
    const { total_pages, results } = data.data
    let totalPages = total_pages
    if (results) {
      let finalIds = []
      if (results.length === 0) {
        yield put(fetcAlgosSuccess(
          { algo: [], pages: totalPages }, fetchAlgoPayload.kind, true, fetchAlgoPayload,
        ))
      } else {
        // favourite is on
        if (isFavOn) {
          finalIds = [...fetchAlgoPayload.algo_uuids.filter(
            id => results.some(idObj => idObj.algo_uuid === id),
          )]
          totalPages = Math.ceil(finalIds.length / reqPageSize)
        } else {
          finalIds = results.map(item => item.algo_uuid)
        }
        yield put({
          type: FETCH_ALGO_INIT,
          params: {
            ...fetchAlgoPayload,
            page_number: reqPageNo,
            total_pages: totalPages,
            algo_uuids: finalIds,
            isFilterOn: !isFavOn,
          },
          detailed: true,
        })
      }

      yield put(filterAlgosSuccess('No strategy found matching the filter selection', finalIds))
    } else {
      yield put(filterAlgosFailure(data.error_msg))
    }
  } catch(err) {
    yield put(deleteAlgoFailure(err.message || error_msg))
  }
}

export function* filterAlgosSaga() {
  yield takeLatest(FILTER_ALGO_INIT, filterAlgos)
}
