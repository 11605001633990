import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import CustomModal from '../../UI/CustomModal'
import SymbolFinder from '.'

import {
  FONTS, SPACING, theme,
} from '../../Theme'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import { updateScripList } from '../../containers/Create/actions'
import { updateBacktestScrips } from '../../containers/Backtests/actions'
import { updateMarketScrips } from '../../containers/Marketwatch/actions'
import { withTheme } from '../../Theme/ThemeProvider'
import { STOCK_ADD_LIMIT } from '../../utils/consts'
import AlertPopup from '../../UI/AlertPopup'

const AddScrip = React.memo(({
  params = {},
  updateAlgoScrips,
  updateBacktestScripList,
  updateMarketScripList,
  updateNewListScrips,
  onDismiss,
  visible,
  dismissKey,
  showSubmitBtn,
  styles,
  alertProps = {},
  clearOnfocus,
  isMobile,
  create_plus,
  toggleSkipTour,
  startTour,
  updateGA,
}) => {
  // if (!visible) {
  //   return null
  // }
  const [showAlert, toggleAlert] = useState(false)
  const [showDiscardModal, shouldShowDiscard] = useState(false)
  const [shouldUpdateList, toggleUpdateList] = useState(false)
  useEffect(() => {
    if (visible) {
      toggleUpdateList(false)
      shouldShowDiscard(false)
    }
  }, [visible])
  const dismissHandler = () => {
    onDismiss(dismissKey)
  }
  const dismissAlert = () => {
    if(startTour) {
      toggleSkipTour(true)
      return
    }
    if (parentPageId === NAVIGATIONS.WATCHLIST.name
      && showDiscardModal) toggleAlert(true)
    else dismissHandler()
  }
  const {
    defaultList, parentPageId, blockedSegmentList, showBaskets = true,
    symbolLimit = STOCK_ADD_LIMIT,
  } = params
  // const [list, updateList] = useState(defaultList)
  const updateList = (newList, extraParams) => {
    // if (newList.length === 0) return DANGER
    // updateList([...newList])
    if (parentPageId === NAVIGATIONS.WATCHLIST.name) {
      updateMarketScripList(newList, extraParams)
    } else if (parentPageId === NAVIGATIONS.CREATE.name) {
      updateAlgoScrips(newList, extraParams)
    } else if (parentPageId === NAVIGATIONS.TECHNICALS.name) {
      updateNewListScrips(newList, extraParams)
    } else {
      updateBacktestScripList(newList, {
        runBacktest: extraParams.submitPressed,
      })
    }
  }
  const onAlertDismiss = () => {
    toggleAlert(!showAlert)
  }
  const {
    title = 'You have updated your watchlist',
    actionsText = ['Discard Changes', 'Apply Changes'],
  } = alertProps
  return (
    <>
      <AlertPopup
        visible={showAlert}
        onDismiss={onAlertDismiss}
        dismissKey="showAlert"
        modalStyles={styles.alertModal}
        title={title}
        actions={[
          {
            text: actionsText[0] || 'Discard Changes',
            action: () => { dismissHandler(); toggleAlert(false) },
            btnProps: {
              buttonColor: 'tabsBg', mode: 'text', labelColor: 'textSecondary',
            },
          },
          {
            text: actionsText[1],
            action: () => { toggleUpdateList(true); toggleAlert(false); dismissHandler() },
          },
        ]}
      />
      <CustomModal
        onDismiss={dismissAlert}
        visible={visible}
        containerStyles={styles.modalContainer}
        position="right"
        fullScreen
      >
        <SymbolFinder
          list={defaultList}
          updateList={updateList}
          onDismiss={dismissHandler}
          symbolLimit={symbolLimit}
          showBaskets={showBaskets}
          blockedSegmentList={blockedSegmentList}
          parentPageId={parentPageId}
          showSubmitBtn={showSubmitBtn}
          styles={styles}
          shouldUpdateList={shouldUpdateList}
          shouldShowDiscard={shouldShowDiscard}
          clearOnfocus={clearOnfocus}
          isMobile={isMobile}
          create_plus={create_plus}
          updateGA={updateGA}
        />
      </CustomModal>
    </>
  )
})

const mapDispatchToProps = (dispatch) => {
  return {
    updateMarketScripList: list => dispatch(updateMarketScrips(list)),
    updateAlgoScrips: list => dispatch(updateScripList(list)),
    updateBacktestScripList: (list, extraParams) => dispatch(
      updateBacktestScrips(list, extraParams),
    ),
  }
}

const stylesheet = {
  modalContainer: {
    // maxHeight: 400,
    // minHeight: DIMENSIONS.HEIGHT * (3 / 4),
    maxHeight: '100%',
    height: '100%',
    width: '40%',
    maxWidth: 650,
    minWidth: 150,
    margin: 'auto',
    overflow: 'hidden',
    padding: 0,
    // backdropFilter: 'blur(35px)',
    // WebkitBackdropFilter: 'blur(35px)',
  },
  container: {
    // padding: `0 ${SPACING.SPACE_20}`,
    backgroundColor: theme.bgPrimary,
    height: '100%',
    overflow: 'auto',
  },
  modalBackDrop: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    cursor: 'default',
  },
  existingListRow: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 120px)',
    overflow: 'hidden',
    position: 'relative',
  },
  existingList: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    transition: 'opacity 0.3s',
    '&  *': {
      transition: 'all 0.3s',
    },
  },
  exsitingStocksSection: {
    position: 'relative',
    height: '34px',
    width: '100%',
    overflow: 'hidden',
  },
  existingListHidden: {
    width: 0,
    opacity: 0,
    '& + $existingStocksHeader': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '& > $existingListRow': {
      width: 0,
      '& > $chipContainer': {
        position: 'absolute',
      },
    },
  },
  existingStocksHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: SPACING.SPACE_8,
    borderBottom: `1px solid ${theme.borderColor}`,
    position: 'absolute',
    top: 0,
    overflow: 'hidden',
    opacity: 0,
    width: '100%',
    transform: 'translateX(100%)',
    transition: 'all 0.3s',
  },
  exsitingStocksBackBtn: {
    marginRight: SPACING.SPACE_8,
  },
  stockListModal: {
    position: 'absolute',
    maxHeight: 'calc(100vh - 100px)',
    width: '100%',
    zIndex: 9,
    overflow: 'auto',
    borderRadius: 5,
    backgroundColor: theme.navBtnBg,
    boxShadow: '0px 3px 20px #162B7424',
    padding: SPACING.SPACE_10,
  },
  header: {
    top: 0,
    position: 'sticky',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    backgroundColor: theme.bgPrimary,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  searchInput: {
    padding: '10px 0',
  },
  actionBar: {
    marginBottom: SPACING.SPACE_10,
    position: 'relative',
  },
  actionBarRow: {
    display: 'flex',
    alignItems: 'center',
  },
  submitBtnContainer: {
    marginLeft: SPACING.SPACE_20,
    display: 'flex',
    minWidth: 200,
  },
  submitBtn: {
    // width: '',
    flex: 1,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    border: '1px solid transparent',
    maxWidth: '200px',
    '&:first-child': {
      margin: 0,
    },
  },
  listView: {
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.hover,
    },
    '&:hover $dycBtn': {
      opacity: 1,
    },
  },
  ptItem: { // product tour class only
    display: 'flex',
    alignItems: 'center',
    opacity: '0.2 !important',
    '&:hover': {
      backgroundColor: theme.hover,
    },
    '&:hover $dycBtn': {
      opacity: 1,
    },
    '&:nth-child(-n+3)': {
      opacity: '1 !important',
    },
  },
  basketItemBtn: {
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  stockItemBtn: {
    padding: `${SPACING.SPACE_10} 0 ${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    width: '100%',
  },
  itemSeleted: {
    backgroundColor: theme.hover,
  },
  dycBtn: {
    paddingLeft: SPACING.SPACE_10,
    paddingRight: SPACING.SPACE_10,
    opacity: 0,
  },
  dycExist: {
    opacity: 1,
  },
  tickBtn: {
    paddingLeft: SPACING.SPACE_6,
    paddingRight: SPACING.SPACE_20,
  },
  itemRow: {
    display: 'flex',
  },
  symbol: {
    flex: 1,
    textAlign: 'left',
  },
  segment: {
    fontSize: FONTS.TINY,
    textAlign: 'left',
  },
  rowIcon: {
    alignSelf: 'center',
  },
  chipContainer: {
    backgroundColor: theme.tabsBg,
    marginTop: SPACING.SPACE_10,
    border: 0,
  },
  removeAllBtn: {
    // marginTop: SPACING.SPACE_10,
    // marginLeft: SPACING.SPACE_20,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  viewMoreBtn: {
    padding: `${SPACING.SPACE_2} ${SPACING.SPACE_12}`,
    position: 'relative',
    bottom: 1,
  },
  viewMoreContainer: {
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
  },
  viewMoreHeader: {
    paddingBottom: `${SPACING.SPACE_10}`,
    marginBottom: `${SPACING.SPACE_10}`,
    borderBottom: `1px solid ${theme.borderColor}`,
    textAlign: 'center',
  },
  loader: {
    maxWidth: '60px',
    margin: 'auto',
    display: 'flex',
  },

  detailsModal: {
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    minHeight: 200,
    maxWidth: 570,
  },
  // alertModal: {
  //   minHeight: 250,
  // },
  '@media only screen and (max-width: 786px)': {
    container: {
      width: '100%',
      padding: '0',
    },
    header: {
      padding: `${SPACING.SPACE_12} ${SPACING.SPACE_12} 0`,
    },
    basketItemBtn: {
      padding: `${SPACING.SPACE_12}`,
    },
    stockItemBtn: {
      padding: `${SPACING.SPACE_12} 0 ${SPACING.SPACE_12} ${SPACING.SPACE_12}`,
    },
    searchInput: {
      padding: '6px 0',
    },
    submitBtn: {
      padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    },
    backBtn: {
      padding: `${SPACING.SPACE_2} ${SPACING.SPACE_8} ${SPACING.SPACE_2} 0`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dycBtn: {
      opacity: 1,
    },
    tickBtn: {
      paddingRight: SPACING.SPACE_12,
    },
  },
}

export default connect(null, mapDispatchToProps)(withTheme(stylesheet)(AddScrip))
