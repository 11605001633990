import makeStyles from '@material-ui/styles/makeStyles'
import React from 'react'

import Icon from '../../UI/Icon'
import CustomText from '../../UI/CustomText'
import SegSym from '../../UI/SegSym'

import {
  SPACING, ICONS, COLORS, theme,
} from '../../Theme'

const CancelSlmrowHeader = React.memo(({
  cancelOrder, iconProps = {}, item,
}) => {
  const {
    orderId, algo_name, tradingsymbol = '', symbol = '', segment = '',
  } = item
  const styles = stylesheet()
  const { COLOR: color, ICON: name } = iconProps
  const sym = tradingsymbol || symbol
  return (
    <div className={styles.cancelSlmRow}>
      <div className={styles.screenerInst}>
        <Icon
          name={ICONS.NOTIF_AUTO}
          size={15}
          color={theme.linkColor}
          style={{ marginRight: SPACING.SPACE_8 }}
        />
        <SegSym
          seg_sym={`${segment}_${sym}`}
          showTooltip
          style={{ flex: 1 }}
          ellipsis={false}
        />
      </div>

      <CustomText size="small">{algo_name}</CustomText>
      <CustomText>{orderId}</CustomText>
      <div className={styles.tagInfo}>
        <Icon name={name} color={color} size={12} style={{ marginRight: SPACING.SPACE_8 }} />
        <CustomText weight="medium" size="small">CANCEL SL-M ORDER</CustomText>
      </div>
      <button
        type="button"
        onClick={() => cancelOrder(item)}
        className={styles.cancelSlmBtn}
      >
        <CustomText color="red" weight="medium" size="small">Cancel Order</CustomText>
        <Icon
          name={ICONS.FORWARD}
          size={10}
          style={{ marginLeft: SPACING.SPACE_6 }}
          color={COLORS.RED}
        />
      </button>
    </div>
  )
})

const stylesheet = makeStyles({
  cancelSlmRow: {
    display: 'flex',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20} ${SPACING.SPACE_6}`,
    alignItems: 'center',
    minWidth: 500,
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.borderColor}`,
    cursor: 'default',
    '&:last-child': {
      borderWidth: 0,
    },
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  screenerInst: {
    display: 'flex',
    alignItems: 'center',
  },
  tagInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  cancelSlmBtn: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 110,
  },
  '@media only screen and (max-width: 786px)': {
  },
})

export default CancelSlmrowHeader
