import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { connect } from 'react-redux'

import CustomText from '../../UI/CustomText'

import {
  ASSETS, COLORS, SPACING, theme,
} from '../../Theme'
import { useTheme } from '../../Theme/ThemeProvider'

const {
  BACKTEST_COUNT, DEPLOY_COUNT, BACKTEST_COUNT_DARK, DEPLOY_COUNT_DARK,
  SCANNER_COUNT, SCANNER_COUNT_DARK,
} = ASSETS

const renderUsageRow = (
  label, value = 0, totalValue = 0, imgSrc = BACKTEST_COUNT, styles = {}, isDark,
) => {
  return (
    <div className={styles.usageRow}>
      <img src={imgSrc} className={styles.usageImg} alt={`${label}_img`} />
      <div className={styles.usageInfo}>
        <div className={styles.usageCount}>
          <CustomText weight="medium" size="large">
            {value ? Math.max(value, 0) : '0'}
          </CustomText>
          <CustomText
            weight="regular"
            size="small"
            color={theme.textSecondary}
            className={styles.totalValue}
          >
            {`/${totalValue}`}
          </CustomText>
        </div>
        <CustomText weight="regular" size="small_0" color={isDark ? 'textSecondary' : ''}>{label}</CustomText>
      </div>
    </div>
  )
}

const UsageMetrics = React.memo(({
  total_backtest, backtest, total_deployments, deployed,
  subscription_type, total_scan_count, scan_count, containerStyles = '',
}) => {
  const styles = stylesheet()
  const { isDark } = useTheme()
  return (
    <div id="dash_tour_4">
      <div className={`${styles.metrics} ${containerStyles}`}>
        {renderUsageRow('Backtest Per Day', total_backtest - Math.abs(backtest), total_backtest, isDark ? BACKTEST_COUNT_DARK : BACKTEST_COUNT, styles, isDark)}
        {renderUsageRow('Concurrent Deployment', total_deployments - Math.abs(deployed), total_deployments, isDark ? DEPLOY_COUNT_DARK : DEPLOY_COUNT, styles, isDark)}
        {subscription_type !== 3 && subscription_type !== 2 && renderUsageRow('Scans Per Day', total_scan_count - Math.abs(scan_count), total_scan_count, isDark ? SCANNER_COUNT_DARK : SCANNER_COUNT, styles, isDark)}
      </div>
    </div>
  )
})

const stylesheet = makeStyles({
  metrics: {
    padding: `${SPACING.SPACE_10} 0`,
    backgroundColor: theme.bgPrimary,
    borderRadius: 8,
    '@media (max-width: 425px)': {
      marginBottom: SPACING.SPACE_24,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',
      boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    },
  },
  usageRow: {
    display: 'flex',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_16}`,
    alignItems: 'center',
  },
  usageImg: {
    height: 30,
    width: 30,
    marginRight: SPACING.SPACE_18,
    backgroundColor: COLORS.USAGE_BG,
    borderRadius: 6,
  },
  totalValue: {
    margin: `0 0 ${SPACING.SPACE_2} ${SPACING.SPACE_4} `,
  },
  usageCount: {
    display: 'flex',
    alignItems: 'baseline',
  },
  usageInfo: {

  },
  '@media only screen and (max-width: 786px)': {
    metrics: {
      display: 'block',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    ...state.auth.user_details,
  }
}

export default connect(mapStateToProps, null)(UsageMetrics)
