import React from 'react'

import { ASSETS } from '../Theme'
import { useTheme } from '../Theme/ThemeProvider'

const { PlaceholderDarkGreyGif, PlaceholderLightGif } = ASSETS

const PlaceHolderLoader = (props) => {
  const { isDark } = useTheme()
  const placeholderGif = isDark ? PlaceholderDarkGreyGif : PlaceholderLightGif
  return (
    <div {...props}>
      <img alt="..." src={placeholderGif} style={{ width: '100%', height: '100%', borderRadius: 14 }} />
    </div>

  )
}

export default PlaceHolderLoader
