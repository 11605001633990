/* eslint-disable max-len */
export default {
  'TREND FOLLOWING': {
    tag_name: 'Trend following',
    tag_short_desc: 'Make the trend your friend.',
    tag_description: `First things first. Trend Trading has stood the test of time. And that too for a good reason - it works! Documentation of trend trading dates back to the 19th and 20th centuries, and it continues to be popular to this day.

    If you’re a trend trader, then you’re basically asking the market to ‘show its hand.’ In other words, you analyze the data available to you and use it as an advantage in determining the direction of the trend. 
    `,
  },
  'PRICE ACTION': {
    tag_name: 'Price action',
    tag_short_desc: 'Price action is king.',
    tag_description: `They say, “Trade what you see, not what you think.” But what does that mean?

    Suppose you are using two indicators: the RSI and the Stochastic. Now, what if one is overbought and the other indicator is oversold, at the same time? Won’t you have conflicting signals? Indeed! But no worries! You can adjust the settings of your RSI indicator and make it oversold, so now, both your RSI and Stochastic indicator are sending out the same “signal”.
    
    But instead of manipulating an indicator to fit your bias, you need to look out for what the price is telling you. And then use the indicators as a confirmation of the signal or the trend.
    `,
  },
  'MEAN REVERSION': {
    tag_name: 'Mean Reversion',
    tag_short_desc: 'The price returns back to the mean.',
    tag_description: `Are prices high or low on a relative basis or has the price deviated too much from the mean price? The law of mean reversal means that if a price moves away from the mean price, it tends to return to the original mean price. 

    This is going to give you better clarity of overbought and oversold levels. You can make better trade decisions about when to enter or exit a trade.
    `,
  },
  MOMENTUM: {
    tag_name: 'Momentum',
    tag_short_desc: 'The rate at which price changes.',
    tag_description: `“The trend is your friend until the end when it bends.”

    If you’re a Bullish trader, you might continue to ride the momentum higher and higher and head towards a destructive ending. And if you’re a bearish trader you might fight the trend and miss opportunity after opportunity when it looks like the market is ready to break.
    
    Momentum indicators are used to determine the rate at which the stock is gaining or falling in the market. Avoid missing opportunities using these strategies.
    `,
  },
  MISCELLANEOUS: {
    tag_name: 'Miscellaneous',
    tag_short_desc: 'miscellaneous',
    tag_description: 'miscellaneous',
  },
  'PIVOT POINTS': {
    tag_name: 'Pivot points',
    tag_short_desc: 'pivot points',
    tag_description: 'pivot points',
  },
  OVERLAYS: {
    tag_name: 'Overlays',
    tag_short_desc: 'overlays',
    tag_description: 'overlays',
  },
  'OPTIONS STRATEGIES': {
    tag_name: 'Options strategies',
    tag_short_desc: 'The versatile ones.',
    tag_description: `Options trading strategies run the gamut from simple, "one-legged" trades to exotic “multi-legged” beasts that seem like they’ve emerged from a fantasy novel. But simple or complex, what all strategies have in common is that they’re based on two fundamental option types: calls and puts.

    Let’s have a look, shall we? 
    `,
  },
  INTRADAY: {
    tag_name: 'Intraday',
    tag_short_desc: 'A life lived on the edge.',
    tag_description: `It may seem exciting, but the life of a day trader is life on the edge. You don’t want to leave any stone unturned and head towards a possible ‘oops moment.’ Unexpected events or trading results can occur at a given point on any given day.

    You need to have a few solid trading strategies well-rehearsed in your mind. And if you’ve been having specific trading-related issues, create a plan for how you will handle them. 
    
    Risk management, discipline, trading balances, and the economic calendar are a few things you can’t overlook.     
    `,
  },
  'SWING TRADING': {
    tag_name: 'Swing trading',
    tag_short_desc: 'For the patient ones.',
    tag_description: `Trades with greater stop losers that last between a few days to a few months! Swing trading requires you to be patient and find the right strategies that suit your trading style and that can produce results, an edge, or a profit.

    Ah, you can make use of the ‘paper trade’ option in Streak so that you can check your strategies for profits and execute them. Paper trading means making hypothetical trades as if you were trading real funds.
    `,
  },
  'POSITION TRADING': {
    tag_name: 'Position trading',
    tag_short_desc: 'The longest term trading of all.',
    tag_description: `With targets as high as several hundred or thousand ticks, this might pull you through bull and bear markets, economic recessions, and booms over the years. But you need to hold on. Hold on until the trend peaks, ignore popular opinions and do your own research & analysis.

    This trading philosophy seeks to exploit the bulk of a trend's upwards move and all the strategies revolve around that.
    `,
  },
  // '': {
  //   tag_name: '',
  //   tag_short_desc: '',
  //   tag_description: '',
  // }

  // 'THE MILLENNIALS': {
  //   tag_name: 'The millennials',
  //   tag_short_desc: 'Born in 1996 & looking at stocks?',
  //   tag_description: 'Did you know, the largest type of shareholders of
  // Zomatoafter its IPO were retail investors? Find your basket with your favorite stocks -
  // Zomato, BurgerKing, Nykaa, Barbeque Nation, RailTel, IRCTC, etc.',
  // },
  // TECH: {
  //   tag_name: 'Tech',
  //   tag_short_desc: 'Is this the new cool?',
  //   tag_description: `The Coronavirus saw a Big Boom in Tech stocks.

  //   The technology sector is vast, comprising gadget makers, software developers,
  // wireless providers,
  // streaming services, semiconductor companies, and cloud computing providers, to name a few.
  // Any company that sells a product or service heavily infused with technology
  // likely belongs to the tech sector.
  // As we become more and more dependent on technology, it also becomes the leading indicator for
  // the economy and stock market.

  //   If you believe tech is the new cool then these strategies might help you create an action plan.
  //   `,
  // },
  // LEADING: {
  //   tag_name: 'Leading',
  //   tag_short_desc: 'Reading between the lines.',
  //   tag_description: 'As the name suggests, these indicators lead the price. They give trade signals when the trend is about to start. It might signal the occurrence of a new trend in advance. You might be excited right now? An indicator that predicts the future? Let’s make one thing very clear - these indicators are notorious for giving false signals. Hence, traders need to be extra careful. To support the trend, you can always use it with the combination of a lagging indicator. And here are a few strategies along these lines.',
  // },
  // 'ATR TRAILING STOP LOSS': {
  //   tag_name: 'ATR trailing stop loss',
  //   tag_short_desc: 'Avoid getting stopped out too soon.',
  //   tag_description: `Now, this is an exciting strategy…

  //   Suppose you buy a stock and the price is in an uptrend. If the momentum is high, then there’s a chance you might either hit your target profit or Stop Loss and exit the trade. But what if you want to ride the trend? You can use the Trailing Stop Loss. It keeps moving upward with the price so that you can book your previous profits. You can then exit the trade once the trend reaches exhaustion or hits the trailing stop loss.
  //   `,
  // },
  MTF: {
    tag_name: 'Multi time frame analysis',
    tag_short_desc: 'Same but different',
    tag_description: `Ever wondered what the price of your stock is doing in a different timeframe? It may not necessarily be the same. Now here’s the deal - Just because your trading timeframe is in an uptrend doesn’t mean your higher timeframe is in an uptrend. There’s a possibility the higher timeframe trend is against your trading timeframe - and that puts the odds against you.
    `,
  },
}
