import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Link } from 'react-router-dom'

import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'

import {
  ICONS, COLORS, SPACING, theme,
} from '../../Theme'
import { pathname_mapping } from '../../utils/navigationConstant'
import { useTheme } from '../../Theme/ThemeProvider'

const renderRow = ({
  title, subTitle, iconProps, link, styles,
}) => {
  return (
    <Link to={link} className={styles.rowBtn} onClick={e => e.stopPropagation()}>
      <Icon size={15} {...iconProps} />
      <div className={styles.info}>
        <CustomText weight="medium" className={styles.title}>{title}</CustomText>
        <CustomText color="textSecondary" size="small_0">{subTitle}</CustomText>
      </div>
      <Icon
        name={ICONS.FORWARD}
        className={styles.rightIcon}
        size={9}
        color={theme.textSecondary}
      />
    </Link>
  )
}

const InProgressSidebar = React.memo(() => {
  const styles = stylesheet()
  const { isDark } = useTheme()

  return (
    <div className={styles.inProgressSideSection}>
      <div className={styles.strategySection}>
        <CustomText className={styles.header} size="regular_1" weight="semi_bold">Strategies</CustomText>
        {renderRow({
          styles,
          title: 'My Strategies',
          subTitle: 'Created by you',
          link: pathname_mapping.MyStrategy,
          iconProps: {
            name: ICONS.FILTER_SETTINGS,
            color: isDark
              ? COLORS.VOILET_100 : COLORS.BLUE,
          },
        })}
        {renderRow({
          styles,
          title: 'Discover',
          subTitle: 'Strategies',
          iconProps: { name: ICONS.SEARCH, color: isDark ? COLORS.VOILET_100 : COLORS.BLUE },
          link: pathname_mapping.Discover,
        })}
        {renderRow({
          styles,
          title: 'Deployed Strategies',
          subTitle: 'Live and paper',
          iconProps: { name: ICONS.PARTIAL_3, color: isDark ? COLORS.VOILET_100 : COLORS.BLUE },
          link: pathname_mapping.live,
        })}
      </div>
      <div className={styles.scannerSection}>
        <CustomText className={styles.header} size="regular_1" weight="semi_bold">Scanner</CustomText>
        {renderRow({
          styles,
          title: 'My Scans',
          subTitle: 'Created by you',
          iconProps: { name: ICONS.SCAN, color: isDark ? COLORS.VOILET_100 : COLORS.BLUE },
          link: pathname_mapping.my_scans,
        })}
        {renderRow({
          styles,
          title: 'Discover',
          subTitle: 'Scanners',
          iconProps: { name: ICONS.SEARCH, color: isDark ? COLORS.VOILET_100 : COLORS.BLUE },
          link: pathname_mapping.popular,
        })}
        {renderRow({
          styles,
          title: 'Live scans',
          subTitle: 'Live',
          iconProps: { name: ICONS.EMBED_CODE, color: isDark ? COLORS.VOILET_100 : COLORS.BLUE },
          link: pathname_mapping.my_scans,
        })}
      </div>
    </div>
  )
})

const stylesheet = makeStyles({
  inProgressSideSection: {},
  rowBtn: {
    display: 'flex',
    alignItems: 'center',
    // width: '100%',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    '&:hover': {
      backgroundColor: theme.hover,
      '& $rightIcon': {
        fill: COLORS.BLUE,
      },
    },
    '& svg': {
      // marginTop: SPACING.SPACE_4,
    },
  },
  rightIcon: {
    alignSelf: 'center',
  },
  info: {
    marginLeft: SPACING.SPACE_16,
    flex: 1,
    textAlign: 'left',
  },
  title: {},
  strategySection: {
    paddingTop: SPACING.SPACE_10,
    marginBottom: SPACING.SPACE_10,
  },
  scannerSection: {
    marginTop: SPACING.SPACE_20,
    marginBottom: SPACING.SPACE_10,
  },
  header: {
    padding: `0 ${SPACING.SPACE_20} ${SPACING.SPACE_12}`,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    width: '32px',
    borderRadius: '5px',
    justifyContent: 'center',
  },
  icon: {
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_8}`,
    background: theme.inProgressIconBg,
    borderRadius: SPACING.SPACE_4,
  },
})

export default InProgressSidebar
