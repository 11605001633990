import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'
// import debounce from 'lodash/debounce'

import classNames from 'classnames'
import Icon from './Icon'
import Swipe from './Swipe'
import StreakCarousalLoader from './Loaders/StreakCarousalLoader'

import {
  COLORS, ICONS, SPACING, theme,
} from '../Theme'
import { withTheme } from '../Theme/ThemeProvider'
import { changePtComponentStatus } from '../containers/Common/actions'

// minHeight: height of card will not decrease more than this
// minWidth: width of card will not decrease more than this
// baseWidth, baseHeight: base dimensions, otherwise it will calculate
// width, height: give if you do not want decrease the dimensions
// navDisabled: u can pass navDisabled in the data if you do not wish to select a card

class StreakCarousal extends PureComponent {
  constructor(props) {
    super(props)
    const { data = [], defaultIndex = 0, maxItemWidth = 0 } = props
    const { listRef } = this.calcDynamicPos(data, defaultIndex)
    this.state = {
      viewWidth: 1,
      scrollType: 'next',
      multiItemWidth: maxItemWidth,
      mulitItemHeight: 1,
      // list: data,
      listRef,
      selectedIndex: defaultIndex,
      opacity: 0,
    }
    this.containerRef = React.createRef()
    this.sliderRef = React.createRef()
    this.multiViewItemRef = React.createRef()
  }

  componentDidMount() {
    this.calcWidthTimer = setTimeout(() => this.calculateDim(), 800)
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      data, defaultIndex, defaultDim, innerWidth, sideBarState, updateComponentPtStatus, loading,
    } = this.props

    const { opacity } = this.state
    if (data.length !== prevProps.data.length && this.sliderRef.current) {
      const { listRef } = this.calcDynamicPos(data)
      this.calculateDim({ selectedIndex: 0, listRef })
    }

    if (defaultIndex !== prevProps.defaultIndex) {
      this.goToIndex(defaultIndex)
    }

    if (!isEqual(defaultDim, prevProps.defaultDim)
    || (prevProps.innerWidth !== innerWidth)
    || (sideBarState
         && !isEqual(sideBarState, prevProps.sideBarState)
         && sideBarState[window.location.pathname])) {
      if (this.calcWidthTimer) clearTimeout(this.calcWidthTimer)
      this.calcWidthTimer = setTimeout(() => this.calculateDim(), 500)
    }

    if ((!isEqual(loading, prevProps.loading) && !loading)
      || (!isEqual(opacity, prevState.opacity) && opacity !== 0)) {
      updateComponentPtStatus('carouselLoaded')
    }
  }

  componentWillUnmount() {
    if (this.calcWidthTimer) clearTimeout(this.calcWidthTimer)
    if (this.showTimer) clearTimeout(this.showTimer)
    if (this.scrollTime) clearTimeout(this.scrollTime)
  }

  calcDynamicPos = (data, moveToIndex) => {
    const listLength = data.length
    const middleEleIndex = Math.floor((listLength) / 2)
    let listRef = data.map((item, index) => {
      if (index > middleEleIndex) return index - listLength
      return index
    })
    if (moveToIndex) {
      listRef = this.shiftArray(moveToIndex - 1, listRef)
    }
    return { listRef }
  }

  calculateDim = (extraChanges = {}) => {
    const { defaultDim = {}, itemHeight, data = [] } = this.props
    let viewWidth = 1
    let changes = {}
    const { maxItemWidth = 0 } = this.props
    if (this.sliderRef.current) {
      const { offsetHeight = 1, offsetWidth = 1 } = this.containerRef.current
      viewWidth = offsetWidth
      changes = { viewWidth, viewHeight: offsetHeight }
    }
    const {
      height, baseHeight, baseWidth, width,
    } = defaultDim
    if (this.multiViewItemRef.current) {
      const { offsetWidth, scrollHeight } = this.multiViewItemRef.current

      let multiItemWidth = baseWidth || width || viewWidth / Math.floor(viewWidth / offsetWidth)
      multiItemWidth = multiItemWidth > maxItemWidth ? maxItemWidth : multiItemWidth

      changes = {
        ...changes,
        multiItemWidth,
        mulitItemHeight: itemHeight || height || baseHeight || scrollHeight,
      }
    }
    this.setState({
      ...changes, ...extraChanges,
    })
    if (this.showTimer) clearTimeout(this.showTimer)
    const time = 50 * data.length
    this.showTimer = setTimeout(() => {
      this.setState({ opacity: 1 })
    }, (time > 600 ? 600 : time))
  }

  onNavButtonPress = () => {
    const { data, onNavButtonPress } = this.props
    const { scrollType, selectedIndex } = this.state
    if (onNavButtonPress) {
      onNavButtonPress(selectedIndex, { scrollType, item: data[selectedIndex] })
    }
  }

  shiftArray = (shiftBy, arr, reverse = false) => {
    const modArr = [...arr]
    const len = arr.length
    if (reverse) {
      modArr.unshift(...modArr.splice(len - shiftBy, shiftBy))
    } else {
      modArr.push(...modArr.splice(0, shiftBy))
    }
    return modArr
  }

  calcIndex = (index, inc) => {
    const { data } = this.props
    let modIndex = index
    if (inc) {
      modIndex += 1
    } else {
      modIndex -= 1
    }
    if (!data[modIndex]) {
      modIndex = inc ? 0 : data.length - 1
    }
    // if the card s disabled skip the card
    if (data[modIndex].navDisabled) {
      return this.calcIndex(modIndex, inc)
    }
    return modIndex
  }

  goNext = () => {
    this.setState((prevState) => {
      const { listRef, selectedIndex } = prevState
      const index = this.calcIndex(selectedIndex, true)
      const modListRef = this.shiftArray(
        Math.abs(index - selectedIndex), listRef, index - selectedIndex > 0,
      )
      // console.log(listRef, modListRef, selectedIndex, index, 'lol')

      return { listRef: modListRef, selectedIndex: index, scrollType: 'next' }
    }, this.onNavButtonPress)
  }

  goBack = () => {
    this.setState((prevState) => {
      const { listRef, selectedIndex } = prevState
      const index = this.calcIndex(selectedIndex, false)
      const modListRef = this.shiftArray(
        Math.abs(index - selectedIndex), listRef, index - selectedIndex > 0,
      )
      return { listRef: modListRef, selectedIndex: index, scrollType: 'back' }
    }, this.onNavButtonPress)
  }

  goToIndex = (i) => {
    this.setState((prevState) => {
      const { listRef, selectedIndex } = prevState
      const shiftBy = selectedIndex - i
      let reverse = true
      let scrollType = 'next'
      if (shiftBy > 0) {
        reverse = false
        scrollType = 'back'
      }
      const modListRef = this.shiftArray(Math.abs(shiftBy), listRef, reverse)
      return { listRef: modListRef, selectedIndex: i, scrollType }
    })
  }

  renderNavButtons = () => {
    const {
      styles, navBtnSize = 32, data, isDark,
    } = this.props
    // const { list } = this.state
    const listLength = data.length
    if (listLength < 2) return null
    const extraStyles = {
      height: navBtnSize,
      width: navBtnSize,
      borderRadius: navBtnSize * 0.17,
      top: `calc(49% - ${navBtnSize / 2}px)`,
    }

    const iconSize = navBtnSize * 0.35
    const iconColor = isDark ? COLORS.BLACK_600 : COLORS.BLUE
    return (
      <>
        {(
          <button
            type="button"
            onClick={this.goNext}
            className={styles.navBtn}
            style={{ ...extraStyles, right: -(navBtnSize / 2) }}
          >
            <Icon name={ICONS.FORWARD} color={iconColor} size={iconSize} />
          </button>
        )}
        {(
          <button
            type="button"
            onClick={this.goBack}
            className={styles.navBtn}
            style={{ ...extraStyles, left: -(navBtnSize / 2) }}
          >
            <Icon name={ICONS.BACK} color={iconColor} size={iconSize} />
          </button>
        )}
      </>
    )
  }

  decrementCalc = (dBy = 8, multiplier, value, minValue = 50) => {
    const dec = dBy * multiplier // dBy + (dBy * (multiplier / 10))
    const finalValue = value - (value * (dec / 100))
    return finalValue < minValue ? minValue : finalValue
  }

  calcLeftPos = (indexRef, offsetLeft, multiItemWidth) => {
    const { defaultDim = {} } = this.props
    let left = 0
    const { minWidth, width } = defaultDim
    if (width) {
      left = offsetLeft - (multiItemWidth / 2) + (indexRef * multiItemWidth)
    } else if (indexRef > 0) {
      const offset = offsetLeft + (multiItemWidth / 2)
      for (let i = 1; i < indexRef; i++) {
        left += this.decrementCalc(10, i, multiItemWidth, minWidth)
      }
      left += offset
    } else if (indexRef < 0) {
      const offset = offsetLeft - (multiItemWidth / 2)
      for (let i = indexRef; i < 0; i++) {
        left += this.decrementCalc(10, Math.abs(i), multiItemWidth, minWidth)
      }
      left = offset - left
    } else {
      left = offsetLeft - (multiItemWidth / 2)
    }
    return left
  }

  renderMultiView = () => {
    const {
      viewRenderer, styles, keyName, defaultDim = {}, data = [],
    } = this.props
    const {
      multiItemWidth, listRef,
      mulitItemHeight, viewWidth, scrollType,
    } = this.state
    const {
      height: defaultHeight, width: defaultWidth, minWidth, minHeight,
    } = defaultDim
    const offsetLeft = viewWidth / 2
    return data.map((item, itemIndex) => {
      let key = ''
      let itemRenderer
      if (typeof item === 'string') {
        key = item
      } else {
        key = `${item[keyName]}`;
        ({ itemRenderer } = item)
      }
      let width = 'unset'
      let viewHeight = 'unset'
      let extraStyles = {}
      const diff = listRef[itemIndex]
      let left = 0
      if (multiItemWidth > 1 && diff !== undefined) {
        if (diff !== 0) {
          width = defaultWidth
            || this.decrementCalc(10, Math.abs(diff), multiItemWidth, minWidth)
          viewHeight = defaultHeight
            || this.decrementCalc(8, Math.abs(diff), mulitItemHeight, minHeight)
        } else {
          width = multiItemWidth
          viewHeight = defaultHeight || mulitItemHeight
        }
        left = this.calcLeftPos(diff, offsetLeft, multiItemWidth)
        // console.log(diff, left, listRef, 'lol viewWidth')
        extraStyles = {
          transform: `translateX(${left}px)`,
          width,
          opacity: Math.abs(diff) <= Math.ceil(viewWidth / (2 * multiItemWidth)) ? 1 : 0,
          height: viewHeight > 1 ? viewHeight : 'unset',
        }
      }
      const extraProps = !diff ? { ref: this.multiViewItemRef } : {}
      return (
        <div
          {...extraProps}
          className={styles.multiViewItemWrapper}
          style={{ ...extraStyles }}
          key={key}
          id={`scItem_${itemIndex}`}
        >
          {itemRenderer ? itemRenderer(item)
            : viewRenderer(item, itemIndex, { isFocused: diff === 0, scrollType })}
        </div>
      )
    })
  }

  render() {
    const {
      styles, showNav, defaultDim = {}, contianerStyles = '', loading, displayDevice,
      data, isMobile,
    } = this.props
    const { mulitItemHeight, opacity } = this.state
    const { height: defaultHeight } = defaultDim
    const sliderClasses = styles.sliderMultiView
    const isLoading = (loading || opacity === 0)
    const listLength = data.length
    return (
      <div className={classNames(styles.sCarousal, contianerStyles)} ref={this.containerRef}>
        {isMobile && listLength >= 2 ? (
          <Swipe onSwipeLeft={this.goBack} onSwipeRight={this.goNext} key={Math.random()} />
        ) : null}
        <div className={styles.sCarousalWrapper} style={{ minHeight: isLoading ? 156 : 'unset' }}>
          {isLoading && <StreakCarousalLoader displayDevice={displayDevice} />}
          <div
            className={classNames(styles.slider, sliderClasses)}
            ref={this.sliderRef}
            style={{
              height: defaultHeight || (mulitItemHeight > 1 ? mulitItemHeight : 100),
              opacity,
            }}
          >
            {this.renderMultiView()}
          </div>
          {showNav && this.renderNavButtons()}
        </div>
      </div>
    )
  }
}

const stylesheet = ({
  sCarousal: {
    width: '100%',
    height: '100%',
    minHeight: 100,
    minWidth: 100,
  },
  sCarousalWrapper: {
    width: '100%',
    position: 'relative',
  },
  slider: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    '& *': {
      transition: 'all 350ms cubic-bezier(0.15, 0.47, 0, 1.16) 0s',
    },
  },
  slideView: {

  },
  navBtn: {
    position: 'absolute',
    backgroundColor: theme.navBtnBg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `0px 4px 6px ${theme.navBtnShadow}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  dotBtn: {
    margin: `${SPACING.SPACE_16} ${SPACING.SPACE_4} 0`,
  },
  sliderMultiView: {
    // overflow: 'hidden',
    display: 'inline-flex',
    width: 'inherit',
    // justifyContent: 'center',
    alignItems: 'center',
    // transition: 'all 150ms cubic-bezier(0.65, -0.15, 0.2, 1.13) 0s',
  },
  multiViewItemWrapper: {
    display: 'block',
    position: 'absolute',
    opacity: 0,
  },
})

const mapDispatchToProps = dispatch => ({
  updateComponentPtStatus: status => dispatch(changePtComponentStatus(status)),
})

const mapStateToProps = (state) => {
  return {
    sideBarState: state.common.websiteConfig.sideBarState,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(StreakCarousal))

StreakCarousal.defaultProps = {
  showNav: false,
  defaultIndex: 0, // index you want to put in center
  defaultDim: {}, // if you do not wish to change the dimension of items
  keyName: '',
}

StreakCarousal.propTypes = {
  viewRenderer: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  showNav: PropTypes.bool,
  defaultDim: PropTypes.object,
  defaultIndex: PropTypes.number,
  keyName: PropTypes.string, // unique key for each item in array
}
