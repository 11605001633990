import React, { useState } from 'react'

import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'

import {
  ICONS, COLORS, theme, SPACING,
} from '../../Theme'

const ScreenerInput = React.memo(({
  styles,
  onPress,
  fancyValue,
  addCondition,
  indicator,
  index,
  inputErr,
  prevCondition,
  mathFunc,
  multiIndex,
  isDark,
  removeField,
  canDeleteAdd,
  toBeRemoved: toBeRemovedProps = false,
  startTour,
}) => {
  const [toBeRemoved, updateToBeRemove] = useState(false)
  let inputStyle = {}
  if (toBeRemoved || toBeRemovedProps) {
    inputStyle = {
      ...inputStyle,
      textDecoration: 'line-through',
      color: COLORS.RED,
      opacity: '0.4',
      backgroundColor: theme.borderColor,
      borderRadius: 4,
    }
  }

  if (!indicator.name) {
    const color = inputErr ? 'RED' : 'ORANGE'
    return (
      <div className={styles.addBtnWrapper}>
        {!startTour
        && (
        <button
          type="button"
          onClick={() => addCondition(prevCondition, mathFunc, index, multiIndex)}
          className={styles.addBtn}
          style={{ borderColor: color, ...inputStyle }}
        >
          <Icon
            name={ICONS.ADD}
            color={COLORS[color]}
            size={10}
            style={{ marginRight: SPACING.SPACE_6 }}
          />
          <CustomText weight="semi_bold" size="small_0" color={color}>Add</CustomText>
        </button>
        )}
        {canDeleteAdd && (
        <button
          type="button"
          className={styles.addDeleteIconBtn}
          onClick={() => { updateToBeRemove(false); removeField(index, multiIndex) }}
          onMouseEnter={() => updateToBeRemove(true)}
          onMouseLeave={() => updateToBeRemove(false)}
        >
          <Icon name={ICONS.CLOSE} color={COLORS[isDark ? 'WHITE' : 'GREY']} size={8} />
        </button>
        )}
      </div>
    )
  }
  const backgroundColor = inputStyle.backgroundColor
    ? inputStyle.backgroundColor : COLORS[isDark ? 'BLUE_300' : 'BLUE_100']
  const color = inputStyle.color || 'blue'
  return (
    <div
      className={styles.inputBtnWrapper}
      style={{
        backgroundColor,
      }}
    >
      <button
        type="button"
        onClick={() => onPress(indicator, index, multiIndex)}
        className={styles.inputBtn}
        style={inputStyle}
        disabled={startTour}
      >
        <CustomText size="small" color={isDark ? 'white' : color}>{fancyValue}</CustomText>
      </button>
      {!startTour
      && (
      <button
        type="button"
        className={styles.deleteIconBtn}
        onClick={() => { updateToBeRemove(false); removeField(index, multiIndex) }}
        onMouseEnter={() => updateToBeRemove(true)}
        onMouseLeave={() => updateToBeRemove(false)}
        style={{ backgroundColor: COLORS[isDark ? 'BLUE_300' : 'BLUE_100'] }}
        disabled={startTour}
      >
        <Icon name={ICONS.CLOSE} color={COLORS[isDark ? 'WHITE' : 'GREY']} size={8} />
      </button>
      )}
    </div>
  )
})

export default ScreenerInput
