import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import classnames from 'classnames'

import CustomText from './CustomText'
import Button from './Button'

import { SPACING, ASSETS } from '../Theme'
import { useTheme } from '../Theme/ThemeProvider'

const EmptyContainer = ({
  containerStyles,
  imgStyles,
  imgSrc,
  actionRenderer,
  title,
  subTitle,
  contentStyles,
  imgContainerStyles,
  subTitleStyles = '',
  titleProps = {},
  titleStyles = '',
  id = 'empty_img',
  btnText,
  onPress,
}) => {
  const styles = stylesheet()
  const { isDark } = useTheme()
  return (
    <div className={classnames(styles.container, containerStyles)} id={id}>
      <div className={classnames(styles.imgContainer, imgContainerStyles)}>
        <img src={ASSETS[isDark ? `${imgSrc}_DARK` : imgSrc] || imgSrc} className={classnames(styles.img, imgStyles)} alt={id} />
      </div>
      <div className={classnames(styles.content, contentStyles)}>
        <CustomText weight="semi_bold" className={classnames(styles.title, titleStyles)} {...titleProps}>{title}</CustomText>
        {Boolean(subTitle) && (
        <CustomText
          weight="regular"
          className={classnames(styles.subTitle, subTitleStyles)}
          color="textSecondary"
        >
          {subTitle}
        </CustomText>
        )}
        {actionRenderer ? actionRenderer() : (
          <Button
            onPress={onPress}
            transparent
            labelColor="blue"
            mode="text"
            labelSize="regular"
            text={btnText}
          />
        )}
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  container: {},
  imgContainer: {
    textAlign: 'center',
    padding: `${SPACING.SPACE_24} 0 ${SPACING.SPACE_20}`,
  },
  img: {
    maxWidth: 120,
  },
  content: {
    textAlign: 'center',
  },
  title: {},
  subTitle: {
    marginTop: SPACING.SPACE_4,
  },
})

export default EmptyContainer
