/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useState } from 'react'
import { ICONS } from '../../Theme' // COLORS,
// import Expandable from '../../UI/Expandable'
import Icon from '../../UI/Icon'
import ASSETS from '../assets'
import FeaturesInfoResponsive from './FeaturesInfoResponsive'

const {
  FeatureScanner, FeatureBacktest, FeatureDeploy, FeatureTechnicals,
  // FeatureVideoBackground,
} = ASSETS

const content = [
  {
    label: 'Scanner',
    subTitle: 'Find a relevant stock',
    description: 'Stop relying on tips. Find a list of healthy stocks that matches your idea.',
    imgSrc: ICONS.FEATURE_SCANNER,
    content: () => (
      <>
        <p
          className="featuresDesc"
          style={{
            color: '#3E4190', display: 'flex', flexDirection: 'column', textAlign: 'left', padding: '0 20px 26px', gap: '10px',
          }}
        >
          <span style={{ fontFamily: 'nobel-bold' }}>Find a relevant stock </span>
          <span style={{ fontFamily: 'nobel-light' }}>Stop relying on tips. Find a list of healthy stocks that matches your idea.
          </span>
        </p>
      </>
    ),
    imgStyles: { width: 28 },
    video: FeatureScanner,
    timeout: 11000,
  },
  {
    label: 'Technicals',
    subTitle: 'Reports & Analysis on Your Fingertips',
    description: 'Avoid reading complex data & chart patterns. Get a real-time snapshot of your stocks.',
    imgSrc: ICONS.FEATURE_TECHNICALS,
    content: () => (
      <>
        <p
          className="featuresDesc"
          style={{
            color: '#3E4190', display: 'flex', flexDirection: 'column', textAlign: 'left', padding: '0 20px 26px', gap: '10px',
          }}
        >
          <span style={{ fontFamily: 'nobel-bold' }}>Reports & Analysis on Your Fingertips</span>
          <span style={{ fontFamily: 'nobel-light' }}>Avoid reading complex data & chart patterns. Get a real-time snapshot of your stocks.
          </span>
        </p>
      </>
    ),
    imgStyles: { width: 20 },
    video: FeatureTechnicals,
    timeout: 22000,
  },
  {
    label: 'Backtest',
    imgSrc: ICONS.FEATURE_BACKTEST,
    subTitle: 'Test Your Ideas Before Trading',
    description: 'Get a backtest done to see what worked in the past and what might possibly work in the future.',
    content: () => (
      <>
        <p
          className="featuresDesc"
          style={{
            color: '#3E4190', display: 'flex', flexDirection: 'column', textAlign: 'left', padding: '0 20px 26px', gap: '10px',
          }}
        >
          <span style={{ fontFamily: 'nobel-bold' }}>Test Your Ideas Before Trading</span>
          <span style={{ fontFamily: 'nobel-light' }}>Get a backtest done to see what worked in the past and what might possibly work in the future.
          </span>
        </p>
      </>
    ),
    imgStyles: { width: 20 },
    video: FeatureBacktest,
    timeout: 24000,
  },
  {
    label: 'Live Deployment',
    imgSrc: ICONS.FEATURE_DEPLOY,
    subTitle: 'Enter Even Before the Prices Make a Move',
    description: 'Track & trade in real time with just a click of a button.',
    content: () => (
      <>
        <p
          className="featuresDesc"
          style={{
            color: '#3E4190', display: 'flex', flexDirection: 'column', textAlign: 'left', padding: '0 20px 26px', gap: '10px',
          }}
        >
          <span style={{ fontFamily: 'nobel-bold' }}>Enter Even Before the Prices Make a Move</span>
          <span style={{ fontFamily: 'nobel-light' }}>Track & trade in real time with just a click of a button.</span>
        </p>
      </>
    ),
    imgStyles: { width: 20 },
    video: FeatureDeploy,
    timeout: 26000,
  },
]

let prevIndex = 0

const FeaturesInfo = React.memo(({ isMobile, onPressTrial, updateGA }) => {
  const [index, updateIndex] = useState(0)
  const [isHover, setIsHover] = useState('')
  // const [expandKey, updateKey] = useState([content[0].label])

  // const onUpdateExpansion = (item) => {
  //   const indexKey = expandKey.indexOf(item)
  //   let newExpanded = expandKey
  //   if (indexKey > -1) {
  //     newExpanded = expandKey.filter((key) => {
  //       return key !== item
  //     })
  //     updateKey(newExpanded)
  //   } else {
  //     newExpanded = [item]
  //     updateKey(newExpanded)
  //   }
  // }

  React.useEffect(() => {
    let updateTimer = null
    const timeout = content[index].timeout
    updateTimer = setTimeout(() => {
      let modIndex = index + 1
      modIndex = modIndex >= content.length ? 0 : modIndex
      prevIndex = index
      updateIndex(modIndex)
    }, timeout)
    return () => {
      if (updateTimer) clearTimeout(updateTimer)
    }
  }, [index])

  // const renderRow = (item) => {
  //   const { label, imgSrc, imgStyles } = item
  //   return (
  //     <div className={expandKey.includes(label) ? 'featureButtonsActive' : 'featureButtons'}>
  //       <div style={{
  //         display: 'flex', padding: '20px 20px', gap: '20px', alignItems: 'center',
  //       }}
  //       >
  //         <Icon name={imgSrc} color={expandKey.includes(label) ? COLORS.BLUE : COLORS.GREY} size={imgStyles.width} />
  //         <p className={expandKey.includes(label) ? 'infoLeftActive' : 'infoLeft'} style={{ marginBottom: '0px' }}>{label}</p>
  //       </div>
  //       {expandKey.includes(label) ? item.content() : null}
  //     </div>
  //   )
  // }

  return (
  //     <div className="homeWrap infoWrap">
  //       <div style={{ marginBottom: '50px', textAlign: '-webkit-center' }}>
  //         <h2 className="infoFeaturesTitle" style={{ color: 'var(--text)', fontWeight: 'bolder' }}>Everything You Need to Trade</h2>
  //         <div className="infoFeaturesSubTitle" style={{ color: '#9F9F9F' }}>Simple, Quick & Smart Trading For You</div>
  //       </div>
  //       {!isMobile ? (
  //         <div className="infoContainer">
  //           <div className="infoLeft">
  //             <div className="infoLeftContent">
  //               {content.map((item, i) => {
  //                 const { label, imgSrc, imgStyles } = item
  //                 return (
  //                   <button key={label} onClick={() => { prevIndex = index; updateIndex(i) }} type="button" className={index === i ? 'featureButtonsActive' : 'featureButtons'}>
  //                     <div style={{
  //                       display: 'flex', padding: '20px 20px', gap: '20px', alignItems: 'center',
  //                     }}
  //                     >
  //                       <Icon name={imgSrc} color={index === i ? COLORS.BLUE : COLORS.GREY} size={imgStyles.width} />
  //                       <p className={index === i ? 'infoLeftActive' : 'infoLeft'}>{label}</p>
  //                     </div>
  //                     { index === i ? item.content() : null}
  //                   </button>
  //                 )
  //               })}
  //             </div>
  //           </div>
  //           <img src={FeatureVideoBackground} alt="background" className="infoRightBgImg" />
  //           <div className="infoRight">
  //             {content.map((item, i) => {
  //               const { label, video } = item
  //               let classes = ''
  //               const active = i === index
  //               if (active) {
  //                 classes = prevIndex > i && prevIndex !== content.length - 1 && i !== 0 ? 'scrollFromUp' : 'scrollFromDown'
  //               } else if (i === prevIndex) {
  //                 classes = index > i || (i === content.length - 1 && index === 0) ? 'scrollToUp' : 'scrollToDown'
  //               }
  //               return (
  //                 <>
  //                   <div key={label} className={`infoRightContent ${classes}`} style={{ height: '100%' }}>
  //                     <video
  //                       src={video}
  //                       autoPlay
  //                       muted
  //                       loop
  //                       style={{
  //                         borderRadius: 12, width: '100%', height: '100%',
  //                       }}
  //                     />
  //                   </div>
  //                 </>
  //               )
  //             })}
  //             <div className="infoRightButtons">
  //               {content.map((item, i) => {
  //                 const { label } = item
  //                 return (
  //                   <input
  //                     key={label}
  //                     onClick={() => { prevIndex = index; updateIndex(i) }}
  //                     type="button"
  //                     className={`${index === i ? 'activeButton' : 'inactiveButton'}`}
  //                   />
  //                 )
  //               })}
  //             </div>

  //     </div>
  //   </div>
  // ) : (
  //   <div className="infoContainer">
  //     {content.map((item, i) => {
  //       const { label, video } = item
  //       return (
  //         <Expandable
  //           key={label}
  //           title={() => renderRow(item, i)}
  //           defaultExpanded={expandKey.includes(label)}
  //           onExpandCallback={onUpdateExpansion}
  //           expandKey={label}
  //           showArrow={false}
  //           headerContainerStyles="headerContainerStyles"
  //           contentStyles="featureContentStyles"
  //           showWhenExpanded={expandKey.includes(label)}
  //         >
  //           {/* <div key={label} className="infoRightContent" style={{ height: '100%', border: '1px solid blue' }} /> */}
  //           {/* {item.content()} */}
  //           <video src={video} autoPlay muted loop style={{ borderRadius: 12, width: '100%', height: '100%' }} />
  //         </Expandable>
  //       )
  //     })}
  //   </div>
  // )}
  // </div>
    <div className="homeWrap infoBannerWrap">
      <div style={{ maxWidth: '1600px', margin: 'auto' }}>
        <div style={{ marginBottom: '50px', textAlign: '-webkit-center' }}>
          <h1 className="startedTitle" style={{ fontSize: `${isMobile && '30px'}`, paddingBottom: `${isMobile && '10px'}` }}>Everything You Need to Trade</h1>
          <div className="startedSubTitle" style={{ fontSize: `${isMobile && '18px'}` }}>Simple, Quick & Smart Trading For You</div>
        </div>
        {isMobile ? <FeaturesInfoResponsive content={content} index={index} prevIndex={prevIndex} updateIndex={updateIndex} onPressTrial={onPressTrial} updateGA={updateGA} />
          : (
            <div className="infoBannerContainer">
              <div className="infoBannerLeft">
                <div className="infoBannerLeftContent">
                  {content.map((item, i) => {
                    const { label, imgSrc, imgStyles } = item
                    return (
                      <button key={label} onClick={() => { prevIndex = index; updateIndex(i); setIsHover(''); updateGA(`Tool ${label}`, label, 1, 'Landing Page') }} type="button" className={index === i ? 'featureButtonsActive' : 'featureButtons'}>
                        <div
                          style={{
                            display: 'flex', margin: '20px', paddingBottom: '10px', gap: '20px', alignItems: 'center', borderBottom: `${index === i ? '1.5px solid #d6dbff' : ''}`,
                          }}
                          onMouseEnter={() => setIsHover(index !== i ? label : '')}
                          onMouseLeave={() => setIsHover('')}
                        >
                          <Icon name={imgSrc} color={isHover === label ? '#445DFF' : '#000651'} size={imgStyles.width} strokeColor={isHover === label ? '#445DFF' : '#000651'} />
                          <p className="infoBannerLeftLabel" style={{ fontFamily: `${index === i ? 'multi-display-bold' : 'multi-display-regular'}` }}>{label}</p>
                        </div>
                        { index === i ? item.content() : null}
                      </button>
                    )
                  })}
                  <button onClick={() => { updateGA('Start Trial B Tools', 'Tools', 1, 'Landing Page'); onPressTrial() }} type="button" style={{ width: 'fit-content' }}>
                    <p className="learnMore">Start Trial</p>
                  </button>
                </div>
              </div>
              <div className="infoBannerRight">
                {content.map((item, i) => {
                  const { label, video } = item
                  // let classes = ''
                  // const active = i === index
                  // if (active) {
                  //   classes = prevIndex > i && prevIndex !== content.length - 1 && i !== 0 ? 'scrollFromUp' : 'scrollFromDown'
                  // } else if (i === prevIndex) {
                  //   classes = index > i || (i === content.length - 1 && index === 0) ? 'scrollToUp' : 'scrollToDown'
                  // }
                  return (
                    <>
                      <div key={label} className={`infoBannerRightContent ${index !== i ? 'scrollFromUp' : 'scrollFromDown'}`} style={{ height: '100%' }}>
                        <video
                          src={video}
                          autoPlay
                          muted
                          loop
                          style={{
                            borderRadius: 12, width: '100%', height: '100%',
                          }}
                        />
                      </div>
                    </>
                  )
                })}
                {/* <div className="infoRightButtons">
                {content.map((item, i) => {
                  const { label } = item
                  return (
                    <input
                      key={label}
                      onClick={() => { prevIndex = index; updateIndex(i) }}
                      type="button"
                      className={`${index === i ? 'activeButton' : 'inactiveButton'}`}
                    />
                  )
                })}
              </div> */}

              </div>
            </div>
          ) }
      </div>
    </div>
  )
})

export default FeaturesInfo
