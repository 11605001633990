import React from 'react'
import PropsTypes from 'prop-types'

const Checkbox = ({
  value,
  onValueChange,
  className,
  id = '',
  childPos,
  children,
  params,
  disabled,
  round,
}) => {
  const valueChangeHandler = (e) => {
    if (disabled) return
    onValueChange({ checked: e.target.checked, ...params })
  }
  return (
    <label className={`cCheckboxLabel ${round ? 'cCheckboxRound' : ''} ${className}`} htmlFor={`checkbox_${id}`}>
      {childPos === 'left' && children}
      <input
        className="cCheckboxInput "
        type="checkbox"
        id={`checkbox_${id}`}
        checked={Boolean(value)}
        onChange={valueChangeHandler}
      />
      <span className="cCheckboxTick" />
      {childPos !== 'left' && children}
    </label>
  )
}

export default Checkbox

Checkbox.defaultProps = {
  childPos: 'right',
  params: {},
  disabled: false,
  round: false,
  className: '',
}

Checkbox.propTypes = {
  value: PropsTypes.bool.isRequired,
  onValueChange: PropsTypes.func.isRequired,
  id: PropsTypes.string.isRequired,
  childPos: PropsTypes.oneOf(['left', 'right']),
  params: PropsTypes.object,
  disabled: PropsTypes.bool,
  round: PropsTypes.bool,
  className: PropsTypes.string,
}
