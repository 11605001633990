export const GET_INSTRUMENTS_INIT = 'GET_INSTRUMENTS_INIT'
export const GET_INSTRUMENTS_SUCCESS = 'GET_INSTRUMENTS_SUCCESS'
export const GET_INSTRUMENTS_FAILURE = 'GET_INSTRUMENTS_FAILURE'

export const UPDATE_TICKS = 'UPDATE_TICKS'
export const SUBSCRIBE_TOKENS = 'SUBSCRIBE_TOKENS'
export const UNSUBSCRIBE_TOKENS = 'UNSUBSCRIBE_TOKENS'

export const GET_EXCHANGE_TOKEN_INIT = 'GET_EXCHANGE_TOKEN_INIT'
export const GET_EXCHANGE_TOKEN_SUCCESS = 'GET_EXCHANGE_TOKEN_SUCCESS'
export const GET_EXCHANGE_TOKEN_FAILURE = 'GET_EXCHANGE_TOKEN_FAILURE'

export const WEBSOCKET_CONNECTION = 'WEBSOCKET_CONNECTION'
