import {
  baseUrl, IN_PRODUCTION, kiteLoginUrl, upstoxLoginUrl,
  paisaLoginUrl, webBaseUrl, iciciLoginUrl, abLoginUrl,
} from './apiEndpoints'
import {
  COLORS, ICONS, DIMENSIONS, ASSETS, theme,
} from '../Theme'

const { HEIGHT, WIDTH } = DIMENSIONS

const {
  BASIC_BOX, PREMIUM_BOX, FREE_PLAN, // FREE_BOX
  KiteLogo, ABLogo, FivePaisaLogo, StreakLogo, EXPLORER, PRO,
  UpstoxLogo, ICICILogo,
} = ASSETS

export const vw = WIDTH / 375
export const vh = HEIGHT / 812
export const tabViewHeight = 288 * vh

// Copied from world_v4
export const DEPLOYMENT_TYPE = {
  PAPER: { name: 'Paper Trading', value: 'paperTrading', backend: 'Paper trading' },
  // AUTO: { name: 'Auto Trading', value: 'autoTrading', backend: 'Auto trading' },
  NOTIFICATION: { name: 'Notification Trading', value: 'notificationTrading', backend: 'Notifications' },
}

export const date_range_epochs = {
  min: 2505600, // 1month
  '3min': 7689600,
  '5min': 7689600,
  '10min': 7689600,
  '15min': 7689600,
  '30min': 7689600,
  hour: 31449600,
  day: 157593600,
}

export const candle_freq_map = {
  '1 Minute': 10,
  '3 Minutes': 9,
  '5 Minutes': 8,
  '10 Minutes': 7,
  '15 Minutes': 6,
  '30 Minutes': 5,
  '1 Hour': 4,
  '1 Day': 3,
}

export const candle_map = {
  min: '1 Minute',
  '3min': '3 Minutes',
  '5min': '5 Minutes',
  '10min': '10 Minutes',
  '15min': '15 Minutes',
  '30min': '30 Minutes',
  hour: '1 Hour',
  day: '1 Day',
  '1 Minute': 'min',
  '3 Minutes': '3min',
  '5 Minutes': '5min',
  '10 Minutes': '10min',
  '15 Minutes': '15min',
  '30 Minutes': '30min',
  '1 Hour': 'hour',
  '1 Day': 'day',
  '2min': '2 Minutes',
  '4min': '4 Minutes',
  '2hour': '2 Hours',
  '3hour': '3 Hours',
  '4hour': '4 Hours',
}

export const condensed_candle_map = {
  min: 'min',
  '3min': '3min',
  '5min': '5min',
  '10min': '10min',
  '15min': '15min',
  '30min': '30min',
  hour: 'hour',
  day: 'day',
  '1 Minute': 'min',
  '3 Minutes': '3min',
  '5 Minutes': '5min',
  '10 Minutes': '10min',
  '15 Minutes': '15min',
  '30 Minutes': '30min',
  '1 Hour': 'hour',
  '1 Day': 'day',
  '1m': 'min',
  '3m': '3min',
  '5m': '5min',
  '10m': '10min',
  '15m': '15min',
  '30m': '30min',
  '1H': 'hour',
  '1D': 'day',
}

export const candleIntervals = {
  '1 Minute': 'min',
  '3 Minutes': '3min',
  '5 Minutes': '5min',
  '10 Minutes': '10min',
  '15 Minutes': '15min',
  '30 Minutes': '30min',
  '1 Hour': 'hour',
  '1 Day': 'day',
}

export const candleIntervalsShort = {
  min: '1m',
  '3min': '3m',
  '5min': '5m',
  '10min': '10m',
  '15min': '15m',
  '30min': '30m',
  hour: '1H',
  day: '1D',
}

export const freq_candle_map = {
  10: 'min',
  9: '3min',
  8: '5min',
  7: '10min',
  6: '15min',
  5: '30min',
  4: 'hour',
  3: 'day',
}

// time frame string to minutes map (for sorting)
export const time_frame_min_map = {
  min: 1,
  '3min': 3,
  '5min': 5,
  '10min': 10,
  '15min': 15,
  '30min': 30,
  hour: 60,
  day: 1440,
}

export const candle_min_map = {
  10: 1,
  9.5: 2,
  9: 3,
  8.5: 4,
  8: 5,
  7: 10,
  6: 15,
  5: 30,
  4: 60,
  3.6: 120,
  3.4: 180,
  3.2: 240,
  3: 1440,
}

export const mtf_candle_map = {
  9.5: '2min',
  8.5: '4min',
  3.6: '2hour',
  3.4: '3hour',
  3.2: '4hour',
}

export const rev_candle = {
  min: 10,
  '3min': 9,
  '5min': 8,
  '10min': 7,
  '15min': 6,
  '30min': 5,
  hour: 4,
  day: 3,
}

export const rev_mtf_candle = {
  '2min': 9.5,
  '4min': 8.5,
  '2hour': 3.6,
  '3hour': 3.4,
  '4hour': 3.2,
}

export const tf_map = ['min', '3min', '5min', '10min', '15min', '30min', 'hour', 'day', '2min', '4min', '2hour', '3hour', '4hour']

export const holding_type_map = {
  MIS: 'MIS',
  'CNC/NRML': 'CNC/NRML',
  CNC: 'CNC/NRML',
}

export const COMMODITY_MULTIPLIER = {
  ALUMINI: 1000,
  ALUMINIUM: 5000,
  CARDAMOM: 100,
  CASTORSEED: 100,
  COPPER: 2500,
  COPPERM: 250,
  COTTON: 25,
  CPO: 1000,
  CRUDEOIL: 100,
  CRUDEOILM: 10,
  GOLD: 100,
  GOLDGUINEA: 1,
  GOLDM: 10,
  GOLDPETAL: 1,
  LEAD: 5000,
  LEADMINI: 1000,
  MENTHOIL: 360,
  NATURALGAS: 1250,
  NICKEL: 1500,
  NICKELM: 100,
  PEPPER: 10,
  RBDPMOLEIN: 1000,
  SILVER: 30,
  SILVERM: 5,
  SILVERMIC: 1,
  ZINC: 5000,
  ZINCMINI: 1000,
  NATGASMINI: 250,
}

export const COMMODITY_TYPE = {
  ALUMINI: 'NAGRI',
  ALUMINIUM: 'NAGRI',
  CARDAMOM: 'AGRI',
  CASTORSEED: 'AGRI',
  COPPER: 'NAGRI',
  COPPERM: 'NAGRI',
  COTTON: 'IAGRI',
  CPO: 'IAGRI',
  CRUDEOIL: 'NAGRI',
  CRUDEOILM: 'NAGRI',
  GOLD: 'NAGRI',
  GOLDGUINEA: 'NAGRI',
  GOLDM: 'NAGRI',
  GOLDPETAL: 'NAGRI',
  LEAD: 'NAGRI',
  LEADMINI: 'NAGRI',
  MENTHOIL: 'NAGRI',
  NATURALGAS: 'NAGRI',
  NICKEL: 'NAGRI',
  NICKELM: 'NAGRI',
  PEPPER: 'AGRI',
  RBDPMOLEIN: 'NAGRI',
  SILVER: 'NAGRI',
  SILVERM: 'NAGRI',
  SILVERMIC: 'NAGRI',
  ZINC: 'NAGRI',
  ZINCMINI: 'NAGRI',
  KAPAS: 'IAGRI',
  NATGASMINI: 'NAGRI',
}

export const CURRENT_APP_VERSION = '0.0.1'

export const CURRENT_APP_VERSION_NUMBER = 1

export const renkoParams = {
  name: 'Renko',
  syntax: 'Renko(<period>)',
  params: [
    [
      'Brick size',
      1,
      true,
    ],
  ],
  tooltip: 'Renko Chart',
}

export const optionUniverse = {
  'Nifty Option Current': 'NIFTY_OPT_CURRENT',
  'Nifty Option Next': 'NIFTY_OPT_NEXT',
  'BankNifty Option Current': 'BANKNIFTY_OPT_CURRENT',
  'BankNifty Option Next': 'BANKNIFTY_OPT_NEXT',
  'Nifty Weekly': 'NIFTY_WEEKLY',
  'BankNifty Weekly': 'BANKNIFTY_WEEKLY',
  'Nifty Weekly Current': 'NIFTY_WEEKLY_CURRENT',
  'BankNifty Weekly Current': 'BANKNIFTY_WEEKLY_CURRENT',
  'FinNifty Option Current': 'FINNIFTY_OPT_CURRENT',
  'FinNifty Option Next': 'FINNIFTY_OPT_NEXT',
  'FinNifty Weekly': 'FINNIFTY_WEEKLY',
  'FinNifty Weekly Current': 'FINNIFTY_WEEKLY_CURRENT',
}

const ULTIMATE_UNIVERSE = Object.keys(optionUniverse)

export const NOT_ALLOWED_UNIVERSE = {
  0: ULTIMATE_UNIVERSE,
  1: ULTIMATE_UNIVERSE,
  2: ULTIMATE_UNIVERSE,
  2.5: ULTIMATE_UNIVERSE,
  3: [],
}

export const sector_map = {
  'Nifty 50': 'Nifty50',
  'Nifty 100': 'Nifty100',
  'Nifty 500': 'Nifty500',
  'NFO Equities': 'NFOequity',
  INDICES: 'INDICES',
  'NFO-FUT': 'NFO',
  MCX: 'MCX',
  CDS: 'CDS',
  Energy: 'Energy',
  'Communication Services': 'Communication Services',
  Materials: 'Materials',
  'Consumer Discretionary': 'Consumer Discretionary',
  'Consumer Staples': 'Consumer Staples',
  Industrials: 'Industrials',
  'Health Care': 'Health Care',
  'Information Technology': 'Information Technology',
  Financials: 'Financials',
  Utilities: 'Utilities',
  'Real Estate': 'Real Estate',
  'Nifty Weekly': 'NIFTY_WEEKLY',
  'BankNifty Weekly': 'BANKNIFTY_WEEKLY',
  ...optionUniverse,
}

export const lightboxParams = {
  screen: 'streak.Loader',
  overrideBackPress: true,
  backgroundBlur: 'dark',
  tapBackgroundToDismiss: false,
}

export const subscriptionTypeMap = {
  basic: 1,
  premium: 2,
  ultimate: 3,
  regular: 2.5,
}

export const renewPlan = {
  1: true,
  3: false,
  6: false,
  12: false,
}

export const stateMap = [
  'Andaman & Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra & Nagar Haveli',
  'Daman & Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
]

export const lightBoxProps = {
  style: {
    tapBackgroundToDismiss: true,
    backgroundColor: 'rgba(0,0,0,.4)',
  },
}

export const getValueFromKey = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}

export const ALGO_ITEM_ACTIONS = {
  // LISTVIEW: 'listview',
  // TABVIEW: 'tabview',
  INFO: 'info',
  MORE: 'more',
  RESULTS: 'results',
  COPY: 'copy',
  EDIT: 'edit',
  DELETE: 'delete',
  FAVORITE: 'mark_favorite',
  VIEW_BACKTEST: 'view_backtest',
  DEPLOY: 'deploy',
  ITEM_SELECTED: 'item_selected',
}

export const technicalsPageLimit = 10

export const analysisTabMap = {
  technicals: { label: 'Technicals', value: 'technicals' },
  resistance: { label: 'Support & Resistance', value: 'resistance' },
}

export const timeRanges = {
  '1 min': 'min',
  '3 mins': '3min',
  '5 mins': '5min',
  '15 mins': '15min',
  '30 mins': '30min',
  hour: 'hour',
  day: 'day',
}

export const platformKeys = {
  android: 0,
  ios: 1,
  websocket: 2,
  chrome: 3,
  firefox: 5,
}

export const transConfig = {}

export const promoConfig = {}

export const notifApp = {
  TRANS: 'TRANS',
  PROMO: 'PROMO',
}

export const actions = {
  BUY: 'BUY',
  SELL: 'SELL',
}

export const modals = {
  BuySell: 'buySell',
  // AddAccount: 'addAccount' //TODO: Remove (from world)

}

export const REWARDS_STATUS_MAP = {
  0: 'Login',
  1: 'Signed Up',
  2: 'Subscribed',
  default: 'Referred',
}

export const SOCIAL_HANDLER = {
  TWITTER: {
    handler: '@StreakTech',
    shareText: referral_deeplink => `Now you too can %23strategy %23trade without %23coding with @StreakTech, sign up now, ${referral_deeplink}`,
  },
  FACEBOOK: {
    shareText: () => 'Now you too can strategy trade without coding, sign up now.',
  },
}

export const shareModalOptions = {
  title: 'Share Strategy',
  message: 'Hey, Check out this strategy on streak platform',
  subject: 'Hey, Check out this strategy',
  failOnCancel: false,
}

// TODO: REPLACE THESE ICON NAMES WITH ICONS.<NAME>
export const getOrderlogMap = () => ({
  'STRATEGY EXPIRED': { ICON_COLOR: COLORS.BLACK, PROGRESS_ICON: ICONS.EXPIRED, TAG_COLOR: theme.text },
  EXPIRED: { ICON_COLOR: COLORS.BLACK, PROGRESS_ICON: ICONS.EXPIRED, TAG_COLOR: theme.text },
  'AT EXCHANGE': {
    ICON_COLOR: COLORS.BLACK, PROGRESS_ICON: ICONS.AT_EXCHANGE, TAG_ICON: '', TAG_COLOR: theme.text,
  },
  BOUGHT: {
    ICON_COLOR: COLORS.BLUE, PROGRESS_ICON: ICONS.BASKET, TAG_ICON: '', TAG_COLOR: theme.linkColor,
  },
  BUY: {
    ICON_COLOR: COLORS.BLUE, PROGRESS_ICON: ICONS.CART, TAG_ICON: '', TAG_COLOR: theme.linkColor,
  },
  'BUY ALERT': {
    ICON_COLOR: COLORS.BLUE,
    PROGRESS_ICON: ICONS.ALERT_ROUND_FILLED,
    TAG_ICON: ICONS.ALERT_ROUND_FILLED,
    TAG_COLOR: theme.linkColor,
  },

  'CANCEL SL-M ORDER': {
    ICON_COLOR: COLORS.RED, PROGRESS_ICON: ICONS.CLOSE_FILLED_OUTLINED, TAG_ICON: '', TAG_COLOR: COLORS.RED,
  },
  'CANCEL SL ORDER': {
    ICON_COLOR: COLORS.RED, PROGRESS_ICON: ICONS.CLOSE_FILLED_OUTLINED, TAG_ICON: '', TAG_COLOR: COLORS.RED,
  },
  COMPLETED: {
    ICON_COLOR: COLORS.GREEN, PROGRESS_ICON: ICONS.TICK_ROUND_FILLED, TAG_ICON: '', TAG_COLOR: COLORS.GREEN,
  },
  COMPLETE: {
    ICON_COLOR: COLORS.GREEN, PROGRESS_ICON: ICONS.TICK_ROUND_FILLED, TAG_ICON: '', TAG_COLOR: COLORS.GREEN,
  },
  CANCELLED: {
    ICON_COLOR: COLORS.RED, PROGRESS_ICON: ICONS.CLOSE_FILLED_OUTLINED, TAG_ICON: '', TAG_COLOR: COLORS.RED,
  },
  ENTERED: {
    ICON_COLOR: COLORS.BLUE, PROGRESS_ICON: ICONS.ENTERED, TAG_ICON: '', TAG_COLOR: theme.textTertiary,
  },
  'FORCE STOPPED': {
    ICON_COLOR: COLORS.RED, PROGRESS_ICON: ICONS.STOP, TAG_ICON: '', TAG_COLOR: COLORS.RED,
  },
  'PLACE S-M ORDER': {
    ICON_COLOR: COLORS.BLUE, PROGRESS_ICON: ICONS.AT_EXCHANGE, TAG_ICON: '', TAG_COLOR: theme.textTertiary,
  },
  'PLACE SL-M ORDER': {
    ICON_COLOR: COLORS.YELLOW_600, PROGRESS_ICON: ICONS.SLM_ORDER, TAG_ICON: '', TAG_COLOR: COLORS.YELLOW_600,
  },
  'PLACE SL ORDER': {
    ICON_COLOR: COLORS.YELLOW_600, PROGRESS_ICON: ICONS.SLM_ORDER, TAG_ICON: '', TAG_COLOR: COLORS.YELLOW_600,
  },
  SOLD: {
    ICON_COLOR: COLORS.ORANGE, PROGRESS_ICON: ICONS.BASKET, TAG_ICON: '', TAG_COLOR: COLORS.ORANGE,
  },
  SELL: {
    ICON_COLOR: COLORS.ORANGE, PROGRESS_ICON: ICONS.CART, TAG_ICON: '', TAG_COLOR: COLORS.ORANGE,
  },
  'SELL ALERT': {
    ICON_COLOR: COLORS.ORANGE,
    PROGRESS_ICON: ICONS.ALERT_ROUND_FILLED,
    TAG_ICON: ICONS.ALERT_ROUND_FILLE,
    TAG_COLOR: COLORS.ORANGE,
  },
  'STOP LOSS ALERT': {
    ICON_COLOR: COLORS.RED,
    PROGRESS_ICON: ICONS.ALERT_ROUND_FILLED,
    TAG_ICON: ICONS.ALERT_ROUND_FILLED,
    TAG_COLOR: COLORS.RED,
  },
  'TRAILING STOP LOSS ALERT': {
    ICON_COLOR: COLORS.RED,
    PROGRESS_ICON: ICONS.ALERT_ROUND_FILLED,
    TAG_ICON: ICONS.ALERT_ROUND_FILLED,
    TAG_COLOR: COLORS.RED,
  },
  'SL-M CANCELLED': { ICON_COLOR: COLORS.RED, PROGRESS_ICON: ICONS.CLOSE_FILLED, TAG_COLOR: theme.text },
  'TARGET PROFIT ALERT': {
    ICON_COLOR: COLORS.GREEN,
    PROGRESS_ICON: ICONS.ALERT_ROUND_FILLED,
    TAG_ICON: ICONS.ALERT_ROUND_FILLED,
    TAG_COLOR: COLORS.GREEN,
  },
  'USER ACTION': { ICON_COLOR: COLORS.BLACK, PROGRESS_ICON: ICONS.USER_ACTION, TAG_COLOR: theme.text },
  WAITING: { ICON_COLOR: COLORS.BLACK, PROGRESS_ICON: ICONS.CLOCK, TAG_COLOR: theme.text },
  STOPPED: { ICON_COLOR: COLORS.RED, PROGRESS_ICON: ICONS.STOP, TAG_COLOR: COLORS.RED },
  REJECTED: {
    ICON_COLOR: COLORS.RED,
    PROGRESS_ICON: ICONS.CLOSE_FILLED_OUTLINED,
    TAG_ICON: ICONS.CLOSE_FILLED,
    TAG_COLOR: COLORS.RED,
  },
  'PAPER TRADING': {
    ICON_COLOR: COLORS.GREEN, PROGRESS_ICON: ICONS.PAPER_FILLED, TAG_ICON: '', TAG_COLOR: COLORS.GREEN,
  },
})

export const error_msg = 'Error getting response'

export const technicalslabelMap = {
  Bearish: {
    MA: {
      text: '#FE9801', bg: '#FFF5E6', bgDark: '#312C25', icon: 'BEARISH',
    },
    DEFAULT: {
      text: '#EB1D54', bg: 'redLightBg', bgDark: 'redLightBg', icon: 'BEARISH',
    },
    OSCILLATOR: {
      text: '#FF667D', bg: '#FCE7EC', bgDark: 'redLightBg', icon: 'BEARISH',
    },
  },
  Neutral: {
    MA: {
      text: '#7B828B', bg: '#EBEFF5', bgDark: 'greyLightBg', icon: 'NEUTRAL',
    },
    DEFAULT: {
      text: '#7B828B', bg: '#EBEFF5', bgDark: 'greyLightBg', icon: 'NEUTRAL',
    },
    OSCILLATOR: {
      text: '#7B828B', bg: '#EBEFF5', bgDark: 'greyLightBg', icon: 'NEUTRAL',
    },
  },
  Bullish: {
    MA: {
      text: '#018EE8', bg: '#E0F3FF', bgDark: '#273138', icon: 'BULLISH',
    },
    DEFAULT: {
      text: '#3CBB00', bg: '#E2FFD5', bgDark: 'greenLightBg', icon: 'BULLISH',
    },
    OSCILLATOR: {
      text: '#034DE6', bg: '#E3ECFF', bgDark: '#313643', icon: 'BULLISH',
    },
  },
}

export const CONDENSED_SCANNER_COUNT = 8
export const SCANNER_PAGE_LIMIT = 6

export const refreshControlProps = {
  color: [COLORS.BLUE, COLORS.GREEN, COLORS.ORANGE],
  tintColor: COLORS.BLUE,
  progressBackgroundColor: COLORS.WHITE,
}

export const TOP_MOVERS_SUBS = 20

export const DEFAULT_TIME_FRAME = 'hour'

export const PRECISION = 2

export const algoDateFormat = 'DD/MM/YYYY'

export const FAVOURITE_PARAMS = {
  publishing_uuid: {
    // fav_type: 'market_place',
    filter: 'discover',
  },
  algo_subscription_uuid: {
    // fav_type: 'market_place',
    filter: 'discover',
  },
  algo_uuid: {
    // fav_type: 'my_algos',
    filter: 'strategy',
  },
  backtest_shared_uuid: {
    // fav_type: 'my_algos',
    filter: 'strategy',
  },
}

export const algoTypes = {
  MY_ALGOS: 'my_algos',
  DISCOVER: 'discover',
}

export const WELCOME_VIEWS = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  TWO_FA: '2fa',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password',
  LOADING: 'loading',
  VALIDATE_EMAIL: 'validate_email',
  NO_INTERNET: 'no_internet',
}

export const MARKETPLACE_TABS = {
  MARKETPLACE: { name: 'All', tabName: 'marketplace', index: 0 },
  SUBSCRIBED: { name: 'My Picks', tabName: 'purchased', index: 1 },
  PUBLISHED: { name: 'Published', tabName: 'published', index: 2 },
}

export const publish_status_mapping = {
  '-1': 'unpublished',
  0: 'submitted for review',
  1: 'live',
}

export const decimalRegex = /^\d*\.?\d*$/
export const decimalRegexAll = /^-?\d*\.?\d*$/
export const qtyRegex = /^[0-9][0-9]*$/

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phoneNumberRegex = /^[0-9]{10}$/

export const ctbPathRegex = /^\/login\/(?!create_session\/)([a-zA-Z0-9-]+\/)?create$/

export const algoNameRegex = /^[a-zA-Z0-9\s]*$/gi

export const planImageMap = {
  basic: BASIC_BOX,
  regular: EXPLORER,
  premium: PREMIUM_BOX,
  ultimate: PRO,
  free: FREE_PLAN,
  default: BASIC_BOX,
}

export const planBgMap = {
  basic: '#fff7f8',
  regular: '#fff7f8',
  premium: '#fff7f8',
  ultimate: '#FFEFE3',
  free: '#fff7f8',
  default: '#fff7f8',
}

export const brokerImgMap = [
  { imgSrc: KiteLogo, brokerName: 'Zerodha' },
  { imgSrc: ABLogo, brokerName: 'Angel One' },
  { imgSrc: UpstoxLogo, brokerName: 'Upstox', width: '28px' },
  { imgSrc: FivePaisaLogo, brokerName: '5Paisa', width: '28px' },
  { imgSrc: ICICILogo, brokerName: 'Icici' },
]

export const functionOptions = ['Indicators', 'Math functions', 'Bracket']
export const indicatorsOptions = ['Comparators', 'Bracket', 'Math operators', 'AND/OR']
export const mathFuncOptions = ['Comparators', 'Bracket', 'Math operators', 'AND/OR']
export const comparatorsOptions = ['Indicators', 'Math functions', 'Bracket']
export const operatorsOptions = ['Indicators', 'Math functions', 'Bracket']
export const bracketsOptions = {
  '(': ['Indicators', 'Math functions', 'Bracket', 'Math operators'],
  ')': ['Comparators', 'Bracket', 'Math operators', 'AND/OR'],
  default: ['Indicators', 'Math functions', 'Bracket', 'Math operators', 'Comparators'],
}

export const INDICATORS_FUNCS = {
  PRE_MARKET: {
    name: 'Pre market',
    key: 'pre_market',
  },
}

/**
 * It should contain multi Condition MATH FUNCS only
 */
export const MATH_FUNCS = {
  MULTI_TIME_FRAME: {
    key: 'multitime_frame',
    name: 'Multitime frame',
    allowComparators: true,
  },
  SYMBOL: {
    key: 'symbol',
    name: 'Symbol',
    allowComparators: true,
  },
  MULTI_TIMEFRAME_COMPLETED: {
    key: 'multitime_framep',
    name: 'Multitime frame completed',
    allowComparators: true,
  },
  MAX: {
    key: 'max',
    name: 'Max',
    allowComparators: false,
  },
  MIN: {
    key: 'min',
    name: 'Min',
    allowComparators: false,
  },
}

// Add mathFuncs and indicators name which are not allowed in create
export const ONLY_SCANNER_FUNC_NAMES = [
  // MATH_FUNCS.MULTI_TIME_FRAME.name,
  INDICATORS_FUNCS.PRE_MARKET.name,
]

export const ONLY_SCANNER_FUNC_KEYS = [
  INDICATORS_FUNCS.PRE_MARKET.key,
]

export const KITE_APP_PKG_NAME = 'com.zerodha.kite3'
export const GRAPH_FETCH_COUNT = '100'
export const STOCK_ADD_LIMIT = 50
export const BASKET_ADD_LIMIT = 100
// const ANDROID_VERSION_LOLLIPOP = 21
export const ANDROID_VERSION_MARSHMALLOW = 23
export const TECHINCAL_FETCH_LIMIT = 20

export const ASYNCSTORAGE_MAP = {
  AUTH_TOKEN: 'auth_token',
  TRANS: notifApp.TRANS,
  PROMO: notifApp.PROMO,
  SHOW_ONBOARDING: 'show_onboarding',
  USER_DATA: 'user_data',
  ICONS_IN_STORAGE: 'icons_in_storage',
  CONTACT_SYNC: 'contactSync',
  THEME: 'app_theme',
  STRATEGY_DETAILS_PINNED: 'pin_strategy',
  SHOW_ALGO_OB: 'strategy_demo',
  SHOW_SCANNER_OB: 'scanner_demo',
  SHOW_TECHNICALS_OB: 'technicals_demo',
  FREE_TRIAL_MODAL: 'free_trial',
}

export const ONBOARDING_ACTIVITY_TYPES = {
  ONBOARDING: 'onboarding',
  TOUR: 'tour',
}

export const ONBOARDING_TYPE_MAP = {
  SHOW_ALGO_OB: 'strategy_demo',
  SHOW_SCANNER_OB: 'scanner_demo',
  SHOW_TECHNICALS_OB: 'technicals_demo',
}

export const screenerIndicatorPattern = {
  conditions: [],
}

export const ULTIMATE_SEGMENT_OBJ = {
  mcx: 'mcx',
  'nfo-opt': 'nfo-opt',
}

export const ACCEPTED_SEGMENT = ['MCX', 'NSE', 'CDS', 'INDICES', 'NFO-FUT', 'NFO-OPT']

export const ULTIMATE_SEGMENTS = Object.values(ULTIMATE_SEGMENT_OBJ)

export const NOT_ALLOWED_SEGMENT = {
  0: ULTIMATE_SEGMENTS,
  1: ULTIMATE_SEGMENTS,
  2: ULTIMATE_SEGMENTS,
  2.5: ULTIMATE_SEGMENTS,
  3: [],
}

export const indicatorPattern = {
  indicator: 'Indicator',
  compareIndicator: 'Indicator',
  comparator: 'Comparator',
}

export const defaultChartList = ['Candlestick', 'Heikin-Ashi', 'Renko'] // do not change the order

export const CHART_ICON_MAP = {
  [defaultChartList[0]]: ICONS.CHART_CANDLESTICK,
  [defaultChartList[1]]: ICONS.CHART_HEIKIN_ASHI,
  [defaultChartList[2]]: ICONS.CHART_RENKO,
}

// name here represent backend values
export const BROKERS = {
  ZERODHA: {
    label: 'Kite',
    hostnames: ['streak.zerodha.com', 'streakv3.zerodha.com', 'streak.ninja', 'v3.streak.ninja'],
    logo: KiteLogo,
    name: 'zerodha', // do not change as this comes in user_details
    domains: ['zerodha.com'],
    serviceName: 'zerodha', // sent while logging in
    termsLink: 'https://pro.streak.tech/terms', // discarded now use termsPage instead
    brokerName: 'Kite',
    homepagePath: '/home/zerodha',
  },
  ANGEL: {
    label: 'Angel One',
    name: 'angel one',
    domains: ['angelbroking.com'],
    hostnames: ['streak.angelbroking.com', 'ab.streak.ninja'],
    logo: ABLogo,
    // name: 'Angel broking', // do not change as this comes in user_details
    serviceName: 'ab', // sent while logging in
    termsLink: 'https://pro.streak.tech/terms_ab', // discarded now use termsPage instead
    brokerName: 'Angel One',
    homepagePath: '/home/angel one',
  },
  NONE: {
    label: 'Streak',
    name: '-', // do not change as this comes in user_details
    domains: ['streak.tech'],
    hostnames: ['web.streak.tech', 'web.streak.ninja', 'web-streak.web.app'],
    serviceName: '-',
    legalName: '',
    termsLink: 'https://pro.streak.tech/terms', // discarded now use termsPage instead
    signURL: 'https://www.streak.tech/signup?utm_source=pureweb&utm_medium=organic&utm_campaign=Web-Streak-Site',
    logo: StreakLogo,
  },
  FIVE_PAISA: {
    label: '5 Paisa',
    name: '5paisa', // do not change as this comes in user_details
    key: 'FIVE_PAISA',
    domain: '5paisa.com',
    hostnames: ['streak.5paisa.com', '5paisa.streak.ninja'],
    serviceName: '5paisa', // sent while logging in
    termsLink: 'https://pro.streak.tech/terms_5paisa', // discarded now use termsPage instead
    logo: FivePaisaLogo,
    brokerName: '5 Paisa',
    homepagePath: '/home/5paisa',
  },
  UPSTOX: {
    key: 'UPSTOX',
    label: 'Upstox',
    name: 'upstox',
    domain: 'upstox.com',
    hostnames: ['streak.upstox.com'],
    serviceName: 'upstox',
    logo: UpstoxLogo,
    legalName: 'Upstox',
    signURL: 'https://login.upstox.com/',
    homepagePath: '/home/upstox',
  },
  ICICI: {
    key: 'ICICI',
    label: 'ICICIdirect',
    name: 'icici',
    domain: 'icicidirect.com',
    hostnames: ['streak.icici.com'],
    serviceName: 'icici',
    logo: ICICILogo,
    legalName: 'icici',
    signURL: 'https://login.icici.com/',
    homepagePath: '/home/icici',
  },
}

export const loginBrokerMap = {
  zerodha: BROKERS.ZERODHA.name,
  '5paisa': BROKERS.FIVE_PAISA.name,
  angel: BROKERS.ANGEL.name,
  upstox: BROKERS.UPSTOX.name,
  icici: BROKERS.ICICI.name,
}

export const WEBSOKET_BROKER_MAP = {
  '-': 'email',
  [BROKERS.ZERODHA.name]: 'kite',
  [BROKERS.ANGEL.name]: 'ab',
  [BROKERS.FIVE_PAISA.name]: '5paisa',
  [BROKERS.UPSTOX.name]: 'upstox',
  [BROKERS.ICICI.name]: 'icici',
}

export const ROOT_MODALS = {
  showAppExit: 'showAppExit',
  showEmailModal: 'showEmailModal',
}

export const googleOauthConfig = {
  webClientId: '955589138292-jvl0kui7atesqrfs2clqqaf3dh0gvt7c.apps.googleusercontent.com',
  scopes: [],
}

// web specific

export const orginMap = (env) => {
  if (env) {
    return { localhost: 1 }
  }
  return {
    'streak.ninja': 1, // streak.zerodha.com
    'v3.streak.ninja': 3, // streak.v3.zerodha.com
    'streak.v3.zerodha.com': 3,
    'ab.streak.ninja': 3,
    'streak.angelbroking.com': 3,
    '5paisa.streak.ninja': 3,
    'streak.5paisa.com': 3,
  }
}

const siteMap = {
  [BROKERS.ZERODHA.name]: IN_PRODUCTION ? 'https://streakv3.zerodha.com' : 'https://v3.streak.ninja',
  [BROKERS.FIVE_PAISA.name]: IN_PRODUCTION ? 'https://streak.5paisa.com/login' : 'https://5paisa.streak.ninja/login',
  [BROKERS.ANGEL.name]: IN_PRODUCTION ? 'https://streak.angelbroking.com/login' : 'https://ab-streak.web.app/login',
  [BROKERS.UPSTOX.name]: IN_PRODUCTION ? 'https://streak.upstox.com/login' : 'https://upstox-staging.firebaseapp.com/login',
  [BROKERS.NONE.name]: webBaseUrl,
}

export const origins = {
  // v1: () => `https://streak.${domain}`,
  v3: broker => siteMap[broker],
  v4: () => webBaseUrl,
  kite: kiteLoginUrl,
  paisa: paisaLoginUrl,
  upstox: upstoxLoginUrl,
  icici: iciciLoginUrl,
  ab: abLoginUrl,
}

export const DISPLAY_DEVICE = {
  MOBILE: {
    value: 0, valueKey: 'mobile',
  },
  TAB: {
    value: 1, valueKey: 'tab',
  },
  DESKTOP: {
    value: 2, valueKey: 'desktop',
  },
  FHD: {
    value: 3, valueKey: '2k',
  },
  UHD: {
    value: 4, valueKey: '4k',
  },
  SMALL_MOBILE: {
    value: 5, valueKey: 'smallMobile',
  },
}

export const TPSL_LIMIT = 1000

export const DAILY_STRATEGY_CYCLE = ['1', '2', '3', '4', '5', '10', '20', '50', '100', '-']
export const POSITION_SIZING_TYPE = {
  DEFAULT: '-',
  CAPITAL_BASED: 'Capital based',
  RISK_BASED: 'Risk based',
}
export const POSITION_SIZING_TYPES = Object.values(POSITION_SIZING_TYPE)
export const TPSL_TYPE = {
  PERCENTAGE: { label: 'Percentage (%)', value: 'pct' },
  ABSOLUTE: { label: 'Absolute (abs)', value: 'abs' },
  POINTS: { label: 'Points (pts)', value: 'pts' },
}
export const TPSL_TYPES = Object.values(TPSL_TYPE)
export const MAX_ALLOCATION_OBJ = (value) => {
  switch (value) {
    case POSITION_SIZING_TYPE.CAPITAL_BASED: {
      return ({
        placeholder: 'Amount per trade (Max Allocation)',
        label: 'Max Allocation',
      })
    }
    case POSITION_SIZING_TYPE.RISK_BASED: {
      return ({
        placeholder: 'Amount per trade (Max SL per trade)',
        label: 'Max SL per trade',
      })
    }
    default: {
      return ({
        placeholder: 'Amount per trade (Max Allocation)',
        label: 'Max Allocation',
      })
    }
  }
}

export const postionBtnMap = isDark => ([
  {
    label: 'Buy',
    value: 'Buy',
    selectedColor: 'VOILET',
    color: isDark ? 'VOILET_400' : 'VOILET_200',
    labelColor: isDark ? 'VOILET_500' : 'VOILET',
    selectedLabelColor: 'WHITE',
  },
  {
    label: 'Sell',
    value: 'Sell',
    selectedColor: 'RED',
    color: isDark ? 'DARK_RED_200' : 'RED_100',
    labelColor: isDark ? 'DARK_RED' : 'RED',
    selectedLabelColor: 'WHITE',
  },
])

export const razorpayKey = 'rzp_live_DGu6CguzBs0547' // rzp_test_aC9YzybiZ4puJ8 // rzp_live_DGu6CguzBs0547
export const razorpayCallback = `${baseUrl}paymentProcess/`

export const ORDER_TYPE = {
  MARKET: 'MARKET',
  LIMIT: 'LIMIT',
}

export const RECAPTCHKEY = 'Fv7qPPuL5LPUeXtG'

export const LINK_MAP = {
  MTF: {
    text: 'Multi-time frame can lead to unverifiable results due to partial candles',
    link: 'https://help.streak.tech/create_advanced/#multi-timeframe-functions',
  },
  MAX_ALLOC: {
    text: 'Note: Position sizing doesn\'t assume any margin requirements or leverage. It uses allocation and instrument price directly to calculate the order quantity. You should enter the allocation value such that margin requirements are being satisfied in your trading account for live trades. To check the margin requirements, visit your brokers\' website.',
  },
}

export const SEGMENT_CHARTS = ['NSE', 'NFO-OPT', 'NFO-FUT']

export const UPSTOX_SEGMENT_CONVERTOR = {
  NSE: 'NSE_EQ',
  'NFO-OPT': 'NSE_FO',
  'NFO-FUT': 'NSE_FO',
  MCX: 'MCX_FO',
  INDICES: 'NSE_INDEX',
}

export const AB_TESTING_MAP = {
  CREATE_PAGE: 'CREATE_PAGE',
}

export const AB_TESTING_STATUS = {
  [AB_TESTING_MAP.CREATE_PAGE]: true,
}

export const PURCHASEFLOW_MAP = {
  ViewContent: 'V',
  AddToCart: 'A',
  InitiateCheckout: 'I',
  Purchase: 'P',
}

export const states = ['Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Lakshadweep',
  'Puducherry']

export const APP_VERSION = '4.0.8'
// 4.1.111('MAJOR UPDATE.MAJOR FEATURE UPDATE.BUG FIX')

export const loginPageFeatures = [
  {
    title: 'Create Strategies',
    icon: ICONS.FEATURESTRATEGIES,
  },
  {
    title: 'Create Scanners',
    icon: ICONS.FEATURESCANNERS,
  },
  {
    title: 'Live Deployment',
    icon: ICONS.FEATURELIVE,
  },
  {
    title: 'Paper Trades',
    icon: ICONS.FEATUREPAPER,
  },
  {
    title: 'Technicals',
    icon: ICONS.FEATURETECHNICAL,
  },
]
