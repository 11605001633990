export const FETCH_MARKETPLACE_CHARTS_INIT = 'FETCH_MARKETPLACE_CHARTS_INIT'
export const FETCH_MARKETPLACE_CHARTS_SUCCESS = 'FETCH_MARKETPLACE_CHARTS_SUCCESS'
export const FETCH_MARKETPLACE_CHARTS_FAILURE = 'FETCH_MARKETPLACE_CHARTS_FAILURE'

export const CLEAR_BACKTEST_GRAPH_DATA = 'CLEAR_BACKTEST_GRAPH_DATA'

export const FETCH_MARKET_ALGOS_SUCCESS = 'FETCH_MARKET_ALGOS_SUCCESS'
export const FETCH_MARKET_ALGOS_FAILURE = 'FETCH_MARKET_ALGOS_FAILURE'
export const FETCH_MARKET_ALGOS_INIT = 'FETCH_MARKET_ALGOS_INIT'

export const FETCH_ONBOARDING_ALGOS_INIT = 'FETCH_ONBOARDING_ALGOS_INIT'

export const DEPLOY_PUBLISHED = 'DEPLOY_PUBLISHED'
export const DEPLOY_PUBLISHED_SUCCESS = 'DEPLOY_PUBLISHED_SUCCESS'
export const DEPLOY_PUBLISHED_FAILURE = 'DEPLOY_PUBLISHED_FAILURE'

export const CLEAR_MARKET_ALGO_DATA = 'CLEAR_MARKET_ALGO_DATA'

export const UPDATE_PUBLICATION_STATS = 'UPDATE_PUBLICATION_STATS'
export const UPDATE_PUBLICATION_STATS_SUCCESS = 'UPDATE_PUBLICATION_STATS_SUCCESS'
export const UPDATE_PUBLICATION_STATS_FAILURE = 'UPDATE_PUBLICATION_STATS_FAILURE'

export const SUBSCRIBE_ALGO_INIT = 'SUBSCRIBE_ALGO_INIT'
export const SUBSCRIBE_ALGO_SUCCESS = 'SUBSCRIBE_ALGO_SUCCESS'
export const SUBSCRIBE_ALGO_FAILURE = 'SUBSCRIBE_ALGO_FAILURE'

export const FETCH_SUBSCRIBED_CHARTS_INIT = 'FETCH_SUBSCRIBED_CHARTS_INIT'
export const FETCH_SUBSCRIBED_CHARTS_SUCCESS = 'FETCH_SUBSCRIBED_CHARTS_SUCCESS'
export const FETCH_SUBSCRIBED_CHARTS_FAILURE = 'FETCH_SUBSCRIBED_CHARTS_FAILURE'

export const FETCH_SUBSCRIBED_ALGO_INIT = 'FETCH_SUBSCRIBED_ALGO_INIT'
export const FETCH_SUBSCRIBED_ALGO_SUCCESS = 'FETCH_SUBSCRIBED_ALGO_SUCCESS'
export const FETCH_SUBSCRIBED_ALGO_FAILURE = 'FETCH_SUBSCRIBED_ALGO_FAILURE'

export const SUBSCRIBE_AND_DEPLOY_INIT = 'SUBSCRIBE_AND_DEPLOY_INIT'

export const MARKET_DEPLOY_STOCKS_INIT = 'MARKET_DEPLOY_STOCKS_INIT'
export const MARKET_DEPLOY_STOCKS_SUCCESS = 'MARKET_DEPLOY_STOCKS_SUCCESS'
export const MARKET_DEPLOY_STOCKS_FAILURE = 'MARKET_DEPLOY_STOCKS_FAILURE'

export const UPDATE_MARKET_SORT_ON = 'UPDATE_MARKET_SORT_ON'

export const FILTER_SUBSCRIBED_ALGO = 'FILTER_SUBSCRIBED_ALGO'
export const FILTER_SUBSCRIBED_ALGO_SUCCESS = 'FILTER_SUBSCRIBED_ALGO_SUCCESS'
export const FILTER_SUBSCRIBED_ALGO_FAILURE = 'FILTER_SUBSCRIBED_ALGO_FAILURE'

export const CLEAR_SUBSCRIBED_ALGO_DATA = 'CLEAR_SUBSCRIBED_ALGO_DATA'

export const FETCH_DISCOVER_CATEGORY = 'FETCH_DISCOVER_CATEGORY'
export const FETCH_DISCOVER_CATEGORY_SUCCESS = 'FETCH_DISCOVER_CATEGORY_SUCCESS'
export const FETCH_DISCOVER_CATEGORY_FAILURE = 'FETCH_DISCOVER_CATEGORY_FAILURE'

export const PAPER_TRADE_MARKET = 'PAPER_TRADE_MARKET'
export const PAPER_TRADE_MARKET_SUCCESS = 'PAPER_TRADE_MARKET_SUCCESS'
export const PAPER_TRADE_MARKET_FAILURE = 'PAPER_TRADE_MARKET_FAILURE'
