import React from 'react'
// import '../containers/Home/homepage.css'
import Auth from '../../containers/Auth/Auth-redux'

import { AUTH_ACTIVE_STATE_MAP } from '../../containers/Auth/Auth'

const DirectLogin = React.memo(({ domainChange, sharedLogin, isHomePage }) => {
  return (
    <div className="homeWrapper">
      <div className="directLoginContainer">
        <div className="directLoginContent">
          <Auth
            activeState={AUTH_ACTIVE_STATE_MAP.LOGIN}
            domainChange={domainChange}
            isHomePage={isHomePage}
            sharedLogin={sharedLogin}
            rightContentStyles="loginRightContent"
            rightContainerStyles="loginRightContainer"
            loginContainerStyles="loginContainer"
            loginBtnStyles="loginBtn"
            fieldInputStyles="loginFieldInput"
            forgotContainerStyles="forgotContainer"
            loginBrokerContainerStyles="loginBrokerContainer"
          />
        </div>
      </div>
    </div>
  )
})

export default DirectLogin
