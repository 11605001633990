import {
  SAVE_PROFILE_INIT,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILURE,
  CLEAR_PROFILE_RESP,
  GENERATE_OTP_INIT,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  REQUEST_DEMO_CALL,
  REQUEST_DEMO_CALL_SUCCESS,
  REQUEST_DEMO_CALL_FAILURE,
  IS_DEMO_REQUESTED,
  UPDATE_PROFILE_INIT,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  FETCH_FILE,
  FETCH_FILE_SUCCESS,
  FETCH_FILE_FAILURE,
  CLEAR_DEMO_CALL_RESP,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_INIT,
  REMOVE_PROFILE,
  REMOVE_PROFILE_FAILURE,
  REMOVE_PROFILE_SUCCESS,
  UPDATE_TRIAL_PLAN,
  UPDATE_TRIAL_PLAN_SUCCESS,
  UPDATE_TRIAL_PLAN_FAILURE,
  SUBMIT_FEEDBACK,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
  TOGGLE_REQUEST_DEMO,
  VERIFY_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER_SUCCESS,
  VERIFY_PHONE_NUMBER_FAILURE,
  SHOW_ADD_BROKER_POPUP,
  FETCH_SHAREABLE_LINKS,
  FETCH_SHAREABLE_LINKS_SUCCESS,
  FETCH_SHAREABLE_LINKS_FAILURE,
  DELETE_SHAREABLE_LINKS,
  DELETE_SHAREABLE_LINKS_SUCCESS,
  DELETE_SHAREABLE_LINKS_FAILURE,
} from './actionTypes'

const initialState = {
  isSaving: false,
  savingError: false,
  saveResp: {},
  generating: false,
  generatingError: false,
  generateResp: {},
  demoRequested: false,
  requestDemoError: false,
  requestDemoErrorMsg: '',
  isRequestingDemo: false,
  requestDemoSuccessMsg: '',
  updateProfileResp: {},
  isUpdatingProfile: false,
  updateProfileError: false,
  updateProfileErrorMsg: '',
  isFetchingFile: false,
  fetchFileError: false,
  fetchFileErrorMsg: '',
  showLoadingProfile: false,
  isChangingPassword: false,
  changePasswordResp: {},
  changePasswordError: false,
  changePasswordErrorMsg: '',
  updateFreeTrialSuccess: false,
  isUpdatingFreeTrial: false,
  updateFreeTrialError: false,
  updateFreeTrialErrorMsg: '',
  isSubmittingFeedback: false,
  feedbackResp: {},
  feedbackError: false,
  feedbackErrorMsg: '',

  showRequestDemoModal: false,
  isCarouselReqDemo: false,

  isVerifyingPhoneNumber: false,
  verifiedPhoneNumber: false,
  verifyPhoneNumberError: false,

  isFetchingShareableLinks: false,
  fetchShareableLinksError: false,
  fetchShareableLinksErrorMsg: '',
  shareableLinksData: [],
  totalPages: 0,

  isDeleteingShareableLinks: false,
  deleteShreableLinkResp: {},
  deleteShreableLinkError: false,
  deleteShreableLinkErrorMsg: '',
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PROFILE_INIT:
      return {
        ...state,
        isSaving: true,
        savingError: false,
        saveResp: {},
      }

    case SAVE_PROFILE_SUCCESS: {
      const { data } = action
      return {
        ...state,
        saveResp: data,
        isSaving: false,
      }
    }

    case SAVE_PROFILE_FAILURE:
      return {
        ...state,
        isSaving: false,
        saveResp: { msg: action.error },
        savingError: true,
      }

    case CLEAR_PROFILE_RESP:
      return {
        ...state,
        isSaving: false,
        savingError: false,
        saveResp: {},
        generating: false,
        generatingError: false,
        generateResp: {},
        isVerifyingPhoneNumber: false,
        verifiedPhoneNumber: false,
        verifyPhoneNumberError: false,
        // requestDemoError: false,
        // requestDemoErrorMsg: '',
        // isRequestingDemo: false,
        // requestDemoSuccessMsg: '',
      }

    case GENERATE_OTP_INIT:
      return {
        ...state,
        generating: true,
        generatingError: false,
      }

    case GENERATE_OTP_SUCCESS: {
      const { data } = action
      return {
        ...state,
        generating: false,
        generateResp: data,
      }
    }

    case GENERATE_OTP_FAILURE:
      return {
        ...state,
        generating: false,
        generatingError: true,
      }

    case REQUEST_DEMO_CALL:
      return {
        ...state,
        requestDemoError: false,
        requestDemoErrorMsg: '',
        isRequestingDemo: true,
        requestDemoSuccessMsg: '',
      }

    case REQUEST_DEMO_CALL_SUCCESS: {
      return {
        ...state,
        requestDemoSuccessMsg: action.msg,
        demoRequested: true,
        isRequestingDemo: false,
      }
    }

    case REQUEST_DEMO_CALL_FAILURE: {
      return {
        ...state,
        requestDemoError: true,
        requestDemoErrorMsg: action.error,
        isRequestingDemo: false,
      }
    }

    case CLEAR_DEMO_CALL_RESP: {
      return {
        ...state,
        requestDemoError: false,
        requestDemoErrorMsg: '',
        isRequestingDemo: false,
        requestDemoSuccessMsg: '',
      }
    }

    case TOGGLE_REQUEST_DEMO: {
      return {
        ...state,
        showRequestDemoModal: action.showDemo,
        isCarouselReqDemo: action.isCarouselReqDemo,
      }
    }

    case IS_DEMO_REQUESTED:
      return {
        ...state,
        demoRequested: action.data,
      }

    case UPDATE_PROFILE_INIT:
      return {
        ...state,
        isUpdatingProfile: true,
        updateProfileResp: {},
        updateProfileError: false,
      }

    case UPDATE_PROFILE_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isUpdatingProfile: false,
        updateProfileResp: data,
      }
    }

    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isUpdatingProfile: false,
        updateProfileError: true,
        updateProfileErrorMsg: action.error,
      }

    case CHANGE_PASSWORD_INIT:
      return {
        ...state,
        isChangingPassword: true,
        changePasswordResp: {},
        changePasswordError: false,
      }

    case CHANGE_PASSWORD_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isChangingPassword: false,
        changePasswordResp: data,
      }
    }

    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangingPassword: false,
        changePasswordError: true,
        changePasswordErrorMsg: action.error,
      }

    case UPLOAD_FILE:
      return {
        ...state,
        isUploading: true,
        uploadError: false,
        uploadSuccess: false,
        uploadErrorMsg: '',
        showLoadingProfile: true,
      }

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        uploadSuccess: true,
      }

    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isUploading: false,
        uploadError: true,
        uploadErrorMsg: action.error,
      }
    case REMOVE_PROFILE:
      return {
        ...state,
        isRemovingProfile: true,
        removeProfileErr: false,
        removeProfileSuccess: false,
        removeProfileErrMsg: '',
      }

    case REMOVE_PROFILE_SUCCESS:
      return {
        ...state,
        isRemovingProfile: false,
        removeProfileSuccess: true,
      }

    case REMOVE_PROFILE_FAILURE:
      return {
        ...state,
        isRemovingProfile: false,
        removeProfileErr: true,
        removeProfileErrMsg: action.error,
      }

    case FETCH_FILE:
      return {
        ...state,
        showLoadingProfile: true,
        isFetchingFile: true,
        fetchFileSuccess: false,
        fetchFileError: false,
        fetchFileErrorMsg: '',
        profileImg: null,
      }

    case FETCH_FILE_SUCCESS: {
      const { data = {} } = action
      return {
        ...state,
        isFetchingFile: false,
        fetchFileSuccess: true,
        profileImg: data,
        showLoadingProfile: false,
      }
    }

    case FETCH_FILE_FAILURE:
      return {
        ...state,
        isFetchingFile: false,
        fetchFileError: true,
        fetchFileErrorMsg: action.error,
        showLoadingProfile: false,
      }

    case UPDATE_TRIAL_PLAN: {
      return {
        ...state,
        updateFreeTrialSuccess: false,
        isUpdatingFreeTrial: true,
        updateFreeTrialError: false,
        updateFreeTrialErrorMsg: '',
      }
    }

    case UPDATE_TRIAL_PLAN_SUCCESS: {
      return {
        ...state,
        updateFreeTrialSuccess: true,
        isUpdatingFreeTrial: false,
      }
    }

    case UPDATE_TRIAL_PLAN_FAILURE: {
      return {
        ...state,
        isUpdatingFreeTrial: false,
        updateFreeTrialError: true,
        updateFreeTrialErrorMsg: action.error,
      }
    }

    case SUBMIT_FEEDBACK:
      return {
        ...state,
        isSubmittingFeedback: true,
        feedbackResp: {},
        feedbackError: false,
      }

    case SUBMIT_FEEDBACK_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isSubmittingFeedback: false,
        feedbackResp: data,
      }
    }

    case SUBMIT_FEEDBACK_FAILURE:
      return {
        ...state,
        isSubmittingFeedback: false,
        feedbackError: true,
        feedbackErrorMsg: action.error,
      }

    case VERIFY_PHONE_NUMBER:
      return {
        ...state,
        isVerifyingPhoneNumber: true,
        verifiedPhoneNumber: false,
        verifyPhoneNumberError: false,
      }

    case VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isVerifyingPhoneNumber: false,
        verifiedPhoneNumber: true,
      }

    case VERIFY_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        isVerifyingPhoneNumber: false,
        verifyPhoneNumberError: true,
        verifyPhoneNumberErrorMsg: action.error,
      }

    case SHOW_ADD_BROKER_POPUP: {
      const { params } = action
      return {
        ...state,
        showAddBrokerPopup: params,
      }
    }

    case FETCH_SHAREABLE_LINKS:
      return {
        ...state,
        isFetchingShareableLinks: true,
        fetchShareableLinksError: false,

      }

    case FETCH_SHAREABLE_LINKS_SUCCESS: {
      const { data = {}, params } = action
      const { kind } = params
      let shareableData = []
      const newShareableData = data.shareable_links.map((item) => {
        return { ...item }
      })

      if(kind === 'append') {
        const oldShareableData = [...state.shareableLinksData]
        shareableData = [...oldShareableData, ...newShareableData]
      } else {
        shareableData = [...newShareableData]
      }

      return {
        ...state,
        isFetchingShareableLinks: false,
        shareableLinksData: shareableData,
        totalPages: data.total_pages,
      }
    }

    case FETCH_SHAREABLE_LINKS_FAILURE:
      return {
        ...state,
        isFetchingShareableLinks: false,
        fetchShareableLinksError: true,
        fetchShareableLinksErrorMsg: action.error,
      }

    case DELETE_SHAREABLE_LINKS:
      return {
        ...state,
        isDeleteingShareableLinks: true,
        deleteShreableLinkError: false,
        deleteShreableLinkErrorMsg: '',
        deleteShreableLinkResp: {},

      }

    case DELETE_SHAREABLE_LINKS_SUCCESS: {
      const { data = {} } = action
      return {
        ...state,
        isDeleteingShareableLinks: false,
        deleteShreableLinkResp: data,
      }
    }

    case DELETE_SHAREABLE_LINKS_FAILURE:
      return {
        ...state,
        isDeleteingShareableLinks: false,
        deleteShreableLinkError: true,
        deleteShreableLinkErrorMsg: action.error,
      }

    default:
      return state
  }
}

export default account
