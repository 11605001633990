import { connect } from 'react-redux'

import ScreenHeader from './ScreenHeader'
import { toggleTour } from '../../containers/Common/actions'
import { logout } from '../../containers/Auth/actions'
import { showAddBrokerPopup } from '../../containers/Account/actions'

const mapStateToProps = (state) => {
  return {
    startTour: state.common.startTour,
    showAddBrokerPopup: state.account.showAddBrokerPopup,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    toggleTour: params => dispatch(toggleTour(params)),
    toggleAddBrokerPopup: params => dispatch(showAddBrokerPopup(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenHeader)
