/* eslint-disable max-len */
import { ICONS } from '../../Theme'
import { pathname_mapping } from '../../utils/navigationConstant'
import assets from '../../homepage/assets'
import { academyBaseUrl, helpPageUrl, mailToSupportUrl } from '../../utils/apiEndpoints'
// import { pathname_mapping } from '../../utils/navigationConstant'

export const productTourMap = {
  TECHNICALS: {
    icon: ICONS.PT_TECHNICALS,
    title: 'Technicals',
    description: 'Understand Trends of stocks',
    gradientBg: 'transparent linear-gradient(219deg, #72E2FF 0%, #35BAFF 100%) 0% 0% no-repeat padding-box',
    primaryBg: '#2CB3F2',
    secondaryBg: '#CEF4FF',
    textDark: '#578CA6',
  },
  SCANNERS: {
    icon: ICONS.PT_SCANNERS,
    title: 'Scanners',
    description: 'Filter Stocks Based on Indicators',
    gradientBg: 'transparent linear-gradient(220deg, #FFA566 0%, #FF5F96 100%) 0% 0% no-repeat padding-box',
    primaryBg: '#FF5F96',
    secondaryBg: '#FFD5CC',
    textDark: '#B05F75',
  },
  DEPLOY: {
    icon: ICONS.PT_DEPLOY,
    title: 'Deploy',
    description: 'Real Time Trading in Live Markets',
    gradientBg: 'transparent linear-gradient(222deg, #72EEBB 0%, #2ECFBB 100%) 0% 0% no-repeat padding-box',
    primaryBg: '#39D4BB',
    secondaryBg: '#D2F8EE',
    textDark: '#5B988E',
  },
  BACKTEST: {
    icon: ICONS.PT_BACKTEST,
    title: 'Backtest',
    description: 'Test Strategies on Past Data',
    gradientBg: 'transparent linear-gradient(224deg, #BB63FF 0%, #7427FF 100%) 0% 0% no-repeat padding-box',
    primaryBg: '#8D3CFF',
    secondaryBg: '#EAD4FF',
    textDark: '#856CAD',
  },
  NOIDEA: {
    icon: ICONS.PT_NOIDEA,
    title: 'No Idea',
    description: 'No idea where to start',
    gradientBg: 'transparent linear-gradient(218deg, #F156A4 0%, #D91979 100%) 0% 0% no-repeat padding-box',
    primaryBg: '#DF2884',
    secondaryBg: '#FFD9EC',
    textDark: '#945776',
  },
}

export const productTourVideoMap = {
  STRATEGIES: {
    title: 'Strategies',
    video: assets.PTStrategy,
  },
  TECHNICALS: {
    title: 'Technicals',
    video: assets.PTTechnicals,
  },
  Scanners: {
    title: 'Scanners',
    video: assets.PTScanner,
  },
}

export const PRODUCT_TOUR_SECTIONS = {
  TECHNICALS: 'TECHNICALS',
  TECHNICALS_CREATE: 'TECHNICALS_CREATE',
  SCANNERS: 'SCANNERS',
  SCANNERS_CREATE: 'SCANNERS_CREATE',
  DEPLOY: 'DEPLOY',
  BACKTEST: 'BACKTEST',
  NOIDEA: 'NOIDEA',
  CREATESTRATEGY: 'CREATESTRATEGY',
  FINISHED: 'FINISHED',
}

export const productTourSteps = {
  TECHNICALS_CREATE: 7,
  SCANNERS_CREATE: 9,
  CREATESTRATEGY: 15,
  TECHNICALS: 5,
  SCANNERS: 5,
  DEPLOY: 7,
  BACKTEST: 3,
  NOIDEA: 1,
}

export const mobileProductTourSteps = {
  TECHNICALS_CREATE: 7,
  SCANNERS_CREATE: 9,
  CREATESTRATEGY: 15,
  TECHNICALS: 5,
  SCANNERS: 6,
  DEPLOY: 7,
  BACKTEST: 2,
  NOIDEA: 1,
}

export const PRDOCUT_TOUR_PATHS = {
  TECHNICALS: pathname_mapping.marketwatch,
  TECHNICALS_CREATE: pathname_mapping.marketwatch,
  SCANNERS: pathname_mapping.Scanners,
  SCANNERS_CREATE: pathname_mapping.Scanners,
  DEPLOY: pathname_mapping.Strategies,
  BACKTEST: '/backtests?publishing_uuid=MTcwNTI0MmItYzM1MC00YmRmLWEwMWMtMDJkNTUzYjBlNmZl',
  CREATESTRATEGY: pathname_mapping.Strategies,
  NOIDEA: '',
}

export const PRODUCT_TOUR_GA_LABELS = {
  TECHNICALS: {
    0: 'Techs Pg 1',
    1: 'Techs Pg 2',
    2: 'Techs Pg 3',
    3: 'Techs Pg 4',
    4: 'Techs Pg 5',
  },
  TECHNICALS_CREATE: {
    0: 'Ct Tech Pg 1',
    1: 'Ct Tech Pg 2',
    2: 'Ct Tech Pg 3',
    3: 'Ct Tech Pg 4',
    4: 'Ct Tech Pg 5',
    5: 'Ct Tech Pg 6',
    6: 'Ct Tech Pg 7',
  },
  SCANNERS: {
    0: 'Disc Scan Pg 1',
    1: 'Disc Scan Pg 2',
    2: 'Disc Scan Pg 3',
    3: 'Disc Scan Pg 4',
    4: 'Disc Scan Pg 5',
  },
  CREATESTRATEGY: {
    0: 'Ct Strat Pg 1',
    1: 'Ct Strat Pg 2',
    2: 'Ct Strat Pg 3',
    3: 'Ct Strat Pg 4',
    4: 'Ct Strat Pg 5',
    5: 'Ct Strat Pg 6',
    6: 'Ct Strat Pg 7',
    7: 'Ct Strat Pg 8',
    8: 'Ct Strat Pg 9',
    9: 'Ct Strat Pg 10',
    10: 'Ct Strat Pg 11',
    11: 'Ct Strat Pg 12',
    12: 'Ct Strat Pg 13',
    13: 'Ct Strat Pg 14',
    14: 'Ct Strat Pg 15',
  },
  DEPLOY: {
    0: 'Disc Strat Pg 1',
    1: 'Disc Strat Pg 2',
    2: 'Disc Strat Pg 3',
    3: 'Disc Strat Pg 4',
    4: 'Disc Strat Pg 5',
    5: 'Disc Strat Pg 6',
    6: 'Disc Strat Pg 7',
  },
  BACKTEST: {
    0: 'Bt Pg 1',
    1: 'Bt Pg 2',
    2: 'Bt Pg 3',
  },
  SCANNERS_CREATE: {
    0: 'Ct Scan Pg 1',
    1: 'Ct Scan Pg 2',
    2: 'Ct Scan Pg 3',
    3: 'Ct Scan Pg 4',
    4: 'Ct Scan Pg 5',
    5: 'Ct Scan Pg 6',
    6: 'Ct Scan Pg 7',
    7: 'Ct Scan Pg 8',
    8: 'Ct Scan Pg 9',
  },
  FINISHED: {
    0: '',
  },
}

export const sideBarMapGALabels = {
  BACKTEST: 'BT Pg 5',
  DEPLOY: 'Disc Strat Pg 9',
  CREATESTRATEGY: 'Ct Strat Pg 17',
  SCANNERS: 'Disc Scan Pg 7',
  SCANNERS_CREATE: 'Ct Scan Pg 11',
  TECHNICALS: 'Techs Pg 7',
  TECHNICALS_CREATE: 'Ct Tech Pg 9',
}

export const closeProductTourPopup = {
  BACKTEST: 'BT Pg 4',
  DEPLOY: 'Disc Strat Pg 8',
  CREATESTRATEGY: 'Ct Strat Pg 16',
  SCANNERS: 'Disc Scan Pg 6',
  SCANNERS_CREATE: 'Ct Scan Pg 10',
  TECHNICALS: 'Techs Pg 6',
  TECHNICALS_CREATE: 'Ct Tech Pg 8',
}

export default () => ({
  [PRODUCT_TOUR_SECTIONS.DEPLOY]: {
    tourKeys: ['tour_deploy_1', 'tour_deploy_2', 'tour_deploy_3', 'tour_deploy_4', 'tour_deploy_5', 'tour_deploy_6', 'tour_deploy_7'],
    content: {
      tour_deploy_1: {
        content: 'Begin your strategy by clicking on the strategy card.',
        placement: 'right',
        showClicker: true,
        dotPlacement: { leftPos: 0.85, topPos: 0.05 },
        subPlacement: 0.55,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_deploy_2: {
        content: 'View the backtest results to see how the strategy performed on past data.',
        placement: 'top',
        showClicker: false,
        focusMap: ['tour_deploy_2_1'],
        canNavigateBack: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_deploy_3: {
        content: 'Click "Deploy All" to confirm your selected stocks and proceed to the next step.',
        placement: 'top',
        showClicker: true,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_deploy_4: {
        content: 'Verify that everything is correct. Choose the "Paper Trade" option to trade without using real capital.',
        placement: 'right',
        showClicker: false,
        subPlacement: 1.6,
        mobile: {
          placement: 'top',
        },
      },
      tour_deploy_5: {
        content: 'Click "Paper Trade" to deploy your strategy.',
        placement: 'right',
        showClicker: true,
        subPlacement: 1.3,
        dotPlacement: { leftPos: 0.5 },
        mobile: {
          placement: 'top',
        },
      },
      tour_deploy_6: {
        content: 'You have deployed a strategy on Streak.',
        placement: 'bottom',
        showClicker: true,
        showPrevious: false,
        dotPlacement: { top: 1.5 },
        mobile: {
          placement: 'top',
        },
      },
      tour_deploy_7: {
        content: 'We are now waiting for your strategy to meet the entry conditions. You will receive a notification when the conditions are met.',
        placement: 'bottom',
        showClicker: false,
        showPrevious: false,
        doneBtnText: 'See how this works',
        icon: ICONS.PT_VIDEO,
        mobile: {
          placement: 'bottom',
        },
      },
    },
    extraTooltip: {
      tour_deploy_4: {
        extraContent: 'Use the "Live Trade" option to deploy the strategy in the live markets with real capital.',
        placement: 'right',
        showClicker: false,
        subPlacement: 0.4,
        mobile: {
          placement: 'bottom',
          subPlacement: 1 / 2,
        },
      },
    },
  },
  [PRODUCT_TOUR_SECTIONS.CREATESTRATEGY]: {
    tourKeys: ['tour_create_strategy_1', 'tour_create_strategy_2', 'tour_create_strategy_3', 'tour_create_strategy_4', 'tour_create_strategy_5', 'tour_create_strategy_6', 'tour_create_strategy_7', 'tour_create_strategy_8', 'tour_create_strategy_9', 'tour_create_strategy_10', 'tour_create_strategy_11', 'tour_create_strategy_12', 'tour_create_strategy_13', 'tour_create_strategy_14', 'tour_create_strategy_15',
    ],
    content: {
      tour_create_strategy_1: {
        content: 'To create a strategy, start by clicking here.',
        placement: 'right',
        showClicker: true,
        dotPlacement: { leftPos: 0.7, topPos: 0.25 },
        mobile: {
          placement: 'bottom',
        },
      },
      tour_create_strategy_2: {
        content: 'Name your new strategy and click "Create".',
        placement: 'right',
        subPlacement: 0.9,
        dotPlacement: { leftPos: 0.8, topPos: 1 / 4 },
        showClicker: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_create_strategy_3: {
        content: 'Add the stocks you want to use for your strategy.',
        placement: 'top',
        showClicker: true,
        dotPlacement: { leftPos: 0.56, topPos: 0.8 },
        canNavigateBack: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_create_strategy_4: {
        content: 'Select the stocks you want to include in your strategy by clicking the "+" button.',
        placement: 'left',
        subPlacement: 1.5,
        dotPlacement: { topPos: 1 / 2, leftPos: 3 / 4 },
        showClicker: true,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_create_strategy_5: {
        content: 'Click "Done" to confirm your selection.',
        placement: 'left',
        showClicker: true,
        subPlacement: 0.7,
        dotPlacement: { topPos: 0.4, leftPos: 3 / 4 },
        mobile: {
          placement: 'bottom',
        },
      },
      tour_create_strategy_6: {
        content: 'The selected stocks have been added.',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'top',
        },
      },
      tour_create_strategy_7: {
        content: ' We have set default values for different chart types, holding types, and quantities. These can always be edited.',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'top',
        },
      },
      tour_create_strategy_8: {
        content: 'Choose how you want to enter a trade. The default setting is "Buy".',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_create_strategy_9: {
        content: 'The assist mode can help fill in your entry and exit conditions.',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_create_strategy_10: {
        content: 'We’ve added a sample entry condition to help get you started.',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'top',
        },
      },
      tour_create_strategy_11: {
        content: ' Stop Loss and Target Profit are mandatory. We have already filled these in for you, but you can always add or edit them later.',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'top',
        },
      },
      tour_create_strategy_12: {
        content: 'Scroll down to verify that everything is correct. You can also go back and edit the details.',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'top',
        },
      },
      tour_create_strategy_13: {
        content: 'The backtest parameters shown here have default values, which can be changed.',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'top',
        },
      },
      tour_create_strategy_14: {
        content: 'Click here to save and backtest your strategy.',
        placement: 'top',
        showClicker: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_create_strategy_15: {
        content: 'We have evaluated your strategy based on past data. Analyze your backtest results before deploying to live markets.',
        placement: 'top',
        showClicker: false,
        showPrevious: false,
        mobile: {
          placement: 'top',
        },
      },
    },
  },
  [PRODUCT_TOUR_SECTIONS.TECHNICALS]: {
    tourKeys: ['tour_tech_1', 'tour_tech_2', 'tour_tech_3', 'tour_tech_4', 'tour_tech_5'],
    content: {
      tour_tech_1: {
        content: 'Let’s get you started with Technicals on Streak. To make things easy, we’ve already curated a watchlist for you.',
        placement: 'right',
        subPlacement: 1 / 1.5,
        showClicker: false,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_tech_2: {
        content: 'Click here to toggle between “Technical Overview” and “Support & Resitstance”',
        placement: 'right',
        dotPlacement: { leftPos: 0.8 },
        showClicker: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_tech_3: {
        content: 'This is the support and resistance snapshot of all stocks in the watchlist.',
        placement: 'right',
        showClicker: false,
        mobile: {
          placement: 'top',
        },
      },
      tour_tech_4: {
        content: 'Let\'s begin by selecting a popular stock. Click on a stock to get it\'s detailed technical overview.',
        placement: 'right',
        subPlacement: 1,
        dotPlacement: { leftPos: 0.8 },
        showClicker: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_tech_5: {
        content: 'You can find a wide range of technicals in this section. We’ve simplified it all for you. Hover on the ⓘ to learn more about each indicator here.',
        placement: 'top',
        subPlacement: 1 / 4,
        fixedPos: true,
        showClicker: false,
        showPrevious: false,
        mobile: {
          placement: 'top',
        },
      },
    },
  },
  [PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE]: {
    tourKeys: ['tour_tech_create_1', 'tour_tech_create_2', 'tour_tech_create_3', 'tour_tech_create_4', 'tour_tech_create_5', 'tour_tech_create_6', 'tour_tech_create_7'],
    content: {
      tour_tech_create_1: {
        content: 'Click on “Create New” to create a new watchlist.',
        placement: 'left',
        subPlacement: 1,
        showClicker: true,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_tech_create_2: {
        content: 'Enter a name for your watchlist.',
        placement: 'right',
        subPlacement: 0.8,
        dotPlacement: { leftPos: 0.8 },
        showClicker: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_tech_create_3: {
        content: 'Let’s choose some stocks to create your watchlist. Click on the + button to add these.',
        placement: 'left',
        subPlacement: 1.5,
        dotPlacement: { topPos: 1 / 2, leftPos: 3 / 4 },
        showClicker: true,
        mobile: {
          placement: 'bottom',
          // subPlacement: 0.8,
        },
      },
      tour_tech_create_4: {
        content: 'Click on ‘done’ to confirm the stocks you’ve chosen.',
        placement: 'left',
        subPlacement: 0.8,
        dotPlacement: { topPos: 0.4, leftPos: 3 / 4 },
        showClicker: true,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_tech_create_5: {
        content: 'Your watchlist is ready. Click here to see the ‘Support & Resistance’ levels of the stocks in your watchlist.',
        placement: 'right',
        dotPlacement: { leftPos: 0.8 },
        showClicker: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_tech_create_6: {
        content: 'Click on a stock to get a detailed technical overview.',
        placement: 'right',
        dotPlacement: { leftPos: 0.8 },
        showClicker: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_tech_create_7: {
        content: 'You can find a wide range of technicals in this section. We’ve simplified it all for you. Hover on the ⓘ to learn more about each indicator here.',
        placement: 'top',
        subPlacement: 1 / 4,
        fixedPos: true,
        showClicker: false,
        showPrevious: false,
        mobile: {
          placement: 'top',
        },
      },
    },
  },
  [PRODUCT_TOUR_SECTIONS.SCANNERS_CREATE]: {
    tourKeys: ['tour_scan_create_1', 'tour_scan_create_2', 'tour_scan_create_3', 'tour_scan_create_4', 'tour_scan_create_5', 'tour_scan_create_6', 'tour_scan_create_7', 'tour_scan_create_8', 'tour_scan_create_9'],
    content: {
      tour_scan_create_1: {
        content: 'Create a new scanner to discover instruments across equities, currencies, futures, options, and commodities based on your search parameters. Click here to begin.',
        placement: 'right',
        dotPlacement: { leftPos: 0.8 },
        showClicker: true,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_scan_create_2: {
        content: 'Name your new scanner.',
        placement: 'right',
        subPlacement: 0.8,
        dotPlacement: { leftPos: 0.9, topPos: 1 / 4 },
        showClicker: true,
        mobile: {
          placement: 'top',
          subPlacement: 0.5,
        },
      },
      tour_scan_create_3: {
        content: 'Select a pre-built or customized basket to scan, such as Nifty100.',
        placement: 'right',
        subPlacement: 0.8,
        canNavigateBack: true,
        showClicker: false,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_scan_create_4: {
        content: 'Choose the chart type and frequency for your scanner, then click "Next" to continue.',
        placement: 'top',
        focusMap: ['tour_scan_create_4_1'],
        showClicker: true,
        dotPlacement: { top: 0.2 },
        mobile: {
          placement: 'top',
        },
      },
      tour_scan_create_5: {
        content: 'The assist mode can help you define your scanner conditions. Keep it turned on for assistance.',
        placement: 'left',
        subPlacement: 2,
        dotPlacement: { topPos: 1 / 6, leftPos: 3 / 4 },
        showClicker: true,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_scan_create_6: {
        content: 'A sample scanner condition has been added to help you get started.',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'top',
        },
      },
      tour_scan_create_7: {
        content: 'Once you’ve reviewed the conditions and parameters, it’s time to get the results.',
        placement: 'top',
        showClicker: false,
        mobile: {
          placement: 'top',
          subPlacement: 1.2,
        },
      },
      tour_scan_create_8: {
        content: 'Click on “Save and Run” to get the results.',
        placement: 'top',
        showClicker: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_scan_create_9: {
        content: 'Here are the results of your scanner. This is a list of all the stocks from Nifty 100 that match the scanner conditions.',
        placement: 'top',
        focusMap: ['tour_scan_create_9_1'],
        showClicker: false,
        showPrevious: false,
        mobile: {
          placement: 'top',
        },
      },
    },
  },
  [PRODUCT_TOUR_SECTIONS.BACKTEST]: {
    tourKeys: ['tour_backtest_1', 'tour_backtest_2', 'tour_backtest_3'],
    mobileTourKeys: ['tour_backtest_1', 'tour_backtest_3'],
    content: {
      tour_backtest_1: {
        content: 'Backtest result for paticular period.',
        placement: 'right',
        subPlacement: 1.3,
        mobile: {
          placement: 'top',
        },
      },
      tour_backtest_2: {
        content: 'Quickly edit various parameters of your strategy and run backtests, without going to the edit strategy page.',
        placement: 'left',
        dupLoading: 100,
        skipScroll: true,
      },
      tour_backtest_3: {
        content: 'A Cumulative result for entire strategy with all backtest results.',
        placement: 'top',
        subPlacement: 1 / 4,
        showPrevious: false,
        mobile: {
          placement: 'top',
        },
      },
    },
  },
  [PRODUCT_TOUR_SECTIONS.SCANNERS]: {
    tourKeys: ['tour_scan_1', 'tour_scan_2', 'tour_scan_3', 'tour_scan_4', 'tour_scan_5'],
    mobileTourKeys: ['tour_scan_1', 'tour_scan_2', 'tour_scan_mobile_3', 'tour_scan_mobile_4', 'tour_scan_4', 'tour_scan_5'],
    content: {
      tour_scan_1: {
        content: 'Scanners allow you to search for instruments across equities, futures, and options based on your specified parameters. Click on the scanner card to begin.',
        placement: 'right',
        showClicker: true,
        dotPlacement: { leftPos: 0.85, topPos: 0.05 },
        subPlacement: 0.55,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_scan_2: {
        content: 'View the scan results to see which instruments meet the search parameters.',
        placement: 'top',
        showClicker: false,
        focusMap: ['tour_scan_2_1'],
        canNavigateBack: true,
        mobile: {
          placement: 'top',
        },
      },
      tour_scan_3: {
        content: ' Enable real-time tracking and searching by clicking "Take Live".',
        placement: 'bottom',
        showClicker: true,
        subPlacement: 0.8,
      },
      tour_scan_mobile_3: {
        content: 'Click here to find the option to take your scanner live.',
        // placement: 'bottom',
        showClicker: true,
        subPlacement: 0.8,
        mobile: {
          placement: 'bottom',
        },
      },
      tour_scan_mobile_4: {
        content: 'Click here to take your scanner "live".',
        // placement: 'bottom',
        showClicker: true,
        subPlacement: 0.8,
        mobile: {
          placement: 'top',
        },
      },
      tour_scan_4: {
        content: 'Select the frequency of your scanner before activating it.',
        placement: 'right',
        dotPlacement: { leftPos: 0.5, topPos: 0.8 },
        showClicker: true,
        subPlacement: 1.1,
        mobile: {
          placement: 'top',
          content: 'Click here to take your scanner "live".',
        },
      },
      tour_scan_5: {
        content: 'Once the scanner is live, you will receive a notification each time a stock meets the set conditions.',
        placement: 'right',
        showClicker: false,
        subPlacement: 4 / 2,
        // canNavigateBack: true,
        showPrevious: false,
        mobile: {
          placement: 'bottom',
        },
      },
    },
  },
  [PRODUCT_TOUR_SECTIONS.FINISHED]: {
    tourKeys: ['tour_finished_1'],
    content: {
      tour_finished_1: {
        title: 'AWESOME WORK',
        content: 'We’ve got a lot more features for you. Choose from the sidebar what you would like to learn next',
        placement: 'center',
        showClicker: false,
      },
    },
  },
})

export const SideBarMap = [
  {
    title: 'Strategies',
    background: '#F2D420',
    expand: true,
    items: [
      {
        title: 'Create Strategy',
        section: PRODUCT_TOUR_SECTIONS.CREATESTRATEGY,
        path: pathname_mapping.Strategies,
      },
      {
        title: 'Deploy Strategy',
        section: PRODUCT_TOUR_SECTIONS.DEPLOY,
        path: pathname_mapping.Strategies,
      },
      {
        title: 'Backtest',
        section: PRODUCT_TOUR_SECTIONS.BACKTEST,
        path: '/backtests?publishing_uuid=MTcwNTI0MmItYzM1MC00YmRmLWEwMWMtMDJkNTUzYjBlNmZl',
      },
    ],
  },
  {
    title: 'Scanner',
    background: '#F2D420',
    items: [
      {
        title: 'Create Scanner',
        section: PRODUCT_TOUR_SECTIONS.SCANNERS_CREATE,
        path: pathname_mapping.Scanners,
      },
      {
        title: 'Discover Scanner',
        section: PRODUCT_TOUR_SECTIONS.SCANNERS,
        path: pathname_mapping.Scanners,
      },
    ],
  },
  {
    title: 'Technicals',
    background: '#F2D420',
    items: [
      {
        title: 'Create Technicals',
        section: PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE,
        path: pathname_mapping.marketwatch,
      },
      {
        title: 'Discover Technicals',
        section: PRODUCT_TOUR_SECTIONS.TECHNICALS,
        path: pathname_mapping.marketwatch,
      },
    ],
  },
  {
    title: 'Help Manual',
    background: '#fff',
    items: [
      {
        title: 'Manual',
        section: 'helpPage',
        path: helpPageUrl,
      },
      {
        title: 'Academy',
        section: 'helpPage',
        path: academyBaseUrl,
      },
      {
        title: 'Support',
        section: 'helpPage',
        path: mailToSupportUrl,
      },
    ],
  },
]
