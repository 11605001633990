export const FETCH_POSITIONS_INIT = 'FETCH_POSITIONS_INIT'
export const FETCH_POSITIONS_SUCCESS = 'FETCH_POSITIONS_SUCCESS'
export const FETCH_POSITIONS_FAILURE = 'FETCH_POSITIONS_FAILURE'

export const FETCH_HOLDINGS_INIT = 'FETCH_HOLDINGS_INIT'
export const FETCH_HOLDINGS_SUCCESS = 'FETCH_HOLDINGS_SUCCESS'
export const FETCH_HOLDINGS_FAILURE = 'FETCH_HOLDINGS_FAILURE'

export const FETCH_SPECIFIC_INIT = 'FETCH_SPECIFIC_INIT'
export const FETCH_SPECIFIC_SUCCESS = 'FETCH_SPECIFIC_SUCCESS'
export const FETCH_SPECIFIC_FAILURE = 'FETCH_SPECIFIC_FAILURE'

export const GET_LIVE_ALGO_INIT = 'GET_LIVE_ALGO_INIT'
export const GET_LIVE_ALGO_SUCCESS = 'GET_LIVE_ALGO_SUCCESS'
export const GET_LIVE_ALGO_FAILURE = 'GET_LIVE_ALGO_FAILURE'

export const EXIT_POSITION_INIT = 'EXIT_POSITION_INIT'
export const EXIT_POSITION_SUCCESS = 'EXIT_POSITION_SUCCESS'
export const EXIT_POSITION_FAILURE = 'EXIT_POSITION_FAILURE'

export const CLEAR_PORTFOLIO_RESP = 'CLEAR_PORTFOLIO_RESP'

export const CLEAR_UNREALISED_PNL = 'CLEAR_UNREALISED_PNL'
export const ADD_UNREALISED_PNL = 'ADD_UNREALISED_PNL'
