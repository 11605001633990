/* eslint-disable no-unused-vars */
import React from 'react'

import { isEmpty } from 'lodash'
import ScreenHeader from '../../UI/ScreenHeader'
import Button from '../../UI/Button'
import AlgoInfo from '../AlgoInfo'
import BacktestResults from './BacktestResults'
import EntryExitRenderer from './EntryExitRenderer'
import AlgoParams from './AlgoParams'
import ViewAllCard from '../../UI/ViewAllCard'
import Icon from '../../UI/Icon'
import BacktestsDetails from './BacktestsDetails'
import CummulativePnl from './CummulativePnl'
import { showSnackbar } from '../../UI/Snackbar'

import {
  COLORS, ICONS, SPACING, theme,
} from '../../Theme'
import { DYNAMIC_SEGMENTS } from '../../containers/Create/Create-utils'
import { withTheme } from '../../Theme/ThemeProvider'
import DotLoader from '../../UI/DotLoader'
import CustomText from '../../UI/CustomText'
import { LINK_MAP, MATH_FUNCS } from '../../utils/consts'

const isBacktestExist = (scripBtResult = {}, includeNoTrades) => {
  if (isEmpty(scripBtResult) || scripBtResult.error || scripBtResult.error_msg
    || scripBtResult.status === false) {
    const errMsg = scripBtResult && scripBtResult.error_msg ? scripBtResult.error_msg : 'No backtest result found'
    return includeNoTrades && scripBtResult.error_msg
      && scripBtResult.error_msg.toLowerCase().includes('no trades')
  }
  return true
}

const findScripFromAlgoObj = (selectedSymbols, scripList) => {
  let scrip = ''
  let index = null
  const segSym = selectedSymbols.values().next().value
  for (let i = 0; i < scripList.length; i++) {
    const { segment, symbol } = scripList[i]
    if (segSym === `${segment}_${symbol}`) {
      scrip = scripList[i]
      index = i
      break
    }
  }
  return { scrip, index }
}

const checkOnlyScannerFuncExist = (conditions = [],
  indiArr = [MATH_FUNCS.MULTI_TIME_FRAME.name], mtfPreference) => {
  let hasScannerOnlyFunc = false
  if(!mtfPreference) return false
  if (conditions.length > 0 && conditions.includes(`${indiArr[0]}(`)) {
    hasScannerOnlyFunc = true
  }

  return hasScannerOnlyFunc
}

class BacktestMarkets extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      subscribeAlgoResp, isSubscribingAlgo, fetchBacktests,
    } = this.props
    if (prevProps.isSubscribingAlgo !== isSubscribingAlgo && !isSubscribingAlgo) {
      if (subscribeAlgoResp && subscribeAlgoResp.status === 'success') {
        const { algo_subscription_uuid } = subscribeAlgoResp
        fetchBacktests(algo_subscription_uuid)
        showSnackbar('Strategy subscribed successfully', {}, 1)
      }
    }
  }

  onAlgoSubscribe = () => {
    const { subscribeAlgo, algoId, csrf } = this.props
    const params = {
      publishing_uuid: algoId,
      csrfmiddlewaretoken: csrf,
    }
    subscribeAlgo(params)
  }

  onCardPress = () => {
    const { algoId, algoIdKey, editAlgo } = this.props
    editAlgo({ [algoIdKey]: algoId })
  }

  actionBarRenderer = ({ scrolled }) => {
    const {
      isFetchingBacktests, isSubscribingAlgo, styles, nameChangeHandler,
      isCloningAlgo,
    } = this.props
    return (
      <div className={styles.headerActionBar} id="tour_bt_8">
        <Button
          onPress={nameChangeHandler}
          disabled={isSubscribingAlgo || isFetchingBacktests || isCloningAlgo}
          buttonColor="blue"
          btnStyles={`${styles.getBtn} ${scrolled ? styles.getBtnSmall : ''}`}
          roundness={6}
          text="Copy"
        />
      </div>
    )
  }

  listHeaderRenderer = ({ scrolled }) => {
    const {
      algoInfo, algoIdKey, algoId, algo_uuid, gaCategory,
      isDark, published_algo_price, downvotes, upvotes, view_count, voted,
    } = this.props
    const {
      algo_name, algo_desc, exitStr, entryStr,
    } = algoInfo
    const { monthly_pricing = 0 } = published_algo_price
    return (
      <AlgoInfo
        algoId={algoId}
        algoIdKey={algoIdKey}
        monthly_pricing={monthly_pricing}
        algo_name={algo_name}
        algo_desc={algo_desc}
        showSocialStats={false}
        downvotes={downvotes}
        upvotes={upvotes}
        view_count={view_count}
        voted={voted}
        publishing_uuid={algoId}
        isDark={isDark}
        algo_uuid={algo_uuid}
        entryStr={entryStr}
        exitStr={exitStr}
        scrolled={scrolled}
        gaCategory={gaCategory}
        gaLabel={gaCategory}
      />
    )
  }

  footerActionBtnRederer = () => {
    const { isPinned } = this.props
    return <Icon name={ICONS.PIN} size={14} color={theme[isPinned ? 'linkColor' : 'text']} />
  }

  onEditAlgo = () => {
    showSnackbar('Click on Get to edit the strategy')
    // this.onAlgoSubscribe()
  }

  renderPnl = ({
    waiting, final_return, error_msg,
  }) => {
    const { styles } = this.props
    let pnlText
    // let backgroundColor = COLORS.WHITE
    if (final_return !== undefined && typeof final_return === 'number') {
      if (+final_return > 0) {
        pnlText = `+${+final_return.toFixed(2)}%`
      } else if (final_return < 0) {
        pnlText = `${+final_return.toFixed(2)}%`
      }
    }
    const renderTypeStyles = styles[final_return >= 0 ? 'gain' : 'loss']
    if (waiting) {
      return (
        <div className={styles.pnlPlaceHolder}>
          <DotLoader props={{ width: 30 }} />
        </div>
      )
    }
    if (error_msg) {
      return (
        <CustomText
          size="small"
        >
          N/A
        </CustomText>
      )
    }
    if (pnlText) {
      return (
        <CustomText
          className={renderTypeStyles}
          weight="medium"
        >
          {pnlText}
        </CustomText>
      )
    }
    return <div className={styles.pnlPlaceHolder} />
  }

  listFooterRenderer = () => {
    const {
      pinStratgyDetails, styles, onCardPress, isDark, algoInfo,
      isRunningBacktests, onExitEdit, onEntryEdit, isSubscribingAlgo,
    } = this.props

    const {
      entryStr, exitStr,
    } = algoInfo
    // console.log(allDeployed, isRunningBacktests, 'disabeld')
    return (
      <ViewAllCard
        title="Strategy Details"
        onPress={pinStratgyDetails}
        actionBtnRederer={this.footerActionBtnRederer}
        cardStyles={styles.footer}
        contentStyles={styles.footerContent}
        headerStyles={styles.footerHeader}
      >

        <div className={styles.strategyDetails}>
          <EntryExitRenderer
            cardTitle="ENTRY"
            onCardPress={onEntryEdit}
            condition={entryStr}
            disabled={Boolean(isSubscribingAlgo || isRunningBacktests)}
            key="entry"
            isDark={isDark}
            cardStyles={styles.entryCard}
          />
          <EntryExitRenderer
            cardTitle="EXIT"
            onCardPress={onExitEdit}
            condition={exitStr}
            disabled={Boolean(isSubscribingAlgo || isRunningBacktests)}
            numberOfLines={1}
            key="exit"
            isDark={isDark}
            cardStyles={styles.exitCard}
          />
        </div>
        {/* <AlgoParams
          isDark={isDark}
          {...btParams}
          disabled={Boolean(isSubscribingAlgo || isRunningBacktests)}
          toggleEditParams={this.onEditAlgo}
          containerStyles={styles.algoParamsContainer}
        /> */}
      </ViewAllCard>
    )
  }

  renderCumulativePnl = () => {
    const {
      backtest_results, isRunningBacktests, user_details, fetchBacktestAll,
      isFetchingData, updateWebSiteConfig, pinCumulativePnl, gaCategory,
      scripList,
    } = this.props
    return (
      <CummulativePnl
        // data={backtest_results}
        isRunningBacktests={isRunningBacktests}
        user_details={user_details}
        fetchBacktestAll={fetchBacktestAll}
        isFetchingData={isFetchingData}
        pinCumulativePnl={pinCumulativePnl}
        updateWebSiteConfig={updateWebSiteConfig}
        gaCategory={gaCategory}
        scripList={scripList}
      />
    )
  }

   removeOverLay = () => {
     const {
       backtest_results, isListView, isMobile, styles,
     } = this.props
     const mtfEle = document.getElementById('mtfContainer')

     if(mtfEle) {
       mtfEle.style.display = 'none'
     }
     backtest_results.forEach((item) => {
       const mtfGridResultEle = document.getElementById(item.seg_sym)
       const mtfRowEle = document.getElementById(`${item.seg_sym}_mtf`)
       const mtfGridPnl = document.getElementById(`${item.seg_sym}_mtf_pnl`)
       const mtfListPnl = document.getElementById(`${item.seg_sym}_mtf_pnl`)

       const mtfIds = ['pnl', 'wvl', 'signals', 'ws', 'ls', 'maxdd']
       mtfIds.forEach((mtfItem) => {
         const getPnlElde = document.getElementById(`${item.seg_sym}_${mtfItem}`)
         if(getPnlElde && !isMobile) {
           getPnlElde.className = styles.headerColumn
         }
       })

       if(mtfRowEle) {
         mtfRowEle.style.display = 'none'
       }
       if(isMobile && isListView) {
         mtfListPnl.style.display = 'none'
       }
       if(mtfGridResultEle) {
         mtfGridResultEle.style.display = 'block'
       }
       if(!isListView && mtfGridPnl) {
         mtfGridPnl.style.display = 'none'
       }
     })
   }

   removeOverLay = () => {
     const { backtest_results, isListView } = this.props
     const mtfEle = document.getElementById('mtfContainer')
     if(mtfEle) {
       mtfEle.style.display = 'none'
     }
     backtest_results.forEach((item) => {
       const mtfGridResultEle = document.getElementById(item.seg_sym)
       const mtfRowEle = document.getElementById(`${item.seg_sym}_mtf`)
       const mtfGridPnl = document.getElementById(`${item.seg_sym}_mtf_pnl`)
       if(mtfRowEle) {
         mtfRowEle.style.display = 'none'
       }
       if(mtfGridResultEle) {
         mtfGridResultEle.style.display = 'block'
       }
       if(!isListView && mtfGridPnl) {
         mtfGridPnl.style.display = 'none'
       }
     })
   }

   renderMTFCell = () => {
     const { styles, isMobile } = this.props
     return (
       <div className={styles.mtfCell} style={{ width: isMobile ? '50%' : 180 }}>
         <span className={styles.labelBlur}>value</span>
         <span className={styles.valueBlur}>labelvalue</span>
       </div>
     )
   }

   renderMTFCumuPNL = () => {
     const { styles, isMobile, isDark } = this.props
     return (
       <div
         className={styles.container}
       >
         {isMobile ? (
           <div className={styles.headerMobile}>
             <div className={styles.subHeaderMobile}>
               <div className={styles.headerLeft}>
                 <Icon
                   name={ICONS.CUMULATIVE_ICON}
                   size={14}
                   style={{ marginRight: SPACING.SPACE_8 }}
                   color={theme.orange}
                 />
                 <CustomText weight="semi_bold" size="medium">Cumulative results</CustomText>
               </div>
               <div className={styles.headerRight}>
                 <CustomText size="regular" weight="medium">
                   Backtest P&L
                   <span className={styles.pnlBlur}>12345</span>
                 </CustomText>
               </div>
             </div>
             <Button
               mode="text"
               labelColor={isDark ? 'blue_300' : 'blue'}
               transparent
               onPress={() => this.removeOverLay()}
               text="Show results"
               btnStyles={styles.showResultBtn}
               respText
             />
           </div>
         )
           : (
             <div className={styles.header}>
               <div className={styles.headerLeft}>
                 <Icon
                   name={ICONS.CUMULATIVE_ICON}
                   size={14}
                   style={{ marginRight: SPACING.SPACE_8 }}
                   color={theme.orange}
                 />
                 <CustomText weight="semi_bold" size="medium">Cumulative results</CustomText>
               </div>
               <div className={styles.headerRight}>
                 <CustomText size="regular" weight="medium">
                   Backtest P&L
                   <span className={styles.pnlBlur}>12345</span>
                 </CustomText>
               </div>
               <Button
                 mode="text"
                 labelColor={isDark ? 'blue_300' : 'blue'}
                 transparent
                 onPress={() => this.removeOverLay()}
                 text="Show results"
                 btnStyles={styles.showResultBtn}
                 respText
               />
             </div>
           )}
         <CustomText size="small_1" weight="medium" className={styles.mtfTitle} color="orange">
           Backtest results for MTF are unverifiable.
           <a href={LINK_MAP.MTF.link} rel="noreferrer" target="_blank"> Learn More.</a>
         </CustomText>
         <div className={styles.mtfContent}>
           {this.renderMTFCell()}
           {this.renderMTFCell()}
           {this.renderMTFCell()}
           {this.renderMTFCell()}
           {this.renderMTFCell()}
           {this.renderMTFCell()}
         </div>
       </div>
     )
   }

   render() {
     const {
       btParams,
       algoInfo,
       renderType,
       scripList,
       backtest_results,
       addNewSymbol,
       showBacktestDetails,
       backPressHandler,
       selectedSymbols,
       onSymbolSelect,
       deployed_seg_sym,
       toggleSelectAll,
       removeSymbols,
       algo_uuid,

       isDark,
       selectedIndex,
       algo_subscription_uuid,
       publishing_uuid,
       algo_subscribed,
       csrf,
       sessionid,
       onDelete,
       isPinned,
       styles,
       sliderProps,
       isRunningBacktests,
       toggleBrokerage,
       brokerageData,
       brokerage,
       selectedItem,
       isFetchingBacktests,
       pinCumulativePnl,
       updateWebSiteConfig,
       updateBrokerage,
       toggleListView,
       isListView,
       algoIdKey,
       isLoggedIn,
       gaCategory,
       updateGA,
       isMobile,
       isPublic,
       reRunBacktestHandler,
       mtfPreference,
     } = this.props
     const len = scripList.length
     const { dt_start, dt_stop } = btParams
     const {
       algo_name, entryStr,
     } = algoInfo

     const mtfExist = checkOnlyScannerFuncExist(entryStr, [
       MATH_FUNCS.MULTI_TIME_FRAME.name,
     ], mtfPreference)

     const commonProps = {
       algo_subscription_uuid,
       algoIdKey,
       publishing_uuid,
       deployed_seg_sym,
       isRunningBacktests,
       brokerageData,
       brokerage,
       isLoggedIn,
       gaLabel: gaCategory,
       gaCategory,
       updateGA,
       ...sliderProps,
       reRunBacktestHandler,
       mtfExist,
     }

     const backtestResultProps = {
       backtest_results,
       scripList,
       addNewSymbol,
       showBacktestDetails,
       selectedSymbols: new Set(),
       onSymbolSelect,
       toggleSelectAll,
       removeSymbols,
       onDelete,
       isDark,
       len,
       dt_start,
       dt_stop,
       selectedIndex,
       selectedItem,
       renderPnl: this.renderPnl,
       toggleListView,
       isListView,
       allSelected: false,
     }

     const backtestDetailsProps = {
       algoInfo,
       details: btParams,
       algo_uuid,
       algo_subscribed,
       csrf,
       sessionid,
       toggleBrokerage,
       updateBrokerage,
       item: selectedItem,
       algo_name,
       isListView,
     }
     const mtfContainerShow = document.getElementById('mtfContainer')
     const mtfContainerStyles = mtfContainerShow?.style.display
     // const item = backtest_results[selectedIndex] || {}
     return (
       <div>
         <ScreenHeader
           backPressHandler={backPressHandler}
           title={renderType}
           actionBarRenderer={this.actionBarRenderer}
           titleSize="large_1"
           containerStyles={styles.screenHeader}
           showBackBtn
           hideOnScroll
           scrolledText={algo_name}
         >
           {({ scrolled }) => this.listHeaderRenderer({ scrolled })}
         </ScreenHeader>
         <div
           className={styles.container}
         >
           {isPinned && isMobile && isPublic && this.listFooterRenderer()}
           {mtfExist && pinCumulativePnl && (
           <div
             className={styles.mtfContainer}
             id="mtfContainer"
             style={{
               display: mtfContainerStyles === 'none' ? 'none' : 'block',
               paddingBottom: 0,
               marginTop: SPACING.SPACE_2,
             }}
           >
             {this.renderMTFCumuPNL()}
           </div>
           )}
           {!isFetchingBacktests && pinCumulativePnl && (
             this.renderCumulativePnl()
           )}

           <BacktestResults
             {...commonProps}
             {...backtestResultProps}
             isBacktestExist={isBacktestExist}
             backtestDetailsProps={backtestDetailsProps}
             hideAddStock
             showShare
           />
           {isListView ? <div /> : (
             <BacktestsDetails
               {...commonProps}
               {...backtestDetailsProps}
               isBacktestExist={isBacktestExist}
             />
           )}
           {!isPinned && isMobile && isPublic && this.listFooterRenderer()}
           {mtfExist && !pinCumulativePnl && (
           <div
             className={styles.mtfContainer}
             id="mtfContainer"
             style={{
               display: mtfContainerStyles === 'none' ? 'none' : 'block',
             }}
           >
             {this.renderMTFCumuPNL()}
           </div>
           )}
           {!isFetchingBacktests && !pinCumulativePnl && (
             this.renderCumulativePnl()
           )}
         </div>
       </div>
     )
   }
}

const stylesheet = ({
  container: {
    margin: `0 0 ${SPACING.SPACE_20}`,
  },
  footer: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
  },
  footerContent: {},
  footerHeader: {
    padding: `0 ${SPACING.SPACE_14}`,
  },
  strategyDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  entryCard: {
    flex: 0.48,
  },
  exitCard: {
    flex: 0.48,
  },
  getBtn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_20}`,
  },
  getBtnSmall: {
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_12}`,
  },
  algoParamsContainer: {},
  screenHeader: {
    // marginRight: SPACING.SPACE_20,
  },
  gain: {
    color: `${COLORS.GREEN} !important`,
  },
  loss: {
    color: `${COLORS.RED} !important`,
  },
  // mtf disc styles
  mtfContainer: {
    width: '100%',
    marginTop: SPACING.SPACE_20,
    padding: SPACING.SPACE_20,
    position: 'absolute',
    zIndex: 9997,
    background: theme.bgPrimary,
    userSelect: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subHeaderMobile: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: SPACING.SPACE_10,
    borderRight: `1px solid ${theme.borderColor}`,
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: SPACING.SPACE_10,
    flex: 1,
  },
  mtfCell: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: SPACING.SPACE_10,
  },
  mtfContent: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_10}`,
  },
  pnlBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #3CBC00',
  },
  labelBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #000000',
  },
  valueBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #9BABC6',
  },
  showResultBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    background: theme.mtfTitleBg,
  },
  mtfTitle: {
    background: theme.mtfTitleBg,
    padding: SPACING.SPACE_8,
    borderRadius: 8,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      margin: 0,
    },
    strategyDetails: {
      flexDirection: 'column',
    },
    footerContent: {
      borderRadius: 10,
      backgroundColor: theme.bgPrimary,
      boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    },
    entryCard: {
      boxShadow: 'none !important',
      borderBottom: `1px solid ${theme.borderColor}`,
      padding: `${SPACING.SPACE_20} 0`,
      margin: `0 ${SPACING.SPACE_14}`,
      borderRadius: 0,
    },
    exitCard: {
      boxShadow: 'none !important',
      borderBottom: `1px solid ${theme.borderColor}`,
      padding: `${SPACING.SPACE_20} 0`,
      margin: `0 ${SPACING.SPACE_14}`,
      borderRadius: 0,
    },
    algoParamsContainer: {
      boxShadow: 'none !important',
      marginTop: 0,
    },
  },
  '@media only screen and (max-width: 610px)': {
    mtfTitle: {
      width: 'calc(100% - 40px)',
    },
  },
})

export default withTheme(stylesheet)(BacktestMarkets)
