export const FETCH_BILLING_INIT = 'FETCH_BILLING_INIT'
export const FETCH_BILLING_SUCCESS = 'FETCH_BILLING_SUCCESS'
export const FETCH_BILLING_FAILURE = 'FETCH_BILLING_FAILURE'

export const CHANGE_SUBSCRIPTION_INIT = 'CHANGE_SUBSCRIPTION_INIT'
export const CHANGE_SUBSCRIPTION_SUCCESS = 'CHANGE_SUBSCRIPTION_SUCCESS'
export const CHANGE_SUBSCRIPTION_FAILURE = 'CHANGE_SUBSCRIPTION_FAILURE'

export const GET_PRICING_INIT = 'GET_PRICING_INIT'
export const GET_PRICING_SUCCESS = 'GET_PRICING_SUCCESS'
export const GET_PRICING_FAILURE = 'GET_PRICING_FAILURE'

export const UNSUBSCRIBE_FEEDBACK = 'UNSUBSCRIBE_FEEDBACK'
export const UNSUBSCRIBE_FEEDBACK_SUCCESS = 'UNSUBSCRIBE_FEEDBACK_SUCCESS'
export const UNSUBSCRIBE_FEEDBACK_FAILURE = 'UNSUBSCRIBE_FEEDBACK_FAILURE'

export const DOWNLOAD_INVOICE_INIT = 'DOWNLOAD_INVOICE_INIT'
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS'
export const DOWNLOAD_INVOICE_FAILURE = 'DOWNLOAD_INVOICE_FAILURE'

export const FETCH_BILLING_OFFERS_INIT = 'FETCH_BILLING_OFFERS_INIT'
export const FETCH_BILLING_OFFERS_SUCCESS = 'FETCH_BILLING_OFFERS_SUCCESS'
export const FETCH_BILLING_OFFERS_FAILURE = 'FETCH_BILLING_OFFERS_FAILURE'
