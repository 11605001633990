import { makeStyles } from '@material-ui/styles'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  extraTourTip,
  togglePtSideBar, toggleTour, toggleTourBackdrop, updateTourIndex,
} from '../../containers/Common/actions'
import {
  COLORS, ICONS, SPACING, theme,
} from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import CustomText from '../../UI/CustomText'
import Expandable from '../../UI/Expandable'
import Icon from '../../UI/Icon'
import { SideBarMap, sideBarMapGALabels } from './ProductTour-utils'

const SIDE_BAR_MAP = SideBarMap

const ProductTourSideBar = (props) => {
  const {
    showTour, changeTourIndex, history, toggleTourSideBar,
    completedTours = [], isMobile, toggleSkipTour, getExtraTourtip,
    updateGA, user_details,
  } = props
  const { completedTours: userCompletedTour = [] } = user_details
  const styles = stylesheet()
  const [sidebarMap, updateSidebarMap] = React.useState(SIDE_BAR_MAP)
  const recentCompleteTour = completedTours[completedTours.length - 1]
  const gaLabel = sideBarMapGALabels[recentCompleteTour]
  const goTo = (link) => {
    window.open(link, '_blank')
  }

  const startTour = (section, path) => {
    if(section === 'helpPage') {
      goTo(path)
      return
    }
    showTour(true, section)
    toggleTourSideBar(false)
    changeTourIndex(0)
    history.push(path)
    toggleSkipTour(false)
    getExtraTourtip(false)
    updateGA(section, gaLabel, 1, 'Product Tour')
  }

  useEffect(() => {
    const aside = document.getElementById('aside')
    const ptSideBar = document.getElementById('ptSidebar')
    if(!isMobile && aside && aside.childElementCount > 0 && ptSideBar) {
      const {
        width, left,
      } = aside.getBoundingClientRect()
      ptSideBar.style.width = `${width}px`
      ptSideBar.style.left = `${left}px`
    }

    const toggleButton = document.getElementById('toogleTourSideBar')
    const toggleButtonDub = document.getElementById('toogleTourSideBarDup')
    if(toggleButton && toggleButtonDub) {
      const {
        width, left, top,
      } = toggleButton.getBoundingClientRect()
      toggleButtonDub.style.width = `${width}px`
      toggleButtonDub.style.left = `${left}px`
      toggleButtonDub.style.top = `${top}px`
    }
  }, [])

  const onExpandCallback = (title) => {
    const newSidebarMap = sidebarMap.map((category) => {
      if (category.title === title) {
        return {
          ...category,
          expand: true,
        }
      }
      return {
        ...category,
        expand: false,
      }
    })
    updateSidebarMap(newSidebarMap)
  }

  const Item = (itemProps) => {
    const {
      item, isLast, onClick, isChecked,
    } = itemProps
    const {
      title, section, path,
    } = item

    return (
      <button type="button" onClick={() => onClick(section, path)} className={styles.itemBtn}>
        <div className={styles.option} style={{ borderRadius: isLast ? '0 0 12px 12px' : '' }}>
          <CustomText size="small" color="black" weight="semi_bold">{title}</CustomText>
          {isChecked && (
          <Icon
            name={ICONS.PT_CHECK}
            size={25}
            color={COLORS.RED}
          />
          )}
        </div>
      </button>
    )
  }

  const closeTour = () => {
    showTour(false)
    toggleTourSideBar(false)
    changeTourIndex(0)
  }

  return (
    <div className={styles.main} id="ptSidebar">
      {!isMobile && (
        <div className={styles.closeBtnContainer}>
          <div />
          <button
            type="button"
            className={styles.closeBtn}
            onClick={closeTour}
          >
            <Icon name={ICONS.CLOSE} size={12} color={theme.productTourSecondary} />
          </button>
        </div>
      )}
      {
        sidebarMap.map((category) => {
          return (
            <div
              style={{ marginInline: SPACING.SPACE_20, marginBottom: SPACING.SPACE_20 }}
              key={category.title}
            >
              <Expandable
                title={category.title}
                titleProps={{ color: 'black' }}
                defaultExpanded={category.expand}
                onExpandCallback={() => onExpandCallback(category.title)}
                headerContainerStyle={{
                  borderRadius: 12,
                  padding: 14,
                  background: category.background,
                }}
                containerStyle={{ borderRadius: 12, background: COLORS.WHITE }}
              >
                {
                 category && category.items && category.items.map((item, index) => (
                   <Item
                     item={item}
                     isChecked={userCompletedTour.includes(item.section)}
                     key={index.toString()}
                     onClick={startTour}
                     isLast={category.items.length === index + 1}
                   />
                 ))
                }
              </Expandable>
            </div>
          )
        })
      }
      {isMobile && (
        <button
          type="button"
          className={styles.closeBtn}
          onClick={closeTour}
          style={{ padding: `${SPACING.SPACE_10} ${SPACING.SPACE_30}` }}
        >
          Close
        </button>
      )}
    </div>
  )
}

const stylesheet = makeStyles({
  main: {
    width: '290px !important',
    borderRadius: '10px',
    background: theme.productTourBg,
    position: 'fixed',
    zIndex: 9999,
    bottom: '5%',
    left: 'calc(80% - 70px) !important',
  },
  option: {
    background: '#FFFBE2',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 20px',
    '&:hover': {
      backgroundColor: '#f1ebc7',
      borderRadius: '12px !important',
    },
  },
  itemBtn: {
    width: '100%',
  },
  closeBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeBtn: {
    paddingRight: SPACING.SPACE_14,
    paddingTop: SPACING.SPACE_10,
    marginBottom: 9,
  },
  '@media only screen and (max-width: 1024px)': {
    main: {
      borderRadius: '10px',
      background: theme.productTourBg,
      paddingTop: '20px',
      paddingBottom: '10px',
      position: 'fixed',
      zIndex: 9999,
      bottom: '50%',
      left: '50% !important',
      transform: 'translate(-50%, 50%)',
    },
    closeBtn: {
      float: 'right',
      marginRight: '20px',
      padding: `${SPACING.SPACE_8} ${SPACING.SPACE_30}`,
      backgroundColor: theme.productTourBgLight,
      color: COLORS.WHITE,
      borderRadius: '6px',
      fontWeight: 'bold',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    ...state.auth,
    startTour: state.common.startTour,
    broker: state.auth.user_details.broker,
    tourType: state.common.tourType,
    completedTours: state.common.completedTours,
    ptComponentStatus: state.common.ptComponentStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showTour: (params, tourType) => dispatch(toggleTour(params, tourType)),
    changeTourIndex: params => dispatch(updateTourIndex(params)),
    toggleTourSideBar: params => dispatch(togglePtSideBar(params)),
    toggleSkipTour: params => dispatch(toggleTourBackdrop(params)),
    getExtraTourtip: params => dispatch(extraTourTip(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(stylesheet)(ProductTourSideBar))
