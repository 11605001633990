/* eslint-disable no-undef */
import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import ScreenerInput from './ScreenerInput'
import ListDropdown from '../../UI/Dropdown/ListDropdown'
import CustomInput from '../../UI/CustomInput'
import CustomText from '../../UI/CustomText'
import CustomModal from '../../UI/CustomModal'
import InputOptions from './InputOptions'
import IndicatorFinder from '../Create/IndicatorFinder'
import AutoComplete from '../AutoComplete'
import Icon from '../../UI/Icon'

import { withTheme } from '../../Theme/ThemeProvider'
import {
  functionOptions,
  indicatorsOptions,
  mathFuncOptions,
  comparatorsOptions,
  operatorsOptions,
  bracketsOptions,
  freq_candle_map,
  MATH_FUNCS,
  mtf_candle_map,
  candle_min_map,
} from '../../utils/consts'
import { nameGenerator, getTemplate } from '../../utils/common'
import {
  SPACING, COLORS, FONTS, theme, ICONS,
} from '../../Theme'
import fTree from '../Create/tree.json'
import store from '../../store'

const candleMap = { ...freq_candle_map, ...mtf_candle_map }
const SECTION_TYPE = {
  INDICATOR: 'indicators',
  COMPARATOR: 'comparators',
  MATH_FUNCTION: 'math functions',
  COMPARE_INDICATOR: 'compareIndicator',
}

const signal_trade_block_indc = ['multitime_frame', 'multitime_framep', 'symbol', 'period_min', 'period_max']
const timeFramesToHide = ['week', 'month']

const getSameIndcNameBlockKeys = (indcName) => {
  const state = store.getState()
  const { common: { blockedTimeFrame } } = state
  const sameIndcNestedKeys = ['multitime_frame', 'multitime_framep', 'symbol']
  let isValidBlockKey = false
  let kToHide = []
  const indcatorsToBlockSearch = {
    'Multitime frame': ['multitime_frame', 'multitime_framep'],
    'Multitime frame completed': ['multitime_frame', 'multitime_framep'],
    Symbol: ['symbol'],
  }
  const indcatorsToBlockSearchTF = {
    'Multitime frame': ['multitime_frame', 'multitime_framep', 'pivot_points', 'central_pivot_range', 'camarilla'],
    'Multitime frame completed': ['multitime_frame', 'multitime_framep', 'pivot_points', 'central_pivot_range', 'camarilla'],
    Symbol: ['symbol'],
  }
  const sameIndcBlockName = sameIndcNestedKeys.map(indc => fTree['math functions'][indc].name)
  if (sameIndcBlockName.includes(indcName)) {
    isValidBlockKey = true
    kToHide = indcatorsToBlockSearch[indcName] || []
  }
  if (timeFramesToHide.includes(blockedTimeFrame)) {
    isValidBlockKey = true
    kToHide = indcatorsToBlockSearchTF[indcName] || []
  }

  return { isValidBlockKey, kToHide }
}

class MathFunctionInput extends Component {
  constructor(props) {
    super(props)
    const {
      conditions, period = 10, multiConditions, symbol = '', keysToHide,
    } = this.getInitialState()

    this.state = {
      conditions,
      period,
      symbol,
      multiConditions,
      showIndicator: {}, //  contains activecondition index and multiindex
      showInputOptions: false,
      sectionType: SECTION_TYPE.INDICATOR,
      keysToHide,
      toBeRemoved: false,
      showDetails: false,
    }
    this.multiConditionsMap = ['Multitime frame', 'Min', 'Max', 'Multitime frame completed']
    this.singleConditionsArr = ['ceil', 'floor', 'abs']
    this.activeCondition = { index: 0 }
    this.optionList = null
    this.inputOptionsTree = {}
  }

  componentDidMount() {
    const { setBlockedTF } = this.props
    const { period } = this.state
    if(typeof setBlockedTF === 'function') {
      setBlockedTF(period)
    }
    this.getInitialState()
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      candleIntervalIndex,
      indicator,
    } = this.props
    if (!isEqual(nextProps.candleIntervalIndex, candleIntervalIndex)
      || !isEqual(this.state, nextState)
      || !isEqual(indicator, nextProps.indicator)) {
      return true
    }
    return false
  }

  componentDidUpdate = (prevProps) => {
    const {
      saveMathFuncDetails, parentIndex, indicator, toBeRemoved,
      setBlockedTF, blockedTimeFrame,
    } = this.props
    const {
      conditions, multiConditions, period, symbol, toBeRemoved: stateToBeRemoved,
    } = this.state
    const modConditions = [...conditions]
    const len = conditions.length
    const { name = '' } = indicator
    if(blockedTimeFrame) {
      setBlockedTF(period)
    }
    // add add button ie extra condition
    if (len > 0 && conditions[len - 1].valid) {
      modConditions.push(getTemplate('func'))
      this.setState({ conditions: modConditions })
    } else if (len > 1 && !conditions[len - 1].valid && !conditions[len - 2].valid) {
      // if there are two add button remove one of the add button
      modConditions.pop()
      this.setState({ conditions: modConditions })
      saveMathFuncDetails(parentIndex, {
        conditions: modConditions,
        period,
        valid: true,
        symbol,
        multiConditions,
      })
    }
    // the indicator has changed
    if (prevProps.indicator.name && prevProps.indicator.name !== name) {
      this.setState({ ...this.getInitialState() })
    }

    if (this.multiConditionsMap.includes(name)) {
      const moMultiConditionsArr = multiConditions
        && multiConditions[1] ? [...multiConditions[1]] : []
      const multiLen = moMultiConditionsArr.length
      if (multiLen > 0 && moMultiConditionsArr[multiLen - 1].valid) {
        moMultiConditionsArr.push(getTemplate('func'))
        this.setState({ multiConditions: { ...multiConditions, 1: moMultiConditionsArr } })
      } else if (multiLen > 1 && !moMultiConditionsArr[multiLen - 1].valid
        && !moMultiConditionsArr[multiLen - 2].valid) {
        moMultiConditionsArr.pop()
        this.setState({ multiConditions: { ...multiConditions, 1: moMultiConditionsArr } })
        saveMathFuncDetails(parentIndex, {
          conditions,
          period,
          valid: true,
          symbol,
          multiConditions: { ...multiConditions, 1: moMultiConditionsArr },
        })
      }
    }
    if (toBeRemoved !== prevProps.toBeRemoved && toBeRemoved !== stateToBeRemoved) {
      this.setState({ toBeRemoved })
    }
  }

  getInitialState = () => {
    const {
      indicator, keysToHide: propsKeysToHide,
    } = this.props
    const {
      conditions = [], multiConditions = {}, name: indiName, symbol, period,
    } = indicator
    const moConditions = [...conditions]
    if (moConditions.length === 0 || moConditions[moConditions.length - 1].valid) {
      moConditions.push(getTemplate('func'))
    }
    const moMultiConditions = { ...multiConditions }
    const validateBlockIndcNames = signal_trade_block_indc.map(indc => fTree['math functions'][indc].name)
    if(moMultiConditions && moMultiConditions[0] && moMultiConditions[0] === 'array') {
      Object.keys(moMultiConditions).forEach((key, i) => {
        if (i === 0) {
          return null
        }
        const arr = moMultiConditions[key]
        if (arr.length === 0 || arr[arr.length - 1].valid) {
          arr.push(getTemplate('func'))
        }
        return null
      })
    }
    let keysToHide = []
    if (indiName === fTree['math functions'].period_min.name
      || indiName === fTree['math functions'].period_max.name) {
      keysToHide = ['nth_candle', 'opening_range']
    }
    if (validateBlockIndcNames.includes(indiName)) {
      keysToHide = [...keysToHide, 'signal_candle', 'trade_candle']
    }
    const { kToHide, isValidBlockKey } = getSameIndcNameBlockKeys(indiName)
    if (isValidBlockKey) {
      keysToHide = [...keysToHide, ...kToHide]
    }

    if (propsKeysToHide) {
      propsKeysToHide.forEach((key) => {
        if (!keysToHide.includes(key)) {
          keysToHide.push(key)
        }
      })
    }
    return {
      keysToHide,
      multiConditions: moMultiConditions,
      conditions: moConditions,
      symbol,
      period,
    }
  }

  togglePopoverField = (index, value, multiIndex) => {
    this.setState((prevState) => {
      let conditionToModify = prevState.conditions
      if (multiIndex) {
        conditionToModify = prevState.multiConditions[multiIndex]
      }
      const funcs = [...conditionToModify]
      const newFncInd = { ...funcs[index] }
      newFncInd.show = value
      funcs[index] = newFncInd
      if (multiIndex) {
        return { multiConditions: { ...prevState.multiConditions, [multiIndex]: funcs } }
      }
      return {
        conditions: funcs,
      }
    })
  }

  removeCondition = (index, multiIndex) => {
    const { saveMathFuncDetails, parentIndex } = this.props
    const {
      conditions,
      period,
      multiConditions,
      symbol,
    } = this.state
    let modConditions = [...conditions]
    if (multiIndex) {
      modConditions = [...multiConditions[multiIndex]]
    }
    const modItem = modConditions[index]
    const emptyTemplate = { ...getTemplate('func'), id: modItem.id }
    modConditions[index] = emptyTemplate
    if (multiIndex) {
      this.setState({ multiConditions: { ...multiConditions, [multiIndex]: modConditions } })
    } else {
      this.setState({ conditions: modConditions })
    }
    saveMathFuncDetails(parentIndex, {
      conditions: multiIndex ? conditions : modConditions,
      period,
      symbol,
      valid: true,
      multiConditions: multiIndex
        ? { ...multiConditions, [multiIndex]: modConditions } : multiConditions,
    })
  }

  saveIndicatorDetails = (index, params, candle_freq, multiIndex) => {
    const { saveMathFuncDetails, parentIndex } = this.props
    const {
      conditions,
      period,
      multiConditions,
      symbol,
    } = this.state
    let conditionToModify = conditions
    if (multiIndex) {
      conditionToModify = multiConditions[multiIndex]
    }
    const modConditions = [...conditionToModify]
    const newCond = { ...modConditions[index] }
    const syntax = newCond[this.itemKey] ? newCond[this.itemKey].syntax : ''
    const finalName = nameGenerator(syntax, params)
    newCond.name = finalName
    newCond.valid = !(finalName.search('undefined') > -1)
    newCond.show = false
    newCond.details = params
    modConditions[index] = newCond
    const extraState = { showDetails: false, showIndicator: {} }
    saveMathFuncDetails(parentIndex, {
      conditions: multiIndex ? conditions : modConditions,
      period,
      symbol,
      valid: true, // !(finalName.search('undefined') > -1),
      multiConditions: multiIndex
        ? { ...multiConditions, [multiIndex]: modConditions } : multiConditions,
    })
    modConditions.push(getTemplate('func'))
    if (multiIndex) {
      this.setState({
        multiConditions: { ...multiConditions, [multiIndex]: modConditions }, ...extraState,
      })
    } else {
      this.setState({ conditions: modConditions, ...extraState })
    }
  }

  indicatorSelect = (indicator, index, template, multiIndex, details) => {
    const { saveMathFuncDetails, parentIndex } = this.props
    const {
      period,
      conditions,
      multiConditions,
      symbol,
    } = this.state
    let conditionToModify = conditions
    if (multiIndex) {
      conditionToModify = multiConditions[multiIndex]
    }
    const modConditions = [...conditionToModify]
    const modItem = { ...template }
    modItem.indicatorDetails = indicator
    let extraState = {}
    let showDetails = false
    if (indicator.params.length === 0) {
      modItem.valid = true
      modItem.name = indicator.name
      modItem.details = {}
    } else if (details) {
      const finalName = nameGenerator(modItem.indicatorDetails.syntax, details)
      modItem.name = finalName
      modItem.valid = !(finalName.search('undefined') > -1)
      modItem.show = false
      modItem.details = details
      extraState = { showDetails, showIndicator: {}, sectionType: SECTION_TYPE.INDICATOR }
    } else {
      modItem.show = true
      this.detailsKey = 'details'
      this.itemKey = 'indicatorDetails'
      showDetails = true
      extraState = {
        showDetails, showIndicator: { index, multiIndex }, sectionType: SECTION_TYPE.INDICATOR,
      }
    }
    this.activeCondition = { ...modItem, index, multiIndex }
    modConditions[index] = { ...modItem, id: modConditions[index].id }
    if (multiIndex) {
      this.setState({
        multiConditions: { ...multiConditions, [multiIndex]: modConditions }, ...extraState,
      })
    } else {
      this.setState({ conditions: modConditions, ...extraState })
    }
    const extraChange = modItem.name === MATH_FUNCS.MULTI_TIME_FRAME.name
      ? { showWarning: 'MTF' } : {}
    saveMathFuncDetails(parentIndex, {
      conditions: multiIndex ? conditions : modConditions,
      period,
      symbol,
      valid: true,
      multiConditions: multiIndex
        ? { ...multiConditions, [multiIndex]: modConditions } : multiConditions,
    },
    extraChange)
  }

  comparatorSelect = (comparator, index, template, multiIndex, details) => {
    const { parentIndex, multiConditions, saveMathFuncDetails } = this.props
    const { period, conditions, symbol } = this.state

    let conditionToModify = conditions
    if (multiIndex) {
      conditionToModify = multiConditions[multiIndex]
    }
    const modConditions = [...conditionToModify]
    const modItem = { ...template }
    modItem.comparator = comparator
    let showDetails = false
    let extraState = {}
    if (comparator.params.length === 0) {
      modItem.name = comparator.name
      modItem.valid = true
      modItem.details = {}
    } else if (details) {
      const finalName = nameGenerator(modItem.comparator.syntax, details)
      modItem.name = finalName
      modItem.details = details
      modItem.valid = !(finalName.search('undefined') > -1)
      modItem.show = false
      extraState = { showDetails, showIndicator: {}, sectionType: SECTION_TYPE.COMPARATOR }
    } else {
      this.detailsKey = 'details'
      this.itemKey = 'comparator'
      showDetails = true
      modItem.name = comparator.name
      modItem.valid = true
      modItem.show = true
      extraState = {
        showDetails, showIndicator: { index, multiIndex }, sectionType: SECTION_TYPE.COMPARATOR,
      }
    }
    this.activeCondition = { ...modItem, index, multiIndex }
    modConditions[index] = { ...modItem, id: modConditions[index].id }
    if (multiIndex) {
      this.setState({
        multiConditions: { ...multiConditions, [multiIndex]: modConditions }, ...extraState,
      })
    } else {
      this.setState({ conditions: modConditions, ...extraState })
    }
    saveMathFuncDetails(parentIndex, {
      conditions: multiIndex ? conditions : modConditions,
      period,
      symbol,
      valid: true,
      multiConditions: multiIndex
        ? { ...multiConditions, [multiIndex]: modConditions } : multiConditions,
    })
  }

  handleChange = (index, value, template, multiIndex) => {
    const { saveMathFuncDetails, parentIndex } = this.props
    const {
      period, conditions, multiConditions, symbol,
    } = this.state
    this.setState((prevState) => {
      let conditionToModify = conditions
      if (multiIndex) {
        conditionToModify = multiConditions[multiIndex]
      }
      const funcs = [...conditionToModify]
      const newItem = { ...template }
      if (newItem.type === 'filler') {
        newItem.name = value
        newItem.valid = true
      } else {
        newItem.name = value
      }
      funcs[index] = { ...newItem, id: funcs[index].id }
      saveMathFuncDetails(parentIndex, {
        conditions: multiIndex ? prevState.conditions : funcs,
        period,
        symbol,
        valid: true,
        multiConditions: multiIndex
          ? { ...prevState.multiConditions, [multiIndex]: funcs } : prevState.multiConditions,
      })
      if (multiIndex) {
        return { multiConditions: { ...prevState.multiConditions, [multiIndex]: funcs } }
      }
      return {
        conditions: funcs,
      }
    })
  }

  mathFunctionSelect = (item, index, template, multiIndex) => {
    const { saveMathFuncDetails, candleIntervalIndex, parentIndex } = this.props
    const {
      multiConditions, conditions, symbol, period: indiPeriod,
    } = this.state
    let modConditions = [...conditions]
    if (multiIndex) {
      modConditions = [...multiConditions[multiIndex]]
    }
    const modItem = { ...template }
    const { name } = item
    modItem.name = name
    const period = 10
    modItem.period = period
    if (name.toLowerCase() === 'min' || name.toLowerCase() === 'max') {
      modItem.multiConditions = { 0: 'array', 1: [] }
    } else if (this.singleConditionsArr.includes(name.toLowerCase())) {
      modItem.multiConditions = { 0: 'none' }
    } else if (name === 'Multitime frame' || name === 'Multitime frame completed') {
      modItem.period = candleMap[candleIntervalIndex]
    }
    modConditions[index] = { ...modItem, id: modConditions[index].id }
    if (multiIndex) {
      this.setState({ multiConditions: { ...multiConditions, [multiIndex]: modConditions } })
    } else {
      this.setState({ conditions: modConditions })
    }
    const extraChange = name === MATH_FUNCS.MULTI_TIME_FRAME.name
      ? { showWarning: 'MTF' } : {}
    saveMathFuncDetails(parentIndex, {
      conditions: multiIndex ? conditions : modConditions,
      period: indiPeriod,
      symbol,
      valid: true,
      multiConditions: multiIndex
        ? { ...multiConditions, [multiIndex]: modConditions } : multiConditions,
    },
    extraChange)
  }

  saveDetails = (type, subType, item, index, multiIndex, details) => {
    const smallOption = subType.toLowerCase()
    let template = getTemplate('func')
    if (smallOption === 'bracket') {
      template = getTemplate('bracket')
    } else if (type === 'func' || smallOption === 'comparators') {
      template = getTemplate('func')
    } else if (type === 'filler') {
      template = getTemplate('filler')
      if (smallOption === 'and/or') {
        template.name = 'and'
        template.valid = true
      } else {
        template.name = '+'
        template.valid = true
      }
    } else if (type === 'bracket') {
      switch (smallOption) {
        case 'math operators':
          template = getTemplate('filler')
          template.name = '+'
          template.valid = true
          break
        case 'indicators':
          template = getTemplate('func')
          break
        default:
          break
      }
    }
    if (smallOption === 'math functions') {
      template.mathFunc = true
    }
    template.subType = smallOption
    if (smallOption === 'comparators') {
      this.comparatorSelect(item, index, template, multiIndex, details)
    } else if (smallOption === 'indicators') {
      this.indicatorSelect(item, index, template, multiIndex, details)
    } else if (smallOption === 'bracket' || smallOption === 'and/or' || smallOption === 'math operators') {
      this.handleChange(index, item.name, template, multiIndex)
    } else if (smallOption === 'math functions') {
      this.mathFunctionSelect(item, index, template, multiIndex)
    }
    // this.setState({ conditions: modConditions })
    // saveConditions(modConditions, candle_freq)
  }

  removeField = (index, multiIndex) => {
    const { saveMathFuncDetails, parentIndex } = this.props
    const {
      conditions, multiConditions, period, symbol,
    } = this.state
    let moConditions = [...conditions]
    if (multiIndex) {
      moConditions = [...multiConditions[multiIndex]]
    }
    if ((moConditions[index] && moConditions[index].valid) || moConditions.length === 1) {
      this.removeCondition(index, multiIndex)
      return
    }
    moConditions.splice(index, 1)
    if (multiIndex) {
      this.setState({ multiConditions: { ...multiConditions, [multiIndex]: moConditions } })
    } else {
      this.setState({ conditions: moConditions })
    }
    saveMathFuncDetails(parentIndex, {
      conditions: multiIndex ? conditions : moConditions,
      period,
      symbol,
      valid: true,
      multiConditions: multiIndex
        ? { ...multiConditions, [multiIndex]: moConditions } : multiConditions,
    })
  }

  saveChildMathFuncDetails = (index, funcState, multiIndex, extraChanges) => {
    const { saveMathFuncDetails, parentIndex } = this.props
    const {
      conditions, period, multiConditions, symbol,
    } = this.state
    let modConditions = [...conditions]
    if (multiIndex) {
      modConditions = [...multiConditions[multiIndex]]
    }
    const modItem = { ...modConditions[index] }
    modItem.conditions = funcState.conditions
    modItem.period = funcState.period
    modItem.multiConditions = funcState.multiConditions
    modItem.valid = funcState.valid
    modItem.symbol = funcState.symbol
    modConditions[index] = modItem
    if (multiIndex) {
      this.setState({ multiConditions: { ...multiConditions, [multiIndex]: modConditions } })
    } else {
      this.setState({ conditions: modConditions })
    }

    saveMathFuncDetails(parentIndex, {
      conditions: multiIndex ? conditions : modConditions,
      period,
      symbol,
      valid: true,
      multiConditions: multiIndex
        ? { ...multiConditions, [multiIndex]: modConditions } : multiConditions,
    }, extraChanges)
  }

  renderMathFunctions = (indicator, parentIndex, multiIndex) => {
    const {
      candleIntervalIndex,
      indicatorsList,
      isDark,
      createStrategy,
      styles,
      editType,
    } = this.props
    const { keysToHide, toBeRemoved } = this.state
    return (
      <MathFunctionInput
        key={indicator.id}
        indicator={indicator}
        parentIndex={parentIndex}
        indicatorsList={indicatorsList}
        saveDetails={this.saveDetails}
        removeCondition={this.removeCondition}
        candleIntervalIndex={candleIntervalIndex}
        removeField={() => this.removeField(parentIndex, multiIndex)}
        onInputPress={() => this.onInputPress(indicator, parentIndex, multiIndex)}
        saveMathFuncDetails={
          (pIndex, state, extraChanges) => this.saveChildMathFuncDetails(
            pIndex, state, multiIndex, extraChanges,
          )
        }
        multiIndex={multiIndex}
        isDark={isDark}
        keysToHide={keysToHide}
        createStrategy={createStrategy}
        toBeRemoved={toBeRemoved}
        styles={styles}
        editType={editType}
      />
    )
  }

  setOptions = (prevCondition = {}, mathFunc, parentFunc) => {
    let options = functionOptions
    if (prevCondition.type === 'func' && prevCondition.subType === 'comparators') {
      options = comparatorsOptions
    } else if (prevCondition.mathFunc) {
      options = mathFuncOptions
    } else if (prevCondition.type === 'func') {
      options = indicatorsOptions
    } else if (prevCondition.type === 'bracket') {
      options = bracketsOptions[prevCondition.name] || bracketsOptions.default
    } else if (prevCondition.type === 'filler') {
      options = operatorsOptions
    }
    let allowComparators = false
    if (parentFunc) {
      const mathFuncs = Object.values(MATH_FUNCS)
      for (let index = 0; index < mathFuncs.length; index++) {
        const mathFuncObj = mathFuncs[index] || {}
        if (mathFuncObj.name && mathFuncObj.name === parentFunc.name) {
          ({ allowComparators } = mathFuncObj)
          break
        }
      }
    }
    if (mathFunc && !allowComparators) {
      options = options.filter(item => item.toLowerCase() !== 'comparators')
    }
    this.optionList = options.map(item => item.toLowerCase())
  }

  addCondition = (prevCondition = {}, mathFunc, index = 0, multiIndex) => {
    const { indicator } = this.props
    this.setOptions(prevCondition, mathFunc, indicator)
    this.activeCondition = { index, multiIndex }
    this.setState({ showIndicator: { index, multiIndex } })
  }

  onInputPress = (indicator, i, multiIndex) => {
    const { conditions, multiConditions } = this.state
    if (multiIndex !== undefined && multiConditions[0] && multiConditions[0] === 'array') {
      this.activeCondition = { ...multiConditions[1][i], index: i, multiIndex }
    } else {
      this.activeCondition = { ...conditions[i], index: i, multiIndex }
    }
    const { details, subType } = this.activeCondition
    if (!isEmpty(details)) {
      this.itemKey = 'indicatorDetails'
      this.detailsKey = 'details'
      let sectionType = SECTION_TYPE.INDICATOR
      if (subType === SECTION_TYPE.COMPARATOR) {
        sectionType = SECTION_TYPE.COMPARATOR
        this.itemKey = 'comparator'
        this.detailsKey = 'details'
      }
      this.setState({ showDetails: true, showIndicator: { index: i, multiIndex }, sectionType })
      return
    }
    const { indicatorsList } = this.props
    this.inputOptionsTree = { [subType]: indicatorsList[subType] }
    this.toggleModal('showInputOptions')
  }

  renderConditions = (conditions, multiIndex) => {
    const { isDark, styles } = this.props
    const {
      showIndicator,
      toBeRemoved,
    } = this.state
    return conditions.map((indicator, i) => {
      const { mathFunc, id, valid } = indicator
      if (mathFunc) {
        return this.renderMathFunctions(indicator, i, multiIndex)
      }
      const prevCondition = i === 0 ? {} : conditions[i - 1]
      return showIndicator.index === i
        && showIndicator.multiIndex === multiIndex ? this.renderIndicatorFinder(id) : (
          <ScreenerInput
            key={id}
            indicator={indicator}
            index={i}
            fancyValue={indicator.name}
            prevCondition={prevCondition}
            inputErr={i === conditions.length - 1 ? false : !valid}
            canDeleteAdd={i !== conditions.length - 1}
            onPress={this.onInputPress}
            styles={styles}
            addCondition={this.addCondition}
            multiIndex={multiIndex}
            mathFunc
            isDark={isDark}
            toBeRemoved={toBeRemoved}
            removeField={this.removeField}
            shouldRemove={this.shouldRemove}
          />
        )
    })
  }

  renderIndicatorFinder = (id) => {
    const {
      candleIntervalIndex, indicatorsList, editType,
    } = this.props
    const { period } = this.state
    const state = store.getState()
    const { common: { blockedTimeFrame } } = state
    const { showDetails, sectionType, keysToHide } = this.state
    const finalPeriod = timeFramesToHide.includes(period) ? period
      : timeFramesToHide.includes(blockedTimeFrame) ? blockedTimeFrame : period
    const blockedPivots = ['pivot_points', 'central_pivot_range', 'camarilla']
    let filteredKeysToHide = keysToHide
    if(!timeFramesToHide.includes(finalPeriod)) {
      filteredKeysToHide = keysToHide.filter(key => !['pivot_points', 'central_pivot_range', 'camarilla'].includes(key))
    } else {
      blockedPivots.forEach((key) => {
        filteredKeysToHide.push(key)
      })
    }
    return (
      <IndicatorFinder
        key={id}
        onItemSelect={this.inputSelectHandler}
        onRemove={this.inputRemoveField}
        onDismiss={this.onDismiss}
        sectionType={sectionType}
        tree={indicatorsList}
        showDetails={showDetails}
        onEdit={this.onEdit}
        saveDetails={this.inputSaveDetails}
        indicatorState={this.activeCondition}
        candleIntervalIndex={candleIntervalIndex}
        detailsKey={this.detailsKey} // comparater
        showSectionHeader
        sectionsToShow={this.optionList}
        itemKey={this.itemKey}
        updatedActiveItem={this.updatedActiveItem}
        keysToHide={filteredKeysToHide}
        editType={editType}
      />
    )
  }

  periodChangeHandler = (value = 0) => {
    const { saveMathFuncDetails, parentIndex } = this.props
    this.setState({ period: value }, () => {
      const {
        conditions, period, multiConditions, symbol,
      } = this.state
      saveMathFuncDetails(parentIndex, {
        multiConditions,
        conditions,
        period,
        symbol,
        valid: true,
      })
    })
  }

  renderPeriodDropDown = (name) => {
    const {
      candleIntervalIndex, styles,
    } = this.props
    const { period } = this.state

    if (name === MATH_FUNCS.MULTI_TIME_FRAME.name
      || name === MATH_FUNCS.MULTI_TIMEFRAME_COMPLETED.name) {
      const listData = []
      const keys = Object.keys(candleMap).sort((a, b) => b - a)
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] <= candleIntervalIndex) {
          if (keys[i] !== '5.5' && (candle_min_map[keys[i]] % candle_min_map[candleIntervalIndex] === 0)) {
            listData.push(candleMap[keys[i]])
          }
        }
      }
      listData.unshift('month', 'week')
      return (
        <>
          <ListDropdown
            selected={period}
            options={listData}
            containerStyles={styles.periodDropDownContianer}
            inputContainerStyles={styles.dropdownInputContainer}
            inputBtnStyles={styles.dropdownInput}
            itemStyles={styles.dropdownItem}
            itemBtnStyles={styles.dropdownItemBtn}
            dropdownIconSize={8}
            showDropDownIcon={false}
            showKeyNav
            onChangeOption={this.periodChangeHandler}
          />
          <CustomText className={styles.stableText}>{',  '}</CustomText>
        </>
      )
    }
    return (
      <>
        <CustomInput
          inputError={null}
          value={period.toString()}
          onChangeText={e => this.periodChangeHandler(Number(e.target.value))}
          inputPlaceHolder="Period"
          containerStyles={styles.period}
          inputStyles={styles.periodInput}
          inputProps={{ type: 'number' }}
        />
        <CustomText className={styles.stableText}>{',  '}</CustomText>
      </>
    )
  }

  symbolChangeHandler = (name, item = {}) => {
    const { saveMathFuncDetails, parentIndex } = this.props
    let symbol = ''
    if (item.segment && item.symbol) {
      symbol = `${item.segment}_${item.symbol}`
    }
    this.setState({
      symbol,
    }, () => {
      const { conditions, period, multiConditions } = this.state
      saveMathFuncDetails(parentIndex, {
        multiConditions,
        conditions,
        period,
        symbol,
        valid: true, // this will become invalid when empty symbol is sent to parent
      })
    })
  }

  renderInputField = (name) => {
    const { styles } = this.props
    const { multiConditions, symbol } = this.state // toBeRemoved
    if (multiConditions && multiConditions[0]) {
      // zeroth index determine what to put in multicondition part (array, none)
      if (multiConditions[0] === 'none') {
        return null
      }
      return (
        <>
          {
            Object.keys(multiConditions).map((key) => {
              if (key === '0') {
                return null
              }
              const conditionsArr = multiConditions[key]
              return this.renderConditions(conditionsArr, key)
            })
          }
          <CustomText className={styles.stableText}>, </CustomText>
        </>
      )
    }
    if (name.toLowerCase() === 'symbol') {
      const { isDark } = this.props
      const modSymbol = symbol && symbol.replace('_', ' ')
      return (
        <>
          <AutoComplete
            defaultLabel={modSymbol}
            selected={symbol}
            placeholder="Search symbols"
            onSelect={this.symbolChangeHandler}
            // inputTextStyles={[styles.symSearchBtn, { color: COLORS[isDark ? 'WHITE' : 'BLUE'] }]}
            inputStyles={styles.symSearchInput}
            itemBtnStyles={styles.dropdownItem}
            isDark={isDark}
          />
          <CustomText className={styles.stableText}>{',  '}</CustomText>
        </>
      )
    }
    return this.renderPeriodDropDown(name)
  }

  inputSelectHandler = (item, cType, subType, details) => {
    const { index, multiIndex } = this.activeCondition
    let type = 'filler'
    if (subType === SECTION_TYPE.INDICATOR || subType === SECTION_TYPE.COMPARATOR
      || subType === SECTION_TYPE.MATH_FUNCTION) {
      type = 'func'
    }
    if (item.params.length === 0) {
      this.setState({ showDetails: false, showInputOptions: false, showIndicator: {} })
    } else {
      this.setState({ showInputOptions: false })
    }
    this.saveDetails(type, subType, item, index, multiIndex, details)
  }

  inputSaveDetails = (data, selected_candle) => {
    const { fancyValue } = this.state
    if (!fancyValue) {
      this.setState({ fancyValue: this.activeCondition.name })
    }
    this.saveIndicatorDetails(
      this.activeCondition.index, data, selected_candle, this.activeCondition.multiIndex,
    )
  }

  inputRemoveField = () => {
    const { index, multiIndex } = this.activeCondition
    // if (this.state.showDetails) { // DANGER
    //   this.setState({ showButton: true, showDetails: false })
    //   return
    // }
    this.setState({
      showDetails: false, showIndicator: {}, showInputOptions: false,
    })
    this.removeField(index, multiIndex)
  }

  onDismiss = () => {
    const { saveMathFuncDetails, parentIndex } = this.props
    const {
      conditions, multiConditions, period, symbol,
    } = this.state
    let extraChange = {}
    const { index, multiIndex } = this.activeCondition
    if (!!multiIndex && multiConditions[0] && multiConditions[0] === 'array') {
      const modConditions = [...multiConditions[multiIndex]]
      modConditions[index] = getTemplate('func')
      extraChange = {
        multiConditions: { ...multiConditions, [multiIndex]: modConditions },
      }
      saveMathFuncDetails(parentIndex, {
        conditions,
        period,
        symbol,
        valid: true,
        multiConditions: { ...multiConditions, [multiIndex]: modConditions },
      })
    } else if (!!index && !conditions[index].valid) {
      const modConditions = [...conditions]
      modConditions[index] = getTemplate('func')
      extraChange = { conditions: modConditions }
      saveMathFuncDetails(parentIndex, {
        conditions: modConditions,
        period,
        symbol,
        valid: true,
        multiConditions,
      })
    }
    this.setState({
      showDetails: false, showIndicator: {}, ...extraChange,
    })
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  onMathFuncPress = () => {
    const { onInputPress, parentIndex, indicator } = this.props
    onInputPress(indicator, parentIndex)
  }

  updateToBeRemove = (toBeRemoved) => {
    this.setState({ toBeRemoved })
  }

  renderRemoveBtn = () => {
    const {
      parentIndex, multiIndex, styles, removeCondition,
      isDark,
    } = this.props
    return (
      <button
        type="button"
        className={styles.deleteIconBtnMath}
        onClick={() => removeCondition(parentIndex, multiIndex)}
        onMouseEnter={() => this.updateToBeRemove(true)}
        onMouseLeave={() => this.updateToBeRemove(false)}
      >
        <Icon name={ICONS.CLOSE} color={COLORS[isDark ? 'WHITE' : 'GREY']} size={8} />
      </button>
    )
  }

  render() {
    const {
      indicator,
      // parentIndex,
      isDark,
      styles,
    } = this.props
    const {
      conditions,
      showInputOptions,
      keysToHide,
      toBeRemoved,
    } = this.state
    let inputStyle = {}
    if (toBeRemoved) {
      inputStyle = {
        ...inputStyle,
        textDecoration: 'line-through',
        color: COLORS.RED,
        opacity: '0.4',
        background: theme.borderColor,
        borderRadius: 4,
      }
    }
    return (
      <>
        <CustomModal
          visible={showInputOptions}
          onDismiss={this.toggleModal}
          dismissKey="showInputOptions"
          containerStyles={styles.inputOptionModal}
        >
          <InputOptions
            tree={this.inputOptionsTree}
            activeCondition={this.activeCondition}
            onItemSelect={this.inputSelectHandler}
            onRemove={this.inputRemoveField}
            onDismiss={this.toggleModal}
            selectedName={this.activeCondition.name}
            dismissKey="showInputOptions"
            isDark={isDark}
            keysToHide={keysToHide}
          />
        </CustomModal>
        <div className={`${styles.functionContainer} ${toBeRemoved ? styles.toBeRemovedContainer : ''}`}>
          <CustomText color="linkColor" style={inputStyle} weight="medium" size="small_1" className={styles.funcName} extraProps={{ onClick: this.onMathFuncPress }}>{`${indicator.name} `}</CustomText>
          <CustomText color="linkColor" style={inputStyle} weight="medium" className={styles.mathFuncBracketLeft}>{'(  '}</CustomText>
          {this.renderInputField(indicator.name)}
          {this.renderConditions(conditions)}
          <CustomText color="linkColor" style={inputStyle} weight="medium" className={styles.mathFuncBracketRight}>)</CustomText>
          <div className={styles.removePlaceHolder}>{this.renderRemoveBtn()}</div>
        </div>
      </>
    )
  }
}

const defaultFieldSpacing = {
  marginRight: SPACING.SPACE_12,
  padding: `${SPACING.SPACE_2} ${SPACING.SPACE_8}`,
  marginBottom: SPACING.SPACE_16,
}

const stylesheet = ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  functionContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: SPACING.SPACE_12,
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    '& > $funcName': {
      ...defaultFieldSpacing,
    },
    '& > $mathFuncBracketLeft': {
      marginBottom: defaultFieldSpacing.marginBottom,
      paddingRight: `${SPACING.SPACE_6}`,
    },
    '& > $mathFuncBracketRight': {
      marginBottom: defaultFieldSpacing.marginBottom,
      marginRight: SPACING.SPACE_10,
    },
    '& > $removePlaceHolder': {
      marginBottom: defaultFieldSpacing.marginBottom,
    },
    '&:hover $deleteIconBtnMath': {
      display: 'flex',
      // right: '5px',
    },
  },
  removePlaceHolder: {
    width: 0,
    height: 0,
    position: 'relative',
  },
  mathFuncBracketLeft: {},
  mathFuncBracketRight: {},
  toBeRemovedContainer: {
    '& > *': {
      textDecoration: 'line-through',
      color: COLORS.RED,
      opacity: '0.4',
    },
  },
  funcName: {
    marginRight: '0 !important',
  },
  mathFuncBracket: {},
  periodDropDownContianer: {
    ...defaultFieldSpacing,
    width: 80,
    padding: 0,
    backgroundColor: theme.blueLightBg,
  },
  dropdownInput: {
    padding: defaultFieldSpacing.padding,
  },
  dropdownItem: {
    textAlign: 'left',
    padding: '4px 10px',
  },
  deleteIconBtnMath: {
    padding: `${SPACING.SPACE_4}`,
    display: 'none',
    position: 'relative',
    opacity: '1 !important',
    height: 20,
    width: 16,
    top: '-10px',
    right: '8px',
    alignItems: 'center',
    borderRadius: '4px',
  },
  // screener input
  addBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...defaultFieldSpacing,
    padding: `0 ${SPACING.SPACE_10} !important`,
    borderColor: COLORS.ORANGE,
    borderWidth: 2,
    borderRadius: 4,
    backgroundColor: theme.bgPrimary,
    borderStyle: 'dashed',
  },
  addBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginRight: SPACING.SPACE_6,
    '&:hover $addDeleteIconBtn': {
      display: 'flex',
      right: '-10px',
    },
  },
  addDeleteIconBtn: {
    display: 'none',
    right: 0,
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    borderRadius: '4px',
    opacity: '1 !important',
    ...defaultFieldSpacing,
    padding: 0,
  },
  inputBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    ...defaultFieldSpacing,
    borderRadius: 4,
    transition: 'all 0.3s var(--anim-func-ease)',
    '&:hover $deleteIconBtn': {
      display: 'flex',
      right: '-10px',
    },
  },
  deleteIconBtn: {
    padding: `${SPACING.SPACE_4}`,
    marginLeft: SPACING.SPACE_4,
    display: 'none',
    right: 0,
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    borderRadius: '4px',
    opacity: '1 !important',
  },
  inputBtn: {
  },
  period: {
    maxWidth: 50,
    // borderColor: COLORS.BORDER_COLOR,
    // borderWidth: 1,
    height: 25,
    alignItems: 'center',
  },
  periodInput: {
    width: '100%',
    color: theme.linkColor,
    fontSize: FONTS.SMALL,
    ...defaultFieldSpacing,
  },
  symSearchBtn: {
    flex: 0,
    paddingHorizontal: SPACING.SPACE_10,
    paddingVertical: 0,
    fontSize: FONTS.TINY,
  },
  symSearchInput: {
    fontSize: FONTS.SMALL,
    minWidth: '80px',
    width: 'unset',
    ...defaultFieldSpacing,
  },
  stableText: {
    marginRight: '4px !important',
  },
  inputOptionModal: {
    padding: 0,
    maxWidth: 350,
  },
})

export default withTheme(stylesheet)(MathFunctionInput)
