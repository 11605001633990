import { takeLatest, put, call } from 'redux-saga/effects'
import {
  prodEndpoints, baseUrl, wpBaseUrl, wpEndpoints,
} from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  fetchFundsSuccess,
  fetchFundsFailure,

  fetchSampleAlgoSuccess,
  fetchSampleAlgoFailure,

  fetchTopperformersSuccess,
  fetchTopperformersFailure,
  cloneSampleSuccess,
  cloneSampleFailure,
  getWhatsNewSuccess,
  getWhatsNewFailure,
  fetchDashboardExploreSuccess,
  fetchDashboardExploreFailure,
  getPartialCompleteSuccess,
  getPartialCompleteFailure,
  getRecommendationsSuccess,
  getRecommendationsFailure,
  getDashboardCarouselSuccess,
  getDashboardCarouselFailure,
  getWordpressCarouselSuccess,
  getWordpressCarouselFailure,
} from './actions'
import {
//   SAMPLE_ALGO_INIT,
//   TOP_PERFORMERS_INIT,
//   DASHBOARD_METRICS_INIT,
  CLONE_SAMPLE_INIT,
  FETCH_DASHBOARD_EXPLORE,

  GET_PARTIAL_COMPLETE_INIT,
  GET_RECOMMENDATIONS_INIT,
  GET_DASHBOARD_CAROUSEL_INIT,
  FETCH_FUNDS_INIT,
  FETCH_SAMPLE_ALGO_INIT,
  FETCH_PERFORMERS_INIT,
  FETCH_WHATS_NEW_INIT,
  GET_WORDPRESS_CAROUSEL_INIT,
} from './actionTypes'

import { error_msg } from '../../utils/common'

function* fetchFunds(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.fetch_funds}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchFundsSuccess(data))
    } else {
      yield put(fetchFundsFailure(data.error))
    }
  } catch(err) {
    yield put(fetchFundsFailure(err.message || error_msg))
  }
}

export function* fetchFundsSaga() {
  yield takeLatest(FETCH_FUNDS_INIT, fetchFunds)
}

function* fetchSampleAlgo(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.fetch_sample_algo}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchSampleAlgoSuccess(data))
    } else {
      yield put(fetchSampleAlgoFailure(data.error))
    }
  } catch (err) {
    yield put(fetchSampleAlgoFailure(err.message || error_msg))
  }
}

export function* fetchSampleAlgoSaga() {
  yield takeLatest(FETCH_SAMPLE_ALGO_INIT, fetchSampleAlgo)
}

function* fetchTopperformers(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.fetch_top_performers}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchTopperformersSuccess(data))
    } else {
      yield put(fetchTopperformersFailure(data.error))
    }
  } catch (err) {
    yield put(fetchTopperformersFailure(err.message || error_msg))
  }
}

export function* fetchTopperformersSaga() {
  yield takeLatest(FETCH_PERFORMERS_INIT, fetchTopperformers)
}

function* cloneSample(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.clone_sample}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(cloneSampleSuccess(data))
    } else {
      yield put(cloneSampleFailure(data.error))
    }
  } catch (error) {
    yield put(cloneSampleFailure(error || error_msg))
  }
}

export function* cloneSampleSaga() {
  yield takeLatest(CLONE_SAMPLE_INIT, cloneSample)
}

function* getWhatsNew(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.whats_new}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(getWhatsNewSuccess(data))
    } else {
      yield put(getWhatsNewFailure(data.error))
    }
  } catch (error) {
    yield put(getWhatsNewFailure(error || error_msg))
  }
}

export function* getWhatsNewSaga() {
  yield takeLatest(FETCH_WHATS_NEW_INIT, getWhatsNew)
}

function* fetchDashboardExplore(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.dashboard_discover}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchDashboardExploreSuccess(data))
    } else {
      yield put(fetchDashboardExploreFailure(data.error))
    }
    //   technicalPairs(dispatch, data.instruments_list) DANGER
  } catch (error) {
    yield put(fetchDashboardExploreFailure(error || error_msg))
  }
}

export function* fetchDashboardExploreSaga() {
  yield takeLatest(FETCH_DASHBOARD_EXPLORE, fetchDashboardExplore)
}

function* getPartialComplete(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.partialAlgos}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(getPartialCompleteSuccess(data))
    } else {
      yield put(getPartialCompleteFailure(data.error.msg))
    }
  } catch (error) {
    yield put(getPartialCompleteFailure(error || error_msg))
  }
}

export function* getPartialCompleteSaga() {
  yield takeLatest(GET_PARTIAL_COMPLETE_INIT, getPartialComplete)
}

function* getRecommendations(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.recommendations}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(getRecommendationsSuccess(data))
    } else {
      yield put(getRecommendationsFailure(data.error.msg))
    }
  } catch (error) {
    yield put(getRecommendationsFailure(error || error_msg))
  }
}

export function* getRecommendationseSaga() {
  yield takeLatest(GET_RECOMMENDATIONS_INIT, getRecommendations)
}

function* getDashboardCarousel(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.dashboard_carousel}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(getDashboardCarouselSuccess(data))
    } else {
      yield put(getDashboardCarouselFailure(data.error.msg))
    }
  } catch (error) {
    yield put(getDashboardCarouselFailure(error || error_msg))
  }
}

export function* getDashboardCarouselSaga() {
  yield takeLatest(GET_DASHBOARD_CAROUSEL_INIT, getDashboardCarousel)
}

function* getWordpressCarousel({ params }) {
  try {
    const url = `${wpBaseUrl}${wpEndpoints.pages}`
    const response = yield call(getRequest, url, params)
    if (!response.error && response.length > 0) {
      yield put(getWordpressCarouselSuccess(response))
    } else {
      yield put(getWordpressCarouselFailure(response.error_msg))
    }
  } catch (err) {
    yield put(getWordpressCarouselFailure(err))
  }
}

export function* getWordpressCarouselSaga() {
  yield takeLatest(GET_WORDPRESS_CAROUSEL_INIT, getWordpressCarousel)
}
