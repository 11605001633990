import React from 'react'
import classNames from 'classnames'

import { CONDITION_TYPE } from '../../containers/Create/Create-utils'
import { ICONS, SPACING, theme } from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import Button from '../../UI/Button'
import CustomInput from '../../UI/CustomInput'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import { getColor } from '../../UI/Steps'

const renderStep = ({
  isActive, isCompleted, hasError, styles, tickStyles = '',
}) => {
  if (isCompleted && !isActive) {
    return (
      <Icon
        name={ICONS.TICK_ROUND_FILLED}
        color={theme.linkColor}
        className={`${styles.stepIcon} ${tickStyles}`}
      />
    )
  }
  return (
    <div
      className={`${styles.stepBtn}  ${isActive ? styles.stepActive : ''}`}
      style={{
        backgroundColor: getColor({
          field: 'btnBg', activeColor: theme.linkColor, isActive, hasError,
        }),
        borderColor: getColor({
          field: 'btnBorder', activeColor: theme.stepsCircleBorder, isActive, hasError,
        }),
      }}
    >
      <CustomText
        size="small"
        color={getColor({
          field: 'label', isActive, activeColor: 'textSecondary', hasError,
        })}
      >
        {' '}
      </CustomText>
    </div>

  )
}

const CreateRow = React.forwardRef((props, ref) => {
  const {
    children, styles, isActive, hasError, isCompleted, isLast = false,
    isFirst, stepContainerStyles, rowContentStyles,
  } = props
  return (
    <div className={styles.row} ref={ref}>
      <div className={styles.rowStep}>
        <div
          className={`${styles.stepContainer} ${stepContainerStyles}`}
        >
          {renderStep({
            isActive, isCompleted, hasError, styles,
          })}
        </div>
        <div className={`${styles.stepLine}
          ${isLast ? styles.stepLastLine : ''}
          ${isFirst ? styles.stepFirstLine : ''}`}
        />
      </div>
      <div className={`${styles.rowContent} ${rowContentStyles}`}>
        {children}
      </div>
    </div>
  )
})

function ScreenerSingle(props) {
  const {
    styles, conditionEditType, positionRef, renderContent,
    scan_on, entryValid, showError, entryRef, screenerName,
    screenerNameRef, handleScreenerNameChange, onSaveNRun, isBtnDisabled,
    screenerDesc, descChangeHandler,
  } = props

  const onNameChange = (e) => {
    handleScreenerNameChange({ listName: e.target.value })
  }

  const onDescChange = (e) => {
    descChangeHandler(e)
  }

  return (
    <div className={styles.createProContainer}>
      <div className={styles.proContent}>
        <CreateRow
          styles={styles}
          isActive={conditionEditType === CONDITION_TYPE.POSITION}
          isFirst
          ref={positionRef}
          isCompleted={scan_on}
          hasError={!scan_on}
          rowContentStyles={styles.rowContentStyle}
        >
          {renderContent()}
        </CreateRow>
        <CreateRow
          styles={styles}
          isActive={conditionEditType === CONDITION_TYPE.SCREENER}
          isCompleted={entryValid}
          hasError={!entryValid && showError}
          ref={entryRef}
        >
          {renderContent(false, CONDITION_TYPE.SCREENER)}
        </CreateRow>
        <CreateRow
          styles={styles}
          isActive={conditionEditType === CONDITION_TYPE.SCREENER_NAME}
          isCompleted={screenerName}
          hasError={!screenerName}
          stepContainerStyles={styles.nameStepContainer}
          ref={screenerNameRef}
        >
          <CustomInput
            placeholder="Save scanner as"
            inputKey="screenerName"
            inputError={!screenerName}
            value={screenerName}
            inputRegx={/^[a-zA-Z0-9\s]*$/gi}
            onChangeText={onNameChange}
            inputStyles={styles.fieldInput}
            containerStyles={styles.fieldInputContainer}
            wrapperStyles={styles.inputWrapper}
          />
        </CreateRow>
        <CreateRow
          styles={styles}
          isActive={conditionEditType === CONDITION_TYPE.SCREENER_NAME}
          isCompleted={screenerDesc}
          hasError={!screenerDesc}
          stepContainerStyles={styles.nameStepContainer}
        >
          <CustomText weight="medium" className={styles.inputLabel}>Scanner Description</CustomText>
          <CustomInput
            inputKey="screenerDesc"
            value={screenerDesc}
            placeholder="Add your scanner description here"
            onChangeText={onDescChange}
            inputStyles={classNames(styles.fieldInput, styles.descInput)}
            multiline
            inputProps={{
              rows: 5,
            }}
          />
        </CreateRow>
        <CreateRow
          styles={styles}
          isActive={false}
          isCompleted={screenerName && entryValid && scan_on}
          hasError={false}
          isLast
        >
          <Button
            text="Save and Scan"
            onPress={onSaveNRun}
            loading={isBtnDisabled}
            buttonColor="blue"
            btnStyles={`${styles.saveBtn} ${isBtnDisabled ? styles.saveBtnDisable : ''}`}
          />
        </CreateRow>
      </div>

    </div>
  )
}

const stylesheet = {
  createProContainer: {},
  sectionTabContainer: {
    minWidth: 200,
    marginRight: SPACING.SPACE_20,
  },
  proContent: {},
  row: {
    display: 'flex',
  },
  rowStep: {
    flex: 0.05,
    minWidth: 80,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: SPACING.SPACE_22,
  },
  stepLine: {
    width: SPACING.SPACE_8,
    backgroundColor: theme.orange,
    height: '100%',
    position: 'absolute',
    top: 0,
  },
  stepLastLine: {
    height: 30,
  },
  stepFirstLine: {
    top: 'unset',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  stepDot: {},
  rowContent: {
    flex: 1,
    padding: `${SPACING.SPACE_20} 0`,
  },
  rowContentStyle: {
    width: '90%',
  },
  paramsCell: {
    display: 'inline-block',
    width: '100%',
    maxWidth: 200,
    minWidth: 100,
    marginBottom: SPACING.SPACE_10,
  },
  paramsContainer: {
    padding: SPACING.SPACE_20,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    background: theme.bgPrimary,
    borderRadius: SPACING.SPACE_10,
  },
  paramsTitle: {
    padding: `0 ${SPACING.SPACE_10} 0 ${SPACING.SPACE_6}`,
  },
  paramsView: {},
  paramsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} 0`,
  },
  paramsHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  paramsHeaderRight: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginRight: SPACING.SPACE_6,
    },
  },
  paramsContent: {
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  conditionContainer: {
    margin: 0,
  },
  stepContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    maxHeight: 20,
    borderRadius: '50%',
    zIndex: 2,
    maxWidth: 20,
  },
  activeStepValue: {
    backgroundColor: '#fff',
    height: '50%',
    width: '50%',
    borderRadius: '50%',
    maxHeight: 10,
    maxWidth: 10,
  },
  stepBtn: {
    width: '100%',
    borderRadius: '50%',
    border: `5px solid ${theme.stepsCircleBorder}`,
  },
  paramsStepContainer: {
    marginTop: 26,
  },
  nameStepContainer: {
    marginTop: SPACING.SPACE_8,
  },
  symbolContainer: {
    marginTop: 0,
  },
  stepIcon: {
    height: '100%',
    width: '100%',
    maxHeight: 20,
    zIndex: 2,
    maxWidth: 20,
    backgroundColor: theme.bgPrimary,
  },
  actionBar: {
    margin: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_30}`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  positions: {
    // marginTop: SPACING.SPACE_20,
  },
  saveBtn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_24}`,
  },
  saveBtnDisable: {
    backgroundColor: theme.disabled,
    borderColor: theme.disabled,
  },
  paramsModal: {
    maxWidth: 815,
    paddingTop: 0,
  },
  inputLabel: {
    marginBottom: SPACING.SPACE_12,
  },
}

export default withTheme(stylesheet)(ScreenerSingle)
