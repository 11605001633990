import { pathname_mapping } from './utils/navigationConstant'

export const pageName = {
  '/': 'Dashboard',
  [pathname_mapping.Dashboard]: 'Dashboard',
  [pathname_mapping.Strategies]: 'Strategies',
  [pathname_mapping.MyStrategy]: 'My strategies',
  [pathname_mapping.Discover]: 'Discover strategies',
  [pathname_mapping.marketwatch]: 'Technicals',
  [pathname_mapping.symbolDetails]: 'Technicals',
  [pathname_mapping.ScanDetails]: 'Scanner result',
  [pathname_mapping.holdings]: 'Portfolio',
  [pathname_mapping.positions]: 'Portfolio',
  [pathname_mapping.live]: 'Deployed',
  [pathname_mapping.paper]: 'Deployed',
  [pathname_mapping.Scanners]: 'Scanners',
  [pathname_mapping.popular]: 'Discover scanners',
  [pathname_mapping.live_scans]: 'Live scanners',
  [pathname_mapping.my_scans]: 'My scanners',
  [pathname_mapping.SharedBacktest]: 'Shared Backtest',
  [pathname_mapping.Backtests]: 'Backtest',
  [pathname_mapping.Create]: 'Create strategy',
  [pathname_mapping.NewScanner]: 'Create scanner',
  [pathname_mapping.profile]: 'Profile',
  [pathname_mapping.Accounts]: 'Profile',
  [pathname_mapping.preferences]: 'Preferences',
  [pathname_mapping.billing]: 'Billing',
  [pathname_mapping.pricing]: 'Pricing',
  [pathname_mapping.Notifications]: 'Notifications',
  [pathname_mapping.Referrals]: 'Referrals',
  [pathname_mapping.Rewards]: 'My rewards',
}

// search, pagination are in respective files
export const gaActions = {
  viewBacktest: 'View backtest',
}
