import {
  FETCH_ORDERS_INIT,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  CANCEL_ORDER_INIT,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
  CLEAR_ORDERBOOK_CANCEL_RESP,
} from './actionTypes'

const initialState = {
  executed: [],
  pending: [],
  isFetchingOrders: true,
  fetchOrdersErrormsg: {},
  ordersError: false,
  cancelResp: {},
  isCanceling: false,
  cancelError: false,
}

const orderbook = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_INIT:
      return {
        ...state,
        isFetchingOrders: true,
        ordersError: false,
        fetchOrdersErrormsg: {},
      }

    case FETCH_ORDERS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingOrders: false,
        executed: data.executed.reverse() || [],
        pending: data.pending.reverse() || [],
      }
    }

    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        isFetchingOrders: false,
        ordersError: true,
        fetchOrdersErrormsg: action.error,
      }

    case CANCEL_ORDER_INIT:
      return {
        ...state,
        isCanceling: true,
        cancelError: false,
      }

    case CANCEL_ORDER_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isCanceling: false,
        cancelResp: data,
      }
    }

    case CANCEL_ORDER_FAILURE: {
      return {
        ...state,
        isCanceling: false,
        cancelError: true,
      }
    }

    case CLEAR_ORDERBOOK_CANCEL_RESP: {
      return {
        ...state,
        cancelResp: {},
        isCanceling: false,
        cancelError: false,
        fetchOrdersErrormsg: {},
      }
    }

    default:
      return state
  }
}

export default orderbook
