/* eslint-disable semi-spacing */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import classnames from 'classnames'
import dayjs from 'dayjs'

import Button from '../../UI/Button'
import CustomModal from '../../UI/CustomModal'
import CreateNewList from '../../UI/CreateNewList'
import ScreenerConditionRenderer from '../../components/Screener/ConditionRenderer'
import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import AlertPopup from '../../UI/AlertPopup'
import ScreenHeader from '../../UI/ScreenHeader'
import { showSnackbar } from '../../UI/Snackbar'
import SectionTab from '../../UI/SectionTab'
import fTree from '../../components/Create/tree.json'
import ConditionRenderer from '../../components/Create/ConditionRenderer'
import AnimatedCarousal from '../../UI/AnimatedCarousal'
import SymbolCard from '../../components/Create/SymbolCard'
import ScanOnFinder from '../../components/Screener/ScanOnFinder'
import EntryExitRenderer from '../../components/Backtests/EntryExitRenderer'
import CustomTooltip from '../../UI/CustomTooltip'

import {
  freq_candle_map,
  candle_freq_map,
  sector_map,
  candleIntervals,
  screenerIndicatorPattern,
  // STOCK_ADD_LIMIT,
  BASKET_ADD_LIMIT,
  MATH_FUNCS,
  indicatorPattern,
  defaultChartList,
  BROKERS,
  ROOT_MODALS,
  CHART_ICON_MAP,
  candle_map,
  LINK_MAP,
  tf_map,
  rev_candle,
  rev_mtf_candle,
  candle_min_map,
  AB_TESTING_MAP,
} from '../../utils/consts'
import {
  ICONS, COLORS, SPACING, ASSETS, theme,
} from '../../Theme'
import { NAVIGATIONS, pathname_mapping } from '../../utils/navigationConstant'
import {
  screenerLogicStringGenerator, parseQuery, getCandleIntervalsMap, setLocalStorage,
} from '../../utils/common'
import {
  logicToStringGenerator, ALGO_MODE, CONDITION_TYPE, CREATE_PAGE_VIEW_MAP,
} from '../Create/Create-utils'
import { accessConditions, PRO_FEATURE_MAP, userAccessCheck } from '../../utils/userAccessChecks'
import { withTheme } from '../../Theme/ThemeProvider'
import ScreenerMulti from '../../components/Screener/ScreenerMulti'
import ScreenerSingle from '../../components/Screener/ScreenerSingle'
import { STEP_CONDITION_TYPE, tabsProps } from './Screener-utils'
import { isExperimentOn } from '../../utils/experimenting'
import { PREFERENCES } from '../Profile/profileUtils'
import { PRODUCT_TOUR_SECTIONS } from '../../components/ProductTour/ProductTour-utils'
import CustomInput from '../../UI/CustomInput'

const { CREATE_SWITCH } = ASSETS
const CARD_HEIGHT = 140
const CARD_WIDTH = 120
let gaParamsMap = []

const multipleCiProps = {
  hideDelay: 5000,
  showReadMore: true,
  link: 'https://blog.streak.tech/timeframe-compatibility-and-setting-a-base-timeframe/',
}

const blockedPeriods = ['week', 'month']
const blockedPivotsIndc = ['Pivot point', 'Camarilla Pivot', 'Central pivot range']

export const checkBlockedMTFIndc = (logic = '') => {
  const regexPattern = /(?:Multitime frame|Multitime frame completed)\([^)]+\)/g
  const matches = logic?.match(regexPattern) || []

  const results = []

  matches?.forEach((match) => {
    const matchedPeriod = blockedPeriods.find(period => match.includes(period))
    const matchedIndc = blockedPivotsIndc.find(indc => match.includes(indc))

    const blockedTF = blockedPeriods.some(period => match.includes(period))
    const blockedPivots = blockedPivotsIndc.some(indc => match.includes(indc))

    const isMatched = blockedTF && blockedPivots

    const result = {
      isMatched,
      matchedPeriod,
      matchedIndc,
    }

    if (isMatched) {
      results.push(result)
    }
  })
  return results
}

class Scanner extends Component {
  constructor(props) {
    super(props)
    const {
      location,
      preferences,
      startTour,
    } = this.props

    const { search, state = {} } = location
    const {
      passedId: savedId, copy, edit: shouldEdit, advance = true, create_new, discover,
      type: conditionEditType = CONDITION_TYPE.POSITION, sample: isSmaple,
      id, // commes from partial page
    } = parseQuery(search, true)
    const passedId = savedId || id
    const edit = shouldEdit || (passedId && !copy)
    const sample = !!(isSmaple && isSmaple === 'true')
    this.routeParams = {
      ...state,
      passedId,
      edit,
      copy,
      advance,
      create_new,
      conditionEditType,
      sample: isSmaple,
    } // passedId, scan, sample, nameDisabled, discover

    this.createNew = !!create_new
    const showNameChange = this.createNew
    const allowed_scans = this.getAllowedScan()
    //   fetchWatchList({ complete: true })
    const createAdvance = advance
    this.chartList = defaultChartList.filter(cType => cType !== 'Renko')

    this.screenerEdited = false
    this.runSave = false
    this.indicatorsList = {}
    this.comparatorsList = {}
    this.scrollRef = React.createRef()
    this.candleList = getCandleIntervalsMap(candleIntervals, candleIntervals)
    this.autoSaveInterval = null
    this.activeIndex = 0
    const pageMode = preferences[PREFERENCES.SINGLE_PAGE_MODE] !== undefined
      ? !preferences[PREFERENCES.SINGLE_PAGE_MODE]
      : ''
    this.isExperimentOn = isExperimentOn(AB_TESTING_MAP.CREATE_PAGE)

    const isMultiPage = startTour ? true : (pageMode !== '' ? pageMode : !this.isExperimentOn)
    // fetch indicator list and trainer model
    const initialConditionState = this.setCreateConditionState(createAdvance, true)
    this.state = {
      ...initialConditionState,
      andOrs: ['and'],
      chart_type: this.chartList[0],
      scan_on: 'Nifty 100',
      time_frame: '1 Minute',
      name: this.routeParams.name || '',
      gotState: false,
      sample,
      buySell: false,
      technical: false,
      assist: false,
      //   scan,
      scrollEnabled: true,
      passedId,
      activeIndex: -1,
      nameEditable: false,
      screener_logic: '',
      setAlerts: false,
      isScreenerLive: false,
      filterValue: '',
      selectedStocks: {},
      moddedStocks: [],
      allSelected: false,
      showFilter: false,
      allowed_scans,
      scanOnBasket: '',
      showScripModal: false,
      conditionsValid: sample || discover,
      scanner_desc: '',

      conditionEditType: null,
      showNameChange,
      showAlert: false,
      createAdvance,
      showCreateAdvance: true,
      stepsCompleted: [],
      readMore: false,
      scanOnModal: false,
      showReadMore: false,
      copiedCondition: {},

      showWarning: false,
      userCanceledWarning: false,
      invalidCandleIntervalMultipleScreener: false,
      invalidCandleIntervalScreener: false,

      isMultiPage,
    }
    this.sections = Object.values(ALGO_MODE)
    this.alertProps = {}
    this.descRef = React.createRef()
    this.containerRef = React.createRef()
    this.createPageViewSection = Object.values(CREATE_PAGE_VIEW_MAP)

    gaParamsMap = []
  }

  componentDidMount() {
    const {
      getScreenerState, user_details: { broker = '' }, changePtComponentStatus, updateTourIndex,
      activeTourIndex,
    } = this.props
    const { newAlgo } = this.routeParams
    const { passedId, sample, isMultiPage } = this.state
    // if id is coming fetch saved state
    if (passedId && !newAlgo) {
      this.initialFetch = true
      const params = {
        screener_uuid: passedId,
        sample,
        broker,
      }
      getScreenerState(params)
      const gaAction = isMultiPage ? 'Landed on multipage' : 'Landed on singlepage'
      this.updateGA(gaAction, 'AB_TESTING_CREATEPAGE')
    }
    if (activeTourIndex < 4) updateTourIndex(2)
    setTimeout(() => changePtComponentStatus('ScreenerUpdated'), 300)
    // start auto save startegy
    if (newAlgo) this.startAutoSave(10000)
    // clearing browser state params to clear new flag (newAlgo) which is coming in state
    // so that is user refresh it shud fetch the algo
    window.history.replaceState({}, document.title)
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      scanError,
      scanErrorMsg,
      saveScreenResp,
      saveScreenError,
      saveScreenErrorMsg,
      screenerState,
      screenerStateError,
      screenerStateErrorMsg,
      tree,
      market_watches,
      history,
      stopAlertScreenResp,
      isStoppingAlertScreens,
      stopAlertScreenError,
      stopAlertScreenErrorMsg,
      isSavingScreen,
      isDeleteingScreen,
      deleteScreenResp,
      deleteScreenError,
      deleteScreenErrorMsg,
      pairsFromSegSym,
      refreshAlgos,
      tourType,
      startTour,
      activeTourIndex,
      updateTourIndex,
      fetchCreateInstruments,
    } = this.props
    const {
      gotState,
      sample,
      indicatorListUpdated: prevIndicatorsUpdated,
      scanOnBasket,
      createAdvance: stateCreateAdvance,
      screener_logic,
      time_frame,
      expressions: entryIndicators,
    } = this.state
    const result = checkBlockedMTFIndc(screener_logic)
    const { isMatched = false, matchedIndc = null, matchedPeriod = null } = result[0] || {}

    if (!prevIndicatorsUpdated && tree.indicators) {
      if (stateCreateAdvance) {
        // update tree scanner
        this.indicatorsList = tree
      } else {
        // update indicator (create basic)
        this.indicatorsList = tree.indicators
        this.comparatorsList = tree.comparators
      }
      this.setState({ indicatorListUpdated: true })
    }

    if (!isEqual(scanError, prevProps.scanError) && scanError) {
      const errorMsg = scanErrorMsg === 'Error getting response'
        ? 'Scanner is taking longer than expected. Try running the scanner on a smaller basket.' : scanErrorMsg
      if (this.runSave) {
        this.runSave = false
      }
      showSnackbar(errorMsg, {}, 0)
    }

    if (scanOnBasket && scanOnBasket.length) {
      fetchCreateInstruments(scanOnBasket, NAVIGATIONS.SCANNER.name)
    }
    // logic for blocking PivotPoints/CPR/Camarilla inside mtf/mtfc when timeframe > day
    if (isMatched && prevState.screener_logic !== screener_logic) {
      showSnackbar(`Cannot use ${matchedIndc} with ${matchedPeriod}, please select timeframe lower than equal to 'day'`)
      return
    }
    if (isSavingScreen !== prevProps.isSavingScreen && !isSavingScreen) {
      if (this.runSave) {
        if (saveScreenError) {
          this.runSave = false
          showSnackbar(saveScreenErrorMsg || 'Error saving scanner', {}, 0)
        } else if (saveScreenResp.status === 'success') {
          this.runSave = false
          showSnackbar('Scanner saved successfully', {}, 1)
          if (gaParamsMap.length) {
            gaParamsMap.map(item => this.updateGA(...item))
            gaParamsMap = []
          }
          history.replace({
            pathname: pathname_mapping.Scanners,
            search: `?id=${btoa(saveScreenResp.screener_uuid)}&tab=scan_details`,
          })
          refreshAlgos('scans', true)
        }
      } else if (this.createNew) {
        if (saveScreenError) {
          showSnackbar(saveScreenErrorMsg || 'Error creating scanner', {}, 0)
        } else if (saveScreenResp.status === 'success') {
          this.createNew = false
          this.setState({
            screener_uuid: saveScreenResp.screener_uuid,
            showNameChange: false,
          })
          this.startAutoSave(10000)
        }
      }
    }
    if (!isEqual(screenerState, prevProps.screenerState)
      && screenerState.screener_state && !gotState) {
      let createAdvance = stateCreateAdvance
      const {
        createAdvance: savedCreateAdvance = true, expressions,
        conditionEditType: savedEditType = CONDITION_TYPE.POSITION,
      } = screenerState.screener_state
      // new scanner
      if (expressions !== undefined) {
        createAdvance = savedCreateAdvance
      }
      let extraState = {}
      if (stateCreateAdvance !== createAdvance) {
        extraState = this.setCreateConditionState(createAdvance)
      }
      const conditionEditType = this.routeParams.conditionEditType || savedEditType
        || screenerState.screener_state.conditionEditType || CONDITION_TYPE.POSITION
      this.activeIndex = this.getStepIndex(conditionEditType)

      const showWarning = savedCreateAdvance ? this.shouldShowWarning() : false
      this.setState({
        ...extraState,
        ...screenerState.screener_state,
        screener_uuid: screenerState.screener_uuid,
        gotState: true,
        scrollEnabled: true,
        isScreenerLive: !sample && screenerState.live,
        screener_logic: screenerState.screener_logic,
        name: screenerState.screener_name || screenerState.screener_state.name || '',
        scanner_desc: screenerState.screener_desc || screenerState.screener_state.screener_desc || '',
        nameEditable: !this.routeParams.nameDisabled,
        showError: false,
        createAdvance,
        conditionEditType,
        showWarning,
      }, () => {
        this.updateCompletedSteps()
      })
      if (screenerState.complete === false) {
        this.startAutoSave(20000)
      }
    } else if (!isEqual(screenerStateError, prevProps.screenerStateError) && screenerStateError) {
      showSnackbar(screenerStateErrorMsg || 'Error getting scan details', {}, 0)
    }
    if (!isEqual(market_watches, prevProps.market_watches)) {
      const allowed_scans = this.getAllowedScan()
      this.setState({ allowed_scans })
    }

    if (screener_logic !== prevState.screener_logic) {
      this.shouldShowReadMore()
      checkBlockedMTFIndc(screener_logic)
    }

    if (isStoppingAlertScreens !== prevProps.isStoppingAlertScreens && !isStoppingAlertScreens) {
      if (stopAlertScreenResp.status === 'success') {
        this.setState({ isScreenerLive: false })
        showSnackbar('Scanner stopped successfully')
      } else if (stopAlertScreenError) {
        showSnackbar(stopAlertScreenErrorMsg || 'Error stopping scanner', {}, 0)
      }
    }
    if (isDeleteingScreen !== prevProps.isDeleteingScreen && !isDeleteingScreen) {
      if (deleteScreenResp && deleteScreenResp.status === 'success') {
        showSnackbar('Scanner deleted successfully', {}, 1)
        refreshAlgos('scans', true)
        history.goBack()
      } else if (deleteScreenError) {
        showSnackbar(deleteScreenErrorMsg || 'Error deleting scanner', {}, 0)
      }
    }

    if (!isEqual(scanOnBasket, prevState.scanOnBasket) && this.isTokenSubscribed) {
      pairsFromSegSym(scanOnBasket, NAVIGATIONS.SCANNER.name)
    }

    if ((!isEqual(screener_logic, prevState.screener_logic) && screener_logic)
      || !isEqual(time_frame, prevState.time_frame)) {
      this.checkForInvalidCandleInterval(screener_logic)
    }

    // previous logic for product tour
    if (!startTour) return
    if (prevProps.activeTourIndex !== activeTourIndex
      && activeTourIndex < prevProps.activeTourIndex) {
      if (tourType === PRODUCT_TOUR_SECTIONS.SCANNERS_CREATE) {
        // previous button logic for Scanner create
        if (activeTourIndex === 1) updateTourIndex(0)
        if (activeTourIndex === 3) {
          this.activeIndex = 0
          const { value } = STEP_CONDITION_TYPE[this.activeIndex]
          this.updateProgress(this.activeIndex - 1, value, true)
          this.updateGA(`${this.activeIndex}-next-1`, `Cscan page ${this.activeIndex + 1}`)
        }
        if (activeTourIndex === 4) {
          this.setState({ assist: false }, () => {
            const { assist } = this.state
            const gaLabel = assist ? 'On' : 'Off'
            this.updateGA('Toggle Assist', gaLabel)
          })
        }
        if (activeTourIndex === 5) {
          this.activeIndex = 1
          const { value } = STEP_CONDITION_TYPE[this.activeIndex]
          this.updateProgress(this.activeIndex - 1, value, true)
          this.updateGA(`${this.activeIndex}-next-1`, `Cscan page ${this.activeIndex + 1}`)
        }
      }
    }
    if (prevProps.activeTourIndex !== activeTourIndex
      && activeTourIndex > prevProps.activeTourIndex) {
      if (tourType === PRODUCT_TOUR_SECTIONS.SCANNERS_CREATE) {
        switch (activeTourIndex) {
          case 4: {
            this.activeIndex = 1
            const { value } = STEP_CONDITION_TYPE[this.activeIndex]
            this.updateProgress(this.activeIndex - 1, value, true)
            this.updateGA(`${this.activeIndex}-next-1`, `Cscan page ${this.activeIndex + 1}`)
            break
          }
          case 5: {
            this.setState({ assist: true }, () => {
              const { assist } = this.state
              const gaLabel = assist ? 'On' : 'Off'
              this.updateGA('Toggle Assist', gaLabel)
            })
            break
          }
          case 6: {
            this.activeIndex = 2
            const { value } = STEP_CONDITION_TYPE[this.activeIndex]
            this.updateProgress(this.activeIndex - 1, value, true)
            this.updateGA(`${this.activeIndex}-next-1`, `Cscan page ${this.activeIndex + 1}`)
            break
          }
          case 8: {
            this.activeIndex = STEP_CONDITION_TYPE.length - 1
            this.runSave = true
            this.onSubmit()
            break
          }
          default: break
        }
      }
    }
  }

  componentWillUnmount = () => {
    this.props.clearScreenResp()
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval)
    }
    if (this.isTokenSubscribed) {
      this.props.unSubscribe(NAVIGATIONS.SCANNER.name)
    }
  }

  checkForInvalidCandleInterval = (indicatorsToCheck) => {
    const { time_frame: candleIntervalSelected } = this.state
    const selectedIndex = candle_freq_map[candleIntervalSelected]
      || rev_candle[candleIntervalSelected]

    let oldInvalidCandleInterval = false
    let oldInvalidCandleIntervalMultiple = false

    let finalIndcators = indicatorsToCheck
    if (indicatorsToCheck.includes('Period min(')) {
      finalIndcators = indicatorsToCheck.replaceAll('Period min(', 'Periodmin(')
    }

    const strArr = finalIndcators.split(new RegExp('[-+()*/:?, ]', 'g'))
    const conditionTimeFrames = strArr
      .filter(e => tf_map.indexOf(e) !== -1)
      .map(tf => rev_candle[tf] || rev_mtf_candle[tf]).sort((a, b) => b - a)
    const smallestConditionTimeFrame = conditionTimeFrames[0]
    if (conditionTimeFrames.length) {
      if (smallestConditionTimeFrame > selectedIndex) {
        showSnackbar('Candle interval can not be higher than the smallest time frame in condition', { hideDelay: 5000 }, 0)
        oldInvalidCandleInterval = true
      }
      const multipleReturnVal = conditionTimeFrames.map((tf) => {
        if (candle_min_map[tf]
          % candle_min_map[selectedIndex] !== 0) {
          return 0
        }
        return 1
      }).sort((a, b) => a - b)[0]
      if (!multipleReturnVal && !oldInvalidCandleInterval) {
        showSnackbar('The time frame in condition is not a multiple of candle interval selected', multipleCiProps, 0)
        oldInvalidCandleIntervalMultiple = true
      }
    }
    this.setState({
      invalidCandleIntervalMultipleScreener: oldInvalidCandleIntervalMultiple,
      invalidCandleIntervalScreener: oldInvalidCandleInterval,
    })
  }

  setCreateConditionState = (createAdvance) => {
    const {
      tree, screenerTree, screenerTrainer, getScreenerTrainer, treeFetched,
    } = this.props
    let indicatorListUpdated = false
    let expressions = [{ ...indicatorPattern, id: Date.now() }]

    if (!tree.indicators) {
      if (!treeFetched) {
        screenerTree()
      }
      if (createAdvance) {
        // update tree scanner
        this.indicatorsList = fTree
        expressions = [{ ...screenerIndicatorPattern, id: Date.now() }]
      } else {
        // update indicator (create basic)
        this.indicatorsList = fTree.indicators
        this.comparatorsList = fTree.comparators
      }
    } else if (createAdvance) {
      indicatorListUpdated = true
      // update tree scanner
      expressions = [{ ...screenerIndicatorPattern, id: Date.now() }]
      this.indicatorsList = tree
    } else {
      // update indicator (create basic)
      indicatorListUpdated = true
      this.indicatorsList = tree.indicators
      this.comparatorsList = tree.comparators
    }
    if (!screenerTrainer.supertrend) {
      getScreenerTrainer({}, this.headers)
    }
    return {
      indicatorListUpdated, expressions,
    }
  }

  getAllowedScan = () => {
    const { market_watches = {}, fetchWatchList } = this.props
    const baskets = Object.values(market_watches)
    const allowed_scans = Object.keys(sector_map)
    if (baskets.length) {
      allowed_scans.unshift({
        label: 'Baskets',
        value: 'baskets',
        options: baskets.map((basket, index) => {
          const obj = basket
          let seg_sym_list = []
          let name = ''
          if (Array.isArray(obj)) {
            seg_sym_list = obj.slice(0, BASKET_ADD_LIMIT)
            name = Object.keys(market_watches)[index]
          } else {
            const { instrumentList = [], listName = '' } = obj
            name = listName
            seg_sym_list = instrumentList.slice(0, BASKET_ADD_LIMIT)
          }
          return {
            name, seg_sym_list, length: seg_sym_list.length, icon: 'basket',
          }
        }),
        expandable: true,
        labelKey: 'name',
        valueKey: 'name',
      })
    } else {
      fetchWatchList({ complete: true })
    }
    return allowed_scans
  }

  scanSaveHandler = () => {
    const {
      user_details,
      csrf,
      sessionid,
      saveScreener,
      scanStocks,
      blockedUniverseList,
      toggleAuthGreeting,
    } = this.props
    const {
      name,
      scanner_desc,
      scan_on,
      time_frame,
      screener_uuid: id,
      expressions,
      chart_type,
      sample,
      passedId,
      scanOnBasket,
      andOrs,
      isScreenerLive,
      conditionsValid,
      createAdvance,
      assist,
      invalidCandleIntervalScreener,
      invalidCandleIntervalMultipleScreener,
      screener_logic: scan_logic,
    } = this.state
    const { user_uuid = '', subscription_type, subscription_valid } = user_details
    let isConditionValid = conditionsValid
    const modExpressions = expressions
    const modAndOrs = andOrs

    if (!subscription_valid) {
      // showSnackbar('Limit Reached.')
      toggleAuthGreeting(PRO_FEATURE_MAP.DP_LIMIT.ID)
      return
    }

    if (this.runSave) {
      // if (expressions[0].conditions.length === 0) {
      //   this.runSave = false
      //   showSnackbar('complete the previous condition')
      //   return
      // }
      if (name.trim().length === 0) {
        this.runSave = false
        this.toggleNameChangeModal()
        showSnackbar('Name cannot be empty')
        return
      }
      if (userAccessCheck({
        condition: accessConditions.ULTIMATE_OPTION_CHECK,
        blockedUniverseList,
        params: { scan_on },
        // showSnackbar
      })) return
      if (createAdvance) {
        isConditionValid = this.validityChecker(true)
        if (!isConditionValid) {
          this.setState({ conditionsValid: isConditionValid })
          this.runSave = false
          return
        }
      }

      // logic for blocking PivotPoints/CPR/Camarilla inside mtf/mtfc when timeframe > day
      const result = checkBlockedMTFIndc(scan_logic)
      const { isMatched = false, matchedIndc = null, matchedPeriod = null } = result[0] || {}
      if (isMatched) {
        showSnackbar(`Cannot use ${matchedIndc} with ${matchedPeriod}, please select timeframe lower than equal to 'day'`)
        return
      }

      // if user adds a condition which is empty
      const lastEle = modExpressions.slice(-1)[0]
      if (modExpressions.length > 1
        && lastEle && lastEle.conditions && lastEle.conditions.length === 0) {
        modExpressions.pop()
        modAndOrs.pop()
      }

      if (invalidCandleIntervalScreener) {
        showSnackbar("Please keep the timeframes in conditions 'higher than equal to' to the candle interval selected", { hideDelay: 5000 })
        this.runSave = false
        return
      }
      if (invalidCandleIntervalMultipleScreener) {
        showSnackbar('The time frame in condition is not a multiple of candle interval selected', multipleCiProps)
        this.runSave = false
        return
      }
    }

    const screener_logic = createAdvance ? screenerLogicStringGenerator(modExpressions, modAndOrs)
      : logicToStringGenerator(expressions, andOrs)
    const conditionLen = { conditions: modExpressions.length }
    if (subscription_type < 3 && screener_logic.toLowerCase().includes('multitime frame')) {
      toggleAuthGreeting(PRO_FEATURE_MAP.MTF.ID)
      return
    }
    let params = {
      condition: screener_logic,
      scan_on: sector_map[scan_on],
      time_frame: freq_candle_map[candle_freq_map[time_frame]],
      chart_type,
      user_uuid,
      csrf,
      session_id: sessionid,
    }
    let extraParams = {}
    if (scanOnBasket) {
      extraParams = {
        basket_name: scan_on,
        basket_symbols: scanOnBasket.map((item) => {
          if (typeof item === 'object') {
            return `${item.segment}_${item.symbol}`
          }
          return item.split('_').reverse().join('_')
        }),
      }
      params = {
        ...params,
        scan_on: 'basket',
        ...extraParams,
      }
      extraParams = {
        ...extraParams,
        universe: 'basket',
      }
    }
    let screener_uuid = (passedId && !sample) ? passedId : '' // gotstate
    if (id && !sample) {
      screener_uuid = id
    }
    const last_scan = this.runSave ? dayjs().format('hh:mm A DD/MM/YY') : ''
    const save_state = {
      ...this.state,
      conditionsValid: isConditionValid,
      last_scan,
      expressions: modExpressions,
      andOrs: modAndOrs,
    }
    delete (save_state.gotState)
    delete (save_state.screener_uuid)
    delete (save_state.setAlerts)
    delete (save_state.allowed_scans)
    delete save_state.showError
    delete save_state.indicatorListUpdated
    delete save_state.stepsCompleted
    delete save_state.showReadMore
    delete save_state.copiedCondition
    delete save_state.userCanceledWarning
    delete save_state.invalidCandleIntervalScreener
    delete save_state.invalidCandleIntervalMultipleScreener
    delete save_state.isMultiPage

    const saveParams = {
      screener_uuid,
      screener_state: save_state,
      screener_name: name.trim(),
      screener_desc: scanner_desc,
      screener_logic,
      time_frame,
      chart_type,
      universe: scan_on,
      screener_result: [],
      tags: [],
      last_scan,
      ...extraParams,
    }
    if (this.runSave) {
      scanStocks({
        saveParams: { data: saveParams, isScreenerLive, csrfmiddlewaretoken: csrf }, params,
      }, this.headers)
      this.setState({ selectedStocks: {}, allSelected: false, screener_logic })
      if (this.runSave) {
        gaParamsMap = [
          ['Adding condition', `Add entry condition${this.routeParams.gaType === 'Edit' ? ' (Edit)' : ''}`, conditionLen.conditions],
          ['Scan now', `Scan now${this.routeParams.gaType === 'Edit' ? ' (Edit)' : ''}`],
          ['Mode + Assist', `${createAdvance ? 'Advanced' : 'Basic'} + ${assist ? 'On' : 'Off'}`],
        ]
      }
      return
    }
    saveScreener({ data: JSON.stringify(saveParams), csrfmiddlewaretoken: csrf })
  }

  validity = (indicatorSec, show) => {
    const {
      conditions,
    } = indicatorSec
    let valid = true
    if (conditions.length === 0) {
      if (show) {
        showSnackbar('Conditions cannot be empty')
      }
      return false
    }
    let stack = 0
    for (let i = 0;i < conditions.length;i++) {
      if (conditions.length - 1 === i && conditions.length !== 1 && conditions[i].type === 'func' && !conditions[i].mathFunc) {
        return true
      }
      const condition = conditions[i]
      if (condition.type === 'mathFunc' || condition.subType === 'math functions') {
        if (condition.conditions.length === 0) {
          valid = false
          if (show) {
            showSnackbar('Condition seems to be incomplete')
          }
          break
        } else if (condition.conditions.length > 0) {
          const multiConditionValid = this.validity(condition, show)
          if (!multiConditionValid) {
            valid = false
            if (show) {
              showSnackbar('Math function condition seems to be incomplete')
            }
            break
          }
        }
        if (condition.multiConditions && condition.multiConditions[0] === 'array') {
          if (condition.multiConditions[1] && condition.multiConditions[1].length === 0) {
            valid = false
            if (show) {
              showSnackbar('Math function condition seems to be incomplete')
            }
            break
          } else {
            const multiConditionValid = this.validity({
              conditions: condition.multiConditions[1],
            }, show)
            if (!multiConditionValid) {
              valid = false
              if (show) {
                showSnackbar('Math function condition seems to be incomplete')
              }
              break
            }
          }
        }
        if (!condition.period) {
          if (show) {
            showSnackbar('Period missing in one of the math function')
          }
          return false
        }
        if (condition.name === MATH_FUNCS.SYMBOL.name && !condition.symbol) {
          if (show) {
            showSnackbar('Please select a valid symbol in symbol function')
          }
          return false
        }
      } else if (!condition.valid) {
        if (show) {
          showSnackbar('Condition seems to be incomplete')
        }
        valid = false
        break
      } else if (condition.name && condition.name.search('undefined') > -1) {
        if (show) {
          showSnackbar('Condition seems to be incomplete')
        }
        valid = false
        break
      } else if (condition.type === 'bracket') {
        if (condition.name === '(') {
          stack++
        } else {
          stack--
        }
      } else if (i === conditions.length - 1 && condition.type === 'filler') {
        if (show) {
          showSnackbar('Condition cannot end with math operator, and, or')
        }
        valid = false
        break
      }
    }
    if (stack !== 0) {
      if (show) {
        showSnackbar('Opening and closing brackets are not matching')
      }
      return false
    }
    return valid
  }

  validator = (validType, extraState = {}) => {
    const {
      expressions, createAdvance, conditionsValid,
    } = this.state
    const isValidFunc = {
      expressions: () => {
        if (createAdvance) {
          for (let i = 0;i < expressions.length;i++) {
            if (!this.validity(expressions[i], extraState.showError)) {
              this.setState(extraState)
              return false
            }
          }
          return true
        }
        return conditionsValid
      },
      scan_on: () => true,
    }
    return isValidFunc[validType]()
  }

  validityChecker = (showError) => {
    const {
      name,
      isMultiPage,
    } = this.state
    let isValid = true
    let extraState = {}
    if (showError) {
      extraState = { showError: true }
    }

    if (!name) {
      showSnackbar('Enter scanner name to continue')
      this.toggleNameChangeModal()
      return false
    }
    const currentStep = isMultiPage ? this.activeIndex : 2
    const { validTypes } = STEP_CONDITION_TYPE[currentStep]
    for (let i = 0;i < validTypes.length;i++) {
      isValid = isValid && this.validator(validTypes[i], extraState)
    }
    return isValid
  }

  handleChange = (value, optionObj, field) => {
    if (field === 'scan_on') {
      const scanOnBasket = (optionObj && optionObj.seg_sym_list) || ''
      this.setState({ scanOnBasket, [field]: value, scanOnModal: false })
      return
    }
    if (field === 'time_frame') {
      this.setState({ time_frame: candle_map[value] })
      return
    }
    if (field === 'chart_type') {
      this.updateGA('Chart type', value)
    }
    this.setState({ [field]: value })
  }

  toggleAssist = () => {
    this.setState((prevState) => {
      return { assist: !prevState.assist }
    }, () => {
      const { assist } = this.state
      const gaLabel = assist ? 'On' : 'Off'
      this.updateGA('Toggle Assist', gaLabel)
      const { updateTourIndex, activeTourIndex } = this.props
      updateTourIndex(activeTourIndex + 1)
    })
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  setAlertScreens = (addParams) => {
    const {
      setAlertScreens,
      user_details,
    } = this.props
    if (this.screenerEdited) {
      showSnackbar('Saving scanner')
      this.setAlertParams = addParams
      this.scanSaveHandler()
      return
    }
    const { user_uuid } = user_details
    const {
      chart_type,
      screener_logic,
      screener_uuid,
      scan_on,
      time_frame,
      name,
      scanOnBasket,
    } = this.state
    let params = {
      ...addParams,
      user_uuid,
      screener_uuid,
      screener_logic,
      chart_type: chart_type.toLowerCase(),
      time_frame: candleIntervals[time_frame],
      universe: sector_map[scan_on],
      screener_name: name,
    }
    if (scanOnBasket) {
      params = {
        ...params,
        universe: 'basket',
        basket_name: scan_on,
        basket_symbols: scanOnBasket.map((item) => {
          if (typeof item === 'object') {
            return `${item.segment}_${item.symbol}`
          }
          return item.split('_').reverse().join('_')
        }),
      }
    }
    if (!params.user_uuid && !params.screener_uuid) {
      showSnackbar('Please try again later')
    }
    const headers = new Headers()
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    setAlertScreens(params, headers)
  }

  stopAlert = () => {
    const { screenerState = {}, stopAlertScreens, csrf } = this.props
    if (screenerState.alert_uuid) {
      stopAlertScreens({ alert_uuids: [screenerState.alert_uuid], csrfmiddlewaretoken: csrf }, { 'content-type': 'application/json' })
    }
  }

  toggleNameChangeModal = () => {
    this.setState((prevState) => {
      if (prevState.showNameChange && this.createNew) {
        this.backPressHandler()
      }
      return { showNameChange: !prevState.showNameChange }
    })
  }

  backPressHandler = () => {
    const { history, location } = this.props
    const params = new URLSearchParams(history.location.search)
    const isaValue = params.get('isa')
    const decodedValue = atob(isaValue)
    if (decodedValue !== 'false') {
      history.push(pathname_mapping.Scanners)
    } else if (location.state && location.state.parentUrl) {
      history.push(location.state.parentUrl)
    } else {
      history.goBack()
    }
    return true
    // this.props.navigation.pop()
    // DANGER
    //   goBack({ currentNavigation: navigation, bottomTab: BOTTOM_TABS.TAB_SCANNER.id })
  }

  getStepIndex = (conditionEditType) => {
    this.activeIndex = 0
    const len = STEP_CONDITION_TYPE.length
    for (let i = 0;i < len;i++) {
      const { value } = STEP_CONDITION_TYPE[i]
      if (value === conditionEditType) {
        this.activeIndex = i
        break
      }
    }
    return this.activeIndex
  }

  startAutoSave = () => { // interval
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval)
    }
    // this.autoSaveInterval = setInterval(() => this.scanSaveHandler(), interval)
  }

  onConditionEdit = () => {
    const { isSavingScreen } = this.props
    if (isSavingScreen) {
      return
    }
    const conditionEditType = CONDITION_TYPE.SCREENER
    this.activeIndex = this.getStepIndex(conditionEditType)
    this.setState({ conditionEditType })
  }

  getAllowedConditions = () => {
    const { user_details = {} } = this.props
    const { subscription_type } = user_details
    switch (subscription_type) {
      case 1:
        return 5
      case 2:
        return 7
      case 2.5:
        return 7
      case 3:
        return 10
      default:
        return 5
    }
  }

  conditionsValid = (expressions, type, andOr, isConditionValid, extraChanges = {}) => {
    this.setState((prevState) => {
      let changes = {}
      const conditionsValid = isConditionValid !== undefined
        ? isConditionValid : prevState.conditionsValid
      let stateChanges = {}
      if (conditionsValid) {
        if (prevState.createAdvance) {
          changes = {
            screener_logic: screenerLogicStringGenerator(expressions, prevState.andOrs),
          }
        } else {
          changes = {
            screener_logic: logicToStringGenerator(expressions, prevState.andOrs),
          }
        }
      } else if (prevState.createAdvance && !extraChanges.showWarning) {
        const showWarning = this.shouldShowWarning()
        stateChanges = { showWarning }
      }
      return {
        ...changes,
        [type]: expressions,
        conditionsValid,
        andOrs: andOr,
        ...extraChanges,
        ...stateChanges,
      }
    })
  }

  dropdownItemRenderer = (optionObj, label, selected, expanded) => {
    const { expandable } = optionObj
    const { styles, isDark } = this.props
    let selectedStyles = { color: COLORS.BLUE }
    let expandIconStyles = { color: COLORS.TEXT }
    if (isDark) {
      selectedStyles = {
        color: COLORS.BLUE_300,
        backgroundColor: COLORS.WHITE,
        borderRadius: 30,
      }
      expandIconStyles = {
        color: COLORS.BLACK_60,
      }
    }
    return (
      <div className={styles.optionLabel}>
        <CustomText className={styles.optionLabelText}>
          {label}
        </CustomText>
        <div>
          {selected && (
            <Icon name={ICONS.TICK_ROUND_FILLED} className={selectedStyles} size={18} />
          )}
          {expandable && (
            <Icon name={ICONS[expanded ? 'UP_HEAD_FILLED' : 'DOWN_HEAD_FILLED']} className={expandIconStyles} size={12} />
          )}
        </div>
      </div>
    )
  }

  toggleScripList = () => {
    this.setState((prevState) => {
      const { showScripModal, scanOnBasket } = prevState
      if (!showScripModal && !this.isTokenSubscribed && scanOnBasket && scanOnBasket.length) {
        this.props.pairsFromSegSym(scanOnBasket, NAVIGATIONS.SCANNER.name)
      }
      return { showScripModal: !showScripModal }
    })
  }

  toggleScanOnModal = () => {
    const { startTour, updateGA } = this.props
    if (startTour) updateGA('Highlight Click', 'Ct Scan Pg 3', 1, 'Product Tour')
    this.setState(prevState => ({ scanOnModal: !prevState.scanOnModal }))
  }

  renderScanOnList = () => {
    const { scanOnBasket, scan_on } = this.state
    const { styles } = this.props
    return (
      <>
        <div className={styles.scanOnList}>
          <button
            type="button"
            onClick={this.toggleScanOnModal}
            className={styles.scanOnInputBtn}
          >
            <CustomText className={styles.scanOn}>{scan_on}</CustomText>
            <Icon name={ICONS.SEARCH} color={COLORS.GREY} size={15} />
          </button>
          {Boolean(scanOnBasket) && (
            <div className={styles.basketWarning}>
              <Icon
                name={ICONS.WARN}
                color={COLORS.ORANGE}
                size={12}
                style={{ marginRight: SPACING.SPACE_8 }}
              />
              <CustomText size="tiny" color="orange" center>
                {'  IMPORTANT: NSE scrips outside Nifty 500 will not be scanned'}
              </CustomText>
            </div>
          )}
        </div>
        {Boolean(scanOnBasket) && (
          <AnimatedCarousal
            singleView={false}
            showNav
          >
            {scanOnBasket.map((stocks) => {
              let seg_sym = stocks
              if (typeof seg_sym === 'object') {
                const { segment, symbol } = stocks
                seg_sym = `${segment}_${symbol}`
              } else {
                seg_sym = seg_sym.split('_').reverse().join('_')
              }
              return (
                <SymbolCard
                  key={seg_sym}
                  isSelected={false}
                  seg_sym={seg_sym}
                  cardHeight={CARD_HEIGHT}
                  cardWidth={CARD_WIDTH}
                  containerStyles={styles.cardContainer}
                />
              )
            })}
          </AnimatedCarousal>
        )}
      </>
    )
  }

  onChangeOption = (value, item, index, type) => {
    const { preferences, updatePreferences } = this.props
    const { createAdvance } = this.state
    switch (type) {
      case 'createMode': {
        if (value !== createAdvance) {
          this.toggleAlert()
        }
        break
      }

      case 'isMultiPage': {
        const activeConditionType = this.getConditionType(this.activeIndex)
        this.setState({
          isMultiPage: value,
          conditionEditType: !value ? CONDITION_TYPE.PRO : activeConditionType,
        }, () => {
          const gaAction = value ? 'Switched to multipage' : 'Switched to singlepage'
          this.updateGA(gaAction, 'AB_TESTING_CREATEPAGE')
          this.validityChecker(true)
          updatePreferences(!value, PREFERENCES.SINGLE_PAGE_MODE)
          setLocalStorage({
            [PREFERENCES.KEY]: {
              ...preferences,
              [PREFERENCES.SINGLE_PAGE_MODE]: !value,
            },
          }, true)
        })
        break
      }

      default:
        break
    }
  }

  toggleAlert = () => {
    const { styles } = this.props
    const { createAdvance } = this.state
    this.alertProps = {
      title: `Switch to ${createAdvance ? 'Basic' : 'Advance'}`,
      content: `You will lose all your progress once you switch to ${createAdvance ? 'Basic' : 'Advance'}`,
      containerStyles: styles.alertContainer,
      contentStyles: styles.alertContent,
      titleStyles: styles.alertTitle,
      imgStyles: styles.alertImg,
      actionBarStyles: styles.alertActionBar,
      imgSrc: CREATE_SWITCH,
      actions: [
        {
          text: 'Switch Now',
          containerStyle: styles.alertActiveBtn,
          action: () => this.toggleCreateAdvance(),
        },
      ],
    }
    this.setState({ showAlert: true })
  }

  handleConditionAddition = (expressions) => {
    const { createAdvance, andOrs } = this.state
    if (expressions.length === this.getAllowedConditions()) {
      showSnackbar('You have reached maximum conditions limit')
    } else {
      const modifiedData = [...expressions]
      const modAndOr = [...andOrs, 'and']
      const defaultPattern = createAdvance
        ? { ...screenerIndicatorPattern } : { ...indicatorPattern }
      defaultPattern.id = Date.now()
      modifiedData.push(defaultPattern)
      this.setState({ expressions: modifiedData, andOrs: modAndOr })
    }
  }

  handleAndOrChange = (editType, index, value) => {
    const field = 'andOrs'
    const andors = this.state[field]
    andors[index] = value
    const newAndOrs = [...andors]
    this.setState({ [field]: newAndOrs }, () => this.onSubmit(false))
  }

  chartLabelRenderer = ({ item, active }) => {
    const { styles, isDark } = this.props
    const { chart_type } = this.state
    const text = /Renko/i.test(chart_type) && /Renko/i.test(item) ? chart_type : item
    return (
      <CustomTooltip
        show
        tooltipProps={{ text }}
      >
        <div className={styles.chartTab}>
          <Icon
            name={CHART_ICON_MAP[item]}
            color={active ? COLORS.WHITE : (isDark ? COLORS.LIGHT_VOILET : COLORS.VOILET)}
          />
        </div>
      </CustomTooltip>
    )
  }

  updateCopiedCondition = (params) => {
    this.setState({ ...params })
  }

  onUpdateGA = () => {
    const { updateGA, activeTourIndex } = this.props
    const gaLabel = activeTourIndex === 3 ? 'Ct Strat Pg 4' : 'Ct Scan Pg 7'
    updateGA('Highlight Click', gaLabel, 1, 'Product Tour')
  }

  renderContent = (disableActions, conditionEditType) => {
    const {
      screenerTrainer, styles, isDark, toggleAuthGreeting, user_details, startTour,
      changePtComponentStatus, isMobile, activeTourIndex, updateGA,
    } = this.props
    const {
      subscription_type,
    } = user_details
    const {
      // showError,
      // conditionEditType,
      // conditionsValid,
      // screener_logic,
      expressions,
      time_frame,
      // editMode,
      assist,
      // assistMode,
      // allowed_scans,
      andOrs,
      // scan_on,
      chart_type,
      createAdvance,
      showCreateAdvance,
      isConditionValid,
      screener_logic,
      showError,
      copiedCondition,
      scanner_desc,
    } = this.state
    if (conditionEditType === CONDITION_TYPE.SCREENER) {
      if (!createAdvance) {
        return (
          <ConditionRenderer
            editType={CONDITION_TYPE.SCREENER}
            expressions={expressions}
            andOrs={andOrs}
            candleInterval={time_frame}
            trainerModel={screenerTrainer}
            assist={assist}
            showAssist
            title="Conditions"
            CONDITION_TYPE={CONDITION_TYPE}
            comparatorsList={this.comparatorsList}
            indicatorsList={this.indicatorsList}
            createAdvance={createAdvance}
            showCreateAdvance={showCreateAdvance}
            copiedCondition={copiedCondition}
            conditionsValid={this.conditionsValid}
            handleAndOrChange={this.handleAndOrChange}
            toggleAssist={this.toggleAssist}
            handleConditionAddition={this.handleConditionAddition}
            toggleCreateAdvance={this.onChangeOption}
            updateCopiedCondition={this.updateCopiedCondition}
            subscription_type={subscription_type}
            changePtComponentStatus={changePtComponentStatus}
            activeTourIndex={activeTourIndex}
            updateGA={updateGA}
          />
        )
      }
      return (
        <ScreenerConditionRenderer
          editType={CONDITION_TYPE.SCREENER}
          CONDITION_TYPE={CONDITION_TYPE}
          candleInterval={time_frame}
          trainerModel={screenerTrainer}
          expressions={expressions}
          andOrs={andOrs}
          assist={assist}
          title="Conditions"
          indicatorsList={this.indicatorsList}
          // headerStyles={styles.conditionHeader}
          createAdvance={createAdvance}
          showCreateAdvance={showCreateAdvance}
          copiedCondition={copiedCondition}
          conditionsValid={this.conditionsValid}
          handleAndOrChange={this.handleAndOrChange}
          toggleAssist={this.toggleAssist}
          handleConditionAddition={this.handleConditionAddition}
          toggleCreateAdvance={this.onChangeOption}
          updateCopiedCondition={this.updateCopiedCondition}
          toggleAuthGreeting={toggleAuthGreeting}
          subscription_type={subscription_type}
          startTour={startTour}
          changePtComponentStatus={changePtComponentStatus}
          activeTourIndex={activeTourIndex}
          isMobile={isMobile}
        />
      )
    }
    const ptCreateId3 = 'tour_scan_create_3'
    const ptCreateId4 = activeTourIndex === 3 ? 'tour_scan_create_4' : activeTourIndex === 6 && isMobile ? 'tour_scan_create_7' : ''
    const ptCreateId7 = !isMobile ? 'tour_scan_create_7' : ''
    return (
      <div
        className={styles.scrollView}
        id={ptCreateId7}
        role="button"
        onClick={this.onUpdateGA}
        tabIndex={0}
        onKeyUp={this.onUpdateGA}
      >
        <div className={styles.scanOnContainer}>
          <div
            id={ptCreateId3}
            className={startTour ? styles.scanOnHighlight : ''}
          >
            <CustomText weight="semi_bold" size="medium">
              Scan on
            </CustomText>
            <div className={styles.scanOnContent}>
              {this.renderScanOnList()}
            </div>
          </div>
        </div>
        <div
          className={styles.chartContainer}
          id={ptCreateId4}
          role="button"
          onClick={this.onUpdateGA}
          tabIndex={0}
          onKeyUp={this.onUpdateGA}
        >
          <CustomText weight="semi_bold" size="medium">Chart</CustomText>
          <div className={classnames(styles.content, styles.chartContent)}>
            <div className={styles.field} style={{ flex: 0.3 }}>
              <CustomText weight="medium" className={styles.inputLabel}>Chart Type</CustomText>
              <CustomHorizontalTab
                tabs={this.chartList}
                defaultActiveTab={chart_type}
                tabChangeHandler={this.handleChange}
                style={classnames(styles.tabContainer, styles.chartTypeContainer)}
                tabStyles={classnames(styles.tab, styles.chartTab)}
                labelRenderer={this.chartLabelRenderer}
                dragBtnStyles={styles.tabActive}
                changeKey="chart_type"
                {...tabsProps}
              />
            </div>
            <div className={styles.field} style={{ flex: 1 }}>
              <CustomText weight="medium" className={styles.inputLabel}>Time frame</CustomText>
              <CustomHorizontalTab
                tabs={this.candleList}
                defaultActiveTab={candle_map[time_frame]}
                changeKey="time_frame"
                tabChangeHandler={this.handleChange}
                style={classnames(styles.tabContainer, styles.candleTabContainer)}
                tabStyles={classnames(styles.tab, styles.candleTab)}
                dragBtnStyles={styles.tabActive}
                {...tabsProps}
              />
            </div>
          </div>
        </div>
        {/* <ListDropdown
          position="bottom"
          options={allowed_scans}
          label="Scan on"
          onChangeOption={this.handleChange}
          changeKey="scan_on"
          selected={scan_on}
          containerStyles={styles.dropDownContainer}
          inputContainerStyles={styles.dropDownInput}
          labelRender={this.dropdownItemRenderer}
          modalStyles={styles.dropdownModal}
        /> */}
        {conditionEditType === CONDITION_TYPE.REVIEW && (
          <div
            className={styles.entryExitWrapper}
          >
            <EntryExitRenderer
              status={isConditionValid}
              condition={screener_logic}
              cardTitle="Conditions"
              onCardPress={this.onConditionEdit}
              titleStyles={styles.entryExitTitle}
              cardStyles={classnames(styles.entryExitCard)}
              showStatus={showError}
              key="conditions"
              isDark={isDark}
            />
          </div>
        )}
        {(conditionEditType === CONDITION_TYPE.REVIEW)
          && (
            <div className={styles.descField}>
              <CustomText weight="medium" className={styles.descInputLabel}>Scanner Description</CustomText>
              <CustomInput
                inputKey="screenerDesc"
                value={scanner_desc}
                placeholder="Add your scanner description here"
                onChangeText={this.descChangeHandler}
                inputStyles={classnames(styles.descFieldInput)}
                multiline
                inputProps={{
                  rows: 5,
                }}
              />
            </div>
          )}
      </div>
    )
  }

  nameChangeHandler = ({ listName: name }) => {
    if (this.createNew) {
      const { csrf, saveScreener } = this.props
      const data = { screener_name: name, screener_result: [], screener_state: { name } }
      saveScreener({ csrfmiddlewaretoken: csrf, data: JSON.stringify(data) })
      this.setState({ name })
      return
    }
    this.setState({ name, showNameChange: false })
  }

  descChangeHandler = (e) => {
    const { target: { value } } = e
    this.setState({ scanner_desc: value })
  }

  checkOnlyScannerFuncExist = (conditions) => {
    let hasScannerOnlyFunc = false
    for (let i = 0;i < conditions.length;i++) {
      const condition = conditions[i]
      const {
        name: conditionName, subType: conditionSubType,
        indicatorDetails: conditionIndicatorDetails = {},
      } = condition
      for (let index = 0;index < [MATH_FUNCS.MULTI_TIME_FRAME.name].length;index++) {
        const funcName = [MATH_FUNCS.MULTI_TIME_FRAME.name][index]
        if (funcName
          && (conditionName === funcName
            || (conditionSubType === 'indicators'
              && conditionIndicatorDetails.name === funcName)
          )) {
          hasScannerOnlyFunc = true
          break
        }
      }
      if (hasScannerOnlyFunc) break
      if (condition.conditions && condition.conditions.length) {
        hasScannerOnlyFunc = this.checkOnlyScannerFuncExist(condition.conditions)
        if (hasScannerOnlyFunc) break
      }
    }
    return hasScannerOnlyFunc
  }

  getConditionType = (index) => {
    const { value } = STEP_CONDITION_TYPE[index]
    return value
  }

  shouldShowWarning = () => {
    const { expressions } = this.state
    let showWarning = false
    const mtfExist = this.checkOnlyScannerFuncExist(expressions)

    if (mtfExist) {
      showWarning = 'MTF'
    }
    return showWarning
  }

  onSubmit = (shouldSave = true) => {
    const {
      expressions, andOrs, createAdvance, conditionsValid,
    } = this.state
    const { user_details, toggleRootModal } = this.props
    let screener_logic = ''
    let isConditionsValid = conditionsValid
    let showWarning = false
    // const lastEntry = expressions.slice(-1)[0]

    if (this.runSave && toggleRootModal
      && [BROKERS.ANGEL.name, BROKERS.FIVE_PAISA.name].includes(user_details.broker)) {
      if (userAccessCheck({ condition: accessConditions.AB_NO_EMAIL_USAGE_LIMIT, user_details })) {
        toggleRootModal(ROOT_MODALS.showEmailModal, true)
        return
      }
    }

    // if (expressions.length !== 1) {
    //   if (createAdvance && lastEntry.conditions && lastEntry.conditions.length === 0) {
    //     const modExpressions = [...expressions]
    //     const modAndOrs = [...andOrs]
    //     modExpressions.pop()
    //     modAndOrs.pop()
    //     this.setState({
    //       expressions: modExpressions,
    //       andOrs: modAndOrs,
    //     }, () => this.onSubmit())
    //     return
    //   }
    // }
    if (createAdvance) {
      screener_logic = screenerLogicStringGenerator(expressions, andOrs)
      isConditionsValid = this.validityChecker()
      showWarning = this.shouldShowWarning()
    } else {
      screener_logic = logicToStringGenerator(expressions, andOrs)
    }

    this.setState({
      screener_logic,
      conditionsValid: isConditionsValid,
      showWarning,
    }, () => {
      if (shouldSave) this.scanSaveHandler()
    })
  }

  renderScrips = () => {
    return null
  }

  deleteScreener = () => {
    const { csrf, deleteScreens } = this.props
    const { screener_uuid } = this.state
    if (!screener_uuid) {
      showSnackbar('Scanner not found', {}, 0)
      return
    }
    const params = {
      csrfmiddlewaretoken: csrf,
      screener_uuid,
    }
    deleteScreens(params)
  }

  //   actionHandler = (actionType) => {
  //     switch(actionType) {
  //       case menuOptions[0].value: {
  //         this.toggleNameChangeModal()
  //         break
  //       }

  //       case menuOptions[1].value: {
  //         this.deleteScreener()
  //         break
  //       }

  //       default:
  //         break
  //     }
  //   }

  headerActionBarRenderer = () => {
    const {
      isGettingScreenerState, isSavingScreen, styles, isMobile, isDark,
      isScanning,
    } = this.props
    const {
      conditionEditType, createAdvance, showCreateAdvance, isMultiPage,
    } = this.state
    const btnText = !isMultiPage ? 'Scan and Save' : isMultiPage && conditionEditType === CONDITION_TYPE.REVIEW ? 'Scan and Save' : 'Next'
    const isBtnDisabled = isScanning || isSavingScreen || isGettingScreenerState
    return (
      <div className={styles.headerActionBar}>
        <SectionTab
          tabs={this.createPageViewSection}
          activeTab={isMultiPage}
          tabChangeHandler={this.onChangeOption}
          style={styles.sectionTabContainer}
          showLabel={!isMobile}
          activeTabColor={isDark ? 'white' : 'blue'}
          tabKey="isMultiPage"
        />
        {Boolean(showCreateAdvance) && !isGettingScreenerState && !isSavingScreen && (
          <SectionTab
            tabs={this.sections}
            activeTab={createAdvance} // ? ALGO_MODE.ADVANCE.value : ALGO_MODE.BASIC.value}
            tabChangeHandler={this.onChangeOption}
            style={styles.sectionTabContainer}
            showLabel={!isMobile}
            activeTabColor={isDark ? 'white' : 'blue'}
            tabKey="createMode"
          />
        )}
        {!isMobile && (
          <Button
            text={btnText}
            onPress={this.onHeaderNext}
            disabled={isScanning || isSavingScreen || isGettingScreenerState}
            loading={isScanning || isSavingScreen || isGettingScreenerState}
            buttonColor="blue"
            btnStyles={`${styles.saveBtn} ${isBtnDisabled ? styles.saveBtnDisable : ''}`}
          />
        )}
      </div>
    )
  }

  toggleCreateAdvance = () => { // addScrips
    this.setState((prevState) => {
      let expressions = [{ ...indicatorPattern, id: Date.now() }]
      const { createAdvance: prevCreateAdvance } = prevState
      let indicatorListUpdated = false

      const {
        tree,
      } = this.props
      if (!prevCreateAdvance) {
        expressions = [{ ...screenerIndicatorPattern, id: Date.now() }]
      }
      if (!tree.indicators) {
        if (!prevCreateAdvance) {
          this.indicatorsList = fTree
        } else {
          this.indicatorsList = fTree.indicators
          this.comparatorsList = fTree.comparators
        }
      } else if (!prevCreateAdvance) {
        indicatorListUpdated = true
        this.indicatorsList = tree
      } else {
        indicatorListUpdated = true
        this.indicatorsList = tree.indicators
        this.comparatorsList = tree.comparators
      }
      this.activeIndex = 0
      return ({
        expressions,
        andOrs: ['and'],
        showAlert: !prevState.showAlert,
        createAdvance: !prevCreateAdvance,
        indicatorListUpdated,
        screener_logic: '',
        conditionsValid: false,
        stepsCompleted: new Set(),
        conditionEditType: CONDITION_TYPE.POSITION,
        copiedCondition: {},
        showWarning: false,
        // userCanceledWarning: false,
      })
    }, () => {
      this.updateGA('Toggle Mode', this.state.createAdvance ? 'Advanced' : 'Basic')
    })
  }

  scrollToTop = () => {
    const main = document.getElementById('main')
    main.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
  }

  updateCompletedSteps = () => {
    const stepsCompleted = new Set()
    STEP_CONDITION_TYPE.forEach((itemObj, index) => {
      const { validTypes } = itemObj
      let isValid = true
      for (let i = 0;i < validTypes.length;i++) {
        isValid = isValid && this.validator(validTypes[i])
      }

      if (isValid) stepsCompleted.add(index)
    })

    this.setState({ stepsCompleted })
  }

  updateGA = (a, l, v) => {
    this.props.updateGA(a, l, v, this.routeParams.gaType === 'Edit' ? 'Edit scanner' : '')
  }

  onStepPress = (item, index) => {
    const isPageParamsValid = this.validityChecker(true, false)
    // if (!isPageParamsValid && index >= this.activeIndex) {
    //   return
    // }
    const oldIndex = this.activeIndex
    this.activeIndex = index
    const { value, label } = item
    this.updateProgress(oldIndex, value, isPageParamsValid)
    this.updateGA(`${label} button`, `Cscan Page ${index + 1}`)
  }

  updateProgress = (oldIndex, value, isPageParamsValid) => {
    const { stepsCompleted } = this.state
    const modStepsCompleted = new Set(stepsCompleted)
    if (isPageParamsValid) {
      modStepsCompleted.add(oldIndex)
    } else if (modStepsCompleted.has(oldIndex)) {
      modStepsCompleted.delete(oldIndex)
    }
    this.setState({ conditionEditType: value, stepsCompleted: modStepsCompleted }, () => {
      this.onSubmit()
      this.scrollToTop()
    })
  }

  onHeaderNext = () => {
    const { isMultiPage } = this.state
    if (!isMultiPage) {
      this.onSaveNRun()
      return
    }
    this.updateStep('header')
  }

  updateStep = (pos) => {
    const {
      startTour, updateGA,
      updateTourIndex, changePtComponentStatus, activeTourIndex,
    } = this.props
    const len = STEP_CONDITION_TYPE.length - 1
    const isPageParamsValid = this.validityChecker(true, false)
    if (startTour) updateGA('Button Click', 'Ct Scan Pg 8', 1, 'Product Tour')
    if (!isPageParamsValid) {
      return
    }
    this.activeIndex += 1
    if (this.activeIndex > len) {
      this.activeIndex = len
      this.runSave = true
      this.onSubmit()
    } else {
      const { value } = STEP_CONDITION_TYPE[this.activeIndex]
      this.updateProgress(this.activeIndex - 1, value, isPageParamsValid)
      this.updateGA(`${this.activeIndex}-next-${pos === 'header' ? '1' : '2'}`, `Cscan page ${this.activeIndex + 1}`)
    }
    if (activeTourIndex < 8) {
      setTimeout(() => {
        updateTourIndex(activeTourIndex + 1)
        setTimeout(() => changePtComponentStatus(`next_${Math.random()}`), 500)
      }, 500)
    }
  }

  toggleReadMore = () => {
    this.setState(prevState => ({ readMore: !prevState.readMore }))
  }

  shouldShowReadMore = () => {
    if (this.descRef.current) {
      if (this.descRef.current.scrollWidth <= this.containerRef.current.offsetWidth) {
        this.setState({ showReadMore: false })
      } else {
        this.setState({ showReadMore: true })
      }
    }
  }

  renderAlgoDesc = (isDark, styles) => {
    const {
      screener_logic, readMore, showReadMore,
    } = this.state
    return screener_logic ? (
      <CustomText ref={this.descRef} weight="regular" color="textGrey" className={`${styles.desc} ${readMore ? '' : styles.shortDesc}`}>
        {screener_logic}
        {showReadMore && (
          <button
            type="button"
            onClick={this.toggleReadMore}
            style={{ display: 'block' }}
          >
            <CustomText
              color="linkColor"
              weight="semi_bold"
            >
              {readMore ? 'Read Less' : 'Read More'}
            </CustomText>
          </button>
        )}
      </CustomText>
    ) : null
  }

  onAlertDismiss = () => {
    this.setState({ showAlert: false })
  }

  onSaveNRun = () => {
    this.runSave = true
    this.scanSaveHandler(true)
  }

  render() {
    const {
      isScanning,
      isSavingScreen,
      isGettingScreenerState,
      isDark,
      styles,
      activeTourIndex,
      startTour,
      preferences,
    } = this.props
    const {
      name,
      scanner_desc,
      isScreenerLive,
      showScripModal,
      showNameChange,
      showAlert,
      conditionEditType,
      stepsCompleted,
      scanOnModal,
      allowed_scans,
      scan_on,
      createAdvance,
      showWarning,
      userCanceledWarning,
      isMultiPage,
      conditionsValid,
      showError,
    } = this.state
    const mtfPreference = preferences[PREFERENCES.MTF_WARNING] === undefined
      ? true : preferences[PREFERENCES.MTF_WARNING]
    const saveDisabled = isScanning || (isSavingScreen && this.runSave) || isGettingScreenerState
      || name.trim().length === 0

    let btnText = 'Scan and Save'
    if (isScreenerLive) {
      btnText = 'Scan'
    } else if (conditionEditType !== CONDITION_TYPE.REVIEW) {
      btnText = 'Next'
    }
    const warningText = LINK_MAP[showWarning] ? LINK_MAP[showWarning].text : false
    const autoSaving = isSavingScreen && !this.runSave
    const ScreenerPage = isMultiPage ? ScreenerMulti : ScreenerSingle
    return (
      <div className={styles.screen} ref={this.containerRef}>
        {mtfPreference && showWarning && showWarning !== true && !userCanceledWarning ? (
          <div className={styles.warning}>
            <CustomText size="small" flex={1}>
              <>
                {warningText}
                <span>&nbsp;</span>
                {LINK_MAP[showWarning].link && (
                  <a href={LINK_MAP[showWarning].link} rel="noreferrer" target="_blank">read more here.</a>
                )}
              </>
            </CustomText>
            <button type="button" onClick={() => this.setState({ showWarning: false, userCanceledWarning: true })}>
              <Icon name={ICONS.CLOSE} size={8} />
            </button>
          </div>
        ) : null}
        <CustomModal
          visible={showScripModal}
          dismissKey="showScripModal"
          onDismiss={this.toggleScripList}
        >
          {this.renderScrips()}
        </CustomModal>
        <CustomModal
          visible={scanOnModal}
          dismissKey="scanOnModal"
          onDismiss={this.toggleScanOnModal}
          position="right"
          fullScreen
        >
          <ScanOnFinder
            allowed_scans={allowed_scans}
            scan_on={scan_on}
            isDark={isDark}
            handleChange={this.handleChange}
            onDismiss={this.toggleScanOnModal}
          />
        </CustomModal>
        <CustomModal
          visible={showNameChange}
          onDismiss={this.toggleNameChangeModal}
        >
          <CreateNewList
            onDismiss={this.toggleNameChangeModal}
            dismissKey="showNameChange"
            title="Save Scanner As"
            inputPlaceholder="Scanner Name"
            btnText="Save"
            prevListName={name}
            onCreate={this.nameChangeHandler}
            visible={showNameChange}
          />
        </CustomModal>
        <AlertPopup
          visible={showAlert}
          onDismiss={this.onAlertDismiss}
          dismissKey="showAlert"
          modalStyles={styles.alertModal}
          {...this.alertProps}
        />
        <ScreenHeader
          title={autoSaving ? 'Saving...' : name}
          actionBarRenderer={this.headerActionBarRenderer}
          isSavingScreen={isSavingScreen || isScanning}
          isGettingScreenerState={isGettingScreenerState}
          createAdvance={createAdvance}
          showBackBtn
          containerStyles={styles.headerContainer}
          backPressHandler={this.backPressHandler}
        />
        {this.renderAlgoDesc(isDark, styles)}
        <ScreenerPage
          saveDisabled={saveDisabled}
          stepsCompleted={stepsCompleted}
          btnText={btnText}
          onStepPress={this.onStepPress}
          activeIndex={this.activeIndex}
          renderContent={this.renderContent}
          stopAlert={this.stopAlert}
          isScreenerLive={isScreenerLive}
          updateStep={this.updateStep}
          isScanning={isScanning}
          isSavingScreen={isSavingScreen}
          runSave={this.runSave}
          screenerName={name}
          screenerDesc={scanner_desc}
          handleScreenerNameChange={this.nameChangeHandler}
          descChangeHandler={this.descChangeHandler}
          isBtnDisabled={isScanning || isSavingScreen || isGettingScreenerState}
          onSaveNRun={this.onSaveNRun}
          entryValid={conditionsValid}
          showError={showError}
          scan_on={scan_on}
          conditionEditType={conditionEditType}
          activeTourIndex={activeTourIndex}
          startTour={startTour}
        />
      </div>
    )
  }
}

const stylesheet = ({
  stepsContainer: {
    margin: `${SPACING.SPACE_12} 0 ${SPACING.SPACE_34}`,
    padding: `${SPACING.SPACE_20} 0`,
    zIndex: 9998,
    top: 106,
    position: 'sticky',
    backgroundColor: theme.screenBg,
  },
  headerActionBar: {
    display: 'flex',
    alignItems: 'center',
  },
  saveBtn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_24}`,
  },
  saveBtnDisable: {
    backgroundColor: theme.disabled,
    borderColor: theme.disabled,
  },
  sectionTabContainer: {
    minWidth: 200,
    marginRight: SPACING.SPACE_20,
  },
  alertContainer: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  alertContent: {
    margin: ' auto',
    width: '100%',
    maxWidth: 200,
  },
  alertTitle: {
    margin: `${SPACING.SPACE_10} auto ${SPACING.SPACE_4}`,
    width: '90%',
  },
  alertActionBar: {
    width: '90%',
    margin: `${SPACING.SPACE_20} auto 0`,
  },
  alertImg: {
    maxHeight: 180,
  },
  alertModal: {
    maxWidth: 300,
  },
  alertActiveBtn: {
    margin: `${SPACING.SPACE_32} auto ${SPACING.SPACE_10}`,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20} ${SPACING.SPACE_24}`,
    background: theme.bgPrimary,
    borderRadius: '10px',
    marginTop: SPACING.SPACE_12,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,

  },
  chartContainer: {
    marginTop: SPACING.SPACE_32,
  },
  chartContent: {
  },
  field: {
    flex: 1,
    marginRight: SPACING.SPACE_20,
    '&:last-child': {
      marginRight: 0,
    },
  },
  fieldInputContainer: {
    maxWidth: 200,
  },
  tabContainer: {
    backgroundColor: theme.createTabsBg,
    padding: '0 ',
  },
  tabActive: {
    backgroundColor: COLORS.VOILET,
  },
  chartTypeContainer: {
    maxWidth: 150,
  },
  tab: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_12}`,
  },
  actionBar: {
    padding: `${SPACING.SPACE_24} 0`,
    textAlign: 'right',
  },
  chartTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
  },

  inputLabel: {
    marginBottom: SPACING.SPACE_12,
  },
  scanOnContent: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    marginTop: SPACING.SPACE_12,
  },
  scanOnList: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  scanOnInputBtn: {
    width: '100%',
    display: 'flex',
    maxWidth: '300px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_12}`,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    borderRadius: 8,
    backgroundColor: theme.bgPrimary,
  },
  scanOn: {},
  basketWarning: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: SPACING.SPACE_10,
  },
  cardContainer: {
    margin: `${SPACING.SPACE_20} ${SPACING.SPACE_10}`,
  },
  entryExitWrapper: {
    display: 'flex',
    marginTop: SPACING.SPACE_40,
  },
  entryExitCard: {
    width: '100%',
  },
  screen: {
    // marginTop: SPACING.SPACE_20,
  },
  desc: {
    marginTop: SPACING.SPACE_8,
    marginRight: SPACING.SPACE_8,
  },
  shortDesc: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
  scrollView: {},
  scanOnContainer: {},
  listView: {},
  chartTooltip: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    backgroundColor: '#000000c4',
    borderRadius: 6,
    overflow: 'hidden',
    // border: '1px solid #9BABC7',
  },
  candleTabContainer: {
    display: 'inline-flex',
  },
  warning: {
    position: 'relative',
    padding: '8px 20px',
    backgroundColor: theme.orangeLightBg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
  },
  descField: {
    flex: 1,
    marginTop: SPACING.SPACE_28,
  },
  descInputLabel: {
    marginBottom: SPACING.SPACE_12,
  },
  descFieldInput: {
    backgroundColor: theme.inputLightBg,
  },
  '@media only screen and (max-width: 786px)': {
    screen: {
      paddingBottom: 60,
      paddingTop: 44,
    },
    actionBar: {
      position: 'fixed',
      bottom: '55px',
      background: theme.bgTertiary,
      width: '100%',
      left: '0',
      zIndex: 99,
      padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
      '& > button': {
        width: '100%',
      },
    },
    sectionTabContainer: {
      marginRight: 0,
      minWidth: 'unset',
    },
    stepsContainer: {
      position: 'sticky',
      top: '28px',
      zIndex: 999,
      backgroundColor: theme.screenBg,
    },
    detailsModal: {
      padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    },
    content: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& $field': {
        margin: `0 0 ${SPACING.SPACE_20}`,
        flex: 'auto',
        '&:last-child': {
          margin: 0,
        },
      },
    },
    inputLabel: {
      marginBottom: SPACING.SPACE_8,
    },
    tabBtn: {
      padding: `${SPACING.SPACE_8} ${SPACING.SPACE_16}`,
    },
    fieldInput: {
      width: '100%',
    },
    tabContainer: {
      flexWrap: 'wrap',
    },
    field: {
      width: '100%',
    },
    fieldInputContainer: {
      maxWidth: 'unset',
    },
  },
  scanOnHighlight: {
    width: 300,
    padding: 20,
  },
})

export default withTheme(stylesheet)(Scanner)
