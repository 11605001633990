import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/styles'

import CustomText from './CustomText'
import Icon from './Icon'

import { ICONS, SPACING, theme } from '../Theme'
import { CURRENCY_SYM } from './ShowLTP'

const GetPNL = ({
  pnl,
  showNA = false,
  showColor,
  showLabel,
  labelText,
  labelStyle,
  pnlStyle,
  naStyle,
  showSign,
  showIcon,
}) => {
  const styles = stylesheet()
  if (!showNA) {
    let arrowIcon = 'DOWN_ARROW'
    let arrowColor = 'red'
    // let textColor = { color: COLORS.RED }
    if (pnl >= 0) {
      arrowIcon = 'UP_ARROW'
      arrowColor = 'green'
      // textColor = { color: COLORS.GREEN }
    }
    let pnlText = pnl
    // keeps the precision preserved while in stringified format.
    // Math.abs removes precision in case .00 is there
    let absPnl = pnl
    if(typeof pnl === 'string' && pnl.includes('-')) {
      absPnl = pnl.replace('-', '')
    }
    if(+pnl === 0) {
      pnlText = (
        <>
          {CURRENCY_SYM}
          {` ${absPnl}`}
        </>
      )
    } else if (showSign) {
      pnlText = pnl > 0 ? (
        <>
          {'+ '}
          {CURRENCY_SYM}
          {` ${pnl}`}
        </>
      ) : (
        <>
          {'- '}
          {CURRENCY_SYM}
          {` ${absPnl}`}
        </>
      )
    } else {
      pnlText = pnl > 0 ? (
        <>
          {CURRENCY_SYM}
          {` ${pnl}`}
        </>
      ) : (
        <>
          {CURRENCY_SYM}
          {` ${absPnl}`}
        </>
      )
    }

    let pnlColor = theme.text
    if(showColor) {
      if(!(+pnl === 0)) {
        pnlColor = +pnl > 0 ? theme.green : theme.red
      }
    }
    return (
      <div className={styles.pnl}>
        <CustomText size="small" className={classnames(styles.labelText, labelStyle)}>
          {showLabel ? labelText || 'P&L' : ''}
        </CustomText>
        {showIcon && pnl !== 0 && (
          <Icon name={ICONS[arrowIcon]} color={theme[arrowColor]} size={11} />
        )}
        <CustomText
          className={classnames(styles.pnlText, pnlStyle)}
          style={{ color: pnlColor, marginLeft: showIcon ? SPACING.SPACE_8 : 0 }}
        >
          {pnlText}
        </CustomText>
      </div>
    )
  }
  return (
    <div className={styles.pnl}>
      <CustomText size="small" className={classnames(styles.labelText, labelStyle)}>
        {showLabel ? labelText || 'P&L' : ''}
      </CustomText>
      <CustomText size="small" className={classnames(styles.naText, naStyle)}>N/A</CustomText>
    </div>
  )
}

const stylesheet = makeStyles(() => ({
  pnl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 2,
  },
  labelText: {
  },
  pnlText: {
    whiteSpace: 'nowrap',
  },
  naText: {
  },
}))

GetPNL.defaultProps = {
  pnl: 0.00.toFixed(2),
  showNA: false,
  showColor: true,
  showLabel: false,
  labelText: '',
  labelStyle: '',
  pnlStyle: '',
  showSign: false,
  showIcon: false,
}

GetPNL.propTypes = {
  pnl: PropTypes.string,
  showNA: PropTypes.bool,
  showColor: PropTypes.bool,
  showLabel: PropTypes.bool,
  labelText: PropTypes.string,
  labelStyle: PropTypes.string,
  pnlStyle: PropTypes.string,
  showSign: PropTypes.bool,
  showIcon: PropTypes.bool,
}

export default GetPNL
