import { Component } from 'react'
import isEqual from 'lodash/isEqual'
import dayjs from 'dayjs'

import {
  defaultChartList, candle_map, sector_map,
  BASKET_ADD_LIMIT,
  candleIntervalsShort, condensed_candle_map, tf_map,
  rev_mtf_candle, rev_candle, candle_min_map,
} from '../../utils/consts'
import { accessConditions, userAccessCheck } from '../../utils/userAccessChecks'
import { getCandleIntervalsMap } from '../../utils/common'
import { showSnackbar } from '../../UI/Snackbar'
import { checkBlockedMTFIndc } from '../../containers/Screener/Screener'

let gaParamsMap = []
const gaFieldLabelMap = {
  scan_on: 'Scan on',
  chart_type: 'Chart type',
  time_frame: 'Interval',
}

const multipleCiProps = {
  hideDelay: 4000,
  showReadMore: true,
  link: 'https://blog.streak.tech/timeframe-compatibility-and-setting-a-base-timeframe/',
}

class EditScreenerParamsHead extends Component {
  constructor(props) {
    super(props)
    const allowed_scans = this.getAllowedScan()
    this.chartList = defaultChartList.filter(cType => cType !== 'Renko')
    this.candleList = getCandleIntervalsMap(candleIntervalsShort, candleIntervalsShort)
    const { screenerState } = props
    const {
      chart_type, time_frame, screener_state, // universe: scan_on,
    } = screenerState
    const {
      scanOnBasket, scan_on,
    } = screener_state
    gaParamsMap = []
    this.state = {
      scanOnBasket,
      scanOnModal: false,
      allowed_scans,
      scan_on,
      chart_type: chart_type && chart_type.includes('candlestick') ? this.chartList[0] : this.chartList[1],
      time_frame: condensed_candle_map[time_frame],
    }
    this.showScanBtn = false
  }

  componentDidUpdate(prevProps) {
    const { market_watches } = this.props
    if (!isEqual(market_watches, prevProps.market_watches)) {
      const allowed_scans = this.getAllowedScan()
      this.setState({ allowed_scans })
    }
  }

  getAllowedScan = () => {
    const { market_watches = {}, fetchWatchList } = this.props
    const baskets = Object.values(market_watches)
    const allowed_scans = Object.keys(sector_map)
    if (baskets.length) {
      allowed_scans.unshift({
        label: 'Baskets',
        value: 'baskets',
        options: baskets.map((basket, index) => {
          const obj = basket
          let seg_sym_list = []
          let name = ''
          if (Array.isArray(obj)) {
            seg_sym_list = obj.slice(0, BASKET_ADD_LIMIT)
            name = Object.keys(market_watches)[index]
          } else {
            const { instrumentList = [], listName = '' } = obj
            name = listName
            seg_sym_list = instrumentList.slice(0, BASKET_ADD_LIMIT)
          }
          return {
            name,
            seg_sym_list,
            length: seg_sym_list.length,
            icon: 'basket',
            iconSize: 9,
          }
        }),
        expandable: true,
        labelKey: 'name',
        valueKey: 'name',
      })
    } else {
      fetchWatchList({ complete: true })
    }
    return allowed_scans
  }

  checkForInvalidCandleInterval = (selectedCandleInterval) => {
    const { screener_logic } = this.props
    const finalActionStr = screener_logic
    if (finalActionStr) {
      let finalIndcators = finalActionStr
      if (finalActionStr.includes('Period min(')) {
        finalIndcators = finalActionStr.replaceAll('Period min(', 'Periodmin(')
      }

      const strArr = finalIndcators.split(new RegExp('[-+()*/:?, ]', 'g'))
      const conditionTimeFrames = strArr
        .filter(e => tf_map.indexOf(e) !== -1)
        .map(tf => rev_candle[tf] || rev_mtf_candle[tf]).sort((a, b) => b - a)
      const smallestConditionTimeFrame = conditionTimeFrames[0]
      const selectedCandleIntervalIndex = rev_candle[selectedCandleInterval]
      if (selectedCandleIntervalIndex < smallestConditionTimeFrame) {
        return -1
      }
      const multipleReturnVal = conditionTimeFrames.map((tf) => {
        if (candle_min_map[tf]
          % candle_min_map[selectedCandleIntervalIndex] !== 0) {
          return -2
        }
        return 1
      }).sort((a, b) => a - b)[0]
      return multipleReturnVal
    }
    return 1
  }

  handleChange = (value, optionObj, field) => {
    this.showScanBtn = true
    if (field === 'scan_on') {
      const scanOnBasket = (optionObj && optionObj.seg_sym_list) || ''
      this.setState({ scanOnBasket, [field]: value, scanOnModal: false })
      gaParamsMap.push(['Scan on', 'Basket'])
      return
    }
    const isValidCandle = this.checkForInvalidCandleInterval(value)
    if (isValidCandle < 0 && field === 'time_frame') {
      if (isValidCandle === -1) {
        showSnackbar('Candle interval can not be higher than the smallest time frame in condition', { hideDelay: 5000 }, 0)
      } else if (isValidCandle === -2) {
        showSnackbar('The time frame in condition is not a multiple of candle interval selected', multipleCiProps, 0)
      }
      return
    }
    this.setState({ [field]: value })
    if (gaFieldLabelMap[field]) gaParamsMap.push([gaFieldLabelMap[field], value])
  }

  scanSaveHandler = () => {
    const {
      screener_state, user_uuid, screener_uuid, live, scanStocks, screener_name,
      csrf, sessionid, screener_logic, screenerState, blockedUniverseList, updateGA,
    } = this.props
    const { screener_logic: logic } = screener_state
    const {
      scan_on, time_frame, chart_type, scanOnBasket,
    } = this.state

    const result = checkBlockedMTFIndc(logic)
    const { isMatched = false, matchedIndc = null, matchedPeriod = null } = result[0] || {}
    if (isMatched) {
      showSnackbar(`Cannot use ${matchedIndc}
   with ${matchedPeriod}, please edit the scanner and select the timeframe lower than equal to 'day'.`)
      return
    }

    if (userAccessCheck({
      condition: accessConditions.ULTIMATE_OPTION_CHECK,
      blockedUniverseList,
      params: { scan_on },
      // showSnackbar
    })) return
    let params = {
      condition: logic || screener_logic,
      scan_on: sector_map[scan_on],
      time_frame,
      chart_type: chart_type.toLowerCase(),
      user_uuid,
      csrf,
      session_id: sessionid,
    }
    let extraParams = {}
    if (scanOnBasket) {
      extraParams = {
        basket_name: scan_on,
        basket_symbols: scanOnBasket.map((item) => {
          if (typeof item === 'object') {
            return `${item.segment}_${item.symbol}`
          }
          return item.split('_').reverse().join('_')
        }),
      }
      params = {
        ...params,
        scan_on: 'basket',
        ...extraParams,
      }
      extraParams = {
        ...extraParams,
        universe: 'basket',
      }
    }
    const save_state = {
      ...screener_state, scan_on, chart_type, time_frame: candle_map[time_frame], scanOnBasket, last_scan: dayjs().format('hh:mm A DD/MM/YY'),
    }
    delete (save_state.gotState)
    delete (save_state.screener_uuid)
    delete (save_state.setAlerts)
    delete (save_state.allowed_scans)
    delete save_state.showError
    delete save_state.indicatorListUpdated
    delete save_state.stepsCompleted
    const saveParams = {
      screener_uuid,
      screener_state: save_state,
      screener_name,
      screener_desc: '',
      screener_logic: logic || screener_logic,
      time_frame: candle_map[time_frame],
      chart_type,
      universe: scan_on,
      screener_result: [],
      tags: [],
      last_scan: dayjs().format('hh:mm A DD/MM/YY'),
      ...extraParams,
    }
    if (!live && !screenerState.sample) {
      scanStocks({
        saveParams: { data: saveParams, isScreenerLive: live, csrfmiddlewaretoken: csrf }, params,
      }, {}, true)
      if (gaParamsMap.length) {
        gaParamsMap.map(item => updateGA(...item, 1, 'Scanner parameters'))
      }
    }
  }

  render() {
    const { children } = this.props
    return children({
      ...this.state,
      showScanBtn: this.showScanBtn,
      chartList: this.chartList,
      candleList: this.candleList,
      handleChange: this.handleChange,
      onEditParams: this.scanSaveHandler,
    })
  }
}

export default EditScreenerParamsHead
