import React from 'react'
import PropTypes from 'prop-types'
import color from 'color'
import isEqual from 'lodash/isEqual'
import makeStyles from '@material-ui/styles/makeStyles'
import classnames from 'classnames'

import CustomTooltip from './CustomTooltip'
import CustomText from './CustomText'
import Icon from './Icon'

import {
  COLORS, ICONS, SPACING, theme,
} from '../Theme'
import StockIcon from './StockIcon'
import { compressedDYCName } from '../utils/common'

const Chip = ({
  iconName, iconColor, labelStyle, iconContainerStyle, iconStyles,
  transparent, selected, onPress, chipBtnStyles, onCancel, cancelContainerStyles, cancelIconStyles,
  btnDisabled, cancelDisabled, cancelIconProps = {}, index, label, containerClass,
  seg_sym, stockIconStyles, stockIconProps = {}, wrap, isDark, isCondesedView, showTooltip,
}) => {
  const styles = stylesheet()
  let containerStyles = {}
  if (transparent) {
    containerStyles = {
      backgroundColor: 'transparent',
    }
  }
  if (selected) {
    const bgColor = iconColor ? color(iconColor).fade(isDark ? 0.5 : 0.85) : 'transparent'
    containerStyles = {
      ...containerStyles,
      borderColor: iconColor || 'transparent',
      backgroundColor: bgColor,
      // borderColor: COLORS.BLUE,
    }
  }
  const { ciName = ICONS.CLOSE, ciColor = COLORS.GREY_200, ciSize = 8 } = cancelIconProps
  const chipLabel = seg_sym ? compressedDYCName(seg_sym) : label
  return (
    <div
      className={classnames(styles.chip, isCondesedView ? styles.chipCondensed : '', containerClass)}
      style={containerStyles}
    >
      <CustomTooltip show={showTooltip} tooltipProps={{ text: label }}>
        <button
          type="button"
          onClick={() => { if (!selected && onPress) onPress(index) }}
          disabled={btnDisabled}
          style={{ borderRadius: containerStyles.borderRadius || styles.chip.borderRadius }}
        >
          <div className={classnames(styles.chipBtn, isCondesedView ? styles.chipBtnCondensed : '', chipBtnStyles)}>
            {iconName.length > 0 && (
            <div
              className={classnames(styles.iconContainer, iconContainerStyle)}
              style={{ backgroundColor: isDark ? COLORS.WHITE : 'transparent' }}
            >
              <Icon
                name={iconName}
                color={iconColor}
                size={16}
                className={classnames(styles.iconStyle, iconStyles)}
              />
            </div>
            )}
            {seg_sym ? (
              <StockIcon
                seg_sym={seg_sym}
                containerStyle={stockIconStyles}
                height={10}
                width={10}
                {...stockIconProps}
              />
            ) : null}
            {!isCondesedView && (
            <CustomText flex={1} size="small" center={!wrap} className={classnames(styles.labelStyle, wrap ? styles.wrapText : '', labelStyle)}>
                {chipLabel}
            </CustomText>
            )}
          </div>
        </button>
      </CustomTooltip>
      {onCancel ? (
        <button
          type="button"
          className={classnames(styles.cancelBtn, cancelContainerStyles)}
          onClick={onCancel}
          disabled={cancelDisabled}
        >
          <Icon name={ciName} color={ciColor} size={ciSize} style={cancelIconStyles} />
        </button>
      ) : null}
    </div>
  )
}

const areEqual = (prevProps, nextProps) => {
  const {
    iconName, iconColor, label,
    transparent, selected, index, isCondesedView,
    btnDisabled, cancelDisabled, shadowDisabled,
  } = prevProps
  if(!isEqual(iconName, nextProps.iconName)
    || !isEqual(iconColor, nextProps.iconColor)
    || !isEqual(label, nextProps.label)
    || !isEqual(transparent, nextProps.transparent)
    || !isEqual(selected, nextProps.selected)
    || !isEqual(btnDisabled, nextProps.btnDisabled)
    || !isEqual(cancelDisabled, nextProps.cancelDisabled)
    || !isEqual(shadowDisabled, nextProps.shadowDisabled)
    || !isEqual(index, nextProps.index)
    || !isEqual(isCondesedView, nextProps.isCondesedView)
  ) {
    return false
  }
  return true
}

const stylesheet = makeStyles({
  chip: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 18,
    border: `1px solid ${theme.borderColor}`,
    backgroundColor: theme.bgTertiary,
    marginRight: SPACING.SPACE_16,
    paddingRight: SPACING.SPACE_6,
  },
  chipCondensed: {
    paddingRight: 0,
    borderRadius: '50%',
  },
  chipBtn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: `${SPACING.SPACE_2} ${SPACING.SPACE_6} ${SPACING.SPACE_2} ${SPACING.SPACE_4}`,
  },
  chipBtnCondensed: {
    padding: SPACING.SPACE_4,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  iconStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelStyle: {
    paddingLeft: SPACING.SPACE_8,
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
  },
  wrapText: {
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  },
  cancelBtn: {
    display: 'flex',
    padding: `0 ${SPACING.SPACE_4} 0 ${SPACING.SPACE_2}`,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

Chip.defaultProps = {
  iconName: '',
  labelStyle: '',
  iconContainerStyle: '',
  iconStyles: '',
  onPress: null,
  onCancel: null,
  transparent: false,
  selected: false,
}

Chip.propTypes = {
  iconName: PropTypes.string,
  iconContainerStyle: PropTypes.string,
  labelStyle: PropTypes.string,
  iconStyles: PropTypes.string,
  onPress: PropTypes.func,
  onCancel: PropTypes.func,
  transparent: PropTypes.bool,
  selected: PropTypes.bool,
}

export default React.memo(Chip, areEqual)
