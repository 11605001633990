/* eslint-disable max-len */
import makeStyles from '@material-ui/styles/makeStyles'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import CustomText from './CustomText'
import CustomInput from './CustomInput'
import Button from './Button'

import {
  SPACING, ASSETS,
} from '../Theme'

const ShareCopy = React.memo(({
  onCreate, title, inputPlaceholder,
  listName: prevListName, btnText, loading,
  subText, privateStrategy,
  onClickHandler,
}) => {
  const styles = stylesheet()
  const [listName, setListName] = useState(prevListName)
  const [nameErr, updateErr] = useState('')
  const edit = !!prevListName
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [])

  const onCreateHandler = () => {
    if (!listName.trim()) {
      updateErr('Name cannot be empty')
      return
    }
    onCreate({
      listName: listName.trim(),
      newList: !edit,
      //   thumbnail: currentColor,
      type: 'color',
      prevListName,
      // prevThumbnail,
    })
  }
  const onChangeText = (e) => {
    if (nameErr) {
      updateErr('')
    }
    if (e.target.value && e.target.value.length > 200) {
      return
    }
    setListName(e.target.value)
  }
  const onKeyDown = (ev) => {
    const { key } = ev
    if (key === 'Enter') {
      onCreateHandler()
    }
  }
  return (
    <section className={styles.container}>
      {privateStrategy && (
      <div className={styles.lockIcon}>
        <img src={ASSETS.Lock} width={106} height={106} alt="no img" />
      </div>
      )}
      <CustomText
        size="medium_1"
        weight="semi_bold"
        style={{
          paddingBottom: '11px', marginInline: 'auto', paddingTop: '16px',
        }}
      >
        {title}
      </CustomText>
      <CustomText size="small_1" className={styles.subText} style={{ paddingBottom: privateStrategy ? '30px' : '0px' }}>{subText}</CustomText>
      {!privateStrategy ? (
        <CustomInput
          value={listName}
          onChangeText={onChangeText}
          inputRegx={/^[A-Za-z0-9- ]+$/gi}
          placeholder={inputPlaceholder}
          containerStyles={styles.inputContainer}
          inputProps={{
            onKeyDown: ev => onKeyDown(ev),
          }}
          inputError={Boolean(nameErr)}
          inputErrorText={nameErr}
          inputErrStyles={styles.inputErr}
          inputRef={inputRef}
          inputStyles={styles.inputStyles}
        />
      ) : null}
      {!privateStrategy ? (
        <Button
          buttonColor="blue"
          onPress={onCreateHandler}
          btnStyles={styles.submitBtn}
          text={btnText}
          disabled={loading}
          labelSize="medium"
        />
      ) : (
        <Button
          buttonColor="blue"
          onPress={onClickHandler}
          btnStyles={styles.submitBtn}
          text={btnText}
          disabled={loading}
          labelSize="medium"
        />
      )}
    </section>
  )
})

const stylesheet = makeStyles({
  container: {
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
  },
  thumbnail: {
  },
  subText: {
    color: '#000000',
    opacity: 0.6,
    paddingTop: '16px',
  },
  colorSelector: {
    marginTop: SPACING.SPACE_20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorBtn: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    transition: 'all 0.2s',
  },
  inputContainer: {
    margin: `${SPACING.SPACE_20} 0`,
    background: '#F6F8FB 0% 0% no-repeat padding-box',
    border: '1px solid #7070701A',
    borderRadius: '8px',
    opacity: 1,
  },
  inputErr: {
    textAlign: 'left',
  },
  inputStyles: {
    color: '#9BABC6',
    opacity: 1,
    fontSize: '16px',
  },
  cancelBtn: {
    marginTop: SPACING.SPACE_10,
  },
  submitBtn: {
    width: '100%',
    borderRadius: '6px',
    padding: '9px',
  },
  submitLabel: {
    fontSize: '17px',
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      marginTop: SPACING.SPACE_20,
      width: '100%',
      maxWidth: '260px',
      margin: 'auto',
    },
  },
})

ShareCopy.defaultProps = {
  btnText: 'Create',
  listName: '',
  inputPlaceholder: 'List Name',
  title: 'Create New List',
}

ShareCopy.propTypes = {
  btnText: PropTypes.string,
  listName: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  title: PropTypes.string,
}

export default ShareCopy
