import { connect } from 'react-redux'

import AlgoInfo from './AlgoInfo'

import {
  markFavorite,
  getFavorite,
} from '../../containers/Scans/actions'
import { deleteAlgo } from '../../containers/Algos/actions'
import { copyAlgo } from '../../containers/Create/actions'
import { fetchBacktestResults, saveBacktestParams } from '../../containers/Backtests/actions'
import { refreshAlgos } from '../../containers/Common/actions'

import { updatePublicationStats } from '../../containers/MarketPlace/actions'
import { toggleLoginModal } from '../../containers/Auth/actions'

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    ...state.auth.loginResp,
    isLoggedIn: state.auth.isLoggedIn,

    favorites: state.scans.favorites,
    isFetchingFavorite: state.scans.isFetchingFavorite,
    favoriteError: state.scans.favoriteError,
    markFavoriteResp: state.scans.markFavoriteResp,
    markFavoriteError: state.scans.markFavoriteError,
    markFavoriteErrorMsg: state.scans.markFavoriteErrorMsg,
    favoriteErrorMsg: state.scans.favoriteErrorMsg,
    isMarkingFavorite: state.scans.isMarkingFavorite,

    isDeleting: state.algos.isDeleteingAlgo,
    deleteAlgoResp: state.algos.deleteAlgoResp,
    deleteAlgoError: state.algos.deleteAlgoError,
    deleteAlgoErrorMsg: state.algos.deleteAlgoErrorMsg,

    copyAlgoResp: state.create.copyAlgoResp,
    isCopyingAlgo: state.create.isCopyingAlgo,
    copyAlgoError: state.create.copyAlgoError,
    copyAlgoErrorMsg: state.create.copyAlgoErrorMsg,

    publicationStatsResp: state.marketplace.publicationStatsResp,
    isUpdatingPublicationStats: state.marketplace.isUpdatingPublicationStats,
    publicationStatsError: state.marketplace.publicationStatsError,
    publicationStatsErrorMsg: state.marketplace.publicationStatsErrorMsg,

    isSavingBacktestParams: state.backtests.isSavingBacktestParams,
    // saveBacktestParamsSuccess: state.backtests.saveBacktestParamsSuccess,
    // saveBacktestParamsError: state.backtests.saveBacktestParamsError,
    // saveBacktestParamsErrorMsg: state.backtests.saveBacktestParamsErrorMsg,

    disabled: state.backtests.onGoingBacktests[state.backtests.backtests?.algo_uuid],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    markFavorite: (params, headers) => dispatch(markFavorite(params, headers)),
    getFavorite: (params, headers) => dispatch(getFavorite(params, headers)),
    deleteAlgo: (params, headers) => dispatch(deleteAlgo(params, headers)),

    updatePublicationStats: params => dispatch(updatePublicationStats(params)),

    copyAlgo: (params, headers) => dispatch(copyAlgo(params, headers)),
    fetchBacktests: params => dispatch(fetchBacktestResults(params)),
    refreshAlgos: (field, value) => dispatch(refreshAlgos(field, value)),
    saveBacktestParams: params => dispatch(saveBacktestParams(params)),
    toggleAuthModal: (state, postLoginParams, loginShared) => dispatch(
      toggleLoginModal(state, postLoginParams, loginShared),
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlgoInfo)
