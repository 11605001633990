/* eslint-disable no-unused-vars */
import {
  takeLatest,
  put,
  call,
} from 'redux-saga/effects'
import {
  CREATE_ORDER_INIT,
  FETCH_PAYMENT_PARAMS_INIT,
  FETCH_PAYMENT_METHOD_INIT,
  FETCH_SUBSCRIPTION_PRICE_INIT,
  PAY_WITH_CREDITS,
} from './actionTypes'
import {
  createOrderFailure,
  createOrderSuccess,
  fetchPaymentParamsSuccess,
  fetchPaymentParamsFailure,
  fetchPaymentMethodsSuccess,
  fetchPaymentMethodsFailure,
  fetchSubscriptionPriceSuccess,
  fetchSubscriptionPriceFailure,
  payWithCreditsSuccess,
  payWithCreditsFailure,
} from './actions'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'

function* createOrder(action) {
  try {
    const { params } = action
    const url = `${baseUrl}${prodEndpoints.generate_subscription}`
    const response = yield call(
      postRequest, url, JSON.stringify(params), { 'content-type': 'application/json' }, false,
    )
    if(response.status !== 'error' && response.status === 'success') {
      yield put(createOrderSuccess({ ...response, paymentPlan: params }))
    } else {
      yield put(createOrderFailure(response.error_msg || 'Some error occurred'))
    }
  } catch (err) {
    yield put(createOrderFailure(err))
  }
}

export function* createOrderSaga() {
  yield takeLatest(CREATE_ORDER_INIT, createOrder)
}

function* fetchPaymentParams(action) {
  try {
    const { headers, params, errorDesc } = action
    const url = prodEndpoints.fetch_payment_params
    const response = yield call(postRequest, url, params, headers)
    if(response.status === 'success') {
      yield put(fetchPaymentParamsSuccess(response, errorDesc))
    } else {
      yield put(fetchPaymentParamsFailure(response.error_msg))
    }
  } catch (err) {
    yield put(fetchPaymentParamsFailure(err))
  }
}

export function* fetchPaymentParamsSaga() {
  yield takeLatest(FETCH_PAYMENT_PARAMS_INIT, fetchPaymentParams)
}

function* fetchPaymentMethods(action) {
  try {
    const { params = {} } = action
    const isPost = params.contact && params.email
    const url = isPost
      ? prodEndpoints.fetch_payment_preference
      : prodEndpoints.fetch_payment_method
    const response = isPost ? yield call(postRequest, url, params)
      : yield call(getRequest, url)
    if(response.status === 'success') {
      yield put(fetchPaymentMethodsSuccess(response))
    } else {
      yield put(fetchPaymentMethodsFailure(response.error_msg))
    }
  } catch (err) {
    yield put(fetchPaymentMethodsFailure(err))
  }
}

export function* fetchPaymentMethodsSaga() {
  yield takeLatest(FETCH_PAYMENT_METHOD_INIT, fetchPaymentMethods)
}

function* fetchSubscriptionPrice(action) {
  try {
    const { params } = action
    const url = prodEndpoints.get_subscription_price
    const response = yield call(
      postRequest, url, JSON.stringify(params), { 'content-type': 'application/json' }, false,
    )
    if(response.status === 'success') {
      yield put(fetchSubscriptionPriceSuccess(response))
    } else {
      yield put(fetchSubscriptionPriceFailure(response.error_msg))
    }
  } catch (err) {
    yield put(fetchSubscriptionPriceFailure(err))
  }
}

export function* fetchSubscriptionPriceSaga() {
  yield takeLatest(FETCH_SUBSCRIPTION_PRICE_INIT, fetchSubscriptionPrice)
}

function* payWithCredits(action) {
  try {
    const { params } = action
    const {
      user_uuid, razorpay_subscription_id,
    } = params
    const url = `/paymentProcess/?user_uuid=${user_uuid}&host=${window.location.host}`
    const response = yield call(
      postRequest, url, JSON.stringify({
        razorpay_subscription_id, app: true,
      }), { 'content-type': 'application/json' }, false,
    )
    if(response.status === 'success') {
      yield put(payWithCreditsSuccess(response))
    } else {
      yield put(payWithCreditsFailure(response.error_msg))
    }
  } catch (err) {
    yield put(payWithCreditsFailure(err))
  }
}

export function* payWithCreditsSaga() {
  yield takeLatest(PAY_WITH_CREDITS, payWithCredits)
}
