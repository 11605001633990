/**
 *   @akshay
 *   how to use
 *   <HighlightedText color="textSecondary" backgroundColor text="" />
 */

import React from 'react'
import PropTypes from 'prop-types'

import Icon from './Icon'

import {
  FONTS, ICONS, SPACING, theme,
} from '../Theme'

const HighlightedText = ({
  backgroundColor,
  color,
  text,
  styles,
  borderColor,
  iconName,
  iconColor,
  iconSize,
  textStyles,
  iconStyles,
}) => {
  const borderStyle = borderColor ? { borderWidth: 1, borderColor } : {}
  const stylesText = {
    backgroundColor: backgroundColor ? (theme[backgroundColor] || backgroundColor) : 'transparent',
    color: theme[color] || color,
    fontSize: FONTS.SMALL,
    paddingHorizontal: 4,
    borderRadius: 10,
    display: 'flex',
    textAlignVertical: 'center',
    textAlign: 'center',
    alignItems: 'center',
    ...borderStyle,
    ...iconStyles,
    ...textStyles,
    ...styles,
  }

  return (
    <p style={stylesText}>
      {iconName && (
        <Icon
          name={ICONS[iconName]}
          color={theme[iconColor] || iconColor}
          size={iconSize}
          style={{ marginRight: SPACING.SPACE_8, ...iconStyles }}
        />
      )}
      {text}
    </p>
  )
}

export default HighlightedText

HighlightedText.defaultProps = {
  backgroundColor: '',
  color: 'red',
  text: '',
  styles: {},
  borderColor: '',
  iconName: '',
  iconColor: 'red',
  iconSize: 12,
  textStyles: {},
  iconStyles: {},
}

HighlightedText.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  textStyles: PropTypes.object,
  styles: PropTypes.object,
  borderColor: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  iconStyles: PropTypes.object,
}
