/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import classNames from 'classnames'
import CustomInput from '../../UI/CustomInput'
import Button from '../../UI/Button'
import CustomText from '../../UI/CustomText'

import { ASSETS } from '../../Theme'
import { abSignUp, termsPage, abLoginHome } from '../../utils/apiEndpoints'

const showLogin = false

const ABLogin = React.memo(({
  styles,
  disabled,
  passwordErr,
  onChangeText,
  password,
  abId,
  abIdErr,
  loginWithAB,
  onKeyDown,
  sharedLogin,
  authModal,
  isMobile,
}) => {
  const instructionImage = isMobile ? ASSETS.AbLoginInstructionApp : ASSETS.AbLoginInstructionWeb

  const renderLogin = () => {
    return (
      <>
        <CustomInput
          // label="Email"
          inputError={abIdErr}
          onChangeText={onChangeText}
          inputKey="abId"
          value={abId}
          placeholder="Enter you user id"
          inputStyles={styles.fieldInput}
          containerStyles={styles.emailInputContainer}
          wrapperStyles={styles.inputWrapper}
          disabled={disabled}
        />
        <CustomInput
          // label="Password"
          inputError={passwordErr}
          onChangeText={onChangeText}
          inputKey="password"
          value={password}
          placeholder="Enter you password"
          inputStyles={styles.fieldInput}
          containerStyles={styles.passwordInputContainer}
          wrapperStyles={styles.inputWrapper}
          inputProps={{
            onKeyDown: ev => onKeyDown(ev, 'password'),
            type: 'password',
          }}
          disabled={disabled}
        />
        <Button
          text="Login"
          onPress={loginWithAB}
          btnStyles={styles.abLoginPageBtn}
          buttonColor="blue"
          loading={disabled}
          disabled={disabled}
        />
        <div className={styles.noAccount}>
          <CustomText>
            {'Don\'t have an account '}
            <a href={abSignUp}>
              Sign Up
            </a>
          </CustomText>
        </div>
        <div className={styles.abTncContainer} style={{ alignItems: sharedLogin ? 'center' : '' }}>
          <CustomText size="small" style={{ whiteSpace: 'pre-line' }}>
            {'By Continuing you agree to our \n'}
            <a href={termsPage} rel="noreferrer" target="_blank" className={styles.tncLink}>
              Terms & Conditions
            </a>
          </CustomText>
        </div>
      </>
    )
  }

  const renderLoginInstructions = () => {
    return (
      <div className={styles.loginInstructionContainer}>
        <CustomText center>
          The login flow for AngelOne has temporarily changed.
        </CustomText>
        <CustomText center>
          To login, please follow the instructions shown below.
        </CustomText>
        <CustomText size="small" color="textSecondary">
          To login to Streak, after logging into AngelOne site, click on the ‘Home’
          tab of the right panel in AngelOne site as shown in screenshot below,
          scroll down and click on Streak button
        </CustomText>

        <div className={styles.stepsContainer}>
          <div className={styles.stepRow}>
            <CustomText size="medium_1" weight="semi_bold" className={styles.stepCountText}>1</CustomText>
            <CustomText size="small" style={{ whiteSpace: 'pre-line' }}>
              {'Login to '}
              <a href={abLoginHome} rel="noreferrer" target="_blank" className={styles.tncLink}>
                trade.angelone.in
              </a>
            </CustomText>
          </div>
          <div className={styles.stepRow}>
            <CustomText size="medium_1" weight="semi_bold" className={styles.stepCountText}>2</CustomText>
            <CustomText size="small">
              Click on ‘Home’ tab, and scroll down
            </CustomText>
          </div>
          <div className={styles.stepRow}>
            <CustomText size="medium_1" weight="semi_bold" className={styles.stepCountText}>3</CustomText>
            <CustomText size="small">
              {'External Services > Streak button'}
            </CustomText>
          </div>
        </div>

        <img src={instructionImage} alt="angel_login_instruction" className={styles.abLoginInstructionImg} />
      </div>
    )
  }

  return (
    <div className={classNames(styles.abLoginContainer, authModal && styles.abLoginModalContainer)}>
      <img src={ASSETS.ABImage} alt="angel_one_logo" className={styles.abLogo} />
      {showLogin ? renderLogin() : renderLoginInstructions()}
    </div>
  )
})

export default ABLogin
