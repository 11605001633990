import FeedBack from './footer_feedback.png'
import Banner from './home_banner.png'
import Powerful from './home_info.png'
import Beautiful from './diamond.png'
import Easy from './thumbs_3d.png'
import TrialOffer from './home_trial.png'
import PlanBasic from './explorer.png'
// import PlanExplorer from './plan_premium.png'
import PlanUltimate from './plan_ultimate.png'
import StrategiesCard from './card_strategies.png'
import TechnicalsCard from './card_technicals.png'
import ScannersCard from './card_scanner.png'
import StreakQR from './streak_scan.PNG'
import OtgMobile from './otg_mobile.png'
import Confetti from './confetti.png'
import TechnicalBar from './technicals.png'
import NotficationBell from './bell.png'
import CreateAdvance from './hand_trade.png'
import PaperTrade from './folder.png'
import OptionsTrading from './chart_3d.png'
import Multitime from './clock_3d.png'
import DycContracts from './rocket_paper.png'
import AppStoreDownload from './app_store.png'
import PlayStoreDownload from './play_store.png'

import AppStore from './footer_app_store.svg'
import PlayStore from './footer_play_store.svg'
import Facebook from './footer_facebook.svg'
import Instagram from './footer_instagram.svg'
import Twitter from './footer_twitter.svg'
import YouTube from './footer_youtube.svg'
import LinkedIn from './footer_linkedin.svg'
import Telegram from './footer_telegram.svg'

import TickBg from './Tick_w_bg.svg'

import Scanner from './home_lighting.svg'
import Strategies from './home_pencil.svg'
import Technicals from './home_send.svg'

import Blogs from './resources_1.svg'
import Videos from './resources_2.svg'
import Academy from './resources_3.svg'

import StreakTmLogo from './streaktm.svg'
import StreakLogoDark from './logos/logoWhite.png'
import FivePaisaLogo from './logos/5paisa_logo.png'
import KiteLogo from './logos/kite_logo.png'
import ABLogo from './logos/ab_logo.png'
import UpstoxLogo from './logos/upstox_logo.png'
import IciciLogo from '../../assets/png/logo/icici.png'

import DemoGif from './gifs/free_demo.gif'

import Features from './features.svg'
import FeaturesTab from './featuresTab.svg'
import FeaturesMobile from './FeaturesMobile.svg'

import FeatureBackground from './Why streak Back 2.svg'
import VideoBackground from './Background_1.svg'
import VideoBackgroundTab from './Background1Tab.png'
import VideoBackgroundMobile from './Background1Mobile.png'
import FeatureBackgrondTab from './BackgroundFeaturesTab.png'

import TestimonialBackground from './TestimonialDesktop.png'
import TestimonialBackgroundTab from './TestimonialBackgroundTab.png'
import TestimonialBackgroundMobile from './TestimonialBackgroundMobile.png'

// marketing broker icon_size
import Zerodha from './brokers/Zerodha-nobg.png'
import AngelOne from './brokers/AB-nobg.png'
import Upstox from './brokers/upstox-nobg.png'
import FivePaisa from './brokers/fivePaisa.png'
import ICICI from './brokers/ICICI.png'

import TestimonialUser1 from './testimonialUser1.png'
import TestimonialUser2 from './testimonialUser2.png'
import TestimonialUser3 from './testimonialUser3.png'
import TestimonialCommonUser from './user.png'

import FooterIllustration from './footer_Illusrtation.png'

import Play from './play.svg'

import FeatureScanner from './media/scanner.mp4'
import FeatureBacktest from './media/backtest.mp4'
import FeatureTechnicals from './media/technicals.mp4'
import FeatureDeploy from './media/deploy.mp4'

// new product tour
import PTScanner from './ScannerPT.mp4'
import PTStrategy from './StrategyPT.mp4'
import PTTechnicals from './TechnicalsPT.mp4'
import PTDeploy from './DeployPT.mp4'

import FeatureVideoBackground from './Background 3.svg'

import HeroImg from './Banner.svg'
import HeroImgNew from './HeroImgNew.svg'
import HeroImgMobile from './HeroImgMobile.png'

import whyStreakTableHeader from './whyStreak-tableHeader.png'
import whyStreakTableRow1 from './whyStreak-tableRow1.png'
import whyStreakTableRow2 from './whyStreak-tableRow2.png'
import whyStreakTableRow3 from './whyStreak-tableRow3.png'
import whyStreakTableRow4 from './whyStreak-tableRow4.png'
import whyStreakTableRow5 from './whyStreak-tableRow5.png'
import ReadMore from './Read-more.png'
import PlanBannerBackground from './PlanBannerBackground.svg'
import WhyStreakTableMobile from './whyStreakTableMobile.png'

import flagPurple from './Flag-Purple.svg'
import flagOrange from './Flag-Orange.svg'
import flagBlue from './Flag-Blue.svg'
import flagPink from './Flag-Pink.svg'
import QouteLeft from './qoute-icon-left.svg'
import QouteRight from './qoute-icon-right.svg'
import ForwardArrow from './ForwardArrow.svg'
import BackwardArrow from './BackwardArrow.svg'

import footerMountains from './footerMountains.svg'
import CtbPageMain from './CTBPageMain.mp4'
import CtbPagePlaceholder from './ctbPagePlaceholder.png'
import CtbBannerBg1 from './ctbBannerBG1.svg'
import CtbBannerBg2 from './ctbBannerBG2.svg'
import CtbBannerMobileBg from './ctbBannerMobileBG.svg'

const whyStreakTable = [
  { name: 'whyStreakTableHeader', src: whyStreakTableHeader },
  { name: 'whyStreakTableRow1', src: whyStreakTableRow1 },
  { name: 'whyStreakTableRow2', src: whyStreakTableRow2 },
  { name: 'whyStreakTableRow3', src: whyStreakTableRow3 },
  { name: 'whyStreakTableRow4', src: whyStreakTableRow4 },
  { name: 'whyStreakTableRow5', src: whyStreakTableRow5 },
]

export default {
  FeedBack,
  Banner,
  Powerful,
  Beautiful,
  Easy,
  TrialOffer,
  PlanBasic,
  // PlanExplorer,
  PlanUltimate,
  StreakQR,
  StrategiesCard,
  TechnicalsCard,
  ScannersCard,
  OtgMobile,
  Confetti,
  TechnicalBar,
  NotficationBell,
  CreateAdvance,
  PaperTrade,
  OptionsTrading,
  Multitime,
  DycContracts,

  Blogs,
  Videos,
  Academy,

  PlayStoreDownload,
  AppStoreDownload,

  AppStore,
  PlayStore,
  Facebook,
  Instagram,
  Twitter,
  YouTube,
  LinkedIn,
  Scanner,
  Strategies,
  Technicals,
  Telegram,

  TickBg,

  StreakTmLogo,
  StreakLogoDark,
  FivePaisaLogo,
  KiteLogo,
  ABLogo,
  IciciLogo,
  UpstoxLogo,

  DemoGif,
  Features,
  FeaturesTab,
  VideoBackground,
  VideoBackgroundTab,
  VideoBackgroundMobile,
  FeatureBackground,
  FooterIllustration,
  TestimonialBackground,
  TestimonialBackgroundTab,
  TestimonialBackgroundMobile,
  FeatureBackgrondTab,
  FeaturesMobile,

  Zerodha,
  AngelOne,
  Upstox,
  FivePaisa,
  ICICI,
  TestimonialUser1,
  TestimonialUser2,
  TestimonialUser3,
  TestimonialCommonUser,

  Play,

  FeatureScanner,
  FeatureBacktest,
  FeatureTechnicals,
  FeatureDeploy,

  FeatureVideoBackground,

  HeroImg,
  HeroImgNew,
  HeroImgMobile,

  whyStreakTable,
  WhyStreakTableMobile,
  ReadMore,
  PlanBannerBackground,

  flagBlue,
  flagOrange,
  flagPurple,
  flagPink,
  QouteLeft,
  QouteRight,
  ForwardArrow,
  BackwardArrow,

  footerMountains,

  PTScanner,
  PTStrategy,
  PTTechnicals,
  PTDeploy,

  CtbPagePlaceholder,
  CtbPageMain,
  CtbBannerBg1,
  CtbBannerBg2,
  CtbBannerMobileBg,
}
