import { connect } from 'react-redux'

import PaymentPages from './PaymentPages'
import { getPaymentInfo } from './actions'

const mapStateToProps = (state) => {
  return {
    ...state.paymentPages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentInfo: headers => dispatch(getPaymentInfo(headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPages)
