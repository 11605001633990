import React from 'react'
import PropsTypes from 'prop-types'

import CustomText from './CustomText'

const CustomRadio = ({
  value,
  onValueChange,
  className,
  id = '',
  disabled,
  labels,
  labelProps,
  color = 'blue',
  showLabel,
}) => {
  const valueChangeHandler = (e, selectedName, isDisabled) => {
    if (isDisabled) {
      e.preventDefault()
      return
    }
    onValueChange(selectedName)
  }
  return (
    <div className="radiogroup">
      {labels.map((item) => {
        const isDisabled = disabled.some(itm => itm === item)
        return (
          <label key={item} className={`cRadioLabel cRadioLabel_${color} ${className}`} htmlFor={`radio_${item}`}>
            <input
              className="cRadioInput"
              type="radio"
              id={`radio_${item}`}
              onChange={e => valueChangeHandler(e, item, isDisabled)}
              name={id}
              checked={value === item}
              value={item}
              disabled={isDisabled}
            />
            <span className="cRadioTick" />
            {showLabel && <CustomText {...labelProps}>{item}</CustomText>}
          </label>
        )
      })}

    </div>
  )
}

export default CustomRadio

CustomRadio.defaultProps = {
  params: {},
  disabled: [],
  className: '',
  labelProps: {},
  value: '',
  showLabel: true,
}

CustomRadio.propTypes = {
  value: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number]),
  onValueChange: PropsTypes.func.isRequired,
  id: PropsTypes.string.isRequired,
  params: PropsTypes.object,
  disabled: PropsTypes.array,
  className: PropsTypes.string,
  labelProps: PropsTypes.object,
  showLabel: PropsTypes.bool,
}
