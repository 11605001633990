import React, { useEffect } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { useLocation } from 'react-router-dom'
import Auth from '../containers/Auth/Auth-redux'
import CustomModal from './CustomModal'
import { setCookie } from '../utils/common'

const LoginModal = ({
  visible, onDismiss, domainChange, isHomePage,
  postLoginParams, isLoggedIn, history, extraProps = {},
  loginShared,
}) => {
  const location = useLocation()
  const styles = stylesheet()
  useEffect(() => {
    if (visible && postLoginParams) {
      if (isLoggedIn) {
        const { pathname, pathParams } = postLoginParams
        history.push(`${pathname}${pathParams}`)
        onDismiss()
      } else setCookie(postLoginParams)
    }
  }, [visible])
  return (
    <CustomModal
      visible={visible}
      dismissKey="showAuthModal"
      onDismiss={onDismiss}
      hideScroll
      containerStyles={styles.loginModal}
    >
      <Auth
        domainChange={domainChange}
        isHomePage={isHomePage}
        location={location}
        loginModal
        sharedLogin
        loginShared={loginShared}
        {...extraProps}
      />
    </CustomModal>

  )
}

const stylesheet = makeStyles({
  container: {},
  loginModal: {
    maxWidth: '387px',
    padding: '0px 0px',
    borderRadius: '27px',
  },
})

export default LoginModal
