import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import CustomTooltip from '../../UI/CustomTooltip'
import Icon from '../../UI/Icon'
import {
  COLORS, DIMENSIONS, ICONS, SPACING, theme,
} from '../../Theme'
import CustomText from '../../UI/CustomText'

const SidebarMenuCondensed = ({ menuItems, onBarPress, isDark }) => {
  const styles = stylesheet()
  const [activeTab, setActiveTab] = useState('')

  const renderMenuItems = (item) => {
    const {
      title, iconName, sectionId, gaAction,
    } = item
    return (
      <div onMouseEnter={() => setActiveTab(title)}>
        <CustomTooltip
          show={activeTab === title}
          placement="left"
          tooltipProps={{ text: title }}
          contentRenderer={() => renderMenuItemToolTip(item)}
          interactive
          tooltipContainerStyles={styles.menuItemTooltip}
          onMouseEnter={() => setActiveTab(title)}
        >
          <button
            onClick={() => onBarPress(sectionId, gaAction)}
            type="button"
            key={title}
          >
            <div className={styles.tooltipRow}>
              <div className={classnames(styles.iconContainer,
                isDark ? styles.iconContainerDark : styles.iconContainerLight)}
              >
                <Icon name={iconName} size={22} className={styles.iconLight} />
              </div>
            </div>
          </button>
        </CustomTooltip>
      </div>
    )
  }

  const renderEmptyText = (title) => {
    return (
      <CustomText size="small" color="textSecondary">
        {title === menuItems[2].title ? 'Not Live' : 'Not Deployed'}
      </CustomText>
    )
  }

  const renderMenuItemToolTip = (item) => {
    const {
      title, iconName, sectionId, subTitle, stPrefix, gaAction,
    } = item
    return (
      <button
        onClick={() => onBarPress(sectionId, gaAction)}
        type="button"
        key={title}
        style={{ width: '100%' }}
      >
        <div className={styles.rowTooltip}>
          <div className={styles.iconContainerTooltip}>
            <Icon name={iconName} color={theme.linkColor} size={16} />
          </div>
          <div className={styles.info}>
            <CustomText weight="medium">{title}</CustomText>
            {subTitle ? (
              <CustomText className={styles.subText} color="textSecondary" size="small_0">
                {`${subTitle} ${stPrefix}`}
              </CustomText>
            ) : renderEmptyText(title)}
          </div>
          <div className={styles.arrow}>
            <Icon name={ICONS.FORWARD} color={theme.textSecondary} size={10} />
          </div>
        </div>
      </button>
    )
  }
  return(
    <div className={styles.menuItems}>
      {menuItems.map(item => <div key={item.title}>{renderMenuItems(item)}</div>)}
    </div>
  )
}

const stylesheet = makeStyles({
  menuItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
    alignItems: 'center',
    margin: `${SPACING.SPACE_10} 0`,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: SPACING.SPACE_10,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.borderColor,
    },
  },
  iconContainerLight: {
    '& svg': {
      fill: theme.linkColor,
    },
    '&:hover': {
      backgroundColor: theme.borderColor,
    },
  },
  iconContainerDark: {
    backgroundColor: theme.borderColor,
    '& svg': {
      fill: COLORS.WHITE,
    },
    '&:hover': {
      backgroundColor: COLORS.BLACK,
      '& svg': {
        fill: theme.linkColor,
      },
    },
  },
  menuItemTooltip: {
    background: theme.bgSidebar,
    boxShadow: `0px 10px 20px ${theme.dropdownShadow}`,
  },
  rowTooltip: {
    display: 'flex',
    padding: `${SPACING.SPACE_10} ${DIMENSIONS.SPACE_HORIZONTAL}`,
    background: theme.bgSidebar,
    alignItems: 'center',
    width: 250,
    '&:hover': {
      background: theme.hover,
      '& $arrow > svg': {
        fill: theme.linkColor,
      },
    },
  },
  info: {
    margin: `0 ${SPACING.SPACE_16}`,
    textAlign: 'left',
    textTransform: 'capitalize',
    flex: 1,
  },
  iconContainerTooltip: {
    display: 'flex',
    alignItems: 'center',
    height: '38px',
    width: '38px',
    borderRadius: '20px',
    justifyContent: 'center',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
  },
})

export default SidebarMenuCondensed
