import React from 'react'

import ToggleButton from '../../UI/ToggleButton'
import Steps from '../../UI/Steps'
import CustomText from '../../UI/CustomText'

import {
  postionBtnMap,
} from '../../utils/consts'
import { CONDITION_TYPE, STEP_CONDITION_TYPE } from '../../containers/Create/Create-utils'

function CreateMulti({
  styles, stepsCompleted, conditionEditType,
  positionType, disableActions, onStepPress, activeIndex,
  togglePositions, renderContent, isDark, renderParams, startTour,
}) {
  return (
    <>
      <Steps
        steps={STEP_CONDITION_TYPE}
        defaultStepIndex={activeIndex}
        onStepPress={onStepPress}
        stepsCompleted={Array.from(stepsCompleted)}
        containerSytles={styles.stepsContainer}
      />
      <div className={styles.listView}>
        {conditionEditType === CONDITION_TYPE.ENTRY
                        && (
                        <div id="tour_create_strategy_8" className={startTour ? styles.positionsTourStyling : styles.positionsContainer}>
                          <CustomText weight="semi_bold" size="medium">Position I would take</CustomText>
                          <ToggleButton
                            selected={positionType.toLowerCase()}
                            btnMap={postionBtnMap(isDark)}
                            toggleHandler={togglePositions}
                            containerStyles={styles.positions}
                                    // isDark={isDark}
                            btnWidth={60}
                          />
                        </div>
                        )}
        {renderContent(disableActions, conditionEditType)}
        {renderParams(disableActions)}
      </div>

    </>
  )
}

export default CreateMulti
