/* eslint-disable max-len */
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import Button from './Button'
import CustomInput from './CustomInput'
import CustomText from './CustomText'
import CustomModal from './CustomModal'
import Marquee from './Marquee'
import Icon from './Icon'

import {
  FONTWEIGHT, SPACING, theme, ICONS,
} from '../Theme'

const EditName = ({
  defaultName = '',
  title = 'Edit strategy name',
  onSubmit,

  children,
  containerStyles,
  btnStyles,
  disabled,
  paramsToSend,
  showTooltip,
}) => {
  const styles = stylesheet()
  const [value, updateValue] = useState(defaultName)
  const [showNameChange, toggleModal] = useState(false)
  const [nameErr, updateErr] = useState('')
  useEffect(() => {
    if (showNameChange && defaultName !== value) {
      updateValue(defaultName)
    }
  }, [showNameChange])
  const onChangeText = (e) => {
    if (nameErr) {
      updateErr('')
    }
    updateValue(e.target.value)
  }
  const onDismiss = () => {
    toggleModal(!showNameChange)
    updateErr('')
  }
  const onPress = () => {
    if (value.trim()) {
      updateErr('')
      onSubmit(value.trim(), paramsToSend)
      toggleModal(false)
    }else{
      updateErr('Name cannot be empty')
    }
  }
  return (
    <>
      <div className={classnames(styles.container, !disabled ? styles.editableName : '', containerStyles)}>
        <button
          type="button"
          disabled={disabled}
          onClick={() => toggleModal(true)}
          className={classnames(styles.nameBtn, btnStyles)}
        >
          {!disabled && (
          <Icon name={ICONS.EDIT} color={theme.linkColor} size={10} className={styles.editIcon} />
          )}
          {showTooltip ? (
            <Marquee showTooltip showMarquee={false} tooltipText={defaultName} changes={defaultName}>
              <CustomText
                weight="semi_bold"
                size="medium"
                className={styles.name}
              >
                {`${defaultName}  `}
              </CustomText>
            </Marquee>
          ) : (
            <CustomText
              weight="semi_bold"
              size="medium"
              ellipsis
              className={styles.name}
            >
              {`${defaultName}  `}
            </CustomText>
          )}

        </button>
        {children && children}
      </div>
      <CustomModal
        visible={showNameChange}
        onDismiss={onDismiss}
      >
        <div className={styles.modalContainer}>
          <CustomText weight="semi_bold" size="medium" className={styles.title}>{title}</CustomText>
          <CustomInput
            value={value}
            onChangeText={onChangeText}
            placeHolder="New name"
            inputRegx={/^[A-Za-z0-9- ]+$/gi}
            containerStyles={styles.inputContainer}
            inputStyles={styles.inputStyles}
            inputProps={{ autoFocus: true }}
            inputError={Boolean(nameErr)}
            inputErrorText={nameErr}
            inputErrStyles={styles.nameErr}
          />
          <div className={styles.actionBar}>
            <Button
              buttonColor="tabsBg"
              onPress={onDismiss}
              btnStyles={styles.cancelBtn}
              labelColor="textSecondary"
              text="Cancel"
              mode="text"
            />
            <Button
              buttonColor="blue"
              onPress={onPress}
              btnStyles={styles.submitBtn}
              text="Save"
            />
          </div>
        </div>
      </CustomModal>
    </>
  )
}

const stylesheet = makeStyles({
  container: {
    display: 'inline-block',
    width: '100%',
    // alignItems: 'baseline',
  },
  name: {
    transition: 'all ease-in 200ms',
  },
  nameBtn: {
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: '80%',
  },
  editIcon: {
    display: 'none',
    alignSelf: 'center',
    position: 'absolute',
    left: 0,
  },
  editableName: {
    '& $nameBtn': {
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
        '& $name': {
          transform: 'translateX(18px)',
          color: `${theme.linkColor} !important`,
        },
        '& $editIcon': {
          display: 'unset',
        },
      },
    },
  },

  // modal styles
  modalContainer: {
    width: '95%',
    margin: 'auto',
  },
  title: {
    marginBottom: SPACING.SPACE_16,
  },
  inputContainer: {
    backgroundColor: theme.tabsBg,
    borderRadius: 6,
  },
  input: {
    fontWeight: FONTWEIGHT.MEDIUM,
    border: 0,
  },
  nameErr: {
    position: 'absolute',
    top: '35px',
  },
  actionBar: {
    marginTop: SPACING.SPACE_20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    flex: 0.5,
    marginRight: SPACING.SPACE_14,
    padding: SPACING.SPACE_10,
    border: `1px solid ${theme.tabsBg}`,
  },
  submitBtn: {
    flex: 0.5,
    marginLeft: SPACING.SPACE_14,
    padding: SPACING.SPACE_10,
  },
})

export default EditName
