export const FETCH_ALGO_INIT = 'FETCH_ALGO_INIT'
export const FETCH_ALGO_SUCCESS = 'FETCH_ALGO_SUCCESS'
export const FETCH_ALGO_FAILURE = 'FETCH_ALGO_FAILURE'

export const DELETE_ALGO_INIT = 'DELETE_ALGO_INIT'
export const DELETE_ALGO_SUCCESS = 'DELETE_ALGO_SUCCESS'
export const DELETE_ALGO_FAILURE = 'DELETE_ALGO_FAILURE'

export const CLEAR_ALGO_RESP = 'CLEAR_ALGO_RESP'

export const CLEAR_ALGO_DATA = 'CLEAR_ALGO_DATA'

export const FETCH_BACKTEST_GRAPH = 'FETCH_BACKTEST_GRAPH'
export const FETCH_BACKTEST_GRAPH_SUCCESS = 'FETCH_BACKTEST_GRAPH_SUCCESS'
export const FETCH_BACKTEST_GRAPH_FAILURE = 'FETCH_BACKTEST_GRAPH_FAILURE'

export const UPDATE_SORT_ON = 'UPDATE_SORT_ON'

export const FILTER_ALGO_INIT = 'FILTER_ALGO_INIT'
export const FILTER_ALGO_SUCCESS = 'FILTER_ALGO_SUCCESS'
export const FILTER_ALGO_FAILURE = 'FILTER_ALGO_FAILURE'
export const FILTER_ALGO_CLEAR = 'FILTER_ALGO_CLEAR'
