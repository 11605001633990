export const SCAN_STOCKS_INIT = 'SCAN_STOCKS_INIT'
export const SCAN_STOCKS_SUCCESS = 'SCAN_STOCKS_SUCCESS'
export const SCAN_STOCKS_FAILURE = 'SCAN_STOCKS_FAILURE'

export const SAVE_SCREEN_INIT = 'SAVE_SCREEN_INIT'
export const SAVE_SCREEN_SUCCESS = 'SAVE_SCREEN_SUCCESS'
export const SAVE_SCREEN_FAILURE = 'SAVE_SCREEN_FAILURE'

export const SCREENER_STATE_INIT = 'SCREENER_STATE_INIT'
export const SCREENER_STATE_SUCCESS = 'SCREENER_STATE_SUCCESS'
export const SCREENER_STATE_FAILURE = 'SCREENER_STATE_FAILURE'

export const CLEAR_SCREENER_RESP = 'CLEAR_SCREENER_RESP'

export const PLACE_DIRECT_INIT = 'PLACE_DIRECT_INIT'
export const PLACE_DIRECT_SUCCESS = 'PLACE_DIRECT_SUCCESS'
export const PLACE_DIRECT_FAILURE = 'PLACE_DIRECT_FAILURE'

export const SCREENER_TRAINER_INIT = 'SCREENER_TRAINER_INIT'
export const SCREENER_TRAINER_SUCCESS = 'SCREENER_TRAINER_SUCCESS'
export const SCREENER_TRAINER_FAILURE = 'SCREENER_TRAINER_FAILURE'

export const SCREENER_TREE_INIT = 'SCREENER_TREE_INIT'
export const SCREENER_TREE_SUCCESS = 'SCREENER_TREE_SUCCESS'
export const SCREENER_TREE_FAILURE = 'SCREENER_TREE_FAILURE'

export const RESET_SCREENER_TREE_FETCH = 'RESET_SCREENER_TREE_FETCH'

export const COPY_SCREENER = 'COPY_SCREENER'
export const COPY_SCREENER_SUCCESS = 'COPY_SCREENER_SUCCESS'
export const COPY_SCREENER_FAILURE = 'COPY_SCREENER_FAILURE'

export const COPY_SHARED_SCREENER = 'COPY_SHARED_SCREENER'

export const GET_SCRIP_LOT_SIZE_INIT = 'GET_SCRIP_LOT_SIZE_INIT'
export const GET_SCRIP_LOT_SIZE_SUCCESS = 'GET_SCRIP_LOT_SIZE_SUCCESS'
export const GET_SCRIP_LOT_SIZE_FAILURE = 'GET_SCRIP_LOT_SIZE_FAILURE'
