import {
  GET_SCREENS_INIT,
  GET_SCREENS_SUCCESS,
  GET_SCREENS_FAILURE,
  DELETE_SCREENS_INIT,
  DELETE_SCREENS_SUCCESS,
  DELETE_SCREENS_FAILURE,
  POPULAR_SCREENS_SUCCESS,
  POPULAR_SCREENS_FAILURE,
  POPULAR_SCREENS_INIT,
  MARK_FAVORITE_INIT,
  MARK_FAVORITE_SUCCESS,
  MARK_FAVORITE_FAILURE,
  GET_FAVORITE_INIT,
  GET_FAVORITE_SUCCESS,
  GET_FAVORITE_FAILURE,
  GET_ALERT_SCREENS_INIT,
  GET_ALERT_SCREENS_SUCCESS,
  GET_ALERT_SCREENS_FAILURE,
  SET_ALERT_SCREENS_INIT,
  SET_ALERT_SCREENS_SUCCESS,
  SET_ALERT_SCREENS_FAILURE,
  STOP_ALERT_SCREENS_INIT,
  STOP_ALERT_SCREENS_SUCCESS,
  STOP_ALERT_SCREENS_FAILURE,
  GET_ALERT_HISTORY_INIT,
  GET_ALERT_HISTORY_SUCCESS,
  GET_ALERT_HISTORY_FAILURE,

  SEARCH_SCANNER_INIT,
  SEARCH_SCANNER_SUCCESS,
  SEARCH_SCANNER_FAILURE,
  CLEAR_SEARCH_SCANNER_RESP,
  FETCH_SCANNER_CATEGORY,
  FETCH_SCANNER_CATEGORY_SUCCESS,
  FETCH_SCANNER_CATEGORY_FAILURE,
  UPDATE_SORT_ON,

  FETCH_SCANNERS_STOCKS,
  FETCH_SCANNER_STOCKS_SUCCESS,
  FETCH_SCANNERS_STOCKS_FAILURE,

  UPDATE_ALERT_SCREENS_STOCKS,
  GENERATE_SCANNER_SHARE_LINK,
  GENERATE_SCANNER_SHARE_LINK_SUCCESS,
  GENERATE_SCANNER_SHARE_LINK_FAILURE,
} from './actionTypes'

export function getScreensSuccess(data, append) {
  return {
    type: GET_SCREENS_SUCCESS,
    data,
    append,
  }
}

export function getScreensFailure(error) {
  return {
    type: GET_SCREENS_FAILURE,
    error,
  }
}

export function getScreens(params, headers, append, isSearching) {
  let type
  if (isSearching) {
    type = SEARCH_SCANNER_INIT
  } else {
    type = GET_SCREENS_INIT
  }
  return {
    type,
    params,
    headers,
    append,
    isSearching,
  }
}

export function popularScreensSuccess(data) {
  return {
    type: POPULAR_SCREENS_SUCCESS,
    data,
  }
}

export function popularScreensFailure(error) {
  return {
    type: POPULAR_SCREENS_FAILURE,
    error,
  }
}

export function popularScreens(params, headers, isSearching) {
  let type
  if(isSearching) {
    type = SEARCH_SCANNER_INIT
  } else {
    type = POPULAR_SCREENS_INIT
  }
  return {
    type,
    params,
    headers,
    append: false,
    isSearching,
  }
}

export function markFavoriteSuccess(data) {
  return {
    type: MARK_FAVORITE_SUCCESS,
    data,
  }
}

export function markFavoriteFailure(error) {
  return {
    type: MARK_FAVORITE_FAILURE,
    error,
  }
}

export function markFavorite(params, headers) {
  return {
    type: MARK_FAVORITE_INIT,
    params,
    headers,
  }
}

export function getFavoriteSuccess(data) {
  return {
    type: GET_FAVORITE_SUCCESS,
    data,
  }
}

export function getFavoriteFailure(error) {
  return {
    type: GET_FAVORITE_FAILURE,
    error,
  }
}

export function getFavorite(params, headers) {
  return {
    type: GET_FAVORITE_INIT,
    params,
    headers,
  }
}

export function deleteScreensSuccess(data) {
  return {
    type: DELETE_SCREENS_SUCCESS,
    data,
  }
}

export function deleteScreensFailure(error) {
  return {
    type: DELETE_SCREENS_FAILURE,
    error,
  }
}

export function deleteScreens(params, headers) {
  return {
    type: DELETE_SCREENS_INIT,
    params,
    headers,
  }
}

export function getAlertScreens(params, dataExists, filters) {
  return {
    type: GET_ALERT_SCREENS_INIT,
    params,
    dataExists,
    filters,
  }
}

export function getAlertScreensSuccess(data, filters) {
  return {
    type: GET_ALERT_SCREENS_SUCCESS,
    data,
    filters,
  }
}

export function getAlertScreensFailure(error) {
  return {
    type: GET_ALERT_SCREENS_FAILURE,
    error,
  }
}

export function setAlertScreens(params, headers) {
  return {
    type: SET_ALERT_SCREENS_INIT,
    params,
    headers,
  }
}

export function setAlertScreensSuccess(data) {
  return {
    type: SET_ALERT_SCREENS_SUCCESS,
    data,
  }
}

export function setAlertScreensFailure(error) {
  return {
    type: SET_ALERT_SCREENS_FAILURE,
    error,
  }
}

export function stopAlertScreens(params, headers) {
  return {
    type: STOP_ALERT_SCREENS_INIT,
    params,
    headers,
  }
}

export function stopAlertScreensSuccess(data) {
  return {
    type: STOP_ALERT_SCREENS_SUCCESS,
    data,
  }
}

export function stopAlertScreensFailure(error) {
  return {
    type: STOP_ALERT_SCREENS_FAILURE,
    error,
  }
}

export function getAlertHistory(params, headers) {
  return {
    type: GET_ALERT_HISTORY_INIT,
    params,
    headers,
  }
}

export function getAlertHistorySuccess(data) {
  return {
    type: GET_ALERT_HISTORY_SUCCESS,
    data,
  }
}

export function getAlertHistoryFailure(error) {
  return {
    type: GET_ALERT_HISTORY_FAILURE,
    error,
  }
}

export function searchScanner(params, headers, append) {
  return {
    type: SEARCH_SCANNER_INIT,
    params,
    headers,
    append,
  }
}

export function searchScannerSuccess(data, append, isCategorised) {
  return {
    type: SEARCH_SCANNER_SUCCESS,
    data,
    append,
    isCategorised,
  }
}

export function searchScannerFailure() {
  return {
    type: SEARCH_SCANNER_FAILURE,
  }
}

export function clearSearchScannerResp() {
  return{
    type: CLEAR_SEARCH_SCANNER_RESP,
  }
}

export function fetchScannerCategories() {
  return {
    type: FETCH_SCANNER_CATEGORY,
  }
}

export function fetchScannerCategoriesSuccess(data) {
  return {
    type: FETCH_SCANNER_CATEGORY_SUCCESS,
    data,
  }
}

export function fetchScannerCategoriesFailure(error) {
  return {
    type: FETCH_SCANNER_CATEGORY_FAILURE,
    error,
  }
}

export function updateSortOn(sortOn, idKey) {
  return {
    type: UPDATE_SORT_ON,
    sortOn,
    idKey,
  }
}

export function fetchScannersStocks(params) {
  return {
    type: FETCH_SCANNERS_STOCKS,
    params,
  }
}

export function fetchScannersStocksSuccess({ idKey, id, data }) {
  return {
    type: FETCH_SCANNER_STOCKS_SUCCESS,
    id,
    idKey,
    data,
  }
}

export function fetchScannersStocksFailure(error) {
  return {
    type: FETCH_SCANNERS_STOCKS_FAILURE,
    error,
  }
}

export function updateAlertScreenStocks(params) {
  return {
    type: UPDATE_ALERT_SCREENS_STOCKS,
    params,
  }
}

export function generateScannerShareLink(params, headers) {
  return {
    type: GENERATE_SCANNER_SHARE_LINK,
    params,
    headers,
  }
}

export function generateScannerShareLinkSuccess(data) {
  return {
    type: GENERATE_SCANNER_SHARE_LINK_SUCCESS,
    data,
  }
}

export function generateScannerShareLinkFailure(error) {
  return {
    type: GENERATE_SCANNER_SHARE_LINK_FAILURE,
    error,
  }
}
