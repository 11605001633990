import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

// import isEqual from 'lodash/isEqual'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import Color from 'color'
import classnames from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'

import CustomText from './CustomText'
import Icon from './Icon'
import Dot from './Dot'

import {
  normalize, COLORS, SPACING, ICONS, ASSETS,
  theme,
} from '../Theme'
import { SCANNER_CATEGORY_ID } from '../containers/Scans/scanUtils'
import { getLocalDate, getProgressColor } from '../utils/common' //
import { pathname_mapping } from '../utils/navigationConstant'

export const SCANNER_CARD_WIDTH = 212
export const SCANNER_CARD_HEIGHT = 125
export const SCANNER_CARD_RADIUS = 10

const { Scanner: ScannerSvg } = ASSETS

const ScannerCard = React.memo(({
  nameContainerStyle,
  nameStyle,
  dateContainerStyle,
  dateStyle,
  matchContainerStyle,
  matchStyle,
  bottomContainerStyle,
  universeStyle,
  intervalContainerStyle,
  intervalStyle,

  screener_uuid,
  showFavourite,
  screener_name,
  percent_complete,
  updatedAt,
  periodicity,
  matchCount,
  universe,
  timeFrame,
  isLive,
  idKey,

  isDark,
  isComplete: isScannerComplete,
  isFavourite,

  wrapperStyle,
  containerStyle,
  onCardPress,
  paramsToSend,
  horizontal,
}) => {
  const styles = stylesheet()
  const renderCardInfo = () => {
    const date = idKey === SCANNER_CATEGORY_ID.LIVE_SCANS ? getLocalDate({ dateObj: updatedAt, offset: '-5:30', format: 'DD MMM YYYY' })
      : dayjs(updatedAt).format('DD MMM YYYY')
    const time = idKey === SCANNER_CATEGORY_ID.LIVE_SCANS ? getLocalDate({ dateObj: updatedAt, offset: '-5:30', format: 'HH:mm:ss' })
      : dayjs(updatedAt).format('HH:mm:ss')
    const matchCountString = `${matchCount} ${matchCount > 1 ? 'Matches' : 'Match'}`
    const hasPeriodicity = periodicity ? periodicity.length > 0 : false

    const isComplete = isLive || isScannerComplete
    const { live, sample } = paramsToSend
    let tab = 'scan_details'
    if (sample) {
      tab = 'scan_details_discover'
    } else if (live) {
      tab = 'scan_details_live'
    }
    let search = `?id=${btoa(screener_uuid)}&tab=${tab}`
    search += sample ? `&sample=${btoa(sample)}` : ''
    let pathname = `${pathname_mapping.Scanners}${search}`
    if(!isComplete) {
      search = `?passedId=${btoa(screener_uuid)}&sample=${btoa(sample)}`
      pathname = `${pathname_mapping.NewScanner}${search}`
    }
    const { progressColor } = getProgressColor(percent_complete, isDark)
    const unfilledColor = Color(progressColor).alpha(isDark ? 1 : 0.22)[isDark ? 'fade' : 'lighten'](isDark ? 0.8 : 0).string()

    let favIconColor
    let favIconName
    if (isDark) {
      favIconColor = isFavourite ? COLORS.ORANGE : COLORS.BLACK_60
      favIconName = ICONS.FAVOURITE
    } else {
      favIconColor = isFavourite ? COLORS.ORANGE : COLORS.GREY_100
      favIconName = isFavourite ? ICONS.FAVOURITE : ICONS.UN_FAVOURITE
    }
    const universeStyles = universe === 'basket' ? { textTransform: 'capitalize' }
      : { textTransform: 'uppercase' }
    return (
      <>
        <div className={classnames(styles.nameContainer, nameContainerStyle)}>
          <Link
            to={pathname}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={e => e.stopPropagation()}
          >
            <CustomText
              weight="medium"
              style={{ textTransform: 'capitalize', ...nameStyle }}
              ellipsis
            >
              {!isComplete ? <Icon name={ICONS.EDIT} color={theme.linkColor} size={9} /> : null}
              {!isComplete ? `  ${screener_name}` : screener_name}
            </CustomText>
          </Link>
          {showFavourite && (

            <Icon
              name={favIconName}
              color={favIconColor}
              style={{ paddingHorizontal: 5, paddingLeft: 10, paddingVertical: 2 }}
              size={12}
            //   onPress={this.toggleFavorite}
            />
          )}
        </div>

        <div className={classnames(styles.dateContainer, dateContainerStyle)}>
          <CustomText size="small" className={classnames(styles.dateStyle, dateStyle)}>{date}</CustomText>
          {isLive && (
            <CustomText size="small_0" color="textSecondary" className={classnames(styles.dateStyle, dateStyle)}>
              {time}
            </CustomText>
          )}
          {hasPeriodicity && <CustomText size="small" color="grey_100" className={dateStyle}>{`(${periodicity})`}</CustomText>}
        </div>
        <div style={{ flex: 1 }} />
        {isComplete ? (
          <div className={classnames(styles.matchContainer, matchContainerStyle)}>
            <Dot color={COLORS.ORANGE} size={5} />
            <CustomText weight="medium" className={classnames(styles.matchStyle, matchStyle)}>{matchCountString}</CustomText>
          </div>
        ) : null}

        {isComplete ? (
          <div className={classnames(styles.bottomContainer, bottomContainerStyle)}>
            <CustomText size="small" className={classnames(styles.universeStyle, universeStyle)} style={universeStyles} ellipsis>{universe}</CustomText>

            <div className={classnames(styles.intervalContainer, intervalContainerStyle)}>
              <CustomText size="small" color={isDark ? 'PURPLE_500' : 'blue'} className={(styles.intervalStyle, intervalStyle)}>{timeFrame}</CustomText>
            </div>
            {isLive ? <img alt="live_icon" src={ScannerSvg} width={16} height={16} /> : null}
            <div />

          </div>
        ) : null}

        {!isComplete ? (
          <div className={styles.incompleteContainer}>
            <div style={{ marginRight: SPACING.SPACE_20 }}>
              <div className={styles.matchContainerPlaceholder} />
              <div className={classnames(styles.bottomContainer, bottomContainerStyle)}>
                <div className={styles.bottomContainerPlaceholder} />
              </div>
            </div>

            <div className={styles.pctSection}>
              <CircularProgressbarWithChildren
                styles={buildStyles({
                  pathColor: progressColor,
                  trailColor: unfilledColor,
                })}
                strokeWidth={6}
                value={percent_complete}
              >
                <CustomText className={styles.progressText} style={{ backgroundColor: unfilledColor }} size="small_0" weight="medium" color={progressColor}>{`${percent_complete}%`}</CustomText>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        )
          : null}

      </>
    )
  }

  const onPress = () => {
    if (!onCardPress) return
    onCardPress(paramsToSend)
  }
  return (
    <div
      className={classnames(
        styles.wrapper,
        !horizontal && styles.wrapperDetailed,
        wrapperStyle,
      )}
      id="tour_scan_1"
    >
      <button
        id="ptCardClick"
        type="button"
        className={classnames(
          styles.container, !horizontal && styles.containerDetailed, containerStyle,
        )}
        onClick={onPress}
      >
        {renderCardInfo()}
      </button>
    </div>
  )
})

const stylesheet = makeStyles({
  wrapper: {
    display: 'inline-flex',
    margin: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
  container: {
    width: SCANNER_CARD_WIDTH,
    height: SCANNER_CARD_HEIGHT,
    padding: `${SPACING.SPACE_12} ${SPACING.SPACE_16}`,
    borderRadius: SCANNER_CARD_RADIUS,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.bgPrimary,
  },
  nameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    // width: SCANNER_CARD_WIDTH - 24,
  },
  dateContainer: {
    flexDirection: 'row',
    marginTop: SPACING.SPACE_4,
    alignItems: 'baseline',
    display: 'flex',
  },
  dateStyle: {
    marginRight: SPACING.SPACE_4,
  },
  incompleteContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginTop: SPACING.SPACE_4,
    // borderWidth: 1,
  },
  perctText: {
    color: theme.text,
  },
  matchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_8,
  },
  matchStyle: {
    marginLeft: SPACING.SPACE_4,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  universeStyle: {
    backgroundColor: theme.disabled,
    marginRight: SPACING.SPACE_10,
    borderRadius: 4,
    padding: `0 ${SPACING.SPACE_8} 0 ${SPACING.SPACE_6}`,
    maxWidth: '35%',
  },
  intervalContainer: {
    backgroundColor: theme.scannerTimeIntervalBg,
    marginRight: SPACING.SPACE_10,
    borderRadius: 4,
    padding: `0 ${SPACING.SPACE_8} 0 ${SPACING.SPACE_6}`,
  },
  intervalStyle: {},

  matchContainerPlaceholder: {
    height: 12,
    marginBottom: SPACING.SPACE_4,
    borderRadius: 8,
    backgroundColor: theme.cardPlaceHolderColor,
    width: normalize(46),
  },
  bottomContainerPlaceholder: {
    height: 12,
    marginTop: SPACING.SPACE_4,
    borderRadius: 8,
    backgroundColor: theme.cardPlaceHolderColor,
    width: normalize(64),
  },
  pctSection: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressText: {
    height: 'calc(100% - 12px)',
    borderRadius: '50%',
    width: 'calc(100% - 12px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media only screen and (max-width: 786px)': {
    wrapperDetailed: {
      margin: `${SPACING.SPACE_10} 0`,
      width: '100%',
    },
    containerDetailed: {
      width: '100%',
    },
    nameContainer: {
      width: '100%',
    },
  },
})

ScannerCard.defaultProps = {
  wrapperStyle: '',
  containerStyle: '',
  nameContainerStyle: '',
  nameStyle: '',
  dateContainerStyle: '',
  dateStyle: '',
  matchContainerStyle: '',
  matchStyle: '',
  bottomContainerStyle: '',
  universeStyle: '',
  intervalContainerStyle: '',
  intervalStyle: '',

  matchCount: 0,
  periodicity: '',
  timeFrame: '',
}

ScannerCard.propTypes = {
  wrapperStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  nameContainerStyle: PropTypes.string,
  nameStyle: PropTypes.string,
  dateContainerStyle: PropTypes.string,
  dateStyle: PropTypes.string,
  matchContainerStyle: PropTypes.string,
  matchStyle: PropTypes.string,
  bottomContainerStyle: PropTypes.string,
  universeStyle: PropTypes.string,
  intervalContainerStyle: PropTypes.string,
  intervalStyle: PropTypes.string,

  screener_name: PropTypes.string.isRequired,
  updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  periodicity: PropTypes.string,
  matchCount: PropTypes.number,
  universe: PropTypes.string.isRequired,
  timeFrame: PropTypes.string,
}

export default ScannerCard
