import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import HomePageNew from './HomePageNew'
import { toggleLoginModal } from '../../../containers/Auth/actions'

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAuthModal: state => dispatch(toggleLoginModal(state)),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(HomePageNew))
