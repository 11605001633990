import {
  combineReducers, applyMiddleware, createStore, compose,
} from 'redux'
import createSagaMiddleware from 'redux-saga'

/* eslint-disable-next-line import/no-cycle */
import rootSaga from './rootSaga'
import auth from './containers/Auth/Auth-reducer'
import market from './containers/Marketwatch/Marketwatch-reducer'
import instruments from './containers/Instruments/Instruments-reducer'
import account from './containers/Account/Account-reducer'
import portfolio from './containers/Portfolio/Portfolio-reducer'
import common from './containers/Common/Common-reducer'
import orderbook from './containers/OrderBook/Orderbook-reducer'
import notification from './containers/Notifications/Notifications-reducer'
import dashboard from './containers/Dashboard/DashBoard-reducer'
import marketplace from './containers/MarketPlace/MarketPlace-reducer'
import scans from './containers/Scans/Scans-reducer'
import algos from './containers/Algos/Algos-reducer'
import deployed from './containers/Deployed/Deployed-reducer'
import screener from './containers/Screener/Screener-reducer'
import create from './containers/Create/Create-reducer'
import backtests from './containers/Backtests/Backtests-reducer'
import technicals from './containers/Technicals/Technicals-reducer'
import billing from './containers/Billing/Billing-reducer'
// import pnl from './containers/Pnl/Pnl-reducer'
// import referral from './containers/Referral/Referral-reducer'
import payment from './containers/Payment/Payment-reducer'
import paymentPages from './containers/PaymentPages/PaymentPages-reducer'

const appReducer = combineReducers({
  auth,
  market,
  instruments,
  account,
  common,
  portfolio,
  orderbook,
  notification,
  dashboard,
  marketplace,
  algos,
  deployed,
  screener,
  scans,
  create,
  backtests,
  technicals,
  // pnl,
  billing,
  // referral,
  payment,
  paymentPages,
})

let enhancer

const sagaMiddleware = createSagaMiddleware()

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(sagaMiddleware)
} else {
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      maxAge: 25,
    })
    : compose
  enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
}

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_USER_DETAILS') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
const store = createStore(rootReducer, enhancer)

sagaMiddleware.run(rootSaga)

export default store
