import React, { PureComponent } from 'react'
import isEmpty from 'lodash/isEmpty'

import IndicatorFinder from './IndicatorFinder'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import CustomModal from '../../UI/CustomModal'
import InputOptions from '../Screener/InputOptions'

import {
  ICONS, SPACING, theme,
} from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import { showSnackbar } from '../../UI/Snackbar'
import IndicatorSectionView from './IndicatorSectionView'

const SECTION_TYPE = {
  INDICATOR: 'indicator',
  COMPARATOR: 'comparator',
  COMPARE_INDICATOR: 'compareIndicator',
}

class IndicatorSection extends PureComponent {
  constructor(props) {
    super(props)
    const {
      indicatorState, indicatorsList,
    } = props
    let isEditable = false
    let comparatorEditable = false
    let showOthers = true
    let initialState = {}
    this.updatedActiveItem = {}
    if (indicatorState && indicatorState.indicatorItem) {
      const {
        // andor,
        indicatorItem,
        compareIndicatorItem,
        comparatorItem = {},
        indicator,
        compareIndicator,
        comparator = '',
        indicatorDetails,
        compareIndicatorDetails,
        comparatorDetails = {},
        comparatorValid = false,
        compareIndicatorValid = false,
        indicatorValid = false,
      } = indicatorState
      this.activeIndicator = indicatorItem
      this.activeCompareIndicator = compareIndicatorItem
      this.activeComparator = comparatorItem
      const allowedComparisons = indicatorItem.allowed_comparison
        || indicatorItem.allowed_comparision || []
      let f_comparatorDetails = comparatorDetails
      if (!comparatorItem.syntax) {
        f_comparatorDetails = this.fillEmptyComparator(
          comparator,
          comparatorDetails,
        )
      }
      this.allowedComparatorIndicators = Object.keys(indicatorsList).filter(
        indi => allowedComparisons.some(
          allowedGrp => allowedGrp === indicatorsList[indi].function_group,
        ),
      )
      if (compareIndicator === 'N/A') {
        showOthers = false
      }
      isEditable = !!indicatorValid
      comparatorEditable = !!indicatorValid

      initialState = {
        indicatorDetails,
        compareIndicatorDetails,
        comparator,
        comparatorDetails: f_comparatorDetails,
        indicator,
        compareIndicator,
        indicatorValid,
        compareIndicatorValid,
        comparatorValid,
      }
    }
    this.state = {
      isEditable,
      comparatorEditable,
      showOthers,
      indicatorDetails: {},
      compareIndicatorDetails: {},
      comparator: '',
      comparatorDetails: {},
      indicator: '',
      compareIndicator: '',
      indicatorValid: false,
      compareIndicatorValid: false,
      comparatorValid: false,
      ...initialState,

      showIndicator: false,
      showComparator: false,
      showCompareIndicator: false,
      showDetails: false,

      sectionType: SECTION_TYPE.INDICATOR,
      showInputOptions: false,
    }
    this.detailsKey = null
    this.selected_candle_interval = null
    this.tree = {}
    this.keysToHide = ['signal_candle', 'trade_candle']
  }

  togglePopup = (type) => {
    this.setState(prevState => ({ [type]: !prevState[type] }))
  }

  fillEmptyComparator = (comparator, comparatorDetails) => {
    const { comparatorsList } = this.props
    const nameRegx = new RegExp(comparator, 'i')
    const comparatorsArr = Object.values(comparatorsList)
    for (let i = 0; i < comparatorsArr.length; i++) {
      const tempItem = comparatorsArr[i]
      if (nameRegx.test(tempItem.name)) {
        this.activeComparator = tempItem
        tempItem.params.forEach((param) => {
          comparatorDetails[param[0]] = param[1]
        })
        break
      }
    }
    return comparatorDetails
  };

  setMinCandleInterval = (interval) => {
    if (!this.selected_candle_interval && interval) {
      this.selected_candle_interval = interval
    } else if (this.selected_candle_interval && interval) {
      if (interval > this.selected_candle_interval) {
        this.selected_candle_interval = interval
      }
    }
  };

  toggleAutoComplete = (type) => {
    this.setState((prevState) => {
      return { [type]: !prevState[type] }
    })
  };

  indicatorSelect = (item, details) => {
    const {
      indicatorsList, exit, assist, position, trainerModel = {}, createStrategy,
      indicatorState,
    } = this.props
    this.activeIndicator = item
    const allowedComparisons = item.allowed_comparison || item.allowed_comparision || []
    this.allowedComparatorIndicators = Object.keys(indicatorsList).filter(
      indicator => allowedComparisons.some(
        allowedGrp => allowedGrp === indicatorsList[indicator].function_group,
      ),
    )
    const extraChanges = { showOthers: true }
    if (allowedComparisons.length === 0) {
      extraChanges.showOthers = false
    }
    const indicatorType = exit ? 'exitIndicators' : 'entryIndicators'
    const side = position
    const key = item.key || item.class

    // as here we are using trainer model of createAdvance (below if condition is used in screener)
    if (assist && !createStrategy && item.key && trainerModel[item.key]
      && trainerModel[item.key][0][0]) {
      const { conditions: assistConditions } = trainerModel[item.key][0][0]
      const [indictr, comparatorItem, compareIndicatorItem] = assistConditions

      this.activeCompareIndicator = compareIndicatorItem.indicatorDetails || {}
      if (this.activeCompareIndicator) {
        // eslint-disable-next-line max-len
        this.activeCompareIndicator.allowed_comparision = this.activeCompareIndicator.allowed_comparison
      }
      this.activeComparator = comparatorItem.comparator || {}
      // if syntax is empty then name will not appear
      const indicatorName = this.nameGenerator(indictr.indicatorDetails.syntax, indictr.details)
      const compareIndicatorName = this.nameGenerator(
        compareIndicatorItem.indicatorDetails.syntax, compareIndicatorItem.details,
      )
      const comparatorName = comparatorItem.indicatorDetails.syntax
        ? this.nameGenerator(comparatorItem.indicatorDetails.syntax, comparatorItem.details)
        : comparatorItem.name
      const { id } = indicatorState
      const indicatorValid = !!indicatorName
      const compareIndicatorValid = !!compareIndicatorName
      const comparatorValid = !!comparatorName
      this.setState({
        indicator: indicatorName,
        compareIndicator: compareIndicatorName,
        // andor,
        comparator: comparatorName,
        indicatorValid,
        compareIndicatorValid,
        comparatorValid,
        comparatorDetails: comparatorItem.details,
        indicatorDetails: indictr.details,
        compareIndicatorDetails: compareIndicatorItem.details,
        isEditable: true,
        comparatorEditable: true,
        id,
        disabled: false,
        showDetails: false,
        showInputOptions: false,
        showIndicator: false,
        ...extraChanges,
      })
      this.props.addIndicatorDetails({
        indicator: indicatorName,
        compareIndicator: compareIndicatorName,
        comparator: comparatorName,
        // andor,
        indicatorItem: this.activeIndicator,
        indicatorValid,
        indicatorDetails: indictr.details,

        comparatorItem: this.activeComparator || {},
        comparatorValid,
        comparatorDetails: comparatorItem.details,

        compareIndicatorItem: this.activeCompareIndicator || {},
        compareIndicatorValid,
        compareIndicatorDetails: compareIndicatorItem.details,
        id,
        assisted: true,
      })
      return
    }
    // assist is on

    if (assist) {
      if (side
        && trainerModel[key]
        && trainerModel[key][side]
        && trainerModel[key][side][0]
        && trainerModel[key][side][0][indicatorType]
        && trainerModel[key][side][0][indicatorType].length
      ) {
        const {
          indicator,
          comparator: cprtr,
          comparatorItem = {},
          compareIndicator,
          compareIndicatorDetails,
          compareIndicatorItem,
          indicatorDetails,
          comparatorDetails = {},
        } = trainerModel[key][side][0][indicatorType][0]
        this.activeCompareIndicator = compareIndicatorItem
        this.activeComparator = comparatorItem
        let f_comparatorDetails = comparatorDetails
        if (!comparatorItem.syntax) {
          f_comparatorDetails = this.fillEmptyComparator(
            cprtr,
            comparatorDetails,
          )
        }
        this.setState({
          indicator,
          isEditable: true,
          comparatorEditable: true,
          indicatorValid: true,
          // andor: 'and',
          comparator: cprtr,
          comparatorValid: true,
          comparatorItem,
          comparatorDetails: f_comparatorDetails,
          compareIndicator,
          compareIndicatorDetails,
          indicatorDetails,
          compareIndicatorValid: true,
          showDetails: false,
          showInputOptions: false,
          showIndicator: false,
          ...extraChanges,
        })
        const { id } = this.props.indicatorState
        this.props.addIndicatorDetails({
          indicator,
          compareIndicator,
          comparator: cprtr,
          id,
          // andor: 'and',
          indicatorItem: this.activeIndicator,
          compareIndicatorItem: this.activeCompareIndicator || {},
          comparatorItem: this.activeComparator || {},
          indicatorValid: true,
          compareIndicatorValid: true,
          comparatorValid: true,
          indicatorDetails,
          compareIndicatorDetails,
          comparatorDetails: f_comparatorDetails,
          selected_candle_interval: this.selected_candle_interval,
          assisted: true,
        })
        return
      }
      // screener tree is used here
      if (!createStrategy
        && trainerModel[key]
        && trainerModel[key][0]
        && trainerModel[key][0][0]
        && trainerModel[key][0][0].conditions.length
      ) {
        const [
          indicatorItem, comparatorItem, compareIndicatorItem,
        ] = trainerModel[key][0][0].conditions
        this.activeCompareIndicator = compareIndicatorItem
        this.activeComparator = comparatorItem
        this.activeIndicator = indicatorItem
        const { details: indicatorDetails, name: indicator } = indicatorItem
        const { details: comparatorDetails, name: cprtr } = comparatorItem
        const { details: compareIndicatorDetails, name: compareIndicator } = compareIndicatorItem
        let f_comparatorDetails = comparatorDetails
        if (!comparatorItem.syntax) {
          f_comparatorDetails = this.fillEmptyComparator(
            cprtr,
            comparatorDetails,
          )
        }
        this.setState({
          indicator,
          indicatorItem,
          isEditable: true,
          comparatorEditable: true,
          indicatorValid: true,
          // andor: 'and',
          comparator: cprtr,
          comparatorValid: true,
          comparatorItem,
          comparatorDetails: f_comparatorDetails,
          compareIndicator,
          compareIndicatorDetails,
          indicatorDetails,
          compareIndicatorValid: true,
          showDetails: false,
          showInputOptions: false,
          showIndicator: false,
          ...extraChanges,
        })
        const { id } = this.props.indicatorState
        this.props.addIndicatorDetails({
          indicator,
          compareIndicator,
          comparator: cprtr,
          id,
          // andor: 'and',
          indicatorItem: this.activeIndicator,
          compareIndicatorItem: this.activeCompareIndicator || {},
          comparatorItem: this.activeComparator || {},
          indicatorValid: true,
          compareIndicatorValid: true,
          comparatorValid: true,
          indicatorDetails,
          compareIndicatorDetails,
          comparatorDetails: f_comparatorDetails,
          selected_candle_interval: this.selected_candle_interval,
          assisted: true,
        })
        return
      }
    }
    if (item.params.length === 0) {
      this.setState({
        indicator: item.syntax,
        isEditable: true,
        comparatorEditable: true,
        compareIndicator: '',
        indicatorValid: true,
        showDetails: false,
        showInputOptions: false,
        showIndicator: false,

        compareIndicatorItem: {},
        compareIndicatorDetails: {},
        compareIndicatorValid: false,
        ...extraChanges,
      })
      if (this.allowedComparatorIndicators.length === 0) {
        const { id } = this.props.indicatorState
        this.props.addIndicatorDetails({
          indicator: item.syntax,
          compareIndicator: 'N/A',
          comparator: 'N/A',
          id,
          // andor: this.state.andor,
          indicatorItem: item,
          indicatorValid: true,
          selected_candle_interval: this.selected_candle_interval,
        })
      } else {
        const { id } = this.props.indicatorState
        this.props.addIndicatorDetails({
          id,
          indicator: item.syntax,
          isEditable: true,
          comparatorEditable: true,
          compareIndicator: '',
          indicatorValid: true,
          compareIndicatorItem: {},
          compareIndicatorDetails: {},
          compareIndicatorValid: false,
          selected_candle_interval: this.selected_candle_interval,
          ...extraChanges,
        })
      }
    } else if (details) {
      this.saveDetails(details, null, {
        ...extraChanges,
        indicatorItem: this.activeIndicator,
      })
    } else {
      this.setState({
        ...extraChanges,
        showDetails: true,
        showIndicator: true,
        showInputOptions: false,
        compareIndicatorItem: {},
        compareIndicatorDetails: {},
        compareIndicatorValid: false,
      })
      // this.togglePopup('showDetails')
    }
  };

  compareIndicatorSelect = (item, details) => {
    const {
      comparator,
      // andor,
      indicator,
      comparatorValid,
    } = this.state
    this.activeCompareIndicator = item
    const extraChanges = {
      compareIndicator: item.name,
      showInputOptions: false,
      compareIndicatorDetails: {},
    }
    if (details) {
      this.saveCompareIndicatorDetails(details, null, extraChanges)
    } else if (item.params.length) {
      this.setState({ ...extraChanges, showCompareIndicator: true, showDetails: true })
    } else {
      const { id } = this.props.indicatorState
      this.props.addIndicatorDetails({
        indicator,
        compareIndicator: item.name,
        comparator,
        id,
        // andor,
        indicatorItem: this.activeIndicator,
        compareIndicatorItem: this.activeCompareIndicator,
        indicatorValid: true,
        compareIndicatorValid: true,
        comparatorValid,
        selected_candle_interval: this.selected_candle_interval,
      })
      this.setState({
        ...extraChanges,
        compareIndicator: item.syntax,
        compareIndicatorValid: true,
        showDetails: false,
        showCompareIndicator: false,
      })
    }
  };

  comparatorSelect = (item, details) => {
    this.activeComparator = item
    const {
      indicator,
      compareIndicator,
      // andor,
      compareIndicatorValid,
    } = this.state
    const extraChanges = {
      comparator: item.name,
      comparatorDetails: {},
      showInputOptions: false,
    }
    if (details) {
      this.saveComparatorDetails(details, null, extraChanges)
    } else if (item.params.length) {
      this.setState({ ...extraChanges, showComparator: true, showDetails: true })
    } else {
      this.setState({
        ...extraChanges, comparatorValid: true, showDetails: false, showComparator: false,
      })
      const { id } = this.props.indicatorState
      this.props.addIndicatorDetails({
        indicator,
        compareIndicator: compareIndicator || 'Indicator',
        comparator: item.name,
        id,
        // andor,
        indicatorItem: this.activeIndicator,
        compareIndicatorItem: this.activeCompareIndicator || {},
        indicatorValid: true,
        comparatorValid: true,
        compareIndicatorValid,
        selected_candle_interval: this.selected_candle_interval,
      })
    }
  };

  inputChangeHandler = (data, type) => {
    this.setState({ [type]: data })
  };

  togglePopup = (popup) => {
    this.setState(prevState => ({ [popup]: !prevState[popup] }))
  };

  saveDetails = (details, selected_candle, extraChanges = {}) => {
    const {
      compareIndicator,
      comparator,
      // andor,
      compareIndicatorValid,
      comparatorValid,
      comparatorDetails,
    } = this.state
    this.setMinCandleInterval(selected_candle)
    const { syntax } = this.activeIndicator
    const finalName = this.nameGenerator(syntax, details)
    this.setState({
      ...extraChanges,
      indicator: finalName,
      isEditable: true,
      comparatorEditable: true,
      indicatorValid: true,
      indicatorDetails: details,
    })
    const { id } = this.props.indicatorState
    let cName = compareIndicator || 'Indicator'
    let comparatorName = comparator || 'Comparator'
    const allowedComparisons = this.activeIndicator.allowed_comparison
      || this.activeIndicator.allowed_comparision || []
    if (allowedComparisons.length === 0) {
      cName = 'N/A'
      comparatorName = 'N/A'
    }
    this.props.addIndicatorDetails({
      indicator: finalName,
      compareIndicator: cName,
      comparator: comparatorName,
      id,
      // andor,
      indicatorItem: this.activeIndicator,
      comparatorItem: this.activeComparator || {},
      comparatorValid,
      comparatorDetails,
      indicatorValid: true,
      compareIndicatorItem: this.activeCompareIndicator || {},
      compareIndicatorValid,
      indicatorDetails: details,
      selected_candle_interval: this.selected_candle_interval,
    })
    this.onDismiss()
  };

  saveCompareIndicatorDetails = (details, selected_candle, extraChanges = {}) => {
    const {
      indicator,
      comparator,
      // andor,
      indicatorDetails,
      comparatorValid,
      comparatorDetails,
    } = this.state
    this.setMinCandleInterval(selected_candle)
    const { syntax } = this.activeCompareIndicator
    const finalName = this.nameGenerator(syntax, details)
    this.setState({
      ...extraChanges,
      compareIndicator: finalName,
      compareIndicatorValid: true,
      compareIndicatorDetails: details,
      showCompareIndicator: false,
      showDetails: false,
    })
    const { id } = this.props.indicatorState
    this.props.addIndicatorDetails({
      indicator,
      compareIndicator: finalName,
      comparator,
      id,
      // andor,
      indicatorItem: this.activeIndicator,
      compareIndicatorItem: this.activeCompareIndicator || {},
      comparatorItem: this.activeComparator || {},
      comparatorDetails,
      comparatorValid,
      indicatorValid: true,
      compareIndicatorValid: true,
      indicatorDetails,
      compareIndicatorDetails: details,
      selected_candle_interval: this.selected_candle_interval,
    })
  };

  saveComparatorDetails = (details, candle, extraChanges = {}) => {
    const {
      indicator,
      indicatorDetails,
      // andor,
      compareIndicatorValid,
      compareIndicatorDetails,
      compareIndicator,
    } = this.state
    const { syntax } = this.activeComparator
    const finalName = this.nameGenerator(syntax, details)
    this.setState({
      ...extraChanges,
      comparator: finalName,
      comparatorValid: true,
      comparatorDetails: details,
      showDetails: false,
      showComparator: false,
    })
    const { id } = this.props.indicatorState
    this.props.addIndicatorDetails({
      indicator,
      indicatorItem: this.activeIndicator || {},
      indicatorDetails,
      indicatorValid: true,
      compareIndicator,
      compareIndicatorItem: this.activeCompareIndicator || {},
      compareIndicatorDetails,
      compareIndicatorValid,
      comparator: finalName,
      comparatorItem: this.activeComparator,
      comparatorDetails: details,
      id,
      comparatorValid: true,
      // andor,
      selected_candle_interval: this.selected_candle_interval,
    })
  }

  onEdit = () => {
    this.togglePopup('showDetails')
  }

  nameGenerator = (syntax = '', details) => {
    let f_syntax = syntax
    const regx = /[^<>]+(?=>)/g
    const params = syntax.match(regx)
    if (params) {
      const detailValues = params.map(param => details[param])
      for (let i = 0; i < params.length; i++) {
        f_syntax = f_syntax.replace(`<${params[i]}>`, detailValues[i])
      }
    }
    return f_syntax
  };

  onRemove = () => {
    const { sectionType } = this.state
    if (`${sectionType}Valid`) {
      if (sectionType === SECTION_TYPE.COMPARE_INDICATOR) {
        this.removeCompareIndicator()
      } else if (sectionType === SECTION_TYPE.COMPARATOR) {
        this.removeComparator()
      } else if (sectionType === SECTION_TYPE.INDICATOR) {
        this.removeIndicator()
      }
      this.onDismiss()
    } else {
      this.setState({ showDetails: false })
    }
  }

  removeCompareIndicator = () => {
    this.setState({
      compareIndicator: '',
      compareIndicatorValid: false,
      compareIndicatorItem: {},
    })
    const { id } = this.props.indicatorState
    this.props.addIndicatorDetails({
      id,
      // andor,
      compareIndicator: 'Indicator',
      compareIndicatorValid: false,
      compareIndicatorItem: {},
      compareIndicatorDetails: {},
      selected_candle_interval: this.selected_candle_interval,
    })
  }

  removeIndicator = () => {
    this.updatedActiveItem = {}
    this.setState({
      indicatorDetails: {},
      compareIndicatorDetails: {},
      indicatorValid: false,
      indicator: 'Indicator',
      compareIndicator: 'Indicator',
      comparator: 'Comparator',
      compareIndicatorValid: false,
      comparatorValid: false,
      comparatorDetails: {},
      showDetails: false,
      showComparator: false,
    })
    const { id } = this.props.indicatorState
    this.props.addIndicatorDetails({
      indicator: 'Indicator',
      compareIndicator: 'Indicator',
      comparator: 'Comparator',
      indicatorItem: {},
      indicatorValid: false,
      comparatorValid: false,
      id,
      // andor,
      compareIndicatorValid: false,
      compareIndicatorItem: {},
      compareIndicatorDetails: {},
      selected_candle_interval: this.selected_candle_interval,
    })
  }

  removeIndicatorHandler = () => {
    const {
      indicatorState: { id },
      removeIndicator,
      index,
    } = this.props
    removeIndicator(id, index)
  };

  removeComparator = () => {
    this.activeComparator = {}
    this.setState({
      comparatorValid: false,
      comparatorDetails: {},
      comparator: '',
    })
    const { indicatorState: { id }, addIndicatorDetails } = this.props
    addIndicatorDetails({
      comparator: '',
      id,
      // andor,
      comparatorValid: false,
      comparatorDetails: {},
      selected_candle_interval: this.selected_candle_interval,
    })
  }

  toggleSections = (type, isEditable) => {
    if (!isEditable) {
      showSnackbar('Select previous indicator details')
      return
    }
    const {
      indicatorsList, comparatorsList, funcGrpLength, treeFromFuncGrp,
    } = this.props
    const {
      indicatorValid, comparatorValid, compareIndicatorValid, indicatorDetails,
      comparatorDetails, compareIndicatorDetails,
    } = this.state
    switch (type) {
      case SECTION_TYPE.INDICATOR: {
        this.detailsKey = 'indicatorDetails'
        if (funcGrpLength) {
          this.tree = { [type]: treeFromFuncGrp }
        } else {
          this.tree = { [type]: indicatorsList }
        }
        // console.log(treeFromFuncGrp, indicatorsList, 'lol indicatorsList')
        const showDetails = Boolean(
          indicatorValid && indicatorDetails && !isEmpty(indicatorDetails),
        )
        const showIndicator = true
        const showInputOptions = false
        // if (indicatorValid && indicatorDetails && isEmpty(indicatorDetails)) {
        //   showIndicator = false
        //   showInputOptions = true
        // }
        this.setState({
          showIndicator, sectionType: type, showDetails, showInputOptions,
        })
        break
      }
      case SECTION_TYPE.COMPARATOR: {
        this.detailsKey = 'comparatorDetails'
        this.tree = { [type]: comparatorsList }
        const showDetails = Boolean(
          comparatorValid && comparatorDetails && !isEmpty(comparatorDetails),
        )
        let showComparator = true
        let showInputOptions = false
        if (comparatorValid && comparatorDetails && isEmpty(comparatorDetails)) {
          showComparator = false
          showInputOptions = true
        }
        this.setState({
          showComparator, sectionType: type, showDetails, showInputOptions,
        })
        break
      }
      case SECTION_TYPE.COMPARE_INDICATOR: {
        this.detailsKey = 'compareIndicatorDetails'
        if (funcGrpLength) {
          this.tree = { [SECTION_TYPE.INDICATOR]: treeFromFuncGrp }
        } else {
          this.tree = { [SECTION_TYPE.INDICATOR]: indicatorsList }
        }
        const showDetails = Boolean(
          compareIndicatorValid && compareIndicatorDetails && !isEmpty(compareIndicatorDetails),
        )
        let showCompareIndicator = true
        let showInputOptions = false
        if (compareIndicatorValid && compareIndicatorDetails && isEmpty(compareIndicatorDetails)) {
          showCompareIndicator = false
          showInputOptions = true
        }
        this.setState({
          showCompareIndicator, sectionType: type, showDetails, showInputOptions,
        })
        break
      }
      default:
        break
    }
  }

  getShowIndicator = (sectionType) => {
    const { showComparator, showIndicator, showCompareIndicator } = this.state
    switch (sectionType) {
      case SECTION_TYPE.INDICATOR:
        return showIndicator

      case SECTION_TYPE.COMPARATOR:
        return showComparator

      case SECTION_TYPE.COMPARE_INDICATOR:
        return showCompareIndicator

      default:
        return false
    }
  }

  renderInputField = (inputParams) => {
    const { styles, showError, indicatorState } = this.props
    const {
      label, valueKey, isValid, isEditable, valueExist, value, showIndicator,
    } = inputParams
    const errStyles = !isValid && showError && indicatorState[SECTION_TYPE.INDICATOR]
      && indicatorState[SECTION_TYPE.INDICATOR] !== 'Indicator' ? styles.cardRowInValid : ''
    return (
      <div className={`${styles.cardRow} ${isValid && valueExist ? styles.cardRowValid : ''} ${errStyles}`}>
        <button
          type="button"
          onClick={() => this.toggleSections(valueKey, isEditable)}
          className={styles.cardRowBtn}
        >
          <CustomText color="orange">{valueExist ? value : label}</CustomText>
          <Icon name={ICONS[showIndicator ? 'UP_HEAD_FILLED' : 'DOWN_HEAD_FILLED']} color={theme.orange} size={10} />
        </button>

        {/* {!isValid && showError && valueExist && (
        <CustomText size="small">
          {'This field is not valid  '}
          <Icon name={ICONS.WARN} size={12} color={COLORS.RED} />
        </CustomText>
        )} */}
      </div>
    )
  }

  renderRow = (label, valueKey, isValid, isEditable) => {
    const {
      indicatorState, candleIntervalIndex, styles, editType,
    } = this.props
    const { showDetails, sectionType } = this.state
    const value = indicatorState[valueKey] || ''
    const valueExist = Boolean(value && value.toLowerCase() !== 'indicator' && value.toLowerCase() !== 'comparator')
    const showIndicator = this.getShowIndicator(valueKey)
    // console.log(label, isEditable, isValid, showError, valueKey, indicatorState, 'indi rowwwww')
    if (!showIndicator) {
      return this.renderInputField({
        label, valueKey, isValid, isEditable, valueExist, value, showIndicator,
      })
    }
    return (
      <IndicatorFinder
        onItemSelect={this.onItemSelect}
        onRemove={this.onRemove}
        onDismiss={this.onDismiss}
        sectionType={sectionType}
        tree={this.tree}
        showDetails={showDetails}
        onEdit={this.onEdit}
        saveDetails={this.onSaveDetails}
        removeIndicator={this.removeIndicator}
        indicatorState={indicatorState}
        candleIntervalIndex={candleIntervalIndex}
        detailsKey={this.detailsKey}
        itemKey={`${sectionType}Item`}
        updatedActiveItem={this.updatedActiveItem}
        keysToHide={this.keysToHide}
        keysToShow={sectionType === SECTION_TYPE.COMPARE_INDICATOR
          ? this.allowedComparatorIndicators : null}
        containerStyles={styles.finderContainer}
        wrapperStyles={styles.finderWrapper}
        editType={editType}
      />
    )
  }

  onDismiss = () => {
    this.updatedActiveItem = {}
    this.setState({
      showDetails: false,
      showComparator: false,
      showIndicator: false,
      showCompareIndicator: false,
      showInputOptions: false,
    })
  }

  onItemSelect = (item, type, sectionName, details) => {
    switch (type) {
      case SECTION_TYPE.INDICATOR: {
        this.indicatorSelect(item, details)
        break
      }
      case SECTION_TYPE.COMPARATOR: {
        this.comparatorSelect(item, details)
        break
      }
      case SECTION_TYPE.COMPARE_INDICATOR: {
        this.compareIndicatorSelect(item, details)
        break
      }
      default:
        break
    }
  }

  inputSelectHandler = (item, type) => {
    if (item.params && item.params.length) {
      this.updatedActiveItem = item
    }
    this.onItemSelect(item, type)
  }

  onSaveDetails = (details, selected_candle) => {
    const { sectionType } = this.state
    this.updatedActiveItem = {}
    switch (sectionType) {
      case SECTION_TYPE.INDICATOR: {
        this.saveDetails(details, selected_candle)
        break
      }
      case SECTION_TYPE.COMPARATOR: {
        this.saveComparatorDetails(details, selected_candle)
        break
      }
      case SECTION_TYPE.COMPARE_INDICATOR: {
        this.saveCompareIndicatorDetails(details, selected_candle)
        break
      }
      default:
        break
    }
  }

  handleAndOrChange = (value) => {
    const { index, handleAndOrChange } = this.props
    handleAndOrChange(value, index)
  }

  renderAndOr = () => {
    const {
      andor,
      showAndOr,
      isDark,
      styles,
    } = this.props
    if (!showAndOr) return null
    return (
      <CustomHorizontalTab
        tabs={[{ label: 'And', value: 'and' }, { label: 'Or', value: 'or' }]}
        defaultActiveTab={andor}
        tabChangeHandler={this.handleAndOrChange}
        style={styles.andOrContainer}
        tabStyles={styles.tab}
        tabColor="GREY_200"
        isDark={isDark}
      />
    )
  }

  onCopy = () => {
    const { onCopy, index } = this.props
    onCopy({ index })
  }

  onPaste = () => {
    const { index, onPaste } = this.props
    onPaste({ index })
  }

  renderIndicatorFinder = () => {
    const {
      indicatorState, candleIntervalIndex, styles, editType,
    } = this.props
    const { showDetails, sectionType } = this.state
    return (
      <IndicatorFinder
        onItemSelect={this.onItemSelect}
        onRemove={this.onRemove}
        onDismiss={this.onDismiss}
        sectionType={sectionType}
        tree={this.tree}
        showDetails={showDetails}
        onEdit={this.onEdit}
        saveDetails={this.onSaveDetails}
        removeIndicator={this.removeIndicator}
        indicatorState={indicatorState}
        candleIntervalIndex={candleIntervalIndex}
        detailsKey={this.detailsKey}
        itemKey={`${sectionType}Item`}
        updatedActiveItem={this.updatedActiveItem}
        keysToHide={this.keysToHide}
        keysToShow={sectionType === SECTION_TYPE.COMPARE_INDICATOR
          ? this.allowedComparatorIndicators : null}
        containerStyles={styles.finderContainer}
        wrapperStyles={styles.finderWrapper}
        editType={editType}
      />
    )
  }

  render() {
    const {
      indicatorState,
      isDark,
      styles,
      copiedIndex,
      copiedEditType,
      index,
      showPaste: shouldShowPaste,
      editType,
      toggleDisableCondition,
      disabled,
    } = this.props
    const {
      isEditable,
      showOthers,
      indicatorValid,
      comparatorEditable,
      // comparatorValid,
      compareIndicatorValid,
      sectionType,
      showInputOptions,
      indicatorDetails,
    } = this.state
    const indicatorEditable = Boolean(
      !indicatorValid || (indicatorValid && indicatorDetails && !isEmpty(indicatorDetails)),
    )
    const showPaste = (copiedEditType === editType
      ? copiedIndex < index : true) && shouldShowPaste
    return (
      <>
        <CustomModal
          visible={showInputOptions}
          onDismiss={this.togglePopup}
          dismissKey="showInputOptions"
          containerStyles={styles.inputOptionModal}
        >
          <InputOptions
            tree={this.tree}
            onItemSelect={this.inputSelectHandler}
            onRemove={this.onRemove} // fixed
            onDismiss={this.togglePopup}
            sectionType={sectionType}
            selectedName={indicatorState[`${sectionType}Item`] && indicatorState[`${sectionType}Item`].name}
            dismissKey="showInputOptions"
            isDark={isDark}
            keysToHide={this.keysToHide}
          />
        </CustomModal>
        {this.renderAndOr()}
        <IndicatorSectionView
          sectionTypeMap={SECTION_TYPE}
          indicatorState={indicatorState}
          showPaste={showPaste}
          showOthers={showOthers}
          comparatorEditable={comparatorEditable}
          indicatorEditable={indicatorEditable}
          isEditable={isEditable}
          indicatorValid={indicatorValid}
          compareIndicatorValid={compareIndicatorValid}
          toggleDisableCondition={toggleDisableCondition}
          disabled={disabled}
          index={index}
          onCopy={this.onCopy}
          onPaste={this.onPaste}
          toggleSections={this.toggleSections}
          getShowIndicator={this.getShowIndicator}
          removeExpression={this.removeIndicatorHandler}
          renderIndicatorFinder={this.renderIndicatorFinder}
        />
      </>
    )
  }
}

const stylesheet = ({
  andOrContainer: {
    margin: SPACING.SPACE_20,
    marginBottom: 0,
    borderRadius: 6,
    maxWidth: 156,
  },
  tab: {
    width: 70,
    borderRadius: 6,
  },
  editIcon: {
    alignSelf: 'flex-end',
    marginBottom: SPACING.SPACE_8,
    marginRight: SPACING.SPACE_8,
  },
  finderContainer: {
    display: 'flex',
    border: `1px solid ${theme.orange} !important`,
    borderRadius: 5,
    padding: `0 ${SPACING.SPACE_16} !important`,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '300px !important',
    margin: '0 !important',
  },
  finderWrapper: {
    marginRight: SPACING.SPACE_28,
    flex: 1,
    border: `1px solid ${theme.borderColor}`,
    backgroundColor: theme.inputLightBg,
    borderRadius: 5,
    padding: `0 ${SPACING.SPACE_16}`,
    width: '100%',
    maxWidth: '300px',
    display: 'flex',
    alignItems: 'center',
  },
  inputOptionModal: {
    padding: 0,
    maxWidth: 350,
  },
})

export default withTheme(stylesheet)(IndicatorSection)
