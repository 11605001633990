/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { useHistory } from 'react-router-dom'

import ScreenHeader from '../../UI/ScreenHeader'
import Button from '../../UI/Button'
import AlgoInfo from '../AlgoInfo'
import BacktestResults from './BacktestResults'
import EntryExitRenderer from './EntryExitRenderer'
import AlgoParams from './AlgoParams'
import ViewAllCard from '../../UI/ViewAllCard'
import Icon from '../../UI/Icon'
import BacktestsDetails from './BacktestsDetails'
import CreateButton from '../../UI/CreateButton'
import CustomText from '../../UI/CustomText'
import EmptyContainer from '../../UI/EmptyContainer'

import {
  COLORS, ICONS, SPACING, theme, ASSETS,
} from '../../Theme'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import { DYNAMIC_SEGMENTS } from '../../containers/Create/Create-utils'

const findScripFromAlgoObj = (selectedSymbols, scripList) => {
  let scrip = ''
  let index = null
  const segSym = selectedSymbols.values().next().value
  for (let i = 0; i < scripList.length; i++) {
    const { segment, symbol } = scripList[i]
    if (segSym === `${segment}_${symbol}`) {
      scrip = scripList[i]
      index = i
      break
    }
  }
  return { scrip, index }
}

const BacktestSubscribed = ({
  btParams,
  algoInfo,
  renderType,
  deployAll,
  isDeploying,
  algoId,
  algoIdKey,
  scripList,
  backtest_results,
  addNewSymbol,
  showBacktestDetails,
  toggleEditParams,
  backPressHandler,
  selectedSymbols,
  onSymbolSelect,
  deployed_seg_sym,
  toggleSelectAll,
  editAlgo,
  navigation,
  // isFetchingBacktests,
  // refetchBacktest,
  isRunningBacktests,
  removeSymbols,
  allDeployed,
  isFocused,
  algo_uuid,
  convertToDynamic,
  create_plus,
  downvotes,
  upvotes,
  view_count,
  voted,
  isPublic,
  editSubscribedAlgo,

  isDark,
  selectedIndex,
  algo_subscription_uuid,
  publishing_uuid,
  algo_subscribed,
  csrf,
  sessionid,
  onDelete,
  pinStratgyDetails,
  isPinned,
  sliderProps,
  deploySymbols,
  toggleBrokerage,
  brokerageData,
  brokerage,
  selectedItem,
  isMobile,
  updateBrokerage,
  gaCategory,
  reRunBacktestHandler,
}) => {
  const styles = stylesheet()
  const history = useHistory()
  const {
    algo_name, algo_desc, entryStr, exitStr,
  } = algoInfo
  const scrollViewRef = useRef(null)
  const onCardPress = () => {
    editSubscribedAlgo({ algoIdKey })
  }
  useEffect(() => {
    if (isRunningBacktests && scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: 0, animated: true })
    }
  }, [isRunningBacktests])
  // const [showDeleteBtn, toggleDeleteBtn] = useState(false)
  const len = scripList.length
  const selectedLen = selectedSymbols.size
  const deployedLen = deployed_seg_sym.length
  let showEditBtn = false
  let segSym = ''
  if (selectedLen === 1) {
    segSym = selectedSymbols.keys().next().value
    const [segment] = segSym.split('_')
    showEditBtn = Boolean((segSym.includes('DYNAMIC CONTRACT') || DYNAMIC_SEGMENTS.includes(segment.toUpperCase())) && create_plus)
  }
  const actionBarRenderer = () => {
    if (len === 0 || !isMobile) return null
    const deployBtnText = selectedLen === (len - deployedLen) || selectedLen === 0 ? 'Deploy All' : `Deploy ${selectedLen}`
    return (
      <Button
        text={deployBtnText}
        onPress={deployAll}
        buttonColor="blue"
        btnStyles={styles.deployBtn}
        uppercase
        disabled={len === 0}
      />
    )
    // return (
    //   <div className={styles.headerActionBar}>
    //     <Button
    //       onPress={deployAll}
    //       text={deployBtnText}
    //       disabled={Boolean(isDeploying || allDeployed)}
    //       contentStyle={styles.deployAllBtn}
    //       labelStyle={styles.depoyBtnText}
    //       buttonColor={COLORS.BLUE}
    //       labelColor={COLORS.WHITE}
    //       mode="contained"
    //       roundness={6}
    //     />
    //     <button
    //       type="button"
    //       onClick={removeSymbols}
    //     >
    //       <div className={styles.deleteBtnView}>
    //         <Icon name={ICONS.DELETE} color={COLORS.WHITE} />
    //       </div>
    //     </button>
    //     {showEditBtn && (
    //     <button
    //       type="button"
    //       onClick={() => convertToDynamic(findScripFromAlgoObj(selectedSymbols, scripList))}
    //     >
    //       <div className={styles.editBtnView}>
    //         <Icon name={ICONS.EDIT} color={COLORS.WHITE} />
    //       </div>
    //     </button>
    //     )}
    //   </div>
    // )
  }
  const listHeaderRenderer = () => {
    return (
      <AlgoInfo
        algoId={algoId}
        algoIdKey={algoIdKey}
        monthly_pricing={0}
        algo_name={algo_name}
        algo_desc={algo_desc}
        parentId={NAVIGATIONS.STRATEGIES.name}
        isFocused={isFocused}
        algo_uuid={algo_uuid}
        isDark={isDark}
        showSocialStats={false}
        downvotes={downvotes}
        upvotes={upvotes}
        view_count={view_count}
        publishing_uuid={publishing_uuid}
        voted={voted}
        // canEditAlgo={isPublic}
        navigation={navigation}
        editSubscribedAlgo={editSubscribedAlgo}
        entryStr={entryStr}
        exitStr={exitStr}
        history={history}
        gaCategory={gaCategory}
        gaLabel={gaCategory}
      />
    )
  }
  const footerActionBtnRederer = () => {
    return <Icon name={ICONS.BEARISH} size={16} />
  }

  const listFooterRenderer = () => {
    const sepratorStyles = isDark ? {
      backgroundColor: COLORS.GREY_300,
    } : {}
    // console.log(allDeployed, isRunningBacktests, 'disabeld')
    return (
      <ViewAllCard
        title="Strategy Details"
        onPress={pinStratgyDetails}
        actionBtnRederer={footerActionBtnRederer}
        cardStyles={styles.footer}
        contentStyles={styles.footerContent}
        headerStyles={styles.footerHeader}
      >

        <div className={styles.strategyDetails}>
          <EntryExitRenderer
            cardTitle="ENTRY"
            onCardPress={onCardPress}
            condition={entryStr}
            disabled={Boolean(isRunningBacktests)}
            key="entry"
            isDark={isDark}
            cardStyles={styles.entryCard}
          />
          <EntryExitRenderer
            cardTitle="EXIT"
            onCardPress={onCardPress}
            condition={exitStr}
            disabled={Boolean(isRunningBacktests)}
            numberOfLines={1}
            key="exit"
            isDark={isDark}
            cardStyles={styles.exitCard}
          />
        </div>
        <AlgoParams
          isDark={isDark}
          {...btParams}
          disabled={Boolean(isRunningBacktests)}
          toggleEditParams={toggleEditParams}
          containerStyles={styles.algoParamsContainer}
        />
      </ViewAllCard>
    )
  }
  const { dt_start, dt_stop } = btParams
  const emptyActionRenderer = () => {
    return (
      <CreateButton
        text="Add Stocks eg SBIN, F&O etc."
        onPress={addNewSymbol}
        className={styles.addStock}
        btnStyles={styles.addStockBtn}
        textSize="small"
      />
    )
  }
  return (
    <>
      <ScreenHeader
        backPressHandler={backPressHandler}
        title={renderType}
        actionBarRenderer={actionBarRenderer}
        titleSize="large_1"
        containerStyles={styles.screenHeader}
        showBackBtn
      />
      <div
        className={styles.container}
        ref={scrollViewRef}
      >
        {listHeaderRenderer()}
        {/* {isPinned && listFooterRenderer()} */}
        {len === 0 ? (
          <EmptyContainer
            containerStyles={styles.emptyContainer}
            imgStyles={styles.emptyImg}
            imgContainerStyles={styles.emptyImgContainer}
            imgSrc="NO_STOCKS"
            actionRenderer={emptyActionRenderer}
            title="No Stocks"
            subTitle="Add symbols to run backtest"
            contentStyles={styles.emptyContent}
          />
        ) : (
          <>
            <BacktestResults
              backtest_results={backtest_results}
              scripList={scripList}
              addNewSymbol={addNewSymbol}
              showBacktestDetails={showBacktestDetails}
              selectedSymbols={selectedSymbols}
              onSymbolSelect={onSymbolSelect}
              deployed_seg_sym={deployed_seg_sym}
              toggleSelectAll={toggleSelectAll}
              allSelected={selectedLen === (len - deployedLen)}
              selectedLen={selectedLen}
              removeSymbols={removeSymbols}
              onDelete={onDelete}
              isDark={isDark}
              len={len}
              dt_start={dt_start}
              dt_stop={dt_stop}
              selectedIndex={selectedIndex}
              deployAll={deployAll}
              brokerageData={brokerageData}
              brokerage={brokerage}
              algoId={algoId}
              algoIdKey={algoIdKey}
              isRunningBacktests={isRunningBacktests}
              gaCategory={gaCategory}
              gaLabel={gaCategory}
              reRunBacktestHandler={reRunBacktestHandler}
            />
            <BacktestsDetails
              algoInfo={algoInfo}
              details={btParams}
              item={selectedItem}
              algo_uuid={algo_uuid}
              algo_subscription_uuid={algo_subscription_uuid}
              publishing_uuid={publishing_uuid}
              algo_subscribed={algo_subscribed}
              deployed_seg_sym={deployed_seg_sym}
              csrf={csrf}
              sessionid={sessionid}
              isRunningBacktests={isRunningBacktests}
              deploySymbols={deploySymbols}
              toggleBrokerage={toggleBrokerage}
              brokerageData={brokerageData}
              brokerage={brokerage}
              updateBrokerage={updateBrokerage}
              algo_name={algo_name}
              {...sliderProps}
            />
          </>
        )}
        {/* {!isPinned && listFooterRenderer()} */}
      </div>
    </>
  )
}

const stylesheet = makeStyles({
  container: {
    margin: `0 ${SPACING.SPACE_18}`,
  },
  footer: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
  },
  footerContent: {},
  footerHeader: {
    padding: `0 ${SPACING.SPACE_14}`,
  },
  strategyDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  entryCard: {
    flex: 0.48,
  },
  exitCard: {
    flex: 0.48,
  },
  algoParamsContainer: {},
  screenHeader: {
    marginRight: SPACING.SPACE_20,
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
    backgroundColor: COLORS.WHITE,
    borderRadius: 10,
    boxShadow: `0px 7px 20px ${theme.borderColor}`,
  },
  emptyContent: {
    textAlign: 'center',
  },
  emptyImg: {
    height: 100,
    paddingRight: SPACING.SPACE_20,
  },
  emptyImgContainer: {
    marginRight: '10%',
  },
  addStock: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_16}`,
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      margin: 0,
    },
    strategyDetails: {
      flexDirection: 'column',
    },
    footerContent: {
      borderRadius: 10,
      backgroundColor: theme.bgPrimary,
      boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    },
    entryCard: {
      boxShadow: 'none !important',
      borderBottom: `1px solid ${theme.borderColor}`,
      padding: `${SPACING.SPACE_20} 0`,
      margin: `0 ${SPACING.SPACE_14}`,
      borderRadius: 0,
    },
    exitCard: {
      boxShadow: 'none !important',
      borderBottom: `1px solid ${theme.borderColor}`,
      padding: `${SPACING.SPACE_20} 0`,
      margin: `0 ${SPACING.SPACE_14}`,
      borderRadius: 0,
    },
    algoParamsContainer: {
      boxShadow: 'none !important',
      marginTop: 0,
    },
  },
})

export default BacktestSubscribed
