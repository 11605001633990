import React, { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import makeStyles from '@material-ui/styles/makeStyles'
import classnames from 'classnames'

import SegSym from '../../UI/SegSym'
import CustomText from '../../UI/CustomText'
import ShowLTP from '../../UI/ShowLTP'
import GetDeployedPNL from './GetDeployedPnl'
import StockIcon from '../../UI/StockIcon'
import Icon from '../../UI/Icon'
import ModalDropdown from '../../UI/Dropdown/ModalDropdown'
import Checkbox from '../../UI/Checkbox'

import { getSegSymFromDC } from '../../containers/Create/Create-utils'
import { segSymConverter } from '../../utils/common'
import {
  DEPLOYED_TABS, DEPLOYED_SECTION_MAP, tpSlCalculation, MENU_MAP,
} from '../../containers/Deployed/deployedUtils'
import { COMMODITY_MULTIPLIER } from '../../utils/consts'
import {
  ICONS, COLORS, theme, SPACING, FONTS,
} from '../../Theme'

const { NOTIF_AUTO, NOTIF_PAPER } = ICONS

const rowStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: SPACING.SPACE_10,
}
export const Row = ({ children, styles = {} }) => (
  <div style={{ ...rowStyles, ...styles }}>{children}</div>
)

const areEqual = (props, nextProps) => {
  const {
    algo, activeTabKey, activeSection, isActionable, isSelected, cancelSlmList,
    isMobile, showCellCheckbox,
  } = props
  if(!isEqual(algo, nextProps.algo)
    || !isEqual(activeTabKey, nextProps.activeTabKey)
    || !isEqual(activeSection, nextProps.activeSection)
    || !isEqual(isActionable, nextProps.isActionable)
    || !isEqual(isSelected, nextProps.isSelected)
    || !isEqual(isMobile, nextProps.isMobile)
    || !isEqual(showCellCheckbox, nextProps.showCellCheckbox)
    || !isEqual(cancelSlmList, nextProps.cancelSlmList)) {
    return false
  }
  return true
}

const DeployedRow = React.memo(({
  containerstyle,

  algo,
  activeTabKey,
  activeSection,
  // meta,

  isActionable,
  gif,
  // cancelSlmList,
  // cancelSlmOrder,
  actionButtonHandler,
  rowPressHandler,
  onSelectHandler,
  isSelected,
  menuOptions,
  tableHeaders,
  isDark,
  isMobile,
  showCellCheckbox,
}) => {
  const [options, updateOptions] = useState(menuOptions)
  useEffect(() => {
    if (isActionable) {
      updateOptions([MENU_MAP.TAKE_ACTION, ...menuOptions])
    } else {
      updateOptions(menuOptions)
    }
  }, [isActionable])
  const {
    algo_name,
    segment_symbol,
    deployment_uuid,
    algorithm_position: {
      seg = '',
      positions: { last_order_average_price = 0.0, qty = 0.0 },
      pnl: { final_pnl },
    },
    algo_obj: {
      stop_loss, take_profit, action_type,
      tpsl_type,
    },
  } = algo
  const styles = stylesheet()
  const realizedPnl = parseFloat(final_pnl, 10) || 0.0
  const { segment, symbol } = segSymConverter(segment_symbol)

  let transaction = ''
  if (+qty > 0) {
    transaction = 'B'
  } else if (+qty < 0) {
    transaction = 'S'
  }
  let comm
  if(seg === 'MCX') {
    comm = symbol.split(/[0-9]/)[0]
  }
  let multiply_quantity = seg === 'CDS-FUT' ? qty * 1000 : qty
  if (seg === 'MCX') {
    multiply_quantity = COMMODITY_MULTIPLIER[comm] * qty
  }

  const showNA = activeTabKey === DEPLOYED_TABS.WAITING.KEY && Number(realizedPnl) === 0
  let showNoPosition = false
  if (activeTabKey !== DEPLOYED_TABS.ENTERED.KEY) {
    showNoPosition = Number(qty) === 0
  }
  const { tp, sl } = tpSlCalculation({
    take_profit,
    stop_loss,
    tpsl_type,
    action_type,
    quantity: multiply_quantity,
    avgPrice: +last_order_average_price,
  })
  const transactionStyle = transaction === 'S'
    ? { color: theme.orange }
    : { color: theme.linkColor }
  const modQuantity = Math.abs(qty)
  // DANGER
  // let borderStyle = {}
  // if(cancelSlmList?.length > 0) {
  //   borderStyle = {
  //     borderWidth: 1,
  //     borderBottomColor: COLORS.SHADOW_GREY_LIGHT,
  //   }
  // }

  // const cancelOrderHandler = (index) => {
  //   cancelSlmOrder(algo, index)
  // }

  const onPress = () => {
    rowPressHandler({
      algo, activeSection, isActionable, activeTabKey,
    })
  }

  const onChangeOption = (actionType) => {
    actionButtonHandler({
      actionType, algo, activeSection, isActionable, activeTabKey,
    })
  }

  let iconName
  let iconColor
  if (activeSection.id === DEPLOYED_SECTION_MAP.PAPER_TRADING.id) {
    iconName = NOTIF_PAPER
    iconColor = theme.green
  } else if(activeSection.id === DEPLOYED_SECTION_MAP.NOTIFICATION_TRADING.id) {
    iconName = NOTIF_AUTO
    iconColor = theme.linkColor
  }
  const precision = seg === 'CDS-FUT' ? 4 : 2
  const [
    { style: instrument }, { style: strategy }, { style: ltp },
    { style: pnl }, { style: position }, { style: traget }, { style: optionsStyles },
  ] = tableHeaders
  const stockIconStyle = {
    marginRight: SPACING.SPACE_8,
    display: 'inline-block',
    marginTop: isMobile ? 3 : 0,
  }
  const showCheckbox = activeTabKey === DEPLOYED_TABS.WAITING.KEY && !isMobile
  const { segSym } = getSegSymFromDC(segment_symbol)
  const renderInstrument = () => (
    <div
      className={`${styles.instrument} ${showCheckbox ? styles.instrumentWcheckbox : ''}`}
      style={isMobile ? {} : instrument}
    >
      {showCellCheckbox && activeTabKey === DEPLOYED_TABS.WAITING.KEY
        ? renderCheckbox
        : (
          <StockIcon seg_sym={segSym} height={20} width={20} containerStyle={stockIconStyle} />
        )}
      <SegSym
        seg_sym={segment_symbol}
        // style={{ width: '80%' }}
        // symStyles={{ maxWidth: '75%' }}
        showTooltip
        ellipsis={false}
      />
    </div>
  )
  const renderAlgoName = () => (
    <div className={styles.algoName} style={isMobile ? {} : strategy}>
      <Icon name={iconName} alt={`${activeSection.id}_icon`} color={iconColor} size={15} />
      <CustomText ellipsis style={{ marginLeft: SPACING.SPACE_10, width: '75%' }}>{algo_name}</CustomText>
    </div>
  )
  const renderActionable = () => {
    return isActionable ? (
      <img src={gif} alt="alert_img" className={styles.blinker} />
    ) : null
  }
  const renderLtp = () => (
    <div style={isMobile ? {} : ltp} className={styles.ltpRow}>
      <ShowLTP
        seg_sym={segSym}
        showLtpColor={false}
        hideChangeColor
        containerStyles={styles.ltpContainer}
      />
      {renderActionable()}
    </div>
  )
  const renderPnl = () => (
    <div style={isMobile ? {} : pnl}>
      <GetDeployedPNL
        segment_symbol={segment_symbol}
        realizedPnl={realizedPnl}
        activeTabKey={activeTabKey}
        last_order_average_price={last_order_average_price}
        multiply_quantity={multiply_quantity}
        segment={segment}
        comm={comm}
        showNA={showNA}
        pnlProps={{
          naStyle: styles.naText,
        }}
      />
    </div>
  )
  const renderPosition = () => (
    <div className={styles.position} style={isMobile ? {} : position}>
      {showNoPosition ? <CustomText color="textSecondary">N/A</CustomText> : (
        <>
          <CustomText weight="medium" style={transactionStyle}>{`${transaction} `}</CustomText>
              &nbsp;&nbsp;
          <CustomText weight="medium">{`${modQuantity} `}</CustomText>
              &nbsp;&nbsp;
          <CustomText size="small" color="textSecondary">
            {`at ${Number(last_order_average_price).toFixed(precision)}`}
          </CustomText>
        </>
      )}
    </div>
  )
  const renderTarget = () => (
    <div className={styles.traget} style={isMobile ? {} : traget}>
      {showNoPosition ? <CustomText color="textSecondary">N/A</CustomText> : (
        <>
          <CustomText size="small" weight="regular" color="red">
            SL
          </CustomText>
    &nbsp;
          <CustomText size="small" weight="regular">{sl.toFixed(precision)}</CustomText>
    &nbsp;&nbsp;
          <CustomText size="small" weight="regular" color="green">
            TP
          </CustomText>
    &nbsp;
          <CustomText size="small" weight="regular">{tp.toFixed(precision)}</CustomText>
        </>
      )}
    </div>
  )
  let modalRespProps = {}
  if (isMobile) {
    modalRespProps = {
      containerStyles: styles.dropdownInput,
    }
  }
  const inputRenderer = () => {
    if (isMobile) {
      return (
        <>
          <Row>
            {renderInstrument()}
            {renderLtp()}
          </Row>
          <Row>
            {renderAlgoName()}
            {renderPnl()}
          </Row>
          <Row>
            {renderPosition()}
            {renderTarget()}
          </Row>
          <div className={styles.seperator} />
        </>
      )
    }
    return (
      <div className={styles.menuBtn} style={optionsStyles}>
        <Icon name={ICONS.THREE_DOTS_VERTICAL} color={COLORS[isDark ? 'BLACK_60' : 'GREY']} size={12} />
      </div>
    )
  }
  const renderCheckbox = (
    <Checkbox
      value={isSelected}
      onValueChange={onSelectHandler}
      params={{ deployment_uuid }}
      className={`${styles.waitingCheckbox} ${isSelected ? styles.selectedCheckbox : ''}`}
      id={`${activeTabKey}_${deployment_uuid}_checkbox`}
    />
  )
  return (
    <li className={classnames(styles.rowWrapper)}>
      {isMobile ? null : (
        <div
          className={classnames(styles.row, containerstyle)}
          style={{ width: `calc(100% - ${optionsStyles.width}px)` }}
        >
          {showCheckbox && (
            renderCheckbox
          )}
          <button
            type="button"
            onClick={onPress}
            className={classnames(styles.rowBtn, containerstyle)}
          >
            {renderInstrument()}
            {renderAlgoName()}
            {renderLtp()}
            {renderPnl()}
            {renderPosition()}
            {renderTarget()}
          </button>
        </div>
      )}
      <ModalDropdown
        options={options}
        onChangeOption={onChangeOption}
        inputRenderer={inputRenderer}
        labelKey="LABEL"
        valueKey="ID"
        {...modalRespProps}
      />
    </li>
  )
}, areEqual)

const stylesheet = makeStyles({
  rowWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${SPACING.SPACE_20} 0 ${SPACING.SPACE_32}`,
    width: '100%',
    position: 'relative',
    '&:hover $waitingCheckbox': {
      display: 'flex',
    },
  },
  rowBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} 0 ${SPACING.SPACE_10} 0`,
    width: '100%',
  },
  instrument: {
    display: 'flex',
    // alignItems: 'center',
    paddingRight: SPACING.SPACE_8,
    textAlign: 'left',
  },
  instrumentWcheckbox: {
    '& *': {
      transition: 'all 0.3s var(--anim-func-ease)',
    },
  },
  waitingCheckbox: {
    left: 8,
    display: 'none',
    position: 'absolute',
    marginRight: SPACING.SPACE_10,
  },
  selectedCheckbox: {
    display: 'flex !important',
  },
  algoName: {
    // display: 'flex',
    // alignItems: 'center',
    textAlign: 'left',
    display: 'inline-block',
    '& > p': {
      verticalAlign: 'top',
      width: '80%',
      display: 'inline-block',
    },
  },
  position: {
    display: 'flex',
    alignItems: 'center',
  },
  traget: {
    display: 'flex',
    alignItems: 'center',
  },
  naText: {
    color: `${theme.textSecondary}  !important`,
    fontSize: `${FONTS.REGULAR}px !important`,
  },
  ltpRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: SPACING.SPACE_8,
  },
  blinker: {
    height: 18,
    width: 18,
    // margin: `0 ${SPACING.SPACE_10}`,
  },
  ltpContainer: {
    alignItems: 'baseline',
    overflow: 'hidden',
  },
  actionable: {
    display: 'flex',
    alignItems: 'center',
  },
  '@media only screen and (max-width: 786px)': {
    row: {
      flexDirection: 'column',
      padding: `${SPACING.SPACE_12} ${SPACING.SPACE_20}`,
    },
    rowWrapper: {
      '&:last-child': {
        '& $seperator': {
          display: 'none',
        },
      },
    },
    waitingCheckbox: {
      position: 'unset',
      display: 'flex',
    },
    instrument: {
      alignItems: 'center',
    },
    ltpRow: {
      flexDirection: 'row-reverse',
    },
    seperator: {
      height: 1,
      backgroundColor: theme.borderColor,
      width: '100%',
    },
    algoName: {
      display: 'flex',
    },
    dropdownInput: {
      width: '100%',
      padding: `${SPACING.SPACE_12} ${SPACING.SPACE_20}`,
      '& > button': {
        width: '100%',
      },
    },
  },
})

export default DeployedRow
