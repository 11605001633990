import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'
import classnames from 'classnames'

import CustomText from './CustomText'
import Icon from './Icon'

import {
  COLORS, ICONS, SPACING, theme,
} from '../Theme'

const ViewEditCard = React.memo(({
  iconObj,
  cardTitle,
  onCardPress,
  children,
  disabled,
  style,
  status,
  titleStyle = '',
  showStatus,
  cardRef = null,
  showEdit = true,
  isDark,
  contentStyles,
  renderHeaderRight,
}) => {
  const styles = stylessheet()
  let statusText = 'Incomplete'
  let statusIcon = 'WARN'
  let statusColor = 'RED'
  if (status) {
    statusText = 'Completed'
    statusIcon = 'TICK_ROUND_FILLED'
    statusColor = 'GREEN'
  }
  const { iconName, color = 'text', size = 14 } = iconObj
  return (
    <div
      className={classnames(styles.cardBtn, style)}
      style={{ boxShadow: isDark ? 'none' : `0px 7px 20px ${theme.borderColor}` }}
      ref={cardRef}
    >
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {/* <Image source={cardImg} className={styles.cardImg} /> */}
          {iconName ? (
            <div
              style={isDark ? { borderRadius: '50%', backgroundColor: COLORS.WHITE } : {}}
              className={styles.iconContainer}
            >
              <Icon
                name={ICONS[iconName.toUpperCase()]}
                color={COLORS[color.toUpperCase()]}
                size={size}
              />
            </div>
          ) : null}
          <CustomText weight="medium" className={titleStyle}>{`  ${cardTitle}`}</CustomText>
          {showEdit && (
          <button
            type="button"
            onClick={onCardPress}
            disabled={disabled}
            className={styles.editBtn}
            // wrapperStyles={styles.editBtnWrapper}
            // borderRadius={20}
            // isDark={isDark}
          >
            <Icon name={ICONS.EDIT} size={10} color={disabled ? theme.disabled : theme.linkColor} />
          </button>
          )}
        </div>
        <div className={styles.headerRight}>
          {showStatus ? (
            <div className={styles.status}>
              <CustomText size="small">{`${statusText}   `}</CustomText>
              &nbsp;
              <Icon name={ICONS[statusIcon]} size={12} color={COLORS[statusColor]} />
            </div>
          ) : null}
          {renderHeaderRight ? renderHeaderRight() : null}
        </div>
      </div>
      <div className={classnames(styles.content, contentStyles)}>
        {children}
      </div>
    </div>
  )
})

const stylessheet = makeStyles({
  cardBtn: {
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_14}`,
    borderRadius: 10,
    backgroundColor: theme.bgPrimary,
    boxShadow: `0px 7px 20px ${theme.borderColor}`,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: SPACING.SPACE_4,
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardImg: {},
  content: {
    marginTop: SPACING.SPACE_4,
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editBtn: {
    padding: `0 ${SPACING.SPACE_6}`,
  },
  editBtnWrapper: {
    left: SPACING.SPACE_10,
    position: 'relative',
  },
})

export default React.forwardRef((props, ref) => (
  <ViewEditCard cardRef={ref} {...props} />
))

ViewEditCard.defaultProps = {
  disabled: false,
  onCardPress: () => null,
  iconObj: {},
  contentStyles: '',
}

ViewEditCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  onCardPress: PropTypes.func,
  disabled: PropTypes.bool,
  iconObj: PropTypes.object,
  contentStyles: PropTypes.string,
}
