/* eslint-disable semi-spacing */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react'
import { Transition } from 'react-spring'
import isEmpty from 'lodash/isEmpty'

import CustomText from '../../UI/CustomText'
import ListDropdown from '../../UI/Dropdown/ListDropdown'
import CustomInput from '../../UI/CustomInput'
import SegSym from '../../UI/SegSym'
import CustomCheckbox from '../../UI/Checkbox'
import Button from '../../UI/Button'
// import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import Dot from '../../UI/Dot'
import Icon from '../../UI/Icon'
import CustomModal from '../../UI/CustomModal'
import LinkBrokerLogin from '../Auth/LinkBrokerLogin'
import ABLoginContainer from '../Auth/ABLoginContainer'
import SectionTab from '../../UI/SectionTab'

import {
  holding_type_map, BROKERS, ROOT_MODALS, MAX_ALLOCATION_OBJ,
  POSITION_SIZING_TYPES, ORDER_TYPE, TPSL_TYPE, POSITION_SIZING_TYPE,
  decimalRegexAll, qtyRegex,
} from '../../utils/consts'
import {
  COLORS, SPACING, theme, ICONS, FONTS,
} from '../../Theme'
import { showSnackbar } from '../../UI/Snackbar'
import { accessConditions, PRO_FEATURE_MAP, userAccessCheck } from '../../utils/userAccessChecks'
import { withTheme } from '../../Theme/ThemeProvider'
import { termsPage } from '../../utils/apiEndpoints'
import CustomTooltip from '../../UI/CustomTooltip'

const DEFAULT_PRICE = {
  OPEN: 'open',
  CLOSE: 'close',
  HIGH: 'high',
  LOW: 'low',
  LTP: 'ltp',
}
const gaFieldMap = {
  deployment_type: 'Deployment type',
  algo_cycle: 'Strategy cycle',
  holding_type: 'Holding type',
  algo_live_for: 'Strategy live for',
  variety: 'Variety',
  order_type: 'Order type',
  position_sizing_type: 'Position sizing type',
  default_price: 'Default price',
}

const tradingText = {
  'Paper trading': 'Paper trading simulates virtual trades based on your strategy. All trades are simulated automatically and profit/loss shown in orderlog for paper trading is hypothetical and for educational and learning purposes only not to be construed as real.',
  Notifications: 'Live trade strategies are tracking the markets real-time based on your conditions for entry and exit. One click notifications with order windows will be sent to your device whenever the conditions are met, you need to click on it to place orders.',
}

const TRANSITION_PROPS = {
  'Paper trading': {
    from: {
      opacity: 0, transform: 'translate3d(-100%,0,0)', width: '40%',
    },
    enter: {
      opacity: 1, transform: 'translate3d(0%,0,0)', width: '100%',
    },
    leave: {
      opacity: 0, transform: 'translate3d(100%,0,0)', position: 'absolute', display: 'none',
    },
  },
  Notifications: {
    from: {
      opacity: 0, transform: 'translate3d(100%,0,0)', width: '40%',
    },
    enter: {
      opacity: 1, transform: 'translate3d(0%,0,0)', width: '100%',
    },
    leave: {
      opacity: 0, transform: 'translate3d(-100%,0,0)', position: 'absolute',
    },
  },
}

const getAmountProperties = ({
  position_sizing_type,
  max_allocation,
  stocksQuantity, stocksQuantityErr,
  stocksMaxAllocation,
  stocksMaxAllocationErr,
}) => {
  const stocksAmount = max_allocation ? stocksMaxAllocation : stocksQuantity
  const stocksAmountErr = max_allocation ? stocksMaxAllocationErr : stocksQuantityErr
  const maxAllocationLabel = MAX_ALLOCATION_OBJ(position_sizing_type).label
  const amountLabel = max_allocation ? maxAllocationLabel : 'Quantity'
  return {
    stocksAmount,
    stocksAmountErr,
    amountLabel,
  }
}

const getStocksToDeploy = (deployed, deployed_seg_sym) => {
  const modDeployedStocks = []
  if (deployed !== undefined) {
    deployed.map((item) => {
      const { segment_symbol = '' } = item
      return modDeployedStocks.push(segment_symbol)
    })
  } else if (deployed_seg_sym !== undefined) {
    deployed_seg_sym.map((item) => {
      return modDeployedStocks.push(item)
    })
  }
  return modDeployedStocks
}

class Deploy extends PureComponent {
  constructor(props) {
    super(props)
    this.gaParamsMap = {}

    const {
      stocks = [], onBackPress, defaultQuantity = 1, marketAlgo, holding_type, position_sizing_type,
      max_allocation, order_type = ORDER_TYPE.MARKET, user_details, deployed, deployed_seg_sym,
      startTour,
    } = props
    const { broker } = user_details
    const stocksQuantity = {}
    const stocksMaxAllocation = {}
    if (stocks.length === 0) {
      showSnackbar('No stocks to deploy')
      onBackPress()
    }
    const modDeployedStocks = getStocksToDeploy(deployed, deployed_seg_sym)
    const finalStocks = stocks.filter(item => !modDeployedStocks.includes(item))

    this.liveForMap = [
      { label: '1 Day', value: '1' },
      { label: '3 Days', value: '3' },
      { label: '1 Week', value: '7' },
      { label: '30 days', value: '30' },
      { label: '60 days', value: '60' },
    ]
    this.varietyMap = ['REGULAR'] // 'BO'
    this.chartTypeMap = ['Heikin-Ashi', 'Candlestick', 'Renko']
    this.holdingTypeMap = ['MIS', 'CNC/NRML'] // 'MARKET', 'LIMIT', // now holdingType
    this.algoCyclesMap = ['1', '2', '3', '4', '5', '10', '15', '20', '50', '100']
    this.deployed = false
    this.mcx = false
    this.scrollViewRef = React.createRef()
    this.orderTypeMap = Object.values(ORDER_TYPE)
    // do not change the following
    this.deploymentTypeMap = [
      {
        label: 'Paper Trade',
        value: 'Paper trading',
        selectedColor: 'GREEN',
        iconName: 'NOTIF_PAPER',
        activeTextColor: theme.green,
        gaLabel: 'Paper trade',
      },
      {
        label: 'Live Trade',
        value: 'Notifications',
        selectedColor: 'BLUE',
        iconName: 'NOTIF_AUTO',
        activeTextColor: theme.linkColor,
        gaLabel: 'Live trade',
      },
    ]
    this.btnTextMap = {
      [this.deploymentTypeMap[0].value]: 'Paper Trade',
      [this.deploymentTypeMap[1].value]: 'Live Trade',
    }
    this.btnColorMap = {
      [this.deploymentTypeMap[0].value]: this.deploymentTypeMap[0].selectedColor,
      [this.deploymentTypeMap[1].value]: this.deploymentTypeMap[1].selectedColor,
    }
    this.linkBrokerLoginProps = {}

    for (let i = 0;i < stocks.length;i++) {
      const itm = stocks[i].split('_')[0]
      if (itm === 'MCX') {
        this.mcx = true
      }
      stocksQuantity[stocks[i]] = defaultQuantity
      stocksMaxAllocation[stocks[i]] = max_allocation || ''
    }

    this.state = {
      algo_cycle: '1',
      algo_live_for: '1',
      disabled: false,
      terms_popup: false,
      variety: 'REGULAR',
      stocksQuantity,
      stocksQuantityErr: false,
      // name: '',
      // nameErr: false,
      selectedSymbols: new Set(finalStocks),
      editName: marketAlgo,
      holding_type: holding_type ? holding_type_map[holding_type] : holding_type_map.MIS,
      order_type: this.orderTypeMap.some(item => item === order_type.toUpperCase())
        ? order_type : ORDER_TYPE.MARKET,
      deployment_type: broker === BROKERS.NONE.name || startTour
        ? this.deploymentTypeMap[0].value : this.deploymentTypeMap[1].value,
      invalidDeploymentType: false,
      stocks,
      termsAccepted: !!startTour,
      position_sizing_type,
      stocksMaxAllocation,
      stocksMaxAllocationErr: {},
      deployPressed: false, // set to true as soon as user presses the button
      termsError: false,
      linkBrokerLogin: false,
      showABLogin: false,
      buffer: 0,
      deployedAmount: user_details.deployed,
    }
  }

  componentDidMount() {
    const { fetchUserDetails } = this.props
    fetchUserDetails()
  }

  componentDidUpdate(prevProps) {
    const {
      isDeploying, marketIsDeployingStocks, isRedeploying,
      startTour, getExtraTourtip, activeTourIndex,
      updateTourIndex, fetchUserDetails, isFocused, onDismiss,
      changePtComponentStatus,
    } = this.props
    const { deployment_type, deployPressed } = this.state
    if ((prevProps.isDeploying && !isDeploying)
      || (prevProps.marketIsDeployingStocks && !marketIsDeployingStocks)
      || (prevProps.isRedeploying && !isRedeploying)) {
      this.setState({ deployPressed: false })
    }

    if (startTour && deployment_type === this.deploymentTypeMap[1].value) {
      getExtraTourtip(true)
    }

    if (prevProps.ptExtraTourtip) {
      getExtraTourtip(false)
      updateTourIndex(3)
      this.setState({ deployment_type: this.deploymentTypeMap[0].value })
    }
    if (startTour && prevProps.activeTourIndex === 4 && activeTourIndex === 5) {
      this.onDeployPress()
    }
    if (isFocused !== prevProps.isFocused && isFocused && deployPressed) {
      fetchUserDetails()
      onDismiss()
    }
    if (activeTourIndex === 3) {
      setTimeout(() => {
        changePtComponentStatus('landedDeployType')
      }, 500)
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user_details.deployed !== state.deployedAmount) {
      return {
        deployedAmount: props.user_details.deployed,
      }
    }
    return null
  }

  onChangeOption = (value, item, field) => {
    const { holding_type, deployment_type } = this.state
    const { tpsl_type } = this.props
    if (field === 'variety' && value === 'BO') {
      if (holding_type !== 'MIS') {
        showSnackbar('BO is not allowed for CNC/NRML')
        return
      }
      if (this.mcx) {
        showSnackbar('BO is not allowed for MCX segment')
        return
      }
      if (deployment_type === this.deploymentTypeMap[0].value) {
        showSnackbar('BO is not allowed for Paper trading')
        return
      }
    }

    if (tpsl_type === TPSL_TYPE.ABSOLUTE.value && field === 'position_sizing_type' && value === POSITION_SIZING_TYPE.RISK_BASED) {
      showSnackbar(`Cannot select TPSL type as ${TPSL_TYPE.ABSOLUTE.value} with ${POSITION_SIZING_TYPE.RISK_BASED} position sizing`)
      return
    }

    if (field === 'deployment_type' && value === this.deploymentTypeMap[0].value) {
      this.setState({
        [field]: value, variety: 'REGULAR', invalidDeploymentType: false, order_type: ORDER_TYPE.MARKET,
      })
      this.gaParamsMap.deployment_type = ['Deployment type', this.deploymentTypeMap[0].gaLabel]
      return
    }
    if (field === 'order_type' && value === ORDER_TYPE.LIMIT
      && deployment_type === this.deploymentTypeMap[0].value) {
      showSnackbar('Cannot deloy as LIMIT in paper trading')
      return
    }
    this.setState({ [field]: value })
    if (gaFieldMap[field]) {
      this.gaParamsMap[field] = [gaFieldMap[field], value.toString()]
    }
  }

  onFieldChange = (e, field) => {
    this.setState({ [field]: e.target.value.trim(), [`${field}Err`]: false })
  }

  handleQuantityChange = (e, field) => {
    const { target: { value } } = e
    const {
      max_allocation,
    } = this.props
    const isValidQuantity = /^[0-9]*$/g.test(value)
    if (!isValidQuantity) {
      showSnackbar('Please enter numerical value')
      return
    }
    this.setState((prevState) => {
      if (max_allocation) {
        const {
          stocksMaxAllocation: prevStocksMaxAllocation,
          stocksMaxAllocationErr: prevStocksMaxAllocationErr,
        } = prevState
        const modStocksMaxAllocation = { ...prevStocksMaxAllocation }
        modStocksMaxAllocation[field] = value
        const modStocksMaxAllocationErr = { ...prevStocksMaxAllocationErr }
        modStocksMaxAllocationErr[field] = !isValidQuantity
        return {
          stocksMaxAllocation: modStocksMaxAllocation,
          stocksMaxAllocationErr: modStocksMaxAllocationErr,
        }
      }
      const {
        stocksQuantity: prevStocksQuantity, stocksQuantityErr: prevStocksQuantityErr,
      } = prevState
      const modQuantity = { ...prevStocksQuantity }
      modQuantity[field] = value
      const modQuantityErr = { ...prevStocksQuantityErr }
      modQuantityErr[field] = !isValidQuantity
      return {
        stocksQuantity: modQuantity,
        stocksQuantityErr: modQuantityErr,
      }
    })
  }

  toggleStockSelection = ({ seg_sym, isSelected }) => {
    const { stocks = [] } = this.props
    if (stocks.length < 2) return
    this.setState((prevState) => {
      const { selectedSymbols } = prevState
      const modSelections = new Set(selectedSymbols)
      if (isSelected) {
        modSelections.delete(seg_sym)
      } else {
        modSelections.add(seg_sym)
      }
      return {
        selectedSymbols: modSelections,
      }
    })
  }

  renderStocks = () => {
    const {
      isDeploying, styles, max_allocation, stocks = [], deployed, deployed_seg_sym,
      startTour, user_details,
    } = this.props
    const { subscription_plan } = user_details
    const {
      stocksQuantity, stocksQuantityErr, helperText, deployPressed, stocksMaxAllocation,
      stocksMaxAllocationErr, position_sizing_type, selectedSymbols,
    } = this.state
    const modDeployedStocks = getStocksToDeploy(deployed, deployed_seg_sym)
    const finalStocks = stocks.filter(item => !modDeployedStocks.includes(item))
    const ptStocks = subscription_plan === 'free' && startTour ? finalStocks.slice(0, 2) : finalStocks
    const isDeployBtnDisabled = isDeploying || deployPressed
    return ptStocks.map((seg_sym) => {
      const isSelected = selectedSymbols.has(seg_sym)
      const { stocksAmount, stocksAmountErr, amountLabel } = getAmountProperties({
        position_sizing_type,
        max_allocation,
        stocksQuantity,
        stocksMaxAllocation,
        stocksQuantityErr,
        stocksMaxAllocationErr,
      })
      return (
        <div className={styles.stockRow} key={seg_sym}>
          <CustomCheckbox
            value={isSelected}
            disabled={ptStocks.length === 1}
            onValueChange={this.toggleStockSelection}
            params={{ seg_sym, isSelected }}
            id={seg_sym}
          />
          <SegSym
            showTooltip
            seg_sym={seg_sym}
            symStyles={{ whiteSpace: 'pre-wrap' }}
            style={{ flexDirection: seg_sym.length > 15 ? 'column' : 'row', paddingRight: SPACING.SPACE_10 }}
            marqueStyle={{ margin: `0 ${SPACING.SPACE_8}`, flex: 1 }}
            optionStyles={{ position: 'relative' }}
          />
          <CustomInput
            value={stocksAmount[seg_sym]}
            inputKey={seg_sym}
            onChangeText={this.handleQuantityChange}
            inputRegx={new RegExp(qtyRegex)}
            placeholder={amountLabel}
            containerStyles={styles.stocksInputContainer}
            inputStyles={styles.stocksInput}
            inputError={stocksAmountErr[seg_sym]}
            inputErrorText={helperText}
            editable={!isDeployBtnDisabled}
            label={amountLabel}
            labelClass={styles.qtyLabel}
          />
        </div>
      )
    })
  }

  toggleDeploymentType = (value) => {
    const { user_details, updateGA, startTour } = this.props
    const { broker } = user_details
    if (startTour) updateGA('Paper Live Toggle', 'Disc Strat Pg 4', 1, 'Product Tour')
    if (broker === BROKERS.NONE.name) {
      showSnackbar('Add at least one of your stock broking accounts.')
      this.toggleModal('linkBrokerLogin')
      return
    }
    this.onChangeOption(value, {}, 'deployment_type')
  }

  renderOptionsField = () => {
    const { deployPressed } = this.state
    const {
      isDeploying, styles, max_allocation, create_plus,
    } = this.props
    const isDeployBtnDisabled = isDeploying || deployPressed
    const {
      algo_cycle, holding_type, // editName, name, nameErr,
      algo_live_for, variety, position_sizing_type, order_type,
      default_price, buffer,
    } = this.state
    return (
      <>
        <ListDropdown
          selected={algo_cycle}
          options={this.algoCyclesMap}
          label="Strategy Cycles"
          changeKey="algo_cycle"
          onChangeOption={this.onChangeOption}
          containerStyles={styles.dropdownContainer}
          inputBtnStyles={styles.dropdownInput}
          disabled={isDeployBtnDisabled}
        />
        <ListDropdown
          selected={holding_type}
          options={this.holdingTypeMap}
          label="Holding Type"
          changeKey="holding_type"
          onChangeOption={this.onChangeOption}
          containerStyles={styles.dropdownContainer}
          inputBtnStyles={styles.dropdownInput}
          disabled={isDeployBtnDisabled}
        />
        {holding_type === 'MIS' && (
          <ListDropdown
            selected={algo_live_for}
            options={this.liveForMap}
            label="Strategy live for"
            changeKey="algo_live_for"
            onChangeOption={this.onChangeOption}
            containerStyles={styles.dropdownContainer}
            inputBtnStyles={styles.dropdownInput}
            disabled={isDeployBtnDisabled}
          />
        )}
        {holding_type === 'MIS' && (
          <ListDropdown
            selected={variety}
            options={this.varietyMap}
            label="Variety"
            changeKey="variety"
            onChangeOption={this.onChangeOption}
            containerStyles={styles.dropdownContainer}
            inputBtnStyles={styles.dropdownInput}
            disabled={isDeployBtnDisabled}
          />
        )}
        {Boolean(max_allocation) && (
          <ListDropdown
            label="Position sizing type"
            selected={position_sizing_type}
            options={POSITION_SIZING_TYPES}
            changeKey="position_sizing_type"
            onChangeOption={this.onChangeOption}
            inputBtnStyles={styles.dropdownInput}
            containerStyles={styles.dropdownContainer}
          />
        )}
        {Boolean(create_plus) && (
          <ListDropdown
            selected={order_type}
            options={this.orderTypeMap}
            label="Order Type"
            changeKey="order_type"
            onChangeOption={this.onChangeOption}
            containerStyles={styles.dropdownContainer}
            inputBtnStyles={styles.dropdownInput}
            disabled={isDeployBtnDisabled}
          />
        )}
        {order_type === ORDER_TYPE.LIMIT && (
          <>
            <ListDropdown
              selected={default_price}
              options={Object.values(DEFAULT_PRICE)}
              label="Default Price"
              changeKey="default_price"
              onChangeOption={this.onChangeOption}
              containerStyles={styles.dropdownContainer}
              inputBtnStyles={styles.dropdownInput}
              disabled={isDeployBtnDisabled}
            />
            <div className={styles.dropdownContainer}>
              <CustomText size="small" weight="medium" className={styles.labelStyles}>Buffer</CustomText>
              <CustomInput
                value={buffer}
                inputKey="buffer"
                onChangeText={this.onFieldChange}
                inputRegx={decimalRegexAll}
                placeholder="Buffer"
                inputStyles={styles.dropdownInput}
                disabled={isDeployBtnDisabled}
              />
            </div>
          </>
        )}
      </>
    )
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  toggleTerms = () => {
    this.setState(
      prevState => ({ termsAccepted: !prevState.termsAccepted, termsError: false }),
    )
  }

  renderDeploymentType = ({ item, active }) => {
    const { iconName, selectedColor } = item
    return (
      <Icon
        name={iconName}
        size={14}
        color={active ? COLORS[selectedColor] : theme.textSecondary}
      />
    )
  }

  onDeployPress = () => {
    const {
      user_details, toggleRootModal, toggleAuthGreeting,
      startTour, showTour, onBackPress, ptSideBar, toggleProductTourSidebar,
      activeTourIndex, updateTourIndex, updateGA, styles,
    } = this.props
    const {
      termsAccepted, selectedSymbols, deployment_type,
      deployedAmount,
    } = this.state
    const { broker, subscription_plan = '', subscription_valid } = user_details
    if (!subscription_valid) {
      // showSnackbar('Limit Reached.')
      toggleAuthGreeting(PRO_FEATURE_MAP.DP_LIMIT.ID)
      return
    }
    const ptSelectedSymbol = Array.from(selectedSymbols).slice(0, 2)
    const finalSelectedSymbols = subscription_plan === 'free' && startTour ? ptSelectedSymbol : selectedSymbols
    if (!termsAccepted) {
      this.setState({ termsError: true })
      return
    }
    if (startTour) updateGA('Highlight Click', 'Disc Strat Pg 5', 1, 'Product Tour')
    if (startTour && deployment_type === this.deploymentTypeMap[1].value) {
      showTour(false)
      onBackPress()
      toggleProductTourSidebar(!ptSideBar)
      return
    }
    if (broker === BROKERS.ANGEL.name) {
      if (userAccessCheck({
        condition: accessConditions.AB_NO_EMAIL_USAGE_LIMIT, user_details,
      })) {
        toggleRootModal(ROOT_MODALS.showEmailModal, true)
        return
      }
    }
    if (finalSelectedSymbols.size > user_details.total_deployments - Math.abs(deployedAmount)) {
      if (subscription_plan === 'ultimate') {
        showSnackbar('Deployment limit reached.')
        // if (deployedAmount === 100) {
        //   showSnackbar(
        //     <p>
        //       <a href="https://help.streak.tech/deployed/" className={styles.deployLimitLink} target="_blank" rel="noreferrer"> Deployment limit</a>
        //       {' '}
        //       reached. Please stop previously deployed
        //       Stocks/Futures/Options to make space for new ones.
        //     </p>,
        //   )
        // } else {
        //   showSnackbar(
        //     <p>
        //       The number of attempted deployments exceeds your remaining
        //       {' '}
        //       <a href="https://help.streak.tech/deployed/" className={styles.deployLimitLink} target="_blank" rel="noreferrer">deployment limit.</a>
        //       {' '}
        //       Please stop previously deployed Stocks/Futures/Options to make space for new ones.
        //     </p>,
        //   )
        // }
      } else {
        showSnackbar('Deployment limit reached.')
        // toggleAuthGreeting(PRO_FEATURE_MAP.DP_LIMIT.ID)
      }
      return
    }
    this.setState({ deployPressed: true }, this.deployHandler)
    if (activeTourIndex === 4) {
      updateTourIndex(5)
    }
  }

  renderDeployBtn = () => {
    const {
      styles, isDeploying, activeTourIndex, startTour,
    } = this.props
    const { deployPressed, deployment_type } = this.state
    const isDeployBtnDisabled = isDeploying || deployPressed
    const finalBtnDisabled = startTour && activeTourIndex === 3 ? true : isDeployBtnDisabled
    return (
      <Button
        onPress={this.onDeployPress}
        buttonColor={this.btnColorMap[deployment_type].toLowerCase()}
        disabled={finalBtnDisabled}
        loading={isDeployBtnDisabled}
        btnStyles={styles.deployBtn}
      >
        <Transition
          items={deployment_type}
          config={{
            mass: 5, tension: 2000, friction: 200,
          }}
          {...TRANSITION_PROPS[deployment_type]}
        >

          {(props, item) => <CustomText weight="medium" color="white" style={props}>{this.btnTextMap[item]}</CustomText>}
        </Transition>
      </Button>
    )
  }

  deployHandler = () => {
    const {
      publishing_uuid = '',
      navigation,
      deployStocks,
      isDeploying,
      max_allocation,
      create_plus,
      startTour,
      user_details,
    } = this.props
    const { subscription_plan } = user_details
    const {
      algo_cycle,
      deployment_type,
      stocksQuantity = {},
      // stocksQuantityErr: quantityErrList,
      holding_type,
      stocks,
      variety,
      algo_live_for,
      stocksMaxAllocation,
      stocksMaxAllocationErr,
      position_sizing_type,
      selectedSymbols,
      order_type,
      default_price,
      buffer,
    } = this.state
    if (isDeploying) {
      return
    }
    const finalStockMaxAllocation = subscription_plan === 'free' && startTour
      ? Object.fromEntries(
        Object.entries(stocksMaxAllocation).slice(0, 2),
      )
      : stocksMaxAllocation
    const finalstocksQuantity = subscription_plan === 'free' && startTour
      ? Object.fromEntries(
        Object.entries(stocksQuantity).slice(0, 2),
      )
      : stocksQuantity
    const selectedStocks = Array.from(selectedSymbols)
    const finalSelectedStocks = subscription_plan === 'free' && startTour ? selectedStocks.slice(0, 2) : selectedStocks

    if (stocks.length === 0 || finalSelectedStocks.length === 0) {
      showSnackbar('Please select at least one stock to deploy')
      navigation.pop()
      return
    }
    const isValidDeploymentType = this.deploymentTypeMap.some(
      item => item.value === deployment_type,
    )
    if (!isValidDeploymentType) {
      showSnackbar('Please Select a deployment Type')
      this.setState({
        invalidDeploymentType: true,
        deployPressed: false,
      })
      return
    }

    // if (editName && !name) {
    //   this.setState({ nameErr: true })
    //   return
    // }
    const stocksQuantityErr = {}
    // const quantityErr = false
    const stocksAmount = max_allocation ? finalStockMaxAllocation : finalstocksQuantity
    const stocksAmountErr = max_allocation ? stocksMaxAllocationErr : stocksQuantityErr
    const modAmountErr = { ...stocksAmountErr }
    let amountErr = false
    finalSelectedStocks.forEach((item) => {
      const value = stocksAmount[item]
      if (value <= 0 || !/^[0-9]*$/g.test(value)) {
        amountErr = true
        modAmountErr[item] = true
      }
    })
    if (amountErr) {
      if (max_allocation) {
        this.setState({ stocksMaxAllocationErr: modAmountErr, deployPressed: false })
      } else {
        this.setState({ stocksQuantityErr: modAmountErr, deployPressed: false })
      }

      showSnackbar('Please enter a valid quantity')
      return
    }
    if (order_type === ORDER_TYPE.LIMIT && create_plus) {
      if (!buffer && buffer !== 0) {
        showSnackbar('Please enter a valid buffer')
        this.setState({
          deployPressed: false,
        })
        return
      }
      if (!default_price) {
        showSnackbar('Please select a default price')
        this.setState({
          deployPressed: false,
        })
        return
      }
    }
    let live_period = 1
    if (holding_type !== 'MIS') {
      live_period = 30
    }
    const f_variety = holding_type_map[holding_type] !== 'MIS' ? 'REGULAR' : variety

    const params = {
      deployment_type,
      live_period,
      frequency: parseInt(algo_cycle, 10) - 1,
      holding_type: holding_type_map[holding_type],
      algo_live_for,
      variety: f_variety,
      position_sizing_type,
      max_allocation,
      order_type,
      default_price,
      buffer,
    }
    const extraParams = {
      publishing_uuid,
      selectedStocks: finalSelectedStocks,
      // quantity,
      stocksQuantity: finalstocksQuantity,
      mcx: this.mcx,
      stocksMaxAllocation: finalStockMaxAllocation,
      // name,
    }
    deployStocks(params, extraParams, this.gaParamsMap)
  }

  renderCurrentBroker = () => {
    const { connectedBrokers, styles, fancyBroker } = this.props
    const currentBroker = connectedBrokers.filter(item => item.currentBroker)
    let view = null
    if (!isEmpty(fancyBroker)) {
      const { label, logo } = fancyBroker
      view = (
        <img src={logo} alt={`${label} logo`} className={styles.logoImg} />
      )
    } else if (!currentBroker[0]) {
      view = (
        <>
          <Icon name={ICONS.ADD} size={12} color="orange" />
          <CustomText color="orange" weight="medium" style={{ marginLeft: SPACING.SPACE_8 }}>Add Account</CustomText>
        </>
      )
    } else {
      const { label, logo } = currentBroker[0]
      view = (
        <img src={logo} alt={`${label} logo`} className={styles.logoImg} />
      )
    }
    return (
      <div className={styles.currentBroker}>
        <button
          type="button"
          onClick={() => {
            this.toggleModal('linkBrokerLogin')
          }}
          className={styles.currentBrokerBtn}
        >
          {view}
        </button>
      </div>
    )
  }

  toggleAbLogin = (addBroker) => {
    this.addBroker = addBroker
    this.setState({ showABLogin: true })
  }

  render() {
    const {
      isDeploying, stocks = [], styles, onBackPress, isMobile, deployed,
      deployed_seg_sym, startTour, user_details,
    } = this.props
    const {
      termsAccepted, deployPressed, selectedSymbols, termsError,
      deployment_type, linkBrokerLogin, showABLogin,
    } = this.state
    const { subscription_plan } = user_details
    const modDeployedStocks = getStocksToDeploy(deployed, deployed_seg_sym)
    const finalStocks = stocks.filter(item => !modDeployedStocks.includes(item))
    const isDeployBtnDisabled = isDeploying || deployPressed
    return (
      <section className={styles.container}>
        <ABLoginContainer
          visible={showABLogin}
          dismissKey="showABLogin"
          onDismiss={this.toggleModal}
          loginProps={{
            addBroker: this.addBroker,
            authModal: true,
          }}
        />
        <CustomModal
          visible={linkBrokerLogin}
          onDismiss={this.toggleModal}
          dismissKey="linkBrokerLogin"
          position="right"
          containerStyles={styles.linkBrokerModal}
        >
          <LinkBrokerLogin
            onDismiss={this.toggleModal}
            dismissKey="linkBrokerLogin"
            toggleAbLogin={this.toggleAbLogin}
            subText="To take live positions or to view your existing positions, you will need to link at least one of your stock broking accounts."
          />
        </CustomModal>
        <div className={styles.header}>
          {isMobile && (
            <button
              type="button"
              onClick={onBackPress}
              className={styles.backBtn}
            >
              <Icon name={ICONS.BACK} color={theme.text} size={16} />
            </button>
          )}
          <CustomText weight="semi_bold" style={{ flex: 1 }} size="large">Deploy</CustomText>
          {this.renderCurrentBroker()}
        </div>
        <div className={styles.content}>
          <div className={styles.stocksSection}>
            <div className={styles.stockSectionHeader}>
              <div className={styles.stockSectionTitle}>
                <Dot size={14} color={COLORS.ORANGE} styles={{ marginRight: SPACING.SPACE_10 }} />
                <CustomText size="medium" weight="semi_bold">Instruments</CustomText>
              </div>
              <div className={styles.stocksCount}>
                <CustomText weight="semi_bold">{subscription_plan === 'free' && startTour ? 2 : selectedSymbols.size}</CustomText>
                <CustomText weight="medium" color="textSecondary">
                  /
                  {subscription_plan === 'free' && startTour ? 2 : finalStocks.length}
                </CustomText>
              </div>
            </div>
            <div className={styles.stocks}>
              {this.renderStocks()}
            </div>
          </div>
          <div className={styles.optionsSection}>
            <div className={styles.optionsSectionWrapper}>
              <div className={styles.deployTypeSection}>
                <div
                  className={styles.deployTypeRow}
                  id={isMobile ? 'tour_deploy_4' : ''}
                >
                  <Dot size={14} color={COLORS.GREEN} styles={{ marginRight: SPACING.SPACE_10 }} />
                  <CustomText weight="semi_bold" style={{ flex: 1 }}>Deployment Type</CustomText>
                  {/* <CustomHorizontalTab
                  tabs={this.deploymentTypeMap}
                  tabChangeHandler={this.toggleDeploymentType}
                  defaultActiveTab={deployment_type}
                  dragBtnStyles={styles.toggleDragBtn}
                  style={styles.tabContainer}
                  tabStyles={styles.tabBtn}
                  labelRenderer={this.renderDeploymentType}
                /> */}
                </div>
                <SectionTab
                  tabs={this.deploymentTypeMap}
                  activeTab={deployment_type}
                  tabChangeHandler={this.toggleDeploymentType}
                  style={styles.sectionTabContainer}
                  id={!isMobile ? 'tour_deploy_4' : ''}
                />
              </div>
              <div className={styles.options}>
                <div className={styles.optionsHeader}>
                  <Dot size={14} color={COLORS.PURPLE} styles={{ marginRight: SPACING.SPACE_10 }} />
                  <CustomText weight="semi_bold">Options</CustomText>
                </div>
                <div className={styles.optionsField}>
                  {this.renderOptionsField()}
                </div>
              </div>
            </div>
            <div className={styles.actionBar}>
              <div className={styles.terms}>
                <CustomCheckbox
                  disabled={isDeployBtnDisabled}
                  value={termsAccepted}
                  onValueChange={this.toggleTerms}
                  id="deploy_terms"
                />
                <CustomText size="small" style={{ marginLeft: SPACING.SPACE_10 }}>
                  I accept the&nbsp;
                  <a href={termsPage} rel="noreferrer" target="_blank">trading terms</a>
                </CustomText>
              </div>
              {termsError && (
                <CustomText size="small_1" color="red" style={{ marginTop: SPACING.SPACE_4 }}>
                  Accept terms to continue
                </CustomText>
              )}
              <div
                className={styles.tradingText}
              >
                <CustomText
                  size="small_1"
                  color={theme.textSecondary}
                  style={{ marginTop: SPACING.SPACE_4 }}
                  className={`${styles.shortDesc}`}
                >
                  {tradingText[deployment_type]}
                </CustomText>
                <CustomTooltip
                  show
                  tooltipProps={{ text: tradingText[deployment_type] }}
                >
                  <button
                    type="button"
                    style={{ marginTop: '2px' }}
                  >
                    <CustomText
                      color={theme.linkColor}
                      weight="semi_bold"
                    >
                      Read More
                    </CustomText>
                  </button>
                </CustomTooltip>
              </div>
              <div id="tour_deploy_5">
                {this.renderDeployBtn()}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const stylesheet = ({
  container: {
    padding: `${SPACING.SPACE_24} ${SPACING.SPACE_28} 0 `,
    overflow: 'hidden',
    height: '100%',
    boxSizing: 'border-box',
  },
  content: {
    display: 'flex',
    marginTop: SPACING.SPACE_20,
    overflow: 'hidden',
    height: 'calc(100% - 55px)',
  },
  stocksSection: {
    borderRight: `1px solid ${theme.borderColor}`,
    width: '50%',
  },
  stockSectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: SPACING.SPACE_28,
  },
  stockSectionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  stocksCount: {
    display: 'flex',
    alignItems: 'center',
  },
  stocks: {
    padding: `0 ${SPACING.SPACE_28} ${SPACING.SPACE_10}`,
    overflow: 'auto',
    height: 'calc(100% - 45px)',
  },
  stockRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: SPACING.SPACE_20,
  },
  stocksInputContainer: {
  },
  stocksInput: {
    maxWidth: 80,
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_4} !important`,
  },
  optionsSection: {
    width: '50%',
    overflow: 'auto',
    height: '100%',
  },
  optionsSectionWrapper: {
    width: '80%',
    margin: 'auto',
  },
  deployTypeSection: {
  },
  deployTypeRow: {
    display: 'flex',
    alignItems: 'center',
  },
  tabContainer: {
    padding: `${SPACING.SPACE_4} !important`,
    border: `1px solid ${theme.borderColor}`,
  },
  tabBtn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_12}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toggleDragBtn: {
    borderRadius: 8,
  },
  options: {
    marginTop: SPACING.SPACE_20,
  },
  optionsHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  optionsField: {
    padding: `0 0 0 ${SPACING.SPACE_20}`,
    borderLeft: `1px solid ${theme.borderColor}`,
    marginTop: SPACING.SPACE_2,
    marginLeft: SPACING.SPACE_6,
    paddingBottom: SPACING.SPACE_20,
  },
  dropdownContainer: {
    marginTop: SPACING.SPACE_16,
  },
  dropdownInput: {
    borderColor: `${theme.borderColor} !important`,
    minHeight: 38,
    borderRadius: 6,
  },
  actionBar: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.bgPrimary,
    padding: `0 0 ${SPACING.SPACE_20} 0`,
    width: '80%',
    margin: 'auto',
  },
  terms: {
    display: 'flex',
    alignItems: 'center',
  },
  deployBtn: {
    width: '100%',
    // marginLeft: SPACING.SPACE_8,
    overflowX: 'hidden',
    marginTop: SPACING.SPACE_10,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  bufferInput: {},
  labelStyles: {
    marginBottom: SPACING.SPACE_4,
  },
  currentBrokerBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_10}`,
  },
  linkBrokerModal: {
    height: '100%',
  },
  logoImg: {
    maxHeight: 20,
  },
  sectionTabContainer: {
    maxWidth: 180,
    margin: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
  },
  qtyLabel: {
    fontSize: `${FONTS.TINY} !important`,
    margin: `0 0 ${SPACING.SPACE_2} ${SPACING.SPACE_2}`,
  },
  deployLimitLink: {
    color: 'inherit',
    fontWeight: '800',
    textDecoration: 'underline',
  },
  tradingText: {
    display: 'flex',
    alignItems: 'center',
  },
  shortDesc: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '70%',
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      padding: 0,
    },
    backBtn: {
      paddingRight: SPACING.SPACE_12,
      paddingLeft: SPACING.SPACE_14,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      height: SPACING.HEADER_HEIGHT_MOBILE,
      boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    },
    content: {
      margin: SPACING.SPACE_20,
      overflow: 'auto',
      flexDirection: 'column',
    },
    stocksSection: {
      width: '100%',
      borderRight: 0,
    },
    optionsSection: {
      width: '100%',
      overflow: 'unset',
    },
    optionsSectionWrapper: {
      margin: `${SPACING.SPACE_20} 0`,
      width: `calc(100% - ${SPACING.SPACE_28})`,
    },
    stocks: {
      height: 'unset',
    },
    actionBar: {
      width: '100%',
      bottom: SPACING.SPACE_20,
    },
  },
})

export default withTheme(stylesheet)(Deploy)
