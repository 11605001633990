import React, { lazy } from 'react'
import {
  Route,
} from 'react-router-dom'

import pathname_mapping from './homepage/homeutils/navigationConstant'
import { BROKERS } from './utils/consts'

const HomePage = lazy(() => import('./homepage/containers/Home/Home-redux'))
const NewHomePage = lazy(() => import('./homepage/containers/HomePageNew/HomePageNew-redux'))
const TermsPage = lazy(() => import('./homepage/containers/Terms/Terms'))
const DislosurePage = lazy(() => import('./homepage/containers/Disclosure/Disclosure'))
const PrivacyPage = lazy(() => import('./homepage/containers/Privacy/Privacy'))
// const DemoPage = lazy(() => import('./homepage/containers/Demo/Demo'))
const CtbPage = lazy(() => import('./homepage/containers/Ctb/CtbPage'))

const brokerHomepage = []

Object.values(BROKERS)
  .map((broker) => {
    if (broker.homepagePath) {
      brokerHomepage.push({ Component: HomePage, path: broker.homepagePath, exact: true })
    }
    return null
  })

const routes = [
  // { Component: HomePage, path: pathname_mapping.Home, exact: true },
  { Component: NewHomePage, path: pathname_mapping.SystematicTrading, exact: true },
  { Component: TermsPage, path: pathname_mapping.Terms, exact: true },
  { Component: DislosurePage, path: pathname_mapping.Disclosure, exact: true },
  { Component: PrivacyPage, path: pathname_mapping.Privacy, exact: true },
  // { Component: DemoPage, path: pathname_mapping.Demo, exact: true },
  { Component: CtbPage, path: pathname_mapping.Ctb, exact: true },
  ...brokerHomepage,
]

const HomeRoutes = ({ domainChange, toggleLoginModal }) => {
  return (
    routes.map((item) => {
      const { path, exact, Component } = item
      return (
        <Route
          key={path}
          exact={exact}
          path={path}
          render={props => (
            // pass the sub-routes down to keep nesting
            <Component {...props} domainChange={domainChange} toggleLoginModal={toggleLoginModal} />
          )}
        />
      )
    })
  )
}

export default HomeRoutes
