import React, { useRef } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'

import CustomModal from './CustomModal'
import CustomText from './CustomText'
import Button from './Button'

import { SOCIAL_HANDLER } from '../utils/consts'
import {
  DIMENSIONS, SPACING, theme, FONTWEIGHT,
} from '../Theme'
import { showSnackbar } from './Snackbar'
import CustomInput from './CustomInput'

const ShareModal = React.memo(({
  url,
  visible,
  onDismiss,
  dismissKey,
  isDark,
  embedCode,
}) => {
  const styles = stylesheet()
  const shareLinkRef = useRef(null)
  const onPress = () => {
    window.open(`https://twitter.com/intent/tweet?&text=Hey, checkout these backtest results on ${SOCIAL_HANDLER.TWITTER.handler}
    ${url}`, '_blank')
  }
  const width = 1200
  const height = 448
  const shareableText = `<iframe src="${url}" title="Streak Backtest Results" width="${width}" height="${height}" 
  style="border: 0; border-radius: 4px; box-shadow: 0px 1px 6px 3px #f3f3f3;" />`
  const copyToClipboard = () => {
    showSnackbar('Link copied to clipboard')
    if (shareLinkRef && shareLinkRef.current) {
      const ele = shareLinkRef.current
      ele.select()
      ele.setSelectionRange(0, 99999)
      document.execCommand('copy')
    }
  }
  return (
    <CustomModal
      visible={visible}
      onDismiss={onDismiss}
      dismissKey={dismissKey}
      className={styles.modal}
    >
      <div className={styles.container}>
        <CustomText weight="semi_bold" size="medium">Shareable Link</CustomText>
        <button
          type="button"
          onClick={copyToClipboard}
          className={styles.inputBtn}
        >
          <CustomInput
            // className={styles.input}
              // color={isDark ? 'white' : 'blue'}
            inputStyles={styles.input}
            inputRef={shareLinkRef}
            value={embedCode ? shareableText : url}
            iconName="COPY"
            iconPosition="end"
            size={14}
            onChangeText={() => {}}
            inputProps={{
              readOnly: true,
            }}
          />
        </button>

        <div className={styles.actionBar}>
          <Button
            text="Cancel"
            className={styles.cancelBtn}
            isDark={isDark}
            onPress={onDismiss}
            params={dismissKey}
            buttonColor="tabsBg"
            mode="text"
            labelColor="textSecondary"
          />
          <Button
            text="Tweet"
            className={styles.tweetBtn}
            isDark={isDark}
            buttonColor="blue"
            onPress={onPress}
          />
        </div>
      </div>
    </CustomModal>
  )
})

const stylesheet = makeStyles({
  modal: {
    alignSelf: 'center',
  },
  container: {
    paddingTop: DIMENSIONS.SPACE_VERTICAL * 2,
    paddingBottom: DIMENSIONS.SPACE_VERTICAL,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputBtn: {
    marginTop: SPACING.SPACE_24,
    marginBottom: SPACING.SPACE_16,
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    border: `1px solid ${theme.borderColor}`,
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_8}`,
    justifyContent: 'space-between',
    borderRadius: 6,
    alignItems: 'center',
    backgroundColor: theme.screenBg,
  },
  input: {
    color: theme.linkColor,
    fontWeight: FONTWEIGHT.MEDIUM,
  },
  copyIcon: {
    marginLeft: SPACING.SPACE_16,
    borderLeftColor: theme.borderColor,
    borderLeftWidth: 1,
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: SPACING.SPACE_20,
    '& button': {
      // width: '',
      flex: 1,
      padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
      border: '1px solid transparent',
      marginLeft: SPACING.SPACE_20,
      '&:first-child': {
        margin: 0,
      },
    },
  },
  tweetBtn: {
  },
})

export default ShareModal
