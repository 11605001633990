import React from 'react'

import { STEP_CONDITION_TYPE } from '../../containers/Screener/Screener-utils'
import { SPACING, theme } from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import Button from '../../UI/Button'
import CustomText from '../../UI/CustomText'
import Steps from '../../UI/Steps'

function ScreenerMulti(props) {
  const {
    stepsCompleted, saveDisabled, btnText,
    activeIndex, onStepPress, styles, renderContent,
    isScreenerLive, stopAlert, isScanning, isSavingScreen, runSave,
    updateStep, conditionEditType, activeTourIndex, startTour, isMobile,
  } = props

  const ptCreateId = activeTourIndex === 3 ? 'tour_scan_create_4_clicker' : 'tour_scan_create_8'
  const ptCreateFocusMapId = activeTourIndex === 3 ? 'tour_scan_create_4_1' : ''
  return (
    <>
      <Steps
        steps={STEP_CONDITION_TYPE}
        defaultStepIndex={activeIndex}
        onStepPress={onStepPress}
        stepsCompleted={Array.from(stepsCompleted)}
        containerSytles={styles.stepsContainer}
      />
      <div className={styles.listView}>
        {renderContent(false, conditionEditType)}
      </div>
      <div
        className={styles.actionBar}
        id={ptCreateFocusMapId}
        style={{
          zIndex: startTour ? 9999 : 99,
          // backgroundColor: startTour ? 'none' : theme.bgTertiary,
          display: isMobile && activeTourIndex === 6 ? 'none' : 'block',
        }}
      >
        {isScreenerLive ? (
          <CustomText size="tiny" weight="regular" className={styles.noSaveNote}>
            Changes made to the scanner will not be saved.
            Please
            <CustomText color="red" extraProps={{ onPress: stopAlert }}>{' stop '}</CustomText>
            the alert to save changes.
          </CustomText>
        ) : null}
        <span id={ptCreateId} style={{ padding: startTour ? 20 : 0 }}>
          <Button
            id={ptCreateId}
            roundness={6}
            mode="contained"
            buttonColor="BLUE"
            onPress={updateStep} // scanSaveHandler
            btnStyles={styles.confirmBtn}
            loading={isScanning || (isSavingScreen && runSave)}
            disabled={saveDisabled}
            text={btnText}
          />
        </span>
      </div>
    </>
  )
}

const stylesheet = ({
  stepsContainer: {
    margin: `${SPACING.SPACE_12} 0 ${SPACING.SPACE_34}`,
    padding: `${SPACING.SPACE_20} 0`,
    zIndex: 9998,
    top: 106,
    position: 'sticky',
    backgroundColor: theme.screenBg,
  },
  actionBar: {
    padding: `${SPACING.SPACE_24} 0`,
    textAlign: 'right',
  },
  listView: {},
  '@media only screen and (max-width: 786px)': {
    actionBar: {
      position: 'fixed',
      bottom: '55px',
      // background: theme.bgTertiary,
      width: '100%',
      left: '0',
      zIndex: 99,
      padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
      '& > button': {
        width: '100%',
      },
    },
    stepsContainer: {
      position: 'sticky',
      top: '28px',
      zIndex: 999,
      backgroundColor: theme.screenBg,
    },
  },
})

export default withTheme(stylesheet)(ScreenerMulti)
