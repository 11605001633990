/* eslint-disable max-len */
import React, { useState } from 'react'
import Slider from './Slider'

import HOME_ASSETS from '../assets'
// import { DISPLAY_DEVICE } from '../../utils/consts'

const testimonialData = [
  {
    comment: 'If you are a systematic trader trading manually and suffering from greed and fear Streak is for you. Deploy it stick to it and you will not be disappointed.I am very happy with what streak has to offer.', name: 'Anupam Ahuja', profileImg: HOME_ASSETS.TestimonialUser2, bgColor: '#F3F6FF', txtColor: '#021931',
  },
  {
    comment: 'This is the platform that i find gives me an edge in building executing and following my thoughts process that is converted into an easy to create strategies. Beauty of streak lies in its ease of doing.', name: 'Sathyaraj Shettigar', profileImg: HOME_ASSETS.TestimonialUser1, bgColor: '#fef3ff', txtColor: '#300231',
  },
  {
    comment: 'Using streak for more than 4 year. It helped in my financial journey. After using many application this is my final destination. Try it use it then trust it.', name: 'Mukesh Sinha', profileImg: HOME_ASSETS.TestimonialUser3, bgColor: '#f3fff7', txtColor: '#023114',
  },
  {
    comment: 'Firstly, thanks to the Streak team for creating such an amazing platform. New UI and new updates have been really helpful. The Streak telegram team is really good. Queries and issues are solved promptly and efficiently.', name: 'Raniel Gomes', profileImg: HOME_ASSETS.TestimonialCommonUser, bgColor: '#fefff3', txtColor: '#2f3102',
  },
  {
    comment: 'Looks like the Bulls are preparing themselves for the next run. Streak is an excellent platform to create unique technical scans and strategies.', name: 'Dr Ameya Kale', profileImg: HOME_ASSETS.TestimonialCommonUser, bgColor: '#fff9f3', txtColor: '#311d02',
  },
  {
    comment: 'Booked 20% profit on capital from march to april 4th... after switching to streak from jan, faced some losses initially..but finally learned system trading and got beautiful results!', name: 'Ari Sam', profileImg: HOME_ASSETS.TestimonialCommonUser, bgColor: '#fff3f3', txtColor: '#310202',
  },
]

const Testimonials = React.memo(({ isMobile, displayDevice }) => {
  const [current, setCurrent] = useState(0)
  const [rollTo, setRollTo] = useState('')
  // const bgImg = DISPLAY_DEVICE.SMALL_MOBILE.valueKey ? HOME_ASSETS.TestimonialBackgroundMobile : isMobile ? HOME_ASSETS.TestimonialBackgroundTab : HOME_ASSETS.TestimonialBackground
  // const bgImg = displayDevice === DISPLAY_DEVICE.DESKTOP.valueKey ? HOME_ASSETS.TestimonialBackground : displayDevice === DISPLAY_DEVICE.TAB.valueKey
  //   ? HOME_ASSETS.TestimonialBackgroundTab : HOME_ASSETS.TestimonialBackgroundMobile

  const onNextClick = () => {
    setRollTo('rollToRight')
    if(current === 5) {
      setCurrent(0)
    }else{
      setCurrent(current + 1)
    }
  }

  const onPrevClick = () => {
    setRollTo('rollToLeft')
    if(current === 0) {
      setCurrent(5)
    }else{
      setCurrent(current - 1)
    }
  }

  return (
  // <div className="planContainer" style={{ marginBottom: '20px', backgroundImage: `url(${bgImg})` }}>
  //   {/* <img src={displayDevice === DISPLAY_DEVICE.SMALL_MOBILE.valueKey ? HOME_ASSETS.TestimonialBackgroundMobile : isMobile ? HOME_ASSETS.TestimonialBackgroundTab : HOME_ASSETS.TestimonialBackground} className="testimonialBackgroundImg" alt="testimonial_background" /> */}
  //   <div className="testimonialFeaturesContainer" style={{ display: 'flex', width: '100%' }}>
  //     <div className="bannerLeft" style={{ textAlign: 'center' }}>
  //       {/* <h4 style={{ color: 'white' }}>
  //         TESTIMONIALS
  //       </h4> */}
  //       <h2 style={{ color: 'white' }}>What our users say about us</h2>
  //     </div>
  //     <Slider carouselData={testimonialData} isMobile={isMobile} displayDevice={displayDevice} />
  //   </div>
  // </div>
    <div className="testimonialWrapper">
      <div className="testimonialFeaturesContainer">
        <h1 className="startedTitle" style={{ fontSize: `${isMobile && '30px'}`, padding: `${isMobile && '0px 30px'}` }}>What our users say about us...</h1>
        {isMobile ? (
          <div className="testimonialDisplayMobile">
            <div className={`testimonialItemMobile ${rollTo}`} style={{ backgroundColor: testimonialData[current].bgColor, color: testimonialData[current].txtColor }} key={testimonialData[current].name}>
              <div className="testimonialUserDetails">
                <img src={testimonialData[current].profileImg} alt="user_img" className="testimonialUserImg" />
                <p className="testimonialUserName">{testimonialData[current].name}</p>
              </div>
              <p className="comment">
                <img src={HOME_ASSETS.QouteLeft} alt="qoute" className="qoutes" />
                {testimonialData[current].comment}
                <img src={HOME_ASSETS.QouteRight} alt="qoute" className="qoutes" />
              </p>
            </div>
            <div className="arrowBtnsContainer" style={{ width: '30%', margin: 'auto' }}>
              <button
                type="button"
                onClick={onPrevClick}
                className="left-arrow"
              >
                <img src={HOME_ASSETS.BackwardArrow} alt="back" />
              </button>
              <button
                type="button"
                onClick={onNextClick}
                className="right-arrow"
              >
                <img src={HOME_ASSETS.ForwardArrow} alt="forward" />
              </button>
            </div>
          </div>
        ) : <Slider carouselData={testimonialData} isMobile={isMobile} displayDevice={displayDevice} />}
      </div>
    </div>
  )
})

export default Testimonials
