import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'

import AutoComplete from './AutoComplete'

import { getStocks, getDefaultStocks } from '../../containers/Create/actions'

const AutoCompleteBase = ({
  fetchStocks,
  user_details,
  onSelect,
  stocks,
  selected = '',
  defaultLabel = '',
  placeholder = '',
  inputTextStyles,
  inputStyles,
  blockedSegmentList = [],
  itemBtnStyles = '',
  getDefaultSymbols,
}) => {
  const [value, updateValue] = useState('')
  const debouncedHandler = useCallback(debounce(v => updateStocks(v), 150), [])
  const updateStocks = (stockQuery) => {
    const { user_uuid } = user_details
    const headers = { 'content-type': 'application/json' }
    fetchStocks(stockQuery.trim(), headers, user_uuid, false)
  }

  const toggleCallback = (isVisible) => {
    if (isVisible) {
      const { user_uuid } = user_details
      getDefaultSymbols({ user_uuid })
    }
  }
  const onSearch = (e) => {
    const { value: stockQuery } = e.target
    updateValue(stockQuery)
    debouncedHandler(stockQuery)
  }
  return (
    <AutoComplete
      onSearch={onSearch}
      searchValue={value}
      options={stocks}
      onSelect={onSelect}
      selected={selected}
      defaultLabel={defaultLabel}
      placeholder={placeholder}
      inputTextStyles={inputTextStyles}
      inputStyles={inputStyles}
      itemBtnStyles={itemBtnStyles}
      blockedSegmentList={blockedSegmentList}
      toggleCallback={toggleCallback}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    ...state.auth.loginResp,
    user_details: state.auth.user_details,
    blockedSegmentList: state.auth.blockedSegmentList,

    stocks: state.create.stocks,
    isFetchingStocks: state.create.isFetchingStocks,
    stocksError: state.create.stocksError,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStocks: (
      params, headers, user_uuid, baskets, exchanges,
    ) => dispatch(getStocks(params, headers, user_uuid, baskets, exchanges)),
    getDefaultSymbols: params => dispatch(getDefaultStocks(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteBase)
