import {
  takeLatest, put, delay, call,
} from 'redux-saga/effects'
import { postRequest, getRequest } from '../../utils/apiRequests'
import {
  FETCH_FILE,
  GENERATE_OTP_INIT,
  REQUEST_DEMO_CALL,
  SAVE_PROFILE_INIT,
  UPDATE_PROFILE_INIT,
  UPLOAD_FILE,
  SHOULD_REQUEST_DEMO,
  CHANGE_PASSWORD_INIT,
  REMOVE_PROFILE,
  UPDATE_TRIAL_PLAN,
  SUBMIT_FEEDBACK,
  VERIFY_PHONE_NUMBER,
  FETCH_SHAREABLE_LINKS,
  DELETE_SHAREABLE_LINKS,
} from './actionTypes'
import {
  saveProfileSuccess,
  saveProfileFailure,
  generateOTPSuccess,
  generateOTPFailure,
  requestDemoCallSuccess,
  requestDemoCallFailure,
  updateProfileSuccess,
  updateProfileFailure,
  fetchFileSuccess,
  fetchFileFailure,
  uploadFileSuccess,
  uploadFileFailure,
  isDemoRequested,
  changePasswordSuccess,
  changePasswordFailure,
  removeProfilePicSuccess,
  removeProfilePicFailure,
  updateTrialPlanSuccess,
  updateTrialPlanFailure,
  submitFeedbackSuccess,
  submitFeedbackFailure,
  verifyPhoneNumberSuccess,
  verifyPhoneNumberFailure,
  fetchShareableLinksSuccess,
  fetchShareableLinksFailure,
  deleteShareableLinksSuccess,
  deleteShareableLinksFailure,
} from './actions'

import {
  baseUrl, prodEndpoints, shareableLinks, supportUrl,
} from '../../utils/apiEndpoints'
import { GET_USER_DETAILS_INIT } from '../Auth/actionTypes'
import { error_msg } from '../../utils/common'

function* saveProfile(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.save_phone_number}`
    const data = yield call(postRequest, url, params, headers)
    if (data.status === 'success') {
      yield put(saveProfileSuccess(data))
      yield put({ type: GET_USER_DETAILS_INIT })
    } else {
      yield put(saveProfileFailure(data.msg || 'Error saving profile'))
    }
  } catch (error) {
    yield put(saveProfileFailure(error))
  }
}

export function* saveProfileSaga() {
  yield takeLatest(SAVE_PROFILE_INIT, saveProfile)
}

function* generateOTP(action) {
  try {
    const { params, headers } = action
    let finalParams = params
    let url = `${baseUrl}${prodEndpoints.generate_otp}`
    if (params.csrfmiddlewaretoken === 'getOtpHome') {
      url = `${baseUrl}${prodEndpoints.generate_otp2}`
      finalParams = { phone_number: params.phone_number, email: params.email }
    }
    const data = yield call(postRequest, url, finalParams, headers)
    if (data.status === 'success' && data.ui_verif_otp) {
      const isHomePageVerify = params.csrfmiddlewaretoken === 'getOtpHome'
      const verifyOtpParams = {
        otp: data.ui_verif_otp,
        phone_number: finalParams.phone_number,
      }
      if (isHomePageVerify) {
        verifyOtpParams.email = finalParams.email
      } else {
        verifyOtpParams.csrfmiddlewaretoken = finalParams.csrfmiddlewaretoken
      }
      const verifyRndpoint = isHomePageVerify ? VERIFY_PHONE_NUMBER : SAVE_PROFILE_INIT
      yield put({ type: verifyRndpoint, params: verifyOtpParams })
    } else if (data.status === 'success') {
      yield put(generateOTPSuccess(data))
    } else {
      yield put(generateOTPFailure('Error generating OTP'))
    }
  } catch (error) {
    yield put(generateOTPFailure(error))
  }
}

export function* generateOTPSaga() {
  yield takeLatest(GENERATE_OTP_INIT, generateOTP)
}

function* requestDemoCall(action) {
  try {
    const { params, headers } = action
    const url = `${supportUrl}${prodEndpoints.request_demo}`
    const data = yield call(postRequest, url, params, headers, false)
    if(data.data === 'Ok') {
      yield put(requestDemoCallSuccess('Demo Requested'))
    } else if(data.error_msg === 'Demo already scheduled') {
      // yield put(requestDemoCallSuccess())
      yield put(requestDemoCallFailure(data.error_msg))
    } else {
      yield put(requestDemoCallFailure(data.error_msg))
    }
  } catch (error) {
    yield put(requestDemoCallFailure(error))
  }
}

export function* requestDemoCallSaga() {
  yield takeLatest(REQUEST_DEMO_CALL, requestDemoCall)
}

function* shouldRequestDemo(action) {
  try {
    const { params, headers } = action
    const url = `${supportUrl}${prodEndpoints.check_request_demo}`
    const data = yield call(postRequest, url, JSON.stringify(params), headers, false)
    if (data.data && data.data !== 'pending') {
      yield put(isDemoRequested(data.error_msg))
    }
  } catch (error) {
    console.log(error.message)
  }
}

export function* shouldRequestDemoSaga() {
  yield takeLatest(SHOULD_REQUEST_DEMO, shouldRequestDemo)
}

function* updateProfile(action) {
  try {
    const { params, headers, additionalInfo } = action
    let url = `${baseUrl}${prodEndpoints.save_profile}`
    if (additionalInfo) {
      url = `${baseUrl}${prodEndpoints.update_additional_info}`
    }
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(updateProfileSuccess(data))
      yield put({ type: GET_USER_DETAILS_INIT })
    } else {
      yield put(updateProfileFailure(data.error_msg))
    }
  } catch (error) {
    yield put(updateProfileFailure(error))
  }
}

export function* updateProfileSaga() {
  yield takeLatest(UPDATE_PROFILE_INIT, updateProfile)
}

function* removeProfilePic(action) {
  try {
    const { params, headers } = action
    const url = `${prodEndpoints.remove_profile_pic}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.data === 'Ok') {
      yield put(removeProfilePicSuccess())
      yield put({
        type: FETCH_FILE,
        params: { user_uuid: params.user_uuid },
      })
    } else {
      yield put(removeProfilePicFailure(data.error_msg || 'No profile to remove'))
    }
  } catch (error) {
    yield put(removeProfilePicFailure(error))
  }
}

export function* removeProfilePicSaga() {
  yield takeLatest(REMOVE_PROFILE, removeProfilePic)
}

function* changePassword(action) {
  try {
    const { params, headers } = action
    const url = prodEndpoints.change_password
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(changePasswordSuccess(data))
    } else {
      yield put(changePasswordFailure(data.error_msg || error_msg))
    }
  } catch (e) {
    yield put(changePasswordFailure(e.message))
  }
}

export function* changePasswordSaga() {
  yield takeLatest(CHANGE_PASSWORD_INIT, changePassword)
}

function* uploadFile(action) {
  try {
    const { payload } = action
    const { params, fileObj } = payload
    const url = prodEndpoints.upload_profile_pic
    const formData = new FormData()
    formData.append('file', fileObj)
    formData.append('json', JSON.stringify(params))
    const data = yield call(postRequest, url, formData, {}, false)
    if (!data.error) {
      yield put(uploadFileSuccess())
      yield delay(1000)
      yield put({
        type: FETCH_FILE,
        params: { user_uuid: params.user_uuid },
      })
    } else {
      yield put(uploadFileFailure(data.error_msg))
    }
  } catch (err) {
    yield put(uploadFileFailure(err))
  }
}

export function* uploadFileSaga() {
  yield takeLatest(UPLOAD_FILE, uploadFile)
}

function* fetchFile(action) {
  try {
    const { params } = action
    const url = prodEndpoints.download_profile_pic
    // date added to remove fetching from cache
    const data = yield call(getRequest, url, { ...params, date: Date.now() }, {},
      { responseType: 'arraybuffer', 'Cache-Control': 'no-cache' }, true)
    if (data && typeof data === 'object' && !data.error && typeof data.data !== 'string') {
      const blob = new Blob([data.data], { type: data.contentType })
      yield put(fetchFileSuccess(window.URL.createObjectURL(blob)))
    } else if (data && data.err_code !== 400) {
      yield put(fetchFileFailure('Unable to fetch profile pic'))
    } else if (data && data.err_code === 400) {
      yield put(fetchFileFailure(''))
    }
  } catch (err) {
    yield put(fetchFileFailure(err))
  }
}

export function* fetchFileSaga() {
  yield takeLatest(FETCH_FILE, fetchFile)
}

function* updateTrialPlan(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.update_to_ultimate}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(updateTrialPlanSuccess(data))
    } else {
      yield put(updateTrialPlanFailure(data.error_msg))
    }
  } catch (err) {
    yield call(updateTrialPlanFailure(error_msg))
  }
}

export function* updateTrialPlanSaga() {
  yield takeLatest(UPDATE_TRIAL_PLAN, updateTrialPlan)
}

function* feedback(action) {
  try {
    const { params } = action
    const url = `${baseUrl}${prodEndpoints.feedback}`
    const data = yield call(postRequest, url, params, {})
    if (!data.error && data.status === 'success') {
      yield put(submitFeedbackSuccess(data))
    } else {
      yield put(submitFeedbackFailure(data.error_msg || 'Unable to send feedback'))
    }
  } catch (err) {
    yield put(submitFeedbackFailure(error_msg))
  }
}

export function* feedbackSaga() {
  yield takeLatest(SUBMIT_FEEDBACK, feedback)
}

function* verifyPhoneNumber(action) {
  try {
    const { params } = action
    const url = `${baseUrl}${prodEndpoints.verify_phone_number}`
    const data = yield call(postRequest, url, params, {})
    if (!data.error && data.status === 'success') {
      yield put(verifyPhoneNumberSuccess(data))
    } else {
      yield put(verifyPhoneNumberFailure(data.error_msg || 'Unable to send feedback'))
    }
  } catch (err) {
    yield put(verifyPhoneNumberFailure(error_msg))
  }
}

export function* verifyPhoneNumberSaga() {
  yield takeLatest(VERIFY_PHONE_NUMBER, verifyPhoneNumber)
}

function* fetchShareableLinks(action) {
  try {
    const { params } = action
    const url = shareableLinks
    const data = yield call(getRequest, url, params)
    if (data && data.status === 'success') {
      yield put(fetchShareableLinksSuccess(data, params))
    } else {
      yield put(fetchShareableLinksFailure('Unable to fetch shareable links'))
    }
  } catch (err) {
    yield put(fetchShareableLinksFailure(err))
  }
}

export function* fetchShareableSaga() {
  yield takeLatest(FETCH_SHAREABLE_LINKS, fetchShareableLinks)
}

function* deleteShareableLinks(action) {
  try {
    const { params, headers } = action
    const url = shareableLinks
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(deleteShareableLinksSuccess(data))
    } else {
      yield put(deleteShareableLinksFailure(data.error))
    }
  } catch(err) {
    yield put(deleteShareableLinksFailure(err.message || error_msg))
  }
}

export function* deleteShareableLinksSaga() {
  yield takeLatest(DELETE_SHAREABLE_LINKS, deleteShareableLinks)
}
