import makeStyles from '@material-ui/styles/makeStyles'
import React from 'react'

import CustomText from '../../UI/CustomText'

import {
  ASSETS, SPACING, COLORS, theme,
} from '../../Theme'

const SignUpSuccess = React.memo(() => {
  const styles = stylesheet()
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={ASSETS.VERIFY_EMAIL} className={styles.verifyImg} alt="verify_email" />
        <CustomText weight="semi_bold" size="large">Verify your email</CustomText>
        <CustomText className={styles.subText}>
          We have sent you a verification instructions on your email
        </CustomText>
      </div>
    </div>
  )
})

const stylesheet = makeStyles({
  container: {
    width: '100%',
    maxWidth: '380px',
    textAlign: 'center',
    padding: '40px 30px',
    borderRadius: '16px',
    // marginTop: '12%',
    backgroundColor: COLORS.WHITE,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    maxHeight: 250,
  },
  content: {
    width: '70%',
    margin: 'auto',
  },
  verifyImg: {
    marginBottom: SPACING.SPACE_20,
    maxWidth: 120,
  },
  subText: {
    marginTop: SPACING.SPACE_10,
  },
})

export default SignUpSuccess
