import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'

import CustomText from './CustomText'
import Icon from './Icon'

import {
  COLORS, SPACING, ICONS,
} from '../Theme'
import { useTheme } from '../Theme/ThemeProvider'

// const btnMap = [
//   { label: '', value: '', selectedColor: '', color: '', labelColor: '' }
// ]

const ToggleButton = ({
  toggleHandler,
  btnMap,
  selected,
  containerStyles,
  labelKey,
  valueKey,
  // isDark,
  // btnWidth = 78,
}) => {
  const styles = stylesheet()
  const { isDark } = useTheme()
  return (
    <div
      className={`${styles.row} ${containerStyles}`}
      // style={{ backgroundColor: COLORS[backgroundColor] }}
    >
      {btnMap.map((btnItem) => {
        const {
          selectedColor = isDark ? 'BLACK_80' : 'WHITE',
          color = isDark ? 'BLACK_100' : 'TABS_BG',
          labelColor = isDark ? 'BLACK_600' : 'TEXT',
          selectedLabelColor = isDark ? 'BLUE_300' : 'BLUE',
        } = btnItem
        const label = labelKey ? btnItem[labelKey] : btnItem.label
        const value = valueKey ? btnItem[valueKey] : btnItem.value
        const isSelected = selected.toLowerCase() === value.toLowerCase()
        return (
          <div key={value} className={`${styles.btnView}`}>
            {/* ${!isSelected && styles.hoverBtnView}`}> */}
            <button
              type="button"
              onClick={() => toggleHandler(value, color, btnItem)}
              className={styles.btn}
              style={{ backgroundColor: COLORS[color] }}
            >
              {isSelected && (
              <Icon
                name={ICONS.TICK}
                color={COLORS[selectedLabelColor]}
                size={9}
                style={{ marginRight: SPACING.SPACE_4 }}
              />
              )}
              <CustomText
                color={isSelected ? selectedLabelColor : labelColor}
                className={styles.btnLabel}
              >
                {label}

              </CustomText>
            </button>
            <div
              className={styles.selectedBtn}
              style={{
                backgroundColor: isSelected ? COLORS[selectedColor] : COLORS[color],
                width: isSelected ? '100%' : 0,
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

const stylesheet = makeStyles({
  row: {
    display: 'inline-flex',
    borderRadius: 10,
    overflow: 'hidden',
    '& *': {
      transition: 'all 0.3s var(--anim-func-ease)',
    },
  },
  btnView: {
    position: 'relative',
  },
  hoverBtnView: {
    // '&:hover $btnLabel': {
    //   fontWeight: `${FONTWEIGHT.SEMI_BOLD} !important`,
    // },
  },
  btnLabel: {},
  btn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_24}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      zIndex: 99,
    },
  },
  selectedBtn: {
    position: 'absolute',
    height: '100%',
    borderRadius: 10,
    top: '0',
    zIndex: '9',
  },
})

export default ToggleButton
