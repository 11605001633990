import React, { useCallback, useEffect, useState } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { connect } from 'react-redux'

import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import PlaceHolderLoader from '../../UI/PlaceHolderLoader'
import CustomText from '../../UI/CustomText'
import {
  ASSETS,
  COLORS,
  SPACING, theme,
} from '../../Theme'
import { useTheme } from '../../Theme/ThemeProvider'
import { fetchHoldings, fetchPositions } from '../../containers/Portfolio/actions'
import { unSubscribe } from '../../containers/Instruments/actions'
import GetPortfolioPnl from './GetPortfolioPnl'
import { PORTFOLIO_TABS } from '../../containers/Portfolio/portfolioUtils'
import { symbolNameByOptions } from '../../utils/common'

const PortfolioSidebarCard = React.memo(({
  getPositions,
  getHoldings,
  positions,
  holdings,
  routeToTechicals,
  isFetchingPositions,
  isFetchingHoldings,
  tabs,
  updateIndex,
  index,
}) => {
  const styles = stylesheet()
  const { isDark } = useTheme()
  const [activeTab, tabChangeHandler] = useState(tabs[0].value)
  const isPositionsTab = index === 0 && PORTFOLIO_TABS.POSITIONS.KEY
  useEffect(() => {
    const filter = 'All'
    const days = '7'
    getPositions({ filter, days }, {})
    getHoldings({ filter, days }, {})
  }, [])
  useEffect(() => {
    if (positions.length === 0
            && holdings.length > 0) {
      updateIndex(tabs[1].value)
      tabChangeHandler(tabs[1].value)
    }
  }, [positions, holdings])

  const renderEmptyContainer = () => {
    return (
      <div className={styles.emptyContianer}>
        <img src={ASSETS[isDark ? 'DASH_PORTFOLIO_EMPTY_DARK' : 'DASH_PORTFOLIO_EMPTY']} className={styles.emptyImg} alt="portfolio_empty" />
        <CustomText color="textSecondary" weight="medium">{`No ${index === 1 ? 'holdings' : 'positions'} available`}</CustomText>
        <button
          type="button"
          onClick={routeToTechicals}
          className={styles.emptyContianerAction}
        >
          <CustomText color="text" weight="medium">Go to Technicals</CustomText>
        </button>
      </div>
    )
  }

  const renderTableContent = (data) => {
    return data.map((item) => {
      const {
        tradingsymbol, product, seg_sym, quantity,
      } = item
      let symbolName = tradingsymbol
      const rawSymbolName = symbolNameByOptions(tradingsymbol)
      let showOptions
      if (symbolName !== rawSymbolName) {
        showOptions = rawSymbolName.charAt(rawSymbolName.length - 2)
        symbolName = rawSymbolName.substring(0, rawSymbolName.length - 4)
      }
      const finalOptionText = {
        backgroundColor: showOptions === 'W' ? COLORS.BLUE : COLORS.ORANGE,
        position: 'relative',
      }

      let rowDisabled = false
      const rowDisabledStyles = {
        opacity: 0.6,
        backgroundColor: theme.btnDisabled,
      }
      if(quantity === 0) {
        rowDisabled = true
      }
      return (
        <ul className={styles.row} key={`${tradingsymbol}_${product}`} style={rowDisabled ? rowDisabledStyles : { opacity: 1 }}>
          <div style={{ width: '60%' }}>
            <CustomText ellipsis style={{ paddingRight: SPACING.SPACE_4 }}>
              {symbolName}
              {showOptions && showOptions !== ' ' && (
              <img src={showOptions === 'W' ? ASSETS.Weekly : ASSETS.Monthly} style={finalOptionText} className="optionsText" alt="w" />
              )}
            </CustomText>
          </div>
          <div>
            <GetPortfolioPnl
              isPositionsTab={isPositionsTab}
              item={item}
              seg_sym={seg_sym}
              pnlProps={{
                pnlStyle: styles.pnlStyle, // { opacity: isClosed ? 0.5 : 1 }]}
                showColor: true,
                showSign: true,
                showLabel: false,
              }}
            />
          </div>
        </ul>
      )
    })
  }

  const renderTable = () => {
    const data = index === 0 ? positions : holdings
    const textProps = {
      weight: 'medium',
      size: 'small',
      color: 'textSecondary',
    }
    return (
      <div className={styles.table}>
        <ul className={styles.tableHeader}>
          <li style={{ width: '80%' }}><CustomText {...textProps}>Instruments</CustomText></li>
          <li style={{ width: '20%' }}><CustomText {...textProps}>P&L</CustomText></li>
        </ul>
        {(
             data.length ? (
               <ul className={styles.tableBody}>
                 {renderTableContent(data.slice(0, 3))}
               </ul>
             ) : renderEmptyContainer()
        )}
      </div>
    )
  }

  const onTabChangeHandler = useCallback((value, item) => {
    // const { index } = item
    updateIndex(value, item)
    tabChangeHandler(value)
  }, [])

  return (
    <div className={styles.container}>
      {isFetchingPositions || isFetchingHoldings ? (
        <>
          <PlaceHolderLoader className={styles.loaderTab} />
          <PlaceHolderLoader className={styles.loader} />
        </>
      ) : (
        <>
          <CustomHorizontalTab
            tabs={tabs}
            tabChangeHandler={onTabChangeHandler}
            defaultActiveTab={activeTab}
            style={styles.tabContainer}
            isDark={isDark}
          />
          {renderTable()}
        </>
      )}

    </div>
  )
})

const stylesheet = makeStyles(() => ({
  container: {
    margin: `${SPACING.SPACE_20} ${SPACING.SPACE_24}`,
  },
  commodityWrapper: {
    marginTop: SPACING.SPACE_32,
  },
  emptyImg: {
    width: 100,
  },
  emptyContianerAction: {
    marginTop: SPACING.SPACE_4,
  },
  equityWrapper: {
    marginTop: SPACING.SPACE_32,
  },
  tabContainer: {
    backgroundColor: theme.tabsBg,
    borderRadius: 8,
    marginBottom: SPACING.SPACE_16,
  },
  loader: {
    height: 150,
  },
  loaderTab: {
    marginBottom: SPACING.SPACE_16,
    height: 50,
  },
  table: {
    width: '100%',
    height: '100%',
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${SPACING.SPACE_20} ${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    marginBottom: SPACING.SPACE_10,
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  tableBody: {
    minHeight: 135,
  },
  tableBodyLoader: {
    marginTop: SPACING.SPACE_40,
  },
  cardContent: {
    height: '100%',
  },
  emptyContianer: {
    display: 'flex',
    height: '65%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${SPACING.SPACE_12} ${SPACING.SPACE_20}`,
    '&:first-child': {
      marginTop: SPACING.SPACE_8,
    },
  },
}))

const mapStateToProps = state => ({
  sessionId: state.auth.loginResp.sessionid,
  positions: state.portfolio.positions,
  holdings: state.portfolio.holdings,

  isFetchingPositions: state.portfolio.isFetchingPositions,
  positionsError: state.portfolio.positionsError,

  isFetchingHoldings: state.portfolio.isFetchingHoldings,
  // meta: state.auth.meta,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getPositions: (params, headers, screenName) => dispatch(
      fetchPositions(params, headers, screenName),
    ),
    getHoldings: (type, headers, screenName) => dispatch(fetchHoldings(type, headers, screenName)),
    unSubscribeTokens: (screenName, pairList) => dispatch(unSubscribe(screenName, pairList)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioSidebarCard)
