import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import Datetime from 'react-datetime'
import classnames from 'classnames'

import { COLORS } from '../Theme'
// 'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
export const monthsMap = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]

const DateTimePicker = React.memo(({
  props,
}) => {
  const styles = stylesheet()
  const renderDay = (dayProps, currentDate, selectedDate) => {
    // Adds 0 to the days in the days view
    const isSelected = selectedDate
      ? currentDate.format('DD MM YYYY') === selectedDate.format('DD MM YYYY') : false
    return (
      <td {...dayProps} className={classnames(dayProps.className, styles.day, isSelected ? styles.selectedDay : '')}>
        {`${currentDate.date()}`}
      </td>
    )
  }
  const renderMonth = (monthProps, month, year, selectedDate) => {
    const isSelected = selectedDate ? selectedDate.month() === month : false
    return (
      <td {...monthProps} className={classnames(monthProps.className, styles.month, isSelected ? styles.selectedMonth : '')}>{monthsMap[month]}</td>
    )
  }
  const renderYear = (yearProps, year, selectedDate) => {
    const isSelected = selectedDate ? selectedDate.year() === year : false
    return (
      <td {...yearProps} className={classnames(yearProps.className, isSelected ? styles.selectedYear : '')}>{year}</td>
    )
  }
  return (
    <Datetime
      renderDay={renderDay}
      renderMonth={renderMonth}
      renderYear={renderYear}
      className={styles.dateTimePicker}
      {...props}
    />
  )
})

// find picker default style changes in index.css
const stylesheet = makeStyles({
  dateTimePicker: {
  },
  // day: {
  //   '&:hover': {
  //     backgroundColor: theme.hover,
  //   },
  // },
  selectedDay: {
    backgroundColor: `${COLORS.VOILET} !important`,
    borderRadius: 4,
    color: COLORS.WHITE,
  },
  selectedYear: {
    backgroundColor: `${COLORS.VOILET} !important`,
    borderRadius: 4,
    color: COLORS.WHITE,
  },
  month: {
  },
  selectedMonth: {
    backgroundColor: `${COLORS.VOILET} !important`,
    borderRadius: 4,
    color: COLORS.WHITE,
  },
})

export default DateTimePicker
