export default ({
  data = [],
  data2 = [],
  lineColor,
  // showTicks = true,
}) => {
  // const ticks = showTicks
  //   ? {
  //     beginAtZero: true,
  //     font: {
  //       size: 8,
  //       weight: '500',
  //       family: 'Poppins, sans-serif',
  //     },
  //     autoSkip: true,
  //     maxTickLimit: 20,
  //   }
  //   : { display: false }
  return {
    type: 'line',
    data: {
      labels: ['bread', 'butter', 'milk'],
      datasets: [
        {
          type: 'line',
          label: 'butter',
          data,
        },
        {
          label: 'milk',
          data: data2,
          type: 'line',
          backgroundColor: 'transparent',
          borderColor: 'grey',
          borderDash: [3],

        },
      ],
    },
    options: {
      events: [],
      responsiveAnimationDuration: 0,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
        // easing: 'easeInOutCirc',
      },
      // transitions: {
      // },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          type: 'linear',
          position: 'bottom',
          display: false,
        },
        y: {
          type: 'linear',
          display: false,
        },
      },
      elements: {
        point: {
          borderWidth: 0,
          backgroundColor: 'transparent',
        },
        line: {
          tension: 0,
          backgroundColor: 'green',
          borderWidth: 1,
          borderColor: lineColor,
          borderJoinStyle: 'line',
          stepped: true,
        },
      },
    },
  }
}
