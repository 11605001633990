import React, { useEffect, useRef, useState } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
// import { isEmpty } from 'lodash'

import ScreenHeader from '../../UI/ScreenHeader'
import Button from '../../UI/Button'
import AlgoInfo from '../AlgoInfo'
import BacktestResults from './BacktestResults'
import BacktestsDetails from './BacktestsDetails'
import CreateButton from '../../UI/CreateButton'
import EmptyContainer from '../../UI/EmptyContainer'
import CummulativePnl from './CummulativePnl'
import ViewAllCard from '../../UI/ViewAllCard'
import Icon from '../../UI/Icon'
import EntryExitRenderer from './EntryExitRenderer'

import {
  COLORS, ICONS, SPACING, theme,
} from '../../Theme'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import DotLoader from '../../UI/DotLoader'
import CustomText from '../../UI/CustomText'
import { LINK_MAP, MATH_FUNCS } from '../../utils/consts'
import CustomTooltip from '../../UI/CustomTooltip'
import CustomModal from '../../UI/CustomModal'
import Swipe from '../../UI/Swipe'
import { nudgeText } from '../AlgoCondensedView/AlgoUtils'

// helper to see if backtest results exists
export const isBacktestExist = (scripBtResult = {}) => {
  // isEmpty(scripBtResult)  || scripBtResult.error_msg || scripBtResult.status === false
  if ((scripBtResult.error_msg?.toLowerCase() === 'no trades happened' && scripBtResult.status === false)
  || scripBtResult.status) {
    // const errMsg = scripBtResult &&
    // scripBtResult.error_msg ? scripBtResult.error_msg : 'No backtest result found'
    // console.log(errMsg)
    return true
  }
  return false
}

// do not change
const menuOptions = [
  {
    label: 'Edit', value: 'edit', icon: ICONS.EDIT, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  {
    label: 'Copy', value: 'copy', icon: ICONS.COPY_FILLED, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  {
    label: 'Delete', value: 'delete', icon: ICONS.DELETE, iconSize: 12, iconColor: 'RED', optionSize: 'small',
  },
  {
    label: 'Export Backtest Result', value: 'exportbt', icon: ICONS.ARROW_DOWN_FILLED, iconSize: 12, iconColor: 'TEXT', optionSize: 'small',
  },
  {
    label: 'All Transaction Details', value: 'alltrancdetails', icon: ICONS.ARROW_DOWN_FILLED, iconSize: 12, iconColor: 'TEXT', optionSize: 'small',
  },
]

const checkOnlyScannerFuncExist = (conditions = [],
  indiArr = [MATH_FUNCS.MULTI_TIME_FRAME.name], mtfPreference) => {
  let hasScannerOnlyFunc = false
  if(!mtfPreference) return false
  if (conditions.length > 0 && conditions.includes(`${indiArr[0]}(`)) {
    hasScannerOnlyFunc = true
  }

  return hasScannerOnlyFunc
}

export const renderPnl = ({
  waiting, final_return, error_msg, final_pnl,
}) => {
  const styles = stylesheet()
  let pnlText
  const pnlAmount = final_pnl?.toFixed(2) || 0
  // let backgroundColor = COLORS.WHITE
  if (final_return !== undefined && typeof final_return === 'number') {
    if (+final_return > 0) {
      pnlText = `+${+final_return.toFixed(2)}%`
    } else if (final_return < 0) {
      pnlText = `${+final_return.toFixed(2)}%`
    }
  }

  const renderTypeStyles = styles[final_return >= 0 ? 'gain' : 'loss']
  if (waiting) {
    return (
      <div className={styles.pnlPlaceHolder}>
        <DotLoader props={{ width: 30 }} />
      </div>
    )
  }
  if (error_msg) {
    return (
      <CustomText
        size="small"
      >
        N/A
      </CustomText>
    )
  }
  if (pnlText) {
    return (
      <>
        {final_pnl ? (
          <CustomText
            className={renderTypeStyles}
            weight="medium"
            size="small"
            center
          >
            {pnlAmount}
          </CustomText>
        ) : null}
        <CustomText
          className={renderTypeStyles}
          weight="medium"
          size="small"
          center
        >
          {final_pnl ? `(${pnlText})` : pnlText}
        </CustomText>
      </>
    )
  }
  return <div className={styles.pnlPlaceHolder} />
}

const Backtests = ({
  btParams,
  algoInfo,
  renderType,
  deployAll,
  algoId,
  algoIdKey,
  scripList,
  backtest_results,
  addNewSymbol,
  showBacktestDetails,
  backPressHandler,
  selectedSymbols,
  onSymbolSelect,
  deployed_seg_sym,
  toggleSelectAll,
  navigation,
  isFetchingBacktests,
  // refetchBacktest,
  isRunningBacktests,
  removeSymbols,
  isFocused,
  algo_uuid,
  convertToDynamic,
  create_plus,
  fetchBacktestAll,
  brokerageData,
  isDark,
  selectedIndex,
  algo_subscription_uuid,
  publishing_uuid,
  algo_subscribed,
  csrf,
  sessionid,
  onDelete,
  sliderProps,
  deploySymbols,
  toggleBrokerage,
  brokerage,
  selectedItem,
  user_details,
  isMobile,
  isFetchingData,
  pinCumulativePnl,
  updateWebSiteConfig,
  domainChange,
  isLoggedIn,
  downloadBtResult,
  updateBrokerage,
  toggleListView,
  isListView,
  downloadAllTD,
  gaCategory,
  updateGA,
  pinStratgyDetails,
  isSubscribingAlgo,
  onEntryEdit,
  onExitEdit,
  isPinned,
  isCtb,
  startTour,
  activeTourIndex,
  changePtComponentStatus,
  reRunBacktestHandler,
  mtfPreference,
  nudgePreference,
  nudgeIndexesDet,
}) => {
  const styles = stylesheet()
  const history = useHistory()
  const [activeNudge, setActiveNudge] = useState('')
  const [nudgeIndex, setNudgeIndex] = useState(-1)
  const [toggleModal, setToggleModal] = useState(false)
  const [nudgeRules, setNudgeRules] = useState([])
  const {
    algo_name, algo_desc, entryStr, exitStr,
  } = algoInfo
  const scrollViewRef = useRef(null)

  useEffect(() => {
    if (isRunningBacktests && scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: 0, animated: true })
    }
  }, [isRunningBacktests])
  const len = scripList.length
  const selectedLen = selectedSymbols.size
  const deployedLen = deployed_seg_sym.length
  const allSelected = deployedLen && len > 1 && deployedLen !== len
    ? (selectedLen === (len - deployedLen)) : selectedLen === len
  const { dt_start, dt_stop } = btParams

  const mtfExist = checkOnlyScannerFuncExist(entryStr, [
    MATH_FUNCS.MULTI_TIME_FRAME.name,
  ], mtfPreference)

  const onSetNudgeData = (showNudgeIndexes) => {
    setNudgeIndex(showNudgeIndexes[0])
    if(isMobile) {
      setNudgeRules(showNudgeIndexes)
    }
  }

  const renderMobileNudge = () => {
    const nudgeIndexes = nudgeRules
    const lastNudgeIndex = nudgeIndexes.length
    const nudgeColor = nudgeText[nudgeIndex] ? nudgeText[nudgeIndex].color : nudgeText[1].color
    const nudgeInfo = nudgeText[nudgeIndex] ? nudgeText[nudgeIndex].text : nudgeText[1].text
    const nudgeLink = nudgeText[nudgeIndex] ? nudgeText[nudgeIndex].link : nudgeText[1].link

    return (
      <div className={styles.nudgeItemContainer}>
        <Swipe
          onSwipeLeft={() => onChangeNudge(-1, nudgeIndexes)}
          onSwipeRight={() => onChangeNudge(1, nudgeIndexes)}
          key={Math.random()}
        />
        <div className={styles.iconHeader}>
          <div className={styles.iconContainer}>
            <Icon name={ICONS.BT_NUDGE} size={30} />
            <CustomText size="regular" color={COLORS.WHITE}>
              Nudge
            </CustomText>
          </div>
          <Button
            iconName="CLOSE"
            onPress={closeNudge}
            iconProps={{ color: COLORS.GREY_LIGHT, size: 10 }}
          />
        </div>
        <div className={styles.nudgeContent}>
          <>
            <span
              className={styles.arrow}
              style={{ background: nudgeColor }}
            />
            <CustomText
              key={nudgeIndex}
              size="small"
              className={styles.nudgeText}
              color={COLORS.WHITE}
            >
              {nudgeInfo}
              <a
                href={nudgeLink}
                rel="noreferrer"
                target="_blank"
                style={{ color: COLORS.NUDGE_HREF, cursor: 'pointer' }}
              >
                {' '}
                here.
              </a>
            </CustomText>
          </>
        </div>
        {nudgeIndexes.length > 1 && (
          <CustomText color={COLORS.WHITE} className={styles.mobileNudgeFooter}>
            {nudgeIndexes.indexOf(nudgeIndex) + 1}
            /
            {lastNudgeIndex}
          </CustomText>
        )}
      </div>
    )
  }

  const renderNudge = (rowItem) => {
    if(!nudgePreference) {
      return null
    }

    const { seg_sym } = isListView ? rowItem.original : rowItem
    let nudgeIndexes = []
    if(nudgeIndexesDet[seg_sym]) {
      nudgeIndexes = nudgeIndexesDet[seg_sym]
    }
    const nudgeToolTipContent = renderNudgeItemToolTip(nudgeIndexes)
    const shouldShowNudgeBtn = nudgeIndexes.length > 0
    const activeNudgeItem = activeNudge === seg_sym

    return (
      shouldShowNudgeBtn && !isMobile ? (
        <CustomTooltip
          show={activeNudgeItem}
          visible={activeNudgeItem}
          placement="right"
          tooltipProps={{}}
          contentRenderer={() => nudgeToolTipContent}
          tooltipContainerStyles={styles.itemTooltip}
          interactive
          onClickOutside={closeNudge}
        >
          <button
            type="button"
            onClick={() => onSetNudgeData(nudgeIndexes)}
            className={styles.nudgeContainer}
            style={{
              paddingRight: isListView ? SPACING.SPACE_16 : 0,
              paddingLeft: !isListView ? SPACING.SPACE_16 : 0,
              marginTop: !isListView ? SPACING.SPACE_2 : 0,
            }}
          >
            <CustomText
              size="tiny"
              color={theme.bgPrimary}
              className={styles.totalNudge}
              style={{
                right: isListView ? 17 : 1,
                top: -3,
              }}
            >
              {shouldShowNudgeBtn && nudgeIndexes.length}
            </CustomText>
            <Icon
              name={isDark ? ICONS.BT_NUDGE : ICONS.BT_DETS_NUDGE}
              size={28}
            />
          </button>
        </CustomTooltip>
      )
        : shouldShowNudgeBtn && (
          <button
            type="button"
            onClick={() => onSetNudgeData(nudgeIndexes)}
            className={styles.nudgeContainer}
            style={{
              paddingRight: isListView ? SPACING.SPACE_16 : 0,
              paddingLeft: !isListView ? SPACING.SPACE_16 : 0,
              marginTop: !isListView ? SPACING.SPACE_2 : 0,
            }}
          >
            <CustomText
              size="tiny"
              color={theme.bgPrimary}
              className={styles.totalNudge}
              style={{
                right: isListView ? 17 : 1,
                top: -3,
              }}
            >
              {shouldShowNudgeBtn && nudgeIndexes.length}
            </CustomText>
            <Icon
              name={isDark ? ICONS.BT_NUDGE : ICONS.BT_DETS_NUDGE}
              size={28}
            />
          </button>
        )
    )
  }

  const showNudgeTooltip = (e, seg_sym) => {
    if(!isMobile) {
      e.stopPropagation()
    }
    if(isMobile) {
      setToggleModal(true)
    }
    setActiveNudge(seg_sym)
  }

  const commonProps = {
    algo_name,
    algoIdKey,
    algo_uuid,
    algo_subscription_uuid,
    publishing_uuid,
    deployed_seg_sym,
    isRunningBacktests,
    brokerageData,
    brokerage,
    isBacktestExist,
    isLoggedIn,
    gaLabel: gaCategory,
    gaCategory,
    updateGA,
    isCtb,
    ...sliderProps,
    changePtComponentStatus,
    startTour,
    activeTourIndex,
    reRunBacktestHandler,
    mtfExist,
    renderNudge,
    showNudgeTooltip,
  }

  const backtestResultProps = {
    backtest_results,
    scripList,
    addNewSymbol,
    showBacktestDetails,
    selectedSymbols,
    onSymbolSelect,
    toggleSelectAll,
    allSelected,
    selectedLen,
    removeSymbols,
    onDelete,
    isDark,
    len,
    dt_start,
    dt_stop,
    selectedIndex,
    deployAll,
    algoId,
    showEditBtn: create_plus,
    showDCParams: convertToDynamic,
    selectedItem,
    domainChange,
    toggleListView,
    isListView,
  }

  const backtestDetailsProps = {
    algoInfo,
    details: btParams,
    item: selectedItem,
    algo_subscribed,
    csrf,
    sessionid,
    deploySymbols,
    toggleBrokerage,
    updateBrokerage,
    algo_name,
    isListView,
  }

  const closeNudge = () => {
    if(isMobile) {
      setToggleModal(false)
    }
    setActiveNudge('')
  }

  const onChangeNudge = (step, nudgeIndexes) => {
    const currentIndex = nudgeIndexes.indexOf(nudgeIndex)
    const newIndex = currentIndex + step
    if (newIndex >= 0 && newIndex < nudgeIndexes.length) {
      setNudgeIndex(nudgeIndexes[newIndex])
    }
  }

  const renderNudgeItemToolTip = (showNudgeIndexes = []) => {
    const nudgeIndexes = showNudgeIndexes
    const lastNudgeIndex = nudgeIndexes.length
    const nudgeColor = nudgeText[nudgeIndex] ? nudgeText[nudgeIndex].color : nudgeText[1].color
    const nudgeInfo = nudgeText[nudgeIndex] ? nudgeText[nudgeIndex].text : nudgeText[1].text
    const nudgeLink = nudgeText[nudgeIndex] ? nudgeText[nudgeIndex].link : nudgeText[1].link

    return (
      <div className={styles.nudgeItemContainer}>
        <div className={styles.iconHeader}>
          <div className={styles.iconContainer}>
            <Icon name={ICONS.BT_NUDGE} size={30} />
            <CustomText size="regular" color={COLORS.WHITE}>
              Nudge
            </CustomText>
          </div>
          <Button
            iconName="CLOSE"
            onPress={closeNudge}
            iconProps={{ color: COLORS.GREY_LIGHT, size: 10 }}
          />
        </div>
        <div className={styles.nudgeContent}>
          <>
            <span
              className={styles.arrow}
              style={{ background: nudgeColor }}
            />
            <CustomText
              key={nudgeIndex}
              size="small"
              className={styles.nudgeText}
              color={COLORS.WHITE}
            >
              {nudgeInfo}
              <a
                href={nudgeLink}
                rel="noreferrer"
                target="_blank"
                style={{ color: COLORS.NUDGE_HREF, cursor: 'pointer' }}
              >
                {' '}
                here.
              </a>
            </CustomText>
          </>
        </div>
        {nudgeIndexes.length > 1 && (
        <div className={styles.nudgeFooter}>
          {nudgeIndex !== nudgeIndexes[0] ? (
            <button type="button" onClick={() => onChangeNudge(-1, nudgeIndexes)}>
              <Icon name={ICONS.ARROW_BACK} color={COLORS.NUDGE_F_ARROW} size={10} />
            </button>
          ) : (
            <div />
          )}
          <CustomText color={COLORS.WHITE}>
            {nudgeIndexes.indexOf(nudgeIndex) + 1}
            /
            {lastNudgeIndex}
          </CustomText>
          {nudgeIndex !== nudgeIndexes[lastNudgeIndex - 1] ? (
            <button type="button" onClick={() => onChangeNudge(1, nudgeIndexes)}>
              <Icon name={ICONS.ARROW_FORWARD_W_LINE} color={COLORS.NUDGE_F_ARROW} size={10} />
            </button>
          ) : (
            <div />
          )}
        </div>
        )}
      </div>
    )
  }

  const actionBarRenderer = () => {
    if (len > 0 && isMobile && selectedLen < 1 && len > deployedLen) {
      const deployBtnText = selectedLen === (len - deployedLen) || selectedLen === 0 ? 'Deploy All' : `Deploy ${selectedLen}`
      return (
        <div id="tour_deploy_3">
          <Button
            text={deployBtnText}
            onPress={deployAll}
            buttonColor="blue"
            btnStyles={styles.deployBtn}
            uppercase
            disabled={len === 0}
          />
        </div>
      )
    }
    return null
    // return (
    //   <div className={styles.headerActionBar}>
    //     <Button
    //       onPress={deployAll}
    //       text={deployBtnText}
    //       disabled={Boolean(isDeploying || allDeployed)}
    //       contentStyle={styles.deployAllBtn}
    //       labelStyle={styles.depoyBtnText}
    //       buttonColor={COLORS.BLUE}
    //       labelColor={COLORS.WHITE}
    //       mode="contained"
    //       roundness={6}
    //     />
    //     <button
    //       type="button"
    //       onClick={removeSymbols}
    //     >
    //       <div className={styles.deleteBtnView}>
    //         <Icon name={ICONS.DELETE} color={COLORS.WHITE} />
    //       </div>
    //     </button>
    //     {showEditBtn && (
    //     <button
    //       type="button"
    //       onClick={() => convertToDynamic(findScripFromAlgoObj(selectedSymbols, scripList))}
    //     >
    //       <div className={styles.editBtnView}>
    //         <Icon name={ICONS.EDIT} color={COLORS.WHITE} />
    //       </div>
    //     </button>
    //     )}
    //   </div>
    // )
  }
  const listHeaderRenderer = ({ scrolled }) => {
    let finalOptions = menuOptions
    if (!isMobile) {
      finalOptions = [menuOptions[1], menuOptions[2], menuOptions[3], menuOptions[4]]
    }
    if (deployed_seg_sym.length) {
      finalOptions = finalOptions.filter(item => item.value !== menuOptions[0].value
        && item.value !== menuOptions[2].value)
    }
    return (
      <AlgoInfo
        algoId={algoId}
        algoIdKey={algoIdKey}
        monthly_pricing={0}
        algo_name={algo_name}
        algo_desc={algo_desc}
        showSocialStats={false}
        menuOptions={finalOptions}
        downloadBtResult={downloadBtResult}
        downloadAllTD={downloadAllTD}
        navigation={navigation}
        // eslint-disable-next-line max-len
        canEditAlgo={!deployed_seg_sym || (deployed_seg_sym && deployed_seg_sym.length === 0)}
        parentId={NAVIGATIONS.STRATEGIES.name}
        isFocused={isFocused}
        algo_uuid={algo_uuid}
        isDark={isDark}
        entryStr={entryStr}
        exitStr={exitStr}
        history={history}
        scrolled={scrolled}
        selectedItem={selectedItem}
        domainChange={domainChange}
        isLoggedIn={isLoggedIn}
        menuBtnStyles={styles.menuBtn}
        gaCategory={gaCategory}
        gaLabel={gaCategory}
        scripList={scripList}
      />
    )
  }

  const emptyActionRenderer = () => {
    return (
      <CreateButton
        text="Add Symbols"
        onPress={addNewSymbol}
        className={styles.addStock}
        btnStyles={styles.addStockBtn}
        textSize="small"
      />
    )
  }

  const removeOverLay = () => {
    const mtfEle = document.getElementById('mtfContainer')

    if(mtfEle) {
      mtfEle.style.display = 'none'
    }
    backtest_results.forEach((item) => {
      const mtfGridResultEle = document.getElementById(item.seg_sym)
      const mtfRowEle = document.getElementById(`${item.seg_sym}_mtf`)
      const mtfGridPnl = document.getElementById(`${item.seg_sym}_mtf_pnl`)
      const mtfListPnl = document.getElementById(`${item.seg_sym}_mtf_pnl`)

      const mtfIds = ['pnl', 'wvl', 'signals', 'ws', 'ls', 'maxdd']
      mtfIds.forEach((mtfItem) => {
        const getPnlElde = document.getElementById(`${item.seg_sym}_${mtfItem}`)
        if(getPnlElde && !isMobile) {
          getPnlElde.className = styles.headerColumn
        }
      })

      if(mtfRowEle) {
        mtfRowEle.style.display = 'none'
      }
      if(isMobile && isListView) {
        mtfListPnl.style.display = 'none'
      }
      if(mtfGridResultEle) {
        mtfGridResultEle.style.display = 'block'
      }
      if(!isListView && mtfGridPnl) {
        mtfGridPnl.style.display = 'none'
      }
    })
  }

  const renderMTFCell = () => {
    return (
      <div className={styles.mtfCell} style={{ width: isMobile ? '50%' : 180 }}>
        <span className={styles.labelBlur}>value</span>
        <span className={styles.valueBlur}>labelvalue</span>
      </div>
    )
  }

  const renderMTFCumuPNL = () => {
    return (
      <div
        className={styles.container}
      >
        {isMobile ? (
          <div className={styles.headerMobile}>
            <div className={styles.subHeaderMobile}>
              <div className={styles.headerLeft}>
                <Icon
                  name={ICONS.CUMULATIVE_ICON}
                  size={14}
                  style={{ marginRight: SPACING.SPACE_8 }}
                  color={theme.orange}
                />
                <CustomText weight="semi_bold" size="medium">Cumulative results</CustomText>
              </div>
              <div className={styles.headerRight}>
                <CustomText size="regular" weight="medium">
                  Backtest P&L
                  <span className={styles.pnlBlur}>12345</span>
                </CustomText>
              </div>
            </div>
            <Button
              mode="text"
              labelColor={isDark ? 'blue_300' : 'blue'}
              transparent
              onPress={() => removeOverLay()}
              text="Show results"
              btnStyles={styles.showResultBtn}
              respText
            />
          </div>
        )
          : (
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <Icon
                  name={ICONS.CUMULATIVE_ICON}
                  size={14}
                  style={{ marginRight: SPACING.SPACE_8 }}
                  color={theme.orange}
                />
                <CustomText weight="semi_bold" size="medium">Cumulative results</CustomText>
              </div>
              <div className={styles.headerRight}>
                <CustomText size="regular" weight="medium">
                  Backtest P&L
                  <span className={styles.pnlBlur}>12345</span>
                </CustomText>
              </div>
              <Button
                mode="text"
                labelColor={isDark ? 'blue_300' : 'blue'}
                transparent
                onPress={() => removeOverLay()}
                text="Show results"
                btnStyles={styles.showResultBtn}
                respText
              />
            </div>
          )}
        <CustomText size="small_1" weight="medium" className={styles.mtfTitle} color="orange">
          Backtest results for MTF are unverifiable.
          <a href={LINK_MAP.MTF.link} rel="noreferrer" target="_blank"> Learn More.</a>
        </CustomText>
        <div className={styles.mtfContent}>
          {renderMTFCell()}
          {renderMTFCell()}
          {renderMTFCell()}
          {renderMTFCell()}
          {renderMTFCell()}
          {renderMTFCell()}
        </div>
      </div>
    )
  }

  const renderCumulativePnl = () => {
    return (
      <CummulativePnl
        // data={backtest_results}
        isRunningBacktests={isRunningBacktests}
        user_details={user_details}
        fetchBacktestAll={fetchBacktestAll}
        isFetchingData={isFetchingData}
        pinCumulativePnl={pinCumulativePnl}
        updateWebSiteConfig={updateWebSiteConfig}
        gaCategory={gaCategory}
        scripList={scripList}
        hide={len === 1}
      />
    )
  }

  const footerActionBtnRederer = () => {
    return <Icon name={ICONS.PIN} size={14} color={theme[isPinned ? 'linkColor' : 'text']} />
  }

  const listFooterRenderer = () => {
    return (
      <ViewAllCard
        title="Strategy Details"
        onPress={pinStratgyDetails}
        actionBtnRederer={footerActionBtnRederer}
        cardStyles={styles.footer}
        contentStyles={styles.footerContent}
        headerStyles={styles.footerHeader}
      >

        <div className={styles.strategyDetails}>
          <EntryExitRenderer
            cardTitle="ENTRY"
            onCardPress={onEntryEdit}
            condition={entryStr}
            disabled={Boolean(isSubscribingAlgo
              || isRunningBacktests || deployed_seg_sym.length > 0)}
            key="entry"
            isDark={isDark}
            cardStyles={styles.entryCard}
          />
          <EntryExitRenderer
            cardTitle="EXIT"
            onCardPress={onExitEdit}
            condition={exitStr}
            disabled={Boolean(isSubscribingAlgo
              || isRunningBacktests || deployed_seg_sym.length > 0)}
            numberOfLines={1}
            key="exit"
            isDark={isDark}
            cardStyles={styles.exitCard}
          />
        </div>
      </ViewAllCard>
    )
  }

  const mtfContainerShow = document.getElementById('mtfContainer')
  const mtfContainerStyles = mtfContainerShow?.style.display
  // const item = backtest_results[selectedIndex]
  //   || { seg_sym: scripList[selectedIndex] ? scripList[selectedIndex].seg_sym : '' }
  return (
    <div className={styles.content}>
      <ScreenHeader
        backPressHandler={backPressHandler}
        title={renderType}
        titleSize="large_1"
        containerStyles={styles.screenHeader}
        showBackBtn
        hideOnScroll
        scrolledText={algo_name}
        actionBarRenderer={actionBarRenderer}
        algoIdKey={algoIdKey}
        startTour={startTour}
      >
        {({ scrolled }) => listHeaderRenderer({ scrolled })}
      </ScreenHeader>
      <div
        className={styles.container}
        ref={scrollViewRef}
        id="backtestContainer"
      >
        {isPinned && isMobile && listFooterRenderer()}
        {mtfExist && pinCumulativePnl && (
        <div
          className={styles.mtfContainer}
          id="mtfContainer"
          style={{
            display: mtfContainerStyles === 'none' ? 'none' : 'block',
            paddingBottom: 0,
            marginTop: SPACING.SPACE_2,
          }}
        >
          {renderMTFCumuPNL()}
        </div>
        )}
        {!isFetchingBacktests && pinCumulativePnl && len > 0 && (
          renderCumulativePnl()
        )}
        {len === 0 ? (
          <EmptyContainer
            containerStyles={`${styles.emptyContainer} ${isDark ? styles.emptyContainerDark : ''}`}
            imgStyles={styles.emptyImg}
            imgContainerStyles={styles.emptyImgContainer}
            imgSrc="NO_STOCKS"
            actionRenderer={emptyActionRenderer}
            title="No Stocks"
            subTitle="Add symbols to run backtest"
            contentStyles={styles.emptyContent}
          />
        ) : (
          <>
            <BacktestResults
              {...commonProps}
              {...backtestResultProps}
              backtestDetailsProps={backtestDetailsProps}
              renderPnl={renderPnl}
            />
            {isListView ? <div /> : (
              <BacktestsDetails
                {...commonProps}
                {...backtestDetailsProps}
              />
            )}
            {mtfExist && !pinCumulativePnl && (
            <div
              className={styles.mtfContainer}
              id="mtfContainer"
              style={{
                display: mtfContainerStyles === 'none' ? 'none' : 'block',
              }}
            >
              {renderMTFCumuPNL()}
            </div>
            )}
            {!isFetchingBacktests && !pinCumulativePnl && len > 0 && (
              renderCumulativePnl()
            )}
          </>
        )}
        {!isPinned && isMobile && listFooterRenderer()}
      </div>
      <CustomModal
        visible={toggleModal}
        onDismiss={showNudgeTooltip}
        dismissKey="showChargesTooltip"
        containerStyles={styles.nudgeModal}
      >
        {renderMobileNudge()}
      </CustomModal>
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    margin: `0 0 ${SPACING.SPACE_20}`,
  },
  content: {
    '&:hover  $menuBtn': {
      '& svg': {
        fill: theme.linkColor,
      },
    },
  },
  menuBtn: {},
  footer: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
  },
  footerContent: {},
  footerHeader: {
    padding: `0 ${SPACING.SPACE_14}`,
  },
  strategyDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  entryCard: {
    flex: 0.48,
  },
  exitCard: {
    flex: 0.48,
  },
  algoParamsContainer: {},
  screenHeader: {
    // marginRight: SPACING.SPACE_20,
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
    backgroundColor: theme.bgPrimary,
    borderRadius: 10,
    boxShadow: `0px 7px 20px ${theme.borderColor}`,
  },
  emptyContainerDark: {
    boxShadow: 'none',
  },
  emptyContent: {
    textAlign: 'center',
  },
  emptyImg: {
    height: 100,
    paddingRight: SPACING.SPACE_20,
  },
  emptyImgContainer: {
    marginRight: '10%',
  },
  emptyAction: {
    textAlign: 'center',
  },
  addStockBtn: {
    marginTop: SPACING.SPACE_12,
  },
  addStock: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_16}`,
  },
  deployBtn: {
    display: 'none',
  },
  gain: {
    color: `${COLORS.GREEN} !important`,
  },
  loss: {
    color: `${COLORS.RED} !important`,
  },
  // mtf disc styles
  mtfContainer: {
    width: '100%',
    marginTop: SPACING.SPACE_20,
    padding: SPACING.SPACE_20,
    position: 'absolute',
    zIndex: 9997,
    background: theme.bgPrimary,
    userSelect: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subHeaderMobile: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: SPACING.SPACE_10,
    borderRight: `1px solid ${theme.borderColor}`,
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: SPACING.SPACE_10,
    flex: 1,
  },
  mtfCell: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: SPACING.SPACE_10,
  },
  mtfContent: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_10}`,
  },
  pnlBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #3CBC00',
  },
  labelBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #000000',
  },
  valueBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #9BABC6',
  },
  showResultBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    background: theme.mtfTitleBg,
  },
  mtfTitle: {
    background: theme.mtfTitleBg,
    padding: SPACING.SPACE_8,
    borderRadius: 8,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  // nudge styling
  nudgeContainer: {
    position: 'relative',
  },
  totalNudge: {
    background: theme.refText,
    borderRadius: '50%',
    width: 12,
    height: 12,
    position: 'absolute',
  },
  itemTooltip: {
    background: COLORS.NUDGE_BG,
    boxShadow: `2px 4px 24px ${COLORS.NUDGE_BOX_SHADOW}`,
    width: '330px',
    paddingBottom: 0,
    position: 'relative',
  },
  itemTooltipGrid: {
    background: COLORS.NUDGE_BG,
    boxShadow: `2px 4px 24px ${COLORS.NUDGE_BOX_SHADOW}`,
    width: '330px',
    paddingBottom: 0,
    position: 'relative',
  },
  nudgeItemContainer: {
    padding: 0,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingInline: SPACING.SPACE_10,
  },
  iconHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nudgeContent: {
    position: 'relative',
    marginBlock: SPACING.SPACE_10,
    textAlign: 'initial',
    paddingInline: SPACING.SPACE_18,
  },
  nudgeText: {
    paddingLeft: SPACING.SPACE_20,
  },
  arrow: {
    position: 'absolute',
    width: 8,
    height: '100%',
  },
  nudgeFooter: {
    marginTop: SPACING.SPACE_6,
    backgroundColor: COLORS.NUDGE_FOOTER_BG,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 330,
    borderRadius: '0 0 6px 6px',
    paddingBlock: SPACING.SPACE_6,
    paddingInline: SPACING.SPACE_10,
  },
  mobileNudgeFooter: {
    textAlign: 'center',
  },
  nudgeModal: {
    borderRadius: '24px 24px 0 0',
    backgroundColor: `${COLORS.NUDGE_BG} !important`,
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      margin: 0,
    },
    strategyDetails: {
      flexDirection: 'column',
    },
    footerContent: {
      borderRadius: 10,
      backgroundColor: theme.bgPrimary,
      boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    },
    entryCard: {
      boxShadow: 'none !important',
      borderBottom: `1px solid ${theme.borderColor}`,
      padding: `${SPACING.SPACE_20} 0`,
      margin: `0 ${SPACING.SPACE_14}`,
      borderRadius: 0,
    },
    exitCard: {
      boxShadow: 'none !important',
      borderBottom: `1px solid ${theme.borderColor}`,
      padding: `${SPACING.SPACE_20} 0`,
      margin: `0 ${SPACING.SPACE_14}`,
      borderRadius: 0,
    },
    algoParamsContainer: {
      boxShadow: 'none !important',
      marginTop: 0,
    },
    screenHeader: {
      margin: 0,
    },
    deployBtn: {
      display: 'unset',
    },
  },
  '@media only screen and (max-width: 610px)': {
    mtfTitle: {
      width: 'calc(100% - 40px)',
    },
  },
})

export default Backtests
