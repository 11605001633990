/* eslint-disable max-len */
import {
  ASSETS, theme, ICONS,
} from '../../Theme'
import { DEPLOYMENT_TYPE, TPSL_TYPE } from '../../utils/consts'

const {
  BlueLightGif, OrangeLightGif, RedLightGif, GreenLightGif,
  BlueDarkGif, OrangeDarkGif, RedDarkGif, GreenDarkGif, YellowDarkGif,
  YellowLightGif,
} = ASSETS

// Stores a map of menu label and id (to reference back the field in this object)
export const MENU_MAP = {
  VIEW_STRATEGY: { LABEL: 'View Strategy Details', ID: 'VIEW_STRATEGY', icon: ICONS.STRATEGY_DETAILS },
  VIEW_ORDERLOG: { LABEL: 'View Order Log', ID: 'VIEW_ORDERLOG', icon: ICONS.ORDER_LOG },
  // VIEW_DETAILS: { LABEL: 'View Details', ID: 'VIEW_DETAILS', ICON: ICONS.STRATEGY_DETAILS },
  REDEPLOY: {
    LABEL: 'Redeploy', ID: 'REDEPLOY', icon: ICONS.REDEPLOY, iconColor: 'GREEN',
  },
  STOP: {
    LABEL: 'Stop', ID: 'STOP', icon: ICONS.STOP, iconColor: 'RED',
  },
  TAKE_ACTION: {
    LABEL: 'Take Action', ID: 'TAKE_ACTION', icon: ICONS.USER_ACTION, iconColor: 'GREEN',
  },
}

export const DEPLOYED_TABS = {
  WAITING: {
    KEY: 'waiting',
    TITLE: 'WAITING',
    ID: 'WAITING',
    INDEX: 0,
    backgroundColor: theme.orange,
    color: theme.orange,
    gaLabel: 'Waiting',
    MENU_MAP: [
      MENU_MAP.VIEW_STRATEGY, MENU_MAP.VIEW_ORDERLOG, MENU_MAP.STOP,
    ],
  },
  ENTERED: {
    KEY: 'entered',
    TITLE: 'ENTERED',
    ID: 'ENTERED',
    INDEX: 1,
    backgroundColor: theme.green,
    color: theme.green,
    gaLabel: 'Entered',
    MENU_MAP: [
      MENU_MAP.VIEW_STRATEGY, MENU_MAP.VIEW_ORDERLOG, MENU_MAP.STOP,
    ],
  },
  STOPPED: {
    KEY: 'stopped',
    TITLE: 'STOPPED',
    ID: 'STOPPED',
    INDEX: 2,
    backgroundColor: theme.stoppedBg,
    color: theme.stoppedBg,
    gaLabel: 'Stopped',
    MENU_MAP: [
      MENU_MAP.VIEW_STRATEGY, MENU_MAP.VIEW_ORDERLOG, MENU_MAP.REDEPLOY,
    ],
  },
}

// Stores a map of active section and it's corresponding key name in redux,
// button label and id to reference the object keys.
export const DEPLOYED_SECTION_MAP = {
  // ALL: {  REDUX: 'autoTrading', BUTTON_LABEL: 'All', id: 'ALL' },
  // AUTO_TRADING: {
  //   REDUX_ID: 'autoTrading',
  //   BUTTON_LABEL: 'Auto Trading',
  //   id: 'AUTO_TRADING',
  //   iconName: 'NOTIF_AUTO',
  //   activeTextColor: theme.PURPLE,
  // },
  NOTIFICATION_TRADING: {
    REDUX_ID: 'notificationTrading',
    BUTTON_LABEL: 'Live Trading',
    id: 'NOTIFICATION_TRADING',
    iconName: 'NOTIF_AUTO',
    activeTextColor: theme.linkColor,
    // new
    route: 'live',
    gaLabel: 'Live',
  },
  PAPER_TRADING: {
    REDUX_ID: 'paperTrading',
    BUTTON_LABEL: 'Paper Trading',
    id: 'PAPER_TRADING',
    iconName: 'NOTIF_PAPER',
    activeTextColor: theme.green,
    // new
    route: 'paper',
    gaLabel: 'Paper',
  },
}

export const EXIT_POSITION_MAP = {
  EXIT_NOW: { TYPE: 'EXIT_NOW', BACKEND: 'exitNow' },
  KEEP_POSITIONS_OPEN: { TYPE: 'KEEP_POSITIONS_OPEN', BACKEND: 'keepPositionsOpen' },
}

export const EXIT_ALL_POSITIONS_LIMIT = 10

export const checkActionableContent = (algoItem, activeTabKey, isDark, used) => {
  let isActionable = false
  let gif = null
  if (activeTabKey !== DEPLOYED_TABS.STOPPED.KEY
    && algoItem.deployment_type !== DEPLOYMENT_TYPE.PAPER.backend) {
    const tag = algoItem.logs[0].log_tag.toUpperCase()
    if (tag === 'BUY ALERT') {
      isActionable = true
      gif = isDark ? BlueDarkGif : BlueLightGif
    } else if (tag === 'SELL ALERT') {
      isActionable = true
      gif = isDark ? OrangeDarkGif : OrangeLightGif
    } else if (tag === 'TARGET PROFIT ALERT') {
      isActionable = true
      gif = isDark ? GreenDarkGif : GreenLightGif
    } else if (tag === 'STOP LOSS ALERT') {
      isActionable = true
      gif = isDark ? RedDarkGif : RedLightGif
    } else if (tag === 'PLACE SL-M ORDER' || tag === 'PLACE SL ORDER') {
      if (used[algoItem.logs[0].notification_data.notification_uuid]) {
        isActionable = false
      } else {
        isActionable = true
      }
      gif = isDark ? YellowDarkGif : YellowLightGif
    }
  }
  // return actionable
  return {
    isActionable,
    gif,
  }
}

export const tpSlCalculation = ({
  take_profit, stop_loss, avgPrice,
  tpsl_type, action_type,
  quantity,
  // trailing_sl, ltp,
}) => {
  let tp = Number(+take_profit || 0.0)
  let sl = Number(+stop_loss || 0.0)
  const avg_price = Number(avgPrice)
  // let tsl = trailing_sl
  // let tslM = 1
  // if (tpsl_type === 'abs') {
  //   tsl /= quantity
  // // eslint-disable-next-line no-empty
  // } else if(tpsl_type === 'pts') {
  // } else {
  //   tsl *= (avgPrice / 100)
  // }
  if (action_type === 'BUY') {
    switch (tpsl_type) {
      case TPSL_TYPE.ABSOLUTE.value:
        tp = avg_price + tp / Math.abs(quantity)
        sl = avg_price - sl / Math.abs(quantity)
        break
      case TPSL_TYPE.POINTS.value:
        tp = avg_price + tp
        sl = avg_price - sl
        break
      default:
        if (+quantity > 0 && take_profit && stop_loss) {
          tp = (1 + (tp / 100)) * avg_price
          sl = (1 - (sl / 100)) * avg_price
        }
        break
    }
  } else {
    switch (tpsl_type) {
      case TPSL_TYPE.ABSOLUTE.value:
        tp = avg_price - tp / Math.abs(quantity)
        sl = avg_price + sl / Math.abs(quantity)
        break
      case TPSL_TYPE.POINTS.value:
        tp = avg_price - tp
        sl = avg_price + sl
        break
      default:
        if (+quantity < 0 && tp && sl) {
          tp = (1 - (tp / 100)) * avg_price
          sl = (1 + (sl / 100)) * avg_price
        }
        break
    }
  }
  // if(trailing_sl) {
  //   if(action_type === 'BUY') {
  //     tslM = (ltp - avg_price) / tsl
  //     if(tslM > 0) {
  //       const tslP = tslM * tsl
  //       const tN = sl + tslP
  //       if(tN > sl) {
  //         sl = tN
  //       }
  //     }
  //   } else {
  //     tslM = (avg_price - ltp) / tsl
  //     if(tslM > 0) {
  //       const tslP = tslM * tsl
  //       const tN = sl - tslP
  //       if(tN < sl) {
  //         sl = tN
  //       }
  //     }
  //   }
  // }
  tp = Math.max(0, tp)
  sl = Math.max(0, sl)
  return { tp, sl }
}
