/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import makeStyles from '@material-ui/styles/makeStyles'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSortBy, useTable } from 'react-table'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'

import {
  SPACING, theme, COLORS, ICONS, normalize,
} from '../../Theme'
import { segSymConverter } from '../../utils/common'
import { useTheme } from '../../Theme/ThemeProvider'
import { ALGO_KEY_MAP } from '../AlgoCondensedView/AlgoUtils'
import { copyAlgo } from '../../containers/Create/actions'
import Checkbox from '../../UI/Checkbox'
import StockIcon from '../../UI/StockIcon'
import SegSym from '../../UI/SegSym'
import ShowLTP from '../../UI/ShowLTP'
import { DYNAMIC_SEGMENTS, getSegSymFromDC } from '../../containers/Create/Create-utils'
import Expandable from '../../UI/Expandable'
// eslint-disable-next-line import/no-cycle
import BacktestsDetails from './BacktestsDetails'
import DotLoader from '../../UI/DotLoader'
import BacktestResultItem from './BacktestResultItem'
import { showSnackbar } from '../../UI/Snackbar'
import { BROKERS, LINK_MAP } from '../../utils/consts'
import CustomTooltip from '../../UI/CustomTooltip'
import Button from '../../UI/Button'
import { pathname_mapping } from '../../utils/navigationConstant'

export const CARD_HEIGHT = 158
export const CARD_WIDTH = 160
export const CONDENSED_HEIGHT = 88
export const CARD_WIDTH_MAP = {
  0: 145,
  1: 140,
  2: 150,
  3: 160,
  4: 200,
}

export const CARD_HEIGHT_MAP = {
  0: 158,
  1: 150,
  2: 165,
  3: 185,
  4: 200,
}

// helper to get btresults if seg_sym is provided
export const getReqBacktests = (backtest_results, index = 0, seg_sym) => {
  let reqBacktest = backtest_results[index]
  if (!reqBacktest || (reqBacktest && reqBacktest.seg_sym !== seg_sym)) {
    reqBacktest = backtest_results.filter(bt => bt.seg_sym === seg_sym)
    reqBacktest = reqBacktest[0] || { seg_sym }
  }
  return reqBacktest
}

const stockIconSize = 24

const styleProps = {
  checkbox: () => ({ width: 30, padding: '0px 10px', textAlign: 'center' }),
  instrument: () => ({ width: '32%', padding: '0px 20px', textAlign: 'left' }),
  ltp: () => ({ width: '10%', padding: '0px 10px', textAlign: 'center' }),
  pnl: () => ({ width: '10%', padding: '0px 10px', textAlign: 'center' }),
  wvl: () => ({ width: '10%', padding: '0px 10px', textAlign: 'center' }),
  signals: () => ({ width: '10%', padding: '0px 10px', textAlign: 'center' }),
  ws: () => ({ width: '5%', padding: '0px', textAlign: 'center' }),
  ls: () => ({ width: '5%', padding: '0px', textAlign: 'center' }),
  maxdd: () => ({ width: '8%', padding: '0', textAlign: 'center' }),
  nudge: () => ({ width: '5%', padding: '0', textAlign: 'center' }),
}

const BacktestResultsListView = ({
  scripList, backtest_results, renderPnl,
  selectedSymbols = new Set(),
  deployed_seg_sym = [],
  onSymbolSelect, allSelected,
  brokerageData, brokerage, algoIdKey, algo_uuid,
  backtestDetailsProps, algo_subscription_uuid, publishing_uuid,
  isRunningBacktests, shared, toggleSelectAll, isBacktestExist,
  isLoggedIn, seg_sym_list, ticks, showEditBtn, showDCParams,
  shareSelected, user_details, reRunBacktestHandler, mtfExist,
  showSideBar, renderNudge, showNudgeTooltip,
  algo_name,
}) => {
  const { isMobile, isDark } = useTheme()
  const [expandKey, updateKey] = useState([])
  const history = useHistory()
  const { location: { pathname = '' } } = history

  const styles = stylesheet()
  const showSelection = isMobile && (
    selectedSymbols.size > 0)

  useEffect(() => {
    const mtfEle = document.getElementById('mtfContainer')
    backtest_results.forEach((item) => {
      const mtfRowEle = document.getElementById(`${item.seg_sym}_mtf`)
      const mtfCumuContainer = mtfEle?.style.display
      if(mtfCumuContainer === 'none') {
        mtfRowEle.style.display = 'none'
      }
    })
  }, [])

  const onUpdateExpansion = (item, a, extraParams) => {
    const rowMtfEle = document.getElementById(`${item}_mtf`)
    const display = rowMtfEle?.style.display
    if (mtfExist && display !== 'none') {
      return
    }

    if (showSelection) {
      if (deployed_seg_sym.includes(extraParams.seg_sym)) {
        showSnackbar('Cannot select a deployed symbol')
        return
      }
      onSymbolSelect(extraParams)
      return
    }
    const index = expandKey.indexOf(item)
    let newExpanded = expandKey

    if (index > -1) {
      newExpanded = expandKey.filter((key) => {
        return key !== item
      })
      updateKey(newExpanded)
    } else {
      newExpanded = [...expandKey, item]
      updateKey(newExpanded)
    }
  }

  useEffect(() => {
    updateKey([])
    if(mtfExist) {
      return
    }
    if (shareSelected.size > 0) {
      const expandDetail = [...shareSelected][0]
      updateKey([expandDetail])
    }
  }, [shareSelected])

  const stockIconContianerStyles = {
    marginRight: SPACING.SPACE_10,
  }

  const getLtp = (seg_sym) => {
    const token = seg_sym_list[seg_sym]
    const { broker } = user_details
    let change = 0
    let ltp = 0
    if (broker === BROKERS.ZERODHA.name) {
      if (ticks[token]) {
        ({ change, last_price: ltp } = ticks[token])
      }
    } else if (ticks[seg_sym]) {
      ({ change, last_price: ltp } = ticks[seg_sym])
    }
    return { ltp, change }
  }

  const renderSortIcon = (isDesc) => {
    return <Icon name={ICONS[isDesc ? 'DOWN' : 'UP']} style={{ marginLeft: SPACING.SPACE_4 }} color={theme.textSecondary} size={9} />
  }

  // const getTokens = (rowA, rowB) => {
  //   const { seg_sym: segSymA } = segSymConverter(rowA.original.seg_sym)
  //   const { seg_sym: segSymB } = segSymConverter(rowB.original.seg_sym)
  //   const tokenA = seg_sym_list[segSymA]
  //   const tokenB = seg_sym_list[segSymB]

  //   return { tokenA, tokenB }
  // }

  const symbolResult = (item, index) => {
    const {
      seg_sym,
      backtest_result = {},
      waiting = false,
    } = getReqBacktests(backtest_results, index, item)
    const scripBtResult = backtest_result[seg_sym] || {}
    const scrip = scripList.filter(e => e.seg_sym === seg_sym)[0]
    const {
      return: final_return = '', error_msg = '', win_count = 0,
      total_number_of_signals = 1,
      loss_count = 0,
      winning_streak = 0,
      lossing_streak = 0,
      max_draw,
      final_pnl = 0,
    } = scripBtResult
    const isSelected = selectedSymbols.has(seg_sym)
    const isDeployed = deployed_seg_sym.some(segSym => segSym === seg_sym)
    let displayPnl = final_pnl
    let totalReturn = final_return
    if (brokerageData[seg_sym] && brokerage) {
      totalReturn = Number(brokerageData[seg_sym].period_return)
      displayPnl = Number(brokerageData[seg_sym].pnl)
    }

    const data = {
      scripBtResult,
      isDeployed,
      isSelected,
      scrip,
      error_msg,
      totalReturn,
      final_return,
      waiting,
      total_number_of_signals,
      win_count,
      winning_streak,
      loss_count,
      lossing_streak,
      max_draw,
      final_pnl: displayPnl,
    }

    return data
  }

  const onClickReRunBacktest = (e, seg_sym) => {
    e.stopPropagation()
    reRunBacktestHandler(seg_sym)
  }

  const onClickShowDCParams = (e, { scrip, index }) => {
    e.stopPropagation()
    showDCParams({ scrip, index })
  }

  const removeOverLay = (seg_sym) => {
    const mtfEle = document.getElementById(`${seg_sym}_mtf`)
    const getPnlEle = document.getElementById(`${seg_sym}_mtf_pnl`)
    const mtfIds = ['pnl', 'wvl', 'signals', 'ws', 'ls', 'maxdd']
    if(!isMobile) {
      mtfIds.forEach((item) => {
        const getPnlElde = document.getElementById(`${seg_sym}_${item}`)
        getPnlElde.className = styles.headerColumn
      })
    }
    if (mtfEle) {
      mtfEle.style.display = 'none'
    }
    if(getPnlEle && isMobile) {
      getPnlEle.style.display = 'none'
    }
    showSnackbar('To access the backtest results for all individual instruments, click on "Show results" of the cumulative backtest result.')
  }

  const renderMTFDesc = (seg_sym) => {
    return (
      <div className={styles.mtfDesc} id="mtfListView">
        <CustomText size="small_1" weight="medium" className={styles.mtfTitle} color="orange" id={`${seg_sym}_mtf_title`}>
          Backtest results for MTF are unverifiable.
          <a href={LINK_MAP.MTF.link} rel="noreferrer" target="_blank"> Learn More.</a>
        </CustomText>
        <Button
          mode="text"
          labelColor={isDark ? 'blue_300' : 'blue'}
          transparent
          onPress={() => removeOverLay(seg_sym)}
          text="Show result"
          btnStyles={styles.showResultBtn}
          id={`${seg_sym}_mtf_btn`}
          respText
        />
      </div>
    )
  }

  const columns = React.useMemo(() => [
    {
      Header: () => {
        const hideCheckBox = algoIdKey === ALGO_KEY_MAP.DISCOVER || algoIdKey
          === ALGO_KEY_MAP.SHARED_ALGOS

        if (hideCheckBox) return null

        return (
          <div className={styles.checkboxHeader}>
            {backtest_results.length ? (
              <Checkbox
                value={allSelected}
                onValueChange={() => toggleSelectAll(allSelected)}
                className={`${styles.waitingCheckbox} ${allSelected ? styles.selectedCheckbox : ''}`}
                id="scanner_result_all"
              />
            ) : null}
          </div>
        )
      },
      Cell: ({ row }) => {
        const item = row.original
        const { seg_sym } = segSymConverter(item.seg_sym)
        const isSelected = selectedSymbols.has(seg_sym)
        const isDeployed = deployed_seg_sym.some(segSym => segSym === seg_sym)
        const hideCheckBox = algoIdKey === ALGO_KEY_MAP.DISCOVER || algoIdKey
          === ALGO_KEY_MAP.SHARED_ALGOS

        if (hideCheckBox) return null

        return (
          <div className={styles.boxRow}>
            {isDeployed
              ? (
                <Icon
                  name={ICONS.DEPLOYED}
                  SIZE={16}
                  color={COLORS.VOILET}
                  style={{ marginTop: SPACING.SPACE_8 }}
                />
              )
              : (
                <Checkbox
                  value={isSelected}
                  onValueChange={onSymbolSelect}
                  params={{ isSelected, seg_sym }}
                  className={`${styles.waitingCheckbox} ${isSelected ? styles.selectedCheckbox : ''}`}
                  id={`scanner_${algo_uuid}_${seg_sym}_checkbox`}
                />
              )}

          </div>
        )
      },
      id: 'checkbox',
      disableSortBy: true,
    },
    {
      Header: () => (
        <div>
          <CustomText size="small" color="textSecondary">Instrument</CustomText>
        </div>
      ),
      Cell: ({ row }) => {
        const item = row.original
        const { seg_sym } = segSymConverter(item.seg_sym)
        const { segment } = segSymConverter(seg_sym)
        const showEdit = showEditBtn
          && Boolean(item.details || DYNAMIC_SEGMENTS.includes(segment.toUpperCase()))
        const index = row.id
        const scrip = item
        return (
          <div className={styles.symRow}>
            <StockIcon
              seg_sym={seg_sym}
              height={stockIconSize}
              width={stockIconSize}
              imgProps={{
                style: {
                  height: stockIconSize,
                  width: stockIconSize,
                },
              }}
              containerStyle={stockIconContianerStyles}
            />
            <SegSym seg_sym={seg_sym} column showTooltip symStyles={{ whiteSpace: 'pre-wrap' }} alignToCenter={false} />
            {showEdit && (
              <>
                <div className={styles.seperator} />
                <div
                  onClick={e => onClickShowDCParams(e, { scrip, index })}
                  onKeyDown={e => onClickShowDCParams(e, { scrip, index })}
                  className={styles.editBtn}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name={ICONS.EDIT} size={12} color={theme.linkColor} />
                </div>
              </>
            )}

          </div>
        )
      },
      id: 'instrument',
      tooltip: 'Instrument',
      accessor: row => row.seg_sym,
      sortType: (rowA, rowB) => {
        const { symbol: rowAValue } = segSymConverter(rowA.original.seg_sym)
        const { symbol: rowBValue } = segSymConverter(rowB.original.seg_sym)
        if (rowAValue > rowBValue) return 1
        if (rowAValue < rowBValue) return -1
        return 0
      },
    },
    {
      Header: () => (
        <div>
          <CustomText size="small" color="textSecondary">LTP</CustomText>
        </div>
      ),
      Cell: ({ row }) => {
        const item = row.original
        const { segSym: seg_sym } = getSegSymFromDC(item.seg_sym)
        return (
          <div>
            <ShowLTP
              containerStyles={styles.ltpContainer}
              ltpStyles={styles.ltp}
              changeStyles={styles.change}
              showBrackets
              seg_sym={seg_sym}
            />
          </div>
        )
      },
      id: 'ltp',
      tooltip: 'LTP',
      accessor: row => row.seg_sym,
      sortType: (rowA, rowB) => {
        const { seg_sym: seg_symA } = segSymConverter(rowA.original.seg_sym)
        const { seg_sym: seg_symB } = segSymConverter(rowB.original.seg_sym)
        const rowAValue = getLtp(seg_symA).ltp
        const rowBValue = getLtp(seg_symB).ltp
        if (rowAValue > rowBValue) return 1
        if (rowAValue < rowBValue) return -1
        return 0
      },
    },
    {
      Header: () => (
        <div>
          <CustomText size="small" color="textSecondary">PNL</CustomText>
        </div>
      ),
      Cell: ({ row }) => {
        const item = row.original
        const { seg_sym } = item
        const {
          error_msg, totalReturn, waiting, final_pnl,
        } = symbolResult(seg_sym, row.id)

        if (error_msg) {
          const tooltip = () => (
            <CustomText color="white" weight="medium" size="tiny">
              Re-run Backtest
            </CustomText>
          )
          return (
            <div
              className={styles.error}
              style={{ top: normalize(1), left: '45%' }}
            >
              <div className={styles.error_msg}>
                <CustomText size="small_0" color={theme.reRunBacktestText}>
                  {error_msg}
                </CustomText>
              </div>
              <CustomTooltip
                show
                contentRenderer={tooltip}
                tooltipContainerStyles={styles.tooltipContainer}
                tooltipProps={{ text: 'Re-run Backtest' }}
              >
                <div
                  onClick={e => onClickReRunBacktest(e, seg_sym)}
                  className={styles.error_text}
                  onKeyDown={e => onClickReRunBacktest(e, seg_sym)}
                  role="button"
                  tabIndex={0}
                >
                  <Icon
                    id="refresh"
                    name={ICONS.REFRESH}
                    size={16}
                    color={isDark ? COLORS.WHITE : COLORS.BLUE}
                    style={{ marginTop: SPACING.SPACE_6 }}
                  />
                </div>
              </CustomTooltip>
            </div>
          )
        }
        return (
          <div id={`${seg_sym}_mtf_pnl`}>
            {
              renderPnl({
                error_msg, final_return: totalReturn, waiting, final_pnl,
              })
            }
          </div>
        )
      },
      id: 'pnl',
      tooltip: 'PNL',
      accessor: row => row.seg_sym,
      sortType: (rowA, rowB) => {
        const itemA = rowA.original
        const itemB = rowB.original

        const { seg_sym: segSymA } = itemA
        const { seg_sym: segSymB } = itemB

        const rowAValue = symbolResult(segSymA, itemA.id).final_pnl
        const rowBValue = symbolResult(segSymB, itemB.id).final_pnl
        if (rowAValue > rowBValue) return 1
        if (rowAValue < rowBValue) return -1
        return 0
      },
    },
    {
      Header: () => (
        <div>
          <CustomText size="small" color="textSecondary">W vs L</CustomText>
        </div>
      ),
      Cell: ({ row }) => {
        const item = row.original
        const { seg_sym } = item
        const {
          win_count, loss_count, waiting,
        } = symbolResult(seg_sym, row.id)
        const renderTypeStyles = styles[win_count >= loss_count ? 'gain' : 'loss']
        if (waiting) {
          return (
            <div className={styles.pnlPlaceHolder}>
              <DotLoader props={{ width: 30 }} />
            </div>
          )
        }
        const { error_msg } = symbolResult(seg_sym, row.id)
        if (error_msg) {
          return <div />
        }
        return (
          <div>
            <CustomText size="small" weight="medium" className={renderTypeStyles}>{`${win_count} | ${loss_count}`}</CustomText>
          </div>
        )
      },
      id: 'wvl',
      tooltip: 'W vs L',
      accessor: row => row.seg_sym,
      sortType: (rowA, rowB) => {
        const itemA = rowA.original
        const itemB = rowB.original

        const { seg_sym: segSymA } = itemA
        const { seg_sym: segSymB } = itemB

        const rowAValue = symbolResult(segSymA, itemA.id).win_count
          / symbolResult(segSymA, itemA.id).loss_count
        const rowBValue = symbolResult(segSymB, itemB.id).win_count
          / symbolResult(segSymB, itemB.id).loss_count
        if (rowAValue > rowBValue) return 1
        if (rowAValue < rowBValue) return -1
        return 0
      },
    },
    {
      Header: () => (
        <div>
          <CustomText size="small" color="textSecondary">Signals</CustomText>
        </div>
      ),
      Cell: ({ row }) => {
        const item = row.original
        const { seg_sym } = item
        const {
          total_number_of_signals, waiting,
        } = symbolResult(seg_sym, row.id)
        if (waiting) {
          return (
            <div className={styles.pnlPlaceHolder}>
              <DotLoader props={{ width: 30 }} />
            </div>
          )
        }
        const { error_msg } = symbolResult(seg_sym, row.id)
        if (error_msg) {
          return <div />
        }
        return (
          <div>
            <CustomText>{total_number_of_signals}</CustomText>
          </div>
        )
      },
      id: 'signals',
      tooltip: 'Signals',
      accessor: row => row.seg_sym,
      sortType: (rowA, rowB) => {
        const itemA = rowA.original
        const itemB = rowB.original

        const { seg_sym: segSymA } = itemA
        const { seg_sym: segSymB } = itemB

        const rowAValue = Number(symbolResult(segSymA, itemA.id).total_number_of_signals)
        const rowBValue = Number(symbolResult(segSymB, itemB.id).total_number_of_signals)
        if (rowAValue > rowBValue) return 1
        if (rowAValue < rowBValue) return -1
        return 0
      },
    },
    {
      Header: () => (
        <div>
          <CustomText size="small" color="textSecondary">WS</CustomText>
        </div>
      ),
      Cell: ({ row }) => {
        const item = row.original
        const { seg_sym } = item
        const {
          winning_streak, waiting,
        } = symbolResult(seg_sym, row.id)

        if (waiting) {
          return (
            <div className={styles.pnlPlaceHolder}>
              <DotLoader props={{ width: 30 }} />
            </div>
          )
        }
        const { error_msg } = symbolResult(seg_sym, row.id)
        if (error_msg) {
          return <div />
        }
        return (
          <div>
            <CustomText>{winning_streak}</CustomText>
          </div>
        )
      },
      id: 'ws',
      tooltip: 'WS',
      accessor: row => row.seg_sym,
      sortType: (rowA, rowB) => {
        const itemA = rowA.original
        const itemB = rowB.original

        const { seg_sym: segSymA } = itemA
        const { seg_sym: segSymB } = itemB

        const rowAValue = symbolResult(segSymA, itemA.id).winning_streak
        const rowBValue = symbolResult(segSymB, itemB.id).winning_streak
        if (rowAValue > rowBValue) return 1
        if (rowAValue < rowBValue) return -1
        return 0
      },
    },
    {
      Header: () => (
        <div>
          <CustomText size="small" color="textSecondary">LS</CustomText>
        </div>
      ),
      Cell: ({ row }) => {
        const item = row.original
        const { seg_sym } = item
        const {
          lossing_streak, waiting,
        } = symbolResult(seg_sym, row.id)

        if (waiting) {
          return (
            <div className={styles.pnlPlaceHolder}>
              <DotLoader props={{ width: 30 }} />
            </div>
          )
        }
        const { error_msg } = symbolResult(seg_sym, row.id)
        if (error_msg) {
          return <div />
        }
        return (
          <div>
            <CustomText>{lossing_streak}</CustomText>
          </div>
        )
      },
      id: 'ls',
      tooltip: 'LS',
      accessor: row => row.seg_sym,
      sortType: (rowA, rowB) => {
        const itemA = rowA.original
        const itemB = rowB.original

        const { seg_sym: segSymA } = itemA
        const { seg_sym: segSymB } = itemB

        const rowAValue = symbolResult(segSymA, itemA.id).lossing_streak
        const rowBValue = symbolResult(segSymB, itemB.id).lossing_streak
        if (rowAValue > rowBValue) return 1
        if (rowAValue < rowBValue) return -1
        return 0
      },
    },
    {
      Header: () => (
        <div>
          <CustomText size="small" color="textSecondary">Max DD</CustomText>
        </div>
      ),
      Cell: ({ row }) => {
        const item = row.original
        const { seg_sym } = item
        const {
          max_draw, waiting,
        } = symbolResult(seg_sym, row.id)
        const max_dd = max_draw || typeof max_draw === 'number' ? Number(max_draw).toFixed(4) : ''

        if (waiting) {
          return (
            <div className={styles.pnlPlaceHolder}>
              <DotLoader props={{ width: 30 }} />
            </div>
          )
        }
        const { error_msg } = symbolResult(seg_sym, row.id)
        if (error_msg) {
          return <div />
        }
        return (
          <div>
            <CustomText>{max_dd}</CustomText>
          </div>
        )
      },
      id: 'maxdd',
      tooltip: 'Max DD',
      accessor: row => row.seg_sym,
      sortType: (rowA, rowB) => {
        const itemA = rowA.original
        const itemB = rowB.original

        const { seg_sym: segSymA } = itemA
        const { seg_sym: segSymB } = itemB
        const rowAValue = Number(symbolResult(segSymA, itemA.id).max_draw)
        const rowBValue = Number(symbolResult(segSymB, itemB.id).max_draw)

        if (rowAValue > rowBValue) return 1
        if (rowAValue < rowBValue) return -1
        return 0
      },
    },
    {
      Header: () => (
        <div />
      ),
      id: 'nudge',
    },
  ], [scripList, selectedSymbols, allSelected, brokerage,
    showSelection, brokerageData, seg_sym_list, isDark,
  ])

  const handleMouseOver = (seg_sym) => {
    const mtfEle = document.getElementById(`${seg_sym}_mtf`)
    const mtfBtn = document.getElementById(`${seg_sym}_mtf_btn`)
    const mtfTitle = document.getElementById(`${seg_sym}_mtf_title`)
    const mtfPnl = document.getElementById(`${seg_sym}_mtf_pnl`)
    if(mtfEle && isMobile) {
      mtfEle.style.background = theme.hover
      mtfBtn.style.background = theme.bgPrimary
      mtfPnl.style.background = theme.hover
      return
    }
    if(mtfEle) {
      mtfEle.style.background = theme.hover
      mtfBtn.style.background = theme.bgPrimary
    }
    if(mtfTitle && isDark) {
      mtfTitle.style.background = theme.bgPrimary
    }
  }

  const handleMouseOut = (seg_sym) => {
    const mtfEle = document.getElementById(`${seg_sym}_mtf`)
    const mtfBtn = document.getElementById(`${seg_sym}_mtf_btn`)
    const mtfTitle = document.getElementById(`${seg_sym}_mtf_title`)
    const mtfPnl = document.getElementById(`${seg_sym}_mtf_pnl`)
    if(mtfEle && isMobile) {
      mtfEle.style.background = theme.bgPrimary
      mtfBtn.style.background = theme.hover
      mtfPnl.style.background = theme.bgPrimary
      return
    }
    if(mtfEle && !isMobile) {
      mtfEle.style.background = theme.bgPrimary
      mtfBtn.style.background = theme.mtfTitleBg
      mtfTitle.style.background = theme.mtfTitleBg
    }
  }

  const renderRow = (row) => {
    const { seg_sym } = row.original
    const item = symbolResult(seg_sym, row.id)
    const { waiting } = item
    const mtfCumuEle = document.getElementById('mtfContainer')
    const mtfCumuContainer = mtfCumuEle?.style.display
    const containerStyles = mtfCumuContainer === 'none' ? styles.mtfContainerHide : styles.mtfDescContainer
    const mtfPath = pathname === pathname_mapping.MarketplaceBacktest

    if (isMobile) {
      return (
        <BacktestResultItem
          item={item}
          seg_sym={seg_sym}
          renderPnl={renderPnl}
          showSelection={showSelection}
          reRunBacktestHandler={reRunBacktestHandler}
          removeOverLay={removeOverLay}
          mtfExist={mtfExist}
          renderNudge={renderNudge}
          showNudgeTooltip={showNudgeTooltip}
          row={row}
          algoIdKey={algoIdKey}
        />
      )
    }
    return (
      <div
        {...row.getRowProps()}
        className={styles.tableRow}
      >
        {row.cells.map((cell) => {
          const tdStyles = styleProps[cell.column.id]
            ? styleProps[cell.column.id]({ isMobile }) : {}
          const mtfIds = ['pnl', 'wvl', 'signals', 'ws', 'ls', 'maxdd']
          let headerStyles = styles.headerColumn
          const mtfContainer = document.getElementById('mtfContainer')
          const mtfContainerStyles = mtfContainer?.style.display
          const cndn = mtfIds.includes(cell.column.id)
          if(mtfContainerStyles !== 'none' && !isMobile) {
            headerStyles = (cndn && mtfExist) ? styles.headerColumnNone : ''
          }
          return (
            <div
              {...cell.getCellProps()}
              style={tdStyles}
              className={headerStyles}
              id={`${cell.value}_${cell.column.id}`}
            >
              {cell.render('Cell')}
            </div>
          )
        })}
        {mtfExist
          && (
          <div
            id={`${seg_sym}_mtf`}
            className={containerStyles}
            style={{ left: (showSideBar && !mtfPath) ? '' : '52%' }}
          >
            {renderMTFDesc(seg_sym)}
          </div>
          )}
        {waiting === false && algoIdKey === ALGO_KEY_MAP.MY_ALGOS
          ? (
            <button type="button" onClick={(e) => { showNudgeTooltip(e, seg_sym) }}>
              {renderNudge(row)
                ? renderNudge(row) : <div style={{ width: '50px' }} /> }
            </button>
          ) : <div style={{ width: '50px' }} /> }
      </div>
    )
  }

  const initialState = {
    sortBy: [
      {
        id: 'instrument',
        desc: false,
      },
    ],
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ data: scripList, columns, initialState }, useSortBy)
  return (
    <div {...getTableProps()} className={styles.table} id="backtestResultList">
      <div className={styles.tableBody}>
        {!isMobile ? headerGroups.map((headerGroup) => {
          return (
            <div
              className={styles.tableHeaderStyle}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => {
                const tdStyles = styleProps[column.id]
                  ? styleProps[column.id]({ isMobile }) : {}
                const cellProps = {
                  ...column.getHeaderProps(column.getSortByToggleProps()),
                  style: { ...tdStyles, cursor: column.disableSortBy ? 'default' : 'pointer' },
                  key: column.id,
                }
                return (
                  <div
                    {...cellProps}
                    className={`${column.disableSortBy ? '' : styles.tableHeaderCell}`}
                    title={['instrument', 'ltp', 'pnl', 'signals', 'wvl', 'ws', 'ls', 'maxdd'].includes(column.id) ? `Sort by ${column.tooltip}` : ''}
                  >
                    <div className={styles.tableHeader}>
                      {column.render('Header')}
                      {column.isSorted ? renderSortIcon(column.isSortedDesc) : ''}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        }) : null}
      </div>
      <div {...getTableBodyProps()} className={styles.tableBody}>
        {rows.map((row) => {
          prepareRow(row)
          const { original } = row
          const { seg_sym } = original
          const item = backtest_results
            .filter(result => result.seg_sym === seg_sym)[0]
            || { seg_sym }
          return (
            <div
              {...row.getRowProps()}
              id="tableRow"
              role="button"
              tabIndex={0}
              onMouseOver={() => handleMouseOver(seg_sym)}
              onMouseOut={() => handleMouseOut(seg_sym)}
            >
              <Expandable
                key={seg_sym}
                title={() => renderRow(row)}
                defaultExpanded={expandKey.includes(seg_sym)}
                onExpandCallback={onUpdateExpansion}
                expandKey={seg_sym}
                containerStyles={styles.expandableRow}
                headerStyle={styles.tableRow}
                showArrow={false}
                headerContainerStyles={styles.headerContainerStyles}
                showWhenExpanded={expandKey.includes(seg_sym)}
                contentStyles={classNames(styles.expandableContentStyle, 'hideScroll')}
                extraData={{ isSelected: selectedSymbols.has(seg_sym), seg_sym }}
              >
                <BacktestsDetails
                  {...backtestDetailsProps}
                  item={item}
                  algo_name={algo_name}
                  algoIdKey={algoIdKey}
                  algo_uuid={algo_uuid}
                  algo_subscription_uuid={algo_subscription_uuid}
                  publishing_uuid={publishing_uuid}
                  deployed_seg_sym={deployed_seg_sym}
                  isRunningBacktests={isRunningBacktests}
                  brokerageData={brokerageData}
                  brokerage={brokerage}
                  shared={shared}
                  isBacktestExist={isBacktestExist}
                  isLoggedIn={isLoggedIn}
                  reRunBacktestHandler={reRunBacktestHandler}
                />
              </Expandable>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const stylesheet = makeStyles({

  selectAllBtn: {
    marginRight: SPACING.SPACE_10,
  },
  table: {
    padding: `0 0 ${SPACING.SPACE_12}`,
    marginTop: SPACING.SPACE_10,
    width: '100%',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: 12,
    alignItems: 'center',
    position: 'relative',
  },
  tableHeaderStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: 12,
    alignItems: 'center',
    '&:first-child': {
      paddingLeft: SPACING.SPACE_10,
    },
  },
  tableHeaderRow: {
    alignItems: 'center',
    margin: `0 ${SPACING.SPACE_20} ${SPACING.SPACE_10}`,
    padding: `${SPACING.SPACE_16} 0 ${SPACING.SPACE_10}`,
  },
  expandableRow: {
    '&:hover': {
      backgroundColor: `${theme.hover} !important`,
    },
    // '&:hover $actionBar': {
    //   opacity: 1,
    // },
    '&:hover $editBtn': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover $error_msg': {
      backgroundColor: theme.hoverReRunBg,
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    padding: `${SPACING.SPACE_14} 0`,
  },
  tableHeaderCell: {
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  tableBody: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.bgPrimary,
    borderRadius: 12,
  },
  boxRow: {
    marginLeft: SPACING.SPACE_10,
  },
  checkboxHeader: {
    marginLeft: SPACING.SPACE_10,
  },
  error: {
    zIndex: 2,
    padding: `0 ${SPACING.SPACE_12}`,
    position: 'absolute',
    display: 'flex',
    width: 'calc(55% - 50px)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error_text: {
    marginTop: `-${SPACING.SPACE_2}`,
    marginLeft: SPACING.SPACE_20,
    marginRight: SPACING.SPACE_18,
    textAlign: 'center',
    fontWeight: 400,
    position: 'absolute',
    right: `-${SPACING.SPACE_30}`,
  },
  error_msg: {
    background: theme.reRunBacktestBg,
    borderRadius: SPACING.SPACE_4,
    padding: SPACING.SPACE_8,
  },
  tooltipContainer: {
    borderRadius: SPACING.SPACE_4,
  },
  symRow: {
    display: 'flex',
    alignItems: 'center',
  },
  editBtn: {
    opacity: 0,
    visibility: 'hidden',
  },
  gain: {
    color: `${COLORS.GREEN} !important`,
  },
  loss: {
    color: `${COLORS.RED} !important`,
  },
  expandContainer: {
    width: '100%',
  },
  headerContainerStyles: {
    padding: `${SPACING.SPACE_24} 0`,
  },
  expandableContentStyle: {
    overflowY: 'scroll',
  },
  headerColumn: {
    display: 'table-cell',
  },
  headerColumnNone: {
    visibility: 'hidden',
  },
  waitingCheckbox: {
    width: 20,
  },
  ltpContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  showResultBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    marginLeft: SPACING.SPACE_20,
    background: theme.mtfTitleBg,

  },
  mtfTitle: {
    background: theme.mtfTitleBg,
    padding: SPACING.SPACE_8,
    borderRadius: 8,
  },
  mtfDesc: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mtfDescContainer: {
    position: 'absolute',
    right: SPACING.SPACE_20,
    paddingLeft: SPACING.SPACE_20,
    background: theme.bgPrimary,
    zIndex: 999,
    '&:hover': {
      background: 'none',
    },
  },
  mtfContainerHide: {
    display: 'none',
  },
  '@media only screen and (max-width: 786px)': {
    headerContainerStyles: {
      padding: `${SPACING.SPACE_24} 0 ${SPACING.SPACE_8} 0`,
    },
  },
})

const mapStateToProps = (state) => {
  const { seg_sym_list, ticks } = state.instruments

  return {
    ...state.auth.loginResp,
    seg_sym_list,
    ticks,
    showSideBar: state.common.showSideBar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    copyAlgorithm: (params, headers) => dispatch(copyAlgo(params, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BacktestResultsListView)
