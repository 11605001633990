// export const CHECK_STATUS_INIT = 'CHECK_STATUS_INIT'
// export const CHECK_STATUS_SUCCESS = 'CHECK_STATUS_SUCCESS'
// export const CHECK_STATUS_FAILURE = 'CHECK_STATUS_FAILURE'

// export const CLEAR_CHECK_RESP = 'CLEAR_CHECK_RESP'

export const APP_VERSION = 'APP_VERSION'
export const APP_VERSION_SUCCESS = 'APP_VERSION_SUCCESS'
export const APP_VERSION_FAILURE = 'APP_VERSION_FAILURE'

export const CLEAR_VERSION = 'CLEAR_VERSION'

export const GET_USER_DETAILS_INIT = 'GET_USER_DETAILS_INIT'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE'
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS'

export const LOGIN_INIT = 'LOGIN_INIT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const CLEAR_LOGIN_RESP = 'CLEAR_LOGIN_RESP'

export const LOGOUT_INIT = 'LOGOUT_INIT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const UPDATE_CSRF_TOKEN = 'UPDATE_CSRF_TOKEN'

export const BROKER_LOGIN = 'BROKER_LOGIN'

export const FETCH_VERSION = 'FETCH_VERSION'
export const FETCH_VERSION_SUCCESS = 'FETCH_VERSION_SUCCESS'
export const FETCH_VERSION_FAILURE = 'FETCH_VERSION_FAILURE'

export const UPDATE_VERSION = 'UPDATE_VERSION'
export const UPDATE_VERSION_SUCCESS = 'UPDATE_VERSION_SUCCESS'
export const UPDATE_VERSION_FAILURE = 'UPDATE_VERSION_FAILURE'

export const VALIDATE_SESSION = 'VALIDATE_SESSION'
export const VALIDATE_SESSION_SUCCESS = 'VALIDATE_SESSION_SUCCESS'
export const VALIDATE_SESSION_FAILURE = 'VALIDATE_SESSION_FAILURE'

export const SAVE_LEAD = 'SAVE_LEAD'
export const SAVE_LEAD_SUCCESS = 'SAVE_LEAD_SUCCESS'
export const SAVE_LEAD_FAILURE = 'SAVE_LEAD_FAILURE'

export const SET_TOKEN = 'SET_TOKEN'

export const VALIDATE_EMAIL = 'VALIDATE_EMAIL'
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS'
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE'

export const EMAIL_LOGIN = 'EMAIL_LOGIN'

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT'

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const FORGOT_PASSWORD_INIT = 'FORGOT_PASSWORD_INIT'

export const SWTICH_BROKER_FAILURE = 'SWTICH_BROKER_FAILURE'

export const ACCOUNT_ACTIVATE_INIT = 'ACCOUNT_ACTIVATE_INIT'
export const ACCOUNT_ACTIVATE_SUCCESS = 'ACCOUNT_ACTIVATE_SUCCESS'
export const ACCOUNT_ACTIVATE_FAILURE = 'ACCOUNT_ACTIVATE_FAILURE'

export const UPDATE_PARTNER_REF = 'UPDATE_PARTNER_REF'
export const UPDATE_PARTNER_REF_SUCCESS = 'UPDATE_PARTNER_REF_SUCCESS'
export const UPDATE_PARTNER_REF_FAILURE = 'UPDATE_PARTNER_REF_FAILURE'

export const UPDATE_ONBRDING_ACTIVITY = 'UPDATE_ONBRDING_ACTIVITY'
export const UPDATE_ONBRDING_ACTIVITY_SUCCESS = 'UPDATE_ONBRDING_ACTIVITY_SUCCESS'
export const UPDATE_ONBRDING_ACTIVITY_FAILURE = 'UPDATE_ONBRDING_ACTIVITY_FAILURE'

export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL'

export const GET_ALL_REWARDS_INIT = 'GET_ALL_REWARDS_INIT'
export const GET_ALL_REWARDS_SUCCESS = 'GET_ALL_REWARDS_SUCCESS'
export const GET_ALL_REWARDS_FAILURE = 'GET_ALL_REWARDS_FAILURE'

export const AWAIL_REWARD_INIT = 'AWAIL_REWARD_INIT'
export const AWAIL_REWARD_SUCCESS = 'AWAIL_REWARD_SUCCESS'
export const AWAIL_REWARD_FAILURE = 'AWAIL_REWARD_FAILURE'

export const UPDATE_FANCY_BROKER = 'UPDATE_FANCY_BROKER'

export const SHOW_DEEPLINK_MODAL = 'SHOW_DEEPLINK_MODAL'
