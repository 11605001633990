export const SAVE_PROFILE_INIT = 'SAVE_PROFILE_INIT'
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS'
export const SAVE_PROFILE_FAILURE = 'SAVE_PROFILE_FAILURE'

export const CLEAR_PROFILE_RESP = 'CLEAR_PROFILE_RESP'

export const GENERATE_OTP_INIT = 'GENERATE_OTP_INIT'
export const GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS'
export const GENERATE_OTP_FAILURE = 'GENERATE_OTP_FAILURE'

export const REQUEST_DEMO_CALL = 'REQUEST_DEMO_CALL'
export const REQUEST_DEMO_CALL_SUCCESS = 'REQUEST_DEMO_CALL_SUCCESS'
export const REQUEST_DEMO_CALL_FAILURE = 'REQUEST_DEMO_CALL_FAILURE'
export const CLEAR_DEMO_CALL_RESP = 'CLEAR_DEMO_CALL_RESP'
export const TOGGLE_REQUEST_DEMO = 'TOGGLE_REQUEST_DEMO'

export const IS_DEMO_REQUESTED = 'IS_DEMO_REQUESTED'

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'
export const UPDATE_PROFILE_INIT = 'UPDATE_PROFILE_INIT'

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'

export const REMOVE_PROFILE = 'REMOVE_PROFILE'
export const REMOVE_PROFILE_SUCCESS = 'REMOVE_PROFILE_SUCCESS'
export const REMOVE_PROFILE_FAILURE = 'REMOVE_PROFILE_FAILURE'

export const FETCH_FILE = 'FETCH_FILE'
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS'
export const FETCH_FILE_FAILURE = 'FETCH_FILE_FAILURE'

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT'

export const SHOULD_REQUEST_DEMO = 'SHOULD_REQUEST_DEMO'

export const UPDATE_TRIAL_PLAN = 'UPDATE_TRIAL_PLAN'
export const UPDATE_TRIAL_PLAN_SUCCESS = 'UPDATE_TRIAL_PLAN_SUCCESS'
export const UPDATE_TRIAL_PLAN_FAILURE = 'UPDATE_TRIAL_PLAN_FAILURE'

export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK'
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS'
export const SUBMIT_FEEDBACK_FAILURE = 'SUBMIT_FEEDBACK_FAILURE'

export const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER'
export const VERIFY_PHONE_NUMBER_SUCCESS = 'VERIFY_PHONE_NUMBER_SUCCESS'
export const VERIFY_PHONE_NUMBER_FAILURE = 'VERIFY_PHONE_NUMBER_FAILURE'

export const SHOW_ADD_BROKER_POPUP = 'SHOW_ADD_BROKER_POPUP'

export const FETCH_SHAREABLE_LINKS = 'FETCH_SHAREABLE_LINKS'
export const FETCH_SHAREABLE_LINKS_SUCCESS = 'FETCH_SHAREABLE_LINKS_SUCCESS'
export const FETCH_SHAREABLE_LINKS_FAILURE = 'FETCH_SHAREABLE_LINKS_FAILURE'

export const DELETE_SHAREABLE_LINKS = 'DELETE_SHAREABLE_LINKS'
export const DELETE_SHAREABLE_LINKS_SUCCESS = 'DELETE_SHAREABLE_LINKS_SUCCESS'
export const DELETE_SHAREABLE_LINKS_FAILURE = 'DELETE_SHAREABLE_LINKS_FAILURE'
