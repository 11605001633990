import {
  FETCH_POSITIONS_INIT,
  FETCH_POSITIONS_SUCCESS,
  FETCH_POSITIONS_FAILURE,
  FETCH_HOLDINGS_INIT,
  FETCH_HOLDINGS_SUCCESS,
  FETCH_HOLDINGS_FAILURE,
  FETCH_SPECIFIC_INIT,
  FETCH_SPECIFIC_SUCCESS,
  FETCH_SPECIFIC_FAILURE,
  GET_LIVE_ALGO_INIT,
  GET_LIVE_ALGO_SUCCESS,
  GET_LIVE_ALGO_FAILURE,
  EXIT_POSITION_INIT,
  EXIT_POSITION_SUCCESS,
  EXIT_POSITION_FAILURE,
  CLEAR_PORTFOLIO_RESP,
  ADD_UNREALISED_PNL,
  CLEAR_UNREALISED_PNL,
} from './actionTypes'

import { NAVIGATIONS } from '../../utils/navigationConstant'

export function fetchPositions(params, headers, screenName = NAVIGATIONS.PORTFOLIO.name) {
  return {
    type: FETCH_POSITIONS_INIT,
    params,
    headers,
    screenName,
  }
}

export function fetchPositionsSuccess(data) {
  return {
    type: FETCH_POSITIONS_SUCCESS,
    data,
  }
}

export function fetchPositionsFailure(error) {
  return {
    type: FETCH_POSITIONS_FAILURE,
    error,
  }
}

export function fetchHoldings(params, headers, screenName = NAVIGATIONS.PORTFOLIO.name) {
  return {
    type: FETCH_HOLDINGS_INIT,
    params,
    headers,
    screenName,
  }
}

export function fetchHoldingsSuccess(data) {
  return {
    type: FETCH_HOLDINGS_SUCCESS,
    data,
  }
}

export function fetchHoldingsFailure(error) {
  return {
    type: FETCH_HOLDINGS_FAILURE,
    error,
  }
}

export function fetchSpecific(params, headers) {
  return {
    type: FETCH_SPECIFIC_INIT,
    params,
    headers,
  }
}

export function fetchSpecificSuccess(data) {
  return {
    type: FETCH_SPECIFIC_SUCCESS,
    data,
  }
}

export function fetchSpecificFailure(error) {
  return {
    type: FETCH_SPECIFIC_FAILURE,
    error,
  }
}

export function getLiveAlgo(params, headers) {
  return {
    type: GET_LIVE_ALGO_INIT,
    headers,
    params,
  }
}

export function getLiveAlgoSuccess(data) {
  return {
    type: GET_LIVE_ALGO_SUCCESS,
    data,
  }
}

export function getLiveAlgoFailure(error) {
  return {
    type: GET_LIVE_ALGO_FAILURE,
    error,
  }
}

export function exitPosition(params, headers) {
  return {
    type: EXIT_POSITION_INIT,
    params,
    headers,
  }
}

export function exitPositionSuccess(data) {
  return {
    type: EXIT_POSITION_SUCCESS,
    data,
  }
}

export function exitPositionFailure(error) {
  return {
    type: EXIT_POSITION_FAILURE,
    error,
  }
}

export function clearPortfolioResp() {
  return {
    type: CLEAR_PORTFOLIO_RESP,
  }
}

export function addUnrealisedPnl(index, pnl, tabKey) {
  return {
    type: ADD_UNREALISED_PNL,
    index,
    pnl,
    tabKey,
  }
}

export function clearUnrealisedPnl(index, tabKey) {
  return {
    type: CLEAR_UNREALISED_PNL,
    index,
    tabKey,
  }
}
