/* eslint-disable no-restricted-globals */
import React, { PureComponent } from 'react'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

import CustomTooltip from '../../UI/CustomTooltip'
import Icon from '../../UI/Icon'
import IndicatorDetails from './IndicatorDetails'
import CustomText from '../../UI/CustomText'
import Switch from '../../UI/Switch'
import CustomModal from '../../UI/CustomModal'

import { withTheme } from '../../Theme/ThemeProvider'
import {
  ICONS, COLORS, DIMENSIONS, SPACING, FONTS, theme, ASSETS,
} from '../../Theme'
import { academyBaseUrl, s3Bucket, s3BucketMap } from '../../utils/apiEndpoints'
import { DISPLAY_DEVICE } from '../../utils/consts'
import { convertToIndicatorKeyName, setLocalStorage } from '../../utils/common'
import { PREFERENCES } from '../../containers/Profile/profileUtils'
import { showSnackbar } from '../../UI/Snackbar'
import { CONDITION_TYPE } from '../../containers/Create/Create-utils'

// indicatorState: state of whole condition
// itemKey: which item in the indicator state, indicator, comparator, or comparator indicator
// detailsKey: key in which indicator details can be found
// keysToShow: which indicators to show // ubb, adx etc
// sectionsToShow: which section to show // indicator comparator
// sectionType: type of section which is selcted // indicator, comparatorInidicator etc
// showDetails: show indicator details page
// this.acitveItem: current active  selected indicator
// this.sectionMap contains the category key that need to displayed
// like inidicator or math func or comparators
const containerWidth = 440
class IndicatorFinder extends PureComponent {
  constructor(props) {
    super(props)
    const {
      keysToShow, tree, indicatorState = {}, itemKey, sectionsToShow, sectionType,
      showDetails, updatedActiveItem, keysToHide, detailsKey, displayDevice,
    } = props
    this.tree = []
    this.sectionMap = []
    const hideSomeSections = Boolean(sectionsToShow && sectionsToShow.length)
    Object.keys(tree).forEach((typeKey) => {
      if (hideSomeSections && !sectionsToShow.includes(typeKey)) {
        return
      }
      if (tree[typeKey]) {
        const data = Object.keys(tree[typeKey])
        let finalData = data
        if (keysToShow || keysToHide) {
          finalData = data.filter((key) => {
            let shouldAdd = true
            if (keysToShow) {
              shouldAdd = keysToShow.includes(key)
            }
            if (keysToHide) {
              shouldAdd = shouldAdd && !keysToHide.includes(key)
            }
            return shouldAdd
          })
        }
        const obj = {
          name: typeKey, data: finalData,
        }
        this.tree.push(obj)
        this.sectionMap.push(typeKey)
      }
    })
    this.currentTree = [...this.tree]
    let fancyValue = ''
    this.activeItem = {}
    // const itemKey = `${sectionType}Item`
    if (itemKey && indicatorState[itemKey] && indicatorState[itemKey].name
      && (indicatorState[`${sectionType}Valid`] || indicatorState.valid)) { // [itemKey]
      fancyValue = indicatorState[itemKey].name
      this.activeItem = indicatorState[itemKey]
      if (detailsKey && indicatorState[detailsKey]) {
        this.activeItem = { ...indicatorState[itemKey], [detailsKey]: indicatorState[detailsKey] }
      }
    } else if (!itemKey && indicatorState && indicatorState.name) {
      fancyValue = indicatorState.name
      this.activeItem = indicatorState
    }
    // when user selects a field from inputoptions component
    if (showDetails && !isEmpty(updatedActiveItem)) {
      this.activeItem = updatedActiveItem
      fancyValue = updatedActiveItem.name
    }
    this.state = {
      fancyValue,
      activeSection: ['All'],
      isFocused: false,
      layoutDim: { opacity: 0 },
      showTooltip: true,
      currentIndex: 0,
      currentTypeIndex: 0,
    }
    this.containerRef = React.createRef()
    this.inputRef = React.createRef()
    this.tooltipRef = React.createRef()
    this.sectionRowRef = React.createRef()
    this.grpItemContainerRef = React.createRef()

    this.isMobile = displayDevice === DISPLAY_DEVICE.MOBILE.value
  }

  componentDidMount() {
    const { isConditionEmpty, showDetails } = this.props
    if (this.inputRef.current && !isConditionEmpty && !showDetails) {
      this.inputRef.current.focus()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isFocused } = this.state
    const { preferences } = this.props
    if (!prevState.isFocused && isFocused && this.containerRef.current
      && !this.isMobile) {
      this.measureLayout()
    }
    if (prevProps.preferences.globalAssist && !preferences.globalAssist) {
      showSnackbar('You can turn assit on anytime from My Preferences', { hideDelay: 4000 }, 1)
    }
  }

  measureLayout = () => {
    const { innerHeight: wHeight, innerWidth: wWidth } = this.props
    const containerEle = this.containerRef.current
    const {
      height: containerHeight, left: containerLeft, top: posTop,
    } = containerEle.getBoundingClientRect()

    const calcHeight = containerHeight > (wHeight * (3 / 4)) ? (wHeight * (3 / 4)) : containerHeight

    const layoutDim = { height: calcHeight, opacity: 1 }
    const main = document.getElementById('main')
    if (main) {
      const heightToView = calcHeight * 0.9
      const { offsetHeight } = main
      if (offsetHeight - posTop < heightToView) {
        main.scrollBy({ top: heightToView - (offsetHeight - posTop), behavior: 'smooth' })
      }
    }
    // const posFromTop = posTop + calcHeight
    // const outOfScreen = posFromTop >= wHeight
    // if (outOfScreen) {
    //   layoutDim.top = wHeight - posFromTop - 10
    // }
    let left = containerLeft
    if ((left + containerWidth) > wWidth) {
      left = wWidth - (left + containerWidth)
      layoutDim.left = left
    }
    // if (left < 0) {
    //   containerWidth = containerWidth + left - 20
    //   layoutDim.left = 10
    // }
    layoutDim.width = containerWidth
    // left
    // debugger
    this.setState({
      layoutDim,
    })
  }

  getFilteredList = (listKeys, regx, value, reqTree) => {
    const list = listKeys.filter((key) => {
      const {
        name = '', description = '', params = [], // syntax,
      } = reqTree[key]
      if (!isNaN(value)) {
        return params.length === 1 && !isNaN(params[0][1]) // && syntax.includes('number')
      }
      return regx.test(name.toLowerCase()) || regx.test(description.toLowerCase())
    })
    return list
  }

  filterList = debounce(() => {
    const newTree = []
    const { tree } = this.props
    const { fancyValue, activeSection } = this.state
    if (fancyValue) {
      RegExp.escape = (s) => {
        return s.replace(/[-\\^$*+?.()|[\]{}]/gi, '\\$&')
      }
      const regx = new RegExp(RegExp.escape(fancyValue), 'i')
      this.currentTree.forEach((keyObj) => {
        const { name = '', data } = keyObj
        if (activeSection[0] === 'All' || activeSection.includes(name)) {
          const reqTree = tree[name.toLowerCase()]
          const reqData = this.getFilteredList(data, regx, fancyValue, reqTree)
          if (reqData.length) {
            newTree.push({
              name,
              data: reqData,
            })
          }
        }
      })
      this.tree = newTree
    } else if (activeSection[0] !== 'All') {
      this.tree = this.currentTree.filter(item => activeSection.includes(item.name))
    } else {
      this.tree = [...this.currentTree]
    }

    this.setState({ updatedAt: Date.now(), showTooltip: true })
  }, 20)

  onInputChange = (e) => {
    const { value } = e.target
    const { showDetails } = this.props
    if (showDetails) return
    this.setState({
      fancyValue: value, showTooltip: false, currentIndex: 0, currentTypeIndex: 0,
    }, this.filterList())
  }

  updatePref = (value, prefType) => {
    const { updatePreferences, preferences } = this.props
    setLocalStorage({ [PREFERENCES.KEY]: { ...preferences, [prefType]: value } }, true)
    updatePreferences(value, prefType)
  }

  readMoreIndicator = (name) => {
    const indicatorKey = convertToIndicatorKeyName(name)
    const url = `${academyBaseUrl}${indicatorKey}`
    window.open(url, '_blank').focus()
  }

  renderSectionHeader = ({ section: { name } }) => {
    const { showSectionHeader, styles, isDark } = this.props
    if (showSectionHeader) {
      return (
        <CustomText className={styles.sectionTitle} color={isDark ? 'black_60' : ''}>{name}</CustomText>
      )
    }
    return null
  }

  onItemSelect = (derivedItem, sectionType, sectionName, itemKey) => {
    this.activeItem = derivedItem
    const { fancyValue } = this.state
    let details = null
    if (fancyValue && !isNaN(fancyValue) && derivedItem.params.length === 1) {
      details = { [derivedItem.params[0][0]]: fancyValue.toString() }
    } else {
      this.setState({ fancyValue: derivedItem.name })
    }
    this.props.onItemSelect(derivedItem, sectionType, sectionName, details, itemKey)
  }

  renderPopup = ({
    indicatorName, long_desciption, key,
  }) => {
    const { styles, preferences } = this.props
    const long_desc = long_desciption || 'learn more about this indicator from down below.'
    const imgSrc = key ? `${s3Bucket}${s3BucketMap.indicators}${key}.png` : ASSETS.Logo

    return (
      <div className={styles.popupContainer}>
        <div className={styles.gAContainer}>
          <CustomText size="small">Global Assist</CustomText>
          <Switch
            value={preferences[PREFERENCES.GLOBAL_ASSIST]}
            onValueChange={this.updatePref}
            params={PREFERENCES.GLOBAL_ASSIST}
            id={PREFERENCES.GLOBAL_ASSIST}
          />
        </div>
        <img alt="..." src={imgSrc} className={styles.popoverImg} />
        {/* ^ placeholder image ^ */}
        <CustomText size="medium" className={styles.popoverTitle} weight="semi_bold">{indicatorName}</CustomText>
        <CustomText className={styles.popoverDesc}>
          {long_desc}
        </CustomText>
        <button
          type="button"
          className={styles.readMorebtn}
          onClick={() => this.readMoreIndicator(indicatorName)}
        >
          <CustomText center color="linkColor" className={styles.selectBtnText}>Read more</CustomText>
        </button>
      </div>
    )
  }

  renderListItem = (derivedItem, sectionName, itemKey, index, typeIndex) => {
    const {
      sectionType, styles, preferences,
    } = this.props
    const { showTooltip } = this.state
    if (!derivedItem) {
      return null
    }
    const {
      name, description, 'long description': long_desciption, key,
    } = derivedItem
    if(key === 'accdist ma' || key === 'accdist') {
      return null
    }
    const { currentIndex, currentTypeIndex } = this.state
    const isSelected = index === currentIndex && currentTypeIndex === typeIndex
    const { name: selectedName } = this.activeItem
    const isGlobalAssistOn = preferences.globalAssist
    const showPopover = sectionName === 'indicators' && showTooltip && isGlobalAssistOn
    return (
      <CustomTooltip
        tooltipProps={{ text: description }}
        contentRenderer={() => this.renderPopup({ description, long_desciption, key })}
        placement="right"
        tooltipContainerStyles={styles.tooltipContainer}
        show={showPopover}
        key={name}
        interactive
      >
        <button
          id={`${sectionName}_${index}`}
          type="button"
          key={name}
          ref={this.tooltipRef}
          className={`${styles.itemBtn} ${isSelected ? styles.itemBtnSelected : ''}`}
          onClick={() => this.onItemSelect(derivedItem, sectionType, sectionName, itemKey)}
        >

          <div className={styles.item} key={name}>
            <div className={styles.itemInfo}>
              <CustomText className={styles.itemName}>{name}</CustomText>
              {!!description && <CustomText size="small" weight="regular" color="textGrey">{description}</CustomText>}
            </div>
            {selectedName === name && (
              <Icon name={ICONS.TICK_ROUND_FILLED} size={14} color={theme.linkColor} />
            )}
          </div>
        </button>
      </CustomTooltip>
    )
  }

  backPresshandler = () => {
    const { onDismiss, isConditionEmpty } = this.props
    if (isConditionEmpty) {
      this.setState({ isFocused: false })
    } else {
      onDismiss()
    }
  }

  clearValue = () => {
    this.setState({ fancyValue: '' }, this.filterList)
  }

  onSectionChange = (typeKey) => {
    this.setState({ activeSection: [typeKey] }, this.filterList)
  }

  renderSectionList = () => {
    const { styles, tree } = this.props
    const { activeSection } = this.state
    return (
      <>
        <div className={styles.sectionRow} ref={this.sectionRowRef}>
          {['All', ...this.sectionMap].map((typeKey) => {
            const isSelected = activeSection.includes(typeKey)
            return (
              <button
                type="button"
                key={typeKey}
                onClick={() => this.onSectionChange(typeKey)}
                className={styles.sectionKeyBtn}
                style={{ backgroundColor: isSelected ? COLORS.BLUE : theme.tabsBg }}
              >
                <CustomText size="small" color={isSelected ? 'white' : 'text'}>{typeKey}</CustomText>
              </button>
            )
          })}
        </div>
        <div className={styles.indicatorList}>
          {this.tree.map((sectionObj, i) => {
            const { name, data } = sectionObj
            return (
              <div key={name}>
                {data.map((itemKey, index) => this.renderListItem(
                  tree[name.toLowerCase()][itemKey], name, itemKey, index, i,
                ))}
              </div>
            )
          })}
        </div>
      </>
    )
  }

  onFocus = () => {
    const { isFocused, layoutDim } = this.state
    if (isFocused && layoutDim.opacity === 1) return
    this.setState({ isFocused: true, layoutDim: { opacity: 0 } })
  }

  onBlur = () => {
    this.setState({ isFocused: false, layoutDim: { opacity: 0 } })
  }

  onRemove = () => {
    this.props.onRemove()
  }

  keyboardNavHandler = (e) => {
    const { currentIndex, currentTypeIndex } = this.state
    const { key: keyPressed } = e
    if (!['ArrowDown', 'ArrowUp', 'Enter'].includes(keyPressed)) return
    e.preventDefault()
    let extraChange = {}
    let newIndex = 0
    let newTypeIndex = currentTypeIndex
    // const moOptionsList = this.tree[currentTypeIndex]
    if (!this.tree[currentTypeIndex]) return
    const treeData = this.tree[currentTypeIndex].data
    const sectionName = this.tree[currentTypeIndex].name
    const subTypeKey = treeData[currentIndex === -1 ? 0 : currentIndex]

    const len = treeData.length
    const parentEle = this.grpItemContainerRef.current
    let shudScroll = Boolean(parentEle)

    if (keyPressed === 'ArrowDown') {
      newIndex = currentIndex + 1
      if (newIndex >= len) {
        if (currentTypeIndex + 1 < this.tree.length) {
          newTypeIndex = currentTypeIndex + 1
          newIndex = 0
        } else {
          newTypeIndex = 0
          newIndex = 0
          if (shudScroll) parentEle.scrollTo(0, 0)
          shudScroll = false
        }
      }
    } else if (keyPressed === 'ArrowUp') {
      newIndex = currentIndex - 1
      if (newIndex <= -1) {
        if (currentTypeIndex - 1 > -1) {
          const prevType = this.tree[currentTypeIndex - 1]
          newTypeIndex = currentTypeIndex - 1
          newIndex = prevType.data.length - 1
        } else {
          const prevType = this.tree[this.tree.length - 1]
          newTypeIndex = this.tree.length - 1
          newIndex = prevType.data.length - 1
          if (shudScroll) parentEle.scrollTo(0, parentEle.scrollHeight)
          shudScroll = false
        }
      }
    } else if (keyPressed === 'Enter' && currentIndex !== -1) {
      const { sectionType } = this.props
      this.onItemSelect(
        this.props.tree[sectionName][subTypeKey],
        sectionType,
        sectionName,
        subTypeKey,
      )
      extraChange = { isFocused: false, layoutDim: { opacity: 0 } }
      newTypeIndex = 0
      newIndex = 0
      shudScroll = false
    }

    this.setState({ currentIndex: newIndex, currentTypeIndex: newTypeIndex, ...extraChange })
    if (shudScroll) {
      const eleId = `${this.tree[newTypeIndex].name}_${newIndex}`
      const ele = document.getElementById(eleId)
      if (ele) {
        let offset = 100
        if (this.sectionRowRef.current) offset = this.sectionRowRef.current.offsetHeight + 42
        if ((ele.offsetTop + ele.offsetHeight - offset) <= parentEle.scrollTop) {
          parentEle.scrollTo(0, ele.offsetTop - offset)
        } else if (ele.offsetTop >= (parentEle.scrollTop + parentEle.offsetHeight)) {
          parentEle.scrollTo(0, ele.offsetTop - parentEle.offsetHeight + ele.offsetHeight)
        }
      }
    }
  }

  renderInputField = () => {
    const {
      styles, showDetails, isConditionEmpty, index, isDark, editType,
    } = this.props
    const { fancyValue, isFocused } = this.state
    const addText = editType === CONDITION_TYPE.EXIT ? 'add? (Optional)' : 'add?'
    const notFirstCondition = isConditionEmpty && index > 0
    return (
      <div
        className={styles.header}
        style={{ backgroundColor: isDark ? COLORS.GREY_300 : COLORS.WHITE }}
      >
        {/* <CustomText style={styles.input}>{fancyValue}</CustomText> */}
        {this.isMobile && isFocused && (
          <button
            type="button"
            onClick={this.backPresshandler}
            className={styles.backIconBtn}
          >
            <Icon name={ICONS.BACK} size={14} />
          </button>
        )}
        <Icon name={ICONS.SEARCH} size={13} color={theme.text} />
        <input
          className={styles.input}
          ref={this.inputRef}
          value={fancyValue}
          placeholder={`What would you like to ${notFirstCondition ? 'add next?' : addText}`}
          onChange={this.onInputChange}
          onFocus={this.onFocus}
          onKeyDown={this.keyboardNavHandler}
        />
        {!showDetails && Boolean(fancyValue) && (
          <button
            type="button"
            className={styles.clearIconBtn}
            onClick={this.clearValue}
          >
            <Icon name={ICONS.CLOSE} color={theme.textSecondary} size={10} />
          </button>
        )}
      </div>
    )
  }

  render() {
    const {
      saveDetails, candleIntervalIndex, detailsKey, wrapperStyles, indicatorState,
      showDetails, styles, isConditionEmpty, containerStyles, onDismiss, itemKey,
    } = this.props
    const { isFocused, layoutDim } = this.state
    let extraStyles = {}
    if (isFocused) {
      extraStyles = this.isMobile ? {
        position: 'fixed',
        width: '100%',
        height: '100%',
        opacity: 1,
        borderColor: 'transparent',
        top: 0,
        left: 0,
        zIndex: 9999,
        margin: '0',
        maxWidth: 'unset',
      } : {
        position: 'absolute',
        ...layoutDim,
        zIndex: 9999,
        opacity: 1,
        borderColor: 'transparent',
        boxShadow: `0px 3px 20px ${theme.boxShadow}`,
        maxWidth: containerWidth,
      }
    }
    const indiName = itemKey && indicatorState[itemKey]
      ? indicatorState[itemKey].name : indicatorState.name
    return (
      <div className={`${styles.wrapper} ${isConditionEmpty ? styles.emptyWrapper : ''} ${wrapperStyles}`}>
        {showDetails ? (
          <CustomText className={styles.indicatorName}>{indiName}</CustomText>
        ) : (
          <>
            {isFocused && (
            <button type="button" className={styles.backDrop} onClick={this.backPresshandler} style={{ pointer: 'default' }}>
              <div className={styles.backDrop} style={{ backgroundColor: 'transparent' }} />
            </button>
            )}
            <div
              className={`${styles.container} ${!isFocused && containerStyles}`}
              style={extraStyles}
              ref={this.containerRef}
            >
              {this.renderInputField()}
              {isFocused && (
              <div
                className={styles.content}
                style={{
                  opacity: this.isMobile ? 1 : layoutDim.opacity,
                }}
                ref={this.grpItemContainerRef}
              >
                {this.renderSectionList()}
              </div>
              )}
            </div>
          </>
        )}
        <CustomModal
          visible={showDetails}
          onDismiss={onDismiss}
          containerStyles={styles.detailsModal}
        >
          <IndicatorDetails
            indicator={this.activeItem}
            saveDetails={saveDetails}
            candleIntervalIndex={candleIntervalIndex}
            indicatorDetails={this.activeItem[detailsKey]}
            onRemove={this.onRemove}
          />
        </CustomModal>
      </div>
    )
  }
}

const stylesheet = ({
  wrapper: {
    position: 'relative',
    minHeight: 35,
    minWidth: 70,
    marginTop: 0,
  },
  emptyWrapper: {
    marginTop: SPACING.SPACE_20,
    width: '100%',
    maxWidth: 340,
    '& $header': {
      padding: `0 ${SPACING.SPACE_12}`,
      borderRadius: 10,
    },
  },
  container: {
    flex: 1,
    maxWidth: 320,
    width: '100%',
    backgroundColor: theme.bgPrimary,
    marginRight: SPACING.SPACE_10,
    // padding: `${SPACING.SPACE_2} 0`,
    // overflow: 'hidden',
    marginBottom: SPACING.SPACE_16,
    borderRadius: 8,
    position: 'absolute',
    border: '1px solid',
    borderColor: theme.borderColor,
    maxHeight: 600,
    zIndex: 999,
    transition: 'all 0.3s var(--anim-func-ease)',
  },
  sectionTitle: {
    textTransform: 'capitalize',
    backgroundColor: theme.bgPrimary,
    paddingHorizontal: DIMENSIONS.SPACE_HORIZONTAL,
    paddingVertical: SPACING.SPACE_10,
  },
  itemBtn: {
    display: 'block',
    padding: '8px',
    width: '100%',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  itemBtnSelected: {
    backgroundColor: theme.hover,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: DIMENSIONS.ROW_SEPERATOR / 2,
    paddingHorizontal: DIMENSIONS.SPACE_HORIZONTAL,
  },
  itemInfo: {
    flex: 1,
  },
  itemName: {},
  header: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `0 ${SPACING.SPACE_8}`,
  },
  backBtn: {
    paddingVertical: DIMENSIONS.SPACE_VERTICAL,
    paddingLeft: DIMENSIONS.SPACE_HORIZONTAL / 2,
    paddingEnd: DIMENSIONS.SPACE_HORIZONTAL,
  },
  input: {
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_4}`,
    margin: 0,
    flex: 1,
    marginLeft: SPACING.SPACE_8,
    fontSize: FONTS.REGULAR,
    color: theme.text,
    backgroundColor: 'transparent',
  },
  removeBtn: {
    paddingHorizontal: SPACING.SPACE_10,
  },
  clearBtn: {
    paddingHorizontal: SPACING.SPACE_10,
  },
  editBtn: {
    paddingHorizontal: SPACING.SPACE_10,
  },
  content: {
    flex: 1,
    width: '85%',
    margin: 'auto',
    overflow: 'auto',
    height: 'calc(100% - 42px)',
    '&::-webkit-scrollbar': {
      display: 'none',
      '-ms-overflow-style': 'none', /* IE and Edge */
      scrollbarWidth: 'none', /* Firefox */
    },
  },
  list: {
    paddingTop: SPACING.SPACE_20,
  },
  indicatorSection: {},
  sectionRow: {
    // margin: `${SPACING.SPACE_10} 0`,
    padding: `${SPACING.SPACE_10} 0`,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.bgPrimary,
  },
  sectionKeyBtn: {
    borderRadius: '10px',
    backgroundColor: 'red',
    padding: '2px 14px',
    marginRight: SPACING.SPACE_10,
    marginTop: SPACING.SPACE_10,
    '&:last-child': {
      marginRight: 0,
    },
    '& p': {
      textTransform: 'capitalize',
    },
  },
  indicatorList: {},
  clearIconBtn: {
    alignItems: 'center',
    display: 'flex',
    padding: '0 10px',
  },
  backDrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'default',
  },
  detailsModal: {
    minHeight: 200,
    maxWidth: 570,
  },
  backIconBtn: {
    paddingRight: SPACING.SPACE_10,
    alignItems: 'center',
    display: 'flex',
  },
  popupContainer: {
    backgroundColor: theme.bgPrimary,
    padding: SPACING.SPACE_20,
    borderRadius: 8,
    minWidth: 300,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
  },
  popoverDesc: {
    padding: `${SPACING.SPACE_20} 0`,
  },
  popoverImg: {
    width: '100%',
    margin: `${SPACING.SPACE_20} 0`,
  },
  readMorebtn: {
    flex: 1,
    display: 'flex',
    padding: `${SPACING.SPACE_6} 0`,
    width: '60%',
  },
  gAContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  tooltipContainer: {
    backgroundColor: 'transparent',
  },
  indicatorName: {
    marginRight: SPACING.SPACE_14,
    padding: `${SPACING.SPACE_2} ${SPACING.SPACE_8}`,
    borderRadius: 4,
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      padding: SPACING.SPACE_8,
    },
  },
})

export default withTheme(stylesheet)(IndicatorFinder)
