import dayjs from 'dayjs'
import {
  // CHECK_STATUS_INIT,
  // CHECK_STATUS_SUCCESS,
  // CHECK_STATUS_FAILURE,
  // CLEAR_CHECK_RESP,
  CLEAR_VERSION,
  SET_TOKEN,

  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SWTICH_BROKER_FAILURE,
  CLEAR_LOGIN_RESP,

  GET_USER_DETAILS_INIT,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  CLEAR_USER_DETAILS,

  LOGOUT_INIT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,

  FETCH_VERSION,
  FETCH_VERSION_SUCCESS,
  FETCH_VERSION_FAILURE,

  UPDATE_VERSION,
  UPDATE_VERSION_SUCCESS,
  UPDATE_VERSION_FAILURE,

  VALIDATE_SESSION,
  VALIDATE_SESSION_SUCCESS,
  VALIDATE_SESSION_FAILURE,

  VALIDATE_EMAIL,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,

  EMAIL_LOGIN,

  RESET_PASSWORD_INIT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  FORGOT_PASSWORD_INIT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  ACCOUNT_ACTIVATE_INIT,
  ACCOUNT_ACTIVATE_SUCCESS,
  ACCOUNT_ACTIVATE_FAILURE,
  SHOW_LOGIN_MODAL,

  GET_ALL_REWARDS_INIT,
  GET_ALL_REWARDS_SUCCESS,
  GET_ALL_REWARDS_FAILURE,
  AWAIL_REWARD_INIT,
  AWAIL_REWARD_SUCCESS,
  AWAIL_REWARD_FAILURE,
  UPDATE_FANCY_BROKER,

  SAVE_LEAD,
  SAVE_LEAD_SUCCESS,
  SAVE_LEAD_FAILURE,

  SHOW_DEEPLINK_MODAL,
} from './actionTypes'
import { NOT_ALLOWED_SEGMENT, NOT_ALLOWED_UNIVERSE, BROKERS } from '../../utils/consts'

const initialState = {

  defaultVersion: null,
  isFetchingVersion: false,
  fetchVersionError: false,
  fetchVersionErrorMsg: '',

  updateVersionSuccess: null,
  isUpdatingVersion: false,
  updateVersionError: false,
  updateVersionErrorMsg: '',

  sessionValid: false,
  sessionValidError: false,

  isFetchingUser: false,
  user_details: {
    // user_profile: {
    //   terms_accepted: true
    // },
    // user_subscription: {}
  },
  userDetailsError: false,
  userDetailsErrorMsg: '',
  blockedSegmentList: NOT_ALLOWED_SEGMENT[0],

  isLogging: false,
  loginError: false,
  loginErrorMsg: '',
  loginResp: {},
  isLoggedIn: false,

  validatingEmail: false,
  emailVerified: false,
  emailVerifyError: false,
  emailVerifyErrorMsg: '',
  // do not change from null
  userExist: null,
  signUp: false,
  brokers_added: {
    brokers: [],
    default: null,
  },
  connectedBrokers: [],
  logoutError: false,
  logoutErrorMsg: '',

  isResetting: false,
  resetError: false,
  resetResp: {},
  resetErrorMsg: '',

  isRequesting: false,
  requestError: false,
  requestErrorMsg: '',
  requestResp: {},

  isActivating: false,
  activationError: false,
  activationErrorMsg: '',
  activationResp: {},

  showLoginModal: false,

  isfetchingReferrals: false,
  referralRewards: [],
  fetchReferralErr: false,
  fetchReferralErrMsg: '',
  unclaimedRewardCount: 0,

  isFetchingRewardData: false,
  awailRewardData: {},
  awailRewardErr: false,
  awailRewardErrMsg: '',

  isSavingLead: false,
  saveLeadErr: false,
  saveLeadErrMsg: '',
  saveLeadResp: {},

  fancyBroker: {},

  showDeeplinkModal: false,
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_VERSION:
      return {
        ...state,
        version: '',
        forceUpdate: 0,
      }

    case GET_USER_DETAILS_INIT:
      return {
        ...state,
        isFetchingUser: true,
        userDetailsError: false,
      }

    case GET_USER_DETAILS_SUCCESS: {
      const { data } = action
      let user_details = {}
      if (data.status === 'success' && data.user_profile && data.user_profile.user_uuid) {
        Object.keys(data).forEach((item) => {
          const itemValue = data[item]
          if (typeof itemValue === 'object' && !Array.isArray(itemValue)) {
            user_details = {
              ...user_details,
              ...itemValue,
            }
          } else {
            user_details = {
              ...user_details,
              [item]: itemValue,
            }
          }
        })
      }
      // console.log(user_details, 'falten')
      const { subscription_type = 0 } = user_details
      return {
        ...state,
        user_details,
        blockedSegmentList: NOT_ALLOWED_SEGMENT[subscription_type],
        blockedUniverseList: NOT_ALLOWED_UNIVERSE[subscription_type],
        isFetchingUser: false,
      }
    }

    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingUser: false,
        userDetailsError: true,
        userDetailsErrorMsg: action.err['error-type'] || action.err.error_msg,
      }

    case CLEAR_USER_DETAILS:
      return {
        ...state,
        user_details: {},
        userDetailsError: false,
        userDetailsErrorMsg: '',
      }

    case LOGIN_INIT: {
      const { user_uuid, adding_broker, email } = action.params
      const addBroker = user_uuid && (adding_broker || email)
      return {
        ...state,
        isLogging: true,
        loginError: false,
        loginErrorMsg: '',
        isLoggedIn: addBroker,
      }
    }

    case LOGIN_SUCCESS: {
      const { data = {} } = action
      return {
        ...state,
        isLogging: false,
        loginError: false,
        loginErrorMsg: '',
        loginResp: data,
        isLoggedIn: true,
        user_details: {},
        defaultVersion: data.version,
      }
    }

    case LOGIN_FAILURE:
      return {
        ...state,
        signUp: action.status_code === 203,
        isLogging: false,
        loginError: true,
        loginErrorMsg: action.error,
      }

    case SWTICH_BROKER_FAILURE: {
      const { csrf, error_msg, sessionid } = action.params
      return {
        ...state,
        loginResp: { sessionid, csrf, status: 'success' },
        loginError: true,
        isLogging: false,
        loginErrorMsg: error_msg,
      }
    }

    case CLEAR_LOGIN_RESP: {
      return {
        ...state,
        isLogging: false,
        loginResp: {},
        loginError: false,
        loginErrorMsg: '',
        isLoggedIn: false,
        signUp: false,
      }
    }

    case LOGOUT_INIT: {
      return {
        ...state,
        isLoggedIn: false,
      }
    }
    case LOGOUT_SUCCESS: {
      return state
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        logoutError: true,
        logoutErrorMsg: action.error,
      }
    }

    case FETCH_VERSION: {
      return {
        ...state,
        // defaultVersion: null,
        isFetchingVersion: true,
        fetchVersionError: false,
        fetchVersionErrorMsg: '',
      }
    }

    case FETCH_VERSION_SUCCESS: {
      return {
        ...state,
        isFetchingVersion: false,
        defaultVersion: action.data.version,
      }
    }

    case FETCH_VERSION_FAILURE: {
      return {
        ...state,
        isFetchingVersion: false,
        fetchVersionError: true,
        fetchVersionErrorMsg: action.error,
      }
    }

    case UPDATE_VERSION: {
      return {
        ...state,
        updateVersionSuccess: null,
        isUpdatingVersion: true,
        updateVersionError: false,
        updateVersionErrorMsg: action.error,
      }
    }

    case UPDATE_VERSION_SUCCESS: {
      return {
        ...state,
        updateVersionSuccess: action.version,
        isUpdatingVersion: false,
      }
    }

    case UPDATE_VERSION_FAILURE: {
      return {
        ...state,
        isUpdatingVersion: false,
        updateVersionError: true,
        updateVersionErrorMsg: action.error,
      }
    }

    case VALIDATE_SESSION: {
      return {
        ...state,
        sessionValid: false,
        sessionValidError: false,
      }
    }

    case VALIDATE_SESSION_SUCCESS: {
      return {
        ...state,
        sessionValid: action.data,
        defaultVersion: action.data.version,
      }
    }

    case VALIDATE_SESSION_FAILURE: {
      return {
        ...state,
        sessionValid: false,
        sessionValidError: true,
      }
    }

    case SET_TOKEN: {
      const { data } = action
      const cookie = {}
      cookie.csrf = atob(data.token)
      cookie.sessionid = atob(data.id)
      return {
        ...state,
        loginResp: cookie,
        isLoggedIn: true,
        user_details: {},
      }
    }

    case VALIDATE_EMAIL: {
      // const { validating } = action
      return {
        ...state,
        validatingEmail: true,
        emailVerified: false,
        emailVerifyError: false,
        emailVerifyErrorMsg: '',
        // do not change from null
        userExist: null,
        signUp: false,
        brokers_added: {
          brokers: [],
          default: null,
        },
      }
    }

    case VALIDATE_EMAIL_SUCCESS: {
      const statusCodeMap = {
        101: false,
        100: true,
      }
      const { data } = action
      const { status_code, brokers_added } = data
      const userExist = statusCodeMap[status_code]
      const brokers = Object.values(BROKERS)
      const broker = state.user_details && state.user_details.broker
      let connectedBrokers = []
      if (brokers_added && brokers_added.brokers.length) {
        brokers.forEach((item) => {
          if (item.name === BROKERS.NONE.name) return
          const currentBroker = broker && (broker === item.name || broker === item.serviceName)
          if (brokers_added.brokers.includes(item.serviceName)) {
            connectedBrokers.push({ connected: true, currentBroker, ...item })
          }
        })
      } else if (broker) {
        const currentBrokerObj = brokers.filter(item => item.name === broker)
        if (currentBrokerObj[0]) {
          connectedBrokers = [{ currentBroker: true, ...currentBrokerObj[0] }]
        }
      }
      return {
        ...state,
        validatingEmail: false,
        emailVerified: true,
        userExist,
        brokers_added,
        connectedBrokers,
      }
    }

    case VALIDATE_EMAIL_FAILURE:
      return {
        ...state,
        validatingEmail: false,
        emailVerifyError: true,
        emailVerifyErrorMsg: action.error,
      }

    case EMAIL_LOGIN:
      return {
        ...state,
        isLogging: true,
        loginError: false,
        loginErrorMsg: '',
        isLoggedIn: false,
      }

    case RESET_PASSWORD_INIT:
      return {
        ...state,
        isResetting: true,
        resetError: false,
        resetResp: {},
        resetErrorMsg: '',
      }

    case RESET_PASSWORD_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isResetting: false,
        resetResp: data,
        resetError: false,
        resetErrorMsg: '',
      }
    }

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isResetting: false,
        resetResp: {},
        resetError: true,
        resetErrorMsg: action.error,
      }

    case FORGOT_PASSWORD_INIT:
      return {
        ...state,
        isRequesting: true,
        requestError: false,
        requestErrorMsg: '',
        requestResp: {},
      }

    case FORGOT_PASSWORD_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isRequesting: false,
        requestResp: data,
        requestError: false,
        requestErrorMsg: '',
      }
    }

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isRequesting: false,
        requestError: true,
        requestResp: {},
        requestErrorMsg: action.error,
      }

    case ACCOUNT_ACTIVATE_INIT:
      return {
        ...state,
        isActivating: true,
        activationError: false,
        activationResp: {},
      }

    case ACCOUNT_ACTIVATE_SUCCESS: {
      const { data } = action
      const { csrf, sessionid } = data
      return {
        ...state,
        isActivating: false,
        activationResp: data,
        isLogging: false,
        loginError: false,
        loginErrorMsg: '',
        loginResp: { sessionid, csrf, status: 'success' },
        isLoggedIn: true,
        user_details: {},
        defaultVersion: data.version,
      }
    }

    case ACCOUNT_ACTIVATE_FAILURE:
      return {
        ...state,
        isActivating: false,
        activationError: true,
        activationErrorMsg: action.error,
      }

    case SHOW_LOGIN_MODAL:
    {
      return {
        ...state,
        showLoginModal: action.state,
        postLoginParams: action.postLoginParams,
        loginShared: action.loginShared,
      }
    }

    case GET_ALL_REWARDS_INIT: {
      return {
        ...state,
        isfetchingReferrals: true,
        fetchReferralErr: false,
        fetchReferralErrMsg: '',
        // referralRewards: [],
        unclaimedRewardCount: 0,
      }
    }
    case GET_ALL_REWARDS_SUCCESS: {
      const { referrals } = action.data
      const allRewards = []
      referrals.map(referral => referral.rewards.map(reward => allRewards.push({
        ...reward,
        referral_name: referral.first_name,
        ref_user_uuid: referral.ref_user_uuid,
      })))
      const referralRewards = allRewards.sort((a, b) => ((a.claimed - b.claimed)
      || (dayjs(b.updated_at) - dayjs(a.updated_at))))
      const unclaimedRewardCount = referralRewards.filter(reward => reward.claimed < 1).length || 0
      return {
        ...state,
        isfetchingReferrals: false,
        referralRewards,
        unclaimedRewardCount,

      }
    }
    case GET_ALL_REWARDS_FAILURE: {
      return {
        ...state,
        isfetchingReferrals: false,
        fetchReferralErr: true,
        fetchReferralErrMsg: action.error,
      }
    }

    case AWAIL_REWARD_INIT: {
      return {
        ...state,
        isFetchingRewardData: true,
        awailRewardData: {},
        awailRewardErr: false,
        awailRewardErrMsg: '',
      }
    }
    case AWAIL_REWARD_SUCCESS: {
      return {
        ...state,
        isFetchingRewardData: false,
        awailRewardData: action.data,
      }
    }
    case AWAIL_REWARD_FAILURE: {
      return {
        ...state,
        isFetchingRewardData: false,
        awailRewardErr: true,
        awailRewardErrMsg: action.error,
      }
    }

    case UPDATE_FANCY_BROKER: {
      return {
        ...state,
        fancyBroker: action.brokerObj,
      }
    }

    case SAVE_LEAD: {
      return {
        ...state,
        isSavingLead: true,
        saveLeadErr: false,
        saveLeadErrMsg: '',
      }
    }

    case SAVE_LEAD_SUCCESS: {
      return {
        ...state,
        isSavingLead: false,
        saveLeadResp: action.data,
      }
    }

    case SAVE_LEAD_FAILURE: {
      return {
        ...state,
        isSavingLead: false,
        saveLeadErr: true,
        saveLeadErrMsg: action.error,
      }
    }

    case SHOW_DEEPLINK_MODAL: {
      return {
        ...state,
        showDeeplinkModal: action.state,
      }
    }

    default:
      return state
  }
}

export default auth
