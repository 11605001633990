import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import dayjs from 'dayjs'
import classnames from 'classnames'

import ViewEditCard from '../../UI/ViewEditCard'
import CustomText from '../../UI/CustomText'

import { candle_map } from '../../utils/consts'
import { SPACING } from '../../Theme'

const iconObj = {
  iconName: 'PARAMETERS', color: 'PARAMETERS',
}

const AlgoParams = React.memo(({
  toggleEditParams,
  quantity = 0,
  time_frame,
  dt_start,
  dt_stop,
  initial_capital = '10000000',
  trading_start_time,
  trading_stop_time,
  chart_type,
  disabled,
  containerStyles = {},
  status,
  showStatus,
  cardRef,
  order_type,
  isDark,
  showEdit = false,
}) => {
  const styles = stylesheet()
  const start_date = dt_start ? dayjs(dt_start, 'DD/MM/YYYY').format('DD MMM YYYY') : '-'
  const stop_date = dt_stop ? dayjs(dt_stop, 'DD/MM/YYYY').format('DD MMM YYYY') : '-'

  const renderCell = (label, value) => (
    <div className={styles.cell}>
      <CustomText weight="medium">{value}</CustomText>
      <CustomText size="tiny" color={isDark ? 'black_60' : ''}>{label}</CustomText>
    </div>
  )

  return (
    <ViewEditCard
      onCardPress={toggleEditParams}
      cardTitle="BACKTEST PARAMETERS"
      iconObj={iconObj}
      style={classnames(styles.card, containerStyles)}
      disabled={disabled}
      status={status}
      showStatus={showStatus}
      cardRef={cardRef}
      isDark={isDark}
      contentStyles={styles.content}
      showEdit={showEdit}
    >
      {renderCell('Quantity', quantity, isDark)}
      {renderCell('Order type', order_type, isDark)}
      {renderCell('Candle interval', candle_map[time_frame], isDark)}
      {renderCell('Chart type', chart_type, isDark)}
      {renderCell('Start Date', start_date, isDark)}
      {renderCell('Stop date', stop_date, isDark)}
      {renderCell('Entry Start time', trading_start_time, isDark)}
      {renderCell('Entry Stop TIme', trading_stop_time, isDark)}
      {renderCell('Initial capital', initial_capital, isDark)}
    </ViewEditCard>
  )
})

const stylesheet = makeStyles({
  card: {
    paddingBottom: 0,
    marginTop: SPACING.SPACE_38,
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${SPACING.SPACE_10} 0`,
  },
  cell: {
    width: 140,
    marginBottom: SPACING.SPACE_24,
  },
})

export default AlgoParams
