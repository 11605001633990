export default ({
  label = '', data = [], lineColor, showTicks = true,
}) => {
  const ticks = showTicks ? {
    beginAtZero: true,
    font: {
      size: 8,
      weight: '500',
      family: 'Poppins, sans-serif',
    },
    autoSkip: true,
    maxTickLimit: 20,
  } : { display: false }

  return {
    type: 'line',
    // scaleSteps: 4,
    data: {
      datasets: [{
        label,
        data,
      }],
    },
    options: {
      responsiveAnimationDuration: 0,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
        // easing: 'easeInOutCirc',
      },
      // transitions: {
      // },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          grid: {
            display: false,
          },
          ticks,
          position: 'right',
        },
      },
      elements: {
        point: {
          borderWidth: 0,
          backgroundColor: 'transparent',
        },
        line: {
          tension: 0,
          backgroundColor: lineColor,
          borderWidth: 1,
          borderColor: lineColor,
          borderJoinStyle: 'round',
          stepped: true,
        },
      },
    },
  }
}
