import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import Icon from '../../UI/Icon'
import DotLoader from '../../UI/DotLoader'
import CustomText from '../../UI/CustomText'
// import CustomModal from '../../UI/CustomModal'

import {
  ICONS, SPACING, theme,
} from '../../Theme'
import { BROKERS, origins } from '../../utils/consts'
import { withTheme } from '../../Theme/ThemeProvider'
import { setCookie } from '../../utils/common'

const brokers = Object.values(BROKERS)

class LinkBrokerLogin extends PureComponent {
  backpressHandler = () => {
    const { dismissKey, onDismiss } = this.props
    onDismiss(dismissKey)
  }

  renderAddBrokerIcon = (label, connected, currentBroker) => {
    const { loading, styles, fromAuth } = this.props
    if (loading) {
      return <DotLoader props={{ className: styles.dotLoader }} />
    }
    if (currentBroker) {
      return <Icon name={ICONS.TICK_ROUND_FILLED} size={20} color={theme.green} />
    }
    if (connected) {
      return <CustomText color="blue" weight="medium">{fromAuth ? 'Login' : 'Switch'}</CustomText>
    }
    return (
      <Icon
        name={ICONS.FORWARD}
        size={12}
        className={styles.fwdIcon}
        color={theme.textSecondary}
      />
    )
  }

  linkBrokerHandler = (brokerName, isConnected) => {
    const {
      user_details, csrf, // toggleAbLogin
    } = this.props
    let adding_broker = !isConnected
    if (user_details.email) {
      const { email, user_uuid } = user_details
      adding_broker = true
      this.addBroker = {
        email, csrf, user_uuid, adding_broker,
      }
    }
    switch (brokerName) {
      case BROKERS.ZERODHA.name: {
        setCookie({ addBroker: JSON.stringify(this.addBroker) })
        window.open(origins.kite, '_self'); break
      }
      case BROKERS.FIVE_PAISA.name: {
        setCookie({ addBroker: JSON.stringify(this.addBroker) })
        window.open(origins.paisa, '_self'); break
      }
      case BROKERS.ANGEL.name: {
        setCookie({ addBroker: JSON.stringify(this.addBroker) })
        window.open(origins.ab, '_self'); break
        // toggleAbLogin(adding_broker ? this.addBroker : null)
        // this.backpressHandler()
        // break
      }
      case BROKERS.UPSTOX.name: {
        setCookie({ addBroker: JSON.stringify(this.addBroker) })
        window.open(origins.upstox, '_self'); break
      }
      case BROKERS.ICICI.name: {
        setCookie({ addBroker: JSON.stringify(this.addBroker) })
        window.open(origins.icici, '_self'); break
      }
      default: break
    }
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  render() {
    const {
      subText, styles, brokers_added, user_details, connectedBrokers,
      fromAuth,
    } = this.props
    const { broker } = user_details
    const data = fromAuth ? connectedBrokers : brokers
    return (
      <div className={styles.container}>
        {/* <CustomModal
          visible={loginModal}
          dismissKey="loginModal"
          onDismiss={this.toggleModal}
        >
        </CustomModal> */}
        <div className={styles.header}>
          <button
            type="button"
            onClick={this.backpressHandler}
            className={styles.backBtn}
          >
            <Icon name={ICONS.BACK} color={theme.text} size={12} />
          </button>
          <CustomText size="large" weight="semi_bold">Choose broker to continue</CustomText>
        </div>
        {Boolean(subText) && (
        <CustomText color="textSecondary" className={styles.subText}>{subText}</CustomText>
        )}
        {data.map((item) => {
          const {
            logo, label, name, serviceName, connected,
          } = item
          if (name === BROKERS.NONE.name) return null
          const disabled = broker === name
          const isConnected = fromAuth ? connected : brokers_added.brokers.includes(serviceName)
          return (
            <button
              disabled={disabled}
              key={label}
              type="button"
              className={styles.brokerRowbtn}
              onClick={() => {
                if (disabled) return
                this.linkBrokerHandler(name, isConnected)
              }}
            >
              <img src={logo} alt={`${label} logo`} className={styles.logo} />
              <CustomText style={{ flex: 1, textAlign: 'left' }}>{label}</CustomText>
              {this.renderAddBrokerIcon(label, isConnected, disabled)}
            </button>
          )
        })}
      </div>
    )
  }
}

const stylesheet = ({
  container: {},
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_20,
  },
  subText: {
    marginBottom: SPACING.SPACE_20,
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    marginRight: SPACING.SPACE_10,
  },
  logo: {
    marginRight: SPACING.SPACE_10,
    width: 18,
  },
  brokerRowbtn: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${SPACING.SPACE_12} ${SPACING.SPACE_8} ${SPACING.SPACE_12} 0`,
    borderBottom: `1px solid ${theme.borderColor}`,
    '&:hover': {
      backgroundColor: theme.hover,
    },
    '&:hover $fwdIcon': {
      fill: theme.linkColor,
    },
    '&:last-child': {
      borderBottom: 0,
    },
  },
  dotLoader: {
    width: 30,
  },
  fwdIcon: {},
})

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    brokers_added: state.auth.brokers_added,
    connectedBrokers: state.auth.connectedBrokers,
    csrf: state.auth.loginResp.csrf,
  }
}

const mapDispatchToProps = () => { // dispatch
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(LinkBrokerLogin))
