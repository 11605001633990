import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Auth from './Auth'
import {
  fetchVersion,
  clearVersion,
  getUserDetails,
  loginUserSuccess,
  // updateCsrftoken,
  loginUser,
  // brokerLogin,
  validateEmail,
  emailLogin,
  toggleLoginModal,
} from './actions'

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUserSuccess: data => dispatch(loginUserSuccess(data)),
    fetchVersion: headers => dispatch(fetchVersion(headers)),
    clearVersion: () => dispatch(clearVersion()),
    getUserDetails: params => dispatch(getUserDetails(params)),
    // updateCsrftoken: headers => dispatch(updateCsrftoken(headers)),
    validateEmail: params => dispatch(validateEmail(params)),
    loginUser: (params, headers, type) => dispatch(loginUser(params, headers, type)),
    emailLogin: params => dispatch(emailLogin(params)),
    toggleLoginModal: state => dispatch(toggleLoginModal(state)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Auth),
)
