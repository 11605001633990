import makeStyles from '@material-ui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

// import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import CustomText from '../../UI/CustomText'
import StockIcon from '../../UI/StockIcon'
import Icon from '../../UI/Icon'
import PlaceHolderLoader from '../../UI/PlaceHolderLoader'

import {
  ASSETS, COLORS, SPACING, ICONS, theme,
} from '../../Theme'
import { fetchOrders } from '../../containers/OrderBook/actions'
import { ORDER_TYPE } from '../../containers/OrderBook/orderbookUtils'
import { pathname_mapping } from '../../utils/navigationConstant'

const {
  CubeFilled, FlowerFilled, CubeFilledDark, FlowerFilledDark,
} = ASSETS

const STOCK_IMG_WIDTH = 30 + 8

// const da = [
//   { exchange: 'NSE', tradingsymbol: 'SBIN' },
//   { exchange: 'NSE', tradingsymbol: 'BIOCON' },
//   { exchange: 'NSE', tradingsymbol: 'IOC' },
//   { exchange: 'NSE', tradingsymbol: 'RELIANCE' },
//   { exchange: 'NSE', tradingsymbol: 'PNB' },
//   { exchange: 'NSE', tradingsymbol: 'PNC' },
// ]

let calcTimer = null
const OrderbookSection = (props) => {
  const styles = stylesheet()
  const [displayLen, updateDisplayLen] = useState(3)
  const [contentWidth, updateContentWidth] = useState(0)
  const {
    containerStyles, onViewAll, routeToTechicals, rowStyles,
    getOrders, executed, pending, isDark, isFetchingOrders, fromSidebar = false,
  } = props
  const filter = 'All'
  const contentRef = React.createRef(null)
  const onOpenOrderPress = () => {
    onViewAll(ORDER_TYPE.PENDING)
  }
  const onExecutedOrderPress = () => {
    onViewAll(ORDER_TYPE.EXECUTED)
  }
  useEffect(() => {
    getOrders(filter)
  }, [])
  useEffect(() => {
    if (calcTimer) clearTimeout(calcTimer)
    const ele = contentRef.current
    calcTimer = setTimeout(() => {
      const constantWidth = 60
      let width = contentWidth
      if (ele) {
        width = ele.offsetWidth
        updateContentWidth(width)
      }
      updateDisplayLen(Math.floor((width - constantWidth) / STOCK_IMG_WIDTH))
    }, 200)
    return () => {
      if (calcTimer) clearTimeout(calcTimer)
    }
  }, [pending.length, executed.length])

  const renderEmptySection = () => {
    return (
      <button
        type="button"
        onClick={routeToTechicals}
      >
        <CustomText color="textSecondary" weight="medium" className={styles.technicalsBtnText}>Go to Technicals</CustomText>
      </button>
    )
  }

  const renderStocks = (data, onPress) => {
    const stocksImg = data.slice(0, displayLen).map((item) => {
      const { exchange, tradingsymbol, order_id } = item
      const seg_sym = `${exchange.toUpperCase()}_${tradingsymbol}`
      return (
        <StockIcon
          containerStyle={{
            height: 30,
            width: 30,
            borderRadius: '50%',
            border: `1px solid ${theme.borderColor}`,
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: SPACING.SPACE_8,
            padding: 5,
            verticalAlign: 'sub',
          }}
          imgProps={{
            height: '100%',
          }}
          seg_sym={seg_sym}
          key={order_id}
        /> // id={`${seg_sym}_ref`}
      )
    })
    const remainingLen = data.length - displayLen
    return (
      <>
        {stocksImg}
        {remainingLen > 0 && (
          <button
            type="button"
            onClick={onPress}
            className={styles.moreBtn}
          >
            {`${remainingLen} more...`}
          </button>
        )}
      </>
    )
  }

  const renderStocksRow = ({
    len, data, onPress,
  }) => (len === 0 ? renderEmptySection() : renderStocks(data, onPress))

  const renderRow = (title, imgSrc, data, onPress, defaultColor, pathname) => {
    const len = data.length
    return (
      <div className={`${styles.row} ${rowStyles}`}>
        {/* <button type="button" onClick={onPress} className={styles.header}> */}
        <Link to={pathname} className={styles.header} onClick={e => e.stopPropagation()}>
          <img alt={`${len}-img`} src={imgSrc} className={styles.rowImg} />
          <div className={styles.details}>
            <CustomText color={defaultColor} size="large" weight="medium">{len}</CustomText>
            {title()}
          </div>
        </Link>
        {/* </button> */}
        {!fromSidebar && (
        <div className={styles.stocks} ref={contentRef}>
          {renderStocksRow({ len, data, onPress })}
        </div>
        )}
      </div>
    )
  }
  const pendingColor = isDark ? 'blue_300' : 'blue'
  const executedColor = 'purple'
  const cardStyle = {
    height: fromSidebar ? '100%' : 248,
    boxShadow: fromSidebar ? 'none' : `0px 3px 20px ${theme.boxShadow}`,
  }
  const openOrdersLink = `${pathname_mapping.Trades}?tab=pending`
  const exeOrdersLink = `${pathname_mapping.Trades}?tab=executed`
  const renderOpenSubTitle = () => {
    return <CustomText color="textSecondary" size="small">Open Orders</CustomText>
  }
  const renderCompleteSubTitle = () => {
    return (
      <div className={styles.subTitle}>
        <Icon name={ICONS.TICK} color={COLORS[executedColor.toUpperCase()]} size={12} />
        <CustomText color="textSecondary" size="small" style={{ marginLeft: SPACING.SPACE_8 }}>Executed Orders</CustomText>
      </div>
    )
  }
  const renderRowLoader = () => {
    return (
      <div className={`${styles.row} ${rowStyles}`}>
        <div className={styles.header}>
          <PlaceHolderLoader className={`${styles.rowImg} ${styles.rowImgLoader}`} />
          <div className={styles.details}>
            <PlaceHolderLoader className={styles.details_1} />
            <PlaceHolderLoader className={styles.details_2} />
          </div>
        </div>
        <div className={styles.stocks} style={{ minHeight: 28 }} ref={contentRef}>
          <PlaceHolderLoader className={styles.stocksLoader} />
        </div>
      </div>
    )
  }
  return (
    <section
      style={cardStyle}
      className={classnames(styles.container, containerStyles)}
    >
      {isFetchingOrders ? (
        <>
          {renderRowLoader()}
          <div className={styles.seperator} />
          {renderRowLoader()}
        </>
      ) : (
        <>
          {renderRow(
            renderOpenSubTitle,
            isDark ? CubeFilledDark : CubeFilled, pending,
            onOpenOrderPress, pendingColor, openOrdersLink,
          )}
          {!fromSidebar && <div className={styles.seperator} />}
          {renderRow(
            renderCompleteSubTitle,
            isDark ? FlowerFilledDark : FlowerFilled, executed,
            onExecutedOrderPress, executedColor, exeOrdersLink,
          )}
        </>

      )}
    </section>
  )
}

const stylesheet = makeStyles({
  container: {
    borderRadius: 8,
    padding: `${SPACING.SPACE_10} 0`,
    backgroundColor: theme.bgPrimary,
    boxSizing: 'border-box',
  },
  technicalsBtnText: {
    '&:hover': {
      color: `${theme.linkColor} !important`,
    },
  },
  seperator: {
    height: 1,
    backgroundColor: theme.borderColor,
  },
  row: {
    padding: `${SPACING.SPACE_14} ${SPACING.SPACE_20}`,
    // '&:hover $technicalsBtnText': {
    //   color: `${theme.linkColor} !important`,
    // },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_10,
    textAlign: 'left',
    minHeight: 48,
  },
  rowImg: {
    height: 35,
    width: 35,
  },
  rowImgLoader: {
    overflow: 'hidden',
    borderRadius: '50%',
  },
  details: {
    padding: `0 ${SPACING.SPACE_20}`,
    flex: 1,
  },
  details_1: {
    width: 40,
    height: 12,
    display: 'flex',
    marginBottom: SPACING.SPACE_6,
  },
  details_2: {
    width: 80,
    height: 12,
    display: 'flex',
  },
  stocks: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  stocksLoader: {
    height: 12,
  },
  moreBtn: {
    color: theme.textSecondary,
    fontWeight: 300,
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.linkColor,
    },
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  loader: {
    height: '16px',
    borderRadius: '5px',
    display: 'flex',
    overflow: 'hidden',
  },
})

const mapStateToProps = (state) => {
  return {
    pending: state.orderbook.pending,
    executed: state.orderbook.executed,
    isFetchingOrders: state.orderbook.isFetchingOrders,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOrders: (type, headers) => dispatch(fetchOrders(type, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderbookSection)
