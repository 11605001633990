/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react'
import dayjs from 'dayjs'
import CustomText from '../../UI/CustomText'
import Button from '../../UI/Button'
import Icon from '../../UI/Icon'

import ListDropdown from '../../UI/Dropdown/ListDropdown'
import {
  // razorpayKey,
  states,
  emailRegex,
  phoneNumberRegex,
  razorpayKey,
  // razorpayKey,
} from '../../utils/consts'
import { withTheme } from '../../Theme/ThemeProvider'
import {
  ASSETS, SPACING, COLORS, ICONS, FONTWEIGHT,
} from '../../Theme'
import { SEO } from '../../utils/common'
import CustomInput from '../../UI/CustomInput'
import CustomModal from '../../UI/CustomModal'
import PageLoader from '../../UI/PageLoader'

const {
  MasterCard, Visa, RuPayLogo, StreakLogo,
  UPI, StreakPaymentsLogo,
  Twitter, Facebook2, GREYBOXES, Dots, BLUEBG,
} = ASSETS

const currentYear = dayjs().year()

export const minReqCredits = 300

const razorpayCheckout = 'https://checkout.razorpay.com/v1/checkout.js'

const STATES = states

const CONTACT_US = [
  {
    name: 'support@streak.tech',
    icon: ICONS.EMAIL,
    link: 'mailto:support@streak.tech',
  },
  {
    name: '+91 9513400550',
    icon: ICONS.PHONE,
    link: 'tel:+91 9513400550',
  },
]

const COMPANY = [
  {
    name: 'Terms of use',
    link: 'https://pro.streak.tech/terms',
  },
  {
    name: 'Privacy Policy',
    link: 'https://pro.streak.tech/privacy',
  },
  {
    name: 'Risk Disclosure',
    link: 'https://pro.streak.tech/disclosure',
  },
]

const SOCIAL = [
  [
    {
      name: 'Youtube',
      icon: ICONS.YOUTUBE,
      link: 'https://www.youtube.com/c/StreakTech',
    },
    {
      name: 'Twitter',
      icon: ICONS.TWITTER,
      link: 'https://twitter.com/StreakTech',
    },
  ],
  [
    {
      name: 'Facebook',
      icon: ICONS.FACEBOOK,
      link: 'https://www.facebook.com/StreakAI/',
    },
    {
      name: 'Instagram',
      icon: ICONS.INSTAGRAM,
      link: 'https://www.instagram.com/streak.tech/',
    },
  ],
  [
    {
      name: 'Telegram',
      icon: ICONS.TELEGRAM,
      link: 'https://t.me/Streaktech',
    },
    {
      name: 'LinkedIn',
      icon: ICONS.LINKEDIN,
      link: 'https://www.linkedin.com/company/streaktech/',
    },
  ],
  [
    {
      name: 'Playstore',
      icon: ICONS.PLAYSTORE,
      link: 'https://play.google.com/store/apps/details?id=com.streak',
    },
    {
      name: 'Appstore',
      icon: ICONS.APPSTORE,
      link: 'https://apps.apple.com/in/app/streak-algo-trade/id1423080803',
    },
  ],
]

const loadCallback = (callbackFunc, url) => {
  if (callbackFunc) callbackFunc()
  console.log(url, 'loaded')
}

const loadScript = (url = '', callbackFunc) => {
  if (url) {
    const scriptEle = document.createElement('script')
    if (callbackFunc) scriptEle.onload = () => loadCallback(callbackFunc, url)
    document.body.appendChild(scriptEle).src = url
  }
}

class PaymentPages extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoadingCheckout: true,
      showPageLoader: true,
      isPaymentSuccess: false,
      isPaymentFailed: false,
      showPaymentDetails: false, // for mobile
      email: '',
      mobile: '',
      broker: '',
      state: '',
      paymentStatusModal: false,
      paymentStatusMessage: 'Payment Failed',
      paymentStatus: false,
      paymentId: '',
      emailError: false,
      mobileError: false,
      brokerError: false,
      stateError: false,
    }
  }

  showPaymentDetails = () => {
    this.setState({ showPaymentDetails: true })
  }

  componentDidMount() {
    loadScript(razorpayCheckout)
    const { getPaymentInfo } = this.props
    getPaymentInfo({ slug: 'offerrzp-strk' })
  }

  componentDidUpdate(prevProps, prevState) {
    const { isFetchingPaymentInfo } = this.props
    const { showPaymentDetails } = this.state
    if(!isFetchingPaymentInfo && prevProps.isFetchingPaymentInfo) {
      this.attachSubHeading()
      this.setState({ showPageLoader: false })
      // set focus email
      const emailInput = document.getElementById('email')
      if (emailInput) {
        emailInput.focus()
      }
    }
    if(showPaymentDetails && !prevState.showPaymentDetails) {
      setTimeout(() => {
        const emailInput = document.getElementById('email')
        if (emailInput) {
          emailInput.focus()
        }
      }, 500)
    }
  }

  attachSubHeading = () => {
    const { paymentInfo, isMobile } = this.props
    const { subHeading } = paymentInfo
    // regex this 'INR 13,876' INR any number
    const priceRegex = /INR\s\d+,\d+/
    const priceMatch = priceRegex.exec(subHeading) || ['']
    const subHeadingArr = subHeading.split(priceMatch[0])
    if(isMobile)subHeadingArr[1] = subHeadingArr[1].replace(': ', ':\n\n')
    subHeadingArr.splice(1, 0, `<span style="font-weight: 800; color: white; font-size: 14px">${priceMatch[0]}</span>`)
    const finalSubHeading = subHeadingArr.join('')

    const subheadingdoc = document.getElementById('subHeading')
    if (subheadingdoc) {
      subheadingdoc.innerHTML = finalSubHeading
    }
  }

  // createNewOrderId = () => {
  //   const {
  //     createPaymentOrder,
  //   } = this.props
  //   const contact = ''
  //   const emailParams = '@gmail.com'
  //   const params = {
  //     plan_to_subscribe: 'ultimate',
  //     subscription_period: '12',
  //     subscription_instance: 'first',
  //     subscription_renewal: false,
  //     contact,
  //     username: emailParams.split('@')[0],
  //     email: emailParams,
  //   }
  //   const cookies = getCookies()
  //   if (cookies.offer_token) {
  //     params.offer_token = cookies.offer_token
  //   }
  //   if (params.plan_to_subscribe && emailParams) {
  //     createPaymentOrder({}, params)
  //   } else {
  //     showSnackbar('Invalid plan details', {}, 0)
  //   }
  // }

  startPayment = () => {
    const { paymentInfo, updateGA } = this.props
    const { price, planPeriod, planType } = paymentInfo
    const {
      email = '', mobile = '', broker = '', state = '',
    } = this.state

    if(!this.verifyDetails()) return

    updateGA('Checkout', 'Razor Pay', '', 'Pricing', 'InitiateCheckout')
    const successFunction = this.updateStatus
    // this.createNewOrderId() // uncomment this to create new order id
    // eslint-disable-next-line no-undef
    const razorpay = new Razorpay({
      // TODO: change this to live key
      key: razorpayKey, // rzp_test_aC9YzybiZ4puJ8
      // order_id,
      amount: price * 100,
      currency: 'INR',
      description: 'Transaction',
      prefill: {
        email,
        contact: mobile,
      },
      notes: {
        broker,
        state,
        planPeriod,
        planType,
      },
      handler(response) {
        updateGA('Purchase', 'Payment Success Razorpay', price, 'Billing', 'Purchase', { value: price, currency: 'INR' })
        const paymentId = response.razorpay_payment_id || ''
        successFunction(true, 'Payment Success', paymentId)
      },
      modal: {
        ondismiss() {
          successFunction(false, 'Payment Failed')
        },
      },
    })
    razorpay.open()
    razorpay.on('payment.failed', () => {
      // const {
      //   code, description, metadata, reason, source,
      // } = response.error || {}
      // this.updateStatus(false, 'Payment Failed')
      // const doc = document.getElementById('positiveBtn')
      // console.log(doc, 'doc')
      // const close = document.getElementsByClassName('modal-close')
      // console.log(close, 'close')
    })
  }

  verifyDetails = () => {
    const {
      email = '', mobile = '', broker = '', state = '',
    } = this.state
    if(!email || !mobile || !broker || !state) {
      if(!email) this.setState({ emailError: true })
      if(!mobile) this.setState({ mobileError: true })
      if(!broker) this.setState({ brokerError: true })
      if(!state) this.setState({ stateError: true })
      return false
    }
    return true
  }

  updateStatus = (paymentStatus, paymentStatusMessage, paymentId = '') => {
    this.setState({
      paymentStatusModal: true, paymentStatusMessage, paymentStatus, paymentId,
    })
  }

  handleInputChange = (e, key) => {
    switch (key) {
      case 'email': {
        if(emailRegex.test(e.target.value)) {
          this.setState({ emailError: false })
        } else {
          this.setState({ emailError: true })
        }
        break
      }
      case 'mobile': {
        if(phoneNumberRegex.test(e.target.value)) {
          this.setState({ mobileError: false })
        } else {
          this.setState({ mobileError: true })
        }
        break
      }
      case 'broker': {
        if(e.target.value) this.setState({ brokerError: false })
        break
      }
      default:
        break
    }

    this.setState({ [key]: e.target.value })
  }

  handleStateChange = (value) => {
    this.setState({ state: value, stateError: false })
  }

  toggleModal = (val = false) => {
    this.setState({ paymentStatusModal: val })
  }

  renderInput = (name, value, key, error) => {
    const { styles, isMobile } = this.props

    if(name === 'Amount' && value) value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return (
      <div className={styles.input}>
        <CustomText color={COLORS.PP_TEXT_COLOR} weight={isMobile ? 'medium' : 'semi_bold'} size="regular" style={{ fontFamily: "'Lato', sans-serif" }}>
          {name}
        </CustomText>
        <CustomInput
          type="text"
          value={value}
          disabled={name === 'Amount'}
          inputKey={key}
          inputError={error}
          inputErrorText={`Please enter a valid ${name}`}
          extraStyles={{
            borderRadius: 2,
            width: isMobile ? '100%' : 260,
            marginTop: isMobile ? SPACING.SPACE_16 : 0,
            paddingLeft: isMobile ? SPACING.SPACE_20 : 20,
            cursor: 'auto',
            background: COLORS.PP_INPUT_BACKGROUND,
            borderWidth: error ? 1 : 0,
            color: (name === 'Amount' && isMobile) ? COLORS.PP_MOBILE_PRICE_COLOR : COLORS.PP_TEXT_COLOR,
            fontWeight: name === 'Amount' ? FONTWEIGHT.SEMI_BOLD : FONTWEIGHT.MEDIUM,
          }}
          inputProps={{ id: key }}
          onChangeText={this.handleInputChange}
        />
      </div>
    )
  }

  renderInfo = (name, icon, link = '/') => {
    const { styles, isMobile } = this.props
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={link} target="_blank" className={styles.info}>
        {icon && (
        <Icon
          name={icon}
          color={COLORS.PP_BLUE_COLOR}
          size={isMobile ? 12 : 16}
          style={{ marginRight: SPACING.SPACE_10 }}
        />
        )}
        {name && <CustomText size={isMobile ? 'small' : 'regular_1'} weight="medium" color={COLORS.PP_TEXT_COLOR}>{name}</CustomText>}
      </a>
    )
  }

  renderFooter = () => {
    const { styles, isMobile } = this.props
    return(
      <div className={styles.footer}>
        <a href="https://pro.streak.tech">
          <div
            style={{ backgroundImage: `url(${StreakPaymentsLogo})` }}
            className={styles.logoLink}
          />
        </a>
        <div className={styles.about}>
          {!isMobile && (
          <div className={styles.aboutSection}>
            <CustomText weight={isMobile ? 'regular' : 'semi_bold'} size={isMobile ? 'medium' : 'large'} color={COLORS.PP_BLUE_COLOR}>
              Contact Us
            </CustomText>
            {CONTACT_US.map(item => this.renderInfo(item.name, item.icon, item.link))}
          </div>
          )}
          <div className={styles.aboutSection}>
            <CustomText weight={isMobile ? 'regular' : 'semi_bold'} size={isMobile ? 'medium' : 'large'} color={COLORS.PP_BLUE_COLOR}>
              Company
            </CustomText>
            {COMPANY.map(item => this.renderInfo(item.name, item.icon, item.link))}
          </div>
          <div className={styles.aboutSection}>
            <CustomText weight={isMobile ? 'regular' : 'semi_bold'} size={isMobile ? 'medium' : 'large'} color={COLORS.PP_BLUE_COLOR} style={{ width: 'min-content' }}>
              Social
            </CustomText>
            {
              SOCIAL.map((item) => {
                return (
                  <div className={styles.row}>
                    {item.map(info => this.renderInfo(null, info.icon, info.link))}
                  </div>
                )
              })
            }
          </div>
        </div>
        {isMobile && (
        <div className={styles.aboutSection} style={{ marginTop: SPACING.SPACE_40 }}>
          <CustomText weight={isMobile ? 'regular' : 'semi_bold'} size="medium" color={COLORS.PP_BLUE_COLOR}>
            Contact Us
          </CustomText>
          {CONTACT_US.map(item => this.renderInfo(item.name, item.icon, item.link))}
        </div>
        )}
        <div className={styles.terms}>
          <CustomText size={isMobile ? 'medium_1' : 'large_1'} weight={isMobile ? 'regular' : 'medium'} color={COLORS.PP_BLUE_COLOR}>
            Terms & Conditions:
          </CustomText>
          <a href="https://pro.streak.tech/terms">
            <CustomText color={COLORS.PP_DARK_BLUE_COLOR} weight={isMobile ? 'regular' : 'semi_bold'} size={isMobile ? 'regular' : 'medium'} style={{ marginTop: SPACING.SPACE_30 }}>
              https://pro.streak.tech/terms
            </CustomText>
          </a>
          <CustomText
            color={COLORS.PP_TEXT_COLOR}
            size={isMobile ? 'small_0' : 'regular_1'}
            weight={isMobile ? 'regular' : 'medium'}
            style={{ marginTop: SPACING.SPACE_10, width: isMobile ? '100%' : 'max-content', lineHeight: 2 }}
          >
            You agree to share information entered on this page with
            Streak (owner of this page) and Razorpay, adhering to applicable laws.
          </CustomText>
        </div>
        <div style={{ marginTop: SPACING.SPACE_40, marginBottom: 100 }}>
          <CustomText color={COLORS.PP_TEXT_COLOR} size="small">
            {`© ${currentYear} Streak AI Technologies Pvt Ltd. All rights reserved.`}
          </CustomText>
        </div>
      </div>
    )
  }

  renderDesktopView = () => {
    const { styles, paymentInfo } = this.props
    const {
      title = '',
      description = ['', ''],
      subHeading = '',
      image = '',
      desktopImage,
      price,
      payBtnText,
    } = paymentInfo ?? {}
    const {
      email, mobile, broker, state,
    } = this.state
    const {
      emailError, mobileError, brokerError, stateError,
    } = this.state

    return (
      <div>
        <div className={styles.desktop}>
          <a href="https://pro.streak.tech">
            <div
              style={{ backgroundImage: `url(${StreakPaymentsLogo})` }}
              className={styles.logoLink}
            />
          </a>
          <div className={styles.desktopContent}>
            <div className={styles.content}>
              <CustomText weight="semi_bold" style={{ fontSize: 24 }} color={COLORS.PP_DESKTOP_TITLE_COLOR} className={styles.headerText}>
                {title}
              </CustomText>
              <CustomText
                className={styles.description}
                color={COLORS.PP_TEXT_COLOR}
                style={{
                  fontWeight: '500',
                  fontSize: '15px',
                }}
              >
                {`${description[0]} ${description[1]}`}
              </CustomText>
              <img className={styles.method} src={GREYBOXES} style={{ height: 60, width: 111 }} alt="boxes" />
              <img src={desktopImage || image} className={styles.img} alt="Christmas Offer" />
              <CustomText
                className={styles.description}
                color={COLORS.PP_TEXT_COLOR}
                style={{
                  fontWeight: '500',
                  fontSize: '15px',
                }}
              >
                {description[2]}
              </CustomText>
            </div>
            <div className={styles.paymentWindow}>
              <div className={styles.paymentContent}>
                <CustomText size="regular" weight="medium" extraProps={{ id: 'subHeading' }} color={COLORS.PP_LIGHT_GREY_COLOR} style={{ width: 340 }}>
                  {subHeading}
                </CustomText>
              </div>
              <div style={{ padding: '20px 20px 0 20px' }}>
                <CustomText weight="semi_bold" size="large" color={COLORS.PP_BLUE_COLOR} style={{ marginBottom: SPACING.SPACE_20, fontFamily: "'Lato', sans-serif" }}>
                  Payment Details
                </CustomText>
                {this.renderInput('Amount', price, 'price')}
                {this.renderInput('Email', email, 'email', emailError)}
                {this.renderInput('Phone', mobile, 'mobile', mobileError)}
                {this.renderInput('Broker ID', broker, 'broker', brokerError)}
                <div className={styles.input}>
                  <CustomText color={COLORS.PP_TEXT_COLOR} size="regular" weight="semi_bold" style={{ fontFamily: "'Lato', sans-serif" }}>
                    State
                  </CustomText>
                  <div>
                    <ListDropdown
                  // error
                  // errorMsg="Please select a state"
                      options={STATES}
                      changeKey="states"
                      selected={state}
                      onChangeOption={this.handleStateChange}
                      containerStyles={styles.dropDown}
                      inputStyles={styles.inputStyles}
                      inputBtnStyles={
                        stateError
                          ? styles.inputBtnStylesError
                          : styles.inputBtnStyles
                        }
                    />
                    {stateError && (
                    <CustomText color="red" style={{ marginTop: SPACING.SPACE_5 }}>
                      Please select a State
                    </CustomText>
                    )}
                  </div>
                </div>
                <div className={styles.paymentBottom}>
                  <div className={styles.grid}>
                    <img className={styles.method} src={Visa} alt="visa card" />
                    <img className={styles.method} src={RuPayLogo} style={{ width: 40, marginTop: SPACING.SPACE_4 }} alt="rupay" />
                    <img className={styles.method} src={UPI} style={{ width: 25 }} alt="upi" />
                    <img className={styles.method} src={MasterCard} style={{ width: 70 }} alt="master card" />
                  </div>
                  <button type="button" onClick={this.startPayment} className={styles.payBtn}>
                    <CustomText weight="semi_bold" size="regular" color="white" style={{ fontFamily: "'Lato', sans-serif" }}>
                      {payBtnText}
                    </CustomText>
                    <Icon
                      style={{ marginLeft: SPACING.SPACE_10 }}
                      name={ICONS.ARROW_FORWARD_W_LINE}
                      color={COLORS.WHITE}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.line} />
      </div>
    )
  }

  renderMobileView = () => {
    const { styles, paymentInfo } = this.props
    const { showPaymentDetails } = this.state
    const {
      title = '',
      description = [],
      subHeading = '',
      image,
      price,
    } = paymentInfo ?? {}
    const {
      email, mobile, broker, state,
    } = this.state
    const {
      emailError, mobileError, brokerError, stateError,
    } = this.state

    const finalPrice = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    if(showPaymentDetails) {
      return (
        <div className={styles.mobilePayment}>
          <div className={styles.details}>
            <div className={styles.indicator} style={{ height: 20, marginTop: SPACING.SPACE_5 }} />
            <CustomText weight="semi_bold" size="large" color={COLORS.PP_BLUE_COLOR} style={{ marginBottom: SPACING.SPACE_34 }}>
              Payment Details
            </CustomText>
            {this.renderInput('Amount', `${price}/-`, 'price')}
            {this.renderInput('Email', email, 'email', emailError)}
            {this.renderInput('Phone', mobile, 'mobile', mobileError)}
            {this.renderInput('Broker ID', broker, 'broker', brokerError)}
            <div className={styles.input}>
              <CustomText color={COLORS.PP_TEXT_COLOR} size="regular" weight="medium" style={{ fontFamily: "'Lato', sans-serif" }}>
                State
              </CustomText>
              <ListDropdown
                options={STATES}
                changeKey="states"
                selected={state}
                onChangeOption={this.handleStateChange}
                containerStyles={styles.dropDown}
                inputStyles={styles.inputStyles}
                inputBtnStyles={stateError ? styles.inputBtnStylesError : styles.inputBtnStyles}
              />
              {stateError && (
              <CustomText color="red" style={{ marginTop: SPACING.SPACE_5 }}>
                Please select a State
              </CustomText>
              )}
            </div>
          </div>
          <div style={{ marginTop: SPACING.SPACE_40 }}>
            <div className={styles.images}>
              <img className={styles.method} src={Visa} alt="visa card" />
              <img className={styles.method} src={UPI} alt="upi" />
              <img className={styles.method} src={MasterCard} style={{ height: 30 }} alt="master card" />
              <img className={styles.method} src={RuPayLogo} style={{ width: 55 }} alt="rupay" />
            </div>
            <button type="button" onClick={this.startPayment} className={styles.mobilePayBtn}>
              <CustomText weight="medium" size="medium" color="white">
                {`Pay Rs.${finalPrice}.00`}
              </CustomText>
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className={styles.mobile}>
        <div className={styles.holder} />
        <div
          className={styles.image}
        >
          <a href="https://pro.streak.tech">
            <img
              src={StreakLogo}
              style={{
                width: 50,
                height: 45,
                objectFit: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
              alt="Streak"
            />
          </a>
        </div>
        <div className={styles.mobileContent}>
          <CustomText size="large" color={COLORS.PP_MOBILE_TITLE_COLOR} style={{ marginTop: SPACING.SPACE_50 }} className={styles.headerText}>
            Streak
          </CustomText>
          <div className={styles.desc}>
            <div className={styles.indicator} />
            <CustomText weight="semi_bold" size="large_1" color={COLORS.PP_BLUE_COLOR} style={{ width: 300 }}>
              {title}
            </CustomText>
            {description.map(desc => (
              <CustomText key={desc} color={COLORS.PP_TEXT_COLOR} size="medium" className={styles.description}>
                {desc}
              </CustomText>
            ))}
          </div>
          <img src={image} className={styles.img} alt="Christmas Offer" />
          <div className={styles.mobilePaymentContent}>
            <img src={Dots} className={styles.dots} alt="dots" />
            <CustomText size="regular_2" color={COLORS.PP_LIGHT_GREY_COLOR} extraProps={{ id: 'subHeading' }} style={{ width: 320, padding: '0 30px', whiteSpace: 'pre-wrap' }}>
              {subHeading}
            </CustomText>
            <img src={Dots} className={styles.dots} alt="dots" />
          </div>
        </div>
      </div>
    )
  }

  share(medium) {
    const { paymentInfo, isMobile } = this.props
    const { title } = paymentInfo ?? {}
    const currentUrl = window.location.href
    const encodeUrl = encodeURIComponent(currentUrl)
    const encodeTitle = encodeURIComponent(`Check out ${title} on Streak.`)
    let url = 'https://pro.streak.tech/offer-info-page'
    switch(medium) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}&quote=${title}`
        break
      case 'twitter':
        url = `https://twitter.com/intent/tweet?url=${encodeUrl}&text=${encodeTitle}`
        break
      case 'whatsapp':
        url = `https://api.whatsapp.com/send?text= Check out "${title}" on Streak. ${currentUrl}`
        break
      default: break
    }
    if(isMobile || medium === 'whatsapp') {
      window.open(url, '_blank')
    } else this.windowpop(url, 600, 600)
  }

  windowpop(url, width, height) {
    const screen = {
      width: window.innerWidth,
      height: window.innerHeight,
    }
    const left = (screen.width / 2) - (width / 2)
    const top = (screen.height / 2) - (height / 2)
    window.open(url, 'popup', `menubar=no,toolbar=no,status=no,width=${width},height=${height},top=${top},left=${left}`)
  }

  renderStatusModal = () => {
    const { styles, paymentInfo, isMobile } = this.props
    const { price } = paymentInfo ?? {}
    const { paymentId, paymentStatus, paymentStatusMessage } = this.state

    const close = () => {
      this.setState({ paymentStatusModal: false })
    }

    return (
      <div
        className={styles.statusModal}
        style={{ padding: SPACING.SPACE_10 }}
      >
        {paymentStatus
          ? (
            <Icon
              name={ICONS.TICK_ROUND_FILLED}
              color={COLORS.PP_TICK_ROUND_COLOR}
              size={isMobile ? 60 : 100}
            />
          )
          : <Icon name={ICONS.CANCEL} color={COLORS.LIST_RED} size={isMobile ? 60 : 100} />}
        <CustomText weight="semi_bold" size={isMobile ? 'medium' : 'large_2'} className={styles.statusText}>
          {paymentStatusMessage}
        </CustomText>
        {paymentStatus ? (
          <div className={styles.statusModal} style={{ width: '100%' }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: SPACING.SPACE_10,
              padding: SPACING.SPACE_10,
            }}
            >
              <div className={styles.statusInfo}>
                <CustomText size={isMobile ? 'small' : 'medium'} color={COLORS.PP_LIGHT_BLUE_COLOR} weight="medium" className={styles.statusDesc}>
                  Amount paid:
                </CustomText>
                <CustomText size={isMobile ? 'small' : 'medium'} color={COLORS.PP_LIGHT_BLUE_COLOR} weight="medium" style={{ marginTop: SPACING.SPACE_10 }} className={styles.statusDesc}>
                  Payment Id:
                </CustomText>
              </div>
              <div className={styles.statusInfo}>
                <CustomText size={isMobile ? 'small' : 'medium'} color={COLORS.PP_LIGHT_BLUE_COLOR} weight="medium" className={styles.statusDesc}>
                  <span style={{ fontFamily: 'sans-serif', marginRight: SPACING.SPACE_2 }}>₹</span>
                  {price}
                </CustomText>
                <CustomText size={isMobile ? 'small' : 'medium'} color={COLORS.PP_LIGHT_BLUE_COLOR} weight="medium" style={{ marginTop: SPACING.SPACE_10 }} className={styles.statusDesc}>
                  {paymentId}
                </CustomText>
              </div>
            </div>
          </div>
        )
          : (
            <div style={{ marginTop: SPACING.SPACE_30, textAlign: 'center', width: '100%' }}>
              <CustomText size={isMobile ? 'small' : 'medium'} color={COLORS.PP_FAILED_TEXT_COLOR} className={styles.statusDesc}>
                Your transaction has failed due to some technical error. Please try again
              </CustomText>
            </div>
          )}
        {paymentStatus && (
        <div className={styles.statusModal}>
          <CustomText size={isMobile ? 'small_0' : 'regular_1'} className={styles.experience}>
            Share Your Experience
          </CustomText>
          <div className={styles.share}>
            <Button btnStyles={styles.emptyBtn} onPress={() => this.share('facebook')}>
              <img src={Facebook2} alt="facebook" style={{ width: 20, height: 20 }} />
            </Button>
            <Button btnStyles={styles.emptyBtn} onPress={() => this.share('twitter')}>
              <img src={Twitter} alt="twitter" style={{ width: 20, height: 20, margin: '0 10px' }} />
            </Button>
            <Button btnStyles={styles.emptyBtn} onPress={() => this.share('whatsapp')}>
              <Icon name={ICONS.WHATSAAP} size={20} />
            </Button>
          </div>
          <CustomText size={isMobile ? 'small_0' : 'regular_1'} className={styles.experience} style={{ marginTop: isMobile ? SPACING.SPACE_5 : SPACING.SPACE_10 }}>
            A confirmation email has been sent to you
          </CustomText>
        </div>
        )}
        <button type="button" onClick={close} className={styles.close} style={{ margin: paymentStatus ? '20px 0 0px 0' : '30px 0 10px 0' }}>
          <CustomText weight="semi_bold" size={isMobile ? 'small' : 'medium'} color="white">
            {paymentStatus ? 'Close' : 'Try Again'}
          </CustomText>
        </button>
      </div>
    )
  }

  render() {
    const {
      styles, isMobile, paymentInfo,
    } = this.props
    const { description, title, image } = paymentInfo ?? {}
    const {
      showPaymentDetails, paymentStatusModal, showPageLoader,
    } = this.state

    return (
      <div className={styles.window}>
        <SEO
          title={title}
          description={description}
          image={image}
          url={window.location.href}
        />
        <PageLoader
          isLoading={showPageLoader}
          fullScreen
        />
        {isMobile ? this.renderMobileView() : this.renderDesktopView()}
        {!showPaymentDetails && this.renderFooter()}
        {isMobile && !showPaymentDetails && (
        <button type="button" onClick={this.showPaymentDetails} className={styles.payBtn}>
          <CustomText weight="semi_bold" size="medium" color="white">
            Next
          </CustomText>
        </button>
        )}
        <CustomModal
          visible={paymentStatusModal}
          dismissKey="paymentStatusModal"
          position="center"
          onDismiss={this.toggleModal}
          containerStyles={isMobile ? styles.mobileModalStyles : styles.modalStyles}
        >
          {this.renderStatusModal()}
        </CustomModal>
      </div>
    )
  }
}

const stylesheet = ({
  modalStyles: {
    minWidth: 400,
  },
  mobileModalStyles: {
    width: '80% !important',
    top: 250,
    left: '10%',
    padding: '10px',
    position: 'fixed',
    borderRadius: SPACING.SPACE_20,
  },
  window: {
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: '9999',
    background: COLORS.WHITE,
    overflow: 'auto',
  },
  desktop: {
    display: 'flex',
    maxWidth: 1100,
    flexDirection: 'column',
    background: COLORS.PP_DESKTOP_BG_COLOR,
    borderRadius: 35,
    margin: 'auto',
    marginTop: SPACING.SPACE_20,
    padding: '30px 50px 50px 50px',
  },
  desktopContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  line: {
    width: 1100,
    height: 10,
    margin: '100px auto',
    background: COLORS.PP_LINE_COLOR,
  },
  logoLink: {
    backgroundSize: 'contain',
    width: 180,
    height: 45,
    backgroundRepeat: 'no-repeat',
  },
  headerText: {
    marginTop: SPACING.SPACE_30,
    fontSize: 100,
    width: 315,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  desc: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: SPACING.SPACE_20,
  },
  description: {
    width: 460,
    lineHeight: 1.5,
    letterSpacing: 0.3,
    marginTop: SPACING.SPACE_20,
    fontFamily: "'Lato', sans-serif",
  },
  img: {
    width: 400,
    objectFit: 'contain',
    borderRadius: SPACING.SPACE_10,
  },
  paymentContent: {
    width: '100%',
    padding: '20px 30px',
    borderRadius: SPACING.SPACE_20,
    background: `url(${BLUEBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    lineHeight: 2,
  },
  paymentPrice: {
    color: COLORS.WHITE,
    fontWeight: FONTWEIGHT.SEMI_BOLD,
    margin: '0 5px',
  },
  paymentWindow: {
    zIndex: 2,
    borderRadius: SPACING.SPACE_10,
    padding: '30px 35px',
    backgroundColor: COLORS.WHITE,
    marginRight: SPACING.SPACE_10,
    boxShadow: '-4px -2px 8px 2px rgb(182 190 226)',
    height: 'min-content',
  },
  input: {
    marginTop: SPACING.SPACE_14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paymentBottom: {
    display: 'flex',
    alignItems: 'center',
    marginTop: SPACING.SPACE_40,
  },
  grid: {
    padding: '5px 5px 5px 10px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    backgroundColor: COLORS.PP_GREY_COLOR,
    width: '28%',
    placeItems: 'center',
    height: 50,
    borderRadius: '2px 0 0 2px',
  },
  method: {
    width: 30,
    height: 20,
    objectFit: 'contain',
  },
  payBtn: {
    backgroundColor: COLORS.PP_PAY_BTN_COLOR,
    color: COLORS.WHITE,
    padding: SPACING.SPACE_20,
    fontWeight: FONTWEIGHT.SEMI_BOLD,
    width: '76%',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0 2px 2px 0px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: SPACING.SPACE_50,
    maxWidth: 1100,
    margin: '10px auto',
  },
  about: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: SPACING.SPACE_50,
  },
  aboutSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginTop: SPACING.SPACE_24,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  terms: {
    marginTop: SPACING.SPACE_50,
  },
  dropDown: {
    borderRadius: 2,
    width: 260,
  },
  inputStyles: {
    color: `${COLORS.PP_TEXT_COLOR} !important`,
    fontWeight: `${FONTWEIGHT.MEDIUM} !important`,
  },
  inputBtnStyles: {
    borderWidth: 0,
    backgroundColor: COLORS.TABS_BG,
    borderRadius: 0,
    paddingLeft: SPACING.SPACE_20,
    height: 40,
  },
  inputBtnStylesError: {
    borderWidth: 1,
    borderColor: COLORS.RED,
    borderRadius: 0,
    height: 40,
  },
  statusModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusText: {
    marginTop: SPACING.SPACE_20,
  },
  statusInfo: {
    marginTop: SPACING.SPACE_10,
    width: 'max-content',
  },
  experience: {
    marginTop: SPACING.SPACE_40,
  },
  close: {
    backgroundColor: COLORS.PP_BLUE_COLOR,
    color: COLORS.WHITE,
    padding: '15px 60px',
    marginTop: SPACING.SPACE_20,
    borderRadius: 8,
    fontWeight: FONTWEIGHT.SEMI_BOLD,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: SPACING.SPACE_20,
  },
  share: {
    display: 'flex',
    marginTop: SPACING.SPACE_10,
  },
  emptyBtn: { padding: 0, borderWidth: 0 },
  '@media only screen and (max-width: 480px)': {
    statusText: {
      marginTop: SPACING.SPACE_10,
    },
    experience: {
      marginTop: SPACING.SPACE_20,
    },
    mobile: {
      backgroundColor: COLORS.PP_MOBILE_BG_COLOR,
      position: 'relative',
    },
    holder: {
      backgroundColor: COLORS.PP_MOBILE_BG_COLOR,
      height: 1,
      width: '100%',
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      padding: SPACING.SPACE_20,
      borderRadius: '15px',
      top: 120,
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.05)',
      backgroundColor: COLORS.WHITE,
      zIndex: 999,
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    headerText: {
      width: 'min-content',
      alignSelf: 'center',
    },
    mobileContent: {
      position: 'relative',
      backgroundColor: COLORS.GREY_000,
      marginTop: 120,
      borderRadius: '60px 60px 0 0',
      padding: '10px 30px',
      display: 'flex',
      flexDirection: 'column',
    },
    desc: {
      marginTop: SPACING.SPACE_40,
    },
    indicator: {
      position: 'absolute',
      width: 20,
      height: 25,
      left: 0,
      marginTop: SPACING.SPACE_10,
      borderRadius: '0 30px 30px 0',
      backgroundColor: COLORS.PP_BLUE_COLOR,
    },
    description: {
      marginTop: SPACING.SPACE_40,
      width: 280,
    },
    img: {
      width: '100%',
      marginTop: SPACING.SPACE_40,
    },
    mobilePaymentContent: {
      marginTop: SPACING.SPACE_40,
      padding: '10px 0px',
      backgroundColor: COLORS.PP_BLUE_COLOR,
      lineHeight: 2,
      margin: '0 -30px',
    },
    dots: {
      width: '100%',
    },
    footer: {
      marginTop: SPACING.SPACE_40,
      padding: '0 30px',
    },
    about: {
      flexDirection: 'row-reverse',
      width: '90%',
    },
    info: {
      marginTop: SPACING.SPACE_20,
    },
    payBtn: {
      position: 'fixed',
      bottom: SPACING.SPACE_30,
      width: 'calc(100% - 60px)',
      marginLeft: '30px',
      padding: SPACING.SPACE_15,
      borderRadius: 1,
      height: 'max-content',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.15)',
    },
    mobilePayment: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    details: {
      marginTop: SPACING.SPACE_50,
      position: 'relative',
      padding: '0 30px',
    },
    input: {
      display: 'block',
      marginTop: SPACING.SPACE_20,
      width: '100%',
    },
    images: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: COLORS.GREY_100,
      padding: SPACING.SPACE_5,
    },
    method: {
      width: 40,
      height: 25,
      padding: SPACING.SPACE_10,
    },
    mobilePayBtn: {
      backgroundColor: COLORS.PP_PAY_BTN_COLOR,
      color: COLORS.WHITE,
      padding: SPACING.SPACE_20,
      fontWeight: FONTWEIGHT.SEMI_BOLD,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dropDown: {
      width: '100%',
      marginTop: SPACING.SPACE_20,
    },
    inputBtnStyles: {
      backgroundColor: COLORS.PP_INPUT_BACKGROUND,
    },
    close: {
      borderRadius: 6,
      padding: '10px 30px',
      marginBottom: SPACING.SPACE_10,
    },
    share: {
      marginTop: SPACING.SPACE_10,
    },
  },
})

export default withTheme(stylesheet)(PaymentPages)
