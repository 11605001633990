import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import classnames from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'
import dayjs from 'dayjs'

import Marquee from '../../UI/Marquee'
import ShowLTP from '../../UI/ShowLTP'
import CustomText from '../../UI/CustomText'
import StockIcon from '../../UI/StockIcon'
import VisualChart from './TopMoversChart'
import {
  ASSETS,
  COLORS,
  FONTS, SPACING, theme,
} from '../../Theme'
import { segSymConverter, symbolNameByOptions } from '../../utils/common'
import { fetchCandles } from '../../containers/Marketwatch/actions'

const areEqual = (prevProps, nextProps) => {
  const {
    seg_sym, pc_change,
  } = prevProps

  if (!isEqual(seg_sym, nextProps.seg_sym) || !isEqual(pc_change, nextProps.pc_change)) {
    return false
  }
  return true
}

const TopMoversSidebarRow = React.memo(({
  seg_sym, pc_change, wrapperStyles, containerStyles,
  routeToTechnicals, csrf, sessionid,
}) => {
  const [data, setData] = React.useState([])
  React.useEffect(() => {
    const [exchange, symbol] = seg_sym.split('_')
    const timePeriod = 'day'
    const d = new Date()
    const m = d.getMonth()
    d.setMonth(d.getMonth() - 3)
    if (d.getMonth() === m) d.setDate(0)
    d.setHours(0, 0, 0, 0)
    const now = dayjs(new Date()).unix()
    const startTime = d / 1000
    const endTime = now
    fetchCandles({
      exchange, symbol, startTime, endTime, timePeriod, csrf, sessionid,
    }).then(async (res) => {
      const modChartData = res.map(
        item => ({ y: Number(item[2].toFixed(2)), x: item[0] }),
      )
      setData(modChartData)
    })
  }, [])
  const styles = stylesheet()
  let change = 'green'
  // let changeText = pc_change
  // if (pc_change > 0) {
  //   changeText = `+${pc_change.toFixed(2)}%`
  // }
  if (pc_change < 0) {
    change = 'red'
    // changeText = `${pc_change.toFixed(2)}%`
  }
  const { symbol, segment } = segSymConverter(seg_sym)
  let symbolName = symbol
  const rawSymbolName = symbolNameByOptions(symbolName)
  let showOptions
  if (symbolName !== rawSymbolName) {
    showOptions = rawSymbolName.charAt(rawSymbolName.length - 2)
    symbolName = rawSymbolName.substring(0, rawSymbolName.length - 4)
  }
  const finalOptionText = {
    backgroundColor: showOptions === 'W' ? COLORS.BLUE : COLORS.ORANGE,
  }
  const stockIconContainerStyle = {
    display: 'inline-block',
    marginRight: SPACING.SPACE_6,
    maxWidth: 20,
  }
  return (
    <div
      className={classnames(styles.wrapper, wrapperStyles)}
    >
      <button
        type="button"
        onClick={() => routeToTechnicals(seg_sym)}
        className={classnames(styles.container, containerStyles)}
      >
        <div className={styles.symbol}>
          <StockIcon
            seg_sym={seg_sym}
            width="unset"
            height="65%"
            containerStyle={stockIconContainerStyle}
          />
          <Marquee props={{ className: styles.symbolRow }} tooltipText={`${segment} ${symbol}`} showMarquee={false}>
            <CustomText weight="medium">
              {symbolName}
              {showOptions && showOptions !== ' ' && (
              <img src={showOptions === 'W' ? ASSETS.Weekly : ASSETS.Monthly} style={finalOptionText} className="optionsText" alt="w" />
              )}
            </CustomText>
          </Marquee>
        </div>
        <div style={{ width: 90 }}>
          <VisualChart
            id={seg_sym}
            data={data}
            chart_height={30}
          />
        </div>
        <div className={styles.changeText}>
          <ShowLTP
            seg_sym={seg_sym}
            containerStyles={styles.ltpContainer}
            ltpStyles={styles.ltp}
            hideChange
          />
          <CustomText size="small" color={change} style={{ display: 'flex', justifyContent: 'end' }}>
            <ShowLTP
              seg_sym={seg_sym}
              showLtp={false}
              changeSize="small"
              hideCurrency
            />
          </CustomText>
        </div>
      </button>
    </div>
  )
}, areEqual)

const stylesheet = makeStyles({
  wrapper: {
    display: 'inline-flex',
    padding: `0 ${SPACING.SPACE_20}`,
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.hover,
    },
    '&:hover $container': {
      backgroundColor: theme.hover,
    },
  },
  container: {
    display: 'flex',
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.bgPrimary,
    padding: `${SPACING.SPACE_10} 0`,
    position: 'relative',
  },
  symbol: {
    // display: 'flex',
    display: 'inline-block',
    width: '33%',
    textAlign: 'left',
    '& > div > svg': {
      height: '100%',
      width: '100%',
    },
  },
  symbolRow: {
    width: 'calc(100% - 26px) !important',
    display: 'inline-block',
  },
  ltpContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginLeft: SPACING.SPACE_2,
  },
  ltp: {
    fontSize: FONTS.MEDIUM,
  },
  changeText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: 1,
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      alignItems: 'flex-start',
    },
    symbol: {
      display: 'inline-block',
    },
    wrapper: {
      display: 'inline-flex',
      padding: `0 ${SPACING.SPACE_12}`,
    },
  },
})

TopMoversSidebarRow.defaultProps = {
  containerStyles: '',
}

TopMoversSidebarRow.propTypes = {
  seg_sym: PropTypes.string.isRequired,
  containerStyles: PropTypes.string,
}

export default TopMoversSidebarRow
