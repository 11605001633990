export const CLEAR_TECHNICALS_RESP = 'CLEAR_TECHNICALS_RESP'
export const FETCH_TECHNICALS_LIST = 'FETCH_TECHNICALS_LIST'
export const FETCH_TECHNICALS_LIST_SUCCESS = 'FETCH_TECHNICALS_LIST_SUCCESS'
export const FETCH_TECHNICALS_LIST_FAILURE = 'FETCH_TECHNICALS_LIST_FAILURE'

export const GET_MULTI_ANALYSIS_SUCCESS = 'GET_MULTI_ANALYSIS_SUCCESS'
export const GET_MULTI_ANALYSIS_FAILURE = 'GET_MULTI_ANALYSIS_FAILURE'
export const GET_MULTI_ANALYSIS_INIT = 'GET_MULTI_ANALYSIS_INIT'
export const CLEAR_MULTI_ANALYSIS = 'CLEAR_MULTI_ANALYSIS'

export const UPDATE_TECHNICALS_LIST = 'UPDATE_TECHNICALS_LIST'
export const UPDATE_TECHNICALS_LIST_SUCCESS = 'UPDATE_TECHNICALS_LIST_SUCCESS'
export const UPDATE_TECHNICALS_LIST_FAILURE = 'UPDATE_TECHNICALS_LIST_FAILURE'

export const FETCH_CAROUSAL_CATEGORY = 'FETCH_CAROUSAL_CATEGORY'
export const FETCH_CAROUSAL_CATEGORY_SUCCESS = 'FETCH_CAROUSAL_CATEGORY_SUCCESS'
export const FETCH_CAROUSAL_CATEGORY_FAILURE = 'FETCH_CAROUSAL_CATEGORY_FAILURE'

export const FETCH_RESISTANCE = 'FETCH_RESISTANCE'
export const FETCH_RESISTANCE_SUCCESS = 'FETCH_RESISTANCE_SUCCESS'
export const FETCH_RESISTANCE_FAILURE = 'FETCH_RESISTANCE_FAILURE'

export const CLEAR_RESISTANCE = 'CLEAR_RESISTANCE'
