import {
  SAVE_PROFILE_INIT,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILURE,
  CLEAR_PROFILE_RESP,
  GENERATE_OTP_INIT,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  REQUEST_DEMO_CALL,
  REQUEST_DEMO_CALL_SUCCESS,
  REQUEST_DEMO_CALL_FAILURE,
  IS_DEMO_REQUESTED,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_INIT,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  FETCH_FILE,
  FETCH_FILE_SUCCESS,
  FETCH_FILE_FAILURE,
  CLEAR_DEMO_CALL_RESP,
  TOGGLE_REQUEST_DEMO,
  SHOULD_REQUEST_DEMO,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_INIT,
  REMOVE_PROFILE,
  REMOVE_PROFILE_SUCCESS,
  REMOVE_PROFILE_FAILURE,
  UPDATE_TRIAL_PLAN,
  UPDATE_TRIAL_PLAN_SUCCESS,
  UPDATE_TRIAL_PLAN_FAILURE,
  SUBMIT_FEEDBACK,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
  VERIFY_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER_SUCCESS,
  VERIFY_PHONE_NUMBER_FAILURE,
  SHOW_ADD_BROKER_POPUP,
  FETCH_SHAREABLE_LINKS,
  FETCH_SHAREABLE_LINKS_SUCCESS,
  FETCH_SHAREABLE_LINKS_FAILURE,
  DELETE_SHAREABLE_LINKS,
  DELETE_SHAREABLE_LINKS_SUCCESS,
  DELETE_SHAREABLE_LINKS_FAILURE,
} from './actionTypes'

export function saveProfileSuccess(data) {
  return {
    type: SAVE_PROFILE_SUCCESS,
    data,
  }
}

export function saveProfileFailure(error) {
  return {
    type: SAVE_PROFILE_FAILURE,
    error,
  }
}

export function saveProfile(params, headers) {
  return {
    type: SAVE_PROFILE_INIT,
    params,
    headers,
  }
}

export function clearSaveResp() {
  return {
    type: CLEAR_PROFILE_RESP,
  }
}

export function generateOTPSuccess(data) {
  return {
    type: GENERATE_OTP_SUCCESS,
    data,
  }
}

export function generateOTPFailure(error) {
  return {
    type: GENERATE_OTP_FAILURE,
    error,
  }
}

export function generateOTP(params, headers) {
  return {
    type: GENERATE_OTP_INIT,
    params,
    headers,
  }
}

export function requestDemoCall(params, headers) {
  return {
    type: REQUEST_DEMO_CALL,
    params,
    headers,
  }
}

export function requestDemoCallSuccess(msg) {
  return {
    type: REQUEST_DEMO_CALL_SUCCESS,
    msg,
  }
}

export function requestDemoCallFailure(error) {
  return {
    type: REQUEST_DEMO_CALL_FAILURE,
    error,
  }
}

export function clearDemoCallResp() {
  return {
    type: CLEAR_DEMO_CALL_RESP,
  }
}

export function toggleRequestDemo(showDemo, isCarouselReqDemo) {
  return {
    type: TOGGLE_REQUEST_DEMO,
    showDemo,
    isCarouselReqDemo,
  }
}

export function isDemoRequested(data) {
  return {
    type: IS_DEMO_REQUESTED,
    data,
  }
}

export function updateProfileSuccess(data) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    data,
  }
}

export function updateProfileFailure(error) {
  return {
    type: UPDATE_PROFILE_FAILURE,
    error,
  }
}

export function updateProfile(params, headers, additionalInfo) {
  return {
    type: UPDATE_PROFILE_INIT,
    params,
    headers,
    additionalInfo,
  }
}

export function changePasswordSuccess(data) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    data,
  }
}

export function changePasswordFailure(error) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    error,
  }
}

export function changePassword(params, headers) {
  return {
    type: CHANGE_PASSWORD_INIT,
    params,
    headers,
  }
}

export function uploadFile(payload) {
  return {
    type: UPLOAD_FILE,
    payload,
  }
}

export function uploadFileSuccess() {
  return {
    type: UPLOAD_FILE_SUCCESS,
  }
}

export function uploadFileFailure(error) {
  return {
    type: UPLOAD_FILE_FAILURE,
    error,
  }
}

export function removeProfilePic(params) {
  return {
    type: REMOVE_PROFILE,
    params,
  }
}

export function removeProfilePicSuccess() {
  return {
    type: REMOVE_PROFILE_SUCCESS,
  }
}

export function removeProfilePicFailure(error) {
  return {
    type: REMOVE_PROFILE_FAILURE,
    error,
  }
}

export function fetchFile(params) {
  return {
    type: FETCH_FILE,
    params,
  }
}

export function fetchFileSuccess(data) {
  return {
    type: FETCH_FILE_SUCCESS,
    data,
  }
}

export function fetchFileFailure(error) {
  return {
    type: FETCH_FILE_FAILURE,
    error,
  }
}

export function shouldRequestDemo(params, headers) {
  return {
    type: SHOULD_REQUEST_DEMO,
    params,
    headers,
  }
}

export function updateTrialPlan(params, headers) {
  return {
    type: UPDATE_TRIAL_PLAN,
    params,
    headers,
  }
}

export function updateTrialPlanSuccess() {
  return {
    type: UPDATE_TRIAL_PLAN_SUCCESS,
  }
}

export function updateTrialPlanFailure(error) {
  return {
    type: UPDATE_TRIAL_PLAN_FAILURE,
    error,
  }
}

export function submitFeedback(params) {
  return {
    type: SUBMIT_FEEDBACK,
    params,
  }
}

export function submitFeedbackSuccess(data) {
  return {
    type: SUBMIT_FEEDBACK_SUCCESS,
    data,
  }
}

export function submitFeedbackFailure(error) {
  return {
    type: SUBMIT_FEEDBACK_FAILURE,
    error,
  }
}

export function verifyPhoneNumber(params) {
  return {
    type: VERIFY_PHONE_NUMBER,
    params,
  }
}

export function verifyPhoneNumberSuccess(data) {
  return {
    type: VERIFY_PHONE_NUMBER_SUCCESS,
    data,
  }
}

export function verifyPhoneNumberFailure(error) {
  return {
    type: VERIFY_PHONE_NUMBER_FAILURE,
    error,
  }
}

export function showAddBrokerPopup(params) {
  return {
    type: SHOW_ADD_BROKER_POPUP,
    params,
  }
}

export function fetchShareableLinks(params) {
  return {
    type: FETCH_SHAREABLE_LINKS,
    params,
  }
}

export function fetchShareableLinksSuccess(data, params, kind) {
  return {
    type: FETCH_SHAREABLE_LINKS_SUCCESS,
    data,
    params,
    kind,
  }
}

export function fetchShareableLinksFailure(error) {
  return {
    type: FETCH_SHAREABLE_LINKS_FAILURE,
    error,
  }
}

export function deleteShareableLinks(params) {
  return {
    type: DELETE_SHAREABLE_LINKS,
    params,
  }
}

export function deleteShareableLinksSuccess(data) {
  return {
    type: DELETE_SHAREABLE_LINKS_SUCCESS,
    data,
  }
}

export function deleteShareableLinksFailure(error) {
  return {
    type: DELETE_SHAREABLE_LINKS_FAILURE,
    error,
  }
}
