import makeStyles from '@material-ui/styles/makeStyles'
import debounce from 'lodash/debounce'
import React, { useState, useCallback } from 'react'

import Icon from '../../UI/Icon'
import CustomInput from '../../UI/CustomInput'
import Button from '../../UI/Button'

import {
  ICONS, SPACING, theme,
} from '../../Theme'
import CustomText from '../../UI/CustomText'

let selectedItem = {}

const ScanOnFinder = React.memo(({
  allowed_scans,
  handleChange,
  changeKey = 'scan_on',
  isMobile,
  onDismiss,
  text,
  scan_on,
  isDark,
  searchKeyMap = ['label', 'value', 'name'],
}) => {
  const styles = stylesheet()
  const [options, updateOptions] = useState(allowed_scans)
  // const [selected, updateSelected] = useState(scan_on)
  const [searchValue, updateValue] = useState('')
  const [expanded, updateExpanded] = useState(false)
  const onSubmit = () => {
    onDismiss()
  }
  const toggleExpanded = expandKey => [
    updateExpanded({ ...expanded, [expandKey]: !expanded[expandKey] }),
  ]
  const debouncedHandler = useCallback(debounce(v => filterOptions(v), 50), [])
  const matchOptions = (query, optionsToFilter) => {
    const modOptions = []
    optionsToFilter.forEach((optionItem) => {
      let isMatched = false
      let newItem = optionItem
      if (typeof optionItem === 'object') {
        if (optionItem.options && optionItem.options.length) {
          const matchedArr = matchOptions(query, optionItem.options)
          isMatched = matchedArr.length > 0
          newItem = { ...newItem, options: matchedArr }
        } else {
          searchKeyMap.forEach((searchKey) => {
            const itemString = optionItem[searchKey] ? optionItem[searchKey].toLowerCase() : ''
            isMatched = isMatched || itemString.includes(query.toLowerCase())
          })
        }
      } else {
        isMatched = optionItem.toLowerCase().includes(query.toLowerCase())
      }
      if (isMatched) {
        modOptions.push(newItem)
      }
    })
    return modOptions
  }
  const filterOptions = (query) => {
    updateOptions(matchOptions(query, allowed_scans))
  }
  const onSearch = (ev) => {
    const { target } = ev
    const stockQuery = target.value
    updateValue(stockQuery)
    debouncedHandler(stockQuery)
  }
  const renderItem = (item, labelKey, valueKey) => {
    let label = item
    let value = item
    let iconName = ''
    if (typeof item === 'object') {
      label = labelKey ? item[labelKey] : item.label
      value = valueKey ? item[valueKey] : item.value
      iconName = item.icon
    }
    const isSelected = value === scan_on
    if (item.expandable) {
      const len = item.options.length
      return (
        <div key={value} className={styles.itemContainer}>
          <button
            type="button"
            onClick={() => toggleExpanded(value)}
            style={{ backgroundColor: isDark ? theme.disabled : theme.screenBg }}
            className={styles.itemHeader}
          >
            <CustomText>
              {len > 0 ? (
                <>
                  {`${label} `}
                  <span style={{ color: theme.textSecondary }}>{`(${len})`}</span>
                </>
              ) : label}

            </CustomText>
            <Icon name={ICONS[expanded[value] ? 'UP' : 'DOWN']} color={theme.textSecondary} size={12} />
          </button>
          {len > 0 && expanded[value] && (
            item.options.map(subItem => renderItem(subItem, item.labelKey, item.valueKey))
          )}
        </div>

      )
    }
    return (
      <button
        key={value}
        type="button"
        onClick={() => {
          selectedItem = item
          handleChange(value, selectedItem, changeKey)
        }}
        className={styles.itemBtn}
      >
        <div className={styles.item}>
          {Boolean(iconName) && (
          <Icon
            name={ICONS[iconName.toUpperCase()]}
            size={16}
            color={theme.linkColor}
            className={styles.icon}
          />
          )}
          <CustomText className={styles.itemLabel}>{label}</CustomText>
        </div>
        {isSelected && (
        <Icon name={ICONS.TICK_ROUND_FILLED} color={theme.green} size={16} />
        )}
      </button>
    )
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.actionBar}>
          {isMobile && (
            <button
              type="button"
              onClick={onDismiss}
              className={styles.backBtn}
            >
              <Icon name={ICONS.BACK} color={text} size={16} />
            </button>
          )}
          <CustomInput
            wrapperStyles={styles.inputContainer}
            iconName="search"
            size={12}
            inputStyles={styles.searchInput}
            onChangeText={onSearch}
            value={searchValue}
            placeholder="Search"
            showClearBtn
          />
          <Button
            text="Done"
            onPress={onSubmit}
            buttonColor="blue"
            btnStyles={styles.submitBtn}
          />
        </div>
      </div>
      {options.map(fields => renderItem(fields))}
    </div>
  )
})

const stylesheet = makeStyles({
  container: {
    padding: `0 ${SPACING.SPACE_20}`,
    backgroundColor: theme.bgPrimary,
    // maxHeight: 400,
    // minHeight: DIMENSIONS.HEIGHT * (3 / 4),
    maxHeight: '100%',
    height: '100%',
    maxWidth: 650,
    minWidth: 150,
    margin: 'auto',
    overflow: 'auto',
    // backdropFilter: 'blur(35px)',
    // WebkitBackdropFilter: 'blur(35px)',
  },
  existingList: {},
  header: {
    top: 0,
    position: 'sticky',
    padding: `${SPACING.SPACE_10} 0 ${SPACING.SPACE_20}`,
    backgroundColor: theme.bgPrimary,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  searchInput: {
    padding: '10px 0',
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_10,
  },
  submitBtn: {
    // width: '',
    flex: 1,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    border: '1px solid transparent',
    marginLeft: SPACING.SPACE_20,
    maxWidth: '200px',
    '&:first-child': {
      margin: 0,
    },
  },
  listView: {
  },
  itemBtn: {
    padding: `${SPACING.SPACE_10}`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  backBtn: {},
  itemContainer: {},
  itemHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_8}`,
    borderRadius: 12,
    width: '100%',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: SPACING.SPACE_8,

  },
  itemLabel: {
    textAlign: 'left',
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      width: '100%',
      padding: '0',
    },
    header: {
      padding: `${SPACING.SPACE_12} ${SPACING.SPACE_12} 0`,
    },
    itemBtn: {
      padding: `${SPACING.SPACE_12}`,
    },
    searchInput: {
      padding: '6px 0',
    },
    submitBtn: {
      padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    },
    backBtn: {
      padding: `${SPACING.SPACE_2} ${SPACING.SPACE_8} ${SPACING.SPACE_2} 0`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
})

export default ScanOnFinder
