import toNumber from 'lodash/toNumber'
import { ICONS, COLORS } from '../../Theme'

// export const POSITIONS_DROPDOWN = {
//   ALL: { LABEL: 'All', BACKEND: 'All', REDUX: 'all' },
//   AUTO: { LABEL: 'Auto', BACKEND: 'Auto', REDUX: 'auto' },
//   PAPER: { LABEL: 'Paper', BACKEND: 'Paper', REDUX: 'paper' },
//   NOTIFICATIONS: { LABEL: 'Notifications', BACKEND: 'Notifications', REDUX: 'notifications' },
// }

export const PORTFOLIO_DROPDOWN = {
  STREAK: {
    LABEL: 'Streak',
    shortLabel: 'Streak',
    ID: 'Streak',
    gaLabel: 'Streak',
  },
  ALL: {
    LABEL: 'All',
    shortLabel: 'All',
    ID: 'All',
    gaLabel: 'All',
  },
}

export const PORTFOLIO_TABS = {
  POSITIONS: {
    KEY: 'positions', TITLE: 'POSITIONS', ID: 'POSITIONS', INDEX: 0, gaLabel: 'Positions',
  },
  HOLDINGS: {
    KEY: 'holdings', TITLE: 'HOLDINGS', ID: 'HOLDINGS', INDEX: 1, gaLabel: 'Holdings',
  },
}

export const SHAREABLE_LINKS_TABS = {
  STRATEGIES: {
    KEY: 'strategies',
    TITLE: 'Strategy Links',
    ID: 'STRATEGIES',
    INDEX: 0,
    gaLabel: 'Strategies',
    deleteTitle: 'Strategy link',
  },
  SCANNERS: {
    KEY: 'scanners',
    TITLE: 'Scanner Links',
    ID: 'SCANNERS',
    INDEX: 1,
    gaLabel: 'Scanners',
    deleteTitle: 'Scanner link',
  },
}

export const PORTFOLIO_MENU_MAP = {
  DETAILS: {
    LABEL: 'Details',
    ID: 'DETAILS',
    icon: ICONS.STRATEGY_DETAILS,
  },
  EXIT: {
    LABEL: 'Exit',
    ID: 'EXIT',
    icon: ICONS.CANCELLED,
    iconColor: 'RED',
    labelStyle: { color: COLORS.RED },
  },
}

// filter portfolio into open and close
export const filterPortfolio = ({ positions = [], holdings = [] } = {}) => {
  const filteredPortfolio = {
    open: {
      positions: [],
      holdings: [],
    },
    close: {
      positions: [],
      holdings: [],
    },
  }

  // the itemType is mapped to each item to identify whether it's position or holding
  // this is done to show highlighted text of P/H in dashboard

  positions.forEach((position) => {
    if(position.quantity) {
      if(toNumber(position.quantity) !== 0) {
        filteredPortfolio.open.positions.push({ ...position, itemType: 'positions' })
      } else {
        filteredPortfolio.close.positions.push({ ...position, itemType: 'positions' })
      }
    }
  })
  holdings.forEach((holding) => {
    if(holding.quantity) {
      if(toNumber(holding.quantity) !== 0) {
        filteredPortfolio.open.positions.push({ ...holding, itemType: 'holdings' })
      } else {
        filteredPortfolio.close.positions.push({ ...holding, itemType: 'holdings' })
      }
    }
  })
  return filteredPortfolio
}

export const getPortfolioSegSym = (item = {}) => {
  let segSym = `${item.exchange}_${item.tradingsymbol}`
  if (item.tradingsymbol.slice(-3) === 'FUT' && item.exchange !== 'MCX') {
    segSym = `${item.exchange}-FUT_${item.tradingsymbol}`
  }
  return segSym
}
