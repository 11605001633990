export const GET_STOCKS_INIT = 'GET_STOCKS_INIT'
export const GET_STOCKS_SUCCESS = 'GET_STOCKS_SUCCESS'
export const GET_STOCKS_FAILURE = 'GET_STOCKS_FAILURE'

export const UPDATE_PREFS_INIT = 'UPDATE_PREFS_INIT'
export const UPDATE_PREFS_SUCCESS = 'UPDATE_PREFS_SUCCESS'
export const UPDATE_PREFS_FAILURE = 'UPDATE_PREFS_FAILURE'

export const DEPLOY_ALGO_INIT = 'DEPLOY_ALGO_INIT'
export const DEPLOY_ALGO_SUCCESS = 'DEPLOY_ALGO_SUCCESS'
export const DEPLOY_ALGO_FAILURE = 'DEPLOY_ALGO_FAILURE'

export const CLEAR_RESP = 'CLEAR_RESP'

export const GET_ALGO_STATE_INIT = 'GET_ALGO_STATE_INIT'
export const GET_ALGO_STATE_SUCCESS = 'GET_ALGO_STATE_SUCCESS'
export const GET_ALGO_STATE_FAILURE = 'GET_ALGO_STATE_FAILURE'

export const CLEAR_ALGO_STATE_RESP = 'CLEAR_ALGO_STATE_RESP'

export const GET_INDICATORS_INIT = 'GET_INDICATORS_INIT'
export const GET_INDICATORS_SUCCESS = 'GET_INDICATORS_SUCCESS'
export const GET_INDICATORS_FAILURE = 'GET_INDICATORS_FAILURE'

export const GET_TRAINER_MODEL_INIT = 'GET_TRAINER_MODEL_INIT'
export const GET_TRAINER_MODEL_SUCCESS = 'GET_TRAINER_MODEL_SUCCESS'
export const GET_TRAINER_MODEL_FAILURE = 'GET_TRAINER_MODEL_FAILURE'

export const SET_LAUNCH_PARAMS = 'SET_LAUNCH_PARAMS'
export const CLEAR_LAUNCH_PARAMS = 'CLEAR_LAUNCH_PARAMS'

export const UPDATE_SCRIPLIST = 'UPDATE_SCRIPLIST'

export const COPY_ALGO = 'COPY_ALGO'
export const COPY_ALGO_SUCCESS = 'COPY_ALGO_SUCCESS'
export const COPY_ALGO_FAILURE = 'COPY_ALGO_FAILURE'

export const GET_DEFAULT_STOCKS = 'GET_DEFAULT_STOCKS'
export const GET_DEFAULT_STOCKS_SUCCESS = 'GET_DEFAULT_STOCKS_SUCCESS'
export const GET_DEFAULT_STOCKS_FAILURE = 'GET_DEFAULT_STOCKS_FAILURE'

export const GET_ALGO_FROM_TOKEN = 'GET_ALGO_FROM_TOKEN'
export const GET_ALGO_FROM_TOKEN_SUCCESS = 'GET_ALGO_FROM_TOKEN_SUCCESS'
export const GET_ALGO_FROM_TOKEN_FAILURE = 'GET_ALGO_FROM_TOKEN_FAILURE'
