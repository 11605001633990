import React from 'react'
import { Spring, animated } from 'react-spring'

import ReactDOM from 'react-dom'
import DashboardSideBar from './DashboardSideBar'
import EditParams from '../EditAlgoParams/EditParams'
import EditScreenerParams from '../Screener/EditScreenerParams'
import InProgressSidebar from '../InProgressAlgos/InProgressSidebar'
import AlgoCategorySideBar from '../AlgoDetailed/AlgoCategorySideBar'
import AlgoSideBar from '../AlgoCondensedView/AlgoSidebar'
import TopMoversSidebar from '../TopMovers/TopMoversSidebar'
import ScannerCategorySideBar from '../ScannerDetailed/ScannerCategorySideBar'
import Icon from '../../UI/Icon'

import { ICONS, SPACING, theme } from '../../Theme'
import { pathname_mapping } from '../../utils/navigationConstant'
import { PROFILE_MENU_MAP } from '../../containers/Profile/profileUtils'
import AlgoCondensedSidebar from '../AlgoCondensedView/AlgoCondensedSidebar'
// set null if do not want to show sidebar
// tabKey is used to show sidebar component where there are more than one route
export const BAR_TYPE_MAP = {
  [pathname_mapping.Dashboard]: {
    Component: DashboardSideBar,
    show: true,
    id: 'dash_side_bar',
  },
  [pathname_mapping.Backtests]: {
    Component: EditParams,
    show: true,
    showToggler: true,
    id: 'tour_bt_6',
    animProps: {
      // from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
      // to: { opacity: 1, transform: 'translate3d(0,0,0)' },
    },
  },
  [pathname_mapping.BacktestsSharedApp]: {
    Component: EditParams,
    show: true,
    showToggler: true,
    id: 'tour_bt_6',
    animProps: {
      // from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
      // to: { opacity: 1, transform: 'translate3d(0,0,0)' },
    },
  },
  [pathname_mapping.Scanners]: {
    show: true,
    Component: AlgoSideBar,
    subComponent: [
      { tabKey: 'scan_details', Component: EditScreenerParams, showToggler: true },
      { tabKey: 'share_scan', Component: EditScreenerParams, showToggler: true },
      { tabKey: 'scan_details_live', Component: null },
      { tabKey: 'share_scan', Component: EditScreenerParams, showToggler: true },
    ],
  },
  [pathname_mapping.Account]: {
    subComponent: [
      { tabKey: PROFILE_MENU_MAP.pricing.tab, Component: null },
    ],
  },
  [pathname_mapping.payment]: null,
  [pathname_mapping.Create]: null,
  [pathname_mapping.NewScanner]: null,
  [pathname_mapping.InProgress]: {
    show: true,
    Component: InProgressSidebar,
  },
  // when we have more subroutes
  [pathname_mapping.Discover]: {
    // subComponent: [
    //   { tabKey: 'explore', Component: AlgoCategorySideBar },
    // ],
    Component: AlgoCategorySideBar,
    show: true,
  },
  [pathname_mapping.Strategies]: {
    show: true,
    Component: AlgoSideBar,
    id: 'tour_algo_side_bar',
  },
  [pathname_mapping.Deployed]: {
    show: true,
    Component: AlgoCondensedSidebar,
  },
  [pathname_mapping.Orderbook]: {
    show: true,
    Component: AlgoSideBar,
  },
  [pathname_mapping.marketwatch]: {
    show: true,
    Component: TopMoversSidebar,
  },
  [pathname_mapping.popular]: {
    show: true,
    Component: ScannerCategorySideBar,
  },
}

const defaultAnimProps = {
  // from: { opacity: 0, transform: 'translate3d(0,300px,-20px)' },
  // to: { opacity: 1, transform: 'translate3d(0,0,0)' },
}

const SideBar = ({
  activeSideBarObj,
  containerStyles,
  isDark,
  isFetchingUser,
  domainChange,
  toggleSideBar,
  closeBtnStyles,
  sideBarRef,
  updateGA,
  hideSideBar,
  startTour,
}) => {
  // const queryParams = parseQuery(search)
  // DANGER need better approach
  // const path = !isEmpty(queryParams) ? `${pathname}?${Object.keys(queryParams)[0]}=` : pathname

  const styles = {
    backgroundColor: theme.bgSidebar,
    borderRadius: 8,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    padding: `${SPACING.SPACE_20} 0`,
  }
  const renderHighlighted = {
    zIndex: 999999,
    position: 'fixed',
    transition: 'all .3s ease-in-out',
    pointerEvents: 'none',
    opacity: 0,
    width: '15%',
  }
  const Renderer = activeSideBarObj.Component
    || BAR_TYPE_MAP[pathname_mapping.Dashboard].Component
  const animationProps = activeSideBarObj.animProps || defaultAnimProps
  const extraAttr = activeSideBarObj.id ? { id: activeSideBarObj.id } : {}
  const ptIdBacktest2 = 'tour_backtest_2'

  const onUpdateGA = () => {
    updateGA('Highlight Click', 'Bt Pg 2', 1, 'Product Tour')
  }

  const renderHighlight = (props) => {
    setTimeout(() => {
      updatePosition()
    }, 2000)
    return (ReactDOM.createPortal(
      <div
        id={`${ptIdBacktest2}_dup`}
        style={renderHighlighted}
        role="button"
        onClick={onUpdateGA}
        tabIndex={0}
        onKeyUp={onUpdateGA}
      >
        <animated.div
          style={{
            ...styles,
            ...props,
            paddingTop: activeSideBarObj.showToggler ? SPACING.SPACE_32 : 0,
          }}
        >
          <Renderer
            isFetchingUser={isFetchingUser}
            isDark={isDark}
            domainChange={domainChange}
            updateGA={updateGA}
          />
        </animated.div>
      </div>,
      document.getElementById('highlightedWindow'),
    ))
  }

  const updatePosition = () => {
    setTimeout(() => {
      const el = document.getElementById(ptIdBacktest2)
      if(!el) return
      const {
        top, left, width, height,
      } = el.getBoundingClientRect()
      const elDup = document.getElementById(`${ptIdBacktest2}_dup`)
      if(!elDup) return
      elDup.style.top = `${top}px`
      elDup.style.left = `${left}px`
      elDup.style.width = `${width}px`
      elDup.style.height = `${height}px`
    }, 1000)
  }

  return (
    <aside className={containerStyles} ref={sideBarRef} {...extraAttr} id="aside">
      {activeSideBarObj.showToggler && (
      <button
        type="button"
        onClick={e => toggleSideBar(e, true)}
        className={closeBtnStyles}
      >
        <Icon name={ICONS.CLOSE} size={10} color={theme.text} />
      </button>
      )}
      {!hideSideBar && (
      <Spring
        {...animationProps}
        config={{
          mass: 5, tension: 2800, friction: 500,
        }}
      >
        {(props) => {
          return (
            <div>
              {startTour && renderHighlight(props) }
              <animated.div
                style={{
                  ...styles,
                  ...props,
                  paddingTop: activeSideBarObj.showToggler ? SPACING.SPACE_32 : 0,
                }}
                id={ptIdBacktest2}
              >
                <Renderer
                  isFetchingUser={isFetchingUser}
                  isDark={isDark}
                  domainChange={domainChange}
                  updateGA={updateGA}
                />
              </animated.div>
            </div>
          )
        }}
      </Spring>
      )}
    </aside>
  )
}

export default SideBar
