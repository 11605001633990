/* eslint-disable semi-spacing */
/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react'
import isEqual from 'lodash/isEqual'
// import axios from 'axios'

import { debounce, isEmpty } from 'lodash'
import moment from 'moment'
import SectionTab from '../../UI/SectionTab'
import ScreenHeader from '../../UI/ScreenHeader'
// import Icon from '../../UI/Icon'
import CreateView from '../../components/Create/CreateView'
import ConditionRenderer from '../../components/Create/ConditionRenderer'
import Button from '../../UI/Button'
import CustomModal from '../../UI/CustomModal'
import CreateNewList from '../../UI/CreateNewList'
import ScreenerConditionRenderer from '../../components/Screener/ConditionRenderer'
import AlertPopup from '../../UI/AlertPopup'
// import ModalDropdown from '../../UI/Dropdown/ModalDropdown'
import IndicatorDetails from '../../components/Create/IndicatorDetails'
import CreateParams from '../../components/EditAlgoParams/CreateParams'
import AddScrips from '../../components/SymbolFinder/AddScrips'
import CustomText from '../../UI/CustomText'

import dayjs from '../../utils/helpers/dayjs'
import Indicators from '../../components/Create/Indicators.json'
import fTree from '../../components/Create/tree.json'
import {
  ICONS, COLORS, ASSETS, SPACING, theme,
} from '../../Theme'
import { NAVIGATIONS, pathname_mapping } from '../../utils/navigationConstant'
import {
  date_range_epochs, candleIntervals, algoDateFormat, freq_candle_map,
  candle_freq_map, holding_type_map, screenerIndicatorPattern, STOCK_ADD_LIMIT,
  ULTIMATE_SEGMENT_OBJ, indicatorPattern, defaultChartList, // ROOT_MODALS, BROKERS,
  TPSL_TYPE, ORDER_TYPE, POSITION_SIZING_TYPE, TPSL_LIMIT, candle_map, postionBtnMap,
  MATH_FUNCS, LINK_MAP, ACCEPTED_SEGMENT, AB_TESTING_MAP,
  tf_map, rev_candle, rev_mtf_candle, candle_min_map,
} from '../../utils/consts'
import {
  hexCodeGenerator, screenerLogicStringGenerator, formEntryExitString, segSymConverter,
  getChartType,
  parseQuery, getAdvancedParamsLabel, setLocalStorage,
} from '../../utils/common'
import { showSnackbar } from '../../UI/Snackbar'
import {
  logicToStringGenerator, dynamicScripParams, getDynamicSymbol, modifyAdvancedParams,
  ALGO_MODE, CONDITION_TYPE, STEP_CONDITION_TYPE, VALID_TYPE_ERROR,
  initialState,
  CREATE_PAGE_VIEW_MAP,
  tslValidator,
  tpslValidator,
} from './Create-utils'
import { accessConditions, PRO_FEATURE_MAP, userAccessCheck } from '../../utils/userAccessChecks'
import { withTheme } from '../../Theme/ThemeProvider'
import Icon from '../../UI/Icon'
import { isExperimentOn, routeToCreate } from '../../utils/experimenting'
import CreateMulti from '../../components/Create/CreateMulti'
import CreateSingle from '../../components/Create/CreateSingle'
import { setEditGAParams } from '../Backtests/Backtests'
import { PREFERENCES } from '../Profile/profileUtils'
import { PRODUCT_TOUR_SECTIONS } from '../../components/ProductTour/ProductTour-utils'

const { CREATE_SWITCH } = ASSETS

const SCROLL_REF = {
  ENTRY: 'entry',
  QUANTITY: 'quantity',
  EXIT: 'exit',
  PARAMETERS: 'params',
  SCRIPLIST: 'scripList',
}

const menuOptions = [
  {
    label: 'Edit Name', value: 'edit', icon: ICONS.EDIT, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  {
    label: 'Delete', value: 'delete', icon: ICONS.DELETE, iconSize: 14, iconColor: 'RED', optionSize: 'small',
  },
]

const blockedPeriods = ['week', 'month']
const blockedPivotsIndc = ['Pivot point', 'Camarilla Pivot', 'Central pivot range']

export const checkBlockedMTFIndc = (logic = '') => {
  const regexPattern = /(?:Multitime frame|Multitime frame completed)\([^)]+\)/g
  const matches = logic?.match(regexPattern) || []

  const results = []

  matches?.forEach((match) => {
    const matchedPeriod = blockedPeriods.find(period => match.includes(period))
    const matchedIndc = blockedPivotsIndc.find(indc => match.includes(indc))

    const blockedTF = blockedPeriods.some(period => match.includes(period))
    const blockedPivots = blockedPivotsIndc.some(indc => match.includes(indc))

    const isMatched = blockedTF && blockedPivots

    const result = {
      isMatched,
      matchedPeriod,
      matchedIndc,
    }

    if (isMatched) {
      results.push(result)
    }
  })

  return results
}

let gaParamsMap = []

const multipleCiProps = {
  hideDelay: 5000,
  showReadMore: true,
  link: 'https://blog.streak.tech/timeframe-compatibility-and-setting-a-base-timeframe/',
}

class Create extends PureComponent {
  constructor(props) {
    super(props)
    const {
      user_details,
      location,
      preferences = {},
      startTour,
    } = props
    const { search, state = {} } = location
    const {
      id: algo_uuid, copy, edit: shouldEdit, advance = true, create_new,
      type: conditionEditType = CONDITION_TYPE.POSITION,
    } = parseQuery(search, true)
    const { stock, tech_route, tokenId } = parseQuery(search, false)
    const edit = shouldEdit || (algo_uuid && !copy)
    this.activeIndex = 0

    this.routeParams = {
      ...state,
      edit,
      copy,
      algo_uuid,
      tech_route,
      advance,
      create_new,
      conditionEditType,
      stock,
      tokenId,
    }
    const {
      expressions, // createAdvance: advance,
      algoName = '',

      scanOnStocks = [],

      //   create_new, // algo is not created yet so no name or id,
      //   newAlgo, // fresh algo is created so name and id exits comes in state
    } = this.routeParams

    this.createNew = (!copy && !algo_uuid && !edit) || Boolean(create_new)
    const gotAlgoState = false
    const disabled = !edit // running backtest is disabled
    const showNameChange = !!copy || Boolean(this.create_new) || Boolean(tokenId)
    const showCreateAdvance = !copy || state.newAlgo
    const algoNameValid = !!algoName
    const algo_name = algoName
    const entryString = ''
    const exitString = ''
    let scripList = []
    let scripValid = false
    const entryAndOrs = ['and']
    const createAdvance = Boolean(!!expressions || advance)
    const entryValid = false

    if (stock && stock.length) {
      stock.forEach((stck) => {
        const { segment, symbol, seg_sym } = segSymConverter(stck)
        if (segment && ACCEPTED_SEGMENT.includes(segment.toUpperCase())) {
          scripList.push({ segment, symbol, seg_sym })
          scripValid = true
        }
      })
      this.activeIndex = this.getStepIndex(conditionEditType)
    }
    if (scanOnStocks.length) {
      scripList = scanOnStocks.map((pair) => {
        const { segment, symbol, seg_sym } = segSymConverter(pair)
        return {
          segment, symbol, id: Date.now() + 1, seg_sym,
        }
      })
      scripValid = true
    }
    if (this.routeParams.stocks) {
      scripList = [...this.routeParams.stocks]
      scripValid = true
    }
    this.numRegx = /^[0-9]*\.?[0-9]*$/
    this.decimalRegx = /^\.[0-9]+$/
    this.indicatorsList = {}
    this.comparatorsList = {}
    this.alertProps = {}
    this.scripProps = {}
    this.prevParams = {}
    this.initialFetch = false
    const pageMode = !startTour && preferences[PREFERENCES.SINGLE_PAGE_MODE] !== undefined
      ? !preferences[PREFERENCES.SINGLE_PAGE_MODE]
      : ''
    this.isExperimentOn = isExperimentOn(AB_TESTING_MAP.CREATE_PAGE)
    gaParamsMap = []

    const { subscription_type = 0 } = user_details
    this.chartList = createAdvance ? defaultChartList.filter(cType => cType !== 'Renko') : [...defaultChartList]
    // DANGER
    this.exchanges = ['NSE', 'NFO', 'CDS']
    if (subscription_type === 3) {
      this.exchanges.push('MCX')
    }

    // DANGER add mcx has to be prevented in stock
    // DANGER
    // let f_seg = segment
    // if (segment === 'CDS') {
    //   f_seg = 'CDS-FUT'
    // } else if (segment === 'NFO') {
    //   f_seg = 'NFO-FUT'
    // }
    const time_frame = candleIntervals[initialState.candleInterval]
    const dt_stop = dayjs(new Date().getTime()).format(algoDateFormat)
    const dt_start = dayjs(dayjs().valueOf() - (date_range_epochs[time_frame]) * 1000)
      .format(algoDateFormat)

    // fetch indicator list and trainer model
    const initialConditionState = this.setCreateConditionState(createAdvance, true)
    const isMultiPage = startTour ? true : (pageMode !== '' ? pageMode : !this.isExperimentOn)
    const intialConditionEditType = isMultiPage
      ? conditionEditType : CONDITION_TYPE.PRO
    this.state = {
      ...initialState,
      ...initialConditionState,
      scripList,
      stopLossErr: false,
      takeProfitErr: false,
      algo_name,
      algoNamErr: false,
      disabled,
      exitAndOrs: ['and'],
      entryAndOrs,
      scripValid,
      scripErr: false,
      entryValid,
      exitValid: false,
      algoNameValid,
      editMode: false,
      show_advance: false,
      dt_start,
      dt_stop,
      showExitIndicator: false,
      edit: false,
      show_renko_selection: false,
      show_chart: false,
      assist: !startTour,
      assistMode: false,
      showCreateAdvance,
      createAdvance,
      showAlert: false,
      gotAlgoState,
      // new
      conditionEditType: intialConditionEditType, // entry, exit, params
      showNameChange,
      showError: false, //! !edit || !!copy,
      longPressActive: false,
      selectedSymbols: new Set(),
      allSelected: false,
      scrollToRef: '',
      entryString,
      exitString,
      rawEntryStr: '',
      rawExitStr: '',
      algo_desc: '',
      // isFocused: false,
      showScripParams: false,
      max_allocation: '',
      max_allocationErr: false,
      max_quantity: '',
      max_quantityErr: false,
      tpsl_type: TPSL_TYPE.PERCENTAGE.value,
      daily_strategy_cycle: '-',
      position_sizing_type: '-',
      addScrips: false,
      readMore: false,
      showReadMore: false,
      stepsCompleted: new Set(),
      trailing_sl: '',
      trailing_slErr: false,

      copiedCondition: {},
      showWarning: false,
      userCanceledWarning: false,

      isMultiPage,
      invalidCandleIntervalEntry: false,
      invalidCandleIntervalExit: false,
      invalidCandleIntervalMultipleEntry: false,
      invalidCandleIntervalMultipleExit: false,
    }
    this.modifiedScrips = new Set()
    this.extraScrip = []
    this.sections = Object.values(ALGO_MODE)
    this.createPageViewSection = Object.values(CREATE_PAGE_VIEW_MAP)
    this.descRef = React.createRef()
    this.containerRef = React.createRef()
    this[`scrollRef${CONDITION_TYPE.ENTRY}`] = React.createRef()
    this[`scrollRef${CONDITION_TYPE.EXIT}`] = React.createRef()
    this[`scrollRef${CONDITION_TYPE.PARAMS}`] = React.createRef()
    this[`scrollRef${CONDITION_TYPE.POSITION}`] = React.createRef()
    this[`scrollRef${CONDITION_TYPE.ALGO_NAME}`] = React.createRef()
    this[`scrollRef${CONDITION_TYPE.QUANTITY}`] = React.createRef()
  }

  componentDidMount() {
    const {
      pairsFromSegSym,
      getAlgoState,
      changePtComponentStatus,
      startTour,
    } = this.props
    const { scripList, isMultiPage } = this.state
    const { algo_uuid, newAlgo } = this.routeParams
    window.addEventListener('beforeunload', this.handleUnload)
    if (startTour) { changePtComponentStatus('createMultiPage') }
    // if algoid is coming fetch saved algo
    if (algo_uuid && !newAlgo) {
      this.initialFetch = true
      getAlgoState(algo_uuid)
      const gaAction = isMultiPage ? 'Landed on multipage' : 'Landed on singlepage'
      this.updateGA(gaAction, 'AB_TESTING_CREATEPAGE')
    }

    // subscribe scrips if stocks are coming
    if (scripList.length && this.routeParams.tech_route) {
      pairsFromSegSym(scripList, NAVIGATIONS.CREATE.name)
    }
    // start auto save startegy
    if (newAlgo) this.startAutoSave(10000)

    // clearing browser state params to clear new flag (newAlgo) which is coming in state
    // so that is user refresh it shud fetch the algo
    window.history.replaceState({}, document.title)

    this.main = document.getElementById('main')
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      user_details,
      isDeployingAlgo,
      deployingAlgoError,
      deployAlgoResp,
      errorMsg,
      algoState,
      algoStateError,
      isFetchingState,
      clearAlgoStateResp,
      clearAlgoResp,
      newScripList,
      history,
      tree,
      fetchCreateInstruments,
      isDeleting,
      deleteAlgoResp,
      deleteAlgoError,
      deleteAlgoErrorMsg,
      isAlgoComplete,
      refreshAlgos,
      indicators,
      updateScripList,
      algo_live,
      styles,
      isFetchingAlgoFromToken,
      algoStateFromTokenErrorMsg,
      algoStateFromTokenError,
      algoStateFromTokenResp,
      csrf,
      deployAlgo,
      changePtComponentStatus,
      activeTourIndex,
      tourType,
      startTour,
      updateTourIndex,
    } = this.props
    const { copy, algo_uuid } = this.routeParams
    const {
      scripList, showNameChange, createAdvance, algoDesc, entryString, exitString,
      editMode, disabled, gotAlgoState, indicatorListUpdated: prevIndicatorsUpdated,
      isMultiPage, // treeUpdated,
      rawEntryStr = '', rawExitStr = '', showCreateAdvance,
    } = this.state
    const disabledCreateAdvanced = Boolean(showCreateAdvance)
      && !isFetchingState && !isDeployingAlgo

    // logic for blocking pivots inside mtf/mtfc when timeframe > day
    if (prevState.entryString !== entryString) {
      const result = checkBlockedMTFIndc(entryString)
      const { isMatched = false, matchedIndc = null, matchedPeriod = null } = result[0] || {}
      if (isMatched) {
        showSnackbar(`Cannot use ${matchedIndc}
     with ${matchedPeriod}, please select timeframe lower than equal to 'day'.`)
        return
      }
    }

    if (prevState.exitString !== exitString) {
      const result = checkBlockedMTFIndc(exitString)
      const { isMatched = false, matchedIndc = null, matchedPeriod = null } = result[0] || {}
      if (isMatched) {
        showSnackbar(`Cannot use ${matchedIndc}
     with ${matchedPeriod}, please select timeframe lower than equal to 'day'.`)
        return
      }
    }

    if (!gotAlgoState && prevProps.isFetchingState !== isFetchingState && !isFetchingState) {
      this.initialFetch = false
      if (algoState && algoState.entryIndicators) { // not a newAlgo
        const changes = copy
          ? { algo_name: '', algoNameValid: false }
          : {}
        const {
          algo_name,
          dt_start,
          dt_stop,
          candleInterval = '1 Hour',
          createAdvance: savedCreateAdvance = false,
          order_type = ORDER_TYPE.MARKET,
          conditionEditType: savedEditType = CONDITION_TYPE.POSITION,
          tpsl_type,
          holding_type: savedHoldingType,
        } = algoState
        if (algo_live && algo_uuid) {
          // DANGER
          showSnackbar('Live strategy cannot be edited', {}, 0)
          history.replace({
            pathname: pathname_mapping.Backtests,
            search: `?algo_uuid=${btoa(algo_uuid)}`,
          })
          // as when come from dashboard and strategy tab is not mounted to pop will not work
          //   goBack({ currentNavigation: navigation, bottomTab: BOTTOM_TABS.TAB_STRATEGY.id })
          // navigation.pop()
          //   navigation.navigate(NAVIGATIONS.BACKTESTS.name, {
          //     algo_uuid,
          //   })
        } else {
          const { indicatorListUpdated } = this.setCreateConditionState(savedCreateAdvance)
          if (!algo_name) {
            showSnackbar('Error fetching saved strategy', {}, 0)
          }
          let { trading_start_time = '00:00', trading_stop_time = '23:59' } = algoState
          if (/^\d{2} : \d{2} \w{2}$/.test(trading_start_time)) {
            trading_start_time = dayjs(trading_start_time, 'hh : mm A').format('HH:mm')
            trading_stop_time = dayjs(trading_stop_time, 'hh : mm A').format('HH:mm')
          } else if (!/^\d{2}:\d{2}$/.test(trading_start_time)) {
            trading_start_time = dayjs(trading_start_time).format('HH:mm')
            trading_stop_time = dayjs(trading_stop_time).format('HH:mm')
          }
          const {
            entryStr, exitStr, final_entry_str, final_exit_str,
          } = formEntryExitString({
            ...initialState, ...algoState, trading_stop_time, trading_start_time, tpsl_type,
          })
          const showWarning = savedCreateAdvance ? this.shouldShowWarning({
            exit_logic: exitStr,
          }) : false
          let mod_dt_stop = prevState.dt_stop
          let mod_dt_start = prevState.dt_start
          const time_frame = freq_candle_map[candle_freq_map[candleInterval]]
          const candle_allowed_time = date_range_epochs[time_frame] * 1000
          if ((!dt_start || !dt_stop) && candleInterval) {
            mod_dt_stop = dayjs().format(algoDateFormat)
            mod_dt_start = dayjs((dayjs().valueOf() - candle_allowed_time)).format(algoDateFormat)
          } else {
            const dtStartUnixTime = dayjs(dt_start, algoDateFormat).valueOf()
            const dtStopUnixTime = dayjs(dt_stop, algoDateFormat).valueOf() // milliseconds
            if ((dtStopUnixTime - dtStartUnixTime) > candle_allowed_time
              && !Number.isNaN(dtStopUnixTime)) {
              const newStartMoment = dayjs(dt_stop, algoDateFormat).valueOf() - candle_allowed_time
              mod_dt_start = dayjs(newStartMoment).format(algoDateFormat)
            } else if (!Number.isNaN(dtStopUnixTime) && !Number.isNaN(dtStartUnixTime)) {
              mod_dt_stop = dt_stop
              mod_dt_start = dt_start
            } else if (!Number.isNaN(dtStopUnixTime)) {
              mod_dt_stop = dt_stop
              const newStartMoment = dayjs(dt_stop, algoDateFormat).valueOf()
                - candle_allowed_time
              mod_dt_start = dayjs(newStartMoment).format(algoDateFormat)
            } else {
              mod_dt_stop = dayjs().format(algoDateFormat)
              mod_dt_start = dayjs((dayjs().valueOf()
                - candle_allowed_time)).format(algoDateFormat)
            }
          }
          if (algoState.scripList && algoState.scripList.length) {
            fetchCreateInstruments(algoState.scripList, NAVIGATIONS.CREATE.name)
          }
          const conditionEditType = !isMultiPage
            ? CONDITION_TYPE.PRO
            : this.routeParams.conditionEditType || savedEditType
          this.activeIndex = this.getStepIndex(conditionEditType)
          const scrip_list = algoState.scripList.length
            ? algoState.scripList : this.routeParams.length
              ? this.routeParams.stocks : scripList
          this.setState({
            ...algoState,
            positionValid: true,
            trading_start_time,
            trading_stop_time,
            gotAlgoState: true,
            editMode: true,
            showCreateAdvance: !copy && !isAlgoComplete,
            entryString: entryStr,
            exitString: exitStr,
            rawEntryStr: final_entry_str,
            rawExitStr: final_exit_str,
            dt_start: mod_dt_start,
            dt_stop: mod_dt_stop,

            createAdvance: savedCreateAdvance,
            disabled,
            showNameChange,
            showAlert: false,
            algoNameValid: !!algo_name,
            selectedSymbols: new Set(),
            longPressActive: false,
            allSelected: false,
            indicatorListUpdated,
            conditionEditType,
            holding_type: candleInterval === '1 Day' ? holding_type_map.CNC : savedHoldingType,
            order_type: Object.values(ORDER_TYPE).some(item => item === order_type.toUpperCase())
              ? order_type : ORDER_TYPE.MARKET,
            showWarning,
            scripList: scrip_list,
            ...changes,
          }, () => {
            this.updateCompletedSteps()
          })
          if (!copy && !isAlgoComplete) {
            this.startAutoSave(20000)
          }
        }
      } else if (algoState && algoState.algo_name) {
        // is a newAlgo new. so only name and id exist
        this.setState({
          algo_name: algoState.algo_name,
          algoNameValid: true,
          showCreateAdvance: true,
        }, () => this.startAutoSave(10000))
      } else if (algoStateError) {
        showSnackbar('Error fetching saved strategy', {}, 0)
        clearAlgoStateResp()
        this.setState({ gotAlgoState: true })
      }
    }
    if (algoDesc !== prevState.algoDesc || (!algoDesc
      && ((exitString !== prevState.exitString) || (entryString !== prevState.entryString))
    )) {
      this.shouldShowReadMore()
    }

    // algo saved runing backest
    if (prevProps.isDeployingAlgo !== isDeployingAlgo
      && !isDeployingAlgo) {
      if (this.runBacktest) {
        this.runBacktest = false
        if (deployingAlgoError) {
          showSnackbar(errorMsg || 'Error saving strategy', {}, 0)

          clearAlgoResp()
        } else if (
          deployAlgoResp.algo_uuid
        ) {
          this.shouldNotUnsubscribe = true
          const msg = editMode && !copy
            ? 'Strategy modified successfully'
            : 'Strategy created successfully'
          showSnackbar(msg, {}, 1)
          // as when come from dashboard and strategy tab is not mounted to pop will not work
          //   goBack({
          //     currentNavigation: navigation,
          // bottomTab: BOTTOM_TABS.TAB_STRATEGY.id, fromRouteName,
          //   })
          // navigation.pop()
          if (gaParamsMap.length) {
            gaParamsMap.map(item => this.updateGA(...item))
            gaParamsMap = []
          }
          history.push({
            pathname: pathname_mapping.Backtests,
            search: `?algo_uuid=${btoa(deployAlgoResp.algo_uuid)}`,
          }, {
            createAlgoParams: {
              scripList,
              algo_obj: {
                ...this.prevParams,
                algo_uuid: deployAlgoResp.algo_uuid,
                user_uuid: user_details.user_uuid,
              },
            },
            parentUrl: `${pathname_mapping.Create}?id=${btoa(deployAlgoResp.algo_uuid)}`,
            parentPageId: pathname_mapping.Create,
          })
          refreshAlgos('algos', true)
        }
      } else if (this.createNew) {
        if (deployingAlgoError) {
          showSnackbar(errorMsg || 'Error saving strategy', {}, 0)
          clearAlgoResp()
        } else if (deployAlgoResp.algo_uuid) {
          this.createNew = false
          let extraChange = {}
          if (this.algoName) {
            extraChange = { algoNameValid: true, algo_name: this.algoName }
            this.algoName = ''
          }
          if (this.newAlgoState) {
            const { entryStr, exitStr } = formEntryExitString({
              ...this.state,
              ...this.newAlgoState,
              entry_logic: this.newAlgoState.entryString,
              exit_logic: this.newAlgoState.exitString,
            })
            extraChange = {
              ...this.newAlgoState, entryString: entryStr, exitString: exitStr, ...extraChange,
            }
            this.newAlgoState = null
          }
          this.setState({
            algo_uuid: deployAlgoResp.algo_uuid,
            showNameChange: false,
            ...extraChange,
          }, () => {
            this.updateCompletedSteps()
          })
          routeToCreate({
            history,
            search: `?id=${btoa(deployAlgoResp.algo_uuid)}`,
            id: deployAlgoResp.algo_uuid,
          })
          // history.replace({
          //   pathname: pathname_mapping.Create,
          //   search: `?id=${btoa(deployAlgoResp.algo_uuid)}`,
          // })
          this.startAutoSave(10000)
        }
      }
    }

    if (!isFetchingAlgoFromToken && prevProps.isFetchingAlgoFromToken && this.algoName) {
      if (!isEmpty(algoStateFromTokenResp)) {
        const modState = { ...algoStateFromTokenResp }
        modState.algo_name = this.algoName
        modState.scripList = scripList
        modState.scripValid = scripList.length
        const params = {
          algo_uuid: '',
          algo_name: this.algoName,
          algo_desc: '',
          entry_logic: modState.entryString,
          exit_logic: '',
          csrfmiddlewaretoken: csrf,
          algo_state: JSON.stringify(modState),
        }
        deployAlgo(params)
        this.newAlgoState = modState
      } else if (algoStateFromTokenError) {
        showSnackbar(algoStateFromTokenErrorMsg, {}, 0)
      }
    }

    // new product tour
    if (startTour && prevProps.activeTourIndex !== activeTourIndex) {
      setTimeout(() => {
        changePtComponentStatus(`ptCreate_${Math.random()}`)
      }, 500)
    }

    if (startTour && prevProps.activeTourIndex === 3 && activeTourIndex === 2
      && tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY) {
      this.setState({ addScrips: false, scripList: [] })
    }

    if (startTour) {
      this.setState({ stopLoss: 4, takeProfit: 6 })
    }

    if (startTour && activeTourIndex === 4 && scripList.length) {
      updateTourIndex(3)
      this.setState({ addScrips: true, scripList: [] })
    }

    if (prevProps.startTour && startTour === false) {
      this.setState({ addScrips: false })
    }

    // next button logic for product tour
    if (startTour && prevProps.activeTourIndex !== activeTourIndex
      && activeTourIndex > prevProps.activeTourIndex) {
      if (tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY) {
        switch (activeTourIndex) {
          case 3: {
            this.addNewSymbol()
            this.toggleModal('addScrips')
            break
          }
          default: break
        }
      }
    }

    // previous logic for create strategy product tour
    if (startTour && prevProps.activeTourIndex !== activeTourIndex
      && activeTourIndex < prevProps.activeTourIndex) {
      if (tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY) {
        // previous button logic for strategy create
        if (activeTourIndex === 1) updateTourIndex(0)
        if (activeTourIndex === 5) {
          this.activeIndex = 0
          const { value } = STEP_CONDITION_TYPE[this.activeIndex]
          this.updateProgress(this.activeIndex - 1, value, true)
          this.updateGA(`${this.activeIndex}-next-1`, `Cstrategy page ${this.activeIndex + 1}`)
        }
        if (activeTourIndex === 6) {
          this.activeIndex = 0
          const { value } = STEP_CONDITION_TYPE[this.activeIndex]
          this.updateProgress(this.activeIndex - 1, value, true)
          this.updateGA(`${this.activeIndex}-next-1`, `Cstrategy page ${this.activeIndex + 1}`)
        }
        if (activeTourIndex === 8) {
          this.setState({ assist: false }, () => {
            const { assist } = this.state
            const gaLabel = assist ? 'On' : 'Off'
            this.updateGA('Toggle Assist', gaLabel)
          })
        }
        if (activeTourIndex === 9) {
          this.activeIndex = 1
          const { value } = STEP_CONDITION_TYPE[this.activeIndex]
          this.updateProgress(this.activeIndex - 1, value, true)
          this.updateGA(`${this.activeIndex}-next-1`, `Cstrategy page ${this.activeIndex + 1}`)
        }
        if (activeTourIndex === 10) {
          this.activeIndex = 2
          const { value } = STEP_CONDITION_TYPE[this.activeIndex]
          this.updateProgress(this.activeIndex - 1, value, true)
          this.updateGA(`${this.activeIndex}-next-1`, `Cstrategy page ${this.activeIndex + 1}`)
        }
      }
    }

    // adding new stocks
    if (newScripList && !isEqual(prevProps.newScripList, newScripList)) {
      this.setState({ scripValid: newScripList.length !== 0 },
        () => this.validityChecker())
      let showAdvanceAlert = false
      const newList = []
      newScripList.forEach((item) => {
        if (!createAdvance && item.segment && item.segment.toLowerCase() === ULTIMATE_SEGMENT_OBJ['nfo-opt']) {
          showAdvanceAlert = true
          this.extraScrip.push(item)
        } else {
          newList.push(item)
          this.modifiedScrips.add(item.seg_sym)
        }
      })
      if (showAdvanceAlert && disabledCreateAdvanced === false) {
        showSnackbar('To add Options to your list of instruments, create a new strategy in Advance mode.')
        return
      }
      if (showAdvanceAlert) {
        this.alertProps = {
          title: 'Switch to Advance',
          content: 'Options are only available in advance. You will lose all your progress once you switch',
          containerStyles: styles.alertContainer,
          contentStyles: styles.alertContent,
          titleStyles: styles.alertTitle,
          imgStyles: styles.alertImg,
          actionBarStyles: styles.alertActionBar,
          imgSrc: CREATE_SWITCH,
          actions: [
            {
              text: 'Switch Now',
              containerStyle: styles.alertActiveBtn,
              action: () => this.toggleCreateAdvance(true),
            },
          ],
        }
        this.setState({ showAlert: true, scripList: [...newList] })
      } else {
        this.setState({ scripList: [...newScripList] })
      }
      updateScripList(null)
    }

    // if new algo id comes
    // if (route && route.params && route.params.algo_uuid
    // && prevProps.route && prevProps.route.params
    //   && route.params.algo_uuid !== prevProps.route.params.algo_uuid) {
    //   if (this.autoSaveInterval) {
    //     clearInterval(this.autoSaveInterval)
    //   }
    //   getAlgoState(route.params.algo_uuid)
    //   //   this.routeParams = route.params
    //   this.setState({
    //     gotAlgoState: false,
    //     algo_name: route.params.algoName || '',
    //     entryString: '',
    //     exitString: '',
    //     entry_logic: '',
    //     exit_logic: '',
    //     scripList: [],
    //     stopLossErr: false,
    //     takeProfitErr: false,
    //     exitAndOrs: ['and'],
    //     entryAndOrs: ['and'],
    //     scripValid: false,
    //     algo_desc: '',
    //     allSelected: false,
    //     longPressActive: false,
    //     showError: false,
    //     stopLoss: '',
    //     takeProfit: '',
    //     ...initialState,
    //   })
    // }
    // update indicator (create basic)
    if (!createAdvance && !prevIndicatorsUpdated && indicators.main) {
      this.indicatorsList = indicators.main.indicator
      this.comparatorsList = indicators.main.comparator
      this.setState({ indicatorListUpdated: true })
    }

    if (!isEqual(exitString, prevState.exitString)) {
      this.checkForInvalidCandleInterval(rawExitStr, 'exit')
    }
    if (!isEqual(entryString, prevState.entryString)) {
      this.checkForInvalidCandleInterval(rawEntryStr, 'entry')
    }
    // update tree scanner
    if (!prevIndicatorsUpdated && createAdvance && tree.indicators) {
      this.indicatorsList = tree
      this.setState({ indicatorListUpdated: true })
    }
    if (prevProps.isDeleting !== isDeleting && !isDeleting) {
      if (deleteAlgoResp.status === 'success') {
        showSnackbar('Strategy deleted successfully', {}, 1)
        // DANGER
        history.replace(pathname_mapping.Strategies)
      } else if (deleteAlgoError) {
        showSnackbar(deleteAlgoErrorMsg, {}, 0)
      }
    }
  }

  componentWillUnmount = () => {
    const { clearAlgoStateResp, unSubscribe } = this.props
    clearAlgoStateResp()
    if (!this.shouldNotUnsubscribe) {
      unSubscribe(NAVIGATIONS.CREATE.name, Array.from(this.modifiedScrips))
    }
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval)
    }
    window.removeEventListener('beforeunload', this.handleUnload)
  }

  handleUnload(e) {
    const message = 'Changes that you made may not be saved.'
    if (e || window.event) {
      const ev = e || window.event
      ev.returnValue = message
    }
    return message
  }

  scrollToTop = () => {
    const main = document.getElementById('main')
    main.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
  }

  updateCompletedSteps = () => {
    const stepsCompleted = new Set()
    STEP_CONDITION_TYPE.forEach((itemObj, index) => {
      const { validTypes } = itemObj
      let isValid = true
      for (let i = 0;i < validTypes.length;i++) {
        isValid = isValid && this.validator(validTypes[i])
      }

      if (isValid) stepsCompleted.add(index)
    })

    this.setState({ stepsCompleted })
  }

  checkForInvalidCandleInterval = (indicatorsToCheck, type) => {
    const { candleInterval: candleIntervalSelected } = this.state
    const selectedIndex = candle_freq_map[candleIntervalSelected]
      || rev_candle[candleIntervalSelected]
    let oldInvalidCandleInterval = false
    let oldInvalidCandleIntervalMultiple = false
    const invalidMultipleType = type === 'entry' ? 'invalidCandleIntervalMultipleEntry' : 'invalidCandleIntervalMultipleExit'
    const invalidType = type === 'entry' ? 'invalidCandleIntervalEntry' : 'invalidCandleIntervalExit'
    let finalIndcators = indicatorsToCheck
    if (indicatorsToCheck.includes('Period min(')) {
      finalIndcators = indicatorsToCheck.replaceAll('Period min(', 'Periodmin(')
    }

    const strArr = finalIndcators.split(new RegExp('[-+()*/:?, ]', 'g'))

    const conditionTimeFrames = strArr
      .filter(e => tf_map.indexOf(e) !== -1)
      .map(tf => rev_candle[tf] || rev_mtf_candle[tf]).sort((a, b) => b - a)
    const smallestConditionTimeFrame = conditionTimeFrames[0]
    if (conditionTimeFrames.length) {
      if (smallestConditionTimeFrame > selectedIndex) {
        showSnackbar('Candle interval can not be higher than the smallest time frame in condition', { hideDelay: 5000 }, 0)
        oldInvalidCandleInterval = true
      }
      const multipleReturnVal = conditionTimeFrames.map((tf) => {
        if (candle_min_map[tf]
          % candle_min_map[selectedIndex] !== 0) {
          return 0
        }
        return 1
      }).sort((a, b) => a - b)[0]
      if (!multipleReturnVal && !oldInvalidCandleInterval) {
        showSnackbar('The time frame in condition is not a multiple of candle interval selected', multipleCiProps, 0)
        oldInvalidCandleIntervalMultiple = true
      }
    }
    this.setState({
      [invalidMultipleType]: oldInvalidCandleIntervalMultiple,
      [invalidType]: oldInvalidCandleInterval,
    })
  }

  checkForTimeFrameParams = (params = []) => {
    const hasTimeFrameParams = params.some(param => param[0] === 'candle' || param[0] === 'range')
    return hasTimeFrameParams
  }

  setCreateConditionState = (createAdvance, noUpdate) => {
    const {
      getTrainerModel, trainerModel = {}, tree, indicators, getIndicators,
      screenerTree, screenerTrainer, getScreenerTrainer, treeFetched,
    } = this.props
    let modChartType = noUpdate ? defaultChartList[0] : this.state.chart_type
    let indicatorListUpdated = false
    let entryIndicators = [{ ...indicatorPattern, id: Date.now() }]
    let exitIndicators = [{ ...indicatorPattern, id: Date.now() }]

    if (createAdvance) {
      if (!treeFetched) {
        screenerTree(this.headers)
        this.indicatorsList = fTree
      } else if (tree.indicators) {
        indicatorListUpdated = true
        this.indicatorsList = tree
      }
      if (!screenerTrainer.supertrend) {
        getScreenerTrainer({}, this.headers)
      }
      if (/Renko/i.test(modChartType)) {
        modChartType = defaultChartList[0]
      }
      entryIndicators = [{ ...screenerIndicatorPattern, id: Date.now() }]
      exitIndicators = [{ ...screenerIndicatorPattern, id: Date.now() }]
      this.chartList = defaultChartList.filter(cType => cType !== 'Renko')
    } else {
      if (!indicators.main) {
        getIndicators(this.headers)
        getTrainerModel(this.headers)
        this.indicatorsList = Indicators.main.indicator
        this.comparatorsList = Indicators.main.comparator
      } else {
        this.indicatorsList = indicators.main.indicator
        this.comparatorsList = indicators.main.comparator
        indicatorListUpdated = true
      }
      if (Object.keys(trainerModel).length === 0) {
        getTrainerModel(this.headers)
      }
      this.chartList = [...defaultChartList]
    }

    return {
      indicatorListUpdated, chart_type: modChartType, entryIndicators, exitIndicators,
    }
  }

  backPressHandler = (e, shouldReplace) => {
    const { history, location } = this.props
    // if (longPressActive) {
    //   this.toggleLongPress()
    // } else if (conditionEditType) {
    //   this.onSubmit()
    // } else {
    //   // if algo is half created or if it is not going to backtest page then save the algo
    //   if (this.autoSaveInterval && !this.shouldNotUnsubscribe) {
    //     this.runBacktest = false
    //     this.backTestSubmitHandler(true)
    //   }
    //   const { navigation } = this.props
    //   const { fromRouteName } = this.routeParams
    //   goBack({
    //     currentNavigation: navigation, bottomTab: BOTTOM_TABS.TAB_STRATEGY.id, fromRouteName,
    //   })
    // }
    const params = new URLSearchParams(history.location.search)
    const isaValue = params.get('isa')
    const decodedValue = atob(isaValue)
    if (decodedValue !== 'false') {
      history.push(pathname_mapping.Strategies)
    } else if (location.state && location.state.parentUrl) {
      history.push(location.state.parentUrl)
    } else if (shouldReplace) {
      history.replace(pathname_mapping.Dashboard)
    } else {
      history.goBack()
    }
    return true
  }

  getStepIndex = (conditionEditType) => {
    this.activeIndex = 0
    const len = STEP_CONDITION_TYPE.length
    for (let i = 0;i < len;i++) {
      const { value } = STEP_CONDITION_TYPE[i]
      if (value === conditionEditType) {
        this.activeIndex = i
        break
      }
    }
    return this.activeIndex
  }

  getConditionType = (index) => {
    const { value } = STEP_CONDITION_TYPE[index]
    return value
  }

  startAutoSave = (interval) => {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval)
    }
    this.autoSaveInterval = setInterval(() => {
      const { isFocused } = this.props
      if (isFocused) {
        this.backTestSubmitHandler()
      }
    }, interval)
  }

  handleAlgoNameChange = (e) => {
    const value = e.target.value
    let algoNameValid = false
    if (value.length && value.length < 41) {
      algoNameValid = true
    }
    if (value.length < 41) {
      this.setState({
        algo_name: value, algoNameValid, algoNameErr: !algoNameValid,
      })
    } else {
      showSnackbar('Strategy name cannot exceed 40 characters', 0)
    }
  }

  formattedScriplist = () => {
    const { scripList } = this.state
    const obj = {}
    scripList.forEach((scrip) => {
      const { symbol, segment } = scrip
      obj[symbol] = segment
    })
    return hexCodeGenerator(JSON.stringify(obj))
  }

  onChangeOption = (value, item, index, type) => {
    const { preferences, updatePreferences } = this.props
    const { createAdvance } = this.state
    switch (type) {
      case 'createMode': {
        if (value !== createAdvance) {
          this.toggleAlert()
        }
        break
      }

      case 'isMultiPage': {
        const activeConditionType = this.getConditionType(this.activeIndex)
        this.setState({
          isMultiPage: value,
          conditionEditType: !value ? CONDITION_TYPE.PRO : activeConditionType,
        }, () => {
          const gaAction = value ? 'Switched to multipage' : 'Switched to singlepage'
          this.updateGA(gaAction, 'AB_TESTING_CREATEPAGE')
          this.validityChecker(true)
          updatePreferences(!value, PREFERENCES.SINGLE_PAGE_MODE)
          setLocalStorage({
            [PREFERENCES.KEY]: {
              ...preferences,
              [PREFERENCES.SINGLE_PAGE_MODE]: !value,
            },
          }, true)
        })
        break
      }

      default:
        break
    }
  }

  toggleAlert = () => {
    const { styles } = this.props
    const { createAdvance } = this.state
    this.alertProps = {
      title: `Switch to ${createAdvance ? 'Basic' : 'Advance'}`,
      content: `You will lose all your progress once you switch to ${createAdvance ? 'Basic' : 'Advance'}`,
      containerStyles: styles.alertContainer,
      contentStyles: styles.alertContent,
      titleStyles: styles.alertTitle,
      imgStyles: styles.alertImg,
      actionBarStyles: styles.alertActionBar,
      imgSrc: CREATE_SWITCH,
      actions: [
        {
          text: 'Switch Now',
          containerStyle: styles.alertActiveBtn,
          action: () => this.toggleCreateAdvance(),
        },
      ],
    }
    this.setState({ showAlert: true })
  }

  nameChangeHandler = async ({ listName: name }) => {
    let algo_name_state
    const algo_name = name.trim()
    const { scripList } = this.state
    if (this.createNew) {
      const {
        csrf, deployAlgo, user_details, getAlgoFromToken,
      } = this.props
      let params = null
      if (algo_name) {
        if (this.routeParams.tokenId) {
          getAlgoFromToken({
            tokenId: this.routeParams.tokenId, broker_id: user_details.user_broker_id,
          }, { 'Content-type': 'application/json' })
          this.algoName = algo_name
          // return
          // const qparams = `?tokenId=${this.routeParams.tokenId}
          // &broker_id=${user_details.user_broker_id}`
          // const resp = await axios.get(`http://127.0.0.1:8080/fetch_deploy_algo${qparams}`, {
          //   'Content-type': 'application/json',
          // })
          // if (resp) {
          //   if (resp.status === 200 && resp.data) {
          //     const algoState = resp.data
          //     algoState.algo_name = algo_name
          //     params = {
          //       algo_uuid: '',
          //       algo_name,
          //       algo_desc: '',
          //       entry_logic: '',
          //       exit_logic: '',
          //       csrfmiddlewaretoken: csrf,
          //       algo_state: JSON.stringify(algoState),
          //     }
          //     this.setState({ ...algoState })
          //   } else {
          //     showSnackbar('Error getting data')
          //   }
          // }
          // deployAlgo(params)
          // this.algoName = algo_name
          return
        }
        const { andOrs } = this.state
        params = {
          algo_uuid: '',
          algo_name,
          algo_desc: '',
          entry_logic: '',
          exit_logic: '',
          csrfmiddlewaretoken: csrf,
          algo_state: JSON.stringify({
            algo_name,
            algo_desc: '',
            ...initialState,
            entryIndicators: [{
              ...indicatorPattern,
              id: Date.now(),
            }],
            entryAndOrs: andOrs,
            entryValid: false,
            scripList,
            scripValid: scripList.length > 0,
            exitIndicators: [{
              ...indicatorPattern,
              id: Date.now(),
            }],
          }),
        }

        deployAlgo(params)
        this.algoName = algo_name
      } else {
        showSnackbar('Enter a valid name to create strategy')
      }
      return
    }
    if (algo_name) {
      algo_name_state = { algoNameValid: true, algo_name }
    } else {
      algo_name_state = {
        algoNameValid: false,
        algo_name,
        disabled: true,
      }
    }

    this.setState({ ...algo_name_state, showNameChange: false }, () => this.validityChecker())
  }

  validator = (validType, extraState) => {
    const {
      quantity, scripValid, entryValid, takeProfit, stopLoss,
      trailing_sl,
      max_allocation, isMultiPage, max_quantity,
      tpsl_type,
    } = this.state
    const isValidFunc = {
      max_allocation: () => {
        if (!quantity && !max_allocation) {
          const MAREF = isMultiPage ? SCROLL_REF.PARAMETERS : CONDITION_TYPE.PARAMS
          this.updateScrollToRef(MAREF, extraState)
          return false
        }
        return true
      },
      max_quantity: () => {
        if (!quantity && !max_quantity) {
          const MAREF = isMultiPage ? SCROLL_REF.PARAMETERS : CONDITION_TYPE.PARAMS
          this.updateScrollToRef(MAREF, extraState)
          return false
        }
        return true
      },
      quantity: () => {
        if (!quantity && !max_allocation) {
          const QTYREF = isMultiPage ? SCROLL_REF.QUANTITY : CONDITION_TYPE.QUANTITY
          this.updateScrollToRef(QTYREF, extraState)
          return false
        }
        return true
      },
      tsl: () => {
        if (trailing_sl !== '') {
          if (tpsl_type === 'pct') {
            if (trailing_sl !== '' && (trailing_sl > 0 && trailing_sl < 0.1)) {
              extraState = {
                ...extraState,
                trailing_slErr: !Number(trailing_sl),
                exitValid: false,
              }
              this.updateScrollToRef(SCROLL_REF.EXIT, extraState)
              return false
            }
          } else if (tpsl_type === 'abs') {
            if (trailing_sl !== '' && (trailing_sl > 0 && trailing_sl < 100)) {
              extraState = {
                ...extraState,
                trailing_slErr: !Number(trailing_sl),
                exitValid: false,
              }
              this.updateScrollToRef(SCROLL_REF.EXIT, extraState)
              return false
            }
          } else if (tpsl_type === 'pts') {
            if (trailing_sl !== '' && (trailing_sl > 0 && trailing_sl < 1)) {
              extraState = {
                ...extraState,
                trailing_slErr: !Number(trailing_sl),
                exitValid: false,
              }
              this.updateScrollToRef(SCROLL_REF.EXIT, extraState)
              return false
            }
          }
        }
        return true
      },
      scripList: () => {
        if (!scripValid) {
          const SCRIPLREF = isMultiPage ? SCROLL_REF.QUANTITY : CONDITION_TYPE.POSITION
          // showSnackbar('Enter at least one stock to continue')
          // this.addNewSymbol()
          this.updateScrollToRef(SCRIPLREF, extraState)
          return false
        }
        return true
      },
      entry: () => {
        const ENTRYREF = isMultiPage ? SCROLL_REF.ENTRY : CONDITION_TYPE.ENTRY
        if (!entryValid) {
          this.updateScrollToRef(ENTRYREF, extraState)
          return false
        }
        return true
      },
      tp: () => {
        const EXITREF = isMultiPage ? SCROLL_REF.EXIT : CONDITION_TYPE.EXIT
        if (!Number(takeProfit)) {
          extraState = {
            ...extraState,
            takeProfitErr: !Number(takeProfit),
            exitValid: false,
          }
          this.updateScrollToRef(EXITREF, extraState)
          return false
        }
        if (takeProfit === 0) {
          extraState = {
            ...extraState,
            takeProfitErr: true,
            exitValid: false,
          }
          this.updateScrollToRef(EXITREF, extraState)
          return false
        }
        return true
      },
      sl: () => {
        const EXITREF = isMultiPage ? SCROLL_REF.EXIT : CONDITION_TYPE.EXIT
        if (!Number(stopLoss)) {
          extraState = {
            ...extraState,
            stopLossErr: !Number(stopLoss),
            exitValid: false,
          }
          this.updateScrollToRef(EXITREF, extraState)
          return false
        }
        if (stopLoss === 0) {
          extraState = {
            ...extraState,
            stopLossErr: true,
            exitValid: false,
          }
          this.updateScrollToRef(EXITREF, extraState)
          return false
        }
        return true
      },
    }
    return isValidFunc[validType]()
  }

  validityChecker = (showError) => {
    const {
      algoNameValid,
      isMultiPage,
    } = this.state
    let isValid = true
    let extraState = {}
    if (showError) {
      extraState = { showError }
    }

    if (!algoNameValid) {
      showSnackbar('Enter strategy name to continue')
      this.toggleNameChangeModal()
      return { isValid: false, inCompletedFields: new Set() }
    }
    const inCompletedFields = new Set()
    const currentStep = isMultiPage ? this.activeIndex : 3
    const { validTypes } = STEP_CONDITION_TYPE[currentStep]
    for (let i = 0;i < validTypes.length;i++) {
      const isFieldValid = this.validator(validTypes[i], extraState)
      if (!isFieldValid) inCompletedFields.add(validTypes[i])
      isValid = isValid && isFieldValid
    }
    // stopLoss, takeProfit, scripValid, 'lolvalidd')
    return { isValid, inCompletedFields }
  }

  // updateScrollToRef = (scrollToRef, extraState = {}) => {
  //   this.setState(extraState)
  //   // this.setState({ scrollToRef, ...extraState })
  // }

  updateScrollToRef = debounce((scrollToRef, extraState = {}) => {
    // this.setState(extraState)
    const scrollEle = this[`scrollRef${scrollToRef}`]
    if (scrollEle && scrollEle.current && this.main && extraState.showError) {
      this.main.scrollTo({ behavior: 'smooth', top: scrollEle.current.offsetTop - SPACING.HEADER_HEIGHT - 40 })
    }
    this.setState({ scrollToRef, ...extraState })
  }, 300)

  toggleNameChangeModal = () => {
    this.setState((prevState) => {
      if (prevState.showNameChange && this.createNew) {
        this.backPressHandler({}, true)
      }
      return { showNameChange: !prevState.showNameChange }
    })
  }

  deleteAlgo = () => {
    const {
      csrf, deleteAlgo, isDeleting,
    } = this.props
    const { algo_uuid } = this.routeParams
    if (isDeleting) return
    const params = { algo_uuid, csrfmiddlewaretoken: csrf }
    deleteAlgo(params)
  }

  confirmRemoveSymbols = () => {
    const { scripList, selectedSymbols } = this.state
    const symbolsArr = Array.from(selectedSymbols)
    const modScripList = scripList.filter((item) => {
      const { segment, symbol, seg_sym } = item
      let segSym = seg_sym
      if (!item.seg_sym) {
        segSym = `${segment}_${symbol}`
      }
      const shouldRemove = symbolsArr.includes(segSym)
      if (shouldRemove) this.modifiedScrips.add(segSym)
      return !shouldRemove
    })
    this.setState({
      scripList: modScripList,
      removeSymbolsModal: false,
      selectedSymbols: new Set(),
      allSelected: false,
      longPressActive: false,
    })
  }

  renderRemoveSymbolConfirmation = () => {
    // const { styles } = this.props
    // return (
    //   <View style={styles.remove}>
    //     <View style={styles.removeHeader}>
    //       <Icon name={ICONS.DELETE} size={13} color={COLORS.RED} />
    //       <CustomText weight="semi_bold">{'  Delete Stocks'}</CustomText>
    //     </View>
    //     <View style={styles.removeContent}>
    //       <CustomText style={styles.removeContentText} size="tiny" weight="regular">
    // Are you sure you want to delete these stocks. if you delete the stocks,
    // you will permanently lose all the data. You might have to add the stocks again to backtest.
    // </CustomText>
    //       <Button
    //         text="Delete"
    //         buttonColor={COLORS.RED}
    //         onPress={this.confirmRemoveSymbols}
    //         labelColor={COLORS.WHITE}
    //         mode="contained"
    //         roundness={6}
    //       />
    //       <Button
    //         text="Cancel"
    //         buttonColor={COLORS.BLUE}
    //         onPress={this.toggleModal}
    //         params="removeSymbolsModal"
    //         contentStyle={styles.removeCancelBtn}
    //         labelStyle={styles.removeCancelBtnText}
    //         containerStyle={{
    //           marginTop: SPACING.SPACE_10,
    //           marginBottom: SPACING.SPACE_28,
    //           alignSelf: 'center',
    //         }}
    //         disableRipple
    //       />
    //     </View>
    //   </View>
    // )
  }

  removeSymbols = () => {
    const { selectedSymbols, edit } = this.state
    if (selectedSymbols.size === 0) {
      showSnackbar('Select stocks to remove')
      return
    }
    // if editing algo then warn user that his backtest will be deleted
    if (edit) {
      this.toggleModal('removeSymbolsModal')
    } else {
      this.confirmRemoveSymbols()
    }
  }

  actionHandler = (actionType) => {
    switch (actionType) {
      case menuOptions[0].value: {
        this.toggleNameChangeModal()
        break
      }

      case menuOptions[1].value: {
        this.deleteAlgo()
        break
      }

      default:
        break
    }
  }

  updateProgress = (oldIndex, value, isPageParamsValid) => {
    const { stepsCompleted } = this.state
    const modStepsCompleted = new Set(stepsCompleted)
    if (isPageParamsValid) {
      modStepsCompleted.add(oldIndex)
    } else if (modStepsCompleted.has(oldIndex)) {
      modStepsCompleted.delete(oldIndex)
    }
    this.setState({ conditionEditType: value, stepsCompleted: modStepsCompleted }, () => {
      this.backTestSubmitHandler()
      this.scrollToTop()
    })
  }

  onHeaderNext = () => {
    const { isMultiPage, position_sizing_type, max_allocation } = this.state
    if (!isMultiPage) {
      this.onSaveNRun()
      return
    }
    if (position_sizing_type === 'Risk based' && max_allocation === '') {
      showSnackbar('Please enter Max SL per trade.')
      return
    }
    if (position_sizing_type === 'Capital based' && max_allocation === '') {
      showSnackbar('Please enter Max Allocation.')
      return
    }
    this.updateStep('header')
  }

  updateStep = (pos) => {
    const { tpsl_type } = this.state
    const len = STEP_CONDITION_TYPE.length - 1
    const { isValid: isPageParamsValid, inCompletedFields } = this.validityChecker(true)
    if (!isPageParamsValid) {
      const fields = Array.from(inCompletedFields)
      if (fields.length) {
        // const label = fields.join(', ') === 'scripList' ? 'Instruments' : fields.join(', ')
        fields.forEach((item, index) => {
          // showSnackbar(`${label} ${fields.length > 1 ? 'fields' : 'field'} has invalid input`)
          if (item === 'tsl') {
            if (tpsl_type === 'pct') {
              const errorMsg = VALID_TYPE_ERROR.tslPct
              const errText = `${errorMsg}` || `${item} has invalid input`
              showSnackbar(errText, { hideDelay: 5000 }, -1, index)
            } else if (tpsl_type === 'abs') {
              const errorMsg = VALID_TYPE_ERROR.tslAbs
              const errText = `${errorMsg}` || `${item} has invalid input`
              showSnackbar(errText, { hideDelay: 5000 }, -1, index)
            } else {
              const errorMsg = VALID_TYPE_ERROR.tslPts
              const errText = `${errorMsg}` || `${item} has invalid input`
              showSnackbar(errText, { hideDelay: 5000 }, -1, index)
            }
          } else {
            const errText = `${VALID_TYPE_ERROR[item]}` || `${item} has invalid input`
            showSnackbar(errText, { hideDelay: 5000 }, -1, index)
          }
        })
      }
      return
    }

    this.activeIndex += 1
    if (this.activeIndex > len) {
      this.activeIndex = len
      this.runBacktest = true
      this.backTestSubmitHandler(true)
    } else {
      const { value } = STEP_CONDITION_TYPE[this.activeIndex]
      this.updateProgress(this.activeIndex - 1, value, isPageParamsValid)
      this.updateGA(`${this.activeIndex}-next-${pos === 'header' ? '1' : '2'}`, `Cstra page ${this.activeIndex + 1}`)
    }
  }

  headerActionBarRenderer = () => {
    const {
      styles, isDeployingAlgo, isFetchingState, isDark, isMobile,
    } = this.props
    const {
      conditionEditType, createAdvance, showCreateAdvance, isMultiPage,
    } = this.state
    const canRunBt = conditionEditType === CONDITION_TYPE.REVIEW
      || conditionEditType === CONDITION_TYPE.PRO
    const isBtnDisabled = isDeployingAlgo || isFetchingState
    const btnText = !isMultiPage ? 'Save and Backtest' : isMultiPage && canRunBt ? 'Save and Backtest' : 'Next'
    const disabledCreateAdvanced = Boolean(showCreateAdvance)
      && !isFetchingState && !isDeployingAlgo

    return (
      <div className={styles.headerActionBar}>
        {/* <SectionTab
          tabs={this.createPageViewSection}
          activeTab={isMultiPage}
          tabChangeHandler={this.onChangeOption}
          style={styles.sectionTabContainer}
          showLabel={!isMobile}
          activeTabColor={isDark ? 'white' : 'blue'}
          tabKey="isMultiPage"
        /> */}
        {/* {Boolean(showCreateAdvance) && !isFetchingState && !isDeployingAlgo && ( */}
        <SectionTab
          tabs={this.sections}
          activeTab={createAdvance} // ? ALGO_MODE.ADVANCE.value : ALGO_MODE.BASIC.value}
          tabChangeHandler={this.onChangeOption}
          style={styles.sectionTabContainer}
          showLabel={!isMobile}
          activeTabColor={isDark ? 'white' : 'blue'}
          tabKey="createMode"
          disabledTab={!disabledCreateAdvanced}
          disabledTabText="Switching between modes is disabled once a strategy is saved"
        />
        {/* )} */}
        {!isMobile && (
          <Button
            text={btnText}
            onPress={this.onHeaderNext}
            disabled={isBtnDisabled}
            loading={isDeployingAlgo || isFetchingState}
            buttonColor="blue"
          />
        )}
      </div>
    )
  }

  togglePositions = (positionType) => {
    const { startTour, updateGA } = this.props
    if (startTour) updateGA('Position Toggle', 'Ct Strat Pg 8', 1, 'Product Tour')
    this.setState({ positionType })
  }

  addNewSymbol = (viewAll) => {
    const {
      blockedSegmentList, updateTourIndex, changePtComponentStatus,
      startTour, activeTourIndex, updateGA,
    } = this.props
    const { scripList } = this.state
    if (!viewAll && scripList.length >= STOCK_ADD_LIMIT) {
      showSnackbar(`You cannot add more than ${STOCK_ADD_LIMIT} stocks`)
      return
    }
    this.addScripParams = {
      defaultList: scripList.map((item) => {
        if (item.seg_sym) {
          return { ...item, disableRemove: false }
        }
        return { ...item, seg_sym: `${item.segment}_${item.symbol}`, disableRemove: false }
      }),
      parentPageId: NAVIGATIONS.CREATE.name,
      blockedSegmentList,
    }
    if (startTour) updateGA('Button Click', 'Ct Strat Pg 3', 1, 'Product Tour')
    if (!startTour) { this.toggleModal('addScrips') }
    let gaLabel = `Add stocks ${this.activeIndex + 1}`
    if (viewAll) {
      gaLabel = 'View all'
    }
    this.updateGA('Add stocks', gaLabel, 1, this.routeParams.gaType === 'Edit' ? 'Edit strategy' : '')
    if (startTour && activeTourIndex === 2) {
      updateTourIndex(3)
      setTimeout(() => changePtComponentStatus('symbolFinder'), 1000)
    }
  }

  onEditCondition = (conditionEditType) => {
    const { isFetchingState } = this.props
    if (isFetchingState) {
      return
    }
    this.activeIndex = this.getStepIndex(conditionEditType)
    this.setState({ conditionEditType })
  }

  // used in app only
  toggleEditParams = () => {
    const {
      user_details, isFetchingState, isDeployingAlgo, navigation,
    } = this.props
    const {
      quantity,
      candleInterval,
      dt_start,
      dt_stop,
      trading_start_time,
      trading_stop_time,
      chart_type,
      algo_desc,
      holding_type,
      createAdvance,
      max_allocation,
      max_allocationErr,
      max_quantity,
      max_quantityErr,
      tpsl_type,
      daily_strategy_cycle,
      position_sizing_type,
    } = this.state
    if (this.runBacktest || isFetchingState) return
    navigation.navigate(NAVIGATIONS.BACKTEST_EDIT_PARAMS.name, {
      parentPage: NAVIGATIONS.CREATE.name,
      user_details,
      isDeployingAlgo,
      algo_desc,
      details: {
        quantity,
        time_frame: candle_map[candleInterval],
        dt_start,
        dt_stop,
        initial_capital: '10000000',
        trading_start_time,
        trading_stop_time,
        chart_type,
        holding_type,
        create_plus: createAdvance,
        max_allocation,
        max_allocationErr,
        max_quantity,
        max_quantityErr,
        tpsl_type,
        daily_strategy_cycle,
        position_sizing_type,
      },
    })
  }

  onEditParams = (editParams, stepChange) => {
    const {
      quantity,
      time_frame,
      dt_start,
      dt_stop,
      initial_capital,
      trading_start_time,
      trading_stop_time,
      chart_type,
      holding_type,
      algo_desc,
      // tpsl_type, some params are there inside condition renderer
      max_allocation,
      max_quantity,
      daily_strategy_cycle,
      position_sizing_type,
    } = editParams
    const showWarning = this.shouldShowWarning({ max_allocation })
    this.setState({
      quantity,
      candleInterval: time_frame,
      dt_start,
      dt_stop,
      initial_capital,
      trading_start_time,
      trading_stop_time,
      chart_type,
      holding_type,
      // not using in web
      // disabled: this.validityChecker(false, true),
      algo_desc,
      // tpsl_type,
      max_allocation,
      max_quantity,
      daily_strategy_cycle,
      position_sizing_type,
      showWarning,
    }, () => {
      this.onSubmit()
      if (stepChange) {
        this.updateStep()
      }
    })
  }

  getAllowedConditions = () => {
    const { user_details = {} } = this.props
    switch (user_details.subscription_type) {
      case 1:
        return 5
      case 2:
        return 7
      case 2.5:
        return 7
      case 3:
        return 10
      default:
        return 5
    }
  }

  handleConditionAddition = (expressions, editType) => {
    const { createAdvance } = this.state
    const indicatorKey = `${editType}Indicators`
    const andOrsKey = `${editType}AndOrs`
    const andOr = this.state[andOrsKey]
    if (expressions.length === this.getAllowedConditions()) {
      showSnackbar('You have reached maximum conditions limit')
    } else {
      const modifiedData = [...expressions]
      const modAndOr = [...andOr, 'and']
      const defaultPattern = createAdvance
        ? { ...screenerIndicatorPattern } : { ...indicatorPattern }
      defaultPattern.id = Date.now()
      modifiedData.push(defaultPattern)
      this.setState({ [indicatorKey]: modifiedData, [andOrsKey]: modAndOr })
    }
  }

  handleAndOrChange = (editType, index, value) => {
    const field = `${editType}AndOrs`
    const andors = this.state[field]
    andors[index] = value
    const newAndOrs = [...andors]
    this.setState({ [field]: newAndOrs }, this.onSubmit)
  }

  conditionsValid = (expression, editType, andOr, isConditionValid, extraChanges = {}) => {
    this.setState(prevState => ({
      [`${editType}Indicators`]: expression,
      [`${editType}Valid`]: isConditionValid !== undefined ? isConditionValid : prevState[`${editType}Valid`],
      [`${editType}AndOrs`]: andOr,
      ...extraChanges,
    }), () => {
      const { createAdvance, max_allocation } = this.state
      if (this.state[`${editType}Valid`]) this.onSubmit()
      else if (createAdvance && !extraChanges.showWarning) {
        const showWarning = this.shouldShowWarning({
          exit_logic: editType === CONDITION_TYPE.EXIT ? expression : '', max_allocation,
        })
        this.setState({ showWarning })
      }
      if (!createAdvance) {
        this.validityChecker()
      }
    })
  }

  toggleExitConditionText = () => {
    const {
      positionType,
      assist,
      entryIndicators,
      stopLoss,
      takeProfit,
      createAdvance,
    } = this.state
    if (!Number(stopLoss) || !Number(takeProfit)) {
      showSnackbar(`${!Number(stopLoss) ? 'Stop loss' : 'Take profit'} has invalid value`, {}, 0)
      this.setState({
        takeProfitErr: !Number(takeProfit), stopLossErr: !Number(stopLoss), exitValid: false,
      })
      return
    }
    const { trainerModel } = this.props
    this.setState((prevState) => {
      let exitIndi = [{ ...indicatorPattern, id: Date.now() }]
      if (createAdvance) {
        exitIndi = [{ ...screenerIndicatorPattern, id: Date.now() }]
      } else if (!prevState.showExitIndicator && assist
        && entryIndicators[0] && entryIndicators[0].assisted
        && entryIndicators[0].indicatorItem && trainerModel[entryIndicators[0].indicatorItem.class]
        && trainerModel[entryIndicators[0].indicatorItem.class][positionType.toUpperCase()][0]
          .exitIndicators.length) {
        exitIndi = trainerModel[entryIndicators[0]
          .indicatorItem.class][positionType.toUpperCase()][0]
          .exitIndicators
      }
      return {
        showExitIndicator: !prevState.showExitIndicator,
        exitIndicators: exitIndi,
      }
    })
  }

  toggleAssist = () => {
    const {
      startTour, updateTourIndex, activeTourIndex, updateGA,
      tourType,
    } = this.props
    const { assist } = this.state
    const gaLabelPT = tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY ? 'Ct Strat Pg 9' : 'Ct Scan Pg 5'
    if (startTour) {
      updateGA('Assist Toggle', gaLabelPT, 1, 'Product Tour')
      updateTourIndex(activeTourIndex + 1)
    }
    this.setState((prevState) => {
      return { assist: !prevState.assist }
    }, () => {
      const gaLabel = assist ? 'On' : 'Off'
      this.updateGA('Toggle Assist', gaLabel)
    })
  }

  slTpValidity = (sltp) => {
    const num = Number(sltp)
    // limit is there in conditionRenderer
    if (num <= 0 || num > 1000) {
      return false
    }
    return true
  }

  getMinCandleFreq = () => {
    const { entryIndicators, exitIndicators } = this.state
    let min_candle_freq = 10
    for (let i = 0;i < entryIndicators.length;i++) {
      const { selected_candle_interval } = entryIndicators[i]
      if (
        selected_candle_interval
        && (min_candle_freq < selected_candle_interval || min_candle_freq === 10)
      ) {
        min_candle_freq = selected_candle_interval
      }
    }
    for (let i = 0;i < exitIndicators.length;i++) {
      const { selected_candle_interval } = exitIndicators[i]
      if (
        selected_candle_interval
        && (min_candle_freq < selected_candle_interval || min_candle_freq === 10)
      ) {
        min_candle_freq = selected_candle_interval
      }
    }
    return min_candle_freq
  }

  showSnackbar = (msg) => {
    this.runBacktest = false
    showSnackbar(msg)
  }

  onSaveNRun = () => {
    this.runBacktest = true
    this.backTestSubmitHandler(true)
  }

  backTestSubmitHandler = (noUpdate) => {
    const {
      // disabled, // false means run backtest
      positionType,
      quantity,
      max_allocation,
      max_quantity,
      tpsl_type,
      daily_strategy_cycle,
      position_sizing_type,
      stopLoss,
      takeProfit,
      algo_name,
      candleInterval,
      entryIndicators,
      exitIndicators,
      chart_type,
      trading_start_time,
      trading_stop_time,
      entryAndOrs,
      exitAndOrs,
      createAdvance,
      dt_stop,
      dt_start,
      algo_desc,
      holding_type,
      order_type,
      scripList,
      assist,
      trailing_sl,
      invalidCandleIntervalEntry,
      invalidCandleIntervalExit,
      invalidCandleIntervalMultipleEntry,
      invalidCandleIntervalMultipleExit,
      conditionEditType,
      initial_capital,
      entryString: logicEntry,
      exitString: logicExit,
    } = this.state
    const {
      user_details = {}, csrf, deployAlgo, toggleAuthGreeting,
    } = this.props
    const {
      edit, algo_uuid, copy, sample,
    } = this.routeParams
    const {
      status, subscription_valid, subscription_type,
    } = user_details
    let runBacktest = false
    if (!subscription_valid) {
      toggleAuthGreeting(PRO_FEATURE_MAP.MTF.ID)
      return
    }
    const expiryDate = dayjs.utc('1 March 2020')
    if (this.runBacktest) {
      let type
      const { isValid, inCompletedFields } = this.validityChecker(true)
      type = isValid
      const invalidFields = Array.from(inCompletedFields)
      if (invalidFields.length) {
        invalidFields.forEach((item, index) => {
          if (item === 'tsl') {
            if (tpsl_type === 'pct') {
              const errorMsg = VALID_TYPE_ERROR.tslPct
              const errText = `${errorMsg}` || `${item} has invalid input`
              showSnackbar(errText, { hideDelay: 5000 }, -1, index)
            } else if (tpsl_type === 'abs') {
              const errorMsg = VALID_TYPE_ERROR.tslAbs
              const errText = `${errorMsg}` || `${item} has invalid input`
              showSnackbar(errText, { hideDelay: 5000 }, -1, index)
            } else {
              const errorMsg = VALID_TYPE_ERROR.tslPts
              const errText = `${errorMsg}` || `${item} has invalid input`
              showSnackbar(errText, { hideDelay: 5000 }, -1, index)
            }
          } else {
            const errText = `${VALID_TYPE_ERROR[item]}` || `${item} has invalid input`
            showSnackbar(errText, { hideDelay: 5000 }, -1, index)
          }
        })
        this.runBacktest = false
      }
      if (parseFloat(quantity) === 0) {
        showSnackbar(VALID_TYPE_ERROR.qty, 'error')
        this.runBacktest = false
        return
      }
      if (parseFloat(max_allocation) === 0) {
        showSnackbar(VALID_TYPE_ERROR.max, 'error')
        return
      }
      if (parseFloat(initial_capital) === 0) {
        showSnackbar(VALID_TYPE_ERROR.ic, 'error')
        return
      }
      if (invalidFields.includes(CONDITION_TYPE.QUANTITY)
        && invalidFields.includes(CONDITION_TYPE.MAX_ALLOC)) {
        showSnackbar('Please enter valid values for quantity or max allocation')
        this.runBacktest = false
        return
      } if (invalidFields.includes(CONDITION_TYPE.QUANTITY)) {
        showSnackbar('Please enter quantity')
        this.runBacktest = false
        return
      }
      runBacktest = true
      this.setState({ showError: true })
      if (status === 'success') {
        if (subscription_valid === false) {
          // this.showSnackbar('Your subscription is over, please renew to continue')
          this.showSnackbar('Backtest limit reached.')
          this.runBacktest = false
          return
        }
        if (
          subscription_type < 3
          && /Renko/i.test(chart_type)
        ) {
          toggleAuthGreeting(PRO_FEATURE_MAP.RENKO.ID)
          this.runBacktest = false
          return
        }
      }
      if (tpsl_type === 'pct') {
        type = this.slTpValidity(stopLoss)
        if (!type) {
          this.showSnackbar(VALID_TYPE_ERROR.slLimit)
          this.updateScrollToRef(CONDITION_TYPE.EXIT, { showError: true })
          this.runBacktest = false
          return
        }
        type = this.slTpValidity(takeProfit)
        if (!type) {
          this.showSnackbar(VALID_TYPE_ERROR.tpLimit)
          this.runBacktest = false
          return
        }
      }
      // if (invalidFields.includes(CONDITION_TYPE.SCRIPLIST)) {
      //   showSnackbar('Please add scrips to continue')
      //   this.runBacktest = false
      //   return
      // }
      if (!type) {
        showSnackbar('Please complete strategy details')
        this.runBacktest = false
        return
      }
      if (invalidCandleIntervalEntry || invalidCandleIntervalExit) {
        showSnackbar("Please keep the timeframes in conditions 'higher than equal to' to the candle interval selected", { hideDelay: 5000 })
        this.runBacktest = false
        return
      }
      if (invalidCandleIntervalMultipleEntry || invalidCandleIntervalMultipleExit) {
        showSnackbar('The time frame in condition is not a multiple of candle interval selected', multipleCiProps)
        this.runBacktest = false
        return
      }
    } else if (!algo_name) {
      this.runBacktest = false
      return
    } else if (dayjs(dt_start, algoDateFormat).isSameOrAfter(dayjs(dt_stop, algoDateFormat))) {
      showSnackbar('Start date cannot be greater that stop date', {}, 0)
      this.runBacktest = false
      return
    } else {
      this.runBacktest = false
    }

    // logic for blocking pivots inside mtf/mtfc when timeframe > day
    if (logicEntry) {
      const result = checkBlockedMTFIndc(logicEntry)
      const { isMatched = false, matchedIndc = null, matchedPeriod = null } = result[0] || {}
      if (isMatched) {
        showSnackbar(`Cannot use ${matchedIndc}
     with ${matchedPeriod}, please select timeframe lower than equal to 'day'.`)
        return
      }
    }

    if (logicExit) {
      const result = checkBlockedMTFIndc(logicExit)
      const { isMatched = false, matchedIndc = null, matchedPeriod = null } = result[0] || {}

      if (isMatched) {
        showSnackbar(`Cannot use ${matchedIndc}
     with ${matchedPeriod}, please select timeframe lower than equal to 'day'.`)
        return
      }
    }

    let isDynamicContractExist = false
    scripList.forEach((scrip) => {
      if (scrip.details || (scrip.symbol && scrip.symbol.includes('DYNAMIC CONTRACT'))) {
        isDynamicContractExist = true
      }
    })

    scripList.some((scrip) => {
      if (scrip.details || (scrip.symbol && scrip.symbol.includes('DYNAMIC CONTRACT'))) {
        if (dayjs(dt_start, algoDateFormat).isBefore(expiryDate)) {
          showSnackbar('Backtesting on expired contracts is only enabled from 1st Mar 2020')
          this.runBacktest = false
          return true
        }
      }
      return false
    })

    let extraAdvanceParams = {}
    let entryString = ''
    let exitString = ''
    const modEntryAndOrs = entryAndOrs
    const modEntryIndicators = entryIndicators
    const modExitAndOrs = exitAndOrs
    const modExitIndicators = exitIndicators
    const conditionLen = { entry: 0, exit: 0 }
    if (createAdvance) {
      if (this.runBacktest) {
        // if user adds a condition which is empty
        // removing extra condition, and/or
        const lastEle = modEntryIndicators.slice(-1)[0]
        if (lastEle && lastEle.conditions && lastEle.conditions.length === 0) {
          modEntryIndicators.pop()
          modEntryAndOrs.pop()
        }
        const lastExitEle = modExitIndicators && modExitIndicators.slice(-1)[0]
        if (modExitIndicators.length > 1 && lastExitEle
          && lastExitEle.conditions && lastExitEle.conditions.length === 0) {
          modExitIndicators.pop()
          modExitAndOrs.pop()
        }
      }
      entryString = screenerLogicStringGenerator(modEntryIndicators, modEntryAndOrs)
      exitString = modExitIndicators[0].conditions
        && screenerLogicStringGenerator(modExitIndicators, modExitAndOrs)
      extraAdvanceParams = {
        ...modifyAdvancedParams({
          max_allocation,
          max_quantity,
          tpsl_type,
          daily_strategy_cycle,
          position_sizing_type,
        }),
      }
      conditionLen.entry = modEntryIndicators.length
      conditionLen.exit = modExitIndicators[0].conditions && modExitIndicators[0].conditions.length
        ? modExitIndicators.length : 0
    } else {
      entryString = logicToStringGenerator(entryIndicators, entryAndOrs)
      exitString = logicToStringGenerator(exitIndicators, exitAndOrs)
      conditionLen.entry = modEntryIndicators.length
      conditionLen.exit = exitIndicators[0]
        && exitIndicators[0].indicator !== indicatorPattern.indicator ? exitIndicators.length
        : 0
    }
    const min_candle_freq = this.getMinCandleFreq()
    const time_frame = freq_candle_map[candle_freq_map[candleInterval]]
    let f_algo_uuid = algo_uuid || this.state.algo_uuid
    if (edit && algo_uuid && !copy) {
      f_algo_uuid = algo_uuid
    }
    if (sample) {
      f_algo_uuid = algo_uuid
    }
    const chart_type_value = getChartType(chart_type)
    let mod_candle_interval = candleInterval
    if (candleInterval !== '1 Day' && time_frame === 'day') {
      mod_candle_interval = '1 Day'
    }
    let mod_dt_start = dayjs(dt_start, 'DD/MM/YYYY')
    if (this.runBacktest && isDynamicContractExist && fTree.indicators
      && fTree.indicators.option_greeks
      && entryString.includes(fTree.indicators.option_greeks.name)) {
      if (time_frame === 'day') {
        mod_dt_start = dayjs((dayjs(dt_stop, 'DD/MM/YYYY').unix()
          - (2 * date_range_epochs.min)) * 1000)
        showSnackbar(`For ${fTree.indicators.option_greeks.name},
    backtesting is allowed only for 2 months with ${time_frame} timeframe`)
      } else {
        mod_dt_start = dayjs((dayjs(dt_stop, 'DD/MM/YYYY').unix()
          - date_range_epochs.min) * 1000)
        showSnackbar(`For ${fTree.indicators.option_greeks.name},
    backtesting is allowed only for 1 month with ${time_frame} timeframe`)
      }
    }

    const f_condition_edit_type = conditionEditType === CONDITION_TYPE.PRO
      ? CONDITION_TYPE.REVIEW
      : conditionEditType
    // const f_trading_start_time = dayjs(trading_start_time).format('HH:mm')
    // const f_trading_stop_time = dayjs(trading_stop_time).format('HH:mm')
    const algo_state = {
      ...this.state,
      algo_name: algo_name.trim(),
      candleInterval: mod_candle_interval,
      time_frame,
      entryIndicators: modEntryIndicators,
      entryAndOrs: modEntryAndOrs,
      exitIndicators: modExitIndicators,
      exitAndOrs: modExitAndOrs,
      isCtbCreate: false,
      conditionEditType: f_condition_edit_type,
    }
    delete algo_state.notifications
    delete algo_state.csrf
    delete algo_state.showCreateAdvance
    delete algo_state.selectedSymbols
    delete algo_state.showError
    delete algo_state.indicatorListUpdated
    delete algo_state.algo_obj
    delete algo_state.stepsCompleted
    delete algo_state.showReadMore
    delete algo_state.copiedCondition
    delete algo_state.userCanceledWarning
    delete algo_state.isMultiPage
    delete algo_state.rawEntryStr
    delete algo_state.rawExitStr
    delete algo_state.invalidCandleIntervalEntry
    delete algo_state.invalidCandleIntervalExit
    delete algo_state.invalidCandleIntervalMultipleEntry
    delete algo_state.invalidCandleIntervalMultipleExit
    // delete algo_state.runBacktest
    // const dt_stop = dayjs(new Date().getTime()).format(algoDateFormat)
    // const dt_start = dayjs(
    //   (dayjs().unix() * 1000 - date_range_epochs[time_frame])
    // ).format(algoDateFormat)
    let finalDailyStategyCycle = daily_strategy_cycle
    if (holding_type === 'CNC/NRML' && daily_strategy_cycle !== '-' && noUpdate) {
      this.setState({ daily_strategy_cycle: '-' })
      finalDailyStategyCycle = '-'
      showSnackbar('Daily strategy has been set to default since it cannot be used when holding type is CNC/NRML', { hideDelay: 6000 })
    }
    const params = {
      ...extraAdvanceParams,
      daily_strategy_cycle: finalDailyStategyCycle,
      algo_uuid: f_algo_uuid,
      algo_name: algo_name.trim(),
      algo_desc,
      position_type: positionType.toUpperCase(),
      position_qty: quantity,
      quantity,
      time_frame,
      equities: this.formattedScriplist(),
      entry_logic: entryString,
      exit_logic: exitString,
      take_profit: takeProfit,
      stop_loss: stopLoss,
      html_block: '',
      min_candle_freq,
      holding_type: holding_type_map[holding_type],
      chart_type: chart_type_value,
      trade_time_given: 'True',
      trading_start_time,
      trading_stop_time,
      csrfmiddlewaretoken: csrf,
      dt_start: mod_dt_start.format(algoDateFormat),
      dt_stop,
      algo_state: JSON.stringify(algo_state),
      create_plus: !!createAdvance,
      order_type,
      trailing_sl,
      // new
      ip_interval: time_frame,
      initial_capital,
    }
    const algo_obj = {
      ...params,
      algo_state: {},
    }
    if (runBacktest || !isEqual(this.prevParams, algo_obj)) {
      // delete entry exit logic key
      deployAlgo(params)
      this.prevParams = algo_obj
      if (!noUpdate) {
        this.setState({ algo_obj })
      }
      if (this.runBacktest) {
        gaParamsMap = [
          ['Adding condition', `Add entry condition${this.routeParams.gaType === 'Edit' ? ' (Edit)' : ''}`, conditionLen.entry],
          ['Adding condition', `Add exit condition${this.routeParams.gaType === 'Edit' ? ' (Edit)' : ''}`, conditionLen.exit],
          ['Run backtest', `Save and run backtest${this.routeParams.gaType === 'Edit' ? ' (Edit)' : ''}`, scripList.length],
          ['Mode + Assist', `${createAdvance ? 'Advanced' : 'Basic'} + ${assist ? 'On' : 'Off'}`],
        ]
      }
      // if (!runBacktest) showSnackbar('Condition auto saved')
    }
  }

  checkOnlyScannerFuncExist = (conditions, indiArr = [MATH_FUNCS.MULTI_TIME_FRAME.name]) => {
    let hasScannerOnlyFunc = false

    for (let i = 0;i < conditions.length;i++) {
      const condition = conditions[i]
      const {
        name: conditionName, subType: conditionSubType,
        indicatorDetails: conditionIndicatorDetails = {},
      } = condition
      for (let index = 0;index < indiArr.length;index++) {
        const funcName = indiArr[index]
        if (funcName
          && (conditionName === funcName
            || (conditionSubType === 'indicators'
              && conditionIndicatorDetails.name === funcName)
          )) {
          hasScannerOnlyFunc = true
          break
        }
      }
      if (hasScannerOnlyFunc) break
      if (condition.conditions && condition.conditions.length) {
        hasScannerOnlyFunc = this.checkOnlyScannerFuncExist(condition.conditions, indiArr)
        if (hasScannerOnlyFunc) break
      }
    }
    return hasScannerOnlyFunc
  }

  shouldShowWarning = ({
    exit_logic, max_allocation,
  }) => {
    // debugger
    const { exitIndicators, entryIndicators } = this.state
    let showWarning = false
    let mtfExist = false
    let maxAllocExist = false

    mtfExist = this.checkOnlyScannerFuncExist(entryIndicators, [
      MATH_FUNCS.MULTI_TIME_FRAME.name,
    ])
    if (exit_logic && !mtfExist) {
      mtfExist = this.checkOnlyScannerFuncExist(exitIndicators, [
        MATH_FUNCS.MULTI_TIME_FRAME.name,
      ])
    }
    if (max_allocation && Number(max_allocation) > 0) {
      maxAllocExist = true
    }

    if (mtfExist) {
      showWarning = 'MTF'
    }
    if (maxAllocExist) {
      showWarning = 'MAX_ALLOC'
    }
    return showWarning
  }

  onSubmit = () => { // runBacktest
    const {
      exitIndicators, entryIndicators,
      createAdvance, entryAndOrs, exitAndOrs,
      isMultiPage, max_allocation,
    } = this.state
    // const { user_details, toggleRootModal } = this.props
    let entry_logic = ''
    let exit_logic = ''
    let showWarning = false
    if (createAdvance) {
      entry_logic = screenerLogicStringGenerator(entryIndicators, entryAndOrs)
      exit_logic = exitIndicators[0].conditions
        && screenerLogicStringGenerator(exitIndicators, exitAndOrs)
      showWarning = this.shouldShowWarning({
        exit_logic, exitIndicators, entryIndicators, entry_logic, max_allocation,
      })
    } else {
      entry_logic = logicToStringGenerator(entryIndicators, entryAndOrs)
      exit_logic = logicToStringGenerator(exitIndicators, exitAndOrs)
    }
    const {
      entryStr: entryString, exitStr: exitString,
      final_entry_str: rawEntryStr, final_exit_str: rawExitStr,
    } = formEntryExitString({
      ...this.state, entry_logic, exit_logic,
    })

    // let showError = false
    // DANGER
    // if (runBacktest) {
    //   if ([BROKERS.ANGEL.name, BROKERS.FIVE_PAISA.name].includes(user_details.broker)) {
    //     if(userAccessCheck({
    // condition: accessConditions.AB_NO_EMAIL_USAGE_LIMIT, user_details })) {
    //       toggleRootModal(ROOT_MODALS.showEmailModal, true)
    //       return
    //     }
    //   }
    //   showError = true
    //   this.runBacktest = true
    //   this.backTestSubmitHandler()
    // }
    this.setState((prevState) => {
      const conditionEditType = !isMultiPage
        ? CONDITION_TYPE.PRO
        : prevState.conditionEditType
      return {
        entryString, exitString, rawEntryStr, rawExitStr, showWarning, conditionEditType,
      }
    })
  }

  handleNumericChangeforTsl = (trailingSl) => {
    const { trailing_sl, tpsl_type } = this.state
    const { user_details, toggleAuthGreeting } = this.props
    const {
      subscription_type,
    } = user_details
    let trailing_slErr = false
    if (subscription_type !== 3) {
      toggleAuthGreeting(PRO_FEATURE_MAP.TRAILING_SL.ID)
      return
    }
    if (Number(trailingSl.trim()) && (this.numRegx.test(trailingSl)
      || this.decimalRegx.test(trailingSl))) {
      const { error, errorMsg } = tslValidator(tpsl_type, trailingSl)
      if (error) {
        trailing_slErr = error
        showSnackbar(errorMsg, 'error')
      }
    }
    this.setState(
      {
        trailing_sl: trailingSl,
        exitValid: Number(trailing_sl) && !trailing_slErr,
        trailing_slErr,
      },
      () => { this.onSubmit() },
    )
  }

  onTpErrChange = (takePct) => {
    let takeProfitErr = true
    if (Number(takePct.trim()) && (this.numRegx.test(takePct) || this.decimalRegx.test(takePct)
      || Number(takePct) >= TPSL_LIMIT)) {
      takeProfitErr = false
    }
    const value = takePct
    const { error, errorMsg } = tpslValidator(value, 'target profit')
    if (error) {
      showSnackbar(errorMsg, 'error')
    }
    this.setState({ takeProfitErr })
  }

  onTpChange = (takePct) => {
    const { stopLoss } = this.state
    let takeProfitErr = true
    if (Number(takePct.trim()) && (this.numRegx.test(takePct) || this.decimalRegx.test(takePct)
      || Number(takePct) >= TPSL_LIMIT)) {
      takeProfitErr = false
    }
    this.setState(
      { takeProfit: takePct, exitValid: Number(stopLoss) && !takeProfitErr },
      () => { this.onSubmit(); this.validityChecker() },
    )
  }

  onSlErrChange = (stopPct) => {
    let stopLossErr = true
    if (Number(stopPct.trim()) && (this.numRegx.test(stopPct) || this.decimalRegx.test(stopPct)
      || Number(stopPct) >= TPSL_LIMIT)) {
      stopLossErr = false
    }
    const value = stopPct
    const { error, errorMsg } = tpslValidator(value, 'stop loss')
    if (error) {
      showSnackbar(errorMsg, 'error')
    }
    this.setState({ stopLossErr })
  }

  onSlChange = (stopPct) => {
    const { takeProfit } = this.state
    let stopLossErr = true
    if (Number(stopPct.trim()) && (this.numRegx.test(stopPct) || this.decimalRegx.test(stopPct)
      || Number(stopPct) >= TPSL_LIMIT)) {
      stopLossErr = false
    }
    this.setState({
      stopLoss: stopPct, exitValid: Boolean(Number(takeProfit) && !stopLossErr),
    }, () => { this.onSubmit(); this.validityChecker() })
  }

  toggleCreateAdvance = (addScrips) => {
    this.setState((prevState) => {
      const { createAdvance } = prevState
      const extraState = this.setCreateConditionState(!createAdvance)

      let modScripList = []
      if (!createAdvance) {
        modScripList = prevState.scripList
      } else {
        modScripList = prevState.scripList.filter(item => (['NSE', 'BSE', 'MCX', 'CDS'].includes(item.segment)))
      }
      if (addScrips && this.extraScrip.length) {
        this.extraScrip.forEach((item) => {
          modScripList.unshift(item)
          this.modifiedScrips.add(item.seg_sym)
        })
        this.extraScrip = []
      }
      this.activeIndex = 0
      return ({
        exitAndOrs: ['and'],
        entryString: '',
        exitString: '',
        entryValid: false,
        exitValid: false,
        entryAndOrs: ['and'],
        showAlert: !prevState.showAlert,
        createAdvance: !prevState.createAdvance,
        scripList: modScripList,
        scripValid: modScripList.length > 0,
        conditionEditType: CONDITION_TYPE.POSITION,
        stepsCompleted: new Set(),
        showError: false,
        copiedCondition: {},
        showWarning: false,
        ...extraState,
      })
    }, () => {
      this.updateGA('Toggle Mode', this.state.createAdvance ? 'Advanced' : 'Basic')
    })
  }

  onSymbolDelete = (seg_sym) => {
    const { scripList } = this.state
    const modScripList = scripList.filter((item) => {
      let shouldRemove = false
      if (item.seg_sym) {
        shouldRemove = item.seg_sym === seg_sym
      } else {
        const { segment, symbol } = item
        shouldRemove = `${segment}_${symbol}` === seg_sym
      }
      if (shouldRemove) this.modifiedScrips.add(seg_sym)
      return !shouldRemove
    })
    this.setState({
      scripList: modScripList,
      scripValid: modScripList.length > 0,
    })
  }

  // not used in web
  onSymbolSelect = ({ seg_sym, isSelected }) => {
    const { selectedSymbols } = this.state
    const modSelection = new Set(selectedSymbols)
    if (!isSelected) {
      modSelection.add(seg_sym)
    } else {
      modSelection.delete(seg_sym)
    }
    let allSelected = false
    if (modSelection.size === selectedSymbols.size) {
      allSelected = true
    }
    this.setState({ selectedSymbols: modSelection, allSelected })
  }

  selectAllSymbols = () => {
    const { scripList } = this.state
    const modSelection = new Set()

    scripList.forEach((item) => {
      const { segment, symbol, seg_sym } = item
      let segSym = seg_sym
      if (!item.seg_sym) {
        segSym = `${segment}_${symbol}`
      }
      modSelection.add(segSym)
    })
    return modSelection
  }

  toggleSelectAll = (allSelected) => {
    if (allSelected) {
      this.setState({ selectedSymbols: new Set(), allSelected: false })
    } else {
      this.setState({ selectedSymbols: this.selectAllSymbols(), allSelected: true })
    }
  }

  toggleLongPress = (seg_sym) => {
    const { scripList } = this.state
    this.setState((prevState) => {
      let extraChanges = {}
      if (prevState.longPressActive) {
        extraChanges = { selectedSymbols: new Set(), allSelected: false }
      } else {
        const selectedSymbols = new Set()
        extraChanges = {
          selectedSymbols: selectedSymbols.add(seg_sym), allSelected: scripList.length === 1,
        }
      }
      return ({ longPressActive: !prevState.longPressActive, ...extraChanges })
    })
  }

  toggleScripParams = (flag) => {
    this.setState({ showScripParams: flag })
  }

  saveParamsDetails = (data) => {
    const { scripList } = this.state
    const modScripList = [...scripList]
    const { index, seg_sym } = this.selectedScrip
    let modScrip = {}
    const symbol = getDynamicSymbol(data, seg_sym)
    // check if this symbol exists in the list
    let isAlreadyExists = false
    scripList.forEach((item) => {
      if (item.symbol === symbol) isAlreadyExists = true
    })
    if (isAlreadyExists) {
      showSnackbar('Symbol already exists')
      return
    }
    modScrip = {
      ...modScripList[index],
      segment: '',
      symbol,
      details: { ...data, seg_sym },
      seg_sym: `_${symbol}`,
    }
    modScripList[index] = modScrip
    this.setState({ scripList: modScripList, showScripParams: false })
  }

  convertToDynamic = (scrip, index) => {
    const { createAdvance } = this.state
    if (!createAdvance) {
      this.toggleAlert()
      return
    }
    const { user_details } = this.props
    const shallReturn = userAccessCheck({
      condition: accessConditions.DYNAMIC_CONTRACT,
      user_details,
      showSnackbar,
    })
    if (shallReturn) return
    const { details, segment, symbol } = scrip
    const seg_sym = details ? details.seg_sym : `${segment}_${symbol}`
    this.selectedScrip = { ...scrip, index, seg_sym }
    this.scripProps = {
      ...dynamicScripParams(seg_sym.split('_')[0]),
      indicatorDetails: details,
    }
    this.setState({ showScripParams: true })
  }

  onSltpTypeChange = (value, obj, field) => {
    const {
      position_sizing_type,
      trailing_sl,
    } = this.state
    let changes = {}

    if (field === 'tpsl_type' && value === 'abs'
      && position_sizing_type === POSITION_SIZING_TYPE.RISK_BASED) {
      changes = { ...changes, position_sizing_type: '-' }
    }
    if (position_sizing_type === POSITION_SIZING_TYPE.RISK_BASED && field === 'tpsl_type' && value === TPSL_TYPE.ABSOLUTE.value) {
      showSnackbar(`Cannot select TPSL type as ${TPSL_TYPE.ABSOLUTE.value} with ${POSITION_SIZING_TYPE.RISK_BASED} position sizing`)
      return
    }
    if (trailing_sl) {
      if (value === TPSL_TYPE.ABSOLUTE.value && (trailing_sl > 0 && trailing_sl < 100)) {
        showSnackbar('Cannot select trailing SL value less than 100 for TPSL type absolute')
        return
      }
      if (value === TPSL_TYPE.POINTS.value && (trailing_sl > 0 && trailing_sl < 1)) {
        showSnackbar('Cannot select trailing SL value less than 1 for TPSL type points')
        return
      }
      if (value === TPSL_TYPE.PERCENTAGE.value && (trailing_sl > 0 && trailing_sl < 0.01)) {
        showSnackbar('Cannot select trailing SL value less than 0.01 for TPSL type percentage')
        return
      }
    }

    this.setState({ [field]: value, ...changes }, this.onSubmit)
  }

  updateCopiedCondition = (params) => {
    this.setState({ ...params })
  }

  renderContent = (disableActions, conditionEditType) => {
    const {
      trainerModel, screenerTrainer, isDark, user_details, toggleAuthGreeting,
      isMobile, changePtComponentStatus, startTour, activeTourIndex, tourType,
      updateGA,
    } = this.props
    const { subscription_type } = user_details
    const {
      candleInterval,
      entryIndicators,
      entryAndOrs,
      assist,
      positionType,
      stopLoss,
      takeProfit,
      exitIndicators,
      exitAndOrs,
      showExitIndicator,
      showError,
      createAdvance,
      showCreateAdvance,
      takeProfitErr,
      stopLossErr,
      tpsl_type,
      scripList,
      selectedSymbols,
      scripValid,
      indicatorListUpdated,
      copiedCondition,
      trailing_sl,
      trailing_slErr,
      isMultiPage,
    } = this.state
    const { slLabel, tpLabel, tslLabel } = getAdvancedParamsLabel({ tpsl_type })
    if (conditionEditType === CONDITION_TYPE.ENTRY) {
      if (createAdvance) {
        return (
          <ScreenerConditionRenderer
            key={CONDITION_TYPE.ENTRY}
            editType={CONDITION_TYPE.ENTRY}
            candleInterval={candleInterval}
            trainerModel={screenerTrainer}
            expressions={entryIndicators}
            andOrs={entryAndOrs}
            assist={assist}
            title="Entry Condition"
            indicatorsList={this.indicatorsList}
            createAdvance={createAdvance}
            showCreateAdvance={showCreateAdvance}
            CONDITION_TYPE={CONDITION_TYPE}
            copiedCondition={copiedCondition}
            createStrategy
            toggleCreateAdvance={this.onChangeOption}
            conditionsValid={this.conditionsValid}
            toggleAssist={this.toggleAssist}
            handleConditionAddition={this.handleConditionAddition}
            handleAndOrChange={this.handleAndOrChange}
            updateCopiedCondition={this.updateCopiedCondition}
            subscription_type={subscription_type}
            toggleAuthGreeting={toggleAuthGreeting}
            startTour={startTour}
            changePtComponentStatus={changePtComponentStatus}
            activeTourIndex={activeTourIndex}
            updateGA={updateGA}
          // assistMode={assistMode}
          // index={i}
          />
        )
      }
      return (
        <ConditionRenderer
          key={CONDITION_TYPE.ENTRY}
          editType={CONDITION_TYPE.ENTRY}
          expressions={entryIndicators}
          exitIndicators={exitIndicators}
          andOrs={entryAndOrs}
          candleInterval={candleInterval}
          trainerModel={trainerModel}
          // addIndicator={this.addEntryIndicator}
          assist={assist}
          showAssist
          positionType={positionType}
          title="Entry Condition"
          CONDITION_TYPE={CONDITION_TYPE}
          disableActions={disableActions}
          comparatorsList={this.comparatorsList}
          indicatorsList={this.indicatorsList}
          showError={showError}
          createAdvance={createAdvance}
          showCreateAdvance={showCreateAdvance}
          copiedCondition={copiedCondition}
          createStrategy
          conditionsValid={this.conditionsValid}
          handleAndOrChange={this.handleAndOrChange}
          toggleAssist={this.toggleAssist}
          handleConditionAddition={this.handleConditionAddition}
          toggleCreateAdvance={this.onChangeOption}
          updateCopiedCondition={this.updateCopiedCondition}
          indicatorListUpdated={indicatorListUpdated}
          changePtComponentStatus={changePtComponentStatus}
        />
      )
    }
    if (conditionEditType === CONDITION_TYPE.EXIT) {
      if (createAdvance) {
        return (
          <ScreenerConditionRenderer
            key={CONDITION_TYPE.EXIT}
            editType={CONDITION_TYPE.EXIT}
            CONDITION_TYPE={CONDITION_TYPE}
            candleInterval={candleInterval}
            trainerModel={screenerTrainer}
            expressions={exitIndicators}
            andOrs={exitAndOrs}
            assist={assist}
            title="Exit Condition"
            indicatorsList={this.indicatorsList}
            createAdvance={createAdvance}
            showCreateAdvance={showCreateAdvance}
            stopLoss={stopLoss}
            onSlChange={this.onSlChange}
            onSlErrChange={this.onSlErrChange}
            takeProfit={takeProfit}
            onTpChange={this.onTpChange}
            onTpErrChange={this.onTpErrChange}
            handleNumericChangeforTsl={this.handleNumericChangeforTsl}
            showSltp
            takeProfitErr={takeProfitErr}
            trailing_slErr={trailing_slErr}
            trailing_sl={trailing_sl}
            stopLossErr={stopLossErr}
            createStrategy
            slLabel={slLabel}
            tpLabel={tpLabel}
            tslLabel={tslLabel}
            tpsl_type={tpsl_type}
            copiedCondition={copiedCondition}
            conditionsValid={this.conditionsValid}
            handleAndOrChange={this.handleAndOrChange}
            toggleAssist={this.toggleAssist}
            handleConditionAddition={this.handleConditionAddition}
            toggleCreateAdvance={this.onChangeOption}
            onSltpTypeChange={this.onSltpTypeChange}
            updateCopiedCondition={this.updateCopiedCondition}
            subscription_type={subscription_type}
            toggleAuthGreeting={toggleAuthGreeting}
            changePtComponentStatus={changePtComponentStatus}
            startTour={startTour}
          // assistMode={assistMode}
          // index={i}
          />
        )
      }
      return (
        <ConditionRenderer
          key={CONDITION_TYPE.EXIT}
          editType={CONDITION_TYPE.EXIT}
          expressions={exitIndicators}
          andOrs={exitAndOrs}
          candleInterval={candleInterval}
          trainerModel={trainerModel}
          // addIndicator={this.addExitIndicator}
          assist={assist}
          positionType={positionType}
          showSltp
          showExitIndicator={showExitIndicator}
          stopLoss={stopLoss}
          onSlChange={this.onSlChange}
          onSlErrChange={this.onSlErrChange}
          takeProfit={takeProfit}
          onTpChange={this.onTpChange}
          onTpErrChange={this.onTpErrChange}
          title="Exit Condition"
          CONDITION_TYPE={CONDITION_TYPE}
          disableActions={disableActions}
          comparatorsList={this.comparatorsList}
          indicatorsList={this.indicatorsList}
          showError={showError}
          createAdvance={createAdvance}
          showCreateAdvance={showCreateAdvance}
          takeProfitErr={takeProfitErr}
          stopLossErr={stopLossErr}
          createStrategy
          slLabel={slLabel}
          tpLabel={tpLabel}
          tslLabel={tslLabel}
          copiedCondition={copiedCondition}
          conditionsValid={this.conditionsValid}
          handleAndOrChange={this.handleAndOrChange}
          toggleAssist={this.toggleAssist}
          handleConditionAddition={this.handleConditionAddition}
          toggleCreateAdvance={this.onChangeOption}
          toggleExitConditionText={this.toggleExitConditionText}
          onSltpTypeChange={this.onSltpTypeChange}
          updateCopiedCondition={this.updateCopiedCondition}
          indicatorListUpdated={indicatorListUpdated}
          subscription_type={subscription_type}
        />
      )
    }
    return (
      <CreateView
        conditionEditType={conditionEditType}
        scripList={scripList}
        positionType={positionType}
        selectedSymbols={selectedSymbols}
        createAdvance={createAdvance}
        togglePositions={this.togglePositions}
        addNewSymbol={this.addNewSymbol}
        disableActions={disableActions}
        onSymbolDelete={this.onSymbolDelete}
        postionBtnMap={postionBtnMap(isDark)}
        isMultiPage={isMultiPage}
        // toggleLongPress={this.toggleLongPress}
        // toggleSelectAll={this.toggleSelectAll}
        // updateScrollToRef={this.updateScrollToRef}
        convertToDynamic={this.convertToDynamic}
        showError={showError}
        scripValid={scripValid}
        isDark={isDark}
        isMobile={isMobile}
        showCreateAdvance={showCreateAdvance}
        startTour={startTour}
        changePtComponentStatus={changePtComponentStatus}
        activeTourIndex={activeTourIndex}
        tourType={tourType}
        updateGA={updateGA}
      />
    )
  }

  renderParams = (disableActions) => {
    const {
      styles, isDark, user_details, toggleAuthGreeting,
      activeTourIndex, updateTourIndex, changePtComponentStatus,
      isMobile, startTour, updateGA,
    } = this.props
    const { createAdvance } = this.state
    if (!this.initialFetch) {
      return (
        <CreateParams
          {...this.state}
          onEditParams={this.onEditParams}
          create_plus={createAdvance}
          user_details={user_details}
          containerStyles={styles.paramsContainer}
          disabled={disableActions}
          isDark={isDark}
          onEditCondition={this.onEditCondition}
          toggleEditParams={this.toggleEditParams}
          toggleAuthGreeting={toggleAuthGreeting}
          updateTourIndex={updateTourIndex}
          activeTourIndex={activeTourIndex}
          changePtComponentStatus={changePtComponentStatus}
          isMobile={isMobile}
          startTour={startTour}
          updateGA={updateGA}
        />
      )
    }
    return null
  }

  toggleModal = (modal) => {
    const { addScrips } = this.state
    const { tourType, startTour, activeTourIndex } = this.props
    if (startTour && !addScrips && (tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY
      && activeTourIndex >= 5)) return
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  onAlertDismiss = () => {
    this.extraScrip = []
    this.setState({ showAlert: false })
  }

  updateGA = (a, l, v) => {
    const { updateGA } = this.props
    updateGA(a, l, v, this.routeParams.gaType === 'Edit' ? 'Edit strategy' : '')
  }

  onStepPress = (item, index) => {
    const { isValid: isPageParamsValid } = this.validityChecker(true)
    // if (!isPageParamsValid && index >= this.activeIndex) {
    //   return
    // }
    const oldIndex = this.activeIndex
    this.activeIndex = index
    const { value, label } = item
    this.updateProgress(oldIndex, value, isPageParamsValid)
    this.updateGA(`${label} button`, `Cstra Page ${index + 1}`)
  }

  toggleParamsModal = () => {
    this.setState((prevState) => {
      return {
        conditionEditType: prevState.conditionEditType === CONDITION_TYPE.REVIEW
          ? CONDITION_TYPE.PRO : CONDITION_TYPE.REVIEW,
      }
    })
  }

  toggleReadMore = () => {
    this.setState(prevState => ({ readMore: !prevState.readMore }))
  }

  shouldShowReadMore = () => {
    if (this.descRef.current) {
      if (this.descRef.current.scrollWidth <= this.containerRef.current.offsetWidth) {
        this.setState({ showReadMore: false })
      } else {
        this.setState({ showReadMore: true })
      }
    }
  }

  handleCandleChange = (value) => {
    const { holding_type } = this.state
    if (holding_type === 'MIS' && value === 'day') {
      showSnackbar('Cannot select day with MIS')
      return
    }
    const candle_allowed_time = date_range_epochs[value]
    const candleInterval = candle_map[value]

    const { dt_start, dt_stop } = this.state
    const start_date = moment(dt_start, 'DD/MM/YYYY')
    const stop_date = moment(dt_stop, 'DD/MM/YYYY')
    if ((moment(stop_date).unix() - moment(start_date).unix()) !== candle_allowed_time) {
      const new_start_time = moment((moment(stop_date).unix() - candle_allowed_time) * 1000)
      const new_start_time_formatted = new_start_time.format(algoDateFormat)
      this.showBacktestBtn = true
      this.setState({ candleInterval, dt_start: new_start_time_formatted })
      showSnackbar('Backtesting period(start date / stop date) adjusted accordingly')
    } else {
      this.showBacktestBtn = true
      this.setState({ candleInterval })
    }
    this.onSubmit()
    setEditGAParams({ time_frame: ['Position toggle', value, 1] })
  }

  onInputChange = (e, key) => {
    const { target: { value } } = e
    this.showBacktestBtn = true
    this.setState({ [key]: value, [`${key}Err`]: false })
  }

  renderAlgoDesc = (isDark, styles) => {
    const {
      algo_desc, entryString, exitString, readMore, showReadMore,
    } = this.state
    const algoDesc = entryString && !algo_desc ? `${entryString} ${exitString ? 'and ' : ''}${exitString}` : algo_desc

    const blueColor = isDark ? 'blue_300' : 'blue'
    return algoDesc ? (
      <CustomText textRef={this.descRef} weight="regular" color="textGrey" className={`${styles.desc} ${readMore ? '' : styles.shortDesc}`}>
        {algoDesc}
        {showReadMore && (
          <button
            type="button"
            onClick={this.toggleReadMore}
            style={{ display: 'block' }}
          >
            <CustomText
              color={blueColor}
              weight="semi_bold"
            >
              {readMore ? 'Read Less' : 'Read More'}
            </CustomText>
          </button>
        )}
      </CustomText>
    ) : null
  }

  render() {
    const {
      isDeployingAlgo, isFetchingState, isDark, styles,
      location, user_details,
      getTrainerModel, trainerModel = {}, tree, indicators, getIndicators, history,
      screenerTree, screenerTrainer, getScreenerTrainer, treeFetched, startTour,
      changePtComponentStatus, activeTourIndex, toggleSkipTour, updateGA,
      toggleAuthGreeting, preferences,
    } = this.props
    const {
      candleInterval, showWarning, isMultiPage, userCanceledWarning,
      addScrips, createAdvance, showNameChange, algo_name, showAlert, showScripParams,
    } = this.state
    const disableActions = isDeployingAlgo || isFetchingState
    const autoSaving = isDeployingAlgo && !this.runBacktest
    let linkColor = 'BLUE'
    let textColor = 'TEXT'
    if (isDark) {
      linkColor = 'BLUE_300'
      textColor = 'WHITE'
    }
    const intervalIndex = candle_freq_map[candleInterval]
    const warningText = LINK_MAP[showWarning] ? LINK_MAP[showWarning].text : false
    const CreatePage = isMultiPage ? CreateMulti : CreateSingle
    const mtfPreference = preferences[PREFERENCES.MTF_WARNING] === undefined
      ? true : preferences[PREFERENCES.MTF_WARNING]

    return (
      <div
        className={styles.screen}
        ref={this.containerRef}
      >
        {mtfPreference && showWarning && showWarning !== true && !userCanceledWarning ? (
          <div className={styles.warning}>
            <CustomText size="small" color={COLORS.BLACK} flex={1}>
              <>
                {warningText}
                <span>&nbsp;</span>
                {LINK_MAP[showWarning].link && (
                  <a href={LINK_MAP[showWarning].link} rel="noreferrer" target="_blank">read more here.</a>
                )}
              </>
            </CustomText>
            <button type="button" onClick={() => this.setState({ showWarning: false, userCanceledWarning: true })}>
              <Icon name={ICONS.CLOSE} size={8} />
            </button>
          </div>
        ) : null}
        <AddScrips
          params={this.addScripParams}
          showSubmitBtn
          onDismiss={this.toggleModal}
          dismissKey="addScrips"
          visible={addScrips}
          clearOnfocus
          create_plus={createAdvance}
          startTour={startTour}
          toggleSkipTour={toggleSkipTour}
          updateGA={updateGA}
        />
        <CustomModal
          visible={showNameChange}
          animationType="fade"
          onDismiss={this.toggleNameChangeModal}
        >
          <CreateNewList
            onDismiss={this.toggleNameChangeModal}
            dismissKey="showNameChange"
            title="Save Strategy As"
            inputPlaceholder="Strategy Name"
            btnText="Save"
            onCreate={this.nameChangeHandler}
            visible={showNameChange}
            listName={algo_name}
          />
        </CustomModal>
        <AlertPopup
          visible={showAlert}
          onDismiss={this.onAlertDismiss}
          dismissKey="showAlert"
          modalStyles={styles.alertModal}
          {...this.alertProps}
        />
        {/* <CustomModal
          visible={removeSymbolsModal}
          onDismiss={this.toggleModal}
          dismissKey="removeSymbolsModal"
        >
          {this.renderRemoveSymbolConfirmation()}
        </CustomModal> */}
        <ScreenHeader
          title={autoSaving ? 'Saving...' : algo_name}
          DANGER={{ color: COLORS[autoSaving ? linkColor : textColor] }}
          actionBarRenderer={this.headerActionBarRenderer}
          isDeployingAlgo={isDeployingAlgo}
          isFetchingState={isFetchingState}
          isMultiPage={isMultiPage}
          showBackBtn
          containerStyles={styles.headerContainer}
          backPressHandler={this.backPressHandler}
        />
        {this.renderAlgoDesc(isDark, styles)}

        <CreatePage
          {...this.state}
          history={history}
          disableActions={disableActions}
          location={location}
          user_details={user_details}
          getTrainerModel={getTrainerModel}
          trainerModel={trainerModel}
          tree={tree}
          indicators={indicators}
          getIndicators={getIndicators}
          screenerTree={screenerTree}
          screenerTrainer={screenerTrainer}
          getScreenerTrainer={getScreenerTrainer}
          treeFetched={treeFetched}
          onStepPress={this.onStepPress}
          activeIndex={this.activeIndex}
          styles={styles}
          togglePositions={this.togglePositions}
          renderContent={this.renderContent}
          renderParams={this.renderParams}
          shouldShowWarning={this.shouldShowWarning}
          onSubmit={this.onSubmit}
          onEditParams={this.onEditParams}
          toggleParamsModal={this.toggleParamsModal}
          backTestSubmitHandler={this.backTestSubmitHandler}
          onSaveNRun={this.onSaveNRun}
          handleAlgoNameChange={this.handleAlgoNameChange}
          initialFetch={this.initialFetch}
          onEditCondition={this.onEditCondition}
          handleCandleChange={this.handleCandleChange}
          onInputChange={this.onInputChange}
          isDark={isDark}
          entryRef={this[`scrollRef${CONDITION_TYPE.ENTRY}`]}
          exitRef={this[`scrollRef${CONDITION_TYPE.EXIT}`]}
          paramsRef={this[`scrollRef${CONDITION_TYPE.PARAMS}`]}
          positionRef={this[`scrollRef${CONDITION_TYPE.POSITION}`]}
          algoNameRef={this[`scrollRef${CONDITION_TYPE.ALGO_NAME}`]}
          quantityRef={this[`scrollRef${CONDITION_TYPE.QUANTITY}`]}
          startTour={startTour}
          changePtComponentStatus={changePtComponentStatus}
          activeTourIndex={activeTourIndex}
          toggleAuthGreeting={toggleAuthGreeting}
          isDeployingAlgo={isDeployingAlgo}
          isFetchingState={isFetchingState}
        />
        {/* submit button is moved inside create params */}
        <CustomModal
          visible={showScripParams}
          onDismiss={() => this.toggleScripParams(false)}
          containerStyles={styles.detailsModal}
        >
          <>
            <CustomText size="small">
              {this.selectedScrip && this.selectedScrip.details
                ? 'Edit dynamic contract'
                : 'Convert to dynamic contract'}
            </CustomText>
            <CustomText weight="medium" size="large" className={styles.dynamicTitle}>
              {this.selectedScrip && this.selectedScrip.seg_sym ? this.selectedScrip.seg_sym.replace('_', ' ') : ''}
            </CustomText>
            <IndicatorDetails
              saveDetails={this.saveParamsDetails}
              dismissModal={() => this.toggleScripParams(false)}
              showRemove={false}
              showSnackbar={showSnackbar}
              {...this.scripProps}
              // details={details}
              // indicator={activeItem}
              // showRemove={showRemove}
              // removeIndicator={removeIndicator}
              candleIntervalIndex={intervalIndex}
            />
          </>
        </CustomModal>
      </div>
    )
  }
}

const stylesheet = ({
  screen: {
    marginTop: SPACING.SPCAE_20,
  },
  stepsContainer: {
    margin: `${SPACING.SPACE_12} 0 ${SPACING.SPACE_34}`,
    padding: `${SPACING.SPACE_20} 0`,
    zIndex: 9998,
    top: 106,
    position: 'sticky',
    backgroundColor: theme.screenBg,
  },
  headerActionBar: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionTabContainer: {
    minWidth: 200,
    marginRight: SPACING.SPACE_20,
  },
  alertContainer: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  alertContent: {
    margin: ' auto',
    width: '100%',
    maxWidth: 200,
  },
  alertTitle: {
    margin: `${SPACING.SPACE_10} auto ${SPACING.SPACE_4}`,
    width: '90%',
  },
  alertActionBar: {
    width: '90%',
    margin: `${SPACING.SPACE_20} auto 0`,
  },
  alertImg: {
    maxHeight: 180,
  },
  alertModal: {
    maxWidth: 300,
  },
  alertActiveBtn: {
    margin: `${SPACING.SPACE_32} auto ${SPACING.SPACE_10}`,
  },
  detailsModal: {
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    minHeight: 200,
    maxWidth: 570,
  },
  positionsTourStyling: {
    width: 300,
    padding: SPACING.SPACE_20,
  },
  positionsContainer: {
    marginTop: SPACING.SPACE_20,
  },
  positions: {
    marginTop: SPACING.SPACE_12,
  },
  dynamicTitle: {
    margin: 'auto',
  },
  desc: {
    marginTop: SPACING.SPACE_8,
    marginRight: SPACING.SPACE_8,
  },
  shortDesc: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
  warning: {
    position: 'relative',
    padding: '8px 20px',
    backgroundColor: theme.orangeLightBg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
  },
  warningText: {

  },
  '@media only screen and (max-width: 786px)': {
    screen: {
      paddingTop: 64,
    },
    sectionTabContainer: {
      marginRight: 0,
      minWidth: 'unset',
    },
    stepsContainer: {
      position: 'sticky',
      top: '28px',
      zIndex: 999,
      backgroundColor: theme.screenBg,
    },
    detailsModal: {
      padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    },
  },
})

export default withTheme(stylesheet)(Create)
