import {
  ACTIVE_ALGOS_STOCK_INIT,
  ACTIVE_ALGOS_STOCK_SUCCESS,
  ACTIVE_ALGOS_STOCK_FAILURE,
  STOPPED_ALGOS_INIT,
  STOPPED_ALGOS_SUCCESS,
  STOPPED_ALGOS_FAILURE,
  FETCH_OPEN_POSITIONS_INIT,
  FETCH_OPEN_POSITIONS_SUCCESS,
  FETCH_OPEN_POSITIONS_FAILURE,
  STOP_ALGO_INIT,
  STOP_ALGO_SUCCESS,
  STOP_ALGO_FAILURE,
  CLEAR_STOP_ALGO_RESP,
  CLEAR_OPEN_POSITIONS_RESP,
  REDEPLOY_INIT,
  REDEPLOY_SUCCESS,
  REDEPLOY_FAILURE,
  ORDER_DETAILS_INIT,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAILURE,
  STOP_WAITING_ALGOS_INIT,
  STOP_WAITING_ALGOS_SUCCESS,
  STOP_WAITING_ALGOS_FAILURE,
  DEPLOYED_COUNT_INIT,
  DEPLOYED_COUNT_FAILURE,
  DEPLOYED_COUNT_SUCCESS,
} from './actionTypes'
import { seperateActiveStocks } from '../../utils/common'
import { DEPLOYMENT_TYPE } from '../../utils/consts'

const initialState = {
  isFetchingActiveStocks: true,
  isFetchingStoppedStocks: true,
  activeWaitingStocks: [],
  activeEnteredStocks: [],
  // --> Separating Deployed Algos as per Notifications | PaperTrade | AutoTrade (copied from world)
  waitingFilteredStocks: { paperTrading: [], notificationTrading: [] },
  enteredFilteredStocks: { paperTrading: [], notificationTrading: [] },
  stoppedFilteredStocks: { paperTrading: [], notificationTrading: [] },
  stoppedStocks: [],
  activeStocksError: false,
  stoppedStocksError: false,
  activeStocksErrorMsg: '',
  stoppedStocksErrorMsg: '',
  isFetchingOpenPositions: false,
  openPositionsData: {},
  openPositionsError: false,
  isGettingStopAlgoResp: false,
  stopAlgoResp: {},
  stopAlgoError: false,
  isRedeploying: false,
  reDeployError: false,
  reDeployResp: {},
  isGettingOrder: false,
  gettingOrderError: false,
  orderDetailsResp: {},
  reDeployErrorMsg: '',

  isStoppingAlgos: false,
  stopWaitingAlgosResp: {},
  stopWaitingAlgosError: false,
  stopWaitingAlgosErrorMsg: '',

  isFetchingDeployedCount: true,
  deployedCount: {
    notifications: 0,
    paper: 0,
    screener: 0,
  },
  deployedCountObj: {},
  deployedCountError: false,
  deployedCountErrorMsg: '',
}

const filterDeployedStock = (stocks) => {
  const paperTrading = []
  const notificationTrading = []
  // const autoTrading = []
  if(stocks && stocks.length) {
    stocks.map((stock) => {
      switch(stock.deployment_type) {
        // case DEPLOYMENT_TYPE.AUTO.backend: autoTrading.push(stock); break
        case DEPLOYMENT_TYPE.PAPER.backend: paperTrading.push(stock); break
        case DEPLOYMENT_TYPE.NOTIFICATION.backend: notificationTrading.push(stock); break
        default: break
      }
      return null
    })
  }
  return { paperTrading, notificationTrading }

  // return { paperTrading, notificationTrading, autoTrading }
}

const deployed = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_ALGOS_STOCK_INIT:
      return {
        ...state,
        isFetchingActiveStocks: true,
        activeStocksError: false,
        stopAlgoError: false,
        openPositionsError: false,
        stoppedStocksError: false,
        activeStocksErrorMsg: '',
        stoppedStocksErrorMsg: '',
      }

    case ACTIVE_ALGOS_STOCK_SUCCESS: {
      const { data: { grouped_orders } } = action
      const { activeEnteredStocks, activeWaitingStocks } = seperateActiveStocks(grouped_orders)
      const waitingFilteredStocks = filterDeployedStock(activeWaitingStocks)
      const enteredFilteredStocks = filterDeployedStock(activeEnteredStocks)
      return {
        ...state,
        isFetchingActiveStocks: false,
        // activeEnteredStocks,
        // activeWaitingStocks,
        waitingFilteredStocks,
        enteredFilteredStocks,
      }
    }

    case ACTIVE_ALGOS_STOCK_FAILURE:
      return {
        ...state,
        isFetchingActiveStocks: false,
        activeStocksError: true,
        activeStocksErrorMsg: action.error_msg || 'Error fetching stocks',
      }

    case STOPPED_ALGOS_INIT:
      return {
        ...state,
        isFetchingStoppedStocks: true,
        stoppedStocksError: false,
        stopAlgoError: false,
        openPositionsError: false,
        activeStocksError: false,
        activeStocksErrorMsg: '',
        stoppedStocksErrorMsg: '',
      }

    case STOPPED_ALGOS_SUCCESS: {
      const { data: { grouped_orders = [] } } = action
      const stoppedFilteredStocks = filterDeployedStock(grouped_orders)

      return {
        ...state,
        isFetchingStoppedStocks: false,
        // stoppedStocks: grouped_orders,
        stoppedFilteredStocks,
      }
    }

    case STOPPED_ALGOS_FAILURE:
      return {
        ...state,
        isFetchingStoppedStocks: false,
        stoppedStocksError: true,
        stoppedStocksErrorMsg: action.error_msg || 'Error fetching stocks',
      }

    case FETCH_OPEN_POSITIONS_INIT:
      return {
        ...state,
        isFetchingOpenPositions: true,
        openPositionsError: false,
        openPositionsData: {},
      }

    case FETCH_OPEN_POSITIONS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingOpenPositions: false,
        openPositionsData: data,
      }
    }

    case FETCH_OPEN_POSITIONS_FAILURE:
      return {
        ...state,
        isFetchingOpenPositions: false,
        openPositionsError: true,
        openPositionsData: {},
      }

    case STOP_ALGO_INIT: {
      const { params } = action
      return {
        ...state,
        isGettingStopAlgoResp: true,
        stopAlgoError: false,
        stopAlgoResp: {},
        isFetchingOpenPositions: false,
        openPositionsData: params?.platform === 'openstreak' ? state.openPositionsData : {},
        openPositionsError: false,
      }
    }

    case STOP_ALGO_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isGettingStopAlgoResp: false,
        stopAlgoResp: data,
      }
    }

    case STOP_ALGO_FAILURE:
      return {
        ...state,
        isGettingStopAlgoResp: false,
        stopAlgoError: true,
      }

    case CLEAR_OPEN_POSITIONS_RESP: {
      return {
        ...state,
        isFetchingOpenPositions: false,
        openPositionsData: {},
        openPositionsError: false,
      }
    }

    case CLEAR_STOP_ALGO_RESP:
      return {
        ...state,
        isGettingStopAlgoResp: false,
        stopAlgoResp: {},
        stopAlgoError: false,
        isFetchingOpenPositions: false,
        openPositionsData: {},
        openPositionsError: false,
        isRedeploying: false,
        reDeployError: false,
        reDeployErrorMsg: '',
        reDeployResp: {},
        isGettingOrder: false,
        gettingOrderError: false,
        orderDetailsResp: {},
        activeStocksErrorMsg: '',
        stoppedStocksErrorMsg: '',
        isStoppingAlgos: false,
        stopWaitingAlgosResp: {},
        stopWaitingAlgosError: false,
        stopWaitingAlgosErrorMsg: '',
      }

    case REDEPLOY_INIT:
      return {
        ...state,
        isRedeploying: true,
        reDeployError: false,
        reDeployResp: {},
        reDeployErrorMsg: '',
      }

    case REDEPLOY_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isRedeploying: false,
        reDeployResp: data,
      }
    }

    case REDEPLOY_FAILURE:
      return {
        ...state,
        isRedeploying: false,
        reDeployError: true,
        reDeployErrorMsg: action.error,
      }

    case ORDER_DETAILS_INIT:
      return {
        ...state,
        isGettingOrder: true,
        gettingOrderError: false,
        orderDetailsResp: {},
      }

    case ORDER_DETAILS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isGettingOrder: false,
        orderDetailsResp: data.order_status,
      }
    }

    case ORDER_DETAILS_FAILURE:
      return {
        ...state,
        isGettingOrder: false,
        gettingOrderError: true,
      }

    case STOP_WAITING_ALGOS_INIT:
      return {
        ...state,
        isStoppingAlgos: true,
        stopWaitingAlgosResp: {},
        stopWaitingAlgosError: false,
      }

    case STOP_WAITING_ALGOS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isStoppingAlgos: false,
        stopWaitingAlgosResp: data,
      }
    }

    case STOP_WAITING_ALGOS_FAILURE:
      return {
        ...state,
        isStoppingAlgos: false,
        stopWaitingAlgosError: true,
        stopWaitingAlgosErrorMsg: action.error,
      }

    case DEPLOYED_COUNT_INIT: {
      return {
        ...state,
        isFetchingDeployedCount: true,
        deployedCountError: false,
        deployedCountErrorMsg: '',
      }
    }

    case DEPLOYED_COUNT_SUCCESS: {
      const { data } = action
      let deployedCount = {}
      Object.keys(data).forEach((sectionKey) => {
        if (sectionKey === 'status') return
        deployedCount = {
          ...deployedCount,
          [sectionKey]: Object.values(data[sectionKey]).reduce(
            (totalCount, itemValue) => totalCount + itemValue,
          ),
        }
      })
      // as we want it to be live instead of entered
      const modData = data.screener && data.screener.entered ? {
        ...data,
        screener: {
          live: data.screener.entered,
        },
      } : data
      return {
        ...state,
        isFetchingDeployedCount: false,
        deployedCount,
        deployedCountObj: modData,
      }
    }

    case DEPLOYED_COUNT_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingDeployedCount: false,
        deployedCountError: true,
        deployedCountErrorMsg: error,
      }
    }

    default:
      return state
  }
}

export default deployed
