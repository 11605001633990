import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import CancelSlmrowHeader from './CancelSlmrowHeader'
import { cancelOrder, clearCancelResp } from '../../containers/OrderBook/actions'

import { markUsed } from '../../containers/Notifications/actions'
import {
  getLogtagIconMapping,
} from '../../containers/Notifications/notificationUtils'
import { showSnackbar } from '../../UI/Snackbar'
import { getHeaderUuid } from '../../utils/common'

const LOGTAG_ICON_MAPPING = getLogtagIconMapping()

const HeaderNotificationPopup = React.memo(({
  slmNotifications, cancelOrderNow, csrf, containerStyles, isCanceling,
  cancelResp, cancelError, clearCancelResponse, markUsedNotif,
}) => {
  if (slmNotifications.length === 0) return null

  useEffect(() => {
    if (cancelError) {
      showSnackbar('Error Cancelling Order', {}, 0)
      clearCancelResponse()
    } else if (cancelResp && cancelResp.status === 'success') {
      showSnackbar('Order Cancelled Successfully', {}, 1)
      clearCancelResponse()
      const params = {
        notification_uuid: slmNotifications.notification_uuid,
        csrfmiddlewaretoken: csrf,
      }
      markUsedNotif(params)
    }
  }, [isCanceling])
  const cancelOrderConfirmed = (item) => {
    const {
      order_id,
      account_name = '',
      exchange = '',
      symbol = '',
    } = item
    const params = {
      order_id,
      account_name,
      exchange,
      symbol,
      csrfmiddlewaretoken: csrf,
    }

    const uuidHeader = { 'X-APP-UUID': getHeaderUuid() }

    cancelOrderNow(params, uuidHeader)
  }
  return (
    <div className={containerStyles}>
      {slmNotifications.map((notif) => {
        return (
          <CancelSlmrowHeader
            key={notif.order_id}
            notifIndex={notif.parentIndex}
            cancelOrder={cancelOrderConfirmed}
            item={notif}
            iconProps={LOGTAG_ICON_MAPPING['CANCEL SL-M ORDER']}
          />
        )
      })}
    </div>
  )
})

const mapStateToProps = (state) => {
  return {
    slmNotifications: state.notification.slmNotifications,
    cancelResp: state.orderbook.cancelResp,
    isCanceling: state.orderbook.isCanceling,
    cancelError: state.orderbook.cancelError,
    ...state.auth.loginResp,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearCancelResponse: () => dispatch(clearCancelResp()),
    cancelOrderNow: (params, header) => dispatch(cancelOrder(params, header)),
    markUsedNotif: (params, headers) => dispatch(markUsed(params, headers)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNotificationPopup)
