import isEmpty from 'lodash/isEmpty'
import {
  DIRECT_ORDER_INIT,
  DIRECT_ORDER_SUCCESS,
  DIRECT_ORDER_FAILURE,
  GET_WATCHLIST_INIT,
  GET_WATCHLIST_FAILURE,
  GET_WATCHLIST_SUCCESS,
  UPDATE_WATCHLIST_INIT,
  UPDATE_WATCHLIST_SUCCESS,
  UPDATE_WATCHLIST_FAILURE,
  REORDER_WATCHLIST,
  GET_ANALYSIS_INIT,
  GET_ANALYSIS_SUCCESS,
  GET_ANALYSIS_FAILURE,
  GET_TOP_MOVERS,
  GET_TOP_MOVERS_SUCCESS,
  GET_TOP_MOVERS_FAILURE,
  CLEAR_UPDATE_RESP,
  UPDATE_MARKET_SCRIPLIST,
  CLEAR_ANALYSIS,
  GET_ORDER_CHARGES,
  GET_ORDER_CHARGES_SUCCESS,
  GET_ORDER_CHARGES_FAILURE,
} from './actionTypes'
import { formatAnalysis, segSymConverter } from '../../utils/common'

const initialState = {
  placingOrder: false,
  directOrderResp: {},
  orderError: false,
  orderErrorMsg: '',
  // fetchWatchList
  initialFetchWatchList: true,
  isFetchingWatchList: false,
  market_watches: {},
  watchListError: false,
  watchListErrorMsg: '',
  // updateWatchList
  updateWatchListResp: null,
  isUpdatingWatchList: false,
  updateWatchListError: false,
  updateWatchListErrorParams: {},
  updateWatchListErrorMsg: '',
  isFetchingAnalysis: false,
  analysis: {},
  analysisError: false,
  analysisErrorMsg: '',
  top_movers: [],
  isFetchingTopMovers: false,
  topMoversError: false,
  topMoversErrorMsg: '',
  market_watch_names_list: [],
  newScripList: null,

  isFetchingOrderCharges: false,
  orderCharges: {},
  orderChargesError: false,
  orderChargesErrorMsg: '',
}

const market = (state = initialState, action) => {
  switch (action.type) {
    case DIRECT_ORDER_INIT:
      return {
        ...state,
        placingOrder: true,
        directOrderResp: {},
        orderError: false,
      }

    case DIRECT_ORDER_SUCCESS: {
      const { data } = action
      return {
        ...state,
        placingOrder: false,
        directOrderResp: data,
      }
    }

    case DIRECT_ORDER_FAILURE:
      return {
        ...state,
        placingOrder: false,
        orderError: true,
        orderErrorMsg: action.error,
      }

    case GET_WATCHLIST_INIT: {
      const market_watches = action.payload.params.complete ? {} : { ...state.market_watches }
      return {
        ...state,
        isFetchingWatchList: true,
        market_watches,
        watchListError: false,
      }
    }

    case GET_WATCHLIST_SUCCESS: {
      const { data = {} } = action
      const { market_watches, market_watch_names_list } = data
      const modData = {
        ...state.market_watches,
        ...market_watches,
      }
      let modMarketwatch = {}
      Object.keys(modData).forEach((itemKey) => {
        let itemData = {}
        try {
          const list = modData[itemKey]
          if (list && typeof list === 'string' && list !== 'undefined') {
            itemData = JSON.parse(list)
            // old format where basket is used to store as an array of sym_seg
            if (Array.isArray(itemData)) {
              const instrumentList = itemData.map(segSym => (
                { ...segSymConverter(segSym, true), id: segSym }
              ))
              itemData = { listName: itemKey, instrumentList, thumbnail: '' }
            }
          } else if (list && typeof list === 'object') {
            itemData = list
          } else {
            itemData = {}
          }
        } catch (e) {
          console.log(e, 'market watch parse')
          itemData = {}
        }
        modMarketwatch = { ...modMarketwatch, [itemKey]: itemData }
      })
      const oldNameList = state.market_watch_names_list
      return {
        ...state,
        initialFetchWatchList: false,
        isFetchingWatchList: false,
        market_watches: modMarketwatch,
        // doing this so that index of each item do not change
        market_watch_names_list: oldNameList.length === 0 ? market_watch_names_list : oldNameList,
      }
    }

    case GET_WATCHLIST_FAILURE:
      return {
        ...state,
        initialFetchWatchList: false,
        isFetchingWatchList: false,
        watchListError: true,
        watchListErrorMsg: action.error,
      }

    case UPDATE_WATCHLIST_INIT:
      return {
        ...state,
        isUpdatingWatchList: true,
        updateWatchListResp: null,
        updateWatchListError: false,
        updateWatchListErrorMsg: '',
        updateWatchListErrorParams: {},
      }

    case UPDATE_WATCHLIST_SUCCESS: {
      const { data } = action
      const { market_watch_names_list: oldNameList = [] } = state
      // doing this so that index of each item do not change
      const {
        market_watch_edit, market_watch_name, update_name, del,
      } = data
      let modNameList = [...oldNameList]
      if (oldNameList.length !== 0) {
        if (del) {
          modNameList = oldNameList.filter(item => item !== market_watch_name)
        } else if (!market_watch_edit) {
          modNameList.unshift(market_watch_name)
        } else if (update_name && market_watch_name) {
          const index = oldNameList.indexOf(market_watch_name)
          modNameList[index] = update_name
        }
      }
      return {
        ...state,
        isUpdatingWatchList: false,
        updateWatchListResp: data,
        market_watch_names_list: modNameList,
        // market_watches,
      }
    }

    case UPDATE_WATCHLIST_FAILURE:
      return {
        ...state,
        isUpdatingWatchList: false,
        updateWatchListError: true,
        updateWatchListErrorParams: action.params,
        updateWatchListErrorMsg: action.error || 'List update failed',
      }

    case REORDER_WATCHLIST: {
      const { watchList = [] } = action
      if(watchList.length) {
        return {
          ...state,
          watchList,
        }
      }
      return {
        ...state,
      }
    }

    case GET_ANALYSIS_INIT:
      return {
        ...state,
        isFetchingAnalysis: true,
        analysisError: false,
        analysis: {},
      }

    case GET_ANALYSIS_SUCCESS: {
      const { data, stock = '' } = action
      return {
        ...state,
        isFetchingAnalysis: false,
        analysis: data && !isEmpty(data) ? { [stock.toUpperCase()]: formatAnalysis(data) }
          : { [stock.toUpperCase()]: { noData: true } },
      }
    }

    case GET_ANALYSIS_FAILURE:
      return {
        ...state,
        isFetchingAnalysis: false,
        analysisError: true,
      }

    case CLEAR_ANALYSIS:
      return {
        ...state,
        isFetchingAnalysis: false,
        analysisError: false,
        analysis: {},
      }

    case GET_TOP_MOVERS:
      return {
        ...state,
        top_movers: [],
        isFetchingTopMovers: true,
        topMoversError: false,
        topMoversErrorMsg: '',
      }

    case GET_TOP_MOVERS_SUCCESS: {
      return {
        ...state,
        top_movers: action.data,
        isFetchingTopMovers: false,
      }
    }

    case GET_TOP_MOVERS_FAILURE:
      return {
        ...state,
        isFetchingTopMovers: false,
        topMoversError: true,
        topMoversErrorMsg: action.error,
      }

    case CLEAR_UPDATE_RESP:
      return {
        ...state,
        updateWatchListResp: null,
        isUpdatingWatchList: false,
        updateWatchListError: false,
        updateWatchListErrorMsg: '',
      }

    case UPDATE_MARKET_SCRIPLIST: {
      return {
        ...state,
        newScripList: action.list,
      }
    }

    case GET_ORDER_CHARGES: {
      return {
        ...state,
        isFetchingOrderCharges: true,
        orderChargesError: false,
        orderChargesErrorMsg: '',
      }
    }

    case GET_ORDER_CHARGES_SUCCESS: {
      const { data = {} } = action
      return {
        ...state,
        isFetchingOrderCharges: false,
        orderCharges: data,
      }
    }

    case GET_ORDER_CHARGES_FAILURE: {
      return {
        ...state,
        isFetchingOrderCharges: false,
        orderChargesError: true,
        orderChargesErrorMsg: action.error,
      }
    }

    default:
      return state
  }
}

export default market
