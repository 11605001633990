/* eslint-disable */
import { segSymConverter } from './common'
import { COMMODITY_TYPE } from './consts'

function broker_cal(seg, sym, qty, price, type = 'CNC', trigger_type = '') {
  let ttt = 0 // total tax and charges
  let turnover
  let comm = ''
  if(seg === 'MCX') {
    comm = sym.split(/[0-9]/)[0]
  }
  if (trigger_type === 'SO' && ['NSE', 'NFO-FUT', 'NFO-OPT', '', 'CDS-FUT', 'MCX'].includes(seg)) ttt += 50
  if (seg === 'NSE') {
    turnover = Math.abs(qty) * price
    if (type === 'MIS') {
      ttt += (turnover * 0.0003 > 20 ? 20 : turnover * 0.0003)
    }
    ttt += (0.00325 / 100 * turnover) // Transaction charges
    ttt += (0.18 * ttt) // GST on broker+transaction charges
    if (type === 'MIS' && qty < 0) {
      ttt += (0.025 * turnover / 100) // STT
    } else if (type !== 'MIS') { ttt += (0.1 * turnover / 100) } // STT
    ttt += 10 * (turnover / 10000000) // SEBI charges
    if (type === 'MIS' && qty > 0) {
      ttt += turnover * 300 / 10000000 // Stamp duty charges
    } else if (type !== 'MIS' && qty > 0) { ttt += turnover * 1500 / 10000000 }// Stamp duty charges
  } else if (seg === 'NFO-FUT' || sym.includes(', FUT,')) {
    turnover = Math.abs(qty) * price
    ttt += (turnover * 0.0003 > 20 ? 20 : turnover * 0.0003)
    ttt += 0.000019 * turnover // Transaction charges
    ttt += 0.18 * ttt // GST on broker+transaction charges
    if (qty < 0) { ttt += 0.000125 * turnover } // STT
    ttt += 10 * (turnover / 10000000) // SEBI charges
    if (qty > 0) { ttt += turnover * 200 / 10000000 }// Stamp duty charges
  } else if (seg === 'NFO-OPT' || sym.includes('DYNAMIC CONTRACT')) {
    turnover = Math.abs(qty) * price
    ttt += 20
    ttt += 0.05 / 100 * turnover // Transaction charges
    ttt += 0.18 * ttt // GST on broker+transaction charges
    if (qty < 0) { ttt += 0.0625 * turnover / 100 } // STT
    ttt += 10 * (turnover / 10000000) // SEBI charges
    if (qty > 0) { ttt += turnover * 300 / 10000000 }// Stamp duty charges
  } else if (seg === 'CDS-FUT') {
    turnover = Math.abs(qty) * price
    ttt += (turnover * 0.0003 > 20 ? 20 : turnover * 0.0003)
    ttt += 0.0009 / 100 * turnover // Transaction charges
    // ttt += 0.0002/100*turnover; //Transaction charges
    ttt += 0.18 * ttt // GST on broker+transaction charges
    if (qty < 0) { ttt += 0.0 } // STT
    ttt += 10 * (turnover / 10000000) // SEBI charges
    if (qty > 0) { ttt += turnover * 10 / 10000000 }// Stamp duty charges
  } else if (seg === 'MCX' && comm !== '') {
    let group = 'a'
    if (comm === 'PEPPER' || comm === 'CASTORSEED' || comm === 'RBDPMOLEIN') {
      group = 'b'
    }
    turnover = Math.abs(qty) * price
    ttt += (turnover * 0.0003 > 20 ? 20 : turnover * 0.0003) // brokerage
    if (group === 'a') {
      ttt += 0.0026 / 100 * turnover // Transaction charges
      // ttt += 0.001 / 100 * turnover // Transaction charges
    } else if (group === 'b') {
      switch (comm) {
        case 'PEPPER':
          ttt += 0.00005 / 100 * turnover // Transaction charges
          break
        case 'CASTORSEED':
          ttt += 0.0005 / 100 * turnover // Transaction charges
          break
        case 'RBDPMOLEIN':
          ttt += 0.001 / 100 * turnover // Transaction charges
          break
        default:
          break
      }
      // ttt += 0.001 / 100 * turnover // Transaction charges
    }
    ttt += 0.18 * ttt // GST on broker+transaction charges
    if (qty < 0 && COMMODITY_TYPE[comm] === 'NAGRI') {
      ttt += 0.01 * turnover / 100
    } // STT
    if(COMMODITY_TYPE[comm] === 'AGRI') {
      ttt += 1 * (turnover / 10000000) // SEBI charges
    } else {
      ttt += 10 * (turnover / 10000000) // SEBI charges
    }
    if (qty > 0) { ttt += turnover * 200 / 10000000 }// Stamp duty charges
  }
  return ttt
}

const processBrokerage = (seg_sym, trade_log, pnl, type = 'MIS') => {
  const xyList = []
  const trade_dict = {}
  let max_cap_used = 0
  const trade_log_brokerage = []
  const { segment: seg, symbol: sym } = segSymConverter(seg_sym)
  let trigger_type = ''

  for (let i = 0; i < trade_log.length; i++) {
    trade_dict[trade_log[i][0]] = trade_log[i].slice(1, 8)
  }
  let adj_price = null
  let total_brokerage_till_now = 0.0
  for (let i = 0; i < pnl.length; i++) {
    if (trade_dict[pnl[i][0]] !== undefined) {
      const price = trade_dict[pnl[i][0]][3]
      const qty = trade_dict[pnl[i][0]][2]
      trigger_type = trade_dict[pnl[i][0]][6]
      const brokerage = broker_cal(seg, sym, qty, price, type, trigger_type)
      total_brokerage_till_now += brokerage
      adj_price = parseFloat((pnl[i][1] - total_brokerage_till_now).toFixed(2))
      const totalCostWithBrokerage = (Math.abs(qty * price) + brokerage)
      if (max_cap_used < totalCostWithBrokerage) {
        max_cap_used = totalCostWithBrokerage
      }
      xyList.push(+adj_price)
    } else {
      adj_price = parseFloat((pnl[i][1] - total_brokerage_till_now).toFixed(2))
      xyList.push(+adj_price)
    }
  }

  total_brokerage_till_now = 0.0
  for (let i = 0; i < trade_log.length; i++) {
    if (trade_dict[trade_log[i][0]] !== undefined) {
      const price = trade_dict[trade_log[i][0]][3]
      const qty = trade_dict[trade_log[i][0]][2]
      trigger_type = trade_dict[trade_log[i][0]][6]
      const brokerage = broker_cal(seg, sym, qty, price, type, trigger_type)
      total_brokerage_till_now += brokerage
      const adj_pnl = parseFloat((trade_log[i][5] - total_brokerage_till_now).toFixed(2))
      trade_log_brokerage.push([...trade_log[i].slice(0, 5), adj_pnl, ...trade_log[i].slice(6)])
    }
  }
  return { price: xyList, max_cap_used, trade_log_brokerage }
}

export default processBrokerage
