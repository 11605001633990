import {
  FETCH_ORDERS_INIT,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  CANCEL_ORDER_INIT,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
  CLEAR_ORDERBOOK_CANCEL_RESP,
} from './actionTypes'

export function fetchOrders(fetchType) {
  return {
    type: FETCH_ORDERS_INIT,
    fetchType,
  }
}

export function fetchOrdersSuccess(data) {
  return {
    type: FETCH_ORDERS_SUCCESS,
    data,
  }
}

export function fetchOrdersFailure(error) {
  return {
    type: FETCH_ORDERS_FAILURE,
    error,
  }
}

export function cancelOrder(params, headers) {
  return {
    type: CANCEL_ORDER_INIT,
    params,
    headers,
  }
}

export function cancelOrderSuccess(data) {
  return {
    type: CANCEL_ORDER_SUCCESS,
    data,
  }
}

export function cancelOrderFailure(error) {
  return {
    type: CANCEL_ORDER_FAILURE,
    error,
  }
}

export function clearCancelResp() {
  return {
    type: CLEAR_ORDERBOOK_CANCEL_RESP,
  }
}
