import {
  CREATE_ORDER_INIT,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_SUCCESS,
  FETCH_PAYMENT_PARAMS_INIT,
  FETCH_PAYMENT_PARAMS_SUCCESS,
  FETCH_PAYMENT_PARAMS_FAILURE,
  FETCH_PAYMENT_METHOD_INIT,
  FETCH_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHOD_FAILURE,
  FETCH_SUBSCRIPTION_PRICE_INIT,
  FETCH_SUBSCRIPTION_PRICE_SUCCESS,
  FETCH_SUBSCRIPTION_PRICE_FAILURE,
  CLEAR_ORDER_RESP,
  PAY_WITH_CREDITS,
  PAY_WITH_CREDITS_SUCCESS,
  PAY_WITH_CREDITS_FAILURE,
} from './actionTypes'

export function createOrder(headers, params) {
  return {
    type: CREATE_ORDER_INIT,
    headers,
    params,
  }
}

export function createOrderFailure(error) {
  return {
    type: CREATE_ORDER_FAILURE,
    error,
  }
}

export function createOrderSuccess(data) {
  return {
    type: CREATE_ORDER_SUCCESS,
    data,
  }
}

export function fetchPaymentParams(params, headers, errorDesc) {
  return {
    type: FETCH_PAYMENT_PARAMS_INIT,
    headers,
    params,
    errorDesc,
  }
}

export function fetchPaymentParamsSuccess(data, errorDesc) {
  return {
    type: FETCH_PAYMENT_PARAMS_SUCCESS,
    data,
    errorDesc,
  }
}

export function fetchPaymentParamsFailure(error) {
  return {
    type: FETCH_PAYMENT_PARAMS_FAILURE,
    error,
  }
}

export function fetchPaymentMethods(params, headers) {
  return {
    type: FETCH_PAYMENT_METHOD_INIT,
    headers,
    params,
  }
}

export function fetchPaymentMethodsSuccess(data) {
  return {
    type: FETCH_PAYMENT_METHOD_SUCCESS,
    data,
  }
}

export function fetchPaymentMethodsFailure(error) {
  return {
    type: FETCH_PAYMENT_METHOD_FAILURE,
    error,
  }
}

export function fetchSubscriptionPrice(params) {
  return {
    type: FETCH_SUBSCRIPTION_PRICE_INIT,
    params,
  }
}

export function fetchSubscriptionPriceSuccess(data) {
  return {
    type: FETCH_SUBSCRIPTION_PRICE_SUCCESS,
    data,
  }
}

export function fetchSubscriptionPriceFailure(error) {
  return {
    type: FETCH_SUBSCRIPTION_PRICE_FAILURE,
    error,
  }
}

export function clearOrderResp(params) {
  return {
    type: CLEAR_ORDER_RESP,
    params,
  }
}

export function payWithCredits(params) {
  return {
    type: PAY_WITH_CREDITS,
    params,
  }
}

export function payWithCreditsSuccess(data) {
  return {
    type: PAY_WITH_CREDITS_SUCCESS,
    data,
  }
}

export function payWithCreditsFailure(error) {
  return {
    type: PAY_WITH_CREDITS_FAILURE,
    error,
  }
}
