import React, { forwardRef, useState } from 'react'
import Tippy from '@tippyjs/react'
import { makeStyles } from '@material-ui/styles'

import CustomText from './CustomText'

import { SPACING, theme } from '../Theme'

const CustomTooltip = forwardRef((props, ref) => {
  const [mounted, setMounted] = useState(false)
  const {
    show,
    children,
    tooltipProps,
    contentRenderer,
    interactive = false,
    placement = 'top',
    showArrow = true,
    tooltipContainerStyles = '',
    delay = [0, 0],
    duration = [0, 0],
    visible,
    onClickOutside,
  } = props
  const styles = stylesheet()
  const { text, textProps = {} } = tooltipProps

  if (!show) {
    return children
  }

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  }

  const renderTooltip = () => (
    <CustomText weight="medium" size="small" color="white" {...textProps}>{text}</CustomText>
  )
  const tooltipContent = contentRenderer ? contentRenderer(tooltipProps) : renderTooltip()

  const computedProps = { }

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])]

  if (props.render) {
    computedProps.render = (...args) => (mounted ? props.render(...args) : '')
  } else {
    computedProps.content = mounted ? tooltipContent : ''
  }

  return (
    <Tippy
      interactiveBorder={30}
      interactive={interactive}
      animation={false}
      className={`${styles.tooltipContainer} ${tooltipContainerStyles}`}
      placement={placement}
      arrow={showArrow}
      delay={delay}
      duration={duration}
      ref={ref}
      visible={visible}
      onClickOutside={onClickOutside}
      appendTo={document.body}
      {...computedProps}
    >
      {children}
    </Tippy>
  )
})

const stylesheet = makeStyles({
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    borderRadius: 6,
    backgroundColor: theme.tooltipBg, // '#1A1A1A',
  },
})

export default CustomTooltip
