import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'

import Button from '../../UI/Button'
import Icon from '../../UI/Icon'
import CustomText from '../../UI/CustomText'

import {
  SPACING, COLORS, ICONS, theme,
} from '../../Theme'

const InputOptions = React.memo(({
  tree,
  onDismiss,
  dismissKey,
  onItemSelect,
  onRemove,
  sectionType,
  selectedName,
  isDark,
  keysToHide,
}) => {
  const optionTitle = Object.keys(tree)[0]
  if (!optionTitle) {
    onDismiss(dismissKey)
    return null
  }
  const styles = stylesheet()
  const fTree = tree[optionTitle]
  let optionKeys = Object.keys(fTree)
  if (keysToHide && keysToHide.length) {
    optionKeys = optionKeys.filter(indiKey => !keysToHide.includes(indiKey))
  }
  const extraStyles = isDark ? { borderBottomColor: COLORS.GREY_300 } : {}
  return (
    <div className={styles.container}>
      <div className={styles.header} style={extraStyles}>
        <CustomText weight="semi_bold" size="large" className={styles.optionTitle}>
          {optionTitle}
        </CustomText>
        <Button
          text="Delete"
          onPress={onRemove}
          buttonColor="RED"
          btnStyles={styles.deleteBtn}
        />
      </div>
      <div className={styles.options}>
        {optionKeys.map((itemKey) => {
          const item = fTree[itemKey]
          const { name } = item
          return (
            <button
              type="button"
              key={name}
              className={styles.itemBtn}
              style={isDark && selectedName === name ? { backgroundColor: COLORS.BLACK_200 } : {}}
              onClick={() => onItemSelect(item, sectionType, optionTitle, null, itemKey)}
            >
              <div className={styles.itemInfo}>
                <CustomText className={styles.itemName}>{name}</CustomText>
              </div>
              {selectedName === name && (
              <div
                style={isDark ? { backgroundColor: COLORS.WHITE, borderRadius: '50%' } : {}}
                className={styles.selectedIcon}
              >
                <Icon name={ICONS.TICK_ROUND_FILLED} size={18} color={COLORS[isDark ? 'BLUE_300' : 'BLUE']} />
              </div>
              )}
            </button>
          )
        })}
      </div>

    </div>
  )
})

const stylesheet = makeStyles({
  container: {
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.bgPrimary,
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
  },
  optionTitle: {
    textTransform: 'capitalize',
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  itemBtn: {
    width: '100%',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  item: {},
  itemInfo: {},
  actionBar: {},
  deleteBtn: {},
  selectedIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default InputOptions
