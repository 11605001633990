/**
how to use
 <CustomInput
    inputError={null}
    inputValue={}
    inputHandler={e => null}
    inputPlaceHolder=""
    containerClass={classes.}
    label=""
    inputClass={classes.}
  />
*/

import React, { useCallback } from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/styles'

import Icon from './Icon'
import CustomText from './CustomText'

import {
  FONTS, theme, ICONS, COLORS, SPACING,
} from '../Theme'

let prevBgColor = 'transparent'

const CustomInput = (props) => {
  const {
    iconName: inputIcon,
    inputType = 'text',
    inputIconProps = {},
    iconContainer = '',
    size = 18,
    iconColor = 'text',
    focusColor = theme.linkColor,
    errorColor = COLORS.RED,
    inputBorderColor = theme.inputBorder,
    inputError,
    value: inputValue,
    onChangeText: inputHandler,
    placeholder: inputPlaceHolder,
    iconPosition = 'start',
    containerStyles: containerClass,
    inputErrorText,
    inputProps = {},
    onInputFocus = () => null,
    inputStyles: inputClass,
    label,
    labelClass = '',
    subComponent,
    multiline,
    disabled,
    onBlur = () => null,
    extraStyles,
    inputRef,
    pasteDisabled,
    copyDisabled,
    inputRegx,
    showClearBtn,
    wrapperStyles,
    inputKey,
    isIconBtn = false,
    onIconClick,
    inputErrStyles = '',
    backgroundColor,
    fontSize = FONTS.REGULAR,
    errPos,
    colorLabel,
    labelSize,
    startTour,
    changePtComponentStatus,
  } = props
  const classes = stylesheet()
  const borderColor = inputError ? `${errorColor}` : inputBorderColor
  const labelColor = inputError ? errorColor : colorLabel || theme.textSecondary
  let additionalProps = {}
  // const borderWidth = inputError ? 1 : 1
  const inputContainerClass = containerClass
    ? classnames(classes.sInputContainer, containerClass) : classes.sInputContainer
  let extraPadding = {}
  // let width = 'auto'
  if (inputIcon) {
    const iconSize = inputIconProps && inputIconProps.width ? inputIconProps.width : size
    extraPadding = iconPosition === 'start' ? { paddingLeft: `${iconSize + 24}px` } : {
      paddingRight: `${iconSize + 16}px`,
    }
    // width = `calc(100% - ${iconSize + 30}px)`
  }
  const onInputChange = useCallback((e) => {
    if (inputRegx && e.target.value) {
      if (inputRegx.test(e.target.value)) inputHandler(e, inputKey)
      return
    }
    inputHandler(e, inputKey)
  }, [inputRegx, inputHandler])
  const clearValue = () => {
    inputHandler({ target: { value: '' } }, inputKey)
  }
  const onFocusHandler = (e) => {
    if(startTour) {
      setTimeout(() => {
        changePtComponentStatus('openCreatePopup')
      }, 500)
    }
    if (inputError) {
      onInputFocus(e)
      return
    }
    e.target.style.borderColor = focusColor
    prevBgColor = e.target.style.backgroundColor
    e.target.style.backgroundColor = theme.bgPrimary
    if (label && document.getElementById(label)) {
      document.getElementById(label).style.color = theme.blue
    }
    // e.target.style.borderWidth = '2px'
    onInputFocus(e)
  }
  const onBlurHandler = (e) => {
    if (inputError) {
      onBlur(e, inputKey)
      return
    }
    e.target.style.borderColor = inputBorderColor
    e.target.style.borderWidth = '1px'
    e.target.style.backgroundColor = prevBgColor
    if(extraStyles) e.target.style.borderWidth = extraStyles.borderWidth
    if (label && document.getElementById(label)) {
      document.getElementById(label).style.color = theme.textSecondary
    }
    onBlur(e)
  }
  let sInputClass = inputClass ? classnames(classes.sInput, inputClass) : classnames(classes.sInput, label ? classes.labelInput : '')
  sInputClass = disabled ? classnames(classes.disabled, sInputClass) : sInputClass
  const sInputLabelClass = labelClass ? classnames(classes.sInputLabel, labelClass)
    : classes.sInputLabel
  let iconContainerClass = iconContainer ? classnames(iconContainer, classes.sInputIconContainer)
    : classes.sInputIconContainer
  iconContainerClass = iconPosition !== 'start' ? classnames(iconContainerClass, classes.iconEnd)
    : iconContainerClass
  if (pasteDisabled) {
    additionalProps = {
      ...additionalProps,
      onPaste: (e) => {
        e.preventDefault()
        return false
      },
    }
  }
  if (copyDisabled) {
    additionalProps = {
      ...additionalProps,
      onCopy: (e) => {
        e.preventDefault()
        return false
      },
      onCut: (e) => {
        e.preventDefault()
        return false
      },
    }
  }
  return (
    <div className={classnames(classes.sWrapper, wrapperStyles)}>
      <div className={inputContainerClass}>

        {inputErrorText && inputError && errPos === 'top' ? (
          <CustomText
            className={`${classes.sInputErrorTop} ${inputErrStyles}`}
            style={{
              color: `${errorColor}`,
            }}
            size="small"
          >
            {inputErrorText}
          </CustomText>
        ) : null}
        {subComponent}
        {label && (
        <CustomText
          className={sInputLabelClass}
          extraProps={{ id: label }}
          size={labelSize || 'small'}
          weight="medium"
          style={{
            color: labelColor,
          }}
        >
          {label}
        </CustomText>
        )}
        <div className={classes.sInputWrapper}>
          {inputIcon && iconPosition === 'start' ? (
            <div className={iconContainerClass}>
              <Icon
                name={ICONS[inputIcon.toUpperCase()]}
                size={size}
                color={theme[iconColor]}
                {...inputIconProps}
              />
            </div>
          ) : null}
          {multiline ? (
            <textarea
              className={sInputClass}
              type={inputType}
              value={inputValue}
              onChange={onInputChange}
              placeholder={inputPlaceHolder}
              style={{
                borderColor,
                // borderWidth,
                ...extraPadding,
                // width,
                backgroundColor,
                fontSize,
                resize: 'none',
              }}
              onFocus={e => !disabled && onFocusHandler(e)}
              onBlur={e => onBlurHandler(e)}
              {...inputProps}
              readOnly={inputProps.readOnly || disabled}
            />
          ) : (
            <input
              className={sInputClass}
              type={inputType}
              value={inputValue}
              onChange={onInputChange}
              placeholder={inputPlaceHolder}
              style={{
                borderColor,
                // borderWidth,
                ...extraPadding,
                // width,
                ...extraStyles,
              }}
              onFocus={e => !disabled && onFocusHandler(e)}
              onBlur={e => onBlurHandler(e)}
              ref={inputRef}
              {...additionalProps}
              {...inputProps}
              readOnly={inputProps.readOnly || disabled}
            />
          )}
          {Boolean(showClearBtn && inputValue) && (
          <button
            type="button"
            onClick={clearValue}
            className={classes.clearBtn}
          >
            <Icon name={ICONS.CLOSE_FILLED} size={14} color={theme.textSecondary} />
          </button>
          )}
          {inputIcon && iconPosition === 'end' ? (
            <button type="button" disabled={!isIconBtn} onClick={onIconClick} className={iconContainerClass}>
              <Icon
                name={ICONS[inputIcon.toUpperCase()]}
                size={size}
                color={theme[iconColor]}
                {...inputIconProps}
              />
            </button>
          ) : null}
        </div>
        {inputErrorText && inputError && errPos !== 'top' ? (
          <CustomText
            className={`${classes.sInputError} ${inputErrStyles}`}
            style={{
              color: `${errorColor}`,
            }}
            size="small"

          >
            {inputErrorText}
          </CustomText>
        ) : null}
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  sWrapper: {},
  sInputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  sInputIconContainer: {
    position: 'absolute',
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '0.9rem 0.5rem',
  },
  iconEnd: {
    paddingLeft: 0,
    right: 10,
  },
  sInput: {
    border: `1px solid ${theme.inputBorder}`,
    borderRadius: 8,
    width: '100%',
    padding: SPACING.SPACE_10,
    fontSize: FONTS.REGULAR,
    background: theme.tabsBg,
    boxSizing: 'border-box',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.placeholderDarkColor,
      fontWeight: 300,
    },
  },
  disabled: {
    cursor: 'no-drop',
    borderColor: `${theme.inputBorder} !important`,
  },
  sInputError: {
    marginTop: '0.5rem',
  },
  sInputErrorTop: {
    marginBottom: '0.3rem',
  },
  sInputLabel: {
    color: theme.textSecondary,
    marginBottom: 8,
  },
  labelInput: {
    padding: '0.5rem 0.1rem',
  },
  clearBtn: {
    display: 'flex',
    position: 'absolute',
    right: '10px',
  },
  sInputWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

  },
  '@media only screen and (min-width: 1366px) and (max-width: 1440px)': {
    sInput: {
      fontSize: 12,
    },
    sInputIconContainer: {
      // padding: '0.8rem 0.5rem',
    },
  },
  '@media only screen and (min-width: 1024px) and (max-width: 1366px)': {
    sInput: {
      fontSize: 12,
    },
    sInputIconContainer: {
      // padding: '0.8rem 0.5rem',
    },
  },
})

export default React.forwardRef((props, ref) => (
  <CustomInput inputRef={ref} {...props} />
))
