import React from 'react'

import HOME_ASSETS from '../assets'

export const brokerImgMap = [
  { imgSrc: HOME_ASSETS.Zerodha, brokerName: 'Zerodha' },
  { imgSrc: HOME_ASSETS.ICICI, brokerName: 'Icici' },
  { imgSrc: HOME_ASSETS.AngelOne, brokerName: 'Angel One' },
  { imgSrc: HOME_ASSETS.Upstox, brokerName: 'Upstox' },
  { imgSrc: HOME_ASSETS.FivePaisa, brokerName: '5Paisa' },
]

const AboutUs = React.memo(({ isMobile }) => {
  return (
  // <div className="homeWrapper">
  //   <div className="aboutUsContainer" style={{ width: '65%', alignItems: 'flex-start' }}>
  //     <h2 className="aboutBrokerText" style={{ fontSize: '22px !important' }}>
  //       Trusted by Top Brokers in India.
  //     </h2>
  //     <div className="brokerImgContainer">
  //       {brokerImgMap.map((data) => {
  //         const { brokerName, imgSrc, width = isMobile ? '200px' : '150px' } = data
  //         return <img src={imgSrc}
  // alt={`${brokerName} img`} width={width} key={brokerName}
  // style={{ paddingTop: isMobile && brokerName === '5Paisa'
  //  ? '15px' : '0px' }} className="brokerImg" />
  //       })}
  //     </div>
  //   </div>
  // </div>
    <>
      {(isMobile)
    && (
    <div className="brokersWrapper">
      <div className="brokersContainer">
        <p className="brokerText">
          TRUSTED BY TOP BROKERS IN INDIA
        </p>
        <div className="brokerImgContainer">
          {brokerImgMap.map((data, index) => {
            const { brokerName, imgSrc } = data
            return <img src={imgSrc} alt={`${brokerName} img`} key={brokerName} className={`bannerBrokerImg broker${index + 1}`} />
          })}
        </div>
      </div>
    </div>
    )}
    </>
  )
})

export default AboutUs
