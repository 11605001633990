import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'

import CustomModal from '../../UI/CustomModal'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import ProfileImage from '../ProfileImage'

import {
  ICONS, theme, ASSETS, SPACING, FONTS,
} from '../../Theme'
import { getDeployedCount } from '../../containers/Deployed/actions'
import { pathname_mapping } from '../../utils/navigationConstant'
import { NOTIFICATIONS_SECTION_MAP } from '../../containers/Notifications/notificationUtils'
import { togglePtSideBar, toggleTour } from '../../containers/Common/actions'

const {
  FAB_OPEN, FAB_EXECUTED, FAB_HOLDINGS, FAB_POSITIONS, FAB_PAPER, FAB_LIVE,
  ScannerFilledDark, ScannerFilled,
} = ASSETS

const MenuItem = React.memo(({
  label, value, onPress, extraParams, styles,
}) => {
  const {
    pathname, iconName, iconProps = { }, imgName, count,
  } = extraParams
  return (
    <button key={value} type="button" onClick={() => onPress(pathname)} className={styles.menuRowBtn}>
      <div className={styles.menuRow}>
        {imgName ? <img src={imgName} alt={value} className={styles.menuRowImg} />
          : <Icon name={ICONS[iconName]} size={14} color={theme.voilet} {...iconProps} />}
        <div className={styles.menuRowInfo}>
          <CustomText weight="medium" style={{ flex: 1 }}>
            {label}
            {count ? <span className={styles.countText}>{count}</span> : null}
          </CustomText>
          {/* <CustomText size="small" color="textSecondary" style={{ flex: 1 }}>
            {subTitle}
          </CustomText> */}
        </div>
        <Icon name={ICONS.FORWARD} color={theme.textSecondary} size={10} />
      </div>
    </button>
  )
})

const ProfileFabOptions = React.memo(({
  showQuickNavModal, toggleQuickNavModal, fetchDeployedCount,
  deployedCountObj, isDark, toggleTourSideBar, ptSideBar,
}) => {
  const styles = stylesheet()
  const hideQuickNav = () => toggleQuickNavModal(false)
  const history = useHistory()

  const routeTo = (pathname) => {
    history.push(pathname)
    hideQuickNav()
  }

  useEffect(() => fetchDeployedCount(), [])

  const deployedSectionCount = useCallback(
    (section, keys) => {
      const data = deployedCountObj[section.DEPLOYED_COUNT_KEY]
      if (isEmpty(data)) return 0
      let count = 0
      keys.forEach((item) => {
        count += data[item] || 0
      })
      return count
    },
    [deployedCountObj],
  )
  const notifCount = deployedSectionCount(NOTIFICATIONS_SECTION_MAP.LIVE, ['waiting', 'entered'])
  const paperCount = deployedSectionCount(NOTIFICATIONS_SECTION_MAP.PAPER, ['waiting', 'entered'])
  const liveCount = deployedSectionCount(NOTIFICATIONS_SECTION_MAP.SCANNER, ['live'])
  return (
    <CustomModal
      visible={showQuickNavModal}
      dismissKey="showQuickNavModal"
      onDismiss={hideQuickNav}
      containerStyles={styles.fabModalContainer}
      mainContainerStyles={styles.fabModal}
    >
      <div className={styles.fabContainer}>
        <div className={styles.content}>
          <CustomText weight="semi_bold" size="regular_2">Deployed</CustomText>
          <div className={styles.menuItems}>
            <MenuItem
              label="Live Trade"
              value="notifications"
              onPress={routeTo}
              extraParams={{
                pathname: pathname_mapping.live,
                // iconName: 'NOTIF_AUTO',
                imgName: FAB_LIVE,
                // subTitle: notifCount ? `${notifCount} Deployed` : 'Not deployed',
                count: notifCount,
              }}
              styles={styles}
            />
            <MenuItem
              label="Paper Trade"
              value="paper"
              onPress={routeTo}
              extraParams={{
                pathname: pathname_mapping.paper,
                // iconName: 'NOTIF_PAPER'
                imgName: FAB_PAPER,
                // subTitle: paperCount ? `${paperCount} Deployed` : 'Not deployed',
                count: paperCount,
              }}
              styles={styles}
            />
            <MenuItem
              label="Live scanners"
              value="scanners"
              onPress={routeTo}
              extraParams={{
                pathname: pathname_mapping.live_scans,
                // iconName: 'NOTIF_SCANNER',
                imgName: isDark ? ScannerFilledDark : ScannerFilled,
                // subTitle: liveCount ? `${liveCount} Live` : 'Not live',
                count: liveCount,
              }}
              styles={styles}
            />
          </div>
        </div>
        <div className={styles.content}>
          <CustomText weight="semi_bold" size="regular_2">Orderbook</CustomText>
          <div className={styles.menuItems}>
            <MenuItem
              label="Open orders"
              value="pending"
              onPress={routeTo}
              extraParams={{
                pathname: pathname_mapping.pending_orders,
                imgName: FAB_OPEN, // isDark ? CubeFilledDark : CubeFilled,
                // subTitle: 'Inprogress',

              }}
              styles={styles}
            />
            <MenuItem
              label="Executed orders"
              value="executed"
              onPress={routeTo}
              extraParams={{
                pathname: pathname_mapping.executed_orders,
                imgName: FAB_EXECUTED, // isDark ? FlowerFilledDark : FlowerFilled,
                // subTitle: 'Completed',

              }}
              styles={styles}
            />
          </div>
        </div>
        <div className={styles.content}>
          <CustomText weight="semi_bold" size="regular_2">Portfolio</CustomText>
          <div className={styles.menuItems}>
            <MenuItem
              label="Positions"
              value="positions"
              onPress={routeTo}
              extraParams={{
                pathname: pathname_mapping.positions,
                // subTitle: 'Inprogress',
                imgName: FAB_POSITIONS,

              }}
              styles={styles}
            />
            <MenuItem
              label="Holdings"
              value="holdings"
              onPress={routeTo}
              extraParams={{
                pathname: pathname_mapping.holdings,
                // subTitle: 'Completed',
                imgName: FAB_HOLDINGS,
              }}
              styles={styles}
            />
          </div>
        </div>
        <div className={styles.content}>
          <button
            type="button"
            onClick={() => {
              toggleTourSideBar(!ptSideBar)
              hideQuickNav()
            }}
            className={styles.menuRowBtn}
          >
            <div className={styles.menuRow}>
              <Icon
                name={ICONS.TOUR}
                size={18}
              />
              <CustomText weight="medium" style={{ flex: 1, marginLeft: SPACING.SPACE_18 }}>
                Tour
              </CustomText>
              <Icon name={ICONS.FORWARD} color={theme.textSecondary} size={10} />
            </div>
          </button>
        </div>
        <div className={styles.content}>
          <button type="button" onClick={() => routeTo(pathname_mapping.profile)} className={styles.menuRowBtn}>
            <div className={styles.menuRow}>
              <ProfileImage showUpload={false} imgContainerStyles={styles.profileImgContainer} />
              <CustomText weight="medium" style={{ flex: 1 }}>
                My Profile
              </CustomText>
              <Icon name={ICONS.FORWARD} color={theme.textSecondary} size={10} />
            </div>
          </button>
        </div>
      </div>
    </CustomModal>
  )
})

const stylesheet = makeStyles({
  fabModalContainer: {
    backgroundColor: 'transparent !important',
    maxHeight: '100%',
  },
  fabModal: {
    height: `calc(100% - ${SPACING.HEADER_HEIGHT}px)`,
    '& > button > div': {
      height: `calc(100% - ${SPACING.HEADER_HEIGHT}px)`,
    },
  },
  fabContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  content: {
    backgroundColor: theme.bgPrimary,
    borderRadius: 10,
    width: '100%',
    maxWidth: 240,
    marginBottom: SPACING.SPACE_20,
    padding: `${SPACING.SPACE_10} 0`,
    '& > p': {
      margin: `${SPACING.SPACE_8} auto ${SPACING.SPACE_6}`,
      width: '100%',
      maxWidth: 180,
    },
    '&:last-child': {
      marginBottom: SPACING.SPACE_10,
    },
  },
  menuItems: {
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 180,
    margin: 'auto',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_6} ${SPACING.SPACE_10} 0`,
  },
  menuRowBtn: {
    width: '100%',
    textAlign: 'left',
  },
  menuRowImg: {
    height: 20,
  },
  countText: {
    position: 'relative',
    bottom: 8,
    left: 0,
    borderRadius: '50%',
    color: '#fff',
    padding: '2px 6px',
    // display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.orange,
    fontSize: FONTS.SMALL_0,
    // verticalAlign: 'middle',
  },
  menuRowInfo: {
    marginLeft: SPACING.SPACE_16,
    flex: 1,
    position: 'relative',
  },
  profileImgContainer: {
    width: '20px',
    height: '20px',
    flex: 'unset',
    marginRight: SPACING.SPACE_16,
  },
})

const mapStateToProps = (state) => {
  return {
    csrf: state.auth.loginResp.csrf,
    deployedCountObj: state.deployed.deployedCountObj,
    isFetchingNotifications: state.notification.isFetchingNotifications,
    isFetchingDeployedCount: state.deployed.isFetchingDeployedCount,
    ptSideBar: state.common.ptSideBar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDeployedCount: headers => dispatch(getDeployedCount(headers)),
    toggleTourSideBar: params => dispatch(togglePtSideBar(params)),
    showTour: (params, tourType) => dispatch(toggleTour(params, tourType)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFabOptions)
