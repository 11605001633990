import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'

import CustomText from './CustomText'
import Icon from './Icon'

import { theme, ICONS, SPACING } from '../Theme'
import { PRECISION } from '../utils/consts'

export const CURRENCY_SYM = <span style={{ fontFamily: 'sans-serif' }}>{'\u20B9'}</span>

/**
 * @augments {Component<Props,State>}
 */
const ShowLTP = React.memo(({
  change = 0,
  ltp,
  seg_sym,
  containerStyles,
  ltpStyles,
  changeStyles,
  disabled,
  changeContainerStyles,
  hideChange,
  showLtpColor,
  hideChangeColor,
  hideChangeIcon,
  showBrackets,
  iconSize,
  hideCurrency,
  showLtpIcon,
  ltpIconProps,
  showLtp,
  changeSize,
  sendLTP,
}) => {
  const styles = styleheet()
  const precision = seg_sym.toLowerCase().includes('cds') ? 4 : PRECISION
  const currency = hideCurrency ? '' : CURRENCY_SYM
  let color = 'text'
  let ltpColor = 'text'
  let ltpIconColor = 'text'
  const icon_name = change < 0 ? ICONS.DOWN_ARROW : ICONS.UP_ARROW
  const ltpIconName = change < 0 ? ICONS.DOWN_HEAD_FILLED : ICONS.UP_HEAD_FILLED

  useEffect(() => {
    if (sendLTP) sendLTP(ltp)
  }, [ltp])

  if (showLtpColor && showLtp) {
    ltpColor = change < 0 ? 'red' : 'green'
    ltpIconColor = ltpColor
  }
  if (!hideChangeColor) {
    color = change < 0 ? 'red' : 'green'
  }

  if (disabled) {
    color = 'textSecondary'
    ltpColor = 'textSecondary'
  }
  let ltpValue = (+ltp).toFixed(precision) || ltp || 'N/A'
  ltpValue = ltpValue !== 'N/A' && currency ? (
    <>
      {currency}
      {` ${ltpValue}`}
    </>
  ) : ltpValue
  let changeValue = change > 0 ? `+${(+change).toFixed(2)}%` : `${(+change).toFixed(2)}%`
  changeValue = showBrackets ? `(${changeValue})` : changeValue
  return (
    <div className={classnames(styles.container, containerStyles)}>
      <div className={styles.ltpContainer}>
        {showLtpIcon && change !== 0 && (
        <Icon
          name={ltpIconName}
          size={8}
          color={theme[ltpIconColor]}
          style={{ marginRight: SPACING.SPACE_4, marginTop: SPACING.SPACE_2 }}
          {...ltpIconProps(change)}
        />
        )}
        {showLtp && (
        <CustomText
          className={classnames(styles.ltp, ltpStyles)}
          style={{ color: theme[ltpColor] }}
        >
          {ltpValue !== 'N/A' ? ltpValue : 'N/A'}
        </CustomText>
        )}
      </div>
      {!(hideChange && hideChangeIcon) ? (
        <div className={classnames(styles.changeContainer, changeContainerStyles)}>
          {!hideChangeIcon ? (
            <Icon
              style={{ marginBottom: 3, marginLeft: SPACING.SPACE_8 }}
              name={icon_name}
              size={iconSize}
              color={theme[color]}
            />
          ) : null}
          {!hideChange ? (
            <CustomText
              className={classnames(styles.changeValue, changeStyles)}
              style={{ color: theme[color] }}
              size={changeSize || 'small_0'}
            >
              {changeValue}
            </CustomText>
          ) : null}
        </div>
      ) : null}
    </div>
  )
})

const styleheet = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  ltpContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  changeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // marginLeft: SPACING.SPACE_8,
  },
  changeValue: {
    marginLeft: SPACING.SPACE_4,
  },
  ltp: {
    whiteSpace: 'nowrap',
  },
}))

const mapStateToProps = (state, ownProps) => {
  const { seg_sym_list = {}, ticks } = state.instruments
  const { seg_sym } = ownProps
  let change = 0
  let ltp = 0
  if(seg_sym && seg_sym_list[seg_sym] && ticks[seg_sym_list[seg_sym]]) {
    ({ change, last_price: ltp } = ticks[seg_sym_list[seg_sym]])
  }
  return {
    change,
    ltp,
  }
}

ShowLTP.defaultProps = {
  containerStyles: '',
  changeContainerStyles: '',
  ltpStyles: '',
  changeStyles: '',
  disabled: false,
  showLtpColor: true,
  hideChangeColor: false,
  hideChange: false,
  hideChangeIcon: true,
  hideCurrency: false,
  showBrackets: false,
  iconSize: 12,
  ltpIconProps: () => {},
  showLtpIcon: false,
  showLtp: true,
}

ShowLTP.propTypes = {
  seg_sym: PropTypes.string.isRequired,
  containerStyles: PropTypes.string,
  changeContainerStyles: PropTypes.string,
  ltpStyles: PropTypes.string,
  changeStyles: PropTypes.string,
  disabled: PropTypes.bool,
  showLtpColor: PropTypes.bool,
  hideChangeColor: PropTypes.bool,
  hideChange: PropTypes.bool,
  hideChangeIcon: PropTypes.bool,
  hideCurrency: PropTypes.bool,
  showBrackets: PropTypes.bool,
  iconSize: PropTypes.number,
  ltpIconProps: PropTypes.func,
  showLtpIcon: PropTypes.bool,
  showLtp: PropTypes.bool,
}

export default connect(mapStateToProps)(ShowLTP)

/*
  How to Use
  <ShowLTP
    containerStyle={classes.ltpContainer}
    ltpStyle={classes.ltp}
    changeStyle={classes.changeValue}
    segSym={seg_sym}
  />
*/
