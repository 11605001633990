import {
  SCAN_STOCKS_INIT,
  SCAN_STOCKS_SUCCESS,
  SCAN_STOCKS_FAILURE,
  SAVE_SCREEN_SUCCESS,
  SAVE_SCREEN_FAILURE,
  SAVE_SCREEN_INIT,
  SCREENER_STATE_INIT,
  SCREENER_STATE_SUCCESS,
  SCREENER_STATE_FAILURE,
  CLEAR_SCREENER_RESP,
  PLACE_DIRECT_INIT,
  PLACE_DIRECT_SUCCESS,
  PLACE_DIRECT_FAILURE,
  SCREENER_TRAINER_INIT,
  SCREENER_TRAINER_SUCCESS,
  SCREENER_TRAINER_FAILURE,
  SCREENER_TREE_INIT,
  SCREENER_TREE_SUCCESS,
  SCREENER_TREE_FAILURE,
  RESET_SCREENER_TREE_FETCH,
  COPY_SCREENER,
  COPY_SCREENER_SUCCESS,
  COPY_SCREENER_FAILURE,
  COPY_SHARED_SCREENER,
  GET_SCRIP_LOT_SIZE_INIT,
  GET_SCRIP_LOT_SIZE_SUCCESS,
  GET_SCRIP_LOT_SIZE_FAILURE,
} from './actionTypes'
import TestTree from '../../components/Create/TestTree.json'

const initialState = {
  isScanning: false,
  scannedStocks: {
    stocks: [],
  },
  scanStatus: false,
  scanError: false,
  scanErrorMsg: '',
  saveScreenResp: {},
  isSavingScreen: false,
  saveScreenError: false,
  saveScreenErrorMsg: '',

  screenerState: {},
  isGettingScreenerState: false,
  screenerStateError: false,
  screenerStateErrorMsg: '',

  isPlacingDirectOrder: false,
  placeDirectResp: {},
  placeDirectError: false,
  placeDirectErrorMsg: '',
  isFetchingScreenerTrainer: false,
  screenerTrainer: {},
  screenerTrainerError: false,
  screenerTrainerErrorMsg: '',
  tree: {},
  treeFetched: false,
  isFetchingTree: false,
  treeError: false,
  treeErrorMsg: '',

  isCopyingScreener: false,
  copyScreenerResp: {},
  copyScreenerError: false,
  copyScreenerErrorMsg: '',

  isCopyingSharedScreener: false,
  scriptLotSize: [],
}

const screener = (state = initialState, action) => {
  switch (action.type) {
    case SCAN_STOCKS_INIT:
      return {
        ...state,
        isScanning: true,
        // scannedStocks: {
        //   stocks: [],
        // },
        scanStatus: false,
        scanError: false,
        scanErrorMsg: '',
      }

    case SCAN_STOCKS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isScanning: false,
        scannedStocks: data,
        scanStatus: true,
      }
    }

    case SCAN_STOCKS_FAILURE:
      return {
        ...state,
        isScanning: false,
        // scannedStocks: {
        //   stocks: [],
        // },
        scanError: true,
        scanErrorMsg: action.error,
      }

    case SAVE_SCREEN_INIT:
      return {
        ...state,
        saveScreenResp: {},
        isSavingScreen: true,
        saveScreenError: false,
      }

    case SAVE_SCREEN_SUCCESS: {
      const { data } = action
      return {
        ...state,
        saveScreenResp: data,
        isSavingScreen: false,
      }
    }

    case SAVE_SCREEN_FAILURE:
      return {
        ...state,
        isSavingScreen: false,
        saveScreenError: true,
        saveScreenErrorMsg: action.error,
      }

    case SCREENER_STATE_INIT:
      return {
        ...state,
        screenerState: {},
        isGettingScreenerState: true,
        screenerStateError: false,
      }

    case SCREENER_STATE_SUCCESS: {
      const { data } = action
      return {
        ...state,
        screenerState: data,
        scannedStocks: { status: 'success', stocks: data.screener_result },
        scanStatus: true,
        isGettingScreenerState: false,
      }
    }

    case SCREENER_STATE_FAILURE:
      return {
        ...state,
        isGettingScreenerState: false,
        screenerStateError: true,
        screenerStateErrorMsg: action.error,
      }

    case CLEAR_SCREENER_RESP:
      return {
        ...initialState,
        tree: state.tree,
        treeFetched: state.treeFetched,
      }

    case PLACE_DIRECT_INIT:
      return {
        ...state,
        isPlacingDirectOrder: true,
        placeDirectResp: {},
        placeDirectError: false,
        placeDirectErrorMsg: '',
      }

    case PLACE_DIRECT_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isPlacingDirectOrder: false,
        placeDirectResp: data,
      }
    }

    case PLACE_DIRECT_FAILURE:
      return {
        ...state,
        isPlacingDirectOrder: false,
        placeDirectError: true,
        placeDirectErrorMsg: action.error,
      }

    case SCREENER_TRAINER_INIT:
      return {
        ...state,
        isFetchingScreenerTrainer: true,
        screenerTrainerError: false,
        screenerTrainerErrorMsg: '',
      }

    case SCREENER_TRAINER_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingScreenerTrainer: false,
        screenerTrainer: data,
      }
    }

    case SCREENER_TRAINER_FAILURE:
      return {
        ...state,
        isFetchingScreenerTrainer: false,
        screenerTrainerError: true,
        screenerTrainerErrorMsg: action.error,
      }

    case SCREENER_TREE_INIT:
      return {
        ...state,
        treeFetched: false,
        isFetchingTree: true,
        treeError: false,
        treeErrorMsg: '',
      }

    case SCREENER_TREE_SUCCESS: {
      const { data } = action
      let tree = {}
      if (data && data.indicators) {
        tree = {
          ...data,
          indicators: { ...data.indicators, ...TestTree.exit },
        }
      }
      return {
        ...state,
        tree,
        treeFetched: true,
        isFetchingTree: false,
      }
    }

    case SCREENER_TREE_FAILURE:
      return {
        ...state,
        isFetchingTree: false,
        treeError: true,
        treeErrorMsg: '',
      }

    case RESET_SCREENER_TREE_FETCH:
      return {
        ...state,
        treeFetched: false,
      }

    case COPY_SCREENER:
      return {
        ...state,
        isCopyingScreener: true,
        copyScreenerResp: {},
        copyScreenerError: false,
        copyScreenerErrorMsg: '',
      }

    case COPY_SCREENER_SUCCESS:
      return {
        ...state,
        isCopyingScreener: false,
        copyScreenerResp: action.data,
      }

    case COPY_SCREENER_FAILURE:
      return {
        ...state,
        isCopyingScreener: false,
        copyScreenerError: true,
        copyScreenerErrorMsg: action.error,
      }

    case COPY_SHARED_SCREENER: {
      return {
        ...state,
        isCopyingSharedScreener: action.params,
      }
    }

    case GET_SCRIP_LOT_SIZE_INIT: {
      return {
        ...state,
      }
    }

    case GET_SCRIP_LOT_SIZE_SUCCESS: {
      return {
        ...state,
        scriptLotSize: action.data.data,
      }
    }

    case GET_SCRIP_LOT_SIZE_FAILURE: {
      return {
        ...state,
      }
    }

    default:
      return state
  }
}

export default screener
