import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'

import CustomText from './CustomText'
import Icon from './Icon'

import {
  DIMENSIONS, SPACING, COLORS, ICONS, theme,
} from '../Theme'

const CreateButton = React.memo(({
  text,
  onPress,
  btnStyles,
  className,
  textSize,
  showIcon,
  // isDark,
  id,
}) => {
  const styles = stylesheet()
  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onPress(e)
  }
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      className={`${styles.createBtn} ${btnStyles}`}
    >
      <div className={`${styles.createBtnView} ${className}`}>
        {showIcon && (
        <Icon
          name={ICONS.ADD}
          color={COLORS.ORANGE}
          size={13}
          className={styles.createBtnIcon}
        />
        )}
        <CustomText size={textSize} color="orange" weight="medium">{text}</CustomText>
      </div>
    </button>
  )
})

const stylesheet = makeStyles({
  createBtn: {
    marginTop: SPACING.SPACE_6,
    marginBottom: SPACING.SPACE_10,
  },
  createBtnView: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    flexDirection: 'row',
    padding: `${SPACING.SPACE_6} ${DIMENSIONS.SPACE_HORIZONTAL}`,
    border: `1px dashed ${COLORS.ORANGE}`,
    backgroundColor: theme.createBtnBg,
  },
  createBtnIcon: {
    marginRight: SPACING.SPACE_8,
  },
})

export default CreateButton

CreateButton.defaultProps = {
  className: '',
  btnStyles: '',
  showIcon: true,
}

CreateButton.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  className: PropTypes.string,
  btnStyles: PropTypes.string,
  showIcon: PropTypes.bool,
}
