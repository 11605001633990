/* eslint-disable max-len */
import makeStyles from '@material-ui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import {
  Spring, animated,
} from 'react-spring'
// import isEqual from 'lodash/isEqual'

import Icon from './Icon'
import CustomText from './CustomText'

import {
  COLORS, ICONS, SPACING, theme,
} from '../Theme'

let prevStepsCompleted = []

export const getColor = ({
  field, activeColor, isActive, hasError,
}) => {
  switch (field) {
    case 'label': {
      if (hasError) return 'red'
      if (isActive) return activeColor
      return 'textSecondary'
    }

    case 'btnBg': {
      if (isActive) return activeColor
      return theme.stepsBg
    }

    case 'btnBorder': {
      if (hasError) return COLORS.STEPS_CIRCLE_ERROR
      if (isActive) return activeColor
      return activeColor
    }

    default:
      return 'text'
  }
}

// const areEqual = (props, nextProps) => {
//   const { steps, defaultStepIndex, stepsCompleted } = props
//   if(!isEqual(steps, nextProps.steps)
//   || !isEqual(defaultStepIndex, nextProps.defaultStepIndex)
//   || !isEqual(stepsCompleted, nextProps.stepsCompleted)) {
//     return false
//   }
//   return true
// }
const Steps = React.memo(({
  steps,
  stepRenderer,
  defaultStepIndex,
  onStepPress,
  disabled,
  stepsCompleted,
  stepColor = theme.linkColor,
  // gradientColor = COLORS.VOILET,
  containerSytles = '',
}) => {
  const styles = stylesheet()
  const [active, update] = useState(defaultStepIndex)
  const [animationProps, updateAnim] = useState({})
  useEffect(() => {
    if (defaultStepIndex !== active) {
      update(defaultStepIndex)
    }
    return () => {
      prevStepsCompleted = []
    }
  }, [defaultStepIndex])
  useEffect(() => {
    // DANGER
    if (steps.length && stepsCompleted.length > -1) {
      updateAnim({
        from: {
          width: `calc(100% * ${(prevStepsCompleted.length / steps.length) + (prevStepsCompleted.length / (steps.length * 2))})`,
          // backgroundImage: `linear-gradient(to right, ${stepColor}, ${gradientColor})`,
        },
        to: {
          width: `calc(100% * ${(stepsCompleted.length / steps.length) + (stepsCompleted.length / (steps.length * 2))})`,
          // backgroundImage: `linear-gradient(to right, ${stepColor}, ${gradientColor})`,
        },
      })
      prevStepsCompleted = stepsCompleted
    } else {
      // updateAnim({
      //   to: {
      //     width: 'calc(10%)',
      //   },
      // })
    }
  }, [stepsCompleted])
  const onClickHandler = (e, item, index, stepDisabled) => {
    e.preventDefault()
    if (disabled || stepDisabled) {
      return
    }
    onStepPress(item, index)
  }
  const renderStep = ({
    stepValue, isActive, isCompleted, hasError,
  }) => {
    if (isActive) {
      return <div className={styles.activeStepValue} />
    }
    if (isCompleted) {
      return <Icon name={ICONS.TICK_ROUND_FILLED} color={theme.linkColor} style={{ height: '100%', width: '100%' }} />
    }
    return (
      <CustomText
        size="small"
        color={getColor({
          field: 'label', isActive, activeColor: 'textSecondary', hasError,
        })}
      >
        {stepValue}
      </CustomText>
    )
  }
  return (
    <div className={`${styles.container} ${containerSytles}`}>
      {steps.map((item, index) => {
        const { label, disabled: stepDisabled } = item
        const isActive = index === active
        const isCompleted = stepsCompleted.includes(index)
        if (stepRenderer) return stepRenderer(item, index, stepDisabled, isActive)
        const hasError = !isCompleted && defaultStepIndex > index
        // let extraStyles = {}
        // if (index === 0) extraStyles = { borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }
        // if (index === steps.length - 1) {
        //   extraStyles = { borderTopRightRadius: 10, borderBottomRightRadius: 10 }
        // }
        return (
          <div key={label} className={styles.stepWrapper}>
            <div className={styles.stepContainer}>
              <button
                type="button"
                onClick={e => onClickHandler(e, item, index)}
                disabled={stepDisabled || disabled || isActive}
                className={`${styles.stepBtn}  ${isActive ? styles.stepActive : ''}`}
                style={{
                  backgroundColor: getColor({
                    field: 'btnBg', activeColor: stepColor, isActive, hasError,
                  }),
                  borderColor: getColor({
                    field: 'btnBorder', activeColor: theme.stepsCircleBorder, isActive, hasError,
                  }),
                }}
              >
                {renderStep({
                  ...item, isActive, isCompleted, hasError,
                })}
              </button>
            </div>
            {label ? (
              <CustomText
                className={styles.stepLabel}
                color={getColor({
                  field: 'label', hasError, isActive, activeColor: 'linkColor',
                })}
                weight={isActive && index === steps.length - 1 ? 'medium' : 'regular'}
              >
                {label}
              </CustomText>
            ) : null}
          </div>
        )
      })}
      <div className={styles.progressBar}>
        <Spring
          {...animationProps}
          config={{
            mass: 5,
            friction: 200,
          }}
        >
          {props => (
            <animated.div style={{
              minWidth: '30px',
              backgroundColor: stepColor,
              height: '100%',
              ...props,
            }}
            />
          )}
        </Spring>
      </div>
      {/* <div
        className={styles.stepBar}
        style={{
          backgroundColor: stepColor,
          backgroundImage: `linear-gradient(to right, ${stepColor}, ${gradientColor})`,
          width: steps.length ? `calc(100% * ${stepsCompleted.length / steps.length})` : '20px',
          minWidth: '30px',
        }}
      /> */}
    </div>
  )
})

export default Steps

const stylesheet = makeStyles({
  container: {
    display: 'flex',
    height: '100%',
    position: 'relative',
  },
  stepWrapper: {
    flex: 1,
    textAlign: 'center',
  },
  stepBtn: {
    borderRadius: '50%',
    marginBottom: SPACING.SPACE_10,
    width: '100%',
    height: '100%',
    maxWidth: '32px',
    maxHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `5px solid ${theme.stepsCircleBorder}`,
    // backgroundClip: 'content-box',
    zIndex: 99,
  },
  stepActive: {
    // border: '7px solid',
  },
  stepLabel: {
    width: '100%',
  },
  activeStepValue: {
    backgroundColor: COLORS.WHITE,
    height: '50%',
    width: '50%',
    borderRadius: '50%',
    maxHeight: 10,
    maxWidth: 10,
  },
  stepContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBar: {
    height: '10px',
    backgroundColor: theme.stepsBar,
    width: '96%',
    margin: '0 2%',
    borderRadius: '10px',
    top: 'calc(50% - 10px)',
    position: 'absolute',
    zIndex: 9,
    overflow: 'hidden',
  },
  progressBarFiller: {

  },
})
