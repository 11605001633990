import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import CustomInput from './CustomInput'
import CustomText from './CustomText'
import Button from './Button'
import Icon from './Icon'
import { ICONS, theme } from '../Theme'

const ShareEmbedCode = ({
  shareCodeParams = {},
  onDismiss,
  backPressHandler,
  displayDevice,
  url,
  dismissKey,
}) => {
  const styles = stylesheet()
  const [frameWidth, onWidthChange] = useState(displayDevice === 'mobile' ? 300 : 1200)
  const [frameHeight, onHeightChange] = useState(displayDevice === 'mobile' ? 600 : 448)
  const width = frameWidth > 0 ? `${frameWidth}px` : '100%'
  const height = frameHeight > 0 ? `${frameHeight}px` : '100%'
  const shareableText = `<iframe src="${shareCodeParams.url || url}" title="Streak Backtest Results" width="${width}" height="${height}" style="border: 0; border-radius: 4px; box-shadow: 0px 1px 6px 3px #f3f3f3;" />`
  const copytext = () => {
    const ele = document.getElementById('codeInput')
    ele.select()
    ele.setSelectionRange(0, 99999)
    document.execCommand('copy')
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {/* <p className={styles.title}> */}
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <button
            type="button"
            onClick={backPressHandler}
            className={styles.backBtn}
          >
            <Icon name={ICONS.BACK} color="#9BABC6" size={13} />
          </button>
          <CustomText weight="semi_bold" className={styles.title}>Share</CustomText>
        </div>
        <button
          type="button"
          onClick={() => onDismiss(dismissKey)}
          className={styles.closeBtn}
        >
          <Icon name={ICONS.CLOSE} color="#9BABC6" size={12} />
        </button>
      </div>
      <div className={styles.content}>
        <CustomInput
          multiline
          inputType="text"
          value={shareableText}
          onClick={copytext}
          onChangeText={() => null}
          inputProps={{
            id: 'codeInput',
            readOnly: true,
            onSelect: copytext,
            rows: 7,
          }}
          inputStyles={styles.textAreaStyle}
          inputClass={styles.input}
          containerStyle={styles.input}
          iconClickHandler={copytext}
          iconContainer={styles.inputIcon}
          backgroundColor={theme.bgPrimary}
          inputBorderColor={theme.profileInputBorder}
          fontSize="16px"
        />
        <div style={{ padding: '0px 8px' }}>
          <CustomText weight="semi_bold" size="medium" style={{ margin: '32.5px 0 18.5px 0px' }}>Embed Options</CustomText>
          <div className={styles.params}>
            <CustomText weight="semi_bold" className={styles.inputLabel}>Width</CustomText>
            <CustomInput
              inputType="text"
              value={frameWidth}
              labelClass={styles.inputLabel}
              containerStyles={styles.inputParams}
              onChangeText={e => onWidthChange(e.target.value)}
              inputClass={styles.paramsInput}
              inputStyles={styles.inputStyles}
              inputRegx={/^[0-9]*$/g}
            />
            <CustomText weight="semi_bold" className={styles.inputLabel}>Height</CustomText>
            <CustomInput
              inputType="text"
              value={frameHeight}
              inputStyles={styles.inputStyles}
              containerStyles={styles.inputParams}
              labelClass={styles.inputLabel}
              onChangeText={e => onHeightChange(e.target.value)}
              inputClass={styles.paramsInput}
              inputRegx={/^[0-9]*$/g}
            />
          </div>
          <div style={{ textAlign: 'center', paddingTop: '36.5px' }}>
            <Button
              text="COPY"
              className={styles.tweetBtn}
            // isDark={isDark}
              buttonColor="blue"
              onPress={copytext}
              btnStyles={styles.copyBtn}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    // color: theme.colors.textDark_2,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '18px !important',
  },
  closeIconRoot: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    margin: '14px 0px 0px 0px',
  },
  text: {
    marginBottom: 15,
  },
  inputLabel: {
    fontSize: '16px !important',
  },
  inputParams: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '15px',
  },
  input: {
    backgGround: '#000000',
    borderRadius: 4,
    padding: '10px 30px 10px 8px',
    height: '78px',
    fontSize: '16px',
  },
  inputStyles: {
    padding: '10.5px 19px',
    width: '85%',
    fontSize: '16px !important',
    color: '#9BABC6',
    opacity: 1,
  },
  textAreaStyle: {
    borderTop: '2px solid rgba(155, 171, 198,0.1) !important',
    borderBottom: '2px solid rgba(155,171,198,0.1) !important',
    padding: '4px 0px',
  },
  inputIcon: {
    right: '0',
    alignSelf: 'center',
    padding: '20px 15px',
    cursor: 'pointer',
  },
  editText: {
    margin: '32.5px 0 18.5px 0px',
  },
  params: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 500,
    columnGap: '10px',
  },
  paramsInput: {
    padding: '10px 10px 10px 5px',
    color: '#9BABC6',
  },
  tweetBtn: {
    width: '287px',
  },
  copyBtn: {
    borderRadius: '12px',
    width: '287px',
    height: '44px',
  },
  backBtn: {
    paddingRight: '15px',
    paddingTop: '6px',
  },
})

export default ShareEmbedCode
