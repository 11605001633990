import { BROKERS } from '../../utils/consts'
import {
  FETCH_BILLING_INIT,
  FETCH_BILLING_SUCCESS,
  FETCH_BILLING_FAILURE,
  CHANGE_SUBSCRIPTION_INIT,
  CHANGE_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION_FAILURE,
  GET_PRICING_INIT,
  GET_PRICING_SUCCESS,
  GET_PRICING_FAILURE,
  UNSUBSCRIBE_FEEDBACK,
  UNSUBSCRIBE_FEEDBACK_SUCCESS,
  UNSUBSCRIBE_FEEDBACK_FAILURE,
  DOWNLOAD_INVOICE_INIT,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_FAILURE,
  FETCH_BILLING_OFFERS_INIT,
  FETCH_BILLING_OFFERS_SUCCESS,
  FETCH_BILLING_OFFERS_FAILURE,
} from './actionTypes'

const initialState = {
  isFetchingBilling: false,
  billingError: false,
  billingDetails: {},
  isChanging: false,
  changeError: false,
  changeErrorMsg: '',
  changeResp: {},
  isFetchingPricing: false,
  available_plans: [],
  pricingError: false,
  unsubscribeFeedbackResp: {},
  submitingFeedback: false,
  unsubscribeFeedbackError: false,
  unsubscribeFeedbackErrorMsg: '',
  downloadInvoiceSuccess: false,
  downloadInvoiceError: false,
  downloadInvoiceErrorMsg: '',
  isFetchingBillingOffers: false,
  billingOffers: [],
  billingOffersError: false,
  billingOffersErrorMsg: '',
}

const billing = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BILLING_INIT: {
      return {
        ...state,
        isFetchingBilling: true,
        billingError: false,
      }
    }

    case FETCH_BILLING_SUCCESS: {
      const { user_subscription_log = [] } = action.data
      const userSubscriptionLog = []
      user_subscription_log.forEach((item) => {
        const data = JSON.parse(item)
        if (data.subscription_total_price) {
          userSubscriptionLog.push(data)
        }
      })
      return {
        ...state,
        isFetchingBilling: false,
        billingDetails: { ...action.data, user_subscription_log: userSubscriptionLog },
      }
    }

    case FETCH_BILLING_FAILURE:
      return {
        ...state,
        isFetchingBilling: false,
        billingError: true,
      }

    case CHANGE_SUBSCRIPTION_INIT:
      return {
        ...state,
        isChanging: true,
        changeError: false,
        changeErrorMsg: '',
        changeResp: {},
      }

    case CHANGE_SUBSCRIPTION_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isChanging: false,
        changeResp: data,
      }
    }

    case CHANGE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isChanging: false,
        changeError: true,
        changeErrorMsg: action.error,
      }

    case GET_PRICING_INIT:
      return {
        ...state,
        isFetchingPricing: true,
        pricingError: false,
      }

    case GET_PRICING_SUCCESS: {
      const { data, broker } = action
      let available_plans = data.pricing || []
      if (broker === BROKERS.ICICI.name) {
        available_plans = available_plans.filter(item => item.subscription_period !== '3 Months')
      }
      // if (broker !== BROKERS.ZERODHA.name) {
      //   available_plans = available_plans.filter(item => item.subscription_period !== '1 Month')
      // }
      // if (broker === BROKERS.ZERODHA.name) {
      //  available_plans = available_plans.filter(item => item.subscription_period !== '12 Months')
      // }
      available_plans = available_plans.filter(item => item.subscription_period !== '12 Months'
      && item.subscription_period !== '1 Month')
      return {
        ...state,
        isFetchingPricing: false,
        available_plans,
      }
    }

    case GET_PRICING_FAILURE:
      return {
        ...state,
        isFetchingPricing: false,
        pricingError: true,
      }

    case UNSUBSCRIBE_FEEDBACK:
      return {
        ...state,
        unsubscribeFeedbackResp: {},
        submitingFeedback: true,
        unsubscribeFeedbackError: false,
        unsubscribeFeedbackErrorMsg: '',
      }

    case UNSUBSCRIBE_FEEDBACK_SUCCESS:
      return {
        ...state,
        unsubscribeFeedbackResp: action.data,
        submitingFeedback: false,
      }

    case UNSUBSCRIBE_FEEDBACK_FAILURE:
      return {
        ...state,
        submitingFeedback: false,
        unsubscribeFeedbackError: true,
        unsubscribeFeedbackErrorMsg: action.error,
      }

    case DOWNLOAD_INVOICE_INIT: {
      return {
        ...state,
        downloadInvoiceSuccess: false,
        downloadInvoiceError: false,
        downloadInvoiceErrorMsg: '',
      }
    }

    case DOWNLOAD_INVOICE_SUCCESS: {
      return {
        ...state,
        downloadInvoiceSuccess: true,
      }
    }

    case DOWNLOAD_INVOICE_FAILURE: {
      return {
        ...state,
        downloadInvoiceError: true,
        downloadInvoiceErrorMsg: action.error,
      }
    }
    case FETCH_BILLING_OFFERS_INIT: {
      return {
        ...state,
        isFetchingBillingOffers: true,
        billingOffers: [],
        billingOffersError: false,
      }
    }

    case FETCH_BILLING_OFFERS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingBillingOffers: false,
        billingOffers: data.billing_carousel,
      }
    }

    case FETCH_BILLING_OFFERS_FAILURE: {
      return {
        ...state,
        isFetchingBillingOffers: false,
        billingOffersError: true,
        billingOffersErrorMsg: action.error,
      }
    }
    default:
      return state
  }
}

export default billing
