/* eslint-disable react/no-array-index-key */
import { makeStyles } from '@material-ui/styles'
import React from 'react'

import PlaceHolderLoader from '../../PlaceHolderLoader'

import {
  SPACING, theme,
} from '../../../Theme'

function SidebarLoader() {
  const styles = stylesheet()

  return (
    <div className={styles.deployedCon}>
      <PlaceHolderLoader className={styles.buySellButton} />
      <PlaceHolderLoader className={styles.chartCondition} />
      {[...Array(3)].map((item, index) => (
        <PlaceHolderLoader key={`backtestParams${index}`} className={styles.collapsedRow} />
      ))}
    </div>
  )
}

const stylesheet = makeStyles({
  deployedCon: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.bgPrimary,
    padding: SPACING.SPACE_20,
  },
  chartCondition: {
    height: 300,
    width: '100%',
    marginBottom: SPACING.SPACE_20,
  },
  collapsedRow: {
    height: 60,
    width: '100%',
    marginBottom: SPACING.SPACE_20,
  },
  fundCarousel: {
    height: 150,
    width: '100%',
    borderRadius: 8,
    marginBottom: SPACING.SPACE_20,
  },
  buySellButton: {
    height: 40,
    width: 150,
    marginBottom: SPACING.SPACE_20,
  },
})

export default SidebarLoader
