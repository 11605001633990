import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import classnames from 'classnames'

import Icon from './Icon'
import CustomText from './CustomText'

import {
  COLORS, SPACING, ICONS, theme,
} from '../Theme'
import { SCANNER_CARD_HEIGHT, SCANNER_CARD_WIDTH } from './ScannerCard'
import { PRODUCT_TOUR_SECTIONS } from '../components/ProductTour/ProductTour-utils'

const CreateCard = ({
  title,
  description,
  onPress,

  wrapperStyle,
  containerStyle,
  iconStyle,
  titleStyle,
  descriptionStyle,
  cardColor: cardPrimaryColor,
  tourType,
}) => {
  const styles = stylesheet()

  const cardColor = cardPrimaryColor || COLORS.ORANGE
  const ptScanId = tourType === PRODUCT_TOUR_SECTIONS.SCANNERS_CREATE ? 'tour_scan_create_1' : tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY ? 'tour_create_strategy_1' : ''
  const ptStyle = tourType
  === PRODUCT_TOUR_SECTIONS.SCANNERS_CREATE ? { padding: 0 } : { padding: 0 }
  return (
    <button
      type="button"
      onClick={onPress}
      className={classnames(styles.addNewScripBtn, wrapperStyle)}
      id={ptScanId}
      style={ptStyle}
    >
      <div className={classnames(styles.addNewCard, containerStyle)}>
        <Icon name={ICONS.ADD} color={cardColor} size={18} style={iconStyle} />
        <CustomText size="MEDIUM_1" color={cardColor} weight="semi_bold" className={classnames(styles.addText, titleStyle)}>{title}</CustomText>
        <CustomText color={cardColor} weight="medium" size="small" className={descriptionStyle}>{description}</CustomText>
      </div>
    </button>
  )
}

const stylesheet = makeStyles({
  addNewScripBtn: {
    borderColor: COLORS.ORANGE,
    borderWidth: 1,
    borderRadius: 6,
    margin: `${SPACING.SPACE_10} ${SPACING.SPACE_20} ${SPACING.SPACE_10} 0`,
    backgroundColor: theme.bgTertiary,
    borderStyle: 'dashed',
    height: SCANNER_CARD_HEIGHT,
    padding: '0 !important',
  },
  addNewCard: {
    alignSelf: 'center',
    width: SCANNER_CARD_WIDTH,
  },
  addText: {
  },
  '@media only screen and (min-width: 786px) and (max-width: 1024px)': {
  },
  '@media only screen and (max-width: 786px)': {
    addNewCard: {
      width: (SCANNER_CARD_WIDTH / 2) - 4,
    },
  },
})

export default CreateCard
