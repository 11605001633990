import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { SPACING } from '../Theme'
import { withTheme } from '../Theme/ThemeProvider'
import Button from './Button'
import CustomText from './CustomText'

function DeeplinkModal(props) {
  const { continueInBrowser, openInApp } = props
  const styles = stylesheet()
  return (
    <div className={styles.modalContainer}>
      <CustomText weight="semi_bold" size="large">Experience Streak in...</CustomText>
      <div className={styles.btnsContainer}>
        <Button text="Open Streak app" buttonColor="blue" onPress={openInApp} />
        <Button text="Continue in browser" mode="outlined" buttonColor="blue" onPress={continueInBrowser} />
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: SPACING.SPACE_10,
    margin: `${SPACING.SPACE_20} 0`,
  },
  '@media only screen and (max-width: 786px)': {
    btnsContainer: {
      flexDirection: 'column',
    },
  },
})

export default withTheme(stylesheet)(DeeplinkModal)
