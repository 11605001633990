import {
  UPDATE_NOTIFICATIONS,
  RESET_NOTIFICATION_COUNT,
  FETCH_ALGOS_INIT,
  FETCH_ALGOS_SUCCESS,
  FETCH_ALGOS_FAILURE,
  ACTIVE_SCREEN,
  TOGGLE_TERMS_PAGE,
  CLEAR_SEARCH_RESP,
  REFRESH_ALGO,
  FETCH_PROFILE_CAROUSEL,
  FETCH_PROFILE_CAROUSEL_SUCCESS,
  FETCH_PROFILE_CAROUSEL_FAILURE,
  TOGGLE_ROOT_MODAL,
  UPDATE_WEBSITE_CONFIG,
  TOGGLE_AUTH_GREETING,
  TOGGLER_TOUR,
  UPDATE_TOUR_INDEX,
  CHANGE_PT_COMPONENT_STATUS,
  PAUSE_TOUR,
  TOGGLE_PT_SIDEBAR,
  EXTRA_TOURTIP,
  UPDATE_COMPLETED_TOURS,
  TOGGLE_TOUR_BACKDROP,
  GET_SIDEBAR_VALUE,
  GET_BLOCKED_MTF_TF,
  GET_LOGIN_VALUE,
} from './actionTypes'

import { setLocalStorage, getLocalStorage } from '../../utils/common'
import { PREFERENCES } from '../Profile/profileUtils'

const siteConfig = getLocalStorage(PREFERENCES.SITE_CONFIG)

const initialState = {
  notifications: [],
  algoList: [],
  isFetchingAlgoList: false,
  algoListError: false,
  active: 'Dashboard',
  terms_uri: false,
  terms_uri_heading: '',
  isFetchingProfileCarousel: true,
  profileCarousel: [],
  profileCarouselError: false,
  profileCarouselErrorMsg: '',
  rootModalName: '',
  showRootModal: false,
  stateChanged: {},
  greetingModal: '',
  routeAfterActivation: '',
  showFeatures: true,
  websiteConfig: siteConfig ? JSON.parse(siteConfig) : {},
  startTour: false,
  tourType: '',
  activeTourIndex: -1,
  ptComponentStatus: '',
  pauseTour: false,
  ptSideBar: false,
  ptExtraTourtip: false,
  completedTours: [],
  showSkipTourPopup: false,
  showSideBar: true,
  blockedTimeFrame: '',
  isLoginOnOrder: false,
}

const serverTicks = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS: {
      const { notification } = action
      return {
        ...state,
        notifications: [notification],
      }
    }

    case RESET_NOTIFICATION_COUNT: {
      return {
        ...state,
        notifications: [],
      }
    }

    case FETCH_ALGOS_INIT:
      return {
        ...state,
        algoListError: false,
        isFetchingAlgoList: true,
      }
    // same keys used for scan result
    case FETCH_ALGOS_SUCCESS: {
      const { data: { data } } = action
      return {
        ...state,
        isFetchingAlgoList: false,
        algoList: data,
      }
    }

    case FETCH_ALGOS_FAILURE: {
      return {
        ...state,
        algoListError: true,
        isFetchingAlgoList: true,
      }
    }

    case ACTIVE_SCREEN: {
      const { active } = action
      return {
        ...state,
        active,
      }
    }

    case TOGGLE_TERMS_PAGE: {
      const { terms_uri, heading } = action
      return {
        ...state,
        terms_uri,
        terms_uri_heading: heading,
      }
    }

    case CLEAR_SEARCH_RESP:
      return {
        ...state,
        algoList: [],
        isFetchingAlgoList: false,
        algoListError: false,
      }

    case REFRESH_ALGO: {
      const { field, value } = action
      return {
        ...state,
        stateChanged: {
          ...state.stateChanged,
          [field]: value,
        },
      }
    }

    case FETCH_PROFILE_CAROUSEL:
      return {
        ...state,
        isFetchingProfileCarousel: true,
        profileCarousel: [],
        profileCarouselError: false,
        profileCarouselErrorMsg: '',
      }

    case FETCH_PROFILE_CAROUSEL_SUCCESS:
      return {
        ...state,
        isFetchingProfileCarousel: false,
        profileCarousel: action.data.profile_carousel,
      }

    case FETCH_PROFILE_CAROUSEL_FAILURE:
      return {
        ...state,
        isFetchingProfileCarousel: false,
        profileCarouselError: true,
        profileCarouselErrorMsg: action.error,
      }

    case TOGGLE_ROOT_MODAL:
      return {
        ...state,
        rootModalName: action.modalName,
        showRootModal: action.showModal,
      }

    case UPDATE_WEBSITE_CONFIG: {
      const { field, config } = action
      let websiteConfig = {}
      if (field === null) {
        websiteConfig = config
      } else {
        websiteConfig = {
          ...state.websiteConfig,
          [field]: config,
        }
        setLocalStorage({ websiteConfig }, true)
      }
      return {
        ...state,
        websiteConfig,
      }
    }

    case TOGGLE_AUTH_GREETING: {
      const { modalType, routeAfterActivation, showFeatures = true } = action
      return {
        ...state,
        greetingModal: modalType,
        routeAfterActivation: routeAfterActivation || state.routeAfterActivation,
        showFeatures: showFeatures && state.showFeatures,
      }
    }

    case TOGGLER_TOUR: {
      const { params, tourType } = action
      return {
        ...state,
        startTour: params,
        tourType,
      }
    }

    case EXTRA_TOURTIP: {
      return {
        ...state,
        ptExtraTourtip: action.params,
      }
    }

    case PAUSE_TOUR: {
      return {
        ...state,
        pauseTour: action.params,
      }
    }

    case UPDATE_TOUR_INDEX: {
      return {
        ...state,
        activeTourIndex: action.index,
      }
    }

    case CHANGE_PT_COMPONENT_STATUS: {
      return {
        ...state,
        ptComponentStatus: action.status,
      }
    }

    case TOGGLE_PT_SIDEBAR: {
      return {
        ...state,
        ptSideBar: action.status,
      }
    }

    case UPDATE_COMPLETED_TOURS: {
      return {
        ...state,
        completedTours: action.tours,
      }
    }

    case TOGGLE_TOUR_BACKDROP: {
      const { params } = action
      return {
        ...state,
        showSkipTourPopup: params,
      }
    }

    case GET_SIDEBAR_VALUE: {
      const { params } = action
      return {
        ...state,
        showSideBar: params,
      }
    }

    case GET_BLOCKED_MTF_TF: {
      const { params } = action
      return {
        ...state,
        blockedTimeFrame: params,
      }
    }

    case GET_LOGIN_VALUE:
      return {
        ...state,
        isLoginOnOrder: true,
      }

    default:
      return state
  }
}

export default serverTicks
