import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'
import { Link } from 'react-router-dom'

import CustomText from './CustomText'
import Icon from './Icon'

import {
  DIMENSIONS, FONTS, ICONS, SPACING, theme,
} from '../Theme'
import PlaceHolderLoader from './PlaceHolderLoader'
import CustomTooltip from './CustomTooltip'

const ViewAllCard = ({
  children,
  onPress,
  title,
  renderTitle,
  contentStyles,
  cardStyles = '',
  actionBtnRederer,
  headerStyles = '',
  showViewAll = true,
  loading,
  showInfo,
  infoText,
  showHeader,
  pathname,
//   isDark
}) => {
  const styles = stylesheet()
  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onPress(e)
  }

  const renderPopup = () => {
    return (
      <div className={styles.popupContainer}>
        <CustomText>
          {infoText}
        </CustomText>
      </div>
    )
  }

  const renderInfoTitle = () => {
    return (
      <div className={styles.infoTitleContainer}>
        <CustomText style={{ textTransform: 'capitalize' }} weight="semi_bold" size="medium">{title}</CustomText>
        <CustomTooltip
          show
          tooltipProps={{}}
          contentRenderer={renderPopup}
          placement="right"
          tooltipContainerStyles={styles.tooltipContainer}
          interactive
        >
          <div className={styles.infoIcon}>
            <Icon
              name={ICONS.INFO}
              className={styles.infoIcon}
              color={theme.textSecondary}
              size={16}
            />
          </div>
        </CustomTooltip>
      </div>
    )
  }

  const renderHeaderTitle = () => {
    return (
      renderTitle ? renderTitle() : showInfo ? renderInfoTitle() : (
        <CustomText style={{ textTransform: 'capitalize' }} weight="semi_bold" size="medium">{title}</CustomText>
      )
    )
  }
  return (
    <section className={classnames(styles.card, 'view-all-card', cardStyles)}>
      {showHeader && (
      <div className={classnames(styles.header, headerStyles)}>
        {loading ? <PlaceHolderLoader className={styles.loader} /> : (
          renderHeaderTitle()
        )}
        {showViewAll && !loading && (
          pathname
            ? (
              <Link
                to={pathname}
                className={styles.viewAllBtn}
                onClick={e => e.stopPropagation()}
              >
                View all
              </Link>
            )
            : (
              <button
                type="button"
                className={classnames(styles.viewAllBtn, 'view-all-button')}
                onClick={onClick}
              >
                {actionBtnRederer ? actionBtnRederer()
                  : (
                    <>
                      View all
                      &nbsp;
                      <Icon name={ICONS.FORWARD} color={theme.textSecondary} size={9} />
                    </>
                  )}
              </button>
            )
        )}
      </div>
      )}
      <div className={contentStyles}>
        {children}
      </div>
    </section>
  )
}

const stylesheet = makeStyles({
  card: {
    borderRadius: 8,
    padding: `${SPACING.SPACE_24} 0`,
    backgroundColor: theme.bgPrimary,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    '&:hover': {
      '& $viewAllBtn': {
        color: theme.linkColor,
        '& > svg': {
          fill: theme.linkColor,
        },
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${DIMENSIONS.SPACE_HORIZONTAL}`,
    marginBottom: SPACING.SPACE_14,
  },
  viewAllBtn: {
    color: theme.textSecondary,
    fontSize: FONTS.SMALL,
  },
  loader: {
    width: '100%',
    maxWidth: '200px',
    height: '20px',
  },
  infoTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: SPACING.SPACE_10,
  },
  infoIcon: {
    height: 16,
    '&:hover $infoIcon': {
      fill: theme.linkColor,
    },
  },
  popupContainer: {
    backgroundColor: theme.bgPrimary,
    padding: SPACING.SPACE_20,
    borderRadius: 8,
    minWidth: 300,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
  },
  tooltipContainer: {
    backgroundColor: 'transparent',
  },
})

ViewAllCard.defaultProps = {
  title: '',
  contentStyles: '',
  cardStyles: '',
  showViewAll: true,
  showHeader: true,
}

ViewAllCard.propTypes = {
  title: PropTypes.string,
  contentStyles: PropTypes.string,
  cardStyles: PropTypes.string,
  showViewAll: PropTypes.bool,
  showHeader: PropTypes.bool,
}

export default ViewAllCard
