import React from 'react'
import { connect } from 'react-redux'

import GetPNL from '../../UI/GetPNL'
import { PRECISION } from '../../utils/consts'

// import { segSymConverter } from '../../utils/common'

export const pnlAccessor = (row, isPositionsTab, last_price) => {
  const {
    pnl = 0.0, multiplier = 1, average_price,
    quantity, t2_quantity = 0, t1_quantity = 0, last_price: itemLtp = 0,
    sell_value, buy_value, // tradingsymbol, // exchange, tradingsymbol,
  } = row
  //   const { seg_sym } = segSymConverter`${exchange}_${tradingsymbol}`
  const ltp = last_price || itemLtp
  let pnlValue = pnl.toFixed(PRECISION)
  if(quantity) {
    pnlValue = ((last_price * quantity * multiplier)
      + (sell_value - buy_value)).toFixed(PRECISION)
  }
  const pnlQty = quantity || t2_quantity || t1_quantity || 0 // do not change order
  let pnlValueHoldings = pnl.toFixed(PRECISION)

  if (multiplier && average_price && pnlQty && ltp) {
    pnlValueHoldings = ((ltp - average_price) * pnlQty * multiplier).toFixed(PRECISION)
  }
  return isPositionsTab ? pnlValue : pnlValueHoldings
}

const getLtp = ({ ticks, seg_sym_list, seg_sym }) => {
  const token = seg_sym_list[seg_sym]
  const tick = ticks[token] || {}
  const { last_price = 0.0, change = 0.0, day_open_price = 0.0 } = tick

  return {
    last_price,
    day_open_price,
    change,
  }
}
const GetPortfolioPnl = ({
  item, pnlProps, last_price, isPositionsTab,
}) => {
  const pnl = pnlAccessor(item, isPositionsTab, last_price)
  return (
    <GetPNL
      pnl={pnl}
      {...pnlProps}
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const { seg_sym_list, ticks } = state.instruments
  const { last_price } = getLtp({
    seg_sym_list, seg_sym: ownProps.seg_sym, ticks,
  })
  return {
    last_price,
  }
}

export default connect(mapStateToProps, null)(GetPortfolioPnl)
