import { all, fork } from 'redux-saga/effects'
/* eslint-disable */

import * as authSagas from './containers/Auth/Auth-saga'
// eslint-disable-next-line import/no-cycle
import * as instrumentSagas from './containers/Instruments/Instruments-saga'
// import * as acceptTermSagas from './containers/Acceptterms/Acceptterms-saga'
import * as marketSagas from './containers/Marketwatch/Marketwatch-saga'
import * as portfolioSagas from './containers/Portfolio/Portfolio-saga'
import * as commonSagas from './containers/Common/Common-saga'
import * as accountSagas from './containers/Account/Account-saga'
import * as orderbookSagas from './containers/OrderBook/Orderbook-saga'
import * as notificationsSagas from './containers/Notifications/Notifications-saga'
import * as dashboardSagas from './containers/Dashboard/DashBoard-saga'
import * as marketPlaceSagas from './containers/MarketPlace/MarketPlace-saga'
import * as deployedSagas from './containers/Deployed/Deployed-saga'
import * as algosSagas from './containers/Algos/Algos-saga'
import * as screenerSagas from './containers/Screener/Screener-saga'
import * as scansSagas from './containers/Scans/Scans-saga'
import * as createSagas from './containers/Create/Create-saga'
import * as backtestsSagas from './containers/Backtests/Backtests-saga'
import * as technicalsSaga from './containers/Technicals/Technicals-saga'
import * as billingSagas from './containers/Billing/Billing-saga'
// import * as referralSagas from './containers/Referral/Referral-saga'
import * as paymentSagas from './containers/Payment/Payment-saga'
import * as paymentPagesSagas from './containers/PaymentPages/PaymentPages-saga'
const auth = Object.values(authSagas).map((saga) => {
  return fork(saga)
})

// const acceptTerms = Object.values(acceptTermSagas).map((saga) => {
//   return fork(saga)
// })

const market = Object.values(marketSagas).map((saga) => {
  return fork(saga)
})

const common = Object.values(commonSagas).map((saga) => {
  return fork(saga)
})

const portfolio = Object.values(portfolioSagas).map((saga) => {
  return fork(saga)
})

const account = Object.values(accountSagas).map((saga) => {
  return fork(saga)
})

const orderbook = Object.values(orderbookSagas).map((saga) => {
  return fork(saga)
})

const notifications = Object.values(notificationsSagas).map((saga) => {
  return fork(saga)
})

const dashboard = Object.values(dashboardSagas).map((saga) => {
  return fork(saga)
})

const instruments = Object.values(instrumentSagas).map((saga) => {
  return fork(saga)
})

const marketPlace = Object.values(marketPlaceSagas).map((saga) => {
  return fork(saga)
})

const scans = Object.values(scansSagas).map((saga) => {
  return fork(saga)
})

const deployed = Object.values(deployedSagas).map((saga) => {
  return fork(saga)
})

const algos = Object.values(algosSagas).map((saga) => {
  return fork(saga)
})

const screener = Object.values(screenerSagas).map((saga) => {
  return fork(saga)
})

const create = Object.values(createSagas).map((saga) => {
  return fork(saga)
})

const backtests = Object.values(backtestsSagas).map((saga) => {
  return fork(saga)
})

const technicals = Object.values(technicalsSaga).map((saga) => {
  return fork(saga)
})

const billing = Object.values(billingSagas).map((saga) => {
  return fork(saga)
})

// const referral = Object.values(referralSagas).map((saga) => {
//   return fork(saga)
// })

const payment = Object.values(paymentSagas).map((saga) => {
  return fork(saga)
})

const paymentPages = Object.values(paymentPagesSagas).map((saga) => {
  return fork(saga)
})

export default function* root() {
  yield all([
    ...auth,
    // ...acceptTerms,
    ...market,
    ...common,
    ...portfolio,
    ...account,
    ...orderbook,
    ...notifications,
    ...dashboard,
    ...marketPlace,
    ...scans,
    ...deployed,
    ...algos,
    ...screener,
    ...create,
    ...backtests,
    ...technicals,
    ...billing,
    // ...referral,
    ...payment,
    ...paymentPages,
    ...instruments,
  ])
}
