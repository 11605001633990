import React from 'react'

import AlertPopup from './AlertPopup'

const DeletePromptModal = React.memo(({
  onDelete,
  visible,
  onDismiss,
  dismissKey,
  typeText,
  name,
  loading,
  overrideMobilePosition = false,
  overrideTitle = '',
  overrideContent = '',
}) => {
  // const typeText = isScanner ? 'Scanner' : isWatchList ? 'Watchlist' : 'Strategy'
  const titleTypeText = name || typeText
  return (
    <AlertPopup
      visible={visible}
      onDismiss={onDismiss}
      dismissKey={dismissKey}
      loading={loading}
      extraStyles={{ margin: 'auto', width: '95%', minHeight: 120 }}
      title={overrideTitle || `Delete ${titleTypeText}`}
      content={overrideContent || `Are you sure, you want to remove this ${typeText}?`}
      overrideMobilePosition={overrideMobilePosition}
      actions={[
        {
          text: 'Cancel',
          action: () => onDismiss(dismissKey),
          btnProps: {
            buttonColor: 'tabsBg', mode: 'text', labelColor: 'textSecondary',
          },
        },
        {
          text: 'Delete', action: onDelete, btnProps: { buttonColor: 'red' },
        },
      ]}
    />
  )
})

export default DeletePromptModal
