// get backtest results
export const FETCH_BACKTEST_INIT = 'FETCH_BACKTEST_INIT'
export const FETCH_BACKTEST_SUCCESS = 'FETCH_BACKTEST_SUCCESS'
export const FETCH_BACKTEST_FAILURE = 'FETCH_BACKTEST_FAILURE'

// get graph data for active screen
export const GET_GRAPH_DATA = 'GET_GRAPH_DATA'

// set active stock to render corresponding details
export const SET_ACTIVE_STOCK = 'SET_ACTIVE_STOCK'

// get trade logs for selected stock
export const GET_TRADE_LOGS = 'GET_TRADE_LOGS'

// check for subscription limit before deploying
export const CHECK_SUBSCRIPTION_LIMIT = 'CHECK_SUBSCRIPTION_LIMIT'
export const CHECK_SUBSCRIPTION_LIMIT_SUCCESS = 'CHECK_SUBSCRIPTION_LIMIT_SUCCESS'
export const CHECK_SUBSCRIPTION_LIMIT_FAILURE = 'CHECK_SUBSCRIPTION_LIMIT_FAILURE'
export const LIMIT_EXCEEDED = 'LIMIT_EXCEEDED'

// deploy stocks
export const DEPLOY_STOCKS_INIT = 'DEPLOY_STOCKS_INIT'
export const DEPLOY_STOCKS_SUCCESS = 'DEPLOY_STOCKS_SUCCESS'
export const DEPLOY_STOCKS_FAILURE = 'DEPLOY_STOCKS_FAILURE'

export const TOGGLE_BROKERAGE = 'TOGGLE_BROKERAGE'

export const CREATE_EQUITIES_ENTRY = 'CREATE_EQUITIES_ENTRY'

export const RUN_BACKTEST = 'RUN_BACKTEST'
export const BACKTEST_RESULT_SUCCESS = 'BACKTEST_RESULT_SUCCESS'
export const BACKTEST_RESULT_FAILURE = 'BACKTEST_RESULT_FAILURE'

export const CLEAR_BACKTEST_RESPONSE = 'CLEAR_BACKTEST_RESPONSE'

export const TAKE_SCREENSHOT = 'TAKE_SCREENSHOT'

export const ONGOING_BACKTESTS = 'ONGOING_BACKTESTS'

export const GENERATE_SHARE_LINK = 'GENERATE_SHARE_LINK'
export const GENERATE_SHARE_LINK_SUCCESS = 'GENERATE_SHARE_LINK_SUCCESS'
export const GENERATE_SHARE_LINK_FAILURE = 'GENERATE_SHARE_LINK_FAILURE'

export const FETCH_SHARE_LINK = 'FETCH_SHARE_LINK'
export const FETCH_SHARE_LINK_SUCCESS = 'FETCH_SHARE_LINK_SUCCESS'
export const FETCH_SHARE_LINK_FAILURE = 'FETCH_SHARE_LINK_FAILURE'

export const UPDATE_BACKTEST_SCRIPS = 'UPDATE_BACKTEST_SCRIPS'

export const FETCH_BACKTEST_DETAILS = 'FETCH_BACKTEST_DETAILS'
export const FETCH_BACKTEST_DETAILS_SUCCESS = 'FETCH_BACKTEST_DETAILS_SUCCESS'
export const FETCH_BACKTEST_DETAILS_FAILURE = 'FETCH_BACKTEST_DETAILS_FAILURE'

export const REFRESH_BACKTEST = 'REFRESH_BACKTEST'

export const SAVE_BACKTEST_PARAMS = 'SAVE_BACKTEST_PARAMS'
export const SAVE_BACKTEST_PARAMS_SUCCESS = 'SAVE_BACKTEST_PARAMS_SUCCESS'
export const SAVE_BACKTEST_PARAMS_FAILURE = 'SAVE_BACKTEST_PARAMS_FAILURE'

export const UPDATE_BACKTEST_PARAMS = 'UPDATE_BACKTEST_PARAMS'

export const EDIT_SUBSCRIBED_ALGO = 'EDIT_SUBSCRIBED_ALGO'

export const FETCH_BACKTEST_ALL = 'FETCH_BACKTEST_ALL'
export const FETCH_BACKTEST_ALL_SUCCESS = 'FETCH_BACKTEST_ALL_SUCCESS'
export const FETCH_BACKTEST_ALL_FAILURE = 'FETCH_BACKTEST_ALL_FAILURE'

export const CHANGE_WASM_STATUS = 'CHANGE_WASM_STATUS'

export const FETCH_BACKTEST_DETAILS_DYC = 'FETCH_BACKTEST_DETAILS_DYC'
export const FETCH_BACKTEST_DETAILS_DYC_SUCCESS = 'FETCH_BACKTEST_DETAILS_DYC_SUCCESS'
export const FETCH_BACKTEST_DETAILS_DYC_FAILURE = 'FETCH_BACKTEST_DETAILS_DYC_FAILURE'

export const UPDATE_BT_SCRIPSTACK = 'UPDATE_BT_SCRIPSTACK'
