import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

import Button from './Button'
import CustomText from './CustomText'

import { ASSETS } from '../Theme'
import { pathname_mapping } from '../utils/navigationConstant'

function WelcomeRewardModal({ onDismiss, dismissKey, updateGA }) {
  const styles = stylesheet()
  const history = useHistory()
  const goToRewards = () => {
    updateAnalytics('Welcome', 'My rewards')
    onDismiss(dismissKey)
    history.push({
      pathname: pathname_mapping.Account,
      search: '?tab=rewards',
    })
  }
  const updateAnalytics = (a, l, v) => {
    if (updateGA) updateGA(a, l, v, 'Pop-ups')
  }
  const welcomeText = "You're almost there"
  return (
    <div>
      <div className={styles.container}>
        <div>
          <img src={ASSETS.Reward} alt="REWARDS" />
        </div>
        <div>
          <CustomText size="large_25" weight="semi_bold" className={styles.heading}>{welcomeText}</CustomText>
          <div style={{ display: 'grid', gridGap: 14 }}>
            <div className={styles.textContainer}>
              <CustomText size="medium">Perform your first deployment or scan,</CustomText>
              <CustomText size="medium" weight="semi_bold">&nbsp;to get 50C</CustomText>
            </div>
            <div className={styles.textContainer}>
              <CustomText size="medium" className={styles.text}>Buy your first plan,</CustomText>
              <CustomText size="medium" weight="semi_bold">&nbsp;to get 100C</CustomText>
            </div>
          </div>
        </div>
      </div>
      <Button
        text="Go to My Rewards"
        mode="contained"
        btnStyles={styles.signUp}
        buttonColor="blue"
        onPress={goToRewards}
      />
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    padding: 40,
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    lineHeight: 2,
  },
  heading: {
    padding: '20px 0px',
  },
  signUp: {
    width: '100%',
    height: 43,
  },
  textContainer: {
    display: 'block',
    // columnGap: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default WelcomeRewardModal
