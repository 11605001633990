import { segSymConverter } from '../../utils/common'
import {
  FETCH_POSITIONS_INIT,
  FETCH_POSITIONS_SUCCESS,
  FETCH_POSITIONS_FAILURE,
  FETCH_HOLDINGS_INIT,
  FETCH_HOLDINGS_SUCCESS,
  FETCH_HOLDINGS_FAILURE,
  FETCH_SPECIFIC_INIT,
  FETCH_SPECIFIC_SUCCESS,
  FETCH_SPECIFIC_FAILURE,
  GET_LIVE_ALGO_INIT,
  GET_LIVE_ALGO_SUCCESS,
  GET_LIVE_ALGO_FAILURE,
  EXIT_POSITION_INIT,
  EXIT_POSITION_SUCCESS,
  EXIT_POSITION_FAILURE,
  CLEAR_PORTFOLIO_RESP,

  ADD_UNREALISED_PNL,
  CLEAR_UNREALISED_PNL,
} from './actionTypes'
import { PORTFOLIO_TABS } from './portfolioUtils'

const initialState = {
  total_investments: 0.0,
  final_pnl: 0.0,
  current_value: 0.0,
  positions: [],
  isFetchingPositions: true,
  positionsError: false,
  holdings: [],
  isFetchingHoldings: false,
  holdingsError: false,
  isFetchingDetails: true,
  details: {},
  detailsError: false,
  isFetchingLiveAlgos: false,
  liveAlgosError: false,
  liveAlgos: [],
  isExitingPosition: false,
  exitingPositionError: false,
  exitingPositionErrorMsg: '',
  exitPositionResp: {},

  unrealisedPnl: {
    [PORTFOLIO_TABS.POSITIONS.KEY]: {},
    [PORTFOLIO_TABS.HOLDINGS.KEY]: {},
  },
}

const portfolio = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSITIONS_INIT:
      return {
        ...state,
        isFetchingPositions: true,
        positionsError: false,
      }

    case FETCH_POSITIONS_SUCCESS: {
      const { data = {} } = action
      return {
        ...state,
        isFetchingPositions: false,
        positions: data.positions ? data.positions.map((item) => {
          const { exchange, tradingsymbol } = item
          // as for BSE ltp comes as 0
          const seg = exchange === 'BSE' ? 'NSE' : exchange
          const { seg_sym } = segSymConverter(`${seg}_${tradingsymbol}`)
          return { ...item, seg_sym }
        }) : [],
      }
    }

    case FETCH_POSITIONS_FAILURE:
      return {
        ...state,
        isFetchingPositions: false,
        positionsError: true,
      }

    case FETCH_HOLDINGS_INIT:
      return {
        ...state,
        isFetchingHoldings: true,
        holdingsError: false,
      }

    case FETCH_HOLDINGS_SUCCESS: {
      const { data: { positions } } = action
      let total_investments = 0
      let final_pnl = 0
      let current_value = 0
      const holdings = []
      for (let i = 0; i < positions.length; i++) {
        const {
          average_price, quantity, pnl, exchange, tradingsymbol,
        } = positions[i]
        const investment = average_price * quantity
        total_investments += investment
        current_value += pnl + investment
        final_pnl += pnl
        // as for BSE ltp comes as 0
        const seg = exchange === 'BSE' ? 'NSE' : exchange
        const { seg_sym } = segSymConverter(`${seg}_${tradingsymbol}`)
        holdings.push({ ...positions[i], seg_sym })
      }
      total_investments = total_investments.toFixed(2)
      final_pnl = final_pnl.toFixed(2)
      current_value = current_value.toFixed(2)
      return {
        ...state,
        isFetchingHoldings: false,
        holdings,
        total_investments,
        final_pnl,
        current_value,
      }
    }

    case FETCH_HOLDINGS_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingHoldings: false,
        holdingsError: error,
      }
    }

    case FETCH_SPECIFIC_INIT:
      return {
        ...state,
        details: {},
        isFetchingDetails: true,
        detailsError: false,
      }

    case FETCH_SPECIFIC_SUCCESS: {
      const { data } = action
      return {
        ...state,
        details: data.positions[0],
        isFetchingDetails: false,
      }
    }

    case FETCH_SPECIFIC_FAILURE: {
      return {
        ...state,
        isFetchingDetails: false,
        detailsError: true,
      }
    }

    case GET_LIVE_ALGO_INIT:
      return {
        ...state,
        isFetchingLiveAlgos: true,
        liveAlgosError: false,
      }

    case GET_LIVE_ALGO_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingLiveAlgos: false,
        liveAlgos: data.algo_names || [],
      }
    }

    case GET_LIVE_ALGO_FAILURE: {
      return {
        ...state,
        isFetchingLiveAlgos: false,
        liveAlgosError: true,
      }
    }

    case EXIT_POSITION_INIT:
      return {
        ...state,
        isExitingPosition: true,
        exitingPositionError: false,
      }

    case EXIT_POSITION_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isExitingPosition: false,
        exitPositionResp: data,
      }
    }

    case EXIT_POSITION_FAILURE: {
      return {
        ...state,
        isExitingPosition: false,
        exitingPositionError: true,
        exitingPositionErrorMsg: action.error,
      }
    }

    case CLEAR_PORTFOLIO_RESP: {
      return {
        ...state,
        isExitingPosition: false,
        exitingPositionError: false,
        exitPositionResp: {},
      }
    }

    case ADD_UNREALISED_PNL: {
      const { index, pnl, tabKey } = action
      const { unrealisedPnl } = state

      if(index === undefined || index === null || !tabKey) {
        return {
          ...state,
        }
      }

      const newUnrealisedPnl = { ...unrealisedPnl }
      if(newUnrealisedPnl[tabKey]?.[index] !== pnl) {
        newUnrealisedPnl[tabKey][index] = pnl
      }
      return {
        ...state,
        unrealisedPnl: newUnrealisedPnl,
      }
    }
    case CLEAR_UNREALISED_PNL: {
      const { index, tabKey } = action
      const { unrealisedPnl } = state
      let newUnrealisedPnl = { ...unrealisedPnl }

      if(index === undefined || index === null || !tabKey) {
        newUnrealisedPnl = initialState.unrealisedPnl
      } else {
        newUnrealisedPnl[tabKey][index] = 0
      }

      return {
        ...state,
        unrealisedPnl: newUnrealisedPnl,
      }
    }

    default:
      return state
  }
}

export default portfolio
