import {
  FETCH_TECHNICALS_LIST,
  FETCH_TECHNICALS_LIST_SUCCESS,
  FETCH_TECHNICALS_LIST_FAILURE,
  GET_MULTI_ANALYSIS_INIT,
  GET_MULTI_ANALYSIS_SUCCESS,
  GET_MULTI_ANALYSIS_FAILURE,
  CLEAR_MULTI_ANALYSIS,
  UPDATE_TECHNICALS_LIST,
  UPDATE_TECHNICALS_LIST_SUCCESS,
  UPDATE_TECHNICALS_LIST_FAILURE,
  // FETCH_CAROUSAL_CATEGORY,
  // FETCH_CAROUSAL_CATEGORY_SUCCESS,
  // FETCH_CAROUSAL_CATEGORY_FAILURE,
  FETCH_RESISTANCE_SUCCESS,
  FETCH_RESISTANCE_FAILURE,
  FETCH_RESISTANCE,
  CLEAR_RESISTANCE,
} from './actionTypes'

export const fetchTechnicalsList = (params, headers) => {
  return {
    type: FETCH_TECHNICALS_LIST,
    params,
    headers,
  }
}

export const fetchTechnicalsListSuccess = (data) => {
  return {
    type: FETCH_TECHNICALS_LIST_SUCCESS,
    data,
  }
}

export const fetchTechnicalsListFailure = (error) => {
  return {
    type: FETCH_TECHNICALS_LIST_FAILURE,
    error,
  }
}

export function getMultiAnalysisSuccess(data, stockList) {
  return {
    type: GET_MULTI_ANALYSIS_SUCCESS,
    data,
    stockList,
  }
}

export function getMultiAnalysisFailure(error) {
  return {
    type: GET_MULTI_ANALYSIS_FAILURE,
    error,
  }
}

export function getMultiAnalysis(params, headers, prevStocks = []) {
  return {
    type: GET_MULTI_ANALYSIS_INIT,
    params,
    headers,
    prevStocks,
  }
}

export function clearMultiAnalysis() {
  return {
    type: CLEAR_MULTI_ANALYSIS,
  }
}

export function updateTechnicalsList(params, headers) {
  return {
    type: UPDATE_TECHNICALS_LIST,
    params,
    headers,
  }
}

export function updateTechnicalsListSuccess(data) {
  return {
    type: UPDATE_TECHNICALS_LIST_SUCCESS,
    data,
  }
}

export function updateTechnicalsListFailure(error) {
  return {
    type: UPDATE_TECHNICALS_LIST_FAILURE,
    error,
  }
}

// export function fetchCarosusalCategories(params, headers) {
//   return {
//     type: FETCH_CAROUSAL_CATEGORY,
//     params,
//     headers,
//   }
// }

// export function fetchCarosusalCategoriesSuccess(data, category) {
//   return {
//     type: FETCH_CAROUSAL_CATEGORY_SUCCESS,
//     data,
//     category,
//   }
// }

// export function fetchCarosusalCategoriesFailure(error) {
//   return {
//     type: FETCH_CAROUSAL_CATEGORY_FAILURE,
//     error,
//   }
// }

export function fetchResistanceSuccess(data, stockList, single) {
  return {
    type: FETCH_RESISTANCE_SUCCESS,
    data,
    stockList,
    single,
  }
}

export function fetchResistanceFailure(error) {
  return {
    type: FETCH_RESISTANCE_FAILURE,
    error,
  }
}

export function fetchResistance(params, headers, single) {
  return {
    type: FETCH_RESISTANCE,
    params,
    headers,
    single,
  }
}

export function clearResistance(single) {
  return {
    type: CLEAR_RESISTANCE,
    single,
  }
}
