import { getNudgeRules } from '../../components/AlgoCondensedView/AlgoUtils'
import {
  FETCH_BACKTEST_INIT,
  FETCH_BACKTEST_SUCCESS, // bt result (row data)
  FETCH_BACKTEST_FAILURE,
  GET_GRAPH_DATA,
  SET_ACTIVE_STOCK,
  CHECK_SUBSCRIPTION_LIMIT,
  CHECK_SUBSCRIPTION_LIMIT_SUCCESS,
  GET_TRADE_LOGS,
  DEPLOY_STOCKS_INIT,
  DEPLOY_STOCKS_SUCCESS,
  DEPLOY_STOCKS_FAILURE,
  LIMIT_EXCEEDED,
  TOGGLE_BROKERAGE,
  BACKTEST_RESULT_SUCCESS, // rerun bt
  BACKTEST_RESULT_FAILURE,
  CREATE_EQUITIES_ENTRY,
  CLEAR_BACKTEST_RESPONSE,
  TAKE_SCREENSHOT,
  ONGOING_BACKTESTS,
  GENERATE_SHARE_LINK,
  GENERATE_SHARE_LINK_SUCCESS,
  GENERATE_SHARE_LINK_FAILURE,
  FETCH_SHARE_LINK,
  FETCH_SHARE_LINK_SUCCESS,
  FETCH_SHARE_LINK_FAILURE,
  UPDATE_BACKTEST_SCRIPS,
  FETCH_BACKTEST_DETAILS,
  FETCH_BACKTEST_DETAILS_SUCCESS, // bt chart details
  FETCH_BACKTEST_DETAILS_FAILURE,
  REFRESH_BACKTEST,
  SAVE_BACKTEST_PARAMS,
  SAVE_BACKTEST_PARAMS_SUCCESS,
  SAVE_BACKTEST_PARAMS_FAILURE,
  UPDATE_BACKTEST_PARAMS,
  EDIT_SUBSCRIBED_ALGO,
  FETCH_BACKTEST_ALL,
  FETCH_BACKTEST_ALL_SUCCESS,
  FETCH_BACKTEST_ALL_FAILURE,
  CHANGE_WASM_STATUS,
  FETCH_BACKTEST_DETAILS_DYC,
  FETCH_BACKTEST_DETAILS_DYC_SUCCESS,
  FETCH_BACKTEST_DETAILS_DYC_FAILURE,
  UPDATE_BT_SCRIPSTACK,
  RUN_BACKTEST,
} from './actionTypes'

const initialState = {
  backtests: {},
  isFetchingBacktests: false,
  error: false,
  backtestsError: false,
  backtestsErrorMsg: null,
  isFetchingBrokerage: false,

  backtestParams: {},
  paramsEdited: false, // flag to set true when params edited and run backtest
  toggleEditSubscribed: false, // toggle flag can be true and false

  graphData: [],
  activeStock: {},
  tradeLogs: [],
  rendering: true,
  isCheckingLimit: false,
  limitExceeded: false,
  isDeployingStocks: false,
  subsLimitResp: {},
  deployResponse: {},
  deploymentError: null,
  deploymentErrorMsg: '',
  brokerage: false,
  takeScreenShot: false,
  onGoingBacktests: {},
  backtest_share_uuid: null,
  multiShare: false,
  generateShareLinkSuccess: false,
  isGeneratingShareLink: false,
  generateShareLinkError: false,
  generateShareLinkErrorMsg: '',
  isFetchingShareableLink: false,
  shareLinkId: null,
  fetchShareLinkError: false,
  fetchShareLinkErrorMsg: '',

  newScripList: null,
  newScripListParams: {},
  backtestDetails: {},
  refreshBacktestPage: false,

  isSavingBacktestParams: false,
  saveBacktestParamsSuccess: false,
  saveBacktestParamsError: false,
  saveBacktestParamsErrorMsg: '',

  isFetchingData: true,
  fetchingAllDataError: '',

  isWasmLoaded: false,
  loadWasm: false,

  backtestRunningStack: [],

  nudgeIndexesDet: {},
}

const backtest = (state = initialState, action) => {
  switch (action.type) {
    case RUN_BACKTEST: {
      return {
        ...state,
        isFetchingData: true,
      }
    }

    case FETCH_BACKTEST_INIT: {
      return {
        ...state,
        isFetchingBacktests: true,
        backtestsError: false,
        backtestsErrorMsg: '',
        backtests: action.clearPrevBacktests ? {} : { ...state.backtests },
        refreshBacktestPage: false,
      }
    }

    case FETCH_BACKTEST_SUCCESS: {
      const { data } = action
      let nudgeIndexesDet = {}
      let segSym = ''
      let btResult = {}
      if(data.backtest_results.length) {
        const { backtest_results = [] } = data
        const { backtest_result = {}, seg_sym = '' } = backtest_results[0]
        segSym = seg_sym
        btResult = backtest_result
        const nudgeIndexes = getNudgeRules(btResult, segSym)
        nudgeIndexesDet = {
          [segSym]: nudgeIndexes,
        }
      }

      return {
        ...state,
        isFetchingBacktests: false,
        backtests: data,
        backtestsError: false,
        nudgeIndexesDet,
      }
    }

    case FETCH_BACKTEST_FAILURE: {
      return {
        ...state,
        backtestsError: true,
        isFetchingBacktests: false,
        backtestsErrorMsg: action.errorMsg || '',
      }
    }

    case CREATE_EQUITIES_ENTRY: {
      const { equities, backtest_results } = action
      return {
        ...state,
        backtests: {
          ...state.backtests,
          equities,
          backtest_results,
          backtestsError: false,
          // deployed_seg_sym: []
        },
      }
    }

    case BACKTEST_RESULT_SUCCESS: {
      const { data, algo_obj, seg_sym = '' } = action
      const { algo_uuid } = algo_obj
      const { backtest_results = [] } = state.backtests
      const backtestResults = [...backtest_results]
      let nudgeIndexesDet = {}
      let nudgeIndexes = []
      let modData = { ...data }
      if (data.error_msg) {
        modData = {
          [seg_sym]: { error_msg: data.error_msg, seg_sym, error: true },
        }
      }
      const backtestData = {
        algo_obj,
        algo_uuid,
        backtest_result: modData,
        seg_sym,
      }
      const index = backtestResults.findIndex(result => result.seg_sym === seg_sym)
      backtestResults[index] = backtestData

      if(backtestResults?.length === 1) {
        nudgeIndexes = getNudgeRules(data, seg_sym)
      }

      nudgeIndexesDet = {
        [seg_sym]: nudgeIndexes,
      }

      const finalParams = {
        backtests: {
          ...state.backtests,
          error: false,
          backtest_results: [...backtestResults],
        },
        backtestDetails: {
          ...state.backtestDetails,
          [seg_sym]: modData[seg_sym] || {},
        },
      }
      if (state.backtestRunningStack.length < 1) {
        finalParams.isFetchingData = false
      }
      return {
        ...state,
        ...finalParams,
        nudgeIndexesDet,
      }
    }

    case BACKTEST_RESULT_FAILURE: {
      const { error, seg_sym } = action
      const { backtest_results = [] } = state.backtests
      const backtestResults = [...backtest_results]
      const index = backtestResults.findIndex(result => result.seg_sym === seg_sym)
      backtestResults[index].waiting = false
      backtestResults[index].empty_msg = error.error_msg
      backtestResults[index].empty = true
      backtestResults[index].backtest_result[seg_sym].error = true
      backtestResults[index].backtest_result[seg_sym].error_msg = error.error_msg || 'Error getting backtest result'
      backtestResults[index].backtest_result[seg_sym].waiting = false
      const finalParams = {
        backtests: {
          ...state.backtests,
          backtest_results: backtestResults,
          error: true,
          errorMsg: JSON.stringify(error) || 'Error getting backtest result',
        },
        backtestDetails: {
          ...state.backtestDetails,
          [seg_sym]: { error: true },
        },
      }
      if (state.backtestRunningStack.length < 1) {
        finalParams.isFetchingData = false
      }
      return {
        ...state,
        ...finalParams,
        // error: true
      }
    }

    case GET_GRAPH_DATA: {
      const { index, name } = action
      const stockName = `NSE_${name}`
      const pnlData = state.backtests.backtest_results[index]
        .backtest_result[stockName].pnl
      const stroke = state.backtests.backtest_results[index]
        .backtest_result[stockName].final_pnl > 0 ? '#06d092' : '#ff4258'
      const graphData = { pnlData, stroke }
      return {
        ...state,
        graphData,
        rendering: false,
      }
    }

    case SET_ACTIVE_STOCK: {
      const { item, index } = action
      const activeStock = { item, index }
      return {
        ...state,
        activeStock,
        rendering: true,
      }
    }

    case GET_TRADE_LOGS: {
      const { item, index } = state.activeStock
      const stockName = `${item.stockName}`
      const tradeLogs = state.backtests.backtest_results[index]
        .backtest_result[stockName].trade_log
      return {
        ...state,
        tradeLogs,
      }
    }

    case CHECK_SUBSCRIPTION_LIMIT: {
      return {
        ...state,
        isCheckingLimit: true,
        deploymentError: null,
        limitExceeded: false,
      }
    }

    case CHECK_SUBSCRIPTION_LIMIT_SUCCESS: {
      const { data } = action
      return {
        ...state,
        subsLimitResp: data,
        isCheckingLimit: false,
      }
    }

    case DEPLOY_STOCKS_INIT: {
      return {
        ...state,
        isDeployingStocks: true,
        deploymentError: null,
        deployResponse: {},
      }
    }

    case DEPLOY_STOCKS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isDeployingStocks: false,
        deployResponse: data,
      }
    }

    case DEPLOY_STOCKS_FAILURE: {
      const { error } = action
      return {
        ...state,
        isCheckingLimit: false,
        isDeployingStocks: false,
        deploymentError: true,
        deploymentErrorMsg: error,
      }
    }

    case LIMIT_EXCEEDED: {
      return {
        ...state,
        limitExceeded: true,
        isCheckingLimit: false,
      }
    }

    case TOGGLE_BROKERAGE: {
      const { to } = action
      return {
        ...state,
        brokerage: to,
      }
    }

    case CLEAR_BACKTEST_RESPONSE:
      return {
        ...state,
        isCheckingLimit: false,
        limitExceeded: false,
        isDeployingStocks: false,
        subsLimitResp: {},
        deployResponse: {},
        deploymentError: null,
        deploymentErrorMsg: '',
        backtests: {},
        backtestDetails: {},
        backtestParams: {},
        paramsEdited: false,
      }

    case TAKE_SCREENSHOT:
      return {
        ...state,
        takeScreenShot: action.take,
      }

    case ONGOING_BACKTESTS: {
      const { algoId, isRunning } = action
      const modBacktests = { ...state.onGoingBacktests }
      if (!isRunning) {
        delete modBacktests[algoId]
      } else {
        modBacktests[algoId] = isRunning
      }
      return {
        ...state,
        onGoingBacktests: modBacktests,
      }
    }

    case GENERATE_SHARE_LINK:
      return {
        ...state,
        backtest_share_uuid: null,
        generateShareLinkSuccess: false,
        isGeneratingShareLink: true,
        generateShareLinkError: false,
        generateShareLinkErrorMsg: '',
      }

    case GENERATE_SHARE_LINK_SUCCESS:
      if(action.data.algo_share_uuid) {
        return {
          ...state,
          backtest_share_uuid: action.data.backtest_share_uuid || action.data.algo_share_uuid,
          isGeneratingShareLink: false,
          generateShareLinkSuccess: true,
          multiShare: true,
        }
      }
      return {
        ...state,
        backtest_share_uuid: action.data.backtest_share_uuid || action.data.algo_share_uuid,
        isGeneratingShareLink: false,
        generateShareLinkSuccess: true,
        multiShare: false,
      }

    case GENERATE_SHARE_LINK_FAILURE:
      return {
        ...state,
        isGeneratingShareLink: false,
        generateShareLinkError: true,
        generateShareLinkErrorMsg: action.error,
      }

    case FETCH_SHARE_LINK:
      return {
        ...state,
        isFetchingShareableLink: true,
        shareLinkId: null,
        fetchShareLinkError: false,
        fetchShareLinkErrorMsg: '',
      }

    case FETCH_SHARE_LINK_SUCCESS:
      return {
        ...state,
        isFetchingShareableLink: false,
        shareLinkId: action.data.key,
      }

    case FETCH_SHARE_LINK_FAILURE:
      return {
        ...state,
        isFetchingShareableLink: false,
        fetchShareLinkError: true,
        fetchShareLinkErrorMsg: action.error,
      }

    case UPDATE_BACKTEST_SCRIPS:
      return {
        ...state,
        newScripList: action.list,
        newScripListParams: action.extraParams || {},
      }

    // fetch backtest chart
    case FETCH_BACKTEST_DETAILS: {
      const { params } = action
      const { seg_sym } = params
      const oldState = state.backtestDetails[seg_sym] || {}
      return {
        ...state,
        backtestDetails: {
          ...state.backtestDetails,
          [seg_sym]: { ...oldState, waiting: true, error: false },
        },
      }
    }

    case FETCH_BACKTEST_DETAILS_SUCCESS: {
      const { params, data } = action
      const { seg_sym } = params
      let finalSegSym = seg_sym

      if(params.seg_sym.includes('%26')) {
        finalSegSym = finalSegSym.replace('%26', '&')
      }

      return {
        ...state,
        backtestDetails: {
          ...state.backtestDetails,
          [finalSegSym]: data.backtests && data.backtests[0]
            && data.backtests[0].backtest_result[finalSegSym],
        },
      }
    }

    case FETCH_BACKTEST_DETAILS_FAILURE: {
      const { params } = action
      const { seg_sym } = params
      return {
        ...state,
        backtestDetails: {
          ...state.backtestDetails,
          [seg_sym]: { waiting: false, error: true },
        },
      }
    }

    case FETCH_BACKTEST_DETAILS_DYC: {
      const { params } = action
      const { seg_sym } = params
      const oldState = state.backtestDetails[seg_sym] || {}
      return {
        ...state,
        backtestDetails: {
          ...state.backtestDetails,
          [seg_sym]: { ...oldState, waiting: true, error: false },
        },
      }
    }

    case FETCH_BACKTEST_DETAILS_DYC_SUCCESS: {
      const { params, data, algo_obj } = action
      const { algo_uuid, seg_sym } = params
      let finalSegSym = seg_sym
      if(params.seg_sym.includes('%26')) {
        finalSegSym = finalSegSym.replace('%26', '&')
      }
      const { backtest_results = [] } = state.backtests
      const backtestResults = [...backtest_results]
      let modData = { ...data.backtests[0]?.backtest_result }
      if (data.error_msg) {
        modData = {
          [finalSegSym]: { error_msg: data.error_msg, seg_sym: finalSegSym, error: true },
        }
      }
      const backtestData = {
        algo_obj,
        algo_uuid,
        backtest_result: modData,
        seg_sym: finalSegSym,
      }
      const index = backtestResults.findIndex(result => result.seg_sym === finalSegSym)
      backtestResults[index] = backtestData
      const finalParams = {
        backtests: {
          ...state.backtests,
          backtest_results: [...backtestResults],
          error: false,
        },
        backtestDetails: {
          ...state.backtestDetails,
          [finalSegSym]: data.backtests && data.backtests[0]
            && data.backtests[0].backtest_result[finalSegSym],
        },
      }

      if (state.backtestRunningStack.length < 1) {
        finalParams.isFetchingData = false
      }
      return {
        ...state,
        ...finalParams,
      }
    }

    case FETCH_BACKTEST_DETAILS_DYC_FAILURE: {
      const { error, seg_sym, runtime } = action
      const { backtest_results = [] } = state.backtests
      const backtestResults = [...backtest_results]
      const index = backtestResults.findIndex(result => result.seg_sym === seg_sym)
      backtestResults[index].waiting = false
      backtestResults[index].empty_msg = error.error_msg
      backtestResults[index].empty = true
      backtestResults[index].runtime = runtime
      backtestResults[index].backtest_result[seg_sym].error = true
      backtestResults[index].backtest_result[seg_sym].error_msg = error.error_msg || 'Backtest is taking longer than expected, please refresh after few minutes to see results'
      backtestResults[index].backtest_result[seg_sym].waiting = false
      const finalParams = {
        backtests: {
          ...state.backtests,
          backtest_results: backtestResults,
          error: true,
          errorMsg: JSON.stringify(error) || 'Backtest is taking longer than expected, please refresh after few minutes to see results',
        },
        backtestDetails: {
          ...state.backtestDetails,
          [seg_sym]: { error: true },
        },
      }
      if (state.backtestRunningStack.length < 1) {
        finalParams.isFetchingData = false
      }
      return {
        ...state,
        ...finalParams,
        // error: true
      }
    }

    case REFRESH_BACKTEST: {
      return {
        ...state,
        refreshBacktestPage: action.flag,
      }
    }

    case SAVE_BACKTEST_PARAMS: {
      return {
        ...state,
        isSavingBacktestParams: true,
        saveBacktestParamsSuccess: false,
        saveBacktestParamsError: false,
        saveBacktestParamsErrorMsg: '',
      }
    }

    case SAVE_BACKTEST_PARAMS_SUCCESS: {
      return {
        ...state,
        isSavingBacktestParams: false,
        saveBacktestParamsSuccess: true,
      }
    }

    case SAVE_BACKTEST_PARAMS_FAILURE: {
      return {
        ...state,
        isSavingBacktestParams: false,
        saveBacktestParamsError: true,
        saveBacktestParamsErrorMsg: action.error,
      }
    }

    case UPDATE_BACKTEST_PARAMS: {
      // do not change the merging with old params
      return {
        ...state,
        backtestParams: { ...state.backtestParams, ...action.params },
        paramsEdited: action.paramsEdited,
      }
    }

    case EDIT_SUBSCRIBED_ALGO: {
      return {
        ...state,
        toggleEditSubscribed: !state.toggleEditSubscribed,
      }
    }

    case FETCH_BACKTEST_ALL:
      return {
        ...state,
        isFetchingData: true,
      }

    case FETCH_BACKTEST_ALL_SUCCESS: {
      if (action.data.backtest_results && action.data.backtest_results) {
        let backtestDetails = {}
        let nudgeIndexesDet = {}
        action.data.backtest_results.forEach((btResult) => {
          const { seg_sym = '', backtest_result = {} } = btResult

          const nudgeIndexes = getNudgeRules(backtest_result, seg_sym)

          nudgeIndexesDet = {
            ...nudgeIndexesDet,
            [seg_sym]: nudgeIndexes,
          }
          if(state.backtestDetails[seg_sym]) {
            backtestDetails = { ...backtestDetails, [seg_sym]: state.backtestDetails[seg_sym] }
          } else {
            backtestDetails = {
              ...backtestDetails,
              [seg_sym]: backtest_result[seg_sym],
            }
          }
        })
        if (state.backtestRunningStack.length < 1) {
          return {
            ...state,
            isFetchingData: false,
            backtestDetails,
            nudgeIndexesDet,
          }
        }
        return {
          ...state,
          backtestDetails,
          nudgeIndexesDet,
        }
      }
      if (state.backtestRunningStack.length < 1) {
        return {
          ...state,
          isFetchingData: false,
        }
      }
      return {
        ...state,
      }
    }

    case FETCH_BACKTEST_ALL_FAILURE: {
      if (state.backtestRunningStack.length < 1) {
        return {
          ...state,
          isFetchingData: false,
          fetchingAllDataError: action.error || 'Error calculating cummulative pnl',
        }
      }
      return {
        ...state,
        fetchingAllDataError: action.error || 'Error calculating cummulative pnl',
      }
    }

    case CHANGE_WASM_STATUS: {
      const { status = false, load = false } = action
      return {
        ...state,
        loadWasm: load,
        isWasmLoaded: status,
      }
    }

    case UPDATE_BT_SCRIPSTACK: {
      const { scripList, updateType } = action
      if (updateType === 'add') {
        return {
          ...state,
          backtestRunningStack: scripList,
        }
      } if (updateType === 'clear') {
        return {
          ...state,
          backtestRunningStack: [],
        }
      }

      return {
        ...state,
        backtestRunningStack: state.backtestRunningStack
          .filter(scrip => scripList.indexOf(scrip) === -1),
      }
    }

    default:
      return state
  }
}

export default backtest
