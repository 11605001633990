import React, { PureComponent } from 'react'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import { connect } from 'react-redux'

import ConditionAddCard from '../Create/ConditionAddCard'
import IndicatorFinder from '../Create/IndicatorFinder'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import MathFunctionInput from './MathFunctionInput'
import ScreenerInput from './ScreenerInput'
import CustomModal from '../../UI/CustomModal'
import IndicatorSectionView from '../Create/IndicatorSectionView'
import InputOptions from './InputOptions'

import { PRO_FEATURE_MAP } from '../../utils/userAccessChecks'
// import { showSnackbar } from '../../UI/Snackbar'
import { withTheme } from '../../Theme/ThemeProvider'
import {
  ICONS, COLORS, SPACING, theme,
} from '../../Theme'
import {
  functionOptions,
  indicatorsOptions,
  mathFuncOptions,
  comparatorsOptions,
  operatorsOptions,
  bracketsOptions,
  freq_candle_map,
  MATH_FUNCS,
  ONLY_SCANNER_FUNC_KEYS,
  mtf_candle_map,
} from '../../utils/consts'
import { getTemplate, nameGenerator } from '../../utils/common'
import { showSnackbar } from '../../UI/Snackbar'
import { setBlockedTimeFrame } from '../../containers/Common/actions'

const candleMap = { ...freq_candle_map, ...mtf_candle_map }
const SECTION_TYPE = {
  INDICATOR: 'indicators',
  COMPARATOR: 'comparators',
  MATH_FUNCTION: 'math functions',
  COMPARE_INDICATOR: 'compareIndicator',
}

class IndicatorSection extends PureComponent {
  constructor(props) {
    super(props)
    const {
      indicatorState, createStrategy, editType, CONDITION_TYPE, isBasicMode,
    } = this.props
    let conditions = []
    // const showIndicator = index !== 0 && indicatorState.length === 0
    if (indicatorState.length) {
      conditions = [...indicatorState]
    }
    if (conditions.length === 0 || conditions[conditions.length - 1].valid) {
      conditions.push(getTemplate('func'))
    }
    this.initialOptions = ['Indicators', 'Bracket', 'Math functions']
    this.singleConditionsArr = ['ceil', 'floor', 'abs']
    this.optionList = null
    this.inputOptionsTree = {}
    this.tree = {}
    this.updatedActiveItem = {}
    this.activeCondition = { index: 0 }
    // addCondition
    this.setOptions()
    let extraState = {}
    if (isBasicMode) {
      let showOthers = true
      if (conditions[1] && conditions[1].name === 'N/A') {
        showOthers = false
      }
      extraState = {
        ...this.updateCompareIndicators(conditions, 0),
        showOthers,
        indicatorFinderIndex: null,
      }
    }

    this.state = {
      conditions,
      candle_freq: 10,
      showIndicator: false,
      showInputOptions: false,
      sectionType: SECTION_TYPE.INDICATOR,
      showDetails: false,

      ...extraState,
    }
    this.itemKey = 'indicatorDetails'
    this.keysToHide = ['signal_candle', 'trade_candle']
    if (createStrategy) {
      if (CONDITION_TYPE && editType === CONDITION_TYPE.EXIT) {
        this.keysToHide = ONLY_SCANNER_FUNC_KEYS
      } else {
        this.keysToHide = [...this.keysToHide, ...ONLY_SCANNER_FUNC_KEYS]
      }
    }
  }

  componentDidUpdate = () => {
    const { saveConditions, index } = this.props
    const { conditions } = this.state
    const modConditions = [...conditions]
    const len = conditions.length
    if (len > 0 && conditions[len - 1].valid) {
      modConditions.push(getTemplate('func'))
      this.setState({ conditions: modConditions })
    } else if (len > 1 && !conditions[len - 1].valid && !conditions[len - 2].valid) {
      modConditions.pop()
      this.setState({ conditions: modConditions })
      saveConditions(modConditions, index)
    }
  }

  fieldClearHandler = (index) => {
    this.setState((prevState) => {
      const sec = [...prevState.conditions]
      sec[index].name = ''
      sec[index].valid = false
      return { conditions: sec }
    })
  }

  getModalState = (showFlag, i) => {
    const { isBasicMode } = this.props
    if (isBasicMode) {
      return { indicatorFinderIndex: showFlag ? i : null }
    }
    return { showIndicator: showFlag }
  }

  indicatorSelect = (indicator, index, template, details) => {
    const {
      saveConditions,
      assist,
      trainerModel,
      index: parentIndx,
    } = this.props
    const { conditions } = this.state
    if (assist && trainerModel[indicator.key] && conditions.length && !conditions[0].valid) {
      const { conditions: assistConditions } = trainerModel[indicator.key][0][0]
      const modConditions = [...assistConditions]
      modConditions.push(getTemplate('func'))
      const extraState = {
        ...this.getModalState(false, index),
        ...this.updateCompareIndicators(modConditions, index),
      }
      this.setState({
        conditions: modConditions, ...extraState,
      })
      saveConditions(assistConditions, parentIndx)
      return
    }
    const modConditions = [...conditions]
    const modItem = { ...template }
    let showDetails = false
    modItem.indicatorDetails = indicator
    if (indicator.params.length === 0) {
      modItem.valid = true
      modItem.name = indicator.name
      modItem.details = {}
    } else if (details) {
      const finalName = nameGenerator(modItem.indicatorDetails.syntax, details)
      modItem.name = finalName
      modItem.valid = !(finalName.search('undefined') > -1)
      modItem.show = false
      modItem.details = details
    } else {
      this.detailsKey = 'details'
      this.itemKey = 'indicatorDetails'
      showDetails = true
      modItem.show = true
    }
    this.activeCondition = { ...modItem, index }
    modConditions[index] = { ...modItem, id: modConditions[index].id }
    const extraChanges = this.updateCompareIndicators(modConditions, index)
    this.setState({
      conditions: modConditions,
      showDetails,
      sectionType: SECTION_TYPE.INDICATOR,
      ...extraChanges,
      ...this.getModalState(showDetails, index),
    })
    saveConditions(modConditions, parentIndx)
  }

  comparatorSelect = (comparator, index, template, details) => {
    const { saveConditions, index: parentIndx } = this.props
    const { conditions } = this.state
    const modConditions = [...conditions]
    const modItem = { ...template }
    modItem.comparator = comparator
    let showDetails = false
    if (comparator.params && comparator.params.length === 0) {
      modItem.name = comparator.name
      modItem.valid = true
      modItem.details = {}
    } else if (details) {
      const finalName = nameGenerator(modItem.comparator.syntax, details)
      modItem.name = finalName
      modItem.details = details
      modItem.valid = !(finalName.search('undefined') > -1)
      modItem.show = false
    } else {
      this.detailsKey = 'details'
      this.itemKey = 'comparator'
      showDetails = true
      modItem.show = true
    }
    this.activeCondition = { ...modItem, index }
    modConditions[index] = { ...modItem, id: modConditions[index].id }
    this.setState({
      conditions: modConditions,
      showDetails,
      ...this.getModalState(showDetails, index),
      sectionType: SECTION_TYPE.COMPARATOR,
    })
    saveConditions(modConditions, parentIndx)
  }

  updateCompareIndicators = (conditions, index) => {
    let showOthers = true
    const { indicatorsList, isBasicMode } = this.props
    let comparatorEditable = false
    let indicatorEditable = false
    let isEditable = false

    let indicatorValid = false
    let compareIndicatorValid = false

    this.allowedComparatorIndicators = []
    if (isBasicMode) {
      const indicator = conditions[index]
      indicatorEditable = true
      indicatorValid = this.isIndicatorValid(conditions, 0)
      compareIndicatorValid = this.isIndicatorValid(conditions, 2)

      comparatorEditable = !!indicatorValid
      isEditable = !!indicatorValid

      if (!isEmpty(indicator.indicatorDetails)
      && indicatorsList && indicatorsList.indicators) {
        const allowedComparisons = indicator
          ? (indicator.indicatorDetails.allowed_comparison
            || indicator.indicatorDetails.allowed_comparision)
          : []
        if (allowedComparisons.length === 0) {
          this.allowedComparatorIndicators = Object.keys(indicatorsList.indicators).filter(
            indi => allowedComparisons.some(
              allowedGrp => allowedGrp === indicatorsList[indi].function_group,
            ),
          )
          showOthers = false
        }
      }
    }
    return {
      showOthers,
      indicatorEditable,
      comparatorEditable,
      isEditable,
      compareIndicatorValid,
      indicatorValid,
    }
  }

  saveIndicatorDetails = (index, params, candle_freq) => {
    const {
      saveConditions, index: parentIndx,
    } = this.props
    const { conditions } = this.state
    const modConditions = [...conditions]
    const newCond = { ...modConditions[index] }
    const syntax = newCond[this.itemKey] ? newCond[this.itemKey].syntax : ''
    const finalName = nameGenerator(syntax, params)
    newCond.name = finalName
    newCond.valid = !(finalName.search('undefined') > -1)
    newCond.show = false
    newCond.details = params
    modConditions[index] = newCond
    const extraChanges = this.updateCompareIndicators(modConditions, index)
    this.setState({
      conditions: modConditions,
      candle_freq,
      showDetails: false,
      ...extraChanges,
      ...this.getModalState(false),
    })
    saveConditions(modConditions, parentIndx)
  }

  saveComparatorDetails = (index, params) => {
    const { saveConditions, index: parentIndx } = this.props
    const { conditions } = this.state // candle_freq
    const modConditions = [...conditions]
    const modItem = { ...modConditions[index] }
    const finalName = nameGenerator(modItem.comparator.syntax, params)
    modItem.name = finalName
    modItem.details = params
    modItem.valid = !(finalName.search('undefined') > -1)
    modItem.show = false
    modConditions[index] = modItem
    this.setState({ conditions: modConditions, showDetails: false, ...this.getModalState(false) })
    saveConditions(modConditions, parentIndx)
  }

  setMinCandleInterval = (interval) => {
    if (!this.selected_candle_interval && interval) {
      this.selected_candle_interval = interval
    } else if (this.selected_candle_interval && interval) {
      if (interval > this.selected_candle_interval) {
        this.selected_candle_interval = interval
      }
    }
  }

  handleChange = (index, value, template) => {
    const { saveConditions, index: parentIndx } = this.props
    const { conditions } = this.state // candle_freq
    const funcs = conditions
    const newItem = { ...template }
    if (newItem.type === 'filler') {
      newItem.name = value
      newItem.valid = true
    } else {
      newItem.name = value
    }
    funcs[index] = { ...newItem, id: funcs[index].id }
    this.setState({ conditions: funcs })
    saveConditions(funcs, parentIndx)
  }

  getFillerOptions = (type) => {
    switch (type) {
      case 'math operators':
        return this.mathOpts
      case 'bracket':
        return this.bracketOpts
      default:
        return this.andOrOpts
    }
  }

  saveMathFuncDetails = (index, funcState, extraChange = {}) => {
    const { saveConditions, index: parentIndx } = this.props
    const { conditions, candle_freq } = this.state
    const modConditions = [...conditions]
    const modItem = modConditions[index]
    modItem.conditions = funcState.conditions
    modItem.period = funcState.period
    modItem.symbol = funcState.symbol
    modItem.multiConditions = funcState.multiConditions
    modItem.valid = funcState.valid
    modConditions[index] = modItem
    this.setState({ conditions: modConditions, candle_freq })
    // const extraChange = modItem.name === MATH_FUNCS.MULTI_TIME_FRAME.name
    //   ? { showWarning: 'MTF' } : {}
    // debugger
    saveConditions(modConditions, parentIndx, { ...extraChange })
  }

  mathFunctionSelect = (item, index, template) => {
    const { saveConditions, candleIntervalIndex, index: parentIndx } = this.props
    const { conditions } = this.state // candle_freq
    const modConditions = [...conditions]
    const modItem = { ...template }

    const { name } = item
    modItem.name = name
    modItem.period = 10
    if (name.toLowerCase() === 'min' || name.toLowerCase() === 'max') {
      modItem.multiConditions = { 0: 'array', 1: [] }
    } else if (this.singleConditionsArr.includes(name.toLowerCase())) {
      modItem.multiConditions = { 0: 'none' }
    } else if (name === 'Multitime frame' || name === 'Multitime frame completed') {
      modItem.period = candleMap[candleIntervalIndex]
    } else if (name === MATH_FUNCS.SYMBOL.name) {
      modItem.symbol = ''
      modItem.valid = false
    }
    const extraChange = name === MATH_FUNCS.MULTI_TIME_FRAME.name
      ? { showWarning: 'MTF' } : {}
    modConditions[index] = { ...modItem, id: Date.now() }
    this.setState({ conditions: modConditions })

    saveConditions(modConditions, parentIndx, extraChange)
  }

  saveDetails = (type, subType, item, index, details) => {
    const smallOption = subType.toLowerCase()
    let template = getTemplate('func')
    if (smallOption === 'bracket') {
      template = getTemplate('bracket')
    } else if (type === 'func' || smallOption === 'comparators') {
      template = getTemplate('func')
    } else if (type === 'filler') {
      template = getTemplate('filler')
      if (smallOption === 'and/or') {
        template.name = 'and'
        template.valid = true
      } else {
        template.name = '+'
        template.valid = true
      }
    } else if (type === 'bracket') {
      switch (smallOption) {
        case 'math operators':
          template = getTemplate('filler')
          template.name = '+'
          template.valid = true
          break
        case 'indicators':
          template = getTemplate('func')
          break
        default:
          break
      }
    }
    if (smallOption === 'math functions') {
      template.mathFunc = true
      template.valid = true
    }
    template.subType = smallOption
    if (smallOption === 'comparators') {
      this.comparatorSelect(item, index, template, details)
    } else if (smallOption === 'indicators') {
      this.indicatorSelect(item, index, template, details)
    } else if (smallOption === 'bracket' || smallOption === 'and/or' || smallOption === 'math operators') {
      this.handleChange(index, item.name, template)
    } else if (smallOption === 'math functions') {
      this.mathFunctionSelect(item, index, template)
    }
    // this.setState({ conditions: modConditions })
    // saveConditions(modConditions, candle_freq)
  }

  removeCondition = (index) => {
    const { conditions } = this.state // candle_freq
    const { saveConditions, index: parentIndx, isBasicMode } = this.props
    let modConditions = [...conditions]
    if (isBasicMode && index === 0) {
      modConditions = [{ ...getTemplate('func'), id: modConditions[index].id }]
    } else {
      modConditions[index] = { ...getTemplate('func'), id: modConditions[index].id }
    }
    this.setState({
      conditions: modConditions,
      ...this.updateCompareIndicators(modConditions, index),
    })
    saveConditions(modConditions, parentIndx)
  }

  saveConditionDetails = (data, candle_freq, type, index) => {
    if (type === SECTION_TYPE.COMPARATOR) {
      this.saveComparatorDetails(index, data, candle_freq)
    } else {
      this.saveIndicatorDetails(index, data, candle_freq)
    }
  }

  renderMathFunctions = (indicator, parentIndex) => {
    const {
      candleIntervalIndex,
      indicatorsList,
      createStrategy,
      isDark,
      editType,
      setBlockedTF,
      blockedTimeFrame,
    } = this.props
    return (
      <MathFunctionInput
        key={indicator.id}
        indicator={indicator}
        parentIndex={parentIndex}
        candleIntervalIndex={candleIntervalIndex}
        saveDetails={this.saveDetails}
        removeField={this.removeField}
        removeCondition={this.removeCondition}
        saveMathFuncDetails={this.saveMathFuncDetails}
        indicatorsList={indicatorsList}
        onInputPress={this.onInputPress}
        createStrategy={createStrategy}
        keysToHide={this.keysToHide}
        isDark={isDark}
        editType={editType}
        setBlockedTF={setBlockedTF}
        blockedTimeFrame={blockedTimeFrame}
      />
    )
  }

  removeField = (index) => {
    const { saveConditions, index: parentIndx, isBasicMode } = this.props
    const { conditions } = this.state // candle_freq
    const moConditions = [...conditions]
    if (isBasicMode
        || (moConditions[index] && moConditions[index].valid)
        || moConditions.length === 1) {
      this.removeCondition(index)
      return
    }
    moConditions.splice(index, 1)
    this.setState({
      conditions: moConditions,
    })
    saveConditions(moConditions, parentIndx)
  }

  toggleModal = (modal) => {
    this.updatedActiveItem = {}
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  onInputPress = (indicator, i) => {
    const { conditions } = this.state
    this.activeCondition = { ...conditions[i], index: i }
    const { details, subType } = this.activeCondition
    if (!isEmpty(details)) {
      this.itemKey = 'indicatorDetails'
      this.detailsKey = 'details'
      let sectionType = SECTION_TYPE.INDICATOR
      if (subType === SECTION_TYPE.COMPARATOR) {
        sectionType = SECTION_TYPE.COMPARATOR
        this.itemKey = 'comparator'
        this.detailsKey = 'details'
      }
      this.updatedActiveItem = {}
      this.setState({ showDetails: true, showIndicator: true, sectionType })
      return
    }
    const { indicatorsList } = this.props
    this.inputOptionsTree = { [subType]: indicatorsList[subType] }
    this.toggleModal('showInputOptions')
  }

  renderConditions = () => {
    const {
      isDark, styles, startTour,
    } = this.props
    const {
      conditions, showIndicator,
    } = this.state
    const { index } = this.activeCondition
    return conditions.map((indicator, i) => {
      const { mathFunc, id, valid } = indicator
      if (mathFunc) {
        return this.renderMathFunctions(indicator, i)
      }
      const prevCondition = i === 0 ? {} : conditions[i - 1]
      return showIndicator && index === i ? this.renderIndicatorFinder(id) : (
        <ScreenerInput
          key={id}
          fancyValue={indicator.name}
          indicator={indicator}
          index={i}
          canDeleteAdd={i !== conditions.length - 1}
          prevCondition={prevCondition}
          inputErr={i === conditions.length - 1 ? false : !valid}
          // changeToButton={i === conditions.length - 1}
          onPress={this.onInputPress}
          styles={styles}
          addCondition={this.addCondition}
          isDark={isDark}
          removeField={this.removeField}
          shouldRemove={this.shouldRemove}
          startTour={startTour}
        />
      )
    })
  }

  renderIndicatorFinder = (id) => {
    const {
      candleIntervalIndex, indicatorsList, indicatorState, index, styles,
      editType, isBasicMode,
    } = this.props
    const { showDetails, sectionType } = this.state
    let extraParams = {}
    if (isBasicMode) {
      extraParams = {
        keysToShow: sectionType === SECTION_TYPE.COMPARE_INDICATOR
          ? this.allowedComparatorIndicators : null,
      }
    }
    return (
      <IndicatorFinder
        key={id}
        containerStyles={id === 'newAddFinder' ? styles.finderContainer : ''}
        onItemSelect={this.inputSelectHandler}
        onRemove={this.inputRemoveField}
        onDismiss={this.onDismiss}
        sectionType={sectionType}
        tree={isBasicMode ? this.tree : indicatorsList}
        showDetails={showDetails}
        onEdit={this.onEdit}
        saveDetails={this.inputSaveDetails}
        indicatorState={this.activeCondition}
        candleIntervalIndex={candleIntervalIndex}
        detailsKey={this.detailsKey} // comparater
        showSectionHeader
        sectionsToShow={isBasicMode
          ? [SECTION_TYPE.INDICATOR, SECTION_TYPE.COMPARATOR]
          : this.optionList}
        itemKey={this.itemKey}
        updatedActiveItem={this.updatedActiveItem}
        keysToHide={this.keysToHide}
        isConditionEmpty={isBasicMode ? false : indicatorState.length === 0}
        index={index}
        wrapperStyles={isBasicMode ? styles.basicFinderWrapper : {}}
        editType={editType}
        {...extraParams}
      />
    )
  }

  setOptions = (prevCondition = {}, mathFunc, parentFunc) => {
    let options = functionOptions
    if (prevCondition.type === 'func' && prevCondition.subType === 'comparators') {
      options = comparatorsOptions
    } else if (prevCondition.mathFunc) {
      options = mathFuncOptions
    } else if (prevCondition.type === 'func') {
      options = indicatorsOptions
    } else if (prevCondition.type === 'bracket') {
      options = bracketsOptions[prevCondition.name] || bracketsOptions.default
    } else if (prevCondition.type === 'filler') {
      options = operatorsOptions
    }
    let allowComparators = false
    if (parentFunc) {
      const mathFuncs = Object.values(MATH_FUNCS)
      for (let index = 0; index < mathFuncs.length; index++) {
        const mathFuncObj = mathFuncs[index] || {}
        if (mathFuncObj.name && mathFuncObj.name === parentFunc.name) {
          ({ allowComparators } = mathFuncObj)
          break
        }
      }
    }
    if (mathFunc && !allowComparators) {
      options = options.filter(item => item.toLowerCase() !== 'comparators')
    }
    this.optionList = options.map(item => item.toLowerCase())
  }

  addCondition = (prevCondition, mathFunc, index = 0) => {
    this.setOptions(prevCondition, mathFunc)
    this.activeCondition = { index }
    this.setState({ showIndicator: true })
  }

  handleConditionAddition = () => {
    const {
      index, indicatorState, handleConditionAddition, isBasicMode,
    } = this.props
    if (indicatorState.length === 0) {
      if (isBasicMode) {
        showSnackbar('Complete previous condition')
        return
      }
      this.addCondition()
      return
    }
    handleConditionAddition(index)
  }

  renderAddBtn = () => {
    const { conditions = [] } = this.state
    const { isDark, styles } = this.props
    const addNext = conditions.length > 0 && conditions[0].valid
    return (
      <button
        type="button"
        onClick={this.handleConditionAddition}
        className={classnames(styles.addConditionBtn, addNext ? styles.addNextConditionBtn : '')}
        style={{ backgroundColor: COLORS[isDark ? 'BLACK_100' : 'WHITE'] }}
      >
        <Icon name={ICONS.SEARCH} size={15} color={COLORS[isDark ? 'WHITE' : 'TEXT']} />
          &nbsp;
          &nbsp;
        <CustomText color="textSecondary">{`   What would you like to ${addNext ? 'add next?' : 'add?'}`}</CustomText>
      </button>
    )
  }

  handleAndOrChange = (value) => {
    const { index, handleAndOrChange } = this.props
    handleAndOrChange(index, value)
  }

  renderAndOr = () => {
    const {
      andor,
      showAndOr,
      isDark,
      styles,
    } = this.props
    if (!showAndOr) return null
    return (
      <CustomHorizontalTab
        tabs={[{ label: 'And', value: 'and' }, { label: 'Or', value: 'or' }]}
        defaultActiveTab={andor}
        tabChangeHandler={this.handleAndOrChange}
        style={styles.andOrContainer}
        tabStyles={styles.tab}
        tabColor={COLORS.GREY_200}
        isDark={isDark}
      />
    )
  }

  onDismiss = () => {
    const { saveConditions, index: parentIndx, indicatorState } = this.props
    const { index } = this.activeCondition
    if (index !== undefined && indicatorState[index] && !indicatorState[index].name) {
      const moConditions = [...indicatorState]
      moConditions.splice(index, 1)
      saveConditions(moConditions, parentIndx)
      this.setState({
        showDetails: false,
        ...this.getModalState(false),
        ...this.updateCompareIndicators(indicatorState, index),
      })
    } else if (parentIndx > 0 && indicatorState && indicatorState.length === 0) {
      // remove the extra added and/or
      this.setState({
        showDetails: false, ...this.getModalState(false),
      }, this.removeExpression())
    } else {
      this.setState({
        showDetails: false, ...this.getModalState(false),
      })
    }
  }

  inputSelectHandler = (item, cType, subType, details, itemKey) => {
    const { subscription_type, toggleAuthGreeting } = this.props
    if (subscription_type < 3 && [MATH_FUNCS.MULTI_TIMEFRAME_COMPLETED.key,
      MATH_FUNCS.MULTI_TIME_FRAME.key].includes(itemKey || item.key)) {
      toggleAuthGreeting(PRO_FEATURE_MAP.MTF.ID)
      return
    }
    const { index } = this.activeCondition
    let type = 'filler'
    if (subType === SECTION_TYPE.INDICATOR || subType === SECTION_TYPE.COMPARATOR
       || subType === SECTION_TYPE.MATH_FUNCTION) {
      type = 'func'
    }
    if (item.params.length === 0) {
      this.setState({
        showDetails: false,
        showInputOptions: false,
        showIndicator: false,
        indicatorFinderIndex: null,
      })
    } else {
      this.setState({ showInputOptions: false })
    }
    this.updatedActiveItem = item
    this.saveDetails(type, subType, item, index, details)
  }

  inputSaveDetails = (data, selected_candle) => {
    const { fancyValue, sectionType } = this.state
    if (!fancyValue) {
      this.setState({ fancyValue: this.activeCondition.name })
    }
    this.saveConditionDetails(data, selected_candle, sectionType, this.activeCondition.index)
  }

  inputRemoveField = () => {
    const { index } = this.activeCondition
    // if (this.state.showDetails) { // DANGER
    //   this.setState({ showButton: true, showDetails: false })
    //   return
    // }
    this.setState({
      showDetails: false, showIndicator: false, showInputOptions: false, indicatorFinderIndex: null,
    })
    this.updatedActiveItem = {}
    this.removeField(index)
  }

  removeExpression = () => {
    const { id, removeIndicator, index } = this.props
    removeIndicator(id, index)
  }

  onCopy = () => {
    const { onCopy, index } = this.props
    onCopy({ index })
  }

  onPaste = () => {
    const {
      index,
      onPaste,
    } = this.props
    onPaste({ index })
    // if (!copiedCondition.conditions) {
    //   showSnackbar('Copied condition is invalid', {}, 0)
    //   return
    // }
    // const moConditions = [...copiedCondition.conditions]
    // this.setState({ conditions: moConditions })
    // saveConditions(moConditions, index)
  }

  isIndicatorValid = (indiData = [], index) => {
    let isValid = false
    const data = indiData[index]
    if (data && data.name && data.valid) {
      isValid = true
    }
    return isValid
  }

  getShowIndicator = (sectionType, dataIndex) => {
    const { indicatorFinderIndex } = this.state
    return indicatorFinderIndex === dataIndex
  }

  toggleSections = (type, isEditable, i, valueExist) => {
    if (!isEditable) {
      showSnackbar('Select previous indicator details')
      return
    }
    const { conditions } = this.state
    const { indicatorsList } = this.props

    if (valueExist) {
      this.activeCondition = { ...conditions[i], index: i }
      const { details, subType } = this.activeCondition

      if (!isEmpty(details)) {
        this.itemKey = 'indicatorDetails'
        this.detailsKey = 'details'
        let sectionType = SECTION_TYPE.INDICATOR
        if (subType === SECTION_TYPE.COMPARATOR) {
          sectionType = SECTION_TYPE.COMPARATOR
          this.itemKey = 'comparator'
          this.detailsKey = 'details'
        }
        this.updatedActiveItem = {}
        this.setState({ showDetails: true, indicatorFinderIndex: i, sectionType })
        return
      }
      this.inputOptionsTree = { [subType]: indicatorsList[subType] }
      this.toggleModal('showInputOptions')
    } else {
      this.activeCondition = { index: i }
      this.itemKey = 'indicatorDetails'
      this.detailsKey = 'details'
      let sectionType = ''
      const indicatorFinderIndex = i
      if (i === 1) {
        sectionType = SECTION_TYPE.COMPARATOR
        this.tree = {
          [sectionType]: indicatorsList[sectionType],
        }
      } else {
        sectionType = SECTION_TYPE.INDICATOR
        this.tree = {
          [sectionType]: indicatorsList[sectionType],
        }
      }
      this.setState({
        indicatorFinderIndex, sectionType, showDetails: false,
      })
    }
  }

  renderBasicContent = ({ showPaste }) => {
    const {
      toggleDisableCondition, disabled, index, indicatorState, updateGA,
    } = this.props
    const {
      showOthers, comparatorEditable, indicatorEditable, isEditable,
      indicatorValid, compareIndicatorValid,
    } = this.state
    return (
      <div>
        <IndicatorSectionView
          sectionTypeMap={SECTION_TYPE}
          showPaste={showPaste}
          showOthers={showOthers}
          comparatorEditable={comparatorEditable}
          indicatorEditable={indicatorEditable}
          isEditable={isEditable}
          indicatorValid={indicatorValid}
          compareIndicatorValid={compareIndicatorValid}
          toggleDisableCondition={toggleDisableCondition}
          disabled={disabled}
          index={index}
          indicatorState={indicatorState}
          createAdvance
          onCopy={this.onCopy}
          onPaste={this.onPaste}
          toggleSections={this.toggleSections}
          getShowIndicator={this.getShowIndicator}
          removeExpression={this.removeExpression}
          renderIndicatorFinder={this.renderIndicatorFinder}
          updateGA={updateGA}
        />
      </div>
    )
  }

  onUpdateGA = () => {
    const { updateGA } = this.props
    updateGA('Highlight Click', 'Ct Scan Pg 6', 1, 'Product Tour')
  }

  renderContent = ({ showPaste }) => {
    const {
      indicatorState, lastEle, styles, toggleDisableCondition, disabled,
      isMobile, index, isDark, startTour, updateGA,
    } = this.props
    if (indicatorState.length === 0) {
      return (
        <div className={styles.emptyCondition}>
          {!startTour && this.renderIndicatorFinder('newAddFinder')}
          <div className={styles.emptyActionBar}>
            {showPaste && (
            <button
              type="button"
              onClick={this.onPaste}
              className={styles.pasteBtn}
            >
              <Icon name={ICONS.PASTE_FILLED} size={14} color={theme.textSecondary} />
              <CustomText weight="medium" color="textSecondary" style={{ margin: '0 0px 0px 6px' }}>Paste</CustomText>
            </button>
            )}
            {index > 0 && (
            <button
              type="button"
              onClick={this.removeExpression}
              className={styles.emptyDeleteIconBtn}
            >
              <Icon name={ICONS.DELETE} color={COLORS.RED} size={14} />
            </button>
            )}
          </div>
        </div>
      )
    }
    const ptCreateId6 = 'tour_scan_create_6'
    return (
      <>
        <ConditionAddCard
          styles={styles}
          onDelete={this.removeExpression}
          isDark={isDark}
          indicatorState={indicatorState}
          onCopy={this.onCopy}
          showCopy={indicatorState && indicatorState[0] && indicatorState[0].name}
          showPaste={showPaste}
          onPaste={this.onPaste}
          index={index}
          disabled={disabled}
          toggleDisableCondition={toggleDisableCondition}
          isMobile={isMobile}
          startTour={startTour}
          updateGA={updateGA}
        >
          <div
            className={styles.conditions}
            id={ptCreateId6}
            role="button"
            onClick={this.onUpdateGA}
            tabIndex={0}
            onKeyUp={this.onUpdateGA}
          >
            {this.renderConditions()}
          </div>
        </ConditionAddCard>
        {lastEle && this.renderAddBtn()}
      </>
    )
  }

  render() {
    const {
      // createStrategy,
      isDark,
      styles,
      index,
      copiedIndex,
      copiedEditType,
      showPaste: shouldShowPaste,
      editType,
      isBasicMode,
    } = this.props
    const {
      // indicatorValid,
      showInputOptions,
    } = this.state
    const showPaste = ((copiedEditType === editType
      ? copiedIndex <= index : true) && shouldShowPaste) || (index === 0 && shouldShowPaste)
    return (
      <>
        <CustomModal
          visible={showInputOptions}
          onDismiss={this.toggleModal}
          dismissKey="showInputOptions"
          containerStyles={styles.inputOptionModal}
        >
          <InputOptions
            tree={this.inputOptionsTree}
            activeCondition={this.activeCondition}
            onItemSelect={this.inputSelectHandler}
            onRemove={this.inputRemoveField}
            onDismiss={this.toggleModal}
            selectedName={this.activeCondition.name}
            dismissKey="showInputOptions"
            isDark={isDark}
            keysToHide={this.keysToHide}
          />
        </CustomModal>
        {this.renderAndOr()}
        {isBasicMode ? this.renderBasicContent({ showPaste })
          : this.renderContent({ showPaste })}

      </>
    )
  }
}

const stylesheet = ({
  andOrContainer: {
    marginTop: SPACING.SPACE_20,
    marginBottom: 0,
    maxWidth: 156,
  },
  tab: {
    width: 70,
  },
  addConditionBtn: {
    display: 'flex',
    margin: `${SPACING.SPACE_20} 0`,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    padding: SPACING.SPACE_12,
    border: '1px solid',
    borderColor: COLORS.BLUE,
  },
  addNextConditionBtn: {
    borderColor: 'transparent',
    width: '100%',
  },
  conditions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  // screener input
  addBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: SPACING.SPACE_10,
    marginBottom: SPACING.SPACE_16,
    padding: `0 ${SPACING.SPACE_10}`,
    borderColor: COLORS.ORANGE,
    borderWidth: 2,
    borderRadius: 4,
    backgroundColor: theme.bgPrimary,
    borderStyle: 'dashed',
  },
  addBtnWrapper: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    marginRight: SPACING.SPACE_6,
    '&:hover $addDeleteIconBtn': {
      display: 'flex',
      right: '-10px',
    },
  },
  addDeleteIconBtn: {
    display: 'none',
    right: 0,
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    borderRadius: '4px',
    opacity: '1 !important',
    marginBottom: SPACING.SPACE_16,
    padding: `0 ${SPACING.SPACE_10}`,
  },
  inputBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: SPACING.SPACE_14,
    padding: `${SPACING.SPACE_2} ${SPACING.SPACE_8}`,
    marginBottom: SPACING.SPACE_16,
    borderRadius: 4,
    position: 'relative',
    transition: 'all 0.3s var(--anim-func-ease)',
    '&:hover $deleteIconBtn': {
      display: 'flex',
      right: '-10px',
    },
  },
  inputBtn: {
  },
  deleteIconBtn: {
    padding: `${SPACING.SPACE_4}`,
    display: 'none',
    right: 0,
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    borderRadius: '4px',
    opacity: '1 !important',
  },
  emptyCondition: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    '&:hover $emptyActionBar': {
      display: 'flex',
      opacity: 1,
      // right: 6,
    },
    '&:hover $pasteBtn': {
      display: 'flex',
    },
  },
  emptyDeleteIconBtn: {
    padding: `${SPACING.SPACE_4}`,
    transition: 'all 0.3s var(--anim-func-ease)',
    display: 'flex',
    // opacity: 1,
    // right: -20,
    position: 'relative',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  finderContainer: {
    position: 'relative',
  },
  emptyActionBar: {
    display: 'none',
    opacity: 0,
    alignItems: 'center',
  },

  // card styles
  cardWrapper: {
    marginTop: SPACING.SPACE_20,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
  },
  card: {
    backgroundColor: theme.bgPrimary,
    borderRadius: 6,
    padding: `${SPACING.SPACE_12} ${SPACING.SPACE_10} 0`,
    '&:hover': {
      '& $footerActionBtn': {
        display: 'flex',
      },
    },
  },
  cardFooter: {
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_10}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTopColor: COLORS.BORDER_COLOR,
    borderTopWidth: 1,
    marginTop: SPACING.SPACE_16,
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  deleteBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${SPACING.SPACE_6} 0`,
    marginLeft: SPACING.SPACE_10,
  },
  inputOptionModal: {
    padding: 0,
    maxWidth: 350,
  },
  footerActionBtn: {
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_10}`,
    marginLeft: SPACING.SPACE_10,
    borderRadius: 5,
    display: 'none',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.hover,
      '& > svg': {
        fill: theme.linkColor,
      },
      '& > p': {
        color: `${theme.linkColor} !important`,
      },
    },
  },
  pasteBtn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    marginRight: SPACING.SPACE_10,
    borderRadius: 5,
    display: 'none',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.hover,
      '& > svg': {
        fill: theme.linkColor,
      },
      '& > p': {
        color: `${theme.linkColor} !important`,
      },
    },
  },
  disabledCard: {
    opacity: 0.5,
  },
  // cardRowBtn: {
  //   padding: `${SPACING.SPACE_4} 0`,
  // },
  basicFinderWrapper: {
    margin: 0,
  },
  '@media only screen and (max-width: 786px)': {
    emptyActionBar: {
      opacity: 1,
    },
    finderContainer: {
      zIndex: 99,
      overflow: 'hidden',
    },
    cardFooter: {
      flexWrap: 'wrap',
    },
    footerActionBtn: {
      padding: `${SPACING.SPACE_6}`,
      marginLeft: SPACING.SPACE_6,
      display: 'flex',
      '& > svg': {
        fill: theme.linkColor,
      },
      '& > p': {
        color: `${theme.linkColor} !important`,
      },
    },
    deleteBtn: {
      marginLeft: SPACING.SPACE_6,
      padding: `${SPACING.SPACE_6}`,
    },
  },
})

const mapStateToProps = (state) => {
  return {
    blockedTimeFrame: state.common.blockedTimeFrame,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBlockedTF: params => dispatch(setBlockedTimeFrame(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTheme(stylesheet)(IndicatorSection),
)
