import {
  FETCH_FUNDS_INIT,
  FETCH_FUNDS_SUCCESS,
  FETCH_FUNDS_FAILURE,
  FETCH_SAMPLE_ALGO_INIT,
  FETCH_SAMPLE_ALGO_SUCCESS,
  FETCH_SAMPLE_ALGO_FAILURE,
  FETCH_PERFORMERS_INIT,
  FETCH_PERFORMERS_SUCCESS,
  FETCH_PERFORMERS_FAILURE,
  CLONE_SAMPLE_INIT,
  CLONE_SAMPLE_SUCCESS,
  CLONE_SAMPLE_FAILURE,
  CLEAR_DASHBOARD_RESP,
  FETCH_WHATS_NEW_INIT,
  FETCH_WHATS_NEW_SUCCESS,
  FETCH_WHATS_NEW_FAILURE,
  FETCH_DASHBOARD_EXPLORE,
  FETCH_DASHBOARD_EXPLORE_SUCCESS,
  FETCH_DASHBOARD_EXPLORE_FAILURE,
  UPDATE_TECHNICALS_LIST,
  UPDATE_TECHNICALS_LIST_SUCCESS,
  UPDATE_TECHNICALS_LIST_FAILURE,

  GET_PARTIAL_COMPLETE_INIT,
  GET_PARTIAL_COMPLETE_SUCCESS,
  GET_PARTIAL_COMPLETE_FAILURE,

  GET_RECOMMENDATIONS_INIT,
  GET_RECOMMENDATIONS_SUCCESS,
  GET_RECOMMENDATIONS_FAILURE,

  GET_DASHBOARD_CAROUSEL_INIT,
  GET_DASHBOARD_CAROUSEL_SUCCESS,
  GET_DASHBOARD_CAROUSEL_FAILURE,
  GET_WORDPRESS_CAROUSEL_INIT,
  GET_WORDPRESS_CAROUSEL_SUCCESS,
  GET_WORDPRESS_CAROUSEL_FAILURE,
} from './actionTypes'

const initialState = {
  isFetchingFunds: false,
  funds: {},
  commodity_funds: {},
  fundsError: false,
  fundsErrorMsg: '',
  isFetchingSampleAlgos: false,
  sampleAlgoError: false,
  sampleAlgos: {},
  isFetchingPerformers: false,
  performers: {},
  performersError: false,
  cloneSampleResp: {},
  isCloning: false,
  cloneError: false,
  whats_new: {},
  isFetchingWhatsnew: false,
  whatsnewError: false,
  isFetchingDashboardDiscover: false,
  fetchDashboardDiscoverError: false,
  fetchDashboardDiscoverErrMsg: '',
  discoverAlgo: [],
  technicalInstruments: [],
  isFetchingAnalysis: false,
  updateInstrumentListError: false,
  updateInstrumentListErrorMsg: '',
  viewer_dict: {},

  partialComplete: {
    algos: [],
    scanners: [],
  },
  isFetchingPartialComplete: true,
  partialCompleteError: false,
  partialCompleteErrorMsg: '',

  isFetchingRecommendations: false,
  recommendations: {},
  recommendationsError: false,
  recommendationsErrorMsg: '',

  isFetchingDashboardCarousel: false,
  dashboardCarousel: [],
  dashboardCarouseError: false,
  dashboardCarouseErrorMsg: '',

  isFetchingWordpressCarousel: false,
  wordpressCarousel: [],
  wordpressCarouselError: false,
  wordpressCarouselErrorMsg: '',

}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FUNDS_INIT:
      return {
        ...state,
        isFetchingFunds: true,
        fundsError: false,
        fundsErrorMsg: '',
      }

    case FETCH_FUNDS_SUCCESS: {
      const { data: { funds = {}, commodity_funds = {}, fno_funds = {} } } = action
      return {
        ...state,
        isFetchingFunds: false,
        funds,
        commodity_funds,
        fno_funds,
      }
    }

    case FETCH_FUNDS_FAILURE: {
      return {
        ...state,
        isFetchingFunds: false,
        fundsError: true,
        fundsErrorMsg: action.error,
      }
    }

    case FETCH_SAMPLE_ALGO_INIT:
      return {
        ...state,
        isFetchingSampleAlgos: true,
        sampleAlgoError: false,
      }

    case FETCH_SAMPLE_ALGO_SUCCESS: {
      const { data: { samples = [] } } = action
      return {
        ...state,
        isFetchingSampleAlgos: false,
        sampleAlgos: samples,
      }
    }

    case FETCH_SAMPLE_ALGO_FAILURE:
      return {
        ...state,
        isFetchingSampleAlgos: false,
        sampleAlgoError: true,
      }

    case FETCH_PERFORMERS_INIT:
      return {
        ...state,
        isFetchingPerformers: true,
        performersError: true,
      }

    case FETCH_PERFORMERS_SUCCESS: {
      const { data: { backtests = [] } } = action
      return {
        ...state,
        isFetchingPerformers: false,
        performers: backtests,
      }
    }

    case FETCH_PERFORMERS_FAILURE:
      return {
        ...state,
        isFetchingPerformers: false,
        performersError: true,
      }

    case CLONE_SAMPLE_INIT:
      return {
        ...state,
        isCloning: true,
        cloneError: false,
      }

    case CLONE_SAMPLE_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isCloning: false,
        cloneSampleResp: data,
      }
    }

    case CLONE_SAMPLE_FAILURE:
      return {
        ...state,
        isCloning: false,
        cloneError: true,
      }

    case CLEAR_DASHBOARD_RESP: {
      return {
        ...state,
        cloneSampleResp: {},
        isCloning: false,
        cloneError: false,
      }
    }

    case FETCH_WHATS_NEW_INIT:
      return {
        ...state,
        isFetchingWhatsnew: true,
        whatsnewError: false,
      }

    case FETCH_WHATS_NEW_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingWhatsnew: false,
        whats_new: data.app,
      }
    }

    case FETCH_WHATS_NEW_FAILURE:
      return {
        ...state,
        isFetchingWhatsnew: false,
        whatsnewError: true,
      }

    case FETCH_DASHBOARD_EXPLORE:
      return {
        ...state,
        isFetchingDashboardDiscover: false,
        fetchDashboardDiscoverError: false,
        fetchDashboardDiscoverErrMsg: '',
        discoverAlgo: [],
        technicalInstruments: [],
      }

    case FETCH_DASHBOARD_EXPLORE_SUCCESS: {
      const { instruments_list } = action.data
      return {
        ...state,
        isFetchingDashboardDiscover: false,
        // discoverAlgo: algo || [],
        // viewer_dict,
        technicalInstruments: instruments_list || [],
      }
    }

    case FETCH_DASHBOARD_EXPLORE_FAILURE:
      return {
        ...state,
        isFetchingDashboardDiscover: false,
        fetchDashboardDiscoverError: true,
        fetchDashboardDiscoverErrMsg: action.error,
      }

    case UPDATE_TECHNICALS_LIST:
      return {
        ...state,
        updateInstrumentListError: false,
        updateInstrumentListErrorMsg: '',
      }

    case UPDATE_TECHNICALS_LIST_SUCCESS: {
      const { seg_sym, remove } = action
      let modList = [...state.technicalInstruments]
      const modAnalysis = { ...state.analysis }
      if (remove) {
        modList = modList.filter(item => item !== seg_sym)
        delete modAnalysis[seg_sym]
      } else {
        modList.unshift(seg_sym)
      }
      return {
        ...state,
        analysis: modAnalysis,
        technicalInstruments: modList,
      }
    }

    case UPDATE_TECHNICALS_LIST_FAILURE:
      return {
        ...state,
        updateInstrumentListError: true,
        updateInstrumentListErrorMsg: action.error,
      }

    case GET_PARTIAL_COMPLETE_INIT: {
      return {
        ...state,
        isFetchingPartialComplete: true,
        partialCompleteError: false,
        partialCompleteErrorMsg: '',
      }
    }

    case GET_PARTIAL_COMPLETE_SUCCESS: {
      const { data } = action
      // TODO: remove or condition below after the typo is fixed on backend
      const partialComplete = {
        algos: data.partial_algos, scanners: data.partial_screeners || data.parital_screeners,
      }
      return {
        ...state,
        // TODO: Correct below line code
        partialComplete,
        isFetchingPartialComplete: false,
        // partialCompleteError: false,
        // partialCompleteErrorMsg: '',
      }
    }
    case GET_PARTIAL_COMPLETE_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingPartialComplete: false,
        partialCompleteError: true,
        partialCompleteErrorMsg: error,
      }
    }

    case GET_RECOMMENDATIONS_INIT: {
      return {
        ...state,
        isFetchingRecommendations: true,
        recommendationsError: false,
        recommendationsErrorMsg: '',
      }
    }
    case GET_RECOMMENDATIONS_SUCCESS: {
      const { data: { top_banner, central_banner } } = action
      return {
        ...state,
        isFetchingRecommendations: false,
        recommendations: { top_banner, central_banner },
      }
    }
    case GET_RECOMMENDATIONS_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingRecommendations: false,
        recommendationsError: true,
        recommendationsErrorMsg: error,

      }
    }

    case GET_DASHBOARD_CAROUSEL_INIT: {
      return {
        ...state,
        isFetchingDashboardCarousel: true,
        dashboardCarouseError: false,
        dashboardCarouseErrorMsg: '',
      }
    }
    case GET_DASHBOARD_CAROUSEL_SUCCESS: {
      const { data: { dashboard_carousel } } = action
      // const { data: { top_banner, central_banner } } = action
      return {
        ...state,
        isFetchingDashboardCarousel: false,
        dashboardCarousel: dashboard_carousel,
        // recommendations: { top_banner, central_banner },
      }
    }
    case GET_DASHBOARD_CAROUSEL_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingDashboardCarousel: false,
        dashboardCarouseError: true,
        dashboardCarouseErrorMsg: error,

      }
    }

    case GET_WORDPRESS_CAROUSEL_INIT: {
      return {
        ...state,
        isFetchingWordpressCarousel: true,
        wordpressCarouselError: false,
        wordpressCarouselErrorMsg: '',
      }
    }
    case GET_WORDPRESS_CAROUSEL_SUCCESS: {
      const { data } = action
      let wordpress_carousel = []
      const data2 = data[0].content.rendered
      const doc = new DOMParser().parseFromString(data2, 'text/html')
      doc.querySelectorAll('tr').forEach((tr, index) => {
        if (index === 0) return
        const tds = tr.querySelectorAll('td')
        const description = tds[0].textContent
        const image_overlay_position = tds[1].textContent
        const action_url = tds[2].textContent
        const image_url = tds[3].textContent
        const action_type = tds[4].textContent
        const image_overlay_text = tds[5].textContent
        let external_url = false
        if(tds[6].textContent === 'true') external_url = true
        const cart_image_url = tds[7].textContent
        wordpress_carousel = wordpress_carousel.concat({
          action_type,
          action_url,
          description,
          external_url,
          image_overlay_position,
          image_overlay_text,
          image_url,
          cart_image_url,
        })
      })
      return {
        ...state,
        isFetchingWordpressCarousel: false,
        wordpressCarousel: wordpress_carousel,
      }
    }
    case GET_WORDPRESS_CAROUSEL_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingWordpressCarousel: false,
        wordpressCarouselError: true,
        wordpressCarouselErrorMsg: error,

      }
    }

    default:
      return state
  }
}

export default dashboard
