import {
  FETCH_MARKET_ALGOS_SUCCESS,
  FETCH_MARKET_ALGOS_FAILURE,
  FETCH_MARKET_ALGOS_INIT,
  CLEAR_BACKTEST_GRAPH_DATA,
  DEPLOY_PUBLISHED,
  DEPLOY_PUBLISHED_SUCCESS,
  DEPLOY_PUBLISHED_FAILURE,
  CLEAR_MARKET_ALGO_DATA,
  CLEAR_SUBSCRIBED_ALGO_DATA,
  UPDATE_PUBLICATION_STATS,
  UPDATE_PUBLICATION_STATS_SUCCESS,
  UPDATE_PUBLICATION_STATS_FAILURE,
  SUBSCRIBE_ALGO_SUCCESS,
  SUBSCRIBE_ALGO_FAILURE,
  SUBSCRIBE_ALGO_INIT,
  FETCH_SUBSCRIBED_CHARTS_SUCCESS,
  FETCH_SUBSCRIBED_CHARTS_FAILURE,
  FETCH_SUBSCRIBED_CHARTS_INIT,
  FETCH_MARKETPLACE_CHARTS_INIT,
  FETCH_MARKETPLACE_CHARTS_SUCCESS,
  FETCH_MARKETPLACE_CHARTS_FAILURE,
  FETCH_SUBSCRIBED_ALGO_SUCCESS,
  FETCH_SUBSCRIBED_ALGO_FAILURE,
  FETCH_SUBSCRIBED_ALGO_INIT,
  SUBSCRIBE_AND_DEPLOY_INIT,
  MARKET_DEPLOY_STOCKS_INIT,
  MARKET_DEPLOY_STOCKS_SUCCESS,
  MARKET_DEPLOY_STOCKS_FAILURE,
  UPDATE_MARKET_SORT_ON,
  FILTER_SUBSCRIBED_ALGO,
  FILTER_SUBSCRIBED_ALGO_SUCCESS,
  FILTER_SUBSCRIBED_ALGO_FAILURE,
  FETCH_DISCOVER_CATEGORY,
  FETCH_DISCOVER_CATEGORY_SUCCESS,
  FETCH_DISCOVER_CATEGORY_FAILURE,
  FETCH_ONBOARDING_ALGOS_INIT,
  PAPER_TRADE_MARKET,
  PAPER_TRADE_MARKET_SUCCESS,
  PAPER_TRADE_MARKET_FAILURE,
} from './actionTypes'

export function fetchMarketAlgosSuccess(data, kind, detailed, params) {
  return {
    type: FETCH_MARKET_ALGOS_SUCCESS,
    data,
    kind,
    detailed,
    params,
  }
}

export function fetchMarketAlgosFailure(error, detailed) {
  return {
    type: FETCH_MARKET_ALGOS_FAILURE,
    error,
    detailed,
  }
}

export function fetchMarketAlgos(params, detailed) {
  return {
    type: FETCH_MARKET_ALGOS_INIT,
    params,
    detailed,
  }
}

export function fetchOnboardingAlgos(params) {
  return {
    type: FETCH_ONBOARDING_ALGOS_INIT,
    params,
  }
}

export function marketPlaceCharts(params, headers) {
  return {
    type: FETCH_MARKETPLACE_CHARTS_INIT,
    params,
    headers,
  }
}

export function marketPlaceChartsSuccess(data, id) {
  return {
    type: FETCH_MARKETPLACE_CHARTS_SUCCESS,
    data,
    id,
  }
}

export function marketPlaceChartsFailure(error, id) {
  return {
    type: FETCH_MARKETPLACE_CHARTS_FAILURE,
    error,
    id,
  }
}

export function clearBacktestGraphData() {
  return {
    type: CLEAR_BACKTEST_GRAPH_DATA,
  }
}

export function deployPublished(params, headers, noDeploy) {
  return {
    type: DEPLOY_PUBLISHED,
    params,
    headers,
    noDeploy,
  }
}

export function deployPublishedSuccess(data) {
  return {
    type: DEPLOY_PUBLISHED_SUCCESS,
    data,
  }
}

export function deployPublishedFailure(error) {
  return {
    type: DEPLOY_PUBLISHED_FAILURE,
    error,
  }
}

export function clearMarketAlgoData(detailed) {
  return {
    type: CLEAR_MARKET_ALGO_DATA,
    detailed,
  }
}

export function clearSubscribedAlgoData(detailed) {
  return {
    type: CLEAR_SUBSCRIBED_ALGO_DATA,
    detailed,
  }
}

export function updatePublicationStats(params) {
  return {
    type: UPDATE_PUBLICATION_STATS,
    params,
  }
}

export function updatePublicationStatsSuccess(data) {
  return {
    type: UPDATE_PUBLICATION_STATS_SUCCESS,
    data,
  }
}

export function updatePublicationStatsFailure(error) {
  return {
    type: UPDATE_PUBLICATION_STATS_FAILURE,
    error,
  }
}

export function subscribeAlgoSuccess(data) {
  return {
    type: SUBSCRIBE_ALGO_SUCCESS,
    data,
  }
}

export function subscribeAlgoFailure(error) {
  return {
    type: SUBSCRIBE_ALGO_FAILURE,
    error,
  }
}

export function subscribeAlgo(params) {
  return {
    type: SUBSCRIBE_ALGO_INIT,
    params,
  }
}

export function subscribedChartsSuccess(data, id) {
  return {
    type: FETCH_SUBSCRIBED_CHARTS_SUCCESS,
    data,
    id,
  }
}

export function subscribedChartsFailure(data, id) {
  return {
    type: FETCH_SUBSCRIBED_CHARTS_FAILURE,
    error: data,
    id,
  }
}

export function fetchSubscribedCharts(params, headers) {
  return {
    type: FETCH_SUBSCRIBED_CHARTS_INIT,
    params,
    headers,
  }
}

export function fetchSubcribedAlgoSuccess(data, kind, detailed, params) {
  return {
    type: FETCH_SUBSCRIBED_ALGO_SUCCESS,
    data,
    kind,
    detailed,
    params,
  }
}

export function fetchSubcribedAlgoFailure(error, detailed) {
  return {
    type: FETCH_SUBSCRIBED_ALGO_FAILURE,
    error,
    detailed,
  }
}

export function fetchSubscribedAlgo(params, detailed) {
  return {
    type: FETCH_SUBSCRIBED_ALGO_INIT,
    params,
    detailed,
  }
}

export function subscribeAndDeployAlgo(params, headers) {
  return {
    type: SUBSCRIBE_AND_DEPLOY_INIT,
    params,
    headers,
  }
}

export function deployAlgoSuccess(data) {
  return {
    type: MARKET_DEPLOY_STOCKS_SUCCESS,
    data,
  }
}

export function deployAlgoFailure(error) {
  return {
    type: MARKET_DEPLOY_STOCKS_FAILURE,
    error,
  }
}

export function deployAlgo(params, headers) {
  return {
    type: MARKET_DEPLOY_STOCKS_INIT,
    params,
    headers,
  }
}

export function updateSortOn(sortOn, algoIdKey) {
  return {
    type: UPDATE_MARKET_SORT_ON,
    sortOn,
    algoIdKey,
  }
}

export function filterSubscribedAlgos(params, headers, fetchAlgoPayload) {
  return {
    type: FILTER_SUBSCRIBED_ALGO,
    params,
    headers,
    fetchAlgoPayload,
  }
}

export function filterSubscribedAlgoSuccess(msg, data) {
  return {
    type: FILTER_SUBSCRIBED_ALGO_SUCCESS,
    msg,
    data,
  }
}

export function filterSubscribedAlgoFailure(error) {
  return {
    type: FILTER_SUBSCRIBED_ALGO_FAILURE,
    error,
  }
}

export function paperTradeMarket(params) {
  return {
    type: PAPER_TRADE_MARKET,
    params,
  }
}

export function paperTradeMarketSuccess(data) {
  return {
    type: PAPER_TRADE_MARKET_SUCCESS,
    data,
  }
}

export function paperTradeMarketFailure(error) {
  return {
    type: PAPER_TRADE_MARKET_FAILURE,
    error,
  }
}

export function fetchDiscoverCategories() {
  return {
    type: FETCH_DISCOVER_CATEGORY,
  }
}

export function fetchDiscoverCategoriesSuccess(data) {
  return {
    type: FETCH_DISCOVER_CATEGORY_SUCCESS,
    data,
  }
}

export function fetchDiscoverCategoriesFailure(error) {
  return {
    type: FETCH_DISCOVER_CATEGORY_FAILURE,
    error,
  }
}
