import React, { Component } from 'react'

import ConditionAddCard from './ConditionAddCard'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'

import {
  ICONS, SPACING, theme,
} from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
// import { showSnackbar } from '../../UI/Snackbar'

class IndicatorSectionView extends Component {
  isError = () => {
    const { indicatorState, createAdvance, sectionTypeMap } = this.props
    if (createAdvance) {
      return true
    }
    return (indicatorState[sectionTypeMap.INDICATOR]
      && indicatorState[sectionTypeMap.INDICATOR] !== 'Indicator')
  }

  renderInputField = (inputParams) => {
    const {
      showError, styles, toggleSections,
    } = this.props
    const {
      label, valueKey, isValid, isEditable, valueExist, value, showIndicator,
      dataIndex,
    } = inputParams
    const errStyles = !isValid && showError && this.isError() ? styles.cardRowInValid : ''
    return (
      <div className={`${styles.cardRow} ${isValid && valueExist ? styles.cardRowValid : ''} ${errStyles}`}>
        <button
          type="button"
          onClick={() => toggleSections(valueKey, isEditable, dataIndex, valueExist)}
          className={styles.cardRowBtn}
        >
          <CustomText color="orange">{valueExist ? value : label}</CustomText>
          <Icon name={ICONS[showIndicator ? 'UP_HEAD_FILLED' : 'DOWN_HEAD_FILLED']} color={theme.orange} size={10} />
        </button>

        {/* {!isValid && showError && valueExist && (
        <CustomText size="small">
          {'This field is not valid  '}
          <Icon name={ICONS.WARN} size={12} color={COLORS.RED} />
        </CustomText>
        )} */}
      </div>
    )
  }

  renderRow = (label, valueKey, isValid, isEditable, dataIndex) => {
    const {
      indicatorState, getShowIndicator, createAdvance, renderIndicatorFinder,
    } = this.props
    const value = createAdvance
      ? (indicatorState[dataIndex] && indicatorState[dataIndex].name)
      : indicatorState[valueKey]
    const valueExist = Boolean(value && value.toLowerCase() !== 'indicator' && value.toLowerCase() !== 'comparator')
    const showIndicator = getShowIndicator(valueKey, dataIndex)
    // console.log(label, isEditable, isValid, showError, valueKey, indicatorState, 'indi rowwwww')
    if (!showIndicator) {
      return this.renderInputField({
        label, valueKey, isValid, isEditable, valueExist, value, showIndicator, dataIndex,
      })
    }
    return renderIndicatorFinder()
    // return (
    //   <IndicatorFinder
    //     {...finderState}
    //     containerStyles={styles.finderContainer}
    //     wrapperStyles={styles.finderWrapper}
    //     keysToShow={sectionType === sectionTypeMap.COMPARE_INDICATOR
    //       ? allowedComparatorIndicators : null}
    //   />
    // )
  }

  render() {
    const {
      showOthers, comparatorEditable, indicatorEditable, isEditable,
      indicatorValid, compareIndicatorValid, showPaste, sectionTypeMap,
      styles, isDark, toggleDisableCondition, disabled, isMobile, index,
      removeExpression, onCopy, onPaste, updateGA,
    } = this.props
    return (
      <div className={styles.basicContent}>
        <ConditionAddCard
          styles={styles}
          onDelete={removeExpression}
          isDark={isDark}
          createStrategy
          onCopy={onCopy}
          showCopy={indicatorValid && compareIndicatorValid}
          showPaste={showPaste}
          onPaste={onPaste}
          toggleDisableCondition={toggleDisableCondition}
          disabled={disabled}
          index={index}
          isMobile={isMobile}
          updateGA={updateGA}
        >
          {this.renderRow('Add Indicator', sectionTypeMap.INDICATOR, indicatorValid, indicatorEditable, 0)}
          {showOthers && this.renderRow('Add Comparator', sectionTypeMap.COMPARATOR, true, comparatorEditable, 1)}
          {showOthers && this.renderRow('Add Indicator', sectionTypeMap.COMPARE_INDICATOR, compareIndicatorValid, isEditable, 2)}
        </ConditionAddCard>
      </div>
    )
  }
}

const stylesheet = {

  // card styles
  cardWrapper: {
    margin: `${SPACING.SPACE_20} 0`,
    '&:hover $cardFooter': {
      '& *': {
        display: 'flex',
        opacity: 1,
      },
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 6,
    backgroundColor: theme.bgPrimary,
    padding: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    '&:hover $cardRow': {
      // '& *': {
      //   color: `${theme.textSecondary} !important`,
      //   fill: `${theme.textSecondary}`,
      // },
    },
    '& $cardRow': {
      '& *': {
        transition: 'all 0.3s var(--anim-func-ease)',
      },
    },
  },
  cardRow: {
    flexDirection: 'row',
    paddingRight: SPACING.SPACE_28,
    justifyContent: 'space-between',
    flex: 1,
  },
  cardRowValid: {
    '& $cardRowBtn': {
      '& *': {
        color: `${theme.text} !important`,
        fill: `${theme.text}`,
      },
    },
  },
  cardRowInValid: {
    '& $cardRowBtn': {
      borderColor: `${theme.red}`,
      '& *': {
        color: `${theme.red} !important`,
      },
    },
  },
  cardRowBtn: {
    display: 'flex',
    border: `1px solid ${theme.borderColor}`,
    backgroundColor: theme.inputLightBg,
    borderRadius: 5,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_16}`,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '300px',
    zIndex: 99,
    '&:hover': {
      '& p': {
        color: `${theme.linkColor} !important`,
      },
      '& svg': {
        fill: `${theme.linkColor} !important`,
        color: `${theme.linkColor} !important`,
      },
    },
  },
  cardFooter: {
    width: 180,
    paddingRight: SPACING.SPACE_10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    '& *': {
      transition: 'all 0.3s var(--anim-func-ease)',
      display: 'flex',
      opacity: 0,
    },
  },
  footerActionBtn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    marginRight: SPACING.SPACE_10,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.hover,
      '& > svg': {
        fill: theme.linkColor,
      },
      '& > p': {
        color: `${theme.linkColor} !important`,
      },
    },
  },
  deleteBtn: {
    display: 'flex',
    padding: 4,
    '& > svg': {
      height: 18,
      width: 18,
    },
  },
  disabledCard: {
    opacity: 0.5,
  },
  '@media only screen and (max-width: 786px)': {
    card: {
      display: 'block',
      '& $cardRow': {
        marginBottom: SPACING.SPACE_20,
        '&:last-child': {
          margin: 0,
        },
      },
    },
    cardRowBtn: {
      maxWidth: 'unset',
    },
    cardFooter: {
      width: '100%',
      // justifyContent: 'space-between',
      '& *': {
        opacity: 1,
      },
    },
    footerActionBtn: {
      '& > svg': {
        fill: theme.linkColor,
      },
      '& > p': {
        color: `${theme.linkColor} !important`,
      },
    },
    deleteBtn: {
      padding: `0 ${SPACING.SPACE_20}`,
    },
  },
}

export default withTheme(stylesheet)(IndicatorSectionView)
