import React, { PureComponent } from 'react'
import classnames from 'classnames'
import isEqual from 'lodash/isEqual'

import CustomInput from '../../UI/CustomInput'
import Button from '../../UI/Button'
import CustomText from '../../UI/CustomText'
import ListDropDown from '../../UI/Dropdown/ListDropdown'
import { showSnackbar } from '../../UI/Snackbar'

import { freq_candle_map, rev_candle } from '../../utils/consts'
import { withTheme } from '../../Theme/ThemeProvider'
import { COLORS, SPACING } from '../../Theme'

class IndicatorDetails extends PureComponent {
  constructor(props) {
    super(props)
    const {
      indicator = {},
      indicatorDetails,
    } = this.props
    const { params = [] } = indicator
    const paramsMap = {}
    params.forEach((param) => {
      const key = param[0]
      let value = `${param[1]}`
      if (indicatorDetails && indicatorDetails[key]) {
        value = `${indicatorDetails[key]}`
      }
      paramsMap[key] = value
    })
    this.state = { ...paramsMap }
    this.defaultMapping = { ...paramsMap }
    this.min_candle_interval = null
  }

  componentDidUpdate(prevProps, prevState) {
    const { autoUpdate } = this.props
    if (autoUpdate && !isEqual(prevState, this.state)) {
      this.saveDetails()
    }
  }

  inputChangeHandler = (e, field) => {
    const { value } = e.target
    const { isRenko = false } = this.props
    let numRegx = /^[-+]?[0-9]*\.?[0-9]*$/
    if (isRenko) {
      numRegx = /^[0-9]*\.?[0-9]*$/
    }
    const decimalRegx = /^\.[0-9]+$/
    if (numRegx.test(value) || decimalRegx.test(value) || value === '') {
      this.setState({ [field]: value, [`${field}Err`]: false })
    } else {
      showSnackbar('Only numeric/decimal values are allowed')
    }
  }

  rangeChangeHandler = (e, field, range) => {
    const [max, min, step = 1] = range
    const { value } = e.target
    const numRegx = /^[-+]?[0-9]*\.?[0-9]*$/
    const decimalRegx = /^\.[0-9]+$/
    if (numRegx.test(value) || decimalRegx.test(value) || value === '') {
      const formatValue = Number(value)
      if (formatValue < min) {
        showSnackbar(`Value should be greater than ${min}`)
      } else if (formatValue > max) {
        showSnackbar(`Value should be less than ${max}`)
      } else if (value !== '-' && (Math.abs(formatValue) - min) % step !== 0) {
        showSnackbar(`Value should be between ${min} and ${max} with step size as ${step}`)
      } else {
        this.setState({ [field]: value, [`${field}Err`]: false })
      }
    } else {
      showSnackbar('Only numeric/decimal values are allowed')
    }
  }

  populateParams = (indicatorParams, candleIntervalIndex, show_45min) => {
    const { styles } = this.props
    if (!indicatorParams) {
      return null
    }
    const params = indicatorParams.map((param) => {
      const label = param[5] || param[0].split('_').join(' ')
      let field = (
        <CustomInput
          inputError={this.state[`${param[0]}Err`]}
          key={param[0].split('_').join(' ')}
          value={this.state[param[0]]}
          inputKey={param[0]}
          onChangeText={this.inputChangeHandler}
          inputStyles={styles.fieldInput}
          containerStyles={styles.fieldInputContainer}
          wrapperStyles={styles.inputWrapper}
        />
      )
      if (param[3] === 'range') {
        field = (
          <CustomInput
            inputError={this.state[`${param[0]}Err`]}
            inputKey={param[0]}
            key={param[0].split('_').join(' ')}
            value={this.state[param[0]]}
            inputStyles={styles.fieldInput}
            onChangeText={e => this.rangeChangeHandler(e, param[0], param[4])}
            containerStyles={styles.fieldInputContainer}
            wrapperStyles={styles.inputWrapper}
          />
        )
      } else if (param[3] === 'dropdown') {
        // filtering candle options based on candle interval for algo
        let listData = param[4]
        if (param[0] === 'candle' || param[0] === 'range') {
          if (listData) {
            listData = listData.filter(timeFrame => rev_candle[timeFrame] <= candleIntervalIndex)
            // && candleIntervalIndex % time_frame_min_map[timeFrame] === 0)
          } else {
            listData = Object.values(freq_candle_map)
            const keys = Object.keys(freq_candle_map)
            for (let i = 0; i < keys.length; i++) {
              if (keys[i] <= candleIntervalIndex) {
                if (keys[i] !== '5.5' || (keys[i] === '5.5' && show_45min)) {
                  listData.push(freq_candle_map[keys[i]])
                }
              }
            }
          }
          this.min_candle_interval = rev_candle[this.state[param[0]]]
        }
        field = (
          <ListDropDown
            showDropDownIcon
            selected={this.state[param[0]]}
            options={listData}
            inputBtnStyles={styles.dropdownInputBtn}
            onChangeOption={(name) => {
              this.setState({ [param[0]]: `${name}` })
              this.min_candle_interval = rev_candle[`${name}`]
            }}
          />
        )
      }
      if(this.state.contract_type === 'FUT') {
        if(param[0] === 'offset' || param[0] === 'change_frequency') {
          return null
        }
      }
      return (
        <div key={param[0]} className={styles.paramsContainer}>
          <CustomText weight="medium" className={styles.labelStyles}>{label}</CustomText>
          {field}
        </div>
      )
    })
    return params
  }

  saveDetails = () => {
    const { saveDetails, extraParams } = this.props
    const params = { ...this.state }
    const keys = Object.keys(this.defaultMapping)
    let errObj = {}
    const valid = []
    keys.forEach((key) => {
      if (params[key] === '' || !params[key]) {
        params[key] = this.defaultMapping[key]
      }
      if (!params[key]) {
        valid.push(key)
        errObj = {
          [`${key}Err`]: true,
        }
      }
      if (params[key] === '.') {
        valid.push(key)
        errObj = {
          ...errObj,
          [`${key}Err`]: true,
        }
      }
      if (key === 'offset' && params[key] && params[key].includes('.')) {
        valid.push(key)
        errObj = {
          ...errObj,
          [`${key}Err`]: true,
        }
      }
    })
    if (valid.length === 0) {
      saveDetails(params, this.min_candle_interval, extraParams)
    } else {
      this.setState({ ...errObj })
      let errorMsg = 'Only numeric/decimal values are allowed'
      if (errObj.offsetErr) {
        errorMsg = 'Only numeric values are allowed for offset'
      }
      showSnackbar(errorMsg, {}, 0)
    }
  }

  render() {
    const {
      indicator = {},
      candleIntervalIndex,
      onRemove,
      showRemove = true,
      styles,
      popupClass = '',
      btnText = 'Done',
      autoUpdate,
    } = this.props
    const show_45min = (indicator?.name || '').includes('Opening Range')
    return (
      <div className={classnames(styles.container, popupClass)}>
        {Boolean(indicator?.name) && (
        <div className={styles.header}>
          <CustomText
            weight="semi_bold"
            size="large"
            flex={1}
          >
            {indicator?.name}
          </CustomText>
          {showRemove && (
          <Button
            text="Delete"
            iconName="DELETE"
            iconProps={{
              color: COLORS.WHITE,
            }}
            buttonColor="red"
            onPress={onRemove}
            btnStyles={styles.removeBtn}
          />
          )}
        </div>
        )}
        <div className={styles.detailsForm}>
          {this.populateParams(indicator.params, candleIntervalIndex, show_45min)}
          {!autoUpdate && (
          <div className={styles.actionBar}>
            <Button
              onPress={this.saveDetails}
              btnStyles={styles.doneBtn}
              buttonColor="blue"
              text={btnText}
            />
          </div>
          )}
        </div>
      </div>
    )
  }
}

const stylesheet = ({
  fieldInput: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    minWidth: 100,
  },
  fieldInputContainer: {
  },
  dropdownInputBtn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    width: 120,
    overflow: 'hidden',
  },
  inputWrapper: {
    width: '100%',
    maxWidth: 120,
  },
  detailsForm: {
    display: 'inline-block',
    width: '100%',
    // flexWrap: 'wrap',
    // justifyContent: 'flex-end',
  },
  paramsContainer: {
    marginTop: SPACING.SPACE_20,
    display: 'inline-flex',
    alignItems: 'center',
    width: '50%',
    verticalAlign: 'top',
    '&:nth-child(2n + 1)': {
      paddingRight: SPACING.SPACE_10,
    },
    '&:nth-child(2n)': {
      paddingLeft: SPACING.SPACE_10,
    },
  },
  labelStyles: {
    textTransform: 'capitalize',
    marginRight: SPACING.SPACE_20,
    flex: 1,
  },
  container: {
    maxWidth: 490,
    margin: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_10,
  },
  footer: {
    margin: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_20}`,
  },
  actionBar: {
    width: '50%',
    float: 'right',
    marginTop: SPACING.SPACE_20,
    paddingLeft: SPACING.SPACE_10,
    textAlign: 'right',
  },
  doneBtn: {
    width: '100%',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    maxWidth: 120,
  },
  removeBtn: {
    display: 'flex',
    alignItems: 'center',
  },
  '@media only screen and (max-width: 480px)': {
    paramsContainer: {
      width: '100%',
      padding: '0 !important',
    },
    actionBar: {
      width: '100%',
      padding: '0 !important',
    },
    doneBtn: {
      maxWidth: 'unset',
    },
  },
})

export default withTheme(stylesheet)(IndicatorDetails)
