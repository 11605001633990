import isEmpty from 'lodash/isEmpty'
import {
  GET_SCREENS_INIT,
  GET_SCREENS_SUCCESS,
  GET_SCREENS_FAILURE,
  DELETE_SCREENS_INIT,
  DELETE_SCREENS_SUCCESS,
  DELETE_SCREENS_FAILURE,
  POPULAR_SCREENS_INIT,
  POPULAR_SCREENS_SUCCESS,
  POPULAR_SCREENS_FAILURE,
  GET_FAVORITE_INIT,
  GET_FAVORITE_SUCCESS,
  GET_FAVORITE_FAILURE,
  MARK_FAVORITE_INIT,
  MARK_FAVORITE_SUCCESS,
  MARK_FAVORITE_FAILURE,
  GET_ALERT_SCREENS_INIT,
  GET_ALERT_SCREENS_SUCCESS,
  GET_ALERT_SCREENS_FAILURE,
  SET_ALERT_SCREENS_INIT,
  SET_ALERT_SCREENS_SUCCESS,
  SET_ALERT_SCREENS_FAILURE,
  STOP_ALERT_SCREENS_INIT,
  STOP_ALERT_SCREENS_SUCCESS,
  STOP_ALERT_SCREENS_FAILURE,
  GET_ALERT_HISTORY_INIT,
  GET_ALERT_HISTORY_SUCCESS,
  GET_ALERT_HISTORY_FAILURE,
  SEARCH_SCANNER_INIT,
  SEARCH_SCANNER_SUCCESS,
  SEARCH_SCANNER_FAILURE,
  CLEAR_SEARCH_SCANNER_RESP,
  FETCH_SCANNER_CATEGORY,
  FETCH_SCANNER_CATEGORY_SUCCESS,
  FETCH_SCANNER_CATEGORY_FAILURE,
  UPDATE_SORT_ON,
  FETCH_SCANNERS_STOCKS,
  FETCH_SCANNER_STOCKS_SUCCESS,
  FETCH_SCANNERS_STOCKS_FAILURE,
  UPDATE_ALERT_SCREENS_STOCKS,
  GENERATE_SCANNER_SHARE_LINK,
  GENERATE_SCANNER_SHARE_LINK_SUCCESS,
  GENERATE_SCANNER_SHARE_LINK_FAILURE,
} from './actionTypes'
import { SCANNER_CATEGORY_ID } from './scanUtils'

const initialState = {
  isFetchingScreens: false,
  screens: [],
  current_screen_page: 1,
  total_screen_pages: 0,
  total_pages: 0, // TODO: Comment this later. Not being used anymore
  screenError: false,
  screenErrorMsg: '',

  deleteScreenResp: {},
  isDeleteingScreen: false,
  deleteScreenError: false,
  deleteScreenErrorMsg: '',

  popularScreens: [],
  condensedPopularScreens: [],
  isFetchingPopular: false,
  popularScreensError: false,
  popularScreenErrorMsg: '',

  favorites: {},
  isFetchingFavorite: false,
  favoriteError: false,
  favoriteErrorMsg: '',
  isMarkingFavorite: false,
  markFavoriteResp: {},
  markFavoriteError: false,
  markFavoriteErrorMsg: '',

  alertScreens: [], // stores the data just for reference
  alertScreensFiltered: [], // used everywhere
  isFetchingAlertScreens: false,
  alertScreenError: false,
  alertScreenErrorMsg: '',
  setAlertScreenResp: null,
  isSettingAlertScreens: false,
  setAlertScreenError: false,
  setAlertScreenErrorMsg: '',
  stopAlertScreenResp: {},
  isStoppingAlertScreens: false,
  stopAlertScreenError: false,
  stopAlertScreenErrorMsg: '',

  alertScreensHistory: {},
  alertHistoryOriginal: [],
  isFetchingAlertHistory: false,
  alertScreensHistoryError: false,
  alertScreensHistoryErrorMsg: '',

  searchScans: [],
  isSearchingScans: false,
  searchScansError: false,
  searchScansErrorMsg: '',
  total_search_pages: 0,
  current_search_page: 1,

  isFetchingScannerCategory: true,
  tagsDescriptionError: false,
  tagsDescriptionErrorMsg: '',
  tagsDescription: [],
  categoryList: [],

  sortOn: {},
  alertCount: {},

  scannersStocks: {
    [SCANNER_CATEGORY_ID.DISCOVER_SCANS]: { },
    [SCANNER_CATEGORY_ID.MY_SCANS]: { },
    [SCANNER_CATEGORY_ID.LIVE_SCANS]: { },
  },
  isFetchingScannerStocks: false,
  scannersStocksError: false,
  scannersStocksErrorMsg: '',

  screener_share_uuid: null,
  generateScannerShareLinkSuccess: false,
  isGeneratingScannerShareLink: false,
  generateScannerShareLinkError: false,
  generateScannerShareLinkErrorMsg: '',
  isFetchingScannerShareableLink: false,
}

const scans = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCREENS_INIT:
      return {
        ...state,
        isFetchingScreens: true,
        screenError: false,
        screenErrorMsg: '',
      }

    case GET_SCREENS_SUCCESS: {
      const { data, append } = action
      const { results = [], total_pages = 0 } = data
      let fScreens = results
      let current_screen_page = 0
      if (append) {
        current_screen_page = state.current_screen_page + 1
        fScreens = [...state.screens, ...results]
      }
      return {
        ...state,
        isFetchingScreens: false,
        screens: fScreens,
        total_screen_pages: total_pages,
        current_screen_page,
      }
    }

    case GET_SCREENS_FAILURE:
      return {
        ...state,
        isFetchingScreens: false,
        screenError: true,
        screenErrorMsg: action.error,
      }

    case DELETE_SCREENS_INIT:
      return {
        ...state,
        isDeleteingScreen: true,
        deleteScreenResp: {},
        deleteScreenError: false,
        deleteScreenErrorMsg: '',
      }

    case DELETE_SCREENS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        deleteScreenResp: data,
        isDeleteingScreen: false,
      }
    }

    case DELETE_SCREENS_FAILURE:
      return {
        ...state,
        isDeleteingScreen: false,
        deleteScreenError: true,
        deleteScreenErrorMsg: action.error,
      }

    case POPULAR_SCREENS_INIT:
      return {
        ...state,
        isFetchingPopular: true,
        popularScreensError: false,
        popularScreenErrorMsg: '',
      }

    case POPULAR_SCREENS_SUCCESS: {
      const { data } = action
      const categorizedScreens = { All: data }
      for (let i = 0; i < data.length; i++) {
        const itm = data[i]
        const lastTag = itm.tags[itm.tags.length - 1]
        if (categorizedScreens[lastTag]) {
          categorizedScreens[lastTag].push(itm)
        } else {
          categorizedScreens[lastTag] = [itm]
        }
      }
      return {
        ...state,
        popularScreens: categorizedScreens,
        condensedPopularScreens: data.slice(0, 6),
        isFetchingPopular: false,
      }
    }

    case POPULAR_SCREENS_FAILURE:
      return {
        ...state,
        isFetchingPopular: false,
        popularScreensError: true,
        popularScreenErrorMsg: action.error,
      }

    case GET_FAVORITE_INIT:
      return {
        ...state,
        isFetchingFavorite: true,
        favoriteError: false,
        favoriteErrorMsg: '',
      }

    case GET_FAVORITE_SUCCESS: {
      const { data } = action
      return {
        ...state,
        favorites: data.favorites,
        isFetchingFavorite: false,
      }
    }

    case GET_FAVORITE_FAILURE:
      return {
        ...state,
        isFetchingFavorite: false,
        favoriteError: true,
        favoriteErrorMsg: action.error,
      }

    case MARK_FAVORITE_INIT:
      return {
        ...state,
        isMarkingFavorite: true,
        markFavoriteResp: {},
        markFavoriteError: false,
        markFavoriteErrorMsg: '',
      }

    case MARK_FAVORITE_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isMarkingFavorite: false,
        markFavoriteResp: data,
      }
    }

    case MARK_FAVORITE_FAILURE:
      return {
        ...state,
        isMarkingFavorite: false,
        markFavoriteError: true,
        markFavoriteErrorMsg: action.error,
      }

    case GET_ALERT_SCREENS_INIT:
      return {
        ...state,
        isFetchingAlertScreens: true,
        alertScreenError: false,
        alertScreenErrorMsg: '',
      }

    case GET_ALERT_SCREENS_SUCCESS: {
      const { data, filters } = action
      let alertCount = {}
      let modData = data === null ? state.alertScreens : data
      // symbol_count does not come from backend in live, need it for sorting
      modData = modData.map(item => ({ ...item, symbol_count: item.results.length }))
      if (filters && data === null) {
        const {
          query, filtesArr, favUuids,
        } = filters
        modData = modData.filter((item) => {
          let matched = true
          const {
            screener_name = '', screener_uuid, chart_type, time_frame,
          } = item
          if (query) {
            matched = screener_name.toLowerCase().includes(query.toLowerCase())
          }
          if (matched) {
            let isMatching = true
            if (!isEmpty(filtesArr)) {
              if (filtesArr.chart_type.length) {
                isMatching = isMatching && filtesArr.chart_type.includes(chart_type)
              }
              if (filtesArr.time_frame.length && isMatching) {
                isMatching = filtesArr.time_frame.includes(time_frame)
              }
            }

            if (favUuids.length) matched = favUuids.includes(screener_uuid)
            return matched && isMatching
          }
          return false
        })
      } else {
        modData.forEach((item) => {
          alertCount = {
            ...alertCount,
            [item.alert_uuid]: item.results ? item.results.length : 0,
          }
        })
      }
      return {
        ...state,
        isFetchingAlertScreens: false,
        alertScreens: data === null ? state.alertScreens : data,
        alertScreensFiltered: modData,
        alertCount,
      }
    }

    case GET_ALERT_SCREENS_FAILURE:
      return {
        ...state,
        isFetchingAlertScreens: false,
        alertScreenError: true,
        alertScreenErrorMsg: action.error,
      }

    case SET_ALERT_SCREENS_INIT:
      return {
        ...state,
        setAlertScreenResp: null,
        isSettingAlertScreens: true,
        setAlertScreenError: false,
        setAlertScreenErrorMsg: '',
      }

    case SET_ALERT_SCREENS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        setAlertScreenResp: data,
        isSettingAlertScreens: false,
      }
    }

    case SET_ALERT_SCREENS_FAILURE:
      return {
        ...state,
        isSettingAlertScreens: false,
        setAlertScreenError: true,
        setAlertScreenErrorMsg: action.error,
      }

    case STOP_ALERT_SCREENS_INIT:
      return {
        ...state,
        stopAlertScreenResp: {},
        isStoppingAlertScreens: true,
        stopAlertScreenError: false,
        stopAlertScreenErrorMsg: '',
      }

    case STOP_ALERT_SCREENS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        stopAlertScreenResp: data,
        isStoppingAlertScreens: false,
      }
    }

    case STOP_ALERT_SCREENS_FAILURE:
      return {
        ...state,
        isSettingAlertScreens: false,
        stopAlertScreenError: true,
        stopAlertScreenErrorMsg: action.error,
      }

    case GET_ALERT_HISTORY_INIT:
      return {
        ...state,
        alertScreensHistory: {},
        isFetchingAlertHistory: true,
        alertScreensHistoryError: false,
        alertScreensHistoryErrorMsg: '',
      }

    case GET_ALERT_HISTORY_SUCCESS: {
      const { data = [] } = action
      const labels = []
      const stocksArr = []
      const stocksCountArr = []

      let stepSize = 0
      if (data.length) {
        data.forEach((item) => {
          // if (index > 100) return
          labels.push((item.created_at).split('.')[0])
          // const reqResults = item.results.map(stock => stock.seg_sym)
          stocksArr.push(item.results)
          const len = item.results.length
          stepSize = len > stepSize ? len : stepSize
          stocksCountArr.push(len)
        })
      }
      return {
        ...state,
        alertScreensHistory: {
          labels,
          stocksArr,
          stepSize: Math.ceil(stepSize / 5),
          stocksCountArr,
        },
        // alertHistoryOriginal: reqArr,
        isFetchingAlertHistory: false,
      }
    }

    case GET_ALERT_HISTORY_FAILURE:
      return {
        ...state,
        isFetchingAlertHistory: false,
        alertScreensHistoryError: true,
        alertScreensHistoryErrorMsg: action.error,
      }

    case SEARCH_SCANNER_INIT:
      return {
        ...state,
        isSearchingScans: true,
        searchScannerError: false,
        searchScannerErrorMsg: '',
      }

    case SEARCH_SCANNER_SUCCESS: {
      const { data, isCategorised, append } = action
      const { results = [], total_pages = 0 } = data

      let categorisedScreens = {}
      // only used in condensed scanner view
      let current_search_page = 0

      if(isCategorised) {
        for (let i = 0; i < data.length; i++) {
          const itm = data[i]
          const lastTag = itm.tags[itm.tags.length - 1]
          if (categorisedScreens[lastTag]) {
            categorisedScreens[lastTag].push(itm)
          } else {
            categorisedScreens[lastTag] = [itm]
          }
        }
      } else {
        categorisedScreens = results
        if (append) {
          current_search_page = state.current_search_page + 1
          categorisedScreens = [...state.searchScans, ...results]
        }
      }

      return {
        ...state,
        searchScans: categorisedScreens,
        isSearchingScans: false,
        total_search_pages: total_pages,
        current_search_page,
      }
    }

    case SEARCH_SCANNER_FAILURE:
      return {
        ...state,
        isSearchingScans: false,
        searchScannerError: true,
        searchScannerErrorMsg: action.error,
      }

    case CLEAR_SEARCH_SCANNER_RESP: {
      return {
        ...state,
        searchScans: [],
        isSearchingScans: false,
        searchScannerError: false,
        searchScannerErrorMsg: '',
        total_search_pages: 0,
        current_search_page: 0,
      }
    }

    case FETCH_SCANNER_CATEGORY: {
      return {
        ...state,
        isFetchingScannerCategory: true,
        tagsDescriptionError: false,
        tagsDescriptionErrorMsg: '',
      }
    }

    case FETCH_SCANNER_CATEGORY_SUCCESS: {
      const categoryList = ['All'].concat(action.data.tags.map(item => item.tag_name))
      return {
        ...state,
        isFetchingScannerCategory: false,
        tagsDescription: action.data.tags,
        categoryList,
      }
    }

    case FETCH_SCANNER_CATEGORY_FAILURE: {
      return {
        ...state,
        isFetchingScannerCategory: false,
        tagsDescriptionError: true,
        tagsDescriptionErrorMsg: action.error,
      }
    }

    // dummy not used
    case UPDATE_SORT_ON: {
      return state
    }

    case FETCH_SCANNERS_STOCKS: {
      return {
        ...state,
        isFetchingScannerStocks: true,
        scannersStocksError: false,
        scannersStocksErrorMsg: '',
      }
    }

    case FETCH_SCANNER_STOCKS_SUCCESS: {
      const { idKey, id, data } = action
      return {
        ...state,
        scannersStocks: {
          ...state.scannersStocks,
          [idKey]: {
            ...state.scannersStocks[idKey],
            [id]: data,
          },
        },
        isFetchingScannerStocks: false,
      }
    }

    case FETCH_SCANNERS_STOCKS_FAILURE: {
      return {
        ...state,
        isFetchingScannerStocks: false,
        scannersStocksError: true,
        scannersStocksErrorMsg: action.error,
      }
    }

    case UPDATE_ALERT_SCREENS_STOCKS: {
      const { alert_uuid, result_count = 0 } = action.params
      return {
        ...state,
        alertCount: {
          ...state.alertCount,
          [alert_uuid]: result_count,
        },
      }
    }

    case GENERATE_SCANNER_SHARE_LINK:
      return {
        ...state,
        screener_share_uuid: null,
        generateScannerShareLinkSuccess: false,
        isGeneratingScannerShareLink: true,
        generateScannerShareLinkError: false,
        generateScannerShareLinkErrorMsg: '',
      }

    case GENERATE_SCANNER_SHARE_LINK_SUCCESS:
      return {
        ...state,
        screener_share_uuid: action.data.screener_share_uuid,
        isGeneratingScannerShareLink: false,
        generateScannerShareLinkSuccess: true,
      }

    case GENERATE_SCANNER_SHARE_LINK_FAILURE:
      return {
        ...state,
        isGeneratingScannerShareLink: false,
        generateScannerShareLinkError: true,
        generateScannerShareLinkErrorMsg: action.error,
      }

    default:
      return state
  }
}

export default scans
