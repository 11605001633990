import moment from 'moment'
import {
  COLORS, ASSETS, ICONS, theme,
} from '../../Theme'

const {
  PaperFilled, PaperFilledDark, ScannerFilled, ScannerFilledDark, WifiFilled, WifiFilledDark,
} = ASSETS

// DEPLOYED_COUNT_KEY represents redux key
// Data key represents redux key
// Count key represents backend key
export const NOTIFICATIONS_SECTION_MAP = {
  ALL: {
    LABEL: 'All Notifications',
    ID: 'ALL',
    COUNT_KEY: 'all',
    DATA_KEY: 'All Notifications',
    DEPLOYMENT_TYPE: 'All',
    DEPLOYED_COUNT_KEY: 'all',
    INDEX: 0,
    iconName: 'NOTIF_DASHBOARD',
    activeTextColor: theme.linkColor,
    gaLabel: 'All notifications',
  },
  LIVE: {
    LABEL: 'Live Strategies',
    ID: 'LIVE',
    COUNT_KEY: 'notifications',
    DATA_KEY: 'Notifications',
    DEPLOYMENT_TYPE: 'Notifications',
    DEPLOYED_COUNT_KEY: 'notifications',
    INDEX: 1,
    iconName: 'NOTIF_AUTO',
    activeTextColor: theme.linkColor,
    gaLabel: 'Live trade',
  },
  PAPER: {
    LABEL: 'Paper Trading',
    ID: 'PAPER',
    COUNT_KEY: 'paper',
    DATA_KEY: 'Paper trading',
    DEPLOYMENT_TYPE: 'Paper trading',
    DEPLOYED_COUNT_KEY: 'paper',
    INDEX: 2,
    iconName: 'NOTIF_PAPER',
    activeTextColor: theme.green,
    gaLabel: 'Paper trade',
  },
  SCANNER: {
    LABEL: 'Scanners',
    ID: 'SCANNER',
    COUNT_KEY: 'direct',
    DATA_KEY: 'direct',
    DEPLOYMENT_TYPE: 'screener_alert',
    DEPLOYED_COUNT_KEY: 'screener',
    INDEX: 3,
    iconName: 'NOTIF_SCANNER',
    activeTextColor: COLORS.SCANNER_PINK,
    gaLabel: 'Scanner',
  },
  DISCLAIMER: {
    LABEL: 'Changelogs',
    ID: 'CHANGELOGS',
    COUNT_KEY: 'changelogs',
    DATA_KEY: 'changelogs',
    DEPLOYMENT_TYPE: 'changelogs',
    DEPLOYED_COUNT_KEY: 'changelogs',
    INDEX: 4,
    iconName: 'CHANGELOGS',
    activeTextColor: COLORS.SCANNER_PINK,
    gaLabel: 'Changelogs',
  },
}

// The last item is added just so that NotificationOveriew can display Deployed Alos
export const getNotificationsIconMap = isDark => ({
  [NOTIFICATIONS_SECTION_MAP.ALL.ID]: {
    name: isDark ? WifiFilledDark : WifiFilled, color: COLORS.BLUE, bgColor: COLORS.BLUE_100,
  },
  [NOTIFICATIONS_SECTION_MAP.LIVE.ID]: {
    name: isDark ? WifiFilledDark : WifiFilled, color: COLORS.BLUE, bgColor: COLORS.BLUE_100,
  },
  [NOTIFICATIONS_SECTION_MAP.PAPER.ID]: {
    name: isDark ? PaperFilledDark : PaperFilled, color: COLORS.GREEN, bgColor: COLORS.GREEN_100,
  },
  [NOTIFICATIONS_SECTION_MAP.SCANNER.ID]: {
    name: isDark ? ScannerFilledDark : ScannerFilled, color: COLORS.RED, bgColor: COLORS.RED_100,
  },
  // Deployed: {
  //   name: ScannerFilled, color: COLORS.GREEN, bgColor: COLORS.GREEN_100,
  // },
})

export const getLogtagIconMapping = () => ({
  'STRATEGY EXPIRED': { COLOR: COLORS.BLACK, ICON: ICONS.EXPIRED },
  EXPIRED: { COLOR: COLORS.BLACK, ICON: ICONS.EXPIRED },
  'AT EXCHANGE': { COLOR: COLORS.BLACK, ICON: ICONS.AT_EXCHANGE },
  BOUGHT: { COLOR: COLORS.BLUE, ICON: ICONS.BASKET },
  BUY: { COLOR: COLORS.BLUE, ICON: ICONS.CART },
  'BUY ALERT': { COLOR: COLORS.BLUE, ICON: ICONS.ALERT_ROUND_FILLED },
  'CANCEL SL-M ORDER': { COLOR: COLORS.RED, ICON: ICONS.CLOSE_FILLED_OUTLINED },
  'CANCEL SL ORDER': { COLOR: COLORS.RED, ICON: ICONS.CLOSE_FILLED_OUTLINED },
  COMPLETED: { COLOR: COLORS.GREEN, ICON: ICONS.TICK_ROUND_FILLED },
  COMPLETE: { COLOR: COLORS.GREEN, ICON: ICONS.TICK_ROUND_FILLED },
  CANCELLED: { COLOR: COLORS.RED, ICON: ICONS.CLOSE_FILLED_OUTLINED },
  ENTERED: { COLOR: COLORS.BLUE, ICON: ICONS.ENTERED },
  'FORCE STOPPED': { COLOR: COLORS.RED, ICON: ICONS.STOP },
  'PLACE S-M ORDER': { COLOR: COLORS.BLUE, ICON: ICONS.AT_EXCHANGE },
  'PLACE SL-M ORDER': { COLOR: COLORS.YELLOW_600, ICON: ICONS.SLM_ORDER },
  'PLACE SL ORDER': { COLOR: COLORS.YELLOW_600, ICON: ICONS.SLM_ORDER },
  SOLD: { COLOR: COLORS.ORANGE, ICON: ICONS.BASKET },
  SELL: { COLOR: COLORS.ORANGE, ICON: ICONS.CART },
  'SELL ALERT': { COLOR: COLORS.ORANGE, ICON: ICONS.ALERT_ROUND_FILLED },
  'STOP LOSS ALERT': { COLOR: COLORS.RED, ICON: ICONS.ALERT_ROUND_FILLED },
  'SL-M CANCELLED': { COLOR: COLORS.RED, ICON: ICONS.CLOSE_FILLED },
  'SL CANCELLED': { COLOR: COLORS.RED, ICON: ICONS.CLOSE_FILLED },
  'TARGET PROFIT ALERT': { COLOR: COLORS.GREEN, ICON: ICONS.ALERT_ROUND_FILLED },
  'USER ACTION': { COLOR: COLORS.BLACK, ICON: ICONS.USER_ACTION },
  WAITING: { COLOR: COLORS.BLACK, ICON: ICONS.CLOCK },
  STOPPED: { COLOR: COLORS.RED, ICON: ICONS.STOP },
  REJECTED: { COLOR: COLORS.RED, ICON: ICONS.CLOSE_FILLED_OUTLINED },
  'SCANNER ALERT': { COLOR: COLORS.PURPLE, ICON: ICONS.SCANNER_ALERT },
  'SL REJECTED': { COLOR: COLORS.RED, ICON: ICONS.CLOSE_FILLED_OUTLINED },
  'SL-M REJECTED': { COLOR: COLORS.RED, ICON: ICONS.CLOSE_FILLED_OUTLINED },
})

export const getLogtagColorMapping = () => ({
  'STRATEGY EXPIRED': COLORS.BLACK,
  EXPIRED: COLORS.BLACK,
  'AT EXCHANGE': COLORS.BLACK,
  BOUGHT: COLORS.BLUE,
  BUY: COLORS.BLUE,
  'BUY ALERT': COLORS.BLUE,
  'CANCEL SL-M ORDER': COLORS.RED,
  'CANCEL SL ORDER': COLORS.RED,
  COMPLETED: COLORS.GREEN,
  COMPLETE: COLORS.GREEN,
  CANCELLED: COLORS.RED,
  ENTERED: COLORS.BLUE,
  'FORCE STOPPED': COLORS.RED,
  'PLACE S-M ORDER': COLORS.BLUE,
  'PLACE SL ORDER': COLORS.YELLOW_600,
  'PLACE SL-M ORDER': COLORS.YELLOW_600,
  SOLD: COLORS.ORANGE,
  SELL: COLORS.ORANGE,
  'SELL ALERT': COLORS.ORANGE,
  'STOP LOSS ALERT': COLORS.RED,
  'SL CANCELLED': COLORS.RED,
  'SL-M CANCELLED': COLORS.RED,
  'TARGET PROFIT ALERT': COLORS.GREEN,
  'USER ACTION': COLORS.BLACK,
  WAITING: COLORS.BLACK,
  STOPPED: COLORS.RED,
  REJECTED: COLORS.RED,
  'SCANNER ALERT': COLORS.PURPLE,
  'SL REJECTED': COLORS.RED,
  'SL-M REJECTED': COLORS.RED,
})

export const getAllNotifications = (notifications) => {
  const allNotifications = []
  Object.keys(notifications).map(notifType => allNotifications.push(notifications[notifType]))
  const sortedNotifications = allNotifications.flat(1).sort((a, b) => {
    return new Date(a.notifications[0].notification_time)
      - new Date(b.notifications[0].notification_time)
  }).reverse()
  return sortedNotifications
}

export const getSlmNotifs = (notifs, used, parentIndex) => {
  const slms = []
  for (let j = 1; j < notifs.length; j++) {
    const notif = notifs[j]
    if (notif['notification-type'] === 'cancel-discipline-notif'
      && !used[notif.notification_uuid]) {
      const {
        order_id, exchange, algo_name, deployment_type, tradingsymbol, symbol,
      } = notif
      const time = moment(moment(notif.notification_time).unix() * 1000 - (new Date().getTimezoneOffset() * 60000)).format('hh:mm:ss A')
      slms.push({
        order_id,
        algo_name,
        deployment_type,
        index: j,
        parentIndex,
        symbol,
        exchange,
        tradingsymbol,
        notification_uuid: notif.notification_uuid,
        time,
      })
    }
  }
  return slms
}
