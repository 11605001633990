/* eslint-disable react/no-array-index-key */
import makeStyles from '@material-ui/styles/makeStyles'
import React from 'react'

import PlaceHolderLoader from '../PlaceHolderLoader'

import { SPACING, theme } from '../../Theme'

const loaderCountMap = {
  0: 1,
  1: 3,
  2: 5,
  3: 7,
  4: 9,
}

const decrementCalc = (dBy = 8, multiplier, value) => {
  const dec = dBy * multiplier // dBy + (dBy * (multiplier / 10))
  const finalValue = value - (value * (dec / 100))
  return finalValue < 50 ? 50 : finalValue
}

const StreakCarousalLoader = ({ arrCount, displayDevice, defaultWidth = 140 }) => {
  const styles = stylesheet()
  const count = arrCount || loaderCountMap[displayDevice] || 4
  const midEle = Math.ceil(count / 2)
  const renderCard = () => {
    return (
      <div className={styles.scLoaderCardWrapper}>
        {[...Array(count)].map((item, index) => {
          return (
            <div
              key={`scLoaderCard${index}`}
              className={styles.scLoaderCard}
              style={{
                width: decrementCalc(12, Math.abs(midEle - (index + 1)), defaultWidth),
                height: decrementCalc(12, Math.abs(midEle - (index + 1)), 120),
              }}
            >
              <div className={styles.scLoaderIconContainer}>
                <PlaceHolderLoader className={styles.scLoaderIcon} />
              </div>
              <div className={styles.scLoaderContent}>
                <PlaceHolderLoader className={styles.scLoaderTitle} />
                <PlaceHolderLoader className={styles.scLoaderText} />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div className={styles.scLoader}>
      {renderCard()}
    </div>
  )
}

const stylesheet = makeStyles({
  // loader styles
  scLoader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  scLoaderCardWrapper: {
    height: '100%',
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  scLoaderCard: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: `calc(100% - ${SPACING.SPACE_40})`,
    width: '156px',
    backgroundColor: theme.bgPrimary,
    borderRadius: '5px',
    margin: `${SPACING.SPACE_20} ${SPACING.SPACE_10}`,
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    boxShadow: '0px 7px 20px #15278412',
    verticalAlign: 'middle',
  },
  scLoaderIconContainer: {
    display: 'flex',
    flex: 0.4,
    alignSelf: 'center',
    marginBottom: SPACING.SPACE_6,
  },
  scLoaderIcon: {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'flex-end',
    justifyContent: 'center',
    '& > img': {
      maxWidth: '24px',
      maxHeight: '24px',
      height: '100px !important',
      width: '100px !important',
      borderRadius: '50% !important',
    },
  },
  scLoaderContent: {
    flex: 0.6,
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  scLoaderTitle: {
    maxHeight: 10,
    height: '100%',
    display: 'flex',
    marginBottom: SPACING.SPACE_6,
    maxWidth: 50,
    width: '78%',
  },
  scLoaderText: {
    maxHeight: 10,
    height: '100%',
    display: 'flex',
    maxWidth: 80,
    alignItems: 'center',
    marginBottom: SPACING.SPACE_10,
  },
})

export default StreakCarousalLoader
