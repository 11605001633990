import React, { useEffect } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import CustomText from '../../UI/CustomText'
import ViewAllCard from '../../UI/ViewAllCard'

import { pathname_mapping } from '../../utils/navigationConstant'
import { theme, SPACING, ASSETS } from '../../Theme'
import { parseQuery } from '../../utils/common'
import { getTopMovers } from '../../containers/Marketwatch/actions'
import TopMoversSidebarRow from './TopMoversSidebarRow'
import PlaceHolderLoader from '../../UI/PlaceHolderLoader'

const { QL_DEPLOYED, QL_ORDERBOOK, QL_PORTFOLIO } = ASSETS
const QUICK_LINKS_MAP = [
  {
    id: 'DEPLOYED', title: 'Deployed', imgSrc: QL_DEPLOYED, to: pathname_mapping.Deployed, filterKey: 'live',
  },
  {
    id: 'ORDERBOOK', title: 'Orderbook', imgSrc: QL_ORDERBOOK, to: pathname_mapping.pending_orders, filterKey: 'pending',
  },
  {
    id: 'PORTFOLIO', title: 'Portfolio', imgSrc: QL_PORTFOLIO, to: pathname_mapping.Portfolio, filterKey: 'positions',
  },
]

const renderActionBtn = () => {
  return <CustomText size="small" color={theme.linkColor}>See more</CustomText>
}

const getSegSym = (item) => {
  const { segment = '', symbol } = item
  return `${segment.toUpperCase()}_${symbol}`
}

const TopMoversSidebar = React.memo(({
  top_movers,
  fetchTopMovers,
  isDark,
  isMobile,
  isFetchingTopMovers,
  csrf,
  sessionid,
  updateGA,
}) => {
  const history = useHistory()
  const location = useLocation()
  const styles = stylesheet()
  const { tab } = parseQuery(location.search)

  const FILTERED_QL = QUICK_LINKS_MAP.filter(ql => ql.filterKey !== tab)

  useEffect(() => {
    if (top_movers.length === 0) {
      fetchTopMovers()
    }
  }, [])

  const onViewAll = () => {
    const ele = document.querySelector('#topMoversId')
    if (ele) {
      updateGA('See more', 'Top movers')
      document.getElementById('main').scrollTo({ top: ele.offsetTop, behavior: 'smooth' })
    }
  }

  const routeToTechnicals = (seg_sym) => {
    history.push({
      pathname: pathname_mapping.symbolDetails,
      search: `?id=${btoa(seg_sym)}`,
    })
  }

  const onPressQuickLink = (link) => {
    history.push(link)
  }

  const renderData = () => {
    return top_movers.slice(0, 10).map((item, index) => {
      const { pc_change = 0 } = item
      const seg_sym = getSegSym(item)

      const lastEle = index === top_movers.length - 1
      return (
        <div key={seg_sym}>
          <TopMoversSidebarRow
            seg_sym={seg_sym}
            pc_change={pc_change}
            index={index}
            isDark={isDark}
            lastEle={lastEle}
            routeToTechnicals={routeToTechnicals}
            isMobile={isMobile}
            item={item}
            csrf={csrf}
            sessionid={sessionid}
          />
        </div>
      )
    })
  }

  const renderTopMoverLoading = () => {
    return (
      <div className={styles.loaderRow}>
        <PlaceHolderLoader className={styles.symbolLoader} />
        <PlaceHolderLoader className={styles.ltpLoader} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <ViewAllCard
        title="Top Movers"
        cardStyles={classnames(styles.cardContainer)}
        onPress={onViewAll}
        headerStyles={classnames(styles.header)}
        showViewAll={top_movers.length > 5}
        actionBtnRederer={renderActionBtn}
      >
        {isFetchingTopMovers ? (
          <div>
            {renderTopMoverLoading()}
            {renderTopMoverLoading()}
            {renderTopMoverLoading()}
            {renderTopMoverLoading()}
            {renderTopMoverLoading()}
            {renderTopMoverLoading()}
            {renderTopMoverLoading()}
            {renderTopMoverLoading()}
            {renderTopMoverLoading()}
            {renderTopMoverLoading()}
          </div>
        ) : renderData()}
      </ViewAllCard>
      <div className={styles.quickLinksContainer}>
        <CustomText size="medium" weight="semi_bold">Quick links</CustomText>
        <div className={styles.quickLinksRow}>
          {FILTERED_QL.map((item) => {
            return (
              <button key={`quickLink-${item.id}`} type="button" onClick={() => onPressQuickLink(item.to)}>
                <div className={styles.quickLink}>
                  <img src={item.imgSrc} alt={item.title} className={styles.quickLinkIcon} />
                  <CustomText>{item.title}</CustomText>
                </div>
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
})

const stylesheet = makeStyles({
  container: {},
  cardContainer: {
    boxShadow: 'none !important',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: SPACING.SPACE_10,
    '& > img': {
      width: 30,
    },
  },
  info: {
    flex: 1,
    textAlign: 'left',
  },
  header: {},
  quickLinksContainer: {
    margin: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
  quickLinksRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: `${SPACING.SPACE_10} 0`,
  },
  quickLinkIcon: {
    width: 40,
    margin: `${SPACING.SPACE_10} 0`,
  },
  loaderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_6,
    margin: SPACING.SPACE_20,
  },
  symbolLoader: {
    width: '50%',
    height: '20px',
  },
  ltpLoader: {
    width: '30%',
    height: '20px',
  },
})

const mapStateToProps = (state) => {
  return {
    ...state.auth.loginResp,
    csrf: state.auth.loginResp.csrf,
    sessionid: state.auth.loginResp.sessionid,
    user_details: state.auth.user_details,

    top_movers: state.market.top_movers,
    isFetchingTopMovers: state.market.isFetchingTopMovers,
    topMoversError: state.market.topMoversError,
    topMoversErrorMsg: state.market.topMoversErrorMsg,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTopMovers: (params, headers) => dispatch(getTopMovers(params, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMoversSidebar)
