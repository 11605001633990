export const GET_SCREENS_INIT = 'GET_SCREENS_INIT'
export const GET_SCREENS_SUCCESS = 'GET_SCREENS_SUCCESS'
export const GET_SCREENS_FAILURE = 'GET_SCREENS_FAILURE'

export const POPULAR_SCREENS_INIT = 'POPULAR_SCREENS_INIT'
export const POPULAR_SCREENS_SUCCESS = 'POPULAR_SCREENS_SUCCESS'
export const POPULAR_SCREENS_FAILURE = 'POPULAR_SCREENS_FAILURE'

export const MARK_FAVORITE_INIT = 'MARK_FAVORITE_INIT'
export const MARK_FAVORITE_SUCCESS = 'MARK_FAVORITE_SUCCESS'
export const MARK_FAVORITE_FAILURE = 'MARK_FAVORITE_FAILURE'

export const GET_FAVORITE_INIT = 'GET_FAVORITE_INIT'
export const GET_FAVORITE_SUCCESS = 'GET_FAVORITE_SUCCESS'
export const GET_FAVORITE_FAILURE = 'GET_FAVORITE_FAILURE'

export const DELETE_SCREENS_INIT = 'DELETE_SCREENS_INIT'
export const DELETE_SCREENS_SUCCESS = 'DELETE_SCREENS_SUCCESS'
export const DELETE_SCREENS_FAILURE = 'DELETE_SCREENS_FAILURE'

export const GET_ALERT_SCREENS_INIT = 'GET_ALERT_SCREENS_INIT'
export const GET_ALERT_SCREENS_SUCCESS = 'GET_ALERT_SCREENS_SUCCESS'
export const GET_ALERT_SCREENS_FAILURE = 'GET_ALERT_SCREENS_FAILURE'

export const SET_ALERT_SCREENS_INIT = 'SET_ALERT_SCREENS_INIT'
export const SET_ALERT_SCREENS_SUCCESS = 'SET_ALERT_SCREENS_SUCCESS'
export const SET_ALERT_SCREENS_FAILURE = 'SET_ALERT_SCREENS_FAILURE'

export const STOP_ALERT_SCREENS_INIT = 'STOP_ALERT_SCREENS_INIT'
export const STOP_ALERT_SCREENS_SUCCESS = 'STOP_ALERT_SCREENS_SUCCESS'
export const STOP_ALERT_SCREENS_FAILURE = 'STOP_ALERT_SCREENS_FAILURE'

export const GET_ALERT_HISTORY_INIT = 'GET_ALERT_HISTORY_INIT'
export const GET_ALERT_HISTORY_SUCCESS = 'GET_ALERT_HISTORY_SUCCESS'
export const GET_ALERT_HISTORY_FAILURE = 'GET_ALERT_HISTORY_FAILURE'

export const SEARCH_SCANNER_INIT = 'SEARCH_SCANNER_INIT'
export const SEARCH_SCANNER_SUCCESS = 'SEARCH_SCANNER_SUCCESS'
export const SEARCH_SCANNER_FAILURE = 'SEARCH_SCANNER_FAILURE'
export const CLEAR_SEARCH_SCANNER_RESP = 'CLEAR_SEARCH_SCANNER_RESP'

export const FETCH_SCANNER_CATEGORY = 'FETCH_SCANNER_CATEGORY'
export const FETCH_SCANNER_CATEGORY_SUCCESS = 'FETCH_SCANNER_CATEGORY_SUCCESS'
export const FETCH_SCANNER_CATEGORY_FAILURE = 'FETCH_SCANNER_CATEGORY_FAILURE'

export const UPDATE_SORT_ON = 'UPDATE_SORT_ON'

export const FETCH_SCANNERS_STOCKS = 'FETCH_SCANNERS_STOCKS'
export const FETCH_SCANNER_STOCKS_SUCCESS = 'FETCH_SCANNER_STOCKS_SUCCESS'
export const FETCH_SCANNERS_STOCKS_FAILURE = 'FETCH_SCANNERS_STOCKS_FAILURE'

export const UPDATE_ALERT_SCREENS_STOCKS = 'UPDATE_ALERT_SCREENS_STOCKS'

export const GENERATE_SCANNER_SHARE_LINK = 'GENERATE_SCANNER_SHARE_LINK'
export const GENERATE_SCANNER_SHARE_LINK_SUCCESS = 'GENERATE_SCANNER_SHARE_LINK_SUCCESS'
export const GENERATE_SCANNER_SHARE_LINK_FAILURE = 'GENERATE_SCANNER_SHARE_LINK_FAILURE'
