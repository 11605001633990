import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'

import CustomText from '../../UI/CustomText'
import ViewEditCard from '../../UI/ViewEditCard'
import Button from '../../UI/Button'

import {
  COLORS, SPACING,
} from '../../Theme'

const iconMap = {
  ENTRY: { iconName: 'ENTRY', color: 'voilet' },
  EXIT: { iconName: 'EXIT', color: 'voilet' },
  Condition: { iconName: 'ENTRY', color: 'voilet' },
  'Scan on': { iconName: 'SCAN_ROUND_FILLED', color: 'voilet' },
}

const strLen = 90

const EntryExitRenderer = React.memo(({
  condition = '',
  cardTitle,
  onCardPress,
  disabled,
  showAddBtn,
  addBtnText,
  status,
  titleStyles,
  cardStyles,
  // numberOfLines = 2,
  cardRef,
  isDark,
  params,
  textStyles,
}) => {
  const styles = stylesheet()
  const [showReadMore, toggleShowReadMore] = useState(condition.length > strLen)
  const [readMore, toggleReadMore] = useState(false)
  useEffect(() => {
    if (!condition) {
      toggleShowReadMore(false)
    } else {
      toggleShowReadMore(condition.length > strLen)
    }
  }, [condition])
  const onIconPress = () => {
    if (disabled) return
    onCardPress()
  }
  const renderHeaderRight = () => {
    if (cardTitle.toLowerCase() === 'exit' && params && params.tpsl_type) {
      return (
        <div className={styles.sltpRow}>
          <CustomText weight="medium">TPSL type</CustomText>
          <CustomText style={{ marginLeft: SPACING.SPACE_8 }}>{params.tpsl_type}</CustomText>
        </div>
      )
    }
    return null
  }
  const addButton = Boolean(showAddBtn && !condition)
  return (
    <ViewEditCard
      iconObj={iconMap[cardTitle]}
      cardTitle={cardTitle}
      onCardPress={onCardPress}
      disabled={disabled}
      status={status}
      titleStyle={titleStyles}
      style={cardStyles}
      cardRef={cardRef}
      showEdit={!addButton}
      isDark={isDark}
      renderHeaderRight={renderHeaderRight}
    >
      {/* <InfoModal
        name={cardTitle}
        desc={condition}
        visible={more}
        showEditIcon={false}
        toggleVisible={toggleReadMore}
        disabled
        isDark={isDark}
      /> */}
      {addButton ? (
        <Button
          onPress={onIconPress}
          text={`    ${addBtnText}`}
          labelColor="VOILET"
          transparent
          labelSize="regular"
          mode="text"
          isDark={isDark}
          btnStyles={styles.iconBtn}
          iconName="ADD_RECT_OUTLINED"
          iconProps={{
            color: COLORS.VOILET,
            size: 40,
          }}
        />
      ) : (
        <CustomText
          weight="regular"
          size="small"
          color="text"
          className={textStyles}
          // onPress: () => toggleReadMore(true),
        >
          {readMore ? condition : condition.substr(0, strLen + 1)}
        </CustomText>
      )}
      {showReadMore && (
        <CustomText
          color="linkColor"
          weight="regular"
          size="small"
          style={{ cursor: 'pointer' }}
          extraProps={{ onClick: () => toggleReadMore(!readMore) }}
        >
          {readMore ? 'Read Less' : 'Read More'}
        </CustomText>
      )}
    </ViewEditCard>
  )
})

const stylesheet = makeStyles({
  btn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconBtn: {
    marginRight: SPACING.SPACE_20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sltpRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: SPACING.SPACE_10,
  },
})

export default EntryExitRenderer
