import { takeLatest, put, call } from 'redux-saga/effects'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest, ApiRequest } from '../../utils/apiRequests'
import {
  fetchVersionSuccess,
  fetchVersionFailure,
  updateVersionSuccess,
  updateVersionFailure,
  validateSessionSuccess,
  validateSessionFailure,
  getUserDetailsFailure,
  getUserDetailsSuccess,
  loginUserSuccess,
  loginUserFailure,
  logoutSuccess,
  logoutFailure,
  validateEmailSuccess,
  validateEmailFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  switchBrokerFailure,
  clearUserDetails,
  accountActivationSuccess,
  accountActivationFailure,
  updatePartnerRefFailure,
  updateOnboardingActivityFailure,
  updateOnboardingActivitySuccess,
  getRewardsFailure,
  getRewardsSuccess,
  awailRewardSuccess,
  awailRewardFailure,
  saveLeadSuccess,
  saveLeadFailure,
} from './actions'
import {
  GET_USER_DETAILS_INIT,
  // LOGOUT_INIT,
  BROKER_LOGIN,
  FETCH_VERSION,
  UPDATE_VERSION,
  VALIDATE_SESSION,
  LOGIN_INIT,
  VALIDATE_EMAIL,
  EMAIL_LOGIN,
  RESET_PASSWORD_INIT,
  FORGOT_PASSWORD_INIT,
  ACCOUNT_ACTIVATE_INIT,
  UPDATE_PARTNER_REF,
  UPDATE_ONBRDING_ACTIVITY,
  GET_ALL_REWARDS_INIT,
  AWAIL_REWARD_INIT,
  SAVE_LEAD,
  UPDATE_FANCY_BROKER,
} from './actionTypes'

import {
  error_msg, getCookies, headersGenerator,
} from '../../utils/common'
import { BROKERS } from '../../utils/consts'
import { isCtbFlow } from '../Create/Create-utils'

// function* getVersion(headers) {
//   try {
//     const url = `${baseUrl}${prodEndpoints.get_version}`
//     const data = yield call(getRequest, url, {}, headers)
//     if (!data.error && data.status === 'success') {
//       getVersionSuccess(data)
//     } else {
//       getVersionFailure(data.error)
//     }
//   } catch(err) {
//     yield put(getVersionFailure(err.message || error_msg))
//   }
// }

// export function* getVersionSaga() {
//   yield takeLatest(APP_VERSION, getVersion)
// }

function* login(action) {
  try {
    const { params, actionType: type, headers } = action
    const { broker } = params
    let url = `${prodEndpoints.login}`
    if (type === 'create_session') {
      url = `${prodEndpoints.ams_create_session}`
    } else if (broker === BROKERS.UPSTOX.name
      || broker === BROKERS.ICICI.name || broker === BROKERS.FIVE_PAISA.name) {
      url = prodEndpoints.broker_sso_login
    } else if (broker && [BROKERS.ANGEL.name].includes(broker)) {
      url = `${prodEndpoints.ams_login}`
    } else if (broker && BROKERS.NONE.name === broker) {
      url = `${prodEndpoints.user_login}`
    }
    const data = yield call(postRequest, url, params, {
      ...headers,
      'Content-type': 'application/x-www-form-urlencoded',
      'x-csrftoken': 'bG9naW4=',
    })
    const { email, user_uuid } = params
    const addBroker = email && user_uuid
    if (!data.error && data.status === 'success') {
      if (addBroker) {
        // clearing state of old broker
        yield put(clearUserDetails())
      }
      yield put(loginUserSuccess(data))
    } else if (addBroker) {
      // adding broker or switching broker failed
      const { id } = getCookies()
      if (id) {
        yield put(switchBrokerFailure({
          ...params, error_msg: data.error_msg, sessionid: atob(id),
        }))
      } else {
        yield put(loginUserFailure(data.error_msg || error_msg))
      }
    } else {
      yield put(loginUserFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err, 'error')
    yield put(loginUserFailure('Login failed. Please hard refresh the page'))
  }
  // yield put(togglePageLoader(false))
}

export function* loginSaga() {
  yield takeLatest(LOGIN_INIT, login)
}

function* getUserDetails(action) {
  try {
    const { params } = action
    let headers = null
    if (params && params.csrf) {
      const { csrf, sessionid } = params
      headers = headersGenerator({
        cookie1: `csrftoken=${csrf};sessionid=${sessionid}`,
      })
      ApiRequest.defaults.headers.common.Authorization = `csrftoken=${csrf};csrfmiddlewaretoken=${csrf};sessionid=${sessionid}`
    }
    const url = `${baseUrl}${prodEndpoints.get_session_status}`
    const data = yield call(getRequest, url, {}, headers)
    if (data.status === 'error' || data['error-type'] === 'auth' || data.error) {
      yield put(getUserDetailsFailure(data))
    } else {
      yield put(getUserDetailsSuccess(data))
      const isCtb = isCtbFlow()
      if (params && params.csrf && !isCtb) {
        const userEmail = data.user_profile.email
        yield put({
          type: VALIDATE_EMAIL,
          params: { email: userEmail },
        })
      }
      if (isCtb) {
        const brokerObj = Object.values(BROKERS)
          .find(broker => broker.name === data.user_profile.broker)
        yield put({
          type: UPDATE_FANCY_BROKER,
          brokerObj,
        })
      }
    }
  } catch(err) {
    yield put(getUserDetailsFailure(err.message || error_msg))
  }
}

export function* getUserDetailsSaga() {
  yield takeLatest(GET_USER_DETAILS_INIT, getUserDetails)
}

// function* requestDemoCall(action) {
//   try {
//     const { params, headers } = action
//     const url = `${baseUrl}${prodEndpoints.request_demo}`
//     const data = yield call(postRequest, url, params, headers)
//     if(data.data === 'Ok') {
//       yield put(requestDemoCallSuccess())
//     } else if(data.error_msg === 'Demo already scheduled') {
//       yield put(requestDemoCallSuccess())
//       yield put(requestDemoCallFailure(data.error_msg))
//     } else {
//       yield put(requestDemoCallFailure(data.error_msg))
//     }
//   } catch(err) {
//     yield put(requestDemoCallFailure(err.message || error_msg))
//   }
// }

// not used
function* brokerLogin(action) {
  try {
    const { urlParams, brokerName, headers } = action
    let url = ''
    switch(brokerName) {
      case BROKERS.ZERODHA.name: {
        url = `${baseUrl}${prodEndpoints.login}`
        break
      }
      case BROKERS.ANGEL.name: {
        url = `${baseUrl}${prodEndpoints.ams_login}`
        break
      }
      case BROKERS.FIVE_PAISA.name: {
        url = `${baseUrl}${prodEndpoints.ams_login}`
        break
      }
      default:
        break
    }
    const data = yield call(postRequest, url, urlParams, headers)
    if (!data.error && data.status === 'success') {
      yield put(loginUserSuccess(data))
      const { csrf, sessionid } = data
      yield put(getUserDetails({ csrf, sessionid }))
    } else {
      // ToastAndroid.show('Something went Wront', ToastAndroid.SHORT)
      yield put(loginUserFailure(data.error_msg || error_msg))
    }
  } catch(err) {
    yield put(loginUserFailure(err.message || error_msg))
  }
}

export function* brokerLoginSaga() {
  yield takeLatest(BROKER_LOGIN, brokerLogin)
}

// DANGER
// eslint-disable-next-line no-unused-vars
function* logout(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.ams_logout}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(logoutSuccess())
    } else {
      yield put(logoutFailure())
    }
  } catch(err) {
    yield put(logoutFailure(err.message || error_msg))
  }
}

// export function* logoutSaga() {
//   yield takeLatest(LOGOUT_INIT, logout)
// }

function* fetchVersion(action) {
  try {
    const { headers } = action
    const url = `${prodEndpoints.user_version_pref}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchVersionSuccess(data))
    } else {
      yield put(fetchVersionFailure(data['error-type'] || data.error_msg))
    }
  } catch (err) {
    yield call(fetchVersionFailure(error_msg))
  }
  // yield put(togglePageLoader(false))
}

export function* fetchVersionSaga() {
  yield takeLatest(FETCH_VERSION, fetchVersion)
}

function* updateVersion(action) {
  try {
    const { params, headers } = action
    const url = `${prodEndpoints.user_version_pref}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(updateVersionSuccess(data.version))
    } else {
      yield put(updateVersionFailure(data.error_msg))
    }
  } catch (err) {
    yield call(updateVersionFailure(error_msg))
  }
}

export function* updateVersionSaga() {
  yield takeLatest(UPDATE_VERSION, updateVersion)
}

function* validateSession(action) {
  try {
    const { params, headers } = action
    const url = `${prodEndpoints.validate_session}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(validateSessionSuccess(data))
    } else {
      yield put(validateSessionFailure(data.error_msg))
    }
  } catch (err) {
    yield call(validateSessionFailure(error_msg))
  }
  // yield put(togglePageLoader(false))
}

export function* validateSessionSaga() {
  yield takeLatest(VALIDATE_SESSION, validateSession)
}

function* validateEmail(action) {
  try {
    const { params } = action
    const url = `${prodEndpoints.validate_email}`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-csrftoken': 'bG9naW4=',
    }
    const data = yield call(postRequest, url, params, headers)
    if (data.status_code && data.status === 'success') {
      yield put(validateEmailSuccess(data))
    } else {
      yield put(validateEmailFailure(data.error_msg))
    }
  } catch (err) {
    yield put(validateEmailFailure(error_msg))
  }
}

export function* validateEmailSaga() {
  yield takeLatest(VALIDATE_EMAIL, validateEmail)
}

function* emailLogin(action) {
  try {
    const { params } = action
    const url = `${prodEndpoints.user_login}`
    const headers = {
      'x-csrftoken': 'bG9naW4=',
    }
    const data = yield call(postRequest, url, params, headers)
    const {
      error,
      status,
      status_code,
    } = data
    if (!error && status === 'success') {
      if (status_code === 102 || status_code === 203) {
        yield put(loginUserFailure(data.msg, status_code))
        return
      }
      yield put(loginUserSuccess(data))
    } else {
      yield put(loginUserFailure(data.error || error_msg))
    }
  } catch (err) {
    yield call(loginUserFailure(error_msg))
  }
}

export function* emailLoginSaga() {
  yield takeLatest(EMAIL_LOGIN, emailLogin)
}

function* resetPassword(action) {
  try {
    const { params, headers } = action
    const url = prodEndpoints.reset_password
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(resetPasswordSuccess(data))
    } else {
      yield put(resetPasswordFailure(data.error_msg || error_msg))
    }
  } catch (e) {
    yield put(resetPasswordFailure(e.message))
  }
}

export function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD_INIT, resetPassword)
}

function* forgotPassword(action) {
  try {
    const { params } = action
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    const url = prodEndpoints.forgot_password
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(forgotPasswordSuccess(data))
    } else {
      yield put(forgotPasswordFailure(data.error_msg || error_msg))
    }
  } catch (e) {
    yield put(forgotPasswordFailure(e.message))
  }
}

export function* forgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD_INIT, forgotPassword)
}

function* accountActivation(action) {
  try {
    const { params } = action
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    const url = prodEndpoints.account_activation
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(accountActivationSuccess(data))
    } else {
      yield put(accountActivationFailure(data.error_msg || error_msg))
    }
  } catch (e) {
    yield put(accountActivationFailure(e.message))
  }
}

export function* accountActivationSaga() {
  yield takeLatest(ACCOUNT_ACTIVATE_INIT, accountActivation)
}

function* updatePartnerRef(action) {
  try {
    const { params, headers } = action.payload
    const url = `${baseUrl}${prodEndpoints.register_partner}`
    yield call(postRequest, url, params, headers)
  } catch (err) {
    yield call(updatePartnerRefFailure(err))
  }
}

export function* updatePartnerRefSaga() {
  yield takeLatest(UPDATE_PARTNER_REF, updatePartnerRef)
}

function* updateOnboardingActivity(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.update_onboarding_activity}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(updateOnboardingActivitySuccess(data))
      yield put({ type: GET_USER_DETAILS_INIT })
    } else {
      yield put(updateOnboardingActivityFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield call(updateOnboardingActivityFailure(err))
  }
}

export function* updateOnboardingActivitySaga() {
  yield takeLatest(UPDATE_ONBRDING_ACTIVITY, updateOnboardingActivity)
}

function* getRewards() {
  try {
    const url = `${baseUrl}${prodEndpoints.get_all_referrals}`
    const data = yield call(getRequest, url, null, {})
    if (!data.error && data.status === 'success') {
      yield put(getRewardsSuccess(data))
    } else {
      yield put(getRewardsFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield call(getRewardsFailure(err))
  }
}

export function* getRewardsSaga() {
  yield takeLatest(GET_ALL_REWARDS_INIT, getRewards)
}

function* awailReward(action) {
  try {
    const url = `${baseUrl}${prodEndpoints.avail_reward}`
    const { params } = action
    const data = yield call(postRequest, url, params, {})
    if (!data.error && data.status === 'success') {
      yield put(awailRewardSuccess(data))
    } else {
      yield put(awailRewardFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield call(awailRewardFailure(err))
  }
}

export function* awailRewardsSaga() {
  yield takeLatest(AWAIL_REWARD_INIT, awailReward)
}

function* saveLead(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}${prodEndpoints.signup}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(saveLeadSuccess(data))
    } else {
      yield put(saveLeadFailure(data.msg || error_msg))
    }
  } catch (err) {
    yield call(saveLeadFailure(err))
  }
}

export function* saveLeadSaga() {
  yield takeLatest(SAVE_LEAD, saveLead)
}
