// const IN_PRODUCTION = process.env.NODE_ENV !== 'development'

// const ctbPathRegex = /^\/create\/[a-z1-9-]*/

// function getCookie() {
//   const cookie_obj = {}
//   if (typeof window !== 'undefined') {
//     let cookies = decodeURIComponent(document.cookie)
//     cookies = cookies.split(';')
//     for (let i = 0; i < cookies.length; i++) {
//       const cookie = cookies[i].split('=')
//       const [key, value] = cookie
//       if (key && value) {
//         const finalValue = cookie.slice(1, cookie.length).join('=')
//         cookie_obj[key.trim()] = finalValue.trim()
//       }
//     }
//   }
//   return cookie_obj
// }

// const isCtbFlow = (customPathname = '') => {
//   if(typeof window === 'undefined') return false
//   const { pathname, search } = window.location
//   const { ctb: isCtbBt } = parseQuery(search, true)
//   const pathName = customPathname || pathname
//   const isCtbCreate = ctbPathRegex.test(pathName)

//   if (isCtbCreate || isCtbBt) {
//     return true
//   }
//   return false
// }

export const parseQuery = (str, decode) => {
  const queries = {}
  if (!str) return queries
  const index = str.indexOf('?')
  let query = str.substring(index + 1)
  query = query.split('&')
  for (let j = 0; j < query.length; j++) {
    const [key, value] = query[j].split('=')
    if (decode) {
      try {
        queries[key] = atob(value)
      } catch (e) {
        queries[key] = value
      }
    } else if (key === 'stock') {
      queries.stock = decodeURIComponent(value).split(',')
      // if (queries.stock) {
      //   queries.stock.push(decodeURIComponent(value))
      // } else {
      //   queries.stock = [value]
      // }
    } else {
      queries[key] = decodeURIComponent(value)
    }
  }
  return queries
}

// const { callType } = getCookie()
// const isCTB = isCtbFlow()
// eslint-disable-next-line import/prefer-default-export
// export const apiInstance = IN_PRODUCTION && (Number(callType) === 2 || isCTB) ? 'api-v2' : 'api'
export const apiInstance = 'api'
