import {
  takeLatest, put, call, takeEvery,
} from 'redux-saga/effects'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  fetchTechnicalsListSuccess,
  fetchTechnicalsListFailure,
  getMultiAnalysisSuccess,
  getMultiAnalysisFailure,
  updateTechnicalsListSuccess,
  updateTechnicalsListFailure,
  fetchResistanceSuccess,
  fetchResistanceFailure,
} from './actions'
import {
  FETCH_TECHNICALS_LIST,
  GET_MULTI_ANALYSIS_INIT,
  UPDATE_TECHNICALS_LIST,
  FETCH_RESISTANCE,
} from './actionTypes'

function* fetchTechnicalsList(action) {
  const { headers, params } = action
  try {
    const {
      path = '',
    } = params
    let urlSubPath = ''
    if(path) {
      const subUrl = path.replace('/technicals', '')
      if(subUrl) {
        urlSubPath = subUrl
      }
    }
    const url = `${baseUrl}${prodEndpoints.discover_watch_list}${urlSubPath}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchTechnicalsListSuccess(data))
    } else {
      yield put(fetchTechnicalsListFailure(data.error_msg))
    }
  } catch(err) {
    yield put(fetchTechnicalsListFailure(err.message))
  }
}

export function* fetchTechnicalsListSaga() {
  yield takeLatest(FETCH_TECHNICALS_LIST, fetchTechnicalsList)
}

function* getMultiAnalysis(action) {
  try {
    const { params, headers } = action
    const url = prodEndpoints.analysis
    const data = yield call(postRequest, url, JSON.stringify(params), headers, false)
    if (data.data && typeof data.data === 'object' && Object.keys(data.data).length) {
      if (Object.keys(data.data).length) {
        yield put(getMultiAnalysisSuccess(data.data, params.stocks))
      }
    } else {
      yield put(getMultiAnalysisFailure('Unable to get analysis'))
    }
  } catch(err) {
    yield put(getMultiAnalysisFailure(err.message))
  }
}

export function* getMultiAnalysisSaga() {
  yield takeEvery(GET_MULTI_ANALYSIS_INIT, getMultiAnalysis)
}

function* updateTechnicalsList(action) {
  try {
    const { params, headers } = action
    const {
      technicalInstruments = [],
      del,
      seg_sym,
      csrfmiddlewaretoken,
      isLoggedIn,
    } = params
    let newTechnicalInstruments = [...technicalInstruments]
    if(del) {
      newTechnicalInstruments = newTechnicalInstruments.filter(segSym => segSym !== seg_sym)
    }else {
      newTechnicalInstruments.unshift(seg_sym)
    }
    const modParams = {
      csrfmiddlewaretoken,
      seg_sym_list: newTechnicalInstruments,
    }
    const successParams = {
      technicalInstruments: newTechnicalInstruments,
      seg_sym,
      remove: del,
    }
    if (isLoggedIn) {
      const url = `${baseUrl}${prodEndpoints.discover_watch_list}`
      const data = yield call(postRequest, url, modParams, headers, false)
      if (!data.error && data.status === 'success') {
        yield put(updateTechnicalsListSuccess(successParams))
      } else {
        yield put(updateTechnicalsListFailure('Unable to get analysis'))
      }
    }
  } catch(err) {
    yield put(updateTechnicalsListFailure(err.message))
  }
}

export function* updateTechnicalsListSaga() {
  yield takeLatest(UPDATE_TECHNICALS_LIST, updateTechnicalsList)
}

function* fetchResistance(action) {
  try {
    const { params, headers, single } = action
    const url = prodEndpoints.resistance_analysis
    const data = yield call(postRequest, url, JSON.stringify(params), headers, false)
    if (!data.error && data.data) {
      yield put(fetchResistanceSuccess(data.data, params.stocks, single))
    } else {
      yield put(fetchResistanceFailure('Unable to get analysis'))
    }
  } catch(err) {
    yield put(fetchResistanceFailure(err.message))
  }
}

export function* fetchResistanceSaga() {
  yield takeEvery(FETCH_RESISTANCE, fetchResistance)
}
