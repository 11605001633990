import store from '../store'
import { AB_TESTING_STATUS } from './consts'
import { pathname_mapping } from './navigationConstant'

export const isExperimentOn = (experimentName) => {
  const { user_details = {} } = store.getState().auth
  const { user_broker_id = '', ab_test_create_page = true } = user_details
  const lastChar = user_broker_id.charAt(user_broker_id.length - 1)
  const isUserOdd = Math.floor(lastChar) % 2 !== 0
  const isExperimentStatusOn = AB_TESTING_STATUS[experimentName]

  if (isUserOdd && isExperimentStatusOn && ab_test_create_page) return true
  return false
}

export const routeToCreate = ({
  history, search = '', routeParams = {}, id,
}) => {
  const isExperimentStatusOn = false // isExperimentOn(AB_TESTING_MAP.CREATE_PAGE)
  if (isExperimentStatusOn) {
    history.push({
      pathname: `${pathname_mapping.Create}/${btoa(id)}`,
      search: `?createType=${btoa('pro')}`,
    }, routeParams)
  } else {
    history.push({
      pathname: pathname_mapping.Create,
      search,
    }, routeParams)
  }
}
