/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import Datetime from 'react-datetime'
import classnames from 'classnames'

import EditAlgoParams from '.'
import CustomText from '../../UI/CustomText'
import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import CustomInput from '../../UI/CustomInput'
import ListDropdown from '../../UI/Dropdown/ListDropdown'
import Icon from '../../UI/Icon'
import EntryExitRenderer from '../Backtests/EntryExitRenderer'
import Button from '../../UI/Button'
import CustomTooltip from '../../UI/CustomTooltip'
import DateTimePicker from '../../UI/DateTimePicker'
// import CustomModal from '../../UI/CustomModal'
// import IndicatorDetails from '../Create/IndicatorDetails'

import {
  CHART_ICON_MAP,
  POSITION_SIZING_TYPES,
  MAX_ALLOCATION_OBJ,
  qtyRegex,
  // algoDateFormat,
//   renkoParams
} from '../../utils/consts'
import { CONDITION_TYPE } from '../../containers/Create/Create-utils'
import {
  COLORS, ICONS, SPACING, theme,
} from '../../Theme'

// export const maxAllocationRegex = /^[1-9]*\d*$/g

const tabsProps = {
  tabColor: 'VOILET',
  activeTabColor: 'WHITE',
}

// const renderTooltip = (item, chart_type, className) => {
//   return (
//     <div className={className} id="tooltip">
//       <CustomText color="white">{/Renko/i.test(chart_type) ? chart_type : item}</CustomText>
//     </div>
//   )
// }

const CreateParams = (props) => {
  const styles = stylesheet()
  const {
    conditionEditType, create_plus, entryValid, entryString, showError,
    disableActions, isDark, exitValid, exitString, onEditCondition, activeTourIndex,
    isMobile, startTour, changePtComponentStatus, updateGA,
  } = props
  const onEntryEdit = () => {
    onEditCondition('entry')
  }
  const onExitEdit = () => {
    onEditCondition('exit')
  }

  useEffect(() => {
    setTimeout(() => {
      changePtComponentStatus('updatePTStatus')
    }, 1000)
  }, [activeTourIndex])

  const renderTimeInput = (timeProps) => {
    return (
      <div className={classnames(styles.fieldInputContainer, styles.timeInputContainer)}>
        <input {...timeProps} className={classnames(styles.fieldInput, styles.timeInput)} />
        <Icon
          name={ICONS.WAITING}
          color={theme.textSecondary}
          size={12}
          className={styles.inputIcon}
        />
      </div>
    )
  }
  const renderDateInput = (dateProps, stopDate) => {
    return (
      <div
        className={classnames(styles.fieldInputContainer, styles.dateInputContainer,
          stopDate ? styles.stopInputContainer : '')}
      >
        <input {...dateProps} className={classnames(styles.fieldInput, styles.dateInput)} style={{ color: isDark ? 'white' : COLORS.VOILET }} />
        <Icon name={ICONS.CALENDAR} color={COLORS.VOILET} size={12} className={styles.inputIcon} />
      </div>
    )
  }

  const onUpdateGA = () => {
    const gaLabel = activeTourIndex === 6 ? 'Ct Strat Pg 7' : 'Ct Strat Pg 13'
    updateGA('Highlight Click', gaLabel, 1, 'Product Tour')
  }

  return (
    <div className={styles.containerParams}>
      <EditAlgoParams {...props}>
        {({
          chart_list_map,
          holding_type_map,
          candleIntervals,
          strategyCycleMap,
          // array ends
          quantity,
          time_frame,
          dt_start,
          dt_stop,
          initial_capital,
          trading_start_time,
          trading_stop_time,
          fancyChartType, // chart_type,
          chart_type,
          algo_desc,
          quantityErr,
          holding_type,
          max_allocation,
          max_allocationErr,
          max_quantity,
          max_quantityErr,
          tpsl_type,
          daily_strategy_cycle,
          position_sizing_type,
          // state ends
          handleChange,
          onInputChange,
          handleCandleChange,
          setTradingTime,
          setSelectedDateTime,
          chartChangeHandler,
          blockDtStart,
          blockDtStop,
          onEditParams,
          // handler ends
        }) => {
          // const isAmpm = trading_start_time.toLowerCase().includes('m')
          const {
            label: maxAllocationLabel, placeholder: maxAllocationPlaceholder,
          } = MAX_ALLOCATION_OBJ(position_sizing_type)
          const isQtyDisabled = max_allocation.length > 0
          const qtyFieldStyle = isQtyDisabled ? styles.qtyDisabled : styles.qtyInput
          const ptID = activeTourIndex === 13 ? 'tour_create_strategy_14' : ''
          const ptIDSection = !isMobile && activeTourIndex === 6 ? 'tour_create_strategy_7' : ''
          // const maxStartTime = typeof stop_time === 'object'
          //   ? dt_stop.clone().subtract(7, 'days') : dt_stop
          const chartLabelRenderer = ({ item, active }) => {
            const text = /Renko/i.test(chart_type) && /Renko/i.test(item) ? chart_type : item
            return (
              <CustomTooltip
                show
                tooltipProps={{ text }}
              >
                <div className={styles.chartTab}>
                  <Icon
                    name={CHART_ICON_MAP[item]}
                    color={active ? COLORS.WHITE : (isDark ? COLORS.LIGHT_VOILET : COLORS.VOILET)}
                  />
                </div>
              </CustomTooltip>
            )
          }
          const isCnc = holding_type === 'CNC/NRML'
          const disabledMsgCnc = 'Daily strategy cycle cannot be used when holding type is CNC/NRML'
          return (
            <>
              <div
                id={ptIDSection}
                role="button"
                onClick={onUpdateGA}
                tabIndex={0}
                onKeyUp={onUpdateGA}
              >
                {(conditionEditType === CONDITION_TYPE.REVIEW
                || conditionEditType === CONDITION_TYPE.POSITION)
                && (
                  <div className={styles.chartContainer}>
                    <CustomText weight="semi_bold" size="medium">Chart</CustomText>
                    <div className={classnames(styles.content, styles.chartContent)} id="tour_create_strategy_7">
                      <div className={styles.field} style={{ flex: 0.25 }}>
                        <CustomText weight="medium" className={styles.inputLabel}>Chart Type</CustomText>
                        <CustomHorizontalTab
                          tabs={chart_list_map}
                          defaultActiveTab={fancyChartType}
                          tabChangeHandler={chartChangeHandler}
                          style={classnames(styles.tabContainer, styles.chartTypeContainer,
                            chart_list_map.length < 2 ? styles.singleTab : '')}
                          tabStyles={classnames(styles.tab, styles.chartTab)}
                          labelRenderer={chartLabelRenderer}
                          dragBtnStyles={styles.tabActive}
                          disableActiveTab={false}
                          {...tabsProps}
                        />
                      </div>
                      <div className={styles.field} style={{ flex: 0.5 }}>
                        <CustomText weight="medium" className={styles.inputLabel}>Candle Interval</CustomText>
                        <CustomHorizontalTab
                          tabs={candleIntervals}
                          defaultActiveTab={time_frame}
                          tabChangeHandler={handleCandleChange}
                          style={classnames(styles.tabContainer, styles.candleTabContainer)}
                          tabStyles={classnames(styles.tab, styles.candleTab)}
                          dragBtnStyles={styles.tabActive}
                          {...tabsProps}
                        />
                      </div>
                      <div className={styles.field} style={{ flex: 0.25 }}>
                        <CustomText weight="medium" className={styles.inputLabel}>
                          Holding type
                        </CustomText>
                        <CustomHorizontalTab
                          tabs={holding_type_map}
                          defaultActiveTab={holding_type}
                          changeKey="holding_type"
                          tabChangeHandler={handleChange}
                          style={classnames(styles.tabContainer, styles.holdingTabContainer)}
                          tabStyles={classnames(styles.tab)}
                          dragBtnStyles={styles.tabActive}
                          {...tabsProps}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {(conditionEditType === CONDITION_TYPE.REVIEW
                || conditionEditType === CONDITION_TYPE.POSITION)
                && (
                <div className={styles.quantityContainer}>
                  <CustomText weight="semi_bold" size="medium">Quantity</CustomText>
                  <div className={classnames(styles.content, styles.quantityContent)}>
                    <div className={styles.field}>
                      <CustomText weight="medium" className={styles.inputLabel}>Quantity ( in lots )</CustomText>
                      <CustomInput
                        value={quantity}
                        inputError={quantityErr}
                        placeholder="Qty in lots (1 = 1 lot)"
                        onChangeText={onInputChange}
                        inputStyles={`${styles.fieldInput} ${qtyFieldStyle}`}
                        containerStyles={styles.fieldInputContainer}
                        inputKey="quantity"
                        inputRegx={new RegExp(qtyRegex)}
                        disabled={isQtyDisabled}
                      />
                    </div>
                    {create_plus && (
                      <>
                        <div className={styles.field}>
                          <CustomText weight="medium" className={styles.inputLabel}>{maxAllocationLabel}</CustomText>
                          <CustomInput
                            value={max_allocation}
                            placeholder={maxAllocationPlaceholder}
                            onChangeText={onInputChange}
                            inputStyles={styles.fieldInput}
                            inputRegx={new RegExp(qtyRegex)}
                            containerStyles={styles.fieldInputContainer}
                            inputKey="max_allocation"
                            inputError={max_allocationErr}
                          />
                        </div>
                        <div className={styles.field}>
                          <CustomText weight="medium" className={styles.inputLabel}>Max Quantity ( in lots )</CustomText>
                          <CustomInput
                            value={max_quantity}
                            placeholder="Max Quantity in lots (1 = 1 lot)"
                            onChangeText={onInputChange}
                            inputStyles={styles.fieldInput}
                            inputRegx={new RegExp(qtyRegex)}
                            containerStyles={styles.fieldInputContainer}
                            inputKey="max_quantity"
                            inputError={max_quantityErr}
                          />
                        </div>
                        <div className={styles.field}>
                          <CustomText weight="medium" className={styles.inputLabel}>Position sizing type</CustomText>
                          <ListDropdown
                            selected={position_sizing_type}
                            options={POSITION_SIZING_TYPES}
                            changeKey="position_sizing_type"
                            onChangeOption={handleChange}
                            containerStyles={styles.dropDownContainer}
                            inputBtnStyles={classnames(
                              styles.fieldInput, styles.positionSizingInput,
                            )}
                          />
                        </div>
                      </>
                    )}

                  </div>
                </div>
                )}
              </div>
              {(conditionEditType === CONDITION_TYPE.REVIEW)
                && (
                <>
                  <div className={styles.entryExitWrapper}>
                    <EntryExitRenderer
                      addBtnText="Add Entry Conditions here"
                      status={entryValid}
                      condition={entryString}
                      cardTitle="ENTRY"
                      onCardPress={onEntryEdit}
                      titleStyles={styles.entryExitTitle}
                      cardStyles={classnames(styles.entryExitCard, styles.entryCard)}
                      showAddBtn
                      showStatus={showError}
                      disabled={disableActions}
                      key="entry"
                      isDark={isDark}
                    />
                    <EntryExitRenderer
                      addBtnText="Add Exit Conditions here"
                      status={exitValid}
                      condition={exitString}
                      cardTitle="EXIT"
                      showAddBtn
                      showStatus={showError}
                      onCardPress={onExitEdit}
                      titleStyles={styles.entryExitTitle}
                      cardStyles={classnames(styles.entryExitCard, styles.exitCard)}
                      disabled={disableActions}
                      numberOfLines={1}
                      key="exit"
                      isDark={isDark}
                      params={{ tpsl_type }}
                    />
                  </div>
                  <div
                    className={classnames(styles.content, styles.btParamsContainer)}
                    id={isMobile ? '' : 'tour_create_strategy_13'}
                    role="button"
                    onClick={onUpdateGA}
                    tabIndex={0}
                    onKeyUp={onUpdateGA}
                  >
                    <div className={styles.row}>
                      <Icon name={ICONS.PARAMETERS} color={COLORS.VOILET} size={14} />
                      &nbsp;
                      <CustomText weight="semi_bold" size="medium">Backtest Parameters</CustomText>
                    </div>
                    <div className={styles.btParamsContent}>
                      <div className={styles.btParamsRow} id={isMobile ? 'tour_create_strategy_13' : ''}>
                        <div className={classnames(styles.field, styles.capitalField)}>
                          <CustomText weight="medium" className={styles.inputLabel}>Initial Capital</CustomText>
                          <CustomInput
                            value={initial_capital}
                            placeholder="Initial Capital"
                            onChangeText={onInputChange}
                            inputStyles={classnames(styles.fieldInput, styles.capitalInput)}
                            containerStyles={styles.fieldInputContainer}
                            inputKey="initial_capital"
                            inputRegx={new RegExp(qtyRegex)}
                          />
                        </div>
                        {create_plus && (
                        <div className={classnames(styles.field, styles.cycleField)}>
                          <CustomText weight="medium" className={styles.inputLabel}>Daily strategy cycle</CustomText>
                          <ListDropdown
                            selected={daily_strategy_cycle}
                            options={strategyCycleMap}
                            changeKey="daily_strategy_cycle"
                            onChangeOption={handleChange}
                            containerStyles={styles.dropDownContainer}
                            inputBtnStyles={classnames(
                              styles.fieldInput, styles.cycleInput,
                            )}
                            disabled={isCnc}
                            disabledMsg={disabledMsgCnc}
                          />
                        </div>
                        )}
                        <div className={classnames(styles.field, styles.btPeriodField)}>
                          <CustomText weight="medium" className={styles.inputLabel}>Backtesting Period</CustomText>
                          <div className={styles.dateRow}>
                            <DateTimePicker
                              props={{
                                timeFormat: false,
                                closeOnSelect: true,
                                dateFormat: 'DD MMM YYYY',
                                value: dt_start,
                                isValidDate: blockDtStart,
                                renderInput: dateProps => renderDateInput(dateProps),
                                onChange: date => setSelectedDateTime('dt_start', date),
                              }}
                            />
                            <DateTimePicker
                              props={{
                                timeFormat: false,
                                closeOnSelect: true,
                                dateFormat: 'DD MMM YYYY',
                                value: dt_stop,
                                isValidDate: blockDtStop,
                                renderInput: dateProps => renderDateInput(dateProps, true),
                                onChange: date => setSelectedDateTime('dt_stop', date),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={classnames(styles.field, styles.descField)}>
                        <CustomText weight="medium" className={styles.inputLabel}>Strategy Description</CustomText>
                        <CustomInput
                          value={algo_desc}
                          placeholder="Add your strategy description here"
                          onChangeText={onInputChange}
                          inputStyles={classnames(styles.fieldInput, styles.descInput)}
                          multiline
                          inputProps={{
                            rows: 5,
                          }}
                          inputKey="algo_desc"
                        />
                      </div>
                    </div>
                  </div>
                </>
                )}
              {(conditionEditType === CONDITION_TYPE.ENTRY)
                && (

                <div className={styles.entryTimeContainer}>
                  <CustomText weight="semi_bold" size="medium">Entry Time</CustomText>
                  <div className={classnames(styles.content, styles.entryTimeContent)}>
                    <div className={styles.entryTime}>
                      <CustomText weight="medium">Start Time</CustomText>
                      <Datetime
                        dateFormat={false}
                        timeFormat="HH:mm"
                        // timeFormat={isAmpm ? 'hh : mm A' : 'HH:mm'}
                        value={trading_start_time}
                        renderInput={renderTimeInput}
                        onChange={date => setTradingTime('trading_start_time', date)}
                      />
                    </div>

                    <div className={styles.exitTime}>
                      <CustomText weight="medium">End Time</CustomText>
                      <Datetime
                        dateFormat={false}
                        timeFormat="HH:mm"
                        // timeFormat={isAmpm ? 'hh : mm A' : 'HH:mm'}
                        value={trading_stop_time}
                        renderInput={renderTimeInput}
                        onChange={date => setTradingTime('trading_stop_time', date)}
                      />
                    </div>
                  </div>
                </div>
                )}
              {isMobile && startTour ? (
                (
                  <span
                    id={ptID}
                    className={styles.ptMobile}
                    style={{ padding: startTour ? 10 : 0 }}
                  >
                    <Button
                      id={ptID}
                      text={conditionEditType === CONDITION_TYPE.REVIEW ? 'Save and Backtest' : 'Next'}
                      onPress={onEditParams}
                      disabled={disableActions}
                      buttonColor="blue"
                      labelColor="white"
                      mode="contained"
                      roundness={6}
                    />
                  </span>
                )
              )
                : (
                  <div
                    className={styles.actionBar}
                  >
                    <span id={ptID} style={{ padding: startTour ? 10 : 0 }}>
                      <Button
                        id={ptID}
                        text={conditionEditType === CONDITION_TYPE.REVIEW ? 'Save and Backtest' : 'Next'}
                        onPress={onEditParams}
                        disabled={disableActions}
                        buttonColor="blue"
                        labelColor="white"
                        mode="contained"
                        roundness={6}
                      />
                    </span>
                  </div>
                )}
            </>
          )
        }}
      </EditAlgoParams>
    </div>
  )
}

const stylesheet = makeStyles({
  containerParams: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20} ${SPACING.SPACE_24}`,
    background: theme.bgPrimary,
    borderRadius: '10px',
    marginTop: SPACING.SPACE_12,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,

  },
  chartContainer: {
    marginTop: SPACING.SPACE_32,
  },
  chartContent: {
  },
  field: {
    flex: 1,
    marginRight: SPACING.SPACE_20,
    '&:last-child': {
      marginRight: 0,
    },
  },
  quantityContainer: {
    marginTop: SPACING.SPACE_40,
  },
  quantityContent: {
  },
  qtyInput: {
    borderColor: `${theme.createTabsBg} !important`,
    backgroundColor: `${theme.createTabsBg} !important`,
  },
  qtyDisabled: {
    borderColor: `${theme.createTabsBg} !important`,
    backgroundColor: `${theme.btnDisabled} !important`,
    color: theme.textSecondary,
  },
  entryExitWrapper: {
    display: 'flex',
    marginTop: SPACING.SPACE_40,
  },
  btParamsContainer: {
    display: 'block',
    marginTop: SPACING.SPACE_40,
    backgroundColor: theme.bgPrimary,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  btParamsContent: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    marginTop: SPACING.SPACE_12,
  },
  btParamsRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: SPACING.SPACE_20,
  },
  capitalField: {
    flex: 0.25,
  },
  cycleField: {
    flex: 0.25,
    marginRight: SPACING.SPACE_20,
  },
  btPeriodField: {
    flex: 0.5,
  },
  entryTimeContainer: {
    marginTop: SPACING.SPACE_40,
  },
  entryTimeContent: {},
  entryTime: {
    display: 'flex',
    alignItems: 'center',
  },
  exitTime: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldInput: {
    backgroundColor: theme.inputLightBg,
  },
  fieldInputContainer: {
    maxWidth: 200,
  },
  inputLabel: {
    marginBottom: SPACING.SPACE_12,
  },
  positionSizingInput: {
    maxWidth: 240,
    height: 40,
  },
  cycleInput: {
    maxWidth: 240,
  },
  inputIcon: {
    position: 'absolute',
    right: 10,
    zIndex: 1,
  },
  timeInput: {
    border: `1px solid ${theme.inputBorder}`,
    borderRadius: 4,
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    zIndex: '9',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  timeInputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    marginLeft: SPACING.SPACE_20,
  },
  dateInputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: theme.createTabsBg,
    borderRadius: 4,
  },
  dateInput: {
    border: '1px solid',
    color: theme.text,
    borderColor: theme.createTabsBg,
    borderRadius: 4,
    backgroundColor: 'transparent',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    zIndex: '9',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  entryExitCard: {
    width: '50%',
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20} ${SPACING.SPACE_24}`,
  },
  entryCard: {
    marginRight: SPACING.SPACE_20,
  },
  exitCard: {},
  dateRow: {
    display: 'flex',
    alignItems: 'center',
  },
  capitalInput: {
    backgroundColor: theme.inputLightBg,
  },
  tabContainer: {
    backgroundColor: theme.createTabsBg,
    padding: '0 ',
    // display: 'inline-flex',
    // minWidth: '115px',
  },
  holdingTabContainer: {
    maxWidth: 180,
  },
  tabActive: {
    backgroundColor: COLORS.VOILET,
  },
  chartTypeContainer: {
    maxWidth: 150,
  },
  singleTab: {
    maxWidth: 60,
  },
  actionBar: {
    padding: `${SPACING.SPACE_20} 0`,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chartTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
  },
  tab: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_12}`,
  },
  candleTab: {
  },
  stopInputContainer: {
    marginLeft: SPACING.SPACE_20,
  },

  chartTooltip: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    backgroundColor: '#000000c4',
    borderRadius: 6,
    overflow: 'hidden',
    // border: '1px solid #9BABC7',
  },
  descInput: {
    backgroundColor: theme.inputLightBg,
  },
  descField: {
    width: '48%',
    flex: 'unset',
  },
  candleTabContainer: {
    display: 'inline-flex',
  },
  ptMobile: {
    padding: 10,
    float: 'right',
    position: 'relative',
    bottom: 60,
  },
  '@media only screen and (max-width:425px)': {
    ptMobile: {
      bottom: 100,
    },
  },
  '@media only screen and (max-width: 786px)': {
    containerParams: {
      paddingBottom: 60,
    },
    actionBar: {
      position: 'fixed',
      bottom: '55px',
      background: theme.bgTertiary,
      width: '100%',
      left: '0',
      zIndex: 99,
      padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
      '& > button': {
        width: '100%',
      },
    },
    content: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& $field': {
        margin: `0 0 ${SPACING.SPACE_20}`,
        flex: 'auto',
        '&:last-child': {
          margin: 0,
        },
      },
    },
    inputLabel: {
      marginBottom: SPACING.SPACE_8,
    },
    entryExitWrapper: {
      display: 'block',
    },
    entryExitCard: {
      width: '100%',
    },
    entryCard: {
      margin: `0 0 ${SPACING.SPACE_24}`,
    },
    btParamsRow: {
      display: 'block',
      '& $field': {
        margin: `0 0 ${SPACING.SPACE_20}`,
        flex: 'auto',
        '&:last-child': {
          margin: 0,
        },
      },
    },
    dateRow: {
      display: 'block',
    },
    stopInputContainer: {
      margin: `${SPACING.SPACE_10} 0 0`,
    },
    entryTime: {
      '& > p': {
        width: '40%',
      },
    },
    exitTime: {
      margin: `${SPACING.SPACE_20} 0 0 0`,
      '& > p': {
        width: '40%',
      },
    },
    tabBtn: {
      padding: `${SPACING.SPACE_8} ${SPACING.SPACE_16}`,
    },
    fieldInput: {
      width: '100%',
    },
    tabContainer: {
      flexWrap: 'wrap',
    },
    field: {
      width: '100%',
    },
    fieldInputContainer: {
      maxWidth: 'unset',
    },
    timeInputContainer: {
      margin: 0,
      width: '100%',
    },
    positionSizingInput: {
      maxWidth: 'unset',
    },
    cycleInput: {
      maxWidth: 'unset',
    },
  },
  '@media only screen and (max-width: 526px)': {
    content: {
    },
    entryTime: {
      width: '100%',
    },
    exitTime: {
      width: '100%',
    },
  },
})

export default CreateParams
