import {
  FETCH_ALGO_INIT,
  FETCH_ALGO_SUCCESS,
  FETCH_ALGO_FAILURE,
  DELETE_ALGO_INIT,
  DELETE_ALGO_SUCCESS,
  DELETE_ALGO_FAILURE,
  CLEAR_ALGO_RESP,
  CLEAR_ALGO_DATA,
  FETCH_BACKTEST_GRAPH,
  FETCH_BACKTEST_GRAPH_SUCCESS,
  FETCH_BACKTEST_GRAPH_FAILURE,
  UPDATE_SORT_ON,
  FILTER_ALGO_INIT,
  FILTER_ALGO_SUCCESS,
  FILTER_ALGO_FAILURE,
} from './actionTypes'

export function fetchAlgos(params, detailed) {
  return {
    type: FETCH_ALGO_INIT,
    params,
    detailed,
  }
}

export function fetcAlgosSuccess(data, kind, detailed, params) {
  return {
    type: FETCH_ALGO_SUCCESS,
    data,
    kind,
    detailed,
    params,
  }
}

export function fetchAlgosFailure(error, detailed) {
  return {
    type: FETCH_ALGO_FAILURE,
    error,
    detailed,
  }
}

export function deleteAlgo(params, headers) {
  return {
    type: DELETE_ALGO_INIT,
    params,
    headers,
  }
}

export function deleteAlgoSuccess(data, deletedAlgoId) {
  return {
    type: DELETE_ALGO_SUCCESS,
    data,
    deletedAlgoId,
  }
}

export function deleteAlgoFailure(error) {
  return {
    type: DELETE_ALGO_FAILURE,
    error,
  }
}

export function clearAlgoResp() {
  return {
    type: CLEAR_ALGO_RESP,
  }
}

export function clearAlgoData(detailed) {
  return {
    type: CLEAR_ALGO_DATA,
    detailed,
  }
}

export function fetchBacktestGraph(params, headers) {
  return {
    type: FETCH_BACKTEST_GRAPH,
    params,
    headers,
  }
}

export function fetchBacktestGraphSuccess(data, id) {
  return {
    type: FETCH_BACKTEST_GRAPH_SUCCESS,
    data,
    id,
  }
}

export function fetchBacktestGraphFailure(error, id) {
  return {
    type: FETCH_BACKTEST_GRAPH_FAILURE,
    error,
    id,
  }
}

export function updateSortOn(sortOn) {
  return {
    type: UPDATE_SORT_ON,
    sortOn,
  }
}

export function filterAlgos(params, headers, fetchAlgoPayload) {
  return {
    type: FILTER_ALGO_INIT,
    params,
    headers,
    fetchAlgoPayload,
  }
}

export function filterAlgosSuccess(msg, data) {
  return {
    type: FILTER_ALGO_SUCCESS,
    msg,
    data,
  }
}

export function filterAlgosFailure(error) {
  return {
    type: FILTER_ALGO_FAILURE,
    error,
  }
}
