/* eslint-disable no-unused-vars */
import {
  takeLatest,
  put,
  call,
} from 'redux-saga/effects'
import {
  GET_PAYMENT_INFO_INIT,
} from './actionTypes'
import {
  getPaymentInfoSuccess,
  getPaymentInfoFailure,
} from './actions'
import {
  prodEndpoints, baseUrl, wpBaseUrl, wpEndpoints,
} from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'

function* fetchPaymentInfo({ params }) {
  try{
    const url = `${wpBaseUrl}${wpEndpoints.pages}`
    const response = yield call(getRequest, url, params)
    if(!response.error && response.length > 0) {
      yield put(getPaymentInfoSuccess(response))
    }else{
      yield put(getPaymentInfoFailure(response.error_msg))
    }
  }catch(err) {
    yield put(getPaymentInfoFailure(err))
  }
}

export default function* fetchPaymentInfoSaga() {
  yield takeLatest(GET_PAYMENT_INFO_INIT, fetchPaymentInfo)
}
