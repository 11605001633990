import { connect } from 'react-redux'

import SymbolFinder from './SymbolFinder'

import { pairsFromSegSym } from '../../containers/Instruments/actions'
import { getStocks, getDefaultStocks } from '../../containers/Create/actions'
import { getWatchList } from '../../containers/Marketwatch/actions'
import {
  changePtComponentStatus, toggleAuthGreeting, updateTourIndex,
} from '../../containers/Common/actions'

const mapStateToProps = (state) => {
  return {
    ...state.auth.loginResp,
    user_details: state.auth.user_details,

    stocks: state.create.stocks,
    isFetchingStocks: state.create.isFetchingStocks,
    stocksError: state.create.stocksError,

    market_watch_names_list: state.market.market_watch_names_list,
    market_watches: state.market.market_watches,
    activeTourIndex: state.common.activeTourIndex,
    tourType: state.common.tourType,
    startTour: state.common.startTour,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStocks: (
      params, headers, user_uuid, baskets, exchanges,
    ) => dispatch(getStocks(params, headers, user_uuid, baskets, exchanges)),
    getDefaultStocks: params => dispatch(getDefaultStocks(params)),
    getWatchList: (params, headers) => dispatch(getWatchList(params, headers)),
    pairsFromSegSym: (pairs, screenName) => pairsFromSegSym(dispatch, pairs, screenName),
    toggleAuthGreeting: (modalType, routeAfterActivation) => dispatch(
      toggleAuthGreeting(modalType, routeAfterActivation),
    ),
    changePtComponentStatus: status => dispatch(changePtComponentStatus(status)),
    updateTourIndex: index => dispatch(updateTourIndex(index)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SymbolFinder)
