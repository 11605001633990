import classnames from 'classnames'
import React from 'react'

const Switch = ({
  value,
  onValueChange,
  params,
  id = 'switch',
}) => {
  return (
    <label className={classnames('cSwitchLabel')} htmlFor={`${id}_switch`}>
      <input
        className={classnames('cSwitchInput')}
        type="checkbox"
        id={`${id}_switch`}
        checked={Boolean(value)}
        onChange={() => onValueChange(!value, params)}
      />
      <span className={classnames('cSwitchSlider')} />
    </label>
  )
}

export default Switch
