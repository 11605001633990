import React from 'react'
import Button from '../../UI/Button'

import HOME_ASSETS from '../assets'

const GetStarted = React.memo(({
  onPressDemo, isMobile, updateGA,
}) => {
  return (
  // <div className="footerWrapper">
  //   <div className="footerContainer">
  //     <div className="footerLeftContent">
  //       {!isMobile && (
  //       <h1 className="footerTitle">
  //         We’ll help you get started
  //       </h1>
  //       )}
  //       <h3 className="footerSubTitle">
  //         Schedule your demo with us Its free.
  //       </h3>
  //       <Button
  //         buttonColor="blue"
  //         text="Get Demo"
  //         btnStyles="getStartedBtFooter"
  //         labelSize={isMobile ? 'large' : 'medium'}
  //         onPress={onPressDemo}
  //       />
  //     </div>
  //     <div className="bannerRightContent">
  //     <img src={HOME_ASSETS.FooterIllustration}
  // alt="banner" className="footerImg" style={{ maxWidth: '100%' }} />
  //     </div>
  //   </div>
  // </div>

    <div className="getStartedContainer">
      <h1 className="startedTitle" style={{ fontSize: `${isMobile && '30px'}` }}>
        We&apos;ll help you get started
      </h1>
      <Button
        text="Get Started"
        btnStyles="getStartedBtFooter"
        mode="text"
        buttonColor="START_FREE_TRIAL_BUTTON"
        labelColor="white"
        labelStyle="getStartedLabelFooter"
        onPress={() => {
          updateGA('Free Demo', 'Free Demo', 1, 'Landing Page')
          onPressDemo()
        }}
      />
      <img src={HOME_ASSETS.footerMountains} alt="getStartedbg" className="getStartedbg" />
    </div>
  )
})

export default GetStarted
