import React from 'react'
import { connect } from 'react-redux'

import GetPNL from '../../UI/GetPNL'

import { COMMODITY_MULTIPLIER } from '../../utils/consts'
import { DEPLOYED_TABS } from '../../containers/Deployed/deployedUtils'

export const calculateDeployedPNL = ({
  activeTabKey, last_price, algo,
}) => {
  let pnl
  const {
    last_order_average_price, segment, multiply_quantity, realizedPnl, comm,
  } = algo
  if (activeTabKey === DEPLOYED_TABS.ENTERED.KEY) {
    const precision = segment === 'CDS-FUT' ? 4 : 2

    pnl = (((last_price - last_order_average_price) * multiply_quantity) + realizedPnl)
      .toFixed(precision)
  } else {
    pnl = realizedPnl.toFixed(2)
    if (segment === 'CDS-FUT') {
      pnl = (realizedPnl * 1000).toFixed(4)
    }
    if (segment === 'MCX') {
      // const comm = symbol.split(/[0-9]/)[0]
      pnl = realizedPnl * COMMODITY_MULTIPLIER[comm]
      pnl = pnl.toFixed(2)
    }
  }
  return pnl
}

const GetDeployedPnl = React.memo(({
  realizedPnl,
  activeTabKey,
  last_order_average_price,
  multiply_quantity,
  last_price,
  segment,
  comm,
  pnlProps = {},
  showNA,
}) => {
  // console.log(realizedPnl, activeTabKey, last_order_average_price,
  // multiply_quantity, last_price, 'lol last_price')
  // calculated pnl based on if algo is entered or not

  const pnlValue = calculateDeployedPNL({
    algo: {
      realizedPnl,
      activeTabKey,
      last_order_average_price,
      multiply_quantity,
      comm,
      segment,
    },
    last_price,
    activeTabKey,
  })
  // console.log(pnlValue, showNA, last_price, 'lol prie')
  return <GetPNL pnl={pnlValue} showNA={showNA} {...pnlProps} showSign />
})

const mapStateToProps = (state, ownProps) => {
  const { segment_symbol: seg_sym } = ownProps
  const { seg_sym_list, ticks } = state.instruments
  const token = seg_sym_list[seg_sym]
  const tick = ticks[token] || {}
  const { last_price = 0.0, change = 0.0 } = tick
  return {
    last_price,
    change,
  }
}

export default connect(mapStateToProps, null)(GetDeployedPnl)
