import React, { PureComponent } from 'react'

import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import ModalDropdown from '../../UI/Dropdown/ModalDropdown'
import EditName from '../../UI/EditName'
import DeletePromptModal from '../../UI/DeletePromptModal'
import CreateNewList from '../../UI/CreateNewList'
import CustomModal from '../../UI/CustomModal'
import Button from '../../UI/Button'
import { showSnackbar } from '../../UI/Snackbar'

import {
  ICONS, COLORS, SPACING, theme,
} from '../../Theme'
import { NAVIGATIONS, pathname_mapping } from '../../utils/navigationConstant'
import { FAVOURITE_PARAMS, shareModalOptions } from '../../utils/consts'
import { ALGO_KEY_MAP } from '../AlgoCondensedView/AlgoUtils'
import { webBaseUrl } from '../../utils/apiEndpoints'
import { withTheme } from '../../Theme/ThemeProvider'
import { CONDITION_TYPE } from '../../containers/Create/Create-utils'
import SharePopUp from '../../UI/SharePopUp'
import { AUTH_ACTIVE_STATE_MAP } from '../../containers/Auth/Auth'
import DirectLogin from '../../homepage/components/DirectLogin'
import { setCookie } from '../../utils/common'
import { routeToCreate } from '../../utils/experimenting'

class AlgoInfo extends PureComponent {
  constructor(props) {
    super(props)
    const {
      algo_desc = '', getFavorite, voted, downvotes = 0, upvotes = 0,
      exitStr = '', entryStr = '',
    } = props
    const algoDesc = entryStr && !algo_desc ? `${entryStr} ${exitStr ? 'and ' : ''}${exitStr}` : algo_desc
    this.state = {
      readMore: false,
      upvoted: voted,
      downvotes,
      upvotes,
      // shortDesc: Boolean(algoDesc),
      algoDesc,
      showNameChange: false,
      showDeleteModal: false,
      showReadMore: false,
      showShareModal: false,
      showLogin: false,
      // showPrivateDialog: false,
    }
    this.favDelete = false
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const ctb = atob(urlParams.get('ctb'))

    if (!ctb) {
      getFavorite({})
    }
    this.copyAlgo = false
    this.descRef = React.createRef()
    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.shouldShowReadMore()
  }

  componentDidUpdate(prevProps) {
    const {
      markFavoriteResp,
      markFavoriteError,
      markFavoriteErrorMsg,
      isMarkingFavorite,
      getFavorite,
      isDeleting,
      deleteAlgoResp,
      deleteAlgoError,
      deleteAlgoErrorMsg,
      publicationStatsResp,
      isUpdatingPublicationStats,
      // parentId,
      algo_desc,
      publicationStatsError,
      publicationStatsErrorMsg,
      copyAlgoResp,
      isCopyingAlgo,
      copyAlgoError,
      copyAlgoErrorMsg,
      fetchBacktests,
      isFocused,
      entryStr,
      exitStr,
      history,
      algoIdKey,
      refreshAlgos,
      updateGA,
      gaLabel,
      gaCategory,
    } = this.props
    if (isFocused) {
      if (prevProps.isMarkingFavorite !== isMarkingFavorite && !isMarkingFavorite) {
        if (markFavoriteResp.status === 'success') {
          const msg = this.favDelete ? 'Strategy removed from favourites' : 'Strategy added to favourites'
          showSnackbar(msg, {}, 1)
          this.favDelete = false
          getFavorite({})
        } else if (markFavoriteError) {
          showSnackbar(markFavoriteErrorMsg, {}, 0)
        }
      }
      // if (prevProps.isDeleting !== isDeleting && !isDeleting) {
      //   if (deleteAlgoResp.status === 'success') {
      //     // if (parentId) {
      //     refreshAlgos('algos', true)
      //     history.replace({
      //       pathname: pathname_mapping.Strategies,
      //     })
      //   } else if (deleteAlgoError) {
      //     showSnackbar(deleteAlgoErrorMsg, {}, 0)
      //   }
      // }
      if (prevProps.isUpdatingPublicationStats !== isUpdatingPublicationStats
          && !isUpdatingPublicationStats) {
        if (publicationStatsResp.status === 'success') {
          const { upvotes, downvotes } = publicationStatsResp
          const upvoted = this.interaction_action === 'upvote' ? true : this.interaction_action === 'downvote' ? false : null
          this.setState({ upvotes, downvotes, upvoted })
          this.interaction_action = null
        } else if (publicationStatsError) {
          showSnackbar(publicationStatsErrorMsg, {}, 0)
        }
      }
      if (algo_desc !== prevProps.algo_desc && algo_desc) {
        this.shouldShowReadMore()
      }
      if (!algo_desc && (exitStr !== prevProps.exitStr || entryStr !== prevProps.entryStr)) {
        const algoDesc = entryStr && !algo_desc ? `${entryStr} ${exitStr ? 'and ' : ''}${exitStr}` : ''
        this.shouldShowReadMore()
        this.setState({ algoDesc })
      }
      // if (isCopyingAlgo !== prevProps.isCopyingAlgo && !isCopyingAlgo) {
      //   if (copyAlgoResp && copyAlgoResp.algo_uuid) {
      //     fetchBacktests({ algo_uuid: copyAlgoResp.algo_uuid })
      //     this.toggleNameChangeModal()
      //     if(algoIdKey === ALGO_KEY_MAP.SHARED_ALGOS) {
      //       updateGA('Copy', `${gaLabel} options`, 1, gaCategory)
      //       routeToCreate({
      //         history,
      //         search: `?id=${btoa(copyAlgoResp.algo_uuid)}&edit=
      // ${btoa(true)}&type=${btoa(CONDITION_TYPE.REVIEW)}`,
      //         id: copyAlgoResp.algo_uuid,
      //       })
      //       // history.replace({
      //       //   pathname: pathname_mapping.Create,
      //       //   search: `?id=${btoa(copyAlgoResp.algo_uuid)}
      // &type=${btoa(CONDITION_TYPE.REVIEW)}`,
      //       // })
      //     } else {
      //       updateGA('Copy', `${gaLabel} options`, 1, gaCategory)
      //       history.replace({
      //         pathname: pathname_mapping.Backtests,
      //         search: `?algo_uuid=${btoa(copyAlgoResp.algo_uuid)}`,
      //       })
      //     }
      //   } else if (copyAlgoError) {
      //     showSnackbar(copyAlgoErrorMsg || 'Some Error occurred', {}, 0)
      //   }
      // }
    }
    if (isCopyingAlgo !== prevProps.isCopyingAlgo && !isCopyingAlgo) {
      if (copyAlgoResp && copyAlgoResp.algo_uuid) {
        fetchBacktests({ algo_uuid: copyAlgoResp.algo_uuid })
        this.toggleNameChangeModal()
        if(algoIdKey === ALGO_KEY_MAP.SHARED_ALGOS) {
          updateGA('Copy', `${gaLabel} options`, 1, gaCategory)
          routeToCreate({
            history,
            search: `?id=${btoa(copyAlgoResp.algo_uuid)}&edit=${btoa(true)}&type=${btoa(CONDITION_TYPE.REVIEW)}`,
            id: copyAlgoResp.algo_uuid,
          })
          // history.replace({
          //   pathname: pathname_mapping.Create,
          //   search: `?id=${btoa(copyAlgoResp.algo_uuid)}&type=${btoa(CONDITION_TYPE.REVIEW)}`,
          // })
        } else {
          updateGA('Copy', `${gaLabel} options`, 1, gaCategory)
          history.replace({
            pathname: pathname_mapping.Backtests,
            search: `?algo_uuid=${btoa(copyAlgoResp.algo_uuid)}`,
          })
        }
      } else if (copyAlgoError) {
        showSnackbar(copyAlgoErrorMsg || 'Some Error occurred', {}, 0)
      }
    }
    if (prevProps.isDeleting !== isDeleting && !isDeleting) {
      if (deleteAlgoResp.status === 'success') {
        // if (parentId) {
        refreshAlgos('algos', true)
        history.replace({
          pathname: pathname_mapping.Strategies,
        })
      } else if (deleteAlgoError) {
        showSnackbar(deleteAlgoErrorMsg, {}, 0)
      }
    }
  }

  shouldShowReadMore = () => {
    if (this.descRef.current) {
      if (this.descRef.current.scrollWidth <= this.containerRef.current.offsetWidth) {
        this.setState({ showReadMore: false })
      } else {
        this.setState({ showReadMore: true })
      }
    }
  }

  onEditPress = () => {
    const {
      algoIdKey, algoId, navigation, editSubscribedAlgo,
    } = this.props
    if (algoIdKey === ALGO_KEY_MAP.SUBSCRIBED) {
      editSubscribedAlgo()
      return
    }
    navigation.navigate(NAVIGATIONS.CREATE.name, { [algoIdKey]: algoId, edit: true })
  }

  toggleReadMore = () => {
    this.setState(prevState => ({ readMore: !prevState.readMore }))
  }

  deleteAlgo = () => {
    const {
      algoId, csrf, deleteAlgo, isDeleting,
    } = this.props
    if (isDeleting) return
    const params = { algo_uuid: algoId, csrfmiddlewaretoken: csrf }
    deleteAlgo(params)
    this.toggleModal('showDeleteModal')
  };

  toggleLike = () => {}

  toggleFavourite = (isFavourite) => {
    const {
      csrf, markFavorite, algo_uuid, algoIdKey, isMarkingFavorite, algoId,
      favorites,
    } = this.props
    if (isMarkingFavorite) return
    const favourites = favorites[FAVOURITE_PARAMS[algoIdKey].filter] || {}
    const params = {
      ...FAVOURITE_PARAMS[algoIdKey],
      csrfmiddlewaretoken: csrf,
      sid: algo_uuid,
      // for discover algo_uuid is present in backtest result which might not come sometime
    }
    if (isFavourite) {
      params.del = true
      params.sid = favourites[algo_uuid] ? algo_uuid : algoId
      this.favDelete = true
    }
    markFavorite(params)
  }

  onShare = () => {
    const { user_details, algoId } = this.props
    if (!algoId) return
    const shareOptions = {
      ...shareModalOptions,
      url: `${webBaseUrl}marketplace_backtest?id=${algoId}&subscribed=false`,
      email: (user_details?.email) || '',
    }
    console.log(shareOptions, 'lol share')
    //   Share.open(shareOptions)
  }

  renderHeaderMenuBtn = () => {
    const { styles, menuBtnStyles } = this.props
    return (
      <div className={`${styles.menuBtn} ${menuBtnStyles}`}>
        <Icon name={ICONS.THREE_DOTS_VERTICAL} color={theme.textSecondary} size={15} />
      </div>
    )
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  actionHandler = (actionType) => {
    const {
      history, disabled, algo_uuid, downloadBtResult, downloadAllTD,
      isLoggedIn, toggleAuthModal,
    } = this.props
    if (disabled) return
    switch(actionType) {
      case 'copy': {
        // for my algos. For my picks it is cloning which is in backtestSubscribed
        if(!isLoggedIn) {
          toggleAuthModal(true, false, true)
        } else {
          this.toggleNameChangeModal()
        }
        // const { algoId, algoIdKey, navigation } = this.props
        // navigation.navigate(NAVIGATIONS.CREATE.name, { [algoIdKey]: algoId, copy: true })
        break
      }

      case 'delete': {
        this.toggleModal('showDeleteModal')
        break
      }

      case 'edit': {
        routeToCreate({
          history,
          search: `?id=${btoa(algo_uuid)}&edit=${btoa(true)}`,
          id: algo_uuid,
          routeParams: {
            parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algo_uuid)}`,
          },
        })
        // history.push({
        //   pathname: pathname_mapping.Create,
        //   search: `?id=${btoa(algo_uuid)}&edit=${btoa(true)}`,
        // }, { parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algo_uuid)}` })
        break
      }
      case 'exportbt': {
        downloadBtResult()
        break
      }
      case 'alltrancdetails': {
        downloadAllTD()
        break
      }
      default:
        break
    }
  }

  copyHandler = () => {
    // for my algos. For my picks it is cloning which is in backtestSubscribed
    // this.toggleNameChangeModal()
    // const { algoId, algoIdKey, navigation } = this.props
    // navigation.navigate(NAVIGATIONS.CREATE.name, { [algoIdKey]: algoId, copy: true })
    const { isSharedPublic, isLoggedIn, algoIdKey } = this.props
    if(algoIdKey === ALGO_KEY_MAP.SHARED_ALGOS) {
      if(!isLoggedIn) {
        setCookie({
          savedPathname: window.location.pathname,
          pathParams: window.location.search,
        })
        this.setState({ showLogin: true })
      } else if(isSharedPublic) {
        this.toggleNameChangeModal()
      } else {
        alert('private strategy')
      }
    } else {
      this.toggleNameChangeModal()
    }
  }

  updatePublicationStats = (interaction_action) => {
    const {
      csrf, updatePublicationStats, publishing_uuid, isUpdatingPublicationStats,
    } = this.props
    if (isUpdatingPublicationStats) return
    updatePublicationStats({
      csrfmiddlewaretoken: csrf,
      interaction_type: 'vote',
      publishing_uuid,
      element_type: 'published_algo',
      interaction_action,
    })
    this.interaction_action = interaction_action
    // this.setState({ upvoted: interaction_action === 'upvote' })
  }

  renderStatsCell = (icon, count, active, value, onPress, isDark) => {
    const { styles } = this.props
    let color = isDark ? 'BLACK_600' : 'text'
    let iconColor = theme.textSecondary
    const blueColor = isDark ? 'BLUE_300' : 'BLUE'
    if (active) {
      color = blueColor
      iconColor = COLORS[blueColor]
    }
    return (
      <button
        type="button"
        onClick={() => { if (onPress) onPress(value) }}
        disabled={!onPress}
        className={styles.statsCellBtn}
      >
        <Icon name={icon} color={iconColor} size={16} />
        <CustomText color={color} style={{ marginLeft: SPACING.SPACE_8 }}>{`   ${count}`}</CustomText>
      </button>
    )
  }

  toggleNameChangeModal = () => {
    this.setState(prevState => ({ showNameChange: !prevState.showNameChange }))
  }

  nameChangeHandler = ({ listName: algo_name }) => {
    const {
      copyAlgo, isCopyingAlgo, algoIdKey, algoId, csrf, shared,
    } = this.props
    if (algo_name && !isCopyingAlgo && algoIdKey === ALGO_KEY_MAP.MY_ALGOS) {
      this.copyAlgo = true
      copyAlgo({ algo_name, algo_uuid: algoId, csrfmiddlewaretoken: csrf })
    } else if (algo_name && !isCopyingAlgo && algoIdKey === ALGO_KEY_MAP.SHARED_ALGOS) {
      this.copyAlgo = true
      if(shared) {
        copyAlgo({ algo_name, algo_share_uuid: algoId, csrfmiddlewaretoken: csrf })
      } else {
        copyAlgo({ algo_name, backtest_share_uuid: algoId, csrfmiddlewaretoken: csrf })
      }
    }
  }

  onNameChange = (algo_name) => {
    const {
      saveBacktestParams, csrf, algoId, algoIdKey, isSavingBacktestParams,
      updateGA, gaLabel, gaCategory,
    } = this.props
    if (isSavingBacktestParams) return
    updateGA('Edit name', `${gaLabel} options`, 1, gaCategory)
    saveBacktestParams({
      csrfmiddlewaretoken: csrf,
      algo_name,
      [algoIdKey]: algoId,
    })
  }

  onEditHandler = () => {
    const {
      algo_uuid, history, canEditAlgo, updateGA, scripList,
    } = this.props
    if (!canEditAlgo) {
      showSnackbar('Cannot edit a deployed strategy')
      return
    }
    updateGA('Edit strategy', 'Edit strategy button')
    routeToCreate({
      history,
      search: `?id=${btoa(algo_uuid)}&edit=${btoa(true)}&type=${btoa(CONDITION_TYPE.REVIEW)}`,
      id: algo_uuid,
      routeParams: {
        parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algo_uuid)}`,
        gaType: 'Edit',
        stocks: scripList,
      },
    })

    // history.push({
    //   pathname: pathname_mapping.Create,
    //   search: `?id=${btoa(algo_uuid)}&edit=${btoa(true)}&type=${btoa(CONDITION_TYPE.REVIEW)}`,
    // }, { parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algo_uuid)}`,
    // gaType: 'Edit' })
  }

  openShareDialog = () => {
    const { showShareModal } = this.state
    if(showShareModal) {
      this.setState({ showShareModal: false })
    } else {
      this.setState({ showShareModal: true })
    }
  }

  showLoginModal = () => {
    const { showLogin } = this.state
    if(showLogin) {
      this.setState({ showLogin: false })
    } else {
      this.setState({ showLogin: true })
    }
  }

  render() {
    const {
      monthly_pricing = '',
      algo_name = '',
      showSocialStats,
      favorites,
      algoId,
      algo_uuid,
      menuOptions = [],
      canEditAlgo,
      view_count = 0,
      algoIdKey,
      isCopyingAlgo,
      isDark,
      isMobile,
      styles,
      scrolled,
      selectedItem,
      domainChange,
    } = this.props
    const {
      upvoted, downvotes, upvotes, readMore,
      showDeleteModal, algoDesc, showNameChange, showReadMore, showLogin,
      showShareModal,
      // showPrivateDialog,
    } = this.state
    const favourites = favorites[FAVOURITE_PARAMS[algoIdKey].filter] || {}
    const isFavourite = Boolean(favourites[algo_uuid] || favourites[algoId])
    const [price, priceSub] = monthly_pricing.toString().split('.')
    let favColor = 'GREY_100'
    const iconName = 'FAVOURITE' // : 'UN_FAVOURITE'
    let blueColor = 'BLUE'
    if (isDark) {
      favColor = 'BLACK_600'
      // iconName = 'FAVOURITE'
      blueColor = 'BLUE_300'
    }
    const editAllowed = algoIdKey === ALGO_KEY_MAP.MY_ALGOS
    return (
      <>
        <div className={styles.container} ref={this.containerRef}>
          <CustomModal
            visible={showNameChange}
            dismissKey="showNameChange"
            animationType="fade"
            onDismiss={this.toggleNameChangeModal}
          >
            <CreateNewList
              onDismiss={this.toggleNameChangeModal}
              dismissKey="showNameChange"
              title="Copy Strategy As"
              inputPlaceholder="Strategy Name"
              btnText="Copy"
              onCreate={this.nameChangeHandler}
              visible={showNameChange}
              loading={isCopyingAlgo}
            />
          </CustomModal>
          <CustomModal
            visible={showLogin}
            animationType="fade"
            onDismiss={this.showLoginModal}
            dismissKey="showLogin"
            containerStyles={styles.loginModal}
          >
            <DirectLogin
              domainChange={domainChange}
              activeState={AUTH_ACTIVE_STATE_MAP.LOGIN}
              sharedLogin
            />
          </CustomModal>
          <CustomModal
            visible={showShareModal}
            animationType="fade"
            onDismiss={this.openShareDialog}
            dismissKey="showShare"
            containerStyles={styles.modalContainer}
          >
            <SharePopUp
              onDismiss={this.openShareDialog}
              reShare
              item={selectedItem}
              algo_uuid={algo_uuid}
            />
          </CustomModal>
          <DeletePromptModal
            onDelete={this.deleteAlgo}
            visible={showDeleteModal}
            onDismiss={this.toggleModal}
            dismissKey="showDeleteModal"
            typeText="Strategy"
          />
          {/* <InfoModal
          name={algo_name}
          desc={algo_desc}
          onEditPress={this.onEditPress}
          visible={readMore}
          toggleVisible={this.toggleReadMore}
          showEditIcon={!!canEditAlgo}
        /> */}
          <div className={styles.header}>
            <EditName
              defaultName={algo_name}
              onSubmit={this.onNameChange}
              disabled={!canEditAlgo || (algoIdKey === ALGO_KEY_MAP.SHARED_ALGOS)}
              btnStyles={styles.algoNameBtn}
              containerStyles={canEditAlgo ? styles.algoNameContainer : ''}
            >
              <button
                type="button"
                onClick={() => this.toggleFavourite(isFavourite)}
                className={styles.favBtn}
              >
                <Icon
                  name={ICONS[iconName]}
                  color={COLORS[isFavourite ? 'ORANGE' : favColor]}
                  size={14}
                />
              </button>
              {!isMobile && editAllowed && (
              <Button
                mode="outlined"
                text="Edit Strategy"
                onPress={this.onEditHandler}
                btnStyles={styles.editBtn}
                buttonColor={isDark ? 'blue_300' : 'blue'}
              />
              )}
            </EditName>
          </div>
          {monthly_pricing ? (
            <CustomText color="orange">
              {`$ ${price}`}
              <CustomText weight="regular" color="orange">{`.${priceSub}`}</CustomText>
            </CustomText>
          ) : null}
          {algoDesc ? (
            <CustomText textRef={this.descRef} weight="regular" color="textGrey" className={`${styles.desc} ${readMore ? '' : styles.shortDesc}`}>
              {algoDesc}
              {showReadMore && (
              <button
                type="button"
                onClick={this.toggleReadMore}
                style={{ display: 'block' }}
              >
                <CustomText
                  color={blueColor}
                  weight="semi_bold"
                >
                  {readMore ? 'Read Less' : 'Read More'}
                </CustomText>
              </button>
              )}
            </CustomText>
          ) : null}
          {showSocialStats && (
          <div className={styles.statsRow}>
            <div className={styles.socialStats}>
              {this.renderStatsCell(ICONS.VIEW, view_count, true, null, null, isDark)}
              {this.renderStatsCell(
                ICONS.LIKE, upvotes, upvoted === true, upvoted === true ? 'remove_upvote' : 'upvote', this.updatePublicationStats, isDark,
              )}
              {this.renderStatsCell(
                ICONS.DISLIKE, downvotes, upvoted === false,
                upvoted === false ? 'remove_downvote' : 'downvote', this.updatePublicationStats, isDark,
              )}
            </div>
            {/* <button
              type="button"
              onClick={this.onShare}
              className={styles.shareBtnView}
            >
              <Icon
                name={ICONS.SHARE}
                color={COLORS[blueColor]}
                size={14}
                style={{ marginRight: SPACING.SPACE_6 }}
              />
              <CustomText color={blueColor}>Share</CustomText>
            </button> */}
          </div>
          )}
        </div>
        {/* <div>
          <Button
            buttonColor="blue"
            mode="outlined"
            text="Share"
          // iconName="SHARE"
            btnStyles={styles.shareStockBtn}
            labelStyle={styles.shareStockBtnText}
            iconProps={{
              color: COLORS.BLUE,
            }}
            // labelColor={theme.linkColor}
            // onPress={showModal}
          />
        </div> */}
        {menuOptions.length ? (
          <ModalDropdown
            inputRenderer={this.renderHeaderMenuBtn}
            options={menuOptions}
            onChangeOption={this.actionHandler}
            containerStyles={`${styles.menuContainer} ${scrolled ? styles.menuContainerScrolled : ''}`}
          />
        ) : null}
      </>
    )
  }
}

const stylesheet = ({
  container: {
    marginBottom: SPACING.SPACE_20,
    marginTop: SPACING.SPACE_2,
    verticalAlign: 'top',
    display: 'inline-block',
    width: 'calc(100% - 26px)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  algoNameContainer: {
    '& $algoNameBtn': {
      '&:hover': {
        '& + $favBtn': {
          opacity: 0,
        },
      },
    },

  },
  algoNameBtn: {},
  favBtn: {
    marginLeft: SPACING.SPACE_10,
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'all ease-in 200ms',
  },
  statsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${theme.borderColor}`,
    padding: '10px 0px',
    marginTop: SPACING.SPACE_20,
  },
  loginModal: {
    width: 'auto',
    padding: '0px 0px',
    borderRadius: '27px',
  },
  socialStats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statsCellBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${SPACING.SPACE_5} ${SPACING.SPACE_16}`,
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  shareBtnView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  shareBtn: {
    width: 80,
    paddingVertical: 3,
    borderRadius: 6,
    paddingHorizontal: 10,
  },
  shareBtnText: {
  },
  desc: {
    marginTop: SPACING.SPACE_8,
    marginRight: SPACING.SPACE_8,
  },
  shortDesc: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
  menuBtn: {
    width: 25,
    alignItems: 'center',
    '&:hover svg': {
      fill: theme.linkColor,
    },
  },
  shareStockBtn: {
    width: 'max-content',
  },
  editBtn: {
    minWidth: '104px',
    marginLeft: SPACING.SPACE_20,
    padding: `${SPACING.SPACE_2} ${SPACING.SPACE_10}`,
    verticalAlign: 'bottom',
  },
  copyBtn: {
    marginLeft: SPACING.SPACE_20,
  },
  menuContainer: {
    display: 'inline-block',
    verticalAlign: 'text-top',
    marginTop: SPACING.SPACE_4,
  },
  menuContainerScrolled: {
    position: 'sticky',
    top: 76,
    zIndex: 9999,
  },
  '@media only screen and (max-width: 786px)': {
    menuContainerScrolled: {
      top: 20,
    },
    container: {
      marginTop: SPACING.SPACE_10,
    },
    menuContainer: {
      marginTop: SPACING.SPACE_10,
    },
  },

})

export default withTheme(stylesheet)(AlgoInfo)
