import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'

// import RoundCheckbox from '../../UI/RoundCheckbox'
import ShowLTP from '../../UI/ShowLTP'
import SegSym from '../../UI/SegSym'
import StockIcon from '../../UI/StockIcon'

import { getSegSymFromDC } from '../../containers/Create/Create-utils'
import {
  normalize, SPACING, FONTWEIGHT, FONTS, theme,
} from '../../Theme'

const SYM_ICON_SIZE = normalize(18)
const stockIconContainerStyle = {
  width: SYM_ICON_SIZE,
  height: SYM_ICON_SIZE,
  borderRadius: 12.5,
  marginRight: SPACING.SPACE_8,
  display: 'inline-flex',
}
const segSymStyles = {
  display: 'inline-grid',
  width: '70%',
  verticalAlign: 'super',
}

const SymbolCard = React.memo(({
  seg_sym, containerStyles, headerContainerStyles, isDark,
  cardHeight, cardWidth, isSelected, condensedHeight,
}) => {
  const height = isSelected ? condensedHeight : cardHeight
  // const toggleCheckbox = () => {
  //   onSymbolSelect(seg_sym, isSelected)
  // }
  const styles = stylesheet()
  const containerDim = {
    height: height || normalize(120),
    width: cardWidth || normalize(130),
  }
  return (
    <div
      className={classnames(styles.container, containerStyles)}
      style={{
        ...containerDim,
        backgroundColor: theme.bgPrimary,
      }}
    >
      <div className={classnames(styles.headerContainer, headerContainerStyles)}>
        <StockIcon seg_sym={seg_sym} containerStyle={stockIconContainerStyle} />
        <SegSym isDark={isDark} seg_sym={seg_sym} column style={segSymStyles} optionStyles={{ position: 'relative' }} />
      </div>
      <ShowLTP
        seg_sym={getSegSymFromDC(seg_sym).segSym}
        containerStyles={styles.ltpContainer}
        changeStyles={classnames(styles.ltpChange)}
        ltpStyles={styles.ltp}
        changeContainerStyles={styles.ltpChangeContainer}
        hideCurrency
      />
    </div>
  )
})

const stylesheet = makeStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: `${SPACING.SPACE_16} ${SPACING.SPACE_12}`,
    borderRadius: 6,
    opacity: 1,
    boxShadow: '0px 7px 20px #15278412',
  },
  headerContainer: {
    display: 'inline-block',
    width: '100%',
  },
  ltpContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: SPACING.SPACE_4,
    flexDirection: 'column',
  },
  ltp: {
    fontWeight: `${FONTWEIGHT.MEDIUM} !important`,
    fontSize: `${FONTS.MEDIUM} !important`,
  },
  ltpChange: {
    marginLeft: 0,
  },
  ltpChangeContainer: {
    marginLeft: 0,
  },
})

SymbolCard.defaultProps = {
}

SymbolCard.propTypes = {
  seg_sym: PropTypes.string.isRequired,
}

export default SymbolCard
