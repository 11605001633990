/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import makeStyles from '@material-ui/styles/makeStyles'
import classnames from 'classnames'

import moment from 'moment'
import dayjs from '../../utils/helpers/dayjs'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import FlatList from '../../UI/Flatlist'
import ListDropdown from '../../UI/Dropdown/ListDropdown'
import DateTimePicker, { monthsMap } from '../../UI/DateTimePicker'
// import TvLiteWidget from '../Marketwatch/TvLiteWidget'
// import { showSnackbar } from '../../UI/Snackbar'
import TvWidget from './TvWidget'
import SegSym from '../../UI/SegSym'

import {
  FONTS, ICONS, SPACING, theme, COLORS,
} from '../../Theme'
import { PRECISION } from '../../utils/consts'
import { CURRENCY_SYM } from '../../UI/ShowLTP'
import { segSymConverter } from '../../utils/common'

const TRIGGER_TYPE_MAP = {
  EN: 'Entry',
  EX: 'Exit',
  SL: 'Stop Loss',
  TP: 'Target Profit',
  SO: 'Square Off',
}

const endMonthDate = {
  Jan: '31',
  Feb: '29',
  March: '31',
  April: '30',
  May: '31',
  June: '30',
  July: '31',
  Aug: '31',
  Sept: '30',
  Oct: '31',
  Nov: '30',
  Dec: '31',

}

// const convertLogsToCSV = (transaction_data, showTriggerType) => {
//   const headerString = `Trigger Date, Buy/Sell, Quantity, Price, Cumulative\
//  P&L ${showTriggerType ? 'Trigger Type' : ''}\n`
//   const rowString = transaction_data
//     .map(
//       log => `${log[0]},${log[1]},${log[2]},${log[4].toFixed(2)},${log[5].toFixed(
//         2,
//       )}${showTriggerType ? `,${log[7]}` : ''}\n`,
//     )
//     .join('')
//   const csvLogs = `${headerString}${rowString}`
//   return csvLogs
// }

const headerFlex = isMobile => ({
  date: isMobile ? 0.25 : 0.20,
  sym: isMobile ? 0.20 : 0.28,
  price: isMobile ? 0.25 : 0.15,
  bs: isMobile ? 0.15 : 0.10,
  qty: 0.12,
  trigger: isMobile ? 0.15 : 0.15,
})

const downloadLogs = ({
  // algo_name = '',
  seg_sym,
  trade_log,
  // showTriggerType,
}) => {
  let csvContent = encodeURI('data:text/csv;charset=utf-8,')
  csvContent += encodeURI('Trigger Date, Trigger Time, Instrument,Buy/Sell,Quantity,Price,Cumulative P&L,Trigger type\r\n')
  trade_log.forEach((row) => {
    const disp_row = [...row]
    disp_row.splice(6, 1)
    disp_row.splice(2, 0, disp_row[1])
    disp_row[0] = dayjs(row[0]).format('DD MMM YYYY')
    disp_row[1] = dayjs(row[0]).format('HH:mm:ss')
    const { segment, symbol } = segSymConverter(disp_row[2])
    disp_row[2] = `${segment}_${symbol}`
    const line = disp_row.join(',')
    csvContent += encodeURI(`${line}\r\n`)
  })
  const link = document.createElement('a')
  link.setAttribute('href', csvContent)
  link.setAttribute('download', `${seg_sym}.csv`)
  link.innerHTML = 'Click Here to download'
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const renderItem = (item, index, currency, precision, len, styles, isMobile, data, isDark) => {
  const dateTime = item[0].split('T')
  const date = dayjs(dateTime[0]).format('DD MMM YYYY')
  let time = dateTime[1].split('.')[0]
  time = time.split('+')[0]
  const buySell = item[2] === 'BUY' ? 'Buy' : 'Sell'
  const bsColor = item[2] === 'BUY' ? 'linkColor' : 'red'
  // const pnlColor = item[5] >= 0 ? 'green' : 'red'
  const quantity = Math.abs((item[3] && item[3].toFixed(0))) || 0
  let pnlColor = 'green'
  const currentIndex = index
  const prevIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1
  if (currentIndex === 0) {
    pnlColor = isDark ? 'white' : 'black'
  } else if (data[prevIndex] && data[prevIndex][5] > item[5]) {
    pnlColor = 'red'
  } else if(data[prevIndex] && data[prevIndex][5] === item[5]) {
    pnlColor = isDark ? 'white' : 'black'
  } else {
    pnlColor = 'green'
  }
  const { segment } = segSymConverter(item[1])
  let valuePrecision = precision
  if(segment === 'CDS-FUT') {
    valuePrecision = 4
  }
  // const currencySym = currency ? `${currency} ` : ''
  return (
    <div className={styles.row} key={`${item[0]}${index}`}>
      <div className={styles.cell} style={{ flex: headerFlex(isMobile).date }}>
        <CustomText size="small" weight="medium" style={{ marginRight: SPACING.SPACE_4 }}>{date}</CustomText>
        <CustomText size={isMobile ? 'small' : 'tiny'} weight="regular" color="textSecondary">{time}</CustomText>
      </div>
      <div className={styles.cell} style={{ flex: headerFlex(isMobile).sym }}>
        <SegSym
          seg_sym={item[1]}
          symStyles={{
            marginRight: item[1].length > 15 ? SPACING.SPACE_8 : 0,
          }}
          segStyles={{ fontSize: isMobile ? FONTS.SMALL : FONTS.TINY }}
          style={{ flexDirection: item[1].length > 15 ? 'column' : 'row' }}
          optionStyles={{ position: 'relative' }}
          column={isMobile}
        />
      </div>
      <div className={`${styles.cell} ${styles.cellPrice}`} style={{ flex: headerFlex(isMobile).price }}>
        <CustomText size="small" weight="medium" color={pnlColor} style={{ marginRight: SPACING.SPACE_4 }}>
          {currency}
          {`${item[4].toFixed(valuePrecision)}`}
        </CustomText>
        <CustomText size={isMobile ? 'small' : 'tiny'} color={pnlColor}>{item[5].toFixed(valuePrecision)}</CustomText>
      </div>
      <div className={styles.cell} style={{ flex: headerFlex(isMobile).bs }}>
        <CustomText size="small" weight="medium" color={bsColor}>{buySell}</CustomText>
        {isMobile && <CustomText size="small" weight="medium">{quantity}</CustomText>}
      </div>
      {!isMobile && (
      <div className={styles.cell} style={{ flex: headerFlex(isMobile).qty }}>
        <CustomText size="small" weight="medium">{quantity}</CustomText>
      </div>
      )}
      <div className={styles.cell} style={{ flex: headerFlex(isMobile).trigger }}>
        <CustomText size="small">{TRIGGER_TYPE_MAP[item[7]] || item[7]}</CustomText>
      </div>
    </div>
  )
}

const dayFilterOptions = ({ endDate, startDate, trade_log }) => {
  const options = [{ label: 'All', value: 'All' }]
  if (startDate && endDate) {
    let calDate = dayjs(startDate.split('T')[0])
    const finalEndDate = endDate.split('T')[0]
    let count = 0
    do {
      const value = calDate.format('YYYY-MM-DD')
      options.push({
        label: calDate.format('DD MMM YYYY'),
        value,
        disabled: trade_log ? !trade_log.some(logItem => logItem[0].split('T')[0] === value) : false,
      })
      calDate = calDate.add(1, 'day')
      count += 1
    }
    while (calDate.isSameOrBefore(dayjs(finalEndDate)) || count <= 1000)
  }
  // if (trade_log.length) {
  //   options = trade_log.map((log) => {
  //     const calDate = log[0].split('T')[0]
  //     return ({ label: moment(calDate).format('DD MMM YYYY'), value: calDate })
  //   })
  //   options.unshift('All')
  // }
  return options
}

const monthFilterOptions = ({ startDate, endDate }) => {
  const options = ['All']
  if (startDate && endDate) {
    // parseInt(date_range_epochs[time_frame] / (60 * 60 * 24 * 30 * 12), 10)
    const startYear = new Date(startDate).getFullYear()
    const endYear = new Date(endDate).getFullYear()
    const startMonth = new Date(startDate).getMonth()
    const endMonth = new Date(endDate).getMonth()
    const duration = endYear - startYear
    for (let i = 0; i <= duration; i++) {
      monthsMap.forEach((item, mi) => {
        if (i === 0) {
          if (mi >= startMonth && mi <= endMonth) options.push(`${item} ${startYear + i}`)
        } else if (i === duration) {
          if (mi <= endMonth) options.push(`${item} ${startYear + i}`)
        } else {
          options.push(`${item} ${startYear + i}`)
        }
      })
    }
  }
  return options
}

const calcOption = (time_frame, params) => {
  const timeFrame = time_frame.toLowerCase()
  if (timeFrame.includes('min')) {
    return [] // dayFilterOptions(params)
  }
  if (timeFrame.includes('day') || timeFrame.includes('hour')) {
    return monthFilterOptions(params)
  }
  return []
}

const TransactionDetails = ({
  trade_log = [], time_frame = '', isDark, csrf, sessionid, dt_start, dt_stop, seg_sym,
  renderPeriodReturn, period_return, period_low, period_high, isMobile, algo_uuid, publishing_uuid,
  shared, algo_name, isLoggedIn, updateGA, isListView, gaCategory, isCtb, showSideBar,
}) => {
  const styles = stylesheet()
  const [tradeLog, updateTradeLog] = useState(trade_log)
  const [filterValue, updateFilter] = useState('')
  const [tradelog_dtstart, updateTradeLogDate] = useState(null)
  const [tradelog_dtstop, updateTradeLogDateStop] = useState(null)
  const [tvChartWidth, updateTVChartWidth] = useState(false)
  const viewType = isListView ? 'List' : 'Card'
  useEffect(() => {
    updateTradeLog(trade_log)
  }, [trade_log])

  useEffect(() => {
    updateTVChartWidth(false)
    setTimeout(() => {
      updateTVChartWidth(true)
    }, 200)
  }, [showSideBar])

  if ((tradeLog.length === 0 || trade_log.length === 0 || trade_log === null) && !filterValue) {
    return <CustomText center>No Trade log found</CustomText>
  }
  const startDate = trade_log && trade_log[0] && trade_log[0][0]
  const endDate = trade_log && trade_log[trade_log.length - 1][0]
  const filterOptions = calcOption(time_frame, { startDate, endDate, trade_log })
  // const [isFiltering, updateIsFiltering] = useState(false)

  const updateData = (value, item, isDate, tradeLogDate) => {
    if (value === '' || value === 'All') {
      updateTradeLog(trade_log)
      updateFilter(value)
      updateTradeLogDate(null)
      updateTradeLogDateStop(null)
      return
    }
    // if (!isEmpty(item)) { //  day filter // not used in web
    //   const isDisabled = !trade_log.some(logItem => logItem[0].split('T')[0] === value)
    //   if (isDisabled) {
    //     showSnackbar('No trade happened on this date')
    //     return
    //   }
    // }

    updateFilter(value)
    const modLogs = trade_log.filter((log) => {
      if (!isEmpty(item) || isDate) {
        const logDate = log[0].split('T')[0]
        updateTradeLogDate(tradeLogDate)
        updateTradeLogDateStop(tradeLogDate)
        return logDate === value
      }
      const logMonth = monthsMap[new Date(log[0]).getMonth()]
      const logYear = new Date(log[0]).getFullYear()
      updateTradeLogDate(dayjs(value).format('DD/MM/YYYY'))
      updateTradeLogDateStop(dayjs(`${endMonthDate[logMonth]} ${value}`).format('DD/MM/YYYY'))
      return (value === `${logMonth} ${logYear}`)
    })
    updateTradeLog(modLogs)
    updateGA('Filter', `Filter + ${viewType}`, 1, gaCategory)
    // updateIsFiltering(false)
  }

  // let seg_sym = ''
  // // let quantity = 0
  // if (trade_log[0]) {
  //   seg_sym = trade_log[0][1]
  //   // quantity = Math.abs((trade_log[0][3] && trade_log[0][3].toFixed(0))) || 0
  // }
  const labelRender = (optionObj, label, selected) => {
    if (time_frame.includes('min')) {
      const { value } = optionObj
      const isDisabled = value !== 'All' && !trade_log.some(item => item[0].split('T')[0] === value)
      return (
        <CustomText
          className={styles.item}
          style={{
            backgroundColor: isDisabled ? theme.screenBg : theme.bgPrimary,
          }}
          color={isDisabled ? theme.disabled : selected ? theme.linkColor : theme.text}
        >
          {label}
        </CustomText>
      )
    }
    return (
      <CustomText
        className={styles.item}
        color={selected ? theme.linkColor : theme.text}
      >
        {label}

      </CustomText>
    )
  }
  const currency = CURRENCY_SYM
  const precision = PRECISION
  const renderListHeader = () => {
    return (
      <div className={styles.tableHeader}>
        <CustomText flex={headerFlex(isMobile).date} color={isDark ? 'textSecondary' : ''} weight="regular" size="tiny">Date</CustomText>
        <CustomText flex={headerFlex(isMobile).sym} color={isDark ? 'textSecondary' : ''} weight="regular" size="tiny">Symbol</CustomText>
        <CustomText flex={headerFlex(isMobile).price} color={isDark ? 'textSecondary' : ''} weight="regular" size="tiny">Price (P&L)</CustomText>
        <CustomText flex={headerFlex(isMobile).bs} color={isDark ? 'textSecondary' : ''} weight="regular" size="tiny" style={{ whiteSpace: 'pre-line' }}>
          {isMobile ? 'Buy/Sell\nQty' : 'Buy/Sell'}
        </CustomText>
        {!isMobile && <CustomText flex={headerFlex(isMobile).qty} color={isDark ? 'textSecondary' : ''} weight="regular" size="tiny">Qty.</CustomText>}
        <CustomText flex={headerFlex(isMobile).trigger} color={isDark ? 'textSecondary' : ''} weight="regular" size="tiny">Trigger</CustomText>
      </div>
    )
  }

  const finalStartDate = startDate ? moment(startDate.split('T')[0]) : ''
  const finalEndDate = endDate ? moment(endDate.split('T')[0]) : ''
  const isDateValid = (current) => {
    const value = current.format('YYYY-MM-DD')
    const logExist = trade_log ? trade_log.some(logItem => logItem[0].split('T')[0] === value) : false
    return current.isSameOrBefore(finalEndDate) && current.isSameOrAfter(finalStartDate) && logExist
  }

  const inputRenderer = (dateProps) => {
    return (
      <input
        {...dateProps}
        value={dateProps.value && filterValue
          ? moment(dateProps.value).format('DD MMM YYYY') : ''}
        className={classnames(styles.fieldInput, styles.dropDownInput)}
      />

    )
  }

  const renderTvWidget = () => {
    return (
      <div className={styles.chart}>
        {tvChartWidth ? (
          <TvWidget
            algo_uuid={algo_uuid}
            publishing_uuid={publishing_uuid}
            tradeLogChart
            csrf={csrf}
            sessionid={sessionid}
            item={{ seg_sym }}
            height={200}
            dt_start={tradelog_dtstart || dt_start}
            dt_stop={tradelog_dtstop || dt_stop}
            time_frame={time_frame}
            isDark={isDark}
            shared={shared}
            algo_name={algo_name}
          />
        ) : null}
      </div>
    )
  }

  return (
    <div className={styles.screen} id="tour_bt_5">
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <div className={styles.title}>
            <CustomText weight="medium" size="medium">Transaction Details</CustomText>
            {/* <div className={styles.qtyView}>
              <CustomText weight="medium">Qty.</CustomText>
              <CustomText weight="medium" style={{ marginLeft: SPACING.SPACE_4 }}>
              {quantity}</CustomText>
            </div> */}
          </div>
        </div>

        <div className={styles.headerRight}>

          <div className={styles.dateDropDown}>
            {time_frame.toLowerCase().includes('min') ? (

              <div
                className={classnames(styles.fieldInputContainer, styles.dropDownContainer)}
              >
                {!filterValue
                  && <CustomText className={styles.dateFilterLabel} color="textSecondary">Select Filter</CustomText>}
                <DateTimePicker
                  props={{
                    timeFormat: false,
                    closeOnSelect: true,
                    dateFormat: 'YYYY-MM-DD',
                    value: filterValue,
                    isValidDate: isDateValid,
                    className: styles.datePickerInput,
                    renderInput: dateProps => inputRenderer(dateProps),
                    onChange: date => updateData(date ? date.format('YYYY-MM-DD') : '', {}, true, date.format('DD/MM/YYYY')),
                  }}
                />
                {(filterValue) ? (
                  <button type="button" onClick={() => updateData('')} className={styles.clearBtn}>
                    <Icon
                      name={ICONS.CLOSE_FILLED}
                      color={theme.textSecondary}
                      size={12}
                      className={styles.inputIcon}
                    />
                  </button>
                ) : null}
                {/* <Icon
                  className={styles.dropDownIcon}
                  name={ICONS[(filterValue) ? 'UP_HEAD_FILLED' : 'DOWN_HEAD_FILLED']}
                  color={COLORS[isDark ? 'GREY_600' : 'TEXT']}
                  size={10}
                /> */}
              </div>
            ) : (
              <ListDropdown
                options={filterOptions}
                // eslint-disable-next-line max-len
                onChangeOption={date => updateData(date || '', {}, false, date)}
                // onChangeOption={updateData}
                selected={filterValue}
                placeholder="Select filter"
                containerStyles={styles.dropDownContainer}
                inputContainerStyles={styles.dropDownInput}
                inputStyles={styles.filterInput}
                labelRender={labelRender}
                inputBtnStyles={styles.filterInputBtn}
              />
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              downloadLogs({
              // algo_name,
                seg_sym,
                trade_log: tradeLog,
                showTriggerType: true,
              })
              updateGA('Download', 'Transaction table', 1, gaCategory)
            }}
            className={styles.downloadBtn}
            style={{
              backgroundColor: isDark ? '#FFFFFF' : 'transparent',
            }}
          >
            <Icon name={ICONS.ARROW_DOWN_FILLED} color={theme.linkColor} size={30} />
          </button>
        </div>
      </div>
      {isLoggedIn && !isCtb && (
        renderTvWidget()
      )}
      {renderPeriodReturn({
        period_return, period_low, period_high,
      })}
      {renderListHeader()}
      <div className={styles.listView}>
        <FlatList
          data={tradeLog}
          numberToRender={20}
          showMoreBtn
          listStyles={styles.list}
          showMoreBarStyles={styles.showMoreBar}
          keyExtractor={(item, i) => `${item[0]}${i}`}
        >
          {list => list.map((item, index) => renderItem(
            item, index, currency, precision, trade_log.length, styles, isMobile, tradeLog, isDark,
          ))}
        </FlatList>
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  screen: {
    background: 'transparent',
    marginTop: SPACING.SPACE_20,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLeft: {},
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  qtyView: {
    marginLeft: SPACING.SPACE_10,
    display: 'flex',
    alignItems: 'baseline',
  },
  headerRight: {
    display: 'flex',
  },
  dateDropDown: {
    width: 220,
    marginRight: SPACING.SPACE_20,
  },
  downloadBtn: {
    display: 'flex',
    alignSelf: 'flex-start',
    borderRadius: 20,
  },
  tableHeader: {
    display: 'flex',
    padding: `${SPACING.SPACE_12} 0`,
    borderTop: `1px solid ${theme.borderColor}`,
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  listView: {

  },
  list: {
    paddingBottom: SPACING.SPACE_20,
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: SPACING.SPACE_20,
  },
  cell: {
    display: 'flex',
    alignItems: 'baseline',
  },
  cellPrice: {
    alignItems: 'center',
  },
  item: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    margin: `0 ${SPACING.SPACE_10}`,
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  filterInputBtn: {
    backgroundColor: theme.tabsBg,
    border: 0,
  },
  datePickerInput: {
    width: '100%',
  },
  showMoreBar: {
    marginTop: SPACING.SPACE_12,
    paddingTop: SPACING.SPACE_16,
    borderTop: `2px solid ${theme.borderColor}`,
    marginRight: SPACING.SPACE_20,
  },
  fieldInput: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    backgroundColor: 'transparent',
    width: '100%',
  },
  fieldInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 6,
    backgroundColor: theme.tabsBg,
    // position: 'relative',
    width: '100%',
  },
  dropDownContainer: {
  },
  dateFilterLabel: {
    position: 'absolute',
    padding: '8px',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: SPACING.SPACE_8,
    zIndex: 99,
  },
  '@media only screen and (max-width: 786px)': {
    title: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    qtyView: {
      margin: 0,
    },
    dateDropDown: {
      width: '100%',
    },
    cell: {
      flexDirection: 'column',
      alignItems: 'flex-start !important',
    },
  },
})

export default TransactionDetails
