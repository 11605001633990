import React, { useState } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import classnames from 'classnames'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'

import EditAlgoParams from '.'
import CustomText from '../../UI/CustomText'
import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import CustomInput from '../../UI/CustomInput'
import ListDropdown from '../../UI/Dropdown/ListDropdown'
import Icon from '../../UI/Icon'
import Button from '../../UI/Button'
import EntryExitRenderer from '../Backtests/EntryExitRenderer'
import ToggleButton from '../../UI/ToggleButton'
import DateTimePicker from '../../UI/DateTimePicker'
import SidebarLoader from '../../UI/Loaders/Backtests/SidebarLoader'
import CandleIntervalView from './CandleIntervalView'
import CustomTooltip from '../../UI/CustomTooltip'

import { STRATEGY_TYPE } from '../../containers/Backtests/Backtests'
import {
  CHART_ICON_MAP,
  POSITION_SIZING_TYPES,
  MAX_ALLOCATION_OBJ,
  qtyRegex,
  decimalRegex,
  // algoDateFormat,
  //   renkoParams
} from '../../utils/consts'
import {
  COLORS, ICONS, SPACING, theme,
} from '../../Theme'
import Expandable from '../../UI/Expandable'
import { updateBacktestParams, editSubscribedAlgo } from '../../containers/Backtests/actions'
import { CONDITION_TYPE } from '../../containers/Create/Create-utils'
import { pathname_mapping } from '../../utils/navigationConstant'
import { showSnackbar } from '../../UI/Snackbar'
import { useTheme } from '../../Theme/ThemeProvider'
import { changePtComponentStatus, toggleAuthGreeting, updateTourIndex } from '../../containers/Common/actions'
import { toggleLoginModal } from '../../containers/Auth/actions'
import { getAdvancedParamsLabel } from '../../utils/common'
import { routeToCreate } from '../../utils/experimenting'
import { PRO_FEATURE_MAP } from '../../utils/userAccessChecks'
// import CustomModal from '../../UI/CustomModal'
// import IndicatorDetails from '../Create/IndicatorDetails'

// export const maxAllocationRegex = /^[1-9]*\d*$/g

const labelProps = {
  weight: 'medium',
  size: 'small',
}

const EXPAND_KEY_TYPE = {
  CHART: 'chart',
  QUANTITY: 'quantity',
  BT_PERIOD: 'btPeriod',
  ENTRY: 'entry',
  EXIT: 'exit',
}

// const renderTooltip = (item, chart_type, className) => {
//   return (
//     <div className={className} id="tooltip">
//       <CustomText color="white" />
//     </div>
//   )
// }

const EditParams = (props) => {
  const history = useHistory()
  const styles = stylesheet()
  const { updateGA } = useTheme()
  const {
    backtestParams, onEditParams, user_details,
    onGoingBacktests, isFetchingBacktests, onEditSubscribedAlgo, isLoggedIn,
    toggleAuthGreetingModal, toggleAuthModal, backtest, updateTour,
    changePtStatus, activeTourIndex, startTour,
  } = props
  const { subscription_valid } = user_details
  const { algo_state = {} } = backtest
  //   const status = Boolean(quantity)
  const [expandKey, updateKey] = useState(EXPAND_KEY_TYPE.CHART)
  // const [open, showPicker] = useState(false)
  const { isDark } = useTheme()
  if (isEmpty(backtestParams) || isFetchingBacktests) {
    return <SidebarLoader />
  }

  const tabsProps = {
    tabColor: isDark ? 'VOILET_600' : 'VOILET',
    activeTabColor: 'WHITE',
  }

  const renderTimeInput = (timeProps) => {
    return (
      <div className={classnames(styles.fieldInputContainer, styles.timeInputContainer)}>
        <input {...timeProps} className={classnames(styles.fieldInput, styles.timeInput)} />
        <Icon
          name={ICONS.WAITING}
          color={theme.textSecondary}
          size={12}
          className={styles.inputIcon}
        />
      </div>
    )
  }
  // const togglePicker = () => {
  //   showPicker(!open)
  // }

  const renderDateInput = (dateProps) => {
    return (
      <div
        className={classnames(styles.fieldInputContainer, styles.dateInputContainer)}
      >
        <input
          {...dateProps}
          className={classnames(styles.fieldInput, styles.dateInput)}
          style={{ color: isDark ? COLORS.WHITE : theme.textPrimary }}
        />
        <Icon
          name={ICONS.CALENDAR}
          color={isDark ? COLORS.WHITE : COLORS.VOILET}
          size={12}
          className={styles.inputIcon}
        />
      </div>
    )
  }
  const {
    exitStr = '', entryStr = '', create_plus, algo_uuid, renderType, algoId,
    isSubscribingAlgo, algo_name, deployed_seg_sym = [], tpsl_type: tpslTypeDefault = '',
  } = backtestParams
  const { public: isPublic, shared } = backtest
  const isRunningBacktests = onGoingBacktests[algoId]
  const isStockDeployed = deployed_seg_sym.length > 0

  const onExitEdit = () => {
    if (isRunningBacktests || isFetchingBacktests
      || isSubscribingAlgo || isStockDeployed) return
    if (isPublic) {
      showSnackbar('You can not edit strategy params without copying it.')
      return
    }
    if (renderType === STRATEGY_TYPE.MARKET) {
      showSnackbar('Copy strategy to edit')
    } else if (renderType === STRATEGY_TYPE.SUBSCRIBED) {
      onEditSubscribedAlgo()
    } else {
      updateAnalytics('Edit strategy', 'Exit backtest parameters')
      routeToCreate({
        history,
        search: `?id=${btoa(algo_uuid)}&edit=${btoa(true)}&type=${btoa(CONDITION_TYPE.EXIT)}`,
        id: algo_uuid,
        routeParams: { parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algo_uuid)}` },
      })
      // history.replace({
      //   pathname: pathname_mapping.Create,
      //   search: `?id=${btoa(algo_uuid)}&edit=${btoa(true)}&type=${btoa(CONDITION_TYPE.EXIT)}`,
      // }, { parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algo_uuid)}` })
    }
  }
  const onEntryEdit = () => {
    if (isRunningBacktests || isFetchingBacktests
      || isSubscribingAlgo || isStockDeployed) return
    if (isPublic) {
      showSnackbar('You can not edit strategy params without copying it.')
      return
    }
    if (renderType === STRATEGY_TYPE.MARKET) {
      showSnackbar('Copy strategy to edit')
    } else if (renderType === STRATEGY_TYPE.SUBSCRIBED) {
      onEditSubscribedAlgo()
    } else {
      updateAnalytics('Edit strategy', 'Entry backtest parameters')
      routeToCreate({
        history,
        search: `?id=${btoa(algo_uuid)}&edit=${btoa(true)}&type=${btoa(CONDITION_TYPE.ENTRY)}`,
        id: algo_uuid,
        routeParams: { parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algo_uuid)}` },
      })
      // history.replace({
      //   pathname: pathname_mapping.Create,
      //   search: `?id=${btoa(algo_uuid)}&edit=${btoa(true)}&type=${btoa(CONDITION_TYPE.ENTRY)}`,
      // }, { parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algo_uuid)}` })
    }
  }
  const gaCategory = renderType === STRATEGY_TYPE.MARKET ? 'Discover edit parameters' : 'Edit parameters'
  const updateAnalytics = (a, l, v) => {
    updateGA(a, l, v, gaCategory)
  }
  return (
    <div className={classnames(styles.containerParams, 'hideScrollbar')}>
      <EditAlgoParams
        {...backtestParams}
        tpsl_type={tpslTypeDefault || algo_state.tpsl_type}
        user_details={user_details}
        isBacktestPage
        scripList={algo_state ? algo_state.scripList : []}
        onEditParams={onEditParams}
        key={algo_name}
        toggleAuthGreeting={toggleAuthGreetingModal}
        gaCategory={gaCategory}
        isPublic={isPublic}
        updateTourIndex={updateTour}
        changePtComponentStatus={changePtStatus}
        activeTourIndex={activeTourIndex}
        startTour={startTour}
        updateGA={updateGA}
      >
        {({
          chart_list_map,
          holding_type_map,
          candleIntervals,
          postionBtnMap,
          strategyCycleMap,
          // array ends
          quantity,
          time_frame,
          dt_start,
          dt_stop,
          initial_capital,
          trading_start_time,
          trading_stop_time,
          fancyChartType, // chart_type,
          //   algo_desc,
          quantityErr,
          holding_type,
          max_allocation,
          max_allocationErr,
          max_quantity,
          max_quantityErr,
          tpsl_type,
          daily_strategy_cycle,
          position_sizing_type,
          showBacktestBtn,
          // to hide the backtest button when nothing is changed
          take_profit,
          stop_loss,
          chart_type,
          // state ends
          handleChange,
          onInputChange,
          handleCandleChange,
          //   setTradingTime,
          setSelectedDateTime,
          chartChangeHandler,
          blockDtStart,
          blockDtStop,
          tpslTypes,
          onSltpTypeChange,
          onSlChange,
          onTpChange,
          onEditParams: onEditParamsHandler,
          setTradingTime,
          togglePositions,
          position_type,
          trailing_sl,
          handleNumericChangeforTsl,
          // handler ends
        }) => {
          // const isAmpm = trading_start_time.toLowerCase().includes('m')
          // console.log(isRunningBacktests, onGoingBacktests, algoId, 'lol position_type')
          const editParamsHandler = (params) => {
            if (!subscription_valid) {
              // showSnackbar('Limit reached.')
              toggleAuthGreetingModal(PRO_FEATURE_MAP.DP_LIMIT.ID)
              return
            }
            if (isLoggedIn) {
              // if (isRunningBacktests) {
              //   showSnackbar('Backtest is still running')
              //   return
              // }
              onEditParamsHandler(params)
            } else {
              toggleAuthModal(true, false, true)
            }
          }
          const {
            label: maxAllocationLabel, placeholder: maxAllocationPlaceholder,
          } = MAX_ALLOCATION_OBJ(position_sizing_type)
          const { slLabel, tpLabel, tslLabel } = getAdvancedParamsLabel({ tpsl_type })
          const isQtyDisabled = max_allocation.length > 0
          const qtyFieldStyle = isQtyDisabled ? styles.qtyDisabled : styles.qtyInput
          // const maxStartTime = typeof stop_time === 'object'
          //   ? dt_stop.clone().subtract(7, 'days') : dt_stop
          const chartLabelRenderer = ({ item, active }) => {
            const text = /Renko/i.test(chart_type) && /Renko/i.test(item) ? chart_type : item
            return (
              <CustomTooltip
                show
                tooltipProps={{ text }}
              >
                <div className={styles.tab}>
                  <Icon
                    name={CHART_ICON_MAP[item]}
                    color={active ? COLORS.WHITE : (isDark ? COLORS.LIGHT_VOILET : COLORS.VOILET)}
                  />
                </div>
              </CustomTooltip>
            )
          }
          const isCnc = holding_type === 'CNC/NRML'
          const disabledMsgCnc = 'Daily strategy cycle cannot be used when holding type is CNC/NRML'
          let showExitCondition = true
          if (shared) {
            showExitCondition = !!isPublic
          }
          return (
            <>
              <div className={styles.positionsContainer}>
                <CustomText weight="semi_bold" size="medium">Position I would take</CustomText>
                <ToggleButton
                  selected={position_type.toLowerCase()}
                  btnMap={postionBtnMap(isDark)}
                  toggleHandler={togglePositions}
                  containerStyles={styles.positions}
                  isDark={isDark}
                  btnWidth={60}
                />
              </div>
              <Expandable
                title="Charts"
                defaultExpanded={expandKey === EXPAND_KEY_TYPE.CHART}
                onExpandCallback={updateKey}
                expandKey={EXPAND_KEY_TYPE.CHART}
                containerStyles={styles.expandContainer}
              >
                <div className={styles.content}>
                  <CustomText {...labelProps} className={styles.inputLabel}>Chart Type</CustomText>
                  <CustomHorizontalTab
                    tabs={chart_list_map}
                    defaultActiveTab={fancyChartType}
                    tabChangeHandler={chartChangeHandler}
                    style={classnames(styles.tabContainer, styles.chartTypeContainer,
                      chart_list_map.length < 2 ? styles.singleTab : '')}
                    tabStyles={styles.tab}
                    labelRenderer={chartLabelRenderer}
                    dragBtnStyles={styles.tabActive}
                    disableActiveTab={false}
                    {...tabsProps}
                  />
                  <CustomText {...labelProps} className={styles.inputLabel}>Interval</CustomText>
                  <CandleIntervalView
                    tabs={candleIntervals}
                    // defaultTabs={[
                    //   { label: 'min', shortLabel: '1m', value: '1min' },
                    //   { label: '3min', shortLabel: '3m', value: '3min' },
                    //   { label: '15min', shortLabel: '15m', value: '15min' },
                    //   { label: 'hour', shortLabel: '1H', value: 'hour' },
                    // ]}
                    defaultActiveTab={time_frame}
                    tabChangeHandler={handleCandleChange}
                    // style={classnames(styles.tabContainer)}
                    tabStyles={styles.tab}
                    isDark={isDark}
                    {...tabsProps}
                  />
                  {/* <CustomHorizontalTab
                    tabs={candleIntervals}
                    defaultActiveTab={time_frame}
                    tabChangeHandler={handleCandleChange}
                    dragBtnStyles={styles.tabActive}
                    {...tabsProps}
                  /> */}
                  <CustomText {...labelProps} className={styles.inputLabel}>
                    Holding type
                  </CustomText>
                  <CustomHorizontalTab
                    tabs={holding_type_map}
                    defaultActiveTab={holding_type}
                    changeKey="holding_type"
                    tabChangeHandler={handleChange}
                    style={styles.tabContainer}
                    tabStyles={styles.tab}
                    dragBtnStyles={styles.tabActive}
                    {...tabsProps}
                  />
                </div>
              </Expandable>

              <Expandable
                title="Quantity"
                defaultExpanded={expandKey === EXPAND_KEY_TYPE.QUANTITY}
                onExpandCallback={updateKey}
                expandKey={EXPAND_KEY_TYPE.QUANTITY}
                containerStyles={styles.expandContainer}
              >
                <div className={styles.content}>
                  <CustomText {...labelProps} className={styles.inputLabel}>Quantity</CustomText>
                  <CustomInput
                    value={quantity}
                    inputError={quantityErr}
                    placeholder="Qty in lots (1 = 1 lot)"
                    onChangeText={onInputChange}
                    inputStyles={`${styles.fieldInput} ${qtyFieldStyle}`}
                    containerStyles={styles.fieldInputContainer}
                    inputKey="quantity"
                    inputRegx={new RegExp(qtyRegex)}
                    disabled={isQtyDisabled}
                  />
                  {create_plus && (
                    <>
                      <CustomText {...labelProps} className={styles.inputLabel}>
                        {maxAllocationLabel}

                      </CustomText>
                      <CustomInput
                        value={max_allocation}
                        placeholder={maxAllocationPlaceholder}
                        onChangeText={onInputChange}
                        inputStyles={styles.fieldInput}
                        inputRegx={new RegExp(qtyRegex)}
                        containerStyles={styles.fieldInputContainer}
                        inputKey="max_allocation"
                        inputError={max_allocationErr}
                      />
                      <CustomText {...labelProps} className={styles.inputLabel}>
                        Max Quantity ( in lots )
                      </CustomText>
                      <CustomInput
                        value={max_quantity}
                        placeholder="Max Quantity in lots (1 = 1 lot)"
                        onChangeText={onInputChange}
                        inputStyles={styles.fieldInput}
                        inputRegx={new RegExp(qtyRegex)}
                        containerStyles={styles.fieldInputContainer}
                        inputKey="max_quantity"
                        inputError={max_quantityErr}
                      />
                      <CustomText {...labelProps} className={styles.inputLabel}>
                        Position sizing type

                      </CustomText>
                      <ListDropdown
                        selected={position_sizing_type}
                        options={POSITION_SIZING_TYPES}
                        changeKey="position_sizing_type"
                        onChangeOption={handleChange}
                        containerStyles={styles.dropDownContainer}
                        modalStyles={styles.dropdownModal}
                        itemBtnStyles={styles.dropdownItem}
                        inputBtnStyles={classnames(
                          styles.fieldInput, styles.positionSizingInput,
                        )}
                      />
                    </>
                  )}
                </div>
              </Expandable>
              <Expandable
                title="Entry"
                defaultExpanded={expandKey === EXPAND_KEY_TYPE.ENTRY}
                onExpandCallback={updateKey}
                expandKey={EXPAND_KEY_TYPE.ENTRY}
                containerStyles={styles.expandContainer}
                strLength={entryStr.length}
              >
                <div className={styles.content}>
                  {/* <div className={styles.entryExitHeader}>
                    <CustomText weight="medium" className={styles.inputLabel}>
                      Entry Conditions
                    </CustomText>
                    <button
                      type="button"
                      onClick={() => {}}
                      className={styles.editBtn}
                    >
                      <Icon name={ICONS.EDIT} color={COLORS.BLUE} size={10} />
                    </button>
                  </div> */}
                  <EntryExitRenderer
                    cardTitle="Entry conditions"
                    onCardPress={onEntryEdit}
                    condition={entryStr}
                    disabled={Boolean(isSubscribingAlgo || isRunningBacktests || isStockDeployed
                      || isPublic === false)}
                    key="entry"
                    cardStyles={styles.entryCard}
                    textStyles={styles.entryExitString}
                    isDark={isDark}
                  />
                  <div className={styles.seperator} />

                  <div className={styles.row}>
                    <CustomText
                      {...labelProps}
                      className={classnames(styles.inputLabel, styles.rowLabel)}
                    >
                      Start time
                    </CustomText>
                    <DateTimePicker
                      props={{
                        dateFormat: false,
                        closeOnSelect: true,
                        className: styles.timePickerContainer,
                        timeFormat: 'HH:mm',
                        // timeFormat={isAmpm ? 'hh : mm A' : 'HH:mm'}
                        value: trading_start_time,
                        renderInput: renderTimeInput,
                        onChange: date => setTradingTime('trading_start_time', date),
                      }}

                    />
                  </div>
                  <div className={styles.row}>
                    <CustomText
                      {...labelProps}
                      className={classnames(styles.inputLabel, styles.rowLabel)}
                    >
                      Stop time
                    </CustomText>
                    <DateTimePicker
                      props={{
                        dateFormat: false,
                        timeFormat: 'HH:mm',
                        className: styles.timePickerContainer,
                        // timeFormat={isAmpm ? 'hh : mm A' : 'HH:mm'}
                        value: trading_stop_time,
                        renderInput: renderTimeInput,
                        onChange: date => setTradingTime('trading_stop_time', date),
                      }}
                    />
                  </div>
                </div>
              </Expandable>
              <Expandable
                title="Exit"
                defaultExpanded={expandKey === EXPAND_KEY_TYPE.EXIT}
                onExpandCallback={updateKey}
                expandKey={EXPAND_KEY_TYPE.EXIT}
                containerStyles={styles.expandContainer}
                strLength={exitStr.length}
              >
                <div className={styles.content}>
                  {create_plus && (
                    <div className={styles.row}>
                      <CustomText
                        {...labelProps}
                        className={classnames(styles.inputLabel, styles.rowLabel)}
                      >
                        TPSL type
                      </CustomText>
                      <ListDropdown
                        selected={tpsl_type}
                        options={tpslTypes}
                        changeKey="tpsl_type"
                        onChangeOption={onSltpTypeChange}
                        containerStyles={styles.dropDownContainer}
                        modalStyles={styles.dropdownModal}
                        itemBtnStyles={styles.dropdownItem}
                        inputBtnStyles={classnames(
                          styles.fieldInput, styles.tpslTypeInput,
                        )}
                      />
                    </div>
                  )}
                  <div className={styles.row}>
                    <CustomText
                      {...labelProps}
                      className={classnames(styles.inputLabel, styles.rowLabel)}
                    >
                      {slLabel}
                    </CustomText>
                    <CustomInput
                      inputError={!stop_loss}
                      value={stop_loss}
                      onChangeText={onSlChange}
                      inputKey="stop_loss"
                      inputStyles={styles.fieldInput}
                      containerStyles={classnames(
                        styles.fieldInputContainer, styles.sltpInputContainer,
                      )}
                      wrapperStyles={styles.fieldInputWrapper}
                      placeholder="00"
                      inputRegx={new RegExp(decimalRegex)}
                    />
                  </div>
                  <div className={styles.row}>
                    <CustomText
                      {...labelProps}
                      className={classnames(styles.inputLabel, styles.rowLabel)}
                    >
                      {tpLabel}
                    </CustomText>
                    <CustomInput
                      inputError={!take_profit}
                      value={take_profit}
                      onChangeText={onTpChange}
                      inputKey="take_profit"
                      inputStyles={styles.fieldInput}
                      containerStyles={classnames(
                        styles.fieldInputContainer, styles.sltpInputContainer,
                      )}
                      wrapperStyles={styles.fieldInputWrapper}
                      inputRegx={new RegExp(decimalRegex)}
                      placeholder="00"
                    />
                  </div>
                  {create_plus && (
                    <div className={styles.row}>
                      <CustomText
                        {...labelProps}
                        className={classnames(styles.inputLabel, styles.rowLabel)}
                      >
                        {`${tslLabel} (optional)`}
                      </CustomText>
                      <CustomInput
                        inputError={!trailing_sl}
                        value={trailing_sl}
                        onChangeText={handleNumericChangeforTsl}
                        inputKey="tsl"
                        inputStyles={styles.fieldInput}
                        containerStyles={classnames(
                          styles.fieldInputContainer, styles.sltpInputContainer,
                        )}
                        wrapperStyles={styles.fieldInputWrapper}
                        inputRegx={new RegExp(decimalRegex)}
                        placeholder="00"
                      />
                    </div>
                  )}
                  <div className={styles.seperator} />

                  {showExitCondition ? (
                    <EntryExitRenderer
                      cardTitle="EXIT"
                      onCardPress={onExitEdit}
                      condition={exitStr}
                      disabled={Boolean(isSubscribingAlgo || isRunningBacktests || isStockDeployed
                        || isPublic === false)}
                      numberOfLines={1}
                      key="exit"
                      isDark={isDark}
                      cardStyles={styles.exitCard}
                      textStyles={styles.entryExitString}
                    />
                  ) : null}
                </div>
              </Expandable>
              <Expandable
                title="Backtest Parameters"
                defaultExpanded={expandKey === EXPAND_KEY_TYPE.BT_PERIOD}
                onExpandCallback={updateKey}
                expandKey={EXPAND_KEY_TYPE.BT_PERIOD}
                containerStyles={styles.expandContainer}
              >
                <div className={styles.content}>
                  <CustomText {...labelProps} className={styles.inputLabel}>
                    Initial Capital
                  </CustomText>
                  <CustomInput
                    value={initial_capital}
                    placeholder="Initial Capital"
                    onChangeText={onInputChange}
                    inputStyles={classnames(styles.fieldInput, styles.capitalInput)}
                    containerStyles={styles.fieldInputContainer}
                    inputKey="initial_capital"
                    inputRegx={new RegExp(qtyRegex)}
                  />

                  <CustomText {...labelProps} className={styles.inputLabel}>
                    Backtesting start date
                  </CustomText>
                  <DateTimePicker
                    props={{
                      timeFormat: false,
                      closeOnSelect: true,
                      dateFormat: 'DD MMM YYYY',
                      value: dt_start,
                      isValidDate: blockDtStart,
                      renderInput: renderDateInput,
                      className: styles.datePickerContainer,
                      onChange: date => setSelectedDateTime('dt_start', date),
                    }}
                  />

                  <CustomText {...labelProps} className={styles.inputLabel}>
                    Backtesting stop date
                  </CustomText>
                  <DateTimePicker
                    props={{
                      timeFormat: false,
                      closeOnSelect: true,
                      dateFormat: 'DD MMM YYYY',
                      value: dt_stop,
                      isValidDate: blockDtStop,
                      renderInput: renderDateInput,
                      className: styles.datePickerContainer,
                      onChange: date => setSelectedDateTime('dt_stop', date),
                    }}
                  />
                  {create_plus && (
                    <>
                      <CustomText {...labelProps} className={styles.inputLabel}>
                        Daily strategy cycle
                      </CustomText>
                      <ListDropdown
                        selected={daily_strategy_cycle}
                        options={strategyCycleMap}
                        changeKey="daily_strategy_cycle"
                        onChangeOption={handleChange}
                        containerStyles={styles.dropDownContainer}
                        modalStyles={styles.dropdownModal}
                        itemBtnStyles={styles.dropdownItem}
                        inputBtnStyles={classnames(
                          styles.fieldInput, styles.cycleInput,
                        )}
                        disabled={isCnc}
                        disabledMsg={disabledMsgCnc}
                      />
                    </>
                  )}
                </div>
              </Expandable>
              <>
                <div className={styles.actionBarPlaceHolder} />
                <div className={styles.actionBar}>
                  <Button
                    text="Run Backtest"
                    onPress={editParamsHandler}
                    disabled={isFetchingBacktests || isSubscribingAlgo || !showBacktestBtn}
                    buttonColor="blue"
                    labelColor="white"
                    mode="contained"
                    roundness={6}
                  />
                </div>
              </>
            </>
          )
        }}
      </EditAlgoParams>
    </div>
  )
}

const stylesheet = makeStyles({
  containerParams: {
    height: 'calc(100vh - 96px)',
    overflow: 'auto',
    position: 'relative',
  },
  content: {
    background: theme.editContentBg,
    borderRadius: '10px',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    margin: `0 ${SPACING.SPACE_20}`,
    // do not remove position it is for timePickerContainer
    position: 'relative',
  },
  qtyInput: {
    // borderColor: 'transparent',
  },
  loginModal: {
    width: 'auto',
    padding: '0px 0px',
    borderRadius: '27px',
  },
  fieldInput: {
    backgroundColor: `${theme.editContentInputBg} !important`,
    borderColor: 'transparent !important',
  },
  qtyDisabled: {
    borderColor: `${theme.createTabsBg} !important`,
    backgroundColor: `${theme.btnDisabled} !important`,
    color: theme.textSecondary,
  },
  dropdownModal: {
    backgroundColor: theme.editDropdownBg,
  },
  dropdownItem: {
    backgroundColor: theme.editDropdownItem,
    '&:hover': {
      backgroundColor: theme.editDropdownItemHover,
    },
  },
  fieldInputContainer: {
    // maxWidth: 120,
  },
  inputLabel: {
    margin: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_10}`,
    '&first-child': {
      marginTop: SPACING.SPACE_10,
    },
  },
  inputIcon: {
    position: 'absolute',
    right: 10,
    zIndex: 1,
  },
  dateInputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: theme.editContentInputBg,
    borderRadius: 4,
  },
  dateInput: {
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: 4,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    zIndex: '9',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  dateRow: {
    display: 'flex',
    alignItems: 'center',
  },
  capitalInput: {
  },
  tabContainer: {
    backgroundColor: theme.chartInputBg,
    padding: '0 ',
    height: 36,
  },
  tabActive: {
    backgroundColor: COLORS.VOILET,
  },
  chartTypeContainer: {
    // maxWidth: 150,
  },
  candleContainer: {
    maxWidth: 120,
  },
  singleTab: {
    maxWidth: 60,
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionBar: {
    padding: `${SPACING.SPACE_20}`,
    position: 'sticky',
    backgroundColor: theme.bgPrimary,
    width: '100%', // `calc(100% - ${SPACING.SPACE_20})`,
    bottom: 0,
    zIndex: 99,
    '& > button': {
      width: '100%',
      padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    },
  },
  actionBarPlaceHolder: {
    height: 60,
  },
  positionsContainer: {
    padding: `0 ${SPACING.SPACE_20}`,
  },
  positions: {
    marginTop: SPACING.SPACE_12,
  },
  contentStyles: {},
  positionSizingInput: {
    height: 40,
  },
  tpslTypeInput: {
    height: 40,
    maxWidth: 90,
  },
  wrap: {
    flexWrap: 'wrap',
  },
  entryExitHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  editBtn: {},
  seperator: {
    height: '1px',
    width: '100%',
    margin: `${SPACING.SPACE_20} 0`,
    backgroundColor: theme.borderColor,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: `0 0 ${SPACING.SPACE_20}`,
    '&:last-child': {
      margin: 0,
    },
  },
  rowLabel: {
    flex: 1,
    margin: 0,
  },
  entryCard: {
    boxShadow: 'none !important',
    borderRadius: 0,
    backgroundColor: 'transparent !important',
    padding: 0,
    marginTop: SPACING.SPACE_10,
  },
  exitCard: {
    boxShadow: 'none !important',
    borderRadius: 0,
    backgroundColor: 'transparent !important',
    padding: 0,
  },
  timeInput: {
    border: `1px solid ${theme.inputBorder}`,
    borderRadius: 4,
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    zIndex: '9',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  sltpInputContainer: {
    maxWidth: 90,
  },
  timeInputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    marginLeft: SPACING.SPACE_20,
  },
  entryExitString: {
    lineHeight: '30px',
  },
  day: {},
  selectedDay: {
    backgroundColor: `${COLORS.VOILET} !important`,
    borderRadius: 4,
    color: COLORS.WHITE,
  },
  timePickerContainer: {
    position: 'unset',
    maxWidth: 120,
    '& > div:nth-child(2)': {
      position: 'absolute',
      // top: '10px',
      left: SPACING.SPACE_2,
      bottom: '130px',
    },
  },
  datePickerContainer: {
    position: 'unset',
    '& > div:nth-child(2)': {
      width: '100%',
      position: 'absolute',
      top: '10px',
      left: '0',
    },
  },
  chartTooltip: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    overflow: 'hidden',
    zIndex: 9,
    // border: '1px solid #9BABC7',
  },
  tooltipContainer: {
    backgroundColor: theme.tooltipBg,
    borderRadius: 6,
  },
  expandContainer: {
    boxShadow: 'none !important',
  },
})

const mapDispatchToProps = (dispatch) => {
  return {
    onEditParams: (params, runBacktest) => dispatch(updateBacktestParams(params, runBacktest)),
    onEditSubscribedAlgo: () => dispatch(editSubscribedAlgo()),
    toggleAuthGreetingModal: (modalType, routeAfterActivation) => dispatch(
      toggleAuthGreeting(modalType, routeAfterActivation),
    ),
    toggleAuthModal: (state, postLoginParams, loginShared) => dispatch(
      toggleLoginModal(state, postLoginParams, loginShared),
    ),
    updateTour: index => dispatch(updateTourIndex(index)),
    changePtStatus: status => dispatch(changePtComponentStatus(status)),
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    isLoggedIn: state.auth.isLoggedIn,
    onGoingBacktests: state.backtests.onGoingBacktests,
    backtestParams: state.backtests.backtestParams,
    backtest: state.backtests.backtests,
    isFetchingBacktests: state.backtests.isFetchingBacktests,
    activeTourIndex: state.common.activeTourIndex,
    startTour: state.common.startTour,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditParams)
