import React, { PureComponent } from 'react'
import cloneDeep from 'lodash/cloneDeep'
// import isEmpty from 'lodash/isEmpty'

import { isEmpty } from 'lodash'
import Icon from '../../UI/Icon'
import CustomText from '../../UI/CustomText'
// import fTree from './tree.json'
import IndicatorSection from './IndicatorSection'
import EmptyContainer from '../../UI/EmptyContainer'
import CustomInput from '../../UI/CustomInput'
import { showSnackbar } from '../../UI/Snackbar'
import Switch from '../../UI/Switch'
import ModalDropdown from '../../UI/Dropdown/ModalDropdown'

import {
  COLORS, SPACING, DIMENSIONS, FONTS, theme, ICONS,
} from '../../Theme'
import {
  candle_freq_map, decimalRegex, MATH_FUNCS, TPSL_TYPES,
} from '../../utils/consts'
import { withTheme } from '../../Theme/ThemeProvider'
import {
  getLocalStorage, screenerLogicStringGenerator, setLocalStorage,
} from '../../utils/common'

const tourCondition = [
  {
    name: 'SMA(close, 12, 0)',
    show: false,
    type: 'func',
    mathFunc: false,
    subType: 'indicators',
    valid: true,
    details: {
      field: 'close',
      period: '12',
      offset: '0',
    },
    conditions: [],
    id: 1560327367356,
    indicatorDetails: {
      name: 'SMA',
      allowed_comparison: [
        'Overlap Studies',
        'BollingerBand',
        'Math operators',
        'Numbers',
        'Price',
        'ATR Bands',
      ],
      function_group: 'Overlap Studies',
      syntax: 'SMA(<field>, <period>, <offset>)',
      params: [
        [
          'field',
          'close',
          true,
          'dropdown',
          [
            'open',
            'high',
            'low',
            'close',
            'volume',
          ],
        ],
        [
          'period',
          20,
          true,
        ],
        [
          'offset',
          0,
          true,
        ],
      ],
      description: 'Simple moving average',
    },
  },
  {
    name: 'higher than',
    comparator: {
      description: 'higher than',
      allowed_comparison: '',
      function_group: '',
      syntax: 'higher than',
      params: [],
      examples: [],
      name: 'higher than',
    },
    show: false,
    type: 'func',
    mathFunc: false,
    subType: 'comparators',
    valid: true,
    details: {},
    conditions: [],
    id: 1560327369489,
    indicatorDetails: {},
  },
  {
    name: 'SMA(close, 26, 0)',
    show: false,
    type: 'func',
    mathFunc: false,
    subType: 'indicators',
    valid: true,
    details: {
      field: 'close',
      period: '26',
      offset: '0',
    },
    conditions: [],
    id: 1560327372009,
    indicatorDetails: {
      name: 'SMA',
      allowed_comparison: [
        'Overlap Studies',
        'BollingerBand',
        'Math operators',
        'Numbers',
        'Price',
        'ATR Bands',
      ],
      function_group: 'Overlap Studies',
      syntax: 'SMA(<field>, <period>, <offset>)',
      params: [
        [
          'field',
          'close',
          true,
          'dropdown',
          [
            'open',
            'high',
            'low',
            'close',
            'volume',
          ],
        ],
        [
          'period',
          20,
          true,
        ],
        [
          'offset',
          0,
          true,
        ],
      ],
      description: 'Simple moving average',
    },
  },
]
class ConditionRenderer extends PureComponent {
  constructor(props) {
    super(props)
    const { startTour, editType, CONDITION_TYPE } = this.props
    if(startTour && editType !== CONDITION_TYPE.EXIT) this.saveConditions(tourCondition, 0)
  }

  componentDidMount() {
    const { changePtComponentStatus, startTour } = this.props
    if(startTour && changePtComponentStatus) {
      changePtComponentStatus('landedExitStep')
    }
  }

  componentDidUpdate(prevProps) {
    const { activeTourIndex, changePtComponentStatus, isMobile } = this.props
    if(isMobile && prevProps.activeTourIndex === 4 && activeTourIndex === 4) {
      setTimeout(() => {
        changePtComponentStatus('assistModeOn')
      }, 500)
    }
  }

  // one more in screener parent
  validity = (indicatorSec, show = true) => {
    const {
      conditions,
    } = indicatorSec
    let valid = true
    if (conditions.length === 0) {
      if (show) {
        showSnackbar('Conditions cannot be empty')
      }
      return false
    }
    let stack = 0
    for (let i = 0; i < conditions.length; i++) {
      if (conditions.length - 1 === i && conditions.length !== 1 && conditions[i].type === 'func' && !conditions[i].mathFunc) {
        return true
      }
      const condition = conditions[i]
      if (condition.type === 'mathFunc' || condition.subType === 'math functions') {
        if (condition.conditions.length === 0) {
          valid = false
          if (show) {
            showSnackbar('Condition seems to be incomplete')
          }
          break
        } else if (condition.conditions.length > 0) {
          const multiConditionValid = this.validity(condition, show)
          if (!multiConditionValid) {
            valid = false
            if (show) {
              showSnackbar('Math function condition seems to be incomplete')
            }
            break
          }
        }
        if (condition.multiConditions && condition.multiConditions[0] === 'array') {
          if (condition.multiConditions[1] && condition.multiConditions[1].length === 0) {
            valid = false
            if (show) {
              showSnackbar('Math function condition seems to be incomplete')
            }
            break
          } else {
            const multiConditionValid = this.validity(
              { conditions: condition.multiConditions[1] }, show,
            )
            if (!multiConditionValid) {
              valid = false
              if (show) {
                showSnackbar('Math function condition seems to be incomplete')
              }
              break
            }
          }
        }
        if (!condition.period) {
          if (show) {
            showSnackbar('Period missing in one of the math function')
          }
          return false
        }
        if (condition.name === MATH_FUNCS.SYMBOL.name && !condition.symbol) {
          if (show) {
            showSnackbar('Please select a valid symbol in symbol function')
          }
          return false
        }
      } else if (!condition.valid) {
        if (show) {
          showSnackbar('Condition seems to be incomplete')
        }
        valid = false
        break
      } else if (condition.name && condition.name.search('undefined') > -1) {
        if (show) {
          showSnackbar('Condition seems to be incomplete')
        }
        valid = false
        break
      } else if (condition.type === 'bracket') {
        if (condition.name === '(') {
          stack++
        } else {
          stack--
        }
      } else if (i === conditions.length - 1 && condition.type === 'filler') {
        if (show) {
          showSnackbar('Condition cannot end with math operator, and, or')
        }
        valid = false
        break
      }
    }
    if (stack !== 0) {
      if (show) {
        showSnackbar('Opening and closing brackets are not matching')
      }
      return false
    }
    return valid
  }

    saveConditions = (conditions, index, extraChanges) => {
      this.screenerEdited = true
      const { expressions } = this.props
      const modExpressions = [...expressions]
      modExpressions[index].conditions = [...conditions]
      this.validityChecker(modExpressions, false, extraChanges)
    }

    removeExpression = (id, index) => {
      const {
        expressions, andOrs, updateCopiedCondition, copiedCondition,
      } = this.props
      let modConditions = [...expressions]
      const modAndOrs = [...andOrs]
      if (modConditions.length === 1) {
        modConditions[0] = {
          id: Date.now(),
          conditions: [],
        }
      } else {
        modConditions = modConditions.filter(indi => indi.id !== id)
        modAndOrs.pop()
      }
      this.screenerEdited = true
      this.validityChecker(modConditions, false)
      if (index === copiedCondition.copiedIndex) updateCopiedCondition({ copiedCondition: {} })
    }

    updateAndOr = (i, value) => {
      this.screenerEdited = true
      const { handleAndOrChange, editType } = this.props
      handleAndOrChange(editType, i, value)
    }

    validityChecker = (expressions, show, extraChanges = {}) => {
      const {
        conditionsValid, editType, andOrs, CONDITION_TYPE,
      } = this.props
      let changes = {}
      if (editType === CONDITION_TYPE.EXIT) {
        changes = { showExitIndicator: expressions[0] && expressions[0].conditions.length > 0 }
      }
      for (let i = 0; i < expressions.length; i++) {
        if (!this.validity(expressions[i], show)) {
          conditionsValid(expressions, editType, andOrs, false, { ...extraChanges, ...changes })
          return false
        }
      }
      conditionsValid(expressions, editType, andOrs, true, { ...extraChanges, ...changes })
      return true
    }

    handleConditionAddition = (index) => {
      const {
        expressions, andOrs, handleConditionAddition, editType,
      } = this.props
      const lastEntry = expressions[index]
      let valid = false
      if (!this.validity(lastEntry, false)) {
        showSnackbar('Complete the previous Condition')
      } else {
        this.screenerEdited = true
        handleConditionAddition(expressions, editType, andOrs)
        valid = true
      }
      return valid
    }

  onCopy = ({ index }) => {
    const {
      expressions,
      editType,
    } = this.props
    if (!this.validity(expressions[index], false)) {
      showSnackbar('Cannot copy an incomplete condition', {}, 0)
      return
    }
    setLocalStorage({
      copiedConditionCookie: JSON.stringify({
        conditions: cloneDeep(expressions[index]),
        copiedIndex: index,
        copiedEditType: editType,
        mode: 'advance',
      }),
      copiedExpression: JSON.stringify(expressions),
    })
    showSnackbar('Condition copied successfully', {}, 1)
    try {
      navigator.clipboard.writeText(screenerLogicStringGenerator([expressions[index]], []))
    } catch(err) {
      console.log(err.message, 'err getting string')
    }
  }

  onPaste = ({ index }) => {
    const {
      expressions,
      editType,
      CONDITION_TYPE,
    } = this.props
    const cookie = getLocalStorage('copiedConditionCookie')
    const parsedCondition = JSON.parse(cookie)
    const { copiedEditType, conditions, mode } = parsedCondition
    if(mode === 'basic') {
      showSnackbar('Conditions copied in Basic mode cannot be pasted in Advance mode')
      return
    }
    if (!parsedCondition || (parsedCondition && !parsedCondition.conditions)) {
      showSnackbar('Copied condition is invalid', {}, 0)
      return
    }
    if(editType !== 'expressions' && copiedEditType === 'expressions') {
      const blocked = conditions.conditions.filter((indicator) => {
        return indicator.indicatorDetails?.key === 'pre_market'
      })
      if(blocked.length) {
        showSnackbar('Copied condition is invalid as Pre Market indicator is not available for creating strategies')
        return
      }
    }
    if(editType !== CONDITION_TYPE.EXIT
      && (copiedEditType === CONDITION_TYPE.EXIT)) {
      const blocked = conditions.conditions.filter((indicator) => {
        return indicator.indicatorDetails?.key === 'signal candle' || indicator.indicatorDetails?.key === 'Trade candle'
      })
      if(blocked.length) {
        showSnackbar('Copied condition is invalid as signal candle and trade candle indicator are not available for creating entry conditions in strategies or scanners')
        return
      }
    }
    this.screenerEdited = true
    const modExpressions = [...expressions]
    modExpressions[index] = {
      ...cloneDeep(parsedCondition.conditions), id: Date.now(), disabled: false,
    }
    this.validityChecker(modExpressions, false)
  }

  toggleDisableCondition = ({ index, disabled }) => {
    const {
      expressions, conditionsValid, editType, andOrs,
    } = this.props
    const modifiedData = [...expressions]
    modifiedData[index] = { ...expressions[index], disabled }
    conditionsValid(modifiedData, editType, andOrs)
  }

  handleConditionAdditionBasic = () => {
    const {
      toggleExitConditionText, editType, showExitIndicator, CONDITION_TYPE,
      expressions,
    } = this.props
    if (editType === CONDITION_TYPE.EXIT && !showExitIndicator) {
      toggleExitConditionText()
      return
    }
    this.handleConditionAddition(expressions.length === 0 ? 0 : (expressions.length - 1))
  }

  renderBasicAddBtn = () => {
    const {
      styles, isDark, editType, CONDITION_TYPE, showExitIndicator,
    } = this.props
    return (
      <button
        type="button"
        onClick={this.handleConditionAdditionBasic}
        className={styles.addConditionBtnBasic}
        style={{ backgroundColor: isDark ? COLORS.BLACK_100 : COLORS.ORANGE_000 }}
      >
        <Icon
          name={ICONS.ADD}
          size={14}
          color={COLORS.ORANGE}
        />
        &nbsp;
        &nbsp;
        <CustomText weight="semi_bold" color="orange">
          {editType === CONDITION_TYPE.EXIT && !showExitIndicator
            ? 'Add Exit Condition(Optional)' : 'Add Another Condition'}
        </CustomText>
      </button>
    )
  }

  renderConditions = () => {
    const {
      // editMode,
      // assistMode,
      candleInterval,
      trainerModel,
      assist,
      showError,
      indicatorsList,
      createStrategy,
      CONDITION_TYPE,
      editType,
      styles,
      isDark,
      expressions,
      andOrs,
      // copiedCondition,
      subscription_type,
      toggleAuthGreeting,
      showExitIndicator,
      isBasicMode,
      startTour,
      updateGA,
    } = this.props
    const intervalIndex = candle_freq_map[candleInterval]
    if (editType === CONDITION_TYPE.EXIT && !showExitIndicator && isBasicMode) {
      return null
    }
    let cookie = {}
    const copiedConditionCookie = getLocalStorage('copiedConditionCookie')
    if(copiedConditionCookie) {
      cookie = copiedConditionCookie
    }
    const parsedCondition = cookie.length ? JSON.parse(cookie) : {}
    const {
      copiedIndex,
      copiedEditType,
    } = parsedCondition || {}
    let showPaste = false
    if(!isEmpty(cookie)) {
      if(parsedCondition && parsedCondition.conditions) {
        showPaste = true
      }
    }
    return expressions.map((indicator, i) => {
      const andor = andOrs[i]
      const showAndOr = i !== 0
      // const editable = (editMode || assistMode) && this.validity(indicator)

      return (
        <IndicatorSection
          key={`${indicator.id}`}
          showAndOr={showAndOr}
          andor={andor}
          candleIntervalIndex={intervalIndex}
          trainerModel={trainerModel}
          saveConditions={this.saveConditions} // addIndicatorDetails
          indicatorState={indicator.conditions}
          disabled={indicator.disabled}
          id={indicator.id}
          removeIndicator={this.removeExpression}
          handleAndOrChange={this.updateAndOr}
          assist={assist}
          index={i}
          styles={styles}
          indicatorsList={indicatorsList}
          showError={showError}
          lastEle={expressions.length - 1 === i}
          handleConditionAddition={this.handleConditionAddition}
          createStrategy={createStrategy}
          CONDITION_TYPE={CONDITION_TYPE}
          editType={editType}
          isDark={isDark}
          theme={theme}
          copiedIndex={copiedIndex}
          // showPaste={conditions && conditions.conditions.length}
          showPaste={showPaste}
          onCopy={this.onCopy}
          copiedEditType={copiedEditType}
          onPaste={this.onPaste}
          toggleDisableCondition={this.toggleDisableCondition}
          subscription_type={subscription_type}
          toggleAuthGreeting={toggleAuthGreeting}
          isBasicMode={isBasicMode}
          showExitIndicator={showExitIndicator}
          startTour={startTour}
          updateGA={updateGA}
        />
      )
    })
  }

  onTpslChange = (e, field) => {
    const { onSlChange, onTpChange, handleNumericChangeforTsl } = this.props
    const { value } = e.target
    if (field === 'sl') onSlChange(value)
    if (field === 'tp') onTpChange(value)
    if (field === 'tsl') handleNumericChangeforTsl(value)
  }

  renderSltp = () => {
    const {
      showError, stopLoss, stopLossErr, takeProfit, takeProfitErr,
      styles, slLabel, tpLabel, tslLabel, trailing_slErr, trailing_sl,
    } = this.props
    return (
      <div className={styles.tpslCard}>
        <div className={styles.tpsl}>
          <CustomText weight="medium" color="red">{slLabel}</CustomText>
          &nbsp;
          &nbsp;
          <div className={styles.tpslRow}>
            <CustomInput
              inputError={(showError && !stopLoss) || stopLossErr}
              value={stopLoss}
              onChangeText={this.onTpslChange}
              inputKey="sl"
              inputStyles={styles.fieldInput}
              containerStyles={styles.fieldInputContainer}
              wrapperStyles={styles.fieldInputWrapper}
              placeholder="00"
              inputRegx={new RegExp(decimalRegex)}
            />
            {/* {(showError && !stopLoss) || stopLossErr ? (
              <CustomText size="tiny" flex={1} style={{ textAlign: 'right' }}>
                {'This field is mandatory  '}
                <Icon name={ICONS.WARN} size={12} color={COLORS.RED} />
              </CustomText>
            ) : null} */}
          </div>
        </div>
        <div className={styles.tpsl}>
          <CustomText weight="medium" color="green">{tpLabel}</CustomText>
          &nbsp;
          &nbsp;
          <div className={styles.tpslRow}>
            <CustomInput
              inputError={(showError && !takeProfit) || takeProfitErr}
              value={takeProfit}
              onChangeText={this.onTpslChange}
              inputKey="tp"
              inputStyles={styles.fieldInput}
              containerStyles={styles.fieldInputContainer}
              wrapperStyles={styles.fieldInputWrapper}
              inputRegx={new RegExp(decimalRegex)}
              placeholder="00"
            />
            {/* {(showError && !takeProfit) || takeProfitErr ? (
              <CustomText size="tiny" flex={1} style={{ textAlign: 'right' }}>
                {'This field is mandatory  '}
                <Icon name={ICONS.WARN} size={12} color={COLORS.RED} />
              </CustomText>
            ) : null} */}
          </div>
        </div>
        <div className={styles.tpsl}>
          <CustomText weight="medium">{`${tslLabel} (optional)`}</CustomText>
          &nbsp;
          &nbsp;
          <div className={styles.tpslRow}>
            <CustomInput
              inputError={trailing_slErr}
              value={trailing_sl}
              onChangeText={this.onTpslChange}
              inputKey="tsl"
              inputStyles={styles.fieldInput}
              containerStyles={styles.fieldInputContainer}
              wrapperStyles={styles.fieldInputWrapper}
              placeholder="00"
              inputRegx={new RegExp(decimalRegex)}
            // inputType="number"
            />
          </div>
        </div>
      </div>
    )
  }

  renderSltpType = () => {
    const { styles, tpsl_type, onSltpTypeChange } = this.props
    return (
      <div className={styles.row}>
        <CustomText weight="semi_bold" size="medium">TPSL type</CustomText>
        <ModalDropdown
          selected={tpsl_type}
          options={TPSL_TYPES}
          changeKey="tpsl_type"
          onChangeOption={onSltpTypeChange}
          containerStyles={styles.dropDownContainer}
          inputStyles={styles.fieldInput}
        />
      </div>
    )
  }

  onUpdateGA = () => {
    const { updateGA } = this.props
    updateGA('Highlight Click', 'Ct Strat Pg 11', 1, 'Product Tour')
  }

  render() {
    const {
      assist,
      toggleAssist,
      // toggleCreateAdvance,
      title,
      expressions,
      createAdvance,
      // showCreateAdvance,
      showSltp,
      // headerStyles,
      styles,
      isDark,
      createStrategy,
      containerStyles = '',
      isBasicMode,
      startTour,
      activeTourIndex,
    } = this.props
    const isConditionEmpty = expressions[0].conditions?.length === 0
    const conditionText = showSltp ? 'an exit' : 'an entry'
    const ptCreateId5 = 'tour_scan_create_5'
    return (
      <div
        id="tour_create_strategy_11"
        className={`${styles.conditionContainer} ${containerStyles}`}
        style={{
          flex: isConditionEmpty && !showSltp ? 1 : 0,
        }}
        role="button"
        onClick={this.onUpdateGA}
        tabIndex={0}
        onKeyUp={this.onUpdateGA}
      >
        <div className={styles.header}>
          <CustomText size="medium" className={styles.title} weight="semi_bold">
            {title}
          </CustomText>
          {showSltp && createAdvance ? this.renderSltpType() : (
            <div
              className={styles.assist}
              id={ptCreateId5}
              style={{
                padding: startTour ? 10 : 0,
              }}
            >
              <div id="tour_create_strategy_9" className={styles.assist}>
                <CustomText weight="regular" color={assist ? 'linkColor' : 'text'} size="small">Assist</CustomText>
                <Switch
                  value={activeTourIndex === 5 || activeTourIndex === 9 ? true : assist}
                  onValueChange={toggleAssist}
                  id="screenerAssist"
                />
              </div>
            </div>
          )}
        </div>
        {showSltp && this.renderSltp()}
        {this.renderConditions()}
        {isBasicMode && this.renderBasicAddBtn()}
        {isConditionEmpty && !showSltp && !isBasicMode && (
          <EmptyContainer
            title="Add a Condition"
            subTitle={`Add ${createStrategy ? conditionText : ''} condition by searching\n for indicators and comparators`}
            imgSrc="CREATE_EMPTY"
            textColor={isDark ? 'black_80' : ''}
            contentStyles={styles.emptyContent}
            containerStyles={styles.emptyContainer}
            imgContainerStyles={styles.emptyContentStyles}
            subTitleStyles={styles.emptySubTitle}
            titleProps={{
              size: 'medium',
            }}
          />
        )}
      </div>
    )
  }
}

const stylesheet = ({
  conditionContainer: {
    margin: `${SPACING.SPACE_40} 0 0`,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  assist: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  assistSwitch: {
    marginLeft: SPACING.SPACE_6,
  },
  assistSwitchIos: {
    transform: [{ scaleX: 0.65 }, { scaleY: 0.65 }],
  },
  emptyContainer: {
    paddingHorizontal: DIMENSIONS.SPACE_HORIZONTAL,
    flex: 1,
    marginTop: SPACING.SPACE_28,
  },
  emptyContent: {
    marginTop: SPACING.SPACE_20,
    padding: `0 0 0 ${SPACING.SPACE_20}`,
  },
  emptyContentStyles: {
    textAlign: 'center',
  },
  advanceToggleBtn: {
    fontSize: FONTS.TINY,
    paddingVertical: SPACING.SPACE_4,
    paddingHorizontal: SPACING.SPACE_10,
  },
  emptySubTitle: {
    whiteSpace: 'pre-line',
    marginTop: SPACING.SPACE_10,
  },
  advanceToggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // flex: 0.5,
    width: '50%',
    marginBottom: SPACING.SPACE_10,
  },
  tpslCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${SPACING.SPACE_24}`,
    background: theme.bgPrimary,
    borderRadius: '10px',
    marginTop: SPACING.SPACE_12,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
  },
  tpsl: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  tpslRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginRight: '10%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputContainer: {
    width: 80,
    borderRadius: 8,
    borderColor: COLORS.BORDER_COLOR,
    borderWidth: 1,
    padding: 0,
    margin: 0,
    backgroundColor: COLORS.SCREEN_BG, // isDark ? COLORS.BLACK_100 : COLORS.SCREEN_BG,
    marginRight: SPACING.SPACE_16,
  },
  input: {
    margin: 0,
    paddingVertical: SPACING.SPACE_8,
    paddingHorizontal: SPACING.SPACE_10,
  },
  dropDownContainer: {
    marginLeft: SPACING.SPACE_20,
  },
  fieldInput: {
    border: `1px solid ${theme.inputBorder}`,
    borderRadius: 4,
    backgroundColor: theme.bgPrimary,
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  fieldInputWrapper: {
    flex: 1,
    maxWidth: 200,
  },
  title: {
    width: '50%', alignSelf: 'flex-start',
  },
  addConditionBtnBasic: {
    borderRadius: 6,
    borderColor: COLORS.ORANGE,
    borderWidth: 2,
    borderStyle: 'dashed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 55,
    width: '100%',
    marginTop: SPACING.SPACE_28,
  },

  '@media only screen and (max-width: 786px)': {
    tpslCard: {
      display: 'block',
      '& $tpsl': {
        '&:first-child': {
          marginBottom: SPACING.SPACE_20,
        },
        '&:nth-child(2n)': {
          marginBottom: SPACING.SPACE_20,
        },
        '& > p': {
          width: '40%',
        },
      },
    },
    title: {
      marginTop: SPACING.SPACE_6,
      width: 'unset',
    },
    row: {
      flex: 1,
      justifyContent: 'flex-end',
    },
    tpslRow: {
      margin: 0,
    },
  },
  '@media only screen and (max-width: 526px)': {
    header: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    title: {
      margin: `0 0 ${SPACING.SPACE_10}`,
    },
    row: {
      justifyContent: 'space-between',
      width: '100%',
    },
  },
})

export default withTheme(stylesheet)(ConditionRenderer)
