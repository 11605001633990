/* eslint-disable semi-spacing */
/* eslint-disable prefer-const */
import React, { PureComponent } from 'react'
import isEqual from 'lodash/isEqual'
import { Helmet } from 'react-helmet-async'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'

import dayjs from 'dayjs'
import Lottie from 'react-lottie'
import BacktestMarkets from '../../components/Backtests/BacktestMarkets'
import Button from '../../UI/Button'
import BacktestSelf, { isBacktestExist } from '../../components/Backtests/Backtests'
import BacktestSubscribed from '../../components/Backtests/BacktestSubscribed'
import CustomModal from '../../UI/CustomModal'
import CreateNewList from '../../UI/CreateNewList'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
// import IndicatorDetails from '../../components/Create/IndicatorDetails'
import AddScrips from '../../components/SymbolFinder/AddScrips'
import Deploy from '../../components/DeploySymbols'
import AlertPopup from '../../UI/AlertPopup'
import processBrokerage from '../../utils/brokerageCalculations'
import IndicatorDetails from '../../components/Create/IndicatorDetails'
import BacktestLoader from '../../UI/Loaders/Backtests/BacktestLoader'
import BacktestShared from '../../components/Backtests/BacktestShared'

import { NAVIGATIONS, pathname_mapping } from '../../utils/navigationConstant'
import {
  condensed_candle_map, STOCK_ADD_LIMIT, holding_type_map, BROKERS, ROOT_MODALS,
  ORDER_TYPE, candle_freq_map, MATH_FUNCS,
  date_range_epochs,
  algoDateFormat,
  LINK_MAP,
  ULTIMATE_SEGMENT_OBJ, // candle_freq_map,
} from '../../utils/consts'
import { ALGO_KEY_MAP, backtestDisclaimer } from '../../components/AlgoCondensedView/AlgoUtils'
import {
  completeAlgoSummary, getBacktestDetails, getChartType, segSymConverter,
  parseQuery,
  removeObjectProperties,
  setLocalStorage,
  getLocalStorage,
  setCookie,
} from '../../utils/common'
import {
  DIMENSIONS, COLORS, SPACING, ICONS, theme, ASSETS,
} from '../../Theme'
import { showSnackbar } from '../../UI/Snackbar'
import {
  getDynamicSymbol, dynamicScripParams, CONDITION_TYPE, assumptionTokenMsgMap,
} from '../Create/Create-utils'
import { accessConditions, PRO_FEATURE_MAP, userAccessCheck } from '../../utils/userAccessChecks'
import { withTheme } from '../../Theme/ThemeProvider'
import { getReqBacktests } from '../../components/Backtests/BacktestResults'
import { DEPLOYED_TABS } from '../Deployed/deployedUtils'
import { PREFERENCES } from '../Profile/profileUtils'
import fTree from '../../components/Create/tree.json'
import { routeToCreate } from '../../utils/experimenting'
import { ctbHelpUrl } from '../../utils/apiEndpoints'
import { discoverDisclaimerRenderer } from '../Common/Common'
import { PRODUCT_TOUR_SECTIONS } from '../../components/ProductTour/ProductTour-utils'

export const STRATEGY_TYPE = {
  MARKET: 'Discover',
  SUBSCRIBED: 'My Picks',
  MY_ALGOS: 'My Strategies',
  SHARED: 'Shared Strategies',
}

const gaCategoryMap = {
  [STRATEGY_TYPE.MARKET]: 'Discover backtest result',
  [STRATEGY_TYPE.SUBSCRIBED]: 'Subscribed backtest result',
  [STRATEGY_TYPE.SHARED]: 'Shared backtest result',
  [STRATEGY_TYPE.MY_ALGOS]: 'Backtest result',
}

let gaParamsMap = {}

export const setEditGAParams = (newParams) => {
  Object.assign(gaParamsMap, newParams)
}

const { DeploySuccess, SUCCESS_LOTTIE } = ASSETS

class Backtests extends PureComponent {
  constructor(props) {
    super(props)
    const { startTour } = this.props
    this.algoInfo = {}
    this.details = {}
    this.extraDetails = {}
    this.newScripList = []
    // this.brokerageData = {}
    this.initialFetch = true
    const detailView = getLocalStorage(PREFERENCES.DETAIL_VIEW)
    const isListView = startTour ? false : !!(detailView && detailView === 'list') || false
    this.state = {
      renderType: '',
      graphList: [],
      tradeLogList: [],
      backtestResults: [],
      showBacktestBtn: false,
      allDeployed: true,
      selectedSymbols: new Set(),
      removeSymbolsModal: false,
      deploySuccessModal: false,
      isFocused: true,
      algoId: '',
      showScripParams: false,
      scripList: [],
      reRunScrips: {},
      showNameChange: false,
      selectedIndex: 0,
      isPinned: '',
      addScrips: false,
      sliderProps: {
        scrollType: 'next',
      },
      showDeploy: false,
      brokerage: false,
      fromOnboard: false,
      brokerageData: {},
      pinCumulativePnl: props.websiteConfig.pinCumulativePnl === true,
      isListView,
      showWarning: 0,
      userCanceledWarning: false,
    }
    this.modifiedScrips = new Set()
    this.addScripParams = {}
    this.deployParams = {}
    this.selectedItem = {}
    this.routeParams = {}
    this.isSharedBt = false
    this.canRunGoBt = false
    this.successModalProps = {}
  }

  componentDidMount() {
    const {
      location: { search, state = {}, pathname = '' }, isLoggedIn,
      isWasmLoaded, history,
    } = this.props
    const { onboard, ctb } = parseQuery(search, true)
    const { ref_id } = parseQuery(search)
    const { assumptionToken = 0 } = state
    if (!isLoggedIn && ref_id) {
      setCookie({
        ref_id,
      })
    }
    this.setState({ showWarning: assumptionToken, userCanceledWarning: false })

    if (pathname === pathname_mapping.BacktestsSharedApp) {
      history.replace({
        pathname: pathname_mapping.Backtests,
        search,
      })
    }

    if (onboard) {
      this.setState({ fromOnboard: true })
      this.nameChangeHandler()
    } else if (!ctb || (ctb && isWasmLoaded)) {
      this.fetchBacktests()
    } else if ((ctb && !isWasmLoaded)) {
      this.setState({
        renderType: STRATEGY_TYPE.MY_ALGOS,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      backtests,
      backtestsError,
      backtestsErrorMsg,
      isCloningAlgo,
      cloneAlgoResp,
      cloneAlgoError,
      cloneAlgoErrorMsg,
      copyAlgoResp,
      newScripList,
      updateBacktestScrips,
      isSavingBacktestParams,
      saveBacktestParamsSuccess,
      saveBacktestParamsError,
      saveBacktestParamsErrorMsg,
      deploymentError,
      marketDeployError,
      isDeployingStocks,
      deployResponse,
      history,
      backtestParams,
      paramsEdited,
      toggleEditSubscribed,
      fetchBacktests,
      refreshAlgos,
      newScripListParams,
      isWasmLoaded,
      activeTourIndex,
      tourType,
      changePtComponentStatus,
      startTour,
    } = this.props
    const { create_plus } = backtests

    if (!isEqual(isWasmLoaded, prevProps.isWasmLoaded) && isWasmLoaded) {
      this.fetchBacktests()
    }

    if (!isEqual(backtests, prevProps.backtests) && backtests.backtest_results) {
      this.formatbacktestResults()
    } else if (!startTour && prevProps.backtestsError !== backtestsError && backtestsError) {
      this.showSnackbar(backtestsErrorMsg, 0)
    }
    if (!prevProps.deploymentError && deploymentError) {
      this.fetchBacktests('', true)
    }
    if (!prevProps.marketDeployError && marketDeployError) {
      this.fetchBacktests('', true)
    }
    // published algo
    if (prevProps.isCloningAlgo !== isCloningAlgo && !isCloningAlgo) {
      if (cloneAlgoResp.status === 'success') {
        const { algo_uuid } = cloneAlgoResp
        this.details = {}
        this.algoInfo = {}
        fetchBacktests({ algo_uuid }, true)
        history.replace({
          pathname: pathname_mapping.Backtests,
          search: `?algo_uuid=${btoa(algo_uuid)}`,
        })
        this.setState({ showNameChange: false })
        refreshAlgos('algos', true)
        // history.push({
        //   pathname: pathname_mapping.Create,
        //   search: `?id=${btoa(algo_uuid)}`,
        // })
        // refreshAlgos('algos', true)
      } else if (cloneAlgoError) {
        const errMsg = typeof cloneAlgoErrorMsg === 'string' ? cloneAlgoErrorMsg : 'Error subscribing algo'
        if (cloneAlgoErrorMsg && errMsg.toLowerCase() === 'strategy name already present') {
          this.setState({ showNameChange: true })
          // as we only show error if it is user typed name
          if (this.showCloneError) {
            showSnackbar(errMsg)
            this.showCloneError = false
          }
        } else {
          this.showSnackbar(errMsg, {}, 0)
        }
      }
    }
    if (!isEqual(copyAlgoResp, prevProps.copyAlgoResp) && copyAlgoResp && copyAlgoResp.algo_uuid) {
      this.refetchParams = {
        algo_uuid: copyAlgoResp.algo_uuid,
      }
      this.details = {}
      this.algoInfo = {}
    }
    // if (prevProps.isCloningShare !== isCloningShare && !isCloningShare) {
    //   if (cloneShareResp.status === 'success') {
    //     this.toggleNameChangeModal()
    //     const { algo_uuid } = cloneShareResp
    //     navigation.navigate(NAVIGATIONS.CREATE.name, { algo_uuid })
    //   } else if (cloneShareError) {
    //     showSnackbar(cloneShareErrorMsg)
    //   }
    // }
    if (newScripList && !isEqual(prevProps.newScripList, newScripList)) {
      let showAdvanceAlert = false
      newScripList.forEach((item) => {
        if (!create_plus && item.segment && item.segment.toLowerCase() === ULTIMATE_SEGMENT_OBJ['nfo-opt']) {
          showAdvanceAlert = true
        }
      })
      if (showAdvanceAlert && create_plus === false) {
        showSnackbar('Strategy is in basic mode. To add Options, create a new strategy in advanced mode.')
        return
      }
    }
    // adding new stocks
    if (newScripList && !isEqual(prevProps.newScripList, newScripList)) {
      this.updateScripList(newScripList, newScripListParams.runBacktest)
      updateBacktestScrips(null)
    }
    if (isSavingBacktestParams !== prevProps.isSavingBacktestParams && !isSavingBacktestParams) {
      const errMsg = typeof saveBacktestParamsErrorMsg === 'string' ? saveBacktestParamsErrorMsg : 'Error editing strategy name'
      if (saveBacktestParamsSuccess) {
        refreshAlgos('algos', true)
        this.refreshAlgoInfo = true
        this.fetchBacktests()
      } else if (saveBacktestParamsError) {
        this.showSnackbar(errMsg, {}, 0)
      }
    }
    // && !isEqual(prevProps.backtestParams, backtestParams)
    if (!isEmpty(prevProps.backtestParams)
      && paramsEdited) {
      this.updateBacktesthandler(backtestParams)
    }
    if (toggleEditSubscribed !== prevProps.toggleEditSubscribed) {
      this.editSubscribedAlgo()
    }
    if (!isDeployingStocks && prevProps.isDeployingStocks && deployResponse && deployResponse.status === 'success') {
      this.refetchParams = {
        algo_uuid: this.algo_uuid,
      }
      this.refetchBacktest()
    }

    if (startTour && tourType === PRODUCT_TOUR_SECTIONS.DEPLOY) {
      if (activeTourIndex === 2) {
        this.setState({ showDeploy: false })
      }
      if (prevProps.activeTourIndex === 2 && activeTourIndex === 3) {
        this.deployAll()
        this.setState({ showDeploy: true })
      }
      if (prevProps.activeTourIndex === 4 && activeTourIndex === 5) {
        this.setState({ showDeploy: false })
        setTimeout(() => {
          changePtComponentStatus('deploySuccessPopup')
        }, 1000)
      }
      if (activeTourIndex === 6) {
        this.dismissDeploy('success', {})
        history.push({
          pathname: pathname_mapping.Strategies,
          search: '?tab=paper&state=waiting',
        })
      }
    }
    setTimeout(() => changePtComponentStatus('deployAllClicked'), 500)
    if (prevProps.startTour && startTour === false) {
      this.setState({ showDeploy: false, deploySuccessModal: false })
    }
  }

  componentWillUnmount() {
    const { unSubscribe, clearResp } = this.props
    clearResp()
    unSubscribe(NAVIGATIONS.BACKTESTS.name, Array.from(this.modifiedScrips))
  }

  checkOnlyScannerFuncExist = (conditions, indiArr = [MATH_FUNCS.MULTI_TIME_FRAME.name]) => {
    let hasScannerOnlyFunc = false
    if (conditions.length > 0 && conditions.includes(`${indiArr[0]}(`)) {
      hasScannerOnlyFunc = true
    }

    return hasScannerOnlyFunc
  }

  shouldShowWarning = ({
    exit_logic, entry_logic,
  }) => {
    // debugger
    let showWarning = false
    let mtfExist = false

    mtfExist = this.checkOnlyScannerFuncExist(entry_logic, [
      MATH_FUNCS.MULTI_TIME_FRAME.name,
    ])

    if (exit_logic && !mtfExist) {
      mtfExist = this.checkOnlyScannerFuncExist(exit_logic, [
        MATH_FUNCS.MULTI_TIME_FRAME.name,
      ])
    }
    if (mtfExist) {
      showWarning = 'MTF'
    }
    return showWarning
  }

  formatbacktestResults = () => {
    const {
      backtests, updateBacktestParams, history,
      isWasmLoaded, changeWasmStatus, changePtComponentStatus,
    } = this.props
    const {
      renderType: currentRenderType, algoId: currentAlgoId, selectedIndex,
    } = this.state
    let renderType = currentRenderType
    let modSelectedIndex = selectedIndex
    let scripList = []
    // let details = {}

    const {
      algo_subscription_uuid, publishing_uuid, backtest_results, deployed_seg_sym = [],
      published_algo_price = {}, publisher_bio, publisher_img_url, publisher_name,
      downvotes, upvotes, view_count, voted, algo_uuid, // equitiesScrip = [],
      backtest_share_uuid, shared, algo_state = {},
    } = backtests
    const { ss } = this.routeParams
    const { isCtbCreate = false } = algo_state

    if (isCtbCreate && !isWasmLoaded) {
      changeWasmStatus(isWasmLoaded, true)
    }

    if (isEmpty(this.algoInfo) || this.refreshAlgoInfo) {
      this.refreshInfo = false
      this.algoInfo = completeAlgoSummary(
        backtests,
      )
    }
    if (
      backtest_results.length
      // && !error
      // && !waiting
      // && !algo_subscription_uuid
      // && !publishing_uuid
    ) {
      if (!publishing_uuid && !algo_subscription_uuid && backtest_results[0].algo_obj
        && backtest_results[0].algo_obj.scripList
        && backtest_results[0].algo_obj.scripList.length) {
        const { scripList: defaultScrips } = backtest_results[0].algo_obj
        this.isSharedBt = !isEmpty(backtest_share_uuid) || shared
        scripList = defaultScrips.map((item, index) => {
          const { seg_sym, segment, symbol } = item
          const segSym = seg_sym || `${segment}_${symbol}`
          if (ss === segSym) modSelectedIndex = index
          return { ...item, seg_sym: segSym }
        })
        // }
      } else {
        this.isSharedBt = !isEmpty(backtest_share_uuid)
        scripList = backtest_results.map((item, index) => {
          const { segment, symbol } = segSymConverter(item.seg_sym)
          if (ss && ss === item.seg_sym) modSelectedIndex = index
          return { seg_sym: item.seg_sym, segment, symbol }
        })
        // scripList = backtest_results.map(item => ({ seg_sym: item.seg_sym }))
      }
      this.algo_uuid = backtest_results[0].algo_obj && backtest_results[0].algo_obj.algo_uuid
    } else {
      scripList = []
    }
    this.algo_uuid = this.algo_uuid || algo_uuid
    this.extraDetails = {
      published_algo_price,
      publisher_bio,
      publisher_img_url,
      publisher_name,
      downvotes,
      upvotes,
      view_count,
      voted,
    }

    // coming from techincals
    if (this.sample && this.algo_uuid) {
      history.replace({
        pathname: pathname_mapping.Backtests,
        search: `?algo_uuid=${this.algo_uuid}`,
      })
      this.sample = false
    }

    let algoId = currentAlgoId
    // DANGER as of 26 Aug 2021 we have removed the concept of my picks from tech
    // if (!currentRenderType) {
    // if (algo_subscription_uuid && algo_subscription_uuid !== 'undefined' && algo_subscribed) {
    //   renderType = STRATEGY_TYPE.SUBSCRIBED
    //   algoId = algo_subscription_uuid
    // }
    if (publishing_uuid) {
      renderType = STRATEGY_TYPE.MARKET
      algoId = publishing_uuid
    } else if (backtest_share_uuid || shared) {
      renderType = STRATEGY_TYPE.SHARED
      algoId = backtest_share_uuid
    } else {
      renderType = STRATEGY_TYPE.MY_ALGOS
      algoId = algo_uuid
    }
    if (isEmpty(this.details)) {
      this.details = getBacktestDetails(backtests)
      const { entryStr, exitStr, algo_desc } = this.algoInfo
      updateBacktestParams({
        ...this.details, exitStr, entryStr, algoId, renderType, deployed_seg_sym, algo_desc,
      })
    }
    const allDeployed = backtest_results.length
      ? deployed_seg_sym.length === backtest_results.length : false
    // const { graphList = [], tradeLogList = [] } = this.filterGraphList(backtest_results)

    if (modSelectedIndex !== 0 && scripList[modSelectedIndex]) {
      let segSym = scripList[modSelectedIndex]
      if (typeof segSym === 'object') {
        segSym = `${segSym.segment}_${segSym.symbol}`
      }
      this.showBacktestDetails(segSym, { index: modSelectedIndex, scrollType: 'next' })
    } else {
      let segSym = scripList[0]
      if (typeof segSym === 'object') {
        segSym = `${segSym.segment}_${segSym.symbol}`
      }
      this.showBacktestDetails(segSym, { index: 0, scrollType: 'next' })
    }
    const {
      create_plus: savedCreateAdvance = false, exit_logic = '', entry_logic = '',
    } = backtests
    const showWarning = savedCreateAdvance ? this.shouldShowWarning({
      exit_logic, entry_logic,
    }) : false

    this.setState({
      // details,
      showWarning,
      renderType,
      // graphList,
      // tradeLogList,
      allDeployed,
      algoId,
      // backtestResults: [...backtest_results],
      scripList,
      gaLabel: `${gaCategoryMap[renderType]}`,
    }, () => {
      changePtComponentStatus('backtestLoaded')
    })
  }

  showSnackbar = (msg, duration, type) => {
    showSnackbar(msg, duration, type)
  }

  updateWebSiteConfig = () => {
    const { pinCumulativePnl } = this.state
    this.setState({ pinCumulativePnl: !pinCumulativePnl }, () => {
      this.props.updateWebSiteConfig('pinCumulativePnl', !pinCumulativePnl)
      if (!pinCumulativePnl) this.updateGA('Pin to top', 'Cumulative pnl')
    })
  }

  updateGA = (a, l, v) => {
    const { renderType } = this.state
    this.props.updateGA(a, l, v, gaCategoryMap[renderType])
  }

  goBack = () => { }

  focusHandler = () => {
    const {
      isFetchingBacktests, onGoingBacktests, refreshBacktestPage, backtests,
    } = this.props
    const { algoId } = this.state
    const isRunningBacktests = onGoingBacktests[algoId]
    if (algoId && !isRunningBacktests && !isFetchingBacktests && refreshBacktestPage) {
      const {
        algo_subscription_uuid, algo_uuid, publishing_uuid,
        algo_subscribed,
      } = backtests
      let extraParams = { algo_uuid }
      if (algo_subscription_uuid && algo_subscribed) {
        extraParams = { algo_subscription_uuid }
      } else if (publishing_uuid) {
        extraParams = { publishing_uuid }
      }
      this.refetchParams = extraParams
      this.refetchBacktest()
    }
    this.setState({ isFocused: true })
  }

  fetchBacktests = (new_subscription_uuid, refetch) => {
    const {
      fetchBacktests,
      location: { search, state = {}, pathname },
      updateBacktestParams,
      history, csrf, sessionid,
    } = this.props
    if (refetch) {
      if (!this.refetchParams) {
        this.showSnackbar('Not able to fetch backtest results', {}, 0)
        // this.goBack({ currentNavigation: navigation })
        return
      }
      this.backtestRan = false
      fetchBacktests(this.refetchParams, true)
      return
    }
    // clear params at bottom
    const {
      publishing_uuid,
      algo_uuid,
      sample,
      algo_share_uuid,
      sbt,
      // algo_subscription_uuid: current_subscription_uuid,
      ss, // selected stock
      ctb = false,
    } = parseQuery(search, true) || {}
    const { createAlgoParams } = state
    this.routeParams = { ss }
    // const algo_subscription_uuid = new_subscription_uuid || current_subscription_uuid
    // if (new_subscription_uuid) {
    //   this.setState({ renderType: '' })
    // }
    let new_algo_obj = {}
    if (createAlgoParams) {
      const { algo_obj, scripList, canRunGoBt = false } = createAlgoParams
      new_algo_obj = { ...algo_obj, scripList }
      this.canRunGoBt = canRunGoBt
    }
    // DANGER check for discover algo subscribe n deploy
    if (!isEmpty(new_algo_obj)) {
      this.refetchParams = {
        publishing_uuid,
        algo_uuid,
        algo_obj: new_algo_obj,
        scripList: new_algo_obj.scripList,
        sample,
        runBacktest: true,
        canRunGoBt: ctb,
        csrf,
        sessionid,
      }
      fetchBacktests(this.refetchParams, this.initialFetch)
      this.details = getBacktestDetails(new_algo_obj)
      this.algoInfo = completeAlgoSummary(new_algo_obj)
      const { entryStr, exitStr } = this.algoInfo
      // const id = algo_subscription_uuid || publishing_uuid || algo_uuid
      updateBacktestParams({
        ...this.details,
        exitStr,
        entryStr,
        algo_desc: new_algo_obj.algo_desc || '',
        algoId: algo_uuid,
        renderType: STRATEGY_TYPE.MY_ALGOS,
        deployed_seg_sym: [],
      })
      // clearing browser state params so that if user refresh the backtest should not run
      // again with the params sent by create page
      const newState = { ...state }
      delete newState.createAlgoParams
      history.replace(`${pathname}${search}`, newState)
    } else {
      const { stocks = '[]' } = parseQuery(search, false)
      const { onboard } = parseQuery(search, true)
      const scripList = JSON.parse(stocks)
      const params = {
        publishing_uuid,
        // algo_subscription_uuid,
        algo_uuid,
        sample,
        algo_obj: {},
        runBacktest: scripList.length > 0,
        scripList,
        sbt,
        algo_share_uuid,
        stocks,
        onboard,
        canRunGoBt: ctb,
        csrf,
        sessionid,
      }
      this.sample = scripList.length && sample
      if (new_subscription_uuid) {
        // params = { algo_uuid: algo_subscription_uuid, algo_subscription_uuid: undefined }
        this.details = {}
        this.algoInfo = {}
        history.replace({
          pathname: pathname_mapping.Backtests,
          search: `?algo_subscription_uuid=${btoa(new_subscription_uuid)}`,
        })
        updateBacktestParams(this.details)
      }
      this.refetchParams = params
      fetchBacktests(params, this.initialFetch)
    }
    this.backtestRan = false
    this.initialFetch = false
  }

  updateBacktesthandler = (newParams, gaLabel = 'Backtest parameters', reRunseg_sym) => {
    const {
      user_details, updateBacktest, backtests, paramsEdited,
      toggleRootModal, updateBacktestParams, refreshAlgos,
      toggleAuthGreeting, updateGA, blockedSegmentList,
      csrf, sessionid,
    } = this.props
    const { scripList } = this.state
    // to make paramsEdited false
    const {
      subscription_status, subscription_valid, status, user_uuid, subscription_type = 0,
      backtest, subscription_validity, subscription_period, subscription_plan = '',
    } = user_details

    if (!subscription_valid) {
      toggleAuthGreeting(PRO_FEATURE_MAP.MTF.ID)
      return
    }
    updateBacktestParams({})
    if (scripList.length === 0) {
      this.addNewSymbol()
      showSnackbar('Add symbols to run backtest')
      return
    }

    let algo_obj = null
    if (user_details.broker === BROKERS.ANGEL.name) {
      if (userAccessCheck({ condition: accessConditions.AB_NO_EMAIL_USAGE_LIMIT, user_details })) {
        toggleRootModal(ROOT_MODALS.showEmailModal, true)
        return
      }
    }
    if (subscription_type < 3 && backtests.backtest_results[0]
      && backtests.backtest_results[0].algo_obj
      && backtests.backtest_results[0].algo_obj.action_str) {
      const { action_str, action_str_exit } = backtests.backtest_results[0].algo_obj
      const str = action_str.concat(action_str_exit)
      if (str.toLowerCase().includes(MATH_FUNCS.MULTI_TIME_FRAME.name.toLowerCase())
        || str.toLowerCase().includes(MATH_FUNCS.MULTI_TIMEFRAME_COMPLETED.name.toLowerCase())) {
        toggleAuthGreeting(PRO_FEATURE_MAP.MTF.ID)
        return
      }
    }
    if (scripList.length > user_details.total_backtest - Math.abs(backtest)) {
      const validity_unix = dayjs(subscription_validity).unix()
      if (subscription_type >= 3 && Number(subscription_period) === 12
        && validity_unix > dayjs().unix()) { // for annaual ultimate users
        showSnackbar('Daily backtest limit reached.', {
          showReadMore: true,
          readMoreText: 'Learn how we count backtests',
          link: 'https://help.streak.tech/backtest/#backtest-per-day',
        })
      } else if (subscription_plan === 'ultimate' && Number(subscription_period) !== 12) { // for non-annual ultimate userss
        toggleAuthGreeting(PRO_FEATURE_MAP.BT_LIMIT.ID, '', false)
      } else { // for others users
        toggleAuthGreeting(PRO_FEATURE_MAP.BT_LIMIT_REGULAR.ID)
      }
      return
    }
    let shouldReturn = false
    const reRunScrips = {}
    for (let i = 0;i < scripList.length;i++) {
      const { segment = '', symbol } = scripList[i]
      const seg_sym = `${segment}_${symbol}`
      reRunScrips[seg_sym] = false
      if (blockedSegmentList && blockedSegmentList.includes(segment.toLowerCase())) {
        toggleAuthGreeting(segment.toLowerCase() === 'mcx' ? PRO_FEATURE_MAP.MCX.ID : PRO_FEATURE_MAP.OPTIONS.ID)
        shouldReturn = true
        // showSnackbar(`Upgrade to higher plan to use ${segment.toUpperCase()}`)
        break
      }
    }
    this.setState({ reRunScrips })
    if (shouldReturn) return
    if (newParams && paramsEdited) {
      algo_obj = { ...newParams, algo_name: backtests.algo_name }
    } else {
      algo_obj = this.details
      // scripList.forEach((item) => {
      //   if (item.seg_sym && item.seg_sym.includes('_')) {
      //     const { segment, symbol } = segSymConverter(item.seg_sym)
      //     scripList.push({ details: item.details, symbol, segment })
      //   }
      // })
    }
    if (!algo_obj) return
    if (algo_obj && user_details.subscription_type < 3 && /Renko/i.test(algo_obj.chart_type)) {
      this.toggleAuthGreeting(PRO_FEATURE_MAP.RENKO.ID)
      // showSnackbar('Subscribe to Ultimate plan to use Renko', {}, 0)
      shouldReturn = true
      return
    }
    if (
      status === 'success'
      && subscription_status
      && subscription_valid === false
    ) {
      // this.showSnackbar(
      //   'Your subscription is over, please renew to continue',
      // )
      this.showSnackbar(
        'Backtest limit reached.',
      )
      return
    }
    const {
      algo_uuid, publishing_uuid, create_plus, backtest_share_uuid, shared, algo_state = {},
    } = backtests
    let extraParams = { algo_uuid }
    // if (algo_subscription_uuid && algo_subscribed) {
    //   extraParams = { algo_subscription_uuid }
    // } else
    if (publishing_uuid) {
      showSnackbar('Changes made will not be saved. Copy the strategy first and then make changes to save them', { hideDelay: 2000 })
      extraParams = { publishing_uuid }
    }
    if (backtest_share_uuid) {
      extraParams = { backtest_share_uuid }
    }
    if (shared) {
      extraParams = { shared }
    }
    this.refetchParams = extraParams
    const {
      // algo_name,
      entry_logic,
      exit_logic,
      action_str,
      action_str_exit,
      action_type,
      equities,
      order_type,
      min_candle_freq,
    } = this.details
    const {
      chart_type,
      quantity,
      algo_name,
      initial_capital,
      time_frame,
      trading_stop_time,
      trading_start_time,
      dt_start,
      dt_stop,
      algo_desc = '',
      holding_type,
      position_type,
      stop_loss,
      take_profit,
    } = algo_obj
    const mod_chart_type = getChartType(chart_type)
    let isDynamicContractExist = false
    scripList.forEach((scrip) => {
      if (scrip.details || (scrip.symbol && scrip.symbol.includes('DYNAMIC CONTRACT'))) {
        isDynamicContractExist = true
        this.canRunGoBt = false
      }
    })

    let mod_dt_start = dayjs(dt_start, 'DD/MM/YYYY')
    if (isDynamicContractExist && fTree.indicators
      && fTree.indicators.option_greeks
      && entry_logic.includes(fTree.indicators.option_greeks.name)) {
      if (time_frame === 'day') {
        mod_dt_start = dayjs((dayjs(dt_stop, 'DD/MM/YYYY').unix()
          - (2 * date_range_epochs.min)) * 1000)
        showSnackbar(`For ${fTree.indicators.option_greeks.name},
          backtesting is allowed only for 2 months with ${time_frame} timeframe`)
      } else {
        mod_dt_start = dayjs((dayjs(dt_stop, 'DD/MM/YYYY').unix()
          - date_range_epochs.min) * 1000)
        showSnackbar(`For ${fTree.indicators.option_greeks.name},
    backtesting is allowed only for 1 month with ${time_frame} timeframe`)
      }
    }
    const new_details = {
      algo_name,
      algo_uuid,
      chart_type: mod_chart_type,
      entry_logic,
      exit_logic,
      equities,
      time_frame: condensed_candle_map[time_frame],
      ip_interval: condensed_candle_map[time_frame],
      position_type: position_type || action_type,
      action_type: position_type || action_type,
      dt_start: mod_dt_start.format(algoDateFormat),
      dt_stop,
      stop_loss,
      take_profit,
      action_str_exit,
      action_str,
      position_qty: quantity,
      initial_capital,
      min_candle_freq,
      trading_start_time,
      trading_stop_time,
      trade_time_given: 'True',
      algo_desc,
      order_type: order_type || ORDER_TYPE.MARKET,
      holding_type: holding_type_map[holding_type],
      create_plus: !!create_plus,
    }
    // this.setState({ details: new_details })
    if (!user_uuid) {
      this.showSnackbar('Error getting user details', {}, 0)
    } else {
      const { isCtbCreate = false } = algo_state
      const canRunGoBt = (!isDynamicContractExist && isCtbCreate) || this.canRunGoBt
      // do not change the order of desctructure
      const new_obj = {
        ...algo_obj,
        ...new_details,
        ...extraParams,
        scripList,
        user_uuid,
      }
      this.details = new_obj
      // if backtest is run then we do not fetch pnl and trade_log (backtestdetails) separatly
      this.backtestRan = true
      const extraParamsBt = {
        canRunGoBt,
        csrf,
        sessionid,
      }
      // from editparams
      if (!isEmpty(gaParamsMap)) {
        Object.keys(gaParamsMap).map(itemKey => updateGA(...gaParamsMap[itemKey]))
        gaParamsMap = {}
      }
      if (reRunseg_sym) {
        extraParamsBt.reRunseg_sym = reRunseg_sym
        extraParamsBt.backtest_results = backtests.backtest_results
        updateBacktest(
          scripList, new_obj, {}, null, extraParamsBt,
        )
      } else {
        updateBacktest(scripList, new_obj, {}, null, extraParamsBt)
        this.algoInfo = completeAlgoSummary({
          ...backtests,
          ...new_obj,
        })
        const { exitStr, entryStr } = this.algoInfo
        updateBacktestParams({
          exitStr, entryStr,
        })
      }
      this.updateGA('Run backtest', gaLabel, scripList.length)
    }
    // to refresh my strategy page
    refreshAlgos('algos', true)
  }

  reRunBacktestHandler = (seg_sym) => {
    const {
      backtests, fetchBacktestDetailsDyc, createEmptyPairEntries,
      isLoggedIn, toggleAuthModal,
    } = this.props
    const { reRunScrips, scripList } = this.state
    const results = backtests.backtest_results
    const backtest_result_map = {}
    if (!isLoggedIn) {
      toggleAuthModal(true, false, true)
      return
    }
    results.forEach((item) => { backtest_result_map[item.seg_sym] = item })
    const algo_obj = backtest_result_map[seg_sym].algo_obj
    const {
      algo_uuid, algo_subscription_uuid, publishing_uuid,
      algo_subscribed, user_uuid,
    } = algo_obj
    const backtest_runtime = backtest_result_map[seg_sym].runtime

    if (!(seg_sym.includes('_DYNAMIC CONTRACT') || seg_sym.includes('DYNAMIC CONTRACT'))) {
      this.updateBacktesthandler(undefined, undefined, seg_sym)
      return
    }

    if ((seg_sym.includes('_DYNAMIC CONTRACT') || seg_sym.includes('DYNAMIC CONTRACT')) && reRunScrips[seg_sym]) {
      this.updateBacktesthandler(undefined, undefined, seg_sym)
      return
    }

    const new_algo_obj = {
      ...algo_obj,
      algo_desc: algo_obj.algo_desc || '',
      algo_pref: '',
      commission: 0,
      version: '2',
    }
    const [seg, sym] = seg_sym.split(/_(.*)/s)
    const formedParams = {
      ...new_algo_obj,
      symbols: [[seg, sym]],
      trade_time_given: 'True',
    }

    const params = {
      seg_sym,
      algo_uuid,
      algo_subscription_uuid,
      publishing_uuid,
      algo_subscribed,
      algo_obj: formedParams,
      runtime: backtest_runtime,
    }
    const equities = []
    const empty_entries = []

    scripList.forEach((scrip) => {
      const { segment, symbol } = scrip
      const entry = `${segment}_${symbol}`
      equities.push(entry)
      if (seg_sym && scrip.seg_sym !== seg_sym) {
        empty_entries.push(backtest_result_map[entry])
        return
      }
      const obj = {}
      obj.algo_obj = algo_obj
      obj.seg_sym = entry
      obj.symbol = entry
      obj.algo_uuid = algo_uuid
      obj.user_uuid = user_uuid
      obj.backtest_result = {
        [entry]: {
          waiting: true,
          seg_sym: entry,
        },
      }
      obj.waiting = true
      empty_entries.push(obj)
    })

    createEmptyPairEntries(equities, empty_entries, algo_obj)

    fetchBacktestDetailsDyc(params)

    reRunScrips[seg_sym] = true
    this.setState({ reRunScrips })
  }

  toggleScripParams = (flag) => {
    this.setState({ showScripParams: flag })
  }

  saveParamsDetails = (data) => {
    const { scripList } = this.state
    const modScripList = [...scripList]
    const { index, seg_sym } = this.selectedScrip
    let modScrip = {}
    const symbol = getDynamicSymbol(data, seg_sym)
    // check if this symbol exists in the list
    let isAlreadyExists = false
    scripList.forEach((item) => {
      if (item.symbol === symbol) isAlreadyExists = true
    })
    if (isAlreadyExists) {
      showSnackbar('Symbol already exists')
      return
    }
    modScrip = {
      ...modScripList[index],
      segment: '',
      symbol,
      details: { ...data, seg_sym },
      seg_sym: `_${symbol}`,
    }
    modScripList[index] = modScrip
    this.setState({ scripList: modScripList, showScripParams: false })
  }

  updateScripList = (list, runBacktest) => {
    const { scripList } = this.state
    const modScripList = list.map((item) => {
      delete item.disableRemove
      delete item.disableEdit
      return item
    })
    const changeIndex = (scripList[0] && list[0] && scripList[0].seg_sym !== list[0].seg_sym)
    // backtestResults.splice(1, 0, ...list)
    if (!runBacktest && changeIndex) {
      this.selectedItem = { seg_sym: modScripList[0].seg_sym }
      this.setState({ selectedIndex: 0, scrollType: 'next', brokerage: false })
    }
    if (runBacktest) {
      this.setState({ scripList: modScripList, showBacktestBtn: true }, () => {
        this.updateBacktesthandler(null, 'Add stocks')
      })
    } else {
      const { saveBacktestParams, csrf } = this.props
      saveBacktestParams({
        csrfmiddlewaretoken: csrf,
        scripList: modScripList,
        algo_uuid: this.algo_uuid,
      })
    }
  }

  addNewSymbol = () => {
    const {
      blockedSegmentList = [], backtests,
    } = this.props
    const { scripList } = this.state // algoId
    // if (onGoingBacktests[algoId]) return
    if (scripList.length >= STOCK_ADD_LIMIT) {
      this.showSnackbar(`Cannot add more than ${STOCK_ADD_LIMIT} stocks`)
      return
    }
    const { backtest_results = [], deployed_seg_sym = [] } = backtests
    this.addScripParams = {
      defaultList: scripList.map((item) => {
        const reqBacktest = backtest_results.filter(bt => bt.seg_sym === item.seg_sym)

        return {
          ...item,
          seg_sym: item.seg_sym,
          disableRemove: Boolean(reqBacktest[0] && reqBacktest[0].backtest_result),
          disableEdit: deployed_seg_sym.includes(item.seg_sym),
        }
      }),
      blockedSegmentList,
      parentPageId: NAVIGATIONS.BACKTESTS.name,
    }
    this.toggleModal('addScrips')
  }

  downloadAllTD = () => {
    const { backtestDetails } = this.props
    const { algo_name } = this.details
    const { scripList } = this.state
    const scripListArr = scripList.map(scrip => scrip.seg_sym)
    if (backtestDetails.length === 0) {
      showSnackbar('No backtest data found')
      return
    }
    showSnackbar('Downloading backtest data')
    if (this.downloading) return
    this.downloading = true
    let csvContent = encodeURI('data:text/csv;charset=utf-8,')
    csvContent += encodeURI('Trigger Date,Trigger Time,Instrument,Buy/Sell,Quantity,Price,Cumulative P&L,Trigger type\r\n')
    Object.values(backtestDetails).forEach((item) => {
      const {
        seg_sym, trade_log,
      } = item
      const result = [...scripListArr].indexOf(item.seg_sym) > -1
      if (result === false) return
      if (!trade_log) return
      if (!trade_log.length) return
      const { segment, symbol } = segSymConverter(seg_sym)
      const segSym = `${segment}_${symbol}`
      csvContent += encodeURI(`${segSym} results\r\n`)
      trade_log.forEach((row) => {
        const disp_row = [...row.slice(0, 6), ...row.slice(7)]
        disp_row.splice(2, 0, disp_row[1])
        disp_row[0] = dayjs(row[0]).format('DD MMM YYYY')
        disp_row[1] = dayjs(row[0]).format('hh:mm A')
        const { segment: seg, symbol: sym } = segSymConverter(disp_row[2])
        disp_row[2] = `${seg}_${sym}`
        const line = disp_row.join(',')
        csvContent += encodeURI(`${line}\r\n`)
      })
      csvContent += encodeURI('\r\n \r\n')
    })
    const link = document.createElement('a')
    link.setAttribute('href', csvContent)
    link.setAttribute('download', `${algo_name}_transaction_details.csv`)
    link.innerHTML = 'Click Here to download'
    document.body.appendChild(link)
    link.click()
    link.remove()
    this.downloading = false
  }

  onDownloadBacktestResult = () => {
    const { selectedSymbols, gaLabel, scripList } = this.state
    const scripListArr = scripList.map(scrip => scrip.seg_sym)
    const { algo_name } = this.details
    const { backtests } = this.props
    const { backtest_results = [] } = backtests
    const finalBtResults = selectedSymbols.size ? backtest_results.filter(item => (
      [...selectedSymbols].indexOf(item.seg_sym) > -1))
      : backtest_results.filter(item => ([...scripListArr].indexOf(item.seg_sym) > -1))
    let csvContent = encodeURI('data:text/csv;charset=utf-8,')
    csvContent += encodeURI('Symbol,Segment,Backtest P&L(Amt),Backtest P&L(%),Total number of signals,Number of wins,Number of losses,Winning streak,Losing streak,Max gains,Max loss,Avg gain/winning trade,Avg loss/losing trade,Max DD\r\n')
    finalBtResults.forEach((row) => {
      removeObjectProperties(row.backtest_result, ['runtime', 'updated_time'])
      if (row.backtest_result) {
        const backtestResultData = Object.values(row.backtest_result)[0] || {}
        const {
          win_count = 0,
          total_number_of_signals = 1,
          loss_count = 0,
          winning_streak = 0,
          lossing_streak = 0,
          average_gain_per_losing_trade = 0,
          average_gain_per_winning_trade = 0,
          maximum_loss = 0,
          maximum_gain = 0,
          max_draw,
          seg_sym = 'NSE_SBIN',
          final_pnl = 0,
          return: pnl_perc,
        } = backtestResultData
        const [segment, symb] = seg_sym.split('_')
        let symbol = symb
        if (seg_sym.includes('DYNAMIC')) {
          symbol = `${seg_sym.trim().replaceAll(',', ' ')}`
        }
        const line = `${symbol},${segment},${final_pnl},${pnl_perc.toFixed(2)}%,${total_number_of_signals},${win_count},${loss_count},${winning_streak},${lossing_streak},${maximum_gain},${maximum_loss},${average_gain_per_winning_trade},${average_gain_per_losing_trade},${max_draw}`
        csvContent += encodeURI(`${line}\r\n`)
      }
    })
    const link = document.createElement('a')
    link.setAttribute('href', csvContent)
    link.setAttribute('download', `${algo_name}_backtest_results.csv`)
    link.innerHTML = 'Click Here to download'
    document.body.appendChild(link)
    link.click()
    link.remove()
    showSnackbar('Successfully Downloaded Backtest results.', {}, 1)
    this.updateGA('Export backtest result', `${gaLabel} options`)
  }

  showBacktestDetails = (seg_sym, {
    index, scrollType,
  }) => { // extraParams
    const {
      backtests, isFetchingBacktests, fetchBacktestDetails, backtestDetails,
    } = this.props
    if (isFetchingBacktests) return
    if (!seg_sym) {
      this.selectedItem = {}
      this.setState({ selectedIndex: 0, scrollType, brokerage: false }) // DANGER
      return
    }
    const { backtest_results = [], shared } = backtests
    const reqBacktest = getReqBacktests(backtest_results, index, seg_sym)

    const { backtest_result = {} } = reqBacktest
    const scripBtResult = backtest_result[seg_sym] || {}
    const backtestExist = isBacktestExist(scripBtResult)
    let finalSegSym = seg_sym
    if (seg_sym.includes('&')) {
      finalSegSym = finalSegSym.replace('&', '%26')
    }
    if (backtestExist) {
      const {
        algo_uuid, publishing_uuid,
        algo_subscribed,
      } = backtests
      if (!this.canRunGoBt) {
        if (!this.backtestRan || !backtestDetails[seg_sym]) {
          if (this.isSharedBt) {
            if (shared) {
              fetchBacktestDetails({
                seg_sym: finalSegSym, algo_uuid, publishing_uuid, algo_subscribed, shared,
              })
            } else {
              fetchBacktestDetails({
                // eslint-disable-next-line max-len
                seg_sym: finalSegSym, algo_uuid: backtest_results[0].algo_uuid, publishing_uuid, algo_subscribed, shared,
              })
            }
          } else {
            fetchBacktestDetails({
              seg_sym: finalSegSym, algo_uuid, publishing_uuid, algo_subscribed, shared,
            })
          }
        }
      }
    }
    this.selectedItem = reqBacktest
    this.setState({ selectedIndex: index, scrollType }) // DANGER
  }

  // used in app
  toggleEditParams = () => {
    // if (!item) return
    const { user_details, navigation, onGoingBacktests } = this.props
    const { scripList, algoId } = this.state
    if (onGoingBacktests[algoId]) return
    if (scripList.length > 0) {
      const modScripList = []
      // DANGER have to use equities
      scripList.forEach((item) => {
        if (item.seg_sym && item.seg_sym.includes('_')) {
          const { segment, symbol } = segSymConverter(item.seg_sym)
          modScripList.push({ symbol, segment })
        }
      })
      navigation.navigate(NAVIGATIONS.BACKTEST_EDIT_PARAMS.name, {
        details: this.details,
        algo_desc: this.algoInfo.algo_desc || '',
        scripList: modScripList,
        user_details,
      })
    } else {
      this.showSnackbar('Add symbols to edit parameters')
    }
  }

  onSymbolSelect = ({ seg_sym, isSelected }) => {
    const { selectedSymbols } = this.state
    const modSelection = new Set(selectedSymbols)
    if (!isSelected) {
      modSelection.add(seg_sym)
    } else {
      modSelection.delete(seg_sym)
    }
    this.setState({ selectedSymbols: modSelection })
  }

  selectAllSymbols = (deploy) => {
    const { backtests } = this.props
    const { scripList } = this.state
    const { deployed_seg_sym = [], backtest_results = [] } = backtests
    const modSelection = new Set()

    // debugger
    scripList.forEach((item, index) => {
      let reqBacktest = backtest_results[index]
      if (!reqBacktest || (reqBacktest && reqBacktest.seg_sym !== item.seg_sym)) {
        reqBacktest = backtest_results.filter(bt => bt.seg_sym === item.seg_sym)
        reqBacktest = reqBacktest[0]
      }
      if (!reqBacktest) {
        reqBacktest = { seg_sym: item.seg_sym }
      }
      const {
        waiting, seg_sym, backtest_result,
      } = reqBacktest
      if (!waiting && !deployed_seg_sym.includes(seg_sym)) { // !error && !errorMsg
        if (deploy) {
          if (backtest_result && backtest_result[seg_sym] && (!backtest_result[seg_sym].error_msg
            || (backtest_result[seg_sym].error_msg && backtest_result[seg_sym].error_msg.toLowerCase().includes('no trades')
            )
          )) {
            modSelection.add(seg_sym)
          }
        } else {
          modSelection.add(seg_sym)
        }
      }
    })
    return modSelection
  }

  toggleSelectAll = (allSelected) => {
    if (allSelected) {
      this.setState({ selectedSymbols: new Set() })
      this.updateGA('Deselect all', `${this.state.gaLabel} optons`)
    } else {
      this.setState({ selectedSymbols: this.selectAllSymbols() })
      this.updateGA('Select all', `${this.state.gaLabel} optons`)
    }
  }

  deploySymbols = ({ seg_sym, isBacktestExist: backtestExist = true }) => {
    const {
      isDeploying, backtests, onGoingBacktests, user_details,
      toggleAuthGreeting,
    } = this.props
    const { subscription_valid } = user_details
    if (!subscription_valid) {
      toggleAuthGreeting(PRO_FEATURE_MAP.DP_LIMIT.ID)
      return
    }
    if (!backtestExist) {
      this.showSnackbar('No backtest found', {}, 0)
      return
    }
    const { algoId } = this.state
    if (onGoingBacktests[algoId]) {
      this.showSnackbar('Still running the backtest')
      return
    }
    if (!isDeploying) {
      const {
        algo_uuid, publishing_uuid, create_plus,
      } = backtests
      const {
        take_profit, stop_loss, time_frame, max_allocation, position_sizing_type,
        daily_strategy_cycle, tpsl_type,
      } = this.details
      this.deployParams = {
        stocks: [seg_sym],
        algoInfo: {
          ...this.algoInfo,
          create_plus,
          take_profit,
          stop_loss,
          time_frame,
          algo_uuid,
          publishing_uuid,
          // algo_subscription_uuid,
          max_allocation,
          position_sizing_type,
          daily_strategy_cycle,
          tpsl_type,
        },
        fromRouteName: '',
      }
      this.toggleModal('showDeploy')

      this.updateGA('Deploy', `${this.state.gaLabel} optons`)
      // this.updateGA('Deploy', `Deploy + ${this.state.isListView ? 'List' : 'Card'}`)
    }
  }

  deployAll = (ga) => {
    const {
      isDeploying, backtests, onGoingBacktests, updateGA,
      updateTourIndex, activeTourIndex, startTour, user_details,
      toggleAuthGreeting,
    } = this.props
    const { selectedSymbols, scripList = [], algoId } = this.state
    const { subscription_valid } = user_details
    if (!subscription_valid) {
      toggleAuthGreeting(PRO_FEATURE_MAP.DP_LIMIT.ID)
      return
    }
    if (onGoingBacktests[algoId]) {
      this.showSnackbar('Still running the backtest')
      return
    }
    // if(selectedSymbols.size === 0) {
    //   this.toggleSelectAll(false)
    // }
    if (!isDeploying || this.filteringToDeploy) {
      const {
        backtest_results, algo_uuid, publishing_uuid, // algo_subscription_uuid,
        create_plus, deployed_seg_sym,
      } = backtests
      let stocks = []

      if (backtest_results.length === 0) {
        this.showSnackbar('Need to backtest before deploying')
        return
      }
      this.filteringToDeploy = true
      if (selectedSymbols.size !== 0) {
        const stocksArr = Array.from(selectedSymbols)
        stocksArr.forEach((segSym) => {
          let reqBacktest = backtest_results.filter(item => item.seg_sym === segSym)
          reqBacktest = reqBacktest[0] || {}
          const { backtest_result = {} } = reqBacktest
          const scripBtResult = backtest_result[segSym] || {}
          if (isBacktestExist(scripBtResult)) {
            stocks.push(segSym)
          }
        })
        if (stocks.length === 0) {
          this.showSnackbar('Cannot deploy stock without backtest')
          this.filteringToDeploy = false
          return
        }
      } else if (scripList.length) {
        // stocks = Array.from(this.selectAllSymbols(true))
        scripList?.forEach((item, index) => {
          const reqBacktest = backtest_results[index]
          const {
            seg_sym, backtest_result,
          } = reqBacktest
          const scripBtResult = backtest_result[seg_sym] || {}
          if (isBacktestExist(scripBtResult)) {
            stocks.push(seg_sym)
          }
        })
        if (stocks?.length === 0) {
          this.showSnackbar('Cannot deploy stock without backtest')
          this.filteringToDeploy = false
          return
        }
      }
      this.filteringToDeploy = false
      if (stocks.length === 0) {
        this.showSnackbar('No symbols selected to deploy')
        return
      }
      const finalStocks = stocks.filter(stock => !deployed_seg_sym.includes(stock))
      if (finalStocks.length === 0) {
        this.showSnackbar('No symbols selected to deploy')
        return
      }
      const {
        take_profit, stop_loss, time_frame, max_allocation, position_sizing_type,
        daily_strategy_cycle, tpsl_type,
      } = this.details
      if (publishing_uuid) return
      this.deployParams = {
        stocks,
        deployed_seg_sym,
        algoInfo: {
          ...this.algoInfo,
          create_plus,
          take_profit,
          stop_loss,
          time_frame,
          algo_uuid,
          publishing_uuid,
          max_allocation,
          position_sizing_type,
          daily_strategy_cycle,
          tpsl_type,
        },
        fromRouteName: '',
      }
      if (!startTour) { this.toggleModal('showDeploy') }
      if (ga) updateGA('Deploy all', `${this.state.gaLabel} optons`, stocks.length)
      // this.setState({ selectedSymbols: new Set() })
      if (activeTourIndex === 2) {
        updateTourIndex(activeTourIndex + 1)
      }
    }
  }

  filterGraphList = (backtest_results = []) => {
    const graphList = []
    const tradeLogList = []
    for (let i = 0;i < backtest_results.length;i++) {
      const { seg_sym } = backtest_results[i]
      const backtestObj = backtest_results[i].backtest_result[seg_sym]
      if (backtestObj && !backtestObj.error && !backtestObj.error_msg) {
        const { trade_log, pnl = [] } = backtestObj
        // const price = []
        // const timeStamp = []
        // pnl.forEach((item) => {
        //   if (item && item[1]) {
        //     price.push(item[1]) // pnl
        //     timeStamp.push(item[0]) // time
        //   }
        // })
        graphList.push(pnl)
        tradeLogList.push(trade_log)
      }
    }
    return { graphList, tradeLogList }
  }

  backPressHandler = () => {
    const { history, location, backtests } = this.props
    const params = new URLSearchParams(location.search)
    const isaValue = params.get('isa')
    const decodedValue = atob(isaValue)
    if (decodedValue !== 'false') {
      history.push(pathname_mapping.Strategies)
    } else if (location.state
      && location.state.parentPageId === pathname_mapping.Create
      && backtests.deployed_seg_sym && backtests.deployed_seg_sym.length > 0) {
      history.push(pathname_mapping.Strategies)
    } else {
      history.goBack()
    }
  }

  editSubscribedAlgo = (isShareNameChange) => {
    this.isShareNameChange = isShareNameChange || false
    this.toggleNameChangeModal()
  }

  toggleNameChangeModal = () => {
    this.setState(prevState => ({ showNameChange: !prevState.showNameChange }))
  }

  nameChangeHandler = (params) => {
    let name = ''
    if (params) {
      this.showCloneError = true
      name = params.listName
    }
    const {
      deployPublished, csrf, backtests, location: { search }, copyAlgo, isLoggedIn, toggleAuthModal,
    } = this.props

    if (!isLoggedIn) {
      toggleAuthModal(true, false, true)
      return
    }

    const {
      publishing_uuid: publishingUuid, algo_share_uuid, backtest_share_uuid, sbt, onboard, stocks = '[]',
    } = parseQuery(search, true)
    const { publishing_uuid } = backtests
    const algoId = onboard
      ? publishingUuid : algo_share_uuid || (backtest_share_uuid || publishing_uuid || sbt)
    const algoIdKey = algo_share_uuid ? 'algo_share_uuid' : 'backtest_share_uuid'
    const copyWithStocks = onboard || false
    const decodedStocks = JSON.parse(decodeURIComponent(stocks)).map(stock => JSON.parse(stock))
    if (backtest_share_uuid || algo_share_uuid || sbt) {
      copyAlgo({
        csrfmiddlewaretoken: csrf,
        algo_name: name,
        [algoIdKey]: algoId,
      })
      return
    }
    deployPublished({
      csrfmiddlewaretoken: csrf,
      name,
      publishing_uuid: algoId,
      stocks: decodedStocks,
      copyWithStocks,
    }, {}, true)
  }

  editAlgo = (params) => {
    const {
      onGoingBacktests, history, backtests,
    } = this.props
    const { algoId } = this.state
    const { deployed_seg_sym } = backtests
    if (onGoingBacktests[algoId]) {
      this.showSnackbar('Still running the backtest')
      return
    }
    if (deployed_seg_sym.length) {
      this.showSnackbar('Cannot edit a live strategy')
      return
    }
    routeToCreate({
      history,
      search: `?id=${btoa(params.algo_uuid)}&edit=true`,
      id: params.algo_uuid,
    })
    // history.push({
    //   pathname: pathname_mapping.Create,
    //   search: `?id=${btoa(params.algo_uuid)}&edit=true`,
    // })
  }

  toggleModal = (modal) => {
    const {
      startTour, toggleSkipTour,
      activeTourIndex,
    } = this.props
    const { deploySuccessModal } = this.state
    if (deploySuccessModal && startTour && activeTourIndex === 5) {
      toggleSkipTour(true)
      return
    }
    if (modal === 'deploySuccessModal') {
      this.setState({ selectedSymbols: new Set() })
    }
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  onDismissObSuccess = () => {
    this.props.showTour(true)
    this.setState({ fromOnboard: false })
  }

  onSymbolDelete = (segSym, params) => {
    const { onGoingBacktests } = this.props
    if (onGoingBacktests[this.state.algoId]) {
      showSnackbar('Backtest still running')
      return
    }
    this.singleSymbolDelete = { segSym, params }
    this.toggleModal('removeSymbolsModal')
  }

  onSingleSymbolDelete = () => {
    const { segSym, params } = this.singleSymbolDelete
    const { saveBacktestParams, csrf } = this.props
    const { scripList, selectedSymbols, gaLabel } = this.state
    const modScripList = scripList.filter((item) => {
      const { seg_sym } = item
      const shouldRemove = seg_sym === segSym
      if (shouldRemove) this.modifiedScrips.add(seg_sym)
      return !shouldRemove
    })

    const modSymbols = new Set(selectedSymbols)
    modSymbols.delete(segSym)
    // this.showSnackbar('Run backtest to save changes')

    saveBacktestParams({
      csrfmiddlewaretoken: csrf,
      scripList: modScripList,
      ...params,
    })
    this.singleSymbolDelete = null

    this.setState({
      // scripList: modScripList,
      selectedSymbols: modSymbols,
      removeSymbolsModal: false,
    })
    this.updateGA('Delete', `${gaLabel} optons`)
  }

  confirmRemoveSymbols = () => {
    if (this.singleSymbolDelete) {
      this.onSingleSymbolDelete()
      return
    }
    const { backtests, csrf, saveBacktestParams } = this.props
    const {
      scripList, selectedSymbols, renderType, gaLabel,
    } = this.state
    const symbolsArr = Array.from(selectedSymbols)
    const { algo_uuid, algo_subscription_uuid } = backtests
    const modScripList = scripList.filter((item) => {
      const { seg_sym } = item
      const shouldRemove = symbolsArr.includes(seg_sym)
      if (shouldRemove) this.modifiedScrips.add(seg_sym)
      return !shouldRemove
    })
    const params = renderType === STRATEGY_TYPE.MY_ALGOS ? { algo_uuid }
      : { algo_subscription_uuid }
    // this.showSnackbar('Run backtest to save changes')

    saveBacktestParams({
      csrfmiddlewaretoken: csrf,
      scripList: modScripList,
      ...params,
    })
    this.setState({
      // scripList: modScripList,
      removeSymbolsModal: false,
      selectedSymbols: new Set(),
      // showBacktestBtn: modScripList.length > 0,
    })
    this.updateGA('Delete all', `${gaLabel} optons`, this.modifiedScrips.size)
  }

  renderRemoveSymbolConfirmation = () => {
    const { styles } = this.props
    const stockText = this.singleSymbolDelete ? 'stock' : 'stocks'
    return (
      <div className={styles.remove}>
        <div className={styles.removeHeader}>
          {/* <Icon name={ICONS.DELETE} size={13} color={COLORS.RED} /> */}
          <CustomText weight="semi_bold" size="medium">{`  Delete ${stockText}`}</CustomText>
        </div>
        <CustomText className={styles.removeContentText} size="small" color="textSecondary">
          {`Are you sure you want to delete ${this.singleSymbolDelete ? 'the' : 'these'} ${stockText}. if you delete the
 ${stockText}, you will permanently lose all the data. You might have to add the ${stockText} again to backtest.`}
        </CustomText>
        <div className={styles.removeActionBar}>
          <Button
            onPress={this.toggleModal}
            params="removeSymbolsModal"
            btnStyles={styles.removeCancelBtn}
            labelStyle={styles.removeCancelBtnText}
            labelColor="textSecondary"
            text="Cancel"
            mode="text"
            buttonColor="tabsBg"
          />
          <Button
            text="Delete"
            buttonColor="red"
            onPress={this.confirmRemoveSymbols}
            labelColor={COLORS.WHITE}
            btnStyles={styles.removeBtn}
            mode="contained"
            roundness={6}
          />
        </div>
      </div>
    )
  }

  removeSymbols = () => {
    const { onGoingBacktests } = this.props
    if (onGoingBacktests[this.state.algoId]) {
      showSnackbar('Backtest still running')
      return
    }
    this.toggleModal('removeSymbolsModal')
  }

  refetchBacktest = () => {
    this.fetchBacktests('', true)
  }

  toggleListView = () => {
    const { isListView } = this.state
    const view = isListView ? 'row' : 'list'
    setLocalStorage({ [PREFERENCES.DETAIL_VIEW]: view })
    this.setState(prevState => ({ isListView: !prevState.isListView }), () => {
      const { isListView: newView, gaLabel } = this.state
      this.updateGA(newView ? 'List view' : 'Card view', `${gaLabel} optons`)
    })
  }

  convertToDynamic = ({ scrip, index }) => {
    const { user_details } = this.props
    const shallReturn = userAccessCheck({
      condition: accessConditions.DYNAMIC_CONTRACT,
      user_details,
      // showSnackbar,
    })
    if (shallReturn) return
    if (!scrip) {
      this.showSnackbar('Scrip not found', {}, 0)
      return
    }
    const { details, segment, symbol } = scrip
    const seg_sym = details ? details.seg_sym : `${segment}_${symbol}`
    this.selectedScrip = { ...scrip, index, seg_sym }
    this.scripProps = {
      ...dynamicScripParams(seg_sym.split('_')[0]),
      indicatorDetails: details,
    }
    this.setState({ showScripParams: true })
  }

  toggleScripParams = (flag) => {
    this.setState({ showScripParams: flag })
  }

  pinStratgyDetails = () => {
    this.setState(prevState => ({ isPinned: prevState.isPinned ? '' : '1' }))
  }

  calulateBrokerage = (item) => {
    let brokerageLogs = []
    const {
      backtestDetails,
    } = this.props

    const {
      seg_sym,
      backtest_result,
    } = item
    const { holding_type } = this.algoInfo
    const { max_cap_used = 1 } = backtest_result[seg_sym] || {}
    let pnl = []
    let trade_log = [];
    ({ pnl, trade_log } = backtestDetails[seg_sym] || {})
    pnl = pnl || []
    trade_log = trade_log || []
    const d = processBrokerage(seg_sym, trade_log, pnl, holding_type)
    brokerageLogs = d.trade_log_brokerage
    const pnl_value = brokerageLogs.length
      ? Number(brokerageLogs[brokerageLogs.length - 1][5]).toFixed(2) : 0
    const final_ret_value = ((pnl_value / max_cap_used) * 100).toFixed(2)
    return { brokerageLogs, pnl: pnl_value, period_return: final_ret_value }
  }

  updateBrokerage = (item) => {
    const { brokerageData: oldData } = this.state
    const brokerageData = { ...oldData }
    brokerageData[item.seg_sym] = this.calulateBrokerage(item)
    this.setState({ brokerageData })
  }

  toggleBrokerage = (item) => {
    const { updateGA, isRunningBacktests } = this.props
    if (isRunningBacktests) return
    this.setState((prevState) => {
      let brokerageData = {}

      let msg = 'Adjusted for brokerage and other charges (does not include DP charges)'
      if (!prevState.brokerage) {
        const { seg_sym } = item
        const { backtestDetails } = this.props
        const {
          waiting, error, pnl = [],
        } = backtestDetails[seg_sym] || {}
        if (waiting) {
          showSnackbar('Fetching trade log')
          return ({ brokerage: prevState.brokerage })
        }
        if (error || pnl.length === 0) {
          showSnackbar('Error fetching graph data', {}, 0)
          // DANGER fetch again
          return ({ brokerage: prevState.brokerage })
        }
        const { brokerageData: oldData } = prevState
        brokerageData = { ...oldData }
        brokerageData[seg_sym] = this.calulateBrokerage(item)
      } else {
        msg = 'Not Adjusted for brokerage and other charges (does not include DP charges)'
        brokerageData = {}
        // this.setState({ brokerageData: [], brokerageLogs: [] })
        // brokerageToggled({}, -1, false)
      }
      showSnackbar(msg, {}, -1)

      return ({ brokerage: !prevState.brokerage, brokerageData })
    }, () => {
      updateGA('Brokerage', 'Single stock', this.state.brokerage ? 1 : 0)
    })
  }

  goToLiveAlgos = (params) => {
    const { activeSection, activeTabIndex } = params
    const {
      history, updateTourIndex, startTour, updateGA,
    } = this.props
    const tabs = Object.values(DEPLOYED_TABS)
    updateTourIndex(6)
    if (startTour) updateGA('Deployed Strategies', 'Disc Strat Pg 6', 1, 'Product Tour')
    history.push({
      pathname: pathname_mapping.Strategies,
      search: `?tab=${activeSection.route}&state=${tabs[activeTabIndex].KEY}`,
    })
  }

  dismissDeploy = (status, params) => {
    const {
      styles, changePtComponentStatus, startTour,
    } = this.props
    if (status === 'success') {
      this.successModalProps = {
        title: 'Strategy Deployed Successfully',
        content: '',
        containerStyles: styles.alertContainer,
        contentStyles: styles.alertContent,
        titleStyles: styles.alertTitle,
        imgStyles: styles.alertImg,
        imgSrc: DeploySuccess,
        actions: [
          // {
          //   text: 'Go back',
          //   containerStyle: styles.alertActiveBtn,
          //   btnProps: {
          //     buttonColor: 'tabsBg', mode: 'text', labelColor: 'textSecondary',
          //   },
          //   action: () => {
          //     this.refetchBacktest()
          //     this.toggleModal('deploySuccessModal')
          //   },
          // },
          {
            text: 'See Deployed Strategies',
            containerStyle: styles.alertActiveBtn,
            action: () => this.goToLiveAlgos(params),
          },
        ],
      }
      if (!startTour) {
        this.toggleModal('showDeploy')
      }
      this.toggleModal('deploySuccessModal')
      changePtComponentStatus('algoDeployed')
    }
  }

  fetchBacktestAll = (onlyFetchTradeLog) => {
    const { backtests, fetchBacktestAll, location: { search } } = this.props
    const { algo_uuid, publishing_uuid, backtest_share_uuid } = backtests
    const { stocks = '[]' } = parseQuery(search, false)
    let params = {
      algo_uuid, publishing_uuid, stocks, getAllData: true,
    }
    if (onlyFetchTradeLog) {
      params = { ...params, max_count: -2 }
    }
    if (this.isSharedBt && backtest_share_uuid) {
      params = { sbt: backtest_share_uuid, getAllData: true }
    } else if (this.isSharedBt) {
      params = { algo_share_uuid: algo_uuid, getAllData: true }
    }

    fetchBacktestAll(params)
  }

  renderNameChangeModal = () => {
    const { showNameChange } = this.state
    const { isCloningAlgo, isCopyingAlgo } = this.props
    return (
      <CustomModal
        visible={showNameChange}
        animationType="fade"
        onDismiss={this.toggleNameChangeModal}
        dismissKey="showNameChange"
      >
        <CreateNewList
          onDismiss={this.toggleNameChangeModal}
          dismissKey="showNameChange"
          title="Copy Strategy As"
          inputPlaceholder="Strategy Name"
          btnText="Copy"
          onCreate={this.nameChangeHandler}
          hideCancel={this.isShareNameChange}
          isShareNameChange={this.isShareNameChange}
          visible={showNameChange}
          loading={isCloningAlgo || isCopyingAlgo}
        />
      </CustomModal>
    )
  }

  onEditAlgo = (type) => {
    const {
      onGoingBacktests, backtests, isSubscribingAlgo, isFetchingBacktests,
      history,
    } = this.props
    const { algoId, renderType } = this.state
    const { deployed_seg_sym = [] } = backtests
    const isRunningBacktests = onGoingBacktests[algoId]
    if (isRunningBacktests || isFetchingBacktests
      || isSubscribingAlgo || deployed_seg_sym.length > 0) return
    if (renderType === STRATEGY_TYPE.MARKET) {
      showSnackbar('Copy strategy to edit')
    } else {
      // updateAnalytics('Edit strategy', '')
      routeToCreate({
        history,
        search: `?id=${btoa(algoId)}&edit=${btoa(true)}&type=${btoa(type)}`,
        id: algoId,
        routeParams: {
          parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algoId)}`,
        },
      })
      // history.replace({
      //   pathname: pathname_mapping.Create,
      //   search: `?id=${btoa(algoId)}&edit=${btoa(true)}&type=${btoa(type)}`,
      // }, { parentUrl: `${pathname_mapping.Backtests}/?algo_uuid=${btoa(algoId)}` })
    }
  }

  onEntryEdit = () => {
    this.onEditAlgo(CONDITION_TYPE.ENTRY)
  }

  onExitEdit = () => {
    this.onEditAlgo(CONDITION_TYPE.EXIT)
  }

  render() {
    const {
      renderType, scripList, allDeployed, selectedSymbols, pinCumulativePnl,
      removeSymbolsModal, isFocused, algoId, showScripParams, brokerageData,
      selectedIndex, isPinned, addScrips, sliderProps, showDeploy, brokerage, deploySuccessModal,
      fromOnboard, isListView, gaLabel, showWarning, userCanceledWarning,
    } = this.state
    const {
      backtests = {}, isSubscribingAlgo, subscribeAlgoResp, subscribeAlgoError,
      subscribeAlgoErrorMsg, subscribeAlgo, csrf, isFetchingBacktests,
      onGoingBacktests, backtestsError, styles, isDark, isFetchingData, updateGA,
      displayDevice, domainChange, isLoggedIn, isMobile, isCloningAlgo, user_details,
      location, startTour, activeTourIndex, changePtComponentStatus, preferences, backtestsErrorMsg,
      nudgeIndexesDet,
    } = this.props
    const {
      deployed_seg_sym = [], publishing_uuid, algo_subscription_uuid,
      algo_desc, public: isPublic, backtest_results = [], create_plus, algo_subscribed,
      backtest_share_uuid,
      shared, algo_name = '', algo_state = {},
    } = backtests
    const { isCtbCreate = false } = algo_state
    const { state = {} } = location
    const { assumptionToken = 0 } = state
    const isCtb = isCtbCreate || this.canRunGoBt
    // const { ctb: isCtb } = parseQuery(search, true)
    const { time_frame } = this.details
    const intervalIndex = candle_freq_map[time_frame]
    const showOnboardSuccess = fromOnboard && (renderType || !isEmpty(backtests))
    const defaultOptions = {
      loop: false,
      animationData: SUCCESS_LOTTIE,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
    const disclaimerText = 'Disclaimer: Backtests are run on any one of the generally accepted combination of the indicators added by you on charts. \n This should not be construed as recommendation or trading / investment advice'

    const errorMsg = backtestsErrorMsg || 'Something went wrong'
    if (!startTour && backtestsError) {
      return (
        <div className={classnames(styles.screen, styles.refetchScreen)}>
          <CustomText center>{errorMsg}</CustomText>
          <CustomText center>The provided link seems incorrect or invalid</CustomText>
          <Button
            text="  Refresh"
            onPress={this.refetchBacktest}
            mode="outlined"
            btnStyles={styles.refetchBtn}
            buttonColor="blue"
          >
            <Icon
              name={ICONS.REFRESH}
              size={12}
              color={theme.linkColor}
              style={{ marginLeft: SPACING.SPACE_10 }}
            />
          </Button>
        </div>
      )
    }

    if (!renderType || isEmpty(backtests)) {
      return (
        <>
          <BacktestLoader displayDevice={displayDevice} />
          {this.renderNameChangeModal()}
        </>
      )
    }
    const isRunningBacktests = onGoingBacktests[algoId]
    const mtfPreference = preferences[PREFERENCES.MTF_WARNING] === undefined
      ? true : preferences[PREFERENCES.MTF_WARNING]
    const nudgePreference = preferences[PREFERENCES.NUDGE] === undefined
      ? true : preferences[PREFERENCES.NUDGE]

    const commonProps = {
      renderType,
      btParams: this.details,
      algoInfo: this.algoInfo,
      scripList,
      backtest_results,
      algo_desc,
      isFetchingBacktests,
      addNewSymbol: this.addNewSymbol,
      showBacktestDetails: this.showBacktestDetails,
      toggleEditParams: this.toggleEditParams,
      backPressHandler: this.backPressHandler,
      fetchBacktests: this.fetchBacktests,
      fetchBacktestAll: this.fetchBacktestAll,
      removeSymbols: this.removeSymbols,
      refetchBacktest: this.refetchBacktest,
      convertToDynamic: this.convertToDynamic,
      isRunningBacktests,
      isFocused,
      algo_uuid: this.algo_uuid,
      create_plus,
      selectedIndex,
      algo_subscribed,
      publishing_uuid,
      algo_subscription_uuid,
      onDelete: this.onSymbolDelete,
      pinStratgyDetails: this.pinStratgyDetails,
      toggleBrokerage: this.toggleBrokerage,
      updateBrokerage: this.updateBrokerage,
      isFetchingData,
      sliderProps,
      isPinned: Boolean(isPinned),
      brokerageData,
      brokerage,
      selectedItem: this.selectedItem,
      isDark,
      pinCumulativePnl,
      updateWebSiteConfig: this.updateWebSiteConfig,
      isLoggedIn,
      downloadBtResult: this.onDownloadBacktestResult,
      downloadAllTD: this.downloadAllTD,
      isMobile,
      toggleListView: this.toggleListView,
      isListView,
      isCtb,
      updateGA: this.updateGA,
      gaLabel,
      gaCategory: gaLabel,
      onExitEdit: this.onExitEdit,
      onEntryEdit: this.onEntryEdit,
      user_details,
      startTour,
      activeTourIndex,
      changePtComponentStatus,
      reRunBacktestHandler: this.reRunBacktestHandler,
      mtfPreference,
      nudgePreference,
      nudgeIndexesDet,
    }
    const marketProps = {
      ...this.extraDetails,
      csrf,
      isPublic,
      isSubscribingAlgo,
      subscribeAlgoResp,
      subscribeAlgoError,
      subscribeAlgoErrorMsg,
      subscribeAlgo,
      isCloningAlgo,
      isBacktestExist,
      nameChangeHandler: this.nameChangeHandler,
      onSymbolSelect: this.onSymbolSelect,
      editSubscribedAlgo: this.editSubscribedAlgo,
    }
    const algoProps = {
      allDeployed,
      selectedSymbols,
      deployed_seg_sym,
      deployAll: this.deployAll,
      deploySymbols: this.deploySymbols, // for single smybol deploy
      onSymbolSelect: this.onSymbolSelect,
      toggleSelectAll: this.toggleSelectAll,
      editAlgo: this.editAlgo,
      domainChange,
    }

    const sharedProps = {
      editSubscribedAlgo: this.editSubscribedAlgo,
      isSharedPublic: isPublic,
      shared: shared || true,
    }
    const warningText = mtfPreference && isCtb ? `Note: ${assumptionTokenMsgMap[assumptionToken]}` : LINK_MAP[showWarning] ? LINK_MAP[showWarning].text : false
    const warningTexturl = isCtb ? ctbHelpUrl : LINK_MAP[showWarning]
      ? LINK_MAP[showWarning].link : false
    return (
      <div className={styles.screen}>
        <Helmet>
          <title>
            {renderType}
            {' | '}
            {algo_name}
          </title>
        </Helmet>
        {mtfPreference && ((assumptionToken >= 0 && isCtb) || (showWarning && showWarning !== true))
          && !userCanceledWarning
          ? (
            <div className={styles.warning}>
              <CustomText size="small" color={COLORS.BLACK} flex={1}>
                <>
                  {((assumptionToken > 0 && isCtb) || showWarning) && (
                    warningText
                  )}
                  {isCtb && (
                    <>
                      <span style={{ display: 'block' }}>&nbsp;</span>
                      {disclaimerText}
                    </>
                  )}
                  <a href={warningTexturl} rel="noreferrer" target="_blank"> read more here.</a>
                </>
              </CustomText>
              <button type="button" className={styles.warningClose} onClick={() => this.setState({ showWarning: false, userCanceledWarning: true })}>
                <Icon name={ICONS.CLOSE} size={8} />
              </button>
            </div>
          ) : null}
        {this.renderNameChangeModal()}
        <AlertPopup
          visible={deploySuccessModal}
          onDismiss={this.toggleModal}
          dismissKey="deploySuccessModal"
          modalStyles={styles.alertModal}
          {...this.successModalProps}
          isMobile={isMobile}
        />
        <CustomModal
          visible={removeSymbolsModal}
          onDismiss={this.toggleModal}
          dismissKey="removeSymbolsModal"
        >
          {this.renderRemoveSymbolConfirmation()}
        </CustomModal>
        {/* <CustomModal
          visible={showScripParams}
          onDismiss={() => this.toggleScripParams(false)}
        >
          <IndicatorDetails
            saveDetails={this.saveParamsDetails}
            dismissModal={() => this.toggleScripParams(false)}
            showRemove={false}
            showSnackbar={this.showSnackbar}
            {...this.scripProps}
            // details={details}
            // indicator={activeItem}
            // showRemove={showRemove}
            // removeIndicator={removeIndicator}
            candleIntervalIndex={intervalIndex}
          />
        </CustomModal> */}
        <CustomModal
          visible={showScripParams}
          onDismiss={() => this.toggleScripParams(false)}
          containerStyles={styles.detailsModal}
        >
          <>
            <CustomText size="small">
              {this.selectedScrip && this.selectedScrip.details
                ? 'Edit dynamic contract'
                : 'Convert to dynamic contract'}
            </CustomText>
            <CustomText weight="medium" size="large" className={styles.dynamicTitle}>
              {this.selectedScrip && this.selectedScrip.seg_sym ? this.selectedScrip.seg_sym.replace('_', ' ') : ''}
            </CustomText>
            <IndicatorDetails
              saveDetails={this.saveParamsDetails}
              dismissModal={() => this.toggleScripParams(false)}
              showRemove={false}
              showSnackbar={showSnackbar}
              {...this.scripProps}
              candleIntervalIndex={intervalIndex}
            />
          </>
        </CustomModal>
        <CustomModal
          visible={showOnboardSuccess}
          onDismiss={this.onDismissObSuccess}
          containerStyles={styles.onboardModalCon}
        >
          <Lottie
            options={defaultOptions}
            width={240}
          />
          <CustomText
            size="large"
            weight="medium"
            center
          >
            Congratulations!
          </CustomText>
          <CustomText
            size="large"
            weight="medium"
            center
          >
            You just ran your first backtest.
          </CustomText>
          <Button
            text="Continue"
            onPress={this.onDismissObSuccess}
            btnStyles={styles.submitBtn}
            buttonColor="blue"
            mode="contained"
            roundness={6}
          />
        </CustomModal>
        <Deploy
          {...this.deployParams}
          visible={showDeploy}
          onDismiss={this.toggleModal}
          dismissKey="showDeploy"
          dismissDeploy={this.dismissDeploy}
          updateGA={updateGA}
          activeTourIndex={activeTourIndex}
          isMobile={isMobile}
        />
        <AddScrips
          parentPageId={NAVIGATIONS.CREATE.name}
          params={this.addScripParams}
          showSubmitBtn
          onDismiss={this.toggleModal}
          dismissKey="addScrips"
          visible={addScrips}
          create_plus={create_plus}
        />
        {discoverDisclaimerRenderer(backtestDisclaimer)}
        <div className={styles.content}>
          {renderType === STRATEGY_TYPE.MARKET && (
            <BacktestMarkets
              {...commonProps}
              {...marketProps}
              algoId={publishing_uuid}
              algoIdKey={ALGO_KEY_MAP.DISCOVER}
            />
          )}
          {renderType === STRATEGY_TYPE.SUBSCRIBED && (
            <BacktestSubscribed
              {...commonProps}
              {...algoProps}
              {...marketProps}
              publishing_uuid={publishing_uuid}
              algoId={algo_subscription_uuid}
              algoIdKey={ALGO_KEY_MAP.SUBSCRIBED}
            />
          )}
          {renderType === STRATEGY_TYPE.MY_ALGOS && (
            <BacktestSelf
              {...commonProps}
              {...algoProps}
              algoIdKey={ALGO_KEY_MAP.MY_ALGOS}
              algoId={this.algo_uuid}
            />
          )}
          {renderType === STRATEGY_TYPE.SHARED && (
            <BacktestShared
              {...commonProps}
              {...algoProps}
              {...sharedProps}
              algoIdKey={ALGO_KEY_MAP.SHARED_ALGOS}
              algoId={backtest_share_uuid || this.algo_uuid}
            />
          )}
        </div>
        {/* {showBacktestBtn && (
          <div className={styles.actionBar}>
            <Button
              text="Run Backtest"
              onPress={this.updateBacktesthandler}
              disabled={isRunningBacktests}
              buttonColor={COLORS.BLUE}
              labelColor={COLORS.WHITE}
              mode="contained"
              roundness={6}
              fullWidth
            />
          </div>
        )} */}
      </div>
    )
  }
}

const stylesheet = ({
  screen: {
    backgroundColor: theme.screenBg,
    flex: 1,
  },
  loaderScreen: {
    backgroundColor: theme.screenBg,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  content: {
    flex: 1,
    // relative position for menuoption button do not remove
    position: 'relative',
  },
  actionBar: {
    // position: 'absolute',
    bottom: 0,
    paddingVertical: SPACING.SPACE_12,
    paddingHorizontal: DIMENSIONS.SPACE_HORIZONTAL,
    backgroundColor: theme.bgPrimary,
    borderTopWidth: 1,
    borderTopColor: COLORS.BORDER_COLOR,
  },
  removeCancelBtn: {
    flex: 0.5,
    marginRight: SPACING.SPACE_14,
    padding: SPACING.SPACE_10,
    border: `1px solid ${COLORS.TABS_BG}`,
  },
  removeBtn: {
    flex: 0.5,
    marginLeft: SPACING.SPACE_14,
    padding: SPACING.SPACE_10,
  },
  submitBtn: {
    width: '60%',
    margin: `${SPACING.SPACE_20} 0`,
  },
  removeCancelBtnText: {
  },
  remove: {
    width: '95%',
    margin: 'auto',
  },
  removeHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: COLORS.BORDER_COLOR,
    borderBottomWidth: 1,
    justifyContent: 'center',
  },
  removeContentText: {
    textAlign: 'center',
    marginTop: SPACING.SPACE_10,
    marginBottom: SPACING.SPACE_28,
  },
  removeActionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  alertContainer: {
  },
  alertContent: {
    margin: ' auto',
    width: '90%',
    textAlign: 'center',
  },
  alertTitle: {
    margin: `${SPACING.SPACE_10} auto`,
    width: '90%',
    textAlign: 'center',
  },
  alertImg: {
    height: '150px',
    width: '150px',
    alignSelf: 'center',
    maxHeight: 180,
  },
  alertModal: {
    maxWidth: 350,
  },
  alertActiveBtn: {
    margin: `${SPACING.SPACE_32} auto ${SPACING.SPACE_10}`,
  },
  refetchScreen: {
    minHeight: '200px',
    padding: '40px',
    background: theme.bgPrimary,
    borderRadius: '10px',
    marginTop: SPACING.SPACE_30,
  },
  refetchBtnWrapper: {
    marginTop: SPACING.SPACE_10,
    borderColor: COLORS.BLUE,
    borderWidth: 2,
    borderRadius: 6,
  },
  refetchBtn: {
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    margin: '10px auto',
  },
  detailsModal: {
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    minHeight: 200,
    maxWidth: 570,
  },
  dynamicTitle: {
    margin: 'auto',
  },
  onboardModalCon: {
    backgroundColor: `${theme.obBgAlgo} !important`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${SPACING.SPACE_24} ${SPACING.SPACE_32}`,
  },
  warning: {
    position: 'relative',
    padding: '8px 20px',
    backgroundColor: theme.orangeLightBg,
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
  },
  warningClose: {
    display: 'flex',
    marginTop: SPACING.SPACE_4,
  },
  warningText: {

  },
  '@media only screen and (max-width: 786px)': {
    screen: {
      margin: '64px 0 20px',
    },
  },
})

export default withTheme(stylesheet)(Backtests)
