/* eslint-disable import/prefer-default-export */

export const BUY_SELL_TYPE_MAP = {
  DIRECT: { TYPE: 'DIRECT' },
  DEPLOYED: { TYPE: 'DEPLOYED' },
  NOTIFICATION: { TYPE: 'NOTIFICATION' },
  STOP_ALGO: { TYPE: 'STOP_ALGO' },
  PORTFOLIO_EXIT: { TYPE: 'PORTFOLIO_EXIT' },

}
