import { segSymConverter } from '../../utils/common'
import {
  SCAN_STOCKS_FAILURE,
  SCAN_STOCKS_INIT,
  SCAN_STOCKS_SUCCESS,
  SAVE_SCREEN_INIT,
  SAVE_SCREEN_SUCCESS,
  SAVE_SCREEN_FAILURE,
  SCREENER_STATE_INIT,
  SCREENER_STATE_SUCCESS,
  SCREENER_STATE_FAILURE,
  CLEAR_SCREENER_RESP,
  PLACE_DIRECT_SUCCESS,
  PLACE_DIRECT_INIT,
  PLACE_DIRECT_FAILURE,
  SCREENER_TRAINER_INIT,
  SCREENER_TRAINER_SUCCESS,
  SCREENER_TRAINER_FAILURE,
  SCREENER_TREE_INIT,
  SCREENER_TREE_SUCCESS,
  SCREENER_TREE_FAILURE,
  RESET_SCREENER_TREE_FETCH,
  COPY_SCREENER,
  COPY_SCREENER_SUCCESS,
  COPY_SCREENER_FAILURE,
  COPY_SHARED_SCREENER,
  GET_SCRIP_LOT_SIZE_INIT,
  GET_SCRIP_LOT_SIZE_SUCCESS,
  GET_SCRIP_LOT_SIZE_FAILURE,
} from './actionTypes'

export function scanStocksSuccess(data) {
  return {
    type: SCAN_STOCKS_SUCCESS,
    data,
  }
}

export function scanStocksFailure(error) {
  return {
    type: SCAN_STOCKS_FAILURE,
    error,
  }
}

export function scanStocks(params, headers, refetchState) {
  return {
    type: SCAN_STOCKS_INIT,
    params,
    headers,
    refetchState,
  }
}

export function saveScreenerSuccess(data) {
  return {
    type: SAVE_SCREEN_SUCCESS,
    data,
  }
}

export function saveScreenerFailure(error) {
  return {
    type: SAVE_SCREEN_FAILURE,
    error,
  }
}

export function saveScreener(params, headers, refetchState) {
  return {
    type: SAVE_SCREEN_INIT,
    params,
    headers,
    refetchState,
  }
}

export function getScreenerStateSuccess(data) {
  return {
    type: SCREENER_STATE_SUCCESS,
    data,
  }
}

export function getScreenerStateFailure(error) {
  return {
    type: SCREENER_STATE_FAILURE,
    error,
  }
}

export function getScreenerState(params, headers, screenName) {
  return {
    type: SCREENER_STATE_INIT,
    params,
    headers,
    screenName,
  }
}

export function clearScreenResp() {
  return {
    type: CLEAR_SCREENER_RESP,
  }
}

export function placeDirectSuccess(data) {
  return {
    type: PLACE_DIRECT_SUCCESS,
    data,
  }
}

export function placeDirectFailure(error) {
  return {
    type: PLACE_DIRECT_FAILURE,
    error,
  }
}

export function placeDirect(params, headers) {
  return {
    type: PLACE_DIRECT_INIT,
    params,
    headers,
  }
}

export function screenerTrainerSuccess(data) {
  return {
    type: SCREENER_TRAINER_SUCCESS,
    data,
  }
}

export function screenerTrainerFailure(error) {
  return {
    type: SCREENER_TRAINER_FAILURE,
    error,
  }
}

export function screenerTrainer(params, headers) {
  return {
    type: SCREENER_TRAINER_INIT,
    params,
    headers,
  }
}

export function screenerTreeSuccess(data) {
  return {
    type: SCREENER_TREE_SUCCESS,
    data,
  }
}

export function screenerTreeFailure(error) {
  return {
    type: SCREENER_TREE_FAILURE,
    error,
  }
}

export function screenerTree(headers) {
  return {
    type: SCREENER_TREE_INIT,
    headers,
  }
}

export function resetScreenerTreeFetch() {
  return {
    type: RESET_SCREENER_TREE_FETCH,
  }
}

export function copyScreenerSuccess(data) {
  return {
    type: COPY_SCREENER_SUCCESS,
    data,
  }
}

export function copyScreenerFailure(error) {
  return {
    type: COPY_SCREENER_FAILURE,
    error,
  }
}

export function copyScreener(params) {
  return {
    type: COPY_SCREENER,
    params,
  }
}

export function copySharedScreener(params) {
  return {
    type: COPY_SHARED_SCREENER,
    params,
  }
}

export function getScripLotSize(params) {
  const { segment, symbol } = segSymConverter(params)
  const pair = [segment, symbol]
  const details_params = {
    symbols: [pair],
  }
  return {
    type: GET_SCRIP_LOT_SIZE_INIT,
    details_params,
  }
}

export function getScripLotSizeSuccess(data) {
  return {
    type: GET_SCRIP_LOT_SIZE_SUCCESS,
    data,
  }
}

export function getScripLotSizeSuccessFailure(error) {
  return {
    type: GET_SCRIP_LOT_SIZE_FAILURE,
    error,
  }
}
