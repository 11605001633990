import React from 'react'

import Auth from '../../containers/Auth/Auth-redux'
import CustomModal from '../../UI/CustomModal'

import { AUTH_ACTIVE_STATE_MAP } from '../../containers/Auth/Auth'

const ABLoginContainer = React.memo(({
  visible,
  onDismiss,
  dismissKey,
  loginProps,
}) => {
  if (!visible) return null
  return (
    <CustomModal
      onDismiss={onDismiss}
      dismissKey={dismissKey}
      visible={visible}
    >
      <Auth
        activeState={AUTH_ACTIVE_STATE_MAP.AB_LOGIN}
        {...loginProps}
      />
    </CustomModal>
  )
})

export default ABLoginContainer
