import {
  GET_STOCKS_INIT,
  GET_STOCKS_SUCCESS,
  GET_STOCKS_FAILURE,
  UPDATE_PREFS_INIT,
  UPDATE_PREFS_SUCCESS,
  UPDATE_PREFS_FAILURE,
  DEPLOY_ALGO_INIT,
  DEPLOY_ALGO_SUCCESS,
  DEPLOY_ALGO_FAILURE,
  CLEAR_RESP,
  GET_ALGO_STATE_INIT,
  GET_ALGO_STATE_SUCCESS,
  GET_ALGO_STATE_FAILURE,
  CLEAR_ALGO_STATE_RESP,
  GET_INDICATORS_INIT,
  GET_INDICATORS_SUCCESS,
  GET_INDICATORS_FAILURE,
  GET_TRAINER_MODEL_INIT,
  GET_TRAINER_MODEL_SUCCESS,
  GET_TRAINER_MODEL_FAILURE,
  SET_LAUNCH_PARAMS,
  CLEAR_LAUNCH_PARAMS,
  UPDATE_SCRIPLIST,
  COPY_ALGO,
  COPY_ALGO_SUCCESS,
  COPY_ALGO_FAILURE,
  GET_DEFAULT_STOCKS,
  GET_DEFAULT_STOCKS_SUCCESS,
  GET_DEFAULT_STOCKS_FAILURE,
  GET_ALGO_FROM_TOKEN,
  GET_ALGO_FROM_TOKEN_SUCCESS,
  GET_ALGO_FROM_TOKEN_FAILURE,
} from './actionTypes'

const initialState = {
  isFetchingStocks: false,
  stocksError: false,
  stockErrorMsg: '',
  stocks: [],
  isUpdatingPrefs: true,
  updatingPrefsError: false,
  updatingPrefsResp: {},
  isDeployingAlgo: false,
  deployingAlgoError: false,
  deployAlgoResp: {},
  errorMsg: null,
  algoState: null,
  algoStateError: false,
  isFetchingState: false,
  indicators: {},
  isFetchingIndicators: false,
  indicatorsError: false,
  isFetchingModel: false,
  trainerModel: {},
  trainerModelError: false,
  trainerModelErrorMsg: '',
  launchParams: '',
  newScripList: null,

  isCopyingAlgo: false,
  copyAlgoResp: null,
  copyAlgoError: false,
  copyAlgoErrorMsg: '',
  isAlgoComplete: true,
  algo_live: false,

  defaultStocksError: false,

  algoStateFromTokenResp: {},
  algoStateFromTokenError: false,
  algoStateFromTokenErrorMsg: '',
  isFetchingAlgoFromToken: false,
}

const createAlgo = (state = initialState, action) => {
  switch (action.type) {
    case GET_STOCKS_INIT:
      return {
        ...state,
        isFetchingStocks: true,
        stocksError: false,
        stockErrorMsg: '',
      }

    case GET_STOCKS_SUCCESS: {
      const { data: { data } } = action
      return {
        ...state,
        isFetchingStocks: false,
        stocks: data,
      }
    }

    case GET_STOCKS_FAILURE:
      return {
        ...state,
        isFetchingStocks: false,
        stocksError: true,
        stockErrorMsg: action.error,
      }

    case UPDATE_PREFS_INIT:
      return {
        ...state,
        isUpdatingPrefs: true,
        updatingPrefsError: false,
      }

    case UPDATE_PREFS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isUpdatingPrefs: false,
        updatingPrefsResp: data,
      }
    }

    case UPDATE_PREFS_FAILURE:
      return {
        ...state,
        isUpdatingPrefs: false,
        updatingPrefsError: true,
      }

    case DEPLOY_ALGO_INIT:
      return {
        ...state,
        deployAlgoResp: {},
        isDeployingAlgo: true,
        deployingAlgoError: false,
      }

    case DEPLOY_ALGO_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isDeployingAlgo: false,
        deployAlgoResp: data,
      }
    }

    case DEPLOY_ALGO_FAILURE: {
      const { error } = action
      return {
        ...state,
        isDeployingAlgo: false,
        deployingAlgoError: true,
        errorMsg: error,
      }
    }

    case CLEAR_RESP: {
      return {
        ...state,
        isDeployingAlgo: false,
        deployAlgoResp: {},
        deployingAlgoError: false,
        errorMsg: null,
      }
    }

    case GET_ALGO_STATE_INIT:
      return {
        ...state,
        isFetchingState: true,
        algoStateError: false,
        algo_live: false,
      }

    case GET_ALGO_STATE_SUCCESS: {
      const { data } = action
      const {
        entry_logic, exit_logic, algo_state, complete = true, algo_live, holding_type,
      } = data.algo
      return {
        ...state,
        isFetchingState: false,
        algoState: {
          ...algo_state, entry_logic, exit_logic, holding_type,
        },
        isAlgoComplete: complete,
        algo_live: algo_live || data.algo_live,
      }
    }

    case GET_ALGO_STATE_FAILURE:
      return {
        ...state,
        isFetchingState: false,
        algoStateError: true,
      }

    case CLEAR_ALGO_STATE_RESP: {
      return {
        ...state,
        algoState: null,
        algoStateError: false,
        isFetchingState: false,
      }
    }

    case GET_INDICATORS_INIT: {
      return {
        ...state,
        isFetchingIndicators: true,
        indicatorsError: false,
      }
    }

    case GET_INDICATORS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingIndicators: false,
        indicators: data,
      }
    }

    case GET_INDICATORS_FAILURE:
      return {
        ...state,
        isFetchingIndicators: false,
        indicatorsError: true,
      }

    case GET_TRAINER_MODEL_INIT:
      return {
        ...state,
        isFetchingModel: true,
        trainerModelError: false,
      }

    case GET_TRAINER_MODEL_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingModel: false,
        trainerModel: data,
      }
    }

    case GET_TRAINER_MODEL_FAILURE:
      return {
        ...state,
        isFetchingModel: false,
        trainerModelError: true,
        trainerModelErrorMsg: action.error,
      }

    case SET_LAUNCH_PARAMS:
      return {
        ...state,
        launchParams: action.data,
      }

    case CLEAR_LAUNCH_PARAMS:
      return {
        ...state,
        launchParams: '',
      }

    case UPDATE_SCRIPLIST:
      return {
        ...state,
        newScripList: action.list,
      }

    case COPY_ALGO:
      return {
        ...state,
        isCopyingAlgo: true,
        copyAlgoResp: null,
        copyAlgoError: false,
        copyAlgoErrorMsg: '',
      }

    case COPY_ALGO_SUCCESS:
      return {
        ...state,
        isCopyingAlgo: false,
        copyAlgoResp: action.data,
      }

    case COPY_ALGO_FAILURE:
      return {
        ...state,
        isCopyingAlgo: false,
        copyAlgoError: true,
        copyAlgoErrorMsg: action.error,
      }

    case GET_DEFAULT_STOCKS:
      return {
        ...state,
        isFetchingStocks: true,
        defaultStocksError: false,
      }

    case GET_DEFAULT_STOCKS_SUCCESS: {
      return {
        ...state,
        isFetchingStocks: false,
        stocks: [...action.data],
      }
    }

    case GET_DEFAULT_STOCKS_FAILURE: {
      return {
        ...state,
        isFetchingStocks: false,
        defaultStocksError: true,
      }
    }

    case GET_ALGO_FROM_TOKEN: {
      return {
        ...state,
        isFetchingAlgoFromToken: true,
        algoStateFromTokenErrorMsg: '',
        algoStateFromTokenError: false,
        algoStateFromTokenResp: {},
      }
    }

    case GET_ALGO_FROM_TOKEN_SUCCESS: {
      return {
        ...state,
        isFetchingAlgoFromToken: false,
        algoStateFromTokenResp: action.data,
      }
    }

    case GET_ALGO_FROM_TOKEN_FAILURE: {
      return {
        ...state,
        isFetchingAlgoFromToken: false,
        algoStateFromTokenErrorMsg: action.error,
        algoStateFromTokenError: true,
      }
    }

    default:
      return state
  }
}

export default createAlgo
