export const GET_NOTIFICATIONS_INIT = 'GET_NOTIFICATIONS_INIT'
export const GET_NOTIFICATIONS_OPEN_STREAK = 'GET_NOTIFICATIONS_INIT'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE'

export const PLACE_ORDER_INIT = 'PLACE_ORDER_INIT'
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS'
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE'

export const MARK_USED_INIT = 'MARK_USED_INIT'
export const MARK_USED_SUCCESS = 'MARK_USED_SUCCESS'
export const MARK_USED_FAILURE = 'MARK_USED_FAILURE'

export const MARK_READ_INIT = 'MARK_READ_INIT'
export const MARK_READ_SUCCESS = 'MARK_READ_SUCCESS'
export const MARK_READ_FAILURE = 'MARK_READ_FAILURE'

export const MARK_NOTIFICATION_USED_INIT = 'MARK_NOTIFICATION_USED_INIT'
export const MARK_NOTIFICATION_USED_SUCCESS = 'MARK_NOTIFICATION_USED_SUCCESS'
export const MARK_NOTIFICATION_USED_FAILURE = 'MARK_NOTIFICATION_USED_FAILURE'

export const UPDATE_DEPLOYED_NOTIF_COUNT = 'UPDATE_DEPLOYED_NOTIF_COUNT'

export const CLEAR_NOTIF_RESP = 'CLEAR_NOTIF_RESP'

export const ORDER_LOG_INIT = 'ORDER_LOG_INIT'
export const ORDER_LOG_SUCCESS = 'ORDER_LOG_SUCCESS'
export const ORDER_LOG_FAILURE = 'ORDER_LOG_FAILURE'

export const REGISTER_NOTIFICATION_INIT = 'REGISTER_NOTIFICATION_INIT'
export const REGISTER_NOTIFICATION_SUCCESS = 'REGISTER_NOTIFICATION_SUCCESS'
export const REGISTER_NOTIFICATION_FAILURE = 'REGISTER_NOTIFICATION_FAILURE'

export const UNREGISTER_NOTIFICATION_INIT = 'UNREGISTER_NOTIFICATION_INIT'
export const UNREGISTER_NOTIFICATION_SUCCESS = 'UNREGISTER_NOTIFICATION_SUCCESS'
export const UNREGISTER_NOTIFICATION_FAILURE = 'UNREGISTER_NOTIFICATION_FAILURE'

export const GET_NOTIFICATION_COUNT_INIT = 'GET_NOTIFICATION_COUNT_INIT'
export const GET_NOTIFICATION_COUNT_SUCCESS = 'GET_NOTIFICATION_COUNT_SUCCESS'
export const GET_NOTIFICATION_COUNT_FAILURE = 'GET_NOTIFICATION_COUNT_FAILURE'

export const GET_DISCLAIMER_NOTIFICATIONS_INIT = 'GET_DISCLAIMER_NOTIFICATIONS_INIT'
export const GET_DISCLAIMER_NOTIFICATIONS_SUCCESS = 'GET_DISCLAIMER_NOTIFICATIONS_SUCCESS'
export const GET_DISCLAIMER_NOTIFICATIONS_FAILURE = 'GET_DISCLAIMER_NOTIFICATIONS_FAILURE'
