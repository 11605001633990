/* eslint-disable react/no-array-index-key */
import { makeStyles } from '@material-ui/styles'
import React from 'react'

import PlaceHolderLoader from '../../PlaceHolderLoader'
import StreakCarousalLoader from '../StreakCarousalLoader'

import { SPACING, theme } from '../../../Theme'

function BacktestLoader({ isMobile, displayDevice }) {
  const styles = stylesheet()
  const renderCell = () => {
    return (
      <div className={styles.cell} style={{ width: isMobile ? '50%' : 140 }}>
        <PlaceHolderLoader className={styles.cellValue} />
        <PlaceHolderLoader className={styles.cellLabel} />
      </div>
    )
  }

  return (
    <div className={styles.backtestContainer}>
      <PlaceHolderLoader className={styles.screenHeader} />
      <div className={styles.header}>
        <PlaceHolderLoader className={styles.algoName} />
        <PlaceHolderLoader className={styles.headerAction} />
      </div>
      <PlaceHolderLoader className={styles.desc_1} />
      <PlaceHolderLoader className={styles.desc_2} />
      <div className={styles.results}>
        <div className={styles.resultsHeader}>
          <PlaceHolderLoader className={styles.resultsTitle} />
          <div className={styles.resultsHeaderAction}>
            <PlaceHolderLoader className={styles.resultsAction_1} />
            <PlaceHolderLoader className={styles.resultsAction_2} />
          </div>
        </div>
      </div>
      <div className={styles.btStocks}>
        <StreakCarousalLoader displayDevice={displayDevice} />
      </div>
      <div className={styles.detailsSection}>
        <div className={styles.details}>
          <div className={styles.detailsHeader}>
            <div className={styles.segSym}>
              <PlaceHolderLoader className={styles.segSymImg} />
              <PlaceHolderLoader className={styles.sym} />
              <PlaceHolderLoader className={styles.pnl} />
            </div>
            <div className={styles.detailsAction}>
              <PlaceHolderLoader className={styles.deploy} />
            </div>
          </div>
        </div>
        <PlaceHolderLoader className={styles.graph} />
        <div className={styles.backtestDetails}>
          {renderCell()}
          {renderCell()}
          {renderCell()}
          {renderCell()}
          {renderCell()}
          {renderCell()}
          {renderCell()}
          {renderCell()}
          {renderCell()}
          {renderCell()}
        </div>
        <div className={styles.transaction}>
          <div className={styles.transactionHeader}>
            <PlaceHolderLoader className={styles.transactionHeaderLeft} />
            <PlaceHolderLoader className={styles.transactionHeaderRight} />
          </div>
          <PlaceHolderLoader className={styles.transactionChart} />
          <div className={styles.listView}>
            {[...Array(8)].map((item, index) => (
              <PlaceHolderLoader key={`transaction${index}`} className={styles.row} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  backtestContainer: {},
  screenHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: SPACING.HEADER_HEIGHT,
    // position: 'sticky',
    zIndex: '9998',
    top: SPACING.HEADER_HEIGHT,
    padding: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_10}`,
    backgroundColor: theme.screenBg,
    maxWidth: 200,
    marginBottom: SPACING.SPACE_10,
    '& > img': {
      height: '20px !important',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: SPACING.SPACE_20,
  },
  algoName: {
    width: '100%',
    maxWidth: 180,
    height: 20,
  },
  headerAction: {
    width: '100%',
    maxWidth: 100,
    height: 20,
    marginLeft: SPACING.SPACE_20,
  },
  desc_1: {
    marginTop: SPACING.SPACE_10,
    height: 12,
    display: 'flex',
  },
  desc_2: {
    marginTop: SPACING.SPACE_6,
    height: 12,
    display: 'flex',
    maxWidth: 300,
  },
  results: {
    marginTop: SPACING.SPACE_28,
    padding: `${SPACING.SPACE_10} 0`,
    // position: 'sticky',
    top: 100,
    zIndex: '999',
    backgroundColor: theme.screenBg,
    transition: 'all 250ms',
  },
  resultsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  resultsTitle: {
    width: '50%',
    maxWidth: 280,
    height: 12,
  },
  resultsHeaderAction: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  resultsAction_1: {
    width: '100%',
    maxWidth: 160,
    height: 20,
    marginRight: SPACING.SPACE_10,
  },
  resultsAction_2: {
    width: '100%',
    maxWidth: 80,
    height: 20,
  },
  btStocks: {
    position: 'relative',
    height: 165,
  },
  details: {},
  detailsSection: {
    backgroundColor: theme.bgPrimary,
  },
  detailsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.bgPrimary,
    alignItems: 'center',
  },
  segSym: {
    position: 'relative',
    zIndex: '99',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_12} ${SPACING.SPACE_20}`,
  },
  segSymImg: {
    height: 26,
    width: 26,
    borderRadius: '50%',
    overflow: 'hidden',
    margin: `0 ${SPACING.SPACE_10} 0 ${SPACING.SPACE_10}`,
  },
  sym: {
    marginRight: SPACING.SPACE_20,
    maxWidth: '22%',
    height: 20,
  },
  pnl: {
    maxWidth: 100,
    height: 20,
  },
  detailsAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  deploy: {
    width: 100,
    height: 20,
  },
  graph: {
    height: 210,
    padding: `0 ${SPACING.SPACE_14}`,
  },
  backtestDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${SPACING.SPACE_10} 0`,
    padding: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_4}`,
    borderTop: `1px solid ${theme.borderColor}`,
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  cell: {
    padding: SPACING.SPACE_10,
  },
  cellValue: {
    height: 12,
    display: 'flex',
    width: 60,
  },
  cellLabel: {
    height: 12,
    display: 'flex',
    width: 100,
    marginTop: SPACING.SPACE_4,
  },
  transaction: {
    padding: `0 ${SPACING.SPACE_14} ${SPACING.SPACE_20}`,
  },
  transactionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 58,
  },
  transactionChart: {
    height: 210,
  },
  listView: {
    display: 'flex',
    flexDirection: 'column',
  },
  transactionHeaderLeft: {
    height: 12,
    width: 100,
    display: 'flex',
  },
  transactionHeaderRight: {
    height: 20,
    maxWidth: 120,
    display: 'flex',
  },
  row: {
    height: 12,
    marginTop: SPACING.SPACE_16,
  },
})

export default BacktestLoader
