import {
  GET_NOTIFICATIONS_INIT,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  PLACE_ORDER_INIT,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  MARK_USED_INIT,
  MARK_USED_SUCCESS,
  MARK_USED_FAILURE,
  CLEAR_NOTIF_RESP,
  MARK_READ_SUCCESS,
  MARK_READ_INIT,
  MARK_READ_FAILURE,
  ORDER_LOG_INIT,
  ORDER_LOG_SUCCESS,
  ORDER_LOG_FAILURE,
  MARK_NOTIFICATION_USED_INIT,
  MARK_NOTIFICATION_USED_SUCCESS,
  MARK_NOTIFICATION_USED_FAILURE,
  UPDATE_DEPLOYED_NOTIF_COUNT,
  GET_NOTIFICATION_COUNT_INIT,
  GET_NOTIFICATION_COUNT_SUCCESS,
  GET_NOTIFICATION_COUNT_FAILURE,
  GET_DISCLAIMER_NOTIFICATIONS_INIT,
  GET_DISCLAIMER_NOTIFICATIONS_SUCCESS,
  GET_DISCLAIMER_NOTIFICATIONS_FAILURE,
} from './actionTypes'

export function getNotifications(params) {
  return {
    type: GET_NOTIFICATIONS_INIT,
    params,
  }
}

export function getNotificationsSuccess(data) {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    data,
  }
}

export function getNotificationsFailure(error) {
  return {
    type: GET_NOTIFICATIONS_FAILURE,
    error,
  }
}

export function placeOrder(orderType, params, headers) {
  return {
    type: PLACE_ORDER_INIT,
    orderType,
    params,
    headers,
  }
}

export function placeOrderSuccess(data) {
  return {
    type: PLACE_ORDER_SUCCESS,
    data,
  }
}

export function placeOrderFailure(error) {
  return {
    type: PLACE_ORDER_FAILURE,
    error,
  }
}

export function markUsed(params, headers) {
  return {
    type: MARK_USED_INIT,
    params,
    headers,
  }
}

export function markUsedSuccess(data) {
  return {
    type: MARK_USED_SUCCESS,
    data,
  }
}

export function markUsedFailure(error) {
  return {
    type: MARK_USED_FAILURE,
    error,
  }
}

export function markRead(params, headers, notifActiveTab) {
  return {
    type: MARK_READ_INIT,
    params,
    headers,
    notifActiveTab,
  }
}

export function markReadSuccess(data, notifActiveTab) {
  return {
    type: MARK_READ_SUCCESS,
    data,
    notifActiveTab,
  }
}

export function markReadFailure(error) {
  return {
    type: MARK_READ_FAILURE,
    error,
  }
}

export function clearNotifResp() {
  return {
    type: CLEAR_NOTIF_RESP,
  }
}

// marks notifs of a particular section & tab combination (like waiting/entered of live/paper)
export function markNotificationUsed(params, headers) {
  return {
    type: MARK_NOTIFICATION_USED_INIT,
    params,
    headers,
  }
}
export function markNotificationUsedSuccess(data) {
  return {
    type: MARK_NOTIFICATION_USED_SUCCESS,
    data,
  }
}
export function markNotificationUsedFailure(error) {
  return {
    type: MARK_NOTIFICATION_USED_FAILURE,
    error,
  }
}

export function updateDeployedNotifCount(countObj) {
  return {
    type: UPDATE_DEPLOYED_NOTIF_COUNT,
    countObj,
    // reduxId,
    // activeTabKey,
    // count,
  }
}

export function fetchOrderlog(deployment_uuid, headers) {
  return {
    type: ORDER_LOG_INIT,
    deployment_uuid,
    headers,
  }
}

export function fetchOrderlogSuccess(data) {
  return {
    type: ORDER_LOG_SUCCESS,
    data,
  }
}

export function fetchOrderlogFailure(error) {
  return {
    type: ORDER_LOG_FAILURE,
    error,
  }
}

export function getNotificationCount() {
  return {
    type: GET_NOTIFICATION_COUNT_INIT,
  }
}

export function getNotificationCountSuccess(data) {
  return {
    type: GET_NOTIFICATION_COUNT_SUCCESS,
    data,
  }
}

export function getNotificationCountFailure(error) {
  return {
    type: GET_NOTIFICATION_COUNT_FAILURE,
    error,
  }
}

export function getDisclaimerNotifications(params, headers) {
  return {
    type: GET_DISCLAIMER_NOTIFICATIONS_INIT,
    params,
    headers,
  }
}

export function getDisclaimerNotificationsSuccess(data, broker) {
  return {
    type: GET_DISCLAIMER_NOTIFICATIONS_SUCCESS,
    data,
    broker,
  }
}

export function getDisclaimerNotificationsFailure(error) {
  return {
    type: GET_DISCLAIMER_NOTIFICATIONS_FAILURE,
    error,
  }
}
