import cloneDeep from 'lodash/cloneDeep'
// import isEmpty from 'lodash/isEmpty'
import {
  GET_NOTIFICATIONS_INIT,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  PLACE_ORDER_INIT,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  MARK_USED_INIT,
  MARK_USED_SUCCESS,
  MARK_USED_FAILURE,
  CLEAR_NOTIF_RESP,
  MARK_READ_INIT,
  MARK_READ_SUCCESS,
  MARK_READ_FAILURE,
  ORDER_LOG_INIT,
  ORDER_LOG_SUCCESS,
  ORDER_LOG_FAILURE,
  //
  REGISTER_NOTIFICATION_INIT,
  REGISTER_NOTIFICATION_SUCCESS,
  REGISTER_NOTIFICATION_FAILURE,
  UNREGISTER_NOTIFICATION_INIT,
  UNREGISTER_NOTIFICATION_SUCCESS,
  UNREGISTER_NOTIFICATION_FAILURE,
  MARK_NOTIFICATION_USED_INIT,
  MARK_NOTIFICATION_USED_SUCCESS,
  MARK_NOTIFICATION_USED_FAILURE,
  UPDATE_DEPLOYED_NOTIF_COUNT,
  GET_NOTIFICATION_COUNT_INIT,
  GET_NOTIFICATION_COUNT_SUCCESS,
  GET_NOTIFICATION_COUNT_FAILURE,
  GET_DISCLAIMER_NOTIFICATIONS_INIT,
  GET_DISCLAIMER_NOTIFICATIONS_SUCCESS,
  GET_DISCLAIMER_NOTIFICATIONS_FAILURE,
} from './actionTypes'

import { DEPLOYED_SECTION_MAP } from '../Deployed/deployedUtils'
import { getSlmNotifs, NOTIFICATIONS_SECTION_MAP } from './notificationUtils'
import { BROKERS } from '../../utils/consts'
import { IN_PRODUCTION } from '../../utils/apiEndpoints'

const initialState = {
  notifications: [],
  notification_dict: {},
  used: {},
  // New in v4, This will contain a union of used and read fields from notification_handler/
  // It's used only to find what all notifications have been marked read.
  read: {},
  slmNotifications: [],

  isFetchingNotificationCount: false,
  notificationCountError: false,
  notificationCountErrorMsg: '',

  isFetchingNotifications: false,
  notificationsError: false,
  isPlacingOrder: false,
  placingError: false,
  placingErrorMsg: '',
  placingOrderResp: {},
  isMarking: false,
  markingError: false,
  markingresp: {},
  unread_count: 0,
  unReadCount: {},

  isMarkingNotificationUsed: false,
  markingNotificationError: false,
  markingNotificationErrorMsg: '',
  sectionUnReadCount: {
    [DEPLOYED_SECTION_MAP.NOTIFICATION_TRADING.REDUX_ID]: {
      waiting: 0,
      entered: 0,
      stopped: 0,
    },
    [DEPLOYED_SECTION_MAP.PAPER_TRADING.REDUX_ID]: {
      waiting: 0,
      entered: 0,
      stopped: 0,
    },
  },

  isMarkingRead: false,
  markingReadError: false,
  markReadResp: {},
  isFetchingOrderlog: false,
  orderlogError: false,
  orderlogErrorMsg: '',
  orderLogs: [],
  // registerNotification
  isRegisteringNotification: false,
  registerNotificationResp: {},
  registerNotificationError: false,
  registerNotificationErrorMsg: '',
  //
  isUnRegisteringNotification: false,
  unRegisterNotificationResp: {},
  unRegisterNotificationError: false,
  unRegisterNotificationErrorMsg: '',

  isFetchingDisclaimerNotifications: false,
  disclaimerNotifications: [],
  disclaimerNotificationsCount: 0,
  disclaimerNotificationsError: false,
  disclaimerNotificationsErrorMsg: '',
}

const notification = (state = initialState, action) => {
  switch (action.type) {
    case UNREGISTER_NOTIFICATION_INIT: {
      return {
        ...state,
        isUnRegisteringNotification: true,
        unRegisterNotificationResp: {},
        unRegisterNotificationError: false,
        unRegisterNotificationErrorMsg: '',
      }
    }

    case UNREGISTER_NOTIFICATION_SUCCESS: {
      const { data = { status: 'success' } } = action
      return {
        ...state,
        isUnRegisteringNotification: false,
        unRegisterNotificationResp: data,
        unRegisterNotificationError: false,
        unRegisterNotificationErrorMsg: '',
      }
    }

    case UNREGISTER_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isUnRegisteringNotification: false,
        unRegisterNotificationError: true,
        unRegisterNotificationErrorMsg: 'Error registering',
      }
    }

    case REGISTER_NOTIFICATION_INIT: {
      return {
        ...state,
        isRegisteringNotification: true,
        registerNotificationResp: {},
        registerNotificationError: false,
        registerNotificationErrorMsg: '',
      }
    }

    case REGISTER_NOTIFICATION_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isRegisteringNotification: false,
        registerNotificationResp: data,
        registerNotificationError: false,
        registerNotificationErrorMsg: '',
      }
    }

    case REGISTER_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isRegisteringNotification: false,
        registerNotificationError: true,
        registerNotificationErrorMsg: 'Error registering',
      }
    }

    case GET_NOTIFICATIONS_INIT:
      return {
        ...state,
        isFetchingNotifications: true,
        notificationsError: false,
      }

    case GET_NOTIFICATIONS_SUCCESS: {
      const { data: { results } } = action
      let notifs = {}
      let allNotifs = []
      const {
        notif_dep_list,
        notification_dict,
        used = {}, unread_count,
        unread_split_count = {},
        read = {}, // new field added for v4
      } = results
      let slmNotifications = []
      for (let j = 0; j < notif_dep_list.length; j++) {
        const item = notif_dep_list[j]
        const notifItem = notification_dict[item[0]]
        const { deployment_uuid, deployment_type } = notifItem
        let type = deployment_type === 'screener_alert' ? 'direct' : deployment_type
        type = deployment_uuid && deployment_uuid.slice(0, 6) === 'DIRECT' ? 'direct' : type
        type = type || 'Notifications'
        allNotifs = [...allNotifs, notifItem]
        if (notifs[type]) {
          const oldArr = notifs[type]
          notifs = {
            ...notifs,
            [type]: [...oldArr, notifItem],
          }
        } else {
          notifs[type] = [notifItem]
        }
        slmNotifications = [...getSlmNotifs(notifItem.notifications, used, j), ...slmNotifications]
      }
      const notifications = { ...notifs, [NOTIFICATIONS_SECTION_MAP.ALL.DATA_KEY]: allNotifs }
      const unReadCount = {
        [NOTIFICATIONS_SECTION_MAP.LIVE.DATA_KEY]: unread_split_count.notifications,
        [NOTIFICATIONS_SECTION_MAP.PAPER.DATA_KEY]: unread_split_count.paper,
        [NOTIFICATIONS_SECTION_MAP.SCANNER.DATA_KEY]: unread_split_count.direct,
      }
      return {
        ...state,
        isFetchingNotifications: false,
        notificationsError: false,
        notifications,
        notification_dict,
        used: used || {},
        read: { ...used, ...read },
        unread_count,
        unReadCount,
        slmNotifications,
      }
    }

    case GET_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        notifications: [],
        isFetchingNotifications: false,
        notificationsError: true,
      }
    }

    case PLACE_ORDER_INIT:
      return {
        ...state,
        isPlacingOrder: true,
        placingError: false,
        placingOrderResp: {},
      }

    case PLACE_ORDER_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isPlacingOrder: false,
        placingOrderResp: data,
      }
    }

    case PLACE_ORDER_FAILURE: {
      const { error } = action
      return {
        ...state,
        isPlacingOrder: false,
        placingError: true,
        placingErrorMsg: error,
      }
    }

    case MARK_USED_INIT:
      return {
        ...state,
        isMarking: true,
        markingresp: {},
        markingError: false,
      }

    case MARK_USED_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isMarking: false,
        markingresp: data,
      }
    }

    case MARK_USED_FAILURE: {
      return {
        ...state,
        isMarking: false,
        markingError: true,
      }
    }

    case MARK_READ_INIT:
      return {
        ...state,
        isMarkingRead: true,
        markingReadError: false,
        unread_count: 0,
      }

    case MARK_READ_SUCCESS: {
      const { data, notifActiveTab } = action
      let modCount = { ...state.unReadCount }
      if (modCount[notifActiveTab] && notifActiveTab !== NOTIFICATIONS_SECTION_MAP.ALL.DATA_KEY) {
        modCount[notifActiveTab] = 0
      } else {
        modCount = {}
      }
      return {
        ...state,
        isMarkingRead: false,
        markReadResp: data,
        unReadCount: modCount,
      }
    }

    case MARK_READ_FAILURE:
      return {
        ...state,
        isMarkingRead: false,
        markingReadError: true,
      }

    case MARK_NOTIFICATION_USED_INIT:
      return {
        ...state,
        isMarkingNotificationUsed: true,
        markingNotificationError: false,
        markingNotificationErrorMsg: '',
      }

    case MARK_NOTIFICATION_USED_SUCCESS:
      return {
        ...state,
        isMarkingNotificationUsed: false,
        markingNotificationError: false,
        markingNotificationErrorMsg: '',
      }

    case MARK_NOTIFICATION_USED_FAILURE: {
      const { error } = action
      return {
        ...state,
        isMarkingNotificationUsed: false,
        markingNotificationError: true,
        markingNotificationErrorMsg: error,
      }
    }

    case UPDATE_DEPLOYED_NOTIF_COUNT: {
      const { countObj } = action
      // const {  reduxId, activeTabKey, count } = action
      // if(isEqual(sectionUnReadCount, countObj)) {
      //   return state
      // }
      const { sectionUnReadCount } = state
      const newSectionUnReadCount = cloneDeep(sectionUnReadCount)

      // newSectionUnReadCount[reduxId][activeTabKey] = count
      return {
        ...state,
        sectionUnReadCount: Object.assign(newSectionUnReadCount, countObj),
      }
    }

    case CLEAR_NOTIF_RESP:
      return {
        ...state,
        isPlacingOrder: false,
        placingError: false,
        placingOrderResp: {},
        isMarking: false,
        markingError: false,
        markingresp: {},
        isMarkingRead: false,
        markingReadError: false,
        markReadResp: {},
      }

    case ORDER_LOG_INIT:
      return {
        ...state,
        isFetchingOrderlog: true,
        orderlogError: false,
        orderlogErrorMsg: '',
      }

    case ORDER_LOG_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingOrderlog: false,
        orderLogs: data,
      }
    }

    case ORDER_LOG_FAILURE:
      return {
        ...state,
        isFetchingOrderlog: false,
        orderlogError: true,
        orderlogErrorMsg: action.error,
      }

    case GET_NOTIFICATION_COUNT_INIT: {
      return {
        ...state,
        isFetchingNotificationCount: true,
        notificationCountError: false,
      }
    }

    case GET_NOTIFICATION_COUNT_SUCCESS: {
      const { data: { unread_split_count } } = action

      const unReadCount = {
        [NOTIFICATIONS_SECTION_MAP.LIVE.DATA_KEY]: unread_split_count.notifications,
        [NOTIFICATIONS_SECTION_MAP.PAPER.DATA_KEY]: unread_split_count.paper,
        [NOTIFICATIONS_SECTION_MAP.SCANNER.DATA_KEY]: unread_split_count.direct,
      }
      return {
        ...state,
        isFetchingNotificationCount: false,
        unReadCount,

      }
    }

    case GET_NOTIFICATION_COUNT_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingNotificationCount: false,
        notificationCountError: true,
        notificationCountErrorMsg: error,

      }
    }

    case GET_DISCLAIMER_NOTIFICATIONS_INIT: {
      return {
        ...state,
        isFetchingDisclaimerNotifications: true,
        disclaimerNotificationsError: false,
      }
    }

    case GET_DISCLAIMER_NOTIFICATIONS_SUCCESS: {
      const { data, broker } = action
      const notifcations = []
      const data2 = data[0].content.rendered
      const doc = new DOMParser().parseFromString(data2, 'text/html')
      doc.querySelectorAll('tr').forEach((tr, index) => {
        if (index === 0) {
          return
        }
        const json = {}
        json.id = tr.cells[0].innerText
        json.title = tr.cells[1].innerText
        json.description = tr.cells[2].innerText
        json.link = tr.cells[3].innerText
        json.visible = tr.cells[4].innerText === 'yes'
        json.expire = tr.cells[5].innerText
        json.broker = BROKERS[tr.cells[6].innerText]?.name || 'streak'
        const pushNotifs = IN_PRODUCTION ? json.description && json.visible : json.description
        const expiresNotifs = (json.expire * 1000) > Date.now()
        if (pushNotifs && expiresNotifs && (json.broker === 'streak' || json.broker === broker)) {
          notifcations.push(json)
        }
      })

      return {
        ...state,
        isFetchingDisclaimerNotifications: false,
        disclaimerNotifications: notifcations,
        unReadCount: {
          ...state.unReadCount,
          [NOTIFICATIONS_SECTION_MAP.DISCLAIMER.DATA_KEY]: notifcations.length,
        },
      }
    }

    case GET_DISCLAIMER_NOTIFICATIONS_FAILURE: {
      const { error } = action
      return {
        ...state,
        isFetchingDisclaimerNotifications: false,
        disclaimerNotificationsError: true,
        disclaimerNotificationsErrorMsg: error,
      }
    }

    default:
      return state
  }
}

export default notification
