import React from 'react'
import Button from '../../UI/Button'
import HOME_ASSETS from '../assets'

export const statistics = [
  { title: 'Backtests', value: '90 Million', icon: HOME_ASSETS.flagPurple },
  { title: 'Technicals', value: '2 Billion', icon: HOME_ASSETS.flagOrange },
  { title: 'Deployments', value: '15 Million', icon: HOME_ASSETS.flagBlue },
  { title: 'Transacted Value', value: '13 Billion USD', icon: HOME_ASSETS.flagPink },
]

function Statistic({ onPressDemo, isMobile, updateGA }) {
  return (
    // <div className="StatisticWrapperContainer">
    //   <div className="bannerContainerCenter">
    //     <div className="bannerCenterContent">
    //       <h2 className="statsTitle">
    //         2 Million +
    //       </h2>
    //       <h2 className="statsSubTitle">
    //         Traders choose Streak, DO YOU?
    //       </h2>
    //       <Button
    //         buttonColor="blue"
    //         text="Start Free Trial"
    //         btnStyles="getStartedBtn"
    //         labelSize="large"
    //         onPress={onPressDemo}
    //       />
    //     </div>
    //   </div>
    //   <div
    //     className="statsContainer"
    //     style={{
    //       display: 'flex', justifyContent: 'space-evenly', margin: 'auto', width: '90%',
    //     }}
    //   >
    //     {statistics.map((item) => {
    //       const styleText = {
    //         background: item.background,
    //         '-webkit-background-clip': 'text',
    //         '-webkit-text-fill-color': 'transparent',
    //         fontSize: '36px',
    //       }
    //       return (
    //         <div style={{ textAlign: 'center' }}>
    //           <h3 style={styleText}>{item.value}</h3>
    //          <h5 style={{ fontSize: '23px', fontWeight: 'bold',
    // color: 'black' }}> { item.title }</h5 >
    //         </div>
    //       )
    //     })}
    //   </div>
    // </div>
    <div className="StatisticPageWrapper">
      <div className="StatisticWrapperContainer">
        <div className="statBannerContainer">
          <div className="statBannerCenterContent">
            <h1 className="startedTitle" style={{ color: '#ffffff', marginBottom: `${!isMobile && '10px'}`, fontSize: `${isMobile && '30px'}` }}>
              2 Million +
            </h1>
            <p className="startedSubTitle" style={{ color: '#CFD1E2', fontSize: `${isMobile && '18px'}` }}>
              Traders choose Streak, DO YOU?
            </p>
            {!isMobile && (
              <Button
                mode="text"
                buttonColor="START_FREE_TRIAL_BUTTON"
                labelColor="white"
                text="Get Started"
                btnStyles="statsGetStartedBtn"
                labelStyle="statGetStartedLabel"
                onPress={() => {
                  onPressDemo()
                  updateGA('Get Started MileStone', 'MileStone', 1, 'Landing Page')
                  updateGA('Log in', 'SystematicTrading', 1, 'Home')
                }}
              />
            )}
          </div>
          <div
            className="statsContainer"
          >
            {statistics.map(item => (
              <div className="statItem" key={item.title}>
                <img src={item.icon} alt="flag_icon" className="flagIcon" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p style={{ fontFamily: 'multi-display-bold' }}>{item.title}</p>
                  <p style={{ fontFamily: 'multi-display-regular', color: `${isMobile ? '#B0B0B0' : '#ffffff'}` }}>{item.value}</p>
                </div>
              </div>
            ))}
          </div>
          {/* { isMobile && (
            <Button
              mode="text"
              buttonColor="START_FREE_TRIAL_BUTTON"
              labelColor="white"
              text="Start Free Trial"
              btnStyles="statsGetStartedBtn"
              labelStyle="statGetStartedLabel"
              onPress={() => {
                updateGA('Get Started MileStone', 'MileStone', 1, 'Landing Page')
                updateGA('Log in', 'SystematicTrading', 1, 'Home')
                onPressDemo()
              }}
            />
          )} */}
        </div>
      </div>
    </div>
  )
}

export default Statistic
