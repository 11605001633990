import React, { useEffect, useRef } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import classnames from 'classnames'

import CustomText from '../../UI/CustomText'
import ShowLTP from '../../UI/ShowLTP'
import StockIcon from '../../UI/StockIcon'
import Icon from '../../UI/Icon'
import Marquee from '../../UI/Marquee'
// import TooltipText from '../../UI/TooltipText'

import { DYNAMIC_SEGMENTS, getSegSymFromDC } from '../../containers/Create/Create-utils'
import { compressedDYCName, segSymConverter, symbolNameByOptions } from '../../utils/common'
import {
  ICONS, COLORS, theme, SPACING, FONTS, ASSETS,
} from '../../Theme'
import { showSnackbar } from '../../UI/Snackbar'

const SYM_ICON_SIZE = 20

const BacktestResultsCard = React.memo(({
  seg_sym = '',
  onPress,
  // cardHeight: height,
  // cardWidth,
  waiting,
  isSelected,
  // onLongPress,
  // longPressActive,
  showEditBtn,
  showDCParams,
  scrip,
  onSymbolSelect,
  isDeployed,
  final_return,
  error_msg,
  index,
  isDark,
  onDelete,
  isFocused,
  scrollType,
  showActionBar,
  isCondesedView,
  algoId,
  algoIdKey,
  cardType,
  showLtp,
  renderPnl,
  shareSelected,
  showSelection,
  isLoggedIn,
  mtfExist,
  showShareModal,
}) => {
  const styles = stylesheet()
  const containerRef = useRef(null)
  const { symbol = '', segment } = segSymConverter(seg_sym)
  const isShareStockCard = cardType === 'shareStockCard'

  // let backgroundColor = COLORS.WHITE
  let shadowColor = '#15278412'
  if (final_return !== undefined && typeof final_return === 'number') {
    if (+final_return > 0) {
      if (isFocused) {
        // backgroundColor = COLORS.GAIN_BG
        shadowColor = COLORS.GAIN_BG
      }
    } else if (final_return < 0) {
      if (isFocused) {
        // backgroundColor = COLORS.LOSS_BG
        shadowColor = COLORS.LOSS_BG
      }
    }
  }

  useEffect(() => {
    if(shareSelected) {
      const list = Array.from(shareSelected)
      if(list[0] === seg_sym) {
        onCardPress()
      }
    }
  }, [shareSelected])

  useEffect(() => {
    const mtfPnl = document.getElementById(`${seg_sym}_mtf_pnl`)
    if (containerRef.current) {
      if (isFocused && final_return !== undefined) {
        let type = final_return > 0 ? 'gain' : 'loss'
        type = error_msg ? '' : type
        if(mtfExist) {
          let gradientColor = type === 'gain' ? theme.gainBg : theme.lossBg
          if(type === '') {
            gradientColor = theme.bgPrimary
          }
          mtfPnl.style.background = `linear-gradient(to right, ${gradientColor}, ${gradientColor} 100%)`
        }
        containerRef.current.style.animationName = `${scrollType}_${type}`
      } else {
        containerRef.current.style.animationName = ''
        if(mtfExist && !showShareModal) {
          mtfPnl.style.background = theme.bgPrimary
        }
      }
    }
  }, [final_return, isFocused])
  // const backgroundColor = COLORS[isDark ? 'BLACK_100' : bgColor]
  const onCardPress = () => {
    onPress({
      isDeployed, seg_sym, isSelected, index,
    })
  }

  const toggleCheckbox = () => {
    if (isDeployed && cardType !== 'shareStockCard') {
      showSnackbar('Cannot select a deployed symbol')
      return
    }
    // onSymbolSelect({ seg_sym, isSelected })
    // if (isDeployed) return
    onSymbolSelect({ seg_sym, isSelected })
  }
  const onDeleteHandler = () => {
    if (isDeployed) return
    onDelete(seg_sym, { [algoIdKey]: algoId })
  }
  const selectIconStyles = {
    borderRadius: '50%',
    backgroundColor: COLORS.WHITE,
  }
  const stockIconStyles = noData => (isCondesedView ? ({
    borderRadius: SYM_ICON_SIZE / 2,
    marginRight: SPACING.SPACE_6,
    marginBottom: 0,
    maxHeight: 35,
    display: 'inline-flex',
    filter: noData ? 'grayscale(1)' : 'unset',
    verticalAlign: 'middle',
  }) : ({
    borderRadius: SYM_ICON_SIZE / 2,
    marginRight: 0,
    marginBottom: '4%',
    maxHeight: 35,
    minHeight: 14,
    display: 'flex',
    filter: noData ? 'grayscale(1)' : 'unset',
    maxWidth: isShareStockCard ? '25px' : '100%',
  }))
  const headerStyles = isCondesedView ? styles.headerRow : styles.headerColumn
  // eslint-disable-next-line max-len
  const containerClass = showActionBar || isShareStockCard ? classnames(styles.container, styles.containerActions)
    : styles.container
  let contentClass = isCondesedView ? classnames(styles.content, styles.condensedContent)
    : styles.content
  contentClass = classnames(contentClass, isSelected ? styles.selected : '')
  const actionBg = isDark ? `${COLORS.BLACK_80}90` : '#ffffffa0'
  const showEdit = showEditBtn
    && Boolean(scrip.details || DYNAMIC_SEGMENTS.includes(segment.toUpperCase()))
  let symbolName = compressedDYCName(symbol)
  const rawSymbolName = symbolNameByOptions(symbolName)
  // options sytles
  let showOptions
  if(symbolName !== rawSymbolName) {
    showOptions = rawSymbolName.charAt(rawSymbolName.length - 2)
    symbolName = rawSymbolName.substring(0, rawSymbolName.length - 4)
  }
  const optionsStyles = {
    backgroundColor: showOptions === 'W' ? COLORS.BLUE : COLORS.ORANGE,
    transform: 'translateY(1px)',
    right: '-18px',
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={containerClass}
        style={{
          boxShadow: isDark ? 'none' : `0px 7px 20px ${shadowColor}`,
        }}
      >
        {isSelected && (
          <button
            type="button"
            onClick={toggleCheckbox}
            className={styles.selectIconBtn}
          >
            <Icon
              name={ICONS.TICK_ROUND_FILLED}
              color={theme.linkColor}
              size={20}
              style={selectIconStyles}
            />
          </button>
        )}
        {showSelection && !isSelected && (
          <button
            type="button"
            onClick={toggleCheckbox}
            className={styles.selectIconBtn}
          >
            <Icon
              name={ICONS.CIRCLE_OUTLINED}
              color={theme.linkColor}
              size={20}
              style={selectIconStyles}
            />
          </button>

        )}
        {(showActionBar || isShareStockCard) && (
        <div className={styles.actionBar} style={{ backgroundColor: actionBg }}>
          <button
            type="button"
            onClick={toggleCheckbox}
            className={styles.selectBtn}
          >
            <CustomText center color="linkColor" className={styles.selectBtnText}>{isSelected ? 'Deselect' : 'Select'}</CustomText>
          </button>
            {showEdit && (
              <>
                <div className={styles.seperator} />
                <button
                  type="button"
                  onClick={() => showDCParams({ scrip, index })}
                  className={styles.iconBtn}
                >
                  <Icon name={ICONS.EDIT} size={12} color={theme.linkColor} />
                </button>
              </>
            )}
          {Boolean(onDelete) && (
          <>
            <div className={styles.seperator} />
            <button
              type="button"
              onClick={onDeleteHandler}
              className={styles.iconBtn}
            >
              <Icon name={ICONS.DELETE} size={12} color={COLORS.RED} />
            </button>
          </>
          )}
        </div>
        )}

        <button type="button" disabled={isFocused} onClick={onCardPress} className={isShareStockCard ? styles.shareContentBtn : styles.contentBtn}>
          <div
            className={classnames(contentClass, 'backtest-anim')}
            ref={containerRef}
          >
            <div className={classnames(styles.header, headerStyles)}>
              <StockIcon width="unset" height="65%" seg_sym={seg_sym} containerStyle={stockIconStyles(Boolean(error_msg))} />
              <Marquee
                changes={{ isCondesedView, index }}
                tooltipText={symbol}
                props={{ className: isCondesedView ? styles.condensedText : styles.textMarque }}
              >
                <CustomText weight="medium" size="small" className={styles.symbol}>
                  {isDeployed && (<Icon name={ICONS.DEPLOYED} SIZE={12} color={COLORS.VOILET} />)}
                  {symbolName}
                  {showOptions && showOptions !== ' ' && (
                  <img src={showOptions === 'W' ? ASSETS.Weekly : ASSETS.Monthly} style={optionsStyles} className="optionsText" alt="w" />
                  )}
                </CustomText>
              </Marquee>
            </div>
            <div className={styles.footer}>
              {!error_msg && (!isCondesedView || showLtp) && !isShareStockCard && isLoggedIn && (
              <ShowLTP
                containerStyles={styles.ltpContainer}
                ltpStyles={styles.ltp}
                changeStyles={styles.change}
                showBrackets
                hideCurrency
                disabled={!isFocused}
                showLtpIcon={isSelected}
                seg_sym={getSegSymFromDC(seg_sym).segSym}
              />
              )}
              {mtfExist && !showShareModal
                && (
                <div
                  className={styles.mtfPnl}
                  id={`${seg_sym}_mtf_pnl`}
                >
                  <span className={styles.valueBlur}>label</span>
                </div>
                )}
              <div
                className={styles.pnlView}
              >
                {renderPnl({
                  waiting, final_return, error_msg,
                })}
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
})

const stylesheet = makeStyles({
  wrapper: {
    padding: '14px 10px',
    width: '100%',
    height: '100%',
  },
  container: {
    position: 'relative',
    boxShadow: '0px 7px 20px #15278412',
    height: '100%',
    borderRadius: 6,
    backgroundColor: theme.bgPrimary,
    // '& *': {
    //   transition: 'height 0.3s var(--anim-func-ease)',
    // },
  },
  containerActions: {
    '&:hover': {
      '& $actionBar': {
        opacity: 1,
        height: '30px',
      },
    },
  },
  content: {
    textAlign: 'center',
    height: '100%',
    // padding: `${SPACING.SPACE_6} ${SPACING.SPACE_4}`,
    padding: `${SPACING.SPACE_10}`,
    border: '2px solid transparent',
    borderRadius: 6,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  contentBtn: {
    height: '100%',
    width: '100%',
  },
  shareContentBtn: {
    height: '100%',
    width: 100,
  },
  condensedContent: {
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_6}`,
    alignItems: 'flex-start',
    // '& $pnlView': {
    //   opacity: 0,
    //   position: 'absolute',
    //   display: 'none',
    // },
  },
  condensedText: {
    whiteSpace: 'nowrap',
    width: 'calc(100% - 30px) !important',
    display: 'inline-block',
    overflowX: 'hidden',
    verticalAlign: 'bottom',
  },
  textMarque: {
    overflowX: 'hidden',
  },
  selected: {
    borderColor: COLORS.BLUE,
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& > div > svg': {
      height: '100%',
      width: '100%',
    },
  },
  headerRow: {
    height: '45%',
    display: 'inline-block',
    textAlign: 'left',
    margin: `${SPACING.SPACE_2} 0`,
    '& > div': {
      // width: '20px !important',
    },
    '& $symbol': {
      justifyContent: 'flex-start',
    },
  },
  footer: {
    height: '40%',
  },
  pnlView: {
    transition: 'none',
    '& *': {
      transition: 'none',
    },
  },
  headerColumn: {
    height: '60%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingTop: '5%',
  },
  gain: {
    color: `${COLORS.GREEN} !important`,
  },
  loss: {
    color: `${COLORS.RED} !important`,
  },
  actionBar: {
    display: 'flex',
    borderRadius: 6,
    position: 'absolute',
    opacity: 0,
    width: '100%',
    top: '0',
    left: '0',
    height: 0,
    // give in carousal
    // transition: 'height 0.3s var(--anim-func-ease)',
  },
  selectBtn: {
    flex: 1,
    display: 'flex',
    padding: `${SPACING.SPACE_6} 0`,
    width: '60%',
  },
  selectIconBtn: {
    position: 'absolute',
    right: -10,
    top: -10,
    zIndex: 99,
  },
  selectBtnText: {
    width: '100%',
  },
  iconBtn: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // borderLeft: `1px solid ${theme.borderColor}`,
  },
  ltpContainer: {
    marginTop: '3%',
    justifyContent: 'center',
    position: 'relative',
  },
  ltp: {
    fontSize: `${FONTS.SMALL_0} !important`,
  },
  change: {
    fontSize: `${FONTS.TINY} !important`,
  },
  symbol: {
    // width: '100%',
    alignItems: 'flex-start',
    display: 'flex',
    // justifyContent: 'center',
    '& > svg': {
      verticalAlign: 'bottom',
    },
  },
  seperator: {
    width: 1, margin: 'auto', height: '70%', backgroundColor: theme.borderColor,
  },
  textTicker: {
    overflow: 'hidden',
  },
  mtfPnl: {
    position: 'absolute',
    zIndex: 9997,
    display: 'flex',
    justifyContent: 'center',
    width: '80%',
    gap: 10,
  },
  valueBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #3CBC00',
    userSelect: 'none',
  },
})

export default BacktestResultsCard
