import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'

import Icon from '../../UI/Icon'
import Button from '../../UI/Button'
import CustomText from '../../UI/CustomText'
// import ListDropdown from '../../UI/Dropdown/ListDropdown'
import ModalDropdown from '../../UI/Dropdown/ModalDropdown'
import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import EditScreenerParamsHead from './EditScreenerParamsHead'
import EntryExitRenderer from '../Backtests/EntryExitRenderer'
import Expandable from '../../UI/Expandable'
import Chip from '../../UI/Chip'
import CustomModal from '../../UI/CustomModal'
import SidebarLoader from '../../UI/Loaders/Backtests/SidebarLoader'
import CandleIntervalView from '../EditAlgoParams/CandleIntervalView'
import CustomTooltip from '../../UI/CustomTooltip'

import { pathname_mapping } from '../../utils/navigationConstant'
import { CONDITION_TYPE } from '../../containers/Create/Create-utils'
import {
  ICONS, COLORS, SPACING, theme,
} from '../../Theme'
import { CHART_ICON_MAP } from '../../utils/consts'
import {
  scanStocks,
  saveScreener,
  copySharedScreener,
} from '../../containers/Screener/actions'
import { getWatchList } from '../../containers/Marketwatch/actions'
import { useTheme } from '../../Theme/ThemeProvider'
import { showSnackbar } from '../../UI/Snackbar'
import { toggleLoginModal } from '../../containers/Auth/actions'
import { toggleAuthGreeting } from '../../containers/Common/actions'
import { PRO_FEATURE_MAP } from '../../utils/userAccessChecks'

const EXPAND_KEY_TYPE = {
  CHART: 'chart',
  SCAN_ON: 'scanOn',
  SCREENER: 'screener',
}

// const renderTooltip = (item, chart_type, className) => {
//   return (
//     <div className={className} id="tooltip">
//       <CustomText color="white">{/Renko/i.test(chart_type) ? chart_type : item}</CustomText>
//     </div>
//   )
// }

const renderExistingStocks = (stocksArr, styles) => {
  return stocksArr.map((stock) => {
    let seg_sym = stock
    if (typeof seg_sym === 'object') {
      const { segment, symbol } = stock
      seg_sym = `${segment}_${symbol}`
    } else {
      seg_sym = seg_sym.split('_').reverse().join('_')
    }
    return (
      <Chip
        key={seg_sym}
        label={seg_sym}
        disabled
        containerClass={styles.chipContainer}
      />
    )
  })
}

const EditScreenerParams = ({
  screenerState,
  isScanning,
  isGettingScreenerState,
  screenerStateError,
  isCopyingScreener,
  save,
  scan,
  sessionid,
  csrf,
  user_details,
  fetchWatchList,
  market_watches,
  blockedUniverseList,
  toggleAuthModal,
  isLoggedIn,
  copySharedScan,
  toggleAuthGreetingModal,
}) => {
  const styles = stylesheet()
  const history = useHistory()
  const { isDark, updateGA } = useTheme()
  const [showViewAllStocks, viewAllStocks] = useState(false)
  const [candleInterval, updateCandleInterval] = useState(false)
  const [expandKey, updateKey] = useState(EXPAND_KEY_TYPE.CHART)
  const toggleViewAll = () => {
    viewAllStocks(!showViewAllStocks)
  }
  // const updateAnalytics = (a, l) => {
  //   updateGA(a,l,1, 'Scanner parameters')
  // }
  const { subscription_valid } = user_details
  const {
    screener_state = {}, screener_uuid, live, screener_name, screener_logic = '',
    sample, shared, public: isPublic,
  } = screenerState
  if (isEmpty(screener_state) || screenerStateError) {
    return <SidebarLoader />
  }

  const tabsProps = {
    tabColor: isDark ? 'VOILET_600' : 'VOILET',
    activeTabColor: 'WHITE',
  }

  const disableActions = isScanning || isGettingScreenerState || isCopyingScreener
  // const dropdownItemRenderer = () => {}
  const onConditionEdit = () => {
    if (disableActions || live || sample) return
    updateGA('Edit scanner', 'Scanner condition', 1, 'Scanner parameters')
    history.push({
      pathname: pathname_mapping.NewScanner,
      search: `?passedId=${btoa(screener_uuid)}&type=${btoa(CONDITION_TYPE.SCREENER)}&edit=${btoa(true)}`,
    })
  }

  const candleIntervalFunc = (param) => {
    updateCandleInterval(param)
  }

  return (
    <div className={styles.editContainer}>
      <EditScreenerParamsHead
        scanStocks={scan}
        saveScreener={save}
        screenerState={screenerState}
        screener_state={screener_state}
        screener_logic={screener_logic}
        screener_uuid={screener_uuid}
        screener_name={screener_name}
        live={live}
        sessionid={sessionid}
        csrf={csrf}
        user_uuid={user_details.user_uuid}
        market_watches={market_watches}
        fetchWatchList={fetchWatchList}
        blockedUniverseList={blockedUniverseList}
        updateGA={updateGA}
      >
        {({
          allowed_scans,
          chartList,
          candleList,

          scan_on,
          chart_type,
          time_frame,
          scanOnBasket,
          // showScanBtn,
          handleChange,
          onEditParams,
        }) => {
          const editParamsHandler = () => {
            if (!subscription_valid) {
              // showSnackbar('Limit reached.')
              toggleAuthGreetingModal(PRO_FEATURE_MAP.DP_LIMIT.ID)
              return
            }
            if (!isLoggedIn) {
              toggleAuthModal(true, false, true)
              return
            }
            if (shared && !isPublic) {
              copySharedScan(true)
              return
            }
            if (shared) {
              copySharedScan(true)
              return
            }
            if (isScanning) {
              showSnackbar('Scan in progress')
              return
            }
            onEditParams()
          }
          const chartLabelRenderer = ({ item, active }) => {
            const text = /Renko/i.test(chart_type) && /Renko/i.test(item) ? chart_type : item
            return (
              <CustomTooltip
                show
                tooltipProps={{ text }}
              >
                <div className={styles.chartTab}>
                  <Icon
                    name={CHART_ICON_MAP[item]}
                    color={active ? COLORS.WHITE : (isDark ? COLORS.LIGHT_VOILET : COLORS.VOILET)}
                  />
                </div>
              </CustomTooltip>
            )
          }
          return (
            <>
              <CustomModal
                visible={showViewAllStocks}
                dismissKey="viewMore"
                onDismiss={toggleViewAll}
                position="bottom"
              >
                <div className={styles.viewMoreContainer}>
                  <CustomText weight="semi_bold" className={styles.viewMoreHeader}>Stocks Added</CustomText>
                  {Boolean(scanOnBasket) && renderExistingStocks(scanOnBasket, styles)}
                </div>
              </CustomModal>
              <Expandable
                title="Scan On"
                defaultExpanded={expandKey === EXPAND_KEY_TYPE.SCAN_ON}
                onExpandCallback={updateKey}
                expandKey={EXPAND_KEY_TYPE.SCAN_ON}
                containerStyles={styles.expandContainer}
              >
                <div className={styles.content} style={{ maxHeight: '270px' }}>
                  {/* <CustomText weight="medium" className={styles.inputLabel}>
                    Scan On
                    </CustomText> */}
                  <ModalDropdown
                    position="bottom"
                    options={allowed_scans}
                    onChangeOption={handleChange}
                    changeKey="scan_on"
                    selected={scan_on}
                    containerStyles={styles.dropDownContainer}
                    inputStyles={styles.dropDownInput}
                    // labelRender={allowedScanLabeRenderer}
                    itemBtnStyles={styles.scanOnOptionBtn}
                    modalStyles={styles.dropdownModal}
                  />
                  {scanOnBasket ? (
                    <>
                      <button
                        type="button"
                        className={styles.viewAllStocks}
                        onClick={toggleViewAll}
                      >
                        <CustomText weight="medium" size="small" color="linkColor">View all symbols</CustomText>
                      </button>
                      <div className={styles.basketWarning}>
                        <Icon
                          name={ICONS.WARN}
                          color={COLORS.ORANGE}
                          size={12}
                          style={{ marginRight: SPACING.SPACE_8 }}
                        />
                        <CustomText size="tiny" color="orange">
                          {'  IMPORTANT: NSE scrips outside Nifty 500 will not be scanned'}
                        </CustomText>
                      </div>
                    </>
                  ) : <div style={{ height: 10 }} />}
                </div>

              </Expandable>
              <Expandable
                title="Charts"
                defaultExpanded={expandKey === EXPAND_KEY_TYPE.CHART}
                onExpandCallback={updateKey}
                expandKey={EXPAND_KEY_TYPE.CHART}
                containerStyles={styles.expandContainer}
                extraData={candleInterval}
              >
                <div className={classnames(styles.content, styles.chartContent)}>
                  <div className={styles.field} style={{ flex: 0.3 }}>
                    <CustomText weight="medium" className={styles.inputLabel}>Chart Type</CustomText>
                    <CustomHorizontalTab
                      tabs={chartList}
                      defaultActiveTab={chart_type}
                      tabChangeHandler={handleChange}
                      style={classnames(styles.tabContainer, styles.chartTypeContainer)}
                      tabStyles={classnames(styles.tab, styles.chartTab)}
                      labelRenderer={chartLabelRenderer}
                      dragBtnStyles={styles.tabActive}
                      changeKey="chart_type"
                      {...tabsProps}
                    />
                  </div>
                  <div className={styles.field} style={{ flex: 1 }}>
                    <CustomText weight="medium" className={styles.inputLabel}>Candle Interval</CustomText>
                    <CandleIntervalView
                      tabs={candleList}
                      defaultActiveTab={time_frame}
                      tabChangeHandler={handleChange}
                      changeKey="time_frame"
                      // style={classnames(styles.tabContainer)}
                      tabStyles={styles.tab}
                      {...tabsProps}
                      dataFromCandleInterval={candleIntervalFunc}
                    />
                    {/* <CustomHorizontalTab
                      tabs={candleList}
                      defaultActiveTab={time_frame}
                      changeKey="time_frame"
                      tabChangeHandler={handleChange}
                      style={classnames(styles.tabContainer, styles.wrap)}
                      tabStyles={styles.tab}
                      dragBtnStyles={styles.tabActive}
                      {...tabsProps}
                    /> */}
                  </div>
                </div>
              </Expandable>

              <Expandable
                title="Conditions"
                defaultExpanded={expandKey === EXPAND_KEY_TYPE.SCREENER}
                onExpandCallback={updateKey}
                expandKey={EXPAND_KEY_TYPE.SCREENER}
                containerStyles={styles.expandContainer}
                strLength={screener_logic.length}
              >
                <div className={styles.content}>
                  <EntryExitRenderer
                    condition={screener_logic}
                    cardTitle="Conditions"
                    disabled={disableActions || shared || isPublic}
                    onCardPress={onConditionEdit}
                    cardStyles={styles.entryCard}
                    textStyles={styles.entryExitString}
                    key="conditions"
                    isDark={isDark}
                  />
                </div>
              </Expandable>
              {!live && (
                <div className={styles.actionBar}>
                  <Button
                    text={live ? 'Scan Now' : 'Save and Scan'}
                    buttonColor="blue"
                    btnStyles={styles.scanBtn}
                    onPress={editParamsHandler}
                    loading={isScanning}
                    disabled={isGettingScreenerState || isCopyingScreener}
                  />
                </div>
              )}
            </>
          )
        }}
      </EditScreenerParamsHead>

    </div>
  )
}

const stylesheet = makeStyles({
  editContainer: {
    height: 'calc(100vh - 96px)',
    overflow: 'auto',
    position: 'relative',
  },
  inputLabel: {
    margin: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_10}`,
    '&:first-child': {
      marginTop: SPACING.SPACE_10,
    },
  },
  scanOnContainer: {},
  scanOnContent: {},
  basketWarning: {
    display: 'flex',
    marginTop: SPACING.SPACE_6,
  },
  chartContainer: {},
  content: {
    background: theme.editContentBg,
    borderRadius: '10px',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    margin: `0 ${SPACING.SPACE_20}`,
    // do not remove position it is for timePickerContainer
    position: 'relative',
  },
  field: {},
  entryExitWrapper: {},
  scanBtn: {},
  actionBar: {
    padding: `${SPACING.SPACE_20}`,
    position: 'sticky',
    backgroundColor: theme.bgPrimary,
    width: '100%', // `calc(100% - ${SPACING.SPACE_20})`,
    bottom: 0,
    zIndex: 99,
    '& > button': {
      width: '100%',
      padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    },
  },
  entryCard: {
    boxShadow: 'none !important',
    borderRadius: 0,
    backgroundColor: 'transparent !important',
    padding: 0,
    marginTop: SPACING.SPACE_10,
  },
  tabContainer: {
    backgroundColor: theme.chartInputBg,
    padding: '0 ',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  tabActive: {
    backgroundColor: COLORS.VOILET,
  },
  chartTypeContainer: {
    maxWidth: 120,
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chartTooltip: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    backgroundColor: '#000000c4',
    borderRadius: 6,
    overflow: 'hidden',
    // border: '1px solid #9BABC7',
  },
  chartTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
  },
  scanOnOptionBtn: {},
  viewMoreContainer: {
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
  },
  viewMoreHeader: {
    paddingBottom: `${SPACING.SPACE_10}`,
    marginBottom: `${SPACING.SPACE_10}`,
    borderBottom: `1px solid ${theme.borderColor}`,
    textAlign: 'center',
  },
  chipContainer: {
    backgroundColor: theme.tabsBg,
    marginTop: SPACING.SPACE_10,
    border: 0,
    '& > button': {
      cursor: 'default',
    },
  },
  viewAllStocks: {
    // backgroundColor: COLORS.BLUE_100,
    padding: `${SPACING.SPACE_2} ${SPACING.SPACE_4}`,
    borderRadius: '6px',
  },
  dropDownContainer: {
    '& > button': {
      width: '100%',
    },
  },
  dropDownInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
    borderRadius: 6,
    backgroundColor: `${theme.editContentInputBg} !important`,
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    border: `1px solid ${theme.borderColor}`,
    width: '100%',
  },
  expandContainer: {
    boxShadow: 'none !important',
  },
})

const mapDispatchToProps = (dispatch) => {
  return {
    scan: (params, headers, refetchState) => dispatch(scanStocks(params, headers, refetchState)),
    save: (params, headers) => dispatch(saveScreener(params, headers)),
    fetchWatchList: (params, headers) => dispatch(getWatchList(params, headers)),
    toggleAuthModal: (state, postLoginParams, loginShared) => dispatch(
      toggleLoginModal(state, postLoginParams, loginShared),
    ),
    toggleAuthGreetingModal: (modalType, routeAfterActivation) => dispatch(
      toggleAuthGreeting(modalType, routeAfterActivation),
    ),
    copySharedScan: params => dispatch(copySharedScreener(params)),
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.auth.loginResp,
    user_details: state.auth.user_details,
    blockedUniverseList: state.auth.blockedUniverseList,
    isLoggedIn: state.auth.isLoggedIn,
    isScanning: state.screener.isScanning,
    scanError: state.screener.scanError,
    screenerState: state.screener.screenerState,
    isGettingScreenerState: state.screener.isGettingScreenerState,
    screenerStateError: state.screener.screenerStateError,
    isCopyingScreener: state.screener.isCopyingScreener,

    market_watches: state.market.market_watches,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditScreenerParams)
