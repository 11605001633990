/* eslint-disable react/no-unescaped-entities */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import CustomInput from '../../UI/CustomInput'
import CustomText from '../../UI/CustomText'
import Button from '../../UI/Button'

import { withTheme } from '../../Theme/ThemeProvider'
import { showSnackbar } from '../../UI/Snackbar'
import { SPACING } from '../../Theme'

import { resetPassword } from '../../containers/Auth/actions'
import { pathname_mapping } from '../../utils/navigationConstant'

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      passwordErr: false,
      confirmPassword: '',
      confirmPasswordErr: false,
    }
  }

  componentDidMount() {
    const { authToken, routeTo } = this.props
    if (!authToken) {
      showSnackbar('Your request is malfunctioned', {}, 0)
      routeTo(pathname_mapping.Auth)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      resetError, resetResp, resetErrorMsg, isResetting, routeTo,
    } = this.props
    if (isResetting !== prevProps.isResetting) {
      if (resetResp && resetResp.status === 'success') {
        showSnackbar('Password reset successfull. Login with new password', {}, 1)
        routeTo(pathname_mapping.Auth)
      } else if (resetError) {
        showSnackbar(resetErrorMsg, {}, 0)
      }
    }
  }

  onChangeText = (e, field) => {
    const { value } = e.target
    this.setState({ [field]: value, [`${field}Err`]: false })
  }

  restPwdHandler = () => {
    const { password, confirmPassword } = this.state
    const {
      isResetting, onResetPassword, authToken,
    } = this.props

    if (isResetting) return

    if (password.length < 8) {
      showSnackbar('Password must be at least 8 characters long')
      this.setState({
        passwordErr: true,
      })
    } else if (password !== confirmPassword) {
      showSnackbar('Password fields do not match')
      this.setState({
        confirmPasswordErr: true,
      })
    } else {
      const params = {
        password,
        confirmed_password: confirmPassword,
        auth: authToken,
      }
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      onResetPassword(params, headers)
    }
  }

  render() {
    const {
      isResetting, styles, containerStyles,
    } = this.props
    const {
      password, passwordErr, confirmPassword, confirmPasswordErr,
    } = this.state
    return (
      <div className={`${styles.container} ${containerStyles}`}>
        <CustomText size="medium_1" weight="medium" center>Change Password</CustomText>
        <CustomInput
        // label="Password"
          inputError={passwordErr}
          onChangeText={this.onChangeText}
          inputKey="password"
          value={password}
          placeholder="Enter your new password"
          inputStyles={styles.fieldInput}
          containerStyles={styles.passwordInputContainer}
          wrapperStyles={styles.inputWrapper}
          inputProps={{
            type: 'password',
          }}
          disabled={isResetting}
        />
        <CustomInput
          inputError={confirmPasswordErr}
          onChangeText={this.onChangeText}
          inputKey="confirmPassword"
          value={confirmPassword}
          placeholder="Confirm your new password"
          inputStyles={styles.fieldInput}
          containerStyles={styles.passwordInputContainer}
          wrapperStyles={styles.inputWrapper}
          inputProps={{
            type: 'password',
          }}
          disabled={isResetting}
        />
        <Button
          text="Reset Password"
          buttonColor="blue"
          btnStyles={styles.resetBtn}
          disabled={isResetting}
          loading={isResetting}
          onPress={this.restPwdHandler}
        />
      </div>
    )
  }
}

const stylesheet = {
  container: {},
  fieldInput: {},
  passwordInputContainer: {
    marginTop: SPACING.SPACE_20,
  },
  inputWrapper: {},
  resetBtn: {
    margin: `${SPACING.SPACE_20} 0`,
    width: '100%',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
}

const mapStateToProps = (state) => {
  return {
    isResetting: state.auth.isResetting,
    resetResp: state.auth.resetResp,
    resetError: state.auth.resetError,
    resetErrorMsg: state.auth.resetErrorMsg,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: (params, headers) => dispatch(resetPassword(params, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(ResetPassword))
