/* eslint-disable react/no-unescaped-entities */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import CustomInput from '../../UI/CustomInput'
import CustomText from '../../UI/CustomText'
import Button from '../../UI/Button'

import { withTheme } from '../../Theme/ThemeProvider'
import { showSnackbar } from '../../UI/Snackbar'
import { ICONS, SPACING, theme } from '../../Theme'

import { saveLead } from '../../containers/Auth/actions'
import { pathname_mapping } from '../../utils/navigationConstant'
import Icon from '../../UI/Icon'
import { emailRegex, phoneNumberRegex } from '../../utils/consts'

class SignUp extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      passwordErr: false,
      confirmPassword: '',
      confirmPasswordErr: false,
    }
  }

  componentDidUpdate(prevProps) {
    const {
      saveLeadErr, saveLeadResp, saveLeadErrMsg, isSavingLead, routeTo,
    } = this.props
    if (isSavingLead !== prevProps.isSavingLead) {
      if (saveLeadResp && saveLeadResp.status === 'success') {
        showSnackbar(saveLeadResp.msg, {}, 1)
        routeTo(pathname_mapping.Auth, 'LOGIN')
      } else if (saveLeadErr) {
        showSnackbar(saveLeadErrMsg, {}, 0)
      }
    }
  }

  onChangeText = (e, field) => {
    const { value } = e.target
    this.setState({ [field]: value, [`${field}Err`]: false })
  }

  signUpHandler = () => {
    const { name, phone, email } = this.state
    const { signup, updateGA } = this.props
    if (!name || !phone || !email) {
      this.setState({
        nameErr: !name,
        phoneErr: !phone,
        emailErr: !email,
      })
      showSnackbar('Please fill all the fields')
      return
    }
    if (!phone.match(phoneNumberRegex)) {
      this.setState({ phoneErr: true })
      showSnackbar('Please enter a valid phone number')
      return
    }
    if (!email.match(emailRegex)) {
      this.setState({ emailErr: true })
      showSnackbar('Please enter a valid email id')
      return
    }
    const params = {
      full_name: name,
      phone_number: phone,
      email,
    }
    updateGA('DematSignUp', '', '', 'Home')
    signup(params)
  }

  render() {
    const {
      isSavingLead, styles, containerStyles, onBackPress, showBackBtn,
    } = this.props
    const {
      name, nameErr, email, emailErr, phone, phoneErr,
    } = this.state
    return (
      <div className={`${styles.container} ${containerStyles}`}>
        <div className={styles.header}>
          {showBackBtn && (
            <button
              type="button"
              onClick={() => onBackPress(pathname_mapping.Auth)}
              className={styles.backIconBtn}
            >
              <Icon name={ICONS.BACK} size={10} color={theme.text} />
            </button>
          )}
          <CustomText size="medium_1" weight="medium" center={!showBackBtn}>Sign up</CustomText>
        </div>
        <CustomText size="small" color="textSecondary" center={!showBackBtn}>Enter your details below and we will get in touch with you.</CustomText>
        <CustomInput
          inputError={nameErr}
          onChangeText={this.onChangeText}
          inputKey="name"
          value={name}
          placeholder="Enter your full name"
          inputStyles={styles.fieldInput}
          containerStyles={styles.passwordInputContainer}
          wrapperStyles={styles.inputWrapper}
        />
        <CustomInput
          inputError={phoneErr}
          onChangeText={this.onChangeText}
          inputKey="phone"
          value={phone}
          placeholder="Enter your phone number"
          inputStyles={styles.fieldInput}
          containerStyles={styles.passwordInputContainer}
          wrapperStyles={styles.inputWrapper}
        />
        <CustomInput
          inputError={emailErr}
          onChangeText={this.onChangeText}
          inputKey="email"
          value={email}
          placeholder="Enter your email id"
          inputStyles={styles.fieldInput}
          containerStyles={styles.passwordInputContainer}
          wrapperStyles={styles.inputWrapper}
        />
        <Button
          text="Sign up"
          buttonColor="blue"
          btnStyles={styles.signupBtn}
          disabled={isSavingLead}
          loading={isSavingLead}
          onPress={this.signUpHandler}
        />
      </div>
    )
  }
}

const stylesheet = {
  container: {},
  fieldInput: {},
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_10,
  },
  passwordInputContainer: {
    marginTop: SPACING.SPACE_20,
  },
  inputWrapper: {},
  signupBtn: {
    margin: `${SPACING.SPACE_20} 0`,
    width: '100%',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
  backIconBtn: {
    marginRight: SPACING.SPACE_10,
  },
}

const mapStateToProps = (state) => {
  return {
    isSavingLead: state.auth.isSavingLead,
    saveLeadResp: state.auth.saveLeadResp,
    saveLeadErr: state.auth.saveLeadErr,
    saveLeadErrMsg: state.auth.saveLeadErrMsg,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (params, headers) => dispatch(saveLead(params, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(SignUp))
