import {
  FETCH_MARKET_ALGOS_INIT,
  FETCH_MARKET_ALGOS_SUCCESS,
  FETCH_MARKET_ALGOS_FAILURE,
  CLEAR_BACKTEST_GRAPH_DATA,
  DEPLOY_PUBLISHED,
  DEPLOY_PUBLISHED_SUCCESS,
  DEPLOY_PUBLISHED_FAILURE,
  CLEAR_MARKET_ALGO_DATA,
  UPDATE_PUBLICATION_STATS,
  UPDATE_PUBLICATION_STATS_SUCCESS,
  UPDATE_PUBLICATION_STATS_FAILURE,
  SUBSCRIBE_ALGO_INIT,
  SUBSCRIBE_ALGO_SUCCESS,
  SUBSCRIBE_ALGO_FAILURE,
  FETCH_SUBSCRIBED_CHARTS_INIT,
  FETCH_SUBSCRIBED_CHARTS_SUCCESS,
  FETCH_SUBSCRIBED_CHARTS_FAILURE,
  FETCH_MARKETPLACE_CHARTS_INIT,
  FETCH_MARKETPLACE_CHARTS_SUCCESS,
  FETCH_MARKETPLACE_CHARTS_FAILURE,
  FETCH_SUBSCRIBED_ALGO_INIT,
  FETCH_SUBSCRIBED_ALGO_SUCCESS,
  FETCH_SUBSCRIBED_ALGO_FAILURE,
  SUBSCRIBE_AND_DEPLOY_INIT,
  MARKET_DEPLOY_STOCKS_INIT,
  MARKET_DEPLOY_STOCKS_SUCCESS,
  MARKET_DEPLOY_STOCKS_FAILURE,
  UPDATE_MARKET_SORT_ON,
  CLEAR_SUBSCRIBED_ALGO_DATA,
  FETCH_DISCOVER_CATEGORY,
  FETCH_DISCOVER_CATEGORY_SUCCESS,
  FETCH_DISCOVER_CATEGORY_FAILURE,
  FETCH_ONBOARDING_ALGOS_INIT,
  PAPER_TRADE_MARKET,
  PAPER_TRADE_MARKET_SUCCESS,
  PAPER_TRADE_MARKET_FAILURE,
} from './actionTypes'
import { ALGO_KEY_MAP } from '../../components/AlgoCondensedView/AlgoUtils'

const initialState = {
  marketAlgos: [],
  isFetchingMarketAlgos: false,
  marketAlgosError: false,
  viewer_dict: {},
  marketTags: [],
  tagsDescription: [],
  isFetchingDiscoverCategory: true,
  tagsDescriptionError: false,
  tagsDescriptionErrorMsg: '',
  total_pages_market: 0,

  detailedIsFetchingMarketAlgos: false,
  detailedMarketAlgosError: false,
  detailedMarketPlaceErrorMsg: '',
  detailedMarketAlgos: [],
  detailedTotal_pages_market: 0,
  detailedMarketAlgosFetchResp: [],
  detailedTopPerformerResp: [],

  // market charts
  backtestGraphData: {},
  isFetchingGraphData: false,
  backtestGraphDataError: false,
  backtestGraphDataErrorMsg: '',

  isDeployingPublished: false,
  deployPublishedError: false,
  deployPublishedErrorMsg: '',
  deployPublishedSuccess: {},
  // stats
  publicationStatsResp: {},
  isUpdatingPublicationStats: false,
  publicationStatsError: false,
  publicationStatsErrorMsg: '',
  //  subscribe to Algo
  isSubscribingAlgo: false,
  subscribeAlgoResp: {},
  subscribeAlgoError: false,
  subscribeAlgoErrorMsg: '',
  subscribeAlgoErrorResp: null,
  //  fetch Subscribed charts
  isFetchingSubscribedCharts: false,
  subscribedChartsResp: {},
  subscribedChartsError: false,
  subscribedChartsErrorMsg: '',
  //  Get Subscribed Algo
  isFetchingSubscribedAlgo: false,
  subscribedAlgoResp: {},
  subscribedAlgos: [],
  subscribed_total_pages: 0,
  subscribedAlgoError: false,
  subscribedAlgoErrorMsg: '',

  detailedIsFetchingSubscribedAlgo: false,
  detailedSubscribedAlgoResp: {},
  detailedSubscribedAlgos: [],
  detailedSubscribed_total_pages: 0,
  detailedSubscribedAlgoError: false,
  detailedSubscribedAlgoErrorMsg: '',
  detailedSubscribedFetchResp: [],

  obIsFetchingAlgo: false,
  onboardingAlgos: [],
  obAlgoError: false,
  obAlgoErrorMsg: '',

  // isDeploying
  isDeployingStocks: false,
  deployResponse: {},
  deploymentError: null,
  deploymentErrorMsg: '',

  isPaperTradingMarket: false,
  paperTrademarketResp: {},
  paperTrademarketErr: false,
  paperTrademarketErrMsg: '',

  sortOn: {},

  subscribed_algos: [],
}

const discover = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MARKET_ALGOS_INIT: {
      const { detailed } = action
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingMarketAlgos: true,
          detailedMarketAlgosError: false,
          detailedMarketPlaceErrorMsg: '',
        }
      }
      return {
        ...state,
        isFetchingMarketAlgos: true,
        marketAlgosError: false,
      }
    }

    case FETCH_ONBOARDING_ALGOS_INIT: {
      return {
        ...state,
        obIsFetchingAlgo: true,
        onboardingAlgos: [],
      }
    }
    case FETCH_MARKET_ALGOS_SUCCESS: {
      const {
        data, kind, detailed, params,
      } = action
      const newAlgoIds = []
      const algoIdKey = ALGO_KEY_MAP.DISCOVER
      const algo = data.algo.map((item) => {
        newAlgoIds.push(item[algoIdKey])
        return { ...item, backtest: item.backtest ? item.backtest.splice(0, 1) : [] }
      })
      let marketAlgos = []
      if (kind === 'append') {
        let oldAlgos = [...state.marketAlgos]
        if (detailed) {
          oldAlgos = [...state.detailedMarketAlgos]
        }
        const { page_limit, page = 1 } = params
        // find the algos which has to be reomved as the algos are now sorted
        // so last fetch algos will not be at the bottom of the array
        // to avoid duplicate we do this
        if (page_limit !== data.algo.length && state.sortOn.sortKey && detailed) {
          oldAlgos = oldAlgos.filter(algoObj => !newAlgoIds.includes(algoObj[algoIdKey]))
        } else {
          oldAlgos = oldAlgos.splice(0, page_limit * (page - 1))
        }
        marketAlgos = [...oldAlgos, ...algo]
      } else {
        marketAlgos = [...algo]
      }
      if (state.sortOn.sortKey && detailed) {
        const { sortKey, asc } = state.sortOn
        marketAlgos.sort((a, b) => {
          if (asc) {
            return (a[sortKey] - b[sortKey])
          }
          return (b[sortKey] - a[sortKey])
        })
      }
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingMarketAlgos: false,
          detailedMarketAlgos: marketAlgos,
          detailedTotal_pages_market: data.pages,
          marketTags: data.all_tags,
          detailedMarketAlgosFetchResp: algo,
          detailedTopPerformerResp: marketAlgos.slice(0, 6),
        }
      }
      if (kind === 'onboarding_fetch') {
        return {
          ...state,
          obIsFetchingAlgo: false,
          onboardingAlgos: data.algo,
        }
      }
      return {
        ...state,
        isFetchingMarketAlgos: false,
        marketAlgos,
        subscribed_algos: data.subscribed_algos || [],
        viewer_dict: data.viewer_dict,
        total_pages_market: data.pages,
        marketTags: data.all_tags,
      }
    }

    case FETCH_MARKET_ALGOS_FAILURE: {
      const { detailed, error, kind } = action
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingMarketAlgos: false,
          detailedMarketAlgosError: true,
          detailedMarketPlaceErrorMsg: error,
        }
      }
      if (kind === 'onboarding_fetch') {
        return {
          ...state,
          obIsFetchingAlgo: false,
          obAlgoError: true,
          obAlgoErrorMsg: error,
        }
      }
      return {
        ...state,
        isFetchingMarketAlgos: false,
        marketAlgosError: true,
      }
    }

    case FETCH_MARKETPLACE_CHARTS_INIT: {
      const { params } = action
      let newData = {}
      params.forEach((idObj) => {
        newData = {
          ...newData,
          [idObj[ALGO_KEY_MAP.DISCOVER]]: { waiting: true, error: false },
        }
      })
      const backtestGraphData = {
        ...state.backtestGraphData,
        ...newData,
      }
      return {
        ...state,
        backtestGraphData,
        isFetchingGraphData: true,
      }
    }

    case FETCH_MARKETPLACE_CHARTS_SUCCESS: {
      const { data, id } = action
      // let backtests = []
      // if (data) {
      //   backtests = data.backtests // data.backtest_results
      // }
      // let graphData = {}
      // backtests.forEach((item) => {
      //   const { seg_sym, backtest_result } = item
      //   graphData = {
      //     ...graphData,
      //     [seg_sym]: backtest_result[seg_sym]
      //   }
      // })
      const { absolute_pnl_pct = 0.0, absolute_pnl = 0.0 } = data
      const modData = {
        ...state.backtestGraphData,
        [id]: {
          waiting: false, data: data.backtests || [], absolute_pnl, absolute_pnl_pct,
        },
      }
      return {
        ...state,
        isFetchingGraphData: false,
        backtestGraphData: modData,
      }
    }

    case FETCH_MARKETPLACE_CHARTS_FAILURE: {
      const { id } = action
      const backtestGraphData = {
        ...state.backtestGraphData,
        [id]: { waiting: false, error: true },
      }
      return {
        ...state,
        backtestGraphData,
        isFetchingGraphData: false,
        backtestGraphDataError: true,
        backtestGraphDataErrorMsg: 'Error fetching Graph',
      }
    }

    case CLEAR_BACKTEST_GRAPH_DATA:
      return {
        ...state,
        backtestGraphData: {},
      }

    case DEPLOY_PUBLISHED:
      return {
        ...state,
        isDeployingPublished: true,
        deployPublishedError: false,
        deployPublishedErrorMsg: '',
        deployPublishedSuccess: {},
      }

    case DEPLOY_PUBLISHED_SUCCESS:
      return {
        ...state,
        isDeployingPublished: false,
        deployPublishedSuccess: action.data,
      }

    case DEPLOY_PUBLISHED_FAILURE:
      return {
        ...state,
        isDeployingPublished: false,
        deployPublishedError: true,
        deployPublishedErrorMsg: action.error,
      }

    case CLEAR_MARKET_ALGO_DATA: {
      const { detailed } = action
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingMarketAlgos: false,
          detailedMarketAlgosError: false,
          detailedMarketPlaceErrorMsg: '',
          detailedMarketAlgos: [],
          detailedTotal_pages_market: 0,
          detailedMarketAlgosFetchResp: [],
          sortOn: {},
        }
      }
      return {
        ...state,
        marketAlgos: [],
        isFetchingMarketAlgos: false,
        marketAlgosError: false,
        total_pages_market: 0,
      }
    }

    case UPDATE_PUBLICATION_STATS:
      return {
        ...state,
        publicationStatsResp: {},
        isUpdatingPublicationStats: true,
        publicationStatsError: false,
        publicationStatsErrorMsg: '',
      }

    case UPDATE_PUBLICATION_STATS_SUCCESS: {
      return {
        ...state,
        publicationStatsResp: action.data,
        isUpdatingPublicationStats: false,
      }
    }

    case UPDATE_PUBLICATION_STATS_FAILURE:
      return {
        ...state,
        isUpdatingPublicationStats: false,
        publicationStatsError: true,
        publicationStatsErrorMsg: action.error,
      }

    case SUBSCRIBE_ALGO_INIT: {
      const { publishing_uuid = '' } = action.params
      return {
        ...state,
        isSubscribingAlgo: true,
        subscribingAlgoUUID: publishing_uuid,
        subscribeAlgoResp: {},
        subscribeAlgoError: false,
        subscribeAlgoErrorMsg: '',
        subscribeAlgoErrorResp: null,
      }
    }

    case SUBSCRIBE_ALGO_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isSubscribingAlgo: false,
        subscribingAlgoUUID: '',
        subscribeAlgoResp: data,
      }
    }

    case SUBSCRIBE_ALGO_FAILURE: {
      return {
        ...state,
        isSubscribingAlgo: false,
        subscribingAlgoUUID: '',
        subscribeAlgoError: true,
        subscribeAlgoErrorMsg: action.error.error_msg,
        subscribeAlgoErrorResp: action.error,
      }
    }

    case FETCH_SUBSCRIBED_CHARTS_INIT: {
      const { params } = action
      let newData = {}
      params.forEach((idObj) => {
        newData = {
          ...newData,
          [idObj[ALGO_KEY_MAP.SUBSCRIBED]]: { waiting: true, error: false },
        }
      })
      const backtestGraphData = {
        ...state.subscribedChartsResp,
        ...newData,
      }
      return {
        ...state,
        isFetchingSubscribedCharts: true,
        subscribedChartsResp: backtestGraphData,
      }
    }

    case FETCH_SUBSCRIBED_CHARTS_SUCCESS: {
      const { data, id } = action
      // let backtests = []
      // if (data) {
      //   backtests = data.backtests // data.backtest_results
      // }
      // let graphData = {}
      // backtests.forEach((item) => {
      //   const { seg_sym, backtest_result } = item
      //   graphData = {
      //     ...graphData,
      //     [seg_sym]: backtest_result[seg_sym]
      //   }
      // })
      const { absolute_pnl_pct = 0.0, absolute_pnl = 0.0 } = data
      const modData = {
        ...state.subscribedChartsResp,
        [id]: {
          waiting: false, data: data.backtests || [], absolute_pnl, absolute_pnl_pct,
        },
      }
      return {
        ...state,
        isFetchingSubscribedCharts: false,
        subscribedChartsResp: modData,
      }
    }

    case FETCH_SUBSCRIBED_CHARTS_FAILURE: {
      const { id } = action
      const backtestGraphData = {
        ...state.subscribedChartsResp,
        [id]: { waiting: false, error: true },
      }
      return {
        ...state,
        isFetchingSubscribedCharts: false,
        subscribedChartsResp: backtestGraphData,
        subscribedChartsError: true,
        subscribedChartsErrorMsg: 'Error fetching Graph',
      }
    }

    case FETCH_SUBSCRIBED_ALGO_INIT: {
      const { params = {}, detailed } = action
      let moreState = {}
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingSubscribedAlgo: false,
          detailedSubscribedAlgoError: false,
          detailedSubscribedAlgoErrorMsg: '',
        }
      }
      if(params.kind === 'new') {
        moreState = {
          initialFetchSubscribedAlgo: true,
          subscribedAlgoResp: {},
          subscribedAlgos: [],
          subscribed_total_pages: 0,
        }
      }
      return {
        ...state,
        isFetchingSubscribedAlgo: true,
        subscribedAlgoError: false,
        subscribedAlgoErrorMsg: '',
        ...moreState,
      }
    }

    case FETCH_SUBSCRIBED_ALGO_SUCCESS: {
      const {
        data, kind, detailed, params,
      } = action
      const { algo = [] } = data
      let algos = []

      if (kind === 'append') {
        let oldAlgos = [...state.subscribedAlgos]
        if (detailed) {
          oldAlgos = [...state.detailedSubscribedAlgos]
        }
        const { page_limit, page = 1 } = params
        // find the algos which has to be reomved as the algos are now sorted
        // so last fetch algos will not be at the bottom of the array
        // to avoid duplicate we do this
        if (page_limit !== data.algo.length && state.sortOn.sortKey && detailed) {
          const algoIdKey = ALGO_KEY_MAP.SUBSCRIBED
          const newAlgoIds = data.algo.map(item => item[algoIdKey])
          oldAlgos = oldAlgos.filter(algoObj => !newAlgoIds.includes(algoObj[algoIdKey]))
        } else {
          oldAlgos = oldAlgos.splice(0, page_limit * (page - 1))
        }
        algos = [...oldAlgos, ...algo]
      } else {
        algos = [...algo]
      }
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingSubscribedAlgo: false,
          detailedSubscribedAlgos: algos,
          detailedSubscribed_total_pages: data.pages,
          detailedSubscribedFetchResp: algo,
        }
      }
      return {
        ...state,
        isFetchingSubscribedAlgo: false,
        initialFetchSubscribedAlgo: false,
        subscribedAlgoResp: data,
        subscribedAlgos: algos,
        subscribed_total_pages: data.pages,
      }
    }

    case FETCH_SUBSCRIBED_ALGO_FAILURE: {
      const { detailed, error } = action
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingSubscribedAlgo: false,
          detailedSubscribedAlgoError: true,
          detailedSubscribedAlgoErrorMsg: error,
        }
      }
      return {
        ...state,
        isFetchingSubscribedAlgo: false,
        initialFetchSubscribedAlgo: false,
        subscribedAlgoError: true,
        subscribedAlgoErrorMsg: error,
      }
    }

    case SUBSCRIBE_AND_DEPLOY_INIT: {
      const { params = {} } = action
      const { publishing_uuid = '' } = params
      return {
        ...state,
        isSubscribingAlgo: true,
        subscribingAlgoUUID: publishing_uuid,
        subscribeAlgoResp: {},
        subscribeAlgoError: false,
        subscribeAlgoErrorMsg: '',
        subscribeAlgoErrorResp: null,
        isDeployingStocks: true,
        deploymentError: false,
        deployResponse: {},
        deploymentErrorMsg: '',
      }
    }
    case MARKET_DEPLOY_STOCKS_INIT: {
      return {
        ...state,
        isDeployingStocks: true,
        deploymentError: false,
        deployResponse: {},
        deploymentErrorMsg: '',
      }
    }

    case MARKET_DEPLOY_STOCKS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isDeployingStocks: false,
        deployResponse: data,
      }
    }

    case MARKET_DEPLOY_STOCKS_FAILURE: {
      const { error } = action
      return {
        ...state,
        isDeployingStocks: false,
        deploymentError: true,
        deploymentErrorMsg: error || 'Unable to deploy',
      }
    }

    case UPDATE_MARKET_SORT_ON: {
      const { sortOn = {}, algoIdKey } = action
      const { asc, sortKey } = sortOn
      if (sortKey) {
        const subscribed = algoIdKey === ALGO_KEY_MAP.SUBSCRIBED
        const modAlgos = subscribed ? [...state.detailedSubscribedAlgos]
          : [...state.detailedMarketAlgos]
        modAlgos.sort((a, b) => {
          if (asc) {
            if (sortKey === 'today_ret' || sortKey === 'today_pnl') {
              return (a.paper_dep_res[sortKey] - b.paper_dep_res[sortKey])
            }
            return (a[sortKey] - b[sortKey])
          }
          if (sortKey === 'today_ret' || sortKey === 'today_pnl') {
            return (b.paper_dep_res[sortKey] - a.paper_dep_res[sortKey])
          }
          return (b[sortKey] - a[sortKey])
        })
        if (subscribed) {
          return {
            ...state,
            sortOn: action.sortOn,
            detailedSubscribedAlgos: modAlgos,
          }
        }
        return {
          ...state,
          sortOn: action.sortOn,
          detailedMarketAlgos: modAlgos,
        }
      }
      return {
        ...state,
        sortOn: action.sortOn,
      }
    }

    case CLEAR_SUBSCRIBED_ALGO_DATA: {
      const { detailed } = action
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingSubscribedAlgo: false,
          detailedSubscribedAlgoResp: {},
          detailedSubscribedAlgos: [],
          detailedSubscribed_total_pages: 0,
          detailedSubscribedAlgoError: false,
          detailedSubscribedAlgoErrorMsg: '',
          detailedSubscribedFetchResp: [],
          sortOn: {},
        }
      }
      return {
        ...state,
        isFetchingSubscribedAlgo: false,
        subscribedAlgoResp: {},
        subscribedAlgos: [],
        subscribed_total_pages: 0,
        subscribedAlgoError: false,
        subscribedAlgoErrorMsg: '',
      }
    }

    case FETCH_DISCOVER_CATEGORY: {
      return {
        ...state,
        isFetchingDiscoverCategory: true,
        tagsDescriptionError: false,
        tagsDescriptionErrorMsg: '',
      }
    }

    case FETCH_DISCOVER_CATEGORY_SUCCESS: {
      return {
        ...state,
        isFetchingDiscoverCategory: false,
        tagsDescription: action.data.tags,
      }
    }
    case FETCH_DISCOVER_CATEGORY_FAILURE: {
      return {
        ...state,
        isFetchingDiscoverCategory: false,
        tagsDescriptionError: true,
        tagsDescriptionErrorMsg: action.error,
      }
    }

    case PAPER_TRADE_MARKET: {
      return {
        ...state,
        isPaperTradingMarket: true,
        paperTrademarketResp: {},
        paperTrademarketErr: false,
        paperTrademarketErrMsg: '',
      }
    }

    case PAPER_TRADE_MARKET_SUCCESS: {
      return {
        ...state,
        isPaperTradingMarket: false,
        paperTrademarketResp: action.data,
      }
    }

    case PAPER_TRADE_MARKET_FAILURE: {
      return {
        ...state,
        isPaperTradingMarket: false,
        paperTrademarketErr: true,
        paperTrademarketErrMsg: action.errror,
      }
    }

    default:
      return state
  }
}

export default discover
