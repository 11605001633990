import { getCookies, parseQuery, setCookie } from '../../utils/common'
import { ctbPathRegex, ORDER_TYPE, TPSL_TYPE } from '../../utils/consts'

export const validity = (indicatorSec) => {
  const {
    indicator,
    compareIndicator,
    comparator,
    comparatorValid = true,
    indicatorValid = true,
    compareIndicatorValid = true,
  } = indicatorSec
  return (comparator !== '' && comparator !== 'Comparator' && (comparatorValid || comparator === 'N/A')
        && compareIndicator !== '' && compareIndicator !== 'Indicator' && (compareIndicatorValid || compareIndicator === 'N/A')
        && indicator !== '' && indicator !== 'Indicator' && indicatorValid)
}

export const logicToStringGenerator = (indicatorArr, andors) => {
  let str = ''
  const len = indicatorArr.length
  let conditionExit = false

  for (let i = 0; i < len; i++) {
    const {
      indicator,
      compareIndicator,
      comparator,
      disabled = false,
      // andor
    } = indicatorArr[i]
    if (!disabled && validity(indicatorArr[i])) {
      if (
        conditionExit
      ) {
        const andor = andors[i] || 'and' // DANGER
        str += ` ${andor} `
      }
      conditionExit = true
      if (compareIndicator !== 'N/A') {
        // add all 3 of them
        str += `${indicator} ${comparator} ${compareIndicator}`
      } else {
        str += `${indicator}`
      }
    } else {
      str += ''
    }
  }
  return str
}

export const dynamicScripParams = (segment) => {
  const offsetRange = segment === 'INDICES' ? [9, -9, 1] : [4, -4, 1]
  const expiry_type_opt = segment === 'INDICES' ? ['MONTHLY', 'WEEKLY'] : ['MONTHLY']
  return {
    indicator: {
      params: [
        [
          'contract_type',
          'CE',
          true,
          'dropdown',
          ['CE', 'PE', 'FUT'],
          'Contract type',
        ],
        [
          'offset', // valueKey
          '0', // value
          true,
          'range', // value type
          offsetRange, // value type params,
          'Strikes offset from ATM', // value label (if not specified then will fallback to value key)
        ],
        [
          'expiring',
          'CURRENT',
          true,
          'dropdown',
          ['NEXT', 'CURRENT'],
          'Expiring',
        ],
        [
          'expiry_type',
          'MONTHLY',
          true,
          'dropdown',
          expiry_type_opt,
          'Expiry type',
        ],
        [
          'order_type',
          'LONG',
          true,
          'dropdown',
          ['LONG', 'SHORT'],
          'Order type',
        ],
        [
          'change_frequency',
          'CANDLE',
          true,
          'dropdown',
          ['CANDLE', 'DAY OPEN'],
          'Change frequency',
        ],
      ],
    },
  }
}

export const DYNAMIC_SEGMENTS = ['NSE', 'INDICES']

export const getDynamicSymbol = (params, seg_sym) => {
  const {
    contract_type, offset, expiring, expiry_type, order_type, change_frequency,
  } = params
  if(contract_type === 'FUT') {
    return `DYNAMIC CONTRACT(${seg_sym}, ${contract_type}, ${expiring}, ${expiry_type}, ${order_type})`
  }
  return `DYNAMIC CONTRACT(${seg_sym}, ${contract_type}, ATM, ${offset}, ${expiring}, ${expiry_type}, ${order_type}, ${change_frequency})`
}

// gives base seg_sym from dynamic contracts
export const getSegSymFromDC = (pair) => {
  let segSym = pair
  let isDyc = false
  if (pair.includes('DYNAMIC CONTRACT')) {
    [segSym] = pair.split('(')[1].split(',')
    isDyc = true
  }
  return { segSym, isDyc }
}

export const modifyAdvancedParams = ({
  max_allocation,
  max_quantity,
  tpsl_type,
  daily_strategy_cycle,
  position_sizing_type,
}) => {
  const tpsl = tpsl_type || TPSL_TYPE.PERCENTAGE.value
  const startegyCycle = daily_strategy_cycle || '-'
  if (!max_allocation) {
    return {
      max_allocation: '',
      max_quantity: '',
      tpsl_type: tpsl,
      daily_strategy_cycle: startegyCycle,
      position_sizing_type: '',
    }
  }
  return {
    max_allocation,
    max_quantity,
    tpsl_type: tpsl_type || TPSL_TYPE.PERCENTAGE.value,
    daily_strategy_cycle: daily_strategy_cycle || '-',
    position_sizing_type,
  }
}

export const getAdvancedParamsLabel = ({ tpsl_type }) => {
  let tpLabel = 'Target profit %'
  let slLabel = 'Stop loss %'
  let tslLabel = 'Trailing SL %'
  switch (tpsl_type) {
    case 'abs':
      tpLabel = 'Total Target profit'
      slLabel = 'Total Stop loss'
      tslLabel = 'Total Trailing SL'
      break
    case 'pts':
      tpLabel = 'Target profit'
      slLabel = 'Stop loss'
      tslLabel = 'Trailing SL'
      break
    default: break
  }
  return {
    tpLabel,
    slLabel,
    tslLabel,
  }
}

export const tslValidator = (tpsl_type, trailingSl) => {
  let error = false
  let errorMsg = ''
  if(tpsl_type === 'pct') {
    if(trailingSl > 0 && trailingSl < 0.1) {
      error = true
      errorMsg = TSL_ERROR_TYPE_MAP.PCT
    }
  } else if(tpsl_type === 'abs') {
    if(trailingSl > 0 && trailingSl < 100) {
      error = true
      errorMsg = TSL_ERROR_TYPE_MAP.ABS
    }
  } else if(tpsl_type === 'pts') {
    if(trailingSl > 0 && trailingSl < 1) {
      error = true
      errorMsg = TSL_ERROR_TYPE_MAP.PTS
    }
  }
  return {
    error, errorMsg,
  }
}

export const tpslValidator = (value, type) => {
  let error = false
  let errorMsg = ''
  if(value <= 0) {
    error = true
    errorMsg = TPSL_ERROR_MAP(type).tpslZeroError
  }
  return {
    error, errorMsg,
  }
}

export const TSL_ERROR_TYPE_MAP = {
  ABS: 'If TPSL type is abs, enter values either 0 or greater than 100(abs)',
  PTS: 'If TPSL type is pts, enter values either 0 or greater than 1(pts)',
  PCT: 'If TPSL type is pct, enter values either 0 or greater than 0.1(pct)',
}

export const TPSL_ERROR_MAP = type => ({
  tpSlABS: `If TPSL type is abs, enter ${type} value greater than 100`,
  tpslZeroError: `Enter ${type} value greater than zero`,
})

export const ALGO_MODE = {
  BASIC: {
    label: 'Basic mode', value: false, id: 'BASIC', iconName: 'SETTING_OUTLINED',
  },
  ADVANCE: {
    label: 'Advance mode', value: true, id: 'ADVANCE', iconName: 'CREATE_ADVANCE',
  },
}

export const CREATE_PAGE_VIEW_MAP = {
  BASIC: {
    label: 'Single page mode', value: false, id: 'SINGLE', iconName: 'SINGLE_PAGE_VIEW',
  },
  ADVANCE: {
    label: 'Multipage mode', value: true, id: 'MULTI', iconName: 'MULTI_PAGE_VIEW',
  },
}

// do not change
export const CONDITION_TYPE = {
  ENTRY: 'entry',
  EXIT: 'exit',
  REVIEW: 'review',
  POSITION: null,
  SCREENER: 'expressions',
  PRO: 'pro',
  PARAMS: 'parameters',
  ALGO_NAME: 'algo_name',
  QUANTITY: 'quantity',
  MAX_ALLOC: 'max_allocation',
  SCRIPLIST: 'scripList',
  SINGLE: 'single',
  SCREENER_NAME: 'screener_name',
}

export const STEP_CONDITION_TYPE = [
  {
    label: 'Positions', value: CONDITION_TYPE.POSITION, stepValue: 1, validTypes: ['scripList', 'quantity'],
  },
  {
    label: 'Entry', value: CONDITION_TYPE.ENTRY, stepValue: 2, validTypes: ['entry'],
  },
  {
    label: 'Exit', value: CONDITION_TYPE.EXIT, stepValue: 3, validTypes: ['tp', 'sl', 'tsl'],
  },
  {
    label: 'Review',
    value: CONDITION_TYPE.REVIEW,
    stepValue: '',
    validTypes: [
      'entry', 'tp', 'sl', 'max_allocation', 'scripList', 'quantity', 'tsl',
    ],
  },
]

export const VALID_TYPE_ERROR = {
  scripList: 'Add instruments to continue',
  quantity: 'Enter a valid quantity',
  entry: 'Entry field has an invalid condition',
  tp: 'Enter target profit greater than zero for your strategy',
  sl: 'Enter stop loss greater than zero for your strategy',
  ic: 'Enter initial capital greater than zero for your strategy',
  max: 'Enter max allocation greater than zero for your strategy',
  qty: 'Enter quantity greater than zero for your strategy',
  tpLimit: 'Enter target profit greater than zero and less than 1000 for TPSL type Pct %',
  slLimit: 'Enter stop loss greater than zero and less than 1000 for TPSL type Pct %',
  tslPct: 'Enter tsl 0 or greater than 0.1(pct)',
  tslAbs: 'Enter tsl 0 or greater than 100(abs)',
  tslPts: 'Enter tsl 0 or greater than 1(pts)',
  tpPct: 'Enter target profit 0 or greater than 0.1(pct)',
  tpAbs: 'Enter target profit 0 or greater than 100(abs)',
  tpPts: 'Enter target profit 0 or greater than 1(pts)',
  slPct: 'Enter stop loss 0 or greater than 0.1(pct)',
  slAbs: 'Enter stop loss 0 or greater than 100(abs)',
  slPts: 'Enter stop loss 0 or greater than 1(pts)',
}

export const initialState = {
  candleInterval: '1 Hour',
  positionType: 'Buy',
  quantity: '1',
  stopLoss: '',
  takeProfit: '',
  holding_type: 'MIS',
  order_type: ORDER_TYPE.MARKET,
  chart_type: 'Candlestick',
  trading_start_time: '00:00',
  trading_stop_time: '23:59',
  trailing_sl: '',
  initial_capital: 10000000,
}

// 1 = unsupported Indicator
// 2 = unsupported time frame
// 3 = unsupported time frame and indicator
// 4 = unsupported chart type
// 5 = unsupported chart type and indicator
// 6 = unsupported chart type and time frame
// 7 = unsupported chart type, timeframe and indicator

export const assumptionTokenMsgMap = {
  1: 'Unsupported Indicator, running backtest based with candle prices on a price action strategy.',
  2: 'Unsupported Time Frame, running backtest on the nearest supported time frame',
  3: 'Unsupported Indicator and Time Frame, running backtest based with candle prices on a price action strategy and nearest supported time frame',
  4: 'Unsupported Chart Type, running backtest on the candlestick chart',
  5: 'Unsupported Indicator and Chart Type, running backtest based with candle prices on a price action strategy and candlestick chart',
  6: 'Unsupported Time Frame and Chart Type, running backtest on the nearest supported time frame and candlestick chart',
  7: 'Unsupported Indicator, Time Frame and Chart Type, running backtest based with candle prices on a price action strategy and nearest supported time frame and candlestick chart',
}

export const isCtbFlow = (customPathname = '', skipCookieCheck) => {
  if (typeof window === 'undefined') return false
  const { ctb } = getCookies()
  const { pathname, search } = window.location
  const { ctb: isCtbBt } = parseQuery(search, true)
  const pathName = customPathname || pathname
  const isCtbCreate = ctbPathRegex.test(pathName)
  let ctbFlow = skipCookieCheck ? false : ctb
  if (!ctbFlow) {
    ctbFlow = (isCtbCreate || isCtbBt)
  }
  if (ctbFlow) {
    setCookie({ ctb: ctbFlow }, new Date(Date.now() + (24 * 60 * 60 * 1000)).toUTCString())
  }
  return ctbFlow
}

export const isLoginFLow = () => {
  if (typeof window === 'undefined') return false
  const { pathname, search } = window.location
  if(pathname.includes('/login/')) {
    const { dl } = parseQuery(search)
    return dl === 'true'
  }
  return false
}
