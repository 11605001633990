import { DEPLOYMENT_TYPE } from './consts'
import { ICONS } from '../Theme'

export const NAVIGATIONS = {
  DASHBOARD: {
    name: 'streak.dashboard',
    id: 'DASHBOARD',
  },
  PARTIAL_COMPLETE: {
    name: 'streak.partialComplete',
    id: 'PARTIAL_COMPLETE',
  },
  DISCOVER: {
    name: 'streak.discover',
    id: 'DISCOVER',
  },
  SUBSCRIBED: {
    name: 'streak.subscribed',
    id: 'SUBSCRIBED',
  },
  REWARDS: {
    name: 'streak.rewards',
    id: 'REWARDS',
  },
  MY_ALGOS: {
    name: 'streak.myAlgos',
    id: 'MY_ALGOS',
  },
  STRATEGIES: {
    name: 'streak.strategies',
    id: 'STRATEGIES',
  },
  CREATE: {
    name: 'streak.create',
    id: 'CREATE',
  },
  BACKTESTS: {
    name: 'streak.backtests',
    id: 'BACKTESTS',
  },
  DEPLOYED: {
    name: 'streak.deployed',
    id: 'DEPLOYED',
  },
  ORDERBOOK: {
    name: 'streak.orderbook',
    id: 'ORDERBOOK',
    title: 'OrderBook',
  },
  PORTFOLIO: {
    name: 'streak.portfolio',
    id: 'PORTFOLIO',
    title: 'Portfolio',
  },
  BASKETS: {
    name: 'streak.baskets',
    id: 'BASKETS',
  },
  NOTIFICATIONS: {
    name: 'streak.notifications',
    id: 'NOTIFICATIONS',
  },
  SCANNER_HISTORY: {
    name: 'streak.scannerHistory',
    id: 'SCANNER_HISTORY',
  },
  ACCOUNT: {
    name: 'streak.account',
    id: 'ACCOUNT',
  },
  FEEDBACK: {
    name: 'streak.feedback',
    id: 'FEEDBACK',
  },
  LOGOUT: {
    name: 'streak.logout',
    id: 'LOGOUT',
  },
  PRICING: {
    name: 'streak.pricing',
    id: 'PRICING',
  },
  PROFILE: {
    name: 'streak.profile',
    id: 'PROFILE',
  },
  VIDEOS: {
    name: 'streak.videos',
    id: 'VIDEOS',
  },
  MANUAL: {
    name: 'streak.manual',
    id: 'MANUAL',
  },
  TERMS: {
    name: 'streak.terms',
    id: 'TERMS',
  },
  PRIVACY: {
    name: 'streak.privacy',
    id: 'PRIVACY',
  },
  BACKTEST_DETAILS: {
    name: 'streak.backtestDetails',
    id: 'BACKTEST_DETAILS',
  },
  SIDEMENU: {
    name: 'streak.sidemenu',
    id: 'SIDEMENU',
  },
  AUTH: {
    name: 'streak.auth',
    id: 'AUTH',
  },
  CENTER_STACK: {
    name: 'streak.centerStack',
    id: 'CENTER_STACK',
  },
  ACCEPTTNC: {
    name: 'streak.acceptTnc',
    id: 'ACCEPTTNC',
  },
  LOGIN: {
    name: 'streak.login',
    id: 'LOGIN',
  },
  BILLING: {
    name: 'streak.billing',
    id: 'BILLING',
  },
  REFERRAL: {
    name: 'streak.referral',
    id: 'REFERRAL',
  },
  SIGNUP: {
    name: 'streak.signup',
    id: 'SIGNUP',
  },
  SIGNUP_SUCCESS: {
    name: 'streak.signupSuccess',
    id: 'SIGNUP_SUCCESS',
  },
  ARCHIVE: {
    name: 'streak.archive',
    id: 'ARCHIVE',
  },
  SCANNER: {
    name: 'streak.scanner',
    id: 'SCANNER',
  },
  SCANS: {
    name: 'streak.scans',
    id: 'SCANS',
  },
  PAYMENT: {
    name: 'streak.payment',
    id: 'PAYMENT',
  },
  BOTTOM_STACK: {
    name: 'streak.bottomStack',
    id: 'BOTTOM_STACK',
  },
  MY_SCANS: {
    name: 'streak.myScans',
    id: 'MY_SCANS',
  },
  LIVE_SCANS: {
    name: 'streak.liveScans',
    id: 'LIVE_SCANS',
  },
  DISCOVER_SCANS: {
    name: 'streak.discoverScans',
    id: 'DISCOVER_SCANS',
  },
  SCAN_DETAILS: {
    name: 'streak.scanDetails',
    id: 'SCAN_DETAILS',
  },
  SCAN_RESULT: {
    name: 'streak.scanResult',
    id: 'SCAN_RESULT',
  },
  MARKET: {
    name: 'streak.market',
    id: 'MARKET',
    title: 'Marketwatch',
  },
  REFERRALS: {
    name: 'streak.referrals',
    id: 'REFERRALS',
  },
  WATCHLIST: {
    name: 'streak.watchList',
    id: 'WATCHLIST',
    title: 'WatchList',
  },
  EDIT_WATCHLIST: {
    name: 'streak.editWatchList',
    id: 'EDIT_WATCHLIST',
  },
  ADD_SCRIP: {
    name: 'streak.addScrip',
    id: 'ADD_SCRIP',
  },
  BACKTEST_TRANSACTIONS: {
    name: 'streak.backtestTransaction',
    id: 'BACKTEST_TRANSACTIONS',
  },
  BACKTEST_EDIT_PARAMS: {
    name: 'streak.backtestEditParams',
    id: 'BACKTEST_EDIT_PARAMS',
  },
  // [DEPLOYMENT_TYPE.AUTO.value]: {
  //   name: 'streak.autotrading',
  //   id: DEPLOYMENT_TYPE.AUTO.value,
  // },
  [DEPLOYMENT_TYPE.PAPER.value]: {
    name: 'streak.papertrading',
    id: DEPLOYMENT_TYPE.PAPER.value,
  },
  [DEPLOYMENT_TYPE.NOTIFICATION.value]: {
    name: 'streak.notificationtrading',
    id: DEPLOYMENT_TYPE.NOTIFICATION.value,
  },
  SYMBOL_DETAILS: {
    name: 'streak.symbolDetails',
    id: 'SYMBOL_DETAILS',
  },
  BUY_SELL: {
    name: 'streak.buySell',
    id: 'BUY_SELL',
  },
  TRADES: {
    name: 'streak.trades',
    id: 'TRADES',
  },
  ORDERLOG: {
    name: 'streak.orderlog',
    id: 'ORDERLOG',
  },
  DEPLOY: {
    name: 'streak.deploy',
    id: 'DEPLOY',
  },
  MY_PROFILE: {
    name: 'streak.myProfile',
    id: 'MY_PROFILE',
  },
  MY_ACCOUNT: {
    name: 'streak.myAccount',
    id: 'MY_ACCOUNT',
  },
  PREFERENCE: {
    name: 'streak.preference',
    id: 'PREFERENCE',
  },
  INVOICES: {
    name: 'streak.invoices',
    id: 'INVOICES',
  },
  TECHNICALS: {
    name: 'streak.technicals',
    id: 'TECHNICALS',
  },
  HELP: {
    name: 'streak.help',
    id: 'HELP',
  },
  ONBOARDING: {
    name: 'streak.onboarding',
    id: 'ONBOARDING',
  },
  TOP_MOVERS: {
    name: 'streak.topMovers',
    id: 'TOP_MOVERS',
  },
  DASHBOARD_POSITIONS: {
    name: 'streak.dashboardPositions',
    id: 'DASHBOARD_POSITIONS',
  },
  WEBVIEW: {
    name: 'streak.webview',
    id: 'WEBVIEW',
  },
  SHAREABLE_LINKS: {
    name: 'streak.shareableLinks',
    id: 'SHAREABLE_LINKS',
  },
}

// This is a mapping of pathnames to the endpoints (in sync with streak world v3 repo)
export const pathname_mapping = {
  Dashboard: '/dashboard',
  Strategies: '/algos',
  Explore: '/algos/explore', //  detailed view
  MyStrategy: '/algos/my_strategy', //  detailed view
  MyPicks: '/algos/my_picks', //  detailed view
  Discover: '/algos/discover', //  detailed view
  AlgoTopPerformer: '/algos/discover?tab=top_performer',
  Deployed: '/algos?tab=live',
  strategy: '/algos?tab=strategy', // mapped
  discover: '/algos?tab=discover', // mapped
  live: '/algos?tab=live', // mapped
  paper: '/algos?tab=paper', // mapped
  auto: '/algos?tab=auto', // mapped
  Create: '/create', // mapped
  Backtests: '/backtests',
  BacktestsSharedApp: '/backtests/',
  Orderbook: '/trades',
  Portfolio: '/trades?tab=positions', // mapped
  holdings: '/trades?tab=holdings', // mapped
  positions: '/trades?tab=positions',
  executed_orders: '/trades?tab=executed', // mapped
  pending_orders: '/trades?tab=pending',
  open_orders: '/trades?tab=open', // mapped
  Scanners: '/scans',
  NewScanner: '/scanner', // mapped
  Notifications: '/notifications',
  notif_live: '/notifications?tab=live',
  notif_paper: '/notifications?tab=paper',
  notif_scanner: '/notifications?tab=scanner',
  Account: '/account',
  Library: '/library',
  profile: '/account?tab=profile',
  pricing: '/account?tab=pricing',
  billing: '/account?tab=billing',
  preferences: 'account?tab=preferences',
  help: 'account?tab=help',
  shareable_links: 'account?tab=shareable_links',
  popular: '/scans/popular',
  ScannerPopular: '/scans/popular?tab=discover_popular',
  my_scans: '/scans/my_scans',
  live_scans: '/scans/live_scans',
  Trades: '/trades',
  InProgress: '/partial_complete',
  ScanDetails: '/scans_details', // not used

  // new added in v4
  demo_request: '/demo_request',
  marketwatch: '/market_watch',
  symbolDetails: '/symbol',
  // demo: '/demo',

  // pending ones from world_web (review below ones and remove unnecessary ones)

  Marketplace: '/market_place',
  SharedBacktest: '/shared_backtest',
  MarketplaceBacktest: '/marketplace_backtest',
  ForgotPassword: '/forgot_password',
  PasswordReset: '/password_reset',
  AccountActivation: '/account_activation',
  Verify2fa: '/verify_2fa',
  BrokerAuth: '/brokerAuth',
  BrokerRedirect: '/broker_redirect',

  Chart: '/chart',
  Accounts: '/account?tab=accounts',
  // Referrals: '/account?tab=referrals',
  // Rewards: '/account?tab=rewards',
  payment: '/payment',
  Help: '/help',

  // change in consts also
  AppZerodhaLogin: '/login/zerodha',
  AppPaisaLogin: '/login/5paisa',
  AppAngelLogin: '/login/angel',
  AppUpstoxLogin: '/login/upstox',
  AppIciciLogin: '/login/icici',

  // // HomePage routes
  // HomepageZerodha: '/home/zerodha',
  // Homepage5Paisa: '/home/5paisa',
  // HomepageAngelBroking: '/home/angel one',
  // HomepageUpstox: '/home/upstox',
  // HomepageIcici: '/home/icici',

  BrowseBroker: '/browse/:broker',
  OfferPage: '/browse/:broker',

  Auth: '/login',
  SignUp: '/signup',
  Home: '/home',
  SystematicTrading: '/SystematicTrading',
  Ctb: '/Chart-To-Backtest',

  Graph: '/graph',

  PaymentPages: '/offer-info-page',

  // for placing order from open screener page
  OpenScanOrder: '/direct_order',

  // for delete data
  DeleteProfile: '/deletemydata',
}

export const carousel_mapping = {
  Referrals: '/referrals',
  DemoRequest: '/demo_request',
  Billing: '/billing',
  Strategies: '/strategies',
}

export const BOTTOM_TABS = {
  TAB_DASHBOARD: {
    id: 'TAB_DASHBOARD',
    icon: ICONS.TAB_DASHBOARD,
    to: pathname_mapping.Dashboard,
    label: 'Dashboard',
    subRoutes: [
      pathname_mapping.Dashboard, pathname_mapping.Trades, pathname_mapping.Notifications,
      pathname_mapping.InProgress,
    ],
  },
  TAB_STRATEGY: {
    id: 'TAB_STRATEGY',
    icon: ICONS.TAB_STRATEGY,
    to: pathname_mapping.Strategies,
    label: 'Strategies',
    subRoutes: [
      pathname_mapping.Create, pathname_mapping.Backtests, pathname_mapping.BacktestsSharedApp,
      pathname_mapping.MyStrategy, pathname_mapping.MyPicks, pathname_mapping.Strategies,
      pathname_mapping.Explore, pathname_mapping.Discover,
      pathname_mapping.AlgoTopPerformer,
      pathname_mapping.Marketplace, pathname_mapping.MarketplaceBacktest,
      pathname_mapping.Graph,
    ],
  },
  TAB_MARKET: {
    id: 'TAB_MARKET',
    icon: ICONS.TAB_MARKET,
    to: pathname_mapping.marketwatch,
    label: 'Technicals',
    subRoutes: [
      pathname_mapping.marketwatch, pathname_mapping.symbolDetails,
    ],
  },
  TAB_SCANNER: {
    id: 'TAB_SCANNER',
    icon: ICONS.TAB_SCANNER,
    to: pathname_mapping.Scanners,
    label: 'Scanners',
    subRoutes: [
      pathname_mapping.Scanners, pathname_mapping.NewScanner, pathname_mapping.popular,
      pathname_mapping.my_scans, pathname_mapping.live_scans,
    ],
  },
  TAB_PROFILE: {
    id: 'TAB_PROFILE',
    icon: ICONS.TAB_PROFILE,
    to: pathname_mapping.Account,
    label: 'Profile',
    subRoutes: [
      pathname_mapping.Account, pathname_mapping.payment, pathname_mapping.Referrals,
    ],
  },
}

export const appLoginRoutes = [
  pathname_mapping.AppAngelLogin, pathname_mapping.AppPaisaLogin,
  pathname_mapping.AppZerodhaLogin, pathname_mapping.AppUpstoxLogin,
  pathname_mapping.AppIciciLogin,
]
