import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import ReactDOM from 'react-dom'
import Icon from '../../UI/Icon'
import ProfileImage from '../ProfileImage'
import CustomTooltip from '../../UI/CustomTooltip'
import ProfileFabOptions from './ProfileFabOptions'

import { COLORS, ICONS, theme } from '../../Theme'
import { BOTTOM_TABS } from '../../utils/navigationConstant'

const NAV_TAB = Object.values(BOTTOM_TABS)

const NavBar = React.memo(({
  styles,
  isMobile,
  isDark,
  updateGA,
  startTour,
}) => {
  const linkRef = useRef()
  const location = useLocation()
  const [layoutDim, updateDim] = useState({})
  const [showQuickNavModal, toggleQuickNavModal] = useState(false)
  useEffect(() => {
    if (linkRef.current) {
      const { offsetWidth, offsetLeft } = linkRef.current
      updateDim({ width: offsetWidth, transform: `translateX(${offsetLeft}px)` })
    }
  }, [location])
  const { pathname } = location
  const tabWidth = `calc(100% / ${NAV_TAB.length})`
  const renderDuplicate = () => {
    return ReactDOM.createPortal(
      <div
        className={styles.headerNav}
        style={{
          zIndex: 999999,
          position: 'fixed',
          opacity: 0,
          backgroundColor: 'white',
          transition: 'all .3s ease-in-out',
        }}
        id="pt_nav_dup"
      >
        <div className={styles.activeBar} style={layoutDim} id="pt_nav_indicator" />
        {NAV_TAB.map((navObj) => {
          const {
            to, icon, id,
          } = navObj
          const ptHandleClick = (e) => { return e.preventDefault() }
          if (isMobile && id === BOTTOM_TABS.TAB_PROFILE.id) {
            return (
              <div key={id} style={{ width: tabWidth }}>
                <button
                  type="button"
                  onClick={() => toggleQuickNavModal(!showQuickNavModal)}
                  className={styles.quickNavToggleBtn}
                  disabled
                >
                  {showQuickNavModal ? (
                    <Icon name={ICONS.CLOSE} color={theme.linkColor} />
                  ) : (
                    <ProfileImage
                      showUpload={false}
                      imgContainerStyles={styles.imgContainerNav}
                    />
                  )}
                </button>
              </div>
            )
          }
          return (
            <div key={id} style={{ width: tabWidth }} className={id === 'TAB_DASHBOARD' ? '' : 'pt_nav_item'}>
              <NavLink
                onClick={e => ptHandleClick(e)}
                to={to}
                className={`${styles.ptNavLink}`}
              >
                {isMobile && id === BOTTOM_TABS.TAB_PROFILE.id
                  ? (
                    <ProfileImage
                      showUpload={false}
                      imgContainerStyles={styles.imgContainerNav}
                    />
                  )
                  : (
                    <Icon
                      name={icon}
                      size={20}
                      color={isDark ? COLORS.BLACK_600 : COLORS.GREY}
                    />
                  )}
              </NavLink>
            </div>
          )
        })}
      </div>,
      document.getElementById('highlightedWindow'),
    )
  }

  return (
    <>

      {isMobile && (
      <ProfileFabOptions
        toggleQuickNavModal={toggleQuickNavModal}
        showQuickNavModal={showQuickNavModal}
        isDark={isDark}
      />
      )}
      {!isMobile && startTour && renderDuplicate()}
      <div className={styles.headerNav} id="pt_nav">
        <div className={styles.activeBar} style={layoutDim} />
        {NAV_TAB.map((navObj) => {
          const {
            to, icon, id, subRoutes, label,
          } = navObj
          const isActive = subRoutes.includes(pathname)
          const updateAnalytics = () => {
            updateGA(label, label)
          }
          if (isMobile && id === BOTTOM_TABS.TAB_PROFILE.id) {
            return (
              <div key={id} ref={isActive ? linkRef : null} style={{ width: tabWidth }}>
                <button
                  type="button"
                  onClick={() => toggleQuickNavModal(!showQuickNavModal)}
                  className={styles.quickNavToggleBtn}
                >
                  {showQuickNavModal ? (
                    <Icon name={ICONS.CLOSE} color={theme.linkColor} />
                  ) : (
                    <ProfileImage showUpload={false} imgContainerStyles={styles.imgContainerNav} />
                  )}
                </button>
              </div>
            )
          }
          return (
            <div key={id} ref={isActive ? linkRef : null} style={{ width: tabWidth }}>
              <CustomTooltip show tooltipProps={{ text: label }} placement="bottom">
                <NavLink
                  to={to}
                  className={`${styles.navLink} ${isActive ? styles.activeNavLink : ''}`}
                  onClick={updateAnalytics}
                >
                  {isMobile && id === BOTTOM_TABS.TAB_PROFILE.id
                    ? (
                      <ProfileImage
                        showUpload={false}
                        imgContainerStyles={styles.imgContainerNav}
                      />
                    )
                    : (
                      <Icon
                        name={icon}
                        size={20}
                        color={isDark ? COLORS.BLACK_600 : COLORS.GREY}
                      />
                    )}
                </NavLink>
              </CustomTooltip>
            </div>
          )
        })}
      </div>
    </>
  )
})

export default NavBar
