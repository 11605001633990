import makeStyles from '@material-ui/styles/makeStyles'
import React, { useCallback, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'

import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Icon from '../../UI/Icon'
import CustomText from '../../UI/CustomText'
import ViewAllCard from '../../UI/ViewAllCard'
import OrderbookSection from '../Dashboard/OrderbookSection'
import PortfolioSidebarCard from '../Portfolio/PortfolioSidebarCard'

import { useTheme } from '../../Theme/ThemeProvider'
import {
  SPACING, DIMENSIONS, ICONS, theme,
} from '../../Theme'
import { pathname_mapping } from '../../utils/navigationConstant'
import { getNotificationCount } from '../../containers/Notifications/actions'
import { getDeployedCount } from '../../containers/Deployed/actions'
import { NOTIFICATIONS_SECTION_MAP } from '../../containers/Notifications/notificationUtils'
import { PORTFOLIO_TABS } from '../../containers/Portfolio/portfolioUtils'
import { ORDER_TYPE } from '../../containers/OrderBook/orderbookUtils'

const tabs = [
  {
    label: 'Positions', value: 'positions', textColor: 'textSecondary', activeTextColor: 'purple', index: 0, gaAction: 'Position',
  },
  {
    label: 'Holdings', value: 'holdings', textColor: 'textSecondary', activeTextColor: 'green', index: 1, gaAction: 'Holding',
  },
]

const gaCategory = 'Sidepanel quicklinks'

const AlgoSideBar = (props) => {
  const styles = stylesheet()
  const history = useHistory()
  const { updateGA, isDark } = useTheme()

  const {
    deployedCountObj,
    fetchDeployedCount,
  } = props

  const [portfolioTabIndex, updateTabIndex] = useState(0)

  useEffect(() => fetchDeployedCount(), [])
  const deployedSectionCount = useCallback(
    (section, keys) => {
      const data = deployedCountObj[section.DEPLOYED_COUNT_KEY]
      if (isEmpty(data)) return 0
      let count = 0
      keys.forEach((item) => {
        count += data[item] || 0
      })
      return count
    },
    [deployedCountObj],
  )

  // do not change the sequence
  const menuItems = [
    {
      title: 'Live Trades',
      sectionId: NOTIFICATIONS_SECTION_MAP.LIVE.ID,
      subTitle: deployedSectionCount(NOTIFICATIONS_SECTION_MAP.LIVE, ['waiting', 'entered']),
      iconName: ICONS.NOTIF_AUTO,
      stPrefix: 'Deployed',
      gaAction: 'Live trade',
      link: `${pathname_mapping.Strategies}?tab=${NOTIFICATIONS_SECTION_MAP.LIVE.ID.toLowerCase()}`,
    },
    {
      title: 'Paper Trades',
      sectionId: NOTIFICATIONS_SECTION_MAP.PAPER.ID,
      subTitle: deployedSectionCount(NOTIFICATIONS_SECTION_MAP.PAPER, ['waiting', 'entered']),
      iconName: ICONS.NOTIF_PAPER,
      stPrefix: 'Deployed',
      gaAction: 'Paper trade',
      link: `${pathname_mapping.Strategies}?tab=${NOTIFICATIONS_SECTION_MAP.PAPER.ID.toLowerCase()}`,
    },
    {
      title: 'Scanners',
      sectionId: NOTIFICATIONS_SECTION_MAP.SCANNER.ID,
      subTitle: deployedSectionCount(NOTIFICATIONS_SECTION_MAP.SCANNER, ['live']),
      iconName: ICONS.NOTIF_SCANNER,
      stPrefix: 'Live',
      gaAction: 'Live scanner',
      link: pathname_mapping.live_scans,
    },
  ]

  const renderEmptyText = (title) => {
    return (
      <CustomText size="small" color="textSecondary">
        {title === menuItems[2].title ? 'Not Live' : 'Not Deployed'}
      </CustomText>
    )
  }

  const onBarPress = (sectionId, gaAction) => {
    updateGA(gaAction, 'Deployed', 1, gaCategory)
    if (sectionId !== NOTIFICATIONS_SECTION_MAP.SCANNER.ID) {
      history.push({
        pathname: pathname_mapping.Strategies,
        search: `?tab=${sectionId.toLowerCase()}`,
      })
    } else {
      history.push({
        pathname: pathname_mapping.live_scans,
      })
    }
  }

  const routeToTechicals = () => {
    history.push({
      pathname: pathname_mapping.marketwatch,
    })
  }

  const routeToOrderbook = (tab) => {
    updateGA(ORDER_TYPE.PENDING ? 'Open' : 'Complete', 'Orders', 1, gaCategory)
    history.push({
      pathname: pathname_mapping.Trades,
      search: `?tab=${tab}`,
    })
  }

  const updateIndex = (tab, item) => {
    if (tab === tabs[0].value) updateTabIndex(0)
    else updateTabIndex(1)
    if (item) {
      updateGA(item.gaAction, 'Portfolio', 1, gaCategory)
    }
  }

  const routeToPortfolio = (tabKey) => {
    updateGA('View all', 'Portfolio', 1, gaCategory)
    history.push({
      pathname: pathname_mapping.Trades,
      search: `?tab=${tabKey}`,
    })
  }

  const onPressPortfolioViewAll = () => {
    routeToPortfolio(portfolioTabIndex === 0
      ? PORTFOLIO_TABS.POSITIONS.KEY
      : PORTFOLIO_TABS.HOLDINGS.KEY)
  }

  const renderMenuItems = (item) => {
    const {
      title, iconName, sectionId, subTitle, stPrefix, gaAction,
      link,
    } = item
    return (
      <button
        onClick={() => onBarPress(sectionId, gaAction)}
        type="button"
        key={title}
        style={{ width: '100%' }}
      >
        <Link to={link} onClick={e => e.stopPropagation()}>
          <div className={styles.row}>
            <div className={styles.iconContainer}>
              <Icon name={iconName} color={theme.linkColor} size={16} />
            </div>
            <div className={styles.info}>
              <CustomText weight="medium">{title}</CustomText>
              {subTitle ? (
                <CustomText className={styles.subText} color="textSecondary" size="small_0">
                  {`${subTitle} ${stPrefix}`}
                </CustomText>
              ) : renderEmptyText(title)}
            </div>
            <div className={styles.arrow}>
              <Icon name={ICONS.FORWARD} color={theme.textSecondary} size={10} />
            </div>
          </div>
        </Link>
      </button>
    )
  }

  const pathname = portfolioTabIndex === 0 ? pathname_mapping.positions : pathname_mapping.holdings

  return (
    <div className={styles.container}>
      <div id="tour_strat_4">
        <CustomText size="medium" weight="semi_bold" className={styles.title}>Deployed</CustomText>
        <div className={styles.menuItems}>
          {menuItems.map(item => renderMenuItems(item))}
        </div>
      </div>
      <CustomText size="medium" weight="semi_bold" className={`${styles.title} ${styles.orderbookTitle}`}>Orderbook</CustomText>
      <OrderbookSection
        containerStyles={styles.orderBookContainer}
        onViewAll={routeToOrderbook}
        routeToTechicals={routeToTechicals}
        rowStyles={styles.orderBookRow}
        isDark={isDark}
        fromSidebar
      />
      <ViewAllCard
        title="Portfolio"
        onPress={onPressPortfolioViewAll}
        pathname={pathname}
        cardStyles={classnames(styles.card)}
      >
        <PortfolioSidebarCard
          tabs={tabs}
          updateIndex={updateIndex}
          routeToTechicals={routeToTechicals}
          index={portfolioTabIndex}
        />
      </ViewAllCard>
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    paddingTop: SPACING.SPACE_10,
  },
  title: {
    margin: `${SPACING.SPACE_10} ${DIMENSIONS.SPACE_HORIZONTAL}`,
  },
  orderbookTitle: {
    marginTop: SPACING.SPACE_24,
  },
  row: {
    display: 'flex',
    padding: `${SPACING.SPACE_10} ${DIMENSIONS.SPACE_HORIZONTAL}`,
    '&:hover': {
      background: theme.hover,
      '& $arrow > svg': {
        fill: theme.linkColor,
      },
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    width: '32px',
    borderRadius: '5px',
    justifyContent: 'center',
  },
  info: {
    marginLeft: SPACING.SPACE_16,
    textAlign: 'left',
    textTransform: 'capitalize',
    flex: 1,
  },
  subText: {
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    boxShadow: 'none',
  },
  orderBookContainer: {
    padding: 0,
  },
  orderBookRow: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
  },
})

const mapStateToProps = (state) => {
  return {
    csrf: state.auth.loginResp.csrf,
    deployedCountObj: state.deployed.deployedCountObj,
    isFetchingNotifications: state.notification.isFetchingNotifications,
    isFetchingDeployedCount: state.deployed.isFetchingDeployedCount,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotificationCount: headers => dispatch(getNotificationCount(headers)),
    fetchDeployedCount: headers => dispatch(getDeployedCount(headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlgoSideBar)
