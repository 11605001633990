/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect } from 'react'
// import { ICONS } from '../../Theme'
// import Icon from '../../UI/Icon'
import HOME_ASSETS from '../assets'
import { DISPLAY_DEVICE } from '../../utils/consts'

const Slider = ({ carouselData, displayDevice }) => {
  const [current, setCurrent] = React.useState(1)
  const [rollTo, setRollTo] = React.useState('')
  const [slideData, setSlideData] = React.useState([])
  const carousalRef = React.useRef(null)

  const isMobile = displayDevice === DISPLAY_DEVICE.SMALL_MOBILE.valueKey
  const isTab = displayDevice === DISPLAY_DEVICE.MOBILE.valueKey

  const dataLength = carouselData.length

  const sliderDotCount = isMobile ? dataLength : dataLength / 2

  useEffect(() => {
    // const carouselRow = document.getElementById('carouselRow')

    // carouselRow.addEventListener('scroll', handleScroll)
    // return () => carouselRow.removeEventListener('scroll', handleScroll)
    if (current === 0) {
      setSlideData([carouselData[dataLength - 1], carouselData[0], carouselData[1]])
    }else if (current === dataLength - 1) {
      setSlideData([carouselData[dataLength - 2], carouselData[dataLength - 1], carouselData[0]])
    } else{
      setSlideData([carouselData[current - 1], carouselData[current], carouselData[current + 1]])
    }
  }, [carouselData, current])

  const nextSlide = () => {
    // const testimonialCard = document.getElementById('testimonialcard')
    // const cardWidth = testimonialCard.offsetWidth
    // const scrollOffset = isMobile ? cardWidth * 1.32 : cardWidth * 1.5

    // carousalRef.current.scrollLeft += (scrollOffset)
    setRollTo('rollToRight')
    setCurrent(current === dataLength - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    // const testimonialCard = document.getElementById('testimonialcard')
    // const cardWidth = testimonialCard.offsetWidth
    // const scrollOffset = isMobile ? cardWidth * 1.32 : cardWidth * 1.5
    // carousalRef.current.scrollLeft -= (scrollOffset)
    setRollTo('rollToLeft')
    setCurrent(current === 0 ? dataLength - 1 : current - 1)
  }

  const selectSlide = (e, selectedIndex) => {
    e.preventDefault()
    const testimonialCard = document.getElementById('testimonialcard')
    const cardWidth = testimonialCard.offsetWidth
    const scrollOffset = isMobile ? cardWidth * 1.32 : cardWidth * 2.1
    if(current < selectedIndex) {
      carousalRef.current.scrollLeft += Math.abs(selectedIndex - current) * scrollOffset
    } else {
      carousalRef.current.scrollLeft -= Math.abs(selectedIndex - current) * scrollOffset
    }
    setCurrent(selectedIndex)
  }

  // const scrollToNextCard = (nextIndex) => {
  //   const testimonialCard = document.getElementById('testimonialcard')
  //   const cardWidth = testimonialCard.offsetWidth
  //   const scrollOffset = isMobile ? cardWidth * 1.32 : cardWidth * 2.1

  //   if (current < nextIndex) {
  //     carousalRef.current.scrollLeft = Math.abs(nextIndex) * scrollOffset
  //   } else {
  //     carousalRef.current.scrollLeft = Math.abs(nextIndex) * scrollOffset
  //   }
  //   setCurrent(nextIndex)
  // }

  // const handleScroll = () => {
  //   const testimonialCard = document.getElementById('testimonialcard')
  //   const cardWidth = testimonialCard.offsetWidth
  //   const scrollOffset = isMobile
  //     ? carousalRef.current.scrollLeft * 0.7 : carousalRef.current.scrollLeft / 2
  //   const currentIndex = Math.round(scrollOffset / cardWidth)
  //   // if (currentIndex !== current) {
  //   //   scrollToNextCard(currentIndex)
  //   // }

  //   setCurrent(currentIndex)
  // }

  return (
  // <div className="paperTradeContainer">
  //   {/* <div style={{ width: '100%' }}>
  //     <div>
  //       <p style={{ fontSize: '36px', fontFamily: 'Assistant,
  //        sans-serif', textAlign: 'center' }}>{carouselData[current].title}</p>
  //       <p className="paper-trading-description">
  //         {carouselData[current].description}
  //       </p>
  //     </div>
  //   </div> */}
  //   <div className="styleContainer"
  // style={{ width: 'fit-content', justifySelf: 'center', position: 'relative' }}>
  //     {!(isTab || isMobile) && (
  //       <button
  //         type="button"
  //         onClick={prevSlide}
  //         className="left-arrow"
  //       >
  //         <Icon name={ICONS.BACK} />
  //       </button>
  //     )}
  //     {!(isTab || isMobile) && (
  //       <button
  //         type="button"
  //         onClick={nextSlide}
  //         className="right-arrow"
  //       >
  //         <Icon name={ICONS.FORWARD} />
  //       </button>
  //     )}
  //     <div
  //       className="styledSlider"
  //       style={{
  //         width: '85%', margin: 'auto',
  //       }}
  //       id="carouselRow"
  //       ref={carousalRef}
  //     >
  //       {carouselData.map((item) => {
  //         return (
  //           <div style={{
  //             display: 'flex',
  //             columnGap: '3%',
  //             flex: 1,
  //             // width: isMobile ? '80%' : '100%',
  //           }}
  //           >
  //             {item.name
  //               ? (
  //                 <div className="testimonialContainer"
  // id="testimonialcard">
  //                   <div className="testimonialSubContainer" style={{ margin: '30px 20px' }}>
  //                     <img src={item.profileImg} alt="user_img"
  // style={{ width: '70px', height: '70px', justifySelf: 'center' }} />
  //                     <p className="comment">
  //                       <q>
  //                         {item.comment}
  //                       </q>
  //                     </p>
  //                     <p style={{
  //                       alignSelf: 'end', color: '#0E0E0E', opacity: 1, fontWeight: 'bold',
  //                     }}
  //                     >
  //                       {item.name}
  //                     </p>
  //                   </div>
  //                 </div>
  //               ) : null}
  //           </div>
  //         )
  //       })}
  //     </div>
  //     {(isMobile || isTab) && (
  //     <div
  //       style={{
  //         justifyContent: 'center', display: 'grid', marginTop: '24px', alignItems: 'center',
  //       }}
  //     >
  //       <div style={{
  //         display: 'flex', alignItems: 'center', gap: 10, height: 20,
  //       }}
  //       >
  //         {[...Array(sliderDotCount)].map((key, index) => {
  //           return (
  //             <div
  //               label={index}
  //               className={`${current === index ? 'activeSlider' : 'unactiveSlider'}`}
  //               role="button"
  //               onClick={(e) => { selectSlide(e, index) }}
  //             />
  //           )
  //         })}
  //       </div>
  //     </div>
  //     )}
  //   </div>
  // </div>

    <div className="paperTradeContainer">
      <div className="styleContainer" style={{ width: 'fit-content', justifySelf: 'center', position: 'relative' }}>
        {!(isTab || isMobile) && (
        <button
          type="button"
          onClick={prevSlide}
          className="left-arrow"
        >
          <img src={HOME_ASSETS.BackwardArrow} alt="back" />
        </button>
        )}
        {!(isTab || isMobile) && (
        <button
          type="button"
          onClick={nextSlide}
          className="right-arrow"
        >
          <img src={HOME_ASSETS.ForwardArrow} alt="forward" />
        </button>
        )}

        <div
          className={`styledSlider ${rollTo}`}
          id="carouselRow"
          ref={carousalRef}
          key={Math.random()}
        >
          {slideData.map((item) => {
            return (
              <div
                style={{
                  display: 'flex',
                  columnGap: '3%',
                  flex: 1,
                }}
                key={item.name}
              >
                {item.name
                  ? (
                    <div className="testimonialContainer" id="testimonialcard" style={{ backgroundColor: item.bgColor, color: item.txtColor }}>
                      <div className="testimonialSubContainer" style={{ margin: '30px 20px' }}>
                        <img src={item.profileImg} alt="user_img" style={{ width: '110px', height: '110px', justifySelf: 'center' }} />
                        <p style={{ fontWeight: '800', fontFamily: 'multi-display-medium' }}>
                          {item.name}
                        </p>
                        <p className="comment">
                          <img src={HOME_ASSETS.QouteLeft} alt="qoute" className="qoutes" />
                          {item.comment}
                          <img src={HOME_ASSETS.QouteRight} alt="qoute" className="qoutes" />
                        </p>
                      </div>
                    </div>
                  ) : null}
              </div>
            )
          })}
        </div>

        {(isMobile || isTab) && (
        <div
          style={{
            justifyContent: 'center', display: 'grid', marginTop: '24px', alignItems: 'center',
          }}
        >
          <div style={{
            display: 'flex', alignItems: 'center', gap: 10, height: 20,
          }}
          >
            {[...Array(sliderDotCount)].map((key, index) => {
              return (
                <div
                  key={key}
                  label={index}
                  className={`${current === index ? 'activeSlider' : 'unactiveSlider'}`}
                  role="button"
                  onClick={(e) => { selectSlide(e, index) }}
                />
              )
            })}
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default Slider
