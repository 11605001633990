import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {
  isEmpty, isEqual,
  // isEqual,
} from 'lodash'
import CustomText from '../../UI/CustomText'
import Switch from '../../UI/Switch'
import Icon from '../../UI/Icon'
import { showSnackbar } from '../../UI/Snackbar'
import processBrokerage from '../../utils/brokerageCalculations'
import { avgBacktestDetailsCalculator } from '../../utils/common'
import {
  ICONS, SPACING, theme, COLORS, FONTS,
} from '../../Theme'
import { accessConditions, userAccessCheck } from '../../utils/userAccessChecks'
import { withTheme } from '../../Theme/ThemeProvider'
import CummulativePnlLoader from '../../UI/Loaders/Backtests/CummulativePnlLoader'
import { ALGO_KEY_MAP } from '../AlgoCondensedView/AlgoUtils'

class CummulativePnl extends PureComponent {
  state = {
    calculatingAvgBrokerage: false,
    finalPnl: 0,
    returnValue: 0,
    isCalculationBrokerage: false,
    isFetching: false,
  }

  fetchAllData = true

  avgBrokerageData = {}

  avgData = {}

  componentDidMount() {
    const { isRunningBacktests } = this.props
    if (!isRunningBacktests) {
      this.debouncedFetch()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isRunningBacktests, isFetchingData, isSavingBacktestParams,
      // scripList = [],
    } = this.props
    if (!isEqual(prevProps.isFetchingData, isFetchingData) && !isFetchingData
    // || (!scripList && !isEqual(data, prevProps.data))
    // || (!isRunningBacktests && !isEqual(scripList, prevProps.scripList))
    ) {
      this.getAvgBacktestData()
    }
    if (isRunningBacktests && this.fetchAllData) {
      this.fetchAllData = false
    }
    if ((!isSavingBacktestParams && prevProps.isSavingBacktestParams)
    || (!isRunningBacktests && prevProps.isRunningBacktests)
    ) {
      this.debouncedFetch()
    }
  }

  componentWillUnmount() {
    if (this.fetchTimer) clearTimeout(this.fetchTimer)
  }

  getAvgBacktestData = () => {
    const {
      isRunningBacktests, data,
      scripList = [],
    } = this.props
    this.avgBrokerageData = {}
    this.avgData = avgBacktestDetailsCalculator(data, isRunningBacktests, scripList)
    const { final_pnl = 0.0, absolute_pnl_pct: final_return = 0.0 } = this.avgData
    this.setState({
      finalPnl: final_pnl,
      returnValue: final_return,
      avg_brokerage_enabled: false,
      isFetching: false,
    })
  }

  debouncedFetch = () => {
    const { fetchBacktestAll } = this.props
    // if(this.fetchAllData) {
    this.setState({ isFetching: true })
    this.fetchTimer = setTimeout(() => {
      fetchBacktestAll(true)
    }, 500)
    // } else {
    // this.getAvgBacktestData()
    // }
  }

  calcAvgBrokerage = () => {
    const { data, scripList = [] } = this.props
    let final_pnl = 0
    let return_value = 0
    let sym_max_cap_used = 0

    // const segSymArr = Object.keys(data)
    let symCount = 0
    scripList.forEach((segSymObj) => {
      const {
        segment, symbol, seg_sym,
      } = segSymObj
      const segSym = seg_sym || `${segment}_${symbol}`
      if (!data[segSym]) return

      // const { seg_sym, backtest_result, error } = item
      const {
        trade_log, pnl, max_cap_used = 0, error: btErr, error_msg: btErrMsg,
        run_params = {}, waiting,
      } = data[segSym] || {}
      const { holding_type } = run_params
      if (btErr || btErrMsg || waiting) return
      symCount += 1
      const { trade_log_brokerage } = processBrokerage(segSym, trade_log, pnl, holding_type)
      const brokerageLogs = trade_log_brokerage
      const pnl_value = Number(brokerageLogs[brokerageLogs.length - 1][5]).toFixed(2)

      return_value += max_cap_used ? ((pnl_value / max_cap_used) * 100) : 0
      // return_value += ((pnl_value / max_cap_used) * 100)

      final_pnl += parseFloat(pnl_value)
      sym_max_cap_used += max_cap_used
    })
    return_value = symCount === 0 ? return_value : return_value / symCount
    this.avgBrokerageData = {
      final_pnl,
      return: return_value,
      absolute_pnl_pct: sym_max_cap_used === 0 ? 0 : (final_pnl / sym_max_cap_used) * 100,
    }
  }

  toggleAvgBrokerage = (enabled) => {
    const { isRunningBacktests, updateGA, gaCategory } = this.props
    if (isRunningBacktests) {
      showSnackbar('Backtest still in progress')
      return
    }
    const { avg_brokerage_enabled } = this.state
    const { final_pnl = 0.0, absolute_pnl_pct: final_return = 0.0 } = this.avgData
    let finalPnl = final_pnl
    let returnValue = final_return
    if (!avg_brokerage_enabled) {
      if (isEmpty(this.avgBrokerageData)) {
        this.setState({ isCalculationBrokerage: true })
        this.calcAvgBrokerage()
      }
      const { final_pnl: pnl = 0.0, absolute_pnl_pct: return_value = 0.0 } = this.avgBrokerageData
      finalPnl = pnl
      returnValue = return_value
    }
    this.setState({
      avg_brokerage_enabled: enabled, finalPnl, returnValue, isCalculationBrokerage: false,
    }, () => {
      updateGA('Brokerage', 'Cumulative', enabled ? 1 : 0, gaCategory)
    })
  }

  renderCell = (label, value) => {
    const { styles, isMobile } = this.props
    return (
      <div className={styles.cell} style={{ width: isMobile ? '50%' : 180 }}>
        <CustomText weight="medium">{value}</CustomText>
        <CustomText size="small" color="textSecondary">{label}</CustomText>
      </div>
    )
  }

  render() {
    const {
      styles, user_details, updateWebSiteConfig, pinCumulativePnl, isFetchingData,
      isRunningBacktests, fetchingAllDataError, isMobile,
      algoIdKey, updateGA, hide,
    } = this.props
    const {
      avg_brokerage_enabled, finalPnl, returnValue, isCalculationBrokerage,
      isFetching,
    } = this.state
    let isFetchingBacktests
    if(algoIdKey === ALGO_KEY_MAP.DISCOVER) {
      isFetchingBacktests = false
    } else {
      isFetchingBacktests = isFetchingData
    }

    if(hide) {
      return null
    }

    if (isFetchingBacktests || isRunningBacktests || isCalculationBrokerage || isFetching) {
      return <CummulativePnlLoader styles={styles} />
    }

    if (fetchingAllDataError) {
      return (
        <div className={styles.container}>
          <CustomText center weight="medium" className={styles.errorText}>Error calculating cumulative pnl</CustomText>
        </div>
      )
    }

    const showBrokeageToggle = userAccessCheck({
      condition: accessConditions.SHOW_BROKERAGE_TOGGLE, user_details,
    })
    // const precision = PRECISION
    const {
      win_count, loss_count, total_number_of_signals, winning_streak,
      lossing_streak, max_draw,
    } = this.avgData
    const classColor = finalPnl >= 0 ? 'up' : 'down'
    const disp_ret = +returnValue >= 0 ? `(+${(+returnValue).toFixed(2)} %)` : `(${(+returnValue).toFixed(2)} %)`

    const cellFlex = 1
    const max_dd = max_draw || typeof max_draw === 'number' ? Number(max_draw).toFixed(4) : ''
    const onUpdateGA = () => {
      updateGA('Highlight Click', 'Bt Pg 3', 1, 'Product Tour')
    }
    return (
      <div
        role="button"
        className={styles.container}
        id="tour_backtest_3"
        onClick={onUpdateGA}
        tabIndex={0}
        onKeyUp={onUpdateGA}
      >
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <Icon
              name={ICONS.CUMULATIVE_ICON}
              size={14}
              style={{ marginRight: SPACING.SPACE_8 }}
              color={theme.orange}
            />
            <CustomText weight="semi_bold" size="medium">Cumulative results</CustomText>
          </div>
          <div className={styles.headerRight}>
            <CustomText size={isMobile ? 'small_0' : 'regular'} weight="medium">
              Backtest P&L
              <span className={styles[classColor]}>{`${Number(finalPnl.toFixed(2)).toLocaleString('en-IN')} ${disp_ret}`}</span>
            </CustomText>
            <div className={styles.headerRightRow}>
              {showBrokeageToggle
                ? (
                  <div className={styles.brokerage}>
                    <CustomText size="small" weight="medium" color="linkColor" className={styles.brokerageText}>Brokerage</CustomText>
                    <Switch
                      value={avg_brokerage_enabled}
                      onValueChange={this.toggleAvgBrokerage}
                      id="backtest_avg"
                    />
                  </div>
                )
                : null}
              <button
                type="button"
                onClick={updateWebSiteConfig}
                className={styles.pinBtn}
              >
                <Icon name={ICONS.PIN} color={theme[pinCumulativePnl ? 'link' : 'text']} size={14} />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {this.renderCell('Total number of signals', total_number_of_signals, cellFlex)}
          {this.renderCell('Number of wins', win_count, cellFlex)}
          {this.renderCell('Number of losses', loss_count, cellFlex)}
          {this.renderCell('Winning streak', winning_streak)}
          {this.renderCell('Losing streak', lossing_streak)}
          {/* {this.renderCell('Max gains', maximum_gain.toFixed(precision))}
          {this.renderCell('Max loss', maximum_loss.toFixed(precision), cellFlex)}
          {this.renderCell('Avg gain/winning trade',
          average_gain_per_winning_trade.toFixed(precision))}
          {this.renderCell('Avg loss/losing trade',
          average_gain_per_losing_trade.toFixed(precision), cellFlex)} */}
          {this.renderCell('Max DD', max_dd)}
        </div>
      </div>
    )
  }
}

const stylesheet = ({
  container: {
    position: 'relative',
    borderRadius: 8,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20} ${SPACING.SPACE_10}`,
    backgroundColor: theme.bgPrimary,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    margin: `${SPACING.SPACE_20} 0`,
  },
  row: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 0.5,
  },
  cell: {
    padding: SPACING.SPACE_10,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: SPACING.SPACE_10,
    borderRight: `1px solid ${theme.borderColor}`,
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: SPACING.SPACE_10,
    flex: 1,
  },
  up: {
    marginLeft: SPACING.SPACE_8,
    fontSize: FONTS.MEDIUM,
    color: `${COLORS.GREEN} !important`,
  },
  down: {
    marginLeft: SPACING.SPACE_8,
    fontSize: FONTS.MEDIUM,
    color: `${COLORS.RED} !important`,
  },
  brokerage: {
    display: 'flex',
  },
  headerRightRow: {
    display: 'flex',
  },
  pinBtn: {
    marginLeft: SPACING.SPACE_20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_10}`,
  },
  cellLoader: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelLoader: {
    maxWidth: 80,
    height: 12,
  },
  valueLoader: {
    maxWidth: 120,
    height: 12,
    marginTop: SPACING.SPACE_10,
  },
  errorText: {
    minHeight: 200,
  },
  '@media only screen and (max-width: 786px)': {
    up: {
      fontSize: FONTS.REGULAR,
    },
    down: {
      fontSize: FONTS.REGULAR,
    },
    headerRight: {
      gap: SPACING.SPACE_10,
      marginLeft: 0,
      width: '100%',
      marginTop: SPACING.SPACE_10,
    },
    header: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    pinBtn: {
      position: 'absolute',
      right: SPACING.SPACE_20,
      top: 26,
    },
    headerLeft: {
      borderRight: 0,
    },
  },
})

const mapStateToProps = (state) => {
  return {
    data: state.backtests.backtestDetails,

    isSavingBacktestParams: state.backtests.isSavingBacktestParams,
  }
}

export default connect(mapStateToProps, null)((withTheme(stylesheet)(CummulativePnl)))
