import React from 'react'

import { ASSETS } from '../Theme'

const DotLoader = React.memo(({ type, props = {} }) => {
  return (
    <img src={type === 'white' ? ASSETS.DotLoaderWhite : ASSETS.DotLoaderBlue} alt="...loading" {...props} />
  )
})

export default DotLoader
