// import ReactDOM from 'react-dom'
import React, { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'

import Icon from '../../UI/Icon'
import CustomText from '../../UI/CustomText'

import { candle_map } from '../../utils/consts'
import {
  ICONS, theme, SPACING, COLORS,
} from '../../Theme'
// import { useTheme } from '../../Theme/ThemeProvider'

const CandleIntervalView = ({
  tabs,
  defaultActiveTab,
  tabChangeHandler,
  style,
  labelKey,
  valueKey,
  extraStyles = {},
  changeKey,
  dataFromCandleInterval = () => null,
  isDark,
}) => {
  const styles = stylesheet()
  const [visible, showDropdown] = useState(false)
  const [layoutDim, updateDim] = useState({ opacity: 0 })
  const btnRef = useRef(null)
  useEffect(() => {
    if (visible) {
      if (btnRef.current) {
        const { top, height, x } = btnRef.current.getBoundingClientRect()
        updateDim({ opacity: 1, top: height + top, left: x })
      }
    } else {
      updateDim({ opacity: 0 })
    }
  }, [visible])

  const onTabChange = (e, value, item, index, active) => {
    e.stopPropagation()
    e.preventDefault()
    if (active && tabs.length === 2) {
      const newIndex = index === 1 ? 0 : 1
      const newItem = tabs[newIndex]
      let newValue = newItem
      if (typeof newItem === 'object') {
        newValue = valueKey ? newItem[valueKey] : newItem.value
      }
      tabChangeHandler(newValue, item, changeKey)
      // debouncedHandler(e, newValue, newItem)
      return
    }
    showDropdown(false)
    // toggleTab(value)
    tabChangeHandler(value, item, changeKey)
  }
  const toggleDropdown = () => {
    showDropdown(!visible)
  }
  const dropDownIcon = visible ? 'UP_HEAD_FILLED' : 'DOWN_HEAD_FILLED'

  dataFromCandleInterval(visible)

  return (
    <div className={classnames(styles.tabContainer, style)} style={extraStyles}>
      {visible && (
      <button type="button" onClick={() => showDropdown(false)}>
        <div className={styles.ciBackdrop} />
      </button>
      )}
      <button
        type="button"
        onClick={toggleDropdown}
        ref={btnRef}
        className={classnames(styles.inputBtn, visible ? styles.inputBtnSelected : '')}
      >
        <CustomText className={classnames(styles.input)} color="white">
          {candle_map[defaultActiveTab] || defaultActiveTab}
        </CustomText>
        <Icon
          className={styles.dropDownIcon}
          name={ICONS[dropDownIcon]}
          color={COLORS.WHITE}
          size={10}
        />
      </button>
      {/* {visible && ReactDOM.createPortal(( */}
      {visible
        && (
        <div className={styles.ciDropdown} style={layoutDim}>
          {tabs.map((item, index) => {
            let label = item
            let value = item
            let shortLabel = item
            if (typeof item === 'object') {
              ({ shortLabel } = item)
              label = labelKey ? item[labelKey] : item.label
              value = valueKey ? item[valueKey] : item.value
            }
            const active = defaultActiveTab === value
            return (
              <button
                key={value}
                id={value}
                name={value}
                type="button"
                onClick={(e) => { onTabChange(e, value, item, index, active) }}
                className={classnames(styles.tabBtn, active ? styles.tabBtnActive : '')}
                disabled={active}
              >
                <CustomText
                  className={styles.btnText}
                  center
                  weight={isDark ? 'regular' : 'medium'}
                  style={{ whiteSpace: 'nowrap', opacity: (isDark && !active) ? 0.6 : 1 }}
                  color={active ? 'white' : isDark ? 'voilet_600' : 'voilet'}
                >
                  {active || !shortLabel ? label : shortLabel}
                </CustomText>
              </button>
            )
          })}
        </div>
        )}
      {/* ), document.getElementById('portalWindow'))} */}
    </div>
  )
}

const stylesheet = makeStyles({
  container: {},
  tabContainer: {
    display: 'flex',
    borderRadius: 10,
    flexDirection: 'column',
    // backgroundColor: 'transparent',
    // position: 'relative',
  },
  ciBackdrop: {
    // position: 'fixed',
    width: '100%',
    height: '100%',
    // top: 0,
    cursor: 'default',
    // left: 0,
  },
  inputBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: COLORS.VOILET,
    borderRadius: 10,
    maxWidth: 150,
  },
  input: {},
  dropDownIcon: {},
  tabBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_12}`,
    flex: 1,
    position: 'relative',
    cursor: 'pointer',
    borderRadius: 11,
    '&:hover': {
      backgroundColor: COLORS.VOILET_100,
    },
  },
  tabBtnActive: {
    backgroundColor: `${COLORS.VOILET} !important`,
  },
  tabContent: {
    display: 'flex',
    alignItems: 'center',
  },
  ciDropdown: {
    display: 'flex',
    // top: 0,
    backgroundColor: theme.editParamsIntervalBg,
    // position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'center',
    zIndex: '9999',
    borderRadius: 8,
    width: 213,
    padding: SPACING.SPACE_6,
  },
})

export default CandleIntervalView
