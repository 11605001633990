import { takeLatest, put, call } from 'redux-saga/effects'
import { prodEndpoints, baseUrl, openUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  fetchPositionsSuccess,
  fetchPositionsFailure,
  fetchHoldingsSuccess,
  fetchHoldingsFailure,
  fetchSpecificSuccess,
  fetchSpecificFailure,
  getLiveAlgoSuccess,
  getLiveAlgoFailure,
  exitPositionSuccess,
  exitPositionFailure,
} from './actions'
import {
  FETCH_POSITIONS_INIT,
  FETCH_HOLDINGS_INIT,
  FETCH_SPECIFIC_INIT,
  GET_LIVE_ALGO_INIT,
  EXIT_POSITION_INIT,
} from './actionTypes'
import { fetchPortfolioInstruments, getInstruments } from '../Instruments/actions'
import { error_msg } from '../../utils/common'

function* fetchPositions(action) {
  try {
    const { headers, params, screenName } = action
    const url = `${baseUrl}${prodEndpoints.fetch_positions}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchPositionsSuccess(data))
      yield put(getInstruments(
        fetchPortfolioInstruments(null, data.positions),
        {},
        screenName,
      ))
    } else {
      yield put(fetchPositionsFailure(data.error))
    }
  } catch(err) {
    yield put(fetchPositionsFailure(err.message || error_msg))
  }
}

export function* fetchPositionsSaga() {
  yield takeLatest(FETCH_POSITIONS_INIT, fetchPositions)
}

function* fetchHoldings(action) {
  try {
    const { headers, params, screenName } = action
    const url = `${baseUrl}${prodEndpoints.fetch_holdings}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchHoldingsSuccess(data))
      yield put(getInstruments(
        fetchPortfolioInstruments(null, data.positions),
        {},
        screenName,
      ))
    } else {
      yield put(fetchHoldingsFailure(data.error))
    }
  } catch(err) {
    yield put(fetchHoldingsFailure(err.message || error_msg))
  }
}

export function* fetchHoldingsSaga() {
  yield takeLatest(FETCH_HOLDINGS_INIT, fetchHoldings)
}

function* fetchSpecific(action) {
  try {
    const { headers, params } = action
    const { platform = 'streak' } = params
    let url = `${baseUrl}${prodEndpoints.fetch_specific_position}`
    if(platform === 'openstreak') {
      url = `${openUrl}${prodEndpoints.fetch_specific_position}`
    }
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(fetchSpecificSuccess(data))
    } else {
      yield put(fetchSpecificFailure(data.error))
    }
  } catch(err) {
    yield put(fetchSpecificFailure(err.message || error_msg))
  }
}

export function* fetchSpecificSaga() {
  yield takeLatest(FETCH_SPECIFIC_INIT, fetchSpecific)
}

function* getLiveAlgo(action) {
  try {
    const { headers, params } = action
    const url = `${baseUrl}${prodEndpoints.get_live_algos}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(getLiveAlgoSuccess(data))
    } else {
      yield put(getLiveAlgoFailure(data.error))
    }
  } catch(err) {
    yield put(getLiveAlgoFailure(err.message || error_msg))
  }
}

export function* getLiveAlgoSaga() {
  yield takeLatest(GET_LIVE_ALGO_INIT, getLiveAlgo)
}

function* exitPosition(action) {
  try {
    const { headers, params } = action
    const { platform = 'streak' } = params
    let coreUrl = baseUrl
    if(platform === 'openstreak') {
      coreUrl = 'https://api-op.streak.tech/'
    }
    const url = `${coreUrl}${prodEndpoints.exit_all}`
    const data = yield call(postRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(exitPositionSuccess(data))
    } else {
      yield put(exitPositionFailure(data.error_msg))
    }
  } catch(err) {
    yield put(exitPositionFailure(err.message || error_msg))
  }
}

export function* exitPositionSaga() {
  yield takeLatest(EXIT_POSITION_INIT, exitPosition)
}
