import { takeLatest, put, call } from 'redux-saga/effects'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  fetchOrdersSuccess,
  fetchOrdersFailure,
  cancelOrderSuccess,
  cancelOrderFailure,
} from './actions'
import {
  FETCH_ORDERS_INIT,
  CANCEL_ORDER_INIT,
} from './actionTypes'

import { error_msg } from '../../utils/common'
import { fetchOrderbookInstruments, getInstruments } from '../Instruments/actions'

function* fetchOrders(action) {
  try {
    const { fetchType: type, screenName } = action
    let endPoint = `${baseUrl}${prodEndpoints.fetch_order_book}?platform=`
    if (type === 'All') {
      endPoint += 'All'
    } else {
      endPoint += 'Streak'
    }
    const data = yield call(getRequest, endPoint, {})
    if (!data.error && data.status === 'success') {
      yield put(fetchOrdersSuccess(data))
      yield put(getInstruments(
        fetchOrderbookInstruments(null, data.pending),
        {},
        screenName,
      ))
    } else {
      yield put(fetchOrdersFailure(data.error))
    }
  } catch(err) {
    yield put(fetchOrdersFailure(err.message || error_msg))
  }
}

export function* fetchOrdersSaga() {
  yield takeLatest(FETCH_ORDERS_INIT, fetchOrders)
}

function* cancelOrder(action) {
  try {
    const { params } = action
    const url = prodEndpoints.cancel_order_click
    const data = yield call(postRequest, url, params)
    if (!data.error && data.status === 'success') {
      yield put(cancelOrderSuccess(data))
    } else {
      yield put(cancelOrderFailure(data.error))
    }
  } catch(err) {
    yield put(cancelOrderFailure(err.message || error_msg))
  }
}

export function* cancelOrderSaga() {
  yield takeLatest(CANCEL_ORDER_INIT, cancelOrder)
}
