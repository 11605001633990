// fetch deployed/waiting algo stocks
export const ACTIVE_ALGOS_STOCK_INIT = 'ACTIVE_ALGOS_STOCK_INIT'
export const ACTIVE_ALGOS_STOCK_SUCCESS = 'ACTIVE_ALGOS_STOCK_SUCCESS'
export const ACTIVE_ALGOS_STOCK_FAILURE = 'ACTIVE_ALGOS_STOCK_FAILURE'

// fetch stopped algo stocks
export const STOPPED_ALGOS_INIT = 'STOPPED_ALGOS_INIT'
export const STOPPED_ALGOS_SUCCESS = 'STOPPED_ALGOS_SUCCESS'
export const STOPPED_ALGOS_FAILURE = 'STOPPED_ALGOS_FAILURE'

// fetch open positions
export const FETCH_OPEN_POSITIONS_INIT = 'FETCH_OPEN_POSITIONS_INIT'
export const FETCH_OPEN_POSITIONS_SUCCESS = 'FETCH_OPEN_POSITIONS_SUCCESS'
export const FETCH_OPEN_POSITIONS_FAILURE = 'FETCH_OPEN_POSITIONS_FAILURE'

// stop algo and? sell stocks
export const STOP_ALGO_INIT = 'STOP_ALGO_INIT'
export const STOP_ALGO_SUCCESS = 'STOP_ALGO_SUCCESS'
export const STOP_ALGO_FAILURE = 'STOP_ALGO_FAILURE'

export const CLEAR_STOP_ALGO_RESP = 'CLEAR_STOP_ALGO_RESP'

export const CLEAR_OPEN_POSITIONS_RESP = 'CLEAR_OPEN_POSITIONS_RESP'

export const REDEPLOY_INIT = 'REDEPLOY_INIT'
export const REDEPLOY_SUCCESS = 'REDEPLOY_SUCCESS'
export const REDEPLOY_FAILURE = 'REDEPLOY_FAILURE'

export const ORDER_DETAILS_INIT = 'ORDER_DETAILS_INIT'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE'

export const STOP_WAITING_ALGOS_INIT = 'STOP_WAITING_ALGOS_INIT'
export const STOP_WAITING_ALGOS_SUCCESS = 'STOP_WAITING_ALGOS_SUCCESS'
export const STOP_WAITING_ALGOS_FAILURE = 'STOP_WAITING_ALGOS_FAILURE'

export const DEPLOYED_COUNT_INIT = 'DEPLOYED_COUNT_INIT'
export const DEPLOYED_COUNT_SUCCESS = 'DEPLOYED_COUNT_SUCCESS'
export const DEPLOYED_COUNT_FAILURE = 'DEPLOYED_COUNT_FAILURE'
