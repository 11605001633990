import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'

import Button from '../../UI/Button'
import IndicatorDetails from '../Create/IndicatorDetails'
import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import Expandable from '../../UI/Expandable'

import { dynamicScripParams, getDynamicSymbol } from '../../containers/Create/Create-utils'
import { showSnackbar } from '../../UI/Snackbar'
import { ICONS, SPACING, theme } from '../../Theme'

// default contract
const getNew = (contractParams, seg_sym) => {
  const data = {}
  contractParams.indicator.params.forEach((param) => { data[param[0]] = param[1] })
  const symbol = getDynamicSymbol(data, seg_sym)
  return {
    segment: '',
    symbol,
    details: { ...data, seg_sym },
    seg_sym: `_${symbol}`,
    new: true,
  }
}
// no of duplicate of default contract
let duplicateCount = 0
// used in parent component to know whether we have deleted the contracts from
// list or this.pairsToupdate
let deletedContracts = []
let dycContracts = new Set() // set of all unique contracts

const MultiDycParams = ({
  list, selectedPair, selectedScrip, saveParamsDetails,
}) => {
  const styles = stylesheet()

  const { seg_sym } = selectedScrip
  const [activeContracts, updateContracts] = useState([])
  const [expanded, toggleExpanded] = useState('')
  const contractParams = dynamicScripParams(seg_sym.split('_')[0])
  const newContract = getNew(contractParams, seg_sym)
  useEffect(() => {
    const modContracts = []
    dycContracts = new Set()
    list.forEach((item, index) => {
      if (item.seg_sym && item.details && item.details.seg_sym === seg_sym) {
        modContracts.push({ ...item, listIndex: index })
        dycContracts.add(item.seg_sym)
      }
    })
    selectedPair.forEach((item, index) => {
      if (item.seg_sym && item.details && item.details.seg_sym === seg_sym) {
        modContracts.push({ ...item, new: true, listIndex: index })
        dycContracts.add(item.seg_sym)
      }
    })
    if (modContracts.length === 0) {
      modContracts.push({ ...newContract })
      dycContracts.add(newContract.seg_sym)
    }
    updateContracts(modContracts)
    toggleExpanded(`${modContracts[0].seg_sym}_0`)
    duplicateCount = 0
    deletedContracts = []
  }, [])
  const addNew = () => {
    // if (dycContracts.has(newContract.seg_sym)) {
    //   showSnackbar(`${newContract.seg_sym.replace('_', '')} already exist`)
    //   return
    // }
    const duplicate = dycContracts.has(newContract.seg_sym)
    duplicateCount = duplicate ? duplicateCount + 1 : duplicateCount
    dycContracts.add(newContract.seg_sym)
    updateContracts([{ ...newContract, duplicate }, ...activeContracts])
    toggleExpanded(`${newContract.seg_sym}_0`)
  }
  const updateDetails = (data, i, { contract, index }) => {
    const detailsData = data
    const contractData = contract
    if (data.offset && Number.isNaN(Math.abs(data.offset))) {
      return
    }
    if(data.contract_type === 'FUT') {
      delete detailsData.offset
      delete detailsData.change_frequency
      delete contractData.details.offset
      delete contractData.details.change_frequency
    }
    const symbol = getDynamicSymbol(data, seg_sym)
    if (contract.disableEdit) {
      return
    }
    if (dycContracts.has(`_${symbol}`)) { // `_${symbol}` !== contract.seg_sym &&
      showSnackbar(`${symbol} already exist`)
      return
    }
    const modContracts = [...activeContracts]
    modContracts[index] = {
      ...contractData,
      segment: '',
      symbol,
      details: { ...detailsData, seg_sym },
      seg_sym: `_${symbol}`,
      duplicate: false,
    }
    if (activeContracts.length === 1) {
      dycContracts.delete(contract.seg_sym)
    } else if (contract.seg_sym === newContract.seg_sym) {
      if (duplicateCount <= 0) {
        dycContracts.delete(contract.seg_sym)
      }
      if (duplicateCount > 0) duplicateCount -= 1
    } else {
      dycContracts.delete(contract.seg_sym)
    }
    dycContracts.add(`_${symbol}`)
    updateContracts(modContracts)
    toggleExpanded(`_${symbol}_${index}`)
  }
  // console.log(activeContracts, dycContracts, duplicateCount, 'lol')
  const saveHandler = () => {
    const finalContracts = []
    let defaultExist = false
    if (dycContracts.size === 0 && activeContracts.length === 1) {
      finalContracts.push(activeContracts[0])
    } else {
      Array.from(dycContracts).forEach((item, i) => {
        if (activeContracts[i]) {
          if (item === newContract.seg_sym) {
            let contractToAdd = null
            if (!defaultExist) {
              // we are doing this as there can be multiple default contracts
              // but we shud first pick an old default contract
              // if that does not exist than we add the newly added default contract
              if (activeContracts[i].seg_sym === item
                && !activeContracts[i].duplicate) {
                defaultExist = true
                finalContracts.push(activeContracts[i])
              } else {
                for (let j = 0; j < activeContracts.length; j++) {
                  if (activeContracts[j].seg_sym === item) {
                    contractToAdd = activeContracts[j]
                    if (!activeContracts[j].duplicate) {
                      defaultExist = true
                      finalContracts.push(activeContracts[j])
                      break
                    }
                  }
                }
              }
            }
            // default contract is added newly so add the default contract
            if (!defaultExist && contractToAdd) {
              defaultExist = true
              finalContracts.push(contractToAdd)
            }
          } else if (activeContracts[i].seg_sym === item && !activeContracts[i].duplicate) {
            finalContracts.push(activeContracts[i])
          } else {
            for (let j = 0; j < activeContracts.length; j++) {
              if (activeContracts[j].seg_sym === item && !activeContracts[j].duplicate) {
                finalContracts.push(activeContracts[j])
                break
              }
            }
          }
        } else {
        // do not remove
          console.log(dycContracts, activeContracts, 'dyc error')
        }
      })
    }
    saveParamsDetails(finalContracts, deletedContracts)
  }

  const deleteContract = (contract, index) => {
    const { duplicate, listIndex } = contract
    if (listIndex !== undefined) {
      deletedContracts.push(contract)
      dycContracts.delete(contract.seg_sym)
    } else if (duplicate && duplicateCount > 0) {
      duplicateCount -= 1
    } else {
      dycContracts.delete(contract.seg_sym)
    }
    const modContract = [...activeContracts]
    modContract.splice(index, 1)
    updateContracts(modContract)
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <CustomText size="small" className={styles.dynamicTitle}>
            Edit dynamic contract
          </CustomText>
          <CustomText weight="medium" size="large" className={styles.dynamicTitle}>
            {seg_sym ? seg_sym.split('_').join(' ') : ''}
          </CustomText>
        </div>
        {seg_sym && (
        <Button
          text="Add New"
          onPress={addNew}
          btnStyles={styles.addBtn}
          labelColor="orange"
          transparent
          labelSize="small"
          mode="text"
          iconName="ADD"
          iconProps={{
            color: theme.orange,
            size: 10,
          }}
        />
        )}
      </div>
      {activeContracts.map((contract, index) => {
        const showDelete = (!contract.disableEdit && activeContracts.length > 1)
        return (
        // eslint-disable-next-line react/no-array-index-key
          <div className={styles.expandableWrapper} key={`${contract.seg_sym}_${index}`}>
            {showDelete && (
            <button
              type="button"
              onClick={() => deleteContract(contract, index)}
              className={styles.deleteBtn}
            >
              <Icon name={ICONS.DELETE} size={12} color={theme.red} />
            </button>
            )}
            <Expandable
              title={contract.seg_sym.slice(1, contract.seg_sym.length)}
              defaultExpanded={expanded === `${contract.seg_sym}_${index}` && !contract.disableEdit}
              onExpandCallback={toggleExpanded}
              expandKey={`${contract.seg_sym}_${index}`}
              containerStyles={styles.expandable}
              titleProps={{ style: { textAlign: 'left' } }}
              disabled={contract.disableEdit}
              headerContainerStyles={styles.expandableHeader}
            >
              <IndicatorDetails
                saveDetails={updateDetails}
                btnText="Save"
                showRemove={false}
                popupClass={styles.detailsContainer}
                autoUpdate
                {...contractParams}
                extraParams={{ contract, index }}
                indicatorDetails={contract.details}
              />
            </Expandable>
          </div>
        )
      })}
      <div className={styles.actionBar}>
        <Button
          buttonColor="blue"
          btnStyles={styles.saveBtn}
          onPress={saveHandler}
          text="Apply Changes"
        />
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  container: {},
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  expandableHeader: {
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_12} 0px 0`,
  },
  expandable: {
    boxShadow: 'none !important',
    marginBottom: SPACING.SPACE_20,
  },
  expandableWrapper: {
    position: 'relative',
    '&:hover > $deleteBtn': {
      visibility: 'visible',
    },
  },
  deleteBtn: {
    position: 'absolute',
    right: 38,
    top: 20,
    zIndex: 999,
    visibility: 'hidden',
  },
  actionBar: {
    textAlign: 'right',
  },
  saveBtn: {
    width: '100%',
    maxWidth: 200,
    margin: `${SPACING.SPACE_8} ${SPACING.SPACE_10} ${SPACING.SPACE_10}`,
  },
  detailsContainer: {
    margin: 0,
  },
  addBtn: {
    padding: `${SPACING.SPACE_2} ${SPACING.SPACE_10}`,
    borderRadius: 6,
    borderColor: theme.orange,
    display: 'flex',
    alignItems: 'center',
    borderWidth: 2,
    borderStyle: 'dashed',
    '&:hover': {
      backgroundColor: theme.orangeLightBg,
    },
  },
})

export default MultiDycParams
