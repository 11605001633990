import React from 'react'

import CustomText from '../../UI/CustomText'
import ListDropdown from '../../UI/Dropdown/ListDropdown'

import { SPACING } from '../../Theme'
import { showSnackbar } from '../../UI/Snackbar'

const labelRender = (optionObj, label, selected) => {
  const { segment, symbol } = optionObj
  return (
    <CustomText
      size="small"
      style={{
        paddingHorizontal: SPACING.SPACE_12,
        paddingVertical: SPACING.SPACE_8,
      }}
      color={selected ? 'linkColor' : 'text'}
    >
      {`${segment}_${symbol}`}
    </CustomText>
  )
}

const AutoComplete = ({
  onSearch,
  options,
  onSelect,
  searchValue,
  defaultLabel,
  selected,
  placeholder,
  inputStyles,
  blockedSegmentList,
  itemBtnStyles,
  toggleCallback,
}) => {
  const onChangeOption = (name, item = {}) => {
    const { segment = '' } = item
    if (segment && blockedSegmentList.includes(segment.toLowerCase())) {
      showSnackbar(`Upgrade to ultimate plan to use ${segment.toUpperCase()}`)
      return
    }
    onSelect(name, item)
  }
  return (
    <ListDropdown
      options={options}
      selected={selected && selected.split('_')[1] ? selected.split('_')[1] : ''}
      defaultLabel={defaultLabel}
      // searchValue={searchValue}
      placeholder={placeholder}
      onChangeOption={onChangeOption}
      showDropDownIcon={false}
      labelKey="symbol"
      valueKey="symbol"
      searchProps={{
        onChange: onSearch,
        value: searchValue,
      }}
      inputBtnStyles={inputStyles}
      itemBtnStyles={itemBtnStyles}
      labelRender={labelRender}
      minWidth={200}
      toggleCallback={toggleCallback}
      showKeyNav
    />
  )
}

export default AutoComplete
