import { takeLatest, put, call } from 'redux-saga/effects'
import {
  getIndicatorsSuccess,
  getIndicatorsFailure,
  deployAlgoSuccess,
  deployAlgoFailure,
  getAlgoStateSuccess,
  getAlgoStateFailure,
  getTrainerModelSuccess,
  getTrainerModelFailure,
  copyAlgoFailure,
  copyAlgoSuccess,
  getStocksSuccess,
  getStocksFailure,
  updatePrefsSuccess,
  updatePrefsFailure,
  getDefaultStocksSuccess,
  getDefaultStocksFailure,
  getAlgoFromTokenSuccess,
  getAlgoFromTokenFailure,
} from './actions'
import { getRequest, postRequest } from '../../utils/apiRequests'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import {
  GET_INDICATORS_INIT,
  GET_ALGO_STATE_INIT,
  GET_TRAINER_MODEL_INIT,
  COPY_ALGO,
  GET_STOCKS_INIT,
  UPDATE_PREFS_INIT,
  DEPLOY_ALGO_INIT,
  GET_DEFAULT_STOCKS,
  GET_ALGO_FROM_TOKEN,
} from './actionTypes'
import { refreshAlgos } from '../Common/actions'
import { error_msg } from '../../utils/common'

function* getStocks(action) {
  try {
    const {
      searchQuey,
      headers,
      user_uuid,
      baskets,
      exchanges,
    } = action
    const url = prodEndpoints.instrument_search
    const include_baskets = baskets
    const params = {
      limit: 10,
      search: searchQuey,
      search_fields: ['name', 'symbol', 'segment', 'from', 'to'],
      return_fields: ['segment', 'symbol', 'name'],
      // instrument_types: ['EQ', 'FUT'],
      exchanges,
      include_baskets,
      user_uuid,
    }
    const data = yield call(postRequest, url, JSON.stringify(params), headers, false)
    if (!data.error) {
      yield put(getStocksSuccess(data))
    } else {
      yield put(getStocksFailure(data.error_msg))
    }
  } catch (error) {
    yield put(getStocksFailure(error.message))
  }
}

export function* getStocksSaga() {
  yield takeLatest(GET_STOCKS_INIT, getStocks)
}

function* updatePrefs(action) {
  try {
    const { params, headers } = action
    const data = yield call(
      postRequest,
      '/update_preference/',
      params,
      headers,
    )
    if (data) {
      yield put(updatePrefsSuccess(data))
    } else {
      yield put(updatePrefsFailure(data.error))
    }
  } catch (error) {
    yield put(updatePrefsFailure(error))
  }
}

export function* updatePrefsSaga() {
  yield takeLatest(UPDATE_PREFS_INIT, updatePrefs)
}

function* deployAlgo(action) {
  try {
    const { params, headers } = action
    const url = `${baseUrl}submit_algorithm/`
    const data = yield call(postRequest, url, params, headers)
    if (data.status !== 'success') {
      yield put(deployAlgoFailure(data.error_msg))
    } else {
      yield put(deployAlgoSuccess(data))
      if (!params.algo_uuid) {
        yield put(refreshAlgos('algos', true))
      }
    }
  } catch (error) {
    yield put(deployAlgoFailure(error))
  }
}

export function* deployAlgoSaga() {
  yield takeLatest(DEPLOY_ALGO_INIT, deployAlgo)
}

function* getAlgoState(action) {
  try {
    const { algo_uuid, headers } = action
    const url = `${baseUrl}${prodEndpoints.getAlgoState}?algo_uuid=${algo_uuid}`
    const data = yield call(getRequest, url, {}, headers)
    if (data.status === 'success') {
      yield put(getAlgoStateSuccess(data))
    } else {
      yield put(getAlgoStateFailure(data.error))
    }
  } catch (error) {
    yield put(getAlgoStateFailure(error))
  }
}

export function* getAlgoStateSaga() {
  yield takeLatest(GET_ALGO_STATE_INIT, getAlgoState)
}

function* getIndicators(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.indicators}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error) {
      yield put(getIndicatorsSuccess(data))
    } else {
      yield put(getIndicatorsFailure(data.error))
    }
  } catch (error) {
    yield put(getIndicatorsFailure(error.messaage))
  }
}

export function* getIndicatorsSaga() {
  yield takeLatest(GET_INDICATORS_INIT, getIndicators)
}

function* getTrainerModel(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${prodEndpoints.trainer_model}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error) {
      yield put(getTrainerModelSuccess(data))
    } else {
      yield put(getTrainerModelFailure(data.error))
    }
  } catch (error) {
    yield put(getTrainerModelFailure(error))
  }
}

export function* getTrainerModelSaga() {
  yield takeLatest(GET_TRAINER_MODEL_INIT, getTrainerModel)
}

function* copyAlgo(action) {
  try {
    const { params, headers } = action
    let url = `${baseUrl}${prodEndpoints.copy_algorithm}`
    if(params.backtest_share_uuid || params.algo_share_uuid) {
      url = `${baseUrl}${prodEndpoints.clone_shared}`
    }

    const data = yield call(postRequest, url, params, headers)
    if (data.status === 'success' && !data.error) {
      yield put(copyAlgoSuccess(data))
    } else {
      yield put(copyAlgoFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(copyAlgoFailure(error_msg))
  }
}

export function* copyAlgoSaga() {
  yield takeLatest(COPY_ALGO, copyAlgo)
}

function* getDefaultStocks(action) {
  try {
    const { params } = action
    const url = prodEndpoints.instrument_search_discover
    const data = yield call(postRequest, url, JSON.stringify(params), { 'content-type': 'application/json' }, false)
    if (!data.error) {
      yield put(getDefaultStocksSuccess(data.data))
    } else {
      yield put(getDefaultStocksFailure(data.error_msg))
    }
  } catch (error) {
    yield put(getDefaultStocksFailure(error.message))
  }
}

export function* getDefaultStocksSaga() {
  yield takeLatest(GET_DEFAULT_STOCKS, getDefaultStocks)
}

function* getAlgoFromToken(action) {
  try {
    const { params, headers } = action
    const url = prodEndpoints.fetch_ctb_deploy_algo
    const data = yield call(getRequest, url, params, headers)
    if (!data.error) {
      yield put(getAlgoFromTokenSuccess(data.data))
    } else {
      yield put(getAlgoFromTokenFailure(data.error_msg))
    }
  } catch (error) {
    yield put(getAlgoFromTokenFailure(error.message))
  }
}

export function* getAlgoFromTokenSaga() {
  yield takeLatest(GET_ALGO_FROM_TOKEN, getAlgoFromToken)
}
