/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable semi-spacing */
import React, { PureComponent } from 'react'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'

import Icon from '../../UI/Icon'
import CustomText from '../../UI/CustomText'
import SegSym from '../../UI/SegSym'
import StockIcon from '../../UI/StockIcon'
import CustomInput from '../../UI/CustomInput'
import CustomModal from '../../UI/CustomModal'
import Chip from '../../UI/Chip'
import Button from '../../UI/Button'
import { showSnackbar } from '../../UI/Snackbar'
import DotLoader from '../../UI/DotLoader'
// import IndicatorDetails from '../Create/IndicatorDetails'
import CustomTooltip from '../../UI/CustomTooltip'
import MultiDycParams from './MultiDycParams'
import IndicatorDetails from '../Create/IndicatorDetails'

import {
  ICONS, SPACING, theme,
} from '../../Theme'
import { segSymConverter } from '../../utils/common'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import { accessConditions, PRO_FEATURE_MAP, userAccessCheck } from '../../utils/userAccessChecks'
import { dynamicScripParams, DYNAMIC_SEGMENTS, getDynamicSymbol } from '../../containers/Create/Create-utils'
import { PRODUCT_TOUR_SECTIONS } from '../ProductTour/ProductTour-utils'

// list: array of stocks which are coming from parent component
// pairToUpdate: set of stocks (name of stock) which user has selected
// dycPairToUpdate:
// disabled: state which blocks user from adding stocks if limit is reached
// shouldUpdateList: flag which tells whether to auto sends the stocks to parent (auto update)
// viewMore
// currentIndex
// showScripParams: flag to show single dyc params
// pairsToSubscribe: not used
// selectedPairs: list of all the stocks(obj of the stock) selected by user
// scripProps: not used
// selectedScrip: payload which we sent to dyc contrat component
// this.listDyc: set of string `dyc#<base_stocks>` to know whether dyc for that base stock
// exist or not
// isDycInSelectedPairs: whether the dyc exit in newly added stock for that stock
// dycExist: whether the dyc exist in list for that stocks

class SymbolFinder extends PureComponent {
  constructor(props) {
    super(props)
    const {
      list = [], symbolLimit, parentPageId, isMobile, // showBaskets, getWatchList,
    } = props
    this.initialLength = list.length
    // dyc of stocks in list
    this.listDyc = new Set()
    list.forEach((item) => {
      if (item.seg_sym && item.details && item.seg_sym.includes('DYNAMIC CONTRACT')) {
        this.listDyc.add(`dyc#${item.details.seg_sym.toUpperCase()}`)
      }
    })
    this.state = {
      searchValue: '',
      selectedBaskets: [],
      list,
      pairToUpdate: new Set(),
      dycPairToUpdate: new Set(),
      disabled: symbolLimit ? this.initialLength >= symbolLimit : false,
      shouldUpdateList: parentPageId === NAVIGATIONS.BACKTESTS.name
        || parentPageId === NAVIGATIONS.CREATE.name || parentPageId === NAVIGATIONS.TECHNICALS.name,
      viewMore: false,
      currentIndex: -1,
      showScripParams: false,
      // for Product tour stock count
      ptStockCount: 0,
      ptErrorMsg: '',
    }
    this.isMobile = isMobile
    this.pairsToSubscribe = []
    // this.pairToUpdate = new Set()
    this.selectedPairs = []
    this.exchanges = ['NSE', 'NFO', 'CDS', 'MCX', 'NFO']
    this.renderLen = 6
    this.submitPressed = false
    this.grpItemContainerRef = React.createRef()
    this.headerRef = React.createRef()
    this.scripProps = {}
    this.selectedScrip = {}
    // if (user_details.subscription_type === 3) {
    //   this.exchanges.push('MCX')
    // }
    // if (showBaskets) getWatchList({ complete: true })
  }

  componentDidMount() {
    const {
      getDefaultStocks, stocks, user_details, getWatchList,
    } = this.props
    getWatchList({ complete: true })
    if (stocks.length === 0) {
      const { user_uuid } = user_details
      getDefaultStocks({ user_uuid })
    }
    document.addEventListener('keydown', this.keyboardNavHandler)
  }

  componentDidUpdate(prevProps) {
    const {
      stockError, stockErrorMsg, changePtComponentStatus,
      activeTourIndex, startTour, tourType, onDismiss,
    } = this.props
    const { ptStockCount, pairToUpdate } = this.state

    if (stockError !== prevProps.stockError && stockError) {
      showSnackbar(stockErrorMsg, {}, 0)
    }

    if (!startTour) return
    if (prevProps.activeTourIndex !== activeTourIndex
      && activeTourIndex < prevProps.activeTourIndex) {
      if (tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE) {
        // previous button logic for support technicals create
        if (activeTourIndex === 2) {
          this.setState({ ptStockCount: 0, pairToUpdate: new Set() }, () => changePtComponentStatus('resetAddedStocks'))
          this.selectedPairs = []
        }
      } else if (tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY) {
        // previous button logic for create strategy
        if (activeTourIndex === 3) {
          this.setState({ ptStockCount: 0, pairToUpdate: new Set() }, () => changePtComponentStatus('resetAddedStocks'))
          this.selectedPairs = []
        }
      }
    }

    let isNext = false

    if (prevProps.activeTourIndex !== activeTourIndex
      && activeTourIndex > prevProps.activeTourIndex) {
      if (tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE) {
        // next button logic for support technicals create
        switch (activeTourIndex) {
          case 3: {
            isNext = true
            for (let i = 0;i < 3;i++) {
              setTimeout(() => {
                const doc = document.getElementById('tour_tech_create_3')
                if (doc && pairToUpdate.size === 0) doc.click()
              }, 200 * i)
            }
            break
          }
          case 4: {
            this.submitPressed = true
            this.setState({ shouldUpdateList: true }, onDismiss)
            break
          }
          default: break
        }
      } else if (tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY) {
        switch (activeTourIndex) {
          case 4: {
            isNext = true
            for (let i = 0;i < 3;i++) {
              setTimeout(() => {
                const doc = document.getElementById('tour_create_strategy_4')
                if (doc && pairToUpdate.size === 0) doc.click()
              }, 200 * i)
            }
            break
          }
          case 5: {
            this.submitPressed = true
            this.setState({ shouldUpdateList: true }, onDismiss)
            break
          }
          default: break
        }
      }
    }

    if (!isNext && activeTourIndex === 3
      && tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE
      && ptStockCount === 0) {
      for (let i = 0;i < 3;i++) {
        setTimeout(() => {
          const doc = document.getElementById('tour_tech_create_3')
          if (doc) doc.click()
        }, 200 * i)
      }
    }

    if (!isNext && activeTourIndex === 4
      && tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY
      && ptStockCount === 0) {
      for (let i = 0;i < 3;i++) {
        setTimeout(() => {
          const doc = document.getElementById('tour_create_strategy_4')
          if (doc) doc.click()
        }, 200 * i)
      }
    }
  }

  componentWillUnmount() {
    const { shouldUpdateList } = this.props
    if (this.state.shouldUpdateList || shouldUpdateList) {
      this.updateList()
    }
    document.removeEventListener('keydown', this.keyboardNavHandler)
  }

  keyboardNavHandler = (e) => {
    const {
      currentIndex, list, viewMore, pairToUpdate, dycPairToUpdate,
    } = this.state
    const { stocks, parentPageId } = this.props
    const { key: keyPressed } = e
    if (!['ArrowDown', 'ArrowUp', 'Enter'].includes(keyPressed)) return
    e.preventDefault()
    let newIndex = 0
    let data = stocks
    if (viewMore) {
      data = parentPageId === NAVIGATIONS.BACKTESTS.name
        ? this.selectedPairs : [...list, ...this.selectedPairs]
    }
    const item = data[currentIndex < 0 ? 0 : currentIndex]
    if (!item) {
      this.setState({ currentIndex: -1 })
      return
    }

    const len = data.length
    const parentEle = this.grpItemContainerRef.current
    let shudScroll = Boolean(parentEle)

    if (keyPressed === 'ArrowDown') {
      newIndex = currentIndex + 1
      if (newIndex >= len) {
        newIndex = 0
        if (shudScroll) parentEle.scrollTo(0, 0)
        shudScroll = false
      }
    } else if (keyPressed === 'ArrowUp') {
      newIndex = currentIndex - 1
      if (newIndex <= -1) {
        newIndex = len - 1
        if (shudScroll) parentEle.scrollTo(0, parentEle.scrollHeight)
        shudScroll = false
      }
    } else if (keyPressed === 'Enter' && currentIndex !== -1) {
      const {
        segment, symbol = '', details,
      } = item
      let isDyc = false
      newIndex = currentIndex
      let seg_sym = `${segment}_${symbol}`
      let fancySegSym = seg_sym
      if (details && details.seg_sym) {
        isDyc = true
        seg_sym = details.seg_sym
        fancySegSym = item.seg_sym
      }
      const { exist: isSame, listItem, dycExist } = this.existInList(seg_sym, fancySegSym)

      const isDycSame = dycPairToUpdate.has(`dyc#${seg_sym.toUpperCase()}`)
      const exist = viewMore && isDyc ? dycExist : isSame
      const addedNewly = isDyc ? isDycSame : pairToUpdate.has(seg_sym.toUpperCase())

      this.onItemSelect(item, { exist, listItem }, addedNewly, isDyc, e)
      shudScroll = false
    }
    this.setState({ currentIndex: newIndex })
    if (shudScroll) {
      const {
        segment, symbol = '',
      } = data[newIndex]
      const seg_sym = `${segment}_${symbol}`
      const eleId = `${seg_sym}_syf`
      const ele = document.getElementById(eleId)
      if (ele) {
        let offset = 114
        if (this.headerRef.current) offset = this.headerRef.current.offsetHeight
        const viewHeight = parentEle.offsetHeight - offset
        if ((ele.offsetTop + ele.offsetHeight - offset) <= parentEle.scrollTop) {
          parentEle.scrollTo(0, ele.offsetTop - offset)
        } else if (ele.offsetTop >= (parentEle.scrollTop + viewHeight)) {
          parentEle.scrollTo(0, ele.offsetTop - parentEle.offsetHeight + ele.offsetHeight)
        }
      }
    }
  }

  updateList = () => {
    const { updateList, pairsFromSegSym, parentPageId } = this.props
    if (this.selectedPairs.length) {
      // subscribe ltp DANGER
      pairsFromSegSym(this.selectedPairs, parentPageId)
    }
    const { list } = this.state
    // stocks with disableRemove has to be added again in parent componenet
    // (used in backtest page where backtest exist for stocks)
    // const modList = list.filter(item => !item.disableRemove)
    updateList([...this.selectedPairs, ...list], { submitPressed: this.submitPressed })
  }

  onChangeText = (ev) => {
    const { value: searchValue } = ev.target
    if (searchValue.trim()) {
      this.getStocks(searchValue)
    }
    this.setState({ searchValue })
  }

  getStocks = debounce((stockQuery) => {
    const { user_details, getStocks, showBaskets } = this.props
    const { user_uuid } = user_details
    const headers = { 'content-type': 'application/json' }
    getStocks(stockQuery.trim(), headers, user_uuid, showBaskets, this.exchanges)
  }, 300)

  updateListWorker = (pairToUpdate, isBasket) => {
    const { symbolLimit } = this.props
    const { list } = this.state
    if (symbolLimit && symbolLimit < (list.length + pairToUpdate.length)) {
      const dif = symbolLimit - (pairToUpdate.length + list.length)
      if (dif <= 0) {
        this.disableAddSymbols()
      }
      this.selectedPairs = this.selectedPairs.slice(0, dif)
    }
    if (isBasket) {
      const newPairToUpdate = new Set()
      this.selectedPairs.forEach((item) => {
        newPairToUpdate.add(item.seg_sym)
      })
      this.setState({ pairToUpdate: newPairToUpdate })
    }
  }

  onItemSelect = (pair, { exist, listItem }, addedNewly, isDyc, ev) => {
    if (ev) {
      ev.preventDefault()
      ev.stopPropagation()
    }
    // this.setState((prevState) => {
    const {
      disabled, pairToUpdate, list, dycPairToUpdate, ptStockCount,
    } = this.state
    const {
      symbolLimit, blockedSegmentList, shouldShowDiscard, toggleAuthGreeting,
      updateTourIndex, activeTourIndex, changePtComponentStatus, tourType,
      startTour, updateGA,
    } = this.props
    const { segment = '', symbol, details } = pair
    const seg_sym = `${(segment).toUpperCase()}_${symbol}`
    const gaLabel = tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY ? 'Ct Strat Pg 4' : 'Ct Tech pg 3'
    if (startTour) updateGA('Scrip Select', gaLabel, 1, 'Product Tour')
    if (exist) {
      // disableRemove = false means stock coming from parent and can be removed
      // disableRemove = undefined means it is currently added
      // disabledRemove = true means stock coming from parent and cannot be removed
      if (listItem.disableRemove) {
        // showSnackbar(listItem.disableRemove)
        return
      }
      const modList = list.filter(stock => stock.seg_sym.toUpperCase() !== seg_sym.toUpperCase())
      if (details) {
        const isOtheDyCExist = list.some(
          item => item.details
            && item.details.seg_sym.toUpperCase() === details.seg_sym.toUpperCase(),
        )
        // const isOtheDyCExist = false
        if (!isOtheDyCExist) this.listDyc.delete(`dyc#${details.seg_sym.toUpperCase()}`)
      }
      this.setState({ list: modList, disabled: false })
      shouldShowDiscard(true)
      return
    }
    const modPairs = new Set(pairToUpdate)
    const modDycPairs = new Set(dycPairToUpdate)
    if (addedNewly) {
      // remove dyc symbols
      if (isDyc) {
        const isOtheDyCExist = this.selectedPairs.some(
          item => item.details
            && item.details.seg_sym.toUpperCase() === details.seg_sym.toUpperCase(),
        )
        if (!isOtheDyCExist) modDycPairs.delete(`dyc#${details.seg_sym.toUpperCase()}`)
      } else {
        modPairs.delete(seg_sym.toUpperCase())
      }
      this.selectedPairs = this.selectedPairs.filter(
        stock => stock.seg_sym.toUpperCase() !== seg_sym.toUpperCase(),
      )
      this.setState({ pairToUpdate: modPairs, disabled: false, dycPairToUpdate: modDycPairs })
      shouldShowDiscard(true)
      return
    }
    if (disabled) {
      showSnackbar(`You cannot add more than ${symbolLimit} stocks`)
      return
    }
    if (symbolLimit && symbolLimit <= (list.length + pairToUpdate.size)) {
      showSnackbar(`You cannot add more than ${symbolLimit} stocks`)
      this.setState({ disabled: true })
      return
    }
    if (blockedSegmentList && blockedSegmentList.includes(segment.toLowerCase())) {
      toggleAuthGreeting(segment.toLowerCase() === 'mcx' ? PRO_FEATURE_MAP.MCX.ID : PRO_FEATURE_MAP.OPTIONS.ID)
      // showSnackbar(`Upgrade to higher plan to use ${segment.toUpperCase()}`)
      return
    }
    modPairs.add(seg_sym.toUpperCase())
    this.selectedPairs.unshift({ ...pair, seg_sym })
    if (startTour) {
      if (tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE
        || tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY) {
        if (ptStockCount === 2 && tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY) {
          updateTourIndex(4)
        } else if (ptStockCount === 2) {
          updateTourIndex(3)
        } else {
          changePtComponentStatus(`stock${ptStockCount + 1}`)
        }
        this.setState({ ptStockCount: ptStockCount + 1, ptErrorMsg: '' })
      } else {
        updateTourIndex(activeTourIndex + 1)
        changePtComponentStatus('addScripsDone')
      }
    }
    this.setState({ pairToUpdate: modPairs })
    shouldShowDiscard(true)
  }

  disableAddSymbols = () => {
    showSnackbar(`You cannot add more than ${this.props.symbolLimit} stocks`)
    this.setState({ disabled: true })
  }

  existInList = (seg_sym, fancySegSym) => {
    const { list } = this.state
    let exist = false
    let listItem = {}
    for (let i = 0;i < list.length;i++) {
      const stock = list[i]
      if (stock.seg_sym.toUpperCase() === fancySegSym.toUpperCase()) {
        listItem = stock
        exist = true
        break
      }
    }
    return {
      exist, listItem, dycExist: this.listDyc.has(`dyc#${seg_sym.toUpperCase()}`),
    }
  }

  onBasketSelect = (key, selected, instrumentList) => {
    if (selected) return
    const { pairToUpdate, disabled, list } = this.state
    const {
      symbolLimit, blockedSegmentList = [],
    } = this.props
    if (disabled) {
      showSnackbar(`You cannot add more than ${symbolLimit} stocks`)
      return
    }
    let notAllowedExist = false
    let notAllowedSegment = ''
    // const { instrumentList = [] } = item
    // const modBaskets = [...selectedBaskets]

    const pairList = []
    const modPairs = new Set(pairToUpdate)
    if (instrumentList.length) {
      instrumentList.forEach((pair) => {
        const modPair = segSymConverter(pair, true)
        const { segment = '', symbol, seg_sym } = modPair
        if (!symbol || !segment) {
          return
        }
        if (blockedSegmentList.includes(segment.toLowerCase())) {
          notAllowedExist = true
          notAllowedSegment = segment.toUpperCase()
          return
        }
        if (!modPairs.has(seg_sym.toUpperCase()) && !list.some(
          prevStock => prevStock.seg_sym.toUpperCase() === seg_sym.toUpperCase(),
        )) {
          modPairs.add(seg_sym.toUpperCase())
          pairList.push({
            ...modPair, exchange: segment,
          })
        }
      })
    }

    if (pairList.length === 0) {
      this.selectedPairs = []
      showSnackbar('All stocks already exists')
      return
    }
    if (notAllowedExist) {
      showSnackbar(`${notAllowedSegment} only available in ultimate plan`)
    }

    this.selectedPairs.unshift(...pairList)
    this.updateListWorker(this.selectedPairs, true)
    this.props.shouldShowDiscard(true)
    // modBaskets.push(name)
    // this.setState({ selectedBaskets: modBaskets })
  }

  renderBasketsItem = ({ item, isMobile }) => {
    const { selectedBaskets } = this.state
    const { basket_name, scrips = [] } = item
    const exist = selectedBaskets.includes(basket_name)
    const { styles } = this.props
    return (
      <button
        type="button"
        onClick={() => this.onBasketSelect(item, exist, scrips)}
        className={classnames(styles.itemRow, styles.basketItemBtn)}
        key={basket_name}
      >
        <div
          style={{ height: 26, width: 26, margin: `${SPACING.SPACE_4} ${SPACING.SPACE_10} 0 0` }}
        >
          <Icon name={ICONS.BASKET} color={theme.linkColor} size={26} />
        </div>
        <div className={styles.symbol}>
          <CustomText weight="medium">{basket_name}</CustomText>
          <CustomText size="tiny" style={{ textAlign: 'left' }}>Basket</CustomText>
        </div>
        <div className={styles.rowIcon}>
          <Icon
            name={ICONS[exist ? 'TICK_ROUND_FILLED' : 'ADD_ROUND_OUTLINED']}
            color={theme[exist ? 'green' : 'linkColor']}
            size={isMobile ? 18 : 22}
          />
        </div>
      </button>
    )
  }

  // isSame: symbol name matched exist in list
  // dycExist: dyc symbol for seg_sym(non dyc) exit in list
  // fancySegSym: actual seg_sym name
  // isDycInSelectedPairs: dyc symbol for seg_sym(non dyc) exit in selectedPairs
  // do not change fancySegSym and seg_sym
  renderStockItem = (props, renderAll) => {
    const {
      item, index, isMobile, tourType, isFetchingStocks, startTour,
    } = props
    if (item.basket_name) {
      return renderAll ? null : this.renderBasketsItem(props)
    }
    const {
      segment, symbol = '', sector = '', details,
    } = item
    const {
      pairToUpdate, currentIndex, dycPairToUpdate, ptStockCount,
    } = this.state
    const { styles, isDark, create_plus } = this.props
    let seg_sym = `${segment}_${symbol}`
    let fancySegSym = seg_sym
    let isDyc = false // whether the symbol has dyc contract or not
    if (details && details.seg_sym) {
      isDyc = true
      fancySegSym = item.seg_sym // dyc segsym
      seg_sym = details.seg_sym // base segsym from whih dyc is formed
    }
    const segChild = <span className={styles.segment}>{`  ${sector}`}</span>
    const {
      exist: isSame, listItem, dycExist,
    } = this.existInList(seg_sym, fancySegSym)
    const isDycInSelectedPairs = dycPairToUpdate.has(`dyc#${seg_sym.toUpperCase()}`)
    const exist = isSame
    const addedNewly = isDyc ? isDycInSelectedPairs : pairToUpdate.has(seg_sym.toUpperCase())
    const isSelected = currentIndex === index
    const ptCreateId3 = !isFetchingStocks && index === ptStockCount && tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE ? 'tour_tech_create_3' : index === ptStockCount && tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY ? 'tour_create_strategy_4' : ''

    const ptIdMobile = index === ptStockCount && tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE ? 'tour_tech_create_3' : index === ptStockCount && tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY ? 'tour_create_strategy_4' : ''

    const ptCreateId3Clicker = index === ptStockCount && tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE ? 'tour_tech_create_3_clicker' : index === ptStockCount && tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY ? 'tour_create_strategy_4_clicker' : ''

    return (
      <div
        id={`${seg_sym}_syf`}
        key={fancySegSym}
        className={classnames(startTour && isMobile ? styles.ptItem : styles.item,
          isSelected ? styles.itemSeleted : '')}
      >
        <button
          type="button"
          onClick={e => this.onItemSelect(item, { exist, listItem }, addedNewly, isDyc, e)}
          className={styles.stockItemBtn}
          disabled={renderAll}
          id={isMobile ? ptIdMobile : ptCreateId3}
        >
          <CustomTooltip
            show={isDyc}
            tooltipProps={{ text: item.symbol }}
          >
            <div className={styles.itemRow}>
              <StockIcon
                width={26}
                height={26}
                seg_sym={seg_sym}
                containerStyle={{ margin: `${SPACING.SPACE_4} ${SPACING.SPACE_10} 0 0` }}
              />
              <div className={styles.symbol} id={ptCreateId3Clicker}>
                <SegSym
                  isDark={isDark}
                  seg_sym={fancySegSym}
                  column
                  segChild={segChild}
                />
              </div>
            </div>
          </CustomTooltip>
        </button>
        {create_plus && (
          (item.segment && DYNAMIC_SEGMENTS.includes(item.segment.toUpperCase()))
          || (isDyc)
        ) && (
            <CustomTooltip
              show
              tooltipProps={{ text: (dycExist) || isDycInSelectedPairs ? 'Edit Dynamic contract' : 'Add dynamic contract' }}
            >
              <button
                type="button"
                className={`${styles.dycBtn} ${(dycExist) || isDycInSelectedPairs ? styles.dycExist : ''}`}
                onClick={e => this.convertToDynamic(
                  item, { exist: isDyc ? isSame : dycExist, isDyc }, isDycInSelectedPairs, e,
                )}
              >
                {(dycExist) || isDycInSelectedPairs
                  ? <CustomText size="tiny" color="green" weight="medium">DYC</CustomText>
                  : <Icon name={ICONS.DYNAMIC_CONTRACT} size={16} color={theme.linkColor} />}
              </button>
            </CustomTooltip>
          )}
        <button
          type="button"
          className={styles.tickBtn}
          onClick={e => this.onItemSelect(item, { exist, listItem }, addedNewly, isDyc, e)}
        >
          <div className={styles.rowIcon}>
            {renderAll ? (
              <Icon name={ICONS.DELETE} color={theme.red} size={isMobile ? 12 : 16} />
            ) : (
              <Icon
                name={ICONS[exist || addedNewly ? 'TICK_ROUND_FILLED' : 'ADD_ROUND_OUTLINED']}
                color={theme[exist || addedNewly ? 'green' : 'linkColor']}
                size={isMobile ? 18 : 22}
              />
            )}
          </div>
        </button>
      </div>
    )
  }

  removeLatinCharEmoji = (listName) => {
    const removeEmoji = listName.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
    const removeLatinChars = removeEmoji.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    return removeLatinChars
  }

  renderStockList = (isMobile) => {
    const {
      stocks, isFetchingStocks, styles, market_watches = {},
      tourType, startTour,
    } = this.props

    const { searchValue } = this.state
    const finalSearchValue = searchValue.toLowerCase()

    const marketwatch_basket = Object.values(market_watches)
      .filter(item => item.listName && item.listName.toLowerCase().includes(finalSearchValue)
        && item.instrumentList && item.instrumentList.length > 0)
      || []

    const finalList = marketwatch_basket.map((item) => {
      const { listName, thumbnail, instrumentList } = item
      const finalListName = this.removeLatinCharEmoji(listName)
      return {
        basket_name: finalListName,
        id: btoa(finalListName),
        thumbnail,
        scrips: instrumentList,
      }
    })

    const resultList = searchValue !== '' ? [...finalList, ...stocks] : stocks

    const uniqueList = [...new Map(resultList
      .map(item => [item.basket_name || item.symbol, item])).values()]

    if (isFetchingStocks) {
      return <DotLoader props={{ className: styles.loader }} />
    }
    if (uniqueList.length === 0) {
      return (
        <CustomText center>Search to add SBIN, F&O etc</CustomText>
      )
    }
    return uniqueList.map((item, index) => this.renderStockItem({
      item, index, isMobile, tourType, startTour,
    }))
  }

  renderExistingStocksCol = (data) => {
    const { isMobile } = this.props
    if (data.length === 0) {
      return (
        <CustomText center>No results found</CustomText>
      )
    }
    return data.map((item, index) => this.renderStockItem({ item, index, isMobile }, true))
  }

  removeAllStocks = () => {
    this.selectedPairs = []
    const { list } = this.state
    this.props.shouldShowDiscard(true)
    this.setState({
      pairToUpdate: new Set(),
      dycPairToUpdate: new Set(),
      list: list.filter((item) => {
        if (!item.disableRemove && item.details) {
          this.listDyc.delete(`dyc#${item.details.seg_sym.toUpperCase()}`)
        }
        return item.disableRemove
      }),
      disabled: false,
      viewMore: false,
    })
  }

  renderRemoveAll = () => {
    const { styles } = this.props
    return (
      <Button
        onPress={this.removeAllStocks}
        btnStyles={styles.removeAllBtn}
        transparent
        mode="outlined"
        labelColor="red"
        text="Remove all"
      />
    )
  }

  clearValue = () => {
    this.setState({ searchValue: '' })
  }

  toggleViewMore = () => {
    this.setState(prevState => ({ viewMore: !prevState.viewMore, searchValue: '', currentIndex: 0 }))
  }

  renderViewMore = (remainingLen, btnStyles) => {
    return (
      <Button
        onPress={this.toggleViewMore}
        text="View all" // {`+${remainingLen} more`}
        btnStyles={btnStyles}
        mode="text"
        labelColor="linkColor"
        transparent
      />
    )
  }

  renderExistingStocks = (data) => { // condensedView
    const { styles } = this.props
    const { viewMore, dycPairToUpdate } = this.state
    const len = data.length
    if (len === 0) return null
    return (
      <div className={styles.exsitingStocksSection}>
        <div className={`${styles.existingList} ${viewMore ? styles.existingListHidden : ''}`}>
          <div className={styles.existingListRow}>
            {data.slice(0, this.renderLen).map((item) => {
              const { seg_sym, disableRemove, details } = item
              const { symbol } = segSymConverter(seg_sym)
              const exist = disableRemove !== undefined
              const extraParams = disableRemove ? {} : {
                cancelIconProps: { ciColor: theme.linkColor },
                onCancel: e => this.onItemSelect(
                  item, { exist, listItem: item }, !exist, !isEmpty(details), e,
                ),
              }
              return (
                <Chip
                  key={seg_sym}
                  seg_sym={seg_sym}
                  label={symbol.toUpperCase()}
                  disabled
                  showTooltip
                  containerClass={styles.chipContainer}
                  {...extraParams}
                />
              )
            })}
          </div>
          {len >= (this.renderLen - 2) || (this.listDyc.size > 0 || dycPairToUpdate.size > 0)
            ? this.renderViewMore(len - this.renderLen, styles.viewMoreBtn)
            : null}
        </div>
        <div className={styles.existingStocksHeader}>
          <CustomText weight="medium">
            <button
              type="button"
              onClick={this.toggleViewMore}
              className={styles.exsitingStocksBackBtn}
            >
              <Icon name={ICONS.BACK} color={theme.text} size={10} />
            </button>
            {'Stocks Added '}
            <span color={{ color: theme.textSecondary }}>{`(${len})`}</span>
          </CustomText>
          {this.renderRemoveAll()}
        </div>
      </div>
    )
  }

  onSubmit = () => {
    const {
      changePtComponentStatus, onDismiss, startTour,
      updateTourIndex, tourType, updateGA,
    } = this.props
    const { pairToUpdate } = this.state
    const gaLabel = tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY ? 'Ct Strat Pg 5' : 'Ct Tech pg 4'
    if (startTour) {
      if (pairToUpdate.size === 0) {
        this.setState({ ptErrorMsg: 'Select stocks to continue' })
        return
      }
      updateGA('Done', gaLabel, 1, 'Product Tour')
      if (tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE) updateTourIndex(4)
      else updateTourIndex(5)
      changePtComponentStatus(`next_${Math.random()}`)
    }
    this.submitPressed = true
    this.setState({ shouldUpdateList: true }, onDismiss)
  }

  onInputFocus = () => {
    const { clearOnfocus } = this.props
    if (clearOnfocus) {
      this.setState({ searchValue: '' })
    }
  }

  toggleScripParams = () => {
    this.setState({ showScripParams: false })
  }

  convertToDynamic = (pair, { exist, isDyc }, addedNewly) => {
    const { create_plus, user_details } = this.props
    if (!create_plus) {
      return
    }
    const shallReturn = userAccessCheck({
      condition: accessConditions.DYNAMIC_CONTRACT,
      user_details,
      showSnackbar,
    })
    if (shallReturn) return
    // const { list } = this.state
    const scrip = pair
    const { segment, symbol, details } = scrip

    if (isDyc) {
      if (!details.seg_sym) return
      this.selectedScrip = {
        ...dynamicScripParams(details.seg_sym.split('_')[0]),
        indicatorDetails: details,
        isSingle: true,
        seg_sym: details.seg_sym,
        item: {
          exist, isDyc, addedNewly, pair,
        },
      }
      this.setState({ showScripParams: true })
      return
    }
    // let details = null

    const seg_sym = `${segment}_${symbol}`
    // let listIndex = -1
    // if (exist) {
    //   list.some((item, index) => {
    //     if (item.details && item.details.seg_sym === seg_sym) {
    //       scrip = item
    //       details = item.details
    //       listIndex = index
    //       return true
    //     }
    //     return false
    //   })
    // } else if (addedNewly) {
    //   this.selectedPairs.some((item) => {
    //     if (item.details && item.details.seg_sym === seg_sym) {
    //       details = item.details
    //       return true
    //     }
    //     return false
    //   })
    // }
    this.selectedScrip = {
      ...scrip, seg_sym, addedNewly, exist, // listIndex,
    }
    // this.scripProps = {
    //   ...dynamicScripParams(seg_sym.split('_')[0]),
    //   indicatorDetails: details,
    // }
    this.setState({ showScripParams: true })
  }

  hideScripParams = () => {
    this.setState({ showScripParams: false })
  }

  saveSingleParamsDetails = (data) => {
    const { list } = this.state

    const { indicatorDetails: details, item } = this.selectedScrip
    const symbol = getDynamicSymbol(data, details.seg_sym)
    const modScrip = {
      segment: '',
      symbol,
      details: { ...data, seg_sym: details.seg_sym },
      seg_sym: `_${symbol}`,
    }
    const { exist, pair, addedNewly } = item
    const oldSegSym = pair.seg_sym.toUpperCase()
    if (oldSegSym === modScrip.seg_sym.toUpperCase()) {
      this.hideScripParams()
      return
    }
    if (this.selectedPairs.some(p => p.seg_sym === modScrip.seg_sym)
      || list.some(p => p.seg_sym === modScrip.seg_sym)) {
      showSnackbar(`${symbol} already added`)
      return
    }
    if (exist) {
      for (let i = 0;i < list.length;i++) {
        if (list[i].seg_sym.toUpperCase() === oldSegSym) {
          const modList = [...list]
          modList[i] = modScrip
          this.setState({ list: modList })
          break
        }
      }
    } else if (addedNewly) {
      for (let i = 0;i < this.selectedPairs.length;i++) {
        if (this.selectedPairs[i].seg_sym.toUpperCase() === oldSegSym) {
          this.selectedPairs[i] = modScrip
        }
      }
    }
    this.hideScripParams()
  }

  saveParamsDetails = (data, deletedContracts = []) => {
    const { dycPairToUpdate, list } = this.state
    const modPairToUpdate = new Set(dycPairToUpdate)
    const modList = [...list]
    data.forEach((item) => {
      const { new: isNew, listIndex } = item
      if (!isNew) {
        delete item.new
        delete item.listIndex
        delete item.duplicate
        modList[listIndex] = item
      } else if (listIndex !== undefined) {
        delete item.new
        delete item.listIndex
        delete item.duplicate
        this.selectedPairs[listIndex] = item
        modPairToUpdate.add(`dyc#${item.details.seg_sym.toUpperCase()}`)
      } else {
        delete item.new
        delete item.listIndex
        delete item.duplicate
        this.selectedPairs.unshift(item)
        modPairToUpdate.add(`dyc#${item.details.seg_sym.toUpperCase()}`)
      }
    })
    deletedContracts.forEach((item) => {
      const { new: isNew, listIndex } = item
      if (!isNew) {
        modList.splice(listIndex, 1)
        const isOtheDyCExist = modList.some(
          pair => pair.details
            && pair.details.seg_sym.toUpperCase() === item.details.seg_sym.toUpperCase(),
        )
        if (!isOtheDyCExist) this.listDyc.delete(`dyc#${item.details.seg_sym.toUpperCase()}`)
      } else if (listIndex !== undefined) {
        this.selectedPairs.splice(listIndex, 1)
        const isOtheDyCExist = this.selectedPairs.some(
          pair => pair.details
            && pair.details.seg_sym.toUpperCase() === item.details.seg_sym.toUpperCase(),
        )
        if (!isOtheDyCExist) modPairToUpdate.delete(`dyc#${item.details.seg_sym.toUpperCase()}`)
      }
    })
    this.setState({ list: modList, dycPairToUpdate: modPairToUpdate, showScripParams: false })
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  render() {
    const {
      placeholder = 'Add Stocks eg: SBIN, F&O etc.',
      styles,
      onDismiss,
      showSubmitBtn,
      parentPageId,
      symbolLimit,
      tourType,
      startTour,
    } = this.props
    const {
      searchValue, viewMore, list, disabled, showScripParams,
      ptErrorMsg,
    } = this.state
    const showModal = viewMore && Boolean(searchValue)
    const data = parentPageId === NAVIGATIONS.BACKTESTS.name
      ? this.selectedPairs : [...list, ...this.selectedPairs]
    const btnText = viewMore && this.selectedPairs.length
      ? `Add ${this.selectedPairs.length} ${this.selectedPairs.length > 1 ? 'Stocks' : 'Stock'}` : 'Done'

    const ptCreateId4 = startTour ? (tourType === PRODUCT_TOUR_SECTIONS.TECHNICALS_CREATE ? 'tour_tech_create_4' : 'tour_create_strategy_5') : ''
    return (
      <div className={styles.container} ref={this.grpItemContainerRef}>
        <CustomModal
          visible={showScripParams}
          onDismiss={this.toggleModal}
          dismissKey="showScripParams"
          containerStyles={styles.detailsModal}
        >
          {this.selectedScrip.isSingle ? (
            <>
              <CustomText size="small">
                Edit dynamic contract
              </CustomText>
              <CustomText weight="medium" size="large" className={styles.dynamicTitle}>
                {this.selectedScrip && this.selectedScrip.seg_sym ? this.selectedScrip.seg_sym.replace('_', ' ') : ''}
              </CustomText>
              <IndicatorDetails
                saveDetails={this.saveSingleParamsDetails}
                dismissModal={this.hideScripParams}
                showRemove={false}
                {...this.selectedScrip}
              />
            </>
          ) : (
            <MultiDycParams
              list={this.listDyc.size > 0 ? list : []}
              selectedPair={this.selectedPairs}
              selectedScrip={this.selectedScrip}
              saveParamsDetails={this.saveParamsDetails}
              onDismiss={this.toggleModal}
            />
          )}
        </CustomModal>
        {showModal && (
          <button type="button" style={{ display: 'flex' }} onClick={() => this.setState({ searchValue: '' })}>
            <div className={styles.modalBackDrop} />
          </button>
        )}
        <div className={styles.header} ref={this.headerRef}>
          <div className={styles.actionBar}>
            <div className={styles.actionBarRow}>
              {this.isMobile && (
                <button
                  type="button"
                  onClick={onDismiss}
                  className={styles.backBtn}
                >
                  <Icon name={ICONS.BACK} color={theme.text} size={16} />
                </button>
              )}
              <CustomInput
                wrapperStyles={styles.inputContainer}
                iconName="search"
                size={12}
                inputStyles={styles.searchInput}
                onChangeText={this.onChangeText}
                value={searchValue}
                placeholder={placeholder}
                showClearBtn
                onInputFocus={this.onInputFocus}
              />

              {showSubmitBtn && (
                <div id={ptCreateId4} className={styles.submitBtnContainer}>
                  <Button
                    text={parentPageId === NAVIGATIONS.BACKTESTS.name ? 'Run Backtest' : btnText}
                    onPress={this.onSubmit}
                    buttonColor="blue"
                    btnStyles={styles.submitBtn}
                  />
                </div>
              )}
            </div>
            {showModal
              && (
                <div className={styles.stockListModal}>
                  {this.renderStockList()}
                </div>
              )}
          </div>
          {this.renderExistingStocks(data)}
          {/* this.isMobile || (list.length + this.selectedPairs.length > 20),
          } */}
          {ptErrorMsg && (
            <CustomText
              size="small"
              color="red"
              style={{ transform: 'translate(0px, 10px)' }}
            >
              {ptErrorMsg}
            </CustomText>
          )}
          {disabled && (
            <CustomText size="small" color="red" style={{ transform: 'translate(0px, 10px)' }}>
              {`You cannot add more than ${symbolLimit} stocks`}
            </CustomText>
          )}
        </div>
        <div className={styles.listView}>
          {viewMore ? this.renderExistingStocksCol(data) : this.renderStockList(this.isMobile)}
        </div>
        {/* <CustomModal
          visible={viewMore}
          dismissKey="viewMore"
          onDismiss={this.toggleViewMore}
          position="bottom"
        >
          <div className={styles.viewMoreContainer}>
            <CustomText weight="semi_bold"
            className={styles.viewMoreHeader}>Stocks Added</CustomText>
            {this.renderExistingStocks()}
          </div>
        </CustomModal> */}
      </div>
    )
  }
}

export default SymbolFinder
