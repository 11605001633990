import React, { useEffect, useRef } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import ScreenHeader from '../../UI/ScreenHeader'
import Button from '../../UI/Button'
import AlgoInfo from '../AlgoInfo'
import BacktestResults from './BacktestResults'
import BacktestsDetails from './BacktestsDetails'
import CreateButton from '../../UI/CreateButton'
import EmptyContainer from '../../UI/EmptyContainer'
import CummulativePnl from './CummulativePnl'

import {
  COLORS, ICONS, SPACING, theme,
} from '../../Theme'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import DotLoader from '../../UI/DotLoader'
import CustomText from '../../UI/CustomText'
import { parseQuery } from '../../utils/common'
import { LINK_MAP, MATH_FUNCS } from '../../utils/consts'
import Icon from '../../UI/Icon'

// helper to see if backtest results exists
export const isBacktestExist = (scripBtResult = {}, includeNoTrades) => {
  if (isEmpty(scripBtResult) || scripBtResult.error || scripBtResult.error_msg
    || scripBtResult.status === false) {
    return includeNoTrades && scripBtResult.error_msg
      && scripBtResult.error_msg.toLowerCase().includes('no trades')
  }
  return true
}

const checkOnlyScannerFuncExist = (conditions = [],
  indiArr = [MATH_FUNCS.MULTI_TIME_FRAME.name], mtfPreference) => {
  let hasScannerOnlyFunc = false
  if(!mtfPreference) return false
  if (conditions.length > 0 && conditions.includes(`${indiArr[0]}(`)) {
    hasScannerOnlyFunc = true
  }

  return hasScannerOnlyFunc
}

// do not change
const menuOptions = [
  {
    label: 'Copy', value: 'copy', icon: ICONS.COPY_FILLED, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
]

export const renderPnl = ({
  waiting, final_return, error_msg, final_pnl,
}) => {
  const styles = stylesheet()
  let pnlText
  const pnlAmount = final_pnl?.toFixed(2) || 0
  // let backgroundColor = COLORS.WHITE
  if (final_return !== undefined && typeof final_return === 'number') {
    if (+final_return > 0) {
      pnlText = `+${+final_return.toFixed(2)}%`
    } else if (final_return < 0) {
      pnlText = `${+final_return.toFixed(2)}%`
    }
  }
  const renderTypeStyles = styles[final_return >= 0 ? 'gain' : 'loss']
  if (waiting) {
    return (
      <div className={styles.pnlPlaceHolder}>
        <DotLoader props={{ width: 30 }} />
      </div>
    )
  }
  if (error_msg) {
    return (
      <CustomText
        size="small"
      >
        N/A
      </CustomText>
    )
  }
  if (pnlText) {
    return (
      <>
        {final_pnl ? (
          <CustomText
            className={renderTypeStyles}
            weight="medium"
            size="small"
            center
          >
            {pnlAmount}
          </CustomText>
        ) : null}
        <CustomText
          className={renderTypeStyles}
          weight="medium"
          size="small"
          center
        >
          {final_pnl ? `(${pnlText})` : pnlText}
        </CustomText>
      </>
    )
  }
  return <div className={styles.pnlPlaceHolder} />
}

const BacktestShared = ({
  btParams,
  algoInfo,
  algo_uuid,
  renderType,
  deployAll,
  algoId,
  algoIdKey,
  scripList,
  backtest_results,
  addNewSymbol,
  showBacktestDetails,
  backPressHandler,
  selectedSymbols,
  onSymbolSelect,
  deployed_seg_sym,
  toggleSelectAll,
  navigation,
  isFetchingBacktests,
  // refetchBacktest,
  isRunningBacktests,
  removeSymbols,
  isFocused,
  convertToDynamic,
  create_plus,
  fetchBacktestAll,
  brokerageData,
  isDark,
  selectedIndex,
  algo_subscription_uuid,
  publishing_uuid,
  algo_subscribed,
  csrf,
  sessionid,
  onDelete,
  sliderProps,
  deploySymbols,
  toggleBrokerage,
  brokerage,
  selectedItem,
  user_details,
  isMobile,
  isFetchingData,
  pinCumulativePnl,
  updateWebSiteConfig,
  isSharedPublic,
  shared,
  domainChange,
  isLoggedIn,
  downloadBtResult,
  updateBrokerage,
  toggleListView,
  isListView,
  editSubscribedAlgo,
  gaCategory,
  updateGA,
  reRunBacktestHandler,
  mtfPreference,
}) => {
  const styles = stylesheet()
  const history = useHistory()
  const {
    algo_name, algo_desc, entryStr, exitStr,
  } = algoInfo
  const scrollViewRef = useRef(null)
  useEffect(() => {
    if (isRunningBacktests && scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: 0, animated: true })
    }
  }, [isRunningBacktests])
  const len = scripList.length
  const selectedLen = selectedSymbols.size
  const deployedLen = deployed_seg_sym.length
  const { dt_start, dt_stop } = btParams
  const { search } = history.location
  let query = {}
  if (search) {
    query = parseQuery(search)
  }
  const { user } = query
  let sharedBy = ''
  let title = ''
  if (user) {
    sharedBy = atob(user)
    title = `Shared by ${sharedBy}`
  }

  const mtfExist = checkOnlyScannerFuncExist(entryStr, [
    MATH_FUNCS.MULTI_TIME_FRAME.name,
  ], mtfPreference)

  const commonProps = {
    algo_name,
    algoIdKey,
    algo_subscription_uuid,
    publishing_uuid,
    algo_uuid,
    deployed_seg_sym,
    isRunningBacktests,
    brokerageData,
    brokerage,
    shared,
    renderType,
    isBacktestExist,
    isLoggedIn,
    gaLabel: gaCategory,
    gaCategory,
    updateGA,
    ...sliderProps,
    reRunBacktestHandler,
    mtfExist,
  }

  const backtestResultProps = {
    backtest_results,
    scripList,
    addNewSymbol,
    showBacktestDetails,
    selectedSymbols,
    onSymbolSelect,
    toggleSelectAll,
    allSelected: (selectedLen === (len - deployedLen)),
    selectedLen,
    removeSymbols,
    onDelete,
    isDark,
    len,
    dt_start,
    dt_stop,
    selectedIndex,
    deployAll,
    algoId,
    showEditBtn: create_plus,
    showDCParams: convertToDynamic,
    selectedItem,
    isSharedPublic,
    domainChange,
    toggleListView,
    isListView,
    editSubscribedAlgo,
  }

  const backtestDetailsProps = {
    algoInfo,
    details: btParams,
    item: selectedItem,
    algo_subscribed,
    csrf,
    sessionid,
    deploySymbols,
    toggleBrokerage,
    updateBrokerage,
    isListView,
  }

  const actionBarRenderer = () => {
    if (len === 0 || !isMobile) return null
    const deployBtnText = selectedLen === (len - deployedLen) || selectedLen === 0 ? 'Deploy All' : `Deploy ${selectedLen}`
    return (
      <Button
        text={deployBtnText}
        onPress={deployAll}
        buttonColor="blue"
        btnStyles={styles.deployBtn}
        uppercase
        disabled={len === 0}
      />
    )
  }
  const listHeaderRenderer = ({ scrolled }) => {
    let finalOptions = menuOptions
    if (isSharedPublic) {
      finalOptions = menuOptions
    } else {
      finalOptions = []
    }

    return (
      <AlgoInfo
        algoId={algoId}
        algoIdKey={algoIdKey}
        monthly_pricing={0}
        algo_name={algo_name}
        algo_desc={algo_desc}
        showSocialStats={false}
        menuOptions={finalOptions}
        downloadBtResult={downloadBtResult}
        navigation={navigation}
        canEditAlgo={!deployed_seg_sym || (deployed_seg_sym && deployed_seg_sym.length === 0)}
        parentId={NAVIGATIONS.STRATEGIES.name}
        isSharedPublic={isSharedPublic}
        isFocused={isFocused}
        isDark={isDark}
        entryStr={entryStr}
        exitStr={exitStr}
        history={history}
        scrolled={scrolled}
        shared={shared}
        selectedItem={selectedItem}
        domainChange={domainChange}
        isLoggedIn={isLoggedIn}
        menuBtnStyles={styles.menuBtn}
        gaCategory={gaCategory}
        gaLabel={gaCategory}
      />
    )
  }

  const emptyActionRenderer = () => {
    return (
      <CreateButton
        text="Add Symbols"
        onPress={addNewSymbol}
        className={styles.addStock}
        btnStyles={styles.addStockBtn}
        textSize="small"
      />
    )
  }

  const removeOverLay = () => {
    const mtfEle = document.getElementById('mtfContainer')

    if(mtfEle) {
      mtfEle.style.display = 'none'
    }
    backtest_results.forEach((item) => {
      const mtfGridResultEle = document.getElementById(item.seg_sym)
      const mtfRowEle = document.getElementById(`${item.seg_sym}_mtf`)
      const mtfGridPnl = document.getElementById(`${item.seg_sym}_mtf_pnl`)
      const mtfListPnl = document.getElementById(`${item.seg_sym}_mtf_pnl`)

      const mtfIds = ['pnl', 'wvl', 'signals', 'ws', 'ls', 'maxdd']
      mtfIds.forEach((mtfItem) => {
        const getPnlElde = document.getElementById(`${item.seg_sym}_${mtfItem}`)
        if(getPnlElde && !isMobile) {
          getPnlElde.className = styles.headerColumn
        }
      })

      if(mtfRowEle) {
        mtfRowEle.style.display = 'none'
      }
      if(isMobile && isListView) {
        mtfListPnl.style.display = 'none'
      }
      if(mtfGridResultEle) {
        mtfGridResultEle.style.display = 'block'
      }
      if(!isListView && mtfGridPnl) {
        mtfGridPnl.style.display = 'none'
      }
    })
  }

  const renderMTFCell = () => {
    return (
      <div className={styles.mtfCell} style={{ width: isMobile ? '50%' : 180 }}>
        <span className={styles.labelBlur}>value</span>
        <span className={styles.valueBlur}>labelvalue</span>
      </div>
    )
  }

  const renderMTFCumuPNL = () => {
    return (
      <div
        className={styles.container}
      >
        {isMobile ? (
          <div className={styles.headerMobile}>
            <div className={styles.subHeaderMobile}>
              <div className={styles.headerLeft}>
                <Icon
                  name={ICONS.CUMULATIVE_ICON}
                  size={14}
                  style={{ marginRight: SPACING.SPACE_8 }}
                  color={theme.orange}
                />
                <CustomText weight="semi_bold" size="medium">Cumulative results</CustomText>
              </div>
              <div className={styles.headerRight}>
                <CustomText size="regular" weight="medium">
                  Backtest P&L
                  <span className={styles.pnlBlur}>12345</span>
                </CustomText>
              </div>
            </div>
            <Button
              mode="text"
              buttonColor={isDark ? 'blue_300' : 'blue'}
              transparent
              onPress={() => removeOverLay()}
              text="Show results"
              btnStyles={styles.showResultBtn}
              respText
            />
          </div>
        )
          : (
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <Icon
                  name={ICONS.CUMULATIVE_ICON}
                  size={14}
                  style={{ marginRight: SPACING.SPACE_8 }}
                  color={theme.orange}
                />
                <CustomText weight="semi_bold" size="medium">Cumulative results</CustomText>
              </div>
              <div className={styles.headerRight}>
                <CustomText size="regular" weight="medium">
                  Backtest P&L
                  <span className={styles.pnlBlur}>12345</span>
                </CustomText>
              </div>
              <Button
                mode="text"
                buttonColor={isDark ? 'blue_300' : 'blue'}
                transparent
                onPress={() => removeOverLay()}
                text="Show results"
                btnStyles={styles.showResultBtn}
                respText
              />
            </div>
          )}
        <CustomText size="small_1" weight="medium" className={styles.mtfTitle} color="orange">
          Backtest results for MTF are unverifiable.
          <a href={LINK_MAP.MTF.link} rel="noreferrer" target="_blank"> Learn More.</a>
        </CustomText>
        <div className={styles.mtfContent}>
          {renderMTFCell()}
          {renderMTFCell()}
          {renderMTFCell()}
          {renderMTFCell()}
          {renderMTFCell()}
          {renderMTFCell()}
        </div>
      </div>
    )
  }

  const renderCumulativePnl = () => {
    return (
      <CummulativePnl
        // data={backtest_results}
        isRunningBacktests={isRunningBacktests}
        user_details={user_details}
        fetchBacktestAll={fetchBacktestAll}
        isFetchingData={isFetchingData}
        pinCumulativePnl={pinCumulativePnl}
        updateWebSiteConfig={updateWebSiteConfig}
        gaCategory={gaCategory}
        scripList={scripList}
      />
    )
  }

  const mtfContainerShow = document.getElementById('mtfContainer')
  const mtfContainerStyles = mtfContainerShow?.style.display

  return (
    <div className={styles.content}>
      <ScreenHeader
        backPressHandler={backPressHandler}
        title={title}
        titleSize="large_1"
        containerStyles={styles.screenHeader}
        showBackBtn={false}
        hideOnScroll
        scrolledText={algo_name}
        actionBarRenderer={actionBarRenderer}
        algoIdKey={algoIdKey}
        isSharedPublic={isSharedPublic}
      >
        {({ scrolled }) => listHeaderRenderer({ scrolled })}
      </ScreenHeader>
      <div
        className={styles.container}
        ref={scrollViewRef}
      >
        {mtfExist && pinCumulativePnl && (
        <div
          className={styles.mtfContainer}
          id="mtfContainer"
          style={{
            display: mtfContainerStyles === 'none' ? 'none' : 'block',
            paddingBottom: 0,
            marginTop: SPACING.SPACE_2,
          }}
        >
          {renderMTFCumuPNL()}
        </div>
        )}
        {!isFetchingBacktests && pinCumulativePnl && (
          renderCumulativePnl()
        )}
        {len === 0 ? (
          <EmptyContainer
            containerStyles={`${styles.emptyContainer} ${isDark ? styles.emptyContainerDark : ''}`}
            imgStyles={styles.emptyImg}
            imgContainerStyles={styles.emptyImgContainer}
            imgSrc="NO_STOCKS"
            actionRenderer={emptyActionRenderer}
            title="No Stocks"
            subTitle="Add symbols to run backtest"
            contentStyles={styles.emptyContent}
          />
        ) : (
          <>
            <BacktestResults
              {...commonProps}
              {...backtestResultProps}
              backtestDetailsProps={backtestDetailsProps}
              renderPnl={renderPnl}
            />
            {isListView ? <div /> : (
              <BacktestsDetails
                {...commonProps}
                {...backtestDetailsProps}
                algo_name={algo_name}
              />
            )}
            {mtfExist && !pinCumulativePnl && (
            <div
              className={styles.mtfContainer}
              id="mtfContainer"
              style={{
                display: mtfContainerStyles === 'none' ? 'none' : 'block',
              }}
            >
              {renderMTFCumuPNL()}
            </div>
            )}
            {!isFetchingBacktests && !pinCumulativePnl && (
              renderCumulativePnl()
            )}
          </>
        )}
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    margin: `0 0 ${SPACING.SPACE_20}`,
  },
  content: {
    '&:hover  $menuBtn': {
      '& svg': {
        fill: theme.linkColor,
      },
    },
  },
  menuBtn: {},
  footer: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
  },
  footerContent: {},
  footerHeader: {
    padding: `0 ${SPACING.SPACE_14}`,
  },
  strategyDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  entryCard: {
    flex: 0.48,
  },
  exitCard: {
    flex: 0.48,
  },
  algoParamsContainer: {},
  screenHeader: {
    // marginRight: SPACING.SPACE_20,
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
    backgroundColor: theme.bgPrimary,
    borderRadius: 10,
    boxShadow: `0px 7px 20px ${theme.borderColor}`,
  },
  emptyContainerDark: {
    boxShadow: 'none',
  },
  emptyContent: {
    textAlign: 'center',
  },
  emptyImg: {
    height: 100,
    paddingRight: SPACING.SPACE_20,
  },
  emptyImgContainer: {
    marginRight: '10%',
  },
  emptyAction: {
    textAlign: 'center',
  },
  addStockBtn: {
    marginTop: SPACING.SPACE_12,
  },
  addStock: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_16}`,
  },
  deployBtn: {
    display: 'none',
  },
  gain: {
    color: `${COLORS.GREEN} !important`,
  },
  loss: {
    color: `${COLORS.RED} !important`,
  },
  // mtf disc styles
  mtfContainer: {
    width: '100%',
    marginTop: SPACING.SPACE_20,
    padding: SPACING.SPACE_20,
    position: 'absolute',
    zIndex: 9997,
    background: theme.bgPrimary,
    userSelect: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subHeaderMobile: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: SPACING.SPACE_10,
    borderRight: `1px solid ${theme.borderColor}`,
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: SPACING.SPACE_10,
    flex: 1,
  },
  mtfCell: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: SPACING.SPACE_10,
  },
  mtfContent: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_10}`,
  },
  pnlBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #3CBC00',
  },
  labelBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #000000',
  },
  valueBlur: {
    fontSize: '15px',
    marginLeft: SPACING.SPACE_10,
    color: 'transparent',
    textShadow: ' 0 0 8px #9BABC6',
  },
  showResultBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    background: theme.mtfTitleBg,
  },
  mtfTitle: {
    background: theme.mtfTitleBg,
    padding: SPACING.SPACE_8,
    borderRadius: 8,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      margin: 0,
    },
    strategyDetails: {
      flexDirection: 'column',
    },
    footerContent: {
      borderRadius: 10,
      backgroundColor: theme.bgPrimary,
      boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    },
    entryCard: {
      boxShadow: 'none !important',
      borderBottom: `1px solid ${theme.borderColor}`,
      padding: `${SPACING.SPACE_20} 0`,
      margin: `0 ${SPACING.SPACE_14}`,
      borderRadius: 0,
    },
    exitCard: {
      boxShadow: 'none !important',
      borderBottom: `1px solid ${theme.borderColor}`,
      padding: `${SPACING.SPACE_20} 0`,
      margin: `0 ${SPACING.SPACE_14}`,
      borderRadius: 0,
    },
    algoParamsContainer: {
      boxShadow: 'none !important',
      marginTop: 0,
    },
    screenHeader: {
      margin: 0,
    },
    deployBtn: {
      display: 'unset',
    },
  },
  '@media only screen and (max-width: 610px)': {
    mtfTitle: {
      width: 'calc(100% - 40px)',
    },
  },
})

export default BacktestShared
