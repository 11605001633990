import dayjs from 'dayjs'
import { ICONS, ASSETS, COLORS } from '../../Theme'
import { PRECISION } from '../../utils/consts'

const {
  ALL, TREND_FOLLOWING, PRICE_ACTION, MEAN_REVERSION, MOMENTUM,
  MISCELLANEOUS, PIVOT_POINTS, OVERLAYS, OPTIONS_STRATEGIES, INTRADAY,
  SWING_TRADING, POSITION_TRADING, THE_MILLENNIALS, TECH, LEADING, ATR, MTF,

  ALL_OB, TREND_FOLLOWING_OB, PRICE_ACTION_OB, MEAN_REVERSION_OB, MOMENTUM_OB,
  MISCELLANEOUS_OB, PIVOT_POINTS_OB, OVERLAYS_OB, OPTIONS_STRATEGIES_OB, INTRADAY_OB,
  SWING_TRADING_OB, POSITION_TRADING_OB, THE_MILLENNIALS_OB, TECH_OB, LEADING_OB, ATR_OB, MTF_OB,
} = ASSETS

export const DISOVER_CATEGORY_ICON_MAP = isDark => ({
  ALL: {
    NAME: ICONS.ALL,
    COLOR: isDark ? '#23233C' : '#F4E5FD',
    TEXT_COLOR: '#A585B9',
    CHIP_COLOR: '#0001fc',
    IMG_OB: ALL_OB,
    IMG: ALL,
  },
  'TREND FOLLOWING': {
    NAME: ICONS.TREND_FOLLOWING,
    COLOR: isDark ? '#251F29' : '#F4E5FD',
    TEXT_COLOR: '#A585B9',
    CHIP_COLOR: '#b231ff', // CATEGORY_GREEN,
    IMG_OB: TREND_FOLLOWING_OB,
    IMG: TREND_FOLLOWING,
  },
  'PRICE ACTION': {
    NAME: ICONS.PRICE_ACTION,
    COLOR: isDark ? '#2E3C2C' : '#E3FFE0',
    TEXT_COLOR: '#88C182',
    CHIP_COLOR: '#19db00', // CATEGORY_YELLOW,
    IMG_OB: PRICE_ACTION_OB,
    IMG: PRICE_ACTION,
  },
  'MEAN REVERSION': {
    NAME: ICONS.MEAN_REVERSION,
    COLOR: isDark ? '#273233' : '#D3FCFF',
    TEXT_COLOR: '#57ABB2',
    CHIP_COLOR: '#08c8d8', // CATEGORY_GREEN,
    IMG_OB: MEAN_REVERSION_OB,
    IMG: MEAN_REVERSION,
  },
  MOMENTUM: {
    NAME: ICONS.MOMENTUM,
    COLOR: isDark ? '#332B28' : '#FEF2EC',
    TEXT_COLOR: '#A77258',
    CHIP_COLOR: '#ff8141', // CATEGORY_YELLOW,
    IMG_OB: MOMENTUM_OB,
    IMG: MOMENTUM,
  },
  MISCELLANEOUS: {
    NAME: ICONS.MISCELLANEOUS,
    COLOR: isDark ? '#483331' : '#FFEEED',
    TEXT_COLOR: '#BA8481',
    CHIP_COLOR: '#e63c32', // CATEGORY_BLUE,
    IMG_OB: MISCELLANEOUS_OB,
    IMG: MISCELLANEOUS,
  },
  'PIVOT POINTS': {
    NAME: ICONS.PIVOT_POINTS,
    COLOR: isDark ? '#2B433B' : '#DDFFF4',
    TEXT_COLOR: '#8BC5B2',
    CHIP_COLOR: '#5dffcc', // CATEGORY_BLUE,
    IMG_OB: PIVOT_POINTS_OB,
    IMG: PIVOT_POINTS,
  },
  OVERLAYS: {
    NAME: ICONS.OVERLAYS,
    COLOR: isDark ? '#2D2D41' : '#E8E8FF',
    TEXT_COLOR: '#A8A8D4',
    CHIP_COLOR: '#6060ff', // CATEGORY_ORANGE,
    IMG_OB: OVERLAYS_OB,
    IMG: OVERLAYS,
  },
  'OPTIONS STRATEGIES': {
    NAME: ICONS.MISCELLANEOUS,
    COLOR: isDark ? '#2D2D41' : '#E8E8FF',
    TEXT_COLOR: '#9898C9',
    CHIP_COLOR: '#1d1efd', // CATEGORY_ORANGE,
    IMG_OB: OPTIONS_STRATEGIES_OB,
    IMG: OPTIONS_STRATEGIES,
  },
  INTRADAY: {
    NAME: ICONS.INTRADAY,
    COLOR: isDark ? '#2E4243' : '#D3FCFF',
    TEXT_COLOR: '#82BFC4',
    CHIP_COLOR: '#08c8d8', // CATEGORY_ORANGE,
    IMG_OB: INTRADAY_OB,
    IMG: INTRADAY,
  },
  'SWING TRADING': {
    NAME: ICONS.INTRADAY,
    COLOR: isDark ? '#483331' : '#FFEEED',
    TEXT_COLOR: '#BA8481',
    CHIP_COLOR: '#e63c32', // CATEGORY_ORANGE,
    IMG_OB: SWING_TRADING_OB,
    IMG: SWING_TRADING,
  },
  'POSITION TRADING': {
    NAME: ICONS.INTRADAY,
    COLOR: isDark ? '#2E412C' : '#E3FFE0',
    TEXT_COLOR: '#88C182',
    CHIP_COLOR: '#19db00', // CATEGORY_ORANGE,
    IMG_OB: POSITION_TRADING_OB,
    IMG: POSITION_TRADING,
  },
  'THE MILLENNIALS': {
    NAME: ICONS.INTRADAY,
    COLOR: isDark ? '#372B3E' : '#F4E5FD',
    TEXT_COLOR: '#A585B9',
    CHIP_COLOR: '#b231ff', // CATEGORY_ORANGE,
    IMG_OB: THE_MILLENNIALS_OB,
    IMG: THE_MILLENNIALS,
  },
  TECH: {
    NAME: ICONS.TECH,
    COLOR: isDark ? '#2E4243' : '#D3FCFF',
    TEXT_COLOR: '#82BFC4',
    CHIP_COLOR: '#58dce7', // CATEGORY_ORANGE,
    IMG_OB: TECH_OB,
    IMG: TECH,
  },
  LEADING: {
    NAME: ICONS.LEADING,
    COLOR: isDark ? '#332B28' : '#FEF2EC',
    TEXT_COLOR: '#A77258',
    CHIP_COLOR: '#ff8141', // CATEGORY_ORANGE,
    IMG_OB: LEADING_OB,
    IMG: LEADING,
  },
  'ATR TRAILING STOP LOSS': {
    NAME: ICONS.LEADING,
    COLOR: isDark ? '#414A34' : '#F0FFD8',
    TEXT_COLOR: '#88C182',
    CHIP_COLOR: '#a2ed2b', // CATEGORY_ORANGE,
    IMG_OB: ATR_OB,
    IMG: ATR,
  },
  'MULTI TIME FRAME ANALYSIS': {
    NAME: ICONS.LEADING,
    COLOR: isDark ? '#2B433B' : '#DDFFF4',
    TEXT_COLOR: '#7BBAA5',
    CHIP_COLOR: '#31f7b7', // CATEGORY_ORANGE,
    IMG_OB: MTF_OB,
    IMG: MTF,
  },
})

export const ALGO_PAGE_LIMIT = 6
export const DISCOVER_PAGE_LIMIT = 6
export const CONDENSED_ALGO_PAGE_LIMIT = 8 // Math.floor(WIDTH / 120)
export const CONDENSED_ALGO_LIMIT = 10
export const ONBOARDING_ALGO_LIMIT = 10
export const CONDENSED_TOP_PERFORMER_LIMIT = 6

export const ALGO_KEY_MAP = {
  MY_ALGOS: 'algo_uuid',
  DISCOVER: 'publishing_uuid',
  SUBSCRIBED: 'algo_subscription_uuid',
  SHARED_ALGOS: 'backtest_shared_uuid',
}

export const ALGO_SORT_MAP = {
  PNL_PCT: {
    LABEL: 'Percentage P&L', SORT_KEY: 'absolute_pnl_pct', ASCENDING: true, ID: 'PNL_PCT',
  },
  PNL_ABS: {
    LABEL: 'Absolute P&L', SORT_KEY: 'absolute_pnl', ASCENDING: true, ID: 'PNL_ABS',
  },
  WINLOSS_RATIO: {
    LABEL: 'Win Loss Ratio', SORT_KEY: 'avg_win_loss_ratio', ASCENDING: true, ID: 'WINLOSS_RATIO',
  },
  WIN_STREAK: {
    LABEL: 'Average Winning Streak', SORT_KEY: 'avg_winning_streak', ASCENDING: true, ID: 'WIN_STREAK',
  },
}

export const TOP_PERFORMER_SORT_MAP = {
  PNL_PCT: {
    LABEL: 'Percentage P&L', SORT_KEY: 'today_ret', ASCENDING: true, ID: 'PNL_PCT',
  },
  PNL_ABS: {
    LABEL: 'Absolute P&L', SORT_KEY: 'today_pnl', ASCENDING: true, ID: 'PNL_ABS',
  },
  WINLOSS_RATIO: {
    LABEL: 'Win Loss Ratio', SORT_KEY: 'avg_win_loss_ratio', ASCENDING: true, ID: 'WINLOSS_RATIO',
  },
  WIN_STREAK: {
    LABEL: 'Average Winning Streak', SORT_KEY: 'avg_winning_streak', ASCENDING: true, ID: 'WIN_STREAK',
  },
}

export const discoverDisclaimer = "Please note, discover strategies are meant for educational purposes and are not to be construed as recommendation. Backtest results are hypothetical results generated based on the conditions used on historical data, and don't represent actual returns or future returns."

export const backtestDisclaimer = "Backtest results are hypothetical results generated based on the conditions used on historical data, and don't represent actual returns or future returns."

// nudge rules utils
export const nudgeText = {
  1: {
    text: 'Your Square Off count is on the higher side, one possible way to avoid this is to use time based exit conditions. Read more about time-based conditions ',
    color: COLORS.NUDGE_SO_RULE,
    link: 'https://blog.streak.tech/eliminate-square-offs-with-an-exit-condition/',
  },
  2:
  {
    text: 'Your number of trades per day seems to be too high, this could possibly be considered as over-trading. To reduce the number of trades per day, you could reduce the number of trading cycles or increase the candle time intervals (ex: instead of 1 min candle use 15 min candle and so on). Read more about Over-trading ',
    color: COLORS.NUDGE_TPD_RULE,
    link: 'https://blog.streak.tech/control-over-trading-reduce-trades-to-reduce-the-transaction-costs/',
  },
  3: {
    text: 'Your SL/TP seems to be too narrow. Read more about SL/TP ',
    color: COLORS.NUDGE_SL_RULE,
    link: 'https://blog.streak.tech/stop-loss-and-target-levels-for-trading-strategies/',
  },
  4: {
    text: 'Your Entry condition seems to be too generic. Read more about how to create effective  Entry conditions ',
    color: COLORS.NUDGE_EN_RULE,
    link: 'https://blog.streak.tech/avoid-using-generic-entry-conditions/',
  },
  5: {
    text: 'Even though your Backtest P&L seems to be positive, your backtesting parameters suggest an irregular strategy performance and may not be profitable when deployed. Read more about backtesting parameters ',
    color: COLORS.NUDGE_LW_RULE,
    link: 'https://blog.streak.tech/factors-to-consider-while-assess-your-trading-strategy/',
  },
}

//* TC: time complexity O(N) where N is len of tradeLog
export const tradeLogCalculations = (tradeLog = [], key) => {
  const triggers = new Map()
  const dayTriggers = new Map()

  tradeLog?.forEach((item) => {
    const trigger = key === 'SO' ? item[7] : dayjs(item[0]).format('YYYY-MM-DD')

    if (key === 'SO') {
      triggers.set(trigger, (triggers.get(trigger) || 0) + 1)
    } else {
      dayTriggers.set(trigger, (dayTriggers.get(trigger) || 0) + 1)
    }
  })

  switch (key) {
    case 'SO':
      return Object.fromEntries(triggers)

    case 'dayTriggers':
      return Object.fromEntries(dayTriggers)

    default:
      return null
  }
}

// nudge rule 1
//* TC: time complexity is O(1) (constant)
export const exitSOCount = ({
  SO = 0, SL = 0, EX = 0, TP = 0, TSL = 0, EXP = 0,
}) => {
  const MIN_EXIT_COUNT_PERCENTAGE = 0.2
  const allExits = SO + SL + EX + TP + TSL + EXP
  const allExitsPerc = allExits * MIN_EXIT_COUNT_PERCENTAGE
  return SO > allExitsPerc
}

// nudge rule 2
//* TC: time complexity O(N) where N is len of tradeLog
export const tradePerDay = (trade_log) => {
  const dayTriggers = tradeLogCalculations(trade_log, 'dayTriggers')

  const MAX_TRADES_PER_DAY = 20
  const filteredData = {}

  const hasExceededMaxTrades = Object.entries(dayTriggers).some(([key, value]) => {
    if (value / 2 > MAX_TRADES_PER_DAY) {
      filteredData[key] = value / 2
      return true
    }
    return false
  })

  return hasExceededMaxTrades
}

// nudge rule 3
//* TC: time complexity O(N) where N is len of tradeLog
export const slTpRuleCal = (tradeLog = [], interval = '') => {
  let count = 0
  let exitCount = 0
  const exitsThreshold = 0.2
  const parsedInterval = parseInterval(interval)

  for (let i = 0; i < tradeLog?.length - 1; i += 2) {
    const currentLog = tradeLog[i]
    const nextLog = tradeLog[i + 1]

    if (nextLog[7] !== 'EN') {
      exitCount++
    }

    if (
      currentLog[7] === 'EN'
  && (nextLog[7] === 'SL' || nextLog[7] === 'TP')
    ) {
      const entryTime = new Date(currentLog[0])
      const slTpTime = new Date(nextLog[0])
      const timeDifference = Math.abs(entryTime - slTpTime)
      if ((timeDifference === parsedInterval)
      || (timeDifference === 2 * parsedInterval)) {
        count++
      }
    }
  }
  return count > exitCount * exitsThreshold
}

// nudge rule 4
//* TC: time complexity O(N) where N is len of tradeLog
export const entryRuleCal = (tradeLog, interval = '') => {
  let count = 0
  let entryCount = 0
  let exitTime = null
  const entryThreshold = 0.2
  const parsedInterval = parseInterval(interval)
  const allExits = ['SO', 'SL', 'EX', 'TP', 'TSL', 'EXP']

  for (let i = 1; i < tradeLog?.length; i += 2) {
    const exitLog = tradeLog[i]
    const entryLog = tradeLog[i + 1]

    if (entryLog) {
      entryCount++
    }
    if (allExits.includes(exitLog[7])) {
      exitTime = new Date(exitLog[0])
    }
    if (entryLog && exitTime !== null) {
      const entryTime = new Date(entryLog[0])
      const timeDifference = Math.abs(entryTime - exitTime)
      if (timeDifference === parsedInterval) {
        count++
      }

      exitTime = null // Reset exitTime for the next iteration
    }
  }
  return count > entryCount * entryThreshold
}

// nudge rule 5
//* TC: time complexity is O(1) (constant)
export const lsCount = (avg_loss = 0, avg_win = 0,
  lossing_streak = 0, winning_streak = 0, final_pnl = 0) => {
  const avgWinRounded = avg_win.toFixed(PRECISION)
  const avgLossRounded = Math.abs(avg_loss.toFixed(PRECISION))
  const isLsWsCount = lossing_streak > 1.5 * winning_streak
  && avgLossRounded > avgWinRounded && final_pnl > 0

  return isLsWsCount
}

function parseInterval(interval) {
  let timeFrame = interval

  if (timeFrame === 'min') {
    timeFrame = '1min'
  }

  const number = parseInt(timeFrame, 10)

  if (Number.isNaN(number)) {
    return null
  }

  const unit = timeFrame.slice(-3)

  const multipliers = {
    min: 60000, // 1 minute = 60,000 milliseconds
    hour: 3600000, // 1 hour = 3,600,000 milliseconds
    day: 86400000, // 1 day = 86,400,000 milliseconds
  }

  const multiplier = multipliers[unit]

  if (multiplier === undefined) {
    return null
  }

  return number * multiplier
}

export const getNudgeRules = (backtest_result, seg_sym) => {
  const {
    trade_log = [], lossing_streak = 0, winning_streak = 0, final_pnl = 0,
    average_gain_per_losing_trade: avg_loss = 0, average_gain_per_winning_trade: avg_win = 0,
    run_params = {},
  } = backtest_result[seg_sym]
  const { time_frame } = run_params
  const triggers = tradeLogCalculations(trade_log, 'SO')
  const nudgeIndexes = []

  const conditions = [
    [exitSOCount(triggers), 1],
    [tradePerDay(trade_log), 2],
    [slTpRuleCal(trade_log, time_frame), 3],
    [entryRuleCal(trade_log, time_frame), 4],
    [lsCount(avg_loss, avg_win, lossing_streak, winning_streak, final_pnl), 5],
  ]

  nudgeIndexes.push(...conditions.filter(([condition]) => condition)
    .map(([, index]) => index))

  return nudgeIndexes
}
