import { connect } from 'react-redux'

import Funds from './Funds'

import { logout } from '../../containers/Auth/actions'
import { fetchFunds } from '../../containers/Dashboard/actions'

const mapStateToProps = (state) => {
  return {
    ...state.auth.loginResp,
    user_details: state.auth.user_details,

    funds: state.dashboard.funds,
    commodity_funds: state.dashboard.commodity_funds,
    fno_funds: state.dashboard.fno_funds,
    isFetchingFunds: state.dashboard.isFetchingFunds,
    fundsError: state.dashboard.fundsError,
    fundsErrorMsg: state.dashboard.fundsErrorMsg,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch((logout())),
    fetchFunds: () => dispatch(fetchFunds()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Funds)
