// DANGER remove cycle dep
/* eslint-disable import/no-cycle */
import React, { lazy } from 'react'
import {
  Route,
} from 'react-router-dom'

import { pathname_mapping } from './utils/navigationConstant'

const Dashboard = lazy(() => import('./containers/Dashboard/DashBoard-redux'))
// const Backtests = lazy(() => import('./containers/Backtests/Backtests-redux'))
const Strategies = lazy(() => import('./containers/Strategies/Strategies-redux'))
const MyStrategy = lazy(() => import('./containers/Algos/Algos-redux'))
const MyPicks = lazy(() => import('./containers/MarketPlace/Subscribed-redux'))
const Marketwatch = lazy(() => import('./containers/Marketwatch/Marketwatch-redux'))
// const Scans = lazy(() => import('./containers/Scanner/Scans-redux'))
const MyScans = lazy(() => import('./containers/Scans/MyScans'))
const LiveScans = lazy(() => import('./containers/Scans/LiveScans'))
const Trades = lazy(() => import('./containers/Trades/Trades'))
const Profile = lazy(() => import('./containers/Profile'))
const Create = lazy(() => import('./containers/Create/Create-redux'))
const CreatePro = lazy(() => import('./containers/CreatePro/CreatePro-redux'))
const Scanner = lazy(() => import('./containers/Screener/Screener-redux'))
const Notifications = lazy(() => import('./containers/Notifications/Notifications-redux'))
const DeleteProfile = lazy(() => import('./containers/DeleteProfile/DeleteProfile'))
// rendered in route /algos
// const Deployed = lazy(() => import('./containers/Deployed/Deployed-redux'))

// components
const SymbolDetails = lazy(() => import('./components/Marketwatch/SymbolDetails'))
const InProgressAlgos = lazy(() => import('./components/InProgressAlgos'))
const GraphContainer = lazy(() => import('./components/Backtests/Graph'))
const Charts = lazy(() => import('./components/Scans/Charts'))

export const authRoutes = [
  { Component: Dashboard, path: pathname_mapping.Dashboard, exact: true },
  // { Component: Backtests, path: pathname_mapping.Backtests },
  { Component: Strategies, path: pathname_mapping.Strategies, exact: true },
  { Component: MyStrategy, path: pathname_mapping.MyStrategy, exact: true },
  { Component: MyPicks, path: pathname_mapping.MyPicks, exact: true },
  { Component: Marketwatch, path: pathname_mapping.marketwatch, exact: true },
  { Component: SymbolDetails, path: pathname_mapping.symbolDetails, exact: true },
  { Component: Strategies, path: pathname_mapping.Deployed, exact: true },
  // { Component: Scans, path: pathname_mapping.Scanners, exact: true },
  { Component: MyScans, path: pathname_mapping.my_scans, exact: true },
  { Component: LiveScans, path: pathname_mapping.live_scans, exact: true },
  { Component: Trades, path: pathname_mapping.Trades },
  { Component: Profile, path: pathname_mapping.Account },
  { Component: Create, path: pathname_mapping.Create, exact: true },
  { Component: CreatePro, path: `${pathname_mapping.Create}/:id` },
  { Component: InProgressAlgos, path: pathname_mapping.InProgress },
  { Component: Notifications, path: pathname_mapping.Notifications },
  { Component: Scanner, path: pathname_mapping.NewScanner },
  { Component: GraphContainer, path: pathname_mapping.Graph, exact: true },
  { Component: Charts, path: pathname_mapping.Chart, exact: true },
  { Component: DeleteProfile, path: pathname_mapping.DeleteProfile, exact: true },
]

const AuthenticatedRoutes = () => {
  return (
    authRoutes.map((item) => {
      const { Component, path, exact } = item
      return (
        <Route
          key={path}
          exact={exact}
          path={path}
          component={Component}
        />
      )
    })
  )
}

export default AuthenticatedRoutes
