/* eslint-disable */
import React, { Component } from 'react'
import classnames from 'classnames'
import { debounce, isEqual, upperCase } from 'lodash'

import CustomText from '../../UI/CustomText'
import Button from '../../UI/Button'
import CustomInput from '../../UI/CustomInput'
import Checkbox from '../../UI/Checkbox'
import CustomRadio from '../../UI/CustomRadio'
import { showSnackbar } from '../../UI/Snackbar'
import ShowLTP, { CURRENCY_SYM } from '../../UI/ShowLTP'
import {
  ASSETS,
  COLORS, FONTS, ICONS, SPACING, theme,
} from '../../Theme'
import { BUY_SELL_TYPE_MAP } from './buySellUtils'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import { withTheme } from '../../Theme/ThemeProvider'
import { BROKERS, PRECISION } from '../../utils/consts'
import Icon from '../../UI/Icon'
import CustomTooltip from '../../UI/CustomTooltip'
import HighlightedText from '../../UI/HighlightedText'
import DotLoader from '../../UI/DotLoader'
import CustomModal from '../../UI/CustomModal'
import { getFinalSymbol, segSymConverter } from '../../utils/common'

// const { Weekly } = ASSETS

class BuySell extends Component {
  constructor(props) {
    super(props)
    // this.tabs = ['MARKET', 'LIMIT']
    const {
      buySellType, showViewHistory = false,
      user_details, isMobile, item, openPositionsData,
      stockDetails,
    } = props
    const { broker } = user_details
    const direct = buySellType === BUY_SELL_TYPE_MAP.DIRECT.TYPE
    let renderType = ''
    let action_type = ''
    let algo_name = ''
    let order_type = 'MARKET'
    let exchange = ''
    let seg = ''
    let product = ''
    let symbol = ''
    let sym = ''
    let trigger_price = 0
    let quantity = 0
    let segment = ''
    let qty = ''
    let variety = 'REGULAR'
    let last_order_average_price = ''
    let seg_sym = props.seg_sym
    let target_profit = 0
    let stop_loss = 0
    let stoploss = 0
    let squareoff = 0
    let advance_visible = false
    let disabledOrderType = ['SL', 'SL-M']
    let type = ''
    this.showViewHistory = showViewHistory
    const trailing_stoploss = '0'
    if (Object.keys(openPositionsData).length > 0) {
      ({
        algo_obj: { action_type, target_profit, stop_loss },
        algo_name,
        variety = 'REGULAR',
        seg_sym,
      } = stockDetails);
      ({
        order_type = 'MARKET',
        seg,
        sym,
        product = 'MIS',
        variety = 'REGULAR',
        positions: { qty, last_order_average_price },
      } = openPositionsData)
      renderType = Number.parseFloat(qty) >= 0 ? 'SELL' : 'BUY'
      quantity = Math.abs(qty)
      exchange = seg
      segment = seg
      symbol = sym
      trigger_price = +last_order_average_price
    } else {
      ({
        action_type = '',
        algo_name,
        product = 'MIS',
        symbol,
        order_type = 'MARKET',
        quantity,
        seg,
        segment,
        exchange,
        seg_sym,
        type,
        stop_loss = 0,
        target_profit = 0,
        renderType,
        trigger_price = +trigger_price,
      } = item)
      quantity = Math.abs(quantity)
      seg = seg || exchange
      renderType = action_type.toLowerCase() === 'sell' ? 'SELL' : 'BUY'
    }
    const finalSegment = segment.toLowerCase().includes('cds')
    const precision = finalSegment ? 4 : PRECISION
    this.defaultTrigger = Number(trigger_price).toFixed(precision)
    this.productLabels = ['MIS', 'CNC']
    const fSeg = segment || seg || exchange
    if (fSeg && !['NSE', 'BSE'].includes(fSeg.toUpperCase())) {
      product = product && product.toLowerCase() === 'cnc' ? 'NRML' : product
      this.productLabels = ['MIS', 'NRML']
    }
    if (variety === 'BO') {
      advance_visible = true
      stoploss = (((+trigger_price) * stop_loss) / 100).toFixed(2)
      squareoff = (((+trigger_price) * target_profit) / 100).toFixed(2)
      order_type = 'LIMIT'
    }
    this.disabledVariety = ['AMO', 'CO']
    if (segment === 'MCX' || type === 'entered' || type === 'portfolio') {
      this.disabledVariety.push('BO')
      variety = 'REGULAR'
    }
    if (product !== 'MIS') {
      advance_visible = false
    }
    if (order_type === 'SL-M' || order_type === 'SL') {
      disabledOrderType = ['MARKET', 'LIMIT', 'SL', 'SL-M']
      advance_visible = false
    }
    let final_price = Number(this.defaultTrigger) || '0'
    if (order_type === 'SL' && broker !== BROKERS.ZERODHA.name) {
      if (renderType === 'BUY') {
        final_price += 0.05
      } else if (renderType === 'SELL') {
        final_price -= 0.05
      }
    }
    this.state = {
      price: Number(final_price).toFixed(precision),
      seg_sym,
      renderType,
      action_type,
      algo_name,
      order_type,
      advance_visible,
      exchange,
      seg,
      product,
      symbol,
      trigger_price: this.defaultTrigger,
      quantity,
      segment,
      qty,
      orderSuccess: false,
      showConfirmModal: false,
      direct,
      variety,
      stop_loss,
      target_profit,
      stoploss,
      squareoff,
      trailing_stoploss,
      disabledOrderType,
      dematConsentChecked: false,
      showChargesTooltip: !isMobile,
      buyBtnPressed: false,
      lotSize: quantity || 1,
      ltp: 0,
    }
  }

  componentDidMount() {
    const { getScripLotSizeData } = this.props
    const { seg_sym } = this.state
    const { segment = '' } = segSymConverter(seg_sym)

    if (seg_sym) getScripLotSizeData(seg_sym)

    if (segment === 'NSE') this.fetchOrderCharges()
  }

  componentDidUpdate(prevProps, prevState) {
    const { scriptLotSize = [] } = this.props
    const { order_type, ltp, direct } = this.state
    const prevStateParams = { ...prevState }
    delete prevStateParams.showChargesTooltip

    const thisStateParams = { ...this.state }
    delete thisStateParams.showChargesTooltip
    // excluding ltp from fetching order charges
    const { ltp: ltpPrev, ...prevStateParamsWithoutLtp } = prevStateParams
    const { ltp: currentLtp, ...thisStateParamsWithoutLtp } = thisStateParams


    if (!isEqual(prevStateParamsWithoutLtp, thisStateParamsWithoutLtp)) {
      this.fetchOrderCharges()
    }
    if (!isEqual(prevProps.scriptLotSize, scriptLotSize) && direct) {
      this.updateLotSize()
      // this.setState({ lotSize: scriptLotSize[0]?.lot_size })
    }
    if (order_type === 'LIMIT' && prevState.order_type !== 'LIMIT') {
      this.setState({ price: ltp })
    }
    if (order_type === 'SL' && prevState.order_type !== 'SL') {
      this.setState({ price: ltp, trigger_price: ltp })
    }
    if (order_type === 'SL-M' && prevState.order_type !== 'SL-M') {
      this.setState({ trigger_price: ltp })
    }
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  updateLotSize = () => {
    const { scriptLotSize = [] } = this.props
    const { symbol } = this.state

    scriptLotSize.forEach((item) => {
      if (item.symbol === symbol && (item.segment === 'NFO-OPT' || item.segment === 'NFO-FUT')) {
        this.setState({ lotSize: item.lot_size }, () => {
          this.fetchOrderCharges()
        })
      }
    })
  }

  onChange = (ev, field) => {
    const { target: { value } } = ev
    const {
      trigger_price,
      stop_loss,
      target_profit,
      segment,
      order_type,
      variety,
    } = this.state
    let changes = {}
    // if (field === 'product' && (order_type === 'SL' || order_type === 'SL-M')) {
    //   return
    // }
    // if (segment === 'MCX' && field === 'variety' && value === 'BO') {
    //   return
    // }
    if (field === 'variety' && value === 'BO') {
      const disabledOrderType = ['MARKET', 'LIMIT']
      changes = {
        product: 'MIS',
        order_type: 'LIMIT',
        disabledOrderType,
        stoploss: (((+trigger_price) * stop_loss) / 100).toFixed(2),
        squareoff: (((+trigger_price) * target_profit) / 100).toFixed(2),
        advance_visible: true,
      }
    }
    if (field === 'product' && value !== 'MIS' && variety === 'BO') {
      changes = {
        variety: 'REGULAR',
        advance_visible: false,
        disabledOrderType: [],
      }
    }
    if (field === 'variety' && value === 'REGULAR') {
      const disabledOrderType = []
      changes = { disabledOrderType, advance_visible: false }
    }
    this.setState({ [field]: value.toString(), ...changes })
  }

  onSelected = (field, value) => {
    const {
      trigger_price,
      stop_loss,
      target_profit,
      segment,
      variety,
      direct,
    } = this.state
    let changes = {}
    if (segment === 'MCX' && field === 'variety' && value === 'BO') {
      return
    }
    if (variety === 'BO' && (value === 'MARKET' || value !== 'MIS')) {
      changes = {
        order_type: 'LIMIT',
        product: 'MIS',
      }
    }
    if (field === 'variety' && value === 'BO') {
      const disabledOrderType = ['MARKET', 'LIMIT', 'SL', 'SL-M']
      changes = {
        product: 'MIS',
        order_type: 'LIMIT',
        disabledOrderType,
        stoploss: (((+trigger_price) * stop_loss) / 100).toFixed(2),
        squareoff: (((+trigger_price) * target_profit) / 100).toFixed(2),
      }
    }
    if (field === 'variety' && value === 'REGULAR') {
      const disabledOrderType = direct ? [] : ['SL', 'SL-M']
      changes = { disabledOrderType }
    }
    if (field === 'order_type' && value !== 'SL' && value !== 'SL-M') {
      changes = {
        ...changes,
        trigger_price: this.defaultTrigger,
        price: this.defaultTrigger,
      }
    }
    this.setState({ [field]: value.toString(), ...changes })
  }

  toggleAdvance = () => {
    this.setState(prevState => ({ advance_visible: !prevState.advance_visible }))
  }

  fetchOrderCharges = debounce(() => {
    const { sessionid, csrf } = this.props
    const {
      exchange = '', symbol, renderType, product, quantity,
      trigger_price, order_type, price, variety, seg, segment, lotSize
    } = this.state
    const { fetchOrderCharges, posProduct } = this.props
    const authorization = `csrftoken=${csrf};csrfmiddlewaretoken=${csrf};sessionid=${sessionid}`

    const params = {
      broker: 'zerodha',
      tradingsymbol: symbol,
      exchange: exchange?.toUpperCase() || seg || segment,
      transaction_type: upperCase(renderType),
      quantity: posProduct ? quantity : lotSize || quantity,
      product,
      trigger_price,
      price,
      disclosed_quantity: posProduct ? quantity : lotSize || quantity,
      validity: 'DAY',
      order_type,
      variety,
      csrfmiddlewaretoken: csrf,
      platform: 'openstreak'
    }
    fetchOrderCharges(params, { Authorization: authorization })
  }, 250)

  orderConfirmed = () => {
    const { placeOrder, demat_consent, scriptLotSize = [], posProduct } = this.props
    const {
      order_type,
      product,
      variety,
      price,
      stoploss,
      squareoff,
      trailing_stoploss,
      quantity,
      dematConsentChecked,
      renderType,
      trigger_price,
      lotSize,
    } = this.state
    const finalQuantity = posProduct ? quantity : lotSize
    const oneLot = scriptLotSize[0]?.lot_size || 1

    let params = {
      order_type,
      product,
      price,
      variety,
      stoploss,
      squareoff,
      quantity: finalQuantity,
      trailing_stoploss: (+trailing_stoploss).toFixed(0),
      platform: 'openstreak',
    }


    if (order_type === 'SL' || order_type === 'SL-M') {
      params = { ...params, trigger_price }
    }

    // const { broker } = user_details
    if (Number(lotSize) % oneLot === Number(lotSize)) {
      showSnackbar(`Value must be greater than or equal to ${oneLot}`)
      return
    }
    if (Number(lotSize) % oneLot !== 0) {
      showSnackbar(`Please enter a valid value. Quantity should be in multiples of ${oneLot}`)
      return
    }
    if (renderType === 'SELL' && product === 'CNC'
      && demat_consent === 'consent' && !dematConsentChecked) {
      showSnackbar('Please select the checkbox to proceed with the order')
      // this.setState({buyBtnPressed: false})
      return
    }
    if (order_type === 'SL') {
      const priceNum = Number(price)
      const triggerPriceNum = Number(trigger_price)
      if (renderType === 'BUY' && triggerPriceNum > priceNum) {
        showSnackbar('Price should be greater than trigger price')
        return
      } if (renderType === 'SELL' && triggerPriceNum < priceNum) {
        showSnackbar('Price should be less than trigger price')
        return
      }
    }
    placeOrder(params)
  }

  handleOrderTypeChange = (item) => {
    this.onSelected('order_type', item)
  }

  closeShowCharges = () => {
    this.setState({ showChargesTooltip: false }, () => {
      const { isMobile } = this.props
      if (isMobile) return
      setTimeout(() => {
        this.setState({ showChargesTooltip: true })
      }, 500)
    })
  }

  updatedLTP = (ltp) => {
    try {
      this.setState({ ltp: ltp })
    } catch (error) {
      console.log(error)
    }
  }

  handleViewHistory = () => {
    const { navigation, buySellType, item } = this.props
    const { algo_name, deployment_uuid } = item
    let checkActionable = false
    if (buySellType === BUY_SELL_TYPE_MAP.NOTIFICATION.TYPE) checkActionable = true
    navigation.pop()
    navigation.navigate(NAVIGATIONS.ORDERLOG.name, {
      activeStockDetails: { algo_name },
      deployment_uuid,
      checkActionable,
    })
  }

  renderChargeRow = (label, value) => {
    const { styles } = this.props
    const isTotal = label === 'Total'
    if (!value) return null
    return (
      <div className={styles.chargeRow}>
        <CustomText size="small_0" weight={isTotal ? 'semi_bold' : 'regular'}>{label}</CustomText>
        <CustomText size="small_0" weight={isTotal ? 'semi_bold' : 'medium'}>
          <>
            {CURRENCY_SYM}
            {` ${Number(value).toFixed(2)}`}
          </>
        </CustomText>
      </div>
    )
  }

  renderPopup = () => {
    const { styles, orderCharges = {} } = this.props

    const {
      // ctt,
      // risk_management_fees,
      // sebi_fees,
      // stamp_duty,
      // stt,
      // transaction,
      ctt,
      risk_management_fees,
      sebi_fees,
      stamp_duty,
      stt,
      transaction,
      dp = 0,
      gst = 0,
      brokerage = 0,
      clearing = 0,
      total_charges = 0,
    } = orderCharges
    return (
      <div className={styles.popupContainer}>
        <div className={styles.popupHeader}>
          <HighlightedText
            text="Actual charges at the end of the day may vary and could also include dp charges."
            color="orange"
            styles={{
              padding: SPACING.SPACE_8,
              fontSize: 9,
              borderRadius: 6,
              textAlign: 'left',
            }}
            backgroundColor="orangeLightBg"
          />
          <Button
            iconName="CLOSE"
            iconProps={{ size: 8, color: theme.text }}
            onPress={this.closeShowCharges}
            btnStyles={styles.tooltipCloseBtn}
            roundness={50}
          />
        </div>
        <div className={styles.chargesContainer}>
          {this.renderChargeRow('Transaction', transaction)}
          {this.renderChargeRow('STT', stt)}
          {this.renderChargeRow('CTT', ctt)}
          {this.renderChargeRow('Stamp Duty', stamp_duty)}
          {this.renderChargeRow('SEBI Fees', sebi_fees)}
          {this.renderChargeRow('Risk Management Fees', risk_management_fees)}
          {this.renderChargeRow('Brokerage', brokerage)}
          {this.renderChargeRow('GST', gst)}
          {this.renderChargeRow('Clearing', clearing)}
        </div>
        <div className={styles.totalContainer}>
          {this.renderChargeRow('Total', total_charges)}
        </div>
        {this.renderChargeRow('Dp plan', dp)}
      </div>
    )
  }

  render() {
    const {
      // action_type,
      segment,
      order_type,
      product,
      symbol,
      seg_sym,
      quantity,
      price,
      advance_visible,
      variety,
      stoploss,
      squareoff,
      trailing_stoploss,
      renderType,
      disabledOrderType,
      direct,
      dematConsentChecked,
      trigger_price = 0,
      showChargesTooltip,
      lotSize
      // buyBtnPressed,
    } = this.state
    const {
      cancelOrder,
      showDetails,
      showDetailsPressed,
      openPositionsData,
      demat_consent,
      loading,
      styles,
      isDark,
      orderCharges = {},
      isFetchingOrderCharges,
      orderChargesErr,
      // orderChargesErrMsg,
      isMobile,
      user_details,
      scriptLotSize = [],
      posProduct
    } = this.props
    const oneLot = scriptLotSize[0]?.lot_size || 1
    // const renderType = (action_type && action_type.toUpperCase() === 'BUY') ? 'BUY' : 'SELL'
    const { broker = '' } = user_details
    const SLMOrderTypes = ['SL', 'SL-M']
    const finalSegment = segment.toLowerCase().includes('cds')
    const precision = finalSegment ? 4 : PRECISION
    // const color = renderType === 'BUY' ? 'OPENBLUE' : 'OPENORANGE' 
    const color = SLMOrderTypes.includes(order_type) ? 'YELLOW_600' : renderType === 'BUY' ? 'OPENBLUE' : 'OPENORANGE'
    const varieties = ['REGULAR'] // portfolioPage ? ['REGULAR'] : ['REGULAR', 'BO']
    const adv_text = advance_visible ? 'Hide advanced' : 'Show advanced'
    const amtToShow = order_type === 'MARKET' ? 'At market' : (
      <>
        {CURRENCY_SYM}
        {` ${Number(price).toFixed(precision)}`}
      </>
    )

    const amtToShowSLM = order_type === 'SL-M' ? (
      <>
        {CURRENCY_SYM}
        {` ${Number(trigger_price).toFixed(precision)}`}
      </>
    ) : (
      <>
        {CURRENCY_SYM}
        {` ${Number(price).toFixed(precision)}`}
      </>
    )

    // const finalOptionStyle = {
    //   width: '3.5%',
    //   transform: 'translateY(2px)',
    // }

    const optionsStyles = {
      transform: 'translateY(0px)',
      position: 'relative',
      width: '1rem',
      margin: '0 4px 0 0',
    }
    const {
      symbolName, symbolSup, restSymbol, showOptions,
    } = getFinalSymbol(seg_sym)
    return (
      <>
        <div className={styles.container}>
          <div className={`${styles.heading} ${styles[color.toLowerCase()]}`}>
            <div className={styles.left_heading}>
              <div className={styles.heading_1}>
                <div>
                  {SLMOrderTypes.includes(order_type)
                    && (
                      <CustomText weight="medium" size="medium" color="white" className={styles.slmContainer}>
                        {`${order_type} Order `}
                        <CustomText size="TINY" color="white" className={styles.segment}>{segment}</CustomText>
                        <a
                          href="https://help.streak.tech/deployed/#entered"
                          target="_blank"
                          rel="noreferrer"
                          className={styles.infoIconContainer}
                        >
                          <Icon
                            name={ICONS.INFO}
                            className={styles.infoIcon}
                            color={COLORS.WHITE}
                            size={16}
                          />
                        </a>
                      </CustomText>
                    )}
                  <CustomText weight="medium" size="medium" color="white" className={styles.symbol}>
                    {`${renderType} `}
                    {symbolName}
                    {showOptions && showOptions === 'W' && (
                      <>
                        <sup style={{ fontSize: '75%', marginTop: '0.5em' }}>
                          {symbolSup}
                          <img src={showOptions === 'W' ? ASSETS.Weekly_White : ASSETS.Monthly_White} style={optionsStyles} className="optionsText" alt="w" />
                        </sup>
                        {restSymbol}
                      </>
                    )}
                    {` x ${posProduct ? Math.abs(quantity) : lotSize} Qty`}
                  </CustomText>
                  {SLMOrderTypes.includes(order_type) && (
                    <CustomText weight="medium" size="MEDIUM" color="white">
                      {amtToShowSLM}
                      {` on ${segment}`}
                    </CustomText>
                  )}
                </div>
                <div className={styles.ltpSection}>
                  <CustomText size="medium" weight="medium" color="white">LTP</CustomText>
                  <ShowLTP
                    containerStyles={styles.ltpContainer}
                    ltpStyles={styles.ltp}
                    changeStyles={styles.change}
                    seg_sym={seg_sym}
                    sendLTP={this.updatedLTP}
                    showBrackets
                    disabled
                  />
                </div>
              </div>
              {!SLMOrderTypes.includes(order_type)
                && (
                  <CustomText weight="medium" size="MEDIUM" color="white">
                    {amtToShow}
                    {` on ${segment}`}
                  </CustomText>
                )}
            </div>
            {showDetails ? (
              <Button
                onPress={showDetailsPressed}
                btnStyles={classnames(styles.show_details, styles[`radio_${color.toLowerCase()}`])}
              >
                VIEW HISTORY
              </Button>
            ) : null}
          </div>
          <div className={styles.body}>
            <div className={styles.row}>
              <div className={styles.left}>
                <CustomRadio
                  id="product"
                  labels={this.productLabels}
                  value={product.toUpperCase()}
                  disabled={direct ? [] : this.productLabels}
                  onValueChange={label => this.onSelected('product', label)}
                  color={color.toLowerCase()}
                />
              </div>
              <div className={styles.fields}>
                {posProduct ? <CustomInput
                  label="Qty."
                  disabled={!direct}
                  value={quantity}
                  inputKey="quantity"
                  onChangeText={this.onChange}
                  containerStyles={styles.inputContainer}
                  inputStyles={styles.inputClass}
                  labelClass={styles.labelClass}
                /> :
                  <CustomInput
                    id="qty"
                    label="Qty."
                    disabled={!direct}
                    value={lotSize}
                    inputKey="lotSize"
                    onChangeText={this.onChange}
                    containerStyles={styles.inputContainer}
                    inputStyles={styles.inputClass}
                    labelClass={styles.labelClass}
                  />}
                <CustomInput
                  id="price"
                  label="Price"
                  value={order_type === 'MARKET' || order_type === 'SL-M' ? 0 : price}
                  disabled={order_type === 'MARKET' || order_type === 'SL-M'}
                  inputKey="price"
                  onChangeText={this.onChange}
                  containerStyles={styles.inputContainer}
                  inputStyles={styles.inputClass}
                  labelClass={styles.labelClass}
                />
              </div>
            </div>
            <div className={styles.rowTwo}>
              <div className={styles.right}>
                <CustomRadio
                  id="order_type"
                  labels={['MARKET', 'LIMIT', 'SL', 'SL-M']}
                  disabled={disabledOrderType}
                  value={order_type}
                  onValueChange={this.handleOrderTypeChange}
                  color={color.toLowerCase()}
                />
              </div>
              <div className={styles.fields}>
                <CustomInput
                  label="Trigger price"
                  disabled
                  // disabled={order_type === 'MARKET' || order_type === 'LIMIT'}
                  value={order_type === 'MARKET' || order_type === 'LIMIT' ? 0 : trigger_price}
                  inputKey="trigger_price"
                  onChangeText={this.onChange}
                  containerStyles={styles.inputContainer}
                  inputStyles={styles.inputClass}
                  labelClass={styles.labelClass}
                />
                <CustomInput
                  label="Disclosed qty."
                  disabled
                  value={0}
                  onChangeText={this.onChange}
                  containerStyles={styles.inputContainer}
                  inputStyles={styles.inputClass}
                  labelClass={styles.labelClass}
                />
              </div>
            </div>
          </div>
          {(advance_visible && variety === 'BO') ? (
            <div className={styles.adv_fields}>
              <CustomInput
                label="Stoploss"
                value={stoploss}
                inputKey="stoploss"
                onChangeText={this.onChange}
                containerStyles={styles.inputContainer}
                inputStyles={styles.inputClass}
                labelClass={styles.labelClass}
              />
              <CustomInput
                label="Target"
                value={squareoff}
                inputKey="squareoff"
                onChangeText={this.onChange}
                containerStyles={styles.inputContainer}
                inputStyles={styles.inputClass}
                labelClass={styles.labelClass}
              />
              <CustomInput
                label="Trailing stoploss"
                value={trailing_stoploss}
                inputKey="trailing_stoploss"
                onChangeText={this.onChange}
                containerStyles={styles.inputContainer}
                inputStyles={styles.inputClass}
                labelClass={styles.labelClass}
              />
            </div>
          ) : null}
          {(product.toUpperCase() === 'MIS' && !openPositionsData && order_type !== 'SL-M' && variety === 'BO') ? (
            <button
              type="button"
              onClick={this.toggleAdvance}
            >
              <CustomText className={styles.advance_toggle} weight="regular" color={isDark ? 'white' : 'grey_200'}>{adv_text}</CustomText>
            </button>
          ) : null}
          {(segment.toLowerCase() !== 'nse' && segment.toLowerCase() !== 'indices') &&
            <CustomText className={styles.lotText}>

              {posProduct ? `${Number(Math.abs(quantity) / oneLot).toFixed(2)} lot(s)` :
                `${Number(lotSize / oneLot).toFixed(2)} lot(s)`}
            </CustomText>}
          <div className={styles.footer}>
            {(product.toUpperCase() === 'MIS' && !openPositionsData && order_type !== 'SL-M') ? (
              <div className={styles.variety}>
                <CustomRadio
                  id="variety"
                  labels={varieties}
                  disabled={this.disabledVariety}
                  value={variety}
                  onValueChange={item => this.onSelected('variety', item)}
                  color={color.toLowerCase()}
                />
              </div>
            ) : null}
            <div className={styles.button_section}>
              {(demat_consent === 'consent' && product === 'CNC' && renderType === 'SELL') ? (
                <div className={styles.consentSection}>
                  <Checkbox
                    id="consentCheck"
                    value={dematConsentChecked}
                    onValueChange={() => this.setState({
                      dematConsentChecked: !dematConsentChecked,
                    })}
                    className={styles.consentCheckboxRoot}
                  />
                  <p className={styles.consentText}>
                    I agree to the debit of shares from my demat account for this trade.
                    <a
                      href="https://zerodha.com/tos/online-dis-consent/"
                      className={styles.consentLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      More info
                    </a>
                  </p>
                </div>
              ) : null}

            </div>
          </div>
          <div className={styles.btnFooter}>
            <div className={styles.orderChargesContainer}>
              {isFetchingOrderCharges ? (
                <div className={styles.contentDot}>
                  <DotLoader props={{ className: styles.featLoader }} />
                </div>
              )
                : orderCharges && orderCharges.total_charges && !orderChargesErr ? (
                  <>
                    {/* <CustomText>
                      Order charges
                      {' '}
                      <>
                        {CURRENCY_SYM}
                        {` ${Number(orderCharges.total_charges).toFixed(precision)}`}
                      </>
                    </CustomText> */}
                    {isMobile ? (
                      <button type="button" className={styles.info} onClick={() => this.toggleModal('showChargesTooltip')}>
                        <CustomText>
                          Order charges
                          {' '}
                          {CURRENCY_SYM}
                          {` ${Number(orderCharges.total_charges).toFixed(precision)}`}
                        </CustomText>
                        <Icon
                          name={ICONS.INFO}
                          className={styles.infoIcon}
                          color={theme.textSecondary}
                          size={12}
                        />
                      </button>
                    )
                      : (
                        <CustomTooltip
                          show={showChargesTooltip}
                          tooltipProps={{}}
                          contentRenderer={this.renderPopup}
                          placement="top"
                          tooltipContainerStyles={styles.tooltipContainer}
                          showArrow={false}
                          delay={[0, 500]}
                          duration={[0, 1000]}
                          interactive
                        >
                          <div className={styles.info}>
                            <CustomText>
                              Order charges
                              {' '}
                              {CURRENCY_SYM}
                              {` ${Number(orderCharges.total_charges).toFixed(precision)}`}
                            </CustomText>
                            <Icon
                              name={ICONS.INFO}
                              className={styles.infoIcon}
                              color={theme.textSecondary}
                              size={12}
                            />
                          </div>
                        </CustomTooltip>
                      )}
                  </>
                ) : null}

            </div>
            <div className={styles.button_section}>
              <Button
                onPress={this.orderConfirmed}
                btnStyles={classnames(styles.orderConfirmed)}
                buttonColor={color}
                labelColor="white"
                text={renderType}
                loading={loading}
                disabled={loading}
              />
              <Button
                onPress={cancelOrder}
                btnStyles={styles.cancelOrder}
                mode="text"
                labelColor="text"
                buttonColor="tabsBg"
                text="Cancel"
              />
            </div>
          </div>
        </div>
        <CustomModal
          visible={isMobile && showChargesTooltip}
          onDismiss={this.toggleModal}
          dismissKey="showChargesTooltip"
          position="center"
          orderbookPopup
        >
          {this.renderPopup()}
        </CustomModal>
      </>
    )
  }
}

const stylesheet = ({

  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: 540,
    // minHeight: 320,
    maxWidth: 540,
    '&:focus': {
      outline: 'none',
    },
    backgroundColor: COLORS.WHITE,
  },
  heading: {
    padding: '5px 20px',
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },
  left_heading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '15px 0',
  },
  body: {
    display: 'flex',
    margin: '20px',
    marginBottom: 0,
  },
  symbol: {
    fontSize: 15,
    marginRight: '0.5rem',
    // display: 'flex',
    flexDirection: 'row',
    // alignItems: 'flex-end',
  },
  slmContainer: {
    fontSize: 15,
    marginRight: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ltpContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ltpSection: {
    display: 'flex',
    gap: SPACING.SPACE_10,
    alignItems: 'start',
  },
  heading_1: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  ltp: {
    fontSize: `${SPACING.SPACE_16} !important`,
    color: `${COLORS.WHITE} !important`,
  },
  change: {
    fontSize: `${SPACING.SPACE_10} !important`,
    color: `${COLORS.WHITE} !important`,
  },
  segment: {
    color: COLORS.WHITE,
    margin: '0.2rem 0 0 0.2rem',
  },
  desc: {
    marginRight: '0.75rem',
    color: COLORS.WHITE,
    textAlign: 'left',
    marginTop: 5,
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  group: {
    flexDirection: 'row',
    marginBottom: 20,
    marginLeft: 6,
    '& label': {
      flex: 5,
    },
  },
  groupTwo: {
    flexDirection: 'row',
    marginBottom: 20,
    justifyContent: 'space-around',
  },
  groupThree: {
    flexDirection: 'row',
    marginLeft: SPACING.SPACE_6,
    '& label': {
      flex: 5,
    },
  },
  row: {
    flex: 3,
    marginRight: '8%',
  },
  rowTwo: {
    flex: 7,
  },
  advance_toggle: {
    color: theme.linkColor,
    display: 'flex',
    padding: `0 ${SPACING.SPACE_24}`,
  },
  blue: {
    backgroundColor: theme.linkColor,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: theme.linkColor,
    },
  },
  orange: {
    backgroundColor: COLORS.ORANGE,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.ORANGE,
    },
  },
  openblue: {
    backgroundColor: theme.linkBlue,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: theme.linkBlue,
    },
  },
  openorange: {
    backgroundColor: COLORS.OPENORANGE,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.OPENORANGE,
    },
  },
  yellow_600: {
    backgroundColor: COLORS.YELLOW_600,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.YELLOW_600,
    },
  },
  infoIconContainer: {
    margin: `${SPACING.SPACE_2} 0 0 ${SPACING.SPACE_6}`,
  },
  infoIcon: {
    marginLeft: SPACING.SPACE_6,
    '&:hover': {
      fill: theme.linkColor,
    },
  },
  radio_blue: {
    color: `${theme.linkColor} !important`,
  },
  radio_orange: {
    color: `${COLORS.ORANGE} !important`,
  },
  radio_openblue: {
    color: `${theme.linkBlue} !important`,
  },
  radio_openorange: {
    color: `${COLORS.OPENORANGE} !important`,
  },
  radioRoot: {
    padding: 6,
    fontSize: 20,
    '& svg': {
      fontSize: 14,
    },
  },
  radioBtn: {
    fontSize: 22,
  },
  adv_fields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 20px 20px',
    width: '100%',
  },
  fields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0.75rem 0',
  },
  lotText: {
    display: 'flex',
    marginInline: SPACING.SPACE_20,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '15px 20px',
  },
  btnFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    backgroundColor: theme.borderColor,
  },
  orderChargesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: SPACING.SPACE_10,
  },
  tooltipContainer: {
    backgroundColor: theme.bgPrimary,
    boxShadow: `0px 3px 20px ${theme.textSecondary}`,
    // marginLeft: SPACING.SPACE_96,
    // marginBottom: SPACING.SPACE_6,
  },
  tooltipCloseBtn: {
    padding: 0,
  },
  popupContainer: {
    position: 'relative',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_16}`,
    borderRadius: 8,
    maxWidth: 300,
  },
  popupHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: SPACING.SPACE_10,
  },
  chargesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_6,
    marginTop: SPACING.SPACE_16,
  },
  totalContainer: {
    border: `1px solid ${theme.borderColor}`,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    padding: `${SPACING.SPACE_8} 0`,
    margin: `${SPACING.SPACE_8} 0`,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover $infoIcon': {
      fill: theme.linkColor,
    },
    // marginTop: SPACING.SPACE_6,
  },
  chargeRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: SPACING.SPACE_10,
  },
  featLoader: {
    width: 30,
    // margin: '30% auto',
    display: 'flex',
  },
  button_section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  orderConfirmed: {
    // fontSize: theme.fonts.small,
    marginRight: '0.75rem',
    padding: '5px 10px',
    minWidth: 65,
    borderRadius: 2,
  },
  cancelOrder: {
    // fontSize: theme.fonts.small,
    padding: '5px 10px',
    minWidth: 65,
    borderRadius: 2,
    border: `1px solid ${theme.borderColor}`,
  },
  show_details: {
    marginLeft: 'auto',
    backgroundColor: COLORS.WHITE,
    fontSize: 10,
    borderRadius: 2,
    lineHeight: '1.5',
    '&:hover': {
      backgroundColor: COLORS.WHITE,
    },
  },
  inputContainer: {
    marginRight: 15,
    '& input': {
      '&:read-only': {
        background: `repeating-linear-gradient(
          135deg,
          transparent,
          transparent 3px,
          var(--btnDisabled) 3px,
          var(--btnDisabled) 4px
        ),
        linear-gradient(
          to bottom,
          var(--inputDarkBg),
          var(--inputDarkBg)
        ) !important`,
      },
    },
  },
  inputClass: {
    width: '100%',
    padding: '0.5rem',
    marginTop: 4,

  },
  labelClass: {
    fontSize: FONTS.REGULAR,
  },
  radioLabel: {
    fontSize: 12,
  },
  consentSection: {
    display: 'flex',
  },
  consentText: {
    margin: '0 12px 0 7px',
    lineHeight: '1.4',
    fontSize: '12px',
  },
  consentLink: {
    marginLeft: '4px',
    textDecoration: 'none',
  },
  consentCheckboxRoot: {
    padding: 0,
  },
  '@media only screen and (max-width: 1024px)': {
    container: {
      width: '100%',
      // maxWidth: 'unset',
    },
    body: {
      display: 'unset',
    },
    symbol: {
      fontSize: 12,
    },
    segment: {
      fontSize: 10,
    },
    row: {
      width: '70%',
    },
    groupTwo: {
      justifyContent: 'space-between',
      margin: '15px 6px 20px',
    },
    group: {
      marginBottom: 5,
    },
    adv_fields: {
      width: 'auto',
    },
  },
})

export default withTheme(stylesheet)(BuySell)
