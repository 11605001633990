import makeStyles from '@material-ui/styles/makeStyles'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import CustomText from './CustomText'
import CustomInput from './CustomInput'
import Button from './Button'

import { COLORS, ASSETS, SPACING } from '../Theme'
import { PRODUCT_TOUR_SECTIONS } from '../components/ProductTour/ProductTour-utils'
// import { algoNameRegex } from '../utils/consts'

const {
  GRADIENT_BLACK, GRADIENT_BLUE, GRADIENT_GREEN, GRADIENT_PEACH, GRADIENT_PURPLE, GRADIENT_RED,
} = ASSETS

export const COLOR_GRADIENT_MAP = {

  [COLORS.LIST_RED]: {
    colorKey: COLORS.LIST_RED, color: COLORS.ORANGE, gradient: GRADIENT_RED,
  },
  [COLORS.LIST_BLUE_100]: {
    colorKey: COLORS.LIST_BLUE_100, color: COLORS.BLUE, gradient: GRADIENT_BLUE,
  },
  [COLORS.LIST_GREEN]: {
    colorKey: COLORS.LIST_GREEN, color: COLORS.GREEN, gradient: GRADIENT_GREEN,
  },
  [COLORS.LIST_GREEN_100]: {
    colorKey: COLORS.LIST_GREEN_100, color: COLORS.PINK, gradient: GRADIENT_PEACH,
  },
  [COLORS.LIST_GREEN_200]: {
    colorKey: COLORS.LIST_GREEN_200, color: COLORS.MAGENTA, gradient: GRADIENT_PURPLE,
  },
  [COLORS.LIST_RED_100]: {
    colorKey: COLORS.LIST_RED_100, color: COLORS.BLACK, gradient: GRADIENT_BLACK,
  },
  // DEFAULT: { color: COLORS.MAGENTA, gradient: GRADIENT_PURPLE }, // GREEN_200
  // { color: COLORS.RED_200, gradient: GRADIENT_RED },
  // { color: COLORS.BLUE_200, gradient: GRADIENT_BLUE },
}

const CreateNewList = React.memo(({
  onDismiss, onCreate, dismissKey, title, inputPlaceholder,
  listName: prevListName, btnText, loading, showThumbnailSelector, hideCancel = false,
  thumbnail: prevThumbnail = COLORS.RED, isShareNameChange = false,
  containerStyles, tourType, isMobile, startTour, changePtComponentStatus,
}) => {
  const styles = stylesheet()
  const [listName, setListName] = useState(prevListName)
  const [nameErr, updateErr] = useState('')
  const [currentColor, setCurrentColor] = useState(prevThumbnail)
  const edit = !!prevListName
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [])

  const onCreateHandler = () => {
    if (!listName.trim()) {
      updateErr('Name cannot be empty')
      return
    }
    onCreate({
      listName: listName.trim(),
      newList: !edit,
      thumbnail: currentColor,
      type: 'color',
      prevListName,
      prevThumbnail,
    })
  }
  const setActiveColor = (color) => {
    setCurrentColor(color)
  }
  const onChangeText = (e) => {
    if (nameErr) {
      updateErr('')
    }
    if (e.target.value && e.target.value.length > 200) {
      return
    }
    setListName(e.target.value)
  }
  const onKeyDown = (ev) => {
    const { key } = ev
    if (key === 'Enter') {
      onCreateHandler()
    }
  }
  const subText = isShareNameChange ? 'To run a Backtest, copy strategy into your account' : null

  const ptCreateId2 = !isMobile && tourType === PRODUCT_TOUR_SECTIONS.SCANNERS_CREATE ? 'tour_scan_create_2' : tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY ? 'tour_create_strategy_2' : 'tour_tech_create_2'
  const ptCreatedId2Clicker = tourType === PRODUCT_TOUR_SECTIONS.SCANNERS_CREATE ? 'tour_scan_create_2_clicker' : tourType === PRODUCT_TOUR_SECTIONS.CREATESTRATEGY ? 'tour_create_strategy_2_clicker' : 'tour_tech_create_2_clicker'

  return (
    <section className={classNames(styles.container, containerStyles)}>
      <CustomText size="medium" weight="semi_bold">{title}</CustomText>
      {showThumbnailSelector && (

        <div className={styles.colorSelector}>
          {Object.values(COLOR_GRADIENT_MAP).map((item) => {
            const { gradient, color, colorKey } = item
            const Gradient = gradient
            let activeColorStyle = {
              backgroundColor: color,
            }
            if (colorKey === currentColor) {
              activeColorStyle = {
                backgroundColor: color,
                transform: 'scale(1.3)',
              }
            }
            return (
              <button type="button" key={color} onClick={() => setActiveColor(colorKey)} style={activeColorStyle} className={styles.colorBtn}>
                <img src={Gradient} alt={`color_${color}`} className={styles.thumbnail} />
              </button>
            )
          })}
        </div>
      )}
      {subText && <CustomText size="small" color="textSecondary" className={styles.subText}>{subText}</CustomText>}
      <div id={ptCreateId2}>
        <CustomInput
          value={listName}
          onChangeText={onChangeText}
          inputRegx={/^[a-zA-Z0-9\s]*$/gi}
          placeholder={inputPlaceholder}
          containerStyles={styles.inputContainer}
          inputProps={{
            onKeyDown: ev => onKeyDown(ev),
            id: dismissKey,
          }}
          inputError={Boolean(nameErr)}
          inputErrorText={nameErr}
          inputErrStyles={styles.inputErr}
          inputRef={inputRef}
          startTour={startTour}
          changePtComponentStatus={changePtComponentStatus}
        />
      </div>
      <Button
        id={ptCreatedId2Clicker}
        buttonColor="blue"
        onPress={onCreateHandler}
        btnStyles={styles.submitBtn}
        text={btnText}
        disabled={loading}
        loading={loading}
      />
      {!hideCancel && (
      <Button
        buttonColor="bgPrimary"
        onPress={onDismiss}
        params={dismissKey}
        btnStyles={styles.cancelBtn}
        labelColor="linkColor"
        text="Cancel"
        mode="text"
      />
      )}
    </section>
  )
})

const stylesheet = makeStyles({
  container: {
    width: '60%',
    margin: 'auto',
    textAlign: 'center',
  },
  thumbnail: {
  },
  colorSelector: {
    marginTop: SPACING.SPACE_20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorBtn: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    transition: 'all 0.2s',
  },
  inputContainer: {
    margin: `${SPACING.SPACE_20} 0`,
  },
  inputErr: {
    textAlign: 'left',
  },
  cancelBtn: {
    marginTop: SPACING.SPACE_10,
  },
  submitBtn: {
    width: '100%',
  },
  subText: {
    margin: `${SPACING.SPACE_10} 0`,
  },
  '@media only screen and (max-width: 786px)': {
    container: {
      marginTop: SPACING.SPACE_20,
      width: '100%',
      maxWidth: '260px',
      margin: 'auto',
    },
  },
})

CreateNewList.defaultProps = {
  btnText: 'Create',
  listName: '',
  inputPlaceholder: 'List Name',
  title: 'Create New List',
}

CreateNewList.propTypes = {
  // listName: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  listName: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  title: PropTypes.string,
}

export default CreateNewList
