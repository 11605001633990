export const CREATE_ORDER_INIT = 'CREATE_ORDER_INIT'
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'

export const FETCH_PAYMENT_PARAMS_INIT = 'FETCH_PAYMENT_PARAMS_INIT'
export const FETCH_PAYMENT_PARAMS_SUCCESS = 'FETCH_PAYMENT_PARAMS_SUCCESS'
export const FETCH_PAYMENT_PARAMS_FAILURE = 'FETCH_PAYMENT_PARAMS_FAILURE'

export const FETCH_PAYMENT_METHOD_INIT = 'FETCH_PAYMENT_METHOD_INIT'
export const FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS'
export const FETCH_PAYMENT_METHOD_FAILURE = 'FETCH_PAYMENT_METHOD_FAILURE'

export const FETCH_SUBSCRIPTION_PRICE_INIT = 'FETCH_SUBSCRIPTION_PRICE_INIT'
export const FETCH_SUBSCRIPTION_PRICE_SUCCESS = 'FETCH_SUBSCRIPTION_PRICE_SUCCESS'
export const FETCH_SUBSCRIPTION_PRICE_FAILURE = 'FETCH_SUBSCRIPTION_PRICE_FAILURE'

export const CLEAR_ORDER_RESP = 'CLEAR_ORDER_RESP'

export const PAY_WITH_CREDITS = 'PAY_WITH_CREDITS'
export const PAY_WITH_CREDITS_SUCCESS = 'PAY_WITH_CREDITS_SUCCESS'
export const PAY_WITH_CREDITS_FAILURE = 'PAY_WITH_CREDITS_FAILURE'
