import React from 'react'

import Button from '../../UI/Button'
import CustomText from '../../UI/CustomText'

import { ASSETS, SPACING, theme } from '../../Theme'
import { PRODUCT_TOUR_SECTIONS } from './ProductTour-utils'
import Icon from '../../UI/Icon'

const { PTOppUp, PTFlors } = ASSETS

const TourPopper = (props) => {
  const {
    popperLayoutDim, content, styles, index, goBack,
    goNext, popperRef, len, placement, showClicker, tourSteps,
    pauseTour, showPrevious = true,
    ptExtraTourtip = false, extraContent = '', tourType, title, icon,
    doneBtnText, isMobile,
  } = props
  const lastEle = len - 1 === index
  const progressWidth = `${((index + 1) / tourSteps) * 100}%`
  const currentIndex = Number(index + 1)
  const popperStyle = {
    ...popperLayoutDim,
  }
  if (pauseTour) popperStyle.opacity = 0

  const isFinishedPopper = tourType === PRODUCT_TOUR_SECTIONS.FINISHED
  const tourPopperId = tourType === PRODUCT_TOUR_SECTIONS.DEPLOY && index === 3
    ? 'tour-popper-deploy' : 'tour-popper'
  const deployMobileDoneBtn = lastEle && tourType === PRODUCT_TOUR_SECTIONS.DEPLOY && isMobile && 'Done'
  return (
    <div
      id={tourPopperId}
      className={`tour-popper ${isFinishedPopper ? styles.finishedTourPopper : styles.tourPopper}`}
      style={popperStyle}
      ref={popperRef}
      data-placement={placement}
    >
      {!isFinishedPopper && <div className="tour-arrow" />}
      <div className={isFinishedPopper ? styles.headerCenter : styles.header}>
        {isFinishedPopper && <img src={PTOppUp} alt="gif" className={styles.ptgif1} />}
        {isFinishedPopper && <img src={PTFlors} alt="gif" className={styles.ptgif2} />}
        {Boolean(title) && (
          <CustomText
            weight="medium"
            color="white"
            style={{ marginTop: isFinishedPopper ? 90 : 0 }}
            size="large"
          >
            {title}
          </CustomText>
        )}
        <CustomText
          color="white"
          weight="medium"
          style={{ width: '90%' }}
          center={isFinishedPopper}
        >
          {ptExtraTourtip ? extraContent : content}
        </CustomText>
        {index === 0 && !isFinishedPopper
         && (tourType !== PRODUCT_TOUR_SECTIONS.BACKTEST
          && tourType !== PRODUCT_TOUR_SECTIONS.TECHNICALS) && (
          <div className={styles.btnContainer}>
            <div />
            <Button
              transparent
              btnStyles={styles.nextBtn}
              onPress={goNext}
            >
              {icon && <Icon name={icon} size={20} style={{ marginRight: SPACING.SPACE_10 }} />}
              <CustomText color={theme.productTourBg} weight="semi_bold">
                Next
              </CustomText>
            </Button>
          </div>
        )}
        {(index !== 0 || !showClicker) && !isFinishedPopper
          && (
          <div className={styles.btnContainer}>
            <div>
              {index !== 0 && showPrevious && (
              <Button
                text="Previous"
                mode="text"
                transparent
                labelColor="white"
                btnStyles={styles.backBtn}
                onPress={goBack}
                labelWeight="semi_bold"
              />
              )}
            </div>
            {!ptExtraTourtip && (
            <Button
              transparent
              btnStyles={styles.nextBtn}
              onPress={goNext}
            >
              {(!deployMobileDoneBtn && icon)
              && <Icon name={icon} size={20} style={{ marginRight: SPACING.SPACE_10 }} />}
              <CustomText color={theme.productTourBg} weight="semi_bold">
                {lastEle ? (deployMobileDoneBtn || doneBtnText || 'Done') : 'Next'}
              </CustomText>
            </Button>
            )}
          </div>
          )}
        {isFinishedPopper && (
          <Button
            transparent
            btnStyles={styles.nextBtn}
            onPress={goNext}
          >
            <CustomText color={theme.productTourBg} weight="semi_bold">Close</CustomText>
          </Button>
        )}
      </div>
      {!isFinishedPopper && (
        <div className={styles.actionBar}>
          <div
            className={styles.tourProgress}
            style={{
              width: progressWidth,
              borderTop: `6px solid ${theme.productTourSecondary}`,
              borderRadius: progressWidth === '100%' ? '0 0 10px 10px' : '0 0 0 10px',
            }}
          />
          {tourSteps > 1 && (
          <CustomText color="yellow" size="small" weight="semi_bold" style={{ marginLeft: SPACING.SPACE_4, position: 'relative' }}>
            <span className={styles.page}>{ ptExtraTourtip ? 4 : currentIndex}</span>
            {`/${tourSteps}`}
          </CustomText>
          )}
        </div>
      )}
    </div>
  )
}

export default React.forwardRef((props, ref) => (
  <TourPopper popperRef={ref} {...props} />
))
