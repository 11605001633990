import {
  FETCH_ALGO_INIT,
  FETCH_ALGO_SUCCESS,
  FETCH_ALGO_FAILURE,
  DELETE_ALGO_INIT,
  DELETE_ALGO_SUCCESS,
  DELETE_ALGO_FAILURE,
  CLEAR_ALGO_RESP,
  CLEAR_ALGO_DATA,
  FETCH_BACKTEST_GRAPH,
  FETCH_BACKTEST_GRAPH_SUCCESS,
  FETCH_BACKTEST_GRAPH_FAILURE,
  UPDATE_SORT_ON,
  FILTER_ALGO_INIT,
  FILTER_ALGO_SUCCESS,
  FILTER_ALGO_FAILURE,
  FILTER_ALGO_CLEAR,
} from './actionTypes'
import { ALGO_KEY_MAP } from '../../components/AlgoCondensedView/AlgoUtils'

const initialState = {
  algos: [],
  isFetchingAlgos: false,
  algosError: false,
  algosErrorMsg: '',
  pages: 0,

  isDeleteingAlgo: false,
  deleteAlgoError: false,
  deleteAlgoResp: {},
  // GRAPH DATA
  backtestGraphData: {},
  isFetchingGraphData: false,
  backtestGraphDataError: false,
  backtestGraphDataErrorMsg: '',
  deleteAlgoErrorMsg: '',
  fetchType: 'condensedView',

  detailedAlgos: [],
  detailedIsFetchingAlgos: false,
  detailedAlgosError: false,
  detailedAlgosErrorMsg: '',
  detailedPages: 0,
  detailedAlgoFetchResp: [],

  filterError: false,
  filterErrMsg: '',
  filterSuccessMsg: '',
  filterSuccess: false,
  filteredAlgos: [],

  sortOn: {},
}

const algo = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALGO_INIT: {
      if (action.detailed) {
        return {
          ...state,
          detailedIsFetchingAlgos: true,
          detailedAlgosError: false,
          detailedAlgosErrorMsg: '',
          isDeleteingAlgo: false,
          deleteAlgoError: false,
          deleteAlgoResp: {},
        }
      }
      return {
        ...state,
        isFetchingAlgos: true,
        algosError: false,
        isDeleteingAlgo: false,
        deleteAlgoError: false,
        deleteAlgoResp: {},
      }
    }

    case FETCH_ALGO_SUCCESS: {
      const {
        data, kind, detailed, params,
      } = action
      let algos
      const newAlgoIds = []
      const algoIdKey = ALGO_KEY_MAP.MY_ALGOS
      const newAlgo = data.algo.map((item) => {
        newAlgoIds.push(item[algoIdKey])
        return { ...item, backtest: item.backtest ? item.backtest.splice(0, 1) : [] }
      })
      if (kind === 'append') {
        let oldAlgos = [...state.algos]
        if (detailed) {
          oldAlgos = [...state.detailedAlgos]
        }
        const { page_limit, page = 0 } = params
        // find the algos which has to be reomved as the algos are now sorted
        // so last fetch algos will not be at the bottom of the array
        // to avoid duplicate we do this
        if (page_limit !== data.algo.length && state.sortOn.sortKey && detailed) {
          oldAlgos = oldAlgos.filter(algoObj => !newAlgoIds.includes(algoObj[algoIdKey]))
        } else {
          oldAlgos = oldAlgos.splice(0, page_limit * (page - 1))
        }
        algos = [...oldAlgos, ...newAlgo]
      } else {
        algos = [...newAlgo]
      }
      if (state.sortOn.sortKey && detailed) {
        const { sortKey, asc } = state.sortOn
        algos.sort((a, b) => {
          if (asc) {
            return (a[sortKey] - b[sortKey])
          }
          return (b[sortKey] - a[sortKey])
        })
      }
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingAlgos: false,
          detailedAlgos: algos,
          detailedPages: data.pages,
          detailedAlgoFetchResp: newAlgo,
        }
      }
      return {
        ...state,
        isFetchingAlgos: false,
        algos,
        pages: data.pages,
      }
    }

    case FETCH_ALGO_FAILURE: {
      const { detailed, error } = action
      if (detailed) {
        return {
          ...state,
          detailedIsFetchingAlgos: false,
          detailedAlgosError: true,
          detailedAlgosErrorMsg: error,
        }
      }
      return {
        ...state,
        isFetchingAlgos: false,
        algosError: true,
        algosErrorMsg: error,
      }
    }

    case DELETE_ALGO_INIT:
      return {
        ...state,
        isDeleteingAlgo: true,
        deleteAlgoError: false,
        deleteAlgoResp: {},
        deleteAlgoErrorMsg: '',
      }

    case DELETE_ALGO_SUCCESS: {
      const { data, deletedAlgoId } = action
      let modAlgos = [...state.detailedAlgos]
      if (deletedAlgoId) {
        modAlgos = modAlgos.filter(item => item.algo_uuid !== deletedAlgoId)
      }
      return {
        ...state,
        isDeleteingAlgo: false,
        deleteAlgoResp: data,
        detailedAlgos: modAlgos,
      }
    }

    case DELETE_ALGO_FAILURE:
      return {
        ...state,
        isDeleteingAlgo: false,
        deleteAlgoError: true,
        deleteAlgoErrorMsg: action.error,
      }

    case CLEAR_ALGO_RESP:
      return {
        ...state,
        isDeleteingAlgo: false,
        deleteAlgoError: false,
        deleteAlgoResp: {},
      }

    case CLEAR_ALGO_DATA: {
      const { detailed } = action
      if (detailed) {
        return {
          ...state,
          detailedAlgos: [],
          detailedIsFetchingAlgos: false,
          detailedAlgosError: false,
          detailedAlgosErrorMsg: '',
          detailedPages: 0,
          detailedAlgoFetchResp: [],
          sortOn: {},
        }
      }
      return {
        ...state,
        algos: [],
        isFetchingAlgos: false,
        algosError: false,
        algosErrorMsg: '',
        pages: 0,
      }
    }

    case FETCH_BACKTEST_GRAPH: {
      const { params } = action
      let newData = {}
      params.forEach((idObj) => {
        newData = {
          ...newData,
          [idObj[ALGO_KEY_MAP.MY_ALGOS]]: { waiting: true, error: false },
        }
      })
      const backtestGraphData = {
        ...state.backtestGraphData,
        ...newData,
      }
      return {
        ...state,
        backtestGraphData,
        isFetchingGraphData: true,
      }
    }

    case FETCH_BACKTEST_GRAPH_SUCCESS: {
      const { data, id } = action
      // let backtests = []
      // if (data) {
      //   backtests = data.backtests // data.backtest_results
      // }
      // let graphData = {}
      // backtests.forEach((item) => {
      //   const { seg_sym, backtest_result } = item
      //   graphData = {
      //     ...graphData,
      //     [seg_sym]: backtest_result[seg_sym]
      //   }
      // })
      const { absolute_pnl_pct = 0.0, absolute_pnl = 0.0 } = data
      const modData = {
        ...state.backtestGraphData,
        [id]: {
          waiting: false, data: data.backtests || [], absolute_pnl_pct, absolute_pnl,
        },
      }
      return {
        ...state,
        isFetchingGraphData: false,
        backtestGraphData: modData,
      }
    }

    case FETCH_BACKTEST_GRAPH_FAILURE: {
      const { id } = action
      const backtestGraphData = {
        ...state.backtestGraphData,
        [id]: { waiting: false, error: true },
      }
      return {
        ...state,
        backtestGraphData,
        isFetchingGraphData: false,
        backtestGraphDataError: true,
        backtestGraphDataErrorMsg: 'Error fetching Graph',
      }
    }

    case UPDATE_SORT_ON: {
      const { sortOn = {} } = action
      const { asc, sortKey } = sortOn
      if (sortKey) {
        const modAlgos = [...state.detailedAlgos]
        modAlgos.sort((a, b) => {
          if (asc) {
            return (a[sortKey] - b[sortKey])
          }
          return (b[sortKey] - a[sortKey])
        })
        return {
          ...state,
          sortOn: action.sortOn,
          detailedAlgos: modAlgos,
        }
      }
      return {
        ...state,
        sortOn: action.sortOn,
      }
    }

    case FILTER_ALGO_INIT:
      return {
        ...state,
        algosError: false,
        isDeleteingAlgo: false,
        deleteAlgoError: false,
        deleteAlgoResp: {},
        filterError: false,
        filterErrMsg: '',
        filterSuccess: false,
      }

    case FILTER_ALGO_SUCCESS:
      return {
        ...state,
        filterSuccessMsg: action.msg,
        filterSuccess: true,
        filteredAlgos: action.data,
      }

    case FILTER_ALGO_FAILURE:
      return {
        ...state,
        filterError: true,
        filterErrMsg: action.error,
      }

    case FILTER_ALGO_CLEAR:
      return {
        ...state,
        filteredAlgos: [],
      }

    default:
      return state
  }
}

export default algo
