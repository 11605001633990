import React from 'react'

import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import CustomTooltip from '../../UI/CustomTooltip'

import {
  ICONS, COLORS, theme,
} from '../../Theme'

// const areEqual = (props, nextProps) => {
//   if (isEqual(props.indicatorState, nextProps.indicatorState)) {
//     return false
//   }
//   return true
// }

const ConditionAddCard = React.memo(({
  styles,
  onDelete,
  children,
  createStrategy,
  onCopy,
  showCopy,
  showPaste,
  onPaste,
  toggleDisableCondition,
  disabled,
  index,
  isMobile,
  startTour,
  updateGA,
}) => {
  const onUpdateGA = () => {
    updateGA('Highlight Click', 'Ct Strat Pg 10', 1, 'Product Tour')
  }

  return (
    <div
      className={styles.cardWrapper}
      id="tour_create_strategy_10"
      role="button"
      onClick={onUpdateGA}
      tabIndex={0}
      onKeyUp={onUpdateGA}
    >
      <div className={`${styles.card} ${disabled ? styles.disabledCard : ''}`}>
        {children}
        <div className={styles.cardFooter}>
          <CustomTooltip
            show={createStrategy}
            tooltipProps={{ text: disabled ? 'Enable' : 'Disable' }}
          >
            <button
              type="button"
              onClick={() => toggleDisableCondition({ disabled: !disabled, index })}
              className={styles.footerActionBtn}
            >
              <Icon
                name={disabled ? ICONS.ENABLE_CONDITION : ICONS.DISABLE_CONDITION}
                size={createStrategy ? 18 : 14}
                color={theme.textSecondary}
              />
              {!createStrategy && (
              <CustomText weight="medium" color="textSecondary" style={{ margin: '0 0px 0px 6px' }}>
                {disabled ? 'Enable' : 'Disable'}
              </CustomText>
              )}
            </button>
          </CustomTooltip>
          {showCopy && (
            <CustomTooltip
              show={createStrategy}
              tooltipProps={{ text: 'Copy' }}
            >
              <button
                type="button"
                onClick={onCopy}
                className={styles.footerActionBtn}
              >
                <Icon
                  name={ICONS.COPY_FILLED}
                  size={createStrategy ? 16 : 12}
                  color={theme.textSecondary}
                />
                {!createStrategy && <CustomText weight="medium" color="textSecondary" style={{ margin: '0 0px 0px 6px' }}>Copy</CustomText>}
              </button>
            </CustomTooltip>
          )}
          {showPaste && (
            <CustomTooltip
              show={createStrategy}
              tooltipProps={{ text: 'Paste' }}
            >
              <button
                type="button"
                onClick={onPaste}
                className={styles.footerActionBtn}
              >
                <Icon
                  name={ICONS.PASTE_FILLED}
                  size={createStrategy ? 16 : 12}
                  color={theme.textSecondary}
                />
                {!createStrategy && <CustomText weight="medium" color="textSecondary" style={{ margin: '0 0px 0px 6px' }}>Paste</CustomText>}
              </button>
            </CustomTooltip>
          )}
          {!startTour
          && (
          <CustomTooltip
            show={createStrategy}
            tooltipProps={{ text: 'Remove condition' }}
          >
            <button
              type="button"
              onClick={onDelete}
              className={styles.deleteBtn}
            >
              <Icon name={ICONS.DELETE} color={COLORS.RED} size={12} style={{ margin: '0 6px 2px 0' }} />
              {!createStrategy && (
              <CustomText color="red">
                {isMobile ? 'Delete' : 'Remove Condition'}
              </CustomText>
              )}
            </button>
          </CustomTooltip>
          )}
        </div>
      </div>
    </div>
  )
})

export default ConditionAddCard
