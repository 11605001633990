/* eslint-disable max-len */
// /* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { isEqual } from 'lodash'
// import CustomModal from '../../UI/CustomModal'
import BuySellDirect from '../../components/BuySellDirect'
import { BUY_SELL_TYPE_MAP } from '../../components/BuySell/buySellUtils'
import { segSymConverter, splitString } from '../../utils/common'
import { NAVIGATIONS } from '../../utils/navigationConstant'
import { openOrdersPairs, unSubscribe } from '../Instruments/actions'
import CircleLoader from '../../UI/CircleLoader'
import { withTheme } from '../../Theme/ThemeProvider'
import { fetchSpecific } from '../Portfolio/actions'
import { ORDER_TYPE } from '../../utils/consts'
import { getLTPChangeValue, ticksProperty } from '../../utils/helpers/ticks'
import { isLoginOnOrder } from '../Common/actions'
import { COLORS } from '../../Theme'
import { fetchActiveStocks, getOpenPositions } from '../Deployed/actions'
import { ApiRequest } from '../../utils/apiRequests'
import { showSnackbar } from '../../UI/Snackbar'
import { getNotifications } from '../Notifications/actions'

class ScanDirectOrder extends Component {
  constructor(props) {
    super(props)
    // const { location } = props
    const segSym = window.location.search.split('?')[1] || ''
    // const { query = {} } = router
    // const { segSym = '' } = query
    const keyValuePairs = segSym?.split('&')
    // const extractedValues = getParamsValues(keyValuePairs)
    const extractedValues = this.getParamsValues(keyValuePairs)
    this.segSym = extractedValues.segSym || ''
    this.ltp = extractedValues.ltp || 0
    this.deployment_uuid = extractedValues.deployment_uuid || ''
    this.notification_uuid = extractedValues.notification_uuid || ''
    this.actionType = extractedValues.actionType || ''
    this.buySellType = extractedValues.buySellType || BUY_SELL_TYPE_MAP.DIRECT.TYPE
    this.details = atob(extractedValues.details)
    // this.data = JSON.parse(extractedValues.item) || {}
    const { csrftoken, sessionid, useruuid } = splitString(this.details)
    this.csrf = csrftoken
    this.sessionid = sessionid
    this.useruuid = useruuid
    this.activeItem = {}

    if (extractedValues?.product) {
      this.product = extractedValues.product || ''
    }

    this.state = {
      buySell: false,
    }

    this.buySellParams = {}
    this.buySellConfirmationParams = null
  }

  componentDidMount() {
    const { fetchSpecificSegSym, isLoginOnPlaceOrder, getNotificationsList } = this.props // isLoginOnPlaceOrder
    const { symbol, segment = '' } = segSymConverter(this.segSym)
    const Authorization = `csrftoken=${this.csrf};csrfmiddlewaretoken=${this.csrf};sessionid=${this.sessionid}`
    ApiRequest.defaults.headers.common.Authorization = `csrftoken=${this.csrf};csrfmiddlewaretoken=${this.csrf};sessionid=${this.sessionid}`
    let finalSeg = segment
    if (finalSeg.includes('-')) {
      finalSeg = finalSeg.split('-')[0]
    }
    const params = {
      symbol,
      exchange: finalSeg,
      product: this.product,
      platform: 'openstreak',
    }

    if(this.deployment_uuid !== '') {
      this.fetchHandler({ Authorization })
    }
    if(this.notification_uuid !== '') {
      getNotificationsList({ platform: 'openstreak' })
    }
    if(this.buySellType === BUY_SELL_TYPE_MAP.STOP_ALGO.TYPE) {
      this.getOpenPositions()
    }

    isLoginOnPlaceOrder()

    if (this.product) {
      fetchSpecificSegSym(params, { Authorization })
    }
    if (!this.product && this.buySellType === BUY_SELL_TYPE_MAP.DIRECT.TYPE) {
      this.toggleBuySellModal()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      details, openOrdersSegSym, unSubscribeTokens, isWebSocketConnected,
      isFetchingActiveStocks, enteredFilteredStocks, waitingFilteredStocks,
      isFetchingOpenPositions, openPositionsData, openPositionsError,
      clearOpenPositionsData, isFetchingNotifications, notifications,
    } = this.props

    setTimeout(() => {
      if (this.segSym && !isEqual(isWebSocketConnected, prevProps.isWebSocketConnected)
        && isWebSocketConnected) {
        unSubscribeTokens(NAVIGATIONS.SCAN_DETAILS.name)

        openOrdersSegSym(this.segSym, NAVIGATIONS.SCAN_DETAILS.name)
      }
    }, 50)

    if ((!isEqual(details, prevProps.details) && details) && this.product && this.buySellType === BUY_SELL_TYPE_MAP.PORTFOLIO_EXIT.TYPE) {
      this.toggleBuySellModal()
    }
    if(isFetchingActiveStocks !== prevProps.isFetchingActiveStocks && !isFetchingActiveStocks && this.buySellType !== BUY_SELL_TYPE_MAP.NOTIFICATION.TYPE) {
      const activeStocks = [...enteredFilteredStocks.notificationTrading, ...waitingFilteredStocks.notificationTrading]
      const deploymentDetails = activeStocks.find(obj => obj.deployment_uuid === this.deployment_uuid)
      this.depploymentDetails = deploymentDetails
      // let activeItem
      const logs = deploymentDetails.logs?.[0]?.notification_data || {}
      // const exchange = logs.exchange || logs.seg
      // const metaObj = getMetaObj(meta, `${logs.seg}_${logs.sym}`, exchange)
      if (logs) {
        // eslint-disable-next-line no-unused-vars
        this.activeItem = { ...logs, seg_sym: `${logs.seg}_${logs.sym}` }
      }
      this.buySellConfirmationParams = {
        item: this.activeItem,
        buySellType: this.buySellType,
        showViewHistory: true,
      }
      if(this.buySellType !== BUY_SELL_TYPE_MAP.STOP_ALGO.TYPE) {
        // this.toggleBuySellModal()
        this.toggleModal('buySell')
      }
    }
    if(!isEqual(isFetchingNotifications, prevProps.isFetchingNotifications) && !isFetchingNotifications) {
      // const activeStocks = [...enteredFilteredStocks.notificationTrading, ...waitingFilteredStocks.notificationTrading]
      let notifDetails = notifications.Notifications.find(item => {
        return item.notifications.some(notification => notification.notification_uuid === this.notification_uuid)
      })
      notifDetails = notifDetails.notifications
      this.depploymentDetails = notifDetails.notifications
      // // let activeItem
      const logs = notifDetails[0]
      // const exchange = logs.exchange || logs.seg
      // const metaObj = getMetaObj(meta, `${logs.seg}_${logs.sym}`, exchange)
      if (logs) {
        // eslint-disable-next-line no-unused-vars
        this.activeItem = { ...logs, seg_sym: `${logs.seg}_${logs.sym}` }
      }
      this.buySellParams = {
        item: this.activeItem,
        buySellType: this.buySellType,
        showViewHistory: true,
      }
      // setTimeout(() => {
      this.toggleModal('buySell')
      // }, 1000)

      // console.log('activeItem', this.activeItem)
      // if(this.buySellType !== BUY_SELL_TYPE_MAP.STOP_ALGO.TYPE) {
      //   console.log('opening here 1')
      //   this.toggleBuySellModal()
      // }
    }
    if (!isFetchingOpenPositions && prevProps.isFetchingOpenPositions) {
      if (openPositionsData.status === 'success') {
        setTimeout(() => {
          this.stockDetails = this.depploymentDetails
          this.toggleModal('buySell')
        }, 1000)
        // const {
        //   algo_obj: { action_type, algo_uuid },
        //   deployment_uuid,
        //   algo_name,
        //   deployment_type,
        //   segment_symbol,
        // } = this.activeStockDetails
        // const {
        //   seg,
        //   sym,
        //   positions: { qty },
        // } = openPositionsData
        // TODO: FIX THIS (NOT THE IDEAL WAY)
        // const [segment = '', symbol = ''] = segment_symbol.split('_')
        // openPositionsData.seg = segment
        // openPositionsData.sym = symbol
        // const renderType = action_type === 'BUY' ? 'SELL' : 'BUY'
        // const exch = seg.split('-')[0]

        // const params = {
        //   deployment_uuid,
        //   algo_uuid,
        //   algo_name,
        //   seg,
        //   exch,
        //   sym,
        //   order_type: 'MARKET',
        //   transaction_type: renderType,
        //   quantity: Math.abs(qty),
        //   // product: 'MIS',
        //   validity: 'DAY',
        //   csrfmiddlewaretoken: this.csrf,
        // }
        // console.log('opening here 3')
        // this.toggleBuySellModal()
        // const num_qty = Number(qty)
        // if (num_qty && deployment_type !== DEPLOYMENT_TYPE.PAPER.backend) {
        //   if (!showStopAlgoConfirmationModal) {
        //     if(isStopAll) {
        //       this.setState({ isStopAll: false })
        //     }
        //     this.setState({ showStopAlgoConfirmationModal: true })
        //   }
        // } else if (num_qty === 0 || deployment_type === DEPLOYMENT_TYPE.PAPER.backend) {
        //   // console.warn('stop algo')
        //   stopAlgo('keepPositionsOpen', params, this.uuidHeader)
        // }
      } else if (openPositionsData.status === 'error' || openPositionsError) {
        showSnackbar('Error fetching positions', {}, 0)
        // close()
        clearOpenPositionsData()
      }
    }
  }

  getOpenPositions = () => {
    const { getOpenPositionsData } = this.props

    const params = {
      deployment_uuid: this.deployment_uuid,
      csrfmiddlewaretoken: this.csrf,
      platform: 'openstreak',
    }

    getOpenPositionsData(params, this.headers)
  }

  fetchHandler = (headers) => {
    const { fetchActiveStocksList } = this.props
    //    const { placed_by } = this.state
    const params = {
      platform: 'openstreak',
    }
    fetchActiveStocksList(params, headers)
  }

  getParamsValues = (keyValuePairs) => {
    const extractedValues = {}
    for (let i = 0; i < keyValuePairs.length; i++) {
      const parts = keyValuePairs[i].split('=')
      const key = parts[0]
      const value = decodeURIComponent(parts[1])
      extractedValues[key] = value
    }
    return extractedValues
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  getLTP = (segSym) => {
    const { ticks, seg_sym_list } = this.props
    let ltp = 0
    if (ticks && seg_sym_list && ticks[seg_sym_list[segSym]]) {
      ({ last_price: ltp } = ticks[seg_sym_list[segSym]])
    }
    return ltp
  }

  toggleBuySellModal = () => {
    const { details } = this.props
    const { seg_sym, segment = '', symbol } = segSymConverter(this.segSym)
    const trigger_price = this.getLTP(this.segSym)

    const {
      quantity, product, tradingsymbol, exchange = '', last_price,
    } = details

    const params = {
      action_type: this.actionType,
      filled_quantity: 0,
      product: 'MIS',
      symbol,
      order_type: 'MARKET',
      quantity: 1,
      segment,
      trigger_price,
      exchange: segment,
      variety: 'REGULAR',
      stop_loss: 0,
      target_profit: 0,
      direct: true,
      algo_name: '',
      seg_sym,
      seg: segment,
    }

    const renderType = quantity > 0 ? 'SELL' : 'BUY'
    const posParams = {
      action_type: renderType,
      algo_name: '',
      filled_quantity: quantity,
      product,
      symbol: tradingsymbol,
      order_type: ORDER_TYPE.MARKET,
      quantity,
      segment: exchange,
      trigger_price: (+last_price) ? last_price : getLTPChangeValue({
        seg_sym,
        label: ticksProperty.ltp,
      }),
      seg_sym,
      exchange,
    }
    this.buySellParams = {
      item: this.product ? posParams : params,
      seg_sym,
      buySellType: this.buySellType,
    }

    this.toggleModal('buySell')
  }

  render() {
    const { router, styles, openPositionsData } = this.props
    const { buySell } = this.state

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center', /* Horizontally centers items */
        alignItems: 'center', /* Vertically centers items */
        height: '94vh', /* Set height to 100% of viewport height */
        backgroundColor: COLORS.BACKDROP_BG,
      }}
      >
        {buySell === false ? (
          <CircleLoader
            className={styles.loader}
            showPosition={false}
          />
        )
          : (
        // <CustomModal
        //   visible={buySell}
        //   onDismiss={() => { }}
        //   dismissKey="buySell"
        //   containerStyles="buy-sell-modal"
        // >
            <BuySellDirect
              onDismiss={() => { }}
              dismissKey="buySell"
              route={{
                params: {
                  openPositionsData,
                  stockDetails: {
                    ...this.stockDetails,
                    seg_sym: this.stockDetails?.segment_symbol || '',

                  },
                  buySellType: this.buySellType,
                  ...this.buySellConfirmationParams,
                  ...this.buySellParams,

                  // params: this.buySellConfirmationParams !== null ? this.buySellConfirmationParams : this.buySellParams,
                },
              }}
              router={router}
              posProduct={this.product}
              sessionid={this.sessionid}
              csrf={this.csrf}
              csrfOpen={this.csrf}
              sessionidOpen={this.sessionid}
            />
        // </CustomModal>
          )}
      </div>
    )
  }
}

const stylesheet = ({
  loader: {
    width: '50px !important',
    position: 'absolute',
    left: '46%',
    top: '40%',
  },
})

const mapStateToProps = (state) => {
  return {
    seg_sym_list: state.instruments.seg_sym_list,
    ticks: state.instruments.ticks,
    details: state.portfolio.details,
    isWebSocketConnected: state.instruments.isWebSocketConnected,
    ...state.deployed,
    ...state.notification,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActiveStocksList: (params, headers) => dispatch(fetchActiveStocks(params, headers)),
    openOrdersSegSym: (pairs, screenName) => openOrdersPairs(dispatch, pairs, screenName),
    unSubscribeTokens: (screenName, pairList) => dispatch(unSubscribe(screenName, pairList)),
    fetchSpecificSegSym: (params, headers) => dispatch(fetchSpecific(params, headers)),
    isLoginOnPlaceOrder: () => dispatch(isLoginOnOrder()),
    getOpenPositionsData: (params, headers) => dispatch(getOpenPositions(params, headers)),
    getNotificationsList: (params, headers) => dispatch(getNotifications(params, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(ScanDirectOrder))
