import {
  FETCH_BILLING_INIT,
  FETCH_BILLING_SUCCESS,
  FETCH_BILLING_FAILURE,
  CHANGE_SUBSCRIPTION_INIT,
  CHANGE_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION_FAILURE,
  GET_PRICING_INIT,
  GET_PRICING_SUCCESS,
  GET_PRICING_FAILURE,
  UNSUBSCRIBE_FEEDBACK,
  UNSUBSCRIBE_FEEDBACK_SUCCESS,
  UNSUBSCRIBE_FEEDBACK_FAILURE,
  DOWNLOAD_INVOICE_INIT,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_FAILURE,
  FETCH_BILLING_OFFERS_INIT,
  FETCH_BILLING_OFFERS_SUCCESS,
  FETCH_BILLING_OFFERS_FAILURE,
} from './actionTypes'

export function fetchBillingSuccess(data) {
  return {
    type: FETCH_BILLING_SUCCESS,
    data,
  }
}

export function fetchBillingFailure(error) {
  return {
    type: FETCH_BILLING_FAILURE,
    error,
  }
}

export function fetchBilling(headers) {
  return {
    type: FETCH_BILLING_INIT,
    headers,
  }
}

export function changeSubscriptionSuccess(data) {
  return {
    type: CHANGE_SUBSCRIPTION_SUCCESS,
    data,
  }
}

export function changeSubscriptionFailure(error) {
  return {
    type: CHANGE_SUBSCRIPTION_FAILURE,
    error,
  }
}

export function changeSubscription(actionType, params, headers) {
  return {
    type: CHANGE_SUBSCRIPTION_INIT,
    actionType,
    params,
    headers,
  }
}

export function getPricingSuccess(data, broker) {
  return {
    type: GET_PRICING_SUCCESS,
    data,
    broker,
  }
}

export function getPricingFailure(error) {
  return {
    type: GET_PRICING_FAILURE,
    error,
  }
}

export function getPricing(headers, params) {
  return {
    type: GET_PRICING_INIT,
    headers,
    params,
  }
}

export function unsubscribeFeedback(params) {
  return {
    type: UNSUBSCRIBE_FEEDBACK,
    params,
  }
}

export function unsubscribeFeedbackSuccess(data) {
  return {
    type: UNSUBSCRIBE_FEEDBACK_SUCCESS,
    data,
  }
}

export function unsubscribeFeedbackFailure(error) {
  return {
    type: UNSUBSCRIBE_FEEDBACK_FAILURE,
    error,
  }
}

export function downloadInvoice(params, fileName) {
  return {
    type: DOWNLOAD_INVOICE_INIT,
    params,
    fileName,
  }
}

export function downloadInvoiceSuccess() {
  return {
    type: DOWNLOAD_INVOICE_SUCCESS,
  }
}

export function downloadInvoiceFailure(error) {
  return {
    type: DOWNLOAD_INVOICE_FAILURE,
    error,
  }
}

export function fetchBillingOffers() {
  return {
    type: FETCH_BILLING_OFFERS_INIT,
  }
}

export function fetchBillingOffersSuccess(data) {
  return {
    type: FETCH_BILLING_OFFERS_SUCCESS,
    data,
  }
}

export function fetchBillingOffersFailure(error) {
  return {
    type: FETCH_BILLING_OFFERS_FAILURE,
    error,
  }
}
