export const FETCH_PERFORMERS_INIT = 'FETCH_PERFORMERS_INIT'
export const FETCH_PERFORMERS_SUCCESS = 'FETCH_PERFORMERS_SUCCESS'
export const FETCH_PERFORMERS_FAILURE = 'FETCH_PERFORMERS_FAILURE'

export const FETCH_SAMPLE_ALGO_INIT = 'FETCH_SAMPLE_ALGO_INIT'
export const FETCH_SAMPLE_ALGO_SUCCESS = 'FETCH_SAMPLE_ALGO_SUCCESS'
export const FETCH_SAMPLE_ALGO_FAILURE = 'FETCH_SAMPLE_ALGO_FAILURE'

export const FETCH_FUNDS_INIT = 'FETCH_FUNDS_INIT'
export const FETCH_FUNDS_SUCCESS = 'FETCH_FUNDS_SUCCESS'
export const FETCH_FUNDS_FAILURE = 'FETCH_FUNDS_FAILURE'

export const CLONE_SAMPLE_INIT = 'CLONE_SAMPLE_INIT'
export const CLONE_SAMPLE_SUCCESS = 'CLONE_SAMPLE_SUCCESS'
export const CLONE_SAMPLE_FAILURE = 'CLONE_SAMPLE_FAILURE'

export const CLEAR_DASHBOARD_RESP = 'CLEAR_DASHBOARD_RESP'

export const FETCH_WHATS_NEW_INIT = 'FETCH_WHATS_NEW_INIT'
export const FETCH_WHATS_NEW_SUCCESS = 'FETCH_WHATS_NEW_SUCCESS'
export const FETCH_WHATS_NEW_FAILURE = 'FETCH_WHATS_NEW_FAILURE'

export const FETCH_DASHBOARD_EXPLORE = 'FETCH_DASHBOARD_EXPLORE'
export const FETCH_DASHBOARD_EXPLORE_SUCCESS = 'FETCH_DASHBOARD_EXPLORE_SUCCESS'
export const FETCH_DASHBOARD_EXPLORE_FAILURE = 'FETCH_DASHBOARD_EXPLORE_FAILURE'

export const UPDATE_TECHNICALS_LIST = 'UPDATE_TECHNICALS_LIST'
export const UPDATE_TECHNICALS_LIST_SUCCESS = 'UPDATE_TECHNICALS_LIST_SUCCESS'
export const UPDATE_TECHNICALS_LIST_FAILURE = 'UPDATE_TECHNICALS_LIST_FAILURE'

export const GET_PARTIAL_COMPLETE_INIT = 'GET_PARTIAL_COMPLETE_INIT'
export const GET_PARTIAL_COMPLETE_SUCCESS = 'GET_PARTIAL_COMPLETE_SUCCESS'
export const GET_PARTIAL_COMPLETE_FAILURE = 'GET_PARTIAL_COMPLETE_FAILURE'

export const GET_RECOMMENDATIONS_INIT = 'GET_RECOMMENDATIONS_INIT'
export const GET_RECOMMENDATIONS_SUCCESS = 'GET_RECOMMENDATIONS_SUCCESS'
export const GET_RECOMMENDATIONS_FAILURE = 'GET_RECOMMENDATIONS_FAILURE'

export const GET_DASHBOARD_CAROUSEL_INIT = 'GET_DASHBOARD_CAROUSEL_INIT'
export const GET_DASHBOARD_CAROUSEL_SUCCESS = 'GET_DASHBOARD_CAROUSEL_SUCCESS'
export const GET_DASHBOARD_CAROUSEL_FAILURE = 'GET_DASHBOARD_CAROUSEL_FAILURE'

export const GET_WORDPRESS_CAROUSEL_INIT = 'GET_WORDPRESS_CAROUSEL_INIT'
export const GET_WORDPRESS_CAROUSEL_SUCCESS = 'GET_WORDPRESS_CAROUSEL_SUCCESS'
export const GET_WORDPRESS_CAROUSEL_FAILURE = 'GET_WORDPRESS_CAROUSEL_FAILURE'
