import React from 'react'

const Info = ({
  width = 20,
  height = 20,
  color = '#9ea1a4',
}) => {
  return (
    <svg viewBox="0 0 54 54" width={width} height={height}>
      <path
        fill={color}
        d="M32,57.19A25.19,25.19,0,1,1,57.19,32,25.22,25.22,0,0,1,32,57.19ZM32,9.81A22.19,22.19,0,1,0,54.19,32,22.21,22.21,0,0,0,32,9.81Zm2.38,11.68a2.13,2.13,0,0,1-4.26,0,2.09,2.09,0,0,1,2.15-2.15A2,2,0,0,1,34.38,21.49Zm-3.82,24.2V26.8H34V45.69H30.55Z"
        transform="translate(-6.81 -6.81)"
      />
    </svg>
  )
}

export default Info
