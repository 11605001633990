/* eslint-disable no-restricted-globals */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'

import BuySell from './BuySell'

import { getScripLotSize, placeDirect } from '../../containers/Screener/actions'
import { BUY_SELL_TYPE_MAP } from './buySellUtils'
import { placeOrder, markUsed } from '../../containers/Notifications/actions'
import { stopAlgo } from '../../containers/Deployed/actions'
import { exitPosition } from '../../containers/Portfolio/actions'
import { showSnackbar } from '../../UI/Snackbar'
import { notifPair } from '../../containers/Instruments/actions'
import {
  getHeaderUuid,
} from '../../utils/common'
import { getOrderCharges } from '../../containers/Marketwatch/actions'
import { BROKERS } from '../../utils/consts'
import { openStreakUrl } from '../../utils/apiEndpoints'

// eslint-disable-next-line max-len
// const stopAlgoMsg = ['Place SL-M order', 'Cancel SL-M order', 'Place SL order', 'Cancel SL order']

const SLorderType = ['SL', 'SL-M']

class BuySellIndex extends Component {
  componentDidMount() {
    this.getHeaders()
  }

  componentDidUpdate(prevProps) {
    const {
      isPlacingOrder,
      placingError,
      placingErrorMsg,
      placingOrderResp,
      placeDirectResp,
      placeDirectError,
      placeDirectErrorMsg,

      isExitingPosition,
      exitingPositionError,
      exitingPositionErrorMsg,
      exitPositionResp,

      isGettingStopAlgoResp,
      stopAlgoResp,
      stopAlgoError,
      route,
      user_details: { broker },
    } = this.props
    if (prevProps.isPlacingOrder !== isPlacingOrder && !isPlacingOrder) {
      if (!isPlacingOrder && !placingError && placingOrderResp && placingOrderResp.status) {
        let msg = ''
        const { order_type = '', transaction_type } = placingOrderResp
        if (placingOrderResp.status === 'success' && !placingOrderResp.error_msg) {
          if (SLorderType.includes(order_type) && transaction_type === 'SELL') {
            msg = 'Order Sent to Exchange!! \n Note : SL/SL-M order has been placed. Remember to cancel this open order if you exit your position by acting on Target profit or exit alerts.'
          } else {
            msg = 'Order Sent to Exchange!!'
          }
        } else if (placingOrderResp.error_msg) {
          msg = placingOrderResp.error_msg
        } else if (placingOrderResp.status === 'used') {
          msg = 'Action has already been taken!!'
        } else {
          msg = `${placingOrderResp.status}`
        }
        showSnackbar(msg, { hideDelay: 4000 })
        this.onDismiss()
        setTimeout(() => {
          close()
        }, 2000)
      } else if (!isPlacingOrder && placingError) {
        if (broker === BROKERS.ZERODHA.name) {
          const linkRegex = /\(([^)]+)\)/
          const linkMatch = placingErrorMsg.match(linkRegex)
          const readMoreLink = linkMatch ? linkMatch[1] : ''
          const modErrorMsg = placingErrorMsg.split('[Read more.]')[0]
          showSnackbar(
            modErrorMsg,
            { showReadMore: true, link: readMoreLink },

            0,
          )
          setTimeout(() => {
            close()
          }, 2000)
        } else { showSnackbar(placingErrorMsg, {}, 0) }
      }
    }
    if (!isEqual(placeDirectResp, prevProps.placeDirectResp) && placeDirectResp.status === 'success') {
      showSnackbar(placeDirectResp.msg || 'Order Sent to Exchange!!')
      this.onDismiss()
      parent.location.href = `${openStreakUrl}/orderbook/pending`
    } else if (!isEqual(placeDirectError, prevProps.placeDirectError) && placeDirectError) {
      showSnackbar(placeDirectErrorMsg, {}, 0)
    }
    if (prevProps.isExitingPosition && !isExitingPosition) {
      if (exitPositionResp.status === 'success') {
        showSnackbar('Order Sent to Exchange!!')
        this.onDismiss()
        setTimeout(() => {
          close()
        }, 1000)
      } else if (exitPositionResp.status === 'error') {
        showSnackbar(exitPositionResp.error_msg, {}, 0)
      } else if (exitingPositionError) {
        showSnackbar(exitingPositionErrorMsg || 'Error exiting position', {}, 0)
      }
    }

    // if (prevProps.isGettingStopAlgoResp && !isGettingStopAlgoResp) {
    //   if (stopAlgoResp.status === 'success') {
    //     // we are showing a modal
    //     if (route.params && route.params.buySellType === BUY_SELL_TYPE_MAP.STOP_ALGO.TYPE) {
    //       showSnackbar('Order Sent to Exchange!!')
    //     } else {
    //       showSnackbar('strategy stopped successfully', {}, 1)
    //     }
    //   } else if (stopAlgoResp.status === 'error') {
    //     showSnackbar(stopAlgoResp.error_msg, {}, 0)
    //   } else if (stopAlgoError) {
    //     showSnackbar('Unable to stop the strategy', {}, 0)
    //   }
    //   this.onDismiss()
    // }
    if(prevProps.isGettingStopAlgoResp && !isGettingStopAlgoResp) {
      if (stopAlgoResp.status === 'success') {
        // we are showing a modal
        if (route.params && route.params.buySellType === BUY_SELL_TYPE_MAP.STOP_ALGO.TYPE) {
          showSnackbar('Order Sent to Exchange!!')
        } else {
          showSnackbar('strategy stopped successfully', {}, 1)
        }
        setTimeout(() => {
          close()
        }, 2000)
      } else if (stopAlgoResp.status === 'error') {
        showSnackbar(stopAlgoResp.error_msg, {}, 0)
      } else if(stopAlgoError) {
        if(stopAlgoResp?.error_msg) {
          showSnackbar(stopAlgoResp.error_msg, {}, 0)
        } else {
          showSnackbar('Unable to stop the strategy', {}, 0)
        }
      }
      this.onDismiss()
    }
  }

  componentWillUnmount() {
    if (this.hardwareBackHandler) this.hardwareBackHandler.remove()
  }

  getHeaders = () => {
    const appUUID = getHeaderUuid()
    this.uuidHeader = { 'X-APP-UUID': appUUID }
  }

  onDismiss = () => {
    const { onDismiss, dismissKey } = this.props
    onDismiss(dismissKey)
  }

  placeOrder = (orderParams) => {
    const {
      placeDirectOrder,
      placeOrderAlgo,
      stopAlgoNow,
      exitPositionFunc,
      route,
      csrfOpen,
      sessionidOpen,
    } = this.props
    const Authorization = `csrftoken=${csrfOpen};csrfmiddlewaretoken=${csrfOpen};sessionid=${sessionidOpen}`

    // route props
    const {
      buySellType, openPositionsData, stockDetails, item,
    } = route.params
    const {
      variety,
      order_type = 'MARKET',
      price,
      quantity,
      trailing_stoploss,
      stoploss,
      squareoff,
      product,
      trigger_price,
    } = orderParams
    let params = {
      variety,
      order_type,
      price,
      csrfmiddlewaretoken: csrfOpen,
      broker: 'zerodha',
      platform: 'openstreak',
    }

    switch (buySellType) {
      case BUY_SELL_TYPE_MAP.DIRECT.TYPE: {
        const {
          symbol,
          segment,
          action_type,
        } = item
        if (orderParams.variety === 'BO') {
          params = {
            ...params,
            trailing_stoploss,
            stoploss,
            squareoff,
          }
        }

        const finalTriggerPrice = order_type === 'MARKET' || order_type === 'LIMIT' ? price : trigger_price

        const final_params = {
          ...params,
          tradingsymbol: symbol,
          exchange: segment,
          transaction_type: action_type.toUpperCase(),
          quantity,
          product,
          price,
          trigger_price: finalTriggerPrice,
          disclosed_quantity: quantity,
          validity: 'DAY',
        }
        placeDirectOrder(final_params, { Authorization, 'x-csrftoken': sessionidOpen, ...this.uuidHeader })
        break
      }

      case BUY_SELL_TYPE_MAP.DEPLOYED.TYPE:
      case BUY_SELL_TYPE_MAP.NOTIFICATION.TYPE: {
        if (variety === 'BO') {
          params = {
            ...params,
            trailing_stoploss,
            stoploss,
            squareoff,
          }
        }
        const {
          notification_uuid,
          deployment_uuid,
          algo_uuid,
          token,
          action_type,
          quantity: defaultQQty,
          product: defaultProduct,
          algo_name,
          seg,
          sym,
          exchange,
          trigger_price: defaultTrigger,
          // sender: defaultSender,
        } = item
        // const value = notification_data.value
        // this.logs[0].notification_data['price_trigger-notification']
        let type = (order_type === 'SL' || order_type === 'SL-M') ? 'discipline' : 'normal'
        params = {
          ...params,
          notification_uuid,
          deployment_uuid,
          algo_uuid,
          transaction_type: action_type.toUpperCase(),
          quantity: defaultQQty,
          product: defaultProduct,
          validity: 'DAY',
          algo_name,
        }
        if (item['price_trigger-notification']) {
          type = 'tpsl'
          params = {
            ...params,
            token,
            tpsl_key: item['price_trigger-notification'],
          }
        } else {
          params = {
            ...params,
            exch: exchange,
            seg,
            sym,
            trigger_price: defaultTrigger,
          }
          if (!params.exch) {
            params.exch = seg.split('-')[0]
          }
        }
        // return console.log('type', type, 'params', params)
        placeOrderAlgo(type, params, { Authorization, 'x-csrftoken': sessionidOpen, ...this.uuidHeader })
        break
      }

      case BUY_SELL_TYPE_MAP.PORTFOLIO_EXIT.TYPE: {
        // let extraParams = { order_type, price, variety }
        if (variety === 'BO') {
          params = {
            ...params,
            stoploss,
            squareoff,
            trailing_stoploss,
          }
        }
        const {
          symbol,
          exchange,
        } = item
        params = {
          ...params,
          symbol,
          exchange,
          product,
          platform: 'openstreak',
          quantity: item.quantity,
        }
        exitPositionFunc(params, { Authorization, 'x-csrftoken': sessionidOpen, ...this.uuidHeader })
        break
      }

      case BUY_SELL_TYPE_MAP.STOP_ALGO.TYPE: {
        if (variety === 'BO') {
          params = {
            ...params,
            trailing_stoploss,
            stoploss,
            squareoff,
          }
        }
        const {
          seg,
          sym,
          product: defaultProduct,
          positions: { qty },
        } = openPositionsData
        const {
          algo_obj: { action_type, algo_uuid },
          deployment_uuid,
          algo_name,
        } = stockDetails
        const { sym: symPos, segment } = item
        const renderType = action_type.toUpperCase() === 'BUY' ? 'SELL' : 'BUY'
        const exch = seg.split('-')[0]
        params = {
          ...params,
          deployment_uuid,
          algo_uuid,
          algo_name,
          exch,
          seg,
          sym,
          transaction_type: renderType,
          quantity: Math.abs(qty),
          product: defaultProduct,
          validity: 'DAY',
        }
        if(sym.includes('DYNAMIC CONTRACT')) {
          params.exch = segment
          params.seg = symPos
          params.sym = symPos
        }
        stopAlgoNow('exitNow', params, { Authorization, 'x-csrftoken': sessionidOpen, ...this.uuidHeader })
        break
      }

      default:
        break
    }
  }

  cancelOrder = () => {
    // const popup = window.open('', '_parent', '')
    // popup.close()

    const {
      route, csrf,
      markAsUsed,
      // stopAlgorigthm,
    } = this.props
    const { buySellType, item } = route.params
    if (buySellType === BUY_SELL_TYPE_MAP.NOTIFICATION.TYPE
        || buySellType === BUY_SELL_TYPE_MAP.DEPLOYED.TYPE) {
      const {
        notification_uuid,
        notification_msg,
      } = item
      const params = {
        notification_uuid,
        csrfmiddlewaretoken: csrf,
        platform: 'openstreak',
      }
      if(buySellType === BUY_SELL_TYPE_MAP.NOTIFICATION.TYPE) {
        // markAsUsed(params)
      }
      if(notification_msg === 'Place SL-M order' || notification_msg === 'Place SL order') {
        markAsUsed(params)
      }
      const stop_algo_params = {
        csrfmiddlewaretoken: csrf,
      }
      if (item['price_trigger-notification']) {
        stop_algo_params.deployment_uuid = item['price_trigger-notification'].split(':')[1]
      } else {
        stop_algo_params.deployment_uuid = item.deployment_uuid
      }
      // if (!stopAlgoMsg.includes(notification_msg)) {
      //   stopAlgorigthm('keepPositionsOpen', stop_algo_params)
      // }
      // const { order_type, action_type, sender } = item
      // let to_send_action = action_type
      // let notif_type = 'ENTRY'
      // if (order_type === 'SL-M') {
      //   notif_type = 'SL_M_TYPE'
      // } else if (item.type === 'inrange') {
      //   notif_type = 'ENTRY'
      // } else if (item.type === 'stop-loss') {
      //   notif_type = 'STOP_LOSS_ACTION'
      // } else if (item.type === 'take-profit') {
      //   notif_type = 'TARGET_PROFIT_ACTION'
      // } else if (item['notification-type'] === 'order-notification') {
      //   if (sender === 'lambda') {
      //     notif_type = 'ENTRY'
      //   } else {
      //     notif_type = 'EXIT'
      //   }
      // }
      // if (item['price_trigger-notification']) {
      //   to_send_action = item['price_trigger-notification'].split(':')[9]
      // }
      // notif_type = `${notif_type}_CANCEL`
      // LOG EVENT
      // this.analytics.logEvent(events[notif_type], { action_type: to_send_action })
    }
    // this.onDismiss()

    close()
  }

  render() {
    const {
      route,
      navigation,
      isPlacingOrder,
      isPlacingDirectOrder,
      isGettingStopAlgoResp,
      user_details,
      subscribePair,
      fetchOrderCharges,
      orderCharges,
      isFetchingOrderCharges,
      orderChargesErr,
      orderChargesErrMsg,
      isExitingPosition,
      scriptLotSize,
      getScripLotSizeData,
      posProduct,
      sessionidOpen,
      csrfOpen,
    } = this.props
    const demat_consent = user_details && user_details.demat_consent
    return (
      <BuySell
        placeOrder={this.placeOrder}
        cancelOrder={this.cancelOrder}
        demat_consent={demat_consent}
        subscribePair={subscribePair}
        user_details={user_details}
        loading={isPlacingOrder || isPlacingDirectOrder
          || isGettingStopAlgoResp || isExitingPosition}
        {...route}
        {...route.params}
        navigation={navigation}
        fetchOrderCharges={fetchOrderCharges}
        orderCharges={orderCharges}
        isFetchingOrderCharges={isFetchingOrderCharges}
        orderChargesErr={orderChargesErr}
        orderChargesErrMsg={orderChargesErrMsg}
        getScripLotSizeData={getScripLotSizeData}
        scriptLotSize={scriptLotSize}
        posProduct={posProduct}
        csrf={csrfOpen}
        sessionid={sessionidOpen}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    ...state.auth.loginResp,

    isPlacingOrder: state.notification.isPlacingOrder,
    placingError: state.notification.placingError,
    placingErrorMsg: state.notification.placingErrorMsg,
    placingOrderResp: state.notification.placingOrderResp,

    isPlacingDirectOrder: state.screener.isPlacingDirectOrder,
    placeDirectResp: state.screener.placeDirectResp,
    placeDirectError: state.screener.placeDirectError,
    placeDirectErrorMsg: state.screener.placeDirectErrorMsg,

    isGettingStopAlgoResp: state.deployed.isGettingStopAlgoResp,
    stopAlgoResp: state.deployed.stopAlgoResp,
    stopAlgoError: state.deployed.stopAlgoError,

    isExitingPosition: state.portfolio.isExitingPosition,
    exitingPositionError: state.portfolio.exitingPositionError,
    exitPositionResp: state.portfolio.exitPositionResp,
    exitingPositionErrorMsg: state.portfolio.exitingPositionErrorMsg,

    isFetchingOrderCharges: state.market.isFetchingOrderCharges,
    orderCharges: state.market.orderCharges,
    orderChargesErr: state.market.orderChargesError,
    orderChargesErrMsg: state.market.orderChargesErrorMsg,

    scriptLotSize: state.screener.scriptLotSize,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    placeDirectOrder: (params, headers) => dispatch(placeDirect(params, headers)),
    placeOrderAlgo: (type, params, headers) => dispatch(placeOrder(type, params, headers)),
    stopAlgoNow: (type, params, headers) => dispatch(stopAlgo(type, params, headers)),
    exitPositionFunc: (params, headers) => dispatch(exitPosition(params, headers)),
    markAsUsed: (params, headers) => dispatch(markUsed(params, headers)),
    stopAlgorigthm: (type, params, headers) => dispatch(stopAlgo(type, params, headers)),
    subscribePair: (pairs, screenName) => notifPair(dispatch, pairs, screenName),
    fetchOrderCharges: (params, headers) => dispatch(getOrderCharges(params, headers)),
    getScripLotSizeData: params => dispatch(getScripLotSize(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuySellIndex)
